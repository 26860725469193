import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

interface Props {
  currentReaderId: string | false;
  blockAllTimer: number | false;
}
const initialState: Props = {
  currentReaderId: false,
  blockAllTimer: false,
};

export const cardTagReaderReducer = createSlice({
  name: "cardTagReader",
  initialState,
  reducers: {
    setBlockAllTimer: (state, action: PayloadAction<any>) => {
      state.blockAllTimer = action.payload;
    },
    setCurrentReaderId: (state, action: PayloadAction<string | false>) => {
      state.currentReaderId = action.payload;
    },
  },
});

export const selectCurrentReaderId = (state: RootState) =>
  state.cardTagReader.currentReaderId;
export const selectBlockAllTimer = (state: RootState) =>
  state.cardTagReader.blockAllTimer;
export const { setBlockAllTimer, setCurrentReaderId } =
  cardTagReaderReducer.actions;
export default cardTagReaderReducer.reducer;
