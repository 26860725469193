import React, { useState, useEffect } from "react";
import TimeWithDescription from "../../../helpersComponents/NumberWithDescription/numberWithDescription";
import StyledBlock from "../../../helpersComponents/StyledBlock/styledBlock";
import { useTranslation } from "react-i18next";
import Scheduler from "../../../helpersComponents/Scheduler/sheduler";
import getRange from "../../../../HelpersFunctions/dateAndTime/getDateRange";
import "./worktime.scss";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import computeDate from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import minsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import TooltipContent from "./tooltipContent";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { statusDictionary } from "../../../../Constants/statusDictionary";
import TooltipSchedule from "../../../helpersComponents/Scheduler/tooltipSchedule";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import useWorkerTimeConxtexMenuSchedule from "../../../helpersComponents/Scheduler/useWorkerTimeConxtexMenuSchedule";

const color = {
  lightGreen: "#E4FBE4",
  orange: "#FFE9C1",
  khaki: "#FFF0E68C",
  yellow: "#FFFFFF00",
  redLight: "#f57582",
  yellowLight: "#FDFDBA",
};

function WorktimeContent() {
  const { t } = useTranslation();
  const [dataModified, setDataModified] = useState();
  const workerTimeConxtexMenuArray = useWorkerTimeConxtexMenuSchedule();
  const [currentDateFetch, setCurrentDateFetch] = useState(() => {
    let schedulerLastDate = JSON.parse(
      localStorage.getItem("workerTimeSchedulerLastDate") || "{}"
    );
    if (schedulerLastDate?.creationTime) {
      if (
        new Date(schedulerLastDate.creationTime).getTime() >
        new Date().getTime() - 300000
      ) {
        return new Date(schedulerLastDate.currentDateFetch);
      }
    }
    return new Date();
  });
  const [eventComponents, setEventComponents] = useState([]);
  const [startdate, setstartdate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState(true);
  const [currentViewName, setCurrentViewName] = useState<
    "Month" | "CurrentWeek" | "Day"
  >("Month");
  const [
    timeSpentOnThePremisesOfTheCompany,
    setTimeSpentOnThePremisesOfTheCompany,
  ] = useState<string>("00:00");
  const [workingTime, setWorkingTime] = useState<string>("00:00");
  const [businessEntriesExits, setBusinessEntriesExits] =
    useState<string>("00:00");
  const [workAccordingToTheLaw, setWorkAccordingToTheLaw] =
    useState<string>("00:00");
  const [workTimeStandard, setWorkTimeStandard] = useState<string>("00:00");
  const [remainingWork, setRemainingWork] = useState<string>("00:00");
  const [todaysBalance, setTodaysBalance] = useState<string>("00:00");
  const [yesterdayBalance, setYesterdayBalance] = useState<string>("00:00");

  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const { data, error } = useFetchGet(
    `worker-time/${authUserId}/summaries?DateFrom=` +
      startdate +
      "&DateTo=" +
      endDate +
      "&GroupBy=day" +
      "&TimeWorkerId=" +
      JSON.parse(localStorage.getItem("authUser") || "{}").currentProfile
        .subjectId,
    !ifFetchAgain || startdate === null || endDate === null ? false : true
  );

  useEffect(() => {
    localStorage.setItem(
      "workerTimeSchedulerLastDate",
      JSON.stringify({
        creationTime: new Date(),
        currentDateFetch,
      })
    );
  }, [currentDateFetch]);

  useEffect(() => {
    setIsFetching(true);

    let range = getRange(currentDateFetch, currentViewName);

    if (!range) return;

    let computedData: Array<string> = [];
    computedData[0] = computeDate(range.startDate, "T00:00:00");
    computedData[1] = computeDate(range.endDate, "T23:59:59");
    setstartdate(computedData[0]);
    setEndDate(computedData[1]);
  }, [currentDateFetch, currentViewName]);

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setIfFetchAgain(false);
      return;
    }
    if (data === null) return;

    let dataModified: any = [];
    let eventComponentsArray: any = [];
    let id = 0;

    let timeSpentOnThePremisesOfTheCompanyLocal = 0;
    let workingTimeLocal = 0;
    let businessEntriesExitsLocal = 0;
    let workAccordingToTheLawLocal = 0;
    let workTimeStandardLocal = 0;
    let remainingWorkLocal = 0;

    const yesterday = new Date().setHours(0, 0, 0, 0);

    data.forEach((value, i) => {
      // if (value.workTimeStandard === 0) return
      if (
        new Date(value.date).setHours(0, 0, 0, 0) ===
        new Date().setHours(0, 0, 0, 0)
      ) {
        setTodaysBalance(convertMinsToHrsMins(value.normBalance));
      } else if (
        new Date(value.date).setHours(0, 0, 0, 0) ===
        new Date(yesterday).setDate(new Date().getDate() - 1)
      ) {
        setYesterdayBalance(convertMinsToHrsMins(value.normBalance));
      }

      timeSpentOnThePremisesOfTheCompanyLocal +=
        value.timeSpentOnThePremisesOfTheCompany;
      workingTimeLocal += value.workingTime;
      businessEntriesExitsLocal += value.unpaidPrivatePass;
      workAccordingToTheLawLocal +=
        value.absencesWithWorkedNorm + value.workingTime;
      workTimeStandardLocal += value.workTimeStandard;
      if (data.length === i + 1) remainingWorkLocal = -value.normBalance;

      let startDateLocal = new Date(value.date);
      let endDateLocal = new Date(value.date);
      startDateLocal.setHours(7);
      endDateLocal.setHours(16);

      // Praca => color.LightGreen;
      // Niedopracowanie => color.Orange;
      // Nieobecnosc => wierszDanych.Contains("nieusprawiedliwiona") ? color.FromArgb(50, 255, 30, 30) : color.Khaki;
      // Wniosek => color.Yellow;

      if (value.absencesDetails != null && value.absencesDetails.length > 0) {
        value.absencesDetails.forEach((singleAbsence) => {
          let colorNumber = color.khaki;

          if (singleAbsence.absenceTypeId === 5) colorNumber = color.redLight;

          eventComponentsArray.push(
            <TooltipSchedule tooltipBody={TooltipContent(value, t)} key={id}>
              <div
                className={`oneEventMonthViewWorkingTime`}
                style={{
                  background: colorNumber,
                }}
              >
                {singleAbsence.absenceTypeName}{" "}
                {convertMinsToHrsMins(value.absences)}
              </div>
            </TooltipSchedule>
          );

          let eventModifiedValue = {
            id: id,
            startDate: new Date(value.startWorkTime),
            endDate: new Date(value.startWorkTime).setHours(15),
            backgroundColor: colorNumber,
          };
          dataModified.push(eventModifiedValue);
          id++;
        });
        if (value.workingTime > 0) {
          eventComponentsArray.push(
            <TooltipSchedule tooltipBody={TooltipContent(value, t)} key={id}>
              <div
                className={"oneEventMonthViewWorkingTime"}
                style={{
                  background: color.lightGreen,
                }}
              >
                {`CC = ${convertMinsToHrsMins(
                  value.timeSpentOnThePremisesOfTheCompany
                )}   CP = ${convertMinsToHrsMins(
                  value.workingTime
                )}   WP = ${convertMinsToHrsMins(value.unpaidPrivatePass)}`}
              </div>
            </TooltipSchedule>
          );
          let eventModifiedValue = {
            id: id,
            startDate: new Date(value.startWorkTime),
            endDate: new Date(value.startWorkTime).setHours(15),
            backgroundColor: color.lightGreen,
          };
          dataModified.push(eventModifiedValue);
          id++;
        }
      } else {
        if (value.workingTime > 0) {
          let colorNumber =
            value.workingTime < value.workTimeStandard
              ? color.orange
              : color.lightGreen;

          eventComponentsArray.push(
            <TooltipSchedule tooltipBody={TooltipContent(value, t)} key={id}>
              <div
                className={"oneEventMonthViewWorkingTime workedOut"}
                style={{
                  background: colorNumber,
                }}
              >
                <div>
                  {`${new Date(value.startWorkTime).toLocaleString(
                    t("scheduler_language"),
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )} - ${new Date(value.endWorkTime).toLocaleString(
                    t("scheduler_language"),
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )} ${t("worked")}, ${t("shift")} ${value.shift}`}
                </div>
                <div>
                  {`${t("cc_worktime")} = ${convertMinsToHrsMins(
                    value.timeSpentOnThePremisesOfTheCompany
                  )}
                                     ${t(
                                       "cp_worktime"
                                     )} = ${convertMinsToHrsMins(
                    value.workingTime
                  )}   ${t("wp_worktime")} =
                                      ${convertMinsToHrsMins(
                                        value.unpaidPrivatePass
                                      )}`}
                </div>
                <div>{`${t("overtime_50")} = ${convertMinsToHrsMins(
                  value.overTime50
                )}`}</div>
                <div>{`${t("overtime_100")} = ${convertMinsToHrsMins(
                  value.overTime100
                )}`}</div>
                <div>
                  {" "}
                  {`${t("late")} = ${convertMinsToHrsMins(value.late)}`}
                </div>
              </div>
            </TooltipSchedule>
          );
          let eventModifiedValue = {
            id: id,
            startDate: new Date(value.startWorkTime),
            endDate: new Date(value.startWorkTime).setHours(15),
            backgroundColor: colorNumber,
          };
          dataModified.push(eventModifiedValue);
          id++;
        } else {
          if (
            value.uncancelledRequests != null &&
            value.uncancelledRequests.length > 0
          ) {
            eventComponentsArray.push(
              <TooltipSchedule tooltipBody={TooltipContent(value, t)} key={id}>
                <div
                  className={"oneEventMonthViewWorkingTime workedOut"}
                  style={{
                    background: color.yellowLight,
                  }}
                >
                  <div>{`${t("request")}: ${
                    value.uncancelledRequests[0]?.absenceType.name
                  }`}</div>
                  <div>{`${t("status")}: ${t(
                    statusDictionary[value.uncancelledRequests[0]?.status]
                  )}`}</div>
                  <div>{`${t("was_added")}: ${new Date(
                    value.uncancelledRequests[0]?.createdAt
                  ).toLocaleString(t("scheduler_language"), {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}`}</div>
                  <div>{`${t("period")}: ${new Date(
                    value.uncancelledRequests[0]?.dateFrom
                  ).toLocaleString(t("scheduler_language"), {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })} - ${new Date(
                    value.uncancelledRequests[0]?.dateTo
                  ).toLocaleString(t("scheduler_language"), {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}`}</div>
                </div>
              </TooltipSchedule>
            );
            let eventModifiedValue = {
              id: id,
              startDate: new Date(value.startWorkTime),
              endDate: new Date(value.startWorkTime).setHours(15),
              backgroundColor: color.yellowLight,
            };
            dataModified.push(eventModifiedValue);
            id++;
          }
        }
      }
    });

    setEventComponents(eventComponentsArray);
    setDataModified(dataModified);
    setTimeSpentOnThePremisesOfTheCompany(
      minsToHrsMins(timeSpentOnThePremisesOfTheCompanyLocal)
    );
    setWorkingTime(minsToHrsMins(workingTimeLocal));
    setBusinessEntriesExits(minsToHrsMins(businessEntriesExitsLocal));
    setWorkAccordingToTheLaw(minsToHrsMins(workAccordingToTheLawLocal));
    setWorkTimeStandard(minsToHrsMins(workTimeStandardLocal));
    setRemainingWork(minsToHrsMins(remainingWorkLocal));
    setIsFetching(false);
  }, [data, error, t]);

  return (
    <>
      <TopBanner pathName={t("worktime")} />
      <div className="flexAndCenter">
        <div>
          <TimeWithDescription
            parametrs={[
              { name: t("summary") },
              {
                time: timeSpentOnThePremisesOfTheCompany,
                description: t("attendane_in_company"),
                unit: "",
              },
              { time: workingTime, description: t("worktime_cp"), unit: "" },
              {
                time: businessEntriesExits,
                description: t("private_exit"),
                unit: "",
              },
              {
                time: workAccordingToTheLaw,
                description: t("work_according_to_the_law"),
                unit: "",
              },
              { time: workTimeStandard, description: t("norm"), unit: "" },
              {
                time: remainingWork,
                description: t("remain_to_work"),
                unit: "",
              },
              {
                time: todaysBalance,
                description: t("todays_balance"),
                unit: "",
              },
              {
                time: yesterdayBalance,
                description: t("yesterday_balance"),
                unit: "",
              },
            ]}
          />
        </div>
        <div>
          <StyledBlock>
            <LoadingWrapper
              isLodadingProgress={isFetching}
              isError={isError}
              setIfFetchAgain={setIfFetchAgain}
              setIsError={setIsError}
            >
              {!isFetching && !isError && (
                <Scheduler
                  data={dataModified}
                  currentDate={currentDateFetch}
                  setCurrentDate={setCurrentDateFetch}
                  components={eventComponents}
                  contextMenuOptions={workerTimeConxtexMenuArray}
                  currentViewName={currentViewName}
                  setCurrentViewName={setCurrentViewName}
                />
              )}
            </LoadingWrapper>
          </StyledBlock>
        </div>
      </div>
    </>
  );
}

export default WorktimeContent;
