function getTimeFromDate(date: Date, ifSeconds: boolean = false) {
  let time = checkValue(date.getHours()) + ":" + checkValue(date.getMinutes());

  if (ifSeconds) {
    time += ` : ${checkValue(date.getSeconds())}`;
  }
  return time;
}

function checkValue(value: number) {
  return value < 10 ? `0${value}` : value.toString();
}

export default getTimeFromDate;
