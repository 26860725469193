import ContentCommunications from "./ContentCommunications";

const Communications = () => {
  return (
    <div>
      <ContentCommunications />
    </div>
  );
};

export default Communications;
