import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileType } from "../../../../enums/profileType";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import ButtonStyled from "../../../helpersComponents/Button/button";
import { TextFieldStyled } from "../../../helpersComponents/MaterialUi";
import StyledBlock from "../../../helpersComponents/StyledBlock/styledBlock";
import styles from "../setting.module.scss";
import innerStyles from "./styles.module.scss";

const DefaultCompanyInSupportSetting = ({
  defaultCompanySettingValue,
  defaultComapnySettingId,
}: {
  defaultCompanySettingValue: string | undefined;
  defaultComapnySettingId: number | undefined;
}) => {
  const { t } = useTranslation();
  const [settingId, setSettingId] = useState(defaultComapnySettingId);
  const [typeMethod, setTypeMethod] = useState(
    defaultCompanySettingValue !== undefined ? "Update" : "Add"
  );

  const authUser = useAppSelector(selectAuthUser)?.currentProfile?.type;
  const profileType = ProfileType[authUser].toLowerCase().replaceAll("_", "-");

  const [defaultCompany, setDefaultCompany] = useState(
    defaultCompanySettingValue ? defaultCompanySettingValue : ""
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [requestBody, setReuqestBody] = useState<false | string>();
  const [fetchingStateData, fetchAgainData] = useFetchOtherThanGET({
    path:
      typeMethod === "Update"
        ? `${profileType}/preferences/${settingId}`
        : `${profileType}/preferences`,
    method: typeMethod === "Update" ? "PUT" : "POST",
    body: requestBody,
    contentType: "application/json",
    getErrorMessage: setErrorMessage,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (fetchingStateData.response?.status === 201) {
      setTypeMethod("Update");
    }

    if (fetchingStateData.response?.location) {
      let settingId = fetchingStateData.response?.location.substring(
        fetchingStateData.response.location.lastIndexOf("/") + 1
      );
      setSettingId(Number(settingId));
    }
  }, [fetchingStateData.response]);

  const submitChanges = () => {
    setReuqestBody(
      JSON.stringify({
        name: "DomyslnaFirmaListaPracownikow",
        value: defaultCompany,
      })
    );
  };

  useEffect(() => {
    if (requestBody) {
      fetchAgainData();
    }
  }, [requestBody, fetchAgainData]);

  return (
    <StyledBlock>
      <div>
        <div className={styles.title}>{`${t("default_company")} - ${t(
          "support"
        )}`}</div>
        <div className={styles.content}>
          <TextFieldStyled
            label={t("company")}
            ifToUpperCaseShipsAndPorts={true}
            value={defaultCompany}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDefaultCompany(e.target.value);
            }}
            className={innerStyles.textField}
          />

          <div className={styles.saveChanges}>
            <ButtonStyled
              onClick={() => submitChanges()}
              className={styles.saveButton}
            >
              {t("save")}
            </ButtonStyled>
          </div>

          <div className={styles.errorSettings}>{errorMessage}</div>
        </div>
      </div>
    </StyledBlock>
  );
};

export default DefaultCompanyInSupportSetting;
