import { useTranslation } from "react-i18next";
import ButtonStyled from "../../../../helpersComponents/Button/button";
import ObjectTypeStateItem from "./ObjectTypeStateItem";
import styles from "./objectTypeStatesContent.module.scss";

const ObjectTypeStatesContent = ({ fields, append, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.objectTypeStatesContainer}>
      <ButtonStyled
        onClick={() => {
          append({
            name: "",
            value: "",
          });
        }}
      >
        {t("add")}
      </ButtonStyled>

      <div className={styles.objectTypeStatesItemsContainer}>
        {fields.map((field, index) => (
          <ObjectTypeStateItem
            id={field.id}
            key={field.id}
            fields={fields}
            index={index}
            {...props}
          />
        ))}
      </div>
    </div>
  );
};

export default ObjectTypeStatesContent;
