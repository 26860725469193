import { ProfileType } from "../../../enums/profileType";
import ContentNotificationsInPorts from "../../helpersComponents/NotificationsInPorts/ContentNotificationsInPorts";

const NotificationInPortsOperationalSupport = () => {
  return (
    <ContentNotificationsInPorts
      pathApiNotifications="operational-support/notification-guests-in-ports"
      pathApiPermissionZones="operational-support/permission-zones?isActive=true&forEmployee=true"
      pathApiAttachments="operational-support/attachments-in-ports"
      layoutSettingName="layoutOperationalSupportNotificationsRecords"
      profileType={ProfileType.OPERATIONAL_SUPPORT}
      noChangeStatus={true}
      notificationPathName="operational-support/notifications"
    />
  );
};

export default NotificationInPortsOperationalSupport;
