import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { TextFieldFormik } from "../../helpersComponents/FormikInputs";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import { Row, RowInput, RowTitle } from "../../helpersComponents/PopupHelpers";
import * as Yup from "yup";

interface IProps {
  rowId?: number;
  onSubmit: (values: IAddEditCompany) => void;
}

const taxNumberRegex = /^\d\d\d-\d\d-\d\d-\d\d\d$/;

const PopupContentCompany: React.FC<IProps> = ({ rowId, onSubmit }) => {
  const { t } = useTranslation();

  const [fetchedRow, fetchingStateFetchedRow, fetchAgainFetchedRow] =
    useFetchAndSetGET<ICompany>({
      path: `superior-ac/ac-companies/${rowId}`,
      startFetchOnInitial: rowId ? true : false,
    });

  return (
    <div>
      <LoadingWrapper
        isLodadingProgress={fetchingStateFetchedRow.isFetching}
        isError={fetchingStateFetchedRow.isError}
        setIfFetchAgain={() => {
          if (fetchingStateFetchedRow.isError) {
            fetchAgainFetchedRow();
          }
        }}
      >
        {(fetchedRow || rowId === undefined) && (
          <Formik
            initialValues={{
              name: fetchedRow?.name ? fetchedRow.name : "",
              abbrev: fetchedRow?.abbrev ? fetchedRow.abbrev : "",
              taxNumber: fetchedRow?.taxNumber ? fetchedRow.taxNumber : "",
            }}
            onSubmit={(values) => {
              onSubmit(values);
            }}
            validationSchema={Yup.object({
              name: Yup.string().required(t("field_required")),
              abbrev: Yup.string().required(t("field_required")),
              taxNumber: Yup.string()
                .required(t("field_required"))
                .test("has_good_format", t("invalid_value"), function (value) {
                  if (value) return taxNumberRegex.test(value);

                  return false;
                }),
            })}
          >
            <Form noValidate id="submitForm">
              <Row>
                <RowTitle>{t("name")}:</RowTitle>
                <RowInput>
                  <TextFieldFormik
                    label={t("name")}
                    name="name"
                    required={true}
                  />
                </RowInput>
              </Row>

              <Row>
                <RowTitle>{t("abbrev_name")}:</RowTitle>
                <RowInput>
                  <TextFieldFormik
                    label={t("abbrev_name")}
                    name="abbrev"
                    required={true}
                  />
                </RowInput>
              </Row>

              <Row>
                <RowTitle>{t("tax_number")}:</RowTitle>
                <RowInput>
                  <TextFieldFormik
                    label={t("tax_number")}
                    name="taxNumber"
                    required={true}
                    mask={"999-99-99-999"}
                  />
                </RowInput>
              </Row>
            </Form>
          </Formik>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default PopupContentCompany;
