const convertMinsToHrsMins = (
  mins: number,
  emptyStringIfZeroMinutes: boolean = false
) => {
  if (emptyStringIfZeroMinutes && mins === 0) return "";
  if (mins >= 0) {
    let h: any = Math.floor(mins / 60);
    let m: any = Math.round(mins % 60);
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}`;
  } else {
    mins = -1 * mins;
    let h: any = Math.floor(mins / 60);
    let m: any = Math.round(mins % 60);
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;

    return `-${h}:${m}`;
  }
};
export default convertMinsToHrsMins;
