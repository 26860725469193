import { useTranslation } from "react-i18next";
import toLocaleStringCustom from "../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import styles from "./styles.module.scss";

const TableRegistrationNumbers = ({
  registrationNumbers,
}: {
  registrationNumbers: IRegistrationNumber[];
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{t("Id")}</th>
            <th>{t("plate_number")}</th>
            <th>{t("date_from")}</th>
            <th>{t("date_to2")}</th>
          </tr>
        </thead>
        <tbody>
          {registrationNumbers.map((registrationNumber, index) => {
            return (
              <tr key={registrationNumber.id} className="">
                <td>{++index}</td>
                <td className={styles.registrationNumberValue}>
                  {registrationNumber.registrationNumber}
                </td>
                <td>
                  {registrationNumber.dateFrom
                    ? toLocaleStringCustom({
                        date: registrationNumber.dateFrom,
                        t,
                      })
                    : "-"}
                </td>
                <td>
                  {registrationNumber.dateTo
                    ? toLocaleStringCustom({
                        date: registrationNumber.dateTo,
                        t,
                      })
                    : "-"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableRegistrationNumbers;
