export enum HourRequestType {
  OVERTIME = 1,
  HOURS_RECEPTION = 2,
  DAYS_RECEPTION = 3,
  PRIVATE_PASS = 4,
  BUSINESS_PASS = 5,
  WORKING_OFF = 6,
  UNPAID_PASS = 7,
  OVERTIME_FOR_HOURS_RECEPTION = 8,
  OVERTIME_FOR_DAY_RECEPTION = 9,
  EMPLOYEE_HOURS_RECEPTION = 10,
  OVERTIME_150 = 11,
  OVERTIME_200 = 12,
  LANGUAGE_HOURS_RECEPTION = 13,
  LANGUAGE_PRIVATE_PASS = 14,
  LANGUAGE_WORKING_OFF = 15,
  OVERNORMATIVE = 16,
  WORKING_HOURS = 17,
  QUITTING_WORK = 18,
  BIRTHDAY_EXIT = 19,
  Care188 = 20,
  OverworkPrivatePass = 21,
  WorkOffPrivatePass = 22,
  ConsentToStayOnThePremisesOfTheWorkPlaceOutsideWorkingHours = 23,
  WorkOffLate = 24,
  PrivatePassWithWorkOff = 25,
  BusinessPassWithPrivatePass = 26,
  Care188WithPrivatePass = 27,
  Care188WithBusinessPass = 28,
}
