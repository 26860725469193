import React from "react";
import { DialogContentStyled } from "../../../helpersComponents/PopupHelpers";
import FormTabs from "./Forms/FormTabs";

interface IProps {
  rowId?: number;
  onSubmit: (values: any) => void;
}

const PopupContentGroups: React.FC<IProps> = ({ rowId, onSubmit }) => {
  return (
    <div>
      <DialogContentStyled>
        <FormTabs rowId={rowId} onSubmit={onSubmit} />
      </DialogContentStyled>
    </div>
  );
};

export default PopupContentGroups;
