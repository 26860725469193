import { useState } from "react";
import AutocompleteStyled from "../../../helpersComponents/MaterialUi/Autocomplete/autocomplete";
import styles from "./../mapArrangment.module.scss";
import { useTranslation } from "react-i18next";
import { selectSettings } from "../../../../reducers/settings";
import { useAppSelector } from "../../../../store/hooks";

interface IProps {
  buildingsGroups: ISMSBuildingsGroup[];
  buildings: ISMSBuilding[];

  selectedBuildingGroup: ISMSBuildingsGroup | null;
  setSelectedBuildingGroup: React.Dispatch<
    React.SetStateAction<ISMSBuildingsGroup | null>
  >;

  selectedBuilding: ISMSBuilding | null;
  setSelectedBuilding: React.Dispatch<
    React.SetStateAction<ISMSBuilding | null>
  >;

  selectedFloor: ISMSBuildingFloor | null;
  setSelectedFloor: React.Dispatch<
    React.SetStateAction<ISMSBuildingFloor | null>
  >;
}

const MapArrangmentToolbar: React.FC<IProps> = ({
  buildingsGroups,
  buildings,
  selectedBuildingGroup,
  setSelectedBuildingGroup,
  selectedBuilding,
  setSelectedBuilding,
  selectedFloor,
  setSelectedFloor,
}) => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);
  const [filteredBuildings, setFilteredBuildings] = useState<any>([]);

  /*const [
    buildingsGroups,
    fetchingStateBuildingsGroups,
    fetchAgainBuildingsGroups,
  ] = useFetchAndSetGET<ISMSBuildingsGroup>({
    path: `sms-administrator/buildings-groups`,
    startFetchOnInitial: true,
  });

  const [buildings, fetchingStateBuildings, fetchAgainBuildings] =
    useFetchAndSetGET<ISMSBuilding[]>({
      path: `sms-administrator/buildings`,
      startFetchOnInitial: true,
    });*/

  /*useEffect(() => {
    if (
      settings.WebSMSWylaczObslugeGrupyBudynkow?.value === "1" &&
      !fetchingStateBuildings.isFetching &&
      fetchingStateBuildings.response?.status === 200
    ) {
      setFilteredBuildings(buildings);
    }
  }, [
    settings.WebSMSWylaczObslugeGrupyBudynkow?.value,
    buildings,
    fetchingStateBuildings.isFetching,
    fetchingStateBuildings.response?.status,
  ]);*/

  return (
    <div className={styles.mainContainerToolbar}>
      {settings.WebSMSWylaczObslugeGrupyBudynkow?.value !== "1" && (
        <div className={styles.toolbarItem}>
          <AutocompleteStyled
            value={selectedBuildingGroup || null}
            required={true}
            label={t("buildings_group")}
            options={buildingsGroups || []}
            getOptionLabel={(el) => el.name}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(_, buildingGroup: ISMSBuildingsGroup) => {
              setSelectedBuildingGroup(buildingGroup);

              if (buildings && buildingGroup) {
                let tempFilteredBuildings = buildings.filter(
                  (b) => b.buildingsGroupId === buildingGroup.id
                );
                setFilteredBuildings(tempFilteredBuildings);
              } else {
                setFilteredBuildings([]);
              }

              setSelectedBuilding(null);
              setSelectedFloor(null);
            }}
          />
        </div>
      )}
      <div className={styles.toolbarItem}>
        <AutocompleteStyled
          value={selectedBuilding || null}
          required={true}
          label={t("building")}
          options={filteredBuildings || []}
          getOptionLabel={(el) => el.name}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(_, building: ISMSBuilding) => {
            setSelectedBuilding(building);
            setSelectedFloor(null);
          }}
        />
      </div>
      <div className={styles.toolbarItem}>
        <AutocompleteStyled
          value={selectedFloor || null}
          required={true}
          label={t("floor")}
          options={selectedBuilding?.floors || []}
          getOptionLabel={(el) => el.name}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(_, floor: ISMSBuildingFloor) => {
            setSelectedFloor(floor);
          }}
        />
      </div>
    </div>
  );
};

export default MapArrangmentToolbar;
