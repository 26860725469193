import React from 'react';
import WorktimeContent from "./worktimeContent"


function Worktime() {
    return (
        <div>
            <WorktimeContent/>
        </div>
    );
}

export default Worktime;
