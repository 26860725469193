import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TableComponent from "./../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "./../../helpersComponents/TopBanner/topBanner";
import DatePickerRange from "../../helpersComponents/DatePickerRange/datePickerRange";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import ACWorkersList from "../../helpersComponents/ACWorkersList/acWorkersList";
import Button from "../../helpersComponents/Button/button";
import { CircularProgress } from "@material-ui/core";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";

function LockersEventsHistoryContent() {
  const { t } = useTranslation();

  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([]);
  const [, setAllWorkersSelected] = useState<boolean>(false);

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Month")
  );

  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Month"));

  const columns = [
    { name: "employee", title: t("employee") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "time", title: t("time"), type: "date_hours" },
    { name: "lockersModuleName", title: t("locker_module") },
    { name: "lockerName", title: t("locker") },
  ];

  const [rowsFiltered, setRowsFiltered] = useState<{}[] | null>(null);

  const [bodyRequest, setBodyRequest] = useState<any>(false);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: "lockers-management/lockers-events-history",
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  useEffect(() => {
    if (
      !fetchingStatePostData.isFetching &&
      !fetchingStatePostData.isError &&
      fetchingStatePostData.response?.status === 200 &&
      fetchingStatePostData.response.resJson
    ) {
      setRowsFiltered(fetchingStatePostData.response.resJson);
    } else {
      setRowsFiltered([]);
    }
  }, [
    fetchingStatePostData.isFetching,
    fetchingStatePostData.isError,
    fetchingStatePostData.response?.status,
    fetchingStatePostData.response?.resJson,
  ]);

  const confirmFilters = () => {
    let selectedWorkersLocal = [...selectedWorkers];
    let selectedWorkersStringLocal: string = "";
    selectedWorkersLocal.forEach((selectedId) => {
      selectedWorkersStringLocal =
        selectedWorkersStringLocal + selectedId.toString() + ",";
    });
    selectedWorkersStringLocal = selectedWorkersStringLocal.replace(
      /,\s*$/,
      ""
    );

    let queryObj = {
      acWorkersIds: selectedWorkersStringLocal.split(","),
      dateFrom: convertDateFetch(startDate),
      dateTo: convertDateFetch(endDate),
    };

    setBodyRequest(JSON.stringify(queryObj));
  };

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {fetchingStatePostData.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <Button
            onClick={() => {
              confirmFilters();
            }}
            disabled={!selectedWorkers || selectedWorkers.length === 0}
          >
            {t("fetch_lockers_events")}
          </Button>
        )}
      </div>
    </>
  );

  return (
    <>
      <TopBanner pathName={t("lockers_events")} />
      <div className="flexAndCenter">
        <div>
          <div>
            <ACWorkersList
              layoutSettingName="layoutSuperiorAcLockersEventsHistorySelectWorkers"
              setSelectedWorkers={setSelectedWorkers}
              selectedWorkers={selectedWorkers}
              pageSize={10}
              setAllWorkersSelected={setAllWorkersSelected}
            />
          </div>
        </div>
        <div>
          <DatePickerRange
            view={["year", "month", "date", "hours", "minutes"]}
            format="dd-MM-yyyy HH:mm"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            keyboardDateTimePicker={true}
            darkTheme={true}
            setDate2={setEndDate}
            confirmButtonRight={confirmButtonRight}
          ></DatePickerRange>
        </div>

        <div>
          {!fetchingStatePostData.isFetching && (
            <TableComponent
              rows={rowsFiltered ? rowsFiltered : []}
              columns={columns}
              layoutSettingName="layoutSuperiorAcLockersEventsHistory"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default LockersEventsHistoryContent;
