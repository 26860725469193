import {
  faArrowLeft,
  faCalendarAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
// import { selectAuthUser } from "../../../reducers/session";
// import { useAppSelector } from "../../../storeAndHooks/hooks";
import SelectLanguage from "../../SelectLanguage";
import "./topBanner.scss";
import SkalfiLogo from "./skalfiLogo.png";
import { useState } from "react";
import useLogout from "../../../hooks/LogOut/logOut";
import { withStyles } from "@material-ui/styles";
import { Tooltip } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

interface TopBannerInterface {
  pathName?: React.ReactNode;
  hideLogOut?: boolean;
  hideLogo?: boolean;
  showGoToLoginPage?: boolean;
  showBackButton?: boolean;
  extraElementNextToPath?: React.ReactNode;
}

const CustomTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "rgb(255, 255, 255);",
    // border: "1px solid rgb(163, 163, 163);",
    boxShadow: "1px -1px 7px -4px #000000;",
    fontSize: "12px;",
  },
})(Tooltip);

export const TopBanner = ({
  pathName,
  hideLogOut,
  hideLogo,
  showGoToLoginPage,
  showBackButton,
  extraElementNextToPath,
}: TopBannerInterface) => {
  const { t } = useTranslation();
  const [logOut, setLogOut] = useState<number>();
  useLogout(logOut);

  const history = useHistory();

  return (
    <div className="topBanner">
      {showBackButton && (
        <div className="backButton">
          <div onClick={history.goBack}>
            <CustomTooltip title={<div className="">{t("back")}</div>}>
              <div className="faArrowLeft">
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>
            </CustomTooltip>
          </div>
        </div>
      )}

      <div className="topBannerLeft">
        <div className="topBannerDateIcon">
          <FontAwesomeIcon
            className="faCalendarAltTopBanner"
            icon={faCalendarAlt}
          />{" "}
          &nbsp;
          <span className="topBannerDate">
            {new Date().toLocaleString(t("scheduler_language"), {
              weekday: "long",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </span>
          <span>&nbsp;(v{process.env.REACT_APP_VERSION})</span>
        </div>

        <div className="currentPathUsername">
          <span className="viewName">
            {extraElementNextToPath ? (
              <div>
                <div className={"extraElementNextToPath"}>
                  <div className="viewName">
                    <b>{pathName}</b>
                  </div>
                  <div>{extraElementNextToPath}</div>
                </div>
              </div>
            ) : (
              <b>{pathName}</b>
            )}
          </span>
        </div>
      </div>
      <div className="topBannerRight">
        <div className="topBannerRightContainer">
          <div className="langLogOut">
            {!hideLogOut && (
              <div className="topBannerLogOut">
                <CustomTooltip
                  title={
                    <div className="logOutTooltipTopBanner">{t("log_out")}</div>
                  }
                >
                  <div>
                    <FontAwesomeIcon
                      onClick={(e) => {
                        setLogOut(401);
                      }}
                      icon={faSignOutAlt}
                    />
                  </div>
                </CustomTooltip>
              </div>
            )}
            {showGoToLoginPage && (
              <div className="topBannerLogOut">
                <Link to={"/login"}>
                  <CustomTooltip
                    title={
                      <div className="logOutTooltipTopBanner">
                        {t("go_to_login_page")}
                      </div>
                    }
                  >
                    <div className="faArrowLeft">
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                  </CustomTooltip>
                </Link>
              </div>
            )}

            <div>
              <SelectLanguage />
            </div>
          </div>
          {!hideLogo && (
            <div>
              <img className="logoSize" src={SkalfiLogo} alt="Skalmex" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
