import styles from "./styles.module.scss";

interface IProps {
  text: string;
  color: string;
}

const CircleWithContent = ({ text, color }: IProps) => {
  return (
    <div className={styles.sharedNumber} style={{ backgroundColor: color }}>
      {text}
    </div>
  );
};

export default CircleWithContent;
