import React from "react";
import { GetCellValueFn } from "@devexpress/dx-react-grid";
import { TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import "./tableComponent.scss";

///////// show column title on hover ///////////
const LabelHeaderTooltip: React.ComponentType<TableHeaderRow.SortLabelProps> =
  ({ getMessage, column, ...restProps }) => {
    interface Column {
      name: string;
      title?: string;
      getCellValue?: GetCellValueFn;
    }
    interface ColumnExtended extends Column {
      customTooltip?: string;
    }
    let columnExtended: ColumnExtended = column;
    return (
      <TableHeaderRow.SortLabel
        column={column}
        className="sortingLabel"
        getMessage={(): any => {
          return columnExtended?.customTooltip
            ? columnExtended.customTooltip
            : columnExtended.title;
        }}
        {...restProps}
      />
    );
  };

export default LabelHeaderTooltip;
