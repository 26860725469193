import "./substitutions.scss";
import DialogStyled from "../../../../helpersComponents/PopupHelpers/dialogStyled";
import DialogTitleStyled from "../../../../helpersComponents/PopupHelpers/dialogTitleStyled";
import { useTranslation } from "react-i18next";
import LoadingWrapper from "../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import DialogActionsStyled from "../../../../helpersComponents/PopupHelpers/dialogActionsStyled";
import { useCallback, useEffect, useState } from "react";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DialogContentStyled } from "../../../../helpersComponents/PopupHelpers";
import AutocompleteFormik from "../../../../helpersComponents/FormikInputs/Autocomplete/autocomplete";
import DatePickerFormik from "../../../../helpersComponents/FormikInputs/DatePicker/datePicker";
import addDaysToDate from "../../../../../HelpersFunctions/dateAndTime/addDaysToDate";
import getEndOfDate from "../../../../../HelpersFunctions/dateAndTime/getEndOfDate";

interface IProps {
  row: any;
  isOpen: boolean;
  closePopup: () => void;
  successCallback: () => void;
}

function AddSubstitutionPopup({
  row,
  isOpen,
  closePopup,
  successCallback,
}: IProps) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [requestBody, setRequestBody] = useState<any>(false);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const [timeSuperiors, fetchingStateTimeSuperiors, fetchAgainTimeSuperiors] =
    useFetchAndSetGET<ITimeSuperiorBasicInfo[]>({
      path: `superior-time/time-superiors`,
      startFetchOnInitial: true,
    });

  const [substitution, fetchingStateSubstitution, fetchAgainSubstitution] =
    useFetchAndSetGET({
      path: `superior-time/substitutions/${row?.id}`,
      startFetchOnInitial: false,
    });

  useEffect(() => {
    if (row?.id && row?.id > 0) {
      fetchAgainSubstitution();
    }
  }, [row?.id, fetchAgainSubstitution]);

  const [fetchingStateAddEditSubstitution, fetchAgainAddEditSubstitution] =
    useFetchOtherThanGET({
      path: `superior-time/substitutions/${row?.id ? row.id : ""}`,
      method: row?.id ? "PUT" : "POST",
      body: requestBody,
      setBody: setRequestBody,
      contentType: "application/json",
      successCallback: successCallbackAndClose,
    });

  useEffect(() => {
    if (requestBody) {
      fetchAgainAddEditSubstitution();
    }
  }, [requestBody, fetchAgainAddEditSubstitution]);

  let initValues = {
    timeSuperior:
      row && timeSuperiors && substitution
        ? timeSuperiors.find((el) => el.id === substitution.substitutionId) ||
          null
        : null,
    dateFrom:
      row && substitution
        ? new Date(substitution.dateFrom)
        : addDaysToDate(new Date(), 1),
    dateTo:
      row && substitution
        ? new Date(substitution.dateTo)
        : getEndOfDate(new Date(), "Month"),
  };

  return (
    <>
      <DialogStyled
        open={isOpen}
        onClose={closePopup}
        TransitionProps={{
          onExited: () => {},
          onEnter: () => {},
        }}
      >
        <DialogTitleStyled
          title={row?.id ? t("editing_substitute") : t("adding_substitute")}
        />

        <LoadingWrapper
          isLodadingProgress={
            fetchingStateSubstitution.isFetching ||
            fetchingStateTimeSuperiors.isFetching
          }
          isError={
            fetchingStateSubstitution.isError ||
            fetchingStateTimeSuperiors.isError
          }
          setIfFetchAgain={() => {
            if (fetchingStateSubstitution.isError) {
              fetchAgainSubstitution();
            }

            if (fetchingStateTimeSuperiors.isError) {
              fetchAgainTimeSuperiors();
            }
          }}
        >
          {timeSuperiors && ((row?.id && substitution) || !row?.id) && (
            <DialogContentStyled>
              <Formik
                validateOnBlur={false}
                initialValues={initValues}
                enableReinitialize={true}
                onSubmit={(
                  values: {
                    timeSuperior: ITimeSuperiorBasicInfo | null;
                    dateFrom: Date;
                    dateTo: Date;
                  },
                  formikHelpers
                ) => {
                  if (values.timeSuperior == null) {
                    setErrorMessage(t("no_substitution_selected"));
                    return;
                  }

                  let objToSend = {
                    substitutionId: values.timeSuperior.id,
                    dateFrom: values.dateFrom,
                    dateTo: values.dateTo,
                  };

                  setRequestBody(JSON.stringify(objToSend));
                  setErrorMessage("");
                  formikHelpers.resetForm();
                }}
                validationSchema={Yup.object({
                  timeSuperior: Yup.object()
                    .nullable()
                    .required(t("field_required")),
                  dateFrom: Yup.date().nullable().required(t("field_required")),
                  dateTo: Yup.date().nullable().required(t("field_required")),
                })}
              >
                {({
                  values,
                  setFieldValue,
                  setFieldError,
                  setFieldTouched,
                }) => {
                  return (
                    <Form noValidate id="submitForm">
                      <div className="selectUser">
                        <div className="titleWidthSubstitutions">
                          {t("substitution")}:
                        </div>
                        <div className="inputWidthSubstitutions">
                          <AutocompleteFormik
                            options={timeSuperiors || []}
                            getOptionLabel={(option: any) => {
                              return `${option.name}[${option.fullName}]`;
                            }}
                            width="100%"
                            getOptionSelected={(
                              option: ITimeSuperiorBasicInfo,
                              value: ITimeSuperiorBasicInfo
                            ) => {
                              return option.id === value.id;
                            }}
                            label={""}
                            name="timeSuperior"
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="selectDateFrom">
                        <div className="titleWidthSubstitutions">
                          {t("date_from")}:
                        </div>
                        <div className="inputWidthSubstitutions">
                          <DatePickerFormik
                            label={t("date")}
                            name="dateFrom"
                            view={["year", "month", "date"]}
                            format="dd-MM-yyyy"
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="selectDateTo">
                        <div className="titleWidthSubstitutions">
                          {t("date_to2")}:
                        </div>
                        <div className="inputWidthSubstitutions">
                          <DatePickerFormik
                            label={t("date")}
                            name="dateTo"
                            view={["year", "month", "date"]}
                            format="dd-MM-yyyy"
                            required={true}
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </DialogContentStyled>
          )}
        </LoadingWrapper>

        <DialogActionsStyled
          typConfirm="submit"
          formConfirm="submitForm"
          onConfirmText={row?.id ? t("edit") : t("add")}
          onCancel={() => {
            setErrorMessage("");
            closePopup();
          }}
          isLoading={fetchingStateAddEditSubstitution.isFetching}
          errorString={errorMessage}
        />
      </DialogStyled>
    </>
  );
}

export default AddSubstitutionPopup;
