import { useTranslation } from "react-i18next";
import { ProfileType } from "../../../enums/profileType";

const useColumns = (profileType: ProfileType) => {
  const { t } = useTranslation();

  return profileType === ProfileType.PFSO ||
    profileType === ProfileType.SHIFT_DIRECTOR
    ? [
        { name: "name", title: t("name") },
        { name: "addingPerson", title: t("user") },
        { name: "createdAt", title: t("data_created"), type: "date" },
      ]
    : [
        { name: "name", title: t("name") },
        { name: "createdAt", title: t("data_created"), type: "date" },
      ];
};

export default useColumns;
