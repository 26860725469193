import TrainingsValidityReportContent from "../../../helpersComponents/TrainingsValidityReport/TrainingsValidityReportContext";

const TrainingValidityReportOperationalSupport = () => {
  return (
    <div>
      <TrainingsValidityReportContent
        pathName="operational-support/trainings-validity-report"
        layoutSettingName="layoutOperationalSupportVisitsReport"
        trainingExpiryDateApiPath={
          "operational-support/trainings-validity-report"
        }
      />
    </div>
  );
};

export default TrainingValidityReportOperationalSupport;
