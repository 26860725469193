import React, { useState, useEffect, useRef } from "react";
// import TextField from '@material-ui/core/TextField';
// import FormGroup from '@material-ui/core/FormGroup';
import Button from "../../../helpersComponents/Button/button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DatePicker from "../../../Helpers/DatePicker/datePicker"
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import CircularProgress from "@material-ui/core/CircularProgress";
// import computeDate from '../../../helpersFunctions/computeDateRangeFetch'
import useFetchPost from "../../../../hooks/fetchHooks/post/useFetchPost";
import useFetchPut from "../../../../hooks/fetchHooks/put/useFetchPut";
// import { hoursRequestsTypes } from '../../../../Constants/hoursRequestsTypes'
import "./notificationGuests.scss";
// import convertMinsToHrsMins from '../../../helpersFunctions/convertMinsToHrsMins';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  TextFieldStyled,
  CheckboxStyled,
} from "../../../helpersComponents/MaterialUi/index";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";

const Popup: React.FC = ({
  row,
  onApplyChanges,
  onCancelChanges,
  open,
}: any) => {
  const { t } = useTranslation();

  const [putingPostingCircularProgress, setPutingPostingCircularProgress] =
    useState<boolean>(false);
  const [responseErrorMessage, setResponseErrorMessage] = useState<string>();
  const [ifFetchGuestById, setIfFetchGuestById] = useState(false);
  const [postRequestBody, setPostRequestBody] = useState<any>(false);
  const [putRequestBody, setPutRequestBody] = useState<any>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [firstNameError, setFirstNameError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [lastNameError, setLastNameError] = useState<any>({
    isError: false,
    errorMessage: "",
  });

  const [ifClearReturnedValue, setIfClearReturnedValue] =
    useState<boolean>(false);
  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [company, setCompany] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [order, setOrder] = useState<number>(1000);
  const [checkbox, setCheckbox] = React.useState({
    active: true,
  });
  const handleChangeCheckbox = (event) => {
    setCheckbox({ ...checkbox, [event.target.name]: event.target.checked });
  };

  const [isLoading, setIsLoading] = useState<boolean>(
    row.hasOwnProperty("id") ? true : false
  );
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;

  let postRequest = useFetchPost(
    `worker-time/${authUserId}/guest-notifications`,
    postRequestBody,
    ifClearReturnedValue
  );
  let putRequest = useFetchPut(
    `worker-time/${authUserId}/guest-notifications/` + row.id,
    putRequestBody,
    ifClearReturnedValue
  );

  const GuestByIdResponse = useFetchGet(
    `worker-time/${authUserId}/guest-notifications/` + row.id,
    ifFetchGuestById && row.id ? true : false,
    ifClearReturnedValue
  );

  useEffect(() => {
    if (row.hasOwnProperty("id")) {
      setIfFetchGuestById(true);
      setIsLoading(true);
    } else setIsLoading(false);

    if (GuestByIdResponse.error !== null) {
      setIsError(true);
      setIfFetchGuestById(false);
      return;
    }
    if (GuestByIdResponse.data !== null && row.hasOwnProperty("id")) {
      setFirstName(GuestByIdResponse.data.firstName);
      setLastName(GuestByIdResponse.data.lastName);
      setCompany(GuestByIdResponse.data.company);
      setDescription(GuestByIdResponse.data.description);
      setOrder(GuestByIdResponse.data.order);
      setCheckbox({
        active: GuestByIdResponse.data.isActive,
      });

      setIsLoading(false);
    }
  }, [row, GuestByIdResponse, t]);

  const applyChanges = useRef(onApplyChanges);
  useEffect(() => {
    if (postRequest.data?.status === 201) {
      applyChanges.current(true);
      setPutingPostingCircularProgress(false);
    } else if (putRequest.data?.status === 200) {
      applyChanges.current();
      setPutingPostingCircularProgress(false);
    } else if (postRequest.data?.status === 400) {
      let error = "";
      Object.keys(postRequest.data.errors).forEach((errorObject) => {
        postRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setResponseErrorMessage(error);
    } else if (putRequest.data?.status === 400) {
      let error = "";
      Object.keys(putRequest.data.errors).forEach((errorObject) => {
        putRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setResponseErrorMessage(error);
    } else if (putRequest.error !== null) {
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(t("something_went_wrong"));
    } else if (postRequest.error !== null) {
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(t("something_went_wrong"));
    }
  }, [postRequest, putRequest, t]);

  const confirm = () => {
    let ifFetch = true;

    if (firstName === "") {
      setFirstNameError({
        isError: true,
        errorMessage: "required",
      });
      ifFetch = false;
    }
    if (lastName === "") {
      setLastNameError({
        isError: true,
        errorMessage: "",
      });
      ifFetch = false;
    }

    // if (!selectedHoursRequestsType) {
    //   setSelectedHoursRequestsTypeError(true)
    //   errorIfPost = false
    // }
    // else {
    //   setSelectedHoursRequestsTypeError(false)
    // }
    // setResponseErrorMessage("")

    if (!ifFetch) return;
    setPutingPostingCircularProgress(true);
    if (row.hasOwnProperty("id")) {
      setPutRequestBody({
        firstName,
        lastName,
        company,
        description,
        isActive: checkbox.active,
        order,
      });
    } else {
      setPostRequestBody({
        firstName,
        lastName,
        company,
        description,
        isActive: checkbox.active,
        order,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancelChanges}
      TransitionProps={{
        onExited: () => {
          setIfFetchGuestById(false);
          setIsLoading(true);
          setPostRequestBody(false);
          setPutRequestBody(false);
          setResponseErrorMessage("");
          setPutingPostingCircularProgress(false);
          setDescription("");
          setFirstName("");
          setLastName("");
          setIsError(false);
          setCompany("");
          setIfClearReturnedValue(true);
          setOrder(1000);
          setCheckbox({
            active: true,
          });
          setLastNameError({
            isError: false,
            errorMessage: "",
          });
          setFirstNameError({
            isError: false,
            errorMessage: "",
          });
        },
        onEnter: () => {
          setIfClearReturnedValue(false);
        },
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className="dialogTitleGlobal" onClick={() => {}}>
        <div className="titlePopup">{t("notification")}</div>
        <div className="description">{t("adding_visitor")}</div>
      </DialogTitle>

      <DialogContent>
        <LoadingWrapper
          isLodadingProgress={isLoading}
          isError={isError}
          setIfFetchAgain={setIfFetchGuestById}
          setIsError={setIsError}
          bigSize={true}
        >
          {!isLoading && !isError && (
            <div className="popupContentGuests">
              <div className="imputText">
                <div className="titleWidthNotifGuests">{t("first_name")}:</div>
                <div className="inputWidthNotifGuests">
                  <TextFieldStyled
                    label={t("first_name")}
                    defaultValue={firstName}
                    required={true}
                    isError={firstNameError.isError}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        setFirstNameError({
                          isError: true,
                          errorMessage: "required",
                        });
                      } else {
                        setFirstNameError({
                          isError: false,
                          errorMessage: "",
                        });
                      }
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="imputText">
                <div className="titleWidthNotifGuests">{t("last_name")}:</div>
                <div className="inputWidthNotifGuests">
                  <TextFieldStyled
                    label={t("last_name")}
                    defaultValue={lastName}
                    required={true}
                    isError={lastNameError.isError}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        setLastNameError({
                          isError: true,
                          errorMessage: "required",
                        });
                      } else {
                        setLastNameError({
                          isError: false,
                          errorMessage: "",
                        });
                      }
                      setLastName(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="imputText">
                <div className="titleWidthNotifGuests">{t("company")}:</div>
                <div className="inputWidthNotifGuests">
                  <TextFieldStyled
                    label={t("company")}
                    defaultValue={company}
                    onBlur={(e) => {
                      setCompany(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="imputText">
                <div className="titleWidthNotifGuests">{t("description")}:</div>
                <div className="inputWidthNotifGuests">
                  <TextFieldStyled
                    label={t("description")}
                    defaultValue={description}
                    onBlur={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="checkboxFormGuests">
                <div className="titleWidthNotifGuests">{t("active")}</div>
                <div>
                  <FormControlLabel
                    control={
                      <CheckboxStyled
                        checked={checkbox.active}
                        name="active"
                        onChange={handleChangeCheckbox}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={<span className="FormControlLabelStyle"></span>}
                  />
                </div>
              </div>

              <div className="imputText">
                <div className="titleWidthNotifGuests">{t("order")}:</div>
                <div className="inputWidthNotifGuests">
                  <TextFieldStyled
                    label={t("order")}
                    defaultValue={1000}
                    type="number"
                    onBlur={(e) => {
                      setOrder(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </LoadingWrapper>
      </DialogContent>

      <DialogActions className="dialogActionsGlobal">
        <div className="responseErrorMessage">{responseErrorMessage}</div>
        {!putingPostingCircularProgress ? (
          <>
            <Button onClick={onCancelChanges}>{t("cancel")}</Button>
            <Button
              onClick={() => {
                confirm();
              }}
            >
              {t("confirm")}
            </Button>
          </>
        ) : (
          <div className="confirmWaiting">
            <CircularProgress size={30} />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
