import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../helpersComponents/PopupHelpers";
import PopupContentPreApprovedCompanies from "./PopupContentPreApprovedCompanies";

const PopupPreApprovedCompanies = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
}: {
  isOpen: boolean;
  rowId: number | undefined;
  closePopup: () => void;
  successCallback: () => void;
}) => {
  const { t } = useTranslation();
  const [bodyRequest, setBodyRequest] = useState<string | false>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (values: IAddUpdatePreApprovedCompany) => {
    setErrorMessage("");
    setBodyRequest(JSON.stringify(values));
  };

  useEffect(() => {
    if (isOpen === false) {
      setErrorMessage("");
    }
  }, [isOpen]);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `pfso/pre-approved-companies${
      rowId !== undefined ? `/${rowId}` : ""
    }`,
    method: rowId !== undefined ? "PUT" : "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  return (
    <DialogStyled open={isOpen} onClose={closePopup}>
      <DialogTitleStyled title={t("add_company")} />
      <PopupContentPreApprovedCompanies rowId={rowId} onSubmit={onSubmit} />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupPreApprovedCompanies;
