import {
  faFireAlt,
  faInfo,
  faListUl,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import FireReportOhs from "../../ohs/FireReport";
import GuestsVisitsReportOhs from "../../ohs/GuestsVisitsReport";
import NotificationInPortsOHS from "../../ohs/NotificationsInPorts";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";
import { selectOHSNotificationMenuItemName } from "../../../reducers/notificationsSummary";
import NotificationInPort from "../../ohs/NotificationInPort";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);
  const notificationsMenuItemName = useAppSelector(
    selectOHSNotificationMenuItemName
  );
  const navLinksArrayOHS: navLinksArrayTimeWorkerInterface = {
    profileId: 12,
    hidenInNavBar: [
      {
        ifHidden: false,
        path: "/ohs/notifications/:id",
        component: (props) => <NotificationInPort {...props} />,
      },
    ],

    routesArrayWithComponents: [
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/ohs/profile",
        component: () => <Profile />,
      },
      {
        icon: faUserFriends,
        name: notificationsMenuItemName,
        ifHidden: false,
        singleLink: true,
        path: "/ohs/home",
        component: () => <NotificationInPortsOHS />,
      },
      {
        icon: faListUl,
        name: t("visits_report"),
        ifHidden: false,
        singleLink: true,
        path: "/ohs/visits-report",
        component: () => <GuestsVisitsReportOhs />,
      },
      {
        icon: faFireAlt,
        name: t("fire_report"),
        ifHidden: false,
        singleLink: true,
        path: "/ohs/fire-report",
        component: () => <FireReportOhs />,
      },
    ],
  };

  return navLinksArrayOHS;
};
export default useNavLinksArray;
