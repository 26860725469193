import "./numberWithDescription.scss";

interface Props {
  parametrs: Array<ObjectInterface>;
}

const TimeWithDescriptionSmall: React.FC<Props> = (props) => {
  return (
    <div className="numberWithDescriptionSmall">
      <div>
        <div className="elementsSmall">
          {props.parametrs.slice(1).map((value, inex) => {
            return (
              <div key={inex} className="containerSmall">
                <div className="vauleSmall">
                  {value.time} {value.unit}
                </div>
                <div className="descriptionSmall">{value.description}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TimeWithDescriptionSmall;
