import React, { useEffect, useRef, useState } from "react";
import styles from "../profile.module.scss";
import { selectAuthUser } from "../../../reducers/session";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectACWorkerProfile,
  setACWorkerProfile,
} from "../../../reducers/profiles";
import { useTranslation } from "react-i18next";
import profileIcon from "../../Navbar/Images/empty-profile-picture.png";
import SelectLanguage from "../../SelectLanguage";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faEdit } from "@fortawesome/free-solid-svg-icons";
import ChangePinPopup from "../Popups/ChangePinPopup";
import QRCode from "qrcode.react";
import StyledBlock from "../../helpersComponents/StyledBlock/styledBlock";
import { selectSettings } from "../../../reducers/settings";

function ACWorkerProfileContent() {
  const settings = useAppSelector(selectSettings);
  const authUser = useAppSelector(selectAuthUser);
  const profile = useAppSelector(selectACWorkerProfile);
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(true);
  const [fetchAgain, setFetchAgain] = useState(true);
  const [mobileToken, setMobileToken] = useState<string>();
  const [isError, setIsError] = useState<boolean>(false);
  const [ififShowPin, setIfifShowPin] = useState<boolean>(false);
  const authUserId = authUser.currentProfile.subjectId;
  const [isChangePinPopupOpen, setIsChangePinPopupOpen] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const fetchMobileToken = async () => {
    await fetch(window.globalConfig.API_URL + "/api/account/token-for-mobile", {
      method: "GET",
      credentials: window.globalConfig?.LOG_BY_WINDOWS_DOMAIN
        ? "include"
        : "omit",
      headers: {
        Authorization: "bearer " + authUser.token,
      },
    })
      .then((response) => {
        if (response.status === 200) return response.text();
        throw response.status;
      })
      .then((data) => {
        setIsFetching(false);
        setFetchAgain(false);
        setMobileToken(data);
      })
      .catch((error) => {
        setIsError(true);
        setFetchAgain(false);
      });
    setIsFetching(false);
  };
  const fetchMobileTokenRef = useRef(fetchMobileToken);

  useEffect(() => {
    if (fetchAgain === false) return;
    async function anyNameFunction() {
      await fetchMobileTokenRef.current();
    }
    anyNameFunction();
  }, [fetchAgain]);

  const changePin = () => {
    setIsChangePinPopupOpen(true);
  };

  return (
    <>
      <div>
        <div className={styles.profilHeader}>
          <div className={styles.profilHeaderLeft}>
            <div className={styles.profilIcon}>
              {profile?.photo ? (
                <img
                  src={"data:image/png;base64, " + profile.photo}
                  alt="Profil"
                />
              ) : (
                <img src={profileIcon} alt="Profil" />
              )}
            </div>
            <div className={styles.profilTopInfo}>
              <div>
                <strong>{profile?.firstName + " " + profile?.lastName}</strong>
              </div>
              <div>{profile?.position}</div>
              <div>
                {profile?.company} -{">"} {profile?.department}
              </div>
            </div>
          </div>
          <div className={styles.qrCodeDiv}>
            <LoadingWrapper
              isLodadingProgress={isFetching}
              isError={isError}
              setIfFetchAgain={setFetchAgain}
              setIsError={setIsError}
            >
              {!isFetching &&
                !isError &&
                mobileToken &&
                settings.WebPracownikKDUkryjKodQRMenuOMnie?.value !== "1" && (
                  <QRCode
                    size={192}
                    value={`{"token": "${mobileToken}", "apiUrl": "${window.globalConfig.API_URL}"}`}
                  />
                )}
            </LoadingWrapper>
          </div>
          <div className={styles.selectLanguageProfile}>
            <SelectLanguage />
          </div>
        </div>
      </div>
      <div>
        <StyledBlock>
          <div className={styles.profileInfoRowsContrainer}>
            <div className={styles.profileInfoRow}>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                <div>
                  <strong>{t("evidence_number")}:</strong>
                </div>
                <div>{profile?.evidenceNumber}</div>
              </div>
              <div
                className={
                  styles.profileInfoSecondColumn +
                  " " +
                  styles.profileInfoColumn
                }
              >
                <div>
                  <strong>PIN:</strong>
                </div>

                <div className={styles.pinContainer}>
                  <div>
                    {profile?.pin ? (
                      <>
                        {ififShowPin ? (
                          profile?.pin
                        ) : (
                          <span className={styles.fontPassword}>****</span>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className={styles.faPlusTable}
                      icon={ififShowPin ? faEye : faEyeSlash}
                      onClick={() => setIfifShowPin(!ififShowPin)}
                    />
                  </div>
                  <div className={styles.editButton}>
                    <FontAwesomeIcon
                      className={styles.faPlusTable}
                      icon={faEdit}
                      onClick={() => changePin()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.profileInfoRow}>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>Login:</strong>
                </div>
                <div>{profile?.login}</div>
              </div>
              <div
                className={
                  styles.profileInfoSecondColumn +
                  " " +
                  styles.profileInfoColumn
                }
              >
                {" "}
                <div>
                  <strong>ID:</strong>
                </div>
                <div>{profile?.id}</div>
              </div>
            </div>

            <div className={styles.profileInfoRow}>
              <div
                className={
                  styles.profileInfoFirstColumn + " " + styles.profileInfoColumn
                }
              >
                <div>
                  <strong>{t("identifier")}</strong>
                </div>
                <div>{profile?.identifier}</div>
              </div>
              <div
                className={
                  styles.profileInfoSecondColumn +
                  " " +
                  styles.profileInfoColumn
                }
              >
                <div>
                  <strong>{t("description")}:</strong>
                </div>
                <div>{profile?.description}</div>
              </div>
            </div>
          </div>
        </StyledBlock>
      </div>

      {isChangePinPopupOpen && (
        <ChangePinPopup
          changePinApiPath={`worker-ac/ac-workers/${authUserId}`}
          isOpen={isChangePinPopupOpen}
          closePopup={() => {
            setIsChangePinPopupOpen(false);
          }}
          successCallback={(newPin) => {
            dispatch(setACWorkerProfile({ ...profile, pin: newPin }));
            setIsChangePinPopupOpen(false);
          }}
        />
      )}
    </>
  );
}

export default ACWorkerProfileContent;
