import FireReportContent from "../../helpersComponents/FireReport/fireReport";
import NavigationReception from "../Navigation/navigationReception";

function FireReportReception() {
  return (
    <div>
      <FireReportContent
        fireReportPath="reception/fire-report"
        layoutSettingName="layoutSettingNameReceptionFireReport"
        navigation={NavigationReception}
        fireZonesPath="reception/fire-zones"
        pathPreferences="reception/preferences"
        onlyGuests={true}
        targetCompaniesPath="reception/guests-visits-target-companies"
      />
    </div>
  );
}

export default FireReportReception;
