import { useState } from "react";
import Performer from "../../noProfileViews/performer";
import TrainingRoot from "../../noProfileViews/training/trainingRoot";
import ChangePassword from "../../UserPanel/changePassword";
import Settings from "../../UserPanel/settings";

const useNavLinksArrayNoProfile = () => {
  const [navLinksArrayTimeWorker] = useState<NoProfileArrayObject>({
    routes: [
      {
        ifHidden: false,
        path: "/settings",
        component: () => <Settings />,
      },
      {
        ifHidden: false,
        path: "/performer-add-notification",
        component: () => <Performer />,
      },
      {
        ifHidden: false,
        path: "/change-password",
        component: (props) => <ChangePassword {...props} />,
      },
      {
        ifHidden: false,
        path: "/training",
        component: () => <TrainingRoot />,
      },
    ],
  });

  return navLinksArrayTimeWorker;
};
export default useNavLinksArrayNoProfile;
