import { ComponentType, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { CheckboxStyled } from "../MaterialUi";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import PopupUsersManagement from "./PopupUsersManagement";

interface Props {
  pathUsers: string;
  pathUserPermissions: string;
  pathDepartments: string;
  pathGroups: string;
  pathPolicies: string;
  layoutSettingName: string;
  SwitchStyled: ComponentType<any>;
}

const UsersManagementContent = ({
  pathUsers,
  pathUserPermissions,
  pathDepartments,
  pathGroups,
  pathPolicies,
  layoutSettingName,
  SwitchStyled,
}: Props) => {
  const { t } = useTranslation();
  const [isInactiveSwitchState, setIsInactiveSwitchState] = useState(false);

  const columns = [
    { name: "login", title: t("login") },
    { name: "permission", title: t("permissions") },
    { name: "fullName", title: t("full_name") },
    { name: "email", title: t("email") },
    { name: "phoneNumber", title: t("phone") },
    { name: "isLocked", title: t("locked") },
    { name: "isChangePasswordRequired", title: t("change_password_required") },
    { name: "loginAttempts", title: t("failed_login_attempts") },
    {
      name: "dateOfPasswordChange",
      title: t("password_change_data"),
      type: "date",
    },
    { name: "lastLogin", title: t("last_login_date"), type: "date" },
  ];

  const modifyResponseCallback = useCallback(
    (responseData) => {
      const modified: any[] = [];
      responseData.forEach((row: any) => {
        if (row.isActive === true && isInactiveSwitchState) return;
        else if (row.isActive === false && !isInactiveSwitchState) return;

        modified.push({
          ...row,
          fullName: row.fullName ? row.fullName : "-",
          phoneNumber: row.phoneNumber ? row.phoneNumber : "-",
          email: row.email ? row.email : "-",
          isLocked: <CheckboxStyled checked={row.isLocked} disabled />,
          isChangePasswordRequired: (
            <CheckboxStyled checked={row.isChangePasswordRequired} disabled />
          ),
          permission: row.permission.name.toUpperCase(),
        });
      });
      return modified;
    },
    [isInactiveSwitchState]
  );

  const [usersRows, fetchingStateUsers, fetchAgainUsers] = useFetchAndSetGET({
    path: pathUsers,
    modifyResponseCallback: modifyResponseCallback,
  });

  const IsActiveSwitch = (
    <div className="SwtichActive">
      {t("active")}
      <SwitchStyled
        checked={isInactiveSwitchState}
        onChange={() => setIsInactiveSwitchState(!isInactiveSwitchState)}
        name="checkedA"
      />
      {t("inactive")}
    </div>
  );

  const [userToRemoveOrRestore, setUserToRemoveOrRestore] = useState<{
    id: false | number;
    method: "DELETE" | "PATCH";
    body?: [
      {
        op: "replace";
        path: "/isActive";
        value: 1;
      }
    ];
  }>({
    id: false,
    method: "DELETE",
  });

  const [userRemoveOrRestoreFetchingState, userRemoveOrRestoreFetchAgain] =
    useFetchOtherThanGET({
      path: `${pathUsers}/${userToRemoveOrRestore?.id}`,
      method: userToRemoveOrRestore.method,
      body:
        userToRemoveOrRestore.method === "PATCH"
          ? JSON.stringify(userToRemoveOrRestore.body)
          : null,
      setBody: setUserToRemoveOrRestore,
      contentType: "application/json",
      successCallback: fetchAgainUsers,
    });

  useEffect(() => {
    if (userToRemoveOrRestore.id) {
      userRemoveOrRestoreFetchAgain();
    }
  }, [userToRemoveOrRestore.id, userRemoveOrRestoreFetchAgain]);

  return (
    <>
      <div>
        <TopBanner pathName={t("users")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <TableComponentMemo
            columns={columns}
            rows={usersRows}
            layoutSettingName={layoutSettingName}
            toolbarComponent={IsActiveSwitch}
            fetchingState={{
              isFetching:
                fetchingStateUsers.isFetching ||
                usersRows === null ||
                userRemoveOrRestoreFetchingState.isFetching,
              isError:
                fetchingStateUsers.isError ||
                userRemoveOrRestoreFetchingState.isError,
              fetchAgain: fetchAgainUsers,
            }}
            actionsColumnUpdated={{
              popup: PopupUsersManagement,
              extraInfo: {
                pathUsers,
                pathUserPermissions,
                pathDepartments,
                pathGroups,
                pathPolicies,
              },
              successCallbackPopup: fetchAgainUsers,
              addButton: {
                ifShow: true,
              },
              editButton: {
                ifShow: isInactiveSwitchState ? false : true,
                name: t("edit"),
              },
              removeButton: {
                ifShow: true,
                name: isInactiveSwitchState ? t("restore") : t("remove"),
                onClick: (row) => {
                  if (row?.isActive) {
                    setUserToRemoveOrRestore({
                      id: row.id,
                      method: "DELETE",
                      body: undefined,
                    });
                  } else {
                    setUserToRemoveOrRestore({
                      id: row.id,
                      method: "PATCH",
                      body: [
                        {
                          op: "replace",
                          path: "/isActive",
                          value: 1,
                        },
                      ],
                    });
                  }
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default UsersManagementContent;
