import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TrainingPersonType } from "../../../../Constants/trainingPersonType";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { useAppSelector } from "../../../../store/hooks";
import ButtonStyled from "../../Button/button";
import generateInitAnswers from "./generateInitAnswers";
import SingleQuestion from "./singleQuestion";
import styles from "./styles.module.scss";
import { selectAuthUser } from "../../../../reducers/session";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";

interface Props {
  training: Training;
  trainingPersonType: TrainingPersonType;
  examStartDate: Date;
  presentationStartDate: Date;
  endQuizCallback: any;
}

export default function Questions({
  training,
  trainingPersonType,
  examStartDate,
  presentationStartDate,
  endQuizCallback,
}: Props) {
  const authUserId = useAppSelector(selectAuthUser)?.currentProfile?.subjectId;
  const { t } = useTranslation();
  const [userAnswers, setUserAnswers] = useState<Answers>(
    generateInitAnswers(training.questions)
  );
  const [questions] = useState(training.questions);
  const [currentQustionIndex, setcurrentQustionIndex] = useState<number>(0);

  const getCurrentQuestionIsAnyAnswer = () => {
    let isAnswer = false;
    Object.keys(userAnswers[questions[currentQustionIndex].id]).forEach(
      (key) => {
        if (key === "id" || key === "question") return;
        if (userAnswers[questions[currentQustionIndex].id][key] === true) {
          isAnswer = true;
        }
      }
    );

    return isAnswer;
  };
  const currentQuestionIsAnyAnswer = getCurrentQuestionIsAnyAnswer();

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [fetchingStateSubmitTest, fetchAgainSubmitTest] = useFetchOtherThanGET({
    path:
      trainingPersonType === TrainingPersonType.Guest
        ? "guest-training-exam"
        : `worker-time/${authUserId}/trainings/training-exam`,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    successCallback: endQuizCallback,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainSubmitTest();
    }
  }, [bodyRequest, fetchAgainSubmitTest]);

  const submitTest = () => {
    setBodyRequest(
      JSON.stringify({
        trainingId: training.id,
        personId: training.personId,
        presentationStartDate: computeDateRangeFetch(presentationStartDate),
        presentationEndDate: computeDateRangeFetch(examStartDate),
        examStartDate: computeDateRangeFetch(examStartDate),
        examEndDate: computeDateRangeFetch(new Date()),
        answers: Object.values(userAnswers),
      })
    );
  };

  return (
    <div>
      <div className={styles.quizRoot}>
        <div className={styles.toolbar}>
          <div className={styles.prevNext}>
            {training.backToPreviousQuestionPossible && (
              <ButtonStyled
                disabled={
                  currentQustionIndex === 0 ||
                  !currentQuestionIsAnyAnswer ||
                  fetchingStateSubmitTest.isFetching
                }
                onClick={() => {
                  setcurrentQustionIndex(currentQustionIndex - 1);
                }}
              >
                {t("back")}
              </ButtonStyled>
            )}
          </div>
          <div className={styles.proggresBar}>
            <progress
              value={((currentQustionIndex + 1) / questions.length) * 100}
              max={"100"}
            ></progress>
            {t("question")} <b>{currentQustionIndex + 1}</b> {t("of")}{" "}
            <b>{questions.length}</b>
          </div>
        </div>

        <SingleQuestion
          question={questions[currentQustionIndex]}
          setUserAnswers={setUserAnswers}
          mulitAnswer={training.multiSelectionInQuestions}
          userAnswers={userAnswers}
        />

        <div className={styles.bottomButtonsContainer}>
          <div className={styles.prevNext}>
            <ButtonStyled
              width="180px"
              height="50px"
              disabled={
                currentQustionIndex === questions.length - 1 ||
                !currentQuestionIsAnyAnswer ||
                fetchingStateSubmitTest.isFetching
              }
              onClick={() => {
                setcurrentQustionIndex(currentQustionIndex + 1);
              }}
            >
              {t("next")}
            </ButtonStyled>
          </div>

          <div className={styles.endtrainingButton}>
            {currentQustionIndex === questions.length - 1 && (
              <div>
                <ButtonStyled
                  width="180px"
                  height="50px"
                  disabled={!currentQuestionIsAnyAnswer}
                  onClick={() => {
                    submitTest();
                  }}
                >
                  {t("finish")}
                </ButtonStyled>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
