import React from "react";
import HoursRequestsContent from "./hoursRequestsContent";

function HoursRequests() {
  return (
    <div>
      <HoursRequestsContent />
    </div>
  );
}

export default HoursRequests;
