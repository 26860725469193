// import styles from "./presence.module.scss";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { useAppSelector } from "../../../../store/hooks";
import { selectTimeWorkersSuperiorObject } from "../../../../reducers/workersLists";
import styles from "./presence.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import Card from "../../../Card/card";

function PresenceContent() {
  const { t } = useTranslation();

  const [fetchAgain, setFetchAgain] = useState(true);
  const [dataModified, setDataModified] = useState<any>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [absenceAmount, setAbsenceAmount] = useState<number>(0);
  const [presenceAmount, setPresenceAmount] = useState<number>(0);
  const [homeOfficeAmount, setHomeOfficeAmount] = useState<number>(0);

  const timeWorkersSuperiorArray = useAppSelector(
    selectTimeWorkersSuperiorObject
  );
  const { data, error } = useFetchGet(
    "superior-time/attendance-report",
    fetchAgain
  );

  const columns = [
    { name: "worker", title: t("employee") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "room", title: t("departments") },
    { name: "present", title: t("presence_2") },
    { name: "homeOffice", title: t("home_office") },
    { name: "absent", title: t("absence") },
    { name: "norm", title: t("norm") },
  ];

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setFetchAgain(false);
      return;
    }
    if (data === null) return;
    let absenceAmountLocal = 0;
    let presenceAmountLocal = 0;
    let homeOfficeAmountLocal = 0;

    data.forEach((element, index) => {
      data[index].id = element.workerId;
      //data[index].evidenceNumber = "";

      //timeWorkersSuperiorArray[element.workerId].evidenceNumber;

      if (element?.ifPresent === true) {
        presenceAmountLocal++;
      } else {
        absenceAmountLocal++;
      }

      data[index].homeOffice = "";

      if (element.ifHomeOffice) {
        data[index].homeOffice = t("yes").toUpperCase();
        homeOfficeAmountLocal++;
      }
    });

    setAbsenceAmount(absenceAmountLocal);
    setPresenceAmount(presenceAmountLocal);
    setHomeOfficeAmount(homeOfficeAmountLocal);
    setDataModified(data);
    setIsFetching(false);
    setFetchAgain(false);
  }, [data, error, timeWorkersSuperiorArray, t]);

  return (
    <>
      <TopBanner pathName={t("presence")} />
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isLodadingProgress={isFetching}
            isError={isError}
            setIfFetchAgain={setFetchAgain}
            setIsError={setIsError}
          >
            {!isFetching && !isError && (
              <div>
                <div className={styles.absentPresent}>
                  <Card
                    title={
                      <span>
                        {t("workers_present")}{" "}
                        <FontAwesomeIcon icon={faThumbsUp} />
                      </span>
                    }
                    content={
                      homeOfficeAmount === 0 ? (
                        <b>{presenceAmount}</b>
                      ) : (
                        <b>
                          {t("all")}: {presenceAmount}, {t("home_office")}:{" "}
                          {homeOfficeAmount}
                        </b>
                      )
                    }
                  />
                  <Card
                    title={
                      <span>
                        {t("workers_absent")}{" "}
                        <FontAwesomeIcon icon={faThumbsDown} />
                      </span>
                    }
                    content={<b>{absenceAmount}</b>}
                  />
                </div>
                <TableComponent
                  layoutSettingName="layoutSuperiorTimePresence"
                  rows={dataModified}
                  columns={columns}
                />
              </div>
            )}
          </LoadingWrapper>
        </div>
      </div>
    </>
  );
}

export default PresenceContent;
