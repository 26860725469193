import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useGoToPortNotification from "../../../hooks/useGoToPortNotification";

const NotificationInPort = () => {
  const routeData: any = useParams();
  const [notificationId, setNotificationId] = useState<number>(-1);

  useGoToPortNotification({
    destinationPath: "/performer-add-notification",
    checkPermirssionApiPath: `pfso/notification-guests-in-ports/${notificationId}/check-permission-to-confirm-notification`,
    notificationId: notificationId,
    profileName: "pfso",
    successRedirectPath: "/home",
    errorRedirectPath: "/home",
  });

  useEffect(() => {
    if (routeData.id) {
      setNotificationId(routeData.id);
    }
  }, [routeData.id]);

  return <div></div>;
};

export default NotificationInPort;
