import React from 'react';
import HolidayPlansContent from "./holidayPlansContent"


function HolidayPlans() {
    return (
        <div>
            <HolidayPlansContent/>
        </div>
    );
}

export default HolidayPlans;
