import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { GridColDef } from "@mui/x-data-grid";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { DaysOfWeek } from "../../../../../../../Constants/daysOfWeek";
import useFetchAndSetGET from "../../../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import {
  AutocompleteFormik,
  DatePickerFormik,
  TextFieldFormik,
} from "../../../../../../helpersComponents/FormikInputs";
import CheckboxWithLabelFormik from "../../../../../../helpersComponents/FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import LoadingWrapper from "../../../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import StyledDataGrid from "../../../../../../helpersComponents/MaterialUi/DataGrid/StyledDataGrid";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../../../../../helpersComponents/PopupHelpers";
import { IAddEditGroupPermission } from "./PopupGroupPermissionItem";
import styles from "./PopupPermissionItem.module.scss";

interface IProps {
  onSubmit: (values: IAddEditGroupPermission, selectedRooms: Room[]) => void;
  isValid: boolean;
}

const PopupGroupPermissionItemContent: React.FC<IProps> = ({
  onSubmit,
  isValid,
}) => {
  const { t } = useTranslation();

  const [roomsList, fetchingStateRoomsList, fetchAgainRoomsList] =
    useFetchAndSetGET<Room[]>({
      path: "superior-ac/rooms?isActive=true",
    });

  const roomsColumns: GridColDef[] = [
    {
      field: "name",
      headerName: t("name"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.name}</span>
      ),
    },
    {
      field: "buildingName",
      headerName: t("building"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.buildingName}</span>
      ),
    },
  ];

  // const defaultDateEntryFrom = new Date(2000, 0, 1);
  // const defaultDateEntryTo = new Date(9998, 11, 31);

  const daysOfWeek = DaysOfWeek(t);
  const [selectedRooms, setSelectedRooms] = useState<Room[]>([]);

  return (
    <DialogContentStyled>
      <LoadingWrapper
        isLodadingProgress={fetchingStateRoomsList.isFetching}
        isError={fetchingStateRoomsList.isError}
        setIfFetchAgain={() => {
          if (fetchingStateRoomsList.isError) {
            fetchAgainRoomsList();
          }
        }}
      >
        {!isValid && (
          <div className={styles.errorContainer}>
            <div>
              <FontAwesomeIcon icon={faExclamationCircle} color="red" />
            </div>
            <div>{t("permissions_overlap")}</div>
          </div>
        )}
        <Formik
          initialValues={{
            startTime: "00:00",
            endTime: "23:59",
            onlyRangeFromCheck: false,
            rangeFrom: null,
            rangeTo: null,
            daysOfWeek: [],
            canAlwaysEnter: false,
            roomsIds: [],
            added: true,
          }}
          onSubmit={(values) => {
            onSubmit(values, selectedRooms);
          }}
          validationSchema={Yup.object({
            startTime: Yup.string()
              .nullable()
              .when("canAlwaysEnter", (canAlways: boolean) => {
                if (!canAlways) {
                  return Yup.string().nullable().required(t("field_required"));
                }
                return Yup.string().nullable();
              }),
            endTime: Yup.string()
              .nullable()
              .when("canAlwaysEnter", (canAlways: boolean) => {
                if (!canAlways) {
                  return Yup.string()
                    .nullable()
                    .required(t("field_required"))
                    .test(
                      "isValidEndTime",
                      t("time_to_must_be_greater_than_the_time_from"),
                      function (value) {
                        const { canAlwaysEnter, startTime } = this.parent;

                        if (!canAlwaysEnter && value && startTime) {
                          return startTime <= value;
                        }

                        return true;
                      }
                    );
                }
                return Yup.string().nullable();
              }),
            daysOfWeek: Yup.array().test(
              "isValiddaysOfWeek",
              t("field_required"),
              function (value) {
                const { canAlwaysEnter } = this.parent;
                if (!canAlwaysEnter && value) {
                  return value?.length > 0;
                }
                return true;
              }
            ),
            rangeFrom: Yup.date()
              .nullable()
              .when("onlyRangeFromCheck", (onlyRangeFromCheck: boolean) => {
                if (onlyRangeFromCheck) {
                  return Yup.date().nullable().required(t("field_required"));
                }
                return Yup.date().nullable();
              }),
            rangeTo: Yup.date()
              .nullable()
              .when("onlyRangeFromCheck", (onlyRangeFromCheck: boolean) => {
                if (onlyRangeFromCheck) {
                  return Yup.date().nullable().required(t("field_required"));
                }
                return Yup.date().nullable();
              }),
            roomsIds: Yup.array().min(1, t("field_required")),
          })}
        >
          {(formikProps) => (
            <Form noValidate id="permissionForm">
              <div className={styles.rootContainer}>
                <div className={styles.formContainer}>
                  <Row>
                    <RowTitle width={220}>
                      {t("permission_start_time")}:
                    </RowTitle>
                    <RowInput>
                      <TextFieldFormik
                        name="startTime"
                        hiddenLabel
                        type="time"
                        disabled={formikProps.values["canAlwaysEnter"]}
                      />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle width={220}>{t("permission_end_time")}:</RowTitle>
                    <RowInput>
                      <TextFieldFormik
                        name="endTime"
                        hiddenLabel
                        type="time"
                        disabled={formikProps.values["canAlwaysEnter"]}
                      />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle width={220}>
                      <CheckboxWithLabelFormik
                        name="onlyRangeFromCheck"
                        ifCircle={true}
                        label={
                          <span style={{ fontSize: "0.9rem" }}>
                            {t("only_in_the_range_from")}
                          </span>
                        }
                      />
                    </RowTitle>
                    <RowInput>
                      {formikProps.values["onlyRangeFromCheck"] && (
                        <DatePickerFormik
                          name="rangeFrom"
                          view={["year", "month", "date"]}
                          format="dd-MM-yyyy"
                          required={true}
                        />
                      )}
                    </RowInput>
                  </Row>

                  {formikProps.values["onlyRangeFromCheck"] && (
                    <Row>
                      <RowTitle width={220}>{t("date_to")}</RowTitle>
                      <RowInput>
                        <DatePickerFormik
                          name="rangeTo"
                          view={["year", "month", "date"]}
                          format="dd-MM-yyyy"
                          required={true}
                        />
                      </RowInput>
                    </Row>
                  )}

                  <Row>
                    <RowTitle width={220}>{t("day_of_the_week")}:</RowTitle>
                    <RowInput>
                      <AutocompleteFormik
                        options={daysOfWeek}
                        name="daysOfWeek"
                        getOptionLabel={(option: {
                          value: number;
                          name: string;
                        }) => {
                          return option.name;
                        }}
                        width="100%"
                        getOptionSelected={(
                          option: IDayOfWeek,
                          value: IDayOfWeek
                        ) => {
                          return option.value === value.value;
                        }}
                        label={t("select")}
                        required={true}
                        multiple
                        disableCloseOnSelect
                        filterSelectedOptions
                        disabled={formikProps.values["canAlwaysEnter"]}
                      />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle width={400}>
                      <CheckboxWithLabelFormik
                        name="canAlwaysEnter"
                        ifCircle={true}
                        label={
                          <span style={{ fontSize: "0.9rem" }}>
                            {t("they_can_always_enter_selected_rooms")}
                          </span>
                        }
                      />
                    </RowTitle>
                  </Row>
                </div>

                {/* Rooms */}
                <div className={styles.roomsContainer}>
                  <div className={styles.roomsGridTitleContainer}>
                    <p className={styles.roomsGridTitle}>{t("rooms")}*</p>
                    {formikProps.errors[`roomsIds`] && (
                      <Tooltip title={t("field_required")!}>
                        <div>
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            color="red"
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <StyledDataGrid
                    rows={roomsList}
                    columns={roomsColumns}
                    defaultPageSize={8}
                    rowsPerPageOptions={[8]}
                    density="compact"
                    checkboxSelection={true}
                    quickFilter={true}
                    onSelectionModelChange={(selected) => {
                      formikProps.setFieldValue(
                        "roomsIds",
                        selected as number[]
                      );

                      const rooms = roomsList.filter((room) =>
                        selected.includes(room.id)
                      );
                      setSelectedRooms(rooms);
                    }}
                  />
                </div>
                {/*  */}
              </div>
            </Form>
          )}
        </Formik>
      </LoadingWrapper>
    </DialogContentStyled>
  );
};

export default PopupGroupPermissionItemContent;
