import React, { useState } from "react";
import styles from "./project.module.scss";

const DragBoxTasks = ({
  children,
  boxNumber,
  handleDrag,
  setwhatIsDragging,
}) => {
  const [dragClassName, setDragClassName] = useState<boolean>(false);

  return (
    <div
      draggable={true}
      id={boxNumber}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDragStart={(e) => {
        e.stopPropagation();
        setDragClassName(true);
        handleDrag(e);
      }}
      onDragEnd={(e) => {
        setDragClassName(false);
        setwhatIsDragging("none");
      }}
      onDragEnter={(e) => {
        e.stopPropagation();
      }}
      className={
        styles.taskDragContainer +
        " " +
        (dragClassName ? styles.dragClassName : "")
      }
    >
      {children}
    </div>
  );
};

export default DragBoxTasks;
