import { useEffect } from "react";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";

export default function useFetchingGlobalCalendar({
  selectedDate,
  setFetchedGlobalCalendarData,
}): [
  {
    isFetching: boolean;
    isError: boolean;
    response: UseFetchResponse | undefined;
  },
  () => void
] {
  const [, fetchingGlobalCalendarState, fetchGlobalCalendarAgain] =
    useFetchAndSetGET<GlobalCalendar[]>({
      path:
        `global-calendar?dateFrom=` +
        computeDateRangeFetch(
          getBeginningOfDate(selectedDate, "Month", { extraDays: -7 })
        ) +
        "&dateTo=" +
        computeDateRangeFetch(
          getEndOfDate(selectedDate, "Month", { extraDays: 7 })
        ),
      startFetchOnInitial: false,
    });

  useEffect(() => {
    if (
      fetchingGlobalCalendarState.isError ||
      fetchingGlobalCalendarState.response === undefined
    )
      return;
    if (!Array.isArray(fetchingGlobalCalendarState.response.resJson)) return;
    let data: GlobalCalendar[] = fetchingGlobalCalendarState.response.resJson;
    setFetchedGlobalCalendarData(data);
  }, [
    fetchingGlobalCalendarState.isError,
    fetchingGlobalCalendarState.response,
    setFetchedGlobalCalendarData,
  ]);

  return [fetchingGlobalCalendarState, fetchGlobalCalendarAgain];
}
