import { useTranslation } from "react-i18next";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styles from "./project.module.scss";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import Button from "../../../helpersComponents/Button/button";
import AddStage from "./addStage";
import DragAndDropStagesContainer from "./dragAndDropStagesContainer";
import AddEditTask from "../SingleTaskComponents/addEditTask";
// import TasksActions from "./tasksActions";
import ProjectDetails from "./projectDetails";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { TextFieldStyled } from "../../../helpersComponents/MaterialUi";
import { InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";
// import SearchIcon from "@material-ui/icons-material/Search";

const ProjectContent = ({ projectId }: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [filterTasks, setFilterTasks] = useState("");

  const [isFetching, setIsFetching] = useState(true);
  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const projectResponse = useFetchGet(
    `worker-time/${authUserId}/projects/${projectId}`,
    ifFetchAgain
  );
  const projectResponseStages = useFetchGet(
    `worker-time/${authUserId}/projects/${projectId}/stages`,
    ifFetchAgain
  );
  const [project, setProject] = useState<any>();
  const [projectStages, setProjectStages] = useState<any>([]);
  const [isAddEditTaskDialogOpen, setIsAddEditTaskDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      projectResponse.error !== null ||
      projectResponseStages.error !== null
    ) {
      setIsError(true);
      setIfFetchAgain(false);
      return;
    }
    if (projectResponse.data === null || projectResponseStages.data === null)
      return;
    let sortedStages = projectResponseStages.data.sort((a, b) => {
      if (a.order < b.order) return -1;
      return 0;
    });
    setProject(projectResponse.data);
    setProjectStages([
      {
        id: 0,
        projectId: projectResponse.data.id,
        name: t("no_stage"),
        order: 0,
      },
      ...sortedStages,
    ]);
    setIsFetching(false);
    setIfFetchAgain(false);
  }, [projectResponse, projectResponseStages, t]);

  const [kanbanView, setKanbanView] = useState(localStorage.getItem("kanban"));
  const handleChangeTypeOfView = () => {
    if (kanbanView === null || kanbanView === "false") {
      localStorage.setItem("kanban", "true");
      setKanbanView("true");
    } else {
      localStorage.setItem("kanban", "false");
      setKanbanView("false");
    }
  };

  return (
    <div>
      <LoadingWrapper
        isLodadingProgress={isFetching}
        isError={isError}
        setIfFetchAgain={setIfFetchAgain}
        setIsError={setIsError}
      >
        {!isFetching && !isError && (
          <>
            <TopBanner
              pathName={
                project.name + (project.isDefault ? ` (${t("default")})` : "")
              }
            />
            <div className={styles.backToProjectsListButton}>
              <Button
                onClick={() => {
                  history.push(`/worker-time/projects/home`);
                }}
              >
                {t("back_to_projects")}
              </Button>
            </div>
            <div>
              <ProjectDetails
                project={project}
                setIfFetchAgain={setIfFetchAgain}
              />
              <Button
                className={styles.buttonChangeView}
                onClick={handleChangeTypeOfView}
              >
                <FontAwesomeIcon icon={faEye} />
                <span>
                  {kanbanView === "true" ? t("default_view") : t("kanban_view")}
                </span>
              </Button>
            </div>
            <div className={styles.stagesAndTasksTitle}>
              <b>{t("stages_and_tasks")}</b>
            </div>
            <div className={styles.contentTopPannel}>
              <div>
                <AddStage
                  projectId={projectId}
                  setIfFetchAgain={setIfFetchAgain}
                />
              </div>
              <div>
                <Button onClick={() => setIsAddEditTaskDialogOpen(true)}>
                  {t("add_task")}
                </Button>
                <AddEditTask
                  projectId={projectId}
                  setIfFetchAgain={setIfFetchAgain}
                  setIsDialogOpen={setIsAddEditTaskDialogOpen}
                  isDialogOpen={isAddEditTaskDialogOpen}
                />
              </div>
              <div>
                <TextFieldStyled
                  type="text"
                  label={t("search")}
                  onChange={(e) => setFilterTasks(e.target.value.toLowerCase())}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div
              className={
                kanbanView === "true"
                  ? styles.kanbanContainer
                  : styles.dragAndDropContainer
              }
            >
              <DragAndDropStagesContainer
                projectStages={projectStages}
                setProjectStages={setProjectStages}
                projectId={projectId}
                setIfFetchAgain={setIfFetchAgain}
                project={project}
                kanbanView={kanbanView}
                filterTasks={filterTasks}
              />
            </div>
          </>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default ProjectContent;
