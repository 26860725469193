import React, { ReactElement, useState } from "react";
import styles from "./singleTask.module.scss";
import { Accordion, AccordionSummary, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { useTranslation } from "react-i18next";
import AddEditTask from "./addEditTask";
import SingleTaskChangeStatus from "./singleTaskChangeStatus";
import CollaboratorsList from "../CollaboratorsComponents/collaboratorsList";
import TaskCollaboratorsContent from "../CollaboratorsComponents/taskCollaboratorsContent";
import SingleTaskDetails from "./singleTaskDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faEdit, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import RemoveComponent from "../removeComponent";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";

const useStyles = makeStyles({
  accordionSummaryRoot: {
    "&.MuiAccordionSummary-root": {
      padding: 0,
    },
  },
  muiAccordionRoot: {
    "&.MuiAccordion-root": {
      boxShadow: "none",
      border: "1px solid lightblue;",
    },
  },
  accordionSummaryTask: {
    padding: "0px 14px 0px 0px",
  },
});

interface Props {
  task: any;
  project: any;
  setIfFetchAgain: React.Dispatch<boolean>;
  ifShowProjectDetails?: boolean;
  photos: any;
  kanbanView?: null | string;
}
export default function SingleTask({
  task,
  project,
  setIfFetchAgain,
  ifShowProjectDetails,
  photos,
  kanbanView,
}: Props): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expandedTasks, setExpandedTasks] = useState<{
    [key in number]: boolean;
  }>({});
  const [isAddEditTaskDialogOpen, setIsAddEditTaskDialogOpen] =
    useState<boolean>(false);

  const [
    isEditTaskCollaboratorsDialogOpen,
    setIsEditCollaboratorsTaskDialogOpen,
  ] = useState<boolean>(false);

  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const editString: any = t("edit");
  const shareString: any = t("assign_workers");
  const deleteString: any = t("delete");

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expandedTasks[task.id] === true}
      onChange={() =>
        setExpandedTasks((prev) => ({
          ...prev,
          [task.id]: !expandedTasks[task.id],
        }))
      }
      className={classes.muiAccordionRoot}
    >
      <AccordionSummary
        className={classes.accordionSummaryTask}
        expandIcon={<ExpandMoreIcon />}
      >
        <div
          className={
            styles.singleTaskTitleContainer +
            " " +
            (expandedTasks[task.id] === true ? styles.bottomLine : "")
          }
        >
          <div
            className={
              styles.taskStatusTitle +
              " " +
              (kanbanView === "true" ? styles.kanbanViewTitle : "")
            }
          >
            <div>
              <SingleTaskChangeStatus
                task={task}
                setIfFetchAgain={setIfFetchAgain}
                projectId={project.id}
              />
            </div>
            {ifShowProjectDetails ? (
              <div>
                <b>{task.name}</b>
                {` - ${project.name}`}
              </div>
            ) : (
              <div>{task.name}</div>
            )}
          </div>
          <div
            className={
              styles.collaboratorRootContainer +
              " " +
              (kanbanView === "true" ? styles.kanbanViewCollaborators : "")
            }
          >
            <CollaboratorsList
              photos={photos}
              collaborators={task.collaborators}
            />
          </div>
          <div
            onClick={(e) => e.stopPropagation()}
            draggable="true"
            className={styles.actionIcons}
          >
            <Tooltip title={editString}>
              <div
                onClick={() => {
                  setIsAddEditTaskDialogOpen(true);
                }}
                className={styles.faEdit}
              >
                <div>
                  <FontAwesomeIcon icon={faEdit} />
                </div>
              </div>
            </Tooltip>
            <Tooltip title={shareString}>
              <div
                onClick={() => {
                  setIsEditCollaboratorsTaskDialogOpen(true);
                }}
                className={styles.faUserFriends}
              >
                <div>
                  <FontAwesomeIcon icon={faUserPlus} />
                </div>
              </div>
            </Tooltip>
            <div>
              <RemoveComponent
                setIfFetchAgain={setIfFetchAgain}
                path={`worker-time/${authUserId}/projects/${project.id}/tasks/${task.id}`}
                confirmDialogText={t("are_you_sure_you_want_to_delete_tasks", {
                  taskName: task.name,
                })}
                snackbarSuccessText={t("task_deleted")}
                ifStopPropagation={true}
                OwnButton={
                  <Tooltip title={deleteString}>
                    <div className={styles.faTimesCircle}>
                      <div>
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    </div>
                  </Tooltip>
                }
              />
            </div>
            <span
              onDragStart={(e) => {
                e.stopPropagation();
              }}
            >
              <AddEditTask
                projectId={project.id}
                setIfFetchAgain={setIfFetchAgain}
                taskId={task.id}
                setIsDialogOpen={setIsAddEditTaskDialogOpen}
                isDialogOpen={isAddEditTaskDialogOpen}
              />
              <TaskCollaboratorsContent
                setIfFetchAgain={setIfFetchAgain}
                taskId={task.id}
                setIsDialogOpen={setIsEditCollaboratorsTaskDialogOpen}
                isDialogOpen={isEditTaskCollaboratorsDialogOpen}
                project={project}
              />
            </span>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        draggable="true"
        onDragStart={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        className={styles.allowSelect}
      >
        <div className={styles.singleTask}>
          <SingleTaskDetails
            photos={photos}
            task={task}
            projectId={project.id}
            kanbanView={kanbanView}
            setIfFetchAgain={setIfFetchAgain}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
