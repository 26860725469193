import React from "react";
import TrainingsRoot from "./trainingsRoot";

function Trainings() {
  return (
    <div>
      <TrainingsRoot />
    </div>
  );
}

export default Trainings;
