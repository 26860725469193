import "./holiday.scss";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../../helpersComponents/PopupHelpers";
import LoadingWrapper from "../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { makeStyles } from "@material-ui/core/styles";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useCachedFetch from "../../../../../hooks/useCachedFetch/cachedFetch";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { ProfileType } from "../../../../../enums/profileType";
import { useAppSelector } from "../../../../../store/hooks";
import { selectAuthUser } from "../../../../../reducers/session";
import DialogContent from "@material-ui/core/DialogContent";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Row,
  RowInput,
  RowTitle,
} from "../../../../helpersComponents/PopupHelpers";
import AutocompleteFormik from "../../../FormikInputs/Autocomplete/autocomplete";
import { getHolidayLimitIdParts } from "../../../../../HelpersFunctions/holidaysLimits/holidaysLimitsHelpers";
import DatePickerFormik from "../../../FormikInputs/DatePicker/datePicker";
import { getCurrentDateWithOwnYear } from "../../../../../HelpersFunctions/dateAndTime/getCurrentDateWithOwnYear";
import TextFieldFormik from "../../../FormikInputs/TextField/textField";
import CheckboxWithLabelFormik from "../../../FormikInputs/CheckboxWithLabel/CheckboxWithLabel";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: "600px",
    width: "440px",
  },
}));

interface Props {
  isOpen: boolean;
  rowId: string | undefined;
  closePopup: () => void;
  successCallback: () => void;
}

const AddEditTimeWorkerLimitPopup: React.FC<Props> = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
}: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState("");
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;

  const [addEditHolidayLimitBodyRequest, setAddEditHolidayLimitBodyRequest] =
    useState<any>(false);

  const [ifClearReturnedValue, setIfClearReturnedValue] =
    useState<boolean>(false);
  const [ifCheckForUpdateCache, setIfCheckForUpdateCache] =
    useState<boolean>(false);

  const timeWorkers = useCachedFetch(
    "timeWorkersWorkerTime",
    ifCheckForUpdateCache,
    3600,
    false,
    ifClearReturnedValue
  );

  const [absencesTypes] = useFetchAndSetGET({
    path:
      profileType === ProfileType.SUPERIOR_TIME
        ? `superior-time/holiday-requests-types`
        : `worker-time/${authUserId}/holiday-requests-types`,
    startFetchOnInitial: true,
  });

  const successCallbackAndClose = useCallback(
    (response) => {
      successCallback();
      setAddEditHolidayLimitBodyRequest(false);
      closePopup();
    },
    [closePopup, successCallback]
  );

  const [addEditHolidayLimitFetchingState, addEditHolidayLimitFetchAgain] =
    useFetchOtherThanGET({
      path: `superior-time/holiday-limits`,
      method: "PUT",
      body: addEditHolidayLimitBodyRequest,
      setBody: setAddEditHolidayLimitBodyRequest,
      contentType: "application/json",
      successCallback: successCallbackAndClose,
    });

  useEffect(() => {
    if (addEditHolidayLimitBodyRequest) {
      addEditHolidayLimitFetchAgain();
    }
  }, [addEditHolidayLimitBodyRequest, addEditHolidayLimitFetchAgain]);

  const cancel = () => {
    setErrorMessage("");
    closePopup();
  };

  const [fetchedRow, fetchingStateFetchedRow, fetchAgainFetchedRow] =
    useFetchAndSetGET({
      path: `superior-time/holiday-limits-info?timeWorkerId=${
        getHolidayLimitIdParts(rowId)[0]
      }&absenceTypeId=${getHolidayLimitIdParts(rowId)[1]}&year=${
        getHolidayLimitIdParts(rowId)[2]
      }`,
      startFetchOnInitial: false, //rowId ? true : false,
    });

  useEffect(() => {
    if (rowId) {
      fetchAgainFetchedRow();
    }
  }, [rowId, fetchAgainFetchedRow]);

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={fetchingStateFetchedRow.isFetching}
        isError={fetchingStateFetchedRow.isError}
        setIfFetchAgain={() => {
          if (fetchingStateFetchedRow.isError) {
            fetchAgainFetchedRow();
          }
        }}
      >
        <DialogStyled
          open={isOpen}
          onClose={closePopup}
          classes={{ paper: classes.dialogPaper }}
          TransitionProps={{
            onExited: () => {},
            onEnter: () => {
              setIfClearReturnedValue(false);
              setIfCheckForUpdateCache(true);
            },
          }}
        >
          <DialogTitleStyled
            title={
              rowId === undefined
                ? t("adding_holiday_limit")
                : t("editing_holiday_limit")
            }
          />

          {timeWorkers &&
            absencesTypes &&
            (fetchedRow || rowId === undefined) && (
              <DialogContent>
                <Formik
                  initialValues={{
                    employee: fetchedRow
                      ? timeWorkers.find(
                          (el) => el.id === fetchedRow.timeWorkerId
                        )
                      : null,
                    absenceType: fetchedRow
                      ? absencesTypes.find(
                          (el) => el.id === fetchedRow.absenceTypeId
                        )
                      : null,
                    year: fetchedRow
                      ? getCurrentDateWithOwnYear(fetchedRow.year)
                      : new Date(),
                    holidayLimit: fetchedRow ? fetchedRow.duration : 0,
                    holidayOverdue: fetchedRow ? fetchedRow.overdue : 0,
                    isDay: fetchedRow ? fetchedRow.isDay : true,
                  }}
                  onSubmit={(values) => {
                    let holidayLimitToAdd = {
                      timeWorkersIds: [values.employee.id],
                      absenceTypeId: values.absenceType.id,
                      year: new Date(values.year).getFullYear(),
                      duration: values.holidayLimit,
                      overdue: values.holidayOverdue,
                      isDay: values.isDay,
                    };

                    setAddEditHolidayLimitBodyRequest(
                      JSON.stringify(holidayLimitToAdd)
                    );
                  }}
                  validationSchema={Yup.object({
                    employee: Yup.object()
                      .nullable()
                      .required(t("field_required")),
                    absenceType: Yup.object()
                      .nullable()
                      .required(t("field_required")),
                    year: Yup.date().typeError(t("invalid_date")),
                    holidayLimit: Yup.number()
                      .nullable()
                      .required(t("field_required")),
                    holidayOverdue: Yup.number().required(t("field_required")),
                    isDay: Yup.bool(),
                  })}
                >
                  {(formikProps) => (
                    <Form noValidate id="submitForm">
                      <Row>
                        <RowTitle>{t("employee")}:</RowTitle>
                        <RowInput>
                          <AutocompleteFormik
                            options={timeWorkers}
                            getOptionLabel={(option: any) =>
                              option.firstName + " " + option.lastName
                            }
                            width="100%"
                            getOptionSelected={(option: any, value: any) => {
                              return option.id === value.id;
                            }}
                            label={t("employee")}
                            name="employee"
                            required={true}
                          />
                        </RowInput>
                      </Row>

                      <Row>
                        <RowTitle>{t("absence_type")}:</RowTitle>
                        <RowInput>
                          <AutocompleteFormik
                            options={absencesTypes}
                            getOptionLabel={(option: any) => {
                              return (
                                "[" + option.nameAbbrev + "] " + option.name
                              );
                            }}
                            width="100%"
                            getOptionSelected={(option: any, value: any) => {
                              return option.id === value.id;
                            }}
                            label={t("absence_type")}
                            name="absenceType"
                            required={true}
                          />
                        </RowInput>
                      </Row>

                      <Row>
                        <RowTitle>{t("year")}:</RowTitle>
                        <RowInput>
                          <DatePickerFormik
                            label={t("year")}
                            name="year"
                            view={["year"]}
                            format="yyyy"
                          />
                        </RowInput>
                      </Row>

                      <Row>
                        <RowTitle>{t("holidays_limits_limit")}:</RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            ifToUpperCaseShipsAndPorts={true}
                            label={t("holidays_limits_limit")}
                            name="holidayLimit"
                            type="number"
                            required={true}
                          />
                        </RowInput>
                      </Row>

                      <Row>
                        <RowTitle>{t("holidays_limits_overdue")}:</RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            ifToUpperCaseShipsAndPorts={true}
                            label={t("holidays_limits_overdue")}
                            name="holidayOverdue"
                            type="number"
                            required={true}
                          />
                        </RowInput>
                      </Row>

                      <Row>
                        <RowTitle width={150}>
                          {t("holidays_limits_is_day")}:
                        </RowTitle>
                        <RowInput width={350}>
                          <CheckboxWithLabelFormik
                            name="isDay"
                            defaultValue={false}
                          />
                        </RowInput>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </DialogContent>
            )}

          <DialogActionsStyled
            typConfirm="submit"
            formConfirm="submitForm"
            onConfirmText={rowId === undefined ? t("add") : t("edit")}
            onCancel={cancel}
            isLoading={addEditHolidayLimitFetchingState.isFetching}
            errorString={errorMessage}
          />
        </DialogStyled>
      </LoadingWrapper>
    </>
  );
};

export default AddEditTimeWorkerLimitPopup;
