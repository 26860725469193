import React, { ReactElement, useContext } from "react";
import { contextTitleValueWidth } from "./rowDetailsContainer";
import styles from "./styles.module.scss";

interface Props {
  children: React.ReactNode | React.ReactNode[];
  width?: number;
}

export function RowDetailsTitle({ children, width }: Props): ReactElement {
  const titleValueWidth = useContext(contextTitleValueWidth);
  let widthFinal: string | number = 300;
  if (width) {
    widthFinal = width;
  } else {
    widthFinal = titleValueWidth?.titleWidth
      ? titleValueWidth?.titleWidth
      : 150;
  }

  return (
    <div className={styles.title} style={{ width: widthFinal }}>
      <b>{children}</b>
    </div>
  );
}
