import { TFunction } from "react-i18next";
import * as Yup from "yup";

type Props = { t: TFunction<"translation"> };

export default function yupIsValidDate({ t }: Props) {
  return Yup.string()
    .nullable()
    .test("isaValidDate", t("invalid_date"), function (value) {
      if (value === null || value === "Invalid Date") {
        ////// returning anythnig that will return error, returning false will "false", cause yup is asynchronous (line 18)
        return Yup.object()
          .shape({
            date: Yup.date().typeError(t("invalid_date")),
          })
          .isValid({ date: "value" });
      }
      return Yup.object()
        .shape({
          date: Yup.date().typeError(t("invalid_date")),
        })
        .isValid({ date: value });
    });
}
