import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import PopupAddNewShip from "./PopupNewShip";

interface IProps {
  pathApiShips: string;
  layoutSettingName: string;
}

const ShipsContent: React.FC<IProps> = ({
  pathApiShips,
  layoutSettingName,
}) => {
  const { t } = useTranslation();

  const modifyShipsResponseCallback = useCallback((responseData: IShip[]) => {
    const modified = responseData.map((row) => {
      return {
        ...row,
        description: row.description ? row.description : "-",
      };
    });
    return modified;
  }, []);

  const columns = [
    { name: "name", title: t("name") },
    { name: "description", title: t("description") },
    { name: "createdAt", title: t("data_created"), type: "date" },
  ];

  const [shipsRows, fetchingStateShip, fetchAgainShips] = useFetchAndSetGET<
    IShip[]
  >({
    path: pathApiShips,
    modifyResponseCallback: modifyShipsResponseCallback,
  });

  const [shipIdToRemove, setShipIdToRemove] = useState<false | number>(false);

  const [fetchingState, fetchAgain] = useFetchOtherThanGET({
    path: `${pathApiShips}/${shipIdToRemove}`,
    method: "DELETE",
    body: setShipIdToRemove,
    setBody: setShipIdToRemove,
    successCallback: fetchAgainShips,
  });

  useEffect(() => {
    if (shipIdToRemove !== false) {
      fetchAgain();
    }
  }, [shipIdToRemove, fetchAgain]);

  return (
    <div>
      <div>
        <TopBanner pathName={t("ships")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <TableComponentMemo
            columns={columns}
            rows={shipsRows}
            layoutSettingName={layoutSettingName}
            actionsColumnUpdated={{
              popup: (props) => (
                <PopupAddNewShip pathApiShips={pathApiShips} {...props} />
              ),
              successCallbackPopup: fetchAgainShips,
              addButton: {
                ifShow: true,
              },
              editButton: {
                ifShow: true,
                name: t("edit"),
              },
              removeButton: {
                ifShow: true,
                onClick: (row) => {
                  setShipIdToRemove(row.id);
                },
              },
            }}
            fetchingState={{
              fetchAgain: fetchAgainShips,
              isError: fetchingStateShip.isError,
              isFetching:
                fetchingStateShip.isFetching || fetchingState.isFetching,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ShipsContent;
