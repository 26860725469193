import { useTranslation } from "react-i18next";
import { HTMLControlType } from "../../../enums/HTMLControlType";
import ElasticCheckboxButtonsGroup from "./Controls/ElasticForm/ElasticFormCheckBoxButtonsGroup";
import ElasticFormParagraph from "./Controls/ElasticForm/ElasticFormParagraph";
import ElasticRadioButtonsGroup from "./Controls/ElasticForm/ElasticFormRadioButtonsGroup";
import ElasticFormSelectionList from "./Controls/ElasticForm/ElasticFormSelectionList";
import ElasticFormTextBox from "./Controls/ElasticForm/ElasticFormTextBox";
import { useCallback, useEffect, useState } from "react";
import { EditorHTMLMode } from "../../../enums/editorHTMLMode";

const ElasticForm: React.FC<EditorHTML> = ({
  controls,
  mode = EditorHTMLMode.NORMAL,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  timeWorkerId,
}) => {
  const { i18n } = useTranslation();
  const [controlsToRender, setControlsToRender] = useState<Array<HTMLControl>>(
    []
  );

  const getHTMLControlForCurrentLanguage = useCallback(
    (controls: Array<HTMLControl>) => {
      let languageNumber = 0;

      switch (i18n.language) {
        case "pl":
          languageNumber = 0;
          break;

        case "en":
          languageNumber = 1;
          break;

        case "de":
          languageNumber = 2;
          break;

        case "ru":
          languageNumber = 3;
          break;

        case "uk":
          languageNumber = 4;
          break;
      }

      let foundObj = controls.find((el) => el.language === languageNumber);
      if (!foundObj && controls.length > 0) {
        foundObj = controls[0];
      }

      return foundObj;
    },
    [i18n.language]
  );

  useEffect(() => {
    let groupedControls: { [key: string]: Array<HTMLControl> } =
      controls.reduce((groups, obj: HTMLControl) => {
        switch (obj.type) {
          case HTMLControlType.PARAGRAPH:
            {
              let tempParagraph = obj as ParagraphHTML;
              let key = `paragraph_${tempParagraph.name?.toLowerCase()}`;

              if (!groups[key]) {
                groups[key] = [];
              }

              groups[key].push(tempParagraph);
            }
            break;

          case HTMLControlType.RADIO_BUTTONS_GROUP:
            {
              let tempRadioButtonsGroup = obj as RadioButtonsGroupHTML;
              let key = `radioButtonsGroup_${tempRadioButtonsGroup.name?.toLowerCase()}`;

              if (!groups[key]) {
                groups[key] = [];
              }

              groups[key].push(tempRadioButtonsGroup);
            }
            break;

          case HTMLControlType.CHECKBOX_BUTTONS_GROUP:
            {
              let tempCheckboxButtonsGroup = obj as CheckboxButtonsGroupHTML;
              let key = `checkboxButtonsGroup_${tempCheckboxButtonsGroup.name?.toLowerCase()}`;

              if (!groups[key]) {
                groups[key] = [];
              }

              groups[key].push(tempCheckboxButtonsGroup);
            }
            break;

          case HTMLControlType.TEXTBOX:
            {
              let tempTextBox = obj as TextBoxHTML;
              let key = `textbox_${tempTextBox.name?.toLowerCase()}`;

              if (!groups[key]) {
                groups[key] = [];
              }

              groups[key].push(tempTextBox);
            }
            break;

          case HTMLControlType.SELECT:
            {
              let tempSelectionList = obj as SelectionListHTML;
              let key = `selectionList_${tempSelectionList.name?.toLowerCase()}`;

              if (!groups[key]) {
                groups[key] = [];
              }

              groups[key].push(tempSelectionList);
            }
            break;
        }

        return groups;
      }, {});

    if (groupedControls) {
      let tempResult: Array<HTMLControl> = [];

      for (const key in groupedControls) {
        let ctr = getHTMLControlForCurrentLanguage(groupedControls[key]);
        if (ctr) {
          tempResult.push(ctr);
        }
      }

      setControlsToRender(tempResult);
    }
  }, [controls, getHTMLControlForCurrentLanguage]);

  return (
    <div id="elasticForm">
      {controlsToRender?.map((el: HTMLControl) => {
        switch (el.type) {
          case HTMLControlType.PARAGRAPH:
            return <ElasticFormParagraph {...(el as ParagraphHTML)} />;

          case HTMLControlType.RADIO_BUTTONS_GROUP:
            return (
              <ElasticRadioButtonsGroup
                {...(el as RadioButtonsGroupHTML)}
                readonly={mode === EditorHTMLMode.PREVIEW}
                formValues={formValues}
                setFormValues={setFormValues}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
            );

          case HTMLControlType.CHECKBOX_BUTTONS_GROUP:
            return (
              <ElasticCheckboxButtonsGroup
                {...(el as CheckboxButtonsGroupHTML)}
                readonly={mode === EditorHTMLMode.PREVIEW}
                formValues={formValues}
                setFormValues={setFormValues}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
            );

          case HTMLControlType.TEXTBOX:
            return (
              <ElasticFormTextBox
                {...(el as TextBoxHTML)}
                readonly={mode === EditorHTMLMode.PREVIEW}
                formValues={formValues}
                setFormValues={setFormValues}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
            );

          case HTMLControlType.SELECT:
            return (
              <ElasticFormSelectionList
                {...(el as SelectionListHTML)}
                readonly={mode === EditorHTMLMode.PREVIEW}
                formValues={formValues}
                setFormValues={setFormValues}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
                timeWorkerId={timeWorkerId}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default ElasticForm;
