import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StatusInPortsType } from "../../../Constants/statusInPortsType";
import { ProfileType } from "../../../enums/profileType";
import classnameForNotificationStatus from "../../../HelpersFunctions/classnameForNotificationStatus";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useRefreshData from "../../../hooks/useRefreshData/useRefreshData";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import OperationsButtons from "../../pfso/OperationButtons/OperationsButons";
import { profilesRoutesDictionary } from "../../ProfilesRouting/profilesDictionaries";
import DatePickerRange from "../DatePickerRange/datePickerRange";
import ParallelActions from "../ParallelActions/parallelActions";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import RowDetailsCrewEntriesDescents from "./RowDetailsCrewEntriesDescents";
import PopupCrewEntryDescent from "../../agent/CrewEntryDescent/PopupCrewEntryDescent";
import SnackbarStyled from "../MaterialUi/Snackbar/snackbar";

interface Props {
  eventType: 0 | 1 | 2;
  topBannerTitle: string;
  pathChangeStatus: string;
  newStatusIdConfirm: number;
  profileType: ProfileType;
  layoutSettingName: string;
  refreshingButton?: boolean;
  id?: number;
  pathApiCrewEntriesDescentsCheckPermissions: string;
  confirmationOnly: boolean;
}
const CrewEntriesDescentsContent = ({
  eventType,
  topBannerTitle,
  pathChangeStatus,
  newStatusIdConfirm,
  profileType,
  layoutSettingName,
  refreshingButton,
  id,
  pathApiCrewEntriesDescentsCheckPermissions,
  confirmationOnly,
}: Props) => {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);

  const [isOpenEditPopup, setIsOpenEditPopup] = useState<{
    isOpen: boolean;
    rowId: number | undefined;
  }>({
    isOpen: false,
    rowId: undefined,
  });

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Day")
  );
  const [endDate, setEndDate] = useState(
    getEndOfDate(new Date(), "Day", { extraDays: 7 })
  );

  const columns = [
    { name: "notificationNumber", title: t("number") },
    { name: "date", title: t("date"), type: "date" },
    { name: "numberOfPeople", title: t("number_of_people") },
    {
      name: "confirmationStatusName",
      title: t("confirmation_status"),
      customFormatter: (row: ICrewEntryDescentGuest) => (
        <p
          className={`status ${classnameForNotificationStatus(
            row.crewEntryDescent.confirmationStatus
          )}`}
        >
          {row.crewEntryDescent.confirmationStatusName}
        </p>
      ),
    },
    { name: "guestsFilter", title: "guestsFilter", hide: true },
  ];

  const modifyResponseCallback = useCallback(
    (responseData: ICrewEntryDescentGuest[]) => {
      const modified = responseData.map((row, index) => {
        return {
          ...row,
          id: ++index,
          notificationNumber: row.crewEntryDescent.notificationNumber,
          date: row.crewEntryDescent.date,
          numberOfPeople: row.guests.length,
          confirmationStatusName: row.crewEntryDescent.confirmationStatusName,
          guestsFilter: row.guests
            .map((item) => ({
              firstName: item.guest.firstName,
              lastName: item.guest.lastName,
              toString: function () {
                return this.firstName + " " + this.lastName;
              },
            }))
            .join(";"),
        };
      });
      return modified;
    },
    []
  );

  const modifyCheckPermissionResponseCallback = useCallback(
    (responseData) => {
      setIsOpenEditPopup({ isOpen: true, rowId: id });
      return responseData;
    },
    [id]
  );

  const errorCheckPermissionCallback = useCallback(
    (responseData: UseFetchResponse | undefined) => {
      if (responseData?.isError) {
        if (responseData.status === 404) {
          setSnackbarState([true, "error", t("notification_not_found")]);
        } else if (responseData.status === 403) {
          setSnackbarState([true, "error", t("no_permission")]);
        }
      }
    },
    [t]
  );

  const profileName = profilesRoutesDictionary[profileType];

  const [rows, fetchingState, fetchAgain] = useFetchAndSetGET<
    ICrewEntryDescentGuest[]
  >({
    path: `${profileName}/crew-entries-descents?DateFrom=${convertDateFetch(
      startDate
    )}&DateTo=${convertDateFetch(endDate)}&EventType=${eventType}`,
    modifyResponseCallback: modifyResponseCallback,
  });

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    fetchAgain();
  }, [startDate, endDate, fetchAgain]);

  //parallel
  const [parallelIsFetching, setParallelIsFetching] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const { refreshingTime, handleChangeRefreshingTime } =
    useRefreshData(fetchAgain);

  const subjectId = useAppSelector(selectAuthUser).currentProfile.subjectId;

  const ifHideConfirmationButton = (row: ICrewEntryDescentGuest) => {
    const crewEntryDescent = row.crewEntryDescent;
    return (
      crewEntryDescent.confirmationStatus === StatusInPortsType.APPROVED ||
      (crewEntryDescent.pfsoConfirmationId !== null &&
        crewEntryDescent.pfsoConfirmationId !== subjectId) ||
      (crewEntryDescent.shiftDirectorConfirmationId != null &&
        crewEntryDescent.shiftDirectorConfirmationId !== subjectId)
    );
  };

  const ifHideRejectButton = (row: ICrewEntryDescentGuest) => {
    const crewEntryDescent = row.crewEntryDescent;
    return (
      crewEntryDescent.confirmationStatus === StatusInPortsType.REJECTED ||
      (crewEntryDescent.pfsoConfirmationId !== null &&
        crewEntryDescent.pfsoConfirmationId !== subjectId) ||
      (crewEntryDescent.shiftDirectorConfirmationId != null &&
        crewEntryDescent.shiftDirectorConfirmationId !== subjectId)
    );
  };

  const closeEditPopup = useCallback(() => {
    setIsOpenEditPopup({
      isOpen: false,
      rowId: undefined,
    });
  }, []);

  const [, , fetchAgainCrewEntriesDescentsPermission] = useFetchAndSetGET({
    path: pathApiCrewEntriesDescentsCheckPermissions,
    startFetchOnInitial: false,
    modifyResponseCallback: modifyCheckPermissionResponseCallback,
    errorCallback: errorCheckPermissionCallback,
  });

  useEffect(() => {
    if (pathApiCrewEntriesDescentsCheckPermissions && id !== undefined) {
      fetchAgainCrewEntriesDescentsPermission();
    }
  }, [
    pathApiCrewEntriesDescentsCheckPermissions,
    id,
    fetchAgainCrewEntriesDescentsPermission,
  ]);

  return (
    <>
      <div>
        <TopBanner pathName={topBannerTitle} />
      </div>
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            view={["year", "month", "date"]}
            darkTheme={true}
          ></DatePickerRange>
        </div>

        <div>
          <TableComponentMemo
            columns={columns}
            rows={rows}
            layoutSettingName={layoutSettingName}
            rowDetail={RowDetailsCrewEntriesDescents}
            fetchingState={{
              isFetching: fetchingState.isFetching || parallelIsFetching,
              isError: fetchingState.isError,
              fetchAgain: fetchAgain,
            }}
            selectData={setSelectedRows}
            selectedData={selectedRows}
            hideSelectDataCondition={(row) => ifHideConfirmationButton(row)}
            actionsColumnUpdated={{
              popup: (props) => {
                return (
                  <PopupCrewEntryDescent
                    rowEditId={isOpenEditPopup.rowId}
                    isOpenEditPopup={isOpenEditPopup.isOpen}
                    closeEditPopup={closeEditPopup}
                    eventType={eventType}
                    successCallback={fetchAgain}
                    profileName={profileName}
                    confirmationOnly={confirmationOnly}
                    pathApiChangeConfirmationStatus={pathChangeStatus}
                    {...props}
                  />
                );
              },

              editButton: {
                ifShow: true,
                customComponent: (row: ICrewEntryDescentGuest) => {
                  return (
                    <OperationsButtons
                      patchApi={
                        pathChangeStatus + "/" + row.crewEntryDescent.id
                      }
                      successCallback={fetchAgain}
                      newStatusIdConfirm={newStatusIdConfirm}
                      ifHideConfirm={ifHideConfirmationButton(row)}
                      ifHideReject={ifHideRejectButton(row)}
                      prefixPathName="/crewEntryDescent"
                      isSecurity={false}
                      profileType={profileType}
                    />
                  );
                },
              },
            }}
            refreshingButton={{
              ifShow: refreshingButton ? true : false,
              refreshingTime: refreshingTime?.value,
              handleChangeRefreshingTime,
            }}
          />
        </div>

        <div>
          <ParallelActions
            path={pathChangeStatus}
            method="PATCH"
            selectedRows={rows
              ?.filter((row) => selectedRows.includes(row.id))
              .map((item) => item.crewEntryDescent.id)}
            setParallelIsFetching={setParallelIsFetching}
            allGoodOrNotAllGoodCallback={fetchAgain}
            buttons={[
              {
                name: t("approve"),
                setBody: [
                  {
                    path: "/crewEntryDescent/confirmationStatus",
                    op: "replace",
                    value: newStatusIdConfirm,
                  },
                ],
              },
            ]}
          />
        </div>
      </div>

      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </>
  );
};

export default CrewEntriesDescentsContent;
