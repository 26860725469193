import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./collaboratos.module.scss";
import Button from "../../../helpersComponents/Button/button";
import { AutocompleteStyled } from "../../../helpersComponents/MaterialUi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

interface Props {
  allPossibleCollaboratorsFiltered: any;
  localCurrentCollaboratorsList: any;
  setLocalCurrentCollaboratorsList: any;
  setAutocompleteIfIsEmpty: React.Dispatch<boolean>;
}

export default function NewCollaboratorsForm({
  allPossibleCollaboratorsFiltered,
  localCurrentCollaboratorsList,
  setLocalCurrentCollaboratorsList,
  setAutocompleteIfIsEmpty,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [autocompleteSelectedWorkers, setAutocompleteSelectedWorkers] =
    useState<any>([]);

  const [
    autocompleteSelectedWorkersError,
    setAutocompleteSelectedWorkersError,
  ] = useState<any>({
    isError: false,
    errorMessage: "",
  });

  useEffect(() => {
    if (autocompleteSelectedWorkers.length > 0) setAutocompleteIfIsEmpty(true);
    else setAutocompleteIfIsEmpty(false);
  }, [autocompleteSelectedWorkers, setAutocompleteIfIsEmpty]);

  return (
    <>
      <div className={styles.formRow}>
        <div className={styles.rowtitle}>{t("select_collaborators")}:</div>
        <div className={styles.rowInput}>
          <AutocompleteStyled
            options={allPossibleCollaboratorsFiltered}
            multiple
            disableCloseOnSelect
            filterSelectedOptions
            getOptionLabel={(option: any) => {
              return option.firstName + " " + option.lastName;
            }}
            width="100%"
            getOptionSelected={(option, value) => option.id === value.id}
            value={
              autocompleteSelectedWorkers ? autocompleteSelectedWorkers : null
            }
            onChange={(_, newValue) => {
              setAutocompleteSelectedWorkers(newValue);
              setAutocompleteSelectedWorkersError({
                isError: false,
                errorMessage: "",
              });
            }}
            isError={autocompleteSelectedWorkersError.isError}
            label={t("select_collaborators")}
          />
        </div>
      </div>
      <div className={styles.assignCollaboratorsButton}>
        <Button
          onClick={() => {
            let localCurrentCollaboratorsListLocal = [
              ...localCurrentCollaboratorsList,
            ];
            autocompleteSelectedWorkers.forEach((selectedworker) =>
              localCurrentCollaboratorsListLocal.push(selectedworker)
            );
            setLocalCurrentCollaboratorsList(
              localCurrentCollaboratorsListLocal
            );
            setAutocompleteSelectedWorkers([]);
          }}
        >
          {`${t("add")}`}{" "}
          <FontAwesomeIcon className={styles.faArrowDown} icon={faArrowDown} />
        </Button>
      </div>
    </>
  );
}
