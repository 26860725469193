import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import SelectFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/SelectField/selectFIeld";
import styles from "./AssigningReadersToOrganizationalUnitsItem.module.scss";
import { useCallback } from "react";

const AssigningReadersToOrganizationalUnitsItem = ({
  id,
  key,
  index,
  fields,
  control,
  getValues,
  setValue,
  watch,
  remove,
  organizationalUnits,
  readers,
}) => {
  const { t } = useTranslation();

  const getAvailableOrganizationalUnits = useCallback(() => {
    let usedOrganizationalUnit: any = [];

    fields.forEach((_, currentIndex) => {
      if (currentIndex !== index) {
        let organizationalUnit = getValues(
          `readers.${currentIndex}.organizationalUnit`
        );
        if (organizationalUnit) {
          usedOrganizationalUnit.push(organizationalUnit.id);
        }
      }
    });

    return organizationalUnits.filter(
      (el) => !usedOrganizationalUnit.includes(el.id)
    );
  }, [fields, getValues, index, organizationalUnits]);

  const getAvailableReaders = useCallback(() => {
    let usedReaders: any = [];

    fields.forEach((_, currentIndex) => {
      let entryReader = getValues(`readers.${currentIndex}.entryReader`);
      let exitReader = getValues(`readers.${currentIndex}.exitReader`);

      if (entryReader) {
        usedReaders.push(entryReader.id);
      }

      if (exitReader) {
        usedReaders.push(exitReader.id);
      }
    });

    return readers.filter((el) => !usedReaders.includes(el.id));
  }, [fields, getValues, readers]);

  return (
    <div>
      <div className={styles.optionItemTitle}>
        <p>
          {`${t("option")} ${index + 1}`}
          <FontAwesomeIcon
            className="faTrash"
            icon={faTimesCircle}
            onClick={() => {
              remove(index);
            }}
          />
        </p>
      </div>
      <div className={styles["form-grid"]}>
        <div className={styles["form-grid-row-item"]}>
          <SelectFieldReactHookForm
            id={`readers.${index}.organizationalUnit`}
            name={`readers.${index}.organizationalUnit`}
            control={control}
            label={t("organizational_cell")}
            required={false}
            options={getAvailableOrganizationalUnits()}
            getOptionLabel={(option) => {
              return `${option.name}`;
            }}
            getOptionSelected={(option, value) => {
              return option.id === value.id;
            }}
            setValue={setValue}
            watch={watch}
          />
        </div>

        <div className={styles["form-grid-row-item"]}>
          <SelectFieldReactHookForm
            id={`readers.${index}.entryReader`}
            name={`readers.${index}.entryReader`}
            control={control}
            label={t("entry_reader")}
            required={false}
            options={getAvailableReaders()}
            getOptionLabel={(option) => {
              return `${option.serialNumber}`;
            }}
            getOptionSelected={(option, value) => {
              return option.id === value.id;
            }}
            setValue={setValue}
            watch={watch}
          />
        </div>

        <div className={styles["form-grid-row-item"]}>
          <SelectFieldReactHookForm
            id={`readers.${index}.exitReader`}
            name={`readers.${index}.exitReader`}
            control={control}
            label={t("exit_reader")}
            required={false}
            options={getAvailableReaders()}
            getOptionLabel={(option) => {
              return `${option.serialNumber}`;
            }}
            getOptionSelected={(option, value) => {
              return option.id === value.id;
            }}
            setValue={setValue}
            watch={watch}
          />
        </div>
      </div>
    </div>
  );
};

export default AssigningReadersToOrganizationalUnitsItem;
