import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import useRefreshData from "../../../../hooks/useRefreshData/useRefreshData";
import { selectSettings } from "../../../../reducers/settings";
import { useAppSelector } from "../../../../store/hooks";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import SelectMenu from "../../../helpersComponents/SelectMenu/selectMenu";
import TableComponentMemo from "../../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const ContentCommunications = () => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date>(
    getBeginningOfDate(new Date(), "Day")
  );
  const [endDate, setEndDate] = useState<Date>(
    getEndOfDate(new Date(), "Day", { extraDays: 7 })
  );

  const columns = [
    { name: "time", title: t("time"), type: "date_hours" },
    { name: "type", title: t("type") },
    { name: "description", title: t("description") },
    { name: "port", title: t("port") },
    { name: "reader", title: t("reader") },
  ];

  const communicationsResponseCallback = useCallback((responseData) => {
    const modified = responseData?.map((row: ACCommunication) => {
      return {
        ...row,
        reader: row.reader ? row.reader : "-",
        port: row.port ? row.port : "-",
      };
    });
    return modified;
  }, []);

  const [alertsRows, fetchingStateAlerts, fetchAgainAlerts] = useFetchAndSetGET<
    ACCommunication[]
  >({
    path:
      `superior-ac/ac-communications?dateFrom=` +
      computeDateRangeFetch(startDate) +
      "&dateTo=" +
      computeDateRangeFetch(endDate),
    startFetchOnInitial: false,
    modifyResponseCallback: communicationsResponseCallback,
  });

  useEffect(() => {
    fetchAgainAlerts();
  }, [startDate, endDate, fetchAgainAlerts]);

  const { refreshingTime, handleChangeRefreshingTime } =
    useRefreshData(fetchAgainAlerts);

  const settings = useAppSelector(selectSettings);
  const restartOfCommunication = settings?.RestartKomunikacji;

  const [requestBody, setRequestBody] = useState<false | any>(false);
  const [, fetchAgainPostData] = useFetchOtherThanGET({
    path: `superior-ac/settings${
      restartOfCommunication ? `/${restartOfCommunication.id}` : ""
    }`,
    method: restartOfCommunication ? "PUT" : "POST",
    body: requestBody,
    setBody: setRequestBody,
    contentType: "application/json",
  });

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, fetchAgainPostData]);

  const [deleteBody, setDeleteBody] = useState<boolean>(false);
  const [, fetchAgainDelete] = useFetchOtherThanGET({
    path: "superior-ac/ac-communications",
    method: "DELETE",
    body: deleteBody,
    setBody: setDeleteBody,
    contentType: "application/json",
  });

  useEffect(() => {
    if (deleteBody) {
      fetchAgainDelete();
      setDeleteBody(false);
    }
  }, [deleteBody, fetchAgainDelete]);

  const { confirm } = useConfirm();

  return (
    <>
      <div>
        <TopBanner pathName={t("communication")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date", "hours", "minutes"]}
            format="dd-MM-yyyy HH:mm"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            darkTheme={true}
            keyboardDateTimePicker={true}
            componentInRow={
              <SelectMenu
                name={<MoreHorizIcon />}
                items={[
                  {
                    optionName: t("communication_restart"),
                    onClick: () => {
                      setRequestBody(
                        JSON.stringify({
                          name: "RestartKomunikacji",
                          description: "",
                          supportedValues: "",
                          value: new Date(),
                          order: 1,
                        })
                      );
                    },
                  },
                  {
                    optionName: t("clear_communication_data"),
                    onClick: async () => {
                      let confirmResponse = await confirm({
                        text: `${t("do_you_clear_communication_data")}?`,
                        buttons: [
                          { buttonName: t("yes"), buttonId: 0 },
                          { buttonName: t("cancel"), buttonId: 1 },
                        ],
                      });
                      if (confirmResponse === 0) setDeleteBody(true);
                    },
                  },
                ]}
              />
            }
          ></DatePickerRange>
        </div>
        <div>
          <TableComponentMemo
            columns={columns}
            rows={alertsRows}
            layoutSettingName="layoutACSuperiorCommunications"
            fetchingState={{
              fetchAgain: fetchAgainAlerts,
              isError: fetchingStateAlerts.isError,
              isFetching: fetchingStateAlerts.isFetching,
            }}
            refreshingButton={{
              ifShow: true,
              refreshingTime: refreshingTime?.value,
              handleChangeRefreshingTime,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ContentCommunications;
