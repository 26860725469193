import { useTranslation } from "react-i18next";
import "./annualRecords.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faCog,
  faHourglass,
  faHourglassEnd,
  faClock,
  faBusinessTime,
  faHome,
  faMoon,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";

const RowDetail: React.FC = ({ row }: any) => {
  const { t } = useTranslation();

  return (
    <div className="detailsAnnual">
      <div className="detailsAnnualRow">
        <div>
          <div className="detailsAnnualTitle">
            <div className="faBriefcase">
              <FontAwesomeIcon className="fa-1x" icon={faBriefcase} />
            </div>
            <b>{t("cp")}:</b>
          </div>
          <div>{row.workingTime !== null ? row.workingTime : "—"}</div>
        </div>
        <div>
          <div className="detailsAnnualTitle">
            <div className="faCalendarCheck">
              <FontAwesomeIcon className="fa-1x" icon={faCalendarCheck} />
            </div>
            <b>{t("cn")}:</b>
          </div>
          <div>{row.normativeTime !== null ? row.normativeTime : "—"}</div>
        </div>
      </div>
      <div className="detailsAnnualRow">
        <div>
          <div className="detailsAnnualTitle">
            <div className="faBusinessTime">
              <FontAwesomeIcon className="fa-1x" icon={faBusinessTime} />
            </div>
            <b>{t("ws")}:</b>
          </div>
          <div>
            {row.businessEntriesExits !== null ? row.businessEntriesExits : "—"}
          </div>
        </div>
        <div>
          <div className="detailsAnnualTitle">
            <div className="faCog">
              <FontAwesomeIcon className="fa-1x" icon={faCog} />
            </div>
            <b>{t("nr")}:</b>
          </div>
          <div>
            {row.workTimeStandard !== null ? row.workTimeStandard : "—"}
          </div>
        </div>
      </div>
      <div className="detailsAnnualRow">
        <div>
          <div className="detailsAnnualTitle">
            <div className="faHourglassEnd">
              <FontAwesomeIcon className="fa-1x" icon={faHourglassEnd} />
            </div>
            <b>{t("50")}:</b>
          </div>
          <div>{row.overTime50 !== null ? row.overTime50 : "—"}</div>
        </div>
        <div>
          <div className="detailsAnnualTitle">
            <div className="faHourglass">
              <FontAwesomeIcon className="fa-1x" icon={faHourglass} />
            </div>
            <b>{t("100")}:</b>
          </div>
          <div>{row.overTime100 !== null ? row.overTime100 : "—"}</div>
        </div>
      </div>
      <div className="detailsAnnualRow">
        <div>
          <div className="detailsAnnualTitle">
            <div className="faMoon">
              <FontAwesomeIcon icon={faMoon} />
            </div>
            <b>{t("pn")}:</b>
          </div>
          <div>{row.nightWork !== null ? row.nightWork : "—"}</div>
        </div>
        <div>
          <div className="detailsAnnualTitle">
            <div>
              <FontAwesomeIcon className="faHome" icon={faHome} />
            </div>

            <b>{t("n")}:</b>
          </div>
          <div>{row.absences !== null ? row.absences : "—"}</div>
        </div>
      </div>
      <div className="detailsAnnualRow">
        <div>
          <div className="detailsAnnualTitle">
            <div className="faClock">
              <FontAwesomeIcon icon={faClock} />
            </div>
            <b>{t("sp")}:</b>
          </div>
          <div>{row.late !== null ? row.late : "—"}</div>
        </div>
      </div>
    </div>
  );
};

export default RowDetail;
