import React, { useState } from "react";
import "./getOvertimeContent.scss";
import { useTranslation } from "react-i18next";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import StyledBlock from "../../../helpersComponents/StyledBlock/styledBlock";
import TextField from "@material-ui/core/TextField";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";

const GetOvertimeContent = () => {
  const { t } = useTranslation();
  const [selectedWorkers, setSelectedWorkers] = useState<any>([]);
  const [fromTimeCheckbox, setFromTimeCheckbox] = useState("00:00");
  const [tillHourCheckbox, setTillHourCheckbox] = useState("00:00");

  const layoutSettingName = "layoutSuperiorTimeListsGetOvertime";

  return (
    <>
      <TopBanner pathName={t("get_overtime")} />
      <div className="flexAndCenter">
        <div>
          <StyledBlock title={"Wybierz datę nadgodzin"}>
            <div className="selectTimeGetOvertime">
              <div>
                <div>Od godziny:</div>
                <div>
                  <TextField
                    value={fromTimeCheckbox}
                    onChange={(e) => {
                      setFromTimeCheckbox(e.target.value);
                    }}
                    type="time"
                  />
                </div>
              </div>
              <div>
                <div>Do:</div>
                <div>
                  <TextField
                    value={tillHourCheckbox}
                    onChange={(e) => {
                      setTillHourCheckbox(e.target.value);
                    }}
                    type="time"
                  />
                </div>
              </div>
            </div>
          </StyledBlock>
        </div>
        <div>
          <WorkerList
            layoutSettingName={layoutSettingName}
            setSelectedWorkers={setSelectedWorkers}
            selectedWorkers={selectedWorkers}
          />
        </div>
      </div>
    </>
  );
};

export default GetOvertimeContent;
