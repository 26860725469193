export const DISABLED_LANGUAGES: string[] = window.globalConfig
  ?.DISABLED_LANGUAGES || [""];

const useDisabledLanguauge = () => {
  const isDisabledLanguage = (langValue: string) => {
    return DISABLED_LANGUAGES.some((disabledLang) => disabledLang === langValue)
      ? true
      : false;
  };

  return isDisabledLanguage;
};

export default useDisabledLanguauge;
