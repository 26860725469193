import { ProfileType } from "../../../../enums/profileType";
import ExitReportContent from "../../../helpersComponents/Support/ExitReportContent";

const ExitReportShiftDirector = () => {
  return (
    <ExitReportContent
      pathApiOperationalSupportExitReport="shift-director/operational-support-exit-report"
      layoutSettingName="layoutShiftDirectorOperationalSupportExitReport"
      profileType={ProfileType.SHIFT_DIRECTOR}
    />
  );
};

export default ExitReportShiftDirector;
