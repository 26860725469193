import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useRefreshData from "../../../hooks/useRefreshData/useRefreshData";
import DatePickerRange from "../DatePickerRange/datePickerRange";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import RowDetails from "./RowDetails";

interface Props {
  pathName: string;
  layoutSettingName: string;
}

const ContentGuestsEventsReport: React.FC<Props> = ({
  pathName,
  layoutSettingName,
}) => {
  const { t } = useTranslation();

  const columns = [
    { name: "eventTime", title: t("event_time"), type: "date_hours" },
    { name: "guest", title: t("person") },
    { name: "worker", title: t("worker") },
    { name: "guestCompanyName", title: t("company") },
    { name: "roomName", title: t("room") },
    { name: "readerSerialNumber", title: t("reader") },
  ];

  const guestsEventsResponseCallback = useCallback(
    (responseData: IGuestEvent[]) => {
      const modified = responseData.map((row) => {
        return {
          ...row,
          guest:
            row?.guestFirstName && row?.guestLastName
              ? row.guestFirstName + " " + row.guestLastName
              : "-",
          worker:
            row?.workerFirstName && row?.workerLastName
              ? row.workerFirstName + " " + row.workerLastName
              : "-",
          guestCompanyName: row?.guestCompanyName ? row.guestCompanyName : "-",
        };
      });
      return modified;
    },
    []
  );

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Day")
  );
  const [endDate, setEndDate] = useState(
    getEndOfDate(new Date(), "Day", { extraDays: 7 })
  );

  const [rows, fetchingState, fetchAgain] = useFetchAndSetGET<IGuestEvent[]>({
    path: `${pathName}?DateFrom=${convertDateFetch(
      startDate
    )}&DateTo=${convertDateFetch(endDate)}`,
    modifyResponseCallback: guestsEventsResponseCallback,
  });

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    fetchAgain();
  }, [startDate, endDate, fetchAgain]);

  const { refreshingTime, handleChangeRefreshingTime } =
    useRefreshData(fetchAgain);

  return (
    <div>
      <div>
        <TopBanner pathName={t("events_report")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            view={["year", "month", "date"]}
            darkTheme={true}
          />
        </div>

        <div>
          <TableComponentMemo
            columns={columns}
            rows={rows}
            layoutSettingName={layoutSettingName}
            rowDetail={RowDetails}
            fetchingState={{
              isFetching: fetchingState.isFetching,
              isError: fetchingState.isError,
              fetchAgain: fetchAgain,
            }}
            refreshingButton={{
              ifShow: true,
              refreshingTime: refreshingTime?.value,
              handleChangeRefreshingTime,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentGuestsEventsReport;
