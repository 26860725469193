import { Form, Formik } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import GetCardTagPopupRow from "../GetCardTagPopupRow/getCardTagPopupRow";
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../PopupHelpers";

interface Props {
  isOpen: boolean;
  closePopup: () => void;
  setCardNumberEndVisit: Dispatch<
    SetStateAction<{
      date: Date;
      cardNumber: string;
    }>
  >;
}

const EndVisitPopup: React.FC<Props> = ({
  isOpen,
  closePopup,
  setCardNumberEndVisit,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isOpen) setErrorMessage("");
  }, [isOpen]);

  const onSubmit = (values: { cardNumber: string }) => {
    setCardNumberEndVisit({
      date: new Date(),
      cardNumber: values.cardNumber,
    });
    closePopup();
  };

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      disableBackdropClick={true}
    >
      <DialogTitleStyled title={t("receive_card")} />
      <DialogContentStyled>
        <Formik
          initialValues={{
            cardNumber: "",
          }}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          validationSchema={Yup.object({
            cardNumber: Yup.string()
              .required(t("field_required"))
              .test(
                "notOnlyZeros",
                t("CARD_NUMBER_INVALID_FORMAT"),
                (value) => value !== "0000000000000000"
              ),
          })}
        >
          {(formikProps) => {
            return (
              <Form noValidate id="submitForm">
                <GetCardTagPopupRow
                  setFieldValue={formikProps.setFieldValue}
                  name="cardNumber"
                  componentId={`endVisit/cardNumber`}
                  isPopupOpen={isOpen}
                  mask={"****************"}
                  widthTitle={200}
                  widthInput={350}
                  startOnInitail={true}
                />
              </Form>
            );
          }}
        </Formik>
      </DialogContentStyled>
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default EndVisitPopup;
