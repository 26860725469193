import { DialogContentStyled } from "../../../helpersComponents/PopupHelpers";
import FormTabs from "./Forms/FormTabs";

interface Props {
  rowId?: number;
  onSubmit: (values: any) => void;
}

const PopupContentACWorker: React.FC<Props> = ({ rowId, onSubmit }) => {
  return (
    <DialogContentStyled>
      <FormTabs onSubmit={onSubmit} rowId={rowId} />
    </DialogContentStyled>
  );
};

export default PopupContentACWorker;
