import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationCircle,
  faPlusCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import {
  GridCellEditCommitParams,
  GridColDef,
  GridPreProcessEditCellProps,
  MuiEvent,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import toLocaleStringCustom from "../../../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import { selectSettings } from "../../../../../../reducers/settings";
import { useAppSelector } from "../../../../../../store/hooks";
import ButtonStyled from "../../../../../helpersComponents/Button/button";
import { TextFieldFormik } from "../../../../../helpersComponents/FormikInputs";
import StyledDataGrid from "../../../../../helpersComponents/MaterialUi/DataGrid/StyledDataGrid";
import {
  Row,
  RowInput,
  RowTitle,
} from "../../../../../helpersComponents/PopupHelpers";
import { regexCardNumber } from "../../FormModel/validationSchema";
import styles from "./CardForm.module.scss";
import SelectCardNumberPopup from "./SelectCardNumberPopup/SelectCardNumberPopup";

interface IProps {
  formFields: FormFieldsACWorker;
  formikValues: {
    [x: string]:
      | string
      | number
      | boolean
      | number[]
      | null
      | any[]
      | Date
      | any;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const CardForm: React.FC<IProps> = ({
  formFields,
  formikValues,
  setFieldValue,
}) => {
  const { t } = useTranslation();

  const { cardNumber, csn, remoteNumber, pin, additionalCards } = formFields;

  const showCSNField =
    useAppSelector(selectSettings)["MifareCSN"]?.value === "1";

  const [additonalsCardsRows, setAdditonalsCardsRows] = useState(
    formikValues[`${additionalCards.name}`] as IAdditionalCard[]
  );

  const columns: GridColDef[] = [
    {
      field: "cardNumber",
      headerName: t("card_number"),
      flex: 1.5,
      renderCell: (params) => {
        if (
          params.row.cardNumber === "" ||
          !regexCardNumber.test(params.row.cardNumber)
        ) {
          return (
            <div className={styles.cardNumberCell}>
              <Tooltip title={t("invalid_value")!}>
                <div>
                  <FontAwesomeIcon icon={faExclamationCircle} color="red" />
                </div>
              </Tooltip>
              <span className={styles.columnSpan}>
                {params.row.cardNumber?.toUpperCase()}
              </span>
            </div>
          );
        }

        return (
          <span className={styles.columnSpan}>{params.row.cardNumber}</span>
        );
      },
      editable: true,
    },
    {
      field: "description",
      headerName: t("description"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.description}</span>
      ),
      editable: true,
    },
    {
      field: "type",
      headerName: t("type"),
      flex: 0.3,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.type}</span>
      ),
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "createdAt",
      headerName: t("data_created"),
      flex: 0.5,
      type: "date",
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {toLocaleStringCustom({
            date: params.row.createdAt,
            t,
            ifShowHours: false,
          })}
        </span>
      ),
    },
    {
      field: "changedAt",
      headerName: t("changed"),
      type: "date",
      flex: 0.5,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {toLocaleStringCustom({
            date: params.row.changedAt,
            t,
            ifShowHours: false,
          })}
        </span>
      ),
    },
    {
      field: "action",
      headerName: t("action"),
      flex: 0.5,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          <Tooltip title={t("remove")!}>
            <div className={styles.deleteIconContainer}>
              <FontAwesomeIcon
                className={styles.deleteIcon}
                icon={faTrashAlt}
                onClick={() => {
                  deleteCardHandler(params.row.id);
                }}
                color="red"
              />
            </div>
          </Tooltip>
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (additonalsCardsRows) {
      setFieldValue(additionalCards.name, additonalsCardsRows);
    }
  }, [additonalsCardsRows, additionalCards.name, setFieldValue]);

  const onCellEditCommitHandler = (
    params: GridCellEditCommitParams,
    event: MuiEvent
  ) => {
    const itemToUpdate = additonalsCardsRows.find((c) => c.id === params.id);
    if (itemToUpdate) {
      itemToUpdate[params.field] = params.value;

      setAdditonalsCardsRows((prevState) => {
        const newState = prevState.map((item) => {
          if (item.id === itemToUpdate.id) {
            return itemToUpdate;
          }
          return item;
        });
        return newState;
      });
    }
  };

  const deleteCardHandler = (id: number) => {
    const cards = formikValues[`${additionalCards.name}`] as IAdditionalCard[];
    setAdditonalsCardsRows(cards.filter((row) => row.id !== id));
  };

  const addCardHandler = () => {
    const newCard = {
      id: new Date().getTime(),
      cardNumber: "",
      type: 0,
      description: "",
      changedAt: null,
      createdAt: new Date(),
    } as IAdditionalCard;

    setAdditonalsCardsRows((prevState) => [...prevState, newCard]);
  };

  const [showSelectCardNumberPopup, setShowSelectCardNumberPopup] = useState({
    fieldName: "",
    isOpen: false,
  });

  const SelectCardButton = (text: string, fieldName: string) => (
    <div className={styles.selectCardButton}>
      <ButtonStyled
        type="button"
        onClick={() => {
          setShowSelectCardNumberPopup({
            fieldName: fieldName,
            isOpen: true,
          });
        }}
      >
        <FontAwesomeIcon icon={faAddressCard} /> {` ${text}`}
      </ButtonStyled>
    </div>
  );

  return (
    <div className={styles.container}>
      {/* Form */}
      <div className={styles.form}>
        {showCSNField && (
          <Row>
            <RowTitle width={180}>{t(csn.label)}:</RowTitle>
            <RowInput>
              <TextFieldFormik
                label={t(csn.label)}
                name={csn.name}
                type="text"
              />
            </RowInput>
          </Row>
        )}

        <Row>
          <RowTitle width={180}>{t(cardNumber.label)}:</RowTitle>
          <RowInput width={400}>
            <TextFieldFormik
              label={t(cardNumber.label)}
              name={cardNumber.name}
              type="text"
              mask={"********-****-****-****-************"}
            />
          </RowInput>

          {SelectCardButton(t("select_card"), cardNumber.name)}
        </Row>

        <Row>
          <RowTitle width={180}>{t(pin.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t(pin.label)}
              name={pin.name}
              type="password"
              onChangeOwn={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                if (isNaN(+value)) return;

                setFieldValue(pin.name, value);
              }}
              inputProps={{
                form: {
                  autocomplete: "off",
                },
              }}
            />
          </RowInput>
        </Row>

        <Row>
          <RowTitle width={180}>{t(remoteNumber.label)}:</RowTitle>
          <RowInput width={400}>
            <TextFieldFormik
              label={t(remoteNumber.label)}
              name={remoteNumber.name}
              type="text"
              mask={"********-****-****-****-************"}
            />
          </RowInput>

          {SelectCardButton(t("select_pilot"), remoteNumber.name)}
        </Row>
      </div>

      {/* Additonal Cards */}
      <div className={styles.additionalCards}>
        <div className={styles.titleContainer}>
          <p className={styles.title}>{t("additional_cards")}</p>
          <div>
            <Tooltip title={t("add")!}>
              <div onClick={addCardHandler}>
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  size="lg"
                  color="green"
                  className={styles.addIcon}
                />
              </div>
            </Tooltip>
          </div>
        </div>
        <StyledDataGrid
          rows={additonalsCardsRows}
          columns={columns}
          defaultPageSize={10}
          rowsPerPageOptions={[10]}
          density="compact"
          onCellEditCommit={onCellEditCommitHandler}
        />
      </div>

      {showSelectCardNumberPopup.isOpen && (
        <SelectCardNumberPopup
          isOpen={showSelectCardNumberPopup.isOpen}
          closePopup={() => {
            setShowSelectCardNumberPopup({
              fieldName: "",
              isOpen: false,
            });
          }}
          setFieldValue={setFieldValue}
          fieldName={showSelectCardNumberPopup.fieldName}
        />
      )}
    </div>
  );
};

export default CardForm;
