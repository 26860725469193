import { ProfileType } from "../../../../enums/profileType";
import ExitReportContent from "../../../helpersComponents/Support/ExitReportContent";

const ExitReportPfso = () => {
  return (
    <ExitReportContent
      pathApiOperationalSupportExitReport="pfso/operational-support-exit-report"
      layoutSettingName="layoutPFSOOperationalSupportExitReport"
      profileType={ProfileType.PFSO}
    />
  );
};

export default ExitReportPfso;
