import { useTranslation } from "react-i18next";
import { TypeOfVisit } from "../../../../Constants/typeOfVisitInPorts";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import AttachmentsListInRowDetail from "../../../helpersComponents/Attachments/attachmentsListInRowDetail";
import AccordionStyled from "../../../helpersComponents/MaterialUi/Accordion/AccordionStyled";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../../../helpersComponents/RowDetailsHelpers";

const RowDetailsNotifications = ({
  row,
}: {
  row: INotificationGuestsInPorts;
}) => {
  const { t } = useTranslation();
  const EMPLOYEE_EMAIL_REQUIRED =
    window.globalConfig.EMPLOYEE_EMAIL_IN_NOTIFICATION;

  return (
    <div>
      <RowDetailsContainer>
        {/* OSOBA */}
        <AccordionStyled title={t("person")} defaultExpanded={true}>
          <>
            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("person")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.guestFirstName && row.guestLastName
                    ? `${row.guestFirstName} ${row.guestLastName}`
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("email")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.guestEmail ? row.guestEmail : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("date_of_birth")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.guestDateOfBirth
                    ? toLocaleStringCustom({
                        date: new Date(row.guestDateOfBirth),
                        t,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("training_expiry_date")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.guestTrainingExpiryDate
                    ? toLocaleStringCustom({
                        date: new Date(row.guestTrainingExpiryDate),
                        t,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>
          </>
        </AccordionStyled>

        {/* INFORMACJE */}
        <AccordionStyled title={t("information")} defaultExpanded={true}>
          <>
            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("date_from")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.dateFrom
                    ? toLocaleStringCustom({
                        date: new Date(row.dateFrom),
                        t,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("date_to2")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.dateTo
                    ? toLocaleStringCustom({
                        date: new Date(row.dateTo),
                        t,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("notification_number")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.notificationNumber ? row.notificationNumber : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("BH3")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.bH3 !== null ? (row?.bH3 ? t("yes") : t("no")) : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("company")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.companyName ? row.companyName : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("type_of_visit")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.typeOfVisitName ? row.typeOfVisitName : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>
                  {!EMPLOYEE_EMAIL_REQUIRED
                    ? t("target_department")
                    : row?.acSuperiorEmail
                    ? t("employee_email")
                    : t("phone_employee")}
                  :
                </RowDetailsTitle>
                <RowDetailsValue>
                  {!EMPLOYEE_EMAIL_REQUIRED
                    ? row?.targetDepartmentName
                      ? row.targetDepartmentName
                      : "-"
                    : row?.acSuperiorEmail
                    ? row.acSuperiorEmail.toLowerCase()
                    : row?.employeePhoneNumber
                    ? row.employeePhoneNumber
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("permission_zone")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.permissionZoneName ? row.permissionZoneName : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("confirmation_pfso")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.pfsoFullName ? row.pfsoFullName + ", " : ""}
                  {row.pfsoDateConfirmation
                    ? toLocaleStringCustom({
                        date: row.pfsoDateConfirmation,
                        t,
                        ifShowHours: true,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("confirmation_employee")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.employeeFullName ? row.employeeFullName + ", " : ""}
                  {row.employeeDateConfirmation
                    ? toLocaleStringCustom({
                        date: row.employeeDateConfirmation,
                        t,
                        ifShowHours: true,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>
                  {t("confirmation_shift_director")}:
                </RowDetailsTitle>
                <RowDetailsValue>
                  {row?.shiftDirectorFullName
                    ? row.shiftDirectorFullName + ", "
                    : ""}
                  {row.shiftDirectorDateConfirmation
                    ? toLocaleStringCustom({
                        date: row.shiftDirectorDateConfirmation,
                        t,
                        ifShowHours: true,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("comments")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.comments ? row.comments : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>
          </>
        </AccordionStyled>

        {/* WARUNKI PRACY */}
        {row?.typeOfVisitId &&
          row.typeOfVisitId === TypeOfVisit.COMPLETION_OF_THE_WORK && (
            <AccordionStyled
              title={t("working_conditions")}
              defaultExpanded={true}
            >
              <>
                <RowDetailsRow>
                  <RowDetailsTitleValue>
                    <RowDetailsTitle>
                      {t("is_work_permit_required")}:
                    </RowDetailsTitle>
                    <RowDetailsValue>
                      {row?.isWorkPermitRequired ? t("yes") : t("no")}
                    </RowDetailsValue>
                  </RowDetailsTitleValue>

                  <RowDetailsTitleValue>
                    <RowDetailsTitle>
                      {t("is_work_particularly_dangerous")}:
                    </RowDetailsTitle>
                    <RowDetailsValue>
                      {row?.isWorkParticularlyDangerous ? t("yes") : t("no")}
                    </RowDetailsValue>
                  </RowDetailsTitleValue>
                </RowDetailsRow>

                {row?.isWorkPermitRequired && (
                  <RowDetailsRow>
                    <RowDetailsTitleValue>
                      <RowDetailsTitle>
                        {t("work_permit_number")}:
                      </RowDetailsTitle>
                      <RowDetailsValue>
                        {row?.workPermitNumber ? row?.workPermitNumber : "-"}
                      </RowDetailsValue>
                    </RowDetailsTitleValue>

                    <RowDetailsTitleValue>
                      <RowDetailsTitle>{t("work_area")}:</RowDetailsTitle>
                      <RowDetailsValue>
                        {row?.workArea ? row?.workArea : "-"}
                      </RowDetailsValue>
                    </RowDetailsTitleValue>
                  </RowDetailsRow>
                )}

                {row?.isWorkParticularlyDangerous && (
                  <RowDetailsRow>
                    <RowDetailsTitleValue>
                      <RowDetailsTitle>
                        {t("work_particularly_dangerous_comments")}:
                      </RowDetailsTitle>
                      <RowDetailsValue>
                        {row?.workParticularlyDangerousComments
                          ? row?.workParticularlyDangerousComments
                          : "-"}
                      </RowDetailsValue>
                    </RowDetailsTitleValue>
                  </RowDetailsRow>
                )}
              </>
            </AccordionStyled>
          )}
      </RowDetailsContainer>

      {/* ZAŁĄCZNIKI */}
      {row?.attachments && row.attachments.length > 0 && (
        <AttachmentsListInRowDetail
          title={t("attachments")}
          files={row?.attachments}
          path={"reception/attachments-in-ports"}
        />
      )}
    </div>
  );
};

export default RowDetailsNotifications;
