import "./events.scss";
import React, { useState, useEffect } from "react";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import Popup from "./popup";
import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";

import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import { SnackbarStyled } from "../../../helpersComponents/MaterialUi";
import useFetchDelete from "../../../../hooks/fetchHooks/delete/useFetchDelete";
import RowDetail from "./rowDetail";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import Button from "../../../helpersComponents/Button/button";
import { CircularProgress } from "@material-ui/core";
import useUpdateLastSelectedWorkers from "../../../../hooks/useUpdateLastSelectedWorkers/useUpdateLastSelectedWorkers";

let Cell: any = TableEditColumn.Cell;

const ActionColumn: React.FC = ({
  children,
  row,
  classes,
  ...restProps
}: any) => {
  return (
    <Cell row={restProps.row} className="tableCellEditCancelStyleGlobal">
      {children}
    </Cell>
  );
};

function EventsContent() {
  const { t } = useTranslation();

  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([]);
  const [selectedWorkersString, setSelectedWorkersString] = useState<
    string | undefined
  >(undefined);
  const [allWorkersSelected, setAllWorkersSelected] = useState<boolean>(false);

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Month")
  );
  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Month"));

  const [fetchAgain, setFetchAgain] = useState(false);
  const [dataModified, setDataModified] = useState<null | any[]>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const { data, error } = useFetchGet(
    "superior-time/events?DateFrom=" +
      computeDateRangeFetch(startDate) +
      "&DateTo=" +
      computeDateRangeFetch(endDate) +
      (allWorkersSelected ? "" : `&timeWorkersIds=${selectedWorkersString}`),
    fetchAgain
  );

  const layoutSettingName = "layoutSuperiorTimeListsEventsSelectWorkers";

  const [updateLastSelectedWorkers] =
    useUpdateLastSelectedWorkers(selectedWorkers);

  const confirmFilters = () => {
    let selectedWorkersLocal = [...selectedWorkers];
    let selectedWorkersStringLocal: string = "";
    selectedWorkersLocal.forEach((selectedId) => {
      selectedWorkersStringLocal =
        selectedWorkersStringLocal + selectedId.toString() + ",";
    });
    selectedWorkersStringLocal = selectedWorkersStringLocal.replace(
      /,\s*$/,
      ""
    );
    setSelectedWorkersString(selectedWorkersStringLocal);
    setDataModified(null);
    setIsFetching(true);
    setFetchAgain(true);
    updateLastSelectedWorkers();
  };

  //////////////////////////////////////////////

  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);
  const [idToDelete, setIdToDelete] = useState();
  const [ifFetchDelete, setIfFetchDelete] = useState<boolean>(false);
  const responseStatusDelete = useFetchDelete(
    "superior-time/events/" + idToDelete,
    ifFetchDelete
  );
  const settings = useAppSelector(selectSettings);

  const columns = [
    { name: "time", title: t("date_and_time"), type: "date_hours" },
    { name: "roomName", title: t("room") },
    { name: "ipAddress", title: t("ip_address") },
    { name: "workerName", title: t("worker") },
    { name: "typeName", title: t("the_type_of_the_event") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "organizationalUnits", title: t("organizational_units") },
  ];

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setFetchAgain(false);
      return;
    }
    if (data === null) return;

    data.forEach((element, index) => {
      data[index].workerName = element.firstName + " " + element.lastName;
    });
    setDataModified(data);
    setIsFetching(false);
    setFetchAgain(false);
  }, [data, error, t]);

  const commitChanges = ({ added, changed, deleted }) => {
    if (deleted) {
      if (dataModified === null) return;
      let row: any = dataModified.find((row) => row.id === deleted[0]);
      setIfFetchDelete(true);
      setIdToDelete(row.id);
    } else if (added) {
      setFetchAgain(true);
      setSnackbarState([true, "success", t("application_added")]);
    } else if (changed) {
      setFetchAgain(true);
      setSnackbarState([true, "success", t("application_edited")]);
    }

    setIsFetching(true); // circ
  };

  useEffect(() => {
    if (responseStatusDelete.data === 204) {
      setSnackbarState([true, "success", t("event_deleted")]);
      setFetchAgain(true);
      setIsFetching(true); // circ
    } else if (responseStatusDelete.data?.status === 400) {
      let error = "";
      Object.keys(responseStatusDelete.data.errors).forEach((errorObject) => {
        responseStatusDelete.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setIsFetching(false); // circ
      setSnackbarState([true, "error", error]);
    } else if (responseStatusDelete.error !== null) {
      setIsFetching(false); // circ
      setSnackbarState([true, "error", t("something_went_wrong")]);
    }
    setIfFetchDelete(false);
  }, [responseStatusDelete, t]);

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <Button
            onClick={() => {
              confirmFilters();
            }}
          >
            {t("get_employees")}
          </Button>
        )}
      </div>
    </>
  );

  return (
    <>
      <TopBanner pathName={t("events")} />
      <div className="flexAndCenter">
        <div>
          <div>
            <WorkerList
              layoutSettingName={layoutSettingName}
              setSelectedWorkers={setSelectedWorkers}
              selectedWorkers={selectedWorkers}
              pageSize={10}
              setAllWorkersSelected={setAllWorkersSelected}
            />
          </div>
        </div>
        <div>
          <DatePickerRange
            view={["year", "month", "date", "hours", "minutes"]}
            format="dd-MM-yyyy HH:mm"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            keyboardDateTimePicker={true}
            darkTheme={true}
            confirmButtonRight={confirmButtonRight}
            setDate2={setEndDate}
          ></DatePickerRange>
        </div>
        <div>
          <LoadingWrapper
            isLodadingProgress={isFetching}
            isError={isError}
            setIfFetchAgain={setFetchAgain}
            setIsError={setIsError}
          >
            {!isFetching && !isError && dataModified !== null && (
              <TableComponent
                layoutSettingName="layoutSuperiorTimeListsEvents"
                rows={dataModified}
                columns={columns}
                popup={Popup}
                ifEdit={
                  settings.WebEdycjaZdarzenPrzezPrzelozonego?.value === "1"
                }
                ifAdd={
                  settings.WebDodawanieZdarzenPrzezPrzelozonego?.value === "1"
                }
                ifDelete={
                  settings.WebUsuwanieZdarzenPrzezPrzelozonego?.value === "1"
                }
                deleteCommandName={t("delete")}
                commitChanges={commitChanges}
                actionColumn={ActionColumn}
                rowDetail={RowDetail}
              />
            )}
          </LoadingWrapper>
          {error ? error.title : null}
        </div>
      </div>
      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </>
  );
}

export default EventsContent;
