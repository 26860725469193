import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../../helpersComponents/RowDetailsHelpers";
import styles from "./WorkersACRowDetail.module.scss";

interface Props {
  row: ACWorker;
}

const WorkersACRowDetail: React.FC<Props> = ({ row }) => {
  const { t } = useTranslation();

  const [acWorker, fetchingStateACWorker, fetchAgainACWorker] =
    useFetchAndSetGET({
      path: `superior-ac/ac-workers/${row.id}/photo`,
      ifBlob: true,
    });

  return (
    <div className={styles.container}>
      <LoadingWrapper
        isLodadingProgress={fetchingStateACWorker.isFetching}
        isError={fetchingStateACWorker.isError}
        setIfFetchAgain={() => {
          if (fetchingStateACWorker.isError) {
            fetchAgainACWorker();
          }
        }}
      >
        <div className={styles.childContainer}>
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>
                <img
                  src={acWorker}
                  alt="Employee"
                  className={styles.workerPhoto}
                />
              </RowDetailsTitle>
            </RowDetailsTitleValue>
          </RowDetailsRow>
        </div>
        <div className={styles.childContainer}>
          <RowDetailsContainer>
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("worker")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row.firstName && row.lastName
                    ? `${row.firstName} ${row.lastName}`
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("position")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row.position ? row.position : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("evidence_number")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row.evidenceNumber ? row.evidenceNumber : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("card_tag")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row.cardNumber ? row.cardNumber : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("ZJW")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row.isZJWElement !== null ? row.isZJWElement : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("VIP")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row.isVipElement ? row.isVipElement : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>
          </RowDetailsContainer>
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default WorkersACRowDetail;
