import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridColDef } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import toLocaleStringCustom from "../../../../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import useFetchAndSetGET from "../../../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import ButtonStyled from "../../../../../../helpersComponents/Button/button";
import LoadingWrapper from "../../../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import StyledDataGrid from "../../../../../../helpersComponents/MaterialUi/DataGrid/StyledDataGrid";
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../../../../helpersComponents/PopupHelpers";
import styles from "./SelectCardNumberPopup.module.scss";

interface IProps {
  isOpen: boolean;
  closePopup: () => void;
  fieldName: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const SelectCardNumberPopup: React.FC<IProps> = ({
  isOpen,
  closePopup,
  fieldName,
  setFieldValue,
}) => {
  const { t } = useTranslation();

  const alertsColumns: GridColDef[] = [
    {
      field: "time",
      headerName: t("time"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.row}>
          {toLocaleStringCustom({
            date: params.row.time,
            ifShowHours: true,
            t,
          })}
        </span>
      ),
    },
    {
      field: "cardNumber",
      headerName: t("card_number"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.row}>{params.row.cardNumber}</span>
      ),
    },
  ];

  const [alertsList, fetchingStateAlertsList, fetchAgainAlertsList] =
    useFetchAndSetGET<Alert[]>({
      path: "superior-ac/alerts/card-reflections",
    });

  const [selectedCardNumber, setSelectedCardNumber] = useState("");

  const selectCardNumberHandler = () => {
    setFieldValue(fieldName, selectedCardNumber);
    closePopup();
  };

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      disableBackdropClick={true}
      maxWidth="xl"
    >
      <DialogTitleStyled title={t("last_unknown_card_reflections")} />
      <div className={styles.refreshContainer}>
        <ButtonStyled onClick={fetchAgainAlertsList}>
          <div>
            <FontAwesomeIcon icon={faRedoAlt} />
            {` ${t("refresh")}`}
          </div>
        </ButtonStyled>
      </div>

      <DialogContentStyled>
        <LoadingWrapper
          isLodadingProgress={fetchingStateAlertsList.isFetching}
          isError={fetchingStateAlertsList.isError}
          setIfFetchAgain={() => {
            if (fetchingStateAlertsList.isError) {
              fetchAgainAlertsList();
            }
          }}
        >
          <div className={styles.alertsContainer}>
            <StyledDataGrid
              rows={alertsList ? alertsList : []}
              columns={alertsColumns}
              defaultPageSize={15}
              rowsPerPageOptions={[15]}
              density="compact"
              quickFilter={true}
              onSelectionModelChange={(selected) => {
                const alert = alertsList.find(
                  (alert) => alert.id === Number(selected[0])
                );
                if (alert) setSelectedCardNumber(alert.cardNumber);
              }}
            />
          </div>
        </LoadingWrapper>
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="button"
        onCancel={closePopup}
        onConfirm={selectCardNumberHandler}
      />
    </DialogStyled>
  );
};

export default SelectCardNumberPopup;
