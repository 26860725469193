import React from 'react';
import WorkersRecordsContent from "./workersRecordsContent"


function NotificationList() {
    return (
        <div>
            <WorkersRecordsContent/>
        </div>
    );
}

export default NotificationList;
