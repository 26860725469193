import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import SelectMultiMenu from "../../../../helpersComponents/SelectMultiMenu";

interface Props {
  substitutionIds: number[];
  removeButtonClick: (ids: number[]) => void;
}

const SubstitutionSelectMenu: React.FC<Props> = ({
  substitutionIds,
  removeButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <SelectMultiMenu
      name={<FontAwesomeIcon icon={faEllipsisH} color="white" />}
      disabled={substitutionIds.length === 0}
      profile="superiorTime"
      items={[
        {
          optionName: t("remove"),
          onClick: () => {
            if (removeButtonClick) {
              removeButtonClick(substitutionIds);
            }
          },
        },
      ]}
    />
  );
};

export default SubstitutionSelectMenu;
