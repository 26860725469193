import { useField, useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { DatePickerStyled } from "../../MaterialUi";
import ErrorLabel from "../ErrorLabel/errorLabel";

const DateMemo = React.memo(
  ({
    value,
    field,
    props,
    onChange,
    meta,
    required,
    disabled,
    setFieldTouched,
  }: any) => {
    return (
      <DatePickerStyled
        date={value}
        setDate={(dateString) => {
          const symulateEventForFormik = {
            target: {
              name: props?.name,
              value: dateString,
            },
          };
          onChange(symulateEventForFormik);
        }}
        {...field}
        {...props}
        width={"100%"}
        isError={meta.touched && meta.error}
        required={required}
        disabled={disabled}
      />
    );
  },
  (prev, curr) => {
    if (prev.meta.error !== curr.meta.error) return false;
    if (prev.meta.touched !== curr.meta.touched) return false;
    if (prev.value !== curr.value) return false;
    if (prev.props.disabled !== curr.props.disabled) return false;
    return true;
  }
);

export default function DatePickerFormik({
  label,
  showTopLabel,
  onChangeExtra,
  required,
  disabled,
  ...props
}: any): ReactElement {
  const [field, meta] = useField(props);

  //// add custom onblur touched
  /// check why it allows to write letters after checked / unchecked
  const { onChange, value } = field;
  let { setFieldTouched } = useFormikContext();

  return (
    <>
      {showTopLabel && (
        <div>
          <b>{label}:</b>
        </div>
      )}
      <DateMemo
        value={value}
        field={field}
        required={required}
        props={props}
        onChange={(value) => {
          if (onChangeExtra) onChangeExtra(value);
          else onChange(value);

          setFieldTouched(field.name, true, false);
        }}
        meta={meta}
        disabled={disabled}
      />
      {meta.touched && meta.error ? (
        <ErrorLabel errorName={meta.error} />
      ) : null}
    </>
  );
}
