import { TFunction } from "react-i18next";
import { PolicyError } from "../../../../enums/policyError";

export const convertPolicyErrorsToStrings = (
  policyErrors: PolicyError[],
  policy: Policy | null,
  t: TFunction<"translation">
): string[] => {
  let errors: string[] = [];
  for (let i = 0; i < policyErrors.length; i++) {
    switch (policyErrors[i]) {
      case PolicyError.TooShort:
        errors.push(t("password_too_short") + `: ${policy?.passwordLength}`);
        break;
      case PolicyError.NoDigit:
        errors.push(t("password_no_digit"));
        break;
      case PolicyError.NoUpperCaseCharacter:
        errors.push(t("password__no_upper_case_character"));
        break;
      case PolicyError.NoLowerCaseCharacter:
        errors.push(t("password__no_lower_case_character"));
        break;
      case PolicyError.NoSpecialCharacter:
        errors.push(t("password__no_special_character"));
        break;
    }
  }
  return errors;
};
