import { useTranslation } from "react-i18next";
import styles from "./deleteLabel.module.scss";

interface IProps {
  onRemoveButtonClick?: () => void;
  onCancelButtonClick?: () => void;
}

export default function DeleteLabel(props: IProps) {
  const { t } = useTranslation();

  const onRemoveButtonClickHandler = () => {
    if (props && props.onRemoveButtonClick) {
      props.onRemoveButtonClick();
    }
  };

  const onCancelButtonClickHandler = () => {
    if (props && props.onCancelButtonClick) {
      props.onCancelButtonClick();
    }
  };

  return (
    <div className={styles.confirmDeleteContainer}>
      <div>
        <p>{t("confirm_delete_element")}</p>
      </div>
      <div className={styles.buttonsContainer}>
        <div
          className={styles.confirmButton}
          onClick={onRemoveButtonClickHandler}
        >
          {t("delete")}
        </div>
        <div
          className={styles.cancelButton}
          onClick={onCancelButtonClickHandler}
        >
          {t("cancel")}
        </div>
      </div>
    </div>
  );
}
