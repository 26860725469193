const formInitialValues = (
  group: IGroup | null = null,
  companies: Company[]
) => {
  return {
    name: group?.name ? group.name : "",
    company: group?.companyId
      ? companies.find((company) => company.id === group.companyId)
      : null,
    description: group?.description ? group.description : "",
    permissions: group?.permissions ? group.permissions : [],
    zonePermissions: group?.zonePermissions ? group.zonePermissions : [],
    groupReaderTimeSettings: group?.groupReaderTimeSettings
      ? group.groupReaderTimeSettings
      : [],
  };
};

export default formInitialValues;
