const formFieldsModel = {
  formFields: {
    //user data
    login: {
      name: "login",
      label: "login",
    },
    permissions: {
      name: "permissions",
      label: "permissions",
    },
    leaveCurrentPassword: {
      name: "leaveCurrentPassword",
      label: "leave_current_password",
    },
    password: {
      name: "password",
      label: "password",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "repeat_password",
    },
    fullName: {
      name: "fullName",
      label: "full_name",
    },
    phoneNumber: {
      name: "phoneNumber",
      label: "phone",
    },
    email: {
      name: "email",
      label: "email",
    },
    locked: {
      name: "locked",
      label: "locked",
    },
    isChangePasswordRequired: {
      name: "isChangePasswordRequired",
      label: "change_password_required",
    },
    //permissions
    departments: {
      name: "departments",
      label: "departments",
    },
    groups: {
      name: "groups",
      label: "supervisor_of_permission_groups",
    },
    //password policies
    passwordPoliciesArray: {
      name: "passwordPoliciesArray",
      label: "",
    },
    passwordPolicy: {
      name: "policy",
      label: "policy",
    },
    passwordPolicyDateFrom: {
      name: "policyDateFrom",
      label: "date_from",
    },
    passwordPolicyDateTo: {
      name: "policyDateTo",
      label: "date_to2",
    },
  },
};

export default formFieldsModel;
