import { useEffect, useState } from "react";
import { CompanyInPortsType } from "../../../Constants/CompanyInPortsType";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../PopupHelpers";
import PopupDialogContent from "./poupDialogContent";

interface SecurityPopupProps {
  onCancelChanges: () => void;
  open: boolean;
  successCallback: () => void;
  pathApi: string;
  companiesInPortsPathApi: string;
  permissionZonesPathApi: string;
  registrationNumber?: boolean;
  title: string;
  onlyGarbageCompany?: boolean;
}

const PopupDeliveryAndGarbageGuest: React.FC<SecurityPopupProps> = ({
  onCancelChanges,
  open,
  successCallback,
  pathApi,
  companiesInPortsPathApi,
  permissionZonesPathApi,
  registrationNumber,
  title,
  onlyGarbageCompany,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (values: DeliveryAndGarbageFormValues) => {
    setErrorMessage("");
    let guests = values.guestsArray.map((guest) => ({
      firstName: guest.firstName,
      lastName: guest.lastName,
      dateOfBirth:
        values.company?.type === CompanyInPortsType.Courier
          ? guest.dateOfBirth
          : null,
      idNumber: guest.idNumber,
      cardNumber: guest.cardNumber,
      trainingExpiryDate:
        values.company?.type === CompanyInPortsType.Courier
          ? guest.trainingExpiryDate
          : null,
    }));
    let body = {
      guests: guests,
      comments: values.comments,
      companyId: values.company?.id,
      permissionZoneId: values.permissionZone?.id,
      registrationNumber: values.registrationNumber,
    };
    setBodyRequest(JSON.stringify(body));
  };

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: pathApi,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallback,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  return (
    <>
      <DialogStyled
        open={open}
        onClose={onCancelChanges}
        disableBackdropClick={true}
      >
        <DialogTitleStyled title={title} />
        <PopupDialogContent
          onSubmit={onSubmit}
          isPopupOpen={open}
          companiesInPortsPathApi={companiesInPortsPathApi}
          permissionZonesPathApi={permissionZonesPathApi}
          registrationNumber={registrationNumber}
          onlyGarbageCompany={onlyGarbageCompany}
        />
        <DialogActionsStyled
          typConfirm="submit"
          formConfirm="submitForm"
          onCancel={onCancelChanges}
          isLoading={fetchingStatePostData.isFetching}
          errorString={errorMessage}
        />
      </DialogStyled>
    </>
  );
};

export default PopupDeliveryAndGarbageGuest;
