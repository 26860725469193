import React, { ReactElement } from "react";
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import "devextreme/ui/html_editor/converters/markdown";
import styles from "./textEditor.module.scss";

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];

interface Props {
  htmlContent: string;
  setHtmlContent?: React.Dispatch<string>;
  hideToolbar?: boolean;
  height?: string;
}

export default function TextEditor({
  htmlContent,
  hideToolbar = false,
  setHtmlContent,
  height,
}: Props): ReactElement {
  return (
    <div className={styles.rootContainer}>
      <HtmlEditor
        height={height ? height : 500}
        value={htmlContent}
        onValueChanged={(e) =>
          setHtmlContent ? setHtmlContent(e.value) : false
        }
        readOnly={setHtmlContent ? false : true}
      >
        {!hideToolbar && (
          <Toolbar>
            <Item name="undo" />
            <Item name="redo" />
            <Item name="separator" />
            <Item name="size" acceptedValues={sizeValues} />
            <Item name="font" acceptedValues={fontValues} />
            <Item name="separator" />
            <Item name="bold" />
            <Item name="italic" />
            <Item name="strike" />
            <Item name="underline" />
            <Item name="separator" />
            <Item name="alignLeft" />
            <Item name="alignCenter" />
            <Item name="alignRight" />
            <Item name="alignJustify" />
            <Item name="separator" />
            <Item name="color" />
            <Item name="background" />
            <Item name="orderedList" />
            <Item name="bulletList" />
            <Item name="insertTable" />
            <Item name="insertRowAbove" />
            <Item name="insertRowBelow" />
            <Item name="insertColumnLeft" />
            <Item name="insertColumnRight" />
            <Item name="deleteColumn" />
            <Item name="deleteRow" />
            <Item name="codeBlock" />
            <Item name="decreaseIndent" />
            <Item name="increaseIndent" />
            <Item name="subscript" />
            <Item name="superscript" />
          </Toolbar>
        )}
      </HtmlEditor>

      {/* <div className="options">
        <div className="value-content">{htmlContent}</div>
      </div> */}
    </div>
  );
}
