import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

export type DialogType = "confirmation" | "warning";

interface initialStateTypes {
  parallelsFetchErrorsState: {
    show: boolean;
    columns: any;
    rows: any;
  };
}

interface showConfirmInterface {
  data: any;
}

const initialState: initialStateTypes = {
  parallelsFetchErrorsState: {
    show: false,
    columns: [],
    rows: [],
  },
};

export const parallelsFetchErrorsStateReducer = createSlice({
  name: "parallelsFetchErrorsState",
  initialState,
  reducers: {
    showParallelsFetchErrorsDialog: (
      state,
      action: PayloadAction<showConfirmInterface>
    ) => {
      state.parallelsFetchErrorsState = {
        show: true,
        columns: action.payload.data.columns,
        rows: action.payload.data.rows,
      };
    },
    hideParallelsFetchErrorsDialog: (state) => {
      state.parallelsFetchErrorsState = {
        show: false,
        columns: [],
        rows: [],
      };
    },
  },
});

export const selectParallelsFetchErrorsState = (state: RootState) =>
  state.parallelsFetchErrorsState.parallelsFetchErrorsState;
export const { showParallelsFetchErrorsDialog } =
  parallelsFetchErrorsStateReducer.actions;
export const { hideParallelsFetchErrorsDialog } =
  parallelsFetchErrorsStateReducer.actions;
export default parallelsFetchErrorsStateReducer.reducer;
