import React, { useState, useEffect, useCallback, useMemo } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./delegationsList.scss";
import { DatePickerStyled } from "../../../helpersComponents/MaterialUi/index";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import DialogActionsStyled from "../../../helpersComponents/PopupHelpers/dialogActionsStyled";
import { DialogContentStyled } from "../../../helpersComponents/PopupHelpers/dialogContentStyled";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextFieldFormik from "../../../helpersComponents/FormikInputs/TextField/textField";
import DatePickerFormik from "../../../helpersComponents/FormikInputs/DatePicker/datePicker";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { AutocompleteFormik } from "../../../helpersComponents/FormikInputs";
import CheckboxWithLabelFormik from "../../../helpersComponents/FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import addDaysToDate from "../../../../HelpersFunctions/dateAndTime/addDaysToDate";
import { DelegationPurpose } from "../../../../Constants/delegationPurpose";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { converStringTimeToMilitaryStringTime } from "../../../../HelpersFunctions/dateAndTime/timeStrings";
import { useHistory } from "react-router-dom";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";

interface IProps {
  row: TimeWorkerDelegationRow | undefined;
  isOpen: boolean;
  closePopup: () => void;
  successCallback: () => void;
}

const Popup: React.FC<IProps> = ({
  row,
  isOpen,
  closePopup,
  successCallback,
}) => {
  const { t } = useTranslation();
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const userName = useAppSelector(selectAuthUser).displayedName;
  const [errorMessage, setErrorMessage] = useState<string>();
  const [requestBody, setRequestBody] = useState<false | any>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDelegationFetched, setIsDelegationFetched] =
    useState<boolean>(false);
  const history: any = useHistory();

  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const delegationsPurposes = useMemo(
    () => [
      {
        id: DelegationPurpose.MARKETING,
        name: t("delegation_purpose_marketing"),
      },
      {
        id: DelegationPurpose.TRAINING,
        name: t("delegation_purpose_training"),
      },
      {
        id: DelegationPurpose.COMPLAINTS,
        name: t("delegation_purpose_complaints"),
      },
      {
        id: DelegationPurpose.MANAGEMENT,
        name: t("delegation_purpose_management"),
      },
      {
        id: DelegationPurpose.GROUP_SERVICE,
        name: t("delegation_purpose_group_service"),
      },
      {
        id: DelegationPurpose.OTHER,
        name: t("delegation_purpose_other"),
      },
    ],
    [t]
  );

  const [countries, countriesFetchingState] = useFetchAndSetGET({
    path: `worker-time/${authUserId}/countries`,
  });

  const [cities, citiesFetchingState] = useFetchAndSetGET({
    path: `worker-time/${authUserId}/cities`,
  });

  const delegationNumberResponseCallback = useCallback(
    (responseData: DelegationNumber | null) => {
      return responseData?.number;
    },
    []
  );

  const [nextDelegationNumber, , nextDelegationNumberFetchAgain] =
    useFetchAndSetGET({
      path: `worker-time/${authUserId}/delegation-number`,
      startFetchOnInitial: false,
      modifyResponseCallback: delegationNumberResponseCallback,
    });

  const [delegation, delegationFetchingState, delegationFetchAgain] =
    useFetchAndSetGET<FetchedDelegation>({
      path: `worker-time/${authUserId}/delegations/${row?.id ?? -1}`,
      startFetchOnInitial: false,
    });

  let initialValues: Delegation = useMemo(() => {
    return {
      id: row?.id ? row?.id : -1,
      timeWorkerId: row?.id ? (delegation ? delegation.timeWorkerId : -1) : -1,
      person: userName,
      delegationNumber: row?.id
        ? delegation
          ? delegation.delegationNumber
          : "1"
        : nextDelegationNumber,
      delegationExtendedNumber: "",
      mpk: row?.id ? (delegation ? delegation.mpk : "") : "",

      country: row?.id
        ? delegation
          ? countries.find((el) => el.id === delegation.countryId)
          : null
        : null,
      city: row?.id
        ? delegation
          ? cities.find((el) => el.id === delegation.cityId)
          : null
        : null,
      startCity: row?.id
        ? delegation
          ? cities.find((el) => el.id === delegation.startCityId)
          : null
        : null,
      endCity: row?.id
        ? delegation
          ? cities.find((el) => el.id === delegation.endCityId)
          : null
        : null,

      startDate: row?.id
        ? row.startDate
        : history.location.state?.startDate
        ? getBeginningOfDate(history.location.state?.startDate, "Day")
        : new Date(),
      endDate: row?.id
        ? row.endDate
        : history.location.state?.endDate
        ? getEndOfDate(history.location.state?.endDate, "Day")
        : addDaysToDate(new Date(), 1),

      departureTime: row?.id
        ? delegation?.departureDate
          ? new Date(delegation.departureDate!).toLocaleTimeString()
          : "00:00"
        : "00:00",
      arrivalTime: row?.id
        ? delegation?.arrivalDate
          ? new Date(delegation.arrivalDate!).toLocaleTimeString()
          : "00:00"
        : "00:00",
      delegationPurpose: row?.id
        ? delegation
          ? delegationsPurposes.find(
              (el) => el.id === delegation.delegationPurposeId
            )
          : null
        : null,

      customDelegationPurpose: row?.id
        ? delegation
          ? delegation.customDelegationPurpose
          : ""
        : "",

      isCompanyGroup: row?.id
        ? delegation
          ? delegation.isCompanyGroup
          : false
        : false,

      isBus: row?.id ? (delegation ? delegation.isBus : false) : false,
      returnByBus: row?.id
        ? delegation
          ? delegation.returnByBus
          : false
        : false,

      isTrain: row?.id ? (delegation ? delegation.isTrain : false) : false,
      returnByTrain: row?.id
        ? delegation
          ? delegation.returnByTrain
          : false
        : false,

      isPlane: row?.id ? (delegation ? delegation.isPlane : false) : false,
      returnByPlane: row?.id
        ? delegation
          ? delegation.returnByPlane
          : false
        : false,

      isCar: row?.id ? (delegation ? delegation.isCar : false) : false,

      category: row?.id ? (delegation ? delegation.category : null) : null,
      daysNumber: row?.id ? (delegation ? delegation.daysNumber : null) : null,

      isDomesticRental: row?.id
        ? delegation
          ? delegation.isDomesticRental
          : false
        : false,
      isForeignRental: row?.id
        ? delegation
          ? delegation.isForeignRental
          : false
        : false,

      isExternalContract: row?.id
        ? delegation
          ? delegation.isExternalContract
          : false
        : false,

      isCompanyCar: row?.id
        ? delegation
          ? delegation.isCompanyCar
          : false
        : false,
      isOwnCarForBusinessPurpose: row?.id
        ? delegation
          ? delegation.isOwnCarForBusinessPurpose
          : false
        : false,

      carEngineCapacity: row?.id
        ? delegation
          ? delegation.carEngineCapacity
          : null
        : null,
      carBrand: row?.id ? (delegation ? delegation.carBrand : null) : null,
      carPlateNumber: row?.id
        ? delegation
          ? delegation.carPlateNumber
          : null
        : null,

      planeArrival: row?.id
        ? delegation
          ? delegation.arrivalDate !== null
          : false
        : false,
      planeDeparture: row?.id
        ? delegation
          ? delegation.departureDate !== null
          : false
        : false,

      approverId: null,
      approverName: null,
      secondApproverId: null,
      secondApproverName: null,
      approvedAt: null,

      status: 0,
      createdAt: new Date(),
    };
  }, [
    row,
    nextDelegationNumber,
    cities,
    countries,
    userName,
    delegation,
    delegationsPurposes,
    history.location.state?.startDate,
    history.location.state?.endDate,
  ]);

  useEffect(() => {
    if (!isOpen) {
      setIsDelegationFetched(false);
      return;
    }

    if (!row?.id) {
      nextDelegationNumberFetchAgain();
    }

    if (
      row?.id &&
      row?.id > 0 &&
      !isDelegationFetched &&
      !delegationFetchingState.isFetching
    ) {
      delegationFetchAgain();
      setIsDelegationFetched(true);
    }
  }, [
    isOpen,
    row,
    nextDelegationNumberFetchAgain,
    delegationFetchAgain,
    delegationFetchingState.isFetching,
    isDelegationFetched,
  ]);

  useEffect(() => {
    if (!countriesFetchingState.isFetching && !citiesFetchingState.isFetching) {
      setIsLoading(false);
    }
  }, [countriesFetchingState, citiesFetchingState]);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `worker-time/${authUserId}/delegations${
      row && row.id ? `/${row.id}` : ""
    }`,
    method: row && row.id ? "PUT" : "POST",
    contentType: "application/json",
    body: requestBody,
    setBody: setRequestBody,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, setRequestBody, fetchAgainPostData]);

  const displayCarField = useCallback((values) => {
    if (
      values.isCompanyCar &&
      !values.isForeignRental &&
      !values.isDomesticRental &&
      !values.isOwnCarForBusinessPurpose &&
      !values.isExternalContract
    ) {
      return false;
    }

    return true;
  }, []);

  return (
    <Dialog
      maxWidth="lg"
      // fullWidth = {true}
      open={isOpen}
      onClose={closePopup}
      TransitionProps={{
        onExited: () => {},
        onEnter: () => {
          setErrorMessage("");
        },
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className="dialogTitleGlobal">
        <div className="">
          {t("business_trip")} -{" "}
          {row?.id ? t("editing_application") : t("adding_application")}
        </div>
      </DialogTitle>

      <DialogContentStyled>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(value: Delegation) => {
            if (
              !value.isBus &&
              !value.returnByBus &&
              !value.isTrain &&
              !value.returnByTrain &&
              !value.isPlane &&
              !value.returnByPlane &&
              !value.isCar
            ) {
              setErrorMessage(t("choose_any_form_of_transporta"));
              return;
            }

            if (value.isPlane && !value.planeDeparture && !value.planeArrival) {
              setErrorMessage(
                t("for_plane_you_have_to_choose_at_least_one_way_of_travel")
              );
              return;
            }

            if (
              value.isCar &&
              !value.isForeignRental &&
              !value.isDomesticRental &&
              !value.isOwnCarForBusinessPurpose &&
              !value.isCompanyCar &&
              !value.isExternalContract
            ) {
              setErrorMessage(
                t(
                  "for_car_you_have_to_choose_at_least_one_way_to_get_into_the_possession_of_the_car"
                )
              );
              return;
            }

            if (value) {
              let obj: any = {
                timeWorkerId: authUserId,
                mpk: value.mpk,
                startCityId: value.startCity ? value.startCity.id : -1,
                endCityId: value.endCity ? value.endCity.id : -1,
                startDate: new Date(value.startDate),
                endDate: new Date(value.endDate),

                departureDate: value.departureTime
                  ? new Date(
                      `2000-01-01T${converStringTimeToMilitaryStringTime(
                        value.departureTime
                      )}`
                    )
                  : new Date(`2000-01-01T00:00:00`),
                arrivalDate: value.arrivalTime
                  ? new Date(
                      `2000-01-01T${converStringTimeToMilitaryStringTime(
                        value.arrivalTime
                      )}`
                    )
                  : new Date(`2000-01-01T00:00:00`),

                delegationPurposeId: value.delegationPurpose
                  ? value.delegationPurpose.id
                  : -1,
                customDelegationPurpose: value.customDelegationPurpose,
                countryId: value.country ? value.country.id : -1,
                cityId: value.city ? value.city.id : -1,
                isCompanyGroup: value.isCompanyGroup,
                isBus: value.isBus,
                returnByBus: value.returnByBus,
                isPlane: value.isPlane,
                returnByPlane: value.returnByPlane,
                isTrain: value.isTrain,
                returnByTrain: value.returnByTrain,

                isCar: value.isCar,
                category: value.category,
                daysNumber: value.daysNumber,

                isDomesticRental: value.isDomesticRental,
                isForeignRental: value.isForeignRental,

                isCompanyCar: value.isCompanyCar,
                isOwnCarForBusinessPurpose: value.isOwnCarForBusinessPurpose,
                carEngineCapacity: value.carEngineCapacity,
                carBrand: value.carBrand,
                carPlateNumber: value.carPlateNumber,
                isExternalContract: value.isExternalContract,
              };

              if (row && row.id) {
                obj["id"] = row.id;
              }

              setRequestBody(JSON.stringify(obj));
            }
          }}
          validationSchema={Yup.object({
            mpk: Yup.number().required(t("field_required")),
            country: Yup.object()
              .nullable(false)
              .required()
              .typeError(t("field_required")),
            city: Yup.object()
              .nullable(false)
              .required()
              .typeError(t("field_required")),
            startCity: Yup.object()
              .nullable(false)
              .required()
              .typeError(t("field_required")),
            endCity: Yup.object()
              .nullable(false)
              .required()
              .typeError(t("field_required")),
            delegationPurpose: Yup.object()
              .nullable(false)
              .required()
              .typeError(t("field_required")),
            daysNumber: Yup.number()
              .nullable(true)
              .when("isForeignRental", {
                is: true,
                then: Yup.number().nullable(true).required(t("field_required")),
              }),
            category: Yup.string()
              .nullable(true)
              .max(5, t("max_length_is") + " 5"),
            carPlateNumber: Yup.string()
              .nullable(true)
              .max(20, t("max_length_is") + " 20"),
            carBrand: Yup.string()
              .nullable(true)
              .max(100, t("max_length_is") + " 100"),

            customDelegationPurpose: Yup.string()
              .nullable(true)
              .when("delegationPurpose", {
                is: (obj) => obj && obj.id === 6,
                then: Yup.string()
                  .nullable(true)
                  .required(t("field_required"))
                  .max(50, t("max_length_is") + " 50"),
              }),
          })}
        >
          {({ values }) =>
            !isLoading ? (
              <LoadingWrapper
                isLodadingProgress={delegationFetchingState.isFetching}
                isError={delegationFetchingState.isError}
                setIfFetchAgain={delegationFetchAgain}
              >
                <Form id="submitForm">
                  <div>
                    <div className="sectionDeleList">
                      <div className="headerDeleList">
                        <b>{t("basic_information")}</b>
                      </div>
                      <div>
                        <div className="rowDeleList">
                          <div>
                            <div className="delegationListItem">
                              <div className="rowTitleDelegationList">
                                {t("delagations_number")}:
                              </div>
                              <div className="inputStyle">
                                <TextFieldFormik
                                  label={t("select_delagations_number")}
                                  defaultValue={1}
                                  type="string"
                                  name="delegationNumber"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="delegationListItem">
                              <div className="rowTitleDelegationList">
                                {t("person")}:
                              </div>
                              <div className="inputStyle">
                                <TextFieldFormik
                                  label={t("person")}
                                  defaultValue={""}
                                  style={{ width: "100%" }}
                                  name="person"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rowDeleList">
                          <div>
                            <div className="delegationListItem">
                              <div className="rowTitleDelegationList">
                                {t("date_created")}:
                              </div>
                              <div className="inputStyle">
                                <DatePickerFormik
                                  label={t("date_from")}
                                  name="createdAt"
                                  view={["year", "month", "date"]}
                                  format="dd-MM-yyyy"
                                  setDate={DatePickerStyled}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="delegationListItem">
                              <div className="rowTitleDelegationList">
                                {t("cc")}:
                              </div>
                              <div className="inputStyle">
                                <TextFieldFormik
                                  label={t("select_cc")}
                                  defaultValue={1}
                                  type="number"
                                  style={{ width: "100%" }}
                                  name="mpk"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="sectionDeleList">
                      <div className="headerDeleList">
                        <b>{t("trip_target_and_date")}</b>
                      </div>
                      <div>
                        <div className="rowDeleList">
                          <div>
                            <div className="delegationListItem">
                              <div className="rowTitleDelegationList">
                                {t("country")}:
                              </div>
                              <div className="inputStyle">
                                <AutocompleteFormik
                                  name="country"
                                  options={countries}
                                  getOptionLabel={(option: Country) => {
                                    return t(option.name);
                                  }}
                                  getOptionSelected={(
                                    option: Country,
                                    value: Country
                                  ) => {
                                    return option.id === value.id;
                                  }}
                                  style={{ width: "100%" }}
                                  label={t("select_country")}
                                  required={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="delegationListItem">
                              <div className="rowTitleDelegationList">
                                {t("ending_city")}:
                              </div>
                              <div className="inputStyle">
                                <AutocompleteFormik
                                  name="city"
                                  options={cities}
                                  getOptionLabel={(option: City) => {
                                    return t(option.name);
                                  }}
                                  getOptionSelected={(
                                    option: City,
                                    value: City
                                  ) => {
                                    return option.id === value.id;
                                  }}
                                  style={{ width: "100%" }}
                                  label={t("ending_city")}
                                  required={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rowDeleList">
                          <div>
                            <div className="delegationListItem">
                              <div className="rowTitleDelegationList">
                                {t("start_city")}:
                              </div>
                              <div className="inputStyle">
                                <AutocompleteFormik
                                  name="startCity"
                                  options={cities}
                                  getOptionLabel={(option: City) => {
                                    return t(option.name);
                                  }}
                                  getOptionSelected={(
                                    option: City,
                                    value: City
                                  ) => {
                                    return option.id === value.id;
                                  }}
                                  style={{ width: "100%" }}
                                  label={t("start_city")}
                                  required={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="delegationListItem">
                              <div className="rowTitleDelegationList">
                                {t("end_city")}:
                              </div>
                              <div className="inputStyle">
                                <AutocompleteFormik
                                  name="endCity"
                                  options={cities}
                                  getOptionLabel={(option: City) => {
                                    return t(option.name);
                                  }}
                                  getOptionSelected={(
                                    option: City,
                                    value: City
                                  ) => {
                                    return option.id === value.id;
                                  }}
                                  style={{ width: "100%" }}
                                  label={t("end_city")}
                                  required={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rowDeleList">
                          <div>
                            <div className="delegationListItem">
                              <div className="rowTitleDelegationList">
                                {t("being_date")}:
                              </div>
                              <div className="inputStyle">
                                <DatePickerFormik
                                  label={t("date_from")}
                                  name="startDate"
                                  view={[
                                    "year",
                                    "month",
                                    "date",
                                    "hours",
                                    "minutes",
                                  ]}
                                  format="dd-MM-yyyy HH:mm"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="delegationListItem">
                              <div className="rowTitleDelegationList">
                                {t("ending_time")}:
                              </div>
                              <div className="inputStyle">
                                <DatePickerFormik
                                  label={t("date_to")}
                                  name="endDate"
                                  view={[
                                    "year",
                                    "month",
                                    "date",
                                    "hours",
                                    "minutes",
                                  ]}
                                  format="dd-MM-yyyy HH:mm"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rowDeleList">
                          <div>
                            <div className="delegationListItem">
                              <div className="rowTitleDelegationList">
                                {t("trip_target")}:
                              </div>
                              <div className="inputStyle">
                                <AutocompleteFormik
                                  name="delegationPurpose"
                                  options={delegationsPurposes}
                                  getOptionLabel={(
                                    option: DelegationPurposeItem
                                  ) => {
                                    return (
                                      delegationsPurposes.find(
                                        (el) => el.id === option.id
                                      )?.name ?? ""
                                    );
                                  }}
                                  getOptionSelected={(
                                    option: DelegationPurposeItem,
                                    value: DelegationPurposeItem
                                  ) => {
                                    return option.id === value.id;
                                  }}
                                  style={{ width: "100%" }}
                                  label={t("select_trip_target")}
                                  required={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="delegationListItem">
                              <div className="rowTitleDelegationList">
                                {t("trip_target_other")}:
                              </div>
                              <div className="inputStyle">
                                <TextFieldFormik
                                  label={t("select_trip_target")}
                                  name="customDelegationPurpose"
                                  defaultValue=""
                                  style={{ width: 250 }}
                                  type="string"
                                  multiline
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div>
                            <CheckboxWithLabelFormik
                              name="isCompanyGroup"
                              label={<span>{t("group")}</span>}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="sectionDeleList">
                      <div className="headerDeleList">
                        <b>{t("mode_of_transport")}</b>
                      </div>
                      <div className="columns">
                        <div className="singleColumn">
                          <div className="columnTitle">{t("bus")}</div>
                          <div className="rowColumn">
                            <CheckboxWithLabelFormik
                              name="isBus"
                              label={<span>{t("departure")}</span>}
                            />
                          </div>
                          <div className="rowColumn">
                            <CheckboxWithLabelFormik
                              name="returnByBus"
                              label={<span>{t("arrival")}</span>}
                            />
                          </div>
                        </div>

                        <div className="singleColumn">
                          <div className="columnTitle">{t("railway")}</div>
                          <div className="rowColumn">
                            <CheckboxWithLabelFormik
                              name="isTrain"
                              grou
                              label={<span>{t("departure")}</span>}
                            />
                          </div>
                          <div className="rowColumn">
                            <CheckboxWithLabelFormik
                              name="returnByTrain"
                              label={<span>{t("arrival")}</span>}
                            />
                          </div>
                        </div>

                        <div className="singleColumn">
                          <div className="columnTitle">{t("plane")}</div>
                          <div className="rowColumn">
                            <CheckboxWithLabelFormik
                              name="isPlane"
                              label={<span>{t("use")}</span>}
                            />
                          </div>
                        </div>

                        <div className="singleColumn">
                          <div className="columnTitle">{t("car")}</div>
                          <div className="rowColumn">
                            <CheckboxWithLabelFormik
                              name="isCar"
                              label={<span>{t("use")}</span>}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {values.isPlane ? (
                      <div className="sectionDeleList showPlaneCarAnimation">
                        <div className="headerDeleList">
                          <b>{t("plane_configuration")}</b>
                        </div>
                        <div>
                          <div className="rowDeleList">
                            <div>
                              <div className="delegationListItem">
                                <div className="rowTitleDelegationList">
                                  <CheckboxWithLabelFormik
                                    name="planeDeparture"
                                    label={<span>{t("plane_take_off")}</span>}
                                  />
                                </div>
                                <div className="inputStyle">
                                  {values.planeDeparture ? (
                                    <TextFieldFormik
                                      hiddenLabel
                                      name="departureTime"
                                      defaultValue=""
                                      style={{ width: 100 }}
                                      type="time"
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="delegationListItem">
                                <div className="rowTitleDelegationList">
                                  <CheckboxWithLabelFormik
                                    name="planeArrival"
                                    label={
                                      <span>{t("plane_take_arrival")}</span>
                                    }
                                  />
                                </div>
                                <div className="inputStyle">
                                  {values.planeArrival ? (
                                    <TextFieldFormik
                                      name="arrivalTime"
                                      defaultValue=""
                                      style={{ width: 100 }}
                                      type="time"
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {values.isCar ? (
                      <div className="sectionDeleList showPlaneCarAnimation">
                        <div className="headerDeleList">
                          <b>{t("car_configuration")}</b>
                        </div>

                        <div>
                          <div className="rowDeleList">
                            <div>
                              <div className="delegationListItem">
                                <div className="rowTitleDelegationList">
                                  <CheckboxWithLabelFormik
                                    name="isForeignRental"
                                    label={<span>{t("rent_abroad")}</span>}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="delegationListItem">
                                <div className="rowTitleDelegationList">
                                  <CheckboxWithLabelFormik
                                    name="isDomesticRental"
                                    label={<span>{t("rent")}</span>}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="delegationListItem">
                                <div className="rowTitleDelegationList">
                                  <CheckboxWithLabelFormik
                                    name="isOwnCarForBusinessPurpose"
                                    label={<span>{t("private_car")}</span>}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="delegationListItem">
                                <div className="rowTitleDelegationList">
                                  <CheckboxWithLabelFormik
                                    name="isCompanyCar"
                                    label={<span>{t("company_car")}</span>}
                                  />
                                </div>
                              </div>
                            </div>

                            <div>
                              <div className="delegationListItem">
                                <div className="rowTitleDelegationList">
                                  <CheckboxWithLabelFormik
                                    name="isExternalContract"
                                    label={
                                      <span>{t("car_external_contract")}</span>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*Dane dla grid*/}
                          <div className="grid-container">
                            {displayCarField(values) && (
                              <div>
                                <div className="delegationListItem">
                                  <div className="rowTitleDelegationList">
                                    {t("category")}:
                                  </div>
                                  <div className="inputStyle">
                                    <TextFieldFormik
                                      label={t("category")}
                                      name="category"
                                      defaultValue=""
                                      type="string"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {displayCarField(values) && (
                              <div>
                                <div className="delegationListItem">
                                  <div className="rowTitleDelegationList">
                                    {t("days_capital")}:
                                  </div>
                                  <div className="inputStyle">
                                    <TextFieldFormik
                                      label={t("days_capital")}
                                      name="daysNumber"
                                      defaultValue=""
                                      style={{ width: "100%" }}
                                      type="number"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {displayCarField(values) && (
                              <div>
                                <div className="delegationListItem">
                                  <div className="rowTitleDelegationList">
                                    {t("brand")}:
                                  </div>
                                  <div className="inputStyle">
                                    <TextFieldFormik
                                      label={t("brand")}
                                      name="carBrand"
                                      defaultValue=""
                                      style={{ width: "100%" }}
                                      type="string"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            <div>
                              <div className="delegationListItem">
                                <div className="rowTitleDelegationList">
                                  {t("plate_number")}:
                                </div>
                                <div className="inputStyle">
                                  <TextFieldFormik
                                    label={t("plate_number")}
                                    name="carPlateNumber"
                                    defaultValue=""
                                    style={{ width: "100%" }}
                                    type="string"
                                  />
                                </div>
                              </div>
                            </div>

                            {displayCarField(values) && (
                              <div>
                                <div className="delegationListItem">
                                  <div className="rowTitleDelegationList">
                                    {t("engine_capacity")}:
                                  </div>

                                  <div className="inputStyle">
                                    <TextFieldFormik
                                      label={t("engine_capacity")}
                                      name="carEngineCapacity"
                                      defaultValue=""
                                      style={{ width: 250 }}
                                      type="number"
                                      inputProps={{
                                        min: "0",
                                        setp: 1,
                                        max: "32767",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Form>
              </LoadingWrapper>
            ) : (
              <div>
                <CircularProgress />
              </div>
            )
          }
        </Formik>
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </Dialog>
  );
};

export default Popup;
