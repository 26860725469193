import HomePageContent from "./homePageContent";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";
import { Redirect, Route } from "react-router-dom";

function HomePage() {
  const settings = useAppSelector(selectSettings);

  return (
    <div>
      <Route>
        {settings.WyswietlajWMenuTylkoZadania?.value === "1" ? (
          <Redirect to="projects/my-tasks" />
        ) : (
          <HomePageContent />
        )}
      </Route>
    </div>
  );
}

export default HomePage;
