import { faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import styles from "./buttons.module.scss";

interface Props {
  onResetChartPosition: () => void;
}

const ResetPositionButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("position_reset")!}>
      <div className={styles.buttonContainer}>
        <FontAwesomeIcon
          icon={faCrosshairs}
          size={"1x"}
          className={styles.button}
          onClick={props.onResetChartPosition}
        />
      </div>
    </Tooltip>
  );
};

export default ResetPositionButton;
