import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import SelectMultiMenu from "../../../helpersComponents/SelectMultiMenu";

interface Props {
  acWorker: ACWorker;
  successCallback: () => void;
  setPopupSetPinParams: Dispatch<SetStateAction<SetPinPopup>>;
}

const SingleWorkerSelectMenu: React.FC<Props> = ({
  acWorker,
  successCallback,
  setPopupSetPinParams,
}) => {
  const { t } = useTranslation();
  const [paramsRequest, setParamsRequest] = useState<ParamsRequest>({
    path: "superior-ac/ac-workers-restore",
    method: "PATCH",
    body: null,
  });

  const [, workerRemoveOrRestoreFetchAgain] = useFetchOtherThanGET({
    path: paramsRequest.path,
    method: paramsRequest.method,
    body: paramsRequest.body
      ? JSON.stringify({
          acWorkersIds: paramsRequest.body,
        })
      : null,
    setBody: setParamsRequest,
    contentType: "application/json",
    successCallback: successCallback,
  });

  useEffect(() => {
    if (paramsRequest.body) {
      workerRemoveOrRestoreFetchAgain();
    }
  }, [paramsRequest.body, workerRemoveOrRestoreFetchAgain]);

  const { confirm } = useConfirm();
  const handleRemoveOrRestore = async (isActive: boolean, id: number) => {
    if (isActive) {
      let confirmResponse = await confirm({
        text: `${t("whether_remove_card_for_reuse")}?`,
        buttons: [
          { buttonName: t("yes"), buttonId: 0 },
          { buttonName: t("no"), buttonId: 1 },
        ],
      });

      setParamsRequest({
        path: `superior-ac/ac-workers${
          confirmResponse === 0 ? "?removeCard=true" : ""
        }`,
        method: "DELETE",
        body: [acWorker.id],
      });
    } else {
      setParamsRequest({
        path: "superior-ac/ac-workers-restore",
        method: "PATCH",
        body: [acWorker.id],
      });
    }
  };

  return (
    <SelectMultiMenu
      name={<FontAwesomeIcon icon={faEllipsisV} color="white" />}
      position={"right"}
      profile="superiorAC"
      items={[
        {
          optionName: !acWorker.isActive ? t("restore") : t("remove"),
          onClick: () => handleRemoveOrRestore(acWorker.isActive, acWorker.id),
        },
        {
          optionName: !acWorker.isContaminated
            ? `${t("lock_employee")} (${t("mark_as_contaminated")})`
            : `${t("unlock_employee")} (${t("mark_as_uncontaminated")})`,
          onClick: () => {
            setParamsRequest({
              path: `superior-ac/ac-workers-update-contamination?isContamination=${
                !acWorker.isContaminated ? "true" : "false"
              }`,
              method: "PATCH",
              body: [acWorker.id],
            });
          },
        },
        {
          optionName: !acWorker.isPinLocked ? t("lock_pin") : t("unlock_pin"),
          onClick: () => {
            setParamsRequest({
              path: `superior-ac/ac-workers-update-pin-locked?isPinLocked=${
                !acWorker.isPinLocked ? "true" : "false"
              }`,
              method: "PATCH",
              body: [acWorker.id],
            });
          },
        },
        {
          optionName: t("remove_card_for_reuse"),
          onClick: () => {
            setParamsRequest({
              path: "superior-ac/ac-workers-remove-card",
              method: "PATCH",
              body: [acWorker.id],
            });
          },
          hidden: acWorker.cardNumber === "-",
        },
        {
          optionName: t("set_pin"),
          onClick: () => {
            setPopupSetPinParams({
              isOpen: true,
              workersIds: [acWorker.id],
            });
          },
        },
        // {
        //   optionName: t("Zaawansowane"),
        //   children: [
        //     {
        //       optionName: "Opcja 1",
        //       onClick: () => alert("Opcja 1"),
        //     },
        //     {
        //       optionName: "Opcja 2",
        //       children: [
        //         {
        //           optionName: "Opcja 2 v1",
        //           onClick: () => alert("Opcja 2v1"),
        //         },
        //       ],
        //     },
        //   ],
        // },
      ]}
    />
  );
};

export default SingleWorkerSelectMenu;
