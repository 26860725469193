import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { TextFieldFormik } from "../../../helpersComponents/FormikInputs";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../../helpersComponents/PopupHelpers";

const PopupContentPreApprovedCompanies = ({
  rowId,
  onSubmit,
}: {
  rowId?: number;
  onSubmit: (values: IAddUpdatePreApprovedCompany) => void;
}) => {
  const { t } = useTranslation();

  const [fetchedRow, fetchingStateFetchedRow, fetchAgainFetchedRow] =
    useFetchAndSetGET<IPreApprovedCompany>({
      path: `pfso/pre-approved-companies/${rowId}`,
      startFetchOnInitial: rowId ? true : false,
    });

  return (
    <LoadingWrapper
      isLodadingProgress={fetchingStateFetchedRow.isFetching}
      isError={fetchingStateFetchedRow.isError}
      setIfFetchAgain={() => {
        if (fetchingStateFetchedRow.isError) {
          fetchAgainFetchedRow();
        }
      }}
    >
      <DialogContentStyled>
        <Formik
          initialValues={{
            companyName: fetchedRow ? fetchedRow.companyName : "",
            comments: fetchedRow ? fetchedRow.comments : "",
          }}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          validationSchema={Yup.object({
            companyName: Yup.string().required(t("field_required")),
          })}
        >
          <Form noValidate id="submitForm">
            <TwoColumnsRowsContainer>
              <Row>
                <RowTitle>{t("company")}:</RowTitle>
                <RowInput>
                  <TextFieldFormik
                    label={t("company")}
                    name="companyName"
                    type="text"
                    required={true}
                    ifToUpperCaseShipsAndPorts={true}
                  />
                </RowInput>
              </Row>

              <Row>
                <RowTitle>{t("comments")}:</RowTitle>
                <RowInput>
                  <TextFieldFormik
                    label={t("comments")}
                    name="comments"
                    type="text"
                    ifToUpperCaseShipsAndPorts={true}
                    multiline={true}
                  />
                </RowInput>
              </Row>
            </TwoColumnsRowsContainer>
          </Form>
        </Formik>
      </DialogContentStyled>
    </LoadingWrapper>
  );
};

export default PopupContentPreApprovedCompanies;
