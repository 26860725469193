//czas przy liniach - wyliczy grid
export const data = [
  //13-12-2022
  {
    id: 1,
    date: "13-12-2022",
    entryRCP: "06:01",
    exitRCP: "10:15",
    workingTime: "04:14",
    breakTime: "00:10",
    timeWorkerId: 10,
    lineId: 1,
    lineName: "Linia A",
    workerFirstName: "Jan",
    workerLastName: "Bednarz",
    evidenceNumber: "12345",
    workerEvidenceNumber: "Jan Bednarz [12345]",
    organizationalUnit: "Unit1",
    company: "Com40",
    from: "6:45",
    timeFrom: new Date(),
    timeTo: new Date(),
    to: "7:50",
    time: "00:55",
    timeOnLines: "02:08",
    comment: "",
  },
  {
    id: 2,
    date: "13-12-2022",
    entryRCP: "06:01",
    exitRCP: "10:15",
    workingTime: "04:14",
    breakTime: "00:10",
    timeWorkerId: 10,
    lineId: 2,
    lineName: "Linia B",
    workerFirstName: "Jan",
    workerLastName: "Bednarz",
    evidenceNumber: "12345",
    workerEvidenceNumber: "Jan Bednarz [12345]",
    organizationalUnit: "Unit1",
    company: "Com40",
    from: "7:50",
    timeFrom: new Date(),
    timeTo: new Date(),
    to: "8:00",
    time: "00:10",
    timeOnLines: "02:08",
    comment: "",
  },
  {
    id: 3,
    date: "13-12-2022",
    entryRCP: "06:01",
    exitRCP: "10:15",
    workingTime: "04:14",
    breakTime: "00:10",
    timeWorkerId: 10,
    lineId: 1,
    lineName: "Linia A",
    workerFirstName: "Jan",
    workerLastName: "Bednarz",
    evidenceNumber: "12345",
    workerEvidenceNumber: "Jan Bednarz [12345]",
    organizationalUnit: "Unit1",
    company: "Com40",
    from: "9:12",
    timeFrom: new Date(),
    timeTo: new Date(),
    to: "10:15",
    time: "01:03",
    timeOnLines: "02:08",
    comment: "Ręczne wyrzucenie",
  },

  //14-12-2022
  {
    id: 4,
    date: "14-12-2022",
    entryRCP: "06:00",
    exitRCP: "14:02",
    workingTime: "08:00",
    breakTime: "00:30",
    timeWorkerId: 10,
    lineId: 2,
    lineName: "Linia B",
    workerFirstName: "Jan",
    workerLastName: "Bednarz",
    evidenceNumber: "12345",
    workerEvidenceNumber: "Jan Bednarz [12345]",
    organizationalUnit: "Unit1",
    company: "Com40",
    from: "6:05",
    timeFrom: new Date(),
    timeTo: new Date(),
    to: "12:05",
    time: "06:00",
    timeOnLines: "07:55",
    comment: "",
  },
  {
    id: 5,
    date: "14-12-2022",
    entryRCP: "06:00",
    exitRCP: "14:02",
    workingTime: "08:00",
    breakTime: "00:30",
    timeWorkerId: 10,
    timeWorkIdDate: "10_14-12-2022",
    lineId: 1,
    lineName: "Linia A",
    workerFirstName: "Jan",
    workerLastName: "Bednarz",
    evidenceNumber: "12345",
    workerEvidenceNumber: "Jan Bednarz [12345]",
    organizationalUnit: "Unit1",
    company: "Com40",
    from: "12:05",
    timeFrom: new Date(),
    timeTo: new Date(),
    to: "14:00",
    time: "01:55",
    timeOnLines: "07:55",
    comment: "",
  },

  {
    id: 6,
    date: "14-12-2022",
    entryRCP: "05:55",
    exitRCP: "14:01",
    workingTime: "08:00",
    breakTime: "00:30",
    timeWorkerId: 11,
    lineId: 1,
    lineName: "Linia A",
    workerFirstName: "Anna",
    workerLastName: "Nowak",
    evidenceNumber: "54321",
    workerEvidenceNumber: "Anna Nowak [54321]",
    organizationalUnit: "Unit2",
    company: "Skalmex",
    from: "6:02",
    timeFrom: new Date(),
    timeTo: new Date(),
    to: "10:02",
    time: "04:00",
    timeOnLines: "07:59",
    comment: "",
  },
  {
    id: 7,
    date: "14-12-2022",
    entryRCP: "05:55",
    exitRCP: "14:01",
    workingTime: "08:00",
    timeWorkerId: 11,
    lineId: 3,
    lineName: "Linia C",
    workerFirstName: "Anna",
    workerLastName: "Nowak",
    evidenceNumber: "54321",
    workerEvidenceNumber: "Anna Nowak [54321]",
    organizationalUnit: "Unit2",
    company: "Skalmex",
    from: "10:02",
    timeFrom: new Date(),
    timeTo: new Date(),
    to: "14:01",
    time: "03:59",
    timeOnLines: "07:59",
    comment: "",
  },
];
