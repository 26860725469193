import { DialogContent } from "@material-ui/core";
import { Form, Formik } from "formik";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import ButtonStyled from "../../helpersComponents/Button/button";
import { TextFieldFormik } from "../../helpersComponents/FormikInputs";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../helpersComponents/PopupHelpers";
import styles from "./styles.module.scss";

interface Props {
  patchApi: string;
  successCallback?: () => void;
  getIsFetchingState?: (isFetchingState: boolean) => void;
  isOpen: boolean;
  closePopup: () => void;
  openPopup: () => void;
  showRefuseButton?: boolean;
  prefixPathName?: string;
  isSecurity: boolean;
}

export default function RejectPopup({
  patchApi,
  successCallback,
  isOpen,
  closePopup,
  openPopup,
  showRefuseButton,
  prefixPathName,
  isSecurity,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [bodyRequest, setBodyRequest] = useState<false | any>(false);

  const successAndClose = () => {
    if (successCallback) successCallback();

    closePopup();
  };

  const [fetchingState, fetchAgain] = useFetchOtherThanGET({
    path: patchApi,
    method: "PATCH",
    body: bodyRequest,
    setBody: setBodyRequest,
    contentType: "application/json",
    successCallback: successAndClose,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgain();
    }
  }, [bodyRequest, fetchAgain]);

  return (
    <>
      {showRefuseButton && (
        <ButtonStyled className={styles.buttonRefuse} onClick={openPopup}>
          {t("refuse")}
        </ButtonStyled>
      )}
      <DialogStyled
        open={isOpen}
        onClose={closePopup}
        disableBackdropClick={true}
      >
        <DialogTitleStyled title={t("refusal_notification")} />
        <DialogContent>
          <Formik
            initialValues={{
              reason: "",
              comments: "",
            }}
            validationSchema={Yup.object({
              reason: Yup.string().required(t("field_required")),
            })}
            onSubmit={(values) => {
              if (isSecurity) {
                setBodyRequest(
                  JSON.stringify([
                    {
                      path: prefixPathName
                        ? prefixPathName + "/isLetIn"
                        : "/isLetIn",
                      op: "replace",
                      value: false,
                    },
                    {
                      path: prefixPathName
                        ? prefixPathName + "/securityRejectionReason"
                        : "/securityRejectionReason",
                      op: "replace",
                      value: values.reason,
                    },
                    {
                      path: prefixPathName
                        ? prefixPathName + "/securityRejectionDescription"
                        : "/securityRejectionDescription",
                      op: "replace",
                      value: values.comments,
                    },
                  ])
                );
              } else {
                setBodyRequest(
                  JSON.stringify([
                    {
                      path: prefixPathName
                        ? prefixPathName + "/confirmationStatus"
                        : "/confirmationStatus",
                      op: "replace",
                      value: 5,
                    },
                    {
                      path: prefixPathName
                        ? prefixPathName + "/rejectionReason"
                        : "/rejectionReason",
                      op: "replace",
                      value: values.reason,
                    },
                    {
                      path: prefixPathName
                        ? prefixPathName + "/rejectionDescription"
                        : "/rejectionDescription",
                      op: "replace",
                      value: values.comments,
                    },
                  ])
                );
              }
            }}
          >
            <Form noValidate id="confirmRejection">
              <Row>
                <RowTitle>{t("reason")}:</RowTitle>
                <RowInput>
                  <TextFieldFormik
                    label={t("reason")}
                    name="reason"
                    type="text"
                    required={true}
                    ifToUpperCaseShipsAndPorts={true}
                  />
                </RowInput>
              </Row>
              <Row>
                <RowTitle>{t("comments")}:</RowTitle>
                <RowInput>
                  <TextFieldFormik
                    label={t("comments")}
                    name="comments"
                    type="text"
                    multiline
                    rows={5}
                    ifToUpperCaseShipsAndPorts={true}
                  />
                </RowInput>
              </Row>
            </Form>
          </Formik>
        </DialogContent>
        <DialogActionsStyled
          typConfirm="submit"
          formConfirm="confirmRejection"
          isLoading={fetchingState.isFetching}
          onCancel={closePopup}
        />
      </DialogStyled>
    </>
  );
}
