import { useTranslation } from "react-i18next";
import styles from "./addEditLabel.module.scss";
import { ColorBox } from "devextreme-react";
import { TextFieldStyled } from "../../../../../../../helpersComponents/MaterialUi";
import { useState } from "react";

interface IProps {
  labelId: number;
  labelName: string;
  labelColor: string;
  onApplyButtonClick?: (label: ScheduleDayLabel) => void;
  onCancelButtonClick?: () => void;
}

export default function AddEditLabel(props: IProps) {
  const { t } = useTranslation();

  const [labelName, setLabelName] = useState<string>(
    props.labelId > 0 ? props.labelName : ""
  );

  const [labelColor, setLabelColor] = useState<string>(
    props.labelId > 0 ? props.labelColor : "#FF0000"
  );

  const applyButtonClickHandler = () => {
    if (props.onApplyButtonClick) {
      let label: ScheduleDayLabel = {
        id: props.labelId,
        name: labelName,
        color: labelColor,
      };

      props.onApplyButtonClick(label);
    }
  };

  const cancelButtonClickHandler = () => {
    if (props.onCancelButtonClick) {
      props.onCancelButtonClick();
    }
  };

  return (
    <>
      <TextFieldStyled
        label={t("label_name")}
        defaultValue={labelName}
        required={true}
        isError={""}
        onBlur={(e) => {
          setLabelName(e.target.value);
        }}
      />

      <div>
        <ColorBox
          defaultValue={labelColor}
          onValueChange={(selectedColor) => {
            setLabelColor(selectedColor);
          }}
          value={labelColor}
        />
      </div>

      <div>
        <div className={styles.buttonsContainer}>
          <div
            className={styles.confirmButton}
            onClick={applyButtonClickHandler}
          >
            {props.labelId > 0 ? t("apply") : t("add")}
          </div>
          <div
            className={styles.cancelButton}
            onClick={cancelButtonClickHandler}
          >
            {t("cancel")}
          </div>
        </div>
      </div>
    </>
  );
}
