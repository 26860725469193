import React from 'react';
import ScheduleContent from "./scheduleContent"


function Schedule() {
    return (
        <div>
            <ScheduleContent/>
        </div>
    );
}

export default Schedule;
