import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileType } from "../../../enums/profileType";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import { colorNameDictionary } from "../../ProfilesRouting/profilesDictionaries";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import PopupSupport from "./PopupSupport";
import styles from "./styles.module.scss";

const RowDetailSupport = ({
  row,
  pathApiListsOfEmployees,
  pathApiListOfEmployeesEmploye,
  fetchAgainListsOfEmployees,
  profileType,
}: {
  row: IListOfEmployees;
  pathApiListsOfEmployees: string;
  pathApiListOfEmployeesEmploye: string;
  fetchAgainListsOfEmployees: () => void;
  profileType: ProfileType;
}) => {
  const { t } = useTranslation();
  const employees = row.employess;
  const authUserType = useAppSelector(selectAuthUser).currentProfile.type;

  //#region Remove
  const [employeesIdToRemove, setEmployeesIdToRemove] = useState<
    false | number
  >(false);

  const [removeFetchingState, removeFetchAgain] = useFetchOtherThanGET({
    path: `${pathApiListOfEmployeesEmploye}/${employeesIdToRemove}`,
    method: "DELETE",
    body: employeesIdToRemove,
    setBody: setEmployeesIdToRemove,
    successCallback: fetchAgainListsOfEmployees,
  });

  useEffect(() => {
    if (employeesIdToRemove !== false) {
      removeFetchAgain();
    }
  }, [employeesIdToRemove, removeFetchAgain]);
  //#endregion

  //#region Edit
  const [isOpenEditPopup, setIsOpenEditPopup] = useState<{
    isOpen: boolean;
    employeeId: number | null;
  }>({
    isOpen: false,
    employeeId: null,
  });

  const handleCloseEditPopup = useCallback(() => {
    setIsOpenEditPopup({
      isOpen: false,
      employeeId: null,
    });
  }, []);
  //#endregion

  const buttons = (employeeId: number) => (
    <div>
      <Tooltip title={t("edit")!}>
        <Button
          onClick={() =>
            setIsOpenEditPopup({
              isOpen: true,
              employeeId: employeeId,
            })
          }
        >
          {<FontAwesomeIcon icon={faEdit} color="#0B9B35" />}
        </Button>
      </Tooltip>

      <Tooltip title={t("remove")!}>
        <Button onClick={() => setEmployeesIdToRemove(employeeId)}>
          {<FontAwesomeIcon icon={faTrashAlt} color="#F14C4C" />}
        </Button>
      </Tooltip>
    </div>
  );

  return (
    <div>
      <LoadingWrapper
        isLodadingProgress={removeFetchingState.isFetching}
        isError={false}
        setIfFetchAgain={() => {}}
      >
        <table className={styles.table}>
          <thead style={{ backgroundColor: colorNameDictionary[authUserType] }}>
            <tr>
              {profileType === ProfileType.OPERATIONAL_SUPPORT && (
                <th className={styles.actionsColumn}>{t("actions")}</th>
              )}
              <th className={styles.lpColumn}>Lp.</th>
              <th>{t("employee")}</th>
              <th>{t("id_number")}</th>
              <th>{t("company")}</th>
              <th>{t("position")}</th>
              <th>{t("comments")}</th>
              <th>{t("is_letted_in")}</th>
              <th>{t("rejection_reason")}</th>
              <th>{t("is_declaration")}</th>
            </tr>
          </thead>
          <tbody>
            {employees.map((employee, index) => {
              return (
                <tr
                  key={employee.id}
                  className={
                    employee.isLetIn === true
                      ? styles.lettedIn
                      : employee.isLetIn === false
                      ? styles.notLettedIn
                      : ""
                  }
                >
                  {profileType === ProfileType.OPERATIONAL_SUPPORT && (
                    <td>{employee.isLetIn === null && buttons(employee.id)}</td>
                  )}
                  <td>{++index}</td>
                  <td>
                    {employee.employeeFirstName} {employee.employeeLastName}
                  </td>
                  <td>
                    {employee.employeeIDNumber
                      ? employee.employeeIDNumber
                      : "-"}
                  </td>
                  <td>{employee.company}</td>
                  <td>{employee.position}</td>
                  <td>{employee.comments ? employee.comments : "-"}</td>
                  <td>
                    {employee.isLetIn !== null
                      ? employee.isLetIn === true
                        ? `${t("yes").toUpperCase()} (${t("by")} ${
                            employee.personLettingInFullName
                          })`
                        : `${t("no").toUpperCase()} (${t("by")} ${
                            employee.personLettingInFullName
                          })`
                      : "-"}
                  </td>
                  <td>
                    {employee.rejectionReason
                      ? employee.rejectionDescription
                        ? `${employee.rejectionReason} ${employee.rejectionDescription}`
                        : employee.rejectionReason
                      : "-"}
                  </td>
                  <td>{employee.isDeclarationChecked ? t("yes") : t("no")}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </LoadingWrapper>

      <div>
        {isOpenEditPopup.isOpen && (
          <PopupSupport
            isOpen={isOpenEditPopup.isOpen}
            listOfEmployeesId={row.id}
            employeeId={isOpenEditPopup.employeeId!}
            closePopup={handleCloseEditPopup}
            pathApiListsOfEmployees={pathApiListsOfEmployees}
            pathApiListOfEmployeesEmploye={pathApiListOfEmployeesEmploye}
            fetchAgainListsOfEmployees={fetchAgainListsOfEmployees}
          />
        )}
      </div>
    </div>
  );
};

export default RowDetailSupport;
