import React from "react";
import ReceivedTrainingsContent from "./receivedTrainingsContent";

function ReceivedTrainings() {
  return (
    <div>
      <ReceivedTrainingsContent />
    </div>
  );
}

export default ReceivedTrainings;
