import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useState, useEffect } from "react";
import { setSettings } from "../../reducers/settings";
import useFetchGet from "../fetchHooks/get/useFetchGet";
import { useTranslation } from "react-i18next";
import { selectAuthUser } from "../../reducers/session";
import { profilesRoutesDictionary } from "../../components/ProfilesRouting/profilesDictionaries";

const useUpdateSettings = (ifUpdateSettings: boolean, ifSetEmpty = false) => {
  const dispatch = useAppDispatch();
  const authUser = useAppSelector(selectAuthUser);
  let url = profilesRoutesDictionary[authUser.currentProfile?.type];
  if (url === "worker-time") {
    url = url + `/${authUser.currentProfile?.subjectId}`;
  }
  const settings = useFetchGet(`${url}/settings`, ifUpdateSettings);

  const preferences = useFetchGet(`${url}/preferences`, ifUpdateSettings);
  const { i18n } = useTranslation();
  const [ifSettingsUpdated, setIfSettingsUpdated] = useState<any>(false);

  useEffect(() => {
    if (ifSetEmpty) {
      dispatch(setSettings({}));
    }
  }, [dispatch, ifSetEmpty]);

  useEffect(() => {
    if (ifUpdateSettings === false && ifSettingsUpdated?.error !== true) {
      setIfSettingsUpdated(false);
    }
  }, [ifUpdateSettings, ifSettingsUpdated]);

  useEffect(() => {
    if (settings.error === 401 || preferences.error === 401) {
      dispatch(setSettings({ ifSettingsFetched: false }));
      return setIfSettingsUpdated({ error: true });
    }
    if (
      settings.data?.status === 400 ||
      settings.error !== null ||
      preferences.data?.status === 400 ||
      preferences.error !== null
    ) {
      dispatch(setSettings({ error: true }));
      return setIfSettingsUpdated({ error: true });
    }
    if (settings.data === null || preferences.data === null) return;

    //////// creating object of settings
    let settingsObject: any = {};
    settings.data.forEach((value) => {
      settingsObject[value.name] = value;
    });

    //////// overwrite setting by preferences
    preferences.data.forEach((value) => {
      settingsObject[value.name] = value;
    });

    /////////////// checking if there is language in settings, if is set it in localstorage
    let language = settingsObject?.["web_jezyk"];

    if (language !== undefined) {
      let languageArray = [language.value, 0];
      i18n.changeLanguage(language.value);
      localStorage.setItem(
        "languageAndOptionId",
        JSON.stringify(languageArray)
      );
    } else if (localStorage.getItem("languageAndOptionId") !== null) {
      let language = JSON.parse(
        localStorage.getItem("languageAndOptionId") || "{}"
      )?.[0];
      i18n.changeLanguage(language);
    }

    settingsObject["ifSettingsFetched"] = true;
    dispatch(setSettings(settingsObject));
    setIfSettingsUpdated(true);
  }, [settings, preferences, i18n, dispatch]);

  return ifSettingsUpdated;
};

export default useUpdateSettings;
