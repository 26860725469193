import { DialogContent } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./collaboratos.module.scss";
import Button from "../../../helpersComponents/Button/button";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from "notistack";
import profileIcon from "../../../Navbar/Images/empty-profile-picture.png";
import NewCollaboratorsForm from "./newCollaboratorsForm";
import useFetchPut from "../../../../hooks/fetchHooks/put/useFetchPut";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";

interface Props {
  projectId: number;
  taskId?: number;
  setIsDialogOpen: React.Dispatch<boolean>;
  setIfFetchAgain: React.Dispatch<boolean>;
  currentAssignedCollaborators: any;
  photos: any;
  allPossibleCollaborators: any;
}

export default function CollaboratorsDialogContent({
  projectId,
  setIsDialogOpen,
  setIfFetchAgain,
  currentAssignedCollaborators,
  taskId,
  photos,
  allPossibleCollaborators,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [
    allPossibleCollaboratorsFiltered,
    setAllPossibleCollaboratorsFiltered,
  ] = useState([]);
  const [autocompleteIfIsEmpty, setAutocompleteIfIsEmpty] = useState(true);
  const [localCurrentCollaboratorsList, setLocalCurrentCollaboratorsList] =
    useState(currentAssignedCollaborators);

  useEffect(() => {
    let allPossibleCollaboratorsFilteredLocal: any = [];
    allPossibleCollaborators.forEach((timeWorker) => {
      let ifInclude = localCurrentCollaboratorsList.some(
        (timeWorkerSelected) => {
          return timeWorker.id === timeWorkerSelected.id;
        }
      );
      if (!ifInclude) allPossibleCollaboratorsFilteredLocal.push(timeWorker);
    });
    setAllPossibleCollaboratorsFiltered(allPossibleCollaboratorsFilteredLocal);
  }, [allPossibleCollaborators, localCurrentCollaboratorsList]);

  const [postRequestBody, setPostRequestBody] = useState<any>(false);
  const [isPosting, setIsPosting] = useState<boolean>(false);
  const [responseErrorMessage, setResponseErrorMessage] = useState<string>("");

  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  let postRequest = useFetchPut(
    taskId
      ? `worker-time/${authUserId}/projects/${projectId}/tasks/${taskId}/collaborators`
      : `worker-time/${authUserId}/sharing-projects/${projectId}`,
    postRequestBody
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (postRequest.data?.status === 200) {
      setIfFetchAgain(true);
      enqueueSnackbar(t("changes_saved"), {
        variant: "success",
      });
      setIsDialogOpen(false);
      setIsPosting(false);
    } else if (postRequest.data?.status === 400) {
      let error = "";
      Object.keys(postRequest.data.errors).forEach((errorObject) => {
        postRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setResponseErrorMessage(error);
    } else if (postRequest.error !== null) {
      setIsPosting(false);
      setResponseErrorMessage(t("something_went_wrong"));
    }
  }, [
    postRequest,
    taskId,
    setIfFetchAgain,
    enqueueSnackbar,
    setIsDialogOpen,
    t,
  ]);

  const confirm = () => {
    setResponseErrorMessage("");
    let collaboratorsIdsArray: number[] = [];
    localCurrentCollaboratorsList.forEach((collaborator) => {
      collaboratorsIdsArray.push(collaborator.id);
    });
    setIsPosting(true);
    if (taskId) setPostRequestBody({ timeWorkersIds: collaboratorsIdsArray });
    else
      setPostRequestBody({
        timeWorkersIds: collaboratorsIdsArray,
      });
  };

  return (
    <>
      <DialogContent>
        <NewCollaboratorsForm
          allPossibleCollaboratorsFiltered={allPossibleCollaboratorsFiltered}
          localCurrentCollaboratorsList={localCurrentCollaboratorsList}
          setLocalCurrentCollaboratorsList={setLocalCurrentCollaboratorsList}
          setAutocompleteIfIsEmpty={setAutocompleteIfIsEmpty}
        />
        <div className={styles.assignetWorkersTitle}>
          <b> {t(taskId ? "assigned_workers_new" : "shared_workers_new")}:</b>
        </div>
        <div className={styles.collaboratorsContainer}>
          {localCurrentCollaboratorsList.map((collaborator) => {
            let collaboratorPhoto = photos.find(
              (photo) => photo.timeWorkerId === collaborator.id
            );
            return (
              <div
                className={styles.collaboratorDialogRow}
                key={collaborator.id}
              >
                <div>
                  {collaboratorPhoto ? (
                    <img
                      src={"data:image/png;base64, " + collaboratorPhoto.photo}
                      alt="Profil"
                      className={styles.collaboratorPhoto}
                    />
                  ) : (
                    <img
                      className={styles.collaboratorPhoto}
                      src={profileIcon}
                      alt="Profil"
                    />
                  )}
                </div>
                <div
                  className={styles.collaboratorNameDialog}
                >{`${collaborator.firstName} ${collaborator.lastName}`}</div>
                <Button
                  onClick={() => {
                    const collaboratorCurrentIndex =
                      localCurrentCollaboratorsList.findIndex(
                        (collaboratorCurrent) =>
                          collaboratorCurrent.id === collaborator.id
                      );
                    let collaboratorCurrentLocal = [
                      ...localCurrentCollaboratorsList,
                    ];
                    collaboratorCurrentLocal.splice(
                      collaboratorCurrentIndex,
                      1
                    );
                    setLocalCurrentCollaboratorsList(collaboratorCurrentLocal);
                  }}
                  className={styles.removeCollaboratorButton}
                >
                  {t("delete")}
                </Button>
              </div>
            );
          })}
          {localCurrentCollaboratorsList.length === 0 && t("none")}
        </div>
        <div className={styles.assignetWorkersTitle}>
          <b> {t(taskId ? "assigned_workers_old" : "shared_workers_old")}:</b>
        </div>
        <div className={styles.collaboratorsContainer}>
          {currentAssignedCollaborators.map((collaborator) => {
            let collaboratorPhoto = photos.find(
              (photo) => photo.timeWorkerId === collaborator.id
            );
            return (
              <div
                className={styles.collaboratorDialogRow}
                key={collaborator.id}
              >
                <div>
                  {collaboratorPhoto ? (
                    <img
                      src={"data:image/png;base64, " + collaboratorPhoto.photo}
                      alt="Profil"
                      className={styles.collaboratorPhoto}
                    />
                  ) : (
                    <img
                      className={styles.collaboratorPhoto}
                      src={profileIcon}
                      alt="Profil"
                    />
                  )}
                </div>
                <div
                  className={styles.collaboratorNameDialog}
                >{`${collaborator.firstName} ${collaborator.lastName}`}</div>
              </div>
            );
          })}
          {currentAssignedCollaborators.length === 0 && t("none")}
        </div>
      </DialogContent>
      <DialogActions className="dialogActionsGlobal">
        <div className="responseErrorMessage">{responseErrorMessage}</div>
        {!isPosting ? (
          <>
            <Button onClick={() => setIsDialogOpen(false)}>
              {t("cancel")}
            </Button>
            <Button
              disabled={autocompleteIfIsEmpty}
              onClick={() => {
                confirm();
              }}
            >
              {t("confirm")}
            </Button>
          </>
        ) : (
          <div className="confirmWaiting">
            <CircularProgress size={30} />
          </div>
        )}
      </DialogActions>
    </>
  );
}
