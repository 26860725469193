import { CircularProgress } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../../../helpersComponents/Button/button";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useUpdateLastSelectedWorkers from "../../../../hooks/useUpdateLastSelectedWorkers/useUpdateLastSelectedWorkers";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";
import CheckboxStyled from "../../../helpersComponents/MaterialUi/Checkbox/checkbox";
import useUpdateSettings from "../../../../hooks/useUpdateSettings/useUpdateSettings";
import { profilesRoutesDictionary } from "./../../../ProfilesRouting/profilesDictionaries";
import { selectAuthUser } from "../../../../reducers/session";
import moment from "moment";
import styles from "./TimeInOrganizationalUnitsReportContent.module.scss";
import { truncateDecimals } from "../../../../HelpersFunctions/numbers/truncateDecimals";

function TimeInOrganizationalUnitsReportContent() {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const layoutSettingName =
    "layoutSuperiorTimeAnalysisTimeInOrganizationalUnitsReport";

  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([]);
  const [allWorkersSelected, setAllWorkersSelected] = useState<boolean>(false);
  const [allWorkers, setAllWorkers] = useState<any[]>([]);

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Year")
  );

  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Day"));

  const [selectedWorkersString, setSelectedWorkersString] = useState<
    string | undefined
  >();

  const settings = useAppSelector(selectSettings);
  const [displayTimeInDecimal, setDisplayTimeInDecimal] =
    useState<boolean>(false);

  const [columns, setColumns] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [settingsRequestBody, setSettingsRequestBody] = useState<any>(false);
  const [ifUpdateSetting, setIfUpdateSetting] = useState<boolean>(false);
  const ifSettingsUpdated = useUpdateSettings(ifUpdateSetting);

  useEffect(() => {
    if (ifSettingsUpdated) {
      setIfUpdateSetting(false);
    }
  }, [ifSettingsUpdated]);

  const successCallbackSaveSettings = useCallback((response) => {
    setIfUpdateSetting(true);
  }, []);

  const [, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${
      profilesRoutesDictionary[authUser.currentProfile?.type]
    }/preferences`,
    method: "POST",
    body: settingsRequestBody,
    setBody: setSettingsRequestBody,
    contentType: "application/json",
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
    successCallback: successCallbackSaveSettings,
  });

  useEffect(() => {
    if (settingsRequestBody) {
      fetchAgainPostData();
    }
  }, [settingsRequestBody, fetchAgainPostData]);

  const successCallback = useCallback(
    (responseData) => {
      if (responseData && responseData.resJson) {
        let data = responseData.resJson;

        // Tworzymy kolumny
        let tempColumns: any = [];
        tempColumns.push({ name: "employee", title: t("employee") });
        tempColumns.push({
          name: "evidenceNumber",
          title: t("evidence_number"),
        });

        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].timesData.length; j++) {
            let tempColumnName = `organizationalUnit_${data[i].timesData[j].organizationalUnitId}`;
            let tempColumnTitle = data[i].timesData[j].organizationalUnitName;

            let foundElement = tempColumns.find(
              (el) => el.name === tempColumnName
            );
            if (!foundElement) {
              tempColumns.push({
                name: tempColumnName,
                title: tempColumnTitle,
              });
            }
          }
        }

        // Tworzymy wiersze
        let tempRows: any = [];

        for (let i = 0; i < data.length; i++) {
          let tempRow = {};
          tempRow["id"] = i;
          tempRow["employee"] = `${data[i].firstName} ${data[i].lastName}`;
          tempRow["evidenceNumber"] = data[i].evidenceNumber;

          for (let j = 0; j < data[i].timesData.length; j++) {
            let tempColumnName = `organizationalUnit_${data[i].timesData[j].organizationalUnitId}`;
            let tempColumnValue = data[i].timesData[j].time;

            tempRow[tempColumnName] = 0;

            if (displayTimeInDecimal) {
              tempRow[tempColumnName] = truncateDecimals(
                moment.duration(tempColumnValue as any, "seconds").asHours(),
                2
              ).toString();
            } else {
              const duration = moment.duration(tempColumnValue, "seconds");
              const timeMoment = moment.utc(duration.asMilliseconds());
              tempRow[tempColumnName] = timeMoment.format("HH:mm:ss");
            }
          }

          tempRows.push(tempRow);
        }

        setColumns(tempColumns);
        setRows(tempRows);
      }
    },
    [t, displayTimeInDecimal]
  );

  const [
    timeInOrganizationalUnitsReportBody,
    setTimeInOrganizationalUnitsReportBody,
  ] = useState<false | string>(false);

  const [fetchingStateOvertimesSummary, fetchAgainOvertimesSummary] =
    useFetchOtherThanGET({
      path: "superior-time/time-in-organizational-units-report",
      method: "POST",
      contentType: "application/json",
      body: timeInOrganizationalUnitsReportBody,
      setBody: setTimeInOrganizationalUnitsReportBody,
      //disableErrorSnackbar: true,
      disableSuccessSnackbar: true,
      successCallback: successCallback,
    });

  useEffect(() => {
    let requestBodyObj = {
      dateFrom: new Date(startDate),
      dateTo: new Date(endDate),
      timeWorkersIds: allWorkersSelected
        ? []
        : selectedWorkersString
        ? selectedWorkersString.split(",").map((e) => parseInt(e))
        : [],
    };

    setTimeInOrganizationalUnitsReportBody(JSON.stringify(requestBodyObj));
  }, [startDate, endDate, allWorkersSelected, selectedWorkersString]);

  useEffect(() => {
    if (startDate && endDate && selectedWorkersString) {
      fetchAgainOvertimesSummary();
    }
  }, [startDate, endDate, selectedWorkersString, fetchAgainOvertimesSummary]);

  const [updateLastSelectedWorkers] =
    useUpdateLastSelectedWorkers(selectedWorkers);

  const confirmFilters = () => {
    if (allWorkers === undefined) return;
    let selectedWorkersLocal = [...selectedWorkers];

    let selectedWorkersStringLocal: string = "";
    selectedWorkersLocal.forEach((selectedId) => {
      selectedWorkersStringLocal =
        selectedWorkersStringLocal + selectedId.toString() + ",";
    });
    selectedWorkersStringLocal = selectedWorkersStringLocal.replace(
      /,\s*$/,
      ""
    );
    setSelectedWorkersString(selectedWorkersStringLocal);
    updateLastSelectedWorkers();
  };

  useEffect(() => {
    setDisplayTimeInDecimal(
      settings
        .PrzelozonyRCPAnalizyRaportCzasuWKomorkachWyswietlanieCzasuDziesietnie
        ?.value === "1"
    );
  }, [
    settings
      .PrzelozonyRCPAnalizyRaportCzasuWKomorkachWyswietlanieCzasuDziesietnie
      ?.value,
  ]);

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {fetchingStateOvertimesSummary.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <ButtonStyled
            onClick={() => {
              confirmFilters();
            }}
          >
            {t("get")}
          </ButtonStyled>
        )}
      </div>
      <span className="errorMessaggeSummarySuperior">
        {fetchingStateOvertimesSummary.isError && t("something_went_wrong")}
      </span>
    </>
  );

  return (
    <>
      <TopBanner pathName={t("time_in_organizational_units_report")} />
      <div className="flexAndCenter">
        <div>
          <WorkerList
            layoutSettingName={layoutSettingName}
            setSelectedWorkers={setSelectedWorkers}
            selectedWorkers={selectedWorkers}
            setAllWorkers={setAllWorkers}
            setAllWorkersSelected={setAllWorkersSelected}
          />
        </div>
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
            confirmButtonRight={confirmButtonRight}
          />
        </div>

        {rows && rows.length > 0 && (
          <>
            <div>
              <TableComponent
                rows={rows}
                columns={columns}
                layoutSettingName="layoutSuperiorTimeAnalysisTimeInOrganizationalUnitsReport"
                toolbarComponent={
                  <div className={styles.displayTimeInDecimalContainer}>
                    <div>{t("display_time_in_decimal")}</div>
                    <div>
                      <CheckboxStyled
                        checked={displayTimeInDecimal}
                        name="displayTimeInDecimal"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          let selected = e.target.checked;
                          setDisplayTimeInDecimal(selected);

                          setSettingsRequestBody(
                            JSON.stringify({
                              name: "PrzelozonyRCPAnalizyRaportCzasuWKomorkachWyswietlanieCzasuDziesietnie",
                              value: selected ? "1" : "0",
                            })
                          );
                        }}
                        ifCircle={true}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </div>
                }
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default TimeInOrganizationalUnitsReportContent;
