import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import { useTranslation } from "react-i18next";
import OperationItem from "./OperationItem";
import styles from "./Operations.module.scss";
import ButtonStyled from "../../helpersComponents/Button/button";
import toLocaleStringCustom from "../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import { useCallback, useEffect, useState } from "react";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import OperationsAutoLogoutIn from "./OperationsAutoLogoutIn";
import { selectSettings } from "../../../reducers/settings";

const Operations = () => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const { confirm } = useConfirm();
  const settings = useAppSelector(selectSettings);
  const [autoLogoutEnabled, setAutoLogoutEnabled] = useState<boolean>(false);
  const [isFinishOperationButtonHovered, setIsFinishOperationButtonHovered] =
    useState(false);

  const [operationsTypes, fetchingStateOperationsTypes] = useFetchAndSetGET({
    path: `worker-time/${authUser.currentProfile?.subjectId}/operations-types`,
    startFetchOnInitial: true,
  });

  const [lastOperationInfo, , fetchAgainLastOperationInfo] = useFetchAndSetGET({
    path: `worker-time/${authUser.currentProfile?.subjectId}/last-operation-info`,
    startFetchOnInitial: true,
  });

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);

  const successCallback = useCallback(() => {
    fetchAgainLastOperationInfo();
    setAutoLogoutEnabled(true);
  }, [fetchAgainLastOperationInfo]);

  const [, fetchAgainAddOperation] = useFetchOtherThanGET({
    path: `worker-time/${authUser.currentProfile?.subjectId}/operations`,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    disableErrorSnackbar: false,
    disableSuccessSnackbar: false,
    successCallback: successCallback,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainAddOperation();
    }
  }, [bodyRequest, fetchAgainAddOperation]);

  const autoLogoutTime = settings.WebOperacjeAutowylogowanieCzas?.value
    ? settings.WebOperacjeAutowylogowanieCzas?.value
    : 40;

  return (
    <div>
      <TopBanner pathName={t("operations")} />
      <div className="flexAndCenter">
        <div className={styles["flex-container"]}>
          <div className={styles["flex-item"]}>
            {!fetchingStateOperationsTypes.isFetching && (
              <div className={styles.buttonsContainer}>
                {operationsTypes?.map((el, index) => {
                  return (
                    <OperationItem
                      key={index}
                      {...el}
                      setBodyRequest={setBodyRequest}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <div className={styles["flex-item"]}>
            <div className={styles["operation-information-container"]}>
              <div className={styles["operation-information-grid-container"]}>
                <div className={styles["operation-information-grid-item"]}>
                  {t("last_operation")}:
                </div>
                <div className={styles["operation-information-grid-item"]}>
                  <span className={styles["operation-info-content"]}>
                    {lastOperationInfo?.name ? lastOperationInfo?.name : "BRAK"}
                  </span>
                </div>
                <div className={styles["operation-information-grid-item"]}>
                  {t("start_date")}:
                </div>
                <div className={styles["operation-information-grid-item"]}>
                  <span className={styles["operation-info-content"]}>
                    {lastOperationInfo?.startDate
                      ? toLocaleStringCustom({
                          date: lastOperationInfo?.startDate,
                          ifShowHours: true,
                          t,
                        })
                      : "BRAK"}
                  </span>
                </div>
                <div className={styles["operation-information-grid-item"]}>
                  {t("end_date")}:
                </div>
                <div className={styles["operation-information-grid-item"]}>
                  <span className={styles["operation-info-content"]}>
                    {lastOperationInfo?.endDate
                      ? toLocaleStringCustom({
                          date: lastOperationInfo?.endDate,
                          ifShowHours: true,
                          t,
                        })
                      : "BRAK"}
                  </span>
                </div>
              </div>

              <ButtonStyled
                style={{
                  marginTop: "24px",
                  backgroundColor: "red",
                  fontSize: "24px",
                  padding: "20px",
                  opacity: isFinishOperationButtonHovered ? 0.5 : 1,
                }}
                onMouseEnter={() => setIsFinishOperationButtonHovered(true)}
                onMouseLeave={() => setIsFinishOperationButtonHovered(false)}
                onClick={async () => {
                  if (
                    !lastOperationInfo?.startDate &&
                    !lastOperationInfo?.endDate
                  ) {
                    await confirm({
                      text: `${t("no_operation_to_finish")}`,
                      buttons: [
                        { buttonName: t("ok").toUpperCase(), buttonId: 0 },
                      ],
                      typeDialog: "info",
                    });
                  } else if (
                    lastOperationInfo?.startDate &&
                    !lastOperationInfo?.endDate
                  ) {
                    let selectedbuttonId = await confirm({
                      text: `${t("are_you_sure_you_want_to_finish_operation")}`,
                      buttons: [
                        { buttonName: t("yes"), buttonId: 0 },
                        {
                          buttonName: t("cancel"),
                          buttonId: 1,
                          backgroundColor: "red",
                        },
                      ],
                    });

                    if ((await selectedbuttonId) === 0) {
                      setBodyRequest(
                        JSON.stringify({
                          eventTypeId: null,
                          type: 1,
                        })
                      );
                    }
                  } else {
                    await confirm({
                      text: `${t("operation_is_finished_yet")}`,
                      buttons: [
                        { buttonName: t("ok").toUpperCase(), buttonId: 0 },
                      ],
                      typeDialog: "info",
                    });
                  }
                }}
              >
                {t("finish_operation")}
              </ButtonStyled>

              {autoLogoutEnabled && (
                <OperationsAutoLogoutIn
                  timeToLogout={autoLogoutTime}
                  setAutoLogoutEnabled={setAutoLogoutEnabled}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Operations;
