import SupportContent from "./SupportContent";

const SupportReception = () => {
  return (
    <SupportContent
      pathApiLetIn="reception/employee-list-visit"
      pathApiReject="reception/lists-of-employees/change-let-in"
      pathApiListsOfEmployees="reception/lists-of-employees"
    />
  );
};

export default SupportReception;
