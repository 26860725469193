import React from "react";
import OperationsLogsContent from "./operationsLogsContent";

function OperationsLogs() {
  return (
    <div>
      <OperationsLogsContent />
    </div>
  );
}

export default OperationsLogs;
