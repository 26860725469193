import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useRefreshData from "../../../../hooks/useRefreshData/useRefreshData";
import TableComponentMemo from "../../../helpersComponents/TableComponent/tableComponent";
import styles from "../styles.module.scss";

const LocationEmployees = () => {
  const { t } = useTranslation();

  const columns = [
    { name: "acWorker", title: t("employee") },
    { name: "acWorkerRegistrationNumber", title: t("evidence_number") },
    { name: "location", title: t("location") },
    { name: "enterTime", title: t("time"), type: "date_hours" },
  ];

  const locationOfEmployeesResponseCallback = useCallback(
    (responseData: ACWorkerSector[]) => {
      const modified = responseData?.map(
        (row: ACWorkerSector, index: number) => {
          return {
            ...row,
            id: index,
          };
        }
      );
      return modified;
    },
    []
  );

  const [
    locationOfEmployees,
    fetchingStateLocationOfEmployees,
    fetchAgainLocationOfEmployees,
  ] = useFetchAndSetGET<ACWorkerSector[]>({
    path: `superior-ac/ac-worker-sectors`,
    modifyResponseCallback: locationOfEmployeesResponseCallback,
  });

  const { refreshingTime, handleChangeRefreshingTime } = useRefreshData(
    fetchAgainLocationOfEmployees
  );

  return (
    <>
      <div className={styles.contentTitleContainerBlockPaper}>
        <div className={styles.headerContainer}>
          {t("recent_locations_employees")}
        </div>
      </div>
      <div className={styles.contentValueContainerBlockPaper}>
        <TableComponentMemo
          columns={columns}
          rows={locationOfEmployees}
          layoutSettingName="layoutACSuperiorLocationOfEmployees"
          fetchingState={{
            fetchAgain: fetchAgainLocationOfEmployees,
            isError: fetchingStateLocationOfEmployees.isError,
            isFetching: fetchingStateLocationOfEmployees.isFetching,
          }}
          backgroundColor="white"
          columnsWidth={[
            {
              columnName: "date",
              width: 200,
            },
          ]}
          refreshingButton={{
            ifShow: true,
            refreshingTime: refreshingTime?.value,
            handleChangeRefreshingTime,
          }}
          filteringRowDefaultHidden={true}
        />
      </div>
    </>
  );
};

export default LocationEmployees;
