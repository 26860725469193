import { faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { GridColDef } from "@mui/x-data-grid";
import { groupBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DaysOfWeek } from "../../../../../../Constants/daysOfWeek";
import getTimeFromDate from "../../../../../../HelpersFunctions/dateAndTime/getTimeFromDate";
import toLocaleStringCustom from "../../../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import useConfirm from "../../../../../../hooks/useConfirm/useConfirm";
import { DatePickerFormik } from "../../../../../helpersComponents/FormikInputs";
import CheckboxWithLabelFormik from "../../../../../helpersComponents/FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import StyledDataGrid from "../../../../../helpersComponents/MaterialUi/DataGrid/StyledDataGrid";
import {
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../../../../helpersComponents/PopupHelpers";
import PopupPermissionItem from "./PermissionItem/PopupPermissionItem";
import styles from "./PermissionsForm.module.scss";

interface IFormattedGroupPermissions {
  id: number;
  roomId: number;
  roomName: string;
  permissions: { dayName: string; time: string }[];
}

interface IProps {
  formFields: FormFieldsACWorker;
  formikValues: {
    [x: string]:
      | string
      | number
      | boolean
      | number[]
      | null
      | any[]
      | Date
      | any;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const PermissionsForm: React.FC<IProps> = ({
  formFields,
  formikValues,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const daysOfWeek = DaysOfWeek(t);

  const {
    permissions,
    isVIP,
    isZJW,
    accessDeniedOnHolidays,
    startPermissionDateCheck,
    startPermissionDate,
    permissionExpirationDateCheck,
    permissionExpirationDate,
    groups,
  } = formFields;

  const columnsWorkerPermissions: GridColDef[] = [
    {
      field: "roomName",
      headerName: t("room"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.roomName}</span>
      ),
    },
    {
      field: "dayName",
      headerName: t("day"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {daysOfWeek.find((dayItem) => dayItem.value === params.row.day)?.name}
        </span>
      ),
    },
    {
      field: "entryFrom",
      headerName: t("from"),
      flex: 0.5,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {new Date(params.row.entryFrom).toLocaleString(
            t("scheduler_language"),
            {
              hour: "2-digit",
              minute: "2-digit",
            }
          )}
        </span>
      ),
    },
    {
      field: "entryTo",
      headerName: t("to"),
      flex: 0.5,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {new Date(params.row.entryTo).toLocaleString(
            t("scheduler_language"),
            {
              hour: "2-digit",
              minute: "2-digit",
            }
          )}
        </span>
      ),
    },
    {
      field: "rangeFrom",
      headerName: t("is_valid_from"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {toLocaleStringCustom({
            date: params.row.rangeFrom,
            t,
            ifShowHours: false,
          })}
        </span>
      ),
    },
    {
      field: "rangeTo",
      headerName: t("is_valid_to"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {toLocaleStringCustom({
            date: params.row.rangeTo,
            t,
            ifShowHours: false,
          })}
        </span>
      ),
    },
  ];

  const [groupPermissions] = useState(
    (formikValues[`${groups.name}`] as Group[]).map(
      (group) => group.permissions
    )[0] ?? []
  );
  const [formattedGroupPermissions, setFormattedGroupPermissions] = useState<
    IFormattedGroupPermissions[]
  >([]);

  useEffect(() => {
    if (groupPermissions && groupPermissions.length > 0) {
      const grouped = groupBy(groupPermissions, (group) => group.roomId);

      let result: IFormattedGroupPermissions[] = [];
      Object.keys(grouped).forEach(function (key) {
        result.push({
          id: grouped[key][0].id,
          roomId: grouped[key][0].roomId,
          roomName: grouped[key][0].roomName,
          permissions: grouped[key].map((gr) => ({
            dayName: `<span style='color:black'>${gr.dayName.trim()} </span>`,
            time: `<span style='color:green'>[${getTimeFromDate(
              new Date(gr.entryFrom)
            )} - ${getTimeFromDate(new Date(gr.entryTo))}]; <span>`,
          })),
        });
      });
      setFormattedGroupPermissions(result);
    }
  }, [groupPermissions]);

  const columnsGroupPermissions: GridColDef[] = [
    {
      field: "roomName",
      headerName: t("room"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.roomName}</span>
      ),
    },
    {
      field: "permissions",
      headerName: t("permissions"),
      flex: 2,
      renderCell: (params) => {
        let content = "";
        params.row.permissions.forEach(
          (perm: { dayName: string; time: string }) => {
            content += perm.dayName + perm.time;
          }
        );
        return (
          <span className={styles.columnSpan}>
            <span dangerouslySetInnerHTML={{ __html: content }}></span>
          </span>
        );
      },
    },
  ];

  const [permissionItemPopup, setPermissionItemPopup] = useState<{
    isOpen: boolean;
    permissionItem: Permission | null;
  }>({
    isOpen: false,
    permissionItem: null,
  });
  const closePermissionItemPoup = useCallback(() => {
    setPermissionItemPopup({
      isOpen: false,
      permissionItem: null,
    });
  }, []);

  const addPermissionHandler = () => {
    setPermissionItemPopup({
      isOpen: true,
      permissionItem: null,
    });
  };

  const [permissionsIdsToRemove, setPermissionsIdsToRemove] = useState<
    number[]
  >([]);
  const { confirm } = useConfirm();
  const permissionRemoveHandler = async () => {
    let confirmResponse = await confirm({
      text: `${t("are_you_sure_you_want_to_remove_selected_permissions")}?`,
      buttons: [
        { buttonName: t("yes"), buttonId: 0 },
        { buttonName: t("cancel"), buttonId: 1 },
      ],
    });
    if (confirmResponse === 0) {
      const permissionsList = formikValues[
        `${permissions.name}`
      ] as Permission[];
      const filtered = permissionsList.filter(
        (permission) => !permissionsIdsToRemove.includes(permission.id)
      );
      setFieldValue(permissions.name, filtered);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.gridEmployeePermissions}>
          <div className={styles.titleContainer}>
            <p className={styles.gridTitle}>{t("permission_of_employee")}</p>
            <div className={styles.buttonsContainer}>
              <div>
                <Tooltip title={t("add")!}>
                  <div
                    className={styles.permissionButton}
                    onClick={addPermissionHandler}
                  >
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      size="lg"
                      color="green"
                    />
                  </div>
                </Tooltip>
              </div>

              <div>
                {permissionsIdsToRemove.length > 0 && (
                  <Tooltip title={t("delete")!}>
                    <div
                      className={styles.permissionButton}
                      onClick={permissionRemoveHandler}
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        size="lg"
                        color="red"
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          <StyledDataGrid
            rows={formikValues[`${permissions.name}`] as Permission[]}
            columns={columnsWorkerPermissions}
            defaultPageSize={10}
            rowsPerPageOptions={[10]}
            density="compact"
            quickFilter
            checkboxSelection={true}
            onSelectionModelChange={(selected) => {
              setPermissionsIdsToRemove(selected as number[]);
            }}
          />
        </div>
      </div>

      <div className={styles.rightContainer}>
        <div className={styles.gridGroupPermissions}>
          <p className={styles.gridTitle}>{t("permission_of_groups")}</p>
          <StyledDataGrid
            columns={columnsGroupPermissions}
            rows={formattedGroupPermissions}
            defaultPageSize={10}
            rowsPerPageOptions={[10]}
            density="compact"
            getRowId={(row: Permission) => row.id}
          />
        </div>

        <div className={styles.formContainer}>
          {/*  */}
          <div className={styles.checkboxContainer}>
            <div>
              <CheckboxWithLabelFormik
                name={t(isVIP.name)}
                ifCircle={true}
                label={
                  <span style={{ fontSize: "0.9rem" }}>{t(isVIP.label)}</span>
                }
              />
            </div>

            <div>
              <CheckboxWithLabelFormik
                name={t(isZJW.name)}
                ifCircle={true}
                label={
                  <span style={{ fontSize: "0.9rem" }}>{t(isZJW.label)}</span>
                }
              />
            </div>

            <div>
              <CheckboxWithLabelFormik
                name={accessDeniedOnHolidays.name}
                ifCircle={true}
                label={
                  <span style={{ fontSize: "0.9rem" }}>
                    {t(accessDeniedOnHolidays.label)}
                  </span>
                }
              />
            </div>
          </div>

          {/*  */}
          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={220}>
                <CheckboxWithLabelFormik
                  name={startPermissionDateCheck.name}
                  ifCircle={true}
                  label={
                    <span style={{ fontSize: "0.9rem" }}>
                      {t(startPermissionDateCheck.label)}
                    </span>
                  }
                />
              </RowTitle>
              <RowInput>
                {formikValues[`${startPermissionDateCheck.name}`] && (
                  <DatePickerFormik
                    label={t(startPermissionDate.label)}
                    name={startPermissionDate.name}
                    view={["year", "month", "date"]}
                    format="dd-MM-yyyy"
                    required={true}
                  />
                )}
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          {/*  */}
          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={220}>
                <CheckboxWithLabelFormik
                  name={permissionExpirationDateCheck.name}
                  ifCircle={true}
                  label={
                    <span style={{ fontSize: "0.9rem" }}>
                      {t(permissionExpirationDateCheck.label)}
                    </span>
                  }
                />
              </RowTitle>
              <RowInput>
                {formikValues[`${permissionExpirationDateCheck.name}`] && (
                  <DatePickerFormik
                    label={t(permissionExpirationDate.label)}
                    name={permissionExpirationDate.name}
                    view={["year", "month", "date"]}
                    format="dd-MM-yyyy"
                    required={true}
                  />
                )}
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>
        </div>
      </div>

      {permissionItemPopup.isOpen && (
        <PopupPermissionItem
          isOpen={permissionItemPopup.isOpen}
          permissionItem={permissionItemPopup.permissionItem}
          closePopup={closePermissionItemPoup}
          formikValues={formikValues}
          setFieldValue={setFieldValue}
          permissionsFormField={permissions}
        />
      )}
    </div>
  );
};

export default PermissionsForm;
