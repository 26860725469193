import { useCallback, useEffect, useReducer } from "react";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";

interface ReducerState {
  cardTag: null | string;
  isError: boolean;
  isListeningForTagCard: boolean;
}

type ReturnedValue = [ReducerState, (type: "START_LISTENING" | "STOP") => void];

type Action =
  | { type: "START_LISTENING" }
  | { type: "ERROR" }
  | { type: "STOP" }
  | { type: "DONE"; payload: string };

const initialState: ReducerState = {
  cardTag: null,
  isListeningForTagCard: false,
  isError: false,
};

function reducer(state: ReducerState, action: Action) {
  switch (action.type) {
    case "START_LISTENING":
      return {
        cardTag: null,
        isListeningForTagCard: true,
        isError: false,
      };
    case "ERROR":
      return {
        cardTag: null,
        isListeningForTagCard: false,
        isError: true,
      };
    case "DONE":
      return {
        cardTag: action.payload,
        isListeningForTagCard: false,
        isError: false,
      };
    case "STOP":
      return {
        cardTag: null,
        isListeningForTagCard: false,
        isError: false,
      };
  }
}

interface IProps {
  startOnInitail?: boolean;
}

export default function useGetCardTag({
  startOnInitail,
}: IProps): ReturnedValue {
  const [{ cardTag, isListeningForTagCard, isError }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const startListetning = useCallback(
    (type: "START_LISTENING" | "STOP") => {
      dispatch({ type });
    },
    [dispatch]
  );

  useEffect(() => {
    if (startOnInitail) {
      dispatch({ type: "START_LISTENING" });
    }
  }, [startOnInitail]);

  const settings = useAppSelector(selectSettings);
  const pathDownloadReader = settings["AdresCzytnikaPobierania"]?.value;

  useEffect(() => {
    let counter = 1;
    let timeout: ReturnType<typeof setTimeout>;
    let abortController: AbortController;
    const getCardNumber = async () => {
      let myHeaders = new Headers();
      abortController = new AbortController();
      myHeaders.append("Authorization", "Basic YWRtaW46YWRtaW4=");
      try {
        const response = await fetch(pathDownloadReader, {
          method: "GET",
          headers: myHeaders,
          signal: abortController.signal,
        });
        const json = await response.json();

        if (abortController.signal.aborted) {
          return;
        }
        if (json.read_time <= 3) {
          if (abortController.signal.aborted) {
            return;
          }

          if (json.tag !== "") {
            dispatch({ type: "DONE", payload: json?.tag });
          } else {
            counter += 1;
            if (counter <= 20) {
              timeout = setTimeout(getCardNumber, 500);
            } else {
              dispatch({ type: "ERROR" });
            }
          }
        } else {
          if (abortController.signal.aborted) {
            return;
          }

          counter += 1;
          if (counter <= 20) {
            timeout = setTimeout(getCardNumber, 500);
          } else {
            dispatch({ type: "ERROR" });
          }
        }
      } catch (e) {
        if (abortController.signal.aborted) {
          return;
        }
        dispatch({ type: "ERROR" });
      }
    };

    if (isListeningForTagCard) {
      getCardNumber();
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (abortController) {
        abortController.abort();
      }
    };
  }, [isListeningForTagCard, pathDownloadReader]);

  return [{ cardTag, isError, isListeningForTagCard }, startListetning];
}
