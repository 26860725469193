import React from 'react';
import DelegationsCitiesContent from "./delegationsCitiesContent"


function DelegationsCities() {
    return (
        <div>
            <DelegationsCitiesContent/>
        </div>
    );
}

export default DelegationsCities;
