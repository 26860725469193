const getInitialValues: () => PopupEntryUnauthorizedFormValues = () => {
  return {
    guestsArray: [
      {
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        email: "",
        idNumber: "",
        cardNumber: "",
        isTrainingNotRequired: false,
        trainingExpiryDate: null,
        bh3: false,
      },
    ],
    companyName: "",
    permissionZone: null,
    comments: "",
    typeOfVisit: null,
    registrationNumber: "",
  };
};

export default getInitialValues;
