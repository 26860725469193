import { useTranslation } from "react-i18next";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../../helpersComponents/RowDetailsHelpers";

const RowDetailsBuilding = ({ row }) => {
  const { t } = useTranslation();
  return (
    <div>
      {row.floors.map((floor, index) => {
        return (
          <div
            style={{
              marginTop: "20px",
              backgroundColor: index % 2 === 0 ? "" : "white",
            }}
          >
            <RowDetailsContainer>
              <RowDetailsRow>
                <RowDetailsTitleValue>
                  <RowDetailsTitle>{t("floor_name")}:</RowDetailsTitle>
                  <RowDetailsValue>{floor.name}</RowDetailsValue>
                </RowDetailsTitleValue>

                <RowDetailsTitleValue>
                  <RowDetailsTitle>{t("floor_level")}:</RowDetailsTitle>
                  <RowDetailsValue>{floor.number}</RowDetailsValue>
                </RowDetailsTitleValue>

                <RowDetailsTitleValue>
                  <RowDetailsTitle>{t("is_default")}:</RowDetailsTitle>
                  <RowDetailsValue>
                    {floor.isDefault ? t("yes") : t("no")}
                  </RowDetailsValue>
                </RowDetailsTitleValue>

                <RowDetailsTitleValue>
                  <RowDetailsTitle>{t("created")}:</RowDetailsTitle>
                  <RowDetailsValue>{floor.createdBy}</RowDetailsValue>
                </RowDetailsTitleValue>

                <RowDetailsTitleValue>
                  <RowDetailsTitle>{t("edited")}:</RowDetailsTitle>
                  <RowDetailsValue>
                    {floor.editedBy ? floor.editedBy : "-"}
                  </RowDetailsValue>
                </RowDetailsTitleValue>
              </RowDetailsRow>
            </RowDetailsContainer>
          </div>
        );
      })}
    </div>
  );
};

export default RowDetailsBuilding;
