import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useConfirm from "../../../../../../hooks/useConfirm/useConfirm";
import TransferList from "../../../../../helpersComponents/MaterialUi/TransferList";
import styles from "./CompaniesForm.module.scss";

interface Props {
  formFields: FormFieldsACWorker;
  companiesList: Company[];
  formikValues: {
    [x: string]:
      | string
      | number
      | boolean
      | number[]
      | null
      | any[]
      | Date
      | any;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  error?: string;
}

const CompaniesForm: React.FC<Props> = ({
  formFields,
  companiesList,
  formikValues,
  setFieldValue,
  error,
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();

  const { companies, groups } = formFields;
  const [companiesToChoice, setCompaniesToChoice] = useState<Company[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>(
    formikValues[`${companies.name}`] as Company[]
  );

  useEffect(() => {
    const array = companiesList.filter(
      (company) => !selectedCompanies.includes(company)
    );
    setCompaniesToChoice(array);
  }, [companiesList, selectedCompanies]);

  const extraRightFunction = (company: Company[]) => {
    const newCompanies = formikValues[`${companies.name}`] as Company[];
    newCompanies.push(...company);
    setFieldValue(companies.name, newCompanies);

    return true;
  };

  const extraLeftFunctions = (company: Company[]) => {
    const newCompanies = selectedCompanies.filter(
      (companyItem) => companyItem.id !== company[0].id
    );

    //#region VALIDATION WITH GROUPS
    const selectedGroups = formikValues[`${groups.name}`] as Group[];
    const selectedComapniesIds = company.map((company) => company.id);
    let errorText = "";
    const isError = selectedGroups.filter((group) => {
      if (selectedComapniesIds.includes(group.companyId)) {
        const companyName = companiesList.find(
          (company) => company.id === group.companyId
        )?.name;
        errorText += `${companyName} -> ${group.name}, `;
      }
      return errorText;
    });

    if (isError.length > 0) {
      confirm({
        text: `${t(
          "cannot_remove_company_assignments"
        )}: (${errorText.substring(0, errorText.length - 2)})!`,
        buttons: [{ buttonName: t("OK"), buttonId: 0 }],
        typeDialog: "warning",
      });
      return false;
    }
    //#endregion

    setFieldValue(companies.name, newCompanies);

    return true;
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div>{`(${t("changing_the_assignment_to_companies_info")})`}</div>
        {error && (
          <div className={styles.error}>
            {t("employee_must_belong_to_at_least_one_company")}
          </div>
        )}
      </div>

      <div className={styles.transferList}>
        <TransferList
          leftArray={companiesToChoice}
          extraLeftFunctions={extraLeftFunctions}
          setLeftArray={setCompaniesToChoice}
          rightArray={formikValues[`${companies.name}`] as Company[]}
          extraRightFunction={extraRightFunction}
          setRightArray={setSelectedCompanies}
          displayPropertyFromObject={"name"}
        />
      </div>
    </div>
  );
};

export default CompaniesForm;
