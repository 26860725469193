export function totalHoursDiff(date1: Date, date2: Date): number {
  var diff = (date2.getTime() - date1.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(diff);
}

export function totalMinutesDiff(date1: Date, date2: Date): number {
  var diff = (date2.getTime() - date1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}
