import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import addDaysToDate from "../../../HelpersFunctions/dateAndTime/addDaysToDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import { DialogContentStyled, Row, RowInput, RowTitle } from "../PopupHelpers";
import * as Yup from "yup";
import { DatePickerFormik, TextFieldFormik } from "../FormikInputs";
import CheckboxWithLabelFormik from "../FormikInputs/CheckboxWithLabel/CheckboxWithLabel";

const PopupContentNewGuest = ({
  rowId,
  onSubmit,
  pathApiGuests,
}: {
  rowId?: number;
  onSubmit: (values: IGuestAdd) => void;
  pathApiGuests: string;
}) => {
  const { t } = useTranslation();

  const [fetchedRow, fetchingStateFetchedRow, fetchAgainFetchedRow] =
    useFetchAndSetGET<IGuest>({
      path: `${pathApiGuests}/${rowId}`,
      startFetchOnInitial: rowId ? true : false,
    });

  const yesterday = addDaysToDate(new Date(), -1);

  return (
    <div>
      <LoadingWrapper
        isLodadingProgress={fetchingStateFetchedRow.isFetching}
        isError={fetchingStateFetchedRow.isError}
        setIfFetchAgain={() => {
          if (fetchingStateFetchedRow.isError) {
            fetchAgainFetchedRow();
          }
        }}
      >
        {(fetchedRow || rowId === undefined) && (
          <DialogContentStyled>
            <Formik
              initialValues={{
                firstName: fetchedRow?.firstName ? fetchedRow.firstName : "",
                lastName: fetchedRow?.lastName ? fetchedRow.lastName : "",
                isSelectedEmail: fetchedRow?.email !== null ? true : false,
                email: fetchedRow?.email ? fetchedRow.email : "",
                isSelectedDateOfBirth:
                  fetchedRow?.dateOfBirth !== null ? true : false,
                dateOfBirth: fetchedRow?.dateOfBirth
                  ? new Date(fetchedRow.dateOfBirth)
                  : null,
              }}
              onSubmit={(values) => {
                onSubmit(values);
              }}
              validationSchema={Yup.object({
                firstName: Yup.string()
                  .nullable()
                  .required(t("field_required")),
                lastName: Yup.string().nullable().required(t("field_required")),
                email: Yup.string()
                  .nullable()
                  .when("isSelectedEmail", {
                    is: true,
                    then: Yup.string()
                      .nullable()
                      .email(t("invalid_email"))
                      .required(t("field_required")),
                    otherwise: Yup.string().nullable(),
                  }),
                dateOfBirth: Yup.date()
                  .nullable()
                  .when("isSelectedDateOfBirth", {
                    is: true,
                    then: Yup.date()
                      .typeError(t("invalid_date"))
                      .max(new Date(), t("date_of_birth_cannot_future")),
                  }),
              })}
            >
              {({ values }) => (
                <Form noValidate id="submitForm">
                  <Row>
                    <RowTitle>{t("first_name")}:</RowTitle>
                    <RowInput>
                      <TextFieldFormik
                        label={t("first_name")}
                        name="firstName"
                        type="text"
                        ifToUpperCaseShipsAndPorts={true}
                        required={true}
                      />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle>{t("last_name")}:</RowTitle>
                    <RowInput>
                      <TextFieldFormik
                        label={t("last_name")}
                        name="lastName"
                        ifToUpperCaseShipsAndPorts={true}
                        type="text"
                        required={true}
                      />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle>
                      <CheckboxWithLabelFormik
                        name="isSelectedEmail"
                        label={<span>{t("email")}</span>}
                        ifCircle={true}
                        onChangeExtra={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (
                            e.target.checked === false &&
                            values.isSelectedDateOfBirth === false
                          ) {
                            values.isSelectedDateOfBirth = true;
                          }
                        }}
                      />
                    </RowTitle>
                    <RowInput>
                      {values.isSelectedEmail && (
                        <TextFieldFormik
                          label={t("email")}
                          name="email"
                          type="text"
                          ifToUpperCaseShipsAndPorts={true}
                          required={true}
                        />
                      )}
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle>
                      <CheckboxWithLabelFormik
                        name="isSelectedDateOfBirth"
                        label={<span>{t("date_of_birth")}</span>}
                        ifCircle={true}
                        onChangeExtra={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (
                            e.target.checked === false &&
                            values.isSelectedEmail === false
                          ) {
                            values.isSelectedEmail = true;
                          }
                        }}
                      />
                    </RowTitle>
                    <RowInput>
                      {values.isSelectedDateOfBirth && (
                        <DatePickerFormik
                          label={t("date_of_birth")}
                          name="dateOfBirth"
                          maxDate={yesterday}
                          view={["year", "month", "date"]}
                          format="dd-MM-yyyy"
                          required={true}
                        />
                      )}
                    </RowInput>
                  </Row>
                </Form>
              )}
            </Formik>
          </DialogContentStyled>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default PopupContentNewGuest;
