import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useCachedFetch from "../../../../hooks/useCachedFetch/cachedFetch";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import DashboardItem from "./DashboardItem";
import styles from "./dashboardStyles.module.scss";

const Dashboard = () => {
  const { t } = useTranslation();

  const [ifCheckForUpdateCache, setIfCheckForUpdateCache] =
    useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const workers = useCachedFetch(
    "acWorkersSuperiorAc",
    ifCheckForUpdateCache,
    3600,
    false
  );

  useEffect(() => {
    if (workers === "error") {
      setIsError(true);
      setIfCheckForUpdateCache(false);
      return;
    }

    if (workers === null) {
      return;
    }
  }, [workers]);

  const [summaryReaders, setSummaryReaders] = useState({
    all: 0,
    detected: 0,
    undetected: 0,
  });

  const [readers, fetchingStateReaders, fetchAgainReaders] = useFetchAndSetGET<
    ACReader[]
  >({
    path: "superior-ac/readers",
  });

  useEffect(() => {
    if (readers) {
      let all = readers.length;
      let detected = 0;
      let undetected = 0;

      readers.forEach((reader) => {
        reader.isDetected ? detected++ : undetected++;
      });

      setSummaryReaders({
        all: all,
        detected: detected,
        undetected: undetected,
      });
    }
  }, [readers]);

  return (
    <div className={styles.dashboard}>
      <LoadingWrapper
        isLodadingProgress={workers === null}
        isError={isError}
        setIfFetchAgain={() => {
          if (isError) {
            setIfCheckForUpdateCache(true);
          }
        }}
      >
        {workers !== "error" && (
          <DashboardItem
            title={t("employees")}
            content={[
              {
                value: workers?.length,
                description: t("all"),
              },
              {
                value: workers?.filter((worker: ACWorker) => worker.isActive)
                  .length,
                description: t("active_3"),
              },
              {
                value: workers?.filter((worker: ACWorker) => !worker.isActive)
                  .length,
                description: t("inactive_3"),
              },
            ]}
          />
        )}
      </LoadingWrapper>

      <LoadingWrapper
        isLodadingProgress={fetchingStateReaders.isFetching}
        isError={fetchingStateReaders.isError}
        setIfFetchAgain={fetchAgainReaders}
      >
        <DashboardItem
          title={t("readers")}
          content={[
            {
              value: summaryReaders.all,
              description: t("all_readers"),
            },
            {
              value: summaryReaders.detected,
              description: t("detected"),
            },
            {
              value: summaryReaders.undetected,
              description: t("undetected"),
            },
          ]}
        />
      </LoadingWrapper>
    </div>
  );
};

export default Dashboard;
