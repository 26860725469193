import TextFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import TwoColumnsRowsContainer from "../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import Row from "../../../helpersComponents/PopupHelpers/row";
import RowTitle from "../../../helpersComponents/PopupHelpers/rowTitle";
import RowInput from "../../../helpersComponents/PopupHelpers/rowInput";
import UploadFileFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/UploadFileField/uploadFileField";
import SelectFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/SelectField/selectFIeld";
import { Tab, Tabs, Tooltip } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import BuildingFloors from "./BuildingFloors";
import { colorNameDictionary } from "./../../../ProfilesRouting/profilesDictionaries";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";
import { selectAuthUser } from "../../../../reducers/session";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import erro400getTranslatedErrorString from "../../../../HelpersFunctions/erro400getTranslatedErrorString";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import styles from "./addEditBuildingPopupContent.module.scss";
import PositionOnMapViewer from "../../../PositionOnMapViewer/PositionOnMapViewer";

const AddEditBuildingPopupContent: React.FC<any> = ({
  rowId,
  control,
  register,
  errors,
  fields,
  append,
  remove,
  setValue,
  getValues,
  setErrorMessage,
  successCallback,
  handleSubmit,
  setIsFetching,
  watch,
}) => {
  const { t } = useTranslation();
  const authUserType = useAppSelector(selectAuthUser).currentProfile?.type;
  const settings = useAppSelector(selectSettings);
  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const [valueTab, setValueTab] = useState<number>(0);
  const [buildingsGroupsLoaded, setBuildingsGroupsLoaded] = useState(false);
  const [buildingLoaded, setBuildingLoaded] = useState(false);

  /*const buildingsGroupsModifyResponseCallback = useCallback((responseData) => {
    setBuildingsGroupsLoaded(true);
    return responseData;
  }, []);*/

  const [
    buildingsGroups,
    fetchingStateBuildingsGroups,
    fetchAgainBuildingsGroups,
  ] = useFetchAndSetGET({
    path: `sms-administrator/buildings-groups`,
    startFetchOnInitial: true,
    //modifyResponseCallback: buildingsGroupsModifyResponseCallback,
  });

  /*const buildingModifyResponseCallback = useCallback((responseData) => {
    setBuildingLoaded(true);
    return responseData;
  }, []);*/

  const [building, fetchingStateBuilding, fetchAgainBuilding] =
    useFetchAndSetGET({
      path: `sms-administrator/buildings/${rowId}`,
      startFetchOnInitial: false,
      //modifyResponseCallback: buildingModifyResponseCallback,
    });

  useEffect(() => {
    if (rowId) {
      fetchAgainBuilding();
    }
  }, [rowId, fetchAgainBuilding]);

  useEffect(() => {
    if (
      fetchingStateBuildingsGroups.isFetching &&
      fetchingStateBuildingsGroups.response?.status === 200
    ) {
      setBuildingsGroupsLoaded(true);
    }

    if (
      !fetchingStateBuilding.isFetching &&
      fetchingStateBuilding.response?.status === 200
    ) {
      setBuildingLoaded(true);
    }
  }, [
    fetchingStateBuilding.isFetching,
    fetchingStateBuilding.response?.status,
    fetchingStateBuildingsGroups.isFetching,
    fetchingStateBuildingsGroups.response?.status,
  ]);

  /*useEffect(() => {
    if (!fetchingStateBuilding.isFetching && building) {
      //setValue("buildingGroupName", building.name);
      //setValue("buildingGroupOrder", building.order);
    }
  }, [building, fetchingStateBuilding.isFetching, setValue]);*/

  const successCallbackAndClose = useCallback(() => {
    successCallback();
  }, [successCallback]);

  const errorCallback = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setErrorMessage(error);
      }
    },
    [t, setErrorMessage]
  );

  const [fetchingStateAddEditBuilding, fetchAgainAddEditBuilding] =
    useFetchOtherThanGET({
      path: `sms-administrator/buildings${rowId ? "/" + rowId : ""}`,
      method: rowId ? "PUT" : "POST",
      body: bodyRequest,
      setBody: setBodyRequest,
      successCallback: successCallbackAndClose,
      errorCallback,
      disableErrorSnackbar: true,
    });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainAddEditBuilding();
    }
  }, [bodyRequest, fetchAgainAddEditBuilding]);

  const TabPanel = useCallback(
    (props: { children?: React.ReactNode; index: number; value: number }) => {
      const { children, value, index, ...other } = props;

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          {...other}
        >
          {value === index && <div>{children}</div>}
        </div>
      );
    },
    []
  );

  const isBasicDataValidationErrors = useCallback(() => {
    if (
      (settings.WebSMSAdministratorUkryjPoleKolejnoscGrupyBudynkow !== "1" &&
        errors.buildingsGroup) ||
      (errors.name && errors.name?.ref?.name === "name") ||
      (errors.description && errors.description?.ref?.name === "description") ||
      errors.photo ||
      (errors.order && errors.order?.ref?.name === "order")
    ) {
      return true;
    }

    return false;
  }, [
    errors.buildingsGroup,
    errors.description,
    errors.name,
    errors.order,
    errors.photo,
    settings.WebSMSAdministratorUkryjPoleKolejnoscGrupyBudynkow,
  ]);

  const isFloorsDataValidationErrors = useCallback(() => {
    if (errors.floors) return true;
  }, [errors.floors]);

  const handleChangeValueTab = useCallback(
    (event: React.ChangeEvent<any>, newValue: number) => {
      setValueTab(newValue);
    },
    []
  );

  useEffect(() => {
    setIsFetching(fetchingStateAddEditBuilding.isFetching);
  }, [fetchingStateAddEditBuilding, setIsFetching]);

  const onSubmit = (data: any) => {
    let jsonDataToSend: any = {
      buildingsGroupId: null,
      name: data.name,
      description: data.description,
      order: data.order,
      showOnMap: data.showOnMap,
      latitude: data.latitude,
      longitude: data.longitude,
      floors: [],
      removePhoto: data.photo ? false : true,
    };

    if (
      settings.WebSMSAdministratorUkryjPoleKolejnoscGrupyBudynkow?.value !== "1"
    ) {
      jsonDataToSend.buildingsGroupId = data.buildingsGroup.id;
    }

    let formData = new FormData();

    data.floors.forEach((floor, index) => {
      jsonDataToSend.floors.push({
        id: floor.id,
        name: floor.name,
        number: floor.number,
        isDefault: floor.isDefault,
        mapFileIndex: index,
      });

      formData.append(`requestBuildingFloorsMapsData_${index}`, floor.map[0]);
    });

    formData.append("requestBuildingData", JSON.stringify(jsonDataToSend));

    if (data.photo) {
      formData.append("requestBuildingPhoto", data.photo[0]);
    }

    setBodyRequest(formData);
  };

  useEffect(() => {
    if (rowId && buildingsGroupsLoaded && buildingLoaded) {
      // Wczytujemy dane budynku
      if (building.buildingsGroupId) {
        let foundObj = buildingsGroups.find(
          (el) => el.id === building.buildingsGroupId
        );

        if (foundObj) {
          setValue("buildingsGroup", foundObj);
        }
      }

      setValue("name", building.name);
      setValue("description", building.description);
      setValue("order", building.order);
      setValue("showOnMap", building.showOnMap);
      setValue("latitude", building.latitude);
      setValue("longitude", building.longitude);

      if (building.photoFileName && building.photoFileSize > 0) {
        setValue("photo", [
          {
            name: building.photoFileName,
            size: building.photoFileSize,
          },
        ]);
      }

      // Wczytujemy dane pięter
      if (building.floors) {
        for (let i = 0; i < building.floors.length; i++) {
          append({
            id: building.floors[i].id,
            name: building.floors[i].name,
            number: building.floors[i].number,
            isDefault: building.floors[i].isDefault,
            map: [
              {
                name: building.floors[i].map.fileName,
                size: building.floors[i].map.fileSize,
              },
            ],
          });
        }
      }
    }
  }, [
    rowId,
    buildingsGroupsLoaded,
    buildingLoaded,
    //building?.buildingsGroupId,
    //building,
    building?.buildingsGroupId,
    building?.description,
    building?.floors,
    building?.latitude,
    building?.longitude,
    building?.name,
    building?.order,
    building?.photoFileName,
    building?.photoFileSize,
    building?.showOnMap,
    setValue,
    buildingsGroups,
    append,
  ]);

  return (
    <LoadingWrapper
      isLodadingProgress={
        fetchingStateBuildingsGroups.isFetching ||
        fetchingStateBuilding.isFetching
      }
      isError={
        fetchingStateBuildingsGroups.isError || fetchingStateBuilding.isError
      }
      setIfFetchAgain={() => {
        if (fetchingStateBuildingsGroups.isError) {
          fetchAgainBuildingsGroups();
        }

        if (fetchingStateBuilding.isError) {
          fetchAgainBuilding();
        }
      }}
    >
      <form
        key={"add_building_form"}
        onSubmit={handleSubmit(onSubmit)}
        id="submitForm"
        noValidate
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
          value={valueTab}
          onChange={handleChangeValueTab}
          TabIndicatorProps={{
            style: {
              backgroundColor: `${colorNameDictionary[authUserType]}`,
            },
          }}
          variant="fullWidth"
        >
          <Tab
            label={t("basic_data")}
            value={0}
            icon={
              isBasicDataValidationErrors() ? (
                <Tooltip title={t("invalid_data")!} placement="top">
                  <Error color="error" />
                </Tooltip>
              ) : (
                <></>
              )
            }
          />
          <Tab
            label={t("floors")}
            value={1}
            icon={
              isFloorsDataValidationErrors() ? (
                <Tooltip title={t("invalid_data")!} placement="top">
                  <Error color="error" />
                </Tooltip>
              ) : (
                <></>
              )
            }
          />
        </Tabs>

        <TabPanel value={valueTab} index={0}>
          <div className={styles["buildings-basic-data-main-container"]}>
            <div className={styles["buildings-basic-data-column"]}>
              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle width={"100px"}>{t("buildings_group")}:</RowTitle>
                  <RowInput>
                    <SelectFieldReactHookForm
                      name={"buildingsGroup"}
                      control={control}
                      label={t("choose_buildings_group")}
                      required={true}
                      options={buildingsGroups || []}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      getOptionSelected={(option, value) => {
                        return option.id === value.id;
                      }}
                      setValue={setValue}
                      watch={watch}
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle width={"100px"}>{t("name")}:</RowTitle>
                  <RowInput>
                    <TextFieldReactHookForm
                      name={"name"}
                      control={control}
                      label={t("building_name")}
                      required={true}
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle width={"100px"}>{t("description")}:</RowTitle>
                  <RowInput>
                    <TextFieldReactHookForm
                      name={"description"}
                      control={control}
                      label={t("building_description")}
                      required={true}
                      multiline
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle width={"100px"}>{t("photo")}:</RowTitle>
                  <RowInput>
                    <UploadFileFieldReactHookForm
                      id="photo"
                      register={register}
                      name={"photo"}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      label={t("building_photo")}
                      required={false}
                      accept="png, .jpeg, .jpg, .bmp"
                      watch={watch}
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              {settings.WebSMSAdministratorUkryjPoleKolejnoscGrupyBudynkow
                ?.value !== "1" && (
                <TwoColumnsRowsContainer>
                  <Row>
                    <RowTitle width={"100px"}>{t("order")}:</RowTitle>
                    <RowInput>
                      <TextFieldReactHookForm
                        name="order"
                        type="number"
                        control={control}
                        label={t("buildings_order")}
                        required={true}
                      />
                    </RowInput>
                  </Row>
                </TwoColumnsRowsContainer>
              )}
            </div>
            <div className={styles["buildings-basic-data-map-column"]}>
              <PositionOnMapViewer
                control={control}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
              />
            </div>
          </div>
        </TabPanel>

        <TabPanel value={valueTab} index={1}>
          <BuildingFloors
            control={control}
            register={register}
            fields={fields}
            append={append}
            remove={remove}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            watch={watch}
          />
        </TabPanel>
      </form>
    </LoadingWrapper>
  );
};

export default AddEditBuildingPopupContent;
