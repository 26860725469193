import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../PopupHelpers";
import PopupDialogContent from "./PopupDialogContent";

interface Props {
  onCancelChanges: () => void;
  open: boolean;
  successCallback: () => void;
  pathApi: string;
  newStatusIdConfirm?: number;
  rowId?: number;
  typeOfVisitId?: number;
  permissionZoneId?: number | null;
  permissionZonesPatchApi?: string;
  prefixPathName?: string;
  notificationId?: number;
  pathApiNotifications?: string;
}

const PopupWorkingConditions = ({
  onCancelChanges,
  open,
  successCallback,
  pathApi,
  newStatusIdConfirm,
  rowId,
  typeOfVisitId,
  permissionZoneId,
  permissionZonesPatchApi,
  prefixPathName,
  notificationId,
  pathApiNotifications,
}: Props) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (values: PopupEntryWorkingConditionsFormValues) => {
    setErrorMessage("");

    let body: PathProperty[] = [];

    if (rowId === undefined) {
      body.push({
        path: prefixPathName
          ? `${prefixPathName}/confirmationStatus`
          : "/confirmationStatus",
        op: "replace",
        value: newStatusIdConfirm,
      });
    }

    body.push({
      path: prefixPathName
        ? `${prefixPathName}/isWorkPermitRequired`
        : "/isWorkPermitRequired",
      op: "replace",
      value: values.isWorkPermitRequiredTrue ? true : false,
    });

    body.push({
      path: prefixPathName ? `${prefixPathName}/workArea` : "/workArea",
      op: "replace",
      value: values.isWorkPermitRequiredTrue ? values.workArea : null,
    });

    body.push({
      path: prefixPathName
        ? `${prefixPathName}/workPermitNumber`
        : "/workPermitNumber",
      op: "replace",
      value: values.isWorkPermitRequiredTrue ? values.workPermitNumber : null,
    });

    body.push({
      path: prefixPathName
        ? `${prefixPathName}/isWorkParticularlyDangerous`
        : "/isWorkParticularlyDangerous",
      op: "replace",
      value: values.isWorkParticularlyDangerousTrue ? true : false,
    });

    body.push({
      path: prefixPathName
        ? `${prefixPathName}/workParticularlyDangerousComments`
        : "/workParticularlyDangerousComments",
      op: "replace",
      value: values.isWorkParticularlyDangerousTrue
        ? values.workParticularlyDangerousComments
        : null,
    });

    if (permissionZoneId === null) {
      body.push({
        path: prefixPathName
          ? `${prefixPathName}/permissionZoneId`
          : "/permissionZoneId",
        op: "replace",
        value: values.permissionZone ? values.permissionZone.id : null,
      });
    }

    body.push({
      path: prefixPathName ? `${prefixPathName}/bh3` : "/bh3",
      op: "replace",
      value: values.bh3,
    });

    setBodyRequest(JSON.stringify(body));
  };

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [fetchingState, fetchAgain] = useFetchOtherThanGET({
    path: pathApi,
    method: "PATCH",
    body: bodyRequest,
    setBody: setBodyRequest,
    contentType: "application/json",
    successCallback,
  });

  useEffect(() => {
    if (open === false) {
      setErrorMessage("");
    }
  }, [open]);

  useEffect(() => {
    if (bodyRequest) {
      fetchAgain();
    }
  }, [bodyRequest, fetchAgain]);

  return (
    <>
      <DialogStyled open={open} onClose={onCancelChanges}>
        <DialogTitleStyled title={t("working_conditions")} />
        <PopupDialogContent
          onSubmit={onSubmit}
          rowId={rowId ? rowId : notificationId ? notificationId : undefined}
          typeOfVisitId={typeOfVisitId}
          permissionZoneId={permissionZoneId}
          permissionZonesPatchApi={permissionZonesPatchApi}
          pathApiNotifications={pathApiNotifications}
        />
        <DialogActionsStyled
          typConfirm="submit"
          formConfirm="submitForm"
          onCancel={onCancelChanges}
          isLoading={fetchingState.isFetching}
          errorString={errorMessage}
        />
      </DialogStyled>
    </>
  );
};

export default PopupWorkingConditions;
