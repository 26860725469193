import styles from "./dashboardStyles.module.scss";

interface Props {
  title: string;
  content: {
    value: number;
    description?: string;
  }[];
}

const DashboardItem: React.FC<Props> = ({ title, content }) => {
  return (
    <div className={styles.paper}>
      <div className={styles.titleCard}>{title}</div>
      <div className={styles.contentCard}>
        {content.map((item, index) => {
          return (
            <div className={styles.contentItemCard} key={index}>
              <div>{item.value}</div>
              <div className={styles.contentItemCardDescription}>
                {item.description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardItem;
