import React, { ReactElement, useContext } from "react";
import { contextInputTitleWidth } from "./dialogContentStyled";
import styles from "./popupHelpers.module.scss";

interface Props {
  children: React.ReactNode | React.ReactNode[];
  width?: number | string;
}

export default function RowTitle({ children, width }: Props): ReactElement {
  const inputTitleWidth = useContext(contextInputTitleWidth);
  let widthFinal: string | number = 300;
  if (width) {
    widthFinal = width;
  } else {
    widthFinal = inputTitleWidth?.titleWidth
      ? inputTitleWidth?.titleWidth
      : 120;
  }
  return (
    <div className={styles.rowTitle} style={{ width: widthFinal }}>
      {children}
    </div>
  );
}
