import TableComponent from "../TableComponent/tableComponent";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import { ProfileType } from "../../../enums/profileType";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import WorkersSelectMenu from "../../superiorTime/administration/HolidaysLimit/SelectMenus/WorkersSelectMenu";
import AddEditTimeWorkerLimitPopup from "./Popups/AddEditTimeWorkerLimit";
import React from "react";
import { convertTime } from "../../../HelpersFunctions/dateAndTime/convertTime";

interface IProps {
  timeWorkersIds?: number[];
  year?: number;
}

const TimeWorkersHolidaysLimits = ({ timeWorkersIds, year }: IProps) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const settings = useAppSelector(selectSettings);
  const authUserId = authUser.currentProfile.subjectId;
  const authUserType = authUser.currentProfile.type;

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [timeSuperiorHolidaysLimits, setTimeSuperiorHolidaysLimits] = useState<
    TimeSuperiorHolidaysLimitsItem[]
  >([]);

  const [holidaysLimits, setHolidaysLimits] = useState<
    TimeSuperiorHolidaysLimitsItem[]
  >([]);

  let holidayHoursLimitInDaysOption: boolean =
    settings.WebWymiarGodzinowyWDniach?.value === "1";

  const holidaysLimitsColumns =
    authUserType === ProfileType.WORKER_TIME
      ? [
          { name: "absenceTypeName", title: t("absence_type_name") },
          { name: "duration", title: t("holidays_limits_limit") },
          { name: "overdue", title: t("holidays_limits_overdue") },
          { name: "used", title: t("holidays_limits_used") },
          { name: "remained", title: t("holidays_limits_remained") },
          { name: "remainedOverdue", title: t("remaining_overdue_uw") },
          { name: "isDay", title: t("holidays_limits_data_unit") },
        ]
      : [
          { name: "employee", title: t("employee") },
          { name: "evidenceNumber", title: t("evidence_number") },
          { name: "organizationalUnits", title: t("organizational_units") },
          { name: "absenceTypeName", title: t("absence_type_name") },
          { name: "duration", title: t("holidays_limits_limit") },
          { name: "overdue", title: t("holidays_limits_overdue") },
          { name: "used", title: t("holidays_limits_used") },
          { name: "remained", title: t("holidays_limits_remained") },
          { name: "remainedOverdue", title: t("remaining_overdue_uw") },
          { name: "isDay", title: t("holidays_limits_data_unit") },
        ];

  const modifyCompaniesResponseCallback = useCallback(
    (responseData: TimeWorkerHolidaysLimits): TimeWorkerHolidayLimitItem[] => {
      let tempResponse: any = responseData ? responseData.limits : [];

      if (tempResponse) {
        tempResponse.forEach((el) => {
          el.duration = convertTime(
            el.duration,
            el.isDay,
            holidayHoursLimitInDaysOption
          );

          el.overdue = convertTime(
            el.overdue,
            el.isDay,
            holidayHoursLimitInDaysOption
          );

          el.used = convertTime(
            el.used,
            el.isDay,
            holidayHoursLimitInDaysOption
          );

          el.remained = convertTime(
            el.remained,
            el.isDay,
            holidayHoursLimitInDaysOption
          );

          el.remainedOverdue = convertTime(
            el.remainedOverdue,
            el.isDay,
            holidayHoursLimitInDaysOption
          );

          el.isDay = el.isDay
            ? t("days").toLowerCase()
            : t("hours").toLowerCase();
        });
      }

      return tempResponse;
    },
    [t, holidayHoursLimitInDaysOption]
  );

  const [
    timeWorkerHolidaysLimits,
    fetchingStatesTimeWorkerHolidaysLimits,
    fetchAgainTimeWorkerHolidaysLimits,
  ] = useFetchAndSetGET({
    path: `worker-time/${authUserId}/holiday-limits-info`,
    modifyResponseCallback: modifyCompaniesResponseCallback,
  });

  const successCallbackHolidaysLimits = useCallback(
    (data) => {
      let tempHolidaysLimits: any = [];
      if (Array.isArray(data.resJson)) {
        let tempData = [...data.resJson];
        for (let i = 0; i < tempData.length; i++) {
          if (tempData[i].limits) {
            for (let j = 0; j < tempData[i].limits.length; j++) {
              let itemToAdd = {
                id: `${tempData[i].timeWorkerId};${tempData[i].limits[j].absenceTypeId};${tempData[i].limits[j].year}`,
                timeWorkerId: tempData[i].timeWorkerId,
                employee: `${tempData[i].lastName} ${tempData[i].firstName}`,
                lastName: tempData[i].lastName,
                evidenceNumber: tempData[i].evidenceNumber,
                organizationalUnits: tempData[i].organizationalUnits,
                absenceTypeId: tempData[i].limits[j].absenceTypeId,
                absenceTypeName: tempData[i].limits[j].absenceTypeName,
                year: tempData[i].limits[j].year,
                duration: convertTime(
                  tempData[i].limits[j].duration,
                  tempData[i].limits[j].isDay,
                  holidayHoursLimitInDaysOption
                ),
                isDay: tempData[i].limits[j].isDay
                  ? t("days").toLowerCase()
                  : t("hours").toLowerCase(),
                overdue: convertTime(
                  tempData[i].limits[j].overdue,
                  tempData[i].limits[j].isDay,
                  holidayHoursLimitInDaysOption
                ),
                used: convertTime(
                  tempData[i].limits[j].used,
                  tempData[i].limits[j].isDay,
                  holidayHoursLimitInDaysOption
                ),
                remained: convertTime(
                  tempData[i].limits[j].remained,
                  tempData[i].limits[j].isDay,
                  holidayHoursLimitInDaysOption
                ),
                remainedOverdue: convertTime(
                  tempData[i].limits[j].remainedOverdue,
                  tempData[i].limits[j].isDay,
                  holidayHoursLimitInDaysOption
                ),
              };

              tempHolidaysLimits.push(itemToAdd);
            }
          }
        }
      }

      setTimeSuperiorHolidaysLimits(tempHolidaysLimits);
    },
    [t, holidayHoursLimitInDaysOption]
  );

  const [bodyRequest, setBodyRequest] = useState<false | string>(false);
  const [
    fetchingStateTimeSuperiorHolidaysLimits,
    fetchAgainTimeSuperiorHolidaysLimits,
  ] = useFetchOtherThanGET({
    path: "superior-time/holiday-limits",
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    disableSuccessSnackbar: true,
    disableErrorSnackbar: true,
    successCallback: successCallbackHolidaysLimits,
  });

  useEffect(() => {
    if (authUserType === ProfileType.SUPERIOR_TIME) {
      let requestObj = {
        timeWorkersIds: timeWorkersIds ? timeWorkersIds : [],
        year: year ? year : new Date().getFullYear(),
      };

      setBodyRequest(JSON.stringify(requestObj));
    } else if (authUserType === ProfileType.WORKER_TIME) {
      fetchAgainTimeWorkerHolidaysLimits();
    }
  }, [
    timeWorkersIds,
    year,
    authUserType,
    fetchAgainTimeWorkerHolidaysLimits,
    fetchAgainTimeSuperiorHolidaysLimits,
  ]);

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainTimeSuperiorHolidaysLimits();
    }
  }, [bodyRequest, fetchAgainTimeSuperiorHolidaysLimits]);

  useEffect(() => {
    if (authUserType === ProfileType.WORKER_TIME) {
      setHolidaysLimits(timeWorkerHolidaysLimits || []);
    } else if (authUserType === ProfileType.SUPERIOR_TIME) {
      setHolidaysLimits(timeSuperiorHolidaysLimits || []);
    }
  }, [timeWorkerHolidaysLimits, timeSuperiorHolidaysLimits, authUserType]);

  const ifHideSelectData = useCallback(() => {
    let result = false;

    if (authUserType !== ProfileType.SUPERIOR_TIME) {
      result = true;
    }

    return result;
  }, [authUserType]);

  const refreshData = useCallback(() => {
    if (authUserType === ProfileType.SUPERIOR_TIME) {
      let requestObj = {
        timeWorkersIds: timeWorkersIds ? timeWorkersIds : [],
        year: year ? year : new Date().getFullYear(),
      };

      setBodyRequest(JSON.stringify(requestObj));
    } else if (authUserType === ProfileType.WORKER_TIME) {
      fetchAgainTimeWorkerHolidaysLimits();
    }
  }, [authUserType, timeWorkersIds, year, fetchAgainTimeWorkerHolidaysLimits]);

  const [
    holidaysLimitsPermissions,
    fetchingStateHolidaysLimitsPermission,
    fetchAgainHolidaysLimitsPermission,
  ] = useFetchAndSetGET<IHolidayLimitsPermissions>({
    path: `superior-time/holiday-limits-permissions`,
    startFetchOnInitial: false,
  });

  const [deleteHolidayLimitItemId, setDeleteHolidayLimitItemId] =
    useState<any>(false);

  const [, deleteHolidayLimitFetchAgain] = useFetchOtherThanGET({
    path:
      "superior-time/holiday-limits" +
      `?timeWorkerId=${deleteHolidayLimitItemId.timeWorkerId}&absenceTypeId=${deleteHolidayLimitItemId.absenceTypeId}&year=${deleteHolidayLimitItemId.year}`,
    method: "DELETE",
    contentType: "application/json",
    body: deleteHolidayLimitItemId,
    setBody: setDeleteHolidayLimitItemId,
    successCallback: refreshData,
  });

  useEffect(() => {
    if (authUserType === ProfileType.SUPERIOR_TIME) {
      fetchAgainHolidaysLimitsPermission();
    }
  }, [authUserType, fetchAgainHolidaysLimitsPermission]);

  useEffect(() => {
    if (deleteHolidayLimitItemId) {
      deleteHolidayLimitFetchAgain();
    }
  }, [deleteHolidayLimitItemId, deleteHolidayLimitFetchAgain]);

  const ifShowTableSelectMenu = useCallback(() => {
    let result = false;

    if (authUserType === ProfileType.SUPERIOR_TIME) {
      result = true;
    }

    return result;
  }, [authUserType]);

  const ifShowAddButton = useCallback(() => {
    let result = false;

    if (authUserType === ProfileType.SUPERIOR_TIME) {
      if (holidaysLimitsPermissions) {
        result = holidaysLimitsPermissions.canAdd;
      }
    }

    return result;
  }, [authUserType, holidaysLimitsPermissions]);

  const ifShowEditButton = useCallback(() => {
    let result = false;

    if (authUserType === ProfileType.SUPERIOR_TIME) {
      if (holidaysLimitsPermissions) {
        result = holidaysLimitsPermissions.canEdit;
      }
    }

    return result;
  }, [authUserType, holidaysLimitsPermissions]);

  return (
    <div>
      {authUserType === ProfileType.SUPERIOR_TIME &&
        !fetchingStateHolidaysLimitsPermission.isFetching && (
          <TableComponent
            fetchingState={{
              fetchAgain: fetchAgainTimeSuperiorHolidaysLimits,
              isError: fetchingStateTimeSuperiorHolidaysLimits.isError,
              isFetching: fetchingStateTimeSuperiorHolidaysLimits.isFetching,
            }}
            layoutSettingName="layoutHolidaysLimits"
            rows={holidaysLimits}
            columns={holidaysLimitsColumns}
            backgroundColor="#fff"
            pageSize={8}
            hideActionsAndVisibilityColumns={true}
            selectData={(ids) => setSelectedRows(ids as string[])}
            selectedData={selectedRows}
            hideSelectDataCondition={() => ifHideSelectData()}
            actionsColumnUpdated={{
              popup: AddEditTimeWorkerLimitPopup,
              successCallbackPopup: refreshData,
              addButton: {
                ifShow: ifShowAddButton(),
                showInToolbar: false,
              },
              editButton: {
                ifShow: ifShowEditButton(),
                name: t("edit"),
              },
              leftToolbarComponent: {
                ifShow: ifShowTableSelectMenu(),
                customComponent: () => {
                  return (
                    <WorkersSelectMenu
                      timeWorkersIds={selectedRows}
                      setTimeWorkersIds={setSelectedRows}
                      successCallback={refreshData}
                    />
                  );
                },
              },
            }}
          />
        )}

      {authUserType === ProfileType.WORKER_TIME && (
        <TableComponent
          fetchingState={{
            fetchAgain: fetchAgainTimeWorkerHolidaysLimits,
            isError: fetchingStatesTimeWorkerHolidaysLimits.isError,
            isFetching: fetchingStatesTimeWorkerHolidaysLimits.isFetching,
          }}
          layoutSettingName="layoutHolidaysLimits"
          rows={holidaysLimits}
          columns={holidaysLimitsColumns}
          backgroundColor="#fff"
          pageSize={8}
        />
      )}
    </div>
  );
};

export default React.memo(TimeWorkersHolidaysLimits);
