import React from "react";
import { useTranslation } from "react-i18next";

const NotificationRowDetail: React.FC = ({ row }: any) => {
  const { t } = useTranslation();

  return (
    <div className="detailsNotificationList">
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("guest_name")}:</b>
          </div>
          <div>{row?.guestFirstName ? row.guestFirstName : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("guest_surname")}:</b>
          </div>
          <div>{row?.guestLastName ? row.guestLastName : "—"}</div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("date_of_arrival")}:</b>
          </div>
          <div>{row?.arrivalDateString ? row.arrivalDateString : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("for_employee")}:</b>
          </div>
          <div>{row?.announces ? row.announces : "—"}</div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("parking_lot")}:</b>
          </div>
          <div>{row?.parkingName ? row.parkingName : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("prepare_meal")}:</b>
          </div>
          <div>
            {row?.isProvisioningString ? row.isProvisioningString : "—"}
          </div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("arrival")}:</b>
          </div>
          <div>{row?.arrivalString ? row.arrivalString : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("comments")}:</b>
          </div>
          <div>{row?.attention ? row.attention : "—"}</div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("description")}:</b>
          </div>
          <div>{row?.description ? row.description : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("comment")}:</b>
          </div>
          <div>{row?.comments ? row.comments : "—"}</div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("was_added")}:</b>
          </div>
          <div>{row?.createdAtString ? row.createdAtString : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("approved")}:</b>
          </div>
          <div>{row?.approvedAtString ? row.approvedAtString : "—"}</div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("licence_place")}:</b>
          </div>
          <div>
            {row?.carRegistrationNumber ? row.carRegistrationNumber : "—"}
          </div>
        </div>
        <div>
          <div>
            <b>{t("approved_by")}:</b>
          </div>
          <div>{row?.approverName ? row.approverName : "—"}</div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("confirm_contact")}:</b>
          </div>
          <div>
            {row?.meetingApprovalString ? row.meetingApprovalString : "—"}
          </div>
        </div>
        <div>
          <div>
            <b>{t("guardian_phone")}:</b>
          </div>
          <div>{row?.guardianPhoneNumber ? row.guardianPhoneNumber : "—"}</div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("purpose_of_visit")}:</b>
          </div>
          <div>{row?.purposeOfVisit ? row.purposeOfVisit : "—"}</div>
        </div>

        <div>
          <div>
            <b>{t("imported_or_exported_items")}:</b>
          </div>
          <div>
            {row?.importedExportedItems ? row.importedExportedItems : "—"}
          </div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("visitation_object")}:</b>
          </div>
          <div>{row?.visitationObject ? row.visitationObject : "—"}</div>
        </div>
      </div>
    </div>
  );
};

export default NotificationRowDetail;
