import "./delegationsCities.scss";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import Popup from "./popup";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "../../../../reducers/session";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { useEffect, useState } from "react";

function DelegationsCitiesContent() {
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const { t } = useTranslation();

  const columns = [
    { name: "countryName", title: t("country") },
    { name: "name", title: t("city") },
    { name: "order", title: t("order") },
  ];

  const [cities, citiesFetchingState, citiesFetchAgain] = useFetchAndSetGET({
    path: `worker-time/${authUserId}/cities`,
  });

  const [cityIdToRemove, setCityIdToRemove] = useState<false | number>(false);

  const [, fetchAgain] = useFetchOtherThanGET({
    path: `worker-time/${authUserId}/cities/${cityIdToRemove}`,
    method: "DELETE",
    contentType: "application/json",
    body: cityIdToRemove,
    setBody: setCityIdToRemove,
    successCallback: citiesFetchAgain,
  });

  useEffect(() => {
    if (cityIdToRemove !== false) {
      fetchAgain();
    }
  }, [cityIdToRemove, fetchAgain]);

  return (
    <>
      <TopBanner pathName={t("list_of_cities")} />
      <div className="flexAndCenter">
        <div>
          <div>
            <TableComponent
              layoutSettingName="layoutWorkerTimeDelegationsCities"
              rows={cities}
              columns={columns}
              actionsColumnUpdated={{
                popup: Popup,
                successCallbackPopup: citiesFetchAgain,

                addButton: {
                  ifShow: true,
                },
                editButton: {
                  ifShow: true,
                  name: t("edit"),
                },

                removeButton: {
                  ifShow: true,
                  onClick: (row) => {
                    setCityIdToRemove(row.id);
                  },
                },
              }}
              fetchingState={{
                fetchAgain: citiesFetchAgain,
                isError: citiesFetchingState.isError,
                isFetching: citiesFetchingState.isFetching,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DelegationsCitiesContent;
