import { Form, Formik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";
import { DatePickerFormik, TextFieldFormik } from "../FormikInputs";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../PopupHelpers";
import CheckboxWithLabelFormik from "../FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";

const PopupContentSupport = ({
  employeeId,
  onSubmit,
  listOfEmployeesId,
  pathApiListsOfEmployees,
}: {
  employeeId?: number;
  onSubmit: (values: IEditEmployeeInListOfEmployees) => void;
  listOfEmployeesId?: number;
  pathApiListsOfEmployees: string;
}) => {
  const { t } = useTranslation();

  const [fetchedEmployee, setFetchedEmployee] =
    useState<IListOfEmployeesEmployee | null>(null);

  const [fetchedRow, fetchingStateFetchedRow, fetchAgainFetchedRow] =
    useFetchAndSetGET<IListOfEmployees>({
      path: `${pathApiListsOfEmployees}/${listOfEmployeesId}`,
      startFetchOnInitial: listOfEmployeesId ? true : false,
    });

  useEffect(() => {
    if (fetchingStateFetchedRow.response?.status === 200 && employeeId) {
      const employee = fetchedRow?.employess?.find(
        (emp) => emp.id === employeeId
      );
      if (employee) {
        setFetchedEmployee(employee);
      }
    }
  }, [fetchingStateFetchedRow.response?.status, fetchedRow, employeeId]);

  //#region Domyślna nazwa firmy
  const settings = useAppSelector(selectSettings);
  const defaultCompanyName = settings["DomyslnaFirmaListaPracownikow"]?.value
    ? settings["DomyslnaFirmaListaPracownikow"].value
    : "";
  //#endregion

  const [errorMessage, setErrorMessage] = useState<false | any>(false);

  const [guestsOnBlacklist, setGuestsOnBlacklist] = useState<any[]>();

  const [
    bodyCheckGuestsOnBlackListRequest,
    setBodyCheckGuestsOnBlackListRequest,
  ] = useState<any>(false);

  const successCallbackRef: any = useRef();

  const checkGuestsOnBlackListSuccessCallback = useCallback(
    async (response: UseFetchResponse) => {
      let tempGuests: any[] = [];
      let tempGuestsInImportedFile: any[] = [];

      if (response.resJson) {
        if (response.resJson.guests) {
          for (let i = 0; i < response.resJson.guests.length; i++) {
            tempGuests.push({
              firstName: response.resJson.guests[i].firstName,
              lastName: response.resJson.guests[i].lastName,
              dateOfBirth: response.resJson.guests[i].dateOfBirth,
              email: response.resJson.guests[i].email,
            });
          }
        }
      }

      setGuestsOnBlacklist(tempGuests);

      if (tempGuests.length === 0 && tempGuestsInImportedFile.length === 0) {
        if (successCallbackRef.current) {
          successCallbackRef.current();
        }
      }

      successCallbackRef.current = undefined;
    },
    []
  );

  const [, fetchAgainCheckGuestsOnBlackList] = useFetchOtherThanGET({
    path: `operational-support/notification-guests-in-ports/check-guests-on-black-list`,
    method: "POST",
    body: bodyCheckGuestsOnBlackListRequest,
    setBody: setBodyCheckGuestsOnBlackListRequest,
    successCallback: checkGuestsOnBlackListSuccessCallback,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (bodyCheckGuestsOnBlackListRequest) {
      fetchAgainCheckGuestsOnBlackList();
    }
  }, [bodyCheckGuestsOnBlackListRequest, fetchAgainCheckGuestsOnBlackList]);

  useEffect(() => {
    if (guestsOnBlacklist && guestsOnBlacklist.length > 0) {
      setErrorMessage(t("form_contains_person_from_black_list"));
    } else {
      setErrorMessage("");
    }
  }, [guestsOnBlacklist, t]);

  return (
    <div>
      <LoadingWrapper
        isLodadingProgress={fetchingStateFetchedRow.isFetching}
        isError={fetchingStateFetchedRow.isError}
        setIfFetchAgain={() => {
          if (fetchingStateFetchedRow.isError) {
            fetchAgainFetchedRow();
          }
        }}
      >
        {(!employeeId || (fetchedRow && fetchedEmployee)) && (
          <DialogContentStyled>
            <Formik
              initialValues={{
                date: fetchedRow?.date ? new Date(fetchedRow.date) : null,
                timeFrom: fetchedRow?.timeFrom ? fetchedRow.timeFrom : "",
                timeTo: fetchedRow?.timeTo ? fetchedRow.timeTo : "",
                employeeFirstName: fetchedEmployee?.employeeFirstName
                  ? fetchedEmployee.employeeFirstName
                  : "",
                employeeLastName: fetchedEmployee?.employeeLastName
                  ? fetchedEmployee.employeeLastName
                  : "",
                employeeIDNumber: fetchedEmployee?.employeeIDNumber
                  ? fetchedEmployee.employeeIDNumber
                  : "",
                company: fetchedEmployee?.company
                  ? fetchedEmployee.company
                  : defaultCompanyName,
                position: fetchedEmployee?.position
                  ? fetchedEmployee.position
                  : "",
                comments: fetchedEmployee?.comments
                  ? fetchedEmployee.comments
                  : "",
                isDeclarationChecked: fetchedEmployee?.isDeclarationChecked
                  ? fetchedEmployee?.isDeclarationChecked
                  : false,
              }}
              onSubmit={(values) => {
                let dataToSend: any = {
                  guestsToCheck: [
                    {
                      firstName: values.employeeFirstName,
                      lastName: values.employeeLastName,
                    },
                  ],
                  checkOnlyFirstAndLastName: true,
                };

                let formData = new FormData();
                formData.append("requestData", JSON.stringify(dataToSend));

                const successCallback = () => {
                  const object = {
                    date: convertDateFetch(values.date!),
                    timeFrom: values.timeFrom,
                    timeTo: values.timeTo ? values.timeTo : null,
                    employeeFirstName: values.employeeFirstName,
                    employeeLastName: values.employeeLastName,
                    employeeIDNumber: values.employeeIDNumber,
                    company: values.company,
                    position: values.position,
                    comments: values.comments,
                    isDeclarationChecked: values.isDeclarationChecked,
                  };

                  onSubmit(object);
                };

                successCallbackRef.current = successCallback;
                setBodyCheckGuestsOnBlackListRequest(formData);
              }}
              validationSchema={Yup.object({
                date: Yup.date().nullable().required(t("field_required")),
                timeFrom: Yup.string().required(t("field_required")),
                employeeFirstName: Yup.string().required(t("field_required")),
                employeeLastName: Yup.string().required(t("field_required")),
                position: Yup.string().required(t("field_required")),
                company: Yup.string().required(t("field_required")),
                isDeclarationChecked: Yup.boolean().oneOf(
                  [true],
                  t("field_required")
                ),
              })}
            >
              {(formikProps) => (
                <Form noValidate id="submitForm">
                  {/*  */}
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("date")}:</RowTitle>
                      <RowInput>
                        <DatePickerFormik
                          label={t("date")}
                          name="date"
                          view={["year", "month", "date"]}
                          format="dd-MM-yyyy"
                          keyboardDateTimePicker={true}
                          required={true}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  {/*  */}
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("time_from")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          name="timeFrom"
                          hiddenLabel
                          type="time"
                          required={true}
                        />
                      </RowInput>
                    </Row>

                    <Row>
                      <RowTitle>{t("time_to")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          name="timeTo"
                          hiddenLabel
                          type="time"
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  {/*  */}
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("first_name")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("first_name")}
                          name="employeeFirstName"
                          type="text"
                          required={true}
                          ifToUpperCaseShipsAndPorts={true}
                        />
                      </RowInput>
                    </Row>

                    <Row>
                      <RowTitle>{t("last_name")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("last_name")}
                          name="employeeLastName"
                          type="text"
                          required={true}
                          ifToUpperCaseShipsAndPorts={true}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  {/*  */}
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("id_number")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("id_number")}
                          name="employeeIDNumber"
                          type="text"
                          ifToUpperCaseShipsAndPorts={true}
                        />
                      </RowInput>
                    </Row>

                    <Row>
                      <RowTitle>{t("company")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("company")}
                          name="company"
                          type="text"
                          ifToUpperCaseShipsAndPorts={true}
                          required={true}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  {/*  */}
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("position")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("position")}
                          name="position"
                          type="text"
                          ifToUpperCaseShipsAndPorts={true}
                          required={true}
                        />
                      </RowInput>
                    </Row>

                    <Row>
                      <RowTitle>{t("comments")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("comments")}
                          name="comments"
                          type="text"
                          ifToUpperCaseShipsAndPorts={true}
                          multiline={true}
                          rows={3}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <CheckboxWithLabelFormik
                    name={"isDeclarationChecked"}
                    ifCircle={true}
                    label={
                      <span>
                        {t("operational_support_employee_declaration")}
                      </span>
                    }
                  />

                  {errorMessage && errorMessage !== "" && (
                    <p
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {errorMessage}
                    </p>
                  )}
                </Form>
              )}
            </Formik>
          </DialogContentStyled>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default PopupContentSupport;
