import { useTranslation } from "react-i18next";
import toLocaleStringCustom from "../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import AccordionStyled from "../MaterialUi/Accordion/AccordionStyled";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../RowDetailsHelpers";

const RowDetailsUnreturnedCards = ({ row }: { row: IUnreturnedCard }) => {
  const { t } = useTranslation();

  return (
    <RowDetailsContainer>
      {/*  */}
      <AccordionStyled title={t("person")} defaultExpanded={true}>
        <>
          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("person")}:</RowDetailsTitle>
              <RowDetailsValue>{`${row?.guestFirstName} ${row?.guestLastName}`}</RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("id_number")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestIDNumber ? row.guestIDNumber : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("date_of_birth")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestDateOfBirth ? row?.guestDateOfBirth : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("email")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestEmail ? row.guestEmail : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>
        </>
      </AccordionStyled>

      {/*  */}
      <AccordionStyled title={t("information")} defaultExpanded={true}>
        <>
          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("entry_date")}:</RowDetailsTitle>
              <RowDetailsValue>
                {toLocaleStringCustom({
                  date: new Date(row.entryDate),
                  ifShowHours: true,
                  t,
                })}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("card_tag")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.cardUniversalNumber &&
                row?.cardUniversalNumber !== "0000000000000000"
                  ? row.cardUniversalNumber
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("company")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.companyName ? row.companyName : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("type_of_entry")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.typeOfEntry ? row.typeOfEntry : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("plate_number")}</RowDetailsTitle>
              <RowDetailsValue>
                {row?.registrationNumber ? row.registrationNumber : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("permission_zone")}</RowDetailsTitle>
              <RowDetailsValue>
                {row?.permissionZoneName ? row.permissionZoneName : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>
        </>
      </AccordionStyled>
    </RowDetailsContainer>
  );
};

export default RowDetailsUnreturnedCards;
