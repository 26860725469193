import {
  faMinus,
  faPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArrayRenderProps } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import FormAddNewGuest from "../../agent/FormAddNewGuest/FormAddNewGuest";
import {
  AutocompleteFormik,
  DatePickerFormik,
} from "../../helpersComponents/FormikInputs";
import GetCardTagPopupRow from "../../helpersComponents/GetCardTagPopupRow/getCardTagPopupRow";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../helpersComponents/PopupHelpers";
import ReadIdCardNumberFormikPopupRow from "../../helpersComponents/ReadIdCardNumberFormikPopupRow/readIdCardNumberFormikPopupRow";
import styles from "./styles.module.scss";
import { Guest } from "./type";

interface Props extends ReadIdCardNumberFormikPopupRowInterface {
  guestIndex: number;
  arrayHelpers: FieldArrayRenderProps;
  numberOfGuests: number;
  guestsArray: { guest: {} | null; cardNumber: string }[];
  isEditing: boolean;
  isPopupOpen: boolean;
  guestsPathApi: string;
}

function GuestFormInspectionCompany({
  guestIndex,
  arrayHelpers,
  numberOfGuests,
  guestsArray,
  isEditing,
  isPopupOpen,
  guestsPathApi,
  ...restProps
}: Props): ReactElement {
  const { t } = useTranslation();

  const { setFieldValue } = restProps;

  const [guests, fetchingStateGuests, fetchAgainGuests] = useFetchAndSetGET({
    path: guestsPathApi,
  });

  const [showAddGuestForm, setShowAddGuestForm] = useState<boolean>(false);
  const [addedGuestId, setAddedGuestId] = useState<false | number>(false);

  useEffect(() => {
    if (addedGuestId) {
      fetchAgainGuests();
    }
  }, [fetchAgainGuests, addedGuestId]);

  const [addedGuests, setAddedGuests] = useState<Guest[]>([]);

  useEffect(() => {
    if (addedGuestId && guests) {
      let foundGuest = guests.find((g: Guest) => g.id === addedGuestId);
      if (foundGuest) {
        setFieldValue(`guestsArray.${guestIndex}.guest`, foundGuest);
        setFieldValue(
          `guestsArray.${guestIndex}.trainingExpiryDate`,
          foundGuest.trainingExpiryDate
            ? new Date(foundGuest.trainingExpiryDate)
            : null
        );
      }
    }
  }, [addedGuestId, guests, guestIndex, setFieldValue]);

  return (
    <>
      <div className={styles.guestTitle}>
        <b>
          {t("person")} {guestIndex + 1}
        </b>
        {numberOfGuests > 1 ? (
          <FontAwesomeIcon
            className="faTrash"
            icon={faTimesCircle}
            onClick={() => {
              arrayHelpers.remove(guestIndex);
            }}
          />
        ) : null}
      </div>
      <LoadingWrapper
        isLodadingProgress={fetchingStateGuests.isFetching}
        isError={fetchingStateGuests.isError}
        setIfFetchAgain={() => {
          if (fetchingStateGuests.isError) {
            fetchAgainGuests();
          }
        }}
      >
        <>
          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle>{t("person")}:</RowTitle>
              <RowInput style={{ display: "flex" }}>
                <AutocompleteFormik
                  options={guests}
                  getOptionLabel={(option: Guest) => {
                    return `${option.firstName} ${option.lastName}`;
                  }}
                  width="100%"
                  getOptionSelected={(option, value) => {
                    return option.id === value.id;
                  }}
                  label={t("select_person")}
                  name={`guestsArray.${guestIndex}.guest`}
                  required={true}
                  filterSelectedOptions
                  style={{ width: "360px", marginRight: "10px" }}
                  disabled={isEditing}
                  onChangeExtra={(_, value) => {
                    setFieldValue(
                      `guestsArray.${guestIndex}.trainingExpiryDate`,
                      value?.trainingExpiryDate
                        ? new Date(value.trainingExpiryDate)
                        : null
                    );
                  }}
                />
                <div
                  className={styles.iconShowGuestForm}
                  onClick={() => {
                    setShowAddGuestForm(!showAddGuestForm);
                  }}
                >
                  {showAddGuestForm ? (
                    <FontAwesomeIcon
                      className="faMinusNotifications fa-lg"
                      icon={faMinus}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="faPlusNotificationsAgent fa-lg"
                      icon={faPlus}
                    />
                  )}
                </div>
              </RowInput>
            </Row>

            <Row>
              <RowTitle>{t("set_guest_training_date_expiry")}:</RowTitle>
              <RowInput>
                <DatePickerFormik
                  label={t("training_expiry_date")}
                  name={`guestsArray.${guestIndex}.trainingExpiryDate`}
                  view={["year", "month", "date"]}
                  minDate={new Date()}
                  format="dd-MM-yyyy"
                  required={false}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          <div style={{ width: "fit-content" }}>
            {showAddGuestForm && (
              <FormAddNewGuest
                addGuestPath={"reception/guests"}
                setShowAddGuestFrom={setShowAddGuestForm}
                setAddedGuestId={setAddedGuestId}
                addedGuestId={addedGuestId}
                guests={guests}
                addedGuests={addedGuests}
                setAddedGuests={setAddedGuests}
                isCrewMember={false}
                selectedGuestsValue={[]}
              />
            )}
          </div>

          <TwoColumnsRowsContainer>
            <GetCardTagPopupRow
              setFieldValue={setFieldValue}
              name={`guestsArray.${guestIndex}.cardNumber`}
              componentId={`inspectionService/guestsArray.${guestIndex}.cardNumber`}
              isPopupOpen={isPopupOpen}
              checkForTagsInOtherInputs={{
                thisInputIndex: guestIndex,
                allInputs: guestsArray,
              }}
              required={true}
              mask={"****************"}
            />

            <ReadIdCardNumberFormikPopupRow
              setFieldValue={setFieldValue}
              setFieldError={restProps.setFieldError}
              setFieldTouched={restProps.setFieldTouched}
              name={`guestsArray.${guestIndex}.idNumber`}
              required={true}
            />
          </TwoColumnsRowsContainer>
        </>
      </LoadingWrapper>
    </>
  );
}

export default GuestFormInspectionCompany;
