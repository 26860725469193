import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import PopupNewCompany from "./PopupNewCompany";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";
import useColumns from "./useColumns";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import { ProfileType } from "../../../enums/profileType";

interface IProps {
  pathApiCompaniesInPorts: string;
  layoutSettingName: string;
}

const CompaniesInPortsContent: React.FC<IProps> = ({
  pathApiCompaniesInPorts,
  layoutSettingName,
}) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const { confirm } = useConfirm();

  const modifyCompaniesResponseCallback = useCallback(
    (responseData: ICompanyInPorts[]) => {
      const modified = responseData?.map((row) => {
        return {
          ...row,
          description: row.description ? row.description : "-",
        };
      });
      return modified;
    },
    []
  );

  const columns = useColumns(authUser.currentProfile.type);

  const [companiesRows, fetchingStateCompanies, fetchAgainCompanies] =
    useFetchAndSetGET({
      path: `${pathApiCompaniesInPorts}${
        authUser.currentProfile.type === ProfileType.PFSO ||
        authUser.currentProfile.type === ProfileType.SHIFT_DIRECTOR
          ? "?typeOfCompany=5"
          : ""
      }`,
      modifyResponseCallback: modifyCompaniesResponseCallback,
    });

  const [companyIdToRemove, setCompanyIdToRemove] = useState<false | number>(
    false
  );

  const [fetchingState, fetchAgain] = useFetchOtherThanGET({
    path: `${pathApiCompaniesInPorts}/${companyIdToRemove}`,
    method: "DELETE",
    body: companyIdToRemove,
    setBody: setCompanyIdToRemove,
    successCallback: fetchAgainCompanies,
  });

  useEffect(() => {
    if (companyIdToRemove !== false) {
      fetchAgain();
    }
  }, [companyIdToRemove, fetchAgain]);

  return (
    <div>
      {" "}
      <div>
        <TopBanner pathName={t("companies")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <TableComponentMemo
            columns={columns}
            rows={companiesRows}
            layoutSettingName={layoutSettingName}
            actionsColumnUpdated={{
              popup: (props) => (
                <PopupNewCompany
                  pathApiCompaniesInPorts={pathApiCompaniesInPorts}
                  {...props}
                />
              ),
              successCallbackPopup: fetchAgainCompanies,
              addButton: {
                ifShow: true,
              },
              editButton: {
                ifShow: true,
                name: t("edit"),
              },
              removeButton: {
                ifShow: true,
                onClick: async (row) => {
                  let selectedbuttonId = await confirm({
                    text: t("confirm_delete_selected_company"),
                    buttons: [
                      { buttonName: t("yes"), buttonId: 0 },
                      { buttonName: t("no"), buttonId: 1 },
                    ],
                    disableBackdropClick: true,
                  });

                  if (selectedbuttonId === 0) {
                    setCompanyIdToRemove(row.id);
                  }
                },
              },
            }}
            fetchingState={{
              fetchAgain: fetchAgainCompanies,
              isError: fetchingStateCompanies.isError,
              isFetching:
                fetchingStateCompanies.isFetching || fetchingState.isFetching,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CompaniesInPortsContent;
