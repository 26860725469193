import { useEffect, useState } from "react";
import FrameDelimiterTransformer from "./frameDelimiterTransformer";

const findPortInLocalStorage = async () => {
  let foundPort = null;
  let { usbProductId, usbVendorId } = JSON.parse(
    localStorage.getItem("useIdentifyCard") || "{}"
  );

  let navigatorAny: any = navigator;
  const availablePorts = await navigatorAny?.serial?.getPorts();

  // if (usbProductId === undefined && usbVendorId === undefined) {
  //   return foundPort;
  // }

  if (availablePorts != null) {
    //automatyczny wybór pierwszego dostępnego portu
    if (availablePorts.length === 1) {
      return availablePorts[0];
    }

    availablePorts.forEach(async (p) => {
      let pData = p.getInfo();
      if (
        pData?.usbProductId === usbProductId &&
        pData?.usbVendorId === usbVendorId
      ) {
        foundPort = p;
      }
    });
  }

  return foundPort;
};

export default function useIdentifyCard(
  startListeningInit = true
): [{ response: string }, React.Dispatch<boolean>, boolean] {
  const [mrzData, setMrzData] = useState<{ response: string }>({
    response: "",
  });
  const [ifStartListetning, setIfStartListetning] =
    useState(startListeningInit);

  useEffect(() => {
    if (!ifStartListetning) return;
    let reader;
    let readableStreamClosed;
    let port;

    const openAndStartRead = async () => {
      let navigatorAny: any = navigator;
      try {
        let portFromLocalStorage = await findPortInLocalStorage();
        port =
          portFromLocalStorage != null
            ? portFromLocalStorage
            : await navigatorAny?.serial?.requestPort();

        if (port === undefined) {
          const errorMessage = { message: "No port to connect" };
          throw errorMessage;
        }

        localStorage.setItem("useIdentifyCard", JSON.stringify(port.getInfo()));
        await port.open({
          baudRate: 9600,
          dataBits: 8,
          stopBits: 1,
          parity: "even",
          flowControl: "none",
        });

        const textDecoder = new TextDecoderStream();
        readableStreamClosed = port.readable.pipeTo(textDecoder.writable);

        while (port.readable) {
          reader = textDecoder.readable
            .pipeThrough(new TransformStream(new FrameDelimiterTransformer()))
            .getReader();

          try {
            while (true) {
              const { value, done } = await reader.read();

              if (value) {
                setMrzData({ response: value });
              }

              if (done) {
                reader.releaseLock();
                break;
              }
            }
          } catch (e) {
            console.error("ERROR", e);
          }
        }
      } catch (e) {
        setIfStartListetning(false);
      }
    };
    openAndStartRead();

    return () => {
      if (reader)
        reader.cancel().catch(() => {
          console.error("reader.cancel");
        });
      if (readableStreamClosed && port) {
        setIfStartListetning(false);
        readableStreamClosed
          .catch(() => {
            console.log("readableStreamClosed");
          })
          .then(() => {
            if (port) port.close();
          })
          .catch(() => {
            console.log("port.close");
          });
      }
    };
  }, [ifStartListetning]);

  return [mrzData, setIfStartListetning, ifStartListetning];
}
