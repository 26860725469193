import "./loadingWrapper.scss";
import { CircularProgress } from "@material-ui/core";
import Button from "../../helpersComponents/Button/button";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

interface LoadingWrapperInterface {
  isError: boolean;
  children: React.ReactNode;
  bigSize?: boolean;
  isLodadingProgress: boolean;
  setIsError?: (data: boolean) => void;
  setIfFetchAgain: (data: boolean) => void;
  size?: string | number;
  removeStyles?: boolean;
}

const LoadingWrapper = ({
  children,
  isError,
  bigSize,
  setIsError,
  setIfFetchAgain,
  isLodadingProgress,
  size,
  removeStyles,
}: LoadingWrapperInterface) => {
  const { t } = useTranslation();

  return (
    <>
      {isError ? (
        <div className={bigSize ? "loadingWrapperBig" : "loadingWrapper"}>
          <Button
            onClick={() => {
              if (setIsError) setIsError(false);
              setIfFetchAgain(true);
            }}
          >
            <FontAwesomeIcon icon={faSyncAlt} />
            &nbsp;&nbsp;
            {t("something_went_wrong")}
          </Button>
        </div>
      ) : (
        <>
          {isLodadingProgress ? (
            <div
              className={
                !removeStyles
                  ? bigSize
                    ? "loadingWrapperBig"
                    : "loadingWrapper"
                  : undefined
              }
            >
              <CircularProgress size={size ? size : undefined} />
            </div>
          ) : (
            children
          )}
        </>
      )}
    </>
  );
};

export default LoadingWrapper;
