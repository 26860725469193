import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Training from "../../helpersComponents/Training/training";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import GuestForm from "./guestForm";

export default function TrainingRoot() {
  const { t } = useTranslation();
  const [training, setTraining] = useState<Training | null>(null);

  return (
    <div>
      <div>
        <TopBanner
          pathName={t("training_2")}
          showGoToLoginPage={true}
          hideLogOut={true}
        />
      </div>
      <div>
        {training && (
          <Training
            resultViewButtonClick={() => setTraining(null)}
            training={training}
          />
        )}
        {!training && <GuestForm setTraining={setTraining} />}
      </div>
    </div>
  );
}
