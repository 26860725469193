import React from "react";
import "./selectModule.scss";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { useHistory, withRouter } from "react-router-dom";
import {
  profileTranslationKeysDictionary,
  colorBackgroundClassNameDictionary,
} from "../ProfilesRouting/profilesDictionaries";
import { useAppDispatch } from "../../store/hooks";
import { setAuthUser } from "../../reducers/session";
import { useTranslation } from "react-i18next";
import { logIn } from "./logIn";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px",
  },
  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    maxHeight: "90vh",
    outline: "none",
    borderRadius: "5px",
    overflowY: "scroll",
  },
}));

function SelectModule(props) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const dictionaryProfilesType = {
    0: t("access_control"),
    1: t("work_time_registration"),
    2: t("access_control"),
    3: t("work_time_registration"),
    4: t("access_control"),
    5: t("notification_system"),
    6: t("notification_system"),
    7: t("notification_system"),
    8: t("notification_system"),
    9: t("notification_system"),
  };

  const body = (
    <div className={classes.paper}>
      <div className="moduleHeader">
        <div>{t("select_module")}</div>
        <div>{t("to_which_you_want_to_log_in")}</div>
      </div>
      <div className="modulesSelect">
        {props.profiles.map((profile, i) => {
          return (
            <div
              key={i}
              className={
                "selectElement " +
                colorBackgroundClassNameDictionary[profile.type]
              }
              onClick={() => {
                logIn({
                  token: props.token,
                  tokenDecoded: props.tokenDecoded,
                  selectedProfile: profile,
                  isChangingProfileOldProfileType: false,
                  isChangingProfileOldProfileSubjectId: false,
                  setAuthUserToRedux: (authUser) =>
                    dispatch(setAuthUser(authUser)),
                  resetReduxState: false,
                });

                if (props.previousPath) {
                  history.push(props.previousPath);
                }
              }}
            >
              <div>{dictionaryProfilesType[profile.type]}</div>

              <div>
                {t(profileTranslationKeysDictionary[profile.type])}
                {profile?.company ? ` - ${profile.company}` : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        className={classes.modal + " modalWidth"}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export default withRouter(SelectModule);
