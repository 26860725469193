import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { withStyles } from "@material-ui/styles";

const CustomTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "rgb(255, 255, 255);",
    // border: "1px solid rgb(163, 163, 163);",
    boxShadow: "1px -1px 7px -4px #000000;",
    maxWidth: 500,
  },
})(Tooltip);

const TooltipSchedule = ({
  children,
  tooltipBody,
}: {
  children: JSX.Element[] | JSX.Element;
  tooltipBody: JSX.Element;
}) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  return (
    <div className="tooltipEvents">
      <ClickAwayListener
        onClickAway={() => {
          setOpenTooltip(false);
        }}
      >
        <CustomTooltip
          title={tooltipBody}
          onClose={() => {
            setOpenTooltip(false);
          }}
          open={openTooltip}
          //   disableFocusListener
          //   disableHoverListener
          //   disableTouchListener
        >
          <div
            onClick={() => {
              setOpenTooltip(!openTooltip);
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
          >
            {children}
          </div>
        </CustomTooltip>
      </ClickAwayListener>
    </div>
  );
};

export default TooltipSchedule;
