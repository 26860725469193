import styles from "./advancedNavbar.module.scss";
import AdvancedNavbarMenuItems from "./AdvancedNavbarMenuItems";

const AdvancedNavbarContent = ({ menuData, depthLevel }) => {
  return (
    <nav className={styles["desktop-nav"]}>
      <ul className={styles["menus"]}>
        {menuData?.map((menu, index) => {
          return (
            <AdvancedNavbarMenuItems
              items={menu}
              key={index}
              depthLevel={depthLevel}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default AdvancedNavbarContent;
