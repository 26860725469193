import React from 'react';
import EventsContent from "./eventsContent"


function Events() {
    return (
        <div>
            <EventsContent/>
        </div>
    );
}

export default Events;
