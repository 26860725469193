import { ProfileType } from "../../../enums/profileType";
import ContentArrivalsOfShips from "../../helpersComponents/ArrivalsOfShips/ContentArrivalsOfShips";

function ArrivalsOfShipsShiftDirector() {
  return (
    <div>
      <ContentArrivalsOfShips
        pathApiArrivalsOfShips="shift-director/arrivals-of-ships"
        pathApiAttachmentsInPorts="shift-director/attachments-in-ports"
        layoutSettingsName="layoutShiftDirectorArrivalsOfShips"
        profileType={ProfileType.SHIFT_DIRECTOR}
      />
    </div>
  );
}

export default ArrivalsOfShipsShiftDirector;
