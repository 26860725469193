import React, { ReactElement, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import AddEditProjectDialogContent from "./addEditProjectDialogContent";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import useFetchGet from "../../../hooks/fetchHooks/get/useFetchGet";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";

interface Props {
  setIfFetchAgain: React.Dispatch<boolean>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  project?: any;
}

export default function AddEditProject({
  isOpen,
  setIfFetchAgain,
  setIsOpen,
  project,
}: Props): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [ifFetchProject, setIfFetchProject] = useState<boolean>(false);
  const [fetchedProject, setFetchedProject] = useState(false);

  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const projectByIdResponse = useFetchGet(
    `worker-time/${authUserId}/projects/${project?.id}`,
    ifFetchProject && project?.id ? true : false
  );
  useEffect(() => {
    if (project === undefined) return;
    if (isOpen) {
      setIsLoading(true);
      setIfFetchProject(true);
    } else {
      setIsLoading(false);
      setIfFetchProject(false);
    }
  }, [isOpen, project]);

  useEffect(() => {
    if (projectByIdResponse.error !== null) {
      setIsError(true);
      setIfFetchProject(false);
      return;
    }
    if (projectByIdResponse.data !== null) {
      setFetchedProject(projectByIdResponse.data);
      setIsLoading(false);
    }
  }, [projectByIdResponse]);

  return (
    <div>
      <Dialog maxWidth={"xl"} open={isOpen} onClose={() => setIsOpen(false)}>
        <LoadingWrapper
          isLodadingProgress={isLoading}
          isError={isError}
          setIfFetchAgain={setIfFetchProject}
          setIsError={setIsError}
          bigSize={true}
        >
          {!isLoading && !isError && (
            <AddEditProjectDialogContent
              setIsDialogOpen={setIsOpen}
              setIfFetchAgain={setIfFetchAgain}
              fetchedProject={fetchedProject}
            />
          )}
        </LoadingWrapper>
      </Dialog>
    </div>
  );
}
