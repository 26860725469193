import styles from "./styles.module.scss";

const GroupCellContent = ({ column, row, t }) => {
  if (row.groupedBy === "typeOfPerson") {
    let groupColor = "rgb(252, 206, 162)";
    if (row.value === t("guest")) {
      groupColor = "lightgreen";
    }
    return (
      <span
        style={{ backgroundColor: groupColor }}
        className={styles.firstLevelGroupSection}
      >
        <strong>{row.value}</strong>
      </span>
    );
  }
  let groupName = column.title;
  if (row.compoundKey.startsWith(t("guest"))) {
    groupName = t("company");
  } else if (row.compoundKey.startsWith(t("worker"))) {
    groupName = t("organizational_units");
  }

  return (
    <span>
      <span className={styles.secondLevelGroupSection}>{groupName}:</span>{" "}
      <strong>{row.value}</strong>
    </span>
  );
};
export default GroupCellContent;
