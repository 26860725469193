import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

const initialState: any = {
  advancedOptions: { ifAdvancedOptionsFetched: false },
};

export const advancedOptionsReducer = createSlice({
  name: "advancedOptions",
  initialState,
  reducers: {
    setAdvancedOptions: (state, action: PayloadAction<any>) => {
      state.advancedOptions = action.payload;
    },
  },
});

export const selectAdvancedOptions = (state: RootState) =>
  state.advancedOptionsState.advancedOptions;
export const { setAdvancedOptions } = advancedOptionsReducer.actions;
export default advancedOptionsReducer.reducer;
