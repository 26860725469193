import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React, { useRef, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { ProfileType } from "../../enums/profileType";
import useFetchPost from "../../hooks/fetchHooks/post/useFetchPost";
import useFetchPut from "../../hooks/fetchHooks/put/useFetchPut";
import { selectAuthUser } from "../../reducers/session";
import { selectSettings } from "../../reducers/settings";
import { useAppSelector } from "../../store/hooks";
import { profilesRoutesDictionary } from "../ProfilesRouting/profilesDictionaries";
import germany from "./flags/Germany.png";
import poland from "./flags/Poland.png";
import russia from "./flags/Russia.png";
import ukraine from "./flags/Ukraine.png";
import unitedKingdom from "./flags/United-Kingdom.png";
import "./selectLanguage.scss";
import useDisabledLanguauge from "./useDisabledLanguauge";

interface ILanguage {
  value: string;
  flag: string;
  alt: string;
}

const languagesList: ILanguage[] = [
  {
    value: "pl",
    flag: poland,
    alt: "Poland",
  },
  {
    value: "en",
    flag: unitedKingdom,
    alt: "UK",
  },
  {
    value: "ru",
    flag: russia,
    alt: "Russia",
  },
  {
    value: "de",
    flag: germany,
    alt: "Germany",
  },
  {
    value: "uk",
    flag: ukraine,
    alt: "Ukraine",
  },
];

function SelectLanguageContent(props) {
  const [open, setOpen] = useState(false);
  const anchorRef: any = useRef(null);
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);

  const [postRequestBody, setPostRequestBody] = useState<any>(false);
  const [putRequestBody, setPutRequestBody] = useState<any>(false);
  const selectedLanguage: any = props.i18n.language;

  let ifWorkerTimeId = "";
  if (authUser?.currentProfile?.type === ProfileType.WORKER_TIME) {
    ifWorkerTimeId = `/${authUser.currentProfile.subjectId}`;
  }
  let ifUserIsloggedIn = Object.keys(authUser).length === 0 ? false : true;
  const settings = useAppSelector(selectSettings);

  useFetchPost(
    `${profilesRoutesDictionary[authUser.currentProfile?.type]
    }${ifWorkerTimeId}/preferences`,
    postRequestBody
  );
  useFetchPut(
    `${profilesRoutesDictionary[authUser.currentProfile?.type]
    }${ifWorkerTimeId}/preferences/` + settings["web_jezyk"]?.id,
    putRequestBody
  );

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const changeLanguage = (language: string) => {
    props.i18n.changeLanguage(language);
  };

  const handleChangeLanguague = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    handleClose(event);
    changeLanguage(value);
    localStorage.setItem("languageAndOptionId", JSON.stringify([value, 0]));
    if (settings?.["web_jezyk"]) {
      if (!ifUserIsloggedIn) return;
      setPutRequestBody({
        timeWorkerId: authUser.currentProfile.subjectId,
        name: "web_jezyk",
        value: value,
      });
    } else {
      if (!ifUserIsloggedIn) return;
      setPostRequestBody({
        timeWorkerId: authUser.currentProfile.subjectId,
        name: "web_jezyk",
        value: value,
      });
    }
  };

  const isDisabledLanguage = useDisabledLanguauge();

  return (
    <div>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        className="dropDownName buttonSize"
        onClick={() => handleToggle()}
      >
        <img
          src={
            languagesList.find((lang) => lang.value === selectedLanguage)?.flag
          }
          alt={
            languagesList.find((lang) => lang.value === selectedLanguage)?.alt
          }
          className="selectedFlag"
        />
        <span className="fontColorNav">{t("select_language")}</span>
        <ArrowDropDownIcon />
      </Button>
      <Popper
        className="setInexZ"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                <MenuList
                  className="selectLanguage"
                  autoFocusItem={open}
                  id="menu-list-grow"
                >
                  {languagesList.map((lang) => {
                    return !isDisabledLanguage(lang.value) ? (
                      <MenuItem
                        onClick={(event) => {
                          handleChangeLanguague(event, lang.value);
                        }}
                      >
                        <img
                          src={lang.flag}
                          alt={lang.alt}
                          className="dropDownLink"
                        />
                      </MenuItem>
                    ) : (
                      <></>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default withTranslation()(SelectLanguageContent);
