import { useTranslation } from "react-i18next";
import { TextFieldStyled } from "../../../helpersComponents/MaterialUi/index";
import styles from "./project.module.scss";
import useFetchPost from "../../../../hooks/fetchHooks/post/useFetchPost";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSnackbar } from "notistack";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";

interface Props {
  projectId: number;
  setIfFetchAgain: (boolean) => void;
}

const AddStage = ({ projectId, setIfFetchAgain }: Props) => {
  const { t } = useTranslation();
  const [newStageName, setNewStageName] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const [postRequestBodyNewStage, setPostRequestBodyNewStage] =
    useState<any>(false);

  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  let postRequestNewStaget = useFetchPost(
    `worker-time/${authUserId}/projects/${projectId}/stages`,
    postRequestBodyNewStage
  );

  useEffect(() => {
    if (postRequestNewStaget.data?.status === 200) {
      enqueueSnackbar(t("stage_added"), { variant: "success" });
      setPostRequestBodyNewStage(false);
      setNewStageName("");
      setIfFetchAgain(true);
    } else if (postRequestNewStaget.data?.status === 400) {
      let error = "";
      Object.keys(postRequestNewStaget.data.errors).forEach((errorObject) => {
        postRequestNewStaget.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      enqueueSnackbar(error, { variant: "error" });
      setPostRequestBodyNewStage(false);
    } else if (postRequestNewStaget.error !== null) {
      setPostRequestBodyNewStage(false);
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
    }
  }, [postRequestNewStaget, setIfFetchAgain, enqueueSnackbar, t]);

  return (
    <>
      <div className={styles.addStageRoot}>
        <div className={styles.textFielContainter}>
          <TextFieldStyled
            onKeyPress={(ev) => {
              if (ev.key === "Enter" && newStageName !== "") {
                setPostRequestBodyNewStage({
                  name: newStageName,
                });
              }
            }}
            value={newStageName}
            label={t("add_new_stage")}
            width={"100%"}
            onChange={(e) => {
              setNewStageName(e.target.value);
            }}
          />
        </div>
        <div
          className={styles.addIcon}
          onClick={() => {
            if (newStageName === "") return;
            setPostRequestBodyNewStage({
              name: newStageName,
            });
          }}
        >
          <FontAwesomeIcon className={styles.addProjectIcon} icon={faPlus} />
        </div>
      </div>
    </>
  );
};

export default AddStage;
