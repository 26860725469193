import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import AddEditImagePopup from "./Popups/AddEditImagePopup";
import { formatSizeInBytes } from "../../../../HelpersFunctions/stringUtils";

const ImagesLibrarySMSAdministrator = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const [imageIdToRemove, setImageIdToRemove] = useState(false);

  const columns = [
    { name: "name", title: t("name") },
    { name: "fileName", title: t("file_name") },
    { name: "fileSize", title: t("file_size") },
    { name: "description", title: t("description") },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "editedBy", title: t("edited") },
    { name: "editedAt", title: t("data_edited"), type: "date_hours" },
  ];

  const modifyResponseCallback = useCallback((responseData) => {
    const modified = responseData.map((row) => {
      return {
        ...row,
        fileSize: formatSizeInBytes(row.fileSize),
      };
    });
    return modified;
  }, []);

  const [images, fetchingStateImages, fetchAgainImages] = useFetchAndSetGET({
    path: `sms-administrator/images-library`,
    modifyResponseCallback: modifyResponseCallback,
    startFetchOnInitial: true,
  });

  const [, fetchAgainRemoveImage] = useFetchOtherThanGET({
    path: `sms-administrator/images-library/${imageIdToRemove}`,
    method: "DELETE",
    body: imageIdToRemove,
    setBody: setImageIdToRemove,
    successCallback: fetchAgainImages,
  });

  useEffect(() => {
    if (imageIdToRemove) {
      fetchAgainRemoveImage();
    }
  }, [imageIdToRemove, fetchAgainRemoveImage]);

  return (
    <div>
      <TopBanner pathName={`${t("images")}`} />
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isLodadingProgress={fetchingStateImages.isFetching}
            isError={fetchingStateImages.isError}
            setIfFetchAgain={() => {
              fetchAgainImages();
            }}
          >
            {!fetchingStateImages.isFetching && (
              <TableComponent
                layoutSettingName="layoutSMSAdministratorImagesLibrary"
                rows={images || []}
                columns={columns}
                actionsColumnUpdated={{
                  popup: (props) => <AddEditImagePopup {...props} />,
                  successCallbackPopup: fetchAgainImages,
                  addButton: {
                    ifShow: true,
                  },
                  removeButton: {
                    ifShow: true,
                    name: t("delete"),
                    onClick: async (item) => {
                      let selectedbuttonId = await confirm({
                        text: `${t("confirm_delete_image")}`,
                        buttons: [
                          { buttonName: t("remove"), buttonId: 0 },
                          { buttonName: t("cancel"), buttonId: 1 },
                        ],
                      });

                      if ((await selectedbuttonId) === 0) {
                        setImageIdToRemove(item.id);
                      }
                    },
                  },
                  editButton: {
                    ifShow: true,
                    name: t("edit"),
                  },
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
};

export default ImagesLibrarySMSAdministrator;
