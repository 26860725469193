import { FormControlLabel } from "@material-ui/core";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AccordionStyled from "../../../../helpersComponents/MaterialUi/Accordion/AccordionStyled";
import MultiSelect from "../../../../helpersComponents/MultiSelect/MultiSelect";
import ButtonStyled from "../../../../helpersComponents/Button/button";
import { Switch, withStyles } from "@material-ui/core";
import { CheckboxStyled } from "../../../../helpersComponents/MaterialUi";
import styles from "./setting.module.scss";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";

const SwitchStyled = withStyles({
  switchBase: {
    color: "gray",
    "&$checked": {
      color: "#50B8CA",
    },
    "&$checked + $track": {
      backgroundColor: "#50B8CA",
    },
  },
  checked: {},
  track: {
    backgroundColor: "silver",
    "$checked$checked + &": {
      backgroundColor: "#50B8CA",
    },
  },
})(Switch);

const HourRequestsSettings: React.FC<any> = () => {
  const { t } = useTranslation();

  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );

  let isAdminProfile = false;

  let foundProfile = authUser.tokenDecoded.profiles.find(
    (el) => el.permissionId && (el.permissionId === 1 || el.permissionId === 2)
  );

  if (foundProfile) {
    isAdminProfile = true;
  }

  const [
    requestsSettingsAccordionExpanded,
    setRequestsSettingsAccordionExpanded,
  ] = useState<boolean>(false);

  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const [
    timeSuperiorRequestsTypesFilterEnabled,
    setTimeSuperiorRequestsTypesFilterEnabled,
  ] = useState(false);

  const [
    timeWorkerRequestsTypesFilterEnabled,
    setTimeWorkerRequestsTypesFilterEnabled,
  ] = useState(false);

  const [
    selectedRequestsTypesForTimeSuperiors,
    setSelectedRequestsTypesForTimeSuperiors,
  ] = useState<number[]>([]);

  const [
    selectedRequestsTypesForTimeWorkers,
    setSelectedRequestsTypesForTimeWorkers,
  ] = useState<number[]>([]);

  const [requestsTypes, requestsTypesFetchingState, requestsTypesFetchAgain] =
    useFetchAndSetGET<IHourRequestType[]>({
      path: `superior-time/hour-requests-types?filter=false`,
      startFetchOnInitial: false,
    });

  const [
    requestsSettings,
    requestsSettingsFetchingState,
    requestsSettingsFetchAgain,
  ] = useFetchAndSetGET<IHourRequestsSettings>({
    path: `superior-time/hour-requests-settings`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (requestsSettingsAccordionExpanded) {
      requestsTypesFetchAgain();
      requestsSettingsFetchAgain();
    }
  }, [
    requestsSettingsAccordionExpanded,
    requestsTypesFetchAgain,
    requestsSettingsFetchAgain,
  ]);

  useEffect(() => {
    if (
      !requestsTypesFetchingState.isFetching &&
      !requestsTypesFetchingState.response?.isError &&
      requestsTypesFetchingState.response?.status === 200 &&
      !requestsSettingsFetchingState.isFetching &&
      !requestsSettingsFetchingState.isError &&
      requestsSettingsFetchingState.response?.status === 200 &&
      !dataLoaded
    ) {
      setDataLoaded(true);
    }
  }, [requestsTypesFetchingState, requestsSettingsFetchingState, dataLoaded]);

  const [checkbox, setCheckbox] = React.useState({
    timeSuperiorDisplayOnlyRequestsOvertimeForDayReception: false,
    addingHourRequest: false,
    editingHourRequest: false,
    deletingHourRequest: false,
  });

  const handleChangeCheckbox = (event) => {
    setCheckbox({ ...checkbox, [event.target.name]: event.target.checked });
  };

  const [saveSettingsRequestBody, setSaveSettingsRequestBody] = useState<
    false | any
  >(false);

  const [, saveSettingsPutDataFetchAgain] = useFetchOtherThanGET({
    path: "superior-time/hour-requests-settings",
    method: "PUT",
    body: saveSettingsRequestBody,
    contentType: "application/json",
    setBody: setSaveSettingsRequestBody,
    forceSnackbar: true,
  });

  useEffect(() => {
    if (saveSettingsRequestBody) {
      saveSettingsPutDataFetchAgain();
    }
  }, [saveSettingsRequestBody, saveSettingsPutDataFetchAgain]);

  const saveSettings = useCallback(() => {
    let objToSend: IUpdateHourRequestsSettings = {
      timeSuperiorDisplayOnlyRequestsOvertimeForDayReception:
        checkbox.timeSuperiorDisplayOnlyRequestsOvertimeForDayReception,
      timeSuperiorRequestsTypesFilter: [],
      timeWorkerRequestsTypesFilter: [],
      canHREmployeeAddRequests: checkbox.addingHourRequest,
      canHREmployeeEditRequests: checkbox.editingHourRequest,
      canHREmployeeDeleteRequests: checkbox.deletingHourRequest,
    };

    if (
      requestsTypes &&
      requestsTypes.length > 0 &&
      selectedRequestsTypesForTimeSuperiors &&
      selectedRequestsTypesForTimeSuperiors.length > 0 &&
      timeSuperiorRequestsTypesFilterEnabled
    ) {
      objToSend.timeSuperiorRequestsTypesFilter =
        selectedRequestsTypesForTimeSuperiors;
    }

    if (
      requestsTypes &&
      requestsTypes.length > 0 &&
      selectedRequestsTypesForTimeWorkers &&
      selectedRequestsTypesForTimeWorkers.length > 0 &&
      timeWorkerRequestsTypesFilterEnabled
    ) {
      objToSend.timeWorkerRequestsTypesFilter =
        selectedRequestsTypesForTimeWorkers;
    }

    setSaveSettingsRequestBody(JSON.stringify(objToSend));
  }, [
    checkbox,
    selectedRequestsTypesForTimeSuperiors,
    selectedRequestsTypesForTimeWorkers,
    timeSuperiorRequestsTypesFilterEnabled,
    timeWorkerRequestsTypesFilterEnabled,
    requestsTypes,
  ]);

  useEffect(() => {
    if (dataLoaded === true) {
      setTimeSuperiorRequestsTypesFilterEnabled(
        requestsSettings.timeSuperiorRequestsTypesFilterEnabled
      );

      setTimeWorkerRequestsTypesFilterEnabled(
        requestsSettings.timeWorkerRequestsTypesFilterEnabled
      );

      if (
        requestsSettings.timeSuperiorRequestsTypesFilter &&
        requestsSettings.timeSuperiorRequestsTypesFilter.length > 0
      ) {
        setSelectedRequestsTypesForTimeSuperiors(
          requestsSettings.timeSuperiorRequestsTypesFilter
        );
      }

      if (
        requestsSettings.timeWorkerRequestsTypesFilter &&
        requestsSettings.timeWorkerRequestsTypesFilter.length > 0
      ) {
        setSelectedRequestsTypesForTimeWorkers(
          requestsSettings.timeWorkerRequestsTypesFilter
        );
      }

      setCheckbox({
        timeSuperiorDisplayOnlyRequestsOvertimeForDayReception:
          requestsSettings.timeSuperiorDisplayOnlyRequestsOvertimeForDayReception,
        addingHourRequest: requestsSettings.canHREmployeeAddRequests,
        editingHourRequest: requestsSettings.canHREmployeeEditRequests,
        deletingHourRequest: requestsSettings.canHREmployeeDeleteRequests,
      });
    }
  }, [dataLoaded, requestsSettings, requestsTypes]);

  if (!isAdminProfile) {
    return <></>;
  }

  return (
    <AccordionStyled
      title={t("applications_for_hour")}
      defaultExpanded={false}
      onChange={(event: ChangeEvent<{}>, expanded: boolean) => {
        setRequestsSettingsAccordionExpanded(expanded);
      }}
    >
      {dataLoaded && (
        <div>
          <div className={styles.settingsContainer}>
            <div className={styles.requestsTypesFilterContainer}>
              <div className={styles.requestsTypesFilterLabel}>
                {t("filter_requests_types_time_superior")}
              </div>
              <FormControlLabel
                control={
                  <CheckboxStyled
                    name="timeSuperiorDisplayOnlyRequestsOvertimeForDayReception"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    checked={
                      checkbox.timeSuperiorDisplayOnlyRequestsOvertimeForDayReception
                    }
                    onChange={handleChangeCheckbox}
                  />
                }
                label={
                  <span className="FormControlLabelStyle">
                    {t("only_requests_overtime_for_day_reception")}
                  </span>
                }
              />

              {!checkbox.timeSuperiorDisplayOnlyRequestsOvertimeForDayReception && (
                <div
                  className={styles.requestsTypesFilterTimeSuperiorContainer}
                >
                  <div className={styles.switchContainer}>
                    <div>{t("off").toUpperCase()}.</div>
                    <SwitchStyled
                      className={styles.switch}
                      checked={timeSuperiorRequestsTypesFilterEnabled}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => setTimeSuperiorRequestsTypesFilterEnabled(checked)}
                    />
                    <div>{t("on").toUpperCase()}.</div>
                  </div>

                  <div>
                    {!requestsTypesFetchingState.isFetching &&
                      timeSuperiorRequestsTypesFilterEnabled && (
                        <MultiSelect
                          label={""}
                          label_id="timeSuperiorRequestsTypes"
                          items={requestsTypes || []}
                          selectedItems={selectedRequestsTypesForTimeSuperiors}
                          setSelectedItems={
                            setSelectedRequestsTypesForTimeSuperiors
                          }
                          textColor={"gray"}
                        />
                      )}
                  </div>
                </div>
              )}

              <div className={styles.hr_requests}>{t("requests_hr")}</div>
              <div>
                <FormControlLabel
                  control={
                    <CheckboxStyled
                      name="addingHourRequest"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      checked={checkbox.addingHourRequest}
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label={
                    <span className="FormControlLabelStyle">{t("adding")}</span>
                  }
                />

                <FormControlLabel
                  control={
                    <CheckboxStyled
                      name="editingHourRequest"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      checked={checkbox.editingHourRequest}
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label={
                    <span className="FormControlLabelStyle">
                      {t("editing")}
                    </span>
                  }
                />

                <FormControlLabel
                  control={
                    <CheckboxStyled
                      name="deletingHourRequest"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      checked={checkbox.deletingHourRequest}
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label={
                    <span className="FormControlLabelStyle">
                      {t("deleting")}
                    </span>
                  }
                />
              </div>
            </div>

            <div className={styles.requestsTypesFilterContainer}>
              <div className={styles.requestsTypesFilterLabel}>
                {t("filter_requests_types_time_worker")}
              </div>
              <div className={styles.switchContainer}>
                <div>{t("off").toUpperCase()}.</div>
                <SwitchStyled
                  className={styles.switch}
                  checked={timeWorkerRequestsTypesFilterEnabled}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => setTimeWorkerRequestsTypesFilterEnabled(checked)}
                />
                <div>{t("on").toUpperCase()}.</div>
              </div>

              <div>
                {!requestsTypesFetchingState.isFetching &&
                  timeWorkerRequestsTypesFilterEnabled && (
                    <MultiSelect
                      label={""}
                      label_id="timeWorkerRequestsTypes"
                      items={requestsTypes || []}
                      selectedItems={selectedRequestsTypesForTimeWorkers}
                      setSelectedItems={setSelectedRequestsTypesForTimeWorkers}
                      textColor={"gray"}
                    />
                  )}
              </div>
            </div>
          </div>

          <div className={styles.saveChanges}>
            <ButtonStyled onClick={saveSettings} className={styles.saveButton}>
              {t("save")}
            </ButtonStyled>
          </div>
        </div>
      )}
    </AccordionStyled>
  );
};

export default HourRequestsSettings;
