import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  DatePickerFormik,
  TextFieldFormik,
} from "../../helpersComponents/FormikInputs";
import styles from "./styles.module.scss";
import ButtonStyled from "../../helpersComponents/Button/button";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import { useCallback, useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import erro400getTranslatedErrorString from "../../../HelpersFunctions/erro400getTranslatedErrorString";
import { useLocation } from "react-router-dom";

const initState: InitState = {
  firstName: "",
  lastName: "",
  email: "",
  dateOfBirth: null,
  captcha: "",
};

interface Props {
  setTraining: any;
}

export default function GuestForm({ setTraining }: Props) {
  const { t } = useTranslation();

  const [
    generateCaptcha,
    fetchingStateGenerateCaptcha,
    fetchAgainGenerateCaptcha,
  ] = useFetchAndSetGET({ path: "generate-captcha", ifBlob: true });

  const [getTrainingPath, setGetTrainingPath] = useState<string>("");
  const [startTrainingError, setstartTrainingError] = useState("");
  const handleErrors = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setGetTrainingPath("");
        setstartTrainingError(error);
        fetchAgainGenerateCaptcha();
      }
    },
    [t, fetchAgainGenerateCaptcha, setGetTrainingPath]
  );

  const [trainingData, fetchingStateTrainingData, fetchAgainTrainingData] =
    useFetchAndSetGET({
      path: getTrainingPath,
      startFetchOnInitial: false,
      errorCallback: handleErrors,
    });
  useEffect(() => {
    if (trainingData) {
      setTraining(trainingData);
    }
  }, [trainingData, setTraining]);

  useEffect(() => {
    if (getTrainingPath !== "" && startTrainingError === "") {
      fetchAgainTrainingData();
    }
  }, [getTrainingPath, startTrainingError, fetchAgainTrainingData]);

  const search = useLocation().search;
  const trainingName = new URLSearchParams(search).get("name");

  const confirmFetching = ({ values }: { values: InitState }) => {
    let path = `guest-training-exam?firstName=${values.firstName}&lastName=${values.lastName}`;
    path += `${
      values.dateOfBirth
        ? `&dateOfBirth=${convertDateFetch(values.dateOfBirth)}`
        : ""
    }`;
    path += `&email=${values.email}`;
    path += trainingName ? `&trainingName=${trainingName}` : "";
    path += `&captchCode=${values.captcha.toUpperCase()}`;

    setGetTrainingPath(path);
    setstartTrainingError("");
  };

  return (
    <div>
      <div className={"flexAndCenter"}>
        <div>
          <div>
            <Formik
              initialValues={initState}
              validationSchema={Yup.object({
                firstName: Yup.string().required(t("field_required")),
                lastName: Yup.string().required(t("field_required")),
                captcha: Yup.string().required(t("field_required")),
                dateOfBirth: Yup.string()
                  .nullable()
                  .test(
                    "isaValidBirthOfDate",
                    t("field_required"),
                    function (value) {
                      // if (
                      //   this.parent.dateOfBirth === null &&
                      //   this.parent?.email !== undefined
                      // ) {
                      //   return true;
                      // }

                      let yesterday = new Date();
                      yesterday.setDate(yesterday.getDate());
                      return Yup.object()
                        .nullable()
                        .shape({
                          date: Yup.date()
                            .typeError(t("invalid_date"))
                            .max(
                              yesterday,
                              t("date_must_be_earlier_than_today")
                            ),
                        })
                        .isValid({ date: value });
                    }
                  ),
                email: Yup.string()
                  .required(t("field_required"))
                  .test("isValidMail", t("invalid_email"), function (value) {
                    // if (
                    //   this.parent?.email === undefined &&
                    //   this.parent.dateOfBirth !== null &&
                    //   this.parent.dateOfBirth !== "Invalid Date"
                    // ) {
                    //   return true;
                    // }
                    return Yup.object()
                      .shape({
                        email: Yup.string()
                          .email(t("invalid_email"))
                          .required(t("field_required")),
                      })
                      .isValid({ email: value });
                  }),
              })}
              onSubmit={(values) => {
                confirmFetching({ values });
              }}
            >
              {({
                values,
                resetForm,
                handleChange,
                setFieldValue,
                ...props
              }) => (
                <Form noValidate>
                  <div className={styles.container}>
                    <div className={styles.titleInput}>
                      <div className={styles.title}>{t("first_name")}:</div>
                      <div className={styles.input}>
                        <TextFieldFormik
                          label={t("first_name")}
                          name="firstName"
                          type="text"
                          required={true}
                          ifToUpperCaseShipsAndPorts={true}
                        />
                      </div>
                    </div>

                    <div className={styles.titleInput}>
                      <div className={styles.title}>{t("last_name")}:</div>
                      <div className={styles.input}>
                        <TextFieldFormik
                          label={t("last_name")}
                          name="lastName"
                          type="text"
                          required={true}
                          ifToUpperCaseShipsAndPorts={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles.container}>
                    <div className={styles.titleInput}>
                      <div className={styles.title}>{t("date_of_birth")}:</div>
                      <div className={styles.input}>
                        <DatePickerFormik
                          label={t("date_of_birth")}
                          name="dateOfBirth"
                          view={["year", "month", "date"]}
                          format="dd-MM-yyyy"
                          required={true}
                        />
                      </div>
                    </div>

                    <div className={styles.titleInput}>
                      <div className={styles.title}>{t("email")}:</div>
                      <div className={styles.input}>
                        <TextFieldFormik
                          label={t("email")}
                          name="email"
                          type="text"
                          required={true}
                          ifToUpperCaseShipsAndPorts={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles.container}>
                    <div className={styles.titleInput}>
                      <div
                        className={styles.title}
                        onClick={() => {
                          setFieldValue(`captcha`, "");
                          props.setTouched({ captcha: false });
                          fetchAgainGenerateCaptcha();
                        }}
                      >
                        <div className={styles.captchaTitle}>
                          <div>{t("verification")}</div>
                          <div>
                            <FontAwesomeIcon
                              className={styles.faSyncAlt}
                              icon={faSyncAlt}
                            />{" "}
                          </div>
                        </div>
                      </div>
                      <div className={styles.input}>
                        <div className={styles.captchaInput}>
                          <LoadingWrapper
                            isLodadingProgress={
                              fetchingStateGenerateCaptcha.isFetching
                            }
                            isError={fetchingStateGenerateCaptcha.isError}
                            setIfFetchAgain={() => {
                              fetchAgainGenerateCaptcha();
                            }}
                            size={20}
                            removeStyles={true}
                          >
                            <div>
                              <img
                                src={generateCaptcha}
                                width={150}
                                height={48}
                                alt="Captcha"
                              />
                            </div>
                            <div>
                              <TextFieldFormik
                                label={t("enter_the_code")}
                                name="captcha"
                                type="text"
                                inputProps={{
                                  maxLength: 4,
                                  style: { textTransform: "uppercase" },
                                }}
                              />
                            </div>
                          </LoadingWrapper>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.bottomContainer}>
                    {fetchingStateTrainingData.isFetching &&
                    startTrainingError === "" ? (
                      <div>
                        <CircularProgress size={30} />
                      </div>
                    ) : (
                      <ButtonStyled type="submit">
                        <FontAwesomeIcon icon={faPlay} /> {t("start")}
                      </ButtonStyled>
                    )}
                    {startTrainingError && (
                      <span className={styles.error}>{startTrainingError}</span>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
