import { useCallback, useEffect, useRef, useState } from "react";
import doFetch from "./doFetch";
import useLogOut from "../../LogOut/logOut";

interface UseFetch {
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | false;
  path: string;
  startFetchOnInitial?: boolean;
  body?: any;
  debounce?: number;
  contentType?: string;
  ifBlob?: boolean;
}

const useFetch = ({
  method,
  path,
  startFetchOnInitial = true,
  body,
  contentType,
  debounce,
  ifBlob,
}: UseFetch): [
  (isFetching: boolean) => void,
  FetchingState,
  () => void,
  () => void
] => {
  const [isFetching, setIsFetching] = useState<boolean>(startFetchOnInitial);
  const [ifFetch, setIfFetch] = useState<boolean>(startFetchOnInitial);
  const [isError, setIsError] = useState<boolean>(false);
  const [response, setResponse] = useState<UseFetchResponse>();
  useLogOut(response?.status);
  const refTimerId = useRef<NodeJS.Timeout>();

  const fetchAgain = useCallback(() => {
    setIsFetching(true);
    setIfFetch(true);
    setIsError(false);
    setResponse(undefined);
  }, []);

  const clearData = useCallback(() => {
    setIsFetching(false);
    setIfFetch(false);
    setIsError(false);
    setResponse(undefined);
  }, []);

  useEffect(() => {
    if (!ifFetch) return;
    if (method === false) return;
    const abortController = new AbortController();

    let fetch = () => {
      doFetch(path, method, abortController, body, contentType, ifBlob)
        .then((response) => {
          if (abortController.signal.aborted) {
            return;
          }
          setIfFetch(false);
          setIsError(response.isError);
          setResponse(response);
        })
        .catch((e) => {
          if (abortController.signal.aborted) {
            return;
          }
          setIfFetch(false);
          setIsError(true);
          setResponse({ isError: true, resJson: null, status: 0 });
        });
    };

    if (debounce) {
      refTimerId.current = setTimeout(() => {
        fetch();
      }, debounce);
    } else {
      fetch();
    }

    return () => {
      if (refTimerId.current) {
        clearTimeout(refTimerId.current);
      }
      if (abortController.signal.aborted) {
        return;
      }
      abortController.abort();
    };
  }, [path, method, contentType, ifBlob, body, debounce, ifFetch]);

  return [
    setIsFetching,
    { isFetching, isError, response },
    fetchAgain,
    clearData,
  ];
};

export default useFetch;
