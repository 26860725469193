import React, { useState, useEffect } from "react";
import "./employeesContent.scss";
import { useTranslation } from "react-i18next";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import Button from "../../../helpersComponents/Button/button";
import { useHistory } from "react-router-dom";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import SelectMenu from "../../../helpersComponents/SelectMenu/selectMenu";
import WorkersSelectMenu from "./SelectMenus/WorkersSelectMenu";
import PopupGenerateEmployeesReport from "./Popups/PopupGenerateEmployeesReport";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";

const EmployeesContent = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const history = useHistory();
  const columns = [
    { name: "lastName", title: t("last_name") },
    { name: "firstName", title: t("first_name") },
    { name: "position", title: t("position") },
    { name: "organizationalUnits", title: t("departments") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "goToTheRecords", title: t("go_to_the_records") },
  ];

  let [timeWorkers] = useFetchAndSetGET({
    path: "superior-time/time-workers?source=TimeSuperiorTimeWorkers",
    startFetchOnInitial: true,
  });

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const [
    popupGenerateEmployeesReportParams,
    setPopupGenerateEmployeesReportParams,
  ] = useState<IGenerateEmployeesReportPopupParams>({
    isOpen: false,
    workersIds: [],
  });

  useEffect(() => {
    if (timeWorkers) {
      let rowsLocayly: any = [];
      timeWorkers.forEach((value, index) => {
        rowsLocayly.push({
          id: value.id,
          firstName: value.firstName,
          lastName: value.lastName,
          position: value.position,
          evidenceNumber: value.evidenceNumber,
          organizationalUnits: value.organizationalUnits,
          goToTheRecords: (
            <div className="goToTheRecordsContainer">
              <Button
                onClick={() => {
                  history.push({
                    pathname: "/superior-time/lists/workers-records",
                    state: { workerId: value.id },
                  });
                }}
              >
                {t("current_month")}
              </Button>
            </div>
          ),
        });
      });
      setRows(rowsLocayly);
    }
  }, [timeWorkers, history, t]);

  const columnVisibilityMenuOptions: {
    optionName: React.ReactElement;
    onClick: () => void;
  }[] = [];

  return (
    <>
      <TopBanner pathName={t("employees")} />
      <div className="flexAndCenter">
        <div>
          <SelectMenu
            name={t("columns_visibility")}
            ifNotCloseAfterSelectDisabled={true}
            items={columnVisibilityMenuOptions}
          />
        </div>
        <div>
          <TableComponent
            layoutSettingName="layoutSuperiorTimeAdministrationEmployees"
            rows={rows}
            columns={columns}
            ifEdit={false}
            ifAdd={false}
            ifDelete={false}
            pageSize={10}
            selectData={(ids) => setSelectedRows(ids as number[])}
            selectedData={selectedRows}
            filteringStateColumnExtensions={[
              { columnName: "goToTheRecords", filteringEnabled: false },
            ]}
            actionsColumnUpdated={{
              leftToolbarComponent: {
                ifShow: true,
                customComponent: () => {
                  return (
                    <WorkersSelectMenu
                      timeWorkersIds={selectedRows}
                      setTimeWorkersIds={setSelectedRows}
                      setPopupGenerateEmployeesReportParams={
                        setPopupGenerateEmployeesReportParams
                      }
                    />
                  );
                },
              },
            }}
          />
        </div>

        <div>
          {popupGenerateEmployeesReportParams.isOpen && (
            <PopupGenerateEmployeesReport
              popupEmployeesReportParams={popupGenerateEmployeesReportParams}
              closePopup={() =>
                setPopupGenerateEmployeesReportParams((prevState) => {
                  return {
                    ...prevState,
                    isOpen: false,
                  };
                })
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EmployeesContent;
