import React from 'react';
import NotificationGuestsContent from "./notificationGuestsContent"


function NotificationGuests() {
    return (
        <div>
            <NotificationGuestsContent/>
        </div>
    );
}

export default NotificationGuests;
