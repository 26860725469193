import {
  faFileAlt,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchFile from "../../../hooks/fetchHooks/get/useFetchFile";
import styles from "./attachments.module.scss";

export default function FilesListWithFetch({
  files,
  path,
  blockDownload,
}: {
  files: { id: number; fileName: string; type?: string; required?: boolean }[];
  path: string;
  blockDownload?: boolean;
}): ReactElement {
  const { t } = useTranslation();

  const [attachmentIdAndFileName, setAttachmentIdAndFileName] = useState<
    [false | string, string]
  >([false, ""]);
  const { data } = useFetchFile(path, attachmentIdAndFileName);
  useEffect(() => {
    if (data === null) return;
    setAttachmentIdAndFileName([false, ""]);
  }, [data]);

  return (
    <>
      {files
        ? files
            .sort((a, b) => Number(b.required) - Number(a.required))
            .map((file, index) => {
              let fileIcon = {
                icon: faFileAlt,
                iconClassName: "faFile",
              };
              let fileType = file.type;
              if (fileType === undefined) {
                fileType =
                  "." +
                  file.fileName.substr(file.fileName.lastIndexOf(".") + 1);
              }

              if (fileType === ".pdf")
                fileIcon = {
                  icon: faFilePdf,
                  iconClassName: "faFilePdf",
                };
              else if (fileType === ".xlsx")
                fileIcon = {
                  icon: faFileExcel,
                  iconClassName: "faFileExcel",
                };
              else if (fileType === ".png" || fileType === ".jpg")
                fileIcon = {
                  icon: faFileImage,
                  iconClassName: "faFileImage",
                };
              else if (fileType === ".7z")
                fileIcon = {
                  icon: faFolder,
                  iconClassName: "faFolder",
                };

              return (
                <div className={styles.singleFiles} key={index}>
                  <div
                    onClick={() => {
                      if (!blockDownload && path) {
                        setAttachmentIdAndFileName([
                          file.id.toString(),
                          file.fileName,
                        ]);
                      }
                    }}
                    className={
                      path && !blockDownload ? styles.fetchFileStyle : ""
                    }
                  >
                    <div>
                      <FontAwesomeIcon
                        className={fileIcon.iconClassName}
                        icon={fileIcon.icon}
                      />
                    </div>
                    <div className={`${file.required ? styles.required : ""}`}>
                      {file.fileName}
                    </div>
                    <div></div>
                  </div>
                </div>
              );
            })
        : null}
      {files?.length === 0 && <div>{t("none")}</div>}
    </>
  );
}
