import styles from "./attachments.module.scss";
import React, {
  InputHTMLAttributes,
  ReactElement,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Button from "../../helpersComponents/Button/button";

// interface SingleFileProps {
//   multiple?: false | undefined;
// }
interface Props extends InputHTMLAttributes<HTMLInputElement> {
  setSelectedFiles: React.Dispatch<File[]>;
  title?: string;
}

export default function SelectFileInput({
  setSelectedFiles,
  title,
  ...inputProps
}: Props): ReactElement {
  const { t } = useTranslation();
  const inputFileRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const [fileInputKey, setFileInputKey] = useState<number>(Date.now());

  return (
    <div className={styles.selectFileInput}>
      <input
        {...inputProps}
        ref={inputFileRef}
        key={fileInputKey}
        type="file"
        onChange={(e) => {
          if (!e.target.files) return;
          let files: File[] = [];
          Array.from(e.target.files).forEach((file) => {
            files.push(file);
          });
          setSelectedFiles(files);
          setFileInputKey(Date.now());
        }}
      />
      <Button
        onClick={() => {
          if (inputFileRef.current !== null) {
            inputFileRef.current.click();
          }
        }}
        type="button"
      >
        {title
          ? title
          : inputProps.multiple
          ? t("select_files")
          : t("select_file")}
      </Button>
    </div>
  );
}
