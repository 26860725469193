import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";
import styles from "../../Navbar/narrowNavbar.module.scss";
import NarrowNavTemplate from "../../Navbar/narrowNavTemplate";
import useNavLinksArray from "./navLinksArray";

const NarrowNavigationReception = (props) => {
  const settings = useAppSelector(selectSettings);
  const navLinksArray = useNavLinksArray();
  if (settings.ifSettingsFetched === false) return <div></div>;
  return (
    <>
      <div className={styles.narrowNavbar}>
        <div>
          {navLinksArray.routesArrayWithComponents.map((linksInfo, index) => {
            if (linksInfo.ifHidden) return null;
            return <NarrowNavTemplate key={index} linksInfo={linksInfo} />;
          })}
        </div>
      </div>
    </>
  );
};
export default NarrowNavigationReception;
