import {
  faExclamationTriangle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import { useTranslation } from "react-i18next";
import styles from "./HomeOfficeDayItem.module.scss";
import { useCallback, useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";

interface IProps {
  day: Date;
  removeDay: () => void;
  validationErrors: IHomeOfficeRequestsErrors[];
}

const HomeOfficeDayItem: React.FC<IProps> = ({
  day,
  removeDay,
  validationErrors,
}) => {
  const { t } = useTranslation();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    if (validationErrors) {
      for (let i = 0; i < validationErrors.length; i++) {
        let objectKeys = Object.keys(validationErrors[i]);
        if (objectKeys && objectKeys.length > 0) {
          let tempDay = new Date(objectKeys[0]);

          let excludedErrors: string[] = ["LIMIT_EXCEEDED"];
          let errors = validationErrors[i][objectKeys[0]];
          errors = errors.filter((el) => !excludedErrors.includes(el));

          if (
            day.getFullYear() === tempDay.getFullYear() &&
            day.getMonth() === tempDay.getMonth() &&
            day.getDate() === tempDay.getDate()
          ) {
            /*let tempErrorMessage: string[] = [];

            for (let j = 0; j < errors.length; j++) {
              //if (j > 0) tempErrorMessage += "<br>";
              tempErrorMessage += `<div>${t(errors[j])}</div>`;
            }*/

            //tempErrorMessage += "</div>";
            setErrorMessages(errors);
          }
        }
      }
    }
  }, [day, validationErrors, t]);

  const tooltipContent = useCallback(
    (rows) => {
      return (
        <div>
          {rows.map((el) => (
            <div>{t(el)}</div>
          ))}
        </div>
      );
    },
    [t]
  );

  return (
    <div>
      <span className={styles.dayItemContent}>
        {day ? toLocaleStringCustom({ date: day, t, ifShowHours: false }) : "-"}
      </span>

      <FontAwesomeIcon
        icon={faTrash}
        style={{ color: "red", marginRight: "10px", cursor: "pointer" }}
        onClick={() => {
          removeDay();
        }}
      />

      {errorMessages && errorMessages.length > 0 && (
        <Tooltip title={tooltipContent(errorMessages)}>
          <span>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ color: "red", marginRight: "10px", cursor: "pointer" }}
            />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default HomeOfficeDayItem;
