import { DialogContent } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TypeOfVisit } from "../../../Constants/typeOfVisitInPorts";
import { ProfileType } from "../../../enums/profileType";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../helpersComponents/PopupHelpers";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import styles from "./performerStyle.module.scss";

interface Props {
  feedbackData: FeedbackResponse[];
  setFeedbackData: React.Dispatch<FeedbackResponse[] | null>;
}

export default function FeedbackDialog({
  feedbackData,
  setFeedbackData,
}: Props): ReactElement {
  const { t } = useTranslation();
  const history = useHistory<string>();
  const [rows, setRows] = useState<
    | {
        id: number;
        notificationNumber: number;
        firstName: string;
        lastName: string;
        dateOfBirth: Date | null;
        email: string;
        information: JSX.Element;
      }[]
    | null
  >([]);

  const columns = [
    { name: "firstName", title: t("first_name") },
    { name: "lastName", title: t("last_name") },
    { name: "dateOfBirth", title: t("date_of_birth"), type: "date" },
    { name: "email", title: t("email") },
    { name: "information", title: t("information") },
  ];

  const authUser = useAppSelector(selectAuthUser);
  const currentProfile = authUser.currentProfile?.type;

  const [notificationNumber, setNotificationNumber] = useState<number | false>(
    false
  );

  useEffect(() => {
    if (feedbackData !== null) {
      let rowsLocal = feedbackData.map(
        (row: FeedbackResponse, index: number) => {
          return {
            id: index,
            notificationNumber: row.notificationNumber,
            firstName: row.firstName,
            lastName: row.lastName,
            dateOfBirth: row.dateOfBirth,
            email: row.email ? row.email : "-",
            information: (
              <>
                <p>
                  {!currentProfile
                    ? t("guest_identification_dct_gdansk_info")
                    : currentProfile === ProfileType.OPERATIONAL_SUPPORT
                    ? t(
                        "notification_sent_to_PFSO_and_ShiftDirector_for_approval"
                      )
                    : (currentProfile === ProfileType.OHS ||
                        currentProfile === ProfileType.EMPLOYEE ||
                        currentProfile === ProfileType.SUPERIOR_AC) &&
                      t("notification_sent_to_PFSO_for_approval")}
                </p>

                {!currentProfile &&
                  row.typeOfVisitId === TypeOfVisit.COMPLETION_OF_THE_WORK &&
                  row.trainingDateExpired && (
                    <p>{t("training_date_expired_dct_gdansk")}</p>
                  )}
              </>
            ),
          };
        }
      );
      setNotificationNumber(rowsLocal[0]?.notificationNumber);
      setRows(rowsLocal);
    } else {
      setRows(null);
    }
  }, [feedbackData, currentProfile, t]);

  return (
    <>
      <DialogStyled
        open={feedbackData !== null}
        onClose={() => setFeedbackData(null)}
      >
        <DialogTitleStyled
          title={`${t(
            "notification_number"
          ).toUpperCase()} [${notificationNumber}]`}
          className={styles.notificationNumberTitle}
        />

        <DialogContent>
          <TableComponentMemo
            layoutSettingName=""
            ifHideWholeToolbalr={true}
            rows={rows ? rows : []}
            columns={columns}
            columnsWidth={[{ columnName: "information", width: 600 }]}
          />
        </DialogContent>

        <DialogActionsStyled
          ifHideCancel={true}
          onConfirm={() => {
            if (history.location.state) {
              const locationState = history.location.state as any;
              history.push({
                pathname: locationState.successRedirectPath,
              });
            }
            setFeedbackData(null);
          }}
          onConfirmText={t("close")}
        />
      </DialogStyled>
    </>
  );
}
