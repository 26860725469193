import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import AddEditTask from "../SingleTaskComponents/addEditTask";
import styles from "./myTasks.module.scss";
import TasksCategory from "./tasksCategory";
import Button from "../../../helpersComponents/Button/button";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import { TextFieldStyled } from "../../../helpersComponents/MaterialUi";
import { InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";

export default function MyTasksContent(): ReactElement {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [ifFetch, setIfFetch] = useState<boolean>(true);
  const [projectList, setProjectList] = useState();
  const [filterTasks, setFilterTasks] = useState("");

  const [addTaskIsDialogOpen, setaddTaskIsDialogOpen] =
    useState<boolean>(false);
  const [categories, setCategories] = useState<
    { name: string; isExpanded: boolean; tasks: { [key in string]: any }[] }[]
  >([]);
  const [projects, setProjects] = useState<{
    [key in number]: {};
  }>({});

  const [photos, setPhotos] = useState<{
    [key in number]: string;
  }>({});

  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const projectsAndTasksResponse = useFetchGet(
    `worker-time/${authUserId}/projects-and-tasks`,
    ifFetch
  );

  useEffect(() => {
    if (projectsAndTasksResponse.error !== null) {
      setIsError(true);
      setIfFetch(false);
      return;
    }
    if (projectsAndTasksResponse.data !== null) {
      let projectsLocal = {};
      projectsAndTasksResponse.data.projects.forEach(
        (project) => (projectsLocal[project.id] = project)
      );

      interface categoryInterface {
        name: string;
        tasks: { [key in string]: any }[];
        isExpanded: boolean;
      }

      let categoriesLocal: {
        name: string;
        tasks: { [key in string]: any }[];
        isExpanded: boolean;
      }[] = [];

      let categoryEnding: categoryInterface = {
        name: t("ending"),
        tasks: [],
        isExpanded: false,
      };

      let categoryHighPriority: categoryInterface = {
        name: t("high_priority"),
        tasks: [],
        isExpanded: false,
      };

      let categoryDefaultProject: categoryInterface = {
        name: t("default_project"),
        tasks: [],
        isExpanded: false,
      };

      let categoryCompleted: categoryInterface = {
        name: t("completed"),
        tasks: [],
        isExpanded: false,
      };

      let categoryIntroduced: categoryInterface = {
        name: t("introduced"),
        tasks: [],
        isExpanded: false,
      };

      let categoryAssignedToMe: categoryInterface = {
        name: t("assigned_to_me"),
        tasks: [],
        isExpanded: false,
      };

      projectsAndTasksResponse.data.tasks.forEach((task) => {
        let ifAssignedToMe = task.collaborators.find(
          (collabo) => authUserId === collabo.timeWorkerId
        );
        if (ifAssignedToMe) {
          categoryAssignedToMe.tasks.push(task);
        }
        if (projectsLocal[task.projectId].isDefault)
          categoryDefaultProject.tasks.push(task);
        if (task.status === 1) categoryIntroduced.tasks.push(task);
        if (task.status === 2) categoryCompleted.tasks.push(task);
        if (
          task.status === 1 &&
          new Date(task.dateTo).getTime() - new Date().getTime() < 604800000 &&
          task.dateTo !== null
        )
          categoryEnding.tasks.push(task);

        if (task.priority === 2) categoryHighPriority.tasks.push(task);
      });
      if (categoryEnding.tasks.length > 0) {
        categoryEnding.isExpanded = true;
      } else {
        categoryEnding.isExpanded = true;
      }
      if (categoryHighPriority.tasks.length > 0) {
        categoryHighPriority.isExpanded = true;
      } else {
        categoryIntroduced.isExpanded = true;
      }

      categoriesLocal.push(categoryAssignedToMe);
      categoriesLocal.push(categoryDefaultProject);
      categoriesLocal.push(categoryEnding);
      categoriesLocal.push(categoryHighPriority);
      categoriesLocal.push(categoryIntroduced);
      categoriesLocal.push(categoryCompleted);

      categoriesLocal.push({
        name: t("all_tasks"),
        tasks: projectsAndTasksResponse.data.tasks,
        isExpanded: false,
      });
      setPhotos(projectsAndTasksResponse.data.photos);
      setProjectList(projectsAndTasksResponse.data.projects);
      setProjects(projectsLocal);
      setCategories(categoriesLocal);
      setIsLoading(false);
      setIfFetch(false);
    }
  }, [projectsAndTasksResponse, authUserId, t]);

  return (
    <div>
      <div>
        <TopBanner pathName={t("my_tasks")} />
      </div>
      <div className={styles.taskcontainer}>
        <LoadingWrapper
          isLodadingProgress={isLoading}
          isError={isError}
          setIfFetchAgain={setIfFetch}
          setIsError={setIsError}
          bigSize={true}
        >
          {!isLoading && !isError && (
            <>
              <div className={styles.toolbar}>
                <div>
                  <Button onClick={() => setaddTaskIsDialogOpen(true)}>
                    {t("add_task")}
                  </Button>
                  <AddEditTask
                    projectsList={projectList}
                    setIfFetchAgain={setIfFetch}
                    setIsDialogOpen={setaddTaskIsDialogOpen}
                    isDialogOpen={addTaskIsDialogOpen}
                  />
                </div>
                <div>
                  <TextFieldStyled
                    type="text"
                    label={t("search")}
                    onChange={(e) =>
                      setFilterTasks(e.target.value.toLowerCase())
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              {categories.map((category) => {
                let tasksFiltered = category.tasks.filter((task) => {
                  if (filterTasks.length > 2) {
                    if (
                      !`${task.ownerFirstName} ${task.ownerLastName}`
                        .toLowerCase()
                        .includes(filterTasks) &&
                      !task.name.toLowerCase().includes(filterTasks)
                    ) {
                      return false;
                    }
                  }
                  return true;
                });
                return (
                  <TasksCategory
                    key={category.name}
                    photos={photos}
                    projects={projects}
                    category={category}
                    tasksFiltered={tasksFiltered}
                    setIfFetchAgain={setIfFetch}
                    filterTasks={filterTasks}
                  />
                );
              })}
            </>
          )}
        </LoadingWrapper>
      </div>
    </div>
  );
}
