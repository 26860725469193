import { Table } from "@devexpress/dx-react-grid-material-ui";
import "./annualRecords.scss";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

const TableCell: any = Table.Cell;

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    //   height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "red",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1ab394",
  },
}))(LinearProgress);

const HighlightedCell = ({ value, row, style, ...restProps }) => {
  let splitedWorkingTime = value.split(":");
  let splitedWorkTimeStandard = row.workTimeStandard.split(":");
  let minutesWorkingTime =
    parseInt(splitedWorkingTime[0]) * 60 + parseInt(splitedWorkingTime[0]);
  let minutesWorkTimeStandard =
    parseInt(splitedWorkTimeStandard[0]) * 60 +
    parseInt(splitedWorkTimeStandard[0]);

  let progressStatus = Math.round(
    (minutesWorkingTime / minutesWorkTimeStandard) * 100
  );
  if (progressStatus > 100) progressStatus = 100;

  return (
    <TableCell {...restProps}>
      <div className={"cellComponentAnnual"}>
        {value}
        <BorderLinearProgress
          variant="determinate"
          value={progressStatus}
          valueBuffer={100}
        />
      </div>
    </TableCell>
  );
};

const Cell = (props) => {
  const { column } = props;
  if (column.name === "workingTime") {
    return <HighlightedCell {...props} />;
  }
  return <Table.Cell {...props} />;
};

export default Cell;
