import "./holiday.scss";
import React, { useCallback, useEffect, useState } from "react";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import AddEditHolideyRequestPopup from "./../../../helpersComponents/Requests/Holiday/AddEditHolidayRequestPopup";
import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import RowDetail from "./rowDetail";
import { statusDictionary } from "../../../../Constants/statusDictionary";
import { Button } from "@material-ui/core";
import useFetchPatch from "../../../../hooks/fetchHooks/patch/useFetchPatch";
import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import { SnackbarStyled } from "../../../helpersComponents/MaterialUi";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { useHistory } from "react-router-dom";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import { RequestStatus } from "../../../../Constants/requestStatus";
import { HolidayRequestTypeFunction } from "../../../../enums/holidayRequestTypeFunction";
import BackOffDaysFromHomeOfficePopup from "../../../helpersComponents/Requests/Holiday/BackOffDaysFromHomeOfficePopup";
import getDateWithoutTime from "../../../../HelpersFunctions/dateAndTime/getDateWithoutTime";
import { HolidayRequestDialogMode } from "../../../../enums/holidayRequestDialogMode";
import HolidayLimitBrief from "../../../helpersComponents/HolidayLimitBrief/holidayLimitBrief";
import { HolidayLimitBriefDisplayMode } from "../../../../enums/holidayLimitBriefDisplayMode";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { ProfileType } from "../../../../enums/profileType";
import { Button as MaterialUIButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

let Cell: any = TableEditColumn.Cell;

const ActionColumn: React.FC = ({
  children,
  row,
  classes,
  ...restProps
}: any) => {
  const { t } = useTranslation();
  let displayBackoffButton = (row: any) => {
    if (row) {
      let allowedRequestStatus: RequestStatus[] = [];
      allowedRequestStatus.push(RequestStatus.INTRODUCTED);
      allowedRequestStatus.push(RequestStatus.APPROVED);
      allowedRequestStatus.push(RequestStatus.FINALLY_APPROWED);
      const currentDate = getDateWithoutTime(new Date());
      const requestDateTo = getDateWithoutTime(new Date(row.dateTo));

      if (
        currentDate <= requestDateTo &&
        allowedRequestStatus.includes(row.status) &&
        (row.typyFunctionId === HolidayRequestTypeFunction.HOME_OFFICE ||
          row.typyFunctionId ===
            HolidayRequestTypeFunction.OCCASIONAL_HOME_OFFICE)
      ) {
        return true;
      }
    }

    return false;
  };

  if (row.status === 1 || row.status === 4) {
    return (
      <Cell
        row={restProps.row}
        className="tableCellEditCancelStyleGlobal"
        style={{ textAlign: "left" }}
      >
        {children}

        {displayBackoffButton(row) && (
          <MaterialUIButton
            style={{
              maxWidth: "30px",
              maxHeight: "30px",
              minWidth: "30px",
              minHeight: "30px",
            }}
            onClick={() => {
              if (restProps.setCurrentRow) {
                restProps.setCurrentRow(row);
              }

              if (restProps.setOpenBackOffDaysDialog) {
                restProps.setOpenBackOffDaysDialog(true);
              }
            }}
          >
            <FontAwesomeIcon
              icon={faArrowCircleLeft}
              size="lg"
              title={t("backoff_days")}
              className="backoffDaysIcon"
            />
          </MaterialUIButton>
        )}
      </Cell>
    );
  }
  return (
    <Cell row={restProps.row} style={{ textAlign: "left" }}>
      <span className="tableCellDisabledGlobal">{children}</span>

      {displayBackoffButton(row) && (
        <Button
          style={{
            color: "balck",
            fontWeight: "bold",
            maxWidth: "30px",
            maxHeight: "30px",
            minWidth: "30px",
            minHeight: "30px",
          }}
          onClick={() => {
            if (restProps.setCurrentRow) {
              restProps.setCurrentRow(row);
            }

            if (restProps.setOpenBackOffDaysDialog) {
              restProps.setOpenBackOffDaysDialog(true);
            }
          }}
        >
          <FontAwesomeIcon
            icon={faArrowCircleLeft}
            size="lg"
            title={t("backoff_days")}
            className="backoffDaysIcon"
          />
        </Button>
      )}
    </Cell>
  );
};

interface IParams {
  mode?: HolidayRequestDialogMode;

  holidayLimitBriefDisplayMode?: HolidayLimitBriefDisplayMode;
}
function HolidayContent({
  mode = HolidayRequestDialogMode.HOLIDAY_REQUEST,
  holidayLimitBriefDisplayMode = HolidayLimitBriefDisplayMode.NORMAL,
}: IParams) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1, 0, 0, 0)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31, 0, 0)
  );

  const [fetchAgain, setFetchAgain] = useState(true);
  const [dataModified, setDataModified] = useState<any>([]);
  const [isFetching, setIsFetching] = useState(true);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const settings = useAppSelector(selectSettings);

  const [isError, setIsError] = useState<boolean>(false);
  const { data, error } = useFetchGet(
    `worker-time/${authUserId}/holiday-requests?DateFrom=` +
      computeDateRangeFetch(startDate, "T00:00:00") +
      "&DateTo=" +
      computeDateRangeFetch(endDate),
    fetchAgain
  );

  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);
  const [newStatusBody, setNewStatusBody] = useState<any>(false);
  const [idUpdateStatus, setIdUpdateStatus] = useState();

  const responseStatusUpdate = useFetchPatch(
    `worker-time/${authUserId}/holiday-requests/` + idUpdateStatus,
    newStatusBody
  );

  const history: any = useHistory();
  const columns = [
    {
      name: "dateFrom",
      title: t("from_date"),
      type: "date_hours",
      customFormatter: (row) => (
        <span style={{ color: "green" }}>
          {toLocaleStringCustom({
            date: row.dateFrom,
            t,
            ifShowHours: row.dateFrom === row.dateTo ? false : true,
          })}
        </span>
      ),
    },
    {
      name: "dateTo",
      title: t("till_date"),
      type: "date_hours",
      customFormatter: (row) => (
        <span style={{ color: "red" }}>
          {toLocaleStringCustom({
            date: row.dateTo,
            t,
            ifShowHours: row.dateFrom === row.dateTo ? false : true,
          })}
        </span>
      ),
    },
    { name: "typeDescription", title: t("type_of_request") },
    { name: "statusTranslated", title: t("request_status") },
    { name: "rejectionReason", title: t("rejection_reason") },
    { name: "createdBy", title: t("created") },
    {
      name: "createdAt",
      title: t("data_created"),
      type: "date",
    },
  ];

  useEffect(() => {
    if (settings.WebWnioskiUrlopowePracownikDaneMiesieczne?.value === "1") {
      let currentDate = new Date();
      setStartDate(
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0)
      );
      setEndDate(new Date(getEndOfDate(currentDate, "Month")));
    }
  }, [settings.WebWnioskiUrlopowePracownikDaneMiesieczne?.value]);

  useEffect(() => {
    setIsError(false);
    setFetchAgain(true);
    setIsFetching(true); // circ
  }, [startDate, endDate]);

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setFetchAgain(false);
      return;
    }
    if (data === null) return;

    data.forEach((element, index) => {
      data[index].statusTranslated = t(statusDictionary[data[index].status]);
      data[index].createdAtString = new Date(element.createdAt).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );
      data[index].fromTime = new Date(element.dateFrom).toLocaleString(
        t("scheduler_language"),
        {
          hour: "2-digit",
          minute: "2-digit",
        }
      );
      data[index].tillHour = new Date(element.dateTo).toLocaleString(
        t("scheduler_language"),
        {
          hour: "2-digit",
          minute: "2-digit",
        }
      );

      data[index].dateToDetails = new Date(element.dateTo).toLocaleString(
        t("scheduler_language"),
        {
          weekday: "long",
          month: "long",
          year: "numeric",
          day: "2-digit",
        }
      );
      data[index].dateFromDetails = new Date(element.dateFrom).toLocaleString(
        t("scheduler_language"),
        {
          weekday: "long",
          month: "long",
          year: "numeric",
          day: "2-digit",
        }
      );
      data[index].creationDateDetails = new Date(
        element.createdAt
      ).toLocaleString(t("scheduler_language"), {
        weekday: "long",
        month: "long",
        year: "numeric",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      data[index].attachments = element.attachments;
    });

    setDataModified(data);
    setIsFetching(false);
    setFetchAgain(false);
  }, [data, error, t]);

  const [openBackOffDaysDialog, setOpenBackOffDaysDialog] = useState(false);
  const [currentRow, setCurrentRow] = useState<any>(null);

  const ActionColumnEnhanced: React.FC = (props) => {
    let newProps = {
      ...props,
      openBackOffDaysDialog,
      setOpenBackOffDaysDialog,
      setCurrentRow,
    };

    return ActionColumn(newProps);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    if (deleted) {
      let row: any = dataModified.find((row) => row.id === deleted[0]);
      setNewStatusBody([
        {
          op: "replace",
          path: "/status",
          value: 7,
        },
      ]);
      setIdUpdateStatus(row.id);
    } else if (added) {
      setFetchAgain(true);
      setSnackbarState([true, "success", t("application_added")]);
    } else if (changed) {
      setFetchAgain(true);
      setSnackbarState([true, "success", t("application_edited")]);
    }
    setIsFetching(true); // circ
  };

  useEffect(() => {
    if (responseStatusUpdate.data?.status === 200) {
      setSnackbarState([true, "success", t("application_canceled")]);
      setFetchAgain(true);
      setIsFetching(true); // circ
    } else if (responseStatusUpdate.data?.status === 400) {
      let error = "";
      Object.keys(responseStatusUpdate.data.errors).forEach((errorObject) => {
        responseStatusUpdate.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setIsFetching(false); // circ
      setSnackbarState([true, "error", error]);
    } else if (responseStatusUpdate.error !== null) {
      setIsFetching(false); // circ
      setSnackbarState([true, "error", t("something_went_wrong")]);
    }
    setNewStatusBody(false);
  }, [responseStatusUpdate, t]);

  const [holidaysLimits, setHolidaysLimits] =
    useState<TimeWorkerHolidayLimitItem[]>();

  const modifyTimeWorkersHolidaysLimitsInfoResponseCallback = useCallback(
    (responseData: TimeWorkerHolidaysLimits): TimeWorkerHolidayLimitItem[] => {
      let tempResponse: any = responseData ? responseData.limits : [];
      setHolidaysLimits(tempResponse);
      return tempResponse;
    },
    []
  );

  const [, ,] = useFetchAndSetGET({
    path: `worker-time/${authUserId}/holiday-limits-info`,
    modifyResponseCallback: modifyTimeWorkersHolidaysLimitsInfoResponseCallback,
    startFetchOnInitial: true,
  });

  const profileType = useAppSelector(selectAuthUser).currentProfile.type;

  const modifyTypesOfRequestsResponseCallback = useCallback(
    (responseData) => {
      let allowedRequestsTypeFunctions = [
        HolidayRequestTypeFunction.HOME_OFFICE,
        HolidayRequestTypeFunction.OCCASIONAL_HOME_OFFICE,
        HolidayRequestTypeFunction.PRIVILEGED_HOME_OFFICE,
      ];

      if (mode === HolidayRequestDialogMode.HOME_OFFICE_REQUEST) {
        return responseData.filter((el) =>
          allowedRequestsTypeFunctions.includes(el.function)
        );
      }

      return responseData;
    },
    [mode]
  );

  const [requestsTypes] = useFetchAndSetGET({
    path:
      profileType === ProfileType.SUPERIOR_TIME
        ? `superior-time/holiday-requests-types`
        : `worker-time/${authUserId}/holiday-requests-types`,
    startFetchOnInitial: true,
    modifyResponseCallback: modifyTypesOfRequestsResponseCallback,
  });

  const [
    occasionalHomeOfficeRequestTypeId,
    setOccasionalHomeOfficeRequestTypeId,
  ] = useState<number>(-1);

  useEffect(() => {
    if (
      mode === HolidayRequestDialogMode.HOME_OFFICE_REQUEST &&
      requestsTypes
    ) {
      let foundType = requestsTypes.find(
        (el) =>
          el.function === HolidayRequestTypeFunction.OCCASIONAL_HOME_OFFICE
      );

      if (foundType && holidaysLimits) {
        let foundHolidayLimit = holidaysLimits?.find(
          (el) => el.absenceTypeId === foundType.id
        );
        if (foundHolidayLimit) {
          setOccasionalHomeOfficeRequestTypeId(foundHolidayLimit.absenceTypeId);
        }
      }
    }
  }, [mode, requestsTypes, holidaysLimits]);

  return (
    <>
      <TopBanner
        pathName={
          mode === HolidayRequestDialogMode.HOLIDAY_REQUEST
            ? t("holiday_applications")
            : t("home_office_requests")
        }
      />
      <div className="flexAndCenter">
        <div>
          {mode === HolidayRequestDialogMode.HOME_OFFICE_REQUEST && (
            <HolidayLimitBrief
              year={new Date(endDate).getFullYear()}
              timeWorkerId={authUserId}
              size={"small"}
              hideTop={true}
              selectedRequestType={occasionalHomeOfficeRequestTypeId}
              remainedLabel={t("left")}
              addVacationButton={true}
              displayMode={
                HolidayLimitBriefDisplayMode.ONLY_OCCASIONAL_HOME_OFFICE
              }
            />
          )}
        </div>
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
          ></DatePickerRange>
        </div>
        <div>
          <LoadingWrapper
            isLodadingProgress={isFetching}
            isError={isError}
            setIfFetchAgain={setFetchAgain}
            setIsError={setIsError}
          >
            {!isFetching && !isError && (
              <TableComponent
                title={
                  mode === HolidayRequestDialogMode.HOLIDAY_REQUEST
                    ? t("list_of_holidays_requests")
                    : t("home_office_requests_list")
                }
                layoutSettingName="layoutWorkerTimeRequestsHoliday"
                rows={dataModified}
                columns={columns}
                popup={AddEditHolideyRequestPopup}
                popupAdditionalParams={{ mode }}
                ifEdit={true}
                ifAdd={true}
                ifDelete={true}
                deleteCommandName={t("delete")}
                commitChanges={commitChanges}
                rowDetail={RowDetail}
                actionColumn={ActionColumnEnhanced}
                openPopup={history.location.state ? true : false}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />

      <BackOffDaysFromHomeOfficePopup
        row={currentRow}
        open={openBackOffDaysDialog}
        onApplyChanges={() => {
          setOpenBackOffDaysDialog(false);
          setCurrentRow(null);
          setFetchAgain(true);
        }}
        onCancelChanges={() => {
          setOpenBackOffDaysDialog(false);
          setCurrentRow(null);
        }}
      />
    </>
  );
}

export default HolidayContent;
