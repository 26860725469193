import GuestsVisitsReportContent from "../../helpersComponents/GuestsVisitsReport/GuestsVisitsReportContent";

function GuestsVisitsReportOhs() {
  return (
    <div>
      <GuestsVisitsReportContent
        pathName="ohs/guest-visits"
        layoutSettingName="layoutOhsVisitsReport"
        pathApiAttachments="ohs/attachments-in-ports"
      />
    </div>
  );
}

export default GuestsVisitsReportOhs;
