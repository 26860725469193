import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../helpersComponents/PopupHelpers";
import PopupContentBusinessExists from "./PopupContentBusinessExists";

interface Props {
  isOpen: boolean;
  rowId: number | undefined;
  closePopup: () => void;
  successCallback: () => void;
}

const BusinessExitsPopup: React.FC<Props> = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
}) => {
  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [requestBody, setRequestBody] = useState<false | any>(false);

  const onSubmit = (values: { purposeOfExit: string }) => {
    setErrorMessage("");
    setRequestBody(
      JSON.stringify([
        {
          op: "replace",
          path: "/purposeOfExit",
          value: values.purposeOfExit,
        },
      ])
    );
  };

  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `worker-time/${authUserId}/business-exits/` + rowId,
    method: "PATCH",
    body: requestBody,
    contentType: "application/json",
    setBody: setRequestBody,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, fetchAgainPostData]);

  return (
    <DialogStyled open={isOpen} onClose={closePopup}>
      <DialogTitleStyled title={t("business_exits")} />
      <PopupContentBusinessExists rowId={rowId} onSubmit={onSubmit} />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default BusinessExitsPopup;
