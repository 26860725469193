import { useEffect, useReducer, useRef } from "react";
import fetchPutFormData from "./fetchPutFormData";
import useLogOut from "../../LogOut/logOut";

const useFetchPutFormDataReducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { data: null, isLoading: true, error: null };
    case "SUCCESS":
      return { data: action.payload, isLoading: false, error: null };
    case "ERROR":
      return { data: null, isLoading: false, error: action.payload };
    case "RESET":
      return { data: null, isLoading: null, error: null };
  }
  return state;
};

const useFetchPutFormData = (
  path: string,
  bodyPost: any,
  ifResetReturnedValue: boolean = false
) => {
  const [response, dispatch] = useReducer(useFetchPutFormDataReducer, {
    data: null,
    isLoading: true,
    error: null,
  });
  useLogOut(response.error);
  let isMounted = useRef(true);

  useEffect(() => {
    if (ifResetReturnedValue) dispatch({ type: "RESET" });
  }, [ifResetReturnedValue]);

  useEffect(() => {
    if (!bodyPost) return;

    dispatch({ type: "FETCHING" });
    fetchPutFormData(path, bodyPost)
      .then((data) => {
        if (isMounted.current) {
          dispatch({ type: "SUCCESS", payload: data });
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          dispatch({ type: "ERROR", payload: error });
        }
      });
  }, [path, bodyPost]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return response;
};

export default useFetchPutFormData;
