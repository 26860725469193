import React, { useState, useEffect } from "react";
import "./annualRecords.scss";
// import StyledBlock from "../../../helpersComponents/StyledBlock/styledBlock"
import { DatePickerStyled } from "../../../helpersComponents/MaterialUi/index";
// import Button from "../../Helpers/Button/button"
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import computeDate from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import CellComponent from "./cellComponent";
import RowDetail from "./rowDetail";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";

function AnnualRecordsContent() {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  const [rows, setRows] = useState<object[]>([]);
  const columns = [
    { name: "month", title: t("month") },
    { name: "workingTime", title: "CP" },
    { name: "normativeTime", title: "CN" },
    // { name: 'businessEntriesExits', title: 'WS' },
    { name: "workTimeStandard", title: "NR" },
    { name: "overTime50", title: "50" },
    { name: "overTime100", title: "100" },
    { name: "nightWork", title: "PN" },
    { name: "absences", title: "N" },
    // { name: 'late', title: 'SP' },

    // { name: 'firstName', title: 'firstName' },
    // { name: 'languageLearningHours', title: 'languageLearningHours' },
    // { name: 'lastName', title: 'lastName' },
    // { name: 'socialBreak', title: 'socialBreak' },
    // { name: 'timeSpentOnThePremisesOfTheCompany', title: 'timeSpentOnThePremisesOfTheCompany' },
    // { name: 'workAboveNorm', title: 'workAboveNorm' },
    // { name: 'workDuringDuty', title: 'workDuringDuty' },
    // { name: 'workOfAdolescentsInForbiddenWork', title: 'workOfAdolescentsInForbiddenWork' },
    // { name: 'workOnDaysOff', title: 'workOnDaysOff' },
    // { name: 'workOnHolidays', title: 'OwneworkOnHolidaysr' },
    // { name: 'workOnSundaysAndHolidays', title: 'workOnSundaysAndHolidays' },
  ];

  const [isFetching, setIsFetching] = useState(true);

  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const { data, error } = useFetchGet(
    `worker-time/${authUserId}/summaries?DateFrom=` +
      computeDate(new Date(date?.getFullYear(), 0, 1, 0, 0, 0)) +
      "&DateTo=" +
      computeDate(
        new Date(date?.getFullYear(), 11, 31, 23, 59, 59),
        "T23:59:59"
      ) +
      "&GroupBy=month" +
      "&TimeWorkerId=" +
      JSON.parse(localStorage.getItem("authUser") || "{}").currentProfile
        .subjectId,
    ifFetchAgain
  );

  useEffect(() => {
    setIfFetchAgain(true);
    setIsFetching(true);
  }, [date]);

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setIfFetchAgain(false);
      return;
    }
    if (data === null) return;

    let dataModified: any = [];
    let id = 0;
    data.forEach((value, index) => {
      let modifiedElement = {
        id: id,
        month: new Date(value.date).getMonth() + 1,
        workingTime: convertMinsToHrsMins(value.workingTime),
        workTimeStandard: convertMinsToHrsMins(value.workTimeStandard),
        businessEntriesExits: convertMinsToHrsMins(value.businessEntriesExits),
        normativeTime: convertMinsToHrsMins(value.normativeTime),
        overTime50: convertMinsToHrsMins(value.overTime50),
        overTime100: convertMinsToHrsMins(value.overTime100),
        nightWork: convertMinsToHrsMins(value.nightWork),
        absences: convertMinsToHrsMins(value.absences),
        late: convertMinsToHrsMins(value.late),
      };

      dataModified.push(modifiedElement);
      id++;
    });
    setIsError(false);
    setIfFetchAgain(false);
    setRows(dataModified);
    setIsFetching(false);
  }, [data, error]);

  return (
    <>
      <TopBanner pathName={t("annual_records")} />
      <div className="flexAndCenter">
        <div className="selectDateDarkGlobal">
          <div className="selectYear">
            <div className="inputTitleAnnualRecords">{t("year")}</div>
            <div className="selectYearInputAnnualRecords">
              <DatePickerStyled
                view={["year"]}
                format="yyyy"
                date={date}
                setDate={setDate}
                darkTheme={true}
                width={"100%"}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <LoadingWrapper
              isLodadingProgress={isFetching}
              isError={isError}
              setIfFetchAgain={setIfFetchAgain}
              setIsError={setIsError}
            >
              {!isFetching && !isError && (
                <TableComponent
                  layoutSettingName="layoutWorkerTimeSummaryAnnualRecords"
                  rows={rows}
                  columns={columns}
                  // columnOrder={this.state.columnOrder}
                  ifEdit={false}
                  ifAdd={false}
                  ifDelete={false}
                  tableCellComponent={CellComponent}
                  rowDetail={RowDetail}
                  pageSize={12}
                />
              )}
            </LoadingWrapper>
          </div>
        </div>
        <div>
          <div className="legend">
            <div>{t("legend")}</div>
            <div>{t("cp")}</div>
            <div>{t("cn")}</div>
            <div>{t("ws")}</div>
            <div>{t("nr")}</div>
            <div>{t("50")}</div>
            <div>{t("100")}</div>
            <div>{t("pn")}</div>
            <div>{t("n")}</div>
            <div>{t("sp")}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnnualRecordsContent;
