export enum RequestStatus {
  INTRODUCTED = 1,
  APPROVED = 2,
  FINALLY_APPROWED = 3,
  TO_CHANGE = 4,
  REJECTED = 5,
  FINALLY_REJECTED = 6,
  CANCELED = 7,

  APPROVED_1 = 101,
  APPROVED_2 = 102,
  APPROVED_3 = 103,
  APPROVED_4 = 104,
  APPROVED_5 = 105,
  APPROVED_6 = 106,
  APPROVED_7 = 107,
  APPROVED_8 = 108,
  APPROVED_9 = 109,
  APPROVED_10 = 110,
}
