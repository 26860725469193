import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import convertSecondsToDaysHrsMinsSeconds from "../../../HelpersFunctions/dateAndTime/convertSecondsToDaysHrsMinsSeconds";
import styles from "./projects.module.scss";

interface Props {
  tasksNumber: number;
  durationTimeTasksSeconds: number;
}

export default function TasksNumberAndDurationTime({
  tasksNumber,
  durationTimeTasksSeconds,
}: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <div className={styles.tasksNumberAndDurationTimeRoot}>
      <div>
        <b>{t("tasks")}:</b> {tasksNumber}
      </div>
      &nbsp;-&nbsp;
      <div>
        <b>{t("duration_time")}:</b>{" "}
        {convertSecondsToDaysHrsMinsSeconds(durationTimeTasksSeconds, t, {
          ifHideSeconds: true,
        })}
      </div>
    </div>
  );
}
