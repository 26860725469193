import { useEffect, useState } from "react";
import useFetch from "../useFetch/useFetch";

interface Props {
  path: string;
  modifyResponseCallback?: (resJson: any, fetchAgain: () => void) => any;
  ifBlob?: boolean;
  contentType?: string;
  startFetchOnInitial?: boolean;
  errorCallback?: (response?: UseFetchResponse) => void;
}

export default function useFetchAndSetGET<T = any>({
  path,
  modifyResponseCallback,
  ifBlob,
  contentType,
  startFetchOnInitial,
  errorCallback,
}: Props): [
  T,
  {
    isFetching: boolean;
    isError: boolean;
    response: UseFetchResponse | undefined;
  },
  () => void
] {
  const [responseData, setResponseData] = useState<any | null>(null);
  let [setIsFetching, fetchingState, fetchAgain] = useFetch({
    method: "GET",
    path: path,
    startFetchOnInitial:
      startFetchOnInitial !== undefined ? startFetchOnInitial : true,
    ifBlob,
    contentType,
  });

  useEffect(() => {
    if (fetchingState.isError) {
      if (errorCallback) errorCallback(fetchingState.response);
      return;
    }
    if (fetchingState.response === undefined) return;
    let data: any = fetchingState.response.resJson;
    if (modifyResponseCallback)
      setResponseData(modifyResponseCallback(data, fetchAgain));
    else setResponseData(data);
    setIsFetching(false);
  }, [
    fetchingState.response,
    modifyResponseCallback,
    fetchingState.isError,
    setIsFetching,
    fetchAgain,
    errorCallback,
  ]);

  return [responseData, fetchingState, fetchAgain];
}
