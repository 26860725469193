import React from "react";
import ScheduleInCalendarContent from "./scheduleInCalendarContent";

function ScheduleInCalendar() {
  return (
    <div>
      <ScheduleInCalendarContent />
    </div>
  );
}

export default ScheduleInCalendar;
