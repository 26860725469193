import {
  faExclamationCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArrayRenderProps, FormikErrors } from "formik";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteFormik, DatePickerFormik } from "../../FormikInputs";
import {
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../PopupHelpers";
import styles from "../styles.module.scss";

interface Props {
  formFields: FormFields;
  formikErrors: FormikErrors<{
    [x: string]: string | boolean | never[] | null | string[];
  }>;
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  policiesList: Policy[];
}

const PasswordPolicyForm = ({
  formFields,
  formikErrors,
  index,
  arrayHelpers,
  policiesList,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const {
    passwordPoliciesArray,
    passwordPolicy,
    passwordPolicyDateFrom,
    passwordPolicyDateTo,
  } = formFields;

  const keyErrorName = passwordPoliciesArray.name;
  const datesOverlapError = formikErrors[keyErrorName]
    ? formikErrors[keyErrorName]![index] === "POLICIES_ERROR_DATES"
    : false;

  return (
    <>
      <div className={styles.passwordPoliciesContainer}>
        <b>
          {t("policy")} {index + 1}
        </b>
        <FontAwesomeIcon
          className="faTrash"
          icon={faTimesCircle}
          onClick={() => {
            arrayHelpers.remove(index);
          }}
        />
      </div>

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>{t(passwordPolicy.label)}:</RowTitle>
          <RowInput>
            <AutocompleteFormik
              options={policiesList}
              getOptionLabel={(option: Policy) => {
                return option.name;
              }}
              width="100%"
              getOptionSelected={(option: Policy, value: Policy) => {
                return option.id === value.id;
              }}
              label={t(passwordPolicy.label)}
              name={`${passwordPoliciesArray.name}.${index}.${passwordPolicy.name}`}
              required={true}
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>{t(passwordPolicyDateFrom.label)}:</RowTitle>
          <RowInput>
            <DatePickerFormik
              label={t(passwordPolicyDateFrom.label)}
              name={`${passwordPoliciesArray.name}.${index}.${passwordPolicyDateFrom.name}`}
              view={["year", "month", "date"]}
              format="dd-MM-yyyy"
              required={true}
            />
          </RowInput>
        </Row>
        <Row>
          <RowTitle width={180}>{t(passwordPolicyDateTo.label)}:</RowTitle>
          <RowInput>
            <DatePickerFormik
              label={t(passwordPolicyDateTo.label)}
              name={`${passwordPoliciesArray.name}.${index}.${passwordPolicyDateTo.name}`}
              view={["year", "month", "date"]}
              format="dd-MM-yyyy"
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      {datesOverlapError && (
        <div className={styles.policyErrorContainer}>
          <div>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              size={"lg"}
              style={{ color: "#CC0000" }}
            />
          </div>
          <div>
            <p>{t("policies_dates_overlap")}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default PasswordPolicyForm;
