import React from "react";
import { ProfileType } from "../../../enums/profileType";
import ContentArrivalsOfShips from "../../helpersComponents/ArrivalsOfShips/ContentArrivalsOfShips";

const ArrivalsOfShipAgent = () => {
  return (
    <ContentArrivalsOfShips
      pathApiArrivalsOfShips="agent/arrivals-of-ships"
      pathApiAttachmentsInPorts="agent/attachments-in-ports"
      pathApiImportFromFile="agent/arrivals-of-ships/import"
      pathApiShips="agent/ships"
      layoutSettingsName="layoutAgentArrivalOfShipRecords"
      profileType={ProfileType.AGENT}
    />
  );
};

export default ArrivalsOfShipAgent;
