import React from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import { TrainingsList } from "./trainingsList";

function Trainings() {
  const { t } = useTranslation();
  return (
    <div>
      <TopBanner pathName={t("trainings")} />
      <TrainingsList />
    </div>
  );
}

export default Trainings;
