import React, { ReactElement } from "react";
import styles from "./attachments.module.scss";
import Button from "../Button/button";
import { useTranslation } from "react-i18next";

interface Props {
  file: File;
  fileIndex: number;
  allFiles: File[];
  setUpadteAllFilesAfterDelete: React.Dispatch<File[]>;
  ifHideFileSize?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export default function DisplaySingleFileWithDeleteButton({
  file,
  allFiles,
  fileIndex,
  ifHideFileSize,
  setUpadteAllFilesAfterDelete,
  onClick,
  disabled = false,
}: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <div className={styles.singleFile}>
      <div>
        <b>{file?.name}</b>{" "}
        {!ifHideFileSize && <>{(file?.size * 0.001).toFixed(2)} kB</>}
      </div>
      <div
        onClick={() => {
          if (!disabled) {
            let newFiles = [...allFiles];
            newFiles.splice(fileIndex, 1);
            setUpadteAllFilesAfterDelete(newFiles);
            if (onClick) {
              onClick();
            }
          }
        }}
      >
        <Button type="button" disabled={disabled}>
          {t("delete")}
        </Button>
      </div>
    </div>
  );
}
