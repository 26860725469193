import { ProfileType } from "../../../enums/profileType";
import ContentWhiteListGuests from "../../helpersComponents/WhiteList/WhiteListGuests/ContentWhiteListGuests";

const WhiteListGuestsPfso = () => {
  return (
    <ContentWhiteListGuests
      pathWhiteListGuests="pfso/white-list-guests"
      pathCompaniesInPorts="pfso/companies-in-ports"
      pathGuests="pfso/guests"
      pathPermissionZones="pfso/permission-zones"
      layoutSettingName="layoutPfsoWhiteListGuestsRecords"
      profileType={ProfileType.PFSO}
    />
  );
};

export default WhiteListGuestsPfso;
