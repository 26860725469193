import { CircularProgress } from "@material-ui/core";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypeOfCompanyInPorts } from "../../../enums/typeOfCompanyInPorts";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import Button from "../../helpersComponents/Button/button";
import { TextFieldStyled } from "../../helpersComponents/MaterialUi/index";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../helpersComponents/PopupHelpers";
import styles from "./formAddNewCompanyStyle.module.scss";

interface IProps {
  typeOfCompanyInPorts?: TypeOfCompanyInPorts;
  addCompanyPath: string;
  setShowAddCompanyForm: Dispatch<SetStateAction<boolean>>;
  setAddedCompanyId: Dispatch<SetStateAction<any>>;
  addedCompanyId: number | false;
  companies: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const FormAddNewCompany = ({
  typeOfCompanyInPorts,
  addCompanyPath,
  setShowAddCompanyForm,
  setAddedCompanyId,
  addedCompanyId,
  companies,
  setFieldValue,
}: IProps) => {
  const { t } = useTranslation();

  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState<any>({
    isError: false,
    errorMessage: "",
  });

  const [errorMessage, setErrorMessage] = useState<false | any>(false);

  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: addCompanyPath,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (addedCompanyId && companies) {
      let foundCompany = companies.find((c) => c.id === addedCompanyId);
      if (foundCompany) {
        setFieldValue("company", foundCompany);
        setAddedCompanyId(false);
        setShowAddCompanyForm(false);
      }
    }
  }, [
    addedCompanyId,
    companies,
    setFieldValue,
    setAddedCompanyId,
    setShowAddCompanyForm,
  ]);

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  const confirm = () => {
    let ifFetch = true;

    if (companyName === "") {
      setCompanyNameError({
        isError: true,
        errorMessage: "",
      });
      ifFetch = false;
    }
    if (!ifFetch) return;

    let companyToAdd = {
      name: companyName,
      shortName: "",
      description: "",
    };

    if (typeOfCompanyInPorts) {
      companyToAdd["type"] = typeOfCompanyInPorts;
    }

    setBodyRequest(JSON.stringify(companyToAdd));
  };

  useEffect(() => {
    if (fetchingStatePostData.response?.status === 201) {
      if (fetchingStatePostData.response.location) {
        let id = fetchingStatePostData.response.location.substring(
          fetchingStatePostData.response.location.lastIndexOf("/") + 1
        );
        setAddedCompanyId(parseInt(id));
      }
    }
  }, [fetchingStatePostData.response, setAddedCompanyId]);

  return (
    <>
      <DialogContentStyled style={{ background: "#eef4f5" }}>
        <Row>
          <RowTitle>{t("name")}:</RowTitle>
          <RowInput>
            <TextFieldStyled
              label={t("name")}
              required={true}
              ifToUpperCaseShipsAndPorts={true}
              isError={companyNameError.isError}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value === "") {
                  setCompanyNameError({
                    isError: true,
                    errorMessage: "",
                  });
                } else {
                  setCompanyNameError({
                    isError: false,
                    errorMessage: "",
                  });
                }
                setCompanyName(e.target.value);
              }}
            />
            {companyNameError.isError && (
              <div className={styles.errorMessage}>
                {companyNameError.errorMessage}
              </div>
            )}
          </RowInput>
        </Row>

        <Row>
          <RowTitle>
            {!fetchingStatePostData.isFetching ? (
              <>
                <div className={styles.containerAddCompanytButton}>
                  <Button
                    className={styles.buttonAddCompany}
                    onClick={() => confirm()}
                    type="button"
                  >
                    {t("add_company")}
                  </Button>
                </div>
              </>
            ) : (
              <div className="confirmWaiting">
                <CircularProgress size={25} />
              </div>
            )}
          </RowTitle>
          {errorMessage && errorMessage !== "" && (
            <RowInput style={{ color: "red" }}>{errorMessage}</RowInput>
          )}
        </Row>
      </DialogContentStyled>
    </>
  );
};

export default FormAddNewCompany;
