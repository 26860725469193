import {
  faDoorOpen,
  faFireExtinguisher,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypeOfCompanyInPorts } from "../../../enums/typeOfCompanyInPorts";
import PopupDeliveryAndGarbageGuest from "../../helpersComponents/DeliveryAndGarbage/PopupDeliveryAndGarbageGuest";
import PopupEntryUnauthorized from "../../helpersComponents/EntryUnauthorized/PopupEntryUnauthorized";
import PopupInspectionServices from "../../helpersComponents/InspectionServices/PopupInspectionServices";
import styles from "./styles.module.scss";

const NavPopups = () => {
  const { t } = useTranslation();

  const [openDeliveryAndGarbage, setOpenDeliveryAndGarbage] = useState(false);
  const [openInspectionServices, setOpenInspectionServices] = useState(false);
  const [openEntryUnauthorized, setOpenEntryUnauthorized] = useState(false);

  const successCallbackInspectionServices = useCallback(() => {
    setOpenInspectionServices(false);
  }, []);

  const successCallbackDeliveryAndGarbage = useCallback(() => {
    setOpenDeliveryAndGarbage(false);
  }, []);

  const successCallbackEntryUnauthorized = useCallback(() => {
    setOpenEntryUnauthorized(false);
  }, []);

  return (
    <>
      <div
        className={styles.navigationElement}
        onClick={() => setOpenInspectionServices(true)}
      >
        <p>
          <FontAwesomeIcon icon={faFireExtinguisher} />{" "}
          {t("inspection_services")}
        </p>
      </div>
      <div
        className={styles.navigationElement}
        onClick={() => setOpenDeliveryAndGarbage(true)}
      >
        <p>
          <FontAwesomeIcon icon={faTruck} />{" "}
          {`${t("delivery_man")} / ${t("garbage_disposal_company")}`}
        </p>
      </div>
      <div
        className={styles.navigationElement}
        onClick={() => setOpenEntryUnauthorized(true)}
      >
        <p>
          <FontAwesomeIcon icon={faDoorOpen} /> {t("entry_unauthorized")}
        </p>
      </div>
      <div>
        {openDeliveryAndGarbage && (
          <PopupDeliveryAndGarbageGuest
            open={openDeliveryAndGarbage}
            onCancelChanges={() => setOpenDeliveryAndGarbage(false)}
            successCallback={successCallbackDeliveryAndGarbage}
            pathApi="security/visits-courier-or-waste-disposal"
            companiesInPortsPathApi={`security/companies-in-ports?typeOfCompany=${TypeOfCompanyInPorts.COURIER_AND_WASTE_DISPOSAL}`}
            permissionZonesPathApi="security/permission-zones?isActive=true"
            registrationNumber={true}
            title={t("adding_notification_delivery_man_garbage")}
          />
        )}

        {openInspectionServices && (
          <PopupInspectionServices
            open={openInspectionServices}
            onCancelChanges={() => setOpenInspectionServices(false)}
            successCallback={successCallbackInspectionServices}
            pathApi="security/visits-inspection-services"
            inspectionServicesPathApi="security/inspection-services"
            permissionZonesPathApi="security/permission-zones?isActive=true"
            acWorkersPathApi="security/ac-workers"
          />
        )}

        {openEntryUnauthorized && (
          <PopupEntryUnauthorized
            open={openEntryUnauthorized}
            onCancelChanges={() => setOpenEntryUnauthorized(false)}
            successCallback={successCallbackEntryUnauthorized}
            pathApi="security/visits-unauthorized"
            ifRegistrationNumber={true}
            permissionZonesPathApi="security/permission-zones?isActive=true"
            typesOfVisitPathApi="security/types-of-visits-in-ports"
            checkGuestsOnBlackListPathApi="security/notification-guests-in-ports/check-guests-on-black-list"
          />
        )}
      </div>
    </>
  );
};

export default NavPopups;
