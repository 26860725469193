import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

interface Props {
  children: React.ReactNode | React.ReactNode[];
  width?: number;
}

export function RowDetailsTitleValue({ children, width }: Props): ReactElement {
  return <div className={styles.titleValueContainer}>{children}</div>;
}
