import { HourRequestType } from "../../enums/hourRequestType";
import { TFunction } from "react-i18next";

export function isJoinedRequestType(
  requestType: HourRequestType | null | undefined
) {
  if (!requestType) return false;

  const joinedRequestsTypes: HourRequestType[] = [
    HourRequestType.PrivatePassWithWorkOff,
    HourRequestType.BusinessPassWithPrivatePass,
    HourRequestType.Care188WithPrivatePass,
    HourRequestType.Care188WithBusinessPass,
  ];

  return joinedRequestsTypes.includes(requestType);
}

export function getJoinedRequestsName(
  requestType: HourRequestType | null | undefined,
  t: TFunction<"translation">
) {
  let request1 = "";
  let request2 = "";

  switch (requestType) {
    case HourRequestType.PrivatePassWithWorkOff:
      request1 = t("private_pass");
      request2 = t("private_pass_workoff");
      break;

    case HourRequestType.BusinessPassWithPrivatePass:
      request1 = t("business_pass");
      request2 = t("private_pass");
      break;

    case HourRequestType.Care188WithPrivatePass:
      request1 = t("care_art_188");
      request2 = t("private_pass");
      break;

    case HourRequestType.Care188WithBusinessPass:
      request1 = t("care_art_188");
      request2 = t("business_pass");
      break;
  }

  return [request1, request2];
}

export function haveJoinedRequestsCommonDateField(
  requestType: HourRequestType | null | undefined
) {
  if (requestType === HourRequestType.PrivatePassWithWorkOff) {
    return false;
  }

  return true;
}
