import React from "react";
import ListOfPermitsContent from "./listOfPermitsContent";

function ListOfPermits() {
  return (
    <div>
      <ListOfPermitsContent />
    </div>
  );
}

export default ListOfPermits;
