import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertSecondsToHrsMinsSeconds from "../../../../HelpersFunctions/dateAndTime/convertSecondsToHrsMinsSeconds";
import ButtonStyled from "../../Button/button";
import SinglePresentation from "./singlePresentation";
import styles from "./styles.module.scss";

interface Props {
  training: Training;
  setStage: React.Dispatch<Stage>;
  setExamStartDate: React.Dispatch<Date>;
}
export default function Presentations({
  setStage,
  training,
  setExamStartDate,
}: Props) {
  const [presentationsSorted] = useState<Presentation[]>(() => {
    let presentationsCopy = [...training.presentations];
    presentationsCopy.sort((a, b) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
    return presentationsCopy;
  });

  const [viewedPresentationsIndexes, setViewedPresentationsIndexes] = useState<{
    [key in number]: boolean;
  }>({});
  const [timer, setTimer] = useState<number>(presentationsSorted[0].minTime);
  const { t } = useTranslation();
  useEffect(() => {
    if (training.presentations.length === 0) {
      setStage("questions");
    }
  }, [training.presentations, setStage]);

  const [currentPresentationIndex, setCurrentPresentationIndex] = useState(0);
  const [isCurrentPresentationLoaded, setIsCurrentPresentationLoaded] =
    useState<boolean>(false);

  useEffect(() => {
    if (timer === 0) {
      setViewedPresentationsIndexes((prev) => {
        return {
          ...prev,
          [currentPresentationIndex]: true,
        };
      });
      return;
    }
  }, [timer, currentPresentationIndex]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (
      viewedPresentationsIndexes[currentPresentationIndex] !== true &&
      isCurrentPresentationLoaded
    ) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 0) {
            clearInterval(interval);
            return prev;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    currentPresentationIndex,
    viewedPresentationsIndexes,
    isCurrentPresentationLoaded,
  ]);

  return (
    <div>
      <div className={styles.navBar}>
        <div className={styles.leftButton}>
          <ButtonStyled
            onClick={() => {
              setTimer(
                presentationsSorted[currentPresentationIndex - 1].minTime
              );
              setCurrentPresentationIndex(currentPresentationIndex - 1);
            }}
            disabled={
              !training.backToPreviousQuestionPossible ||
              currentPresentationIndex === 0 ||
              viewedPresentationsIndexes[currentPresentationIndex] !== true
            }
          >
            {t("back")}
          </ButtonStyled>
        </div>
        <div className={styles.timer}>
          {viewedPresentationsIndexes[currentPresentationIndex] !== true ? (
            convertSecondsToHrsMinsSeconds(timer)
          ) : (
            <span>
              <FontAwesomeIcon
                className={styles.faCheckCircle}
                icon={faCheckCircle}
              />
            </span>
          )}
        </div>
        {currentPresentationIndex !== presentationsSorted.length - 1 && (
          <div className={styles.rightButton}>
            <ButtonStyled
              onClick={() => {
                setTimer(
                  presentationsSorted[currentPresentationIndex + 1].minTime
                );
                setCurrentPresentationIndex(currentPresentationIndex + 1);
              }}
              disabled={
                viewedPresentationsIndexes[currentPresentationIndex] !== true
              }
            >
              {t("next")}
            </ButtonStyled>
          </div>
        )}
        {currentPresentationIndex === presentationsSorted.length - 1 && (
          <div className={styles.rightButton}>
            <ButtonStyled
              disabled={
                viewedPresentationsIndexes[presentationsSorted.length - 1] !==
                true
              }
              onClick={() => {
                setExamStartDate(new Date());
                setStage("questions");
              }}
            >
              {t("go_to_the_test")}
            </ButtonStyled>
          </div>
        )}
      </div>
      <div className={styles.presentationRoot}>
        <SinglePresentation
          presentation={presentationsSorted[currentPresentationIndex]}
          setLoaded={setIsCurrentPresentationLoaded}
        />
      </div>
    </div>
  );
}
