import React from 'react';
import HolidaysRequestsContent from "./holidaysRequestsContent"


function HolidaysRequests() {
    return (
        <div>
            <HolidaysRequestsContent/>
        </div>
    );
}

export default HolidaysRequests;
