import { TFunction } from "react-i18next";
import styles from "./GroupCellByLineName.module.scss";

const GroupCellByLineName = (
  options: any,
  t: TFunction<"translation">,
  showWorkerCount: boolean
) => {
  let text = "";
  if (options.value === "end_work_on_line") text = t("end_work_on_line");
  else if (options.value) text = options.value;
  else if (!options.value) text = t("unassigned");

  return (
    <div className={styles.group}>
      <span className={styles.groupLineName}>[{text}]</span>
      {showWorkerCount && (
        <span className={styles.groupEmployeesCount}>{` - ${t("employees")}: ${
          options.data.aggregates[0]
        }`}</span>
      )}
    </div>
  );
};

export default GroupCellByLineName;
