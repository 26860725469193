import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvancedSelect from "../../../../../../helpersComponents/AdvancedSelect/advancedSelect";
import { EmployeesReportSchemasWorkingMode } from "./../../../../../../../enums/employeesReportSchemasWorkingMode";
import ShareSchema from "./ShareSchema";
import DeleteSchema from "./DeleteSchema";
import useFetchOtherThanGET from "../../../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useFetchAndSetGET from "../../../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";

interface IProps {
  columnsSchemas: IEmployeesReportColumnsSchema[];
  onSchemasSharedSuccessfully?: () => void;
  onSchemaDeletedSuccessfully?: () => void;
  onChangeValue?: (columnsSchema: IEmployeesReportColumnsSchema) => void;
  selectedSchemaName: string;
  setSelectedSchemaName: Dispatch<SetStateAction<string>>;
}

const EmployeesReportColumnsSchemas: React.FC<IProps> = ({
  columnsSchemas,
  selectedSchemaName,
  setSelectedSchemaName,
  onSchemasSharedSuccessfully,
  onSchemaDeletedSuccessfully,
  onChangeValue,
}) => {
  const { t } = useTranslation();

  const [workingMode, setWorkingMode] =
    useState<EmployeesReportSchemasWorkingMode>(
      EmployeesReportSchemasWorkingMode.NORMAL_STATE
    );

  const [schemaIdToRemove, setSchemaIdToRemove] = useState<false | number>(
    false
  );

  const [shareSchemaBody, setShareSchemaBody] = useState<false | string>(false);

  const [selectedSchema, setSelectedSchema] = useState<
    IEmployeesReportColumnsSchema | undefined
  >(undefined);

  const [selectedEmployeesReportUsers, setSelectedEmployeesReportUsers] =
    useState<number[]>([]);

  const onShareButtonClick = () => {
    if (selectedSchema) {
      setWorkingMode(EmployeesReportSchemasWorkingMode.SHARE_STATE);
    }
  };

  const onRemoveButtonClick = () => {
    if (selectedSchema) {
      setWorkingMode(EmployeesReportSchemasWorkingMode.REMOVE_STATE);
    }
  };

  const onColumnsSchemasChangeValue = (
    columnsSchema: IEmployeesReportColumnsSchema
  ) => {
    setSelectedSchema(columnsSchema);

    if (onChangeValue) {
      onChangeValue(columnsSchema);
    }

    setWorkingMode(EmployeesReportSchemasWorkingMode.NORMAL_STATE);
  };

  const [timeSuperiorsBasicInfo] = useFetchAndSetGET<ITimeSuperiorBasicInfo[]>({
    path: `superior-time/time-superiors`,
    startFetchOnInitial: true,
  });

  const [, shareSchemaFetchAgain] = useFetchOtherThanGET({
    path: `superior-time/share-employees-report-columns-schema`,
    method: "POST",
    contentType: "application/json",
    body: shareSchemaBody,
    setBody: setShareSchemaBody,
    successCallback: onSchemasSharedSuccessfully,
  });

  const [, deleteSchemaFetchAgain] = useFetchOtherThanGET({
    path: `superior-time/employees-report-columns-schemas/${schemaIdToRemove}`,
    method: "DELETE",
    body: schemaIdToRemove,
    setBody: setSchemaIdToRemove,
    successCallback: onSchemaDeletedSuccessfully,
  });

  useEffect(() => {
    if (schemaIdToRemove !== false) {
      deleteSchemaFetchAgain();
    }
  }, [schemaIdToRemove, deleteSchemaFetchAgain]);

  useEffect(() => {
    if (shareSchemaBody !== false) {
      shareSchemaFetchAgain();
    }
  }, [shareSchemaBody, shareSchemaFetchAgain]);

  useEffect(() => {
    if (selectedSchemaName) {
      const foundSchema = columnsSchemas.find(
        (el) => el.name === selectedSchemaName
      );
      if (foundSchema) {
        setSelectedSchema(foundSchema);
      }

      setSelectedSchemaName("");
    }
  }, [selectedSchemaName, columnsSchemas, setSelectedSchemaName]);

  const onShareSchemaConfirmButtonClick = () => {
    if (
      selectedSchema &&
      selectedEmployeesReportUsers &&
      selectedEmployeesReportUsers.length > 0
    ) {
      let shareSchemaQuery: IShareEmployeesReportColumnsSchemaQuery = {
        schemaId: selectedSchema.id,
        timeSuperiorsIds: selectedEmployeesReportUsers,
      };
      setShareSchemaBody(JSON.stringify(shareSchemaQuery));
      setSelectedEmployeesReportUsers([]);
    }
    setWorkingMode(EmployeesReportSchemasWorkingMode.NORMAL_STATE);
  };

  const onShareSchemaCancelButtonClick = () => {
    setWorkingMode(EmployeesReportSchemasWorkingMode.NORMAL_STATE);
  };

  const onConfirmRemoveRemoveButtonClick = () => {
    if (selectedSchema) {
      if (selectedSchema.id > 0) {
        setSchemaIdToRemove(selectedSchema.id);
      }
    }

    setWorkingMode(EmployeesReportSchemasWorkingMode.NORMAL_STATE);
  };

  const onConfirmRemoveCancelButtonClick = () => {
    setWorkingMode(EmployeesReportSchemasWorkingMode.NORMAL_STATE);
  };

  return (
    <div>
      <AdvancedSelect
        label={t("select_schema")}
        dataSource={columnsSchemas}
        value={selectedSchema}
        displayShareButton={true}
        displayRemoveButton={true}
        onShareButtonClick={onShareButtonClick}
        onRemoveButtonClick={onRemoveButtonClick}
        onChangeValue={onColumnsSchemasChangeValue}
        displayMember={(schema: IEmployeesReportColumnsSchema) => schema.name}
      />

      {workingMode === EmployeesReportSchemasWorkingMode.SHARE_STATE && (
        <ShareSchema
          employeesReportUsers={timeSuperiorsBasicInfo}
          selectedEmployeesReportUsers={selectedEmployeesReportUsers}
          setSelectedEmployeesReportUsers={setSelectedEmployeesReportUsers}
          onConfirmButtonClick={onShareSchemaConfirmButtonClick}
          onCancelButtonClick={onShareSchemaCancelButtonClick}
        />
      )}
      {workingMode === EmployeesReportSchemasWorkingMode.REMOVE_STATE && (
        <DeleteSchema
          onRemoveButtonClick={onConfirmRemoveRemoveButtonClick}
          onCancelButtonClick={onConfirmRemoveCancelButtonClick}
        />
      )}
    </div>
  );
};

export default EmployeesReportColumnsSchemas;
