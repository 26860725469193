import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

interface State {
  isOpenLeftNavbar: boolean;
}

const initialState: State = {
  isOpenLeftNavbar: localStorage.getItem("isOpenNavbar") === "true",
};

export const isOpenLeftNavbarReducer = createSlice({
  name: "setIsOpenLeftNavbar",
  initialState,
  reducers: {
    setIsOpenLeftNavbar: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem("isOpenNavbar", JSON.stringify(action.payload));
      state.isOpenLeftNavbar = action.payload;
    },
  },
});

export const selectIsOpenLeftNavbar = (state: RootState) =>
  state.isOpenLeftNavbar.isOpenLeftNavbar;
export const { setIsOpenLeftNavbar } = isOpenLeftNavbarReducer.actions;
export default isOpenLeftNavbarReducer.reducer;
