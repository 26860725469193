import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button/button";
import { DatePickerStyled } from "../MaterialUi/index";
import StyledBlock from "../StyledBlock/styledBlock";
import "./datePickerRange.scss";
import PeriodOfTimeChooser from "../PeriodOfTimeChooser/PeriodOfTimeChooser";
// import { useState, useEffect } from 'react';

interface DatePickerRangeInterface {
  children?: React.ReactNode;
  view: string[];
  format: string;
  setDate: any;
  setDate2: any;
  date: Date | null;
  date2: Date | null;
  onClick?(): void;
  buttonName?: string;
  darkTheme?: boolean;
  confirmButtonRight?: JSX.Element;
  keyboardDateTimePicker?: boolean;
  onlyTime?: boolean;
  disabled?: boolean;
  disabledSecond?: boolean;
  componentInRow?: ReactElement<any, any>;
}

function DatePickerRange({
  children,
  view,
  format,
  setDate,
  setDate2,
  date,
  date2,
  onClick,
  buttonName,
  darkTheme = false,
  confirmButtonRight,
  keyboardDateTimePicker = false,
  onlyTime = false,
  disabled,
  disabledSecond,
  componentInRow,
}: DatePickerRangeInterface) {
  const { t } = useTranslation();

  if (darkTheme)
    return (
      <>
        <div className="selectDateDarkGlobal">
          <div>
            <div className="datePickerSelectDataContainer">
              {componentInRow && <div>{componentInRow}</div>}
              <div className="datepickerRange">
                {onlyTime === false || onlyTime === undefined ? (
                  <div>{t("date_from")}</div>
                ) : (
                  <div>{t("time_from")}</div>
                )}
                <div className="datepickerRangeInput">
                  <DatePickerStyled
                    keyboardDateTimePicker={keyboardDateTimePicker}
                    view={view}
                    format={format}
                    date={date}
                    setDate={setDate}
                    // onlyTime={onlyTime}
                    darkTheme={darkTheme}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="datepickerRange">
                <div>{t("date_to")}</div>
                <div className="datepickerRangeInput">
                  <DatePickerStyled
                    keyboardDateTimePicker={keyboardDateTimePicker}
                    view={view}
                    format={format}
                    date={date2}
                    setDate={setDate2}
                    // onlyTime={onlyTime}
                    darkTheme={darkTheme}
                    disabled={disabled || disabledSecond}
                  />
                </div>
              </div>
              <div>
                <PeriodOfTimeChooser
                  setStartDate={setDate}
                  setEndDate={setDate2}
                />
              </div>
              <div className="additionalContentContainer">
                {confirmButtonRight && confirmButtonRight}
              </div>
            </div>
            {buttonName ? (
              <div className="datePickerButton">
                <Button onClick={onClick}>{buttonName}</Button>
              </div>
            ) : null}
          </div>
          <div>{children}</div>
        </div>
      </>
    );

  return (
    <>
      <div className="datePickerRange">
        <StyledBlock>
          <div>
            <div className="datePickerSelectData">
              <div>
                {onlyTime === false || onlyTime === undefined ? (
                  <div>{t("date_from")}</div>
                ) : (
                  <div>{t("time_from")}</div>
                )}
                {t("date_from")}
                <DatePickerStyled
                  keyboardDateTimePicker={keyboardDateTimePicker}
                  view={view}
                  format={format}
                  date={date}
                  setDate={setDate}
                  // onlyTime={onlyTime}
                  disabled={disabled}
                />
              </div>
              <div>
                {t("date_to")}
                <DatePickerStyled
                  keyboardDateTimePicker={keyboardDateTimePicker}
                  view={view}
                  format={format}
                  date={date2}
                  setDate={setDate2}
                  // onlyTime={onlyTime}
                  disabled={disabled || disabledSecond}
                />
              </div>
              <div>
                <PeriodOfTimeChooser
                  setStartDate={setDate}
                  setEndDate={setDate2}
                />
              </div>
              <div className="additionalContentContainer">
                {confirmButtonRight && confirmButtonRight}
              </div>
            </div>
            {buttonName ? (
              <div className="datePickerButton">
                <Button onClick={onClick}>{buttonName}</Button>
              </div>
            ) : null}
          </div>

          <div>{children}</div>
        </StyledBlock>
      </div>
    </>
  );
}

export default DatePickerRange;
