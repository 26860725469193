import { TFunction } from "react-i18next";

const convertSecondsToDaysHrsMinsSeconds = (
  seconds: number,
  t: TFunction<"translation">,
  extraOptions: { ifHideSeconds?: boolean; ifHideMinutes?: boolean } = {
    ifHideSeconds: false,
    ifHideMinutes: false,
  }
): string => {
  if (!seconds) return "";

  let days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  let hrs = Math.floor(seconds / 3600);
  seconds -= hrs * 3600;
  let mnts = Math.floor(seconds / 60);
  seconds -= mnts * 60;
  seconds = Math.floor(seconds);

  return (
    `${days} ${t("days")}, ${hrs} ${t("hours_2")}` +
    (extraOptions.ifHideMinutes ? "" : `, ${mnts} ${t("minutes")}`) +
    (extraOptions.ifHideSeconds ? "" : `, ${seconds} ${t("seconds")}`)
  );
};
export default convertSecondsToDaysHrsMinsSeconds;
