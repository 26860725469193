import React from 'react';
import DyFilesContent from "./myFilesContent"


function DelegationsCities() {
    return (
        <div>
            <DyFilesContent />
        </div>
    );
}

export default DelegationsCities;
