import { CrewEntryDescentType } from "../../../Constants/crewEntryDescentTypes";
import ContentCrewEntryDescent from "./ContentCrewEntryDescent";

const CrewEntryDescent = ({
  eventType,
}: {
  eventType: CrewEntryDescentType;
}) => {
  return (
    <div>
      <ContentCrewEntryDescent eventType={eventType} />
    </div>
  );
};

export default CrewEntryDescent;
