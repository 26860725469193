import React from "react";
import { useTranslation } from "react-i18next";
import delegationPurposeName from "../../../../HelpersFunctions/delegationPurposeName";
import RequestStatusName from "../../../../HelpersFunctions/requestStatusName";

const RowDetail: React.FC = ({ row }: any) => {
  const { t } = useTranslation();

  return (
    <div className="detailsDelegation">
      <div className="detailsDelegationRow">
        <div>
          <div>
            <b>{t("request_status")}:</b>
          </div>
          <div>{RequestStatusName(row.status)}</div>
        </div>
        <div>
          <div>
            <b>{t("delagations_number")}:</b>
          </div>
          <div>
            {row.delegationNumber !== null ? row.delegationNumber : "—"}
          </div>
        </div>
      </div>

      <div className="detailsDelegationRow">
        <div>
          <div>
            <b>{t("trip_target")}:</b>
          </div>
          <div>{delegationPurposeName(row.delegationPurposeId)}</div>
        </div>
        <div>
          <div>
            <b>{t("cc")}:</b>
          </div>
          <div>{row.mpk}</div>
        </div>
      </div>

      <div className="detailsDelegationRow">
        <div>
          <div>
            <b>{t("country")}:</b>
          </div>
          <div>{row.country !== null ? row.countryName : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("city")}:</b>
          </div>
          <div>{row.cityName !== null ? row.cityName : "—"}</div>
        </div>
      </div>

      <div className="detailsDelegationRow">
        <div>
          <div>
            <b>{t("start_city")}:</b>
          </div>
          <div>{row.startCity !== null ? row.startCityName : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("end_city")}:</b>
          </div>
          <div>{row.endCity !== null ? row.endCityName : "—"}</div>
        </div>
      </div>

      <div className="detailsDelegationRow">
        <div>
          <div>
            <b>{t("being_date")}:</b>
          </div>
          <div>
            {new Date(row.startDate).toLocaleString(t("scheduler_language"), {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
        </div>
        <div>
          <div>
            <b>{t("ending_time")}:</b>
          </div>
          <div>
            {new Date(row.endDate).toLocaleString(t("scheduler_language"), {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
        </div>
      </div>

      <div className="detailsDelegationRow">
        <div>
          <div>
            <b>{t("data_created")}:</b>
          </div>
          <div>
            {new Date(row.createdAt).toLocaleString(t("scheduler_language"), {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
        </div>
        <div>
          <div>
            <b>{t("approved")}:</b>
          </div>
          <div>
            {row.approvedAt !== null
              ? new Date(row.approvedAt).toLocaleString(
                  t("scheduler_language"),
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )
              : "—"}
          </div>
        </div>
      </div>

      <div className="detailsDelegationRow">
        <div>
          <div>
            <b>{t("approved_by")}:</b>
          </div>
          <div>{row.approverName !== null ? row.approverName : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("finnal_approved_by")}:</b>
          </div>
          <div>
            {row.secondApproverName !== null ? row.secondApproverName : "—"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RowDetail;
