import { Tooltip } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchPatch from "../../../../hooks/fetchHooks/patch/useFetchPatch";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import { CheckboxStyled } from "../../../helpersComponents/MaterialUi";
import {
  statusCheckboxColorsDictionary,
  statusDictionaryTasks,
} from "../statusDictionary";

interface Props {
  task: any;
  setIfFetchAgain: React.Dispatch<boolean>;
  projectId: number;
}

export default function SingleTaskChangeStatus({
  task,
  setIfFetchAgain,
  projectId,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [updateTaskStatusBody, setUpdateTaskStatusBody] = useState<any>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const patchResponse = useFetchPatch(
    `worker-time/${authUserId}/projects/${projectId}/tasks/${task.id}`,
    updateTaskStatusBody
  );

  useEffect(() => {
    if (patchResponse.data?.status === 200) {
      enqueueSnackbar(t("task_status_changed"), { variant: "success" });
      setUpdateTaskStatusBody(false);
      setIfFetchAgain(true);
    } else if (patchResponse.data?.status === 400) {
      let error = "";
      Object.keys(patchResponse.data.errors).forEach((errorObject) => {
        patchResponse.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      enqueueSnackbar(error, { variant: "error" });
      setUpdateTaskStatusBody(false);
    } else if (patchResponse.error !== null) {
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
      setUpdateTaskStatusBody(false);
    }
  }, [patchResponse, enqueueSnackbar, setIfFetchAgain, t]);
  let toolTipTitle: any = t(statusDictionaryTasks[task.status]);

  return (
    <>
      <Tooltip title={task?.status ? toolTipTitle : false}>
        <div>
          <CheckboxStyled
            checked={
              task.status === 2 || task.status === 3 || task.status === 6
            }
            disabled={task.status !== 1 && task.status !== 2}
            name="active"
            ifCircle={true}
            overrideColor={statusCheckboxColorsDictionary[task.status]}
            onClick={(e) => {
              e.stopPropagation();
              setUpdateTaskStatusBody([
                {
                  op: "replace",
                  path: "/status",
                  value: task.status === 1 ? 2 : 1,
                },
              ]);
            }}
            inputProps={{
              "aria-label": "primary checkbox",
            }}
          />
        </div>
      </Tooltip>
    </>
  );
}
