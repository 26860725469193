import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useLogout from "../../../../hooks/LogOut/logOut";
import { TextFieldStyled } from "../../../helpersComponents/MaterialUi";
import Setting from "../Setting";
import styles from "./twoFactorAuthenticationSetting.module.scss";

const TwoFactorAuthenticationSetting = () => {
  const { t } = useTranslation();
  const [twoFactorAuthenticationEnabled, setTwoFactorAuthenticationEnabled] =
    useState(false);

  const [
    twoFactorAuthenticationSwitchLocked,
    setTwoFactorAuthenticationSwitchLocked,
  ] = useState(false);

  const [logOut, setLogOut] = useState<number>(200);

  const [qrCodeImage, setQRCodeImage] = useState("");
  const [setupCode, setSetupCode] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  const [securityCodeError, setSecurityCodeError] = useState({
    isError: false,
    errorMessage: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [bodyRequest, setBodyRequest] = useState<false | any>(false);

  const twoFactorAuthenticationResponseCallback = useCallback(
    (responseData) => {
      setTwoFactorAuthenticationEnabled(responseData.isEnabled);
      setQRCodeImage(responseData.qrCode);
      setSetupCode(responseData.authenticationCode);

      if (responseData.isTwoFactorAuthenticationForced) {
        setTwoFactorAuthenticationEnabled(true);
        setTwoFactorAuthenticationSwitchLocked(true);
      }
    },
    []
  );

  useFetchAndSetGET({
    path: `account/two-factor-authentication-info`,
    modifyResponseCallback: twoFactorAuthenticationResponseCallback,
    startFetchOnInitial: true,
  });

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: "account/two-factor-authentication-info",
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  useLogout(logOut);

  useEffect(() => {
    let timeHandle: NodeJS.Timeout;

    if (
      fetchingStatePostData &&
      !fetchingStatePostData.isError &&
      fetchingStatePostData.response?.status === 200
    ) {
      if (twoFactorAuthenticationEnabled) {
        timeHandle = setTimeout(() => {
          setLogOut(401);
        }, 5000);
      }

      return () => {
        if (timeHandle) {
          clearTimeout(timeHandle);
        }
      };
    }
  }, [fetchingStatePostData, twoFactorAuthenticationEnabled]);

  const onSubmit = () => {
    let ifFetch = true;

    if (twoFactorAuthenticationEnabled) {
      if (securityCode === "") {
        setSecurityCodeError({
          isError: true,
          errorMessage: t("field_required"),
        });

        ifFetch = false;
      }

      if (!ifFetch) return;

      setBodyRequest(
        JSON.stringify({
          isEnabled: twoFactorAuthenticationEnabled,
          securityCode: securityCode,
        })
      );
    } else {
      setBodyRequest(
        JSON.stringify({
          isEnabled: twoFactorAuthenticationEnabled,
        })
      );
    }
  };

  const switchOnChange = (event: any) => {
    setSecurityCodeError({
      isError: false,
      errorMessage: "",
    });

    setErrorMessage("");
    setSecurityCode("");
    setTwoFactorAuthenticationEnabled(event.target.checked);
  };

  useEffect(() => {
    if (
      fetchingStatePostData.response?.status === 200 ||
      fetchingStatePostData.response?.status === 201
    ) {
      setSecurityCodeError({
        isError: false,
        errorMessage: "",
      });

      setErrorMessage("");
    }
  }, [fetchingStatePostData.response]);

  return (
    <Setting
      title={t("two_factor_authentication")}
      switchChecked={twoFactorAuthenticationEnabled}
      switchOnChange={switchOnChange}
      submitChanges={onSubmit}
      errorMessage={errorMessage}
      switchDisabled={twoFactorAuthenticationSwitchLocked}
    >
      {twoFactorAuthenticationEnabled && (
        <div>
          <img src={qrCodeImage} alt="" />

          <p className={styles.setup_code}>
            {t("setup_code")}: {setupCode}
          </p>

          <div className={styles.securityCode}>
            <div>
              <TextFieldStyled
                label={t("security_code")}
                defaultValue=""
                type="number"
                required={true}
                isError={securityCodeError.isError}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setSecurityCodeError({
                      isError: true,
                      errorMessage: "required",
                    });
                  } else {
                    setSecurityCodeError({
                      isError: false,
                      errorMessage: "",
                    });
                  }
                  setSecurityCode(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </Setting>
  );
};

export default TwoFactorAuthenticationSetting;
