const getEndOfDate = (
  date: Date,
  endOfType: "Year" | "CurrentQuarter" | "Month" | "CurrentWeek" | "Day",
  addToDate?: {
    extraDays?: number;
    extraHours?: number;
  }
): Date => {
  let extraDays = addToDate?.extraDays ?? 0;
  let extraHours = addToDate?.extraHours ?? 0;

  if (endOfType === "Month") {
    return new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0 + extraDays,
      23 + extraHours,
      59,
      59
    );
  } else if (endOfType === "CurrentWeek") {
    let firstDay = date.getDate() - date.getDay();
    let lastDay = firstDay + 7;
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      lastDay + extraDays,
      23 + extraHours,
      59,
      59
    );
  } else if (endOfType === "Year") {
    return new Date(
      date.getFullYear(),
      12,
      0 + extraDays,
      23 + extraHours,
      59,
      59
    );
  } else if (endOfType === "Day") {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + extraDays,
      23 + extraHours,
      59,
      59
    );
  } else if (endOfType === "CurrentQuarter") {
    let actualDate = new Date();
    let currentQuarter = Math.floor(actualDate.getMonth() / 3) + 1;
    let endMonth = currentQuarter * 3;
    return new Date(
      new Date(
        actualDate.getFullYear(),
        endMonth,
        0 + extraDays,
        23 + extraHours,
        23,
        59
      )
    );
  }
  return date;
};

export default getEndOfDate;
