import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { showParallelsFetchErrorsDialog } from "../../../reducers/parallelsFetchErrors";

const useParallelsFetchErrorsSummary = (
  rows: any[],
  columns: ColumnTable[],
  formatRow: (row: any, errors: string[]) => any
) => {
  const dispatch = useAppDispatch();
  const [fetchResponse, setFetchResponse] = useState<any>();

  useEffect(() => {
    if (fetchResponse) {
      if (!fetchResponse.isAllGood) {
        let dataToRet: {
          columns: ColumnTable[];
          rows: any[];
        } = {
          columns: columns,
          rows: [],
        };

        for (let i = 0; i < fetchResponse.receivedIdsArray.length; i++) {
          const tempId = fetchResponse.receivedIdsArray[i];
          if (fetchResponse.results[tempId] !== "ok") {
            if (fetchResponse.results[tempId]?.errors?.status) {
              const rowErrors: string[] =
                fetchResponse.results[tempId].errors.status;
              const foundRow = rows.find((el) => el.id === tempId);

              let tempRow = formatRow(foundRow, rowErrors);
              dataToRet.rows.push(tempRow);
            }
          }
        }

        if (dataToRet.rows.length > 0) {
          dispatch(showParallelsFetchErrorsDialog({ data: dataToRet }));
        }
      }
    }
  }, [rows, columns, dispatch, formatRow, fetchResponse]);

  return [setFetchResponse];
};

export default useParallelsFetchErrorsSummary;
