import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { useTranslation } from "react-i18next";
import ContentOperationalSupportNotificationInPorts from "../../../helpersComponents/NotificationsInPorts/OperationalSupportNoitifcationsInPorts/ContentOperationalSupportNotificationInPort";

const AddOperationalSupportNotification = ({ pathCompaniesInPorts }) => {
  const { t } = useTranslation();

  return (
    <div>
      <TopBanner
        pathName={t("adding_operational_support_notification")}
        showGoToLoginPage={false}
        hideLogOut={false}
        showBackButton={true}
      />

      <ContentOperationalSupportNotificationInPorts />
    </div>
  );
};

export default AddOperationalSupportNotification;
