import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import convertMinsToHrsMins from "../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import AbsencesChart from "./absenceChart";
import AccordingSummaryWithTable from "./accordingSummaryWithTable";
import styles from "./homePage.module.scss";
import YesterdayTwoDaysAgoChart from "./yesterdayTwoDaysAgoChart";
import SelectMenu from "../../helpersComponents/SelectMenu/selectMenu";
import HoursRequestsAccordion from "../lists/HoursRequests/hoursRequestsAccordion";
import HolidaysRequestsAccordion from "../lists/HolidaysRequests/holidaysRequestsAccordion";
import ButtonStyled from "../../helpersComponents/Button/button";
import { useHistory } from "react-router";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import { selectTimeWorkersSuperiorArray } from "../../../reducers/workersLists";
import { useAppSelector } from "../../../store/hooks";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";

export default function HomePageContent(): ReactElement {
  const { t } = useTranslation();

  const [timeWorkersIdsString, settimeWorkersIdsString] = useState<
    null | string
  >(null);
  const history = useHistory();

  const [workingTimeYesterday, setWorkingTimeYesterday] = useState(0);
  const [workingTimeDayBeforeYesterday, setWorkingTimeDayBeforeYesterday] =
    useState(0);
  const [overtimeYesterday, setOvertimeYesterday] = useState(0);
  const [overtimeDayBeforeYesterday, setOvertimeDayBeforeYesterday] =
    useState(0);
  const [notWorkedYesterday, setNotWorkedYesterday] = useState(0);
  const [notWorkedDayBeforeYesterday, setNotWorkedDayBeforeYesterday] =
    useState(0);

  const [workedDaysYesterday, setWorkedDaysYesterday] = useState(0);
  const [workedDaysDayBeforeYesterday, setWorkedDaysDayBeforeYesterday] =
    useState(0);

  let twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

  ///////////////////////////// late data state
  const [lateData, setLateData] = useState<{
    rows: LateRow[] | null;
    columns: ColumnTable[];
    title: React.ReactNode;
  }>({
    rows: [],
    columns: [
      { name: "lp", title: "L.p." },
      { name: "fullName", title: t("employee") },
      { name: "late", title: "SP" },
      { name: "date", title: t("date"), type: "date" },
      { name: "evidenceNumber", title: t("evidence_number") },
      { name: "goToRheRecords", title: t("action") },
    ],
    title: "",
  });

  ///////////////////////////// unexcusedAbsence data state
  const [unexcusedAbsence, setUnexcusedAbsence] = useState<{
    rows: UnexcusedAbsenceRow[] | null;
    columns: ColumnTable[];
    title: React.ReactNode;
  }>({
    rows: [],
    columns: [
      { name: "lp", title: "L.p." },
      { name: "fullName", title: t("employee") },
      { name: "date", title: t("date"), type: "date" },
      { name: "evidenceNumber", title: t("evidence_number") },
      { name: "goToRheRecords", title: t("action") },
    ],
    title: "",
  });

  ///////////////////////////// unfinished Working Time data state
  const [unfinishedWorkingTime, setUnfinishedWorkingTime] = useState<{
    rows: UnfinishedWorkingTimeRow[] | null;
    columns: ColumnTable[];
    title: React.ReactNode;
  }>({
    rows: [],
    columns: [
      { name: "lp", title: "L.p." },
      { name: "fullName", title: t("employee") },
      { name: "date", title: t("date"), type: "date" },
      { name: "evidenceNumber", title: t("evidence_number") },
      { name: "value", title: t("time") },
      { name: "goToRheRecords", title: t("action") },
    ],
    title: "",
  });

  //////////////// creating timeWorkers Ids String
  const workerList = useAppSelector(selectTimeWorkersSuperiorArray);
  useEffect(() => {
    let timeWorkersIdsStringLocal: string = "";
    workerList.forEach((timeworker) => {
      timeWorkersIdsStringLocal =
        timeWorkersIdsStringLocal + timeworker.id.toString() + ",";
    });
    timeWorkersIdsStringLocal = timeWorkersIdsStringLocal.replace(/,\s*$/, "");
    settimeWorkersIdsString(timeWorkersIdsStringLocal);
  }, [workerList, t]);

  const [summariesBody, setSummariesBody] = useState<false | string>(false);

  const [fetchingStateDaily, fetchAgainDaily] = useFetchOtherThanGET({
    path: "superior-time/daily-summaries",
    method: "POST",
    contentType: "application/json",
    body: summariesBody,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    let tempTwoMonthsAgo = new Date();
    tempTwoMonthsAgo.setMonth(tempTwoMonthsAgo.getMonth() - 2);

    let requestBodyObj = {
      dateFrom: convertDateFetch(tempTwoMonthsAgo, "T00:00:00"),
      dateTo: convertDateFetch(new Date(), "T23:59:59"),
    };

    setSummariesBody(JSON.stringify(requestBodyObj));
  }, []);

  useEffect(() => {
    if (fetchingStateDaily.isError || fetchingStateDaily.response === undefined)
      return;
    if (!Array.isArray(fetchingStateDaily.response.resJson)) return;

    let dailyData = fetchingStateDaily.response.resJson;
    let workingTimeYesterdayLocal = 0;
    let workingTimeDayBeforeYesterdayLocal = 0;

    let workedDaysYesterdayLocal = 0;
    let workedDaysDayBeforeYesterdayLocal = 0;

    let overtimeYesterdayLocal = 0;
    let overtimeDayBeforeYesterdayLocal = 0;

    let notWorkedYesterdayLocal = 0;
    let notWorkedDayBeforeYesterdayLocal = 0;

    let currentDate = new Date();
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    let lateRowsLocal: LateRow[] = [];
    let unexcusedAbsenceLocal: UnexcusedAbsenceRow[] = [];
    let unfinishedWorkingTimeLocal: UnfinishedWorkingTimeRow[] = [];
    let lpLate = 1;
    let lpUnexcusedAbsenceLocal = 1;
    let lpUnfinishedWorkingTimeLocal = 1;
    // let yesterdayDateLocal = new Date(
    //   new Date().getTime() - 1000 * 60 * 60 * 24
    // );

    dailyData.forEach((worker, index) => {
      if (worker?.late > 0) {
        lateRowsLocal.push({
          id: index,
          lp: lpLate,
          evidenceNumber: worker.evidenceNumber,
          late: convertMinsToHrsMins(worker.late),
          goToRheRecords: (
            <div>
              <ButtonStyled
                onClick={() => {
                  history.push({
                    pathname: "/superior-time/lists/workers-records",
                    state: {
                      workerId: worker.timeWorkerId,
                      dateFrom: getBeginningOfDate(new Date(worker.day), "Day"),
                      dateTo: getEndOfDate(new Date(worker.day), "Day"),
                    },
                  });
                }}
              >
                {t("go_to_the_records")}
              </ButtonStyled>
            </div>
          ),
          date: worker.day,
          fullName: `${worker.firstName} ${worker.lastName}`,
        });
        lpLate++;
      }
      if (
        typeof worker?.absenceShortcut === "string" ||
        worker?.absenceShortcut instanceof String
      ) {
        if (worker?.absenceShortcut.startsWith("NN")) {
          unexcusedAbsenceLocal.push({
            id: index,
            lp: lpUnexcusedAbsenceLocal,
            evidenceNumber: worker.evidenceNumber,
            date: worker.day,
            goToRheRecords: (
              <div>
                <ButtonStyled
                  onClick={() => {
                    history.push({
                      pathname: "/superior-time/lists/workers-records",
                      state: {
                        workerId: worker.timeWorkerId,
                        dateFrom: getBeginningOfDate(
                          new Date(worker.day),
                          "Day"
                        ),
                        dateTo: getEndOfDate(new Date(worker.day), "Day"),
                      },
                    });
                  }}
                >
                  {t("go_to_the_records")}
                </ButtonStyled>
              </div>
            ),
            fullName: `${worker.firstName} ${worker.lastName}`,
          });
          lpUnexcusedAbsenceLocal++;
        }
      }

      ///////////////////////////////// unfinished working time
      //// first "if" check yesterday, te second one check day before yesterday
      if (
        currentDate.getTime() - 1000 * 60 * 60 * 24 ===
        new Date(worker.day).getTime()
      ) {
        workingTimeYesterdayLocal += worker.workingTime;
        overtimeYesterdayLocal += worker.overtime;

        if (worker.workingTime > 0) {
          workedDaysYesterdayLocal++;
        }

        if (
          worker.workingTime + worker.absences < worker.workTimeStandard &&
          worker.workingTime > 0
        ) {
          notWorkedYesterdayLocal++;
          unfinishedWorkingTimeLocal.push({
            id: index,
            lp: lpUnfinishedWorkingTimeLocal,
            evidenceNumber: worker.evidenceNumber,
            goToRheRecords: (
              <div>
                <ButtonStyled
                  onClick={() => {
                    history.push({
                      pathname: "/superior-time/lists/workers-records",
                      state: {
                        workerId: worker.timeWorkerId,
                        dateFrom: getBeginningOfDate(
                          new Date(worker.day),
                          "Day"
                        ),
                        dateTo: getEndOfDate(new Date(worker.day), "Day"),
                      },
                    });
                  }}
                >
                  {t("go_to_the_records")}
                </ButtonStyled>
              </div>
            ),
            date: worker.day,
            fullName: `${worker.firstName} ${worker.lastName}`,
            value: convertMinsToHrsMins(
              worker.workingTime - worker.workTimeStandard
            ),
          });
          lpUnfinishedWorkingTimeLocal++;
        }
      } else if (
        currentDate.getTime() - 1000 * 60 * 60 * 24 * 2 ===
        new Date(worker.day).getTime()
      ) {
        workingTimeDayBeforeYesterdayLocal += worker.workingTime;
        overtimeDayBeforeYesterdayLocal += worker.overtime;

        if (worker.workingTime > 0) {
          workedDaysDayBeforeYesterdayLocal++;
        }

        if (
          worker.workingTime + worker.absences < worker.workTimeStandard &&
          worker.workingTime > 0
        ) {
          notWorkedDayBeforeYesterdayLocal++;
          unfinishedWorkingTimeLocal.push({
            id: index,
            goToRheRecords: (
              <div>
                <ButtonStyled
                  onClick={() => {
                    history.push({
                      pathname: "/superior-time/lists/workers-records",
                      state: {
                        workerId: worker.timeWorkerId,
                        dateFrom: getBeginningOfDate(
                          new Date(worker.day),
                          "Day"
                        ),
                        dateTo: getEndOfDate(new Date(worker.day), "Day"),
                      },
                    });
                  }}
                >
                  {t("go_to_the_records")}
                </ButtonStyled>
              </div>
            ),
            lp: lpUnfinishedWorkingTimeLocal,
            evidenceNumber: worker.evidenceNumber,
            date: worker.day,
            fullName: `${worker.firstName} ${worker.lastName}`,
            value: convertMinsToHrsMins(
              worker.workingTime - worker.workTimeStandard
            ),
          });
          lpUnfinishedWorkingTimeLocal++;
        }
      }
    });

    setWorkingTimeYesterday(workingTimeYesterdayLocal);
    setWorkingTimeDayBeforeYesterday(workingTimeDayBeforeYesterdayLocal);
    setOvertimeYesterday(overtimeYesterdayLocal);
    setOvertimeDayBeforeYesterday(overtimeDayBeforeYesterdayLocal);
    setNotWorkedYesterday(notWorkedYesterdayLocal);
    setNotWorkedDayBeforeYesterday(notWorkedDayBeforeYesterdayLocal);
    setWorkedDaysYesterday(workedDaysYesterdayLocal);
    setWorkedDaysDayBeforeYesterday(workedDaysDayBeforeYesterdayLocal);

    setUnfinishedWorkingTime((prev) => {
      return {
        rows: unfinishedWorkingTimeLocal,
        columns: [...prev.columns],
        title: (rowsLength) => (
          <div className={styles.titleAccordion}>
            {t("not_worked")}
            <div
              className={
                styles.redBorderColor + " " + styles.circleAccordionTitleNumber
              }
            >
              {rowsLength}
            </div>
          </div>
        ),
      };
    });

    setUnexcusedAbsence((prev) => {
      return {
        rows: unexcusedAbsenceLocal,
        columns: [...prev.columns],
        title: (rowsLength) => (
          <div className={styles.titleAccordion}>
            {t("unexcused_absences")}
            <div
              className={
                styles.redBorderColor + " " + styles.circleAccordionTitleNumber
              }
            >
              {rowsLength}
            </div>
          </div>
        ),
      };
    });

    setLateData((prev) => {
      return {
        rows: lateRowsLocal,
        columns: [...prev.columns],
        title: (rowsLength) => (
          <div className={styles.titleAccordion}>
            {t("lates")}
            <div
              className={
                styles.redBorderColor + " " + styles.circleAccordionTitleNumber
              }
            >
              {rowsLength}
            </div>
          </div>
        ),
      };
    });
  }, [
    fetchingStateDaily.isError,
    fetchingStateDaily.response,
    fetchingStateDaily.isFetching,
    t,
    history,
  ]);

  /////////////////////////////////// fetch summaries and daily after getting timeWorkersIdsString
  useEffect(() => {
    if (timeWorkersIdsString === null) return;
    fetchAgainDaily();
  }, [timeWorkersIdsString, fetchAgainDaily]);

  ////////////////////////////////

  const [filterTablesByDate, setfilterTablesByDate] =
    useState<FilterTablesByDateType>("currentMonth");
  const [currentOptionName, setCurrentOptionName] = useState<string>(
    t("current_month")
  );

  return (
    <div>
      <TopBanner pathName={t("home_page")} />
      <div className="flexAndCenter">
        <div>
          <div className={styles.chartsGrid}>
            <div>
              <AbsencesChart />
            </div>
            {!fetchingStateDaily.isFetching && !fetchingStateDaily.isError && (
              <>
                <YesterdayTwoDaysAgoChart
                  titleString={t("worktime")}
                  yesterday={workingTimeYesterday}
                  twoDaysAgo={workingTimeDayBeforeYesterday}
                />
                <YesterdayTwoDaysAgoChart
                  titleString={t("overtime")}
                  yesterday={overtimeYesterday}
                  twoDaysAgo={overtimeDayBeforeYesterday}
                />
                <YesterdayTwoDaysAgoChart
                  titleString={t("not_worked")}
                  ifTimeFormat={false}
                  yesterday={notWorkedYesterday}
                  twoDaysAgo={notWorkedDayBeforeYesterday}
                />

                <YesterdayTwoDaysAgoChart
                  titleString={t("worked_days2")}
                  ifTimeFormat={false}
                  yesterday={workedDaysYesterday}
                  twoDaysAgo={workedDaysDayBeforeYesterday}
                  rightArrowButtonPath="/superior-time/summary/attendance-report"
                />
              </>
            )}
          </div>
        </div>
        {unexcusedAbsence.rows !== null &&
          lateData.rows !== null &&
          unfinishedWorkingTime.rows !== null && (
            <div>
              <SelectMenu
                name={currentOptionName}
                items={[
                  {
                    optionName: t("today"),
                    onClick: () => {
                      setfilterTablesByDate("today");
                      setCurrentOptionName(t("today"));
                    },
                  },
                  {
                    optionName: t("yesterday"),
                    onClick: () => {
                      setfilterTablesByDate("yesterday");
                      setCurrentOptionName(t("yesterday"));
                    },
                  },
                  {
                    optionName: t("day_before_yesterday"),
                    onClick: () => {
                      setfilterTablesByDate("dayBeforeYesterday");
                      setCurrentOptionName(t("day_before_yesterday"));
                    },
                  },
                  {
                    optionName: t("current_week"),
                    onClick: () => {
                      setfilterTablesByDate("currentWeek");
                      setCurrentOptionName(t("current_week"));
                    },
                  },
                  {
                    optionName: `${t("last")} 7 ${t("days")}`,
                    onClick: () => {
                      setfilterTablesByDate("lastWeek");
                      setCurrentOptionName(`${t("last")} 7 ${t("days")}`);
                    },
                  },
                  {
                    optionName: t("current_month"),
                    onClick: () => {
                      setfilterTablesByDate("currentMonth");
                      setCurrentOptionName(t("current_month"));
                    },
                  },
                  {
                    optionName: `${t("last")} 30 ${t("days")}`,
                    onClick: () => {
                      setfilterTablesByDate("lastMonth");
                      setCurrentOptionName(`${t("last")} 30 ${t("days")}`);
                    },
                  },
                  {
                    optionName: t("previous_month"),
                    onClick: () => {
                      setfilterTablesByDate("previousMonth");
                      setCurrentOptionName(t("previous_month"));
                    },
                  },
                ]}
              />
            </div>
          )}
        <div>
          <div>
            <LoadingWrapper
              isLodadingProgress={fetchingStateDaily.isFetching}
              isError={fetchingStateDaily.isError}
              setIfFetchAgain={(fetchAgain) => {
                if (fetchingStateDaily.isError) fetchAgainDaily();
              }}
            >
              {unexcusedAbsence.rows !== null &&
                lateData.rows !== null &&
                unfinishedWorkingTime.rows !== null && (
                  <div className={styles.accordionsContainer}>
                    <AccordingSummaryWithTable
                      filterTablesByDate={filterTablesByDate}
                      rows={unexcusedAbsence.rows}
                      columns={unexcusedAbsence.columns}
                      title={unexcusedAbsence.title}
                      layoutSettingName={
                        "layoutSuperiorTimeHomePageUnexcusedAbsence"
                      }
                    />
                    <AccordingSummaryWithTable
                      filterTablesByDate={filterTablesByDate}
                      rows={lateData.rows}
                      columns={lateData.columns}
                      title={lateData.title}
                      layoutSettingName={"layoutSuperiorTimeHomePageLate"}
                    />
                    <AccordingSummaryWithTable
                      filterTablesByDate={filterTablesByDate}
                      rows={unfinishedWorkingTime.rows}
                      columns={unfinishedWorkingTime.columns}
                      title={unfinishedWorkingTime.title}
                      layoutSettingName={
                        "layoutSuperiorTimeHomePageUnfinishedWorkingTime"
                      }
                    />
                  </div>
                )}
            </LoadingWrapper>

            <div className={styles.accordionsContainer}>
              <div>
                <HolidaysRequestsAccordion
                  startDateFetch={twoMonthsAgo}
                  ifOnlyIntroduced={true}
                  settingsLayoutName={
                    "layoutSuperiorTimeListsHolidaysRequestsHomePage"
                  }
                />
              </div>
              <div>
                <HoursRequestsAccordion
                  startDateFetch={twoMonthsAgo}
                  ifOnlyIntroduced={true}
                  settingsLayoutName={
                    "layoutSuperiorTimeListsHoursRequestsHomePage"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
