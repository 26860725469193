import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";

const HomePageSMSUser = () => {
  const { t } = useTranslation();

  return (
    <div>
      <TopBanner pathName={t("home_page")} />
      <div className="flexAndCenter"></div>
    </div>
  );
};

export default HomePageSMSUser;
