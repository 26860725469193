import { useCallback, useState } from "react";
import { StatusInPortsType } from "../../../Constants/statusInPortsType";
import ConfrimButton from "./ConfrimButton";
import RejectPopup from "./RejectPopup";
import styles from "./styles.module.scss";
import { ProfileType } from "../../../enums/profileType";

interface Props {
  patchApi: string;
  successCallback?: () => void;
  newStatusIdConfirm: StatusInPortsType;
  ifHideConfirm?: boolean;
  ifHideReject?: boolean;
  typeOfVisitId?: number;
  permissionZoneId?: number | null;
  permissionZonesPatchApi?: string;
  prefixPathName?: string;
  notificationId?: number;
  pathApiNotifications?: string;
  isSecurity: boolean;
  isToPfsoAndShiftDirector?: boolean;
  profileType: ProfileType;
}

const OperationsButtons = ({
  successCallback,
  patchApi,
  newStatusIdConfirm,
  ifHideConfirm,
  ifHideReject,
  typeOfVisitId,
  permissionZoneId,
  permissionZonesPatchApi,
  prefixPathName,
  notificationId,
  pathApiNotifications,
  isSecurity,
  isToPfsoAndShiftDirector,
  profileType,
}: Props) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isOpenRejectPopup, setIsOpenRejectPopup] = useState(false);

  const handleCloseRejectPopup = useCallback(() => {
    setIsOpenRejectPopup(false);
  }, []);

  const handleOpenRejectPopup = useCallback(() => {
    setIsOpenRejectPopup(true);
  }, []);

  return (
    <>
      <div className={styles.buttonsContainer}>
        {!ifHideConfirm && (
          <div>
            <ConfrimButton
              pathApi={patchApi}
              successCallback={successCallback}
              newStatusIdConfirm={newStatusIdConfirm}
              getIsFetchingState={setIsFetching}
              typeOfVisitId={typeOfVisitId}
              permissionZoneId={permissionZoneId}
              permissionZonesPatchApi={permissionZonesPatchApi}
              prefixPathName={prefixPathName}
              notificationId={notificationId}
              pathApiNotifications={pathApiNotifications}
              isToPfsoAndShiftDirector={isToPfsoAndShiftDirector}
              profileType={profileType}
            />
          </div>
        )}
        <span style={isFetching ? { display: "none" } : {}}>
          {!ifHideReject && (
            <div>
              <RejectPopup
                patchApi={patchApi}
                successCallback={successCallback}
                isOpen={isOpenRejectPopup}
                closePopup={handleCloseRejectPopup}
                openPopup={handleOpenRejectPopup}
                showRefuseButton={true}
                prefixPathName={prefixPathName}
                isSecurity={isSecurity}
              />
            </div>
          )}
        </span>
      </div>
    </>
  );
};

export default OperationsButtons;
