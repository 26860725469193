import { Dialog, DialogProps } from "@material-ui/core";
import React, { ReactElement } from "react";

interface Props extends DialogProps {
  children: React.ReactNode | React.ReactNode[];
  columns?: "one" | "two";
  disableBackdropClick?: boolean;
}

export default function DialogStyled({
  children,
  columns,
  disableBackdropClick,
  onClose,
  ...restProps
}: Props): ReactElement {
  return (
    <Dialog
      maxWidth={false}
      {...restProps}
      onClose={(event, reason) => {
        if (
          (!disableBackdropClick ||
            (disableBackdropClick && reason !== "backdropClick")) &&
          onClose
        ) {
          onClose(event, reason);
        }
      }}
    >
      {children}
    </Dialog>
  );
}
