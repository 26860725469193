import React, { useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../../../helpersComponents/Button/button";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "./holiday.scss";
import { HolidayRequestTypeFunction } from "../../../../enums/holidayRequestTypeFunction";
import RejectionReasonItem from "./RejectionReasonItem";

const RejectionReasonPopup: React.FC<any> = ({
  rows,
  onApplyChanges,
  onCancelChanges,
  open,
}: any) => {
  const { t } = useTranslation();
  const [responseErrorMessage, setResponseErrorMessage] = useState<string>();
  const [putingPostingCircularProgress, setPutingPostingCircularProgress] =
    useState<boolean>(false);

  const applyChanges = useRef(onApplyChanges);

  const [rejectionFormValues, setRejectionFormValues] = useState<any>({});
  const [rejectionFormErrors, setRejectionFormErrors] = useState<any>({});

  const getRejectionReason = (id: number) => {
    let result = "";

    const option1Name = `${id}_option1`;
    const option2Name = `${id}_option2`;
    const textBoxName = `${id}_customReason`;

    if (rejectionFormValues[option1Name]) {
      result += t("privileged_home_office_rejection_reason_1");
    }

    if (rejectionFormValues[option2Name]) {
      if (result) result += ",";
      result += t("privileged_home_office_rejection_reason_2");
    }

    if (rejectionFormValues[textBoxName]) {
      if (result) result += ",";
      result += rejectionFormValues[textBoxName];
    }

    return result;
  };

  const confirm = () => {
    let anyErrors = false;
    let result = {};

    if (rows) {
      for (let i = 0; i < rows.length; i++) {
        if (
          rows[i].typyFunctionId ===
          HolidayRequestTypeFunction.PRIVILEGED_HOME_OFFICE
        ) {
          const option1Name = `${rows[i].id}_option1`;
          const option2Name = `${rows[i].id}_option2`;
          const textBoxName = `${rows[i].id}_customReason`;
          const errorName = `${rows[i].id}`;

          result[rows[i].id] = getRejectionReason(rows[i].id);

          if (
            !rejectionFormValues[option1Name] &&
            !rejectionFormValues[option2Name] &&
            !rejectionFormValues[textBoxName]
          ) {
            setRejectionFormErrors((prev) => {
              return { ...prev, [errorName]: true };
            });
            anyErrors = true;
          } else {
            setRejectionFormErrors((prev) => {
              return { ...prev, [errorName]: false };
            });
          }
        }
      }
    }

    if (!anyErrors) {
      if (applyChanges.current) {
        applyChanges.current(rows, result);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancelChanges}
      TransitionProps={{
        onExited: () => {
          setResponseErrorMessage("");
          setPutingPostingCircularProgress(false);
        },
      }}
    >
      <DialogTitle className="dialogTitleGlobal">
        <div className="titlePopup">{t("request_rejection_reason")}</div>
      </DialogTitle>
      <DialogContent>
        <div>
          <p className="rejectionReasonParagraph">
            Uzasadnienie odmowy udzielenia zgody w przypadku wniosku o pracę
            zdalną na podstawie art. 67<sup>19</sup>§ 6 Kodeksu pracy:
          </p>
        </div>

        {rows
          ?.filter(
            (el) =>
              el.typyFunctionId ===
              HolidayRequestTypeFunction.PRIVILEGED_HOME_OFFICE
          )
          .map((el) => {
            return (
              <RejectionReasonItem
                row={el}
                formValues={rejectionFormValues}
                setFormValues={setRejectionFormValues}
                formErrors={rejectionFormErrors}
                setFormErrors={setRejectionFormErrors}
              />
            );
          })}
      </DialogContent>
      <DialogActions className="dialogActionsGlobal">
        <div className="responseErrorMessage">{responseErrorMessage}</div>

        {!putingPostingCircularProgress ? (
          <>
            <Button onClick={onCancelChanges}>{t("cancel")}</Button>
            <Button
              onClick={() => {
                confirm();
              }}
            >
              {t("confirm")}
            </Button>
          </>
        ) : (
          <div className="confirmWaiting">
            <CircularProgress size={30} />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RejectionReasonPopup;
