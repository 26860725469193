import styles from "./styles.module.scss";
import DialogStyled from "../../../../helpersComponents/PopupHelpers/dialogStyled";
import DialogTitleStyled from "../../../../helpersComponents/PopupHelpers/dialogTitleStyled";
import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../../../helpersComponents/PopupHelpers/dialogActionsStyled";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DialogContentStyled } from "../../../../helpersComponents/PopupHelpers";
import { useCallback, useEffect, useState } from "react";
//import { useSnackbar } from "notistack";
import DatePickerFormik from "../../../FormikInputs/DatePicker/datePicker";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import erro400getTranslatedErrorString from "../../../../../HelpersFunctions/erro400getTranslatedErrorString";

interface IProps {
  guestId: number;
  trainingExpiryDateApiPath: string;
  isOpen: boolean;
  closePopup: () => void;
  successCallback: () => void;
}

function ChangeTrainingExpiryDatePopup({
  guestId,
  trainingExpiryDateApiPath,
  isOpen,
  closePopup,
  successCallback,
}: IProps) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [bodyRequest, setBodyRequest] = useState<any>(false);

  const [
    currentExpiryDate,
    fetchingStateCurrentExpiryDate,
    fetchAgainCurrentExpiryDate,
  ] = useFetchAndSetGET({
    path: `${trainingExpiryDateApiPath}/training-expiry-date?guestId=${guestId}`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (isOpen && guestId) {
      fetchAgainCurrentExpiryDate();
    }
  }, [isOpen, guestId, fetchAgainCurrentExpiryDate]);

  const handleErrors = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setErrorMessage(error);
      }
    },
    [t]
  );

  const [fetchingStateChnageExpiryDate, fetchAgainExpiryDate] =
    useFetchOtherThanGET({
      path: `${trainingExpiryDateApiPath}/training-expiry-date/${guestId}`,
      method: "PATCH",
      body: bodyRequest,
      setBody: setBodyRequest,
      contentType: "application/json",
      disableErrorSnackbar: true,
      errorCallback: handleErrors,
      successCallback: () => {
        successCallback();
      },
    });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainExpiryDate();
    }
  }, [bodyRequest, fetchAgainExpiryDate]);

  const getInitialData = useCallback(() => {
    return { trainingExpiryDate: currentExpiryDate };
  }, [currentExpiryDate]);

  return (
    <>
      <DialogStyled
        open={isOpen}
        onClose={closePopup}
        TransitionProps={{
          onExited: () => {},
          onEnter: () => {},
        }}
      >
        <DialogTitleStyled title={t("changing_training_expiration_date")} />
        <DialogContentStyled>
          {!fetchingStateCurrentExpiryDate.isFetching && (
            <Formik
              validateOnBlur={false}
              initialValues={getInitialData()}
              enableReinitialize={false}
              onSubmit={(values: { trainingExpiryDate: Date | null }) => {
                let body: PathProperty[] = [];

                body.push({
                  path: "/trainingExpiryDate",
                  op: "replace",
                  value: values.trainingExpiryDate,
                });

                setBodyRequest(JSON.stringify(body));
              }}
              validationSchema={Yup.object({
                trainingExpiryDate: Yup.date()
                  .nullable()
                  .required(t("field_required"))
                  .test(
                    "is-future-date",
                    t("TRAINING_EXPIRY_DATE_CANT_BE_PAST"),
                    function (value) {
                      if (value) {
                        const currentDate = new Date();
                        // Usuń czas z daty aktualnej
                        currentDate.setHours(0, 0, 0, 0);
                        // Usuń czas z daty do walidacji
                        value.setHours(0, 0, 0, 0);
                        return value >= currentDate;
                      }
                      return true;
                    }
                  ),
              })}
            >
              {({ values, setFieldValue, setFieldError, setFieldTouched }) => {
                return (
                  <Form noValidate id="submitForm">
                    <div className={styles.changeExpiryDate}>
                      <div className="titleWidthSubstitutions">
                        {t("training_expiration_date")}:
                      </div>
                      <div className="inputWidthSubstitutions">
                        <DatePickerFormik
                          label={t("training_expiry_date")}
                          name="trainingExpiryDate"
                          view={["year", "month", "date"]}
                          format="dd-MM-yyyy"
                          required={true}
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </DialogContentStyled>
        <DialogActionsStyled
          typConfirm="submit"
          formConfirm="submitForm"
          onConfirmText={t("save")}
          onCancel={() => {
            setErrorMessage("");
            closePopup();
          }}
          isLoading={fetchingStateChnageExpiryDate.isFetching}
          errorString={errorMessage}
        />
      </DialogStyled>
    </>
  );
}

export default ChangeTrainingExpiryDatePopup;
