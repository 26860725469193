import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import computeDate from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import LoadingWrapper from "../../LoadingWrapper/loadingWrapper";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import { ProfileType } from "../../../../enums/profileType";

const HolidayLeftInfo = ({
  startDate,
  endDate,
  open,
  timeWorkerId,
}: {
  startDate: Date;
  endDate: Date;
  open: boolean;
  timeWorkerId?: number;
}) => {
  const { t } = useTranslation();

  const [scheduleData, setScheduleData] = useState<any>();
  const [scheduleDataWorkingDaysAmount, setScheduleDataWorkingDaysAmount] =
    useState<any>();
  const [isErrorSchedule, setIsErrorSchedule] = useState<boolean>(false);
  const [isLoadingSchedule, setIsLoadingSchedule] = useState<boolean>(true);
  const [ifFetchSchedule, setIfFetchSchedule] = useState(true);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;

  const scheduleResponse = useFetchGet(
    profileType === ProfileType.SUPERIOR_TIME
      ? `superior-time/schedules-for-time-worker?TimeWorkerId=${timeWorkerId}&DateFrom=${computeDate(
          startDate,
          "T00:00:00"
        )}&DateTo=${computeDate(endDate)}`
      : `worker-time/${authUserId}/Schedules?DateFrom=${computeDate(
          startDate,
          "T00:00:00"
        )}&DateTo=${computeDate(endDate)}`,
    ifFetchSchedule && open
  );

  useEffect(() => {
    if (scheduleResponse.error !== null) {
      setIsErrorSchedule(true);
      setIfFetchSchedule(false);
      return;
    }
    if (scheduleResponse.data === null) return;

    let workingDaysAmount = 0;
    scheduleResponse.data.forEach((daySchedule) => {
      if (daySchedule.workStandardInMinutes !== 0) workingDaysAmount++;
    });

    setScheduleDataWorkingDaysAmount(workingDaysAmount);
    setIfFetchSchedule(false);
    setIsLoadingSchedule(false);
    setScheduleData(scheduleResponse.data);
  }, [scheduleResponse]);

  useEffect(() => {
    setIsErrorSchedule(false);
    setIfFetchSchedule(true);
    setIsLoadingSchedule(true);
  }, [startDate, endDate, timeWorkerId]);

  return (
    <LoadingWrapper
      isLodadingProgress={isLoadingSchedule}
      isError={isErrorSchedule}
      setIfFetchAgain={setIfFetchSchedule}
      setIsError={setIsErrorSchedule}
    >
      {!isLoadingSchedule && !isErrorSchedule && (
        <div className="scheduleContainer">
          <div className="titleWidthHoliday sheduleTitle">
            {t("schedule")}: (<b>{scheduleDataWorkingDaysAmount}</b>)
          </div>
          {scheduleData?.map((daySchedule, i) => {
            const startDate = new Date(daySchedule.startDate);
            return (
              <div className="scheduleSingleDay" key={i}>
                <div>
                  <b>
                    {startDate.toLocaleString(t("scheduler_language"), {
                      day: "2-digit",
                    })}
                    &nbsp;
                    {startDate.toLocaleString(t("scheduler_language"), {
                      weekday: "short",
                    })}
                  </b>
                </div>
                <div>
                  {daySchedule.workStandardInMinutes === 0 ? (
                    <span className="freeDayColor">{t("wolne")}</span>
                  ) : (
                    convertMinsToHrsMins(daySchedule.workStandardInMinutes)
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </LoadingWrapper>
  );
};

export default HolidayLeftInfo;
