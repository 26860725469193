import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import "./events.scss";
import Map from "../../../helpersComponents/Map/map";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";

const RowDetail: React.FC = ({ row }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const eventResponse = useFetchGet(
    "superior-time/events/" + row.id,
    ifFetchAgain
  );

  useEffect(() => {
    if (eventResponse.error !== null) {
      setIsError(true);
      setIfFetchAgain(false);
      return;
    }
    if (eventResponse.data === null) return;
    setIsLoading(false);
    setIfFetchAgain(false);
  }, [eventResponse]);

  return (
    <div className="eventsSuperriorContainer">
      <LoadingWrapper
        isLodadingProgress={isLoading}
        isError={isError}
        setIfFetchAgain={setIfFetchAgain}
        setIsError={setIsError}
      >
        {!isLoading && !isError && (
          <>
            {row.latitude !== null && row.longitude !== null && (
              <div className="mapEventsSuperrior">
                <Map position={[row.latitude, row.longitude]} />
              </div>
            )}
            <div className="superiorEventsImage">
              {eventResponse.data?.photos.map((data, index) => {
                return (
                  <img
                    key={index}
                    alt={eventResponse.data.workerName}
                    src={`data:image/jpeg;base64,${data}`}
                  />
                );
              })}
            </div>
          </>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default RowDetail;
