import { DialogContent, FormControlLabel } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteStyled,
  CheckboxStyled,
  DatePickerStyled,
  TextFieldStyled,
} from "../../../helpersComponents/MaterialUi";
import styles from "./addTask.module.scss";
import Button from "../../../helpersComponents/Button/button";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import computeDate from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchPostFormData from "../../../../hooks/fetchHooks/post/useFetchPostFormData";
import { useSnackbar } from "notistack";
import { taskPriority } from "./taskPriority";
import useFetchPutFormData from "../../../../hooks/fetchHooks/put/useFetchPutFormData";
import AttachmentsNewOld from "../../../helpersComponents/Attachments/attachmentsNewOld";
import erro400getTranslatedErrorString from "../../../../HelpersFunctions/erro400getTranslatedErrorString";
import TextEditor from "../../../helpersComponents/TextEditor/textEditor";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import {
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../../helpersComponents/PopupHelpers";

interface Props {
  projectId?: number;
  setIsDialogOpen: React.Dispatch<boolean>;
  projectStages: any;
  setIfFetchAgain: React.Dispatch<boolean>;
  task?: any;
  projectsList?: any;
  setNewProjectId?: React.Dispatch<number>;
  setIfFetchAgainTaskStages?: React.Dispatch<boolean>;
  isFetchingNewStages: boolean;
  confidentialData?: string | false;
}

export default function AddEditTaskDialogContent({
  projectId,
  setIsDialogOpen,
  setIfFetchAgain,
  projectStages,
  task,
  projectsList,
  setNewProjectId,
  setIfFetchAgainTaskStages,
  isFetchingNewStages,
  confidentialData,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [postRequestBody, setPostRequestBody] = useState<any>(false);
  const [putRequestBody, setPutRequestBody] = useState<any>(false);
  const [putingPostingCircularProgress, setPutingPostingCircularProgress] =
    useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [oldFilesRemoved, setOldFilesRemoved] = useState<any>([]);
  const [selectedProject, setselectedProject] = useState(() => {
    let defaoultProject = projectsList?.find(
      (project) => project.isDefault === true
    );
    return defaoultProject;
  });

  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  let postRequest = useFetchPostFormData(
    `worker-time/${authUserId}/projects/${
      projectId ? projectId : selectedProject.id
    }/tasks`,
    postRequestBody
  );

  let putRequest = useFetchPutFormData(
    `worker-time/${authUserId}/projects/${projectId}/tasks/${task?.id}`,
    putRequestBody
  );

  const [responseErrorMessage, setResponseErrorMessage] = useState<string>("");
  const [name, setName] = useState<String>(task ? task?.name : "");
  const [nameError, setNameError] = useState<any>({
    isError: false,
    errorMessage: "",
  });

  const [startDate, setStartDate] = useState<Date>(
    task ? new Date(task?.dateFrom) : new Date()
  );
  const [startDateError, setStartDateError] = useState<any>({
    isError: false,
    errorMessage: "",
  });

  const [endDate, setEndDate] = useState<Date>(
    task?.dateTo ? new Date(task.dateTo) : new Date()
  );
  const [endDateError, setEndDateError] = useState<any>({
    isError: false,
    errorMessage: "",
  });

  const [checkbox, setCheckbox] = React.useState({
    startDate: task ? true : false,
    endDate: task ? (task?.dateTo === null ? false : true) : false,
  });
  const findStage = (stageId) => {
    return projectStages.find((stage) => {
      if (stageId === null) return stage.id === 0;
      else return stage.id === stageId;
    });
  };
  const [selectedStage, setSelectedStage] = useState<any>(
    task ? findStage(task?.stageId) : projectStages[0]
  );

  const [editorContent, setEditorContent] = useState(
    task && task?.description !== null ? task.description : ""
  );

  const [selectedPriority, setSelectedPriority] = useState<any>(
    task ? taskPriority[task?.priority] : taskPriority[0]
  );

  const [selectedPriorityError, setSelectedPriorityError] = useState<any>({
    isError: false,
    errorMessage: "",
  });

  const [confidentialInformation, setConfidentialInformation] =
    useState<String>(confidentialData ? confidentialData : "");

  const handleChangeCheckbox = (event) => {
    setCheckbox({ ...checkbox, [event.target.name]: event.target.checked });
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (postRequest.data?.status === 201) {
      setIfFetchAgain(true);
      setIsDialogOpen(false);
      enqueueSnackbar(t("task_added"), { variant: "success" });
      setPutingPostingCircularProgress(false);
    } else if (putRequest.data?.status === 200) {
      setIfFetchAgain(true);
      setIsDialogOpen(false);
      enqueueSnackbar(t("task_edited"), { variant: "success" });
      setPutingPostingCircularProgress(false);
    } else if (postRequest.data?.status === 400) {
      setPutingPostingCircularProgress(false);
      setIfFetchAgain(true);
      setResponseErrorMessage(
        erro400getTranslatedErrorString(postRequest.data.errors, t)
      );
    } else if (putRequest.data?.status === 400) {
      setPutingPostingCircularProgress(false);
      setIfFetchAgain(true);
      setResponseErrorMessage(
        erro400getTranslatedErrorString(putRequest.data.errors, t)
      );
    } else if (postRequest.error !== null || putRequest.error !== null) {
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(t("something_went_wrong"));
    }
  }, [
    postRequest,
    putRequest,
    enqueueSnackbar,
    setIsDialogOpen,
    setIfFetchAgain,
    t,
  ]);

  const confirm = () => {
    let ifFetch = true;

    if (name === "") {
      ifFetch = false;
      setNameError({
        isError: true,
        errorMessage: "",
      });
    } else {
      setNameError({
        isError: false,
        errorMessage: "",
      });
    }
    let bodyRequest = {};

    if (checkbox.startDate === true) {
      if (startDate === null || isNaN(startDate.getTime())) {
        setStartDateError({
          isError: true,
          errorMessage: t("invalid_date"),
        });
        ifFetch = false;
      } else {
        bodyRequest["dateFrom"] = computeDate(startDate);
        setStartDateError({
          isError: false,
          errorMessage: "",
        });
      }
    } else {
      bodyRequest["dateFrom"] = null;
    }
    if (checkbox.endDate === true) {
      if (endDate === null || isNaN(endDate.getTime())) {
        setEndDateError({
          isError: true,
          errorMessage: t("invalid_date"),
        });
        ifFetch = false;
      } else {
        bodyRequest["dateTo"] = computeDate(endDate);
        setEndDateError({
          isError: false,
          errorMessage: "",
        });
      }
    } else {
      bodyRequest["dateTo"] = null;
    }
    if (selectedPriority === null) {
      ifFetch = false;
      setSelectedPriorityError({
        isError: true,
        errorMessage: "",
      });
    } else {
      bodyRequest["priority"] = selectedPriority.id;
      setSelectedPriorityError({
        isError: false,
        errorMessage: "",
      });
    }

    if (selectedStage?.id !== 0 && selectedStage !== null)
      bodyRequest["stageId"] = selectedStage.id;
    else bodyRequest["stageId"] = null;
    if (confidentialInformation !== "")
      bodyRequest["confidentialInformation"] = confidentialInformation;

    if (editorContent) bodyRequest["description"] = editorContent;

    bodyRequest["name"] = name;
    if (oldFilesRemoved.length > 0) {
      bodyRequest["attachmentsToRemove"] = oldFilesRemoved;
    }
    if (ifFetch === false) return;

    setPutingPostingCircularProgress(true);

    let formdata: any = new FormData();

    selectedFiles.forEach((file) => {
      formdata.append("attachments", file);
    });

    if (task) {
      bodyRequest["status"] = task?.status;
      formdata.append("requestData", JSON.stringify(bodyRequest));
      setPutRequestBody(formdata);
    } else {
      formdata.append("requestData", JSON.stringify(bodyRequest));
      setPostRequestBody(formdata);
    }
  };

  return (
    <>
      <DialogContent>
        <TwoColumnsRowsContainer>
          <Row>
            <RowTitle>{t("name")}:</RowTitle>
            <RowInput>
              <TextFieldStyled
                label={t("name")}
                defaultValue={name}
                required={true}
                isError={nameError.isError}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setNameError({
                      isError: true,
                      errorMessage: "required",
                    });
                  } else {
                    setNameError({
                      isError: false,
                      errorMessage: "",
                    });
                  }
                  setName(e.target.value);
                }}
              />
            </RowInput>
          </Row>
          {projectsList !== undefined && (
            <Row>
              <RowTitle>{t("select_project")}:</RowTitle>
              <RowInput>
                <AutocompleteStyled
                  options={projectsList}
                  getOptionLabel={(option: any) => {
                    return option.name;
                  }}
                  width="100%"
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={selectedProject}
                  onChange={(_, newValue) => {
                    if (newValue === null) return;
                    setselectedProject(newValue);
                    if (setIfFetchAgainTaskStages && setNewProjectId) {
                      setIfFetchAgainTaskStages(true);
                      setNewProjectId(newValue.id);
                      setSelectedStage(null);
                    }
                  }}
                  label={t("select_project")}
                />
              </RowInput>
            </Row>
          )}
        </TwoColumnsRowsContainer>

        <TwoColumnsRowsContainer>
          <Row>
            <RowTitle>
              <FormControlLabel
                control={
                  <CheckboxStyled
                    checked={checkbox.startDate}
                    disabled={task ? true : false}
                    name="startDate"
                    onChange={handleChangeCheckbox}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={<span className="chekbox">{t("date_from")}:</span>}
              />
            </RowTitle>
            <RowInput>
              {checkbox.startDate ? (
                <DatePickerStyled
                  keyboardDateTimePicker={true}
                  view={["year", "month", "date", "hours", "minutes"]}
                  format="dd-MM-yyyy HH:mm"
                  date={startDate}
                  isError={startDateError.isError}
                  width="100%"
                  setDate={(date) => {
                    setStartDate(date);
                  }}
                />
              ) : (
                <></>
              )}
            </RowInput>
          </Row>
          <Row>
            <RowTitle>
              <FormControlLabel
                control={
                  <CheckboxStyled
                    checked={checkbox.endDate}
                    name="endDate"
                    onChange={handleChangeCheckbox}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={<span className="chekbox">{t("date_to2")}:</span>}
              />
            </RowTitle>
            <RowInput>
              {checkbox.endDate && (
                <DatePickerStyled
                  keyboardDateTimePicker={true}
                  view={["year", "month", "date", "hours", "minutes"]}
                  format="dd-MM-yyyy HH:mm"
                  date={endDate}
                  isError={endDateError.isError}
                  width="100%"
                  setDate={(date) => {
                    setEndDate(date);
                  }}
                />
              )}
            </RowInput>
          </Row>
        </TwoColumnsRowsContainer>

        <TwoColumnsRowsContainer>
          <Row>
            <RowTitle>{t("select_stage")}:</RowTitle>
            <RowInput>
              {isFetchingNewStages ? (
                <CircularProgress />
              ) : (
                <AutocompleteStyled
                  options={projectStages}
                  getOptionLabel={(option: any) => {
                    return option.name;
                  }}
                  width="100%"
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={selectedStage ? selectedStage : null}
                  onChange={(_, newValue) => {
                    setSelectedStage(newValue);
                  }}
                  label={t("select_stage")}
                />
              )}
            </RowInput>
          </Row>
          <Row>
            <RowTitle>{t("select_priority")}:</RowTitle>
            <RowInput>
              <AutocompleteStyled
                options={taskPriority}
                getOptionLabel={(option: any) => {
                  return t(option.name);
                }}
                width="100%"
                getOptionSelected={(option, value) => option.id === value.id}
                value={selectedPriority ? selectedPriority : null}
                label={t("select_priority")}
                required={true}
                isError={selectedPriorityError.isError}
                onChange={(_, newValue) => {
                  setSelectedPriority(newValue);
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setSelectedPriorityError({
                      isError: true,
                      errorMessage: "",
                    });
                  } else {
                    setSelectedPriorityError({
                      isError: false,
                      errorMessage: "",
                    });
                  }
                }}
              />
            </RowInput>
          </Row>
        </TwoColumnsRowsContainer>

        {/* <TwoColumnsRowsContainer>
          <Row>
            <RowTitle>{t("confidential_information")}:</RowTitle>
            <RowInput>
              <TextFieldStyled
                multiline
                minRows={4}
                label={t("confidential_information")}
                defaultValue={confidentialInformation}
                onBlur={(e) => {
                  setConfidentialInformation(e.target.value);
                }}
              />
            </RowInput>
          </Row> */}
        {/* <Row>
          <AttachmentsNewOld
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            oldFilesRemoved={oldFilesRemoved}
            setOldFilesRemoved={setOldFilesRemoved}
            path={`worker-time/${authUserId}/projects/${projectId}/tasks/${task.id}/attachments`}
            oldFiles={task.attachments}
            inputProps={{ multiple: true }}
            // disableOld={true}
          />
          </Row> */}
        {/* </TwoColumnsRowsContainer> */}

        <TwoColumnsRowsContainer>
          <Row>
            <RowTitle>{t("confidential_information")}:</RowTitle>
            <RowInput>
              <TextFieldStyled
                multiline
                minRows={4}
                label={t("confidential_information")}
                defaultValue={confidentialInformation}
                onBlur={(e) => {
                  setConfidentialInformation(e.target.value);
                }}
              />
            </RowInput>
          </Row>

          <Row>
            <AttachmentsNewOld
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              oldFilesRemoved={oldFilesRemoved}
              setOldFilesRemoved={setOldFilesRemoved}
              path={`worker-time/${authUserId}/projects/${projectId}/tasks/${task.id}/attachments`}
              oldFiles={task.attachments}
              inputProps={{ multiple: true }}
              // disableOld={true}
            />
          </Row>
        </TwoColumnsRowsContainer>

        <div className={styles.editor}>
          <TextEditor
            htmlContent={editorContent}
            setHtmlContent={setEditorContent}
          />
        </div>
      </DialogContent>

      <DialogActions className="dialogActionsGlobal">
        <div className="responseErrorMessage">{responseErrorMessage}</div>
        {!putingPostingCircularProgress ? (
          <>
            <Button onClick={() => setIsDialogOpen(false)}>
              {t("cancel")}
            </Button>
            <Button
              onClick={() => {
                confirm();
              }}
            >
              {t("confirm")}
            </Button>
          </>
        ) : (
          <div className="confirmWaiting">
            <CircularProgress size={30} />
          </div>
        )}
      </DialogActions>
    </>
  );
}
