import FireReportContent from "../../helpersComponents/FireReport/fireReport";

function FireReportShiftDirector() {
  return (
    <div>
      <FireReportContent
        fireReportPath="shift-director/fire-report"
        layoutSettingName="layoutSettingNameShiftDirectorFireReport"
        fireZonesPath="shift-director/fire-zones"
        pathPreferences="shift-director/preferences"
        targetCompaniesPath="shift-director/guests-visits-target-companies"
      />
    </div>
  );
}

export default FireReportShiftDirector;
