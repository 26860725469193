import React from "react";
import NotificationsContent from "./notificationsContent";

function Notifications() {
  return (
    <div>
      <NotificationsContent />
    </div>
  );
}

export default Notifications;
