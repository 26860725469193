import React, { ReactElement, useContext } from "react";
import { contextInputTitleWidth } from "./dialogContentStyled";
import styles from "./popupHelpers.module.scss";

interface Props {
  children: React.ReactNode | React.ReactNode[];
  width?: number | string;
  style?: any;
}

export default function RowInput({
  children,
  width,
  style,
}: Props): ReactElement {
  const inputTitleWidth = useContext(contextInputTitleWidth);
  let widthFinal: string | number = 300;
  if (width) {
    widthFinal = width;
  } else {
    widthFinal = inputTitleWidth?.inputWidth
      ? inputTitleWidth?.inputWidth
      : 300;
  }
  return (
    <div
      className={styles.rowInput}
      style={!style ? { width: widthFinal } : style}
    >
      {children}
    </div>
  );
}
