import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import addDaysToDate from "../../../../HelpersFunctions/dateAndTime/addDaysToDate";
import convertDateFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";

import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";

export default function useFetchingSchedules({
  selectedDate,
  selectedWorkersString,
  setFetchedSchedules,
  setIsGridCreated,
}): [
  {
    isFetching: boolean;
    isError: boolean;
    response: UseFetchResponse | undefined;
  },
  () => void
] {
  const { t } = useTranslation();

  const [starDateFetch, setStarDateFetch] = useState<Date>(
    addDaysToDate(getBeginningOfDate(new Date(), "Month"), -7)
  );
  const [endDateFetch, setEndDateFetch] = useState<Date>(
    addDaysToDate(getEndOfDate(new Date(), "Month"), 7)
  );

  useEffect(() => {
    setStarDateFetch(
      addDaysToDate(getBeginningOfDate(selectedDate, "Month"), -7)
    );
    setEndDateFetch(addDaysToDate(getEndOfDate(selectedDate, "Month"), 7));
  }, [selectedDate]);

  const [schedulesBody, setSchedulesBody] = useState<false | string>(false);

  useEffect(() => {
    let requestBodyObj = {
      dateFrom: convertDateFetch(starDateFetch),
      dateTo: convertDateFetch(endDateFetch),
      timeWorkersIds: selectedWorkersString
        ? selectedWorkersString.split(",").map((e) => parseInt(e))
        : [],
    };

    setSchedulesBody(JSON.stringify(requestBodyObj));
  }, [starDateFetch, endDateFetch, selectedWorkersString]);

  const [fetchingSchedulesState, fetchSchedulesAgain] = useFetchOtherThanGET({
    path: "superior-time/schedules",
    method: "POST",
    contentType: "application/json",
    body: schedulesBody,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (
      fetchingSchedulesState.isError ||
      fetchingSchedulesState.response === undefined
    )
      return;
    if (!Array.isArray(fetchingSchedulesState.response.resJson)) return;
    let data: FetchedWorkersSchedules[] =
      fetchingSchedulesState.response.resJson;

    setFetchedSchedules(data);
    setIsGridCreated(false);
  }, [
    fetchingSchedulesState.isError,
    fetchingSchedulesState.response,
    setIsGridCreated,
    setFetchedSchedules,
    t,
  ]);

  return [fetchingSchedulesState, fetchSchedulesAgain];
}
