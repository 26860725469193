import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

//// moved to cached Fetch
const initialState: any = {
  acWorkersSuperiorArray: undefined,
  acWorkersSuperiorObject: undefined,
};

export const acWorkersListsReducer = createSlice({
  name: "setACWorkers",
  initialState,
  reducers: {
    setACWorkersSuperior: (state, action: PayloadAction<any>) => {
      let acWorkersSuperiorObject = { updateTime: action.payload[1] };
      action.payload.forEach((acWorker) => {
        acWorkersSuperiorObject[acWorker.id] = acWorker;
      });
      state.acWorkersSuperiorArray = action.payload;
      state.acWorkersSuperiorObject = acWorkersSuperiorObject;
    },
  },
});

export const selectACWorkersSuperiorArray = (state: RootState) =>
  state.acWorkersLists.acWorkersSuperiorArray;
export const selectACWorkersSuperiorObject = (state: RootState) =>
  state.acWorkersLists.acWorkersSuperiorObject;
export const { setACWorkersSuperior } = acWorkersListsReducer.actions;
export default acWorkersListsReducer.reducer;
