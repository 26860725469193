import React, { ReactElement } from "react";
import styles from "./errorLabel.module.scss";

interface Props {
  errorName: string;
}

export default function ErrorLabel({ errorName }: Props): ReactElement {
  return <div className={styles.error}>{errorName}</div>;
}
