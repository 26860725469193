import { FormControlLabel } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { CheckboxStyled } from "../../../helpersComponents/MaterialUi";
import TableComponentMemo from "../../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";

function TrainingsContent() {
  const { t, i18n } = useTranslation();

  const columns = [
    { name: "traineeFullName", title: t("employee") },
    { name: "name", title: t("training_name") },
    { name: "examStartDate", title: t("exam_start_date"), type: "date_hours" },
    { name: "examEndDate", title: t("exam_end_date"), type: "date_hours" },
    { name: "examResult", title: t("exam_result") },
    { name: "isExamPassed", title: t("is_exam_passed") },
  ];

  const [rows, setRows] = useState<any[]>([]);

  const getLocalizedName = useCallback(
    (receivedTraining: ReceivedTraining) => {
      switch (i18n.language) {
        case "en":
          return receivedTraining.nameEN;

        case "de":
          return receivedTraining.nameDE;

        case "ru":
          return receivedTraining.nameRU;

        case "uk":
          return receivedTraining.nameUK;

        default:
          return receivedTraining.namePL;
      }
    },
    [i18n.language]
  );

  const getCheckBoxControl = (state: boolean) => {
    return (
      <div>
        <FormControlLabel
          control={
            <CheckboxStyled
              checked={state}
              name="active"
              disabled
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          label={<span className="FormControlLabelStyle"></span>}
        />
      </div>
    );
  };

  const [
    receivedTrainings,
    fetchingStateReceivedTrainings,
    fetchAgainReceivedTrainings,
  ] = useFetchAndSetGET<ReceivedTraining[]>({
    path: `superior-time/trainings/received-trainings`,
    startFetchOnInitial: true,
  });

  useEffect(() => {
    if (receivedTrainings) {
      setRows(
        receivedTrainings.map((receivedTraining, index) => {
          return {
            ...receivedTraining,
            name: getLocalizedName(receivedTraining),
            isExamPassed: getCheckBoxControl(receivedTraining.isExamPassed),
          };
        })
      );
    }
  }, [receivedTrainings, i18n.language, getLocalizedName]);

  return (
    <>
      <TopBanner pathName={t("received_trainings")} />
      <div className="flexAndCenter">
        <div>
          <TableComponentMemo
            layoutSettingName=""
            ifHideWholeToolbalr={true}
            rows={rows}
            columns={columns}
            fetchingState={{
              fetchAgain: () => {
                fetchAgainReceivedTrainings();
              },
              isError: fetchingStateReceivedTrainings.isError,
              isFetching: fetchingStateReceivedTrainings.isFetching,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default TrainingsContent;
