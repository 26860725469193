import ContentWhiteListCompanies from "../../helpersComponents/WhiteList/WhiteListCompanies/ContentWhiteListCompanies";

const WhiteListCompaniesPfso = () => {
  return (
    <ContentWhiteListCompanies
      pathWhiteListCompanies="pfso/white-list-companies"
      pathCompaniesInPorts="pfso/companies-in-ports"
      layoutSettingName="layoutPfsoWhiteListCompaniesRecords"
    />
  );
};

export default WhiteListCompaniesPfso;
