import React from "react";
import "./selectModule.scss";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import ChangePasswordForm from "../UserPanel/changePasswordForm";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  // paper: {
  //     position: 'absolute',
  //     width: 450,
  //     backgroundColor: theme.palette.background.paper,
  //     border: '2px solid #000',
  //     boxShadow: theme.shadows[5],
  //     outline: "none",
  //     borderRadius: "5px",
  // },
}));

function ChangePasswordPopup(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const body = (
    <div className="changePasswordPupup">
      <div className="changePasswordPopupTitle">
        {t("password_change_required")}
      </div>
      <ChangePasswordForm token={props.token} onClose={props.handleClose} />
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export default ChangePasswordPopup;
