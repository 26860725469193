import TrainingsValidityReportContent from "../../../helpersComponents/TrainingsValidityReport/TrainingsValidityReportContext";

const TrainingValidityReportPFSO = () => {
  return (
    <div>
      <TrainingsValidityReportContent
        pathName="pfso/trainings-validity-report"
        layoutSettingName="layoutPFSOTrainingValidityReport"
        trainingExpiryDateApiPath="pfso/trainings-validity-report"
      />
    </div>
  );
};

export default TrainingValidityReportPFSO;
