import React, {
  InputHTMLAttributes,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import useFetchFile from "../../../hooks/fetchHooks/get/useFetchFile";
import Button from "../Button/button";
import styles from "./attachments.module.scss";
import DisplaySingleFileWithDeleteButton from "./displaySingleFileWithDeleteButton";
import SelectFileInput from "./selectFileInput";

interface Props {
  setSelectedFiles: React.Dispatch<File[]>;
  selectedFiles: File[];
  setOldFilesRemoved: React.Dispatch<{ [key in any]: any }[]>;
  oldFilesRemoved: { [key in any]: any }[];
  oldFiles?: false | any[];
  path?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  marginButton?: string;
  title?: string;
  isValidate?: boolean | null;
  disabled?: boolean;
}

export default function AttachmentsNewOld({
  setSelectedFiles,
  selectedFiles,
  setOldFilesRemoved,
  oldFilesRemoved,
  oldFiles = false,
  path,
  inputProps,
  marginButton,
  title,
  isValidate,
  disabled = false,
}: Props): ReactElement {
  const { t } = useTranslation();
  // const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [oldFilesLocal, setOldFilesLocal] = useState<any>(oldFiles);

  const [attachmentIdAndFileName, setAttachmentIdAndFileName] = useState<
    [false | string, string]
  >([false, ""]);
  const { data } = useFetchFile(path ? path : "", attachmentIdAndFileName);
  useEffect(() => {
    if (data === null) return;
    setAttachmentIdAndFileName([false, ""]);
  }, [data]);

  return (
    <>
      <div
        className={`${styles.attachmentsContainer} ${
          isValidate === false ? ` ${styles.error}` : ""
        }`}
      >
        <div>
          <div className={styles.attachments} style={{}}>
            <div
              className={styles.inputSelectTitle}
              style={title ? { fontWeight: "bold" } : {}}
            >
              {title ? (
                title
              ) : (
                <div className={styles.listItem}>{t("new_attachments")}</div>
              )}
              :
            </div>
            <div
              className={styles.inputSelectFile}
              style={marginButton ? { marginLeft: marginButton } : {}}
            >
              <SelectFileInput
                {...inputProps}
                setSelectedFiles={setSelectedFiles}
                disabled={disabled}
              />
            </div>
          </div>
          <div className={styles.filesListContainer}>
            {selectedFiles.length > 0 &&
              selectedFiles[0] &&
              selectedFiles.map((file, index) => {
                return (
                  <DisplaySingleFileWithDeleteButton
                    key={index}
                    fileIndex={index}
                    file={file}
                    allFiles={selectedFiles}
                    setUpadteAllFilesAfterDelete={setSelectedFiles}
                    disabled={disabled}
                  />
                );
              })}
            {(selectedFiles.length === 0 || !selectedFiles[0]) && t("none")}
          </div>
        </div>

        {oldFiles !== false && (
          <div className={styles.oldAttachmentsContainer}>
            <div className={styles.attachments}>
              <div>
                <div className={styles.listItem}>{t("old_attachments")}:</div>
              </div>
            </div>
            <div className={styles.filesListContainer}>
              {oldFilesLocal.map((file, index) => {
                return (
                  <div className={styles.singleFiles} key={index}>
                    <div
                      className={path ? styles.fetchFileStyle : ""}
                      onClick={() => {
                        if (!disabled && path) {
                          setAttachmentIdAndFileName([file.id, file.fileName]);
                        }
                      }}
                    >
                      <b>{file.fileName}</b>
                      {/* {(file.size * 0.001).toFixed(2)} kB */}
                    </div>
                    <div
                      onClick={() => {
                        if (!disabled) {
                          let indexFileToDelete = oldFilesLocal.findIndex(
                            (cancelFile) =>
                              cancelFile.fileName === file.fileName
                          );
                          let currentFiles = [...oldFilesLocal];
                          let deletedFiles = [...oldFilesRemoved];

                          let deleteFile = currentFiles.splice(
                            indexFileToDelete,
                            1
                          );
                          deletedFiles.push(deleteFile[0].id);

                          setOldFilesLocal(currentFiles);
                          setOldFilesRemoved(deletedFiles);
                        }
                      }}
                    >
                      <Button type="button" disabled={disabled}>
                        {t("delete")}
                      </Button>
                    </div>
                  </div>
                );
              })}
              {oldFiles.length === 0 && t("none")}
            </div>
          </div>
        )}
      </div>

      <div className={styles.errorText}>
        {isValidate === false && <div>{t("field_required")}</div>}
      </div>
    </>
  );
}
