import HomePageEventsPreview from "../../eventsPreview/HomePage";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";
import { faHome, faInfo } from "@fortawesome/free-solid-svg-icons";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const navLinksArrayEventsPreview: navLinksArrayTimeWorkerInterface = {
    profileId: 15,

    routesArrayWithComponents: [
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/events-preview/profile",
        component: () => <Profile />,
      },
      {
        icon: faHome,
        name: t("events"),
        ifHidden: false,
        singleLink: true,
        path: "/events-preview/home",
        component: (props) => <HomePageEventsPreview {...props} />,
      },
    ],
  };

  return navLinksArrayEventsPreview;
};
export default useNavLinksArray;
