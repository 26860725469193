import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControlLabel, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { DaysOfWeek } from "../../../../../../../Constants/daysOfWeek";
import useFetchAndSetGET from "../../../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import {
  AutocompleteFormik,
  DatePickerFormik,
  TextFieldFormik,
} from "../../../../../../helpersComponents/FormikInputs";
import CheckboxWithLabelFormik from "../../../../../../helpersComponents/FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import LoadingWrapper from "../../../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { CheckboxStyled } from "../../../../../../helpersComponents/MaterialUi";
import StyledDataGrid from "../../../../../../helpersComponents/MaterialUi/DataGrid/StyledDataGrid";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../../../../../helpersComponents/PopupHelpers";
import {
  SpecialPermissionsValue,
  SpecialPermissionsValues,
} from "../SpecialPermissionNumber";
import { IAddSpecialPermission } from "./PopupSpecialPermissionItem";
import styles from "./PopupSpecialPermissionItem.module.scss";

interface IInitialStateFormValues {
  permission: null | SpecialPermissionsValue;
  startTime: string;
  endTime: string;
  onlyRangeFromCheck: boolean;
  rangeFrom: null | Date;
  rangeTo: null | Date;
  daysOfWeek: IDayOfWeek[];
  roomsIds: number[];
  isHoliday: boolean;
  added: boolean;
}

interface IProps {
  onSubmit: (values: IAddSpecialPermission, selectedRooms: Room[]) => void;
  isValid: boolean;
}

const PopupSpecialPermissionItemContent: React.FC<IProps> = ({
  onSubmit,
  isValid,
}) => {
  const { t } = useTranslation();

  const [roomsList, fetchingStateRoomsList, fetchAgainRoomsList] =
    useFetchAndSetGET<Room[]>({
      path: "superior-ac/rooms?isActive=true",
    });

  const roomsColumns: GridColDef[] = [
    {
      field: "name",
      headerName: t("name"),
      flex: 1,
      renderCell: (params) => <span>{params.row.name}</span>,
    },
    {
      field: "buildingName",
      headerName: t("building"),
      flex: 1,
      renderCell: (params) => <span>{params.row.buildingName}</span>,
    },
  ];

  const daysOfWeek = DaysOfWeek(t);
  const [selectedRooms, setSelectedRooms] = useState<Room[]>([]);

  const SPECIAL_PERMISSIONS = SpecialPermissionsValues(t);

  const initialFormValues: IInitialStateFormValues = {
    permission: null,
    startTime: "00:00",
    endTime: "23:59",
    onlyRangeFromCheck: false,
    rangeFrom: null,
    rangeTo: null,
    daysOfWeek: [],
    roomsIds: [],
    isHoliday: false,
    added: true,
  };

  const [selectAllDays, setSelectAllDays] = useState(false);
  const handleSelectAllDays = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const checked = event.target.checked;
    setSelectAllDays(checked);
    if (checked) {
      setFieldValue("daysOfWeek", daysOfWeek);
    } else {
      setFieldValue("daysOfWeek", []);
    }
  };

  return (
    <DialogContentStyled>
      <LoadingWrapper
        isLodadingProgress={fetchingStateRoomsList.isFetching}
        isError={fetchingStateRoomsList.isError}
        setIfFetchAgain={() => {
          if (fetchingStateRoomsList.isError) {
            fetchAgainRoomsList();
          }
        }}
      >
        {!isValid && (
          <div className={styles.errorContainer}>
            <div>
              <FontAwesomeIcon icon={faExclamationCircle} color="red" />
            </div>
            <div>{t("permissions_overlap")}</div>
          </div>
        )}
        <Formik
          initialValues={initialFormValues}
          onSubmit={(values: IInitialStateFormValues) => {
            const object = {
              specialPermissionNumber: values.permission!,
              startTime: values.startTime,
              endTime: values.endTime,
              onlyRangeFromCheck: values.onlyRangeFromCheck,
              rangeFrom: values.rangeFrom,
              rangeTo: values.rangeTo,
              daysOfWeek: values.daysOfWeek,
              isHoliday: values.isHoliday,
              roomsIds: values.roomsIds,
            };
            onSubmit(object, selectedRooms);
          }}
          validationSchema={Yup.object({
            permission: Yup.object().nullable().required(t("field_required")),
            startTime: Yup.string().nullable().required(t("field_required")),
            endTime: Yup.string()
              .nullable()
              .required(t("field_required"))
              .test(
                "isValidEndTime",
                t("time_to_must_be_greater_than_the_time_from"),
                function (value) {
                  const { startTime } = this.parent;
                  if (value && startTime) {
                    return startTime <= value;
                  }

                  return true;
                }
              ),
            daysOfWeek: Yup.array().min(1, t("field_required")),
            rangeFrom: Yup.date()
              .nullable()
              .when("onlyRangeFromCheck", (onlyRangeFromCheck: boolean) => {
                if (onlyRangeFromCheck) {
                  return Yup.date().nullable().required(t("field_required"));
                }
                return Yup.date().nullable();
              }),
            rangeTo: Yup.date()
              .nullable()
              .when("onlyRangeFromCheck", (onlyRangeFromCheck: boolean) => {
                if (onlyRangeFromCheck) {
                  return Yup.date().nullable().required(t("field_required"));
                }
                return Yup.date().nullable();
              }),
            roomsIds: Yup.array().min(1, t("field_required")),
          })}
        >
          {(formikProps) => (
            <Form noValidate id="specialPermissionForm">
              <div className={styles.rootContainer}>
                {/* Rooms */}
                <div className={styles.roomsContainer}>
                  <div className={styles.roomsGridTitleContainer}>
                    <p className={styles.roomsGridTitle}>{t("rooms")}*</p>
                    {formikProps.errors[`roomsIds`] && (
                      <Tooltip title={t("field_required")!}>
                        <div>
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            color="red"
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <StyledDataGrid
                    rows={roomsList}
                    columns={roomsColumns}
                    defaultPageSize={10}
                    rowsPerPageOptions={[10]}
                    density="compact"
                    checkboxSelection={true}
                    quickFilter={true}
                    onSelectionModelChange={(selected) => {
                      formikProps.setFieldValue(
                        "roomsIds",
                        selected as number[]
                      );

                      const rooms = roomsList.filter((room) =>
                        selected.includes(room.id)
                      );
                      setSelectedRooms(rooms);
                    }}
                  />
                </div>
                {/*  */}

                {/*  */}
                <div className={styles.formContainer}>
                  <Row>
                    <RowTitle width={220}>{t("permission")}:</RowTitle>
                    <RowInput>
                      <AutocompleteFormik
                        options={SPECIAL_PERMISSIONS}
                        name="permission"
                        getOptionLabel={(option: SpecialPermissionsValue) => {
                          return option.value;
                        }}
                        width="100%"
                        getOptionSelected={(
                          option: SpecialPermissionsValue,
                          value: SpecialPermissionsValue
                        ) => {
                          return option.id === value.id;
                        }}
                        label={t("select")}
                        required={true}
                      />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle width={220}>
                      {t("permission_start_time")}:
                    </RowTitle>
                    <RowInput>
                      <TextFieldFormik
                        name="startTime"
                        hiddenLabel
                        type="time"
                      />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle width={220}>{t("permission_end_time")}:</RowTitle>
                    <RowInput>
                      <TextFieldFormik name="endTime" hiddenLabel type="time" />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle width={220}>
                      <CheckboxWithLabelFormik
                        name="onlyRangeFromCheck"
                        ifCircle={true}
                        label={
                          <span style={{ fontSize: "0.9rem" }}>
                            {t("only_in_the_range_from")}
                          </span>
                        }
                      />
                    </RowTitle>
                    <RowInput>
                      {formikProps.values["onlyRangeFromCheck"] && (
                        <DatePickerFormik
                          name="rangeFrom"
                          view={["year", "month", "date"]}
                          format="dd-MM-yyyy"
                          required={true}
                        />
                      )}
                    </RowInput>
                  </Row>

                  {formikProps.values["onlyRangeFromCheck"] && (
                    <Row>
                      <RowTitle width={220}>{t("date_to")}</RowTitle>
                      <RowInput>
                        <DatePickerFormik
                          name="rangeTo"
                          view={["year", "month", "date"]}
                          format="dd-MM-yyyy"
                          required={true}
                        />
                      </RowInput>
                    </Row>
                  )}

                  <Row>
                    <RowTitle width={220}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {t("day_of_the_week")}:
                        <FormControlLabel
                          control={
                            <CheckboxStyled
                              ifCircle={true}
                              checked={selectAllDays}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                handleSelectAllDays(
                                  event,
                                  formikProps.setFieldValue
                                )
                              }
                            />
                          }
                          label={<span>{t("all_days")}</span>}
                        />
                      </div>
                    </RowTitle>
                    <RowInput>
                      <AutocompleteFormik
                        options={daysOfWeek}
                        name="daysOfWeek"
                        getOptionLabel={(option: {
                          value: number;
                          name: string;
                        }) => {
                          return option.name;
                        }}
                        width="100%"
                        getOptionSelected={(
                          option: IDayOfWeek,
                          value: IDayOfWeek
                        ) => {
                          return option.value === value.value;
                        }}
                        label={t("select")}
                        required={true}
                        multiple
                        disableCloseOnSelect
                        filterSelectedOptions
                        ListboxProps={{ style: { maxHeight: "25rem" } }}
                      />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle width={220}>
                      <CheckboxWithLabelFormik
                        name="isHoliday"
                        ifCircle={true}
                        label={
                          <span style={{ fontSize: "0.9rem" }}>
                            {t("or_on_holiday")}
                          </span>
                        }
                      />
                    </RowTitle>
                  </Row>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </LoadingWrapper>
    </DialogContentStyled>
  );
};

export default PopupSpecialPermissionItemContent;
