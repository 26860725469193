import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../helpersComponents/PopupHelpers";
import PopupContentServicesAndDeliveries from "./PopupContentServicesAndDeliveries";
import RejectPopup from "../../pfso/OperationButtons/RejectPopup";
import { StatusInPortsType } from "../../../Constants/statusInPortsType";
import { useHistory } from "react-router-dom";
import { ProfileType } from "../../../enums/profileType";

interface IProps {
  isOpen: boolean;
  rowId?: number;
  closePopup: () => void;
  successCallback: () => void;
  isOpenRepeatPopup?: boolean;
  closeRepeatPopup?: () => void;
  repeatDeliveryId?: number;
  isOpenEditPopup?: boolean;
  editRowId?: number;
  closeEditPopup?: () => void;
  pathApiServicesAndDeliveries: string;
  pathApiCompaniesInPorts: string;
  pathApiArrivalsOfShips: string;
  pathApiTypesOfVisit: string;
  pathApiGuests: string;
  confirmationOnly: boolean;
  pathApiChangeConfirmationStatus: string;
  profileType: ProfileType;
}

const PopupAddServicesAndDeliveries: React.FC<IProps> = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
  isOpenRepeatPopup,
  closeRepeatPopup,
  repeatDeliveryId,
  isOpenEditPopup,
  editRowId,
  closeEditPopup,
  pathApiServicesAndDeliveries,
  pathApiCompaniesInPorts,
  pathApiArrivalsOfShips,
  pathApiTypesOfVisit,
  pathApiGuests,
  confirmationOnly,
  pathApiChangeConfirmationStatus,
  profileType,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpenRejectPopup, setIsOpenRejectPopup] = useState(false);
  const [
    changeConfirmationStatusBodyRequest,
    setChangeConfirmationStatusBodyRequest,
  ] = useState<string | boolean>(false);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
    if (closeRepeatPopup) closeRepeatPopup();
    if (closeEditPopup) closeEditPopup();

    if (
      confirmationOnly &&
      (profileType === ProfileType.PFSO ||
        profileType === ProfileType.SHIFT_DIRECTOR)
    ) {
      let profileName = "";
      if (profileType === ProfileType.PFSO) {
        profileName = "pfso";
      } else if (profileType === ProfileType.SHIFT_DIRECTOR) {
        profileName = "shift-director";
      }

      history.replace(`/${profileName}/services-and-deliveries`);
    }
  }, [
    successCallback,
    closePopup,
    closeRepeatPopup,
    closeEditPopup,
    history,
    profileType,
    confirmationOnly,
  ]);

  const [, fetchAgainChangeNotificationStatus] = useFetchOtherThanGET({
    path: `${pathApiChangeConfirmationStatus}/${editRowId}`,
    method: "PATCH",
    body: changeConfirmationStatusBodyRequest,
    setBody: setChangeConfirmationStatusBodyRequest,
    contentType: "application/json",
    successCallback: successCallbackAndClose,
  });

  useEffect(() => {
    if (changeConfirmationStatusBodyRequest) {
      fetchAgainChangeNotificationStatus();
    }
  }, [changeConfirmationStatusBodyRequest, fetchAgainChangeNotificationStatus]);

  const handleCloseRejectPopup = useCallback(() => {
    setIsOpenRejectPopup(false);
  }, []);

  const handleOpenRejectPopup = useCallback(() => {
    setIsOpenRejectPopup(true);
  }, []);

  const onSubmit = (values: IAddDeliveryService) => {
    if (confirmationOnly) {
      let tempBody = [
        {
          path: "/deliveryService/confirmationStatus",
          op: "replace",
          value: StatusInPortsType.APPROVED,
        },
      ];

      setChangeConfirmationStatusBodyRequest(JSON.stringify(tempBody));
    } else {
      setErrorMessage("");
      setBodyRequest(JSON.stringify(values));
    }
  };

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${pathApiServicesAndDeliveries}${
      editRowId !== undefined ? `/${editRowId}` : ""
    }`,
    method: editRowId !== undefined ? "PUT" : "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  useEffect(() => {
    if (isOpen === false) {
      setErrorMessage("");
    }
  }, [isOpen]);

  return (
    <DialogStyled
      open={isOpen || isOpenRepeatPopup || isOpenEditPopup ? true : false}
      onClose={() => {
        closePopup();
        if (closeRepeatPopup) closeRepeatPopup();
        if (closeEditPopup) closeEditPopup();
      }}
    >
      <DialogTitleStyled
        title={
          confirmationOnly
            ? t("confirm_service_delivery")
            : t("add_service_delivery")
        }
      />
      <PopupContentServicesAndDeliveries
        rowId={editRowId}
        onSubmit={onSubmit}
        pathApiServicesAndDeliveries={pathApiServicesAndDeliveries}
        pathApiCompaniesInPorts={pathApiCompaniesInPorts}
        pathApiArrivalsOfShips={pathApiArrivalsOfShips}
        pathApiTypesOfVisit={pathApiTypesOfVisit}
        pathApiGuests={pathApiGuests}
        repeatDeliveryId={repeatDeliveryId}
        confirmationOnly={confirmationOnly}
      />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        ifHideReject={confirmationOnly ? false : true}
        onRejectText={t("refuse")}
        onRejectColor="red"
        onConfirmColor={confirmationOnly ? "green" : ""}
        onCancel={() => {
          closePopup();
          if (closeRepeatPopup) closeRepeatPopup();
          if (closeEditPopup) closeEditPopup();
        }}
        onReject={() => {
          setIsOpenRejectPopup(true);
        }}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />

      <div>
        {isOpenRejectPopup && (
          <RejectPopup
            patchApi={`${pathApiChangeConfirmationStatus}/${editRowId}`}
            prefixPathName="/deliveryService"
            isOpen={isOpenRejectPopup}
            successCallback={successCallbackAndClose}
            closePopup={handleCloseRejectPopup}
            openPopup={handleOpenRejectPopup}
            isSecurity={false}
          />
        )}
      </div>
    </DialogStyled>
  );
};

export default PopupAddServicesAndDeliveries;
