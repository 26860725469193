import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../../../../helpersComponents/Button/button";
import CopyProjectPopup from "./copyProjectPopup";

interface Props {
  projectId: number;
}

export default function CopyProjectRoot({ projectId }: Props): ReactElement {
  const { t } = useTranslation();
  const [isOpenCopyProjectPopup, setIsOpenCopyProjectPopup] =
    useState<boolean>(false);

  return (
    <>
      <ButtonStyled
        onClick={() => {
          setIsOpenCopyProjectPopup(true);
        }}
      >
        {t("copy_project")}
      </ButtonStyled>
      <CopyProjectPopup
        isOpen={isOpenCopyProjectPopup}
        setIsOpen={setIsOpenCopyProjectPopup}
        projectId={projectId}
      />
    </>
  );
}
