import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileType } from "../../../../enums/profileType";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import Setting from "../Setting";

const FCMSetting = ({
  FCMSettingValue,
}: {
  FCMSettingValue: string | undefined;
}) => {
  const { t } = useTranslation();
  const [oldFCMSettingValue, setOldFCMSettingValue] = useState(
    FCMSettingValue === "1" ? true : false
  );
  const [FCMNotificationEnabled, setFCMNotificationEnabled] = useState(
    FCMSettingValue === "1" ? true : false
  );

  const authUser = useAppSelector(selectAuthUser)?.currentProfile?.type;
  const profileType = ProfileType[authUser].toLowerCase();

  const [errorMessage, setErrorMessage] = useState("");
  const [fetchingStateData, fetchAgainData] = useFetchOtherThanGET({
    path: `${profileType}/preferences`,
    method: "POST",
    body: JSON.stringify({
      name: "PowiadomieniaOAwizacjiNaTelefon",
      value: FCMNotificationEnabled ? "1" : "0",
    }),
    contentType: "application/json",
    getErrorMessage: setErrorMessage,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: false,
    forceSnackbar: true,
  });

  const submitChanges = () => {
    if (oldFCMSettingValue !== FCMNotificationEnabled) {
      fetchAgainData();
    }
  };

  useEffect(() => {
    if (
      fetchingStateData.response?.status === 200 ||
      fetchingStateData.response?.status === 201
    ) {
      setOldFCMSettingValue((prevValue) => !prevValue);
    }
  }, [fetchingStateData.response]);

  return (
    <Setting
      title={t("notifications_on_phone_about_notifications")}
      switchChecked={FCMNotificationEnabled ? FCMNotificationEnabled : false}
      switchOnChange={() =>
        setFCMNotificationEnabled((prevValue) => !prevValue)
      }
      submitChanges={submitChanges}
      errorMessage={errorMessage}
    />
  );
};

export default FCMSetting;
