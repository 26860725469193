import React from "react";
import MultipleLinksTemplate from "../../Navbar/multipleLinksTemplate";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectNumberUnreadMessages } from "../../../reducers/checkIfNewMessages";
import { selectSettings } from "../../../reducers/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { SingleLink } from "../../Navbar/singleLink";
import useNavLinksArray from "./navLinksArray";
import styles from "../../Navbar/navbar.module.scss";

const Navigation = () => {
  const { t } = useTranslation();
  const numberUnreadMessages = useAppSelector(selectNumberUnreadMessages);
  const settings = useAppSelector(selectSettings);
  const navLinksArray = useNavLinksArray();

  if (settings.ifSettingsFetched === false) return <div></div>;
  return (
    <>
      <div className={styles.navigation}>
        {navLinksArray.routesArrayWithComponents.map((linkInfo) => {
          if (linkInfo.ifHidden) return null;
          if (!linkInfo.singleLink)
            return (
              <MultipleLinksTemplate
                name={linkInfo.name}
                links={linkInfo.path}
                key={linkInfo.name}
              />
            );
          let numberUnreadMessagesLocal = null;
          if (linkInfo.path === "/worker-time/messages") {
            numberUnreadMessagesLocal = numberUnreadMessages;
          }
          return (
            <SingleLink
              key={linkInfo.path}
              path={linkInfo.path}
              name={linkInfo.name}
              numberUnreadMessages={numberUnreadMessagesLocal}
            />
          );
        })}

        {settings.WebUkryjPracownikKDPomoc?.value !== "1" && (
          <div>
            <a
              className={styles.singleLinkNavigation}
              href={
                settings.WebPracownikKDLinkPomoc?.value
                  ? settings.WebPracownikKDLinkPomoc?.value
                  : "https://pomoc.skalmex.pl/#/instructions/employee_profile"
              }
            >
              <div className={styles.navbarSingleLink}>
                <div>
                  <FontAwesomeIcon className="fa-xs" icon={faCircle} />
                </div>
                <div>{t("help")}</div>
              </div>
            </a>
          </div>
        )}
      </div>
    </>
  );
};
export default Navigation;
