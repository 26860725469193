import React, { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { SingleLink } from "./singleLink";
import Collapse from "@material-ui/core/Collapse";
import { colorClassNameDictionary } from "../ProfilesRouting/profilesDictionaries";
import styles from "./navbar.module.scss";

function MultipleLinksTemplate(props) {
  const location = useLocation();
  const currentPath = location.pathname;
  let initialIsOpen = false;
  props.links.forEach((link) => {
    if (link.path === currentPath) return (initialIsOpen = true);
  });

  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );
  const selectedRouteClassName = colorClassNameDictionary[
    authUser.currentProfile.type
  ]
    ? colorClassNameDictionary[authUser.currentProfile.type]
    : styles.selectedRouteSetGreen;

  const [iseOpen, setIsOpen] = useState<boolean>(initialIsOpen);
  let ifAllHidden = true;
  props.links.forEach((link) => {
    if (link.ifHidden === false) ifAllHidden = false;
  });
  if (ifAllHidden) return null;

  return (
    <>
      <div
        onClick={() => {
          setIsOpen(!iseOpen);
        }}
      >
        <div
          className={
            styles.navbarSingleLink +
            " " +
            (initialIsOpen && styles.navbarSelectedGroup)
          }
        >
          <div>
            <FontAwesomeIcon
              className={"fa-xs " + (initialIsOpen && selectedRouteClassName)}
              icon={faCircle}
            />
          </div>
          <div>
            {props.name} <ArrowDropDownIcon />
          </div>
        </div>
      </div>
      <Collapse in={iseOpen}>
        <div className={styles.navigationLinks}>
          {props.links.map((link) => {
            if (link.ifHidden) return null;
            return (
              <SingleLink key={link.path} path={link.path} name={link.name} />
            );
          })}
        </div>
      </Collapse>
    </>
  );
}

export default MultipleLinksTemplate;
