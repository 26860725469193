import { FormControlLabel } from "@material-ui/core";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AccordionStyled from "../../../helpersComponents/MaterialUi/Accordion/AccordionStyled";
import ButtonStyled from "../../../helpersComponents/Button/button";
import { CheckboxStyled } from "../../../helpersComponents/MaterialUi";
import styles from "./setting.module.scss";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";

const HolidaysLimitsSettings: React.FC<any> = () => {
  const { t } = useTranslation();

  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );

  let isAdminProfile = false;

  let foundProfile = authUser.tokenDecoded.profiles.find(
    (el) => el.permissionId && (el.permissionId === 1 || el.permissionId === 2)
  );

  if (foundProfile) {
    isAdminProfile = true;
  }

  const [
    holidayLimitsSettingsAccordionExpanded,
    setHolidayLimitsSettingsAccordionExpanded,
  ] = useState<boolean>(false);

  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const [
    holidayLimitsSettings,
    holidayLimitsSettingsFetchingState,
    holidayLimitsSettingsFetchAgain,
  ] = useFetchAndSetGET<IHolidayLimitsSettings>({
    path: `superior-time/holiday-limits-settings`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (holidayLimitsSettingsAccordionExpanded) {
      holidayLimitsSettingsFetchAgain();
    }
  }, [holidayLimitsSettingsAccordionExpanded, holidayLimitsSettingsFetchAgain]);

  useEffect(() => {
    if (
      !holidayLimitsSettingsFetchingState.isFetching &&
      !holidayLimitsSettingsFetchingState.isError &&
      holidayLimitsSettingsFetchingState.response?.status === 200 &&
      !dataLoaded
    ) {
      setDataLoaded(true);
    }
  }, [holidayLimitsSettingsFetchingState, dataLoaded]);

  const [checkbox, setCheckbox] = React.useState({
    addingHolidayLimits: false,
    editingHolidayLimits: false,
  });

  const handleChangeCheckbox = (event) => {
    setCheckbox((prev) => {
      return { ...prev, [event.target.name]: event.target.checked };
    });
  };

  const [saveSettingsHolidayLimitsBody, setSaveSettingsHolidayLimitsBody] =
    useState<false | any>(false);

  const [, saveSettingsPutDataFetchAgain] = useFetchOtherThanGET({
    path: "superior-time/holiday-limits-settings",
    method: "PUT",
    body: saveSettingsHolidayLimitsBody,
    contentType: "application/json",
    setBody: setSaveSettingsHolidayLimitsBody,
    forceSnackbar: true,
  });

  useEffect(() => {
    if (saveSettingsHolidayLimitsBody) {
      saveSettingsPutDataFetchAgain();
    }
  }, [saveSettingsHolidayLimitsBody, saveSettingsPutDataFetchAgain]);

  const saveSettings = useCallback(() => {
    let objToSend: IUpdateHolidayLimitsSettings = {
      canHREmployeeAdd: checkbox.addingHolidayLimits,
      canHREmployeeEdit: checkbox.editingHolidayLimits,
    };

    setSaveSettingsHolidayLimitsBody(JSON.stringify(objToSend));
  }, [checkbox]);

  useEffect(() => {
    if (dataLoaded === true) {
      setCheckbox({
        addingHolidayLimits: holidayLimitsSettings.canHREmployeeAdd,
        editingHolidayLimits: holidayLimitsSettings.canHREmployeeEdit,
      });
    }
  }, [dataLoaded, holidayLimitsSettings]);

  if (!isAdminProfile) {
    return <></>;
  }

  return (
    <AccordionStyled
      title={t("holidays_limit")}
      defaultExpanded={false}
      onChange={(event: ChangeEvent<{}>, expanded: boolean) => {
        setHolidayLimitsSettingsAccordionExpanded(expanded);
      }}
    >
      {dataLoaded && (
        <div>
          <div className={styles.settingsContainer}>
            <div className={styles.requestsTypesFilterContainer}>
              <div className={styles.hr_requests}>
                {t("holidays_limits_hr")}:
              </div>
              <div>
                <FormControlLabel
                  control={
                    <CheckboxStyled
                      name="addingHolidayLimits"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      checked={checkbox.addingHolidayLimits}
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label={
                    <span className="FormControlLabelStyle">{t("adding")}</span>
                  }
                />

                <FormControlLabel
                  control={
                    <CheckboxStyled
                      name="editingHolidayLimits"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      checked={checkbox.editingHolidayLimits}
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label={
                    <span className="FormControlLabelStyle">
                      {t("editing")}
                    </span>
                  }
                />
              </div>
            </div>
          </div>

          <div className={styles.saveChanges}>
            <ButtonStyled onClick={saveSettings} className={styles.saveButton}>
              {t("save")}
            </ButtonStyled>
          </div>
        </div>
      )}
    </AccordionStyled>
  );
};

export default HolidaysLimitsSettings;
