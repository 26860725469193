import React from "react";
import HomePageContent from "./homePageContent";

function HomePage() {
  return (
    <div>
      <HomePageContent />
    </div>
  );
}

export default HomePage;
