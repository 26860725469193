import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArrayRenderProps } from "formik";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { TextFieldFormik } from "../FormikInputs";
import GetCardTagPopupRow from "../GetCardTagPopupRow/getCardTagPopupRow";
import {
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../PopupHelpers";
import ReadIdCardNumberFormikPopupRow from "../ReadIdCardNumberFormikPopupRow/readIdCardNumberFormikPopupRow";
import styles from "./stylesInspectionServices.module.scss";

interface Props extends ReadIdCardNumberFormikPopupRowInterface {
  guestIndex: number;
  arrayHelpers: FieldArrayRenderProps;
  numberOfGuests: number;
  isPopupOpen: boolean;
  guestsArray: GuestInspectionServiceObject[];
  acWorkersPathApi: string;
}

export default function GuestForm({
  guestIndex,
  arrayHelpers,
  numberOfGuests,
  isPopupOpen,
  guestsArray,
  acWorkersPathApi,
  ...restProps
}: Props): ReactElement {
  const { t } = useTranslation();

  const { setFieldValue } = restProps;

  const successCallbackIdReader = useCallback(
    (readerResponse) => {
      setFieldValue(
        `guestsArray.${guestIndex}.firstName`,
        readerResponse.fields.firstName
      );
      setFieldValue(
        `guestsArray.${guestIndex}.lastName`,
        readerResponse.fields.lastName
      );
    },
    [setFieldValue, guestIndex]
  );

  const [scannedACWorkerCard, setScannedACWorkerCard] = useState("");
  const [acWorker, , fetchAgainACWorker] = useFetchAndSetGET<ACWorker>({
    path: `${acWorkersPathApi}?cardNumber=${scannedACWorkerCard}`,
    startFetchOnInitial: false,
  });

  const successCallbackCardNumberReader = useCallback(
    (readerResponse) => {
      setScannedACWorkerCard(readerResponse);
    },
    [setScannedACWorkerCard]
  );

  useEffect(() => {
    if (scannedACWorkerCard) {
      fetchAgainACWorker();
    }
  }, [scannedACWorkerCard, fetchAgainACWorker]);

  useEffect(() => {
    if (acWorker) {
      setFieldValue(`guestsArray.${guestIndex}.firstName`, acWorker.firstName);
      setFieldValue(`guestsArray.${guestIndex}.lastName`, acWorker.lastName);
    }
  }, [acWorker, setFieldValue, guestIndex]);

  return (
    <>
      <div className={styles.guestTitle}>
        <b>
          {t("person")} {guestIndex + 1}
        </b>
        {numberOfGuests > 1 ? (
          <FontAwesomeIcon
            className="faTrash"
            icon={faTimesCircle}
            onClick={() => {
              arrayHelpers.remove(guestIndex);
            }}
          />
        ) : null}
      </div>
      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle>{t("first_name")}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t("first_name")}
              name={`guestsArray.${guestIndex}.firstName`}
              ifToUpperCaseShipsAndPorts={true}
              type="text"
              required={true}
              multiline
            />
          </RowInput>
        </Row>
        <Row>
          <RowTitle>{t("last_name")}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t("last_name")}
              ifToUpperCaseShipsAndPorts={true}
              name={`guestsArray.${guestIndex}.lastName`}
              type="text"
              required={true}
              multiline
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>
      <TwoColumnsRowsContainer>
        <GetCardTagPopupRow
          setFieldValue={setFieldValue}
          name={`guestsArray.${guestIndex}.cardNumber`}
          componentId={`inspectionServices/guestsArray.${guestIndex}.cardNumber`}
          isPopupOpen={isPopupOpen}
          checkForTagsInOtherInputs={{
            thisInputIndex: guestIndex,
            allInputs: guestsArray,
          }}
          mask={"****************"}
          successCallback={successCallbackCardNumberReader}
        />
        <ReadIdCardNumberFormikPopupRow
          successCallback={successCallbackIdReader}
          setFieldValue={setFieldValue}
          setFieldError={restProps.setFieldError}
          setFieldTouched={restProps.setFieldTouched}
          name={`guestsArray.${guestIndex}.idNumber`}
        />
      </TwoColumnsRowsContainer>
    </>
  );
}
