import React from "react";
import HoursRequestContent from "./hoursRequestContent";

function HoursRequest() {
  return (
    <div>
      <HoursRequestContent />
    </div>
  );
}

export default HoursRequest;
