import React, { ReactElement, useEffect, useState } from "react";
import styles from "./singleTask.module.scss";
import { taskPriority } from "./taskPriority";
import { statusDictionaryTasks } from "../statusDictionary";
import { useTranslation } from "react-i18next";
import Button from "../../../helpersComponents/Button/button";
import CommentsRoot from "../CommentsComponents/commentsRoot";
import FilesListWithFetch from "../../../helpersComponents/Attachments/filesListWithFetch";
import TextEditor from "../../../helpersComponents/TextEditor/textEditor";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import CollaboratorsList from "../CollaboratorsComponents/collaboratorsList";
import {
  CircularProgress,
  ClickAwayListener,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import getDifferenceInTwoDates from "../../../../HelpersFunctions/dateAndTime/getDifferenceInTwoDates";
import convertSecondsToDaysHrsMinsSeconds from "../../../../HelpersFunctions/dateAndTime/convertSecondsToDaysHrsMinsSeconds";
import CopyMoveRoot from "./MoveCopyTask/copyMoveRoot";
import useFetch from "../../../../hooks/fetchHooks/useFetch/useFetch";

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "13px",
  },
})(Tooltip);

interface Props {
  task: any;
  projectId: number;
  photos: any;
  kanbanView?: null | string;
  setIfFetchAgain: React.Dispatch<boolean>;
}

export default function SingleTaskDetails({
  task,
  projectId,
  photos,
  kanbanView,
  setIfFetchAgain,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [confidentialData, setConfidentialData] = useState<string>();
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;

  let [
    setIsFetchingConfidential,
    fetchingStateConfidential,
    fetchAgainConfidential,
  ] = useFetch({
    method: "GET",
    path: `worker-time/${authUserId}/projects/${projectId}/task-confidential-data/${task.id}`,
    startFetchOnInitial: false,
  });

  const [ifCopyConfidential, setIfCopyConfidential] = useState<boolean>(false);

  useEffect(() => {
    if (fetchingStateConfidential.isError) {
      setIsFetchingConfidential(false);
      return;
    }
    if (fetchingStateConfidential.response === undefined) return;
    let data = fetchingStateConfidential.response.resJson;
    setConfidentialData(data);
    setIsFetchingConfidential(false);
  }, [
    fetchingStateConfidential.isError,
    fetchingStateConfidential.response,
    setIsFetchingConfidential,
  ]);

  useEffect(() => {
    if (confidentialData !== undefined && ifCopyConfidential) {
      navigator.clipboard.writeText(confidentialData);
      setConfidentialData(undefined);
      setIfCopyConfidential(false);
      setOpenTooltip(true);
    }
  }, [confidentialData, ifCopyConfidential]);

  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  return (
    <>
      <div
        className={
          kanbanView === "true"
            ? styles.kanbanViewTaskDetails
            : styles.collaboratorsTaskDetalis
        }
      >
        <CollaboratorsList photos={photos} collaborators={task.collaborators} />
      </div>

      <div className={styles.singleTaskRow}>
        <div className={styles.singleTaskRowtitle}>
          <b>{t("actions")}:</b>
        </div>
        <div className={styles.singleTaskRowValue}>
          <CopyMoveRoot
            setIfFetchAgain={setIfFetchAgain}
            currentProjectId={projectId}
            taskId={task.id}
          />
        </div>
      </div>
      <div className={styles.singleTaskRow}>
        <div className={styles.singleTaskRowtitle}>
          <b>{t("status")}:</b>
        </div>
        <div className={styles.singleTaskRowValue}>
          {task.status ? t(statusDictionaryTasks[task.status]) : "-"}
        </div>
      </div>
      <div className={styles.singleTaskRow}>
        <div className={styles.singleTaskRowtitle}>
          <b>{t("owner")}:</b>
        </div>
        <div className={styles.singleTaskRowValue}>
          {task.ownerFirstName
            ? `${task.ownerFirstName} ${task.ownerLastName}`
            : "-"}
        </div>
      </div>
      <div className={styles.singleTaskRow}>
        <div className={styles.singleTaskRowtitle}>
          <b>{t("description")}:</b>
        </div>
        <div className={styles.singleTaskRowValue}>
          {task.description !== null ? (
            <div className={styles.editor}>
              <TextEditor
                htmlContent={task.description}
                hideToolbar={true}
                height={"auto"}
              />
            </div>
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className={styles.singleTaskRow}>
        <div className={styles.singleTaskRowtitle}>
          <b>{t("data_created")}:</b>
        </div>
        <div className={styles.singleTaskRowValue}>
          {task.createdAt
            ? new Date(task.createdAt).toLocaleString(t("scheduler_language"), {
                month: "short",
                year: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
            : "-"}
        </div>
      </div>
      <div className={styles.singleTaskRow}>
        <div className={styles.singleTaskRowtitle}>
          <b>{t("date_from")}:</b>
        </div>
        <div className={styles.singleTaskRowValue}>
          {task.dateFrom
            ? new Date(task.dateFrom).toLocaleString(t("scheduler_language"), {
                month: "short",
                year: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
            : "-"}
        </div>
      </div>
      <div className={styles.singleTaskRow}>
        <div className={styles.singleTaskRowtitle}>
          <b>{t("date_to2")}:</b>
        </div>
        <div className={styles.singleTaskRowValue}>
          {task.dateTo
            ? new Date(task.dateTo).toLocaleString(t("scheduler_language"), {
                month: "short",
                year: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
            : "-"}
        </div>
      </div>
      {task.dateTo && task.dateFrom && (
        <div className={styles.singleTaskRow}>
          <div className={styles.singleTaskRowtitle}>
            <b>{t("duration_time")}:</b>
          </div>
          <div className={styles.singleTaskRowValue}>
            {convertSecondsToDaysHrsMinsSeconds(
              getDifferenceInTwoDates(
                new Date(task.dateTo),
                new Date(task.dateFrom)
              ) / 1000,
              t,
              { ifHideSeconds: true }
            )}
          </div>
        </div>
      )}
      <div className={styles.singleTaskRow}>
        <div className={styles.singleTaskRowtitle}>
          <b>{t("approval_rejection_time")}:</b>
        </div>
        <div className={styles.singleTaskRowValue}>
          {task.approvalRejectionTime
            ? new Date(task.approvalRejectionTime).toLocaleString(
                t("scheduler_language"),
                {
                  month: "short",
                  year: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )
            : "-"}
        </div>
      </div>
      <div className={styles.singleTaskRow}>
        <div className={styles.singleTaskRowtitle}>
          <b>{t("priority")}:</b>
        </div>
        <div className={styles.singleTaskRowValue}>
          {t(taskPriority[task.priority].name)}
        </div>
      </div>
      <div className={styles.singleTaskRow}>
        <div className={styles.singleTaskRowtitle}>
          <b>{t("confidential_data")}:</b>
        </div>
        <div className={styles.singleTaskRowValue}>
          {confidentialData !== undefined && ifCopyConfidential !== true
            ? confidentialData
              ? confidentialData
              : "-"
            : "******"}
          {!fetchingStateConfidential.isFetching ? (
            <div className={styles.showConfidentialDataContainer}>
              <div>
                <Button
                  className={styles.showConfidentialData}
                  onClick={() => {
                    if (confidentialData === undefined) {
                      fetchAgainConfidential();
                    } else {
                      setConfidentialData(undefined);
                    }
                  }}
                >
                  {confidentialData !== undefined ? t("hide") : t("show")}
                </Button>
              </div>
              <div className={styles.copyRoot}>
                <ClickAwayListener
                  onClickAway={() => {
                    setOpenTooltip(false);
                  }}
                >
                  <CustomTooltip
                    title={<span>{t("copied")}</span>}
                    onClose={() => {
                      setOpenTooltip(false);
                    }}
                    open={openTooltip}
                    placement={"top"}
                  >
                    <div>
                      <Button
                        onClick={() => {
                          if (confidentialData === undefined) {
                            setIfCopyConfidential(true);
                            fetchAgainConfidential();
                          } else {
                            navigator.clipboard.writeText(confidentialData);
                            setOpenTooltip(true);
                          }
                        }}
                      >
                        <span className={styles.copiedTooltip}>
                          {t("copy")}
                        </span>
                      </Button>
                    </div>
                  </CustomTooltip>
                </ClickAwayListener>
              </div>
            </div>
          ) : (
            <>
              &nbsp;
              <CircularProgress size={17} />
              &nbsp;
            </>
          )}
          &nbsp;
          {fetchingStateConfidential.isError ? t("something_went_wrong") : ""}
        </div>
      </div>
      <div>
        <div className={styles.singleTaskRow}>
          <div className={styles.singleTaskRowtitle}>
            <b>{t("attachments")}:</b>
          </div>
        </div>
        <div className={styles.files}>
          <FilesListWithFetch
            files={task.attachments}
            path={`worker-time/${authUserId}/projects/${projectId}/tasks/${task.id}/attachments`}
          />
        </div>
      </div>
      <div className={styles.commentsRootContainer}>
        <CommentsRoot
          url={`worker-time/${authUserId}/projects/${projectId}/tasks/${task.id}/comments`}
        />
      </div>
    </>
  );
}
