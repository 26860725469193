import React from "react";
import GuestsInPortsContent from "../../helpersComponents/GuestsInPorts/GuestsInPortsContent";

const GuestsInPortsAgent = () => {
  return (
    <GuestsInPortsContent
      pathApiGuests="agent/guests"
      layoutSettingName="layoutAgentGuestsRecords"
    />
  );
};

export default GuestsInPortsAgent;
