import React, { ButtonHTMLAttributes, CSSProperties } from "react";
import "./button.scss";
import { colorWithHoverClassNameDictionary } from "../../ProfilesRouting/profilesDictionaries";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  width?: number | string;
  height?: number | string;
  color?: string;
}

const ForwardInputRefFunction: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  Props
> = (props, ref) => {
  const authUser = useAppSelector(selectAuthUser);
  const myStyles: CSSProperties = {
    width: props.width,
    height: props.height,
    backgroundColor: props.color,
  };
  const { children, className } = props;
  return (
    <>
      <button
        style={myStyles}
        ref={ref}
        {...props}
        className={
          "styledButton " +
          (!(
            Object.keys(authUser).length === 0 &&
            authUser.constructor === Object
          )
            ? colorWithHoverClassNameDictionary[authUser.currentProfile?.type]
            : "acSuperiorWithHover") +
          " " +
          (className ? className : "")
        }
      >
        {children}
      </button>
    </>
  );
};

const ButtonStyled = React.forwardRef(ForwardInputRefFunction);

export default ButtonStyled;
