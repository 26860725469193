import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import Dashboard from "./Dashboard/Dashboard";
import LastEventsEmployees from "./LastEventsEmployees";
import LocationEmployees from "./LocationEmployees";
import styles from "./styles.module.scss";

const HomePageSuperiorAC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <TopBanner pathName={t("home_page")} />
      <div className="flexAndCenter">
        <div className={styles.contentContainer}>
          <div className={styles.contentContainerBlock}>
            <Dashboard />
            <LastEventsEmployees />
          </div>

          <div className={styles.contentContainerBlockPaper}>
            <LocationEmployees />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageSuperiorAC;
