import AnnualHolidaysPlanContent from "./annualHolidaysPlanContent";

function AnnualHolidaysPlan() {
  return (
    <div>
      <AnnualHolidaysPlanContent />
    </div>
  );
}

export default AnnualHolidaysPlan;
