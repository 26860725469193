import { CircularProgress } from "@material-ui/core";
import React, { ReactElement, useEffect } from "react";
import useParallelFetchOverlay from "../../../hooks/fetchHooks/parallelJSON/useParallelFetchOverlay";
import ButtonStyled from "../Button/button";

interface ButtonsData {
  name: string;
  setBody: any;
  width?: number;
}

interface Props {
  selectedRows: number[];
  path: string;
  method: "PUT" | "POST" | "PATCH" | `DELETE`;
  buttons: ButtonsData[];
  setParallelIsFetching?: React.Dispatch<boolean>;
  allGoodOrNotAllGoodCallback?: (result) => void;
  hideSnackbarMessage?: boolean;
}

export default function ParallelActions({
  selectedRows,
  path,
  method,
  buttons,
  setParallelIsFetching,
  allGoodOrNotAllGoodCallback,
  hideSnackbarMessage,
}: Props): ReactElement {
  const [isFetching, setBodyPatch] = useParallelFetchOverlay({
    path,
    method,
    selectedRows,
    allGoodCallback: allGoodOrNotAllGoodCallback,
    notAllGoodCallback: allGoodOrNotAllGoodCallback,
    hideSnackbarMessage: hideSnackbarMessage,
  });
  useEffect(() => {
    if (setParallelIsFetching) setParallelIsFetching(isFetching);
  }, [isFetching, setParallelIsFetching]);

  return (
    <div>
      {isFetching ? (
        <CircularProgress size={25} />
      ) : (
        <div>
          <div className="acionButtonsContainerGlobal">
            {buttons.map((button, index) => {
              return (
                <div key={index}>
                  <ButtonStyled
                    width={button.width ? button.width : 250}
                    onClick={() => {
                      setBodyPatch(button.setBody);
                    }}
                    disabled={selectedRows?.length === 0}
                    className="actionButtonGlobal"
                  >
                    {button.name}
                  </ButtonStyled>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
