import React, { useState } from "react";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";

export default function useSummaryRowProps(): [
  {
    totalItems: { columnName: string; type: string }[];
    customItem: any;
    calculator: any;
  },
  any
] {
  const [items, setItems] = useState([
    { columnName: "absencesString", type: "" },
    { columnName: "absencesWithWorkedNormString", type: "" },
    { columnName: "breaksString", type: "" },
    { columnName: "businessEntriesExitsString", type: "" },
    { columnName: "daysOffWithWorkedNormString", type: "" },
    { columnName: "homeOfficeString", type: "" },
    { columnName: "languageLearningHoursString", type: "" },
    { columnName: "lateString", type: "" },
    { columnName: "nightWorkString", type: "" },
    { columnName: "nightWorkOvertimeString", type: "" },
    { columnName: "nightWorkScheduleString", type: "" },
    { columnName: "normBalanceString", type: "" },
    { columnName: "normativeTimeString", type: "" },
    { columnName: "notWorkedString", type: "" },
    { columnName: "overtimeString", type: "" },
    { columnName: "overtime50String", type: "" },
    { columnName: "overtime100String", type: "" },
    { columnName: "overtime150String", type: "" },
    { columnName: "overtime200String", type: "" },
  ]);

  return [
    {
      totalItems: items,
      customItem: ({ ...props }) => {
        return <b>{props.value}</b>;
      },
      calculator: (type, rows, getValue) => {
        let rowMinutes = 0;
        rows.forEach((_, index) => {
          let rowValue = getValue(rows[index]);
          if (rowValue) {
            let a = rowValue.split(":");
            rowMinutes = rowMinutes + parseInt(a[0]) * 60 + parseInt(a[1]);
          }
        });
        return convertMinsToHrsMins(rowMinutes);
      },
    },
    setItems,
  ];
}
