import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../helpersComponents/PopupHelpers";
import PopupContentReceiveReplacementCard from "./PopupContentReceiveReplacementCard";

interface Props {
  isOpen: boolean;
  cardNumber: null | string;
  closePopup: () => void;
  successCallback: () => void;
  pathApi: string;
  setOpenReceiveCardPopup: Dispatch<
    SetStateAction<{
      open: boolean;
      cardNumber: null | string;
    }>
  >;
}

const PopupReceiveReplacementCard: React.FC<Props> = ({
  isOpen,
  cardNumber,
  closePopup,
  successCallback,
  pathApi,
  setOpenReceiveCardPopup,
}) => {
  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [requestBody, setRequestBody] = useState<false | any>(false);

  const [cardNumberToPath, setCardNumberToPath] = useState(cardNumber);

  useEffect(() => {
    if (isOpen) setErrorMessage("");
  }, [isOpen]);

  const onSubmit = (values: Partial<ReceiveReplacementCard>) => {
    setErrorMessage("");

    if (!cardNumber) {
      setCardNumberToPath(values.receiveCardUniversalNumber!);
    }

    const body = {
      returnedCardDescription: values.returnedCardDescription,
      isLost: values.isLost,
    };
    setRequestBody(JSON.stringify(body));
  };

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${pathApi}/${cardNumberToPath}`,
    method: "PUT",
    body: requestBody,
    contentType: "application/json",
    setBody: setRequestBody,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, fetchAgainPostData]);

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      disableBackdropClick={true}
    >
      <DialogTitleStyled title={t("receive_replacement_card")} />
      <DialogContentStyled>
        <PopupContentReceiveReplacementCard
          onSubmit={onSubmit}
          isOpen={isOpen}
          cardNumber={cardNumber}
        />
      </DialogContentStyled>
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupReceiveReplacementCard;
