import FormControlLabel from "@mui/material/FormControlLabel";
import CheckboxStyled from "../../../../MaterialUi/Checkbox/checkbox";
import styles from "./styles.module.scss";
import Typography from "@mui/material/Typography";
import { HTMLControlOrientation } from "../../../../../../enums/HTMLControlOrientation";
import { useEffect } from "react";

const ElasticCheckboxButtonsGroup: React.FC<CheckboxButtonsGroupHTML> = ({
  name,
  label,
  orientation,
  isRequired,
  buttons,
  readonly,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
}) => {
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setFormValues((prev) => {
      let objToRet = { ...prev };
      objToRet[name] = checked;
      return objToRet;
    });
  };

  useEffect(() => {
    let anyButtonSelected = false;

    if (isRequired) {
      for (let i = 0; i < buttons.length; i++) {
        if (formValues[buttons[i].name]) {
          anyButtonSelected = true;
          break;
        }
      }

      buttons.forEach((btn) => {
        setFormErrors((prev) => {
          let objToRet = { ...prev };
          objToRet[btn.name] = !anyButtonSelected;
          return objToRet;
        });
      });
    }
  }, [formValues, isRequired, buttons, setFormErrors]);

  useEffect(() => {
    buttons.forEach((btn) => {
      setFormValues((prev) => {
        let objToRet = { ...prev };
        objToRet[btn.name] = btn.isSelected;
        return objToRet;
      });
    });
  }, [buttons, setFormValues]);

  return (
    <>
      <div>
        <Typography variant="body1" gutterBottom>
          {label}
          {isRequired ? <span style={{ color: "red" }}>*</span> : ""}:
        </Typography>

        <div
          className={
            orientation === HTMLControlOrientation.VERTICAL
              ? styles.formVerticalOrientation
              : styles.formHorizontalOrientation
          }
        >
          {buttons &&
            buttons.map((btn) => (
              <div className={styles.checkboxRow}>
                <FormControlLabel
                  onChange={handleCheckboxChange}
                  control={
                    <div style={{ alignSelf: "start" }}>
                      <CheckboxStyled
                        name={btn.name}
                        checked={formValues[btn.name] === true}
                        isError={formErrors[btn.name] === true}
                        ifCircle={false}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        disabled={readonly}
                      />
                    </div>
                  }
                  label={btn.label}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ElasticCheckboxButtonsGroup;
