import { Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { TypeOfVisit } from "../../../Constants/typeOfVisitInPorts";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import { removeDiacritics } from "../../../HelpersFunctions/stringUtils";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  AutocompleteFormik,
  DatePickerFormik,
  TextFieldFormik,
} from "../../helpersComponents/FormikInputs";
import CheckboxWithLabelFormik from "../../helpersComponents/FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import GetCardTagPopupRow from "../../helpersComponents/GetCardTagPopupRow/getCardTagPopupRow";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TextFieldStyled } from "../../helpersComponents/MaterialUi";
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../helpersComponents/PopupHelpers";
import ReadIdCardNumberFormikPopupRow from "../../helpersComponents/ReadIdCardNumberFormikPopupRow/readIdCardNumberFormikPopupRow";

interface FormikValues {
  idNumber: string;
  cardTag: string;
  permissionZone: PermissionZone | null;
  evidenceNumber: string | null;
  trainingExpiryDate: Date | null;
  excludedFromTraining?: boolean;
  registrationNumber?: string;
  bh3?: boolean;
}

const PopupLetIn: any = ({
  onCancelChanges,
  open,
  successCallback,
  row,
  path = "",
  pathConditional,
  permissionZonesPathApi,
  selectedPermissionZoneId,
  hidePermissionZone,
  showTrainingExpiryInfo,
  fromWhiteList,
  excludedFromTraining,
  bh3,
  showRegistrationNumber,
  scannedRegistrationNumber,
}: any) => {
  const { t } = useTranslation();

  /////////////// check if first/last name data match with read id card
  const [errorMessageReadCardId, setErrorMessageReadCardId] = useState("");
  const [readFirstLastName, setReadFirstLastName] = useState<{
    firstName: string;
    lastName: string;
  } | null>(null);
  const getIdCardCallback = useCallback((readCardData) => {
    setReadFirstLastName({
      firstName: readCardData.fields.firstName,
      lastName: readCardData.fields.lastName,
    });
  }, []);

  useEffect(() => {
    if (!row || readFirstLastName === null) return;
    const readFirstName = removeDiacritics(
      readFirstLastName.firstName.toLocaleLowerCase()
    );
    const readLastName = removeDiacritics(
      readFirstLastName.lastName.toLocaleLowerCase()
    );
    const dbFirstName = removeDiacritics(
      row.guestFirstName.toLocaleLowerCase()
    );
    const dbLastName = removeDiacritics(row.guestLastName.toLocaleLowerCase());

    if (
      !readFirstName.includes(dbFirstName) ||
      !readLastName.includes(dbLastName)
    ) {
      setErrorMessageReadCardId(
        `${t("firstname_lastname_from_card_dont_agree")}, ${t(
          "first_name"
        )}: ${dbFirstName}, ${t("readed")}: ${readFirstName}; ${t(
          "last_name"
        )}: ${dbLastName}, ${t("readed")}: ${readLastName}`
      );
    } else setErrorMessageReadCardId("");
  }, [readFirstLastName, row, t]);
  /////////////////////////////////////////////////////////////////

  const [errorMessage, setErrorMessage] = useState("");
  const [bodyRequest, setBodyRequest] = useState<false | any>({
    rowId: null,
    body: false,
  });

  const [idNumberGuest, setIdNumberGuest] = useState<string>();

  const onSubmit = (values: FormikValues) => {
    setErrorMessage("");

    let body = {
      cardNumber: values?.cardTag,
      idNumber: values?.idNumber,
      permissionZoneId: values?.permissionZone?.id,
      evidenceNumber:
        row?.evidenceNumber === null ||
        row?.evidenceNumber === undefined ||
        row?.evidenceNumber === "-"
          ? null
          : row?.evidenceNumber,
      trainingExpiryDate: values?.trainingExpiryDate,
      registrationNumber: values?.registrationNumber
        ? values.registrationNumber
        : "",
      bh3: values?.bh3 ? values.bh3 : false,
    };

    setIdNumberGuest(values?.idNumber);

    setBodyRequest({
      body: JSON.stringify(body),
    });
  };
  const successCallbackPost = useCallback(() => {
    onCancelChanges();
    successCallback();
  }, [successCallback, onCancelChanges]);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${pathConditional ? pathConditional(row) : path}${
      row?.crewEntryDescentId ? `?IDNumber=${idNumberGuest}` : ""
    }`,
    method: row?.crewEntryDescentId ? "PATCH" : "POST",
    body: row?.crewEntryDescentId
      ? JSON.stringify([
          {
            path: "/isLetIn",
            op: "replace",
            value: true,
          },
        ])
      : bodyRequest.body,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackPost,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (open === false) {
      setReadFirstLastName(null);
      setErrorMessage("");
      setErrorMessageReadCardId("");
    }
  }, [open]);

  useEffect(() => {
    if (bodyRequest.body) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  const [
    permissionZones,
    fetchingStatePermissionZones,
    fetchAgainPermissionZones,
  ] = useFetchAndSetGET<PermissionZone[]>({
    path: permissionZonesPathApi,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (open && !hidePermissionZone) {
      fetchAgainPermissionZones();
    }
  }, [open, hidePermissionZone, fetchAgainPermissionZones]);

  const visitFromWhiteList =
    fromWhiteList && row?.evidenceNumber !== "-" ? true : false;

  return (
    <>
      <DialogStyled
        open={open}
        onClose={onCancelChanges}
        disableBackdropClick={true}
      >
        <DialogTitleStyled title={t("admitting_guest")} />
        <LoadingWrapper
          isLodadingProgress={fetchingStatePermissionZones.isFetching}
          isError={fetchingStatePermissionZones.isError}
          setIfFetchAgain={() => {
            if (fetchingStatePermissionZones.isError) {
              fetchAgainPermissionZones();
            }
          }}
        >
          <DialogContentStyled titleWidth={200}>
            <Formik
              initialValues={{
                idNumber:
                  row?.guestIdNumber && row?.guestIdNumber !== "-"
                    ? row.guestIdNumber
                    : "",
                cardTag: "",
                permissionZone: selectedPermissionZoneId
                  ? permissionZones?.find(
                      (permissionZone) =>
                        selectedPermissionZoneId === permissionZone.id
                    )!
                  : null,
                evidenceNumber: "",
                trainingExpiryDate: row?.guestTrainingExpiryDate
                  ? new Date(row.guestTrainingExpiryDate)
                  : null,
                registrationNumber:
                  showRegistrationNumber && scannedRegistrationNumber
                    ? scannedRegistrationNumber
                    : "",
                bh3: bh3 ? bh3 : false,
              }}
              onSubmit={(values) => {
                onSubmit(values);
              }}
              validationSchema={Yup.object({
                idNumber: Yup.string().required(t("field_required")),
                cardTag:
                  !row?.crewEntryDescentId && !visitFromWhiteList
                    ? Yup.string().when("bh3", {
                        is: (value: boolean) => value === true,
                        then: Yup.string().nullable(),
                        otherwise: Yup.string()
                          .required(t("field_required"))
                          .test(
                            "length",
                            t("card_number_have_to_16_chars"),
                            (val) => {
                              const val_length_without_dashes = val?.replace(
                                /-|_/g,
                                ""
                              ).length;
                              return val_length_without_dashes === 16;
                            }
                          ),
                      })
                    : Yup.string().nullable(),
                permissionZone: Yup.object()
                  .nullable()
                  .test("isRequired", t("field_required"), (value) => {
                    if (!hidePermissionZone) {
                      return value !== null;
                    }
                    return true;
                  }),
                trainingExpiryDate: Yup.date()
                  .nullable()
                  .test("isRequired", t("field_required"), (value) => {
                    //Jeżeli trening nie jest wymagany lub
                    //firma wykluczona ze szkolenia
                    if (
                      row?.isTrainingNotRequired ||
                      excludedFromTraining ||
                      row?.crewEntryDescentId ||
                      (row?.notificationId &&
                        row?.typeOfVisitId !==
                          TypeOfVisit.COMPLETION_OF_THE_WORK)
                    ) {
                      return true;
                    }
                    if (showTrainingExpiryInfo && !row?.isTrainingNotRequired) {
                      return value !== null;
                    }
                    return Yup.date()
                      .min(
                        getBeginningOfDate(new Date(), "Day"),
                        `${t("date")} ${`${t("must_be_later_than")} ${t(
                          "yesterday"
                        )}`.toLocaleLowerCase()}
                   `
                      )
                      .isValid(value);
                  }),
                registrationNumber: Yup.string().test(
                  "isRequired",
                  t("field_required"),
                  (value) => {
                    if (showRegistrationNumber && !value) {
                      return false;
                    }

                    return true;
                  }
                ),
              })}
            >
              {({ setFieldValue, setFieldError, setFieldTouched, values }) => {
                return (
                  <Form noValidate id="submitForm">
                    {!row?.crewEntryDescentId &&
                      !visitFromWhiteList &&
                      !values.bh3 && (
                        <GetCardTagPopupRow
                          setFieldValue={setFieldValue}
                          name={"cardTag"}
                          isPopupOpen={open}
                          mask="****************"
                          componentId="security/notifications"
                          required={true}
                        />
                      )}
                    <ReadIdCardNumberFormikPopupRow
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                      name={"idNumber"}
                      onChangeExtra={() => {
                        if (readFirstLastName !== null) {
                          setReadFirstLastName(null);
                          setErrorMessageReadCardId("");
                        }
                      }}
                      successCallback={getIdCardCallback}
                      startListeningInit={row?.guestIdNumber ? false : true}
                      required={true}
                    />

                    {!hidePermissionZone && (
                      <Row>
                        <RowTitle>{t("permission_zone")}:</RowTitle>
                        <RowInput>
                          <AutocompleteFormik
                            options={permissionZones}
                            getOptionLabel={(option: PermissionZone) => {
                              return option.name;
                            }}
                            width="100%"
                            getOptionSelected={(
                              option: PermissionZone,
                              value: PermissionZone
                            ) => {
                              return option.id === value.id;
                            }}
                            disabled={
                              selectedPermissionZoneId !== null ? true : false
                            }
                            label={t("select_permission_zone")}
                            name="permissionZone"
                            required={true}
                          />
                        </RowInput>
                      </Row>
                    )}

                    <Row>
                      <RowTitle>{t("first_name")}:</RowTitle>
                      <RowInput>
                        <TextFieldStyled
                          label={t("first_name")}
                          disabled={true}
                          value={row?.guestFirstName ?? ""}
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>{t("last_name")}:</RowTitle>
                      <RowInput>
                        <TextFieldStyled
                          label={t("last_name")}
                          disabled={true}
                          value={row?.guestLastName ?? ""}
                        />
                      </RowInput>
                    </Row>

                    {visitFromWhiteList && (
                      <Row>
                        <RowTitle>{t("evidence_number")}:</RowTitle>
                        <RowInput>
                          <TextFieldStyled
                            label={t("evidence_number")}
                            disabled={true}
                            value={row?.evidenceNumber ?? ""}
                          />
                        </RowInput>
                      </Row>
                    )}

                    {showTrainingExpiryInfo && (
                      <Row>
                        <RowTitle>
                          {t("set_guest_training_date_expiry")}:
                        </RowTitle>
                        <RowInput>
                          {!row?.isTrainingNotRequired ? (
                            <DatePickerFormik
                              label={t("training_expiry_date")}
                              name={`trainingExpiryDate`}
                              minDate={new Date()}
                              view={["year", "month", "date"]}
                              format="dd-MM-yyyy"
                              required={true}
                            />
                          ) : (
                            t("unnecessary_training")
                          )}
                        </RowInput>
                      </Row>
                    )}

                    {showRegistrationNumber && (
                      <Row>
                        <RowTitle>{t("plate_number")}:</RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            label={t("plate_number")}
                            name="registrationNumber"
                            type="text"
                            required={false}
                          />
                        </RowInput>
                      </Row>
                    )}

                    {bh3 !== null && (
                      <Row>
                        <RowTitle>
                          <CheckboxWithLabelFormik
                            name="bh3"
                            ifCircle={true}
                            label={
                              <span style={{ fontSize: "0.9rem" }}>
                                {t("BH3")}
                              </span>
                            }
                          />
                        </RowTitle>
                      </Row>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </DialogContentStyled>
        </LoadingWrapper>
        <DialogActionsStyled
          typConfirm="submit"
          formConfirm="submitForm"
          onCancel={onCancelChanges}
          isLoading={fetchingStatePostData.isFetching}
          errorString={`${errorMessage} ${errorMessageReadCardId}`}
        />
      </DialogStyled>
    </>
  );
};

export default PopupLetIn;
