import {
  faMinus,
  faPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArrayRenderProps } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormAddNewGuest from "../../../agent/FormAddNewGuest/FormAddNewGuest";
import { AutocompleteFormik } from "../../FormikInputs";
import LoadingWrapper from "../../LoadingWrapper/loadingWrapper";
import { Row, RowInput, RowTitle } from "../../PopupHelpers";
import styles from "./styles.module.scss";

interface IProps {
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  numberOfGuests: number;
  guestsArray: {
    guest: {} | null;
    idNumber?: string;
    isTrainingNotRequired?: boolean;
  }[];
  guests: IGuest[];
  fetchAgainGuests: () => void;
  fetchingStateGuests: {
    isFetching: boolean;
    isError: boolean;
    response: UseFetchResponse | undefined;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  isEditing: boolean;
}

const GuestForm: React.FC<IProps> = ({
  index,
  arrayHelpers,
  numberOfGuests,
  guestsArray,
  guests,
  fetchAgainGuests,
  fetchingStateGuests,
  setFieldValue,
  isEditing,
}) => {
  const { t } = useTranslation();

  const [showAddGuestForm, setShowAddGuestForm] = useState(false);
  const [addedGuestId, setAddedGuestId] = useState<false | number>(false);
  const [addedGuests, setAddedGuests] = useState<any[]>([]);

  useEffect(() => {
    if (addedGuestId !== false) {
      fetchAgainGuests();
    }
  }, [addedGuestId, fetchAgainGuests]);

  return (
    <div>
      <div className={styles.guestTitle}>
        <b>
          {t("person")} {index + 1}
        </b>
        {numberOfGuests > 1 ? (
          <FontAwesomeIcon
            className="faTrash"
            icon={faTimesCircle}
            onClick={() => {
              arrayHelpers.remove(index);
            }}
          />
        ) : null}
      </div>

      <LoadingWrapper
        isLodadingProgress={fetchingStateGuests.isFetching}
        isError={fetchingStateGuests.isError}
        setIfFetchAgain={() => {
          if (fetchingStateGuests.isError) {
            fetchAgainGuests();
          }
        }}
      >
        <Row>
          <RowTitle>{t("person")}:</RowTitle>
          <RowInput style={{ display: "flex" }}>
            <AutocompleteFormik
              options={guests}
              getOptionLabel={(option: IGuest) => {
                return `${option.firstName} ${option.lastName} ${
                  option.dateOfBirth ? `[${option.dateOfBirth}]` : ""
                } ${option.email ? `[${option.email}]` : ""}
                `;
              }}
              width="100%"
              style={{ width: "300px", marginRight: "10px" }}
              getOptionSelected={(option: IGuest, value: IGuest) => {
                return option.id === value.id;
              }}
              label={t("select_person")}
              name={`guestsArray.${index}.guest`}
              required={true}
              filterSelectedOptions
              disabled={isEditing}
            />
            {!isEditing && (
              <div
                className={styles.iconShowGuestForm}
                onClick={() => {
                  setShowAddGuestForm(!showAddGuestForm);
                }}
              >
                {showAddGuestForm ? (
                  <FontAwesomeIcon
                    className="faMinusNotifications fa-lg"
                    icon={faMinus}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="faPlusNotificationsAgent fa-lg"
                    icon={faPlus}
                  />
                )}
              </div>
            )}
          </RowInput>
        </Row>
      </LoadingWrapper>

      {showAddGuestForm && (
        <FormAddNewGuest
          addGuestPath={"pfso/guests"}
          setShowAddGuestFrom={setShowAddGuestForm}
          setAddedGuestId={setAddedGuestId}
          addedGuestId={addedGuestId}
          guests={guests}
          addedGuests={addedGuests}
          setAddedGuests={setAddedGuests}
          setFieldValue={setFieldValue}
          keySetFieldValue={`guestsArray.${index}.guest`}
          isSingleValue={true}
          isTrainingNotRequired={guestsArray[index].isTrainingNotRequired}
          selectedGuestsValue={[]}
        />
      )}
    </div>
  );
};

export default GuestForm;
