import { ProfileType } from "../../../enums/profileType";
import ContentWhiteListGuests from "../../helpersComponents/WhiteList/WhiteListGuests/ContentWhiteListGuests";

const WhiteListGuestsReception = () => {
  return (
    <ContentWhiteListGuests
      pathWhiteListGuests="reception/white-list-guests?current=true"
      pathPermissionZones="reception/permission-zones"
      layoutSettingName="layoutReceptionWhiteListGuestsRecords"
      pathLetInGuest="reception/guest-visit-from-white-list"
      profileType={ProfileType.RECEPTION}
    />
  );
};

export default WhiteListGuestsReception;
