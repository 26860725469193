import formFieldsModel from "./formFieldsModel";

const {
  formFields: {
    //user data
    login,
    permissions,
    leaveCurrentPassword,
    password,
    repeatPassword,
    fullName,
    phoneNumber,
    email,
    locked,
    isChangePasswordRequired,
    //permissions
    departments,
    groups,
    //password policies
    passwordPoliciesArray,
  },
} = formFieldsModel;

const formInitialValues = (acUser: ACUser | null = null) => {
  return {
    //user data
    [login.name]: acUser?.login ? acUser?.login : "",
    [permissions.name]: acUser?.permission ? acUser?.permission : null,
    [leaveCurrentPassword.name]: acUser ? true : false,
    [password.name]: "",
    [repeatPassword.name]: "",
    [fullName.name]: acUser?.fullName ? acUser?.fullName : "",
    [phoneNumber.name]: acUser?.phoneNumber ? acUser?.phoneNumber : "",
    [email.name]: acUser?.email ? acUser?.email : "",
    [locked.name]: acUser?.isLocked ? acUser?.isLocked : false,
    [isChangePasswordRequired.name]:
      acUser?.isChangePasswordRequired === false ||
      acUser?.isChangePasswordRequired === true
        ? acUser?.isChangePasswordRequired
        : true,
    //permissions
    [departments.name]:
      acUser?.departments && acUser?.departments.length > 0
        ? acUser?.departments
        : [],
    [groups.name]:
      acUser?.groups && acUser?.groups.length > 0 ? acUser?.groups : [],
    //password policies
    [passwordPoliciesArray.name]:
      acUser?.policies && acUser?.policies.length > 0
        ? acUser?.policies.map((pp: any) => ({
            policy: pp.policy,
            policyDateFrom: new Date(pp.policyDateFrom),
            policyDateTo: pp.policyDateTo ? new Date(pp.policyDateTo) : null,
          }))
        : [],
  };
};

export default formInitialValues;
