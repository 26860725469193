import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

const OnExportingExcelReport = (e, docHeader: string | undefined) => {
  const workbook = new Workbook();

  const worksheet = workbook.addWorksheet("Report", {
    pageSetup: { paperSize: 9, orientation: "landscape" },
  });

  exportDataGrid({
    component: e,
    worksheet: worksheet,
    topLeftCell: docHeader ? { row: 3, column: 1 } : { row: 1, column: 1 },
  })
    .then(() => {
      if (docHeader) {
        const headerRow = worksheet.getRow(2);
        headerRow.height = 20;
        worksheet.mergeCells(1, 1, 2, 8);

        headerRow.getCell(1).value = docHeader;
        headerRow.getCell(1).font = { size: 18 };
        headerRow.getCell(1).alignment = { horizontal: "center" };
      }
    })
    .then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "Report.xlsx"
        );
      });
    });
  e.cancel = true;
};

export default OnExportingExcelReport;
