import React from "react";
import TimesSpentInRoomsContent from "./timesSpentInRoomsContent";

function TimesSpentInRooms() {
  return (
    <div>
      <TimesSpentInRoomsContent />
    </div>
  );
}

export default TimesSpentInRooms;
