import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput as MuiOutlinedInput,
  Select,
  withStyles,
} from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxStyled } from "../MaterialUi";
import styles from "./styles.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.2 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 7,
    horizontal: 0,
  },
};
const OutlinedInput = withStyles((theme) => ({
  notchedOutline: {
    borderColor: "#676a6c !important",
    marginTop: 2,
  },
}))(MuiOutlinedInput);

const SelectFireZone = ({
  label,
  fireZones,
  selectedFireZone,
  setSelectedFireZone,
}: {
  label: string;
  fireZones: any;
  selectedFireZone: FireZone | null;
  setSelectedFireZone: Dispatch<SetStateAction<FireZone>>;
}) => {
  const { t } = useTranslation();

  const handleChangeFireZone = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const fireZone = JSON.parse(event.target.value as string) as FireZone;
    if (fireZone.id === selectedFireZone?.id)
      setSelectedFireZone({
        id: "",
        name: "",
      });
    else {
      setSelectedFireZone(fireZone);
    }
  };

  return (
    <div className={styles.selectZoneContainer}>
      <FormControl variant="outlined">
        <InputLabel id="select-zone" style={{ color: "#1ab394" }}>
          {label}
        </InputLabel>
        <Select
          labelId="select-zone"
          value={selectedFireZone}
          onChange={handleChangeFireZone}
          input={<OutlinedInput label={t("zone")} />}
          renderValue={(selected: any) => selected.name}
          MenuProps={MenuProps}
          style={{ minWidth: "220px" }}
        >
          {fireZones.map((fireZone: FireZone) => (
            <MenuItem
              key={fireZone.id}
              value={JSON.stringify(fireZone)}
              autoFocus={fireZone.id === selectedFireZone?.id ? true : false}
            >
              <CheckboxStyled
                ifCircle={true}
                checked={fireZone.id === selectedFireZone?.id}
              />
              <ListItemText primary={fireZone.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectFireZone;
