export const statusDictionaryTasks = {
  0: "0",
  1: "introduced",
  2: "finished",
  3: "approved",
  4: "rejected",
  5: "rejected_introduced",
  6: "canceled",
};

export const statusCheckboxColorsDictionary = {
  0: "0",
  1: "rgb(76, 201, 76)",
  2: "rgb(76, 201, 76)",
  3: "rgb(54, 148, 192)",
  4: "red",
  5: "red",
  6: "black",
};
