import { useParams } from "react-router-dom";
import { ProfileType } from "../../../enums/profileType";
import ServicesAndDeliveriesContent from "../../helpersComponents/ServicesAndDeliveries/ServicesAndDeliveriesContent";

const ServicesAndDeliverieShiftDirector = () => {
  const params: any = useParams();

  return (
    <div>
      <ServicesAndDeliveriesContent
        pathApiServicesAndDeliveriesCheckPermissions={`shift-director/deliveries-services/${
          params?.id ? params.id : ""
        }/check-permission-to-confirm-delivery-and-service`}
        pathApiCompaniesInPorts="shift-director/companies-in-ports"
        pathApiArrivalsOfShips="shift-director/arrivals-of-ships"
        pathApiTypesOfVisit="shift-director/delivery-service-types"
        pathApiGuests="shift-director/guests"
        pathApiChangeConfirmationStatus="shift-director/delivery-service-change-status"
        pathApiServicesAndDeliveries="shift-director/deliveries-services"
        layoutSettingName="layoutShiftDirectorServicesAndDeliverie"
        profileType={ProfileType.SHIFT_DIRECTOR}
        id={params?.id ? params.id : undefined}
        confirmationOnly={params?.id ? true : false}
      />
    </div>
  );
};

export default ServicesAndDeliverieShiftDirector;
