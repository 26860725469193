import { FireReportMode } from "../../../enums/fireReportMode";
import FireReportContent from "../../helpersComponents/FireReport/fireReport";

function EvacuationReportSuperiorAc() {
  return (
    <div>
      <FireReportContent
        fireReportPath="superior-ac/fire-report"
        layoutSettingName="layoutSettingNameSuperiorAcFireReport"
        fireZonesPath="superior-ac/fire-zones"
        pathPreferences="superior-ac/preferences"
        targetCompaniesPath="superior-ac/guests-visits-target-companies"
        fireReportMode={FireReportMode.EVACUATION_REPORT}
      />
    </div>
  );
}

export default EvacuationReportSuperiorAc;
