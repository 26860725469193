import { useTranslation } from "react-i18next";
import { CrewEntryDescentType } from "../../../Constants/crewEntryDescentTypes";
import { ProfileType } from "../../../enums/profileType";
import CrewEntriesDescentsContent from "../../helpersComponents/CrewEntriesDescents/CrewEntriesDescentsContent";
import { useParams } from "react-router-dom";

function CrewDescentsShiftDirector() {
  let { t } = useTranslation();
  const params: any = useParams();

  return (
    <div>
      <CrewEntriesDescentsContent
        eventType={CrewEntryDescentType.DESCENT}
        topBannerTitle={t("crew_descent")}
        pathApiCrewEntriesDescentsCheckPermissions={`shift-director/crew-entries-descents/${
          params?.id ? params.id : ""
        }/check-permission-to-confirm-crew-entry-descent`}
        pathChangeStatus={"shift-director/crew-entry-descent-change-status"}
        newStatusIdConfirm={4}
        profileType={ProfileType.SHIFT_DIRECTOR}
        layoutSettingName="layoutShiftDirectorCrewDescents"
        refreshingButton={true}
        id={params?.id ? params.id : undefined}
        confirmationOnly={params?.id ? true : false}
      />
    </div>
  );
}

export default CrewDescentsShiftDirector;
