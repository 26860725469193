import "./evacuation.scss";
// import React, { useState, useEffect } from 'react';
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import { useTranslation } from "react-i18next";
// import useFetch from '../../fetch/get/noParametrs'
// import computeDateRangeFetch from '../../HelpersFunctions/convertDateFetch'
import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import { useState } from "react";
// import LoadingWrapper from '../../helpersComponents/LoadingWrapper/loadingWrapper'

let Cell: any = TableEditColumn.Cell;

const ActionColumn: React.FC = ({
  children,
  row,
  classes,
  ...restProps
}: any) => {
  return (
    <Cell row={restProps.row} className="tableCellEditCancelStyleGlobal">
      {children}
    </Cell>
  );
};

function EventsContent() {
  const { t } = useTranslation();

  const columns = [
    { name: "time", title: t("date_and_time"), type: "date_hours" },
    { name: "roomName", title: t("room") },
    { name: "workerName", title: t("worker") },
    { name: "typeName", title: t("the_type_of_the_event") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "organizationalUnits", title: t("organizational_units") },
  ];

  // useEffect(() => {

  //     setFetchAgain(true)
  //     setIsFetching(true) // circ

  // }, [startDate, endDate])

  // useEffect(() => {
  //     if (error !== null) {
  //         setIsError(true)
  //         setFetchAgain(false)
  //         return
  //     }
  //     if (data === null) return

  //     data.forEach((element, index) => {
  //         data[index].workerName = element.firstName + " " + element.lastName
  //         data[index].timeString = new Date(element.time).toLocaleString(t("scheduler_language"), {
  //             year: 'numeric',
  //             month: '2-digit',
  //             day: '2-digit',
  //             hour: '2-digit',
  //             minute: '2-digit',
  //             second: '2-digit',
  //         })

  //     })

  //     setDataModified(data)
  //     setIsFetching(false)
  //     setFetchAgain(false)

  // }, [data, error, t])

  const [state, setstate] = useState(1);

  return (
    <>
      <div onClick={() => setstate(state + 1)} className="currentPath">
        <div>{t("Raport ewakuacyjny")}</div>
      </div>
      {state}
      <div className="flexAndCenter">
        <div>
          <TableComponent
            title={t("Osoby w strefie zagrożenia")}
            layoutSettingName="layoutSuperiorAcEvacuation"
            rows={[]}
            // rows={dataModified}
            columns={columns}
            actionColumn={ActionColumn}
          />
          {/* <LoadingWrapper
                    // isLodadingProgress={isFetching}
                    // isError={isError}
                    // setIfFetchAgain={setFetchAgain}
                    // setIsError={setIsError}
                    >
                        <TableComponent
                            title={t("Osoby w strefie zagrożenia")}
                            layoutSettingName="layoutSuperiorAcEvacuation"
                            rows={[]}
                            // rows={dataModified}
                            columns={columns}
                            actionColumn={ActionColumn}
                        />
                    </LoadingWrapper> */}
        </div>
      </div>
    </>
  );
}

export default EventsContent;
