export const statusDictionary = {
  0: "0",
  1: "introduced",
  2: "approved",
  3: "finally_approved",
  4: "to_change",
  5: "rejected",
  6: "finally_rejected",
  7: "canceled",
  101: "request_status_approved_1",
  102: "request_status_approved_2",
  103: "request_status_approved_3",
  104: "request_status_approved_4",
  105: "request_status_approved_5",
  106: "request_status_approved_6",
  107: "request_status_approved_7",
  108: "request_status_approved_8",
  109: "request_status_approved_9",
  110: "request_status_approved_10",
};
