import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import { faDoorOpen, faHome, faInfo } from "@fortawesome/free-solid-svg-icons";
import HomePage from "../../workerAc/HomePage/index";
import PermittedRooms from "../../workerAc/PermittedRooms";
import Profile from "../../UserPanel/profile";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const navLinksArrayTimeWorker: navLinksArrayTimeWorkerInterface = {
    profileId: 2,
    hidenInNavBar: [],
    routesArrayWithComponents: [
      {
        icon: faHome,
        name: t("home_page"),
        ifHidden: settings.WebUkryjPracownikKDStronaGlowna?.value === "1",
        singleLink: true,
        path: "/worker-ac/home",
        component: () => <HomePage />,
      },
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjPracownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/worker-ac/profile",
        component: () => <Profile />,
      },
      {
        icon: faDoorOpen,
        name: t("permitted_rooms"),
        ifHidden:
          settings.WebUkryjPracownikKDUprawnieniaDoPomieszczen?.value === "1",
        singleLink: true,
        path: "/worker-ac/permitted-rooms",
        component: () => <PermittedRooms />,
      },
    ],
  };

  return navLinksArrayTimeWorker;
};
export default useNavLinksArray;
