import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import Map from "../../helpersComponents/Map/map";

const RowDetail: React.FC = ({ row }: any) => {
  const { t } = useTranslation();

  const [fetchedEvent, fetchingStateFetchedEvent, fetchAgainEvent] =
    useFetchAndSetGET({
      path: `superior-time/events/${row.id}`,
    });

  return (
    <div className="eventsSuperriorContainer">
      <LoadingWrapper
        isLodadingProgress={fetchingStateFetchedEvent.isFetching}
        isError={fetchingStateFetchedEvent.isError}
        setIfFetchAgain={() => {
          if (fetchingStateFetchedEvent.isError) {
            fetchAgainEvent();
          }
        }}
      >
        {fetchedEvent && (
          <>
            {fetchedEvent.latitude && fetchedEvent.longitude ? (
              <div className="mapEventsSuperrior">
                <Map
                  position={[
                    fetchedEvent.latitude ? fetchedEvent.latitude : "",
                    fetchedEvent.longitude ? fetchedEvent.longitude : "",
                  ]}
                />
              </div>
            ) : (
              <div className="emptyLocationContainer">
                <p>{t("no_location_to_display_map")}</p>
              </div>
            )}
            <div className="superiorEventsImage">
              {fetchedEvent?.photos.map((data, index) => {
                return (
                  <img
                    key={index}
                    alt={`${fetchedEvent.firstName} ${fetchedEvent.lastName}`}
                    src={`data:image/jpeg;base64,${data}`}
                  />
                );
              })}
            </div>
          </>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default RowDetail;
