import React from "react";
import SummaryContent from "./summaryContent";

function Summary() {
  return (
    <div>
      <SummaryContent />
    </div>
  );
}

export default Summary;
