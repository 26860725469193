export const hoursRequestsTypes = {
  1: "overtime",
  2: "hours_reception",
  3: "days_reception",
  4: "private_pass",
  5: "business_pass",
  6: "working_off",
  7: "unpaid_pass",
  8: "overtime_for_hours_reception",
  9: "over_time_for_day_reception",
  10: "employee_hours_reception",
  11: "overtime_150",
  12: "overtime_200",
  13: "language_hours_reception",
  14: "language_privatePass",
  15: "language_working_off",
  16: "overnormative",
  17: "working_hours",
  18: "quitting_work",
  19: "birthday_exit",
  20: "care_art_188",
  21: "overwork_private_pass",
  22: "private_pass_workoff",
  23: "consent_to_stay_on_the_premises_of_the_work_place_outside_working_hours",
  24: "workoff_late",
  25: "private_pass_with_workoff",
  26: "business_pass_with_private_pass",
  27: "care_188_with_private_pass",
  28: "care_188_With_business_pass",
};
