import FireReportContent from "../../helpersComponents/FireReport/fireReport";

function FireReportOhs() {
  return (
    <div>
      <FireReportContent
        fireReportPath="ohs/fire-report"
        layoutSettingName="layoutSettingNameOhsFireReport"
        fireZonesPath="ohs/fire-zones"
        pathPreferences="ohs/preferences"
        targetCompaniesPath="ohs/guests-visits-target-companies"
      />
    </div>
  );
}

export default FireReportOhs;
