import React from "react";
import ProjectContent from "./projectContent";

function Project({ match }: any) {
  return (
    <div>
      <ProjectContent projectId={match.params.projectId} />
    </div>
  );
}

export default Project;
