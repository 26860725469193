import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import erro400getTranslatedErrorString from "../../../HelpersFunctions/erro400getTranslatedErrorString";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import { useAppSelector } from "../../../store/hooks";
import Button from "../../helpersComponents/Button/button";
import CheckboxStyled from "../../helpersComponents/MaterialUi/Checkbox/checkbox";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import styles from "./styles.module.scss";
import { selectAuthUser } from "../../../reducers/session";

interface TrainingsToReceiveProps {
  trainingsToReceive: TrainingToReceive[];
  typesOfVisits: TypeOfVisit[];
  setTraining: (training: Training) => void;
}

export const TrainingsToReceive = ({
  trainingsToReceive,
  typesOfVisits,
  setTraining,
}: TrainingsToReceiveProps) => {
  const { t, i18n } = useTranslation();
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;

  const useStyles = makeStyles({
    accordionSummary: {
      "&": {
        borderBottom: "1px solid lightgrey",
      },
    },
  });

  const classes = useStyles();
  const { confirm } = useConfirm();
  const [open, setOpen] = useState<boolean>(false);
  const [getTrainingPath, setGetTrainingPath] = useState<string>("");
  const [startTrainingError, setStartTrainingError] = useState("");

  const columns = [
    { name: "options", title: " " },
    { name: "name", title: t("name") },
    { name: "kindOfVisit", title: t("type_of_visit") },
    { name: "validFrom", title: t("valid_from"), type: "date_hours" },
    { name: "validTo", title: t("valid_to"), type: "date_hours" },
    { name: "importance", title: t("validity_of__test") },
    {
      name: "backToPreviousQuestionPossible",
      title: t("possibility_to_return_to_previous_questions"),
    },
    { name: "numberOfQuestions", title: t("number_of_questions") },
    { name: "multiSelectionInQuestions", title: t("multiple_choice") },
    { name: "isRepeatPossible", title: t("possibility_to_repeat_test") },
    { name: "isRequired", title: t("is_training_required") },
    { name: "description", title: t("description") },
    { name: "order", title: t("order") },
  ];

  const handleErrors = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setGetTrainingPath("");
        setStartTrainingError(error);
      }
    },
    [t, setGetTrainingPath]
  );

  const [trainingData, , fetchAgainTrainingData] = useFetchAndSetGET({
    path: getTrainingPath,
    startFetchOnInitial: false,
    errorCallback: handleErrors,
  });

  useEffect(() => {
    if (trainingData) {
      setTraining(trainingData);
    }
  }, [trainingData, setTraining]);

  useEffect(() => {
    if (getTrainingPath !== "" && startTrainingError === "") {
      fetchAgainTrainingData();
    }
  }, [getTrainingPath, startTrainingError, fetchAgainTrainingData]);

  const [rows, setRows] = useState<any[]>([]);

  const getKindOfVisit = useCallback(
    (trainingToReceive: TrainingToReceive) => {
      if (typesOfVisits[trainingToReceive.kindOfVisit]) {
        switch (i18n.language) {
          case "en":
            return typesOfVisits[trainingToReceive.kindOfVisit].nameEN;

          case "de":
            return typesOfVisits[trainingToReceive.kindOfVisit].nameDE;

          case "ru":
            return typesOfVisits[trainingToReceive.kindOfVisit].nameRU;

          case "uk":
            return typesOfVisits[trainingToReceive.kindOfVisit].nameUK;

          default:
            return typesOfVisits[trainingToReceive.kindOfVisit].namePL;
        }
      }

      return "";
    },
    [i18n.language, typesOfVisits]
  );

  const getLocalizedName = useCallback(
    (trainingToReceive: TrainingToReceive) => {
      switch (i18n.language) {
        case "en":
          return trainingToReceive.nameEN;

        case "de":
          return trainingToReceive.nameDE;

        case "ru":
          return trainingToReceive.nameRU;

        case "uk":
          return trainingToReceive.nameUK;

        default:
          return trainingToReceive.namePL;
      }
    },
    [i18n.language]
  );

  const getLocalizedDescription = useCallback(
    (trainingToReceive: TrainingToReceive) => {
      switch (i18n.language) {
        case "en":
          return trainingToReceive.descriptionEN;

        case "de":
          return trainingToReceive.descriptionDE;

        case "ru":
          return trainingToReceive.descriptionRU;

        case "uk":
          return trainingToReceive.descriptionUK;

        default:
          return trainingToReceive.descriptionPL;
      }
    },
    [i18n.language]
  );

  const getCheckBoxControl = (state: boolean) => {
    return (
      <div>
        <FormControlLabel
          control={
            <CheckboxStyled
              checked={state}
              name="active"
              disabled
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          label={<span className="FormControlLabelStyle"></span>}
        />
      </div>
    );
  };

  useEffect(() => {
    if (trainingsToReceive) {
      setRows(
        trainingsToReceive.map((trainingToReceive, index) => {
          let backToPreviousQuestionPossibleComponent = getCheckBoxControl(
            trainingToReceive.backToPreviousQuestionPossible
          );

          let multiSelectionInQuestionsComponent = getCheckBoxControl(
            trainingToReceive.backToPreviousQuestionPossible
          );

          let isRequiredComponent = getCheckBoxControl(
            trainingToReceive.isRequired
          );

          let isRepeatPossibleComponent = getCheckBoxControl(
            trainingToReceive.isRepeatPossible
          );

          return {
            ...trainingToReceive,
            name: getLocalizedName(trainingToReceive),
            description: getLocalizedDescription(trainingToReceive),
            kindOfVisit: getKindOfVisit(trainingToReceive),
            backToPreviousQuestionPossible:
              backToPreviousQuestionPossibleComponent,
            multiSelectionInQuestions: multiSelectionInQuestionsComponent,
            isRequired: isRequiredComponent,
            isRepeatPossible: isRepeatPossibleComponent,
            options: (
              <div>
                <Button
                  onClick={async () => {
                    let selectedbuttonId = await confirm({
                      text:
                        t("are_you_sure_you_want_to_go_to_the_training") + "?",
                      buttons: [
                        { buttonName: t("yes"), buttonId: 0 },
                        { buttonName: t("no"), buttonId: 1 },
                      ],
                    });

                    if (selectedbuttonId === 0) {
                      setGetTrainingPath(
                        `worker-time/${authUserId}/trainings/training-exam?trainingId=${trainingToReceive.id}`
                      );

                      setStartTrainingError("");
                    }
                  }}
                  type="button"
                >
                  {t("go")}
                </Button>
              </div>
            ),
          };
        })
      );
    }
  }, [
    trainingsToReceive,
    i18n.language,
    getLocalizedName,
    getLocalizedDescription,
    confirm,
    getKindOfVisit,
    t,
    authUserId,
  ]);

  return (
    <Accordion expanded={open} onChange={() => setOpen(!open)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordionSummary}
      >
        <div className={styles.accordingTitle}>
          <div>{t("trainings_to_receive")}</div>
        </div>
      </AccordionSummary>

      <AccordionDetails style={{ overflow: "auto" }}>
        <TableComponentMemo
          layoutSettingName=""
          ifHideWholeToolbalr={true}
          rows={rows}
          columns={columns}
          filteringStateColumnExtensions={[
            { columnName: "options", filteringEnabled: false },
          ]}
        />
      </AccordionDetails>
    </Accordion>
  );
};
