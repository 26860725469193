import { faBan, faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Editing,
  FilterRow,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  OperationDescriptions,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/data-grid";
import { useCallback, useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { EventType } from "../../../../enums/eventType";
import convertDateFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import HeaderButtons from "../ComponentsReport/GridHeader/HeaderButtons";
import "../ComponentsReport/gridStyles.scss";
import GroupCellByLineName from "../ComponentsReport/GroupCell/GroupCellByLineName";
import styles from "./PeopleOnProductionLines.module.scss";

const lineNameColumnCustomize = (cellInfo, t: TFunction<"translation">) => {
  if (cellInfo.value === "end_work_on_line") {
    return (cellInfo.value = t("end_work_on_line"));
  } else if (cellInfo.value === null) {
    return (cellInfo.value = t("unassigned"));
  }
  return cellInfo.value;
};

const renderOperationButtons = (
  cellInfo: any,
  t: TFunction<"translation">,
  endWorkOnLineHanlder: (timeWorkerId: number, worker: string) => void,
  undoEndWorkOnLineHanlder: (timeWorkerId: number, worker: string) => void
) => {
  return cellInfo.data.lineName !== null &&
    cellInfo.data.lineName !== "end_work_on_line" ? (
    <Tooltip title={t("end_work_on_line")!}>
      <div className={styles.kickOutButtonContainer}>
        <FontAwesomeIcon
          icon={faBan}
          onClick={() => {
            const worker = `${cellInfo.data.workerFirstName} ${cellInfo.data.workerLastName}`;
            endWorkOnLineHanlder(cellInfo.data.timeWorkerId, worker);
          }}
          className={styles.kickOutButton}
        />
      </div>
    </Tooltip>
  ) : cellInfo.data.lineName === "end_work_on_line" ? (
    <Tooltip title={t("undo_end_work_on_line")!}>
      <div className={styles.undoKickOutButtonContainer}>
        <FontAwesomeIcon
          icon={faUndoAlt}
          onClick={() => {
            const worker = `${cellInfo.data.workerFirstName} ${cellInfo.data.workerLastName}`;
            undoEndWorkOnLineHanlder(cellInfo.data.id, worker);
          }}
          className={styles.undoKickOutButton}
        />
      </div>
    </Tooltip>
  ) : null;
};

const PeopleOnProductionLinesContent = () => {
  const { t } = useTranslation();

  const [
    peopleOnProductionLines,
    fetchingStatePeopleOnProductionLines,
    fetchAgainPeopleOnProductionLines,
  ] = useFetchAndSetGET<IPersonOnIndividualTask[]>({
    path: "superior-time/production-lines/people-on-production-lines",
  });

  const { confirm } = useConfirm();

  const [requestBody, setRequestBody] = useState<false | any>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: "superior-time/events",
    method: "POST",
    body: requestBody,
    setBody: setRequestBody,
    contentType: "application/json",
    successCallback: fetchAgainPeopleOnProductionLines,
  });
  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, fetchAgainPostData]);
  const endWorkOnLineHanlder = async (timeWorkerId: number, worker: string) => {
    let confirmResponse = await confirm({
      text: `${t("are_you_sure_end_work_on_line_for")} ${worker}?`,
      buttons: [
        { buttonName: t("yes"), buttonId: 0 },
        { buttonName: t("cancel"), buttonId: 1 },
      ],
    });

    if (confirmResponse === 0) {
      setRequestBody(
        JSON.stringify({
          timeWorkerId: timeWorkerId,
          time: convertDateFetch(new Date()),
          typeId: EventType.END_WORK_ON_LINE,
          addSector: false,
          readerId: 2,
        })
      );
    }
  };

  const [eventIdToRemove, setEventIdToRemove] = useState<false | number>(false);
  const [removeFetchingState, removeFetchAgain] = useFetchOtherThanGET({
    path: `superior-time/events/${eventIdToRemove}`,
    method: "DELETE",
    body: eventIdToRemove,
    setBody: setEventIdToRemove,
    successCallback: fetchAgainPeopleOnProductionLines,
  });
  const undoEndWorkOnLineHanlder = async (eventId: number, worker: string) => {
    let confirmResponse = await confirm({
      text: `${t("are_you_sure_undo_end_work_on_line_for")} ${worker}?`,
      buttons: [
        { buttonName: t("yes"), buttonId: 0 },
        { buttonName: t("cancel"), buttonId: 1 },
      ],
    });

    if (confirmResponse === 0) {
      setEventIdToRemove(eventId);
      removeFetchAgain();
    }
  };

  const [gridInstance, setGridInstance] = useState(null);
  const gridRef = useCallback((ref) => {
    if (ref?.instance) {
      setGridInstance(ref.instance);
    }
  }, []);

  const [isCollapsedGrid, setIsCollapsedGrid] = useState(true);
  // const [gridComponent, setGridComponent] = useState<dxDataGrid>();

  return (
    <div>
      <TopBanner pathName={t("people_on_production_lines")} />

      <LoadingWrapper
        isLodadingProgress={
          fetchingStatePeopleOnProductionLines.isFetching ||
          fetchingStatePostData.isFetching ||
          removeFetchingState.isFetching
        }
        isError={fetchingStatePeopleOnProductionLines.isError}
        setIfFetchAgain={() => {
          if (fetchingStatePeopleOnProductionLines.isError) {
            fetchAgainPeopleOnProductionLines();
          }
        }}
      >
        <>
          {gridInstance && (
            <HeaderButtons
              dataGridInstance={gridInstance}
              refreshHandler={fetchAgainPeopleOnProductionLines}
              isCollapsedGrid={isCollapsedGrid}
              setIsCollapsedGrid={setIsCollapsedGrid}
            />
          )}

          <div className={styles.reportContainer}>
            <DataGrid
              ref={gridRef}
              //onInitialized={(e) => setGridComponent(e.component)}
              dataSource={peopleOnProductionLines}
              keyExpr="timeWorkerId"
              twoWayBindingEnabled={false}
              className={styles.grid}
              noDataText={t("no_data")}
            >
              <GroupPanel visible={true} allowColumnDragging={false} />

              <SearchPanel visible={true} placeholder={t("search")} />
              <FilterRow
                visible={true}
                resetOperationText={t("reset")}
                showAllText={t("all")}
              >
                <OperationDescriptions
                  startsWith={t("startsWith")}
                  endsWith={t("endsWith")}
                  contains={t("contains")}
                  notContains={t("notContains")}
                  equal={t("equal")}
                  notEqual={t("notEqual")}
                />
              </FilterRow>
              <HeaderFilter visible={true} texts={{ cancel: t("cancel") }} />

              <Selection mode="single" />

              <Editing mode="row" useIcons={true} allowDeleting={true} />

              {/* <Column
                caption={`${t("Lp")}.`}
                cellTemplate={function (container, options) {
                  if (gridComponent) {
                    container.textContent =
                      gridComponent.pageIndex() * gridComponent.pageSize() +
                      options.rowIndex;
                  }
                  return container;
                }}
                allowExporting={false}
              /> */}

              <Column
                dataField="lineName"
                caption={t("line_name")}
                showWhenGrouped={true}
                groupIndex={0}
                autoExpandGroup={!isCollapsedGrid}
                groupCellRender={(options) =>
                  GroupCellByLineName(options, t, true)
                }
                customizeText={(options) => lineNameColumnCustomize(options, t)}
                allowSorting={true}
              />
              <Column
                dataField="workerFirstName"
                caption={t("first_name")}
                allowFiltering={true}
              />
              <Column
                dataField="workerLastName"
                caption={t("last_name")}
                allowFiltering={true}
              />
              <Column
                dataField="evidenceNumber"
                caption={t("evidence_number")}
                allowFiltering={true}
              />
              <Column
                dataField="company"
                caption={t("company")}
                allowFiltering={true}
              />
              <Column
                dataField="organizationalUnit"
                caption={t("organizational_unit")}
                allowFiltering={true}
              />

              <Column
                type="buttons"
                caption={t("operation")}
                cellRender={(cellInfo) => {
                  return renderOperationButtons(
                    cellInfo,
                    t,
                    endWorkOnLineHanlder,
                    undoEndWorkOnLineHanlder
                  );
                }}
              />

              <Summary>
                <GroupItem
                  column="lineName"
                  summaryType="count"
                  displayFormat={`${t("employees")} {0}`}
                />
              </Summary>
              <SortByGroupSummaryInfo summaryItem="Group" />

              <Paging defaultPageSize={100} />
              <Pager
                visible={true}
                allowedPageSizes={[20, 50, 100, 200]}
                displayMode={"full"}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
                infoText={`${t("page")} {0} ${t("of")} {1} ({2} ${t("items")})`}
              />
            </DataGrid>
          </div>
        </>
      </LoadingWrapper>
    </div>
  );
};

export default PeopleOnProductionLinesContent;
