import { TFunction } from "react-i18next";

type Props = { t: TFunction<"translation"> };

const getInitialState: ({
  t,
}: Props) => OperationalSupportNotificationInitState = ({ t }) => {
  const initState: OperationalSupportNotificationInitState = {
    company: null,
    entryDate: new Date(),
    timeFrom: "00:00",
    timeTo: "00:00",
    firstName: "",
    lastName: "",
    position: null,
    permissionZone: null,
    cardNumber: "",
    idNumber: "",
    comments: t("with_ship_director_permission"),
  };

  return initState;
};

export default getInitialState;
