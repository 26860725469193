import { useTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  CheckboxStyled,
  TextFieldStyled,
} from "../../../helpersComponents/MaterialUi/index";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import { useEffect } from "react";

const RejectionReasonItem: React.FC<any> = ({
  row,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
}) => {
  const { t } = useTranslation();

  const checkbox1Name = `${row.id}_option1`;
  const checkbox2Name = `${row.id}_option2`;
  const textBoxName = `${row.id}_customReason`;
  const errorName = `${row.id}`;

  const handleChangeCheckbox = (event) => {
    setFormValues((prev) => {
      return { ...prev, [event.target.name]: event.target.checked };
    });
  };

  useEffect(() => {
    if (
      !formValues[checkbox1Name] &&
      !formValues[checkbox2Name] &&
      !formValues[textBoxName]
    ) {
      setFormErrors((prev) => {
        return { ...prev, [errorName]: true };
      });
    } else {
      setFormErrors((prev) => {
        return { ...prev, [errorName]: false };
      });
    }
  }, [
    formValues,
    checkbox1Name,
    checkbox2Name,
    errorName,
    setFormErrors,
    textBoxName,
  ]);

  return (
    <div className="rejectionReasonItem">
      <p>
        {row.firstName} {row.lastName}[{row.evidenceNumber}],{" "}
        {toLocaleStringCustom({ date: row.createdAt, t })}
      </p>
      <FormControlLabel
        control={
          <CheckboxStyled
            checked={
              formValues[checkbox1Name] ? formValues[checkbox1Name] : false
            }
            name={checkbox1Name}
            onChange={handleChangeCheckbox}
            inputProps={{ "aria-label": "primary checkbox" }}
            style={{ color: formErrors[errorName] ? "red" : "" }}
          />
        }
        label={"1. organizacja pracy"}
      />

      <FormControlLabel
        control={
          <CheckboxStyled
            checked={
              formValues[checkbox2Name] ? formValues[checkbox2Name] : false
            }
            name={checkbox2Name}
            onChange={handleChangeCheckbox}
            inputProps={{ "aria-label": "primary checkbox" }}
            style={{ color: formErrors[errorName] ? "red" : "" }}
          />
        }
        label={"2. rodzaj pracy wykonywanej przez pracownika"}
      />

      <TextFieldStyled
        label={t("custom_rejection_reason")}
        name={textBoxName}
        required={false}
        isError={formErrors[errorName]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFormValues((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
          });
        }}
      />
    </div>
  );
};

export default RejectionReasonItem;
