import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store/hooks";
import Button from "../../../helpersComponents/Button/button";
import { AutocompleteStyled } from "../../../helpersComponents/MaterialUi/index";
import useCachedFetch from "../../../../hooks/useCachedFetch/cachedFetch";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { CircularProgress } from "@material-ui/core";
import { selectAuthUser } from "../../../../reducers/session";
import { ProfileType } from "../../../../enums/profileType";
import useFetchAndSetGET from "./../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import ElasticForm from "../../ElasticForm";
import { HTMLControlType } from "../../../../enums/HTMLControlType";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import "./styles.scss";
const AddEditUniversalRequestPopup: React.FC<any> = ({
  row,
  onApplyChanges,
  onCancelChanges,
  open,
  timeWorkerId,
  defaultTemplate,
  defaultDay,
  additionalParams,
}: any) => {
  const { t } = useTranslation();
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;
  const applyChanges = useRef(onApplyChanges);

  const [ifCheckForUpdateCache, setIfCheckForUpdateCache] =
    useState<boolean>(false);
  const [ifClearReturnedValue, setIfClearReturnedValue] =
    useState<boolean>(false);

  const [selectedEmployeeFormError, setSelectedEmployeeFormError] =
    useState<any>({
      isError: false,
      errorMessage: "",
    });

  const [selectedTemplateFormError, setSelectedTemplateFormError] =
    useState<any>({
      isError: false,
      errorMessage: "",
    });

  const [selectedEmployee, setSelectedEmployee] = useState<any>();

  const timeWorkers = useCachedFetch(
    "timeWorkersWorkerTime",
    ifCheckForUpdateCache,
    3600,
    false,
    ifClearReturnedValue
  );

  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const [fetchingStatePutPostData, fetchAgainPutPostData] =
    useFetchOtherThanGET({
      path:
        profileType === ProfileType.SUPERIOR_TIME
          ? `superior-time/universal-requests/${row?.id ? row.id : ""}`
          : `worker-time/${authUserId}/universal-requests/${
              row?.id ? row.id : ""
            }`,
      method: row?.id ? "PUT" : "POST",
      body: bodyRequest,
      contentType: "application/json",
      setBody: setBodyRequest,
      disableErrorSnackbar: true,
      disableSuccessSnackbar: true,
    });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPutPostData();
    }
  }, [bodyRequest, fetchAgainPutPostData]);

  useEffect(() => {
    if (
      !fetchingStatePutPostData.isError &&
      !fetchingStatePutPostData.isFetching &&
      (fetchingStatePutPostData.response?.status === 200 ||
        fetchingStatePutPostData.response?.status === 201)
    ) {
      applyChanges.current();
    }
  }, [
    fetchingStatePutPostData.isError,
    fetchingStatePutPostData.isFetching,
    fetchingStatePutPostData.response?.status,
  ]);

  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [
    universalRequestsTemplates,
    fetchingStateUniversalRequestsTemplates,
    fatchAgainUniversalRequestsTemplates,
  ] = useFetchAndSetGET({
    path:
      profileType === ProfileType.SUPERIOR_TIME
        ? `superior-time/universal-requests-templates`
        : `worker-time/${authUserId}/universal-requests-templates`,
    startFetchOnInitial: true,
  });

  const [elasticFormValues, setElasticFormValues] = useState<any>({});
  const [elasticFormErrors, setElasticFormErrors] = useState<any>({});
  const [elasticFormData, setElasticFormData] = useState<EditorHTML | null>(
    null
  );

  useEffect(() => {
    if (selectedTemplate != null) {
      setElasticFormData(
        selectedTemplate.data ? JSON.parse(selectedTemplate.data) : null
      );
    }
  }, [selectedTemplate]);

  const getOwnData = () => {
    let result: { controls: any[] } | undefined = undefined;

    if (elasticFormData) {
      result = { controls: [] };

      for (let i = 0; i < elasticFormData.controls.length; i++) {
        switch (elasticFormData.controls[i].type) {
          case HTMLControlType.PARAGRAPH:
            {
              let tempControl: ParagraphHTML = elasticFormData.controls[
                i
              ] as ParagraphHTML;

              result.controls.push(tempControl);
            }
            break;

          case HTMLControlType.RADIO_BUTTONS_GROUP:
            {
              let tempControl: RadioButtonsGroupHTML = elasticFormData.controls[
                i
              ] as RadioButtonsGroupHTML;

              let tempValue = elasticFormValues[tempControl.name];

              for (let j = 0; j < tempControl.buttons.length; j++) {
                tempControl.buttons[j].isSelected =
                  tempControl.buttons[j].value === tempValue;
              }

              result.controls.push(tempControl);
            }
            break;

          case HTMLControlType.CHECKBOX_BUTTONS_GROUP:
            {
              let tempControl: CheckboxButtonsGroupHTML = elasticFormData
                .controls[i] as CheckboxButtonsGroupHTML;

              for (let j = 0; j < tempControl.buttons.length; j++) {
                if (elasticFormValues[tempControl.buttons[j].name]) {
                  tempControl.buttons[j].isSelected =
                    !!elasticFormValues[tempControl.buttons[j].name];
                }
              }

              result.controls.push(tempControl);
            }
            break;

          case HTMLControlType.TEXTBOX:
            {
              let tempControl: TextBoxHTML = elasticFormData.controls[
                i
              ] as TextBoxHTML;
              tempControl.value = elasticFormValues[tempControl.name];
              result.controls.push(tempControl);
            }
            break;

          case HTMLControlType.SELECT:
            {
              let tempControl: SelectionListHTML = elasticFormData.controls[
                i
              ] as SelectionListHTML;
              tempControl.value = elasticFormValues[tempControl.name];
              result.controls.push(tempControl);
            }
            break;
        }
      }
    }

    return result;
  };

  const forceElasticFormErrors = () => {
    let anyErrors = false;

    if (elasticFormData?.controls) {
      for (let i = 0; i < elasticFormData?.controls.length; i++) {
        switch (elasticFormData?.controls[i].type) {
          case HTMLControlType.RADIO_BUTTONS_GROUP:
            {
              let tempControl: RadioButtonsGroupHTML = elasticFormData
                ?.controls[i] as RadioButtonsGroupHTML;
              if (tempControl.isRequired) {
                if (!elasticFormValues[tempControl.name]) {
                  setElasticFormErrors((prev) => {
                    let objToRet = { ...prev };
                    objToRet[tempControl.name] = true;
                    return objToRet;
                  });

                  anyErrors = true;
                }
              }
            }
            break;

          case HTMLControlType.CHECKBOX_BUTTONS_GROUP:
            {
              let tempControl: CheckboxButtonsGroupHTML = elasticFormData
                ?.controls[i] as CheckboxButtonsGroupHTML;

              if (tempControl.isRequired) {
                let anyButtonSelected = false;

                for (let j = 0; j < tempControl.buttons.length; j++) {
                  if (elasticFormValues[tempControl.buttons[j].name]) {
                    anyButtonSelected = true;
                    break;
                  }
                }

                if (!anyButtonSelected) {
                  for (let j = 0; j < tempControl.buttons.length; j++) {
                    setElasticFormErrors((prev) => {
                      let objToRet = { ...prev };
                      objToRet[tempControl.buttons[j].name] = true;
                      return objToRet;
                    });

                    anyErrors = true;
                  }
                }
              }
            }
            break;

          case HTMLControlType.TEXTBOX:
            {
              let tempControl: TextBoxHTML = elasticFormData?.controls[
                i
              ] as TextBoxHTML;
              if (tempControl.isRequired) {
                if (!elasticFormValues[tempControl.name]) {
                  setElasticFormErrors((prev) => {
                    let objToRet = { ...prev };
                    objToRet[tempControl.name] = t("field_required");
                    return objToRet;
                  });

                  anyErrors = true;
                }
              }
            }
            break;

          case HTMLControlType.SELECT:
            {
              let tempControl: SelectionListHTML = elasticFormData?.controls[
                i
              ] as SelectionListHTML;
              if (tempControl.isRequired) {
                if (!elasticFormValues[tempControl.name]) {
                  setElasticFormErrors((prev) => {
                    let objToRet = { ...prev };
                    objToRet[tempControl.name] = t("field_required");
                    return objToRet;
                  });

                  anyErrors = true;
                }
              }
            }
            break;
        }
      }
    }

    return anyErrors;
  };

  const [universalRequest, , fetchAgainUniversalRequest] = useFetchAndSetGET({
    path:
      profileType === ProfileType.SUPERIOR_TIME
        ? `superior-time/universal-requests/` + row?.id
        : `worker-time/${authUserId}/universal-requests/` + row?.id,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (row?.id) {
      fetchAgainUniversalRequest();
    }
  }, [row, fetchAgainUniversalRequest]);

  useEffect(() => {
    if (universalRequestsTemplates === null || timeWorkers === null) return;

    if (timeWorkerId) {
      setSelectedEmployee(timeWorkers.find((v) => v.id === timeWorkerId));
    }

    if (universalRequest !== null && row.hasOwnProperty("id")) {
      if (universalRequest.data) {
        let declarationControls = JSON.parse(universalRequest.data).controls;

        for (let i = 0; i < declarationControls.length; i++) {
          switch (declarationControls[i].type) {
            case HTMLControlType.RADIO_BUTTONS_GROUP:
              {
                let tempControl: RadioButtonsGroupHTML = declarationControls[
                  i
                ] as RadioButtonsGroupHTML;

                let selectedRadioButtonValue = "";

                for (let j = 0; j < tempControl.buttons.length; j++) {
                  if (tempControl.buttons[j].isSelected) {
                    selectedRadioButtonValue = tempControl.buttons[j].value;
                    break;
                  }
                }

                setElasticFormValues((prev) => {
                  let objToRet = { ...prev };
                  objToRet[tempControl.name] = selectedRadioButtonValue;
                  return objToRet;
                });
              }
              break;

            case HTMLControlType.CHECKBOX_BUTTONS_GROUP:
              {
                let tempControl: CheckboxButtonsGroupHTML = declarationControls[
                  i
                ] as CheckboxButtonsGroupHTML;

                for (let j = 0; j < tempControl.buttons.length; j++) {
                  if (tempControl.buttons[j].isSelected) {
                    setElasticFormValues((prev) => {
                      let objToRet = { ...prev };
                      objToRet[tempControl.buttons[j].name] = true;
                      return objToRet;
                    });
                  }
                }
              }
              break;

            case HTMLControlType.TEXTBOX:
              {
                let tempControl: TextBoxHTML = declarationControls[
                  i
                ] as TextBoxHTML;

                setElasticFormValues((prev) => {
                  let objToRet = { ...prev };
                  objToRet[tempControl.name] = tempControl.value;
                  return objToRet;
                });
              }
              break;

            case HTMLControlType.SELECT:
              {
                let tempControl: SelectionListHTML = declarationControls[
                  i
                ] as SelectionListHTML;

                setElasticFormValues((prev) => {
                  let objToRet = { ...prev };
                  objToRet[tempControl.name] = tempControl.value;
                  return objToRet;
                });
              }
              break;
          }
        }
      }

      setSelectedEmployee(
        timeWorkers.find((v) => v.id === universalRequest.timeWorkerId)
      );

      let selectedTemplate = universalRequestsTemplates.find(
        (v) => v.id === universalRequest.templateId
      );
      setSelectedTemplate(selectedTemplate);
    }
  }, [
    row,
    universalRequest,
    timeWorkers,
    timeWorkerId,
    universalRequestsTemplates,
  ]);

  /*useEffect(() => {
    if (defaultTemplate && universalRequestsTemplates) {
      let foundTemplate = universalRequestsTemplates.find(
        (el) => el.id === parseInt(defaultTemplate)
      );
      if (foundTemplate) {
        setSelectedTemplate(foundTemplate);
      }
    }
  }, [defaultTemplate, universalRequestsTemplates, open]);*/

  const confirm = async () => {
    let ifFetch = true;
    let declarationData = getOwnData();

    if (!selectedTemplate) {
      setSelectedTemplateFormError({
        isError: true,
        errorMessage: t("select_request_template"),
      });
      ifFetch = false;
    } else {
      setSelectedTemplateFormError({
        isError: false,
        errorMessage: "",
      });
    }

    if (profileType === ProfileType.SUPERIOR_TIME) {
      if (!selectedEmployee) {
        setSelectedEmployeeFormError({
          isError: true,
          errorMessage: t("select_employee"),
        });
        ifFetch = false;
      } else {
        setSelectedEmployeeFormError({
          isError: false,
          errorMessage: "",
        });
      }
    }

    let anyErrors = forceElasticFormErrors();
    if (anyErrors) {
      ifFetch = false;
    }

    if (!ifFetch) return;

    if (row?.hasOwnProperty("id")) {
      let dataToSend = {
        templateId: selectedTemplate.id,
        timeWorkerId:
          profileType === ProfileType.SUPERIOR_TIME
            ? selectedEmployee?.id
            : null,
        data: declarationData ? JSON.stringify(declarationData) : undefined,
      };

      setBodyRequest(JSON.stringify(dataToSend));
    } else {
      let dataToSend = {
        templateId: selectedTemplate.id,
        timeWorkerId:
          profileType === ProfileType.SUPERIOR_TIME
            ? selectedEmployee?.id
            : null,
        data: declarationData ? JSON.stringify(declarationData) : undefined,
      };

      setBodyRequest(JSON.stringify(dataToSend));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancelChanges}
      TransitionProps={{
        onExited: () => {
          setSelectedTemplateFormError({
            isError: false,
            errorMessage: "",
          });

          setSelectedEmployeeFormError({
            isError: false,
            errorMessage: "",
          });

          setSelectedEmployee(null);
          setSelectedTemplate(null);
          setElasticFormData(null);

          setElasticFormValues({});
          setElasticFormErrors({});
        },
        onEnter: () => {
          setIfClearReturnedValue(false);
          setIfCheckForUpdateCache(true);

          if (defaultTemplate && universalRequestsTemplates) {
            let foundTemplate = universalRequestsTemplates.find(
              (el) => el.id === parseInt(defaultTemplate)
            );
            if (foundTemplate) {
              setSelectedTemplate(foundTemplate);
            }
          }
        },
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className="dialogTitleGlobal">
        <div className="titlePopup">{t("universal_request")}</div>
        <div className="description">{t("adding_an_application")}</div>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper
          isLodadingProgress={
            fetchingStateUniversalRequestsTemplates.isFetching
          }
          isError={fetchingStateUniversalRequestsTemplates.isError}
          setIfFetchAgain={(fetchAgain) => {
            if (fetchAgain) {
              fatchAgainUniversalRequestsTemplates();
            }
          }}
          bigSize={true}
        >
          {!fetchingStateUniversalRequestsTemplates.isFetching &&
            !fetchingStateUniversalRequestsTemplates.isError && (
              <div className="popupContent">
                {profileType === ProfileType.SUPERIOR_TIME && (
                  <div className="selectEmployee">
                    <div className="titleWidthHoliday">{t("employee")}:</div>
                    <div className="inputWidthHoliday">
                      {timeWorkers && (
                        <AutocompleteStyled
                          id="combo-box-demo1"
                          options={timeWorkers || []}
                          getOptionLabel={(option: any) =>
                            option.firstName + " " + option.lastName
                          }
                          width={"100%"}
                          isError={selectedEmployeeFormError.isError}
                          value={selectedEmployee ? selectedEmployee : null}
                          onChange={(_, newValue) => {
                            setSelectedEmployee(newValue);
                          }}
                          label={t("employee")}
                          required={true}
                          onBlur={(e) => {
                            if (e.target.value === "") {
                              setSelectedEmployeeFormError({
                                isError: true,
                                errorMessage: t("select_employee"),
                              });
                            } else {
                              setSelectedEmployeeFormError({
                                isError: false,
                                errorMessage: t(""),
                              });
                            }
                          }}
                        />
                      )}
                      {selectedEmployeeFormError.isError ? (
                        <div className="errorHolidayrequest">
                          {selectedEmployeeFormError.errorMessage}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}

                <div className="typeOfRequest">
                  <div className="titleWidthHoliday">{t("request_type")}:</div>
                  <div className="inputWidthHoliday">
                    <AutocompleteStyled
                      id="combo-box-demo1"
                      options={universalRequestsTemplates}
                      getOptionLabel={(option: any) => {
                        return option.name;
                      }}
                      width={"100%"}
                      isError={selectedTemplateFormError.isError}
                      value={selectedTemplate ? selectedTemplate : null}
                      onChange={(_, newValue) => {
                        setSelectedTemplate(newValue);
                      }}
                      label={t("request_type")}
                      required={true}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setSelectedTemplateFormError({
                            isError: true,
                            errorMessage: t("select_request_type"),
                          });
                        } else {
                          setSelectedTemplateFormError({
                            isError: false,
                            errorMessage: t(""),
                          });
                        }
                      }}
                    />
                    {selectedTemplateFormError.isError ? (
                      <div className="errorHolidayrequest">
                        {selectedTemplateFormError.errorMessage}
                      </div>
                    ) : null}
                  </div>
                </div>

                {elasticFormData && (
                  <ElasticForm
                    controls={elasticFormData.controls}
                    formValues={elasticFormValues}
                    setFormValues={setElasticFormValues}
                    formErrors={elasticFormErrors}
                    setFormErrors={setElasticFormErrors}
                  />
                )}
              </div>
            )}
        </LoadingWrapper>
      </DialogContent>
      <DialogActions className="dialogActionsGlobal">
        {!fetchingStatePutPostData.isFetching ? (
          <>
            <Button onClick={onCancelChanges}>{t("cancel")}</Button>
            <Button
              onClick={async () => {
                confirm();
              }}
            >
              {t("confirm")}
            </Button>
          </>
        ) : (
          <div className="confirmWaiting">
            <CircularProgress size={30} />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddEditUniversalRequestPopup;
