import React, { useState, useEffect } from "react";
import StyledBlock from "../../../helpersComponents/StyledBlock/styledBlock";
import { useTranslation } from "react-i18next";
import Scheduler from "../../../helpersComponents/Scheduler/sheduler";
import getRange from "../../../../HelpersFunctions/dateAndTime/getDateRange";
import "./schedule.scss";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import useFetchPost from "../../../../hooks/fetchHooks/post/useFetchPost";
import computeDate from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper"; /*  */
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import TooltipSchedule from "../../../helpersComponents/Scheduler/tooltipSchedule";
import { getMinutesFromDatesHoursAndMinutes } from "../../../../HelpersFunctions/dateAndTime/getMinutesFromDatesHoursAndMinutes";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";
import useWorkerTimeConxtexMenuSchedule from "../../../helpersComponents/Scheduler/useWorkerTimeConxtexMenuSchedule";

const TooltipContent = (eventModifiedValue, ifOnlyNorm, t) => {
  if (ifOnlyNorm) {
    return (
      <div className="tooltipSchedule">
        <div className="tooltipHeaderSchedule">{t("work_schedule")}</div>
        <div className="tooltipContentSchedule">
          <div>
            <div>{t("working_time_standard")}: </div>
            <div>
              <b>
                {convertMinsToHrsMins(eventModifiedValue.workStandardInMinutes)}
              </b>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="tooltipSchedule">
      <div className="tooltipHeaderSchedule">{t("work_schedule")}</div>
      <div className="tooltipContentSchedule">
        <div>
          <div>{t("work_start")}: </div>
          <div>
            <b>
              {eventModifiedValue.startDate.toLocaleString(
                t("scheduler_language"),
                {
                  weekday: "long",
                  month: "long",
                  year: "numeric",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}
            </b>
          </div>
        </div>
        <div>
          <div>{t("work_end")}: </div>
          <div>
            <b>
              {eventModifiedValue.endDate.toLocaleString(
                t("scheduler_language"),
                {
                  weekday: "long",
                  month: "long",
                  year: "numeric",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}
            </b>
          </div>
        </div>
        <div>
          <div>{t("working_time_standard")}: </div>
          <div>
            <b>
              {convertMinsToHrsMins(eventModifiedValue.workStandardInMinutes)}
            </b>
          </div>
        </div>
        <div>
          <div>{t("break_time")}: </div>
          <div>
            <b>{eventModifiedValue.breakTimeInMinutes}</b>
          </div>
        </div>
        <div>
          <div>{t("day_shift_number")}: </div>
          <div>
            <b>{eventModifiedValue.dayShiftNumber}</b>
          </div>
        </div>
      </div>
    </div>
  );
};

function ScheduleContent() {
  const { t } = useTranslation();
  const [dataModified, setDataModified] = useState();

  const workerTimeConxtexMenuArray = useWorkerTimeConxtexMenuSchedule();
  const [currentDateFetch, setCurrentDateFetch] = useState(() => {
    let schedulerLastDate = JSON.parse(
      localStorage.getItem("workerTimeSchedulerLastDate") || "{}"
    );
    if (schedulerLastDate?.creationTime) {
      if (
        new Date(schedulerLastDate.creationTime).getTime() >
        new Date().getTime() - 300000
      ) {
        return new Date(schedulerLastDate.currentDateFetch);
      }
    }
    return new Date();
  });
  const settings = useAppSelector(selectSettings);
  const [eventComponents, setEventComponents] = useState([]);
  const [startdate, setstartdate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [currentViewName, setCurrentViewName] = useState<
    "Month" | "CurrentWeek" | "Day"
  >("Month");
  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const { data, error } = useFetchGet(
    `worker-time/${authUserId}/Schedules?DateFrom=` +
      startdate +
      "&DateTo=" +
      endDate,
    !ifFetchAgain || startdate === null || endDate === null ? false : true
  );

  useFetchPost(
    `worker-time/${authUserId}/Schedules/approve?Date=` + endDate,
    endDate !== null
  );

  useEffect(() => {
    localStorage.setItem(
      "workerTimeSchedulerLastDate",
      JSON.stringify({
        creationTime: new Date(),
        currentDateFetch,
      })
    );
  }, [currentDateFetch]);

  useEffect(() => {
    setIsFetching(true);

    let range = getRange(currentDateFetch, currentViewName);

    if (!range) return;

    let computedData: Array<string> = [];
    computedData[0] = computeDate(range.startDate, "T00:00:00");
    computedData[1] = computeDate(range.endDate, "T23:59:59");

    setstartdate(computedData[0]);
    setEndDate(computedData[1]);
  }, [currentDateFetch, currentViewName]);

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setIfFetchAgain(false);
      return;
    }
    if (data === null) return;

    let dataModified: any = [];
    let eventComponentsArray: any = [];
    let id = 0;

    data.forEach((value, i) => {
      let startDateTo: false | Date = false;
      if (value.startDate !== value.startDateTo) {
        startDateTo = new Date(value.startDateTo);
      }
      let eventModifiedValue = {
        id: id,
        breakTimeInMinutes: value.breakTimeInMinutes,
        isDayOff: value.isDayOff,
        startDate: new Date(value.startDate),
        startDateTo: startDateTo,
        endDate: new Date(value.endDate),
        workStandardInMinutes: value.workStandardInMinutes,
        dayShiftNumber: value.dayShiftNumber,
        backgroundColor: value.isDayOff ? "#1a83b3" : undefined,
      };
      if (value.isDayOff) {
        eventComponentsArray.push(
          <div className={"freeDay"} key={id}>
            <div>{t("day_off")}</div>
          </div>
        );
      } else {
        eventComponentsArray.push(
          <TooltipSchedule
            tooltipBody={TooltipContent(
              eventModifiedValue,
              settings.HarmonogramNorma?.value === "1",
              t
            )}
            key={id}
          >
            <div className="eventMonthViewSchedule">
              {settings.HarmonogramNorma?.value !== "1" && (
                <div>
                  {t("work_from")}:&nbsp;
                  {convertMinsToHrsMins(
                    getMinutesFromDatesHoursAndMinutes(
                      eventModifiedValue.startDate
                    )
                  )}
                  {eventModifiedValue.startDateTo !== false &&
                    eventModifiedValue.startDateTo !== true && (
                      <>
                        &nbsp;-&nbsp;
                        {convertMinsToHrsMins(
                          getMinutesFromDatesHoursAndMinutes(
                            eventModifiedValue.startDateTo
                          )
                        )}
                      </>
                    )}
                </div>
              )}
              <div>
                {t("norm_schedule")}:{" "}
                {convertMinsToHrsMins(eventModifiedValue.workStandardInMinutes)}
              </div>
              {settings.HarmonogramNorma?.value !== "1" && (
                <div>
                  {t("break")}: {eventModifiedValue.breakTimeInMinutes}{" "}
                  {t("minutes")}
                </div>
              )}
            </div>
          </TooltipSchedule>
        );
      }

      dataModified.push(eventModifiedValue);
      id++;
    });

    setEventComponents(eventComponentsArray);
    setDataModified(dataModified);
    setIsFetching(false);
  }, [data, settings, error, t]);

  return (
    <>
      <TopBanner pathName={t("schedule_work")} />
      <div className="flexAndCenter">
        <div>
          <StyledBlock>
            <LoadingWrapper
              isLodadingProgress={isFetching}
              isError={isError}
              setIfFetchAgain={setIfFetchAgain}
              setIsError={setIsError}
            >
              {!isFetching && !isError && (
                <Scheduler
                  data={dataModified}
                  currentDate={currentDateFetch}
                  setCurrentDate={setCurrentDateFetch}
                  components={eventComponents}
                  currentViewName={currentViewName}
                  contextMenuOptions={workerTimeConxtexMenuArray}
                  setCurrentViewName={setCurrentViewName}
                />
              )}
            </LoadingWrapper>
          </StyledBlock>
        </div>
      </div>
    </>
  );
}

export default ScheduleContent;
