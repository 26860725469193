import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../helpersComponents/PopupHelpers";
import PopupContentReplacementCard from "./PopupContentReplacementCard";

interface Props {
  isOpen: boolean;
  closePopup: () => void;
  successCallback: () => void;
  pathApi: string;
  pathWorkersApi: string;
}

const PopupReplacementCards: React.FC<Props> = ({
  isOpen,
  closePopup,
  successCallback,
  pathApi,
  pathWorkersApi,
}) => {
  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [requestBody, setRequestBody] = useState<false | any>(false);

  const onSubmit = (values: Partial<AddReplacementCard>) => {
    setErrorMessage("");

    const body = {
      acWorkerId: values.acWorker?.id,
      newCardUniversalNumber: values.newCardUniversalNumber,
      location: values.location,
      type: 0,
      cardDescription: values.cardDescription,
    };
    setRequestBody(JSON.stringify(body));
  };

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: pathApi,
    method: "POST",
    body: requestBody,
    contentType: "application/json",
    setBody: setRequestBody,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, fetchAgainPostData]);

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      disableBackdropClick={true}
    >
      <DialogTitleStyled title={t("issue_replacement_card")} />
      <DialogContentStyled>
        <PopupContentReplacementCard
          onSubmit={onSubmit}
          isOpen={isOpen}
          pathWorkersApi={pathWorkersApi}
        />
      </DialogContentStyled>
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupReplacementCards;
