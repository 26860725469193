import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import AttachmentsNewOld from "../Attachments/attachmentsNewOld";
import { AutocompleteFormik, DatePickerFormik } from "../FormikInputs";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import { DialogContentStyled, Row, RowInput, RowTitle } from "../PopupHelpers";
import FormAddNewShip from "./FormAddNewShip";
import styles from "./styles.module.scss";

interface IProps {
  onSubmit: (values: AddArrivalOfShip) => void;
  rowId?: number;
  crewListAdded: boolean | null;
  setCrewListAdded: Dispatch<SetStateAction<boolean | null>>;
  pathApiArrivalsOfShips: string;
  pathApiShips: string;
  pathApiAttachmentsInPorts: string;
}

const PopupContentArrivalOfShip: React.FC<IProps> = ({
  onSubmit,
  rowId,
  crewListAdded,
  setCrewListAdded,
  pathApiArrivalsOfShips,
  pathApiShips,
  pathApiAttachmentsInPorts,
}) => {
  const { t } = useTranslation();

  const [ships, fetchingStateShips, fetchingAgainShips] = useFetchAndSetGET({
    path: pathApiShips,
  });

  const [fetchedRow, fetchingStateFetchedRow, fetchAgainFetchedRow] =
    useFetchAndSetGET<ArrivalOfShip>({
      path: `${pathApiArrivalsOfShips}/${rowId}`,
      startFetchOnInitial: rowId ? true : false,
    });

  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [oldFiles, setOldFiles] = useState<any>(false);
  const [oldFilesRemoved, setOldFilesRemoved] = useState<any>([]);

  const [selectedCrewListFile, setSelectedCrewListFile] = useState<any>([]);
  const [oldCrewListFile, setOldCrewListFile] = useState<any>(false);
  const [oldCrewListFileRemoved, setOldCrewListFileRemoved] = useState<any>([]);

  useEffect(() => {
    if (fetchedRow) {
      const additionalAttachments = fetchedRow.shipAttachments?.filter(
        (a) => !a.required
      );

      setOldFiles(additionalAttachments ? additionalAttachments : []);

      const oldCrewList = fetchedRow.shipAttachments?.filter((a) => a.required);

      setOldCrewListFile(oldCrewList ? oldCrewList : []);
    }
  }, [fetchedRow]);

  const [showAddShipForm, setShowAddShipForm] = useState<boolean>(false);
  const [addedShipId, setAddedShipId] = useState<false | number>(false);

  useEffect(() => {
    setCrewListAdded(null);
    if (addedShipId !== false) {
      fetchingAgainShips();
    }
  }, [addedShipId, fetchingAgainShips, setCrewListAdded]);

  useEffect(() => {
    if (selectedCrewListFile.length > 0) {
      setCrewListAdded(true);
    }
  }, [selectedCrewListFile.length, setCrewListAdded]);

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={fetchingStateFetchedRow.isFetching}
        isError={fetchingStateFetchedRow.isError}
        setIfFetchAgain={() => {
          if (fetchingStateFetchedRow.isError) {
            fetchAgainFetchedRow();
          }
        }}
      >
        {ships && (fetchedRow || rowId === undefined) && (
          <DialogContentStyled>
            <Formik
              initialValues={{
                ship:
                  ships !== null && fetchedRow !== null
                    ? ships.find((ship: IShip) => ship.id === fetchedRow.shipId)
                    : ships !== null && addedShipId !== false
                    ? ships.find((ship: IShip) => ship.id === addedShipId)
                    : null,
                dateOfArrival: fetchedRow
                  ? new Date(fetchedRow.dateOfArrival)
                  : new Date(),
              }}
              onSubmit={(values) => {
                const object = {
                  ...values,
                  shipAttachments: selectedFiles,
                  shipAttachmentsToRemove: oldFilesRemoved,
                  crewList: selectedCrewListFile,
                  crewListToRemove: oldCrewListFileRemoved,
                  oldCrewListFile: oldCrewListFile,
                };

                onSubmit(object);
              }}
              validationSchema={Yup.object({
                ship: Yup.object()
                  .nullable()
                  .test("isShip", t("field_required"), (value) => {
                    return value !== null ? true : false;
                  }),
                dateOfArrival: Yup.date().typeError(t("invalid_date")),
              })}
            >
              {(formikProps) => (
                <Form noValidate id="submitForm">
                  <Row>
                    <RowTitle>{t("ship")}:</RowTitle>
                    <RowInput style={{ display: "flex" }}>
                      <LoadingWrapper
                        isLodadingProgress={fetchingStateShips.isFetching}
                        isError={fetchingStateShips.isError}
                        setIfFetchAgain={() => {
                          if (fetchingStateShips.isError) {
                            fetchingAgainShips();
                          }
                        }}
                      >
                        <AutocompleteFormik
                          options={ships}
                          getOptionLabel={(option: IShip) => {
                            return option.description !== null &&
                              option.description !== ""
                              ? `${option?.name} - ${option?.description}`
                              : option?.name;
                          }}
                          width="100%"
                          getOptionSelected={(option: IShip, value: IShip) => {
                            return option.id === value.id;
                          }}
                          label={t("select_ship")}
                          name="ship"
                          required={true}
                          style={{ width: "300px", marginRight: "10px" }}
                        />
                        <div
                          className={styles.iconShowShipForm}
                          onClick={() => {
                            setShowAddShipForm(!showAddShipForm);
                          }}
                        >
                          {showAddShipForm ? (
                            <FontAwesomeIcon
                              className="faMinusNotifications fa-lg"
                              icon={faMinus}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="faPlusNotificationsAgent fa-lg"
                              icon={faPlus}
                            />
                          )}
                        </div>
                      </LoadingWrapper>
                    </RowInput>
                  </Row>
                  {showAddShipForm && (
                    <FormAddNewShip
                      pathApiShips={pathApiShips}
                      setShowAddShipForm={setShowAddShipForm}
                      setAddedShipId={setAddedShipId}
                      addedShipId={addedShipId}
                      ships={ships}
                      setFieldValue={formikProps.setFieldValue}
                    />
                  )}

                  <Row>
                    <RowTitle>{t("date_of_arrival")}:</RowTitle>
                    <RowInput>
                      <DatePickerFormik
                        label={t("date_from")}
                        name="dateOfArrival"
                        view={["year", "month", "date"]}
                        format="dd-MM-yyyy"
                        required={true}
                      />
                    </RowInput>
                  </Row>

                  {(oldCrewListFile !== false || rowId === undefined) && (
                    <Row>
                      <RowTitle>{t("crew_list")}*:</RowTitle>
                      <RowInput>
                        <AttachmentsNewOld
                          selectedFiles={selectedCrewListFile}
                          setSelectedFiles={setSelectedCrewListFile}
                          oldFilesRemoved={oldCrewListFileRemoved}
                          setOldFilesRemoved={setOldCrewListFileRemoved}
                          path={`agent/attachments-in-ports`}
                          oldFiles={oldCrewListFile}
                          inputProps={{ multiple: true }}
                          marginButton="25px"
                          isValidate={crewListAdded}
                        />
                      </RowInput>
                    </Row>
                  )}

                  {(oldFiles !== false || rowId === undefined) && (
                    <Row>
                      <RowTitle>{t("additional_attachments")}:</RowTitle>
                      <RowInput>
                        <AttachmentsNewOld
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                          oldFilesRemoved={oldFilesRemoved}
                          setOldFilesRemoved={setOldFilesRemoved}
                          path={pathApiAttachmentsInPorts}
                          oldFiles={oldFiles}
                          inputProps={{ multiple: true }}
                          marginButton="25px"
                        />
                      </RowInput>
                    </Row>
                  )}
                </Form>
              )}
            </Formik>
          </DialogContentStyled>
        )}
      </LoadingWrapper>
    </>
  );
};

export default PopupContentArrivalOfShip;
