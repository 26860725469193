import { faCaretDown, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, DropDownButton } from "devextreme-react";
import React, { Dispatch, SetStateAction } from "react";
import { TFunction, useTranslation } from "react-i18next";
import OnExportingExcelReport from "../ExportReport/OnExportingExcelReport";
import OnExportingPdfReport from "../ExportReport/OnExportingPdfReport";
import styles from "./HeaderButtons.module.scss";

const onExportingReport = (
  e,
  type: "pdf" | "excel",
  docHeader,
  t: TFunction<"translation">
) => {
  if (type === "pdf") OnExportingPdfReport(e, docHeader, t);
  else if (type === "excel") OnExportingExcelReport(e, docHeader);
};

interface IProps {
  refreshHandler: () => void;
  dataGridInstance: any;
  isCollapsedGrid: boolean;
  setIsCollapsedGrid: Dispatch<SetStateAction<boolean>>;
  exportDocHeader?: string;
  customOnExportingPdfReport?: (
    e,
    docHeader: string | undefined,
    t: TFunction<"translation">
  ) => void;
  customOnExportingExcelReport?: (
    e,
    docHeader: string | undefined,
    t: TFunction<"translation">
  ) => void;
}

const HeaderButtons: React.FC<IProps> = ({
  refreshHandler,
  dataGridInstance,
  isCollapsedGrid,
  setIsCollapsedGrid,
  exportDocHeader,
  customOnExportingPdfReport,
  customOnExportingExcelReport,
}) => {
  const { t } = useTranslation();

  const collapseAllGroups = () => {
    if (dataGridInstance != null) dataGridInstance.collapseAll();
  };
  const expandAllGroups = () => {
    if (dataGridInstance != null) dataGridInstance.expandAll();
  };
  const changeCollapseHandler = () => {
    if (isCollapsedGrid) expandAllGroups();
    else collapseAllGroups();

    setIsCollapsedGrid((prevState) => !prevState);
  };

  return (
    <div className={styles.buttonsContainer}>
      <div>
        <Button onClick={refreshHandler} className={styles.buttonRefresh}>
          <FontAwesomeIcon icon={faRedoAlt} /> {t("refresh")}
        </Button>

        <Button
          onClick={changeCollapseHandler}
          className={styles.buttonCollapse}
        >
          <FontAwesomeIcon
            icon={faCaretDown}
            transform={`${isCollapsedGrid ? "rotate-360" : "rotate-180"}`}
          />{" "}
          {isCollapsedGrid ? t("expand") : t("collapse")}
        </Button>
      </div>

      <div>
        <DropDownButton
          text={t("actions")}
          icon="export"
          dropDownOptions={{ width: 230 }}
          className={styles.buttonExport}
          items={[t("export_pdf"), t("export_excel")]}
          onItemClick={(e) => {
            if (e.itemData === t("export_pdf")) {
              if (customOnExportingPdfReport) {
                customOnExportingPdfReport(dataGridInstance, "pdf", t);
              } else {
                onExportingReport(dataGridInstance, "pdf", exportDocHeader, t);
              }
            } else if (e.itemData === t("export_excel")) {
              if (customOnExportingExcelReport) {
                customOnExportingExcelReport(dataGridInstance, "excel", t);
              } else {
                onExportingReport(
                  dataGridInstance,
                  "excel",
                  exportDocHeader,
                  t
                );
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default HeaderButtons;
