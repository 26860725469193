import React from "react";
import ReadersMonitoringContent from "./readersMonitoringContent";

function ReadersMonitoring() {
  return (
    <div>
      <ReadersMonitoringContent />
    </div>
  );
}

export default ReadersMonitoring;
