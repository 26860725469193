import React from "react";
import TimeInOrganizationalUnitsReportContent from "./TimeInOrganizationalUnitsReportContent";

function TimeInOrganizationalUnitsReport() {
  return (
    <div>
      <TimeInOrganizationalUnitsReportContent />
    </div>
  );
}

export default TimeInOrganizationalUnitsReport;
