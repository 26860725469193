import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";

interface ReducerState {
  cardTag: null | string;
  isError: boolean;
  isListeningForTagCard: boolean;
}

type ReturnedValue = [ReducerState, (type: "START_LISTENING" | "STOP") => void];

type Action =
  | { type: "START_LISTENING" }
  | { type: "ERROR" }
  | { type: "STOP" }
  | { type: "DONE"; payload: string };

const initialState: ReducerState = {
  cardTag: null,
  isListeningForTagCard: false,
  isError: false,
};

function reducer(state: ReducerState, action: Action) {
  switch (action.type) {
    case "START_LISTENING":
      return {
        cardTag: null,
        isListeningForTagCard: true,
        isError: false,
      };
    case "ERROR":
      return {
        cardTag: null,
        isListeningForTagCard: true,
        isError: true,
      };
    case "DONE":
      return {
        cardTag: action.payload,
        isListeningForTagCard: true,
        isError: false,
      };
    case "STOP":
      return {
        cardTag: null,
        isListeningForTagCard: false,
        isError: false,
      };
  }
}

export default function useGetCardTagConfirmationRostrumReader(
  setCardTag: Dispatch<SetStateAction<string | null | undefined>>,
  setBufferedCard: Dispatch<SetStateAction<boolean>>,
  setErrorCounter: Dispatch<SetStateAction<number>>
): ReturnedValue {
  const [{ cardTag, isListeningForTagCard, isError }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const startListetning = useCallback(
    (type: "START_LISTENING" | "STOP") => {
      dispatch({ type });
    },
    [dispatch]
  );

  const settings = useAppSelector(selectSettings);
  const pathConfirmationReader = settings["AdresCzytnikaPotwierdzania"]?.value;

  const [beforeCardTag, setBeforeCardTag] = useState("");
  const currentTime = useRef(0);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    let abortController: AbortController;

    const getCardNumber = async () => {
      let myHeaders = new Headers();
      abortController = new AbortController();
      myHeaders.append("Authorization", "Basic YWRtaW46YWRtaW4=");
      try {
        const response = await fetch(pathConfirmationReader, {
          method: "GET",
          headers: myHeaders,
          signal: abortController.signal,
        });
        const json = await response.json();

        if (abortController.signal.aborted) {
          return;
        }
        if (json.read_time <= 5) {
          if (abortController.signal.aborted) {
            return;
          }

          if (
            json?.tag !== "" &&
            json?.tag === beforeCardTag &&
            json?.read_time < currentTime?.current
          ) {
            setBufferedCard(true);
          } else {
            setBufferedCard(false);
          }
          currentTime.current = json?.read_time;

          if (json?.tag !== "" && beforeCardTag !== json?.tag) {
            setCardTag(json?.tag);
            currentTime.current = 0;
          }
          setBeforeCardTag(json?.tag);

          timeout = setTimeout(getCardNumber, 500);
        } else {
          if (abortController.signal.aborted) {
            return;
          }
          setCardTag(null);
          setBufferedCard(false);
          currentTime.current = 0;
          setBeforeCardTag("");
          timeout = setTimeout(getCardNumber, 500);
        }
        setErrorCounter(0);
      } catch (e) {
        if (abortController.signal.aborted) {
          return;
        }
        currentTime.current = 0;
        setBeforeCardTag("");
        dispatch({ type: "ERROR" });
        setErrorCounter((prevValue) => prevValue + 1);
        timeout = setTimeout(getCardNumber, 500);
      }
    };

    if (isListeningForTagCard) {
      getCardNumber();
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (abortController) {
        abortController.abort();
      }
    };
  }, [
    isListeningForTagCard,
    setCardTag,
    pathConfirmationReader,
    setBufferedCard,
    beforeCardTag,
    setErrorCounter,
  ]);

  return [{ cardTag, isError, isListeningForTagCard }, startListetning];
}
