import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

const initialState: any = {
  numberUnreadMessages: null,
};

export const checkIfNewMessagesReducer = createSlice({
  name: "setNumberUnreadMessages",
  initialState,
  reducers: {
    setNumberUnreadMessages: (state, action: PayloadAction<any>) => {
      state.numberUnreadMessages = action.payload;
    },
    decrementMessages: (state) => {
      state.numberUnreadMessages -= 1;
    },
  },
});

export const selectNumberUnreadMessages = (state: RootState) =>
  state.checkIfNewMessages.numberUnreadMessages;
export const { setNumberUnreadMessages, decrementMessages } =
  checkIfNewMessagesReducer.actions;
export default checkIfNewMessagesReducer.reducer;
