import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import DatePickerRange from "../../helpersComponents/DatePickerRange/datePickerRange";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import Button from "../../helpersComponents/Button/button";
import { CircularProgress } from "@material-ui/core";

function LockersAssignmentHistory() {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Month")
  );

  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Month"));

  const columns = [
    { name: "employee", title: t("employee") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "lockersModuleName", title: t("locker_module") },
    { name: "lockerName", title: t("locker") },
    { name: "dateFrom", title: t("date_from"), type: "date_hours" },
    { name: "dateTo", title: t("date_to2"), type: "date_hours" },
    { name: "addedBy", title: t("added_by") },
    { name: "deletedBy", title: t("deleted_by") },
  ];

  const modifyResponseCallback = useCallback(
    (responseData: LockerAssignmentToEmployeeHistoryItem[]) => {
      const modified = responseData.map((row, index) => {
        return {
          ...row,
          employee: `${row.firstName} ${row.lastName}`,
        };
      });
      return modified;
    },
    []
  );

  const [
    lockersAssignmentToEmployeesHistory,
    fetchingStateLockersAssignmentToEmployeesHistory,
    fetchAgainStateLockersAssignmentToEmployeesHistory,
  ] = useFetchAndSetGET({
    path: `lockers-management/lockers-assignment-to-employees-history?dateFrom=${convertDateFetch(
      startDate
    )}&dateTo=${convertDateFetch(endDate)}`,
    startFetchOnInitial: true,
    modifyResponseCallback: modifyResponseCallback,
  });

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {fetchingStateLockersAssignmentToEmployeesHistory.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <Button
            onClick={() => {
              fetchAgainStateLockersAssignmentToEmployeesHistory();
            }}
          >
            {t("fetch_lockers_events")}
          </Button>
        )}
      </div>
    </>
  );

  return (
    <>
      <TopBanner pathName={t("lockers_assignment_history")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date", "hours", "minutes"]}
            format="dd-MM-yyyy HH:mm"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            keyboardDateTimePicker={true}
            darkTheme={true}
            setDate2={setEndDate}
            confirmButtonRight={confirmButtonRight}
          ></DatePickerRange>
        </div>
        <div>
          {!fetchingStateLockersAssignmentToEmployeesHistory.isFetching && (
            <TableComponent
              rows={lockersAssignmentToEmployeesHistory || []}
              columns={columns}
              layoutSettingName="layoutSuperiorAcLockersAssignmentHistory"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default LockersAssignmentHistory;
