import {
  faSignInAlt,
  faSignOutAlt,
  faBriefcase,
  faCog,
  faHourglass,
  faHourglassEnd,
  faClock,
  faCalendarPlus,
  faBusinessTime,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import minsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import getTimeFromDate from "../../../../HelpersFunctions/dateAndTime/getTimeFromDate";

const TooltipContent = (eventModifiedValue, t) => {
  return (
    <div className="worktimePopup">
      <div className="rowPopup">
        <div className="keyValueContainer">
          <div className="keyValue">
            <div>
              <div className="faSignInAlt">
                <FontAwesomeIcon className="fa-1x" icon={faSignInAlt} />
              </div>
              <div>{t("work_start")}</div>
            </div>
            <div>
              <b>
                {getTimeFromDate(new Date(eventModifiedValue.startWorkTime))}
              </b>
            </div>
          </div>
        </div>
        <div className="keyValueContainer">
          <div className="keyValue">
            <div>
              <div className="faSignOutAlt">
                <FontAwesomeIcon className="fa-1x" icon={faSignOutAlt} />
              </div>
              <div>{t("work_end")}</div>
            </div>
            <div>
              <b>{getTimeFromDate(new Date(eventModifiedValue.endWorkTime))}</b>
            </div>
          </div>
        </div>
      </div>
      <div className="rowPopup">
        <div className="keyValueContainer">
          <div className="keyValue">
            <div>
              <div className="faBriefcase">
                <FontAwesomeIcon className="fa-1x" icon={faBriefcase} />
              </div>
              <div>{t("worktime")}</div>
            </div>
            <div>
              <b>{minsToHrsMins(eventModifiedValue.workingTime)}</b>
            </div>
          </div>
        </div>
        <div className="keyValueContainer">
          <div className="keyValue">
            <div>
              <div className="faCog">
                <FontAwesomeIcon className="fa-1x" icon={faCog} />
              </div>
              <div>{t("working_time_standard")}</div>
            </div>
            <div>
              <b>{minsToHrsMins(eventModifiedValue.workTimeStandard)}</b>
            </div>
          </div>
        </div>
      </div>
      <div className="rowPopup">
        <div className="keyValueContainer">
          <div className="keyValue">
            <div>
              <div className="faHourglassEnd">
                <FontAwesomeIcon className="fa-1x" icon={faHourglassEnd} />
              </div>
              <div>{t("overtime_50")}</div>
            </div>
            <div>
              <b>{minsToHrsMins(eventModifiedValue.overTime50)}</b>
            </div>
          </div>
        </div>
        <div className="keyValueContainer">
          <div className="keyValue">
            <div>
              <div className="faHourglass">
                <FontAwesomeIcon className="fa-1x" icon={faHourglass} />
              </div>
              <div>{t("overtime_100")}</div>
            </div>
            <div>
              <b>{minsToHrsMins(eventModifiedValue.overTime100)}</b>
            </div>
          </div>
        </div>
      </div>
      <div className="rowPopup">
        <div className="keyValueContainer">
          <div className="keyValue">
            <div>
              <div className="faClock">
                <FontAwesomeIcon className="fa-1x" icon={faClock} />
              </div>
              <div>{t("late")}</div>
            </div>
            <div>
              <b>{minsToHrsMins(eventModifiedValue.late)}</b>
            </div>
          </div>
        </div>
        <div className="keyValueContainer">
          <div className="keyValue">
            <div>
              <div className="faCalendarPlus">
                <FontAwesomeIcon className="fa-1x" icon={faCalendarPlus} />
              </div>
              <div>{t("work_above_the_norm")}</div>
            </div>
            <div>
              <b>{minsToHrsMins(eventModifiedValue.workAboveNorm)}</b>
            </div>
          </div>
        </div>
      </div>
      <div className="rowPopup">
        <div className="keyValueContainer">
          <div className="keyValue">
            <div>
              <div className="faBusinessTime">
                <FontAwesomeIcon className="fa-1x" icon={faBusinessTime} />
              </div>
              <div>{t("business_exits")}</div>
            </div>
            <div>
              <b>{minsToHrsMins(eventModifiedValue.businessEntriesExits)}</b>
            </div>
          </div>
        </div>
        <div className="keyValueContainer">
          <div className="keyValue">
            <div>
              <div className="faBuilding">
                <FontAwesomeIcon className="fa-1x" icon={faBuilding} />
              </div>
              <div>{t("time_spent_on_the_premises_of_the_company")}</div>
            </div>
            <div>
              <b>
                {minsToHrsMins(
                  eventModifiedValue.timeSpentOnThePremisesOfTheCompany
                )}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TooltipContent;
