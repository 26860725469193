import {
  DataGrid,
  GridCellEditCommitParams,
  GridInputSelectionModel,
  GridRowClassNameParams,
  GridRowIdGetter,
  GridSelectionModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  MuiEvent,
} from "@mui/x-data-grid";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

interface ICustomToolbar {
  exportData?: boolean;
  quickFilter?: boolean;
}

function CustomToolbar({ exportData, quickFilter }: ICustomToolbar) {
  return (
    <GridToolbarContainer className={styles.toolbar}>
      {exportData && (
        <div>
          <GridToolbarExport className={styles.export} />
        </div>
      )}
      <div>
        <GridToolbarFilterButton className={styles.filter} />
      </div>
      {quickFilter && (
        <div>
          <GridToolbarQuickFilter />
        </div>
      )}
    </GridToolbarContainer>
  );
}

interface IProps {
  columns: any;
  rows: any[];
  defaultPageSize?: number;
  rowsPerPageOptions?: number[];
  density?: "standard" | "compact" | "comfortable";
  exportData?: boolean;
  quickFilter?: boolean;
  checkboxSelection?: boolean;
  onSelectionModelChange?: (selectionModel: GridSelectionModel) => void;
  onCellEditCommit?: (
    params: GridCellEditCommitParams,
    event: MuiEvent
  ) => void;
  onEditRowsModelChange?: (model) => void;
  getRowClassName?: (params: GridRowClassNameParams) => string;
  getRowId?: GridRowIdGetter;
  selectionModel?: GridInputSelectionModel;
}

const StyledDataGrid: React.FC<IProps> = ({
  columns,
  rows,
  defaultPageSize,
  rowsPerPageOptions,
  density,
  exportData,
  quickFilter,
  checkboxSelection,
  onSelectionModelChange,
  onCellEditCommit,
  onEditRowsModelChange,
  getRowClassName,
  getRowId,
  selectionModel,
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(
    defaultPageSize ? defaultPageSize : 5
  );

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [5, 10, 20]}
      density={density ? density : "standard"}
      getRowHeight={() => "auto"}
      onCellEditCommit={onCellEditCommit}
      onEditRowsModelChange={onEditRowsModelChange}
      getRowClassName={getRowClassName}
      getRowId={getRowId}
      selectionModel={selectionModel}
      localeText={{
        toolbarColumns: t("columns"),
        filterPanelColumns: t("column"),
        filterPanelInputLabel: t("value"),
        filterPanelInputPlaceholder: t("value"),
        toolbarFilters: t("filters"),
        toolbarExport: t("export"),
        columnMenuShowColumns: t("show_columns"),
        columnMenuHideColumn: t("hide"),
        columnMenuFilter: t("filter_table"),
        columnMenuSortAsc: `${t("sort")} ASC`,
        columnMenuSortDesc: `${t("sort")} DESC`,
        toolbarExportPrint: t("print"),
        toolbarExportCSV: t("export_excel"),
        columnMenuUnsort: t("unsort"),
        noRowsLabel: t("no_data"),
        noResultsOverlayLabel: t("none"),
        toolbarQuickFilterPlaceholder: `${t("search")}...`,
        filterPanelOperators: t("condition"),
        checkboxSelectionHeaderName: t("selected_2"),
        columnsPanelShowAllButton: t("show_all"),
        columnsPanelHideAllButton: t("hide_all"),
        columnsPanelTextFieldPlaceholder: t("column_name"),
        columnsPanelTextFieldLabel: t("find_column"),
        footerRowSelected(count) {
          return (
            <div>
              {t("selected").charAt(0).toUpperCase() + t("selected").slice(1)}:{" "}
              {count}
            </div>
          );
        },
      }}
      components={{
        Toolbar:
          exportData || quickFilter
            ? () => CustomToolbar({ exportData, quickFilter })
            : null,
      }}
      componentsProps={{
        toolbar: {},
      }}
      checkboxSelection={checkboxSelection ? true : false}
      onSelectionModelChange={
        onSelectionModelChange
          ? (selected) => onSelectionModelChange(selected)
          : undefined
      }
    />
  );
};

export default StyledDataGrid;
