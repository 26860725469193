import ShipsContent from "../../helpersComponents/Ships/ShipsContent";

const ShipsAgent = () => {
  return (
    <ShipsContent
      pathApiShips="agent/ships"
      layoutSettingName="layoutAgentShipsRecords"
    />
  );
};

export default ShipsAgent;
