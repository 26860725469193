import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { SchedulerContextMenuItemType } from "../../../enums/SchedulerContextMenuItemType";

export default function useWorkerTimeConxtexMenuSchedule() {
  const { t } = useTranslation();
  const history = useHistory();

  return [
    {
      optionName: t("add_business_trip"),
      optionType: SchedulerContextMenuItemType.ADD_DELEGATION,
      onClick: (selectedDateRange: SchedulerContextMenuCallbackData) => {
        history.push({
          pathname: "/worker-time/delegations-list",
          state: selectedDateRange,
        });
      },
    },
    {
      optionName: t("submit_hours_application"),
      optionType: SchedulerContextMenuItemType.ADD_HOURS_REQUEST,
      onClick: (selectedDateRange: SchedulerContextMenuCallbackData) => {
        history.push({
          pathname: "/worker-time/hours-request",
          state: selectedDateRange,
        });
      },
    },
    {
      optionName: t("submit_a_holiday_application"),
      optionType: SchedulerContextMenuItemType.ADD_HOLIDAYS_REQUEST,
      onClick: (selectedDateRange: SchedulerContextMenuCallbackData) => {
        history.push({
          pathname: "/worker-time/holiday",
          state: selectedDateRange,
        });
      },
    },
    {
      optionName: t("plan_holidays"),
      optionType: SchedulerContextMenuItemType.ADD_HOLIDAY_PLAN,
      onClick: (selectedDateRange: SchedulerContextMenuCallbackData) => {
        history.push({
          pathname: "/worker-time/holidays-plan",
          state: selectedDateRange,
        });
      },
    },
    {
      optionName: t("home_office"),
      optionType: SchedulerContextMenuItemType.ADD_HOME_OFFICE_REQUEST,
      onClick: (
        selectedDateRange: SchedulerContextMenuCallbackData,
        additionalData: any
      ) => {
        /*if (additionalData) {
        }
        //console.log(`selectedDateRange: ${JSON.stringify(selectedDateRange)}`);
        history.push({
          pathname: "/worker-time/holidays-plan",
          state: selectedDateRange,
        });*/
      },
    },
  ];
}
