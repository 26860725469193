import { Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextFieldFormik } from "../../../helpersComponents/FormikInputs";
import CheckboxWithLabelFormik from "../../../helpersComponents/FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../../helpersComponents/PopupHelpers";
import styles from "./styles.module.scss";
import * as Yup from "yup";

import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { isNullOrEmpty } from "../../../../HelpersFunctions/stringUtils";

export default function QuestionPopup({
  isOpen,
  row,
  closePopup,
  successCallbackAdd,
  successCallbackEdit,
  multiSelectionInQuestions,
}) {
  const { t } = useTranslation();
  const isSubmittingRef = useRef(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [value, setValue] = React.useState(0);

  let initValues: Question = {
    id: 0,
    questionPL: "",
    questionEN: "",
    questionDE: "",
    questionRU: "",
    questionUK: "",

    answerA_PL: "",
    answerA_EN: "",
    answerA_DE: "",
    answerA_RU: "",
    answerA_UK: "",

    answerB_PL: "",
    answerB_EN: "",
    answerB_DE: "",
    answerB_RU: "",
    answerB_UK: "",

    answerC_PL: "",
    answerC_EN: "",
    answerC_DE: "",
    answerC_RU: "",
    answerC_UK: "",

    answerD_PL: "",
    answerD_EN: "",
    answerD_DE: "",
    answerD_RU: "",
    answerD_UK: "",

    isAnswerACorrect: false,
    isAnswerBCorrect: false,
    isAnswerCCorrect: false,
    isAnswerDCorrect: false,
  };

  if (row) {
    initValues = {
      id: row.id,
      questionPL: row.questionPL,
      questionEN: row.questionEN,
      questionDE: row.questionDE,
      questionRU: row.questionRU,
      questionUK: row.questionUK,

      answerA_PL: row.answerA_PL,
      answerA_EN: row.answerA_EN,
      answerA_DE: row.answerA_DE,
      answerA_RU: row.answerA_RU,
      answerA_UK: row.answerA_UK,

      answerB_PL: row.answerB_PL,
      answerB_EN: row.answerB_EN,
      answerB_DE: row.answerB_DE,
      answerB_RU: row.answerB_RU,
      answerB_UK: row.answerB_UK,

      answerC_PL: row.answerC_PL,
      answerC_EN: row.answerC_EN,
      answerC_DE: row.answerC_DE,
      answerC_RU: row.answerC_RU,
      answerC_UK: row.answerC_UK,

      answerD_PL: row.answerD_PL,
      answerD_EN: row.answerD_EN,
      answerD_DE: row.answerD_DE,
      answerD_RU: row.answerD_RU,
      answerD_UK: row.answerD_UK,

      isAnswerACorrect: row.isAnswerACorrect,
      isAnswerBCorrect: row.isAnswerBCorrect,
      isAnswerCCorrect: row.isAnswerCCorrect,
      isAnswerDCorrect: row.isAnswerDCorrect,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChangeTabControl = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const closeDialog = () => {
    closePopup();
    setValue(0);
  };

  return (
    <DialogStyled open={isOpen} onClose={closeDialog}>
      <DialogTitleStyled
        title={row ? t("editing_question") : t("adding_question")}
      />
      <DialogContentStyled>
        <Formik
          initialValues={initValues}
          onSubmit={(values) => {
            if (
              isNullOrEmpty(values.questionPL) &&
              isNullOrEmpty(values.questionEN) &&
              isNullOrEmpty(values.questionDE) &&
              isNullOrEmpty(values.questionRU) &&
              isNullOrEmpty(values.questionUK)
            ) {
              setErrorMessage(t("no_question_entered"));
              isSubmittingRef.current = false;
              return;
            }

            closePopup();
            if (row) successCallbackEdit(values, row);
            else successCallbackAdd(values);
            isSubmittingRef.current = false;
          }}
          validationSchema={Yup.object().shape({
            questionPL: Yup.string(),
            questionEN: Yup.string(),
            questionDE: Yup.string(),
            questionRU: Yup.string(),
            questionUK: Yup.string(),

            answerA_PL: Yup.string().when(
              "questionPL",
              (questionPL, schema) => {
                if (questionPL?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerB_PL: Yup.string().when(
              "questionPL",
              (questionPL, schema) => {
                if (questionPL?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerC_PL: Yup.string().when(
              "questionPL",
              (questionPL, schema) => {
                if (questionPL?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerD_PL: Yup.string().when(
              "questionPL",
              (questionPL, schema) => {
                if (questionPL?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerA_EN: Yup.string().when(
              "questionEN",
              (questionEN, schema) => {
                if (questionEN?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerB_EN: Yup.string().when(
              "questionEN",
              (questionEN, schema) => {
                if (questionEN?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerC_EN: Yup.string().when(
              "questionEN",
              (questionEN, schema) => {
                if (questionEN?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerD_EN: Yup.string().when(
              "questionEN",
              (questionEN, schema) => {
                if (questionEN?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerA_DE: Yup.string().when(
              "questionDE",
              (questionDE, schema) => {
                if (questionDE?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerB_DE: Yup.string().when(
              "questionDE",
              (questionDE, schema) => {
                if (questionDE?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerC_DE: Yup.string().when(
              "questionDE",
              (questionDE, schema) => {
                if (questionDE?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerD_DE: Yup.string().when(
              "questionDE",
              (questionDE, schema) => {
                if (questionDE?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerA_RU: Yup.string().when(
              "questionRU",
              (questionRU, schema) => {
                if (questionRU?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerB_RU: Yup.string().when(
              "questionRU",
              (questionRU, schema) => {
                if (questionRU?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerC_RU: Yup.string().when(
              "questionRU",
              (questionRU, schema) => {
                if (questionRU?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerD_RU: Yup.string().when(
              "questionRU",
              (questionRU, schema) => {
                if (questionRU?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerA_UK: Yup.string().when(
              "questionUK",
              (questionUK, schema) => {
                if (questionUK?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerB_UK: Yup.string().when(
              "questionUK",
              (questionUK, schema) => {
                if (questionUK?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerC_UK: Yup.string().when(
              "questionUK",
              (questionUK, schema) => {
                if (questionUK?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),

            answerD_UK: Yup.string().when(
              "questionUK",
              (questionUK, schema) => {
                if (questionUK?.length > 0) {
                  return schema.required(t("field_required"));
                } else {
                  return schema;
                }
              }
            ),
          })}
        >
          {(formikProps) => {
            isSubmittingRef.current = formikProps.isSubmitting;
            return (
              <Form noValidate id="submitQuestion">
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="disabled tabs example"
                  value={value}
                  onChange={handleChangeTabControl}
                >
                  <Tab label="PL" {...a11yProps(0)} />
                  <Tab label="EN" {...a11yProps(1)} />
                  <Tab label="DE" {...a11yProps(2)} />
                  <Tab label="RU" {...a11yProps(3)} />
                  <Tab label="UK" {...a11yProps(4)} />
                </Tabs>

                <TabPanel value={value} index={0}>
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("question")}:</RowTitle>
                      <RowInput width={745}>
                        <TextFieldFormik
                          label={t("question")}
                          type="text"
                          name={`questionPL`}
                          multiline
                          rows={3}
                          onChangeExtra={(e) => {
                            setErrorMessage("");
                          }}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          name={`isAnswerACorrect`}
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>A)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"A"}
                          name={`answerA_PL`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          name={`isAnswerBCorrect`}
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>B)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"B"}
                          name={`answerB_PL`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          name={`isAnswerCCorrect`}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>C)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"C"}
                          name={`answerC_PL`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                            }
                          }}
                          name={`isAnswerDCorrect`}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>D)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"D"}
                          name={`answerD_PL`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("question")}:</RowTitle>
                      <RowInput width={745}>
                        <TextFieldFormik
                          label={t("question")}
                          type="text"
                          name={`questionEN`}
                          multiline
                          rows={3}
                          onChangeExtra={(e) => {
                            setErrorMessage("");
                          }}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          name={`isAnswerACorrect`}
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>A)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"A"}
                          name={`answerA_EN`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          name={`isAnswerBCorrect`}
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>B)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"B"}
                          name={`answerB_EN`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          name={`isAnswerCCorrect`}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>C)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"C"}
                          name={`answerC_EN`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                            }
                          }}
                          name={`isAnswerDCorrect`}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>D)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"D"}
                          name={`answerD_EN`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("question")}:</RowTitle>
                      <RowInput width={745}>
                        <TextFieldFormik
                          label={t("question")}
                          type="text"
                          name={`questionDE`}
                          multiline
                          rows={3}
                          onChangeExtra={(e) => {
                            setErrorMessage("");
                          }}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          name={`isAnswerACorrect`}
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>A)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"A"}
                          name={`answerA_DE`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          name={`isAnswerBCorrect`}
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>B)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"B"}
                          name={`answerB_DE`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          name={`isAnswerCCorrect`}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>C)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"C"}
                          name={`answerC_DE`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                            }
                          }}
                          name={`isAnswerDCorrect`}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>D)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"D"}
                          name={`answerD_DE`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>
                </TabPanel>

                <TabPanel value={value} index={3}>
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("question")}:</RowTitle>
                      <RowInput width={745}>
                        <TextFieldFormik
                          label={t("question")}
                          type="text"
                          name={`questionRU`}
                          multiline
                          rows={3}
                          onChangeExtra={(e) => {
                            setErrorMessage("");
                          }}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          name={`isAnswerACorrect`}
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>A)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"A"}
                          name={`answerA_RU`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          name={`isAnswerBCorrect`}
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>B)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"B"}
                          name={`answerB_RU`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          name={`isAnswerCCorrect`}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>C)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"C"}
                          name={`answerC_RU`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                            }
                          }}
                          name={`isAnswerDCorrect`}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>D)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"D"}
                          name={`answerD_RU`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>
                </TabPanel>

                <TabPanel value={value} index={4}>
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("question")}:</RowTitle>
                      <RowInput width={745}>
                        <TextFieldFormik
                          label={t("question")}
                          type="text"
                          name={`questionUK`}
                          multiline
                          rows={3}
                          onChangeExtra={(e) => {
                            setErrorMessage("");
                          }}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          name={`isAnswerACorrect`}
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>A)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"A"}
                          name={`answerA_UK`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          name={`isAnswerBCorrect`}
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>B)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"B"}
                          name={`answerB_UK`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerDCorrect",
                                false
                              );
                            }
                          }}
                          name={`isAnswerCCorrect`}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>C)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"C"}
                          name={`answerC_UK`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          onChangeExtra={(e) => {
                            if (
                              multiSelectionInQuestions === false &&
                              e.target.value === "false"
                            ) {
                              formikProps.setFieldValue(
                                "isAnswerBCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerCCorrect",
                                false
                              );
                              formikProps.setFieldValue(
                                "isAnswerACorrect",
                                false
                              );
                            }
                          }}
                          name={`isAnswerDCorrect`}
                          ifCircle={multiSelectionInQuestions ? false : true}
                          label={<span className={styles.label}>D)</span>}
                        />
                      </RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={"D"}
                          name={`answerD_UK`}
                          type="text"
                          multiline
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>
                </TabPanel>
              </Form>
            );
          }}
        </Formik>
      </DialogContentStyled>
      <DialogActionsStyled
        typConfirm="submit"
        disabledConfirm={isSubmittingRef.current}
        formConfirm="submitQuestion"
        onCancel={closePopup}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
}
