import SystemObjectItem from "./SystemObjectItem";

interface IProps {}

const MapArrangmentObjectsExplorer: React.FC<IProps> = () => {
  return (
    <>
      <SystemObjectItem id={1} text={"item1"} />
      <SystemObjectItem id={2} text={"item2"} />
    </>
  );
};

export default MapArrangmentObjectsExplorer;
