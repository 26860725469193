export enum PolicyError {
  TooShort = 1,
  TooLittleDifference = 2,
  NoDigit = 3,
  NoUpperCaseCharacter = 4,
  NoLowerCaseCharacter = 5,
  NoSpecialCharacter = 6,
  UsedBefore = 7,
  TwoFactorAuthenticationRequired = 8,
}
