import { useTranslation } from "react-i18next";
import { RequestStatus } from "../Constants/requestStatus";

const RequestStatusName = (requestStatusId: RequestStatus) => {
  const { t } = useTranslation();

  switch (requestStatusId) {
    case RequestStatus.INTRODUCTED:
      return t("request_status_introduced");
    case RequestStatus.APPROVED:
      return t("request_status_approved");
    case RequestStatus.FINALLY_APPROWED:
      return t("request_status_finally_approved");
    case RequestStatus.TO_CHANGE:
      return t("request_status_to_change");
    case RequestStatus.REJECTED:
      return t("request_status_rejected");
    case RequestStatus.FINALLY_REJECTED:
      return t("request_status_finally_rejected");
    case RequestStatus.CANCELED:
      return t("request_status_canceled");
    case RequestStatus.APPROVED_1:
      return t("request_status_approved_1");
    case RequestStatus.APPROVED_2:
      return t("request_status_approved_2");
    case RequestStatus.APPROVED_3:
      return t("request_status_approved_3");
    case RequestStatus.APPROVED_4:
      return t("request_status_approved_4");
    case RequestStatus.APPROVED_5:
      return t("request_status_approved_5");
    case RequestStatus.APPROVED_6:
      return t("request_status_approved_6");
    case RequestStatus.APPROVED_7:
      return t("request_status_approved_7");
    case RequestStatus.APPROVED_8:
      return t("request_status_approved_8");
    case RequestStatus.APPROVED_9:
      return t("request_status_approved_9");
    case RequestStatus.APPROVED_10:
      return t("request_status_approved_10");

    default:
      return "";
  }
};

export default RequestStatusName;
