import React from 'react';
import HolidaysPlanContent from "./holidaysPlanContent"


function HolidaysPlan() {
    return (
        <div>
            <HolidaysPlanContent/>
        </div>
    );
}

export default HolidaysPlan;
