import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import WorkersACManagementContent from "./WorkersACManagementContent";

const WorkersACManagement = () => {
  const { t } = useTranslation();
  return (
    <div>
      <TopBanner pathName={t("employees")} />
      <WorkersACManagementContent />
    </div>
  );
};

export default WorkersACManagement;
