import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

interface IACUser {
  id: number | null;
  login: string | null;
  isActive: boolean | null;
  fullName: string | null;
  email: string | null;
  phoneNumber: string | null;
  isLocked: boolean | null;
  isChangePasswordRequired: boolean | null;
  loginAttempts: number | null;
  dateOfPasswordChange: Date | null;
  lastLogin: Date | null;
  permission: ACUserPermission | null;
  departments: Department[] | null;
  groups: Group[] | null;
  policies: Policy[] | null;
}

interface InitialState {
  acUser: IACUser;
}

const initialState: InitialState = {
  acUser: {
    id: null,
    login: null,
    isActive: null,
    fullName: null,
    email: null,
    phoneNumber: null,
    isLocked: null,
    isChangePasswordRequired: null,
    loginAttempts: null,
    dateOfPasswordChange: null,
    lastLogin: null,
    permission: null,
    departments: null,
    groups: null,
    policies: null,
  },
};

export const acSuperiorCurrentUserReducer = createSlice({
  name: "acSuperiorCurrentUser",
  initialState,
  reducers: {
    setACSuperiorCurrentUser: (state, action: PayloadAction<any>) => {
      state.acUser = action.payload;
    },
  },
});

export const selectACSuperiorCurrentUser = (state: RootState) =>
  state.acSuperiorCurrentUser.acUser;
export const { setACSuperiorCurrentUser } =
  acSuperiorCurrentUserReducer.actions;
export default acSuperiorCurrentUserReducer.reducer;
