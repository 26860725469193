import React, { useState } from "react";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";

export default function useSummaryRowProps(): [
  {
    totalItems: { columnName: string; type: string }[];
    customItem: any;
    calculator: any;
  },
  any
] {
  const [items, setItems] = useState([
    { columnName: "absencesString", type: "" },
    { columnName: "absencesWithWorkedNormString", type: "" },
    { columnName: "breaksString", type: "" },
    { columnName: "businessEntriesExitsString", type: "" },
    { columnName: "daysOffWithWorkedNormString", type: "" },
    { columnName: "homeOfficeString", type: "" },
    { columnName: "languageLearningHoursString", type: "" },
    { columnName: "nightWorkString", type: "" },
    { columnName: "nightWorkOvertimeString", type: "" },
    { columnName: "nightWorkScheduleString", type: "" },

    { columnName: "lateString", type: "" },
    { columnName: "normBalanceString", type: "" },
    { columnName: "normativeTimeString", type: "" },
    { columnName: "overtimeString", type: "" },
    { columnName: "overtime50String", type: "" },
    { columnName: "overtime100String", type: "" },
    { columnName: "overtime150String", type: "" },
    { columnName: "overtime200String", type: "" },

    { columnName: "notWorkedString", type: "" },
    { columnName: "remainsToWorkoutString", type: "" },
    { columnName: "socialBreakString", type: "" },
    { columnName: "timeSpentOnThePremisesOfTheCompanyString", type: "" },
    { columnName: "unpaidPrivatePassString", type: "" },
    { columnName: "workAboveNormString", type: "" },
    { columnName: "workDuringDutyString", type: "" },
    { columnName: "workOfAdolescentsInForbiddenWorkString", type: "" },
    { columnName: "workOnDaysOffString", type: "" },
    { columnName: "workOnHolidayString", type: "" },
    { columnName: "workOnSundaysAndHolidaysString", type: "" },
    { columnName: "workingOffString", type: "" },
    { columnName: "blockToEditString", type: "" },
    // { columnName: "od1String", type: "" },
    // { columnName: "do1String", type: "" },
    { columnName: "dutyString", type: "" },
    { columnName: "entryCompanyAreaString", type: "" },
    { columnName: "exitCompanyAreaString", type: "" },
    { columnName: "firstShiftString", type: "" },

    { columnName: "overtime50PercentToPayString", type: "" },
    { columnName: "overtime50PercentToTakeString", type: "" },
    { columnName: "overtime100PercentToPayString", type: "" },
    { columnName: "overtime100PercentToTakeString", type: "" },
    { columnName: "privateLeaveString", type: "" },
    { columnName: "secondShfitString", type: "" },
    { columnName: "skTrainingString", type: "" },
    { columnName: "thirdShiftString", type: "" },
    { columnName: "workAtCostCenterString", type: "" },
    { columnName: "workOnHolidaysString", type: "" },
    { columnName: "workedString", type: "" },
    { columnName: "workingTimeString", type: "" },
    { columnName: "workTimeStandardString", type: "" },
    { columnName: "overtimeBalanceString", type: "" },
    { columnName: "daysBalanceString", type: "" },
  ]);

  return [
    {
      totalItems: items,
      customItem: ({ ...props }) => {
        return <b>{props.value}</b>;
      },
      calculator: (type, rows, getValue) => {
        let rowMinutes = 0;
        rows.forEach((_, index) => {
          let rowValue = getValue(rows[index]);
          if (rowValue?.props) {
            let reactElementValue = rowValue.props.children;
            if (reactElementValue) {
              let a = reactElementValue.split(":");
              rowMinutes = rowMinutes + parseInt(a[0]) * 60 + parseInt(a[1]);
            }
          } else if (rowValue) {
            let a = rowValue.split(":");
            rowMinutes = rowMinutes + parseInt(a[0]) * 60 + parseInt(a[1]);
          }
        });
        return convertMinsToHrsMins(rowMinutes);
      },
    },
    setItems,
  ];
}
