import { useEffect } from "react";
import useFetchAndSetGET from "../fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectAuthUser } from "../../reducers/session";
import { getProfileName } from "../../HelpersFunctions/profileName";
import { ProfileType } from "../../enums/profileType";
import {
  setACSuperiorNotificationMenuItemName,
  setEmployeeNotificationMenuItemName,
  setOHSNotificationMenuItemName,
  setOperationalSupportNotificationMenuItemName,
  setPFSONotificationMenuItemName,
  setPFSOCrewDescentsMenuItemName,
  setPFSOCrewEntriesMenuItemName,
  setPFSOServicesAndDeliveriesMenuItemName,
  setReceptionNotificationMenuItemName,
  setSecurityNotificationMenuItemName,
  setShiftDirectorCrewDescentsMenuItemName,
  setShiftDirectorCrewEntriesMenuItemName,
  setShiftDirectorNotificationMenuItemName,
  setShiftDirectorServicesAndDeliveriesMenuItemName,
} from "../../reducers/notificationsSummary";
import { useTranslation } from "react-i18next";
import { NotificationsSummaryType } from "../../enums/notificationSummaryType";

const allowedProfilesType: ProfileType[] = [
  ProfileType.SUPERIOR_AC,
  ProfileType.EMPLOYEE,
  ProfileType.OHS,
  //ProfileType.OPERATIONAL_SUPPORT,
  ProfileType.PFSO,
  ProfileType.RECEPTION,
  ProfileType.SECURITY,
  ProfileType.SHIFT_DIRECTOR,
];

const useNotificationsSummary = () => {
  const authUser = useAppSelector(selectAuthUser);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  let [
    notificationsSummary,
    fetchingStateNotificationsSummary,
    fetchAgainNotificationsSummary,
  ] = useFetchAndSetGET<INotificationsSummary>({
    path: `${getProfileName(
      authUser.currentProfile?.type
    )}/notifications-summary`,
    startFetchOnInitial:
      authUser.currentProfile &&
      allowedProfilesType.includes(authUser.currentProfile.type)
        ? true
        : false,
  });

  useEffect(() => {
    if (
      !fetchingStateNotificationsSummary.isFetching &&
      !fetchingStateNotificationsSummary.isError &&
      fetchingStateNotificationsSummary.response?.status === 200 &&
      notificationsSummary
    ) {
      switch (authUser.currentProfile?.type) {
        case ProfileType.SUPERIOR_AC:
          {
            let tempNotificationsSummary = notificationsSummary.summaries.find(
              (el) => el.type === NotificationsSummaryType.NOTIFICATION
            );

            if (tempNotificationsSummary) {
              let counter =
                tempNotificationsSummary.introducedNumber +
                tempNotificationsSummary.preApprovedNumber +
                tempNotificationsSummary.toBeApprovedNumber;

              let newItemName = t("notifications");

              if (counter > 0) {
                newItemName = `${newItemName}(${counter})`;
              }

              dispatch(setACSuperiorNotificationMenuItemName(newItemName));
            }
          }
          break;

        case ProfileType.EMPLOYEE:
          {
            let tempNotificationsSummary = notificationsSummary.summaries.find(
              (el) => el.type === NotificationsSummaryType.NOTIFICATION
            );

            if (tempNotificationsSummary) {
              let counter = tempNotificationsSummary.introducedNumber;

              let newItemName = t("notifications");

              if (counter > 0) {
                newItemName = `${newItemName}(${counter})`;
              }

              dispatch(setEmployeeNotificationMenuItemName(newItemName));
            }
          }
          break;

        case ProfileType.OHS:
          {
            let tempNotificationsSummary = notificationsSummary.summaries.find(
              (el) => el.type === NotificationsSummaryType.NOTIFICATION
            );

            if (tempNotificationsSummary) {
              let counter =
                tempNotificationsSummary.introducedNumber +
                tempNotificationsSummary.preApprovedNumber +
                tempNotificationsSummary.toBeApprovedNumber;

              let newItemName = t("notifications");

              if (counter > 0) {
                newItemName = `${newItemName}(${counter})`;
              }

              dispatch(setOHSNotificationMenuItemName(newItemName));
            }
          }
          break;

        case ProfileType.OPERATIONAL_SUPPORT:
          {
            let tempNotificationsSummary = notificationsSummary.summaries.find(
              (el) => el.type === NotificationsSummaryType.NOTIFICATION
            );

            if (tempNotificationsSummary) {
              let counter =
                tempNotificationsSummary.introducedNumber +
                tempNotificationsSummary.preApprovedNumber +
                tempNotificationsSummary.toBeApprovedNumber;

              let newItemName = t("notifications");

              if (counter > 0) {
                newItemName = `${newItemName}(${counter})`;
              }

              dispatch(
                setOperationalSupportNotificationMenuItemName(newItemName)
              );
            }
          }
          break;

        case ProfileType.PFSO:
          {
            // Notifications
            let tempNotificationsSummary = notificationsSummary.summaries.find(
              (el) => el.type === NotificationsSummaryType.NOTIFICATION
            );

            if (tempNotificationsSummary) {
              let counter = tempNotificationsSummary.preApprovedNumber;

              let newItemName = t("notifications");

              if (counter > 0) {
                newItemName = `${newItemName}(${counter})`;
              }

              dispatch(setPFSONotificationMenuItemName(newItemName));
            }

            // DeliveriesAndServices
            let tempDeliveriesAndServicesSummary =
              notificationsSummary.summaries.find(
                (el) =>
                  el.type === NotificationsSummaryType.DELIVERIES_AND_SERVICES
              );

            if (tempDeliveriesAndServicesSummary) {
              let counter =
                tempDeliveriesAndServicesSummary.introducedNumber +
                tempDeliveriesAndServicesSummary.preApprovedNumber +
                tempDeliveriesAndServicesSummary.toBeApprovedNumber;

              let newItemName = t("services_and_deliveries");

              if (counter > 0) {
                newItemName = `${newItemName}(${counter})`;
              }

              dispatch(setPFSOServicesAndDeliveriesMenuItemName(newItemName));
            }

            // CrewEntries
            let tempCrewEntriesSummary = notificationsSummary.summaries.find(
              (el) => el.type === NotificationsSummaryType.CREW_ENTRY
            );

            if (tempCrewEntriesSummary) {
              let counter =
                tempCrewEntriesSummary.introducedNumber +
                tempCrewEntriesSummary.preApprovedNumber +
                tempCrewEntriesSummary.toBeApprovedNumber;

              let newItemName = t("crew_entry");

              if (counter > 0) {
                newItemName = `${newItemName}(${counter})`;
              }

              dispatch(setPFSOCrewEntriesMenuItemName(newItemName));
            }

            // CreweDescents
            let tempCrewDescentsSummary = notificationsSummary.summaries.find(
              (el) => el.type === NotificationsSummaryType.CREW_DESCENT
            );

            if (tempCrewDescentsSummary) {
              let counter =
                tempCrewDescentsSummary.introducedNumber +
                tempCrewDescentsSummary.preApprovedNumber +
                tempCrewDescentsSummary.toBeApprovedNumber;

              let newItemName = t("crew_descent");

              if (counter > 0) {
                newItemName = `${newItemName}(${counter})`;
              }

              dispatch(setPFSOCrewDescentsMenuItemName(newItemName));
            }
          }
          break;

        case ProfileType.RECEPTION:
          {
            let tempNotificationsSummary = notificationsSummary.summaries.find(
              (el) => el.type === NotificationsSummaryType.NOTIFICATION
            );

            if (tempNotificationsSummary) {
              let counter =
                tempNotificationsSummary.introducedNumber +
                tempNotificationsSummary.preApprovedNumber +
                tempNotificationsSummary.toBeApprovedNumber;

              let newItemName = t("list_of_notifications");

              if (counter > 0) {
                newItemName = `${newItemName}(${counter})`;
              }

              dispatch(setReceptionNotificationMenuItemName(newItemName));
            }
          }
          break;

        case ProfileType.SECURITY:
          {
            let tempNotificationsSummary = notificationsSummary.summaries.find(
              (el) => el.type === NotificationsSummaryType.NOTIFICATION
            );

            if (tempNotificationsSummary) {
              let counter =
                tempNotificationsSummary.introducedNumber +
                tempNotificationsSummary.preApprovedNumber +
                tempNotificationsSummary.toBeApprovedNumber;

              let newItemName = t("list_of_notifications");

              if (counter > 0) {
                newItemName = `${newItemName}(${counter})`;
              }

              dispatch(setSecurityNotificationMenuItemName(newItemName));
            }
          }
          break;

        case ProfileType.SHIFT_DIRECTOR:
          {
            // Notifications
            let tempNotificationsSummary = notificationsSummary.summaries.find(
              (el) => el.type === NotificationsSummaryType.NOTIFICATION
            );

            if (tempNotificationsSummary) {
              let counter = tempNotificationsSummary.preApprovedNumber;

              let newItemName = t("notifications");

              if (counter > 0) {
                newItemName = `${newItemName}(${counter})`;
              }

              dispatch(setShiftDirectorNotificationMenuItemName(newItemName));
            }

            // DeliveriesAndServices
            let tempDeliveriesAndServicesSummary =
              notificationsSummary.summaries.find(
                (el) =>
                  el.type === NotificationsSummaryType.DELIVERIES_AND_SERVICES
              );

            if (tempDeliveriesAndServicesSummary) {
              let counter =
                tempDeliveriesAndServicesSummary.introducedNumber +
                tempDeliveriesAndServicesSummary.preApprovedNumber +
                tempDeliveriesAndServicesSummary.toBeApprovedNumber;

              let newItemName = t("services_and_deliveries");

              if (counter > 0) {
                newItemName = `${newItemName}(${counter})`;
              }

              dispatch(
                setShiftDirectorServicesAndDeliveriesMenuItemName(newItemName)
              );
            }

            // CrewEntries
            let tempCrewEntriesSummary = notificationsSummary.summaries.find(
              (el) => el.type === NotificationsSummaryType.CREW_ENTRY
            );

            if (tempCrewEntriesSummary) {
              let counter =
                tempCrewEntriesSummary.introducedNumber +
                tempCrewEntriesSummary.preApprovedNumber +
                tempCrewEntriesSummary.toBeApprovedNumber;

              let newItemName = t("crew_entry");

              if (counter > 0) {
                newItemName = `${newItemName}(${counter})`;
              }

              dispatch(setShiftDirectorCrewEntriesMenuItemName(newItemName));
            }

            // CreweDescents
            let tempCrewDescentsSummary = notificationsSummary.summaries.find(
              (el) => el.type === NotificationsSummaryType.CREW_DESCENT
            );

            if (tempCrewDescentsSummary) {
              let counter =
                tempCrewDescentsSummary.introducedNumber +
                tempCrewDescentsSummary.preApprovedNumber +
                tempCrewDescentsSummary.toBeApprovedNumber;

              let newItemName = t("crew_descent");

              if (counter > 0) {
                newItemName = `${newItemName}(${counter})`;
              }

              dispatch(setShiftDirectorCrewDescentsMenuItemName(newItemName));
            }
          }
          break;
      }
    }
  }, [
    authUser.currentProfile,
    authUser.currentProfile?.type,
    dispatch,
    fetchingStateNotificationsSummary.isFetching,
    fetchingStateNotificationsSummary.isError,
    fetchingStateNotificationsSummary.response?.status,
    notificationsSummary,
    notificationsSummary?.summaries,
    t,
  ]);

  useEffect(() => {
    let timer = setInterval(() => {
      if (
        authUser.currentProfile &&
        allowedProfilesType.includes(authUser.currentProfile.type)
      ) {
        fetchAgainNotificationsSummary();
      }
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, [authUser.currentProfile, fetchAgainNotificationsSummary]);
};

export default useNotificationsSummary;
