import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LabelsManagerWorkingMode } from "../../../../../../../enums/labelsManagerWorkingMode";
import AdvancedSelect from "../../../../../../helpersComponents/AdvancedSelect/advancedSelect";
import AddEditLabel from "./AddEditLabel/addEditLabel";
import DeleteLabel from "./DeleteLabel/deleteLabel";
import useFetchOtherThanGET from "../../../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";

interface IProps {
  scheduleDaysLabels: ScheduleDayLabel[];
  initValueId: number;
  onLabelDeletedSuccessfully?: () => void;
  onLabelUpdatedSuccessfully?: (id: number) => void;
  onLabelAddedSuccessfully?: (id: number) => void;
  onChangeValue?: (label: ScheduleDayLabel) => void;
}

const LabelsManager = (props: IProps) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [labelIdToRemove, setLabelIdToRemove] = useState<false | number>(false);

  const [selectedLabel, setSelectedLabel] = useState<
    ScheduleDayLabel | undefined
  >(undefined);

  const [workingMode, setWorkingMode] = useState<LabelsManagerWorkingMode>(
    LabelsManagerWorkingMode.NORMAL_STATE
  );

  const [labelId, setLabelId] = useState<number>(-1);
  const [labelName, setLabelName] = useState<string>("");
  const [labelColor, setLabelColor] = useState<string>("");

  const onRemoveButtonClick = () => {
    if (selectedLabel) {
      setWorkingMode(LabelsManagerWorkingMode.REMOVE_STATE);
    }
  };

  const onEditButtonClick = () => {
    if (selectedLabel) {
      setLabelId(selectedLabel.id);
      setLabelName(selectedLabel.name);
      setLabelColor(selectedLabel.color);
      setWorkingMode(LabelsManagerWorkingMode.EDIT_STATE);
    }
  };

  const onAddButtonClick = () => {
    setLabelId(0);
    setLabelName("");
    setLabelColor("#FF0000");
    setWorkingMode(LabelsManagerWorkingMode.ADD_STATE);
  };

  const onChangeValue = (label: ScheduleDayLabel) => {
    setSelectedLabel(label);
    if (props.onChangeValue) {
      props.onChangeValue(label);
    }

    setWorkingMode(LabelsManagerWorkingMode.NORMAL_STATE);
  };

  useEffect(() => {
    if (props.scheduleDaysLabels && props.scheduleDaysLabels.length > 0) {
      setSelectedLabel(props.scheduleDaysLabels[0]);
      if (props.onChangeValue) {
        props.onChangeValue(props.scheduleDaysLabels[0]);
      }
      setIsLoading(false);
    }

    // eslint-disable-next-line
  }, [props.scheduleDaysLabels]);

  const [, deleteLabelfetchAgain] = useFetchOtherThanGET({
    path: `superior-time/schedule-days-labels/${labelIdToRemove}`,
    method: "DELETE",
    body: setLabelIdToRemove,
    setBody: setLabelIdToRemove,
    successCallback: props.onLabelDeletedSuccessfully,
  });

  useEffect(() => {
    if (labelIdToRemove !== false) {
      deleteLabelfetchAgain();
    }
  }, [labelIdToRemove, deleteLabelfetchAgain]);

  const [updateLabelRequestBody, setUpdateLabelRequestBody] = useState<
    false | any
  >(false);

  const [, updateLabelfetchAgain] = useFetchOtherThanGET({
    path: `superior-time/schedule-days-labels/${labelId}`,
    method: "PUT",
    body: updateLabelRequestBody,
    contentType: "application/json",
    setBody: setUpdateLabelRequestBody,
    successCallback: (response: UseFetchResponse) => {
      if (props.onLabelUpdatedSuccessfully) {
        props.onLabelUpdatedSuccessfully(labelId);
      }
    },
  });

  useEffect(() => {
    if (updateLabelRequestBody) {
      updateLabelfetchAgain();
    }
  }, [updateLabelRequestBody, updateLabelfetchAgain]);

  const [addLabelRequestBody, setAddLabelRequestBody] = useState<false | any>(
    false
  );

  const [, addLabelfetchAgain] = useFetchOtherThanGET({
    path: `superior-time/schedule-days-labels`,
    method: "POST",
    body: addLabelRequestBody,
    contentType: "application/json",
    setBody: setAddLabelRequestBody,
    successCallback: (response: UseFetchResponse) => {
      if (props.onLabelAddedSuccessfully) {
        let addedLabelId = 0;

        if (response.location) {
          let locationParts = response.location.split("/");
          addedLabelId = parseInt(locationParts[locationParts.length - 1]);
        }

        props.onLabelAddedSuccessfully(addedLabelId);
      }
    },
  });

  useEffect(() => {
    if (addLabelRequestBody) {
      addLabelfetchAgain();
    }
  }, [addLabelRequestBody, addLabelfetchAgain]);

  const onConfirmRemoveRemoveButtonClick = () => {
    if (selectedLabel) {
      if (selectedLabel.id > 0) {
        setLabelIdToRemove(selectedLabel.id);
      }
    }

    setWorkingMode(LabelsManagerWorkingMode.NORMAL_STATE);
  };

  const onConfirmRemoveCancelButtonClick = () => {
    setWorkingMode(LabelsManagerWorkingMode.NORMAL_STATE);
  };

  const onEditLabelApplyButtonClick = (label: ScheduleDayLabel) => {
    setUpdateLabelRequestBody(JSON.stringify(label));
  };

  const onEditLabelCancelButtonClick = () => {
    setWorkingMode(LabelsManagerWorkingMode.NORMAL_STATE);
  };

  const onAddLabelApplyButtonClick = (label: ScheduleDayLabel) => {
    setAddLabelRequestBody(JSON.stringify(label));
  };

  const onAddLabelCancelButtonClick = () => {
    setWorkingMode(LabelsManagerWorkingMode.NORMAL_STATE);
  };

  useEffect(() => {
    if (props.initValueId > 0) {
      if (props.scheduleDaysLabels && props.scheduleDaysLabels.length > 0) {
        let found = false;
        for (let i = 0; i < props.scheduleDaysLabels.length; i++) {
          if (props.scheduleDaysLabels[i].id === props.initValueId) {
            found = true;
            let tempLabel: ScheduleDayLabel = props.scheduleDaysLabels[i];
            setSelectedLabel(tempLabel);
            if (props.onChangeValue) {
              props.onChangeValue(tempLabel);
            }
            break;
          }
        }

        if (!found) {
          let tempLabel: ScheduleDayLabel = props.scheduleDaysLabels[0];
          setSelectedLabel(tempLabel);
          if (props.onChangeValue) {
            props.onChangeValue(tempLabel);
          }
        }
      }
    }
  }, [props, props.initValueId, props.scheduleDaysLabels]);

  return (
    <>
      {!isLoading && (
        <AdvancedSelect
          label={t("label")}
          dataSource={props.scheduleDaysLabels}
          value={selectedLabel}
          displayRemoveButton={true}
          displayEditButton={true}
          displayAddButton={true}
          onRemoveButtonClick={onRemoveButtonClick}
          onEditButtonClick={onEditButtonClick}
          onAddButtonClick={onAddButtonClick}
          onChangeValue={onChangeValue}
          displayMember={(obj: ScheduleDayLabel) => obj.name}
        />
      )}

      {workingMode === LabelsManagerWorkingMode.REMOVE_STATE && (
        <DeleteLabel
          onRemoveButtonClick={onConfirmRemoveRemoveButtonClick}
          onCancelButtonClick={onConfirmRemoveCancelButtonClick}
        />
      )}
      {workingMode === LabelsManagerWorkingMode.EDIT_STATE && (
        <AddEditLabel
          labelId={labelId}
          labelName={labelName}
          labelColor={labelColor}
          onApplyButtonClick={onEditLabelApplyButtonClick}
          onCancelButtonClick={onEditLabelCancelButtonClick}
        />
      )}
      {workingMode === LabelsManagerWorkingMode.ADD_STATE && (
        <AddEditLabel
          labelId={labelId}
          labelName={labelName}
          labelColor={labelColor}
          onApplyButtonClick={onAddLabelApplyButtonClick}
          onCancelButtonClick={onAddLabelCancelButtonClick}
        />
      )}
    </>
  );
};

export default LabelsManager;
