import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import SwitchStyled from "../../../helpersComponents/MaterialUi/Switch/StyledSwitch";
import TableComponentMemo from "../../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import PopupProductionLines from "./PopupProductionLines";

const ProductionLinesContent = () => {
  const { t } = useTranslation();
  const [isInactiveSwitchState, setIsInactiveSwitchState] = useState(false);

  const columns = [
    { name: "name", title: t("name") },
    { name: "readerName", title: t("reader") },
    { name: "lineName", title: t("line_name") },
    { name: "createdAt", title: t("data_created"), type: "date" },
  ];

  const modifyResponseCallback = useCallback(
    (responseData: IReaderLine[]) => {
      const modified: IReaderLine[] = [];
      responseData.forEach((row) => {
        if (row.isActive === true && isInactiveSwitchState) return;
        else if (row.isActive === false && !isInactiveSwitchState) return;

        modified.push(row);
      });

      return modified;
    },
    [isInactiveSwitchState]
  );

  const [readersLines, fetchingStateReadersLines, fetchAgainReadersLines] =
    useFetchAndSetGET<IReaderLine[]>({
      path: "superior-time/readers-lines",
      modifyResponseCallback: modifyResponseCallback,
    });

  const IsActiveSwitch = (
    <div className="SwtichActive">
      {t("active")}
      <SwitchStyled
        checked={isInactiveSwitchState}
        onChange={() => setIsInactiveSwitchState(!isInactiveSwitchState)}
        name="checkedA"
      />
      {t("inactive")}
    </div>
  );

  const [readerLineToRemoveOrRestore, setReaderLineToRemoveOrRestore] =
    useState<{
      id: false | number;
      method: "DELETE" | "PATCH";
      body?: [
        {
          op: "replace";
          path: "/isActive";
          value: 1;
        }
      ];
    }>({
      id: false,
      method: "DELETE",
    });

  const [
    readerLineRemoveOrRestoreFetchingState,
    readerLineRemoveOrRestoreFetchAgain,
  ] = useFetchOtherThanGET({
    path: `superior-time/readers-lines/${readerLineToRemoveOrRestore?.id}`,
    method: readerLineToRemoveOrRestore.method,
    body:
      readerLineToRemoveOrRestore.method === "PATCH"
        ? JSON.stringify(readerLineToRemoveOrRestore.body)
        : null,
    setBody: setReaderLineToRemoveOrRestore,
    contentType: "application/json",
    successCallback: fetchAgainReadersLines,
  });

  useEffect(() => {
    if (readerLineToRemoveOrRestore.id) {
      readerLineRemoveOrRestoreFetchAgain();
    }
  }, [readerLineToRemoveOrRestore.id, readerLineRemoveOrRestoreFetchAgain]);

  return (
    <div>
      <div>
        <TopBanner pathName={t("production_lines")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <TableComponentMemo
            columns={columns}
            rows={readersLines}
            layoutSettingName={"timeSuperiorReadersLine"}
            toolbarComponent={IsActiveSwitch}
            fetchingState={{
              isFetching:
                fetchingStateReadersLines.isFetching ||
                readersLines === null ||
                readerLineRemoveOrRestoreFetchingState.isFetching,
              isError:
                fetchingStateReadersLines.isError ||
                readerLineRemoveOrRestoreFetchingState.isError,
              fetchAgain: fetchAgainReadersLines,
            }}
            actionsColumnUpdated={{
              popup: PopupProductionLines,
              successCallbackPopup: fetchAgainReadersLines,
              addButton: {
                ifShow: true,
              },
              editButton: {
                ifShow: isInactiveSwitchState ? false : true,
                name: t("edit"),
              },
              removeButton: {
                ifShow: true,
                name: isInactiveSwitchState ? t("restore") : t("remove"),
                onClick: (row) => {
                  if (row?.isActive) {
                    setReaderLineToRemoveOrRestore({
                      id: row.id,
                      method: "DELETE",
                      body: undefined,
                    });
                  } else {
                    setReaderLineToRemoveOrRestore({
                      id: row.id,
                      method: "PATCH",
                      body: [
                        {
                          op: "replace",
                          path: "/isActive",
                          value: 1,
                        },
                      ],
                    });
                  }
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductionLinesContent;
