import { useTranslation } from "react-i18next";
import CheckboxWithLabelFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/CheckboxWithLabelField/checkboxWithLabelField";
import TextFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import UploadFileFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/UploadFileField/uploadFileField";
import styles from "./buildingFloors.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const BuildingFloorItem: React.FC<any> = ({
  key,
  fields,
  index,
  control,
  register,
  setValue,
  remove,
  errors,
  getValues,
  watch,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.buildingFloorMainContainer}>
      <div className={styles.buildingFloorItemTitle}>
        <p>
          {t("floor")}
          <FontAwesomeIcon
            className="faTrash"
            icon={faTimesCircle}
            onClick={() => {
              remove(index);
            }}
          />
        </p>
      </div>
      <div className={styles.buildingFloorItem}>
        <div className={styles.inputsRow}>
          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("name")}</div>
            <div className={styles.inputFieldInRow}>
              <TextFieldReactHookForm
                id={`floors.${index}.name`}
                name={`floors.${index}.name`}
                control={control}
                label={t("floor_name")}
                required={true}
              />
            </div>
          </div>

          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("floor_level")}</div>
            <div className={styles.inputFieldInRow}>
              <TextFieldReactHookForm
                id={`floors.${index}.number`}
                name={`floors.${index}.number`}
                type="number"
                control={control}
                label={t("floor_level")}
                required={true}
              />
            </div>
          </div>
        </div>

        <div className={styles.inputsRow}>
          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("is_default")}</div>
            <div className={styles.inputFieldInRow}>
              <CheckboxWithLabelFieldReactHookForm
                id={`floors.${index}.isDefault`}
                name={`floors.${index}.isDefault`}
                control={control}
                value={getValues(`floors.${index}.isDefault`)}
                setValue={setValue}
                ifCircle={true}
                label={<span></span>}
                onChangeExtra={(event, value) => {
                  if (value) {
                    fields.forEach((field, i) => {
                      if (i !== index) {
                        setValue(`floors.${i}.isDefault`, false);
                      }
                    });
                  }
                }}
              />
            </div>
          </div>

          <div className={styles.inputWithTitleInRow}>
            <div className={styles.inputTitleInRow}>{t("map")}</div>
            <div className={styles.inputFieldInRow}>
              <UploadFileFieldReactHookForm
                id={`floors.${index}.map`}
                name={`floors.${index}.map`}
                register={register}
                control={control}
                setValue={setValue}
                getValues={getValues}
                label={t("map")}
                required={true}
                accept=".svg, png, .jpeg, .jpg, .bmp"
                watch={watch}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingFloorItem;
