import { useState, useEffect, useCallback } from "react";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser, selectLanguage } from "../../../reducers/session";

const useImageLoader = (imageUrls) => {
  const authUser = useAppSelector(selectAuthUser);
  const language = useAppSelector(selectLanguage);

  const [imagesData, setImagesData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  const clearImagesData = useCallback(() => {
    setImagesData([]);
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const fetchPromises = imageUrls.map(async (imageUrl) => {
          const response = await fetch(
            window.globalConfig.API_URL + "/api/" + imageUrl,
            {
              method: "GET",
              credentials: window.globalConfig?.LOG_BY_WINDOWS_DOMAIN
                ? "include"
                : "omit",
              headers: {
                Authorization: "bearer " + authUser.token,
                "Accept-Language": language,
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            throw new Error(`Błąd pobierania obrazka: ${response.status}`);
          }

          const blob = await response.blob();
          const data = URL.createObjectURL(blob);
          const size = blob.size;

          return { url: imageUrl, data, size };
        });

        const imagesDataArray = await Promise.all(fetchPromises);
        setImagesData(imagesDataArray);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    if (imageUrls.length > 0) {
      fetchImages();
    } else {
      setLoading(false);
    }
  }, [imageUrls, authUser.token, language]);

  return { imagesData, clearImagesData, loading, error };
};

export default useImageLoader;
