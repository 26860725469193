import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import CollaboratorsDialogContent from "./collaboratorsDialogContent";
import projectCreatePhotosArray from "./projectCreatePhotosArray";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";

interface Props {
  setIfFetchAgain: React.Dispatch<boolean>;
  setIsDialogOpen: React.Dispatch<boolean>;
  isDialogOpen: boolean;
  project: any;
}

export default function ProjectCollaboratorsContent({
  setIfFetchAgain,
  setIsDialogOpen,
  isDialogOpen,
  project,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [ifFetch, setIfFetch] = useState<boolean>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const [allPossibleCollaborators, setAllPossibleCollaborators] =
    useState<any>(false);
  const timeWorkersResponse = useFetchGet(
    `worker-time/${authUserId}/time-workers`,
    ifFetch
  );

  useEffect(() => {
    if (isDialogOpen) {
      setIsLoading(true);
      setIfFetch(true);
    } else {
      setIsLoading(false);
      setIfFetch(false);
    }
  }, [isDialogOpen]);

  useEffect(() => {
    if (timeWorkersResponse.error !== null) {
      setIsError(true);
      setIfFetch(false);
      return;
    } else if (timeWorkersResponse.data !== null) {
      setAllPossibleCollaborators(timeWorkersResponse.data);
      setIsLoading(false);
      setIfFetch(false);
    }
  }, [project, timeWorkersResponse, t]);

  return (
    <div>
      <Dialog
        maxWidth={"xl"}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogTitle className="dialogTitleGlobal">
          <div className="titlePopup">{t("assign_workers")}</div>
        </DialogTitle>
        <LoadingWrapper
          isLodadingProgress={isLoading}
          isError={isError}
          setIfFetchAgain={setIfFetch}
          setIsError={setIsError}
          bigSize={true}
        >
          <CollaboratorsDialogContent
            projectId={project.id}
            setIsDialogOpen={setIsDialogOpen}
            setIfFetchAgain={setIfFetchAgain}
            currentAssignedCollaborators={project.collaborators}
            photos={projectCreatePhotosArray(project.collaborators)}
            allPossibleCollaborators={allPossibleCollaborators}
          />
        </LoadingWrapper>
      </Dialog>
    </div>
  );
}
