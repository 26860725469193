import { Tab, Tabs, Tooltip } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import { colorNameDictionary } from "../../ProfilesRouting/profilesDictionaries";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import formFieldsModel from "./FormModel/formFieldsModel";
import formInitialValues from "./FormModel/formInitialValues";
import validationSchema from "./FormModel/validationSchema";
import PasswordPoliciesForm from "./Forms/PasswordPoliciesForm";
import UserDataForm from "./Forms/UserDataForm";
import UserPermissionsForm from "./Forms/UserPermissionsForm";
import styles from "./styles.module.scss";

const CenteredTabs = withStyles({
  flexContainer: {
    justifyContent: "space-around",
    alignItems: "center",
  },
})(Tabs);

interface Props {
  onSubmit: (values) => void;
  rowId?: number;
  apiPaths: ApiPaths;
}

const FormTabs = ({ onSubmit, rowId, apiPaths }: Props) => {
  function TabPanel(props: {
    children?: React.ReactNode;
    index: number;
    value: number;
  }) {
    const { children, value, index, ...other } = props;

    return (
      <div
        className={styles.form}
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  }

  const [valueTab, setValueTab] = useState<number>(0);
  const handleChangeValueTab = (
    event: React.ChangeEvent<any>,
    newValue: number
  ) => {
    setValueTab(newValue);
  };

  const { t } = useTranslation();
  const authUserType = useAppSelector(selectAuthUser).currentProfile?.type;

  const { formFields } = formFieldsModel;

  //Fetching from API
  const {
    pathUsers,
    pathUserPermissions,
    pathDepartments,
    pathGroups,
    pathPolicies,
  } = apiPaths;

  const [
    userPermissionsList,
    fetchingStateUserPermissionsList,
    fetchAgainUserPermissionsList,
  ] = useFetchAndSetGET({
    path: pathUserPermissions,
  });

  const [
    departmentsList,
    fetchingStateDepartmentsList,
    fetchAgainDepartmentsList,
  ] = useFetchAndSetGET({
    path: pathDepartments,
  });

  const [groupsList, fetchingStateGroupsList, fetchAgainGoupsList] =
    useFetchAndSetGET({
      path: pathGroups,
    });

  const [policiesList, fetchingStatePoliciesList, fetchAgainPoliciesList] =
    useFetchAndSetGET({
      path: pathPolicies,
    });

  const [acUserFetched, fetchingStateACUserFetched, fetchAgainACUserFetched] =
    useFetchAndSetGET({
      path: `${pathUsers}/${rowId}`,
      startFetchOnInitial: rowId ? true : false,
    });

  const validationSchemaObj = validationSchema(t);

  const isUserDataFormErrors = useCallback(
    (formikProps) => {
      if (
        formikProps.errors[formFields.password.name] ||
        formikProps.errors[formFields.repeatPassword.name] ||
        formikProps.errors[formFields.login.name]
      )
        return true;

      return false;
    },
    [
      formFields.login.name,
      formFields.password.name,
      formFields.repeatPassword.name,
    ]
  );

  const isPoliciesFormErros = useCallback(
    (formikProps) => {
      if (formikProps.errors[formFields.passwordPoliciesArray.name])
        return true;

      return false;
    },
    [formFields.passwordPoliciesArray.name]
  );

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={
          fetchingStateUserPermissionsList.isFetching ||
          fetchingStateDepartmentsList.isFetching ||
          fetchingStateGroupsList.isFetching ||
          fetchingStatePoliciesList.isFetching ||
          fetchingStateACUserFetched.isFetching
        }
        isError={
          fetchingStateUserPermissionsList.isError ||
          fetchingStateDepartmentsList.isError ||
          fetchingStateGroupsList.isError ||
          fetchingStatePoliciesList.isError ||
          fetchingStateACUserFetched.isError
        }
        setIfFetchAgain={() => {
          if (fetchingStateACUserFetched.isError) {
            fetchAgainACUserFetched();
          }
          if (fetchingStateUserPermissionsList.isError) {
            fetchAgainUserPermissionsList();
          }
          if (fetchingStateDepartmentsList.isError) {
            fetchAgainDepartmentsList();
          }
          if (fetchingStateGroupsList.isError) {
            fetchAgainGoupsList();
          }
          if (fetchingStatePoliciesList.isError) {
            fetchAgainPoliciesList();
          }
        }}
      >
        {(acUserFetched || rowId === undefined) &&
          departmentsList &&
          groupsList &&
          policiesList &&
          fetchingStateUserPermissionsList && (
            <Formik
              initialValues={formInitialValues(
                acUserFetched ? acUserFetched : null
              )}
              validationSchema={validationSchemaObj}
              onSubmit={(values) => {
                onSubmit(values);
              }}
            >
              {(formikProps) => {
                return (
                  <Form noValidate id="submitForm">
                    <CenteredTabs
                      value={valueTab}
                      onChange={handleChangeValueTab}
                      variant="fullWidth"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: `${colorNameDictionary[authUserType]}`,
                        },
                      }}
                    >
                      <Tab
                        label={t("user_data")}
                        value={0}
                        icon={
                          isUserDataFormErrors(formikProps) &&
                          valueTab !== 0 ? (
                            <Tooltip title={t("invalid_data")!} placement="top">
                              <Error color="error" />
                            </Tooltip>
                          ) : (
                            <></>
                          )
                        }
                      />

                      <Tab label={t("permissions_for_web_page")} value={1} />

                      <Tab
                        label={t("password_policy")}
                        value={2}
                        icon={
                          isPoliciesFormErros(formikProps) && valueTab !== 2 ? (
                            <Tooltip title={t("invalid_data")!} placement="top">
                              <Error color="error" />
                            </Tooltip>
                          ) : (
                            <></>
                          )
                        }
                      />
                    </CenteredTabs>

                    <TabPanel value={valueTab} index={0}>
                      <UserDataForm
                        formFields={formFields}
                        formikValues={formikProps.values}
                        rowId={rowId}
                        userPermissionsList={userPermissionsList}
                      />
                    </TabPanel>

                    <TabPanel value={valueTab} index={1}>
                      <UserPermissionsForm
                        formFields={formFields}
                        departmentsList={departmentsList}
                        groupsList={groupsList}
                      />
                    </TabPanel>

                    <TabPanel value={valueTab} index={2}>
                      <PasswordPoliciesForm
                        formFields={formFields}
                        formikValues={formikProps.values}
                        formikErrors={formikProps.errors}
                        policiesList={policiesList}
                      />
                    </TabPanel>
                  </Form>
                );
              }}
            </Formik>
          )}
      </LoadingWrapper>
    </>
  );
};

export default FormTabs;
