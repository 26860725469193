import React from "react";
import { useTranslation } from "react-i18next";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import FormatRegistrationNumber from "../../../helpersComponents/FormattersComponent/FormatRegistrationNumber";
import AccordionStyled from "../../../helpersComponents/MaterialUi/Accordion/AccordionStyled";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../../../helpersComponents/RowDetailsHelpers";

const RowDetailsServicesAndDeliveries = ({
  row,
}: {
  row: INotificationGuestsInPorts;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <RowDetailsContainer>
        {/* OSOBA */}
        <AccordionStyled title={t("person")} defaultExpanded={true}>
          <>
            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("person")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.guestFirstName && row.guestLastName
                    ? `${row.guestFirstName} ${row.guestLastName}`
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("email")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.guestEmail ? row.guestEmail : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("date_of_birth")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.guestDateOfBirth
                    ? toLocaleStringCustom({
                        date: new Date(row.guestDateOfBirth),
                        t,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("training_expiry_date")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.guestTrainingExpiryDate
                    ? toLocaleStringCustom({
                        date: new Date(row.guestTrainingExpiryDate),
                        t,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>
          </>
        </AccordionStyled>

        {/* INFORMACJE */}
        <AccordionStyled title={t("information")} defaultExpanded={true}>
          <>
            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("date")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.dateFrom
                    ? toLocaleStringCustom({
                        date: new Date(row.dateFrom),
                        t,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("type_of_visit")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.typeOfVisitName ? row.typeOfVisitName : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("date_to2")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.dateTo
                    ? toLocaleStringCustom({
                        date: new Date(row.dateTo),
                        t,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>&nbsp;</RowDetailsTitle>
                <RowDetailsValue>&nbsp;</RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("notification_number")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.notificationNumber ? row.notificationNumber : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("ship")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.shipName ? row.shipName : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("confirmation_pfso")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.pfsoFullName ? row.pfsoFullName + ", " : ""}
                  {row.pfsoDateConfirmation
                    ? toLocaleStringCustom({
                        date: row.pfsoDateConfirmation,
                        t,
                        ifShowHours: true,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>
                  {t("confirmation_shift_director")}:
                </RowDetailsTitle>
                <RowDetailsValue>
                  {row?.shiftDirectorFullName
                    ? row.shiftDirectorFullName + ", "
                    : ""}
                  {row.shiftDirectorDateConfirmation
                    ? toLocaleStringCustom({
                        date: row.shiftDirectorDateConfirmation,
                        t,
                        ifShowHours: true,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("company")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.companyName ? row.companyName : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("plate_number")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.registrationNumber ? (
                    <FormatRegistrationNumber
                      registrationNumber={row.registrationNumber}
                    />
                  ) : (
                    "-"
                  )}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("comments")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.comments ? row.comments : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>
          </>
        </AccordionStyled>
      </RowDetailsContainer>
    </div>
  );
};

export default RowDetailsServicesAndDeliveries;
