import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/styles";

const CustomTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "rgb(255, 255, 255);",
    boxShadow: "1px -1px 7px -4px #000000;",
    maxWidth: 500,
    padding: "10px 20px",
    fontSize: 20,
  },
})(Tooltip);

const ErrorTooltip = ({
  children,
  tooltipBody,
}: {
  children: JSX.Element[] | JSX.Element;
  tooltipBody: JSX.Element;
}) => {
  return (
    <div className="tooltipEvents">
      <CustomTooltip title={tooltipBody}>
        <div>{children}</div>
      </CustomTooltip>
    </div>
  );
};

export default ErrorTooltip;
