import { Form, Formik } from "formik";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { TypeOfVisit } from "../../../Constants/typeOfVisitInPorts";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { AutocompleteFormik, TextFieldFormik } from "../FormikInputs";
import CheckboxWithLabelFormik from "../FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../PopupHelpers";
import getValidationSchema from "./getValidationSchema";
import styles from "./styles.module.scss";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";
import { ProfileType } from "../../../enums/profileType";

const PopupDialogContent = ({
  onSubmit,
  rowId,
  typeOfVisitId,
  permissionZoneId,
  permissionZonesPatchApi,
  pathApiNotifications,
}: {
  onSubmit: (values: PopupEntryWorkingConditionsFormValues) => void;
  rowId?: number;
  typeOfVisitId?: number;
  permissionZoneId?: number | null;
  permissionZonesPatchApi?: string;
  pathApiNotifications?: string;
}) => {
  const { t } = useTranslation();

  const [
    notificationGuest,
    fetchingStateNotificationGuest,
    fetchAgainNotificationGuest,
  ] = useFetchAndSetGET<INotificationInPortsGuest>({
    path: `${pathApiNotifications}/${rowId}`,
    startFetchOnInitial:
      rowId !== undefined && pathApiNotifications ? true : false,
  });

  const [
    permissionZones,
    fetchingStatePermissionZones,
    fetchAgainPermissionZones,
  ] = useFetchAndSetGET<PermissionZone[]>({
    path: permissionZonesPatchApi!,
    startFetchOnInitial:
      permissionZoneId === null && permissionZonesPatchApi ? true : false,
  });
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;
  const initState: PopupEntryWorkingConditionsFormValues = {
    isWorkPermitRequiredTrue: notificationGuest?.notification
      .isWorkPermitRequired
      ? true
      : false,
    isWorkPermitRequiredFalse:
      notificationGuest?.notification?.isWorkPermitRequired === false
        ? true
        : false,
    workPermitNumber: notificationGuest?.notification?.workPermitNumber
      ? notificationGuest?.notification.workPermitNumber
      : "",
    workArea: notificationGuest?.notification?.workArea
      ? notificationGuest.notification.workArea
      : "",
    isWorkParticularlyDangerousTrue: notificationGuest?.notification
      ?.isWorkParticularlyDangerous
      ? true
      : false,
    isWorkParticularlyDangerousFalse:
      notificationGuest?.notification?.isWorkParticularlyDangerous === false
        ? true
        : false,
    workParticularlyDangerousComments: notificationGuest?.notification
      ?.workParticularlyDangerousComments
      ? notificationGuest?.notification.workParticularlyDangerousComments
      : "",
    permissionZone: notificationGuest?.notification?.permissionZoneId
      ? permissionZones?.find(
          (pz) => pz.id === notificationGuest?.notification.permissionZoneId
        )
      : null,
    bh3: false,
  };

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={
          fetchingStateNotificationGuest.isFetching ||
          fetchingStatePermissionZones.isFetching
        }
        isError={
          fetchingStateNotificationGuest.isError ||
          fetchingStatePermissionZones.isError
        }
        setIfFetchAgain={() => {
          if (fetchingStateNotificationGuest.isError) {
            fetchAgainNotificationGuest();
          }
          if (fetchingStatePermissionZones.isError) {
            fetchAgainPermissionZones();
          }
        }}
      >
        {/* {(rowId === undefined || notificationGuest) && ( */}
        <DialogContentStyled titleWidth={200}>
          <Formik
            initialValues={initState}
            onSubmit={(values) => {
              onSubmit(values);
            }}
            validationSchema={getValidationSchema(
              typeOfVisitId,
              t,
              permissionZoneId
            )}
          >
            {({ values, setFieldValue, touched, errors }) => (
              <Form noValidate id="submitForm">
                {typeOfVisitId === TypeOfVisit.COMPLETION_OF_THE_WORK && (
                  <>
                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle>{t("is_work_permit_required")}:</RowTitle>
                        <RowInput>
                          <div>
                            <CheckboxWithLabelFormik
                              name={"isWorkPermitRequiredTrue"}
                              className={
                                (touched.isWorkPermitRequiredTrue ||
                                  touched.isWorkPermitRequiredFalse) &&
                                values.isWorkPermitRequiredTrue === false &&
                                values.isWorkPermitRequiredFalse === false
                                  ? styles.error
                                  : ""
                              }
                              onChangeExtra={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => {
                                if (
                                  event.target.checked &&
                                  values.isWorkPermitRequiredFalse
                                ) {
                                  setFieldValue(
                                    "isWorkPermitRequiredFalse",
                                    false
                                  );
                                }
                              }}
                              ifCircle={true}
                              label={
                                <span className={styles.formControlLabel}>
                                  {t("yes")}
                                </span>
                              }
                            />
                            <CheckboxWithLabelFormik
                              name={"isWorkPermitRequiredFalse"}
                              className={
                                (touched.isWorkPermitRequiredTrue ||
                                  touched.isWorkPermitRequiredFalse) &&
                                values.isWorkPermitRequiredTrue === false &&
                                values.isWorkPermitRequiredFalse === false
                                  ? styles.error
                                  : ""
                              }
                              onChangeExtra={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => {
                                if (
                                  event.target.checked &&
                                  values.isWorkPermitRequiredTrue
                                ) {
                                  setFieldValue(
                                    "isWorkPermitRequiredTrue",
                                    false
                                  );
                                }
                              }}
                              ifCircle={true}
                              label={
                                <span className={styles.formControlLabel}>
                                  {t("no")}
                                </span>
                              }
                            />
                          </div>
                          {errors.isWorkPermitRequiredFalse !== undefined &&
                            (touched.isWorkPermitRequiredTrue ||
                              touched.isWorkPermitRequiredFalse) && (
                              <div className={styles.errorMessage}>
                                {t("field_required")}
                              </div>
                            )}
                        </RowInput>
                      </Row>
                      <Row>
                        <RowTitle>
                          {t("is_work_particularly_dangerous")}:
                        </RowTitle>
                        <RowInput>
                          <div>
                            <CheckboxWithLabelFormik
                              name={"isWorkParticularlyDangerousTrue"}
                              className={
                                (touched.isWorkParticularlyDangerousTrue ||
                                  touched.isWorkParticularlyDangerousFalse) &&
                                values.isWorkParticularlyDangerousTrue ===
                                  false &&
                                values.isWorkParticularlyDangerousFalse ===
                                  false
                                  ? styles.error
                                  : ""
                              }
                              onChangeExtra={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => {
                                if (
                                  event.target.checked &&
                                  values.isWorkParticularlyDangerousFalse
                                ) {
                                  setFieldValue(
                                    "isWorkParticularlyDangerousFalse",
                                    false
                                  );
                                }
                              }}
                              ifCircle={true}
                              label={
                                <span className={styles.formControlLabel}>
                                  {t("yes")}
                                </span>
                              }
                            />
                            <CheckboxWithLabelFormik
                              name={"isWorkParticularlyDangerousFalse"}
                              className={
                                (touched.isWorkParticularlyDangerousTrue ||
                                  touched.isWorkParticularlyDangerousFalse) &&
                                values.isWorkParticularlyDangerousTrue ===
                                  false &&
                                values.isWorkParticularlyDangerousFalse ===
                                  false
                                  ? styles.error
                                  : ""
                              }
                              onChangeExtra={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => {
                                if (
                                  event.target.checked &&
                                  values.isWorkParticularlyDangerousTrue
                                ) {
                                  setFieldValue(
                                    "isWorkParticularlyDangerousTrue",
                                    false
                                  );
                                }
                              }}
                              ifCircle={true}
                              label={
                                <span className={styles.formControlLabel}>
                                  {t("no")}
                                </span>
                              }
                            />
                          </div>
                          {errors.isWorkParticularlyDangerousFalse !==
                            undefined &&
                            (touched.isWorkParticularlyDangerousTrue ||
                              touched.isWorkParticularlyDangerousFalse) && (
                              <div className={styles.errorMessage}>
                                {t("field_required")}
                              </div>
                            )}
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>

                    {values.isWorkPermitRequiredTrue && (
                      <TwoColumnsRowsContainer>
                        <Row>
                          <RowTitle>{t("work_permit_number")}:</RowTitle>
                          <RowInput>
                            <TextFieldFormik
                              label={t("work_permit_number")}
                              name="workPermitNumber"
                              type="text"
                              ifToUpperCaseShipsAndPorts={true}
                            />
                          </RowInput>
                        </Row>
                        <Row>
                          <RowTitle>{t("work_area")}:</RowTitle>
                          <RowInput>
                            <TextFieldFormik
                              label={t("work_area")}
                              name="workArea"
                              type="text"
                              ifToUpperCaseShipsAndPorts={true}
                              required={values.isWorkParticularlyDangerousTrue}
                              multiline={true}
                            />
                          </RowInput>
                        </Row>
                      </TwoColumnsRowsContainer>
                    )}

                    {values.isWorkParticularlyDangerousTrue && (
                      <TwoColumnsRowsContainer>
                        <Row>
                          <RowTitle>
                            {t("work_particularly_dangerous_comments")}:
                          </RowTitle>
                          <RowInput>
                            <TextFieldFormik
                              label={t("work_particularly_dangerous_comments")}
                              name="workParticularlyDangerousComments"
                              type="text"
                              ifToUpperCaseShipsAndPorts={true}
                              multiline={true}
                              rows={3}
                            />
                          </RowInput>
                        </Row>
                      </TwoColumnsRowsContainer>
                    )}
                  </>
                )}
                {permissionZoneId === null && (
                  <Row>
                    <RowTitle>{t("permission_zone")}:</RowTitle>
                    <RowInput>
                      <AutocompleteFormik
                        options={permissionZones ? permissionZones : []}
                        getOptionLabel={(option: PermissionZone) => {
                          return option.name;
                        }}
                        width="100%"
                        getOptionSelected={(
                          option: PermissionZone,
                          value: PermissionZone
                        ) => {
                          return option.id === value.id;
                        }}
                        label={t("select_permission_zone")}
                        name="permissionZone"
                        required={true}
                      />
                    </RowInput>
                  </Row>
                )}
                {profileType === ProfileType.EMPLOYEE && (
                  <Row>
                    <RowInput>
                      <CheckboxWithLabelFormik
                        name={"bh3"}
                        ifCircle={true}
                        label={t("BH3")}
                      />
                    </RowInput>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
        </DialogContentStyled>
        {/* )} */}
      </LoadingWrapper>
    </>
  );
};

export default PopupDialogContent;
