import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AccordionStyled from "../../../helpersComponents/MaterialUi/Accordion/AccordionStyled";
import ButtonStyled from "../../../helpersComponents/Button/button";
import styles from "./TimeInOrganizationalUnitsReportSettings.module.scss";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import AssigningReadersToOrganizationalUnits from "./AssigningReadersToOrganizationalUnits/AssigningReadersToOrganizationalUnits";
import { useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const TimeInOrganizationalUnitsReportSettings: React.FC<any> = () => {
  const { t } = useTranslation();

  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );

  let isAdminProfile = false;

  let foundProfile = authUser.tokenDecoded.profiles.find(
    (el) => el.permissionId && (el.permissionId === 1 || el.permissionId === 2)
  );

  if (foundProfile) {
    isAdminProfile = true;
  }

  const [
    timeInOrganizationalUnitsReportSettingsAccordionExpanded,
    setTimeInOrganizationalUnitsReportSettingsAccordionExpanded,
  ] = useState<boolean>(false);

  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  // const [selectedOrganizationalUnits, setSelectedOrganizationalUnits] = useState<number[]>([]);

  const [
    organizationalUnits,
    fetchingStateOrganizationalUnits,
    fetchAgainOrganizationalUnits,
  ] = useFetchAndSetGET<IOrganizationalUnit[]>({
    path: `superior-time/organizational-units`,
    startFetchOnInitial: false,
  });

  const [readers, fetchingStateReaders, fetchAgainReaders] = useFetchAndSetGET<
    IReader[]
  >({
    path: `superior-time/readers`,
    startFetchOnInitial: false,
  });

  const [
    timeInOrganizationalUnitsReportSettings,
    fetchingStateTimeInOrganizationalUnitsReportSettings,
    fetchAgainTimeInOrganizationalUnitsReportSettings,
  ] = useFetchAndSetGET<TimeInOrganizationalUnitsReportSettings>({
    path: `superior-time/time-in-organizational-units-report-settings`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (timeInOrganizationalUnitsReportSettingsAccordionExpanded) {
      fetchAgainOrganizationalUnits();
      fetchAgainReaders();
      fetchAgainTimeInOrganizationalUnitsReportSettings();
    }
  }, [
    timeInOrganizationalUnitsReportSettingsAccordionExpanded,
    fetchAgainOrganizationalUnits,
    fetchAgainReaders,
    fetchAgainTimeInOrganizationalUnitsReportSettings,
  ]);

  useEffect(() => {
    if (
      !fetchingStateOrganizationalUnits.isFetching &&
      !fetchingStateOrganizationalUnits.isError &&
      fetchingStateOrganizationalUnits.response?.status === 200 &&
      !fetchingStateReaders.isFetching &&
      !fetchingStateReaders.isError &&
      fetchingStateReaders.response?.status === 200 &&
      !fetchingStateTimeInOrganizationalUnitsReportSettings.isFetching &&
      !fetchingStateTimeInOrganizationalUnitsReportSettings.isError &&
      fetchingStateTimeInOrganizationalUnitsReportSettings.response?.status ===
        200 &&
      !dataLoaded
    ) {
      setDataLoaded(true);
    }
  }, [
    fetchingStateOrganizationalUnits,
    fetchingStateReaders,
    fetchingStateTimeInOrganizationalUnitsReportSettings,
    dataLoaded,
  ]);

  const [settingsBody, setSettingsBody] = useState<false | any>(false);

  const [, saveSettingsDataFetchAgain] = useFetchOtherThanGET({
    path: "superior-time/time-in-organizational-units-report-settings",
    method: "POST",
    body: settingsBody,
    contentType: "application/json",
    setBody: setSettingsBody,
    forceSnackbar: true,
  });

  useEffect(() => {
    if (settingsBody) {
      saveSettingsDataFetchAgain();
    }
  }, [settingsBody, saveSettingsDataFetchAgain]);

  const schema = Yup.object({
    readers: Yup.array().of(
      Yup.object().shape({
        organizationalUnit: Yup.object().nullable(),
        entryReader: Yup.object().nullable().required(t("field_required")),
        exitReader: Yup.object().nullable().required(t("field_required")),
      })
    ),
  });

  const { handleSubmit, control, setValue, getValues, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "readers",
  });

  const onSubmit = (data) => {
    let dataToSend: TimeInOrganizationalUnitsReportSettings = {
      readers: [],
    };

    for (let i = 0; i < data.readers.length; i++) {
      dataToSend.readers.push({
        organizationalUnitId: data.readers[i].organizationalUnit?.id,
        entryReaderId: data.readers[i].entryReader.id,
        exitReaderId: data.readers[i].exitReader.id,
      });
    }

    setSettingsBody(JSON.stringify(dataToSend));
  };

  useEffect(() => {
    if (dataLoaded === true) {
      let initData: any = [];

      if (timeInOrganizationalUnitsReportSettings.readers) {
        timeInOrganizationalUnitsReportSettings.readers.forEach((el) => {
          const foundOrganizationaUnit = el.organizationalUnitId
            ? organizationalUnits.find(
                (ou) => ou.id === el.organizationalUnitId
              )
            : null;
          const foundEntryReader = readers.find(
            (r) => r.id === el.entryReaderId
          );
          const foundExitReader = readers.find((r) => r.id === el.exitReaderId);

          initData.push({
            organizationalUnit: foundOrganizationaUnit,
            entryReader: foundEntryReader,
            exitReader: foundExitReader,
          });
        });
      }

      setValue("readers", initData);
      /*if (organizationalUnitsSettings?.value) {
        const tempValues = organizationalUnitsSettings.value.split(",");
        setSelectedOrganizationalUnits(
          tempValues
            .filter((el) => !isNaN(parseInt(el)))
            .map((el) => parseInt(el))
        );
      }*/
    }
  }, [
    dataLoaded,
    organizationalUnits,
    readers,
    setValue,
    timeInOrganizationalUnitsReportSettings?.readers,
  ]);

  if (!isAdminProfile) {
    return <></>;
  }

  return (
    <AccordionStyled
      title={t("time_in_organizational_units_report")}
      defaultExpanded={false}
      onChange={(event: ChangeEvent<{}>, expanded: boolean) => {
        setTimeInOrganizationalUnitsReportSettingsAccordionExpanded(expanded);
      }}
    >
      {dataLoaded && (
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id="timeInOrganizationalUnitsReportForm"
          >
            <AssigningReadersToOrganizationalUnits
              fields={fields}
              append={append}
              remove={remove}
              control={control}
              setValue={setValue}
              watch={watch}
              organizationalUnits={organizationalUnits}
              readers={readers}
              getValues={getValues}
            />
          </form>

          <div className={styles.saveChanges}>
            <ButtonStyled
              className={styles.saveButton}
              form="timeInOrganizationalUnitsReportForm"
            >
              {t("save")}
            </ButtonStyled>
          </div>
        </div>
      )}
    </AccordionStyled>
  );
};

export default TimeInOrganizationalUnitsReportSettings;
