import React from "react";
import OvertimesSummaryContent from "./OvertimesSummaryContent";

function OvertimesSummary() {
  return (
    <div>
      <OvertimesSummaryContent />
    </div>
  );
}

export default OvertimesSummary;
