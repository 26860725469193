import getBeginningOfDate from "./getBeginningOfDate";
import getEndOfDate from "./getEndOfDate";

const getDateRange = (
  date: Date,
  rangeType: "Month" | "CurrentWeek" | "Day"
): { startDate: Date; endDate: Date } => {
  if (rangeType === "Month") {
    return {
      startDate: getBeginningOfDate(date, rangeType),
      endDate: getEndOfDate(date, rangeType),
    };
  } else if (rangeType === "CurrentWeek") {
    return {
      startDate: getBeginningOfDate(date, rangeType),
      endDate: getEndOfDate(date, rangeType),
    };
  } else if (rangeType === "Day") {
    return {
      startDate: getBeginningOfDate(date, rangeType),
      endDate: getEndOfDate(date, rangeType),
    };
  }
  return { startDate: new Date(), endDate: new Date() };
};

export default getDateRange;
