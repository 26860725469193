export enum ACUserPermissions {
  ADMINISTRATOR = 1,
  SERVICE = 2,
  GUARD = 3,
  PREVIEW = 4,
  DOSIMETRY = 5,
  RECEPTION = 6,
  WEB_USER = 7,
  WEB_ADMINISTRATOR = 8,
  WEB_GUESTS_ADVISING = 9,
  WEB_GUESTS_ACCPETING = 10,
  AGENT = 11,
  PFSO = 12,
  SHIFT_DIRECTOR = 13,
  SECURITY = 14,
  OHS = 15,
  OPERATIONAL_SUPPORT = 16,
  INTEGRATOR = 17,
  EVENTS_PREVIEW = 18,
}
