import { TableRow } from "@devexpress/dx-react-grid";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import {
  faCheck,
  faDoorOpen,
  faMinusCircle,
  faRedoAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypeOfVisit } from "../../../Constants/typeOfVisitInPorts";
import computeDateRangeFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useRefreshData from "../../../hooks/useRefreshData/useRefreshData";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";
import FormatRegistrationNumberInGrid from "../../helpersComponents/FormattersComponent/FormatRegistrationNumberInGrid";
import { CheckboxStyled } from "../../helpersComponents/MaterialUi";
import PopupRequirements from "../../helpersComponents/PopupRequirements/PopupRequirements";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import RejectPopup from "../../pfso/OperationButtons/RejectPopup";
import NavigationSecurity from "../NavigationSecurity/navigationSecurity";
import PopupLetIn from "./PopupLetIn";
import RowDetailsNotifications from "./RowDetails/RowDetailsNotifications";
import RowDetailsServicesAndDeliveries from "./RowDetails/RowDetailsServicesAndDeliveries";
import styles from "./securityNotificationsStyle.module.scss";

const CheckIfConditionsAreNotMet = (
  notificationId: number | null,
  typeOfVisitId: number | null,
  deliveryServiceId: number | null | undefined,
  guestTrainingExpiryDate: Date | null
) => {
  return (
    ((notificationId && typeOfVisitId === TypeOfVisit.COMPLETION_OF_THE_WORK) ||
      deliveryServiceId) &&
    (guestTrainingExpiryDate === null ||
      getEndOfDate(new Date(guestTrainingExpiryDate), "Day") <
        getEndOfDate(new Date(), "Day"))
  );
};

const ContentSecurityNotifications = () => {
  const { t } = useTranslation();

  const [openLetInGuestState, setOpenLetInGuestState] = useState<any>({
    isOpen: false,
    row: null,
  });
  const handleCloseLetInGuest = useCallback(() => {
    setOpenLetInGuestState({
      isOpen: false,
      row: null,
    });
  }, []);

  const [openRequirementsPopup, setOpenRequirementsPopup] = useState<{
    isOpen: boolean;
    guestId: null | number;
  }>({
    isOpen: false,
    guestId: null,
  });
  const handleCloseRequirementsPopup = useCallback(() => {
    setOpenRequirementsPopup({
      isOpen: false,
      guestId: null,
    });
  }, []);

  const columns = [
    { name: "notificationNumber", title: t("notification_number") },
    { name: "type", title: t("type") },
    { name: "confirmationStatusName", title: t("confirmation_status") },
    { name: "firstName", title: t("first_name") },
    { name: "lastName", title: t("last_name") },
    { name: "dateFrom", title: t("date_from"), type: "date" },
    { name: "dateTo", title: t("date_to2"), type: "date" },
    { name: "registrationNumberFormatted", title: t("plate_number") },
    { name: "company", title: t("company") },
    {
      name: "bh3Check",
      title: t("BH3"),
    },
    { name: "permissionZoneName", title: t("permission_zone") },
    { name: "requirements", title: t("requirements") },
  ];

  const requirementsResult = useCallback(
    (row: INotificationGuestsInPorts) => {
      return CheckIfConditionsAreNotMet(
        row.notificationId,
        row.typeOfVisitId,
        row.deliveryServiceId,
        row.guestTrainingExpiryDate
      ) ? (
        <Tooltip title={t("edit")!}>
          <div
            className={styles.containerRequirementsAreNotMet}
            onClick={() => {
              setOpenRequirementsPopup({
                isOpen: true,
                guestId: row.guestId,
              });
            }}
          >
            <div>
              <FontAwesomeIcon
                className={styles.iconDisagree}
                icon={faTimes}
                size="2x"
              />
              <div className={styles.reasonRequirementsAreNotMet}>
                {t("lack_of_training")}
              </div>
            </div>
          </div>
        </Tooltip>
      ) : (
        <FontAwesomeIcon
          className={styles.iconAgree}
          icon={faCheck}
          size="2x"
        />
      );
    },
    [t]
  );

  const modifyResponseCallback = useCallback(
    (responseData) => {
      const modified = responseData.map((row: INotificationGuestsInPorts) => {
        return {
          ...row,
          id: row.id,
          type: row?.deliveryServiceId
            ? t("service_delivery")
            : t("notification_2"),
          firstName: row.guestFirstName,
          lastName: row.guestLastName,
          company: row.companyName,
          targetDepartment: row?.targetDepartmentName ?? "-",
          permissionZoneName: row?.permissionZoneName
            ? row.permissionZoneName
            : "-",
          requirements: (
            <div className={styles.containerIconRequirements}>
              {requirementsResult(row)}
            </div>
          ),
          registrationNumberFormatted: (
            <FormatRegistrationNumberInGrid
              registrationNumber={row?.registrationNumber}
            />
          ),
          bh3Check: row?.notificationId ? (
            <CheckboxStyled checked={row.bH3 ? true : false} disabled />
          ) : (
            "-"
          ),
        };
      });
      return modified;
    },
    [t, requirementsResult]
  );

  const dateFrom = computeDateRangeFetch(getBeginningOfDate(new Date(), "Day"));
  const dateTo = computeDateRangeFetch(
    getBeginningOfDate(new Date(), "Day", { extraDays: 1 })
  );

  const [
    notificationsRows,
    fetchingStateNotifications,
    fetchAgainNotifications,
  ] = useFetchAndSetGET<INotificationGuestsInPorts[]>({
    path: `security/notification-guests-in-ports?ConfirmationStatuses=4&ConfirmationStatuses=7&DateFrom=${dateFrom}&DateTo=${dateTo}&RegistrationNumber=true`,
    modifyResponseCallback: modifyResponseCallback,
  });

  const settings = useAppSelector(selectSettings);
  let timeRefreshingNotifications: number = settings["CzasOdswiezaniaAwizacji"]
    ?.value
    ? settings["CzasOdswiezaniaAwizacji"].value
    : 300_000;

  const { handleChangeRefreshingTime } = useRefreshData(
    fetchAgainNotifications,
    timeRefreshingNotifications
  );

  interface ICustomRow {
    tableRow: TableRow;
    row: any;
    children: React.ReactNode;
  }

  const plateDetectionOptionName = settings.KameraOpcja?.value
    ? settings.KameraOpcja?.value || "OstatnioWykryteTablice"
    : "OstatnioWykryteTablice";

  const [scannedRegistrationNumber, , fetchAgainScannedRegistrationNumber] =
    useFetchAndSetGET({
      path: `security/settings?settingName=${plateDetectionOptionName}&noCache=true`,
    });

  const [tableRow, setTableRow] =
    useState<
      ({ row, tableRow, children, ...restProps }: ICustomRow) => JSX.Element
    >();

  const highlightedRows = useCallback(
    (registrationNumber: string | undefined) => {
      const TableRow = ({
        row,
        tableRow,
        children,
        ...restProps
      }: ICustomRow): JSX.Element => {
        return (
          <Table.Row
            row={row}
            tableRow={tableRow}
            children={children}
            {...restProps}
            className={
              row?.registrationNumber?.includes(registrationNumber)
                ? styles.highlighted
                : ""
            }
            style={
              row.isGuestOnBlackList === true
                ? { backgroundColor: "#FFC4C4" }
                : {}
            }
          />
        );
      };
      return TableRow;
    },
    []
  );

  function arrayMoveItem(arr: any[], fromIndex: number, toIndex: number) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  const [
    existsRegistrationNumberOnWhiteList,
    fetchingStateExistsRegistrationNumberOnWhiteList,
    fetchAgainExistsRegistrationNumberOnWhiteList,
  ] = useFetchAndSetGET<Exists>({
    path: `security/white-list-guests/is-registration-number-on-white-list?registrationNumber=${scannedRegistrationNumber?.value}`,
    startFetchOnInitial: false,
  });

  const [registrationNumber, setRegistrationNumber] = useState<string | false>(
    ""
  );

  const [existsOnWhiteList, setExistsOnWhiteList] = useState(false);
  useEffect(() => {
    if (
      fetchingStateExistsRegistrationNumberOnWhiteList.response?.status ===
        200 &&
      existsRegistrationNumberOnWhiteList?.result &&
      registrationNumber !== scannedRegistrationNumber?.value
    ) {
      setExistsOnWhiteList(true);
    } else if (
      fetchingStateExistsRegistrationNumberOnWhiteList.response?.status ===
        200 &&
      existsRegistrationNumberOnWhiteList?.result === false &&
      registrationNumber !== scannedRegistrationNumber?.value
    ) {
      setExistsOnWhiteList(false);
    }
  }, [
    fetchingStateExistsRegistrationNumberOnWhiteList,
    existsRegistrationNumberOnWhiteList?.result,
    scannedRegistrationNumber?.value,
    registrationNumber,
  ]);

  const sortRows = useCallback(
    (registrationNumber: string | undefined) => {
      let founded = false;
      let index: number = 0;
      for (let i = 0; i < notificationsRows.length; i++) {
        if (
          registrationNumber &&
          notificationsRows[i].registrationNumber?.includes(registrationNumber)
        ) {
          founded = true;
          arrayMoveItem(notificationsRows, i, index);
          setExistsOnWhiteList(false);
          index++;
        }
      }
      if (founded) {
        setRows([...notificationsRows]);
      }

      if (!founded && registrationNumber !== scannedRegistrationNumber?.value) {
        fetchAgainExistsRegistrationNumberOnWhiteList();
      }
    },
    [
      notificationsRows,
      fetchAgainExistsRegistrationNumberOnWhiteList,
      scannedRegistrationNumber?.value,
    ]
  );

  const [rows, setRows] = useState<Object[]>([]);

  useEffect(() => {
    let interval: any;

    if (scannedRegistrationNumber?.value) {
      if (interval !== undefined) {
        clearInterval(interval);
      }
      interval = setInterval(() => {
        fetchAgainScannedRegistrationNumber();

        if (scannedRegistrationNumber.value !== registrationNumber) {
          setRegistrationNumber(scannedRegistrationNumber.value);
          setTableRow(() => highlightedRows(scannedRegistrationNumber.value));
          sortRows(scannedRegistrationNumber.value);
        }
      }, 5000);
    } else if (scannedRegistrationNumber?.value === "") {
      if (interval !== undefined) {
        clearInterval(interval);
      }
      interval = setInterval(() => {
        fetchAgainScannedRegistrationNumber();
      }, 5000);
    }

    return () => {
      if (interval !== undefined) {
        clearInterval(interval);
      }
    };
  }, [
    fetchAgainScannedRegistrationNumber,
    highlightedRows,
    notificationsRows,
    registrationNumber,
    scannedRegistrationNumber?.value,
    sortRows,
  ]);

  useEffect(() => {
    if (notificationsRows) {
      setRows([...notificationsRows]);
      if (registrationNumber !== false) {
        sortRows(registrationNumber);
      }
    }
  }, [notificationsRows, sortRows, registrationNumber]);

  const [isOpenRejectPopup, setIsOpenRejectPopup] = useState(false);
  const [rejectPathApi, setRejectPathApi] = useState("");

  const handleCloseRejectPopup = useCallback(() => {
    setIsOpenRejectPopup(false);
  }, []);

  const handleOpenRejectPopup = useCallback(() => {
    setIsOpenRejectPopup(true);
  }, []);

  return (
    <>
      <div>
        <TopBanner pathName={t("list_of_notifications")} />
      </div>
      <div>
        <NavigationSecurity existsOnWhiteList={existsOnWhiteList} />
      </div>
      <div>
        <TableComponentMemo
          columns={columns}
          rows={rows}
          customRow={tableRow}
          layoutSettingName="layoutSecurityNotificationsRecords"
          hideActionsAndVisibilityColumns={true}
          rowDetail={(data) => {
            const rowInfo = data?.row as INotificationGuestsInPorts;
            if (rowInfo.deliveryServiceId) {
              return <RowDetailsServicesAndDeliveries row={rowInfo} />;
            }
            return <RowDetailsNotifications row={rowInfo} />;
          }}
          tableEditColumnWidth={230}
          fetchingState={{
            isFetching:
              fetchingStateNotifications.isFetching ||
              notificationsRows === null,
            isError: fetchingStateNotifications.isError,
            fetchAgain: fetchAgainNotifications,
          }}
          actionsColumnUpdated={{
            addButton: {
              ifShow: true,
              name: t("refresh"),
              icon: faRedoAlt,
              onClick: () => {
                fetchAgainNotifications();
                handleChangeRefreshingTime(timeRefreshingNotifications);
              },
            },
            editButton: {
              ifShow: true,
              customComponent: (row) => {
                return (row.notificationId &&
                  row.typeOfVisitId === TypeOfVisit.BUSINESS) ||
                  getEndOfDate(new Date(row.guestTrainingExpiryDate), "Day") >=
                    getEndOfDate(new Date(), "Day") ? (
                  <>
                    <Tooltip title={t("let_in")}>
                      <Button
                        disabled={row.isGuestOnBlackList}
                        onClick={() => {
                          setOpenLetInGuestState({
                            isOpen: true,
                            row: row,
                          });
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faDoorOpen}
                          style={{ fontSize: "20px", color: "green" }}
                        />
                      </Button>
                    </Tooltip>
                  </>
                ) : (
                  <></>
                );
              },
            },
            removeButton: {
              ifShow: true,
              name: t("refuse"),
              customComponent: (row) => {
                return (
                  <Tooltip title={t("refuse")}>
                    <Button
                      onClick={() => {
                        setIsOpenRejectPopup(true);
                        row?.deliveryServiceId
                          ? setRejectPathApi(
                              `security/delivery-service-guest-change-let-in/${row.id}`
                            )
                          : setRejectPathApi(
                              `security/notification-guest-change-let-in/${row.id}`
                            );
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        style={{ fontSize: "20px", color: "red" }}
                      />
                    </Button>
                  </Tooltip>
                );
              },
            },
          }}
        />
      </div>
      <div>
        {openLetInGuestState && (
          <PopupLetIn
            open={openLetInGuestState.isOpen}
            row={openLetInGuestState.row}
            onCancelChanges={handleCloseLetInGuest}
            successCallback={fetchAgainNotifications}
            pathConditional={(row: INotificationGuestsInPorts) =>
              row?.deliveryServiceId
                ? `security/delivery-service-guest-let-in/${row?.id}`
                : `security/notification-guest-let-in/${row?.id}`
            }
            permissionZonesPathApi="security/permission-zones"
            selectedPermissionZoneId={openLetInGuestState.row?.permissionZoneId}
            bh3={
              openLetInGuestState.row?.notificationId &&
              openLetInGuestState.row?.bH3 !== null
                ? openLetInGuestState.row?.bH3
                : null
            }
          />
        )}
      </div>
      <div>
        {isOpenRejectPopup && (
          <RejectPopup
            patchApi={rejectPathApi}
            isOpen={isOpenRejectPopup}
            successCallback={fetchAgainNotifications}
            closePopup={handleCloseRejectPopup}
            openPopup={handleOpenRejectPopup}
            isSecurity={true}
          />
        )}
      </div>
      <div>
        <PopupRequirements
          open={openRequirementsPopup.isOpen}
          guestId={openRequirementsPopup.guestId}
          pathGetGuestTrainingDateExpiry="security/guest-training-date-expiry"
          pathUpdateTrainingDateExpiry="security/guest-training-date-expiry"
          onCancelChanges={handleCloseRequirementsPopup}
          successCallback={fetchAgainNotifications}
        />
      </div>
    </>
  );
};

export default ContentSecurityNotifications;
