const FormatRegistrationNumber = ({
  registrationNumber,
}: {
  registrationNumber: string;
}) => {
  let array: string[] = [];
  if (registrationNumber.includes(",")) {
    array = registrationNumber.split(",");
  } else if (registrationNumber.includes(";")) {
    array = registrationNumber.split(";");
  } else if (registrationNumber.includes(",")) {
    array = registrationNumber.split(",");
  }

  let content: any = [];
  if (array && array.length > 0) {
    content.push(array.map((item, index) => <div key={index}>{item}</div>));
  } else {
    content.push(registrationNumber);
  }

  return content;
};

export default FormatRegistrationNumber;
