import { useTranslation } from "react-i18next";
import toLocaleStringCustom from "../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import { colorNameDictionary } from "../../ProfilesRouting/profilesDictionaries";
import FilesListWithFetch from "../Attachments/filesListWithFetch";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../RowDetailsHelpers";
import styles from "./styles.module.scss";

interface IProps {
  row: ArrivalOfShip;
  pathApiAttachmentsInPorts: string;
}

const RowDetailArrivalOfShip: React.FC<IProps> = ({
  row,
  pathApiAttachmentsInPorts,
}) => {
  const { t } = useTranslation();
  const authUserType = useAppSelector(selectAuthUser).currentProfile.type;

  return (
    <>
      <RowDetailsContainer>
        <RowDetailsRow>
          <RowDetailsTitleValue>
            <RowDetailsTitle>{t("ship")}:</RowDetailsTitle>
            <RowDetailsValue>
              {row?.shipName ? row.shipName : "-"}
            </RowDetailsValue>
          </RowDetailsTitleValue>

          <RowDetailsTitleValue>
            <RowDetailsTitle>{t("date_of_arrival")}:</RowDetailsTitle>
            <RowDetailsValue>
              {toLocaleStringCustom({ date: new Date(row.dateOfArrival), t })}
            </RowDetailsValue>
          </RowDetailsTitleValue>
        </RowDetailsRow>

        <RowDetailsRow>
          <RowDetailsTitleValue>
            <RowDetailsTitle>{t("data_created")}:</RowDetailsTitle>
            <RowDetailsValue>
              {toLocaleStringCustom({ date: new Date(row.createdAt), t })}
            </RowDetailsValue>
          </RowDetailsTitleValue>
        </RowDetailsRow>
      </RowDetailsContainer>

      <div
        className={styles.detailsTableListRowFiles}
        style={{
          backgroundColor: `${colorNameDictionary[authUserType]}`,
        }}
      >
        <div>
          <div>
            <b>{t("attachments")}</b>
          </div>
        </div>
      </div>
      <div className={styles.tableRowFiles}>
        <FilesListWithFetch
          files={
            row?.shipAttachments as {
              id: number;
              fileName: string;
              type?: string;
              required?: boolean;
            }[]
          }
          path={pathApiAttachmentsInPorts}
        />
      </div>
    </>
  );
};

export default RowDetailArrivalOfShip;
