import React from 'react';
// import { NavLink } from "react-router-dom";
import LoginPageContent from './loginPageContent'

function LoginPage()  {

    return (
        <>
            <LoginPageContent />
        </>
    )
}
// const condition = authUser => !authUser;

export default LoginPage;