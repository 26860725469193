import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "./../../../reducers/session";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { DialogContentStyled } from "../PopupHelpers/dialogContentStyled";
import { useCallback, useEffect, useState } from "react";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import DialogStyled from "../PopupHelpers/dialogStyled";
import { useTranslation } from "react-i18next";
import DialogTitleStyled from "../PopupHelpers/dialogTitleStyled";
import DialogActionsStyled from "../PopupHelpers/dialogActionsStyled";
import TwoColumnsRowsContainer from "../PopupHelpers/twoColumnsRowsContainer";
import Row from "../PopupHelpers/row";
import RowTitle from "../PopupHelpers/rowTitle";
import RowInput from "../PopupHelpers/rowInput";
import TextFieldFormik from "../FormikInputs/TextField/textField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { selectSettings } from "../../../reducers/settings";

interface IProps {
  rowId: number;
  setIsOpen: React.Dispatch<boolean>;
  isOpen: boolean;
}

const DelegationCarbonFootprintPopup = ({
  rowId,
  isOpen,
  setIsOpen,
}: IProps) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const settings = useAppSelector(selectSettings);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [delegationCarbonFootprint, , fetchingAgainDelegationCarbonFootprint] =
    useFetchAndSetGET<IDelegationCarbonFootprint>({
      path: `worker-time/${authUser.currentProfile?.subjectId}/delegations-carbon-footprint-settlement/${rowId}`,
    });

  const successCallbackAndClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const [fetchingStatePutData, fetchAgainPutData] = useFetchOtherThanGET({
    path: `worker-time/${authUser.currentProfile?.subjectId}/delegations-carbon-footprint-settlement/${rowId}`,
    method: "PUT",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    disableErrorSnackbar: true,
    successCallback: successCallbackAndClose,
  });

  useEffect(() => {
    if (isOpen) {
      fetchingAgainDelegationCarbonFootprint();
    }
  }, [isOpen, fetchingAgainDelegationCarbonFootprint]);

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPutData();
    }
  }, [bodyRequest, fetchAgainPutData]);

  const labelColumnWidth = "160px";

  const getInitialValues = () => {
    return {
      distanceByPlane: delegationCarbonFootprint?.distanceByPlane
        ? delegationCarbonFootprint?.distanceByPlane
        : 0,
      distanceByTrain: delegationCarbonFootprint?.distanceByTrain
        ? delegationCarbonFootprint?.distanceByTrain
        : 0,
      distanceByBus: delegationCarbonFootprint?.distanceByBus
        ? delegationCarbonFootprint?.distanceByBus
        : 0,
      distanceByPetrolCar: delegationCarbonFootprint?.distanceByPetrolCar
        ? delegationCarbonFootprint?.distanceByPetrolCar
        : 0,
      distanceByDieselCar: delegationCarbonFootprint?.distanceByDieselCar
        ? delegationCarbonFootprint?.distanceByDieselCar
        : 0,
      distanceByElectricCar: delegationCarbonFootprint?.distanceByElectricCar
        ? delegationCarbonFootprint?.distanceByElectricCar
        : 0,
      distanceByLPGCar: delegationCarbonFootprint?.distanceByLPGCar
        ? delegationCarbonFootprint?.distanceByLPGCar
        : 0,
    };
  };

  return (
    <DialogStyled
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <DialogTitleStyled title={t("carbon_footprint_settlement")} />
      {delegationCarbonFootprint && (
        <DialogContentStyled>
          <Formik
            initialValues={getInitialValues()}
            onSubmit={(values) => {
              const dataToUpdate = {
                distanceByPlane: values.distanceByPlane,
                distanceByTrain: values.distanceByTrain,
                distanceByBus: values.distanceByBus,
                distanceByPetrolCar: values.distanceByPetrolCar,
                distanceByDieselCar: values.distanceByDieselCar,
                distanceByElectricCar: values.distanceByElectricCar,
                distanceByLPGCar: values.distanceByLPGCar,
              };

              setBodyRequest(JSON.stringify(dataToUpdate));
            }}
            validationSchema={Yup.object({
              distanceByPlane: Yup.number().required(t("field_required")),
              distanceByTrain: Yup.number().required(t("field_required")),
              distanceByBus: Yup.number().required(t("field_required")),
              distanceByPetrolCar: Yup.number().required(t("field_required")),
              distanceByDieselCar: Yup.number().required(t("field_required")),
              distanceByElectricCar: Yup.number().required(t("field_required")),
              distanceByLPGCar: Yup.number().required(t("field_required")),
            })}
          >
            {({ values, setFieldValue, errors }) => {
              return (
                <>
                  <Form noValidate id="submitForm">
                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle width={labelColumnWidth}>
                          {t("distance_by_plane")}:
                        </RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            name="distanceByPlane"
                            label={t("distance_in_kilometers")}
                            type="number"
                            required={true}
                            displayMode={
                              settings.WebSladWeglowyFormatDanych?.value
                            }
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>

                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle width={labelColumnWidth}>
                          {t("distance_by_train")}:
                        </RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            name="distanceByTrain"
                            label={t("distance_in_kilometers")}
                            type="number"
                            required={true}
                            displayMode={
                              settings.WebSladWeglowyFormatDanych?.value
                            }
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>

                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle width={labelColumnWidth}>
                          {t("distance_by_bus")}:
                        </RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            name="distanceByBus"
                            label={t("distance_in_kilometers")}
                            type="number"
                            required={true}
                            displayMode={
                              settings.WebSladWeglowyFormatDanych?.value
                            }
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>

                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle width={labelColumnWidth}>
                          {t("distance_by_petrol_car")}:
                        </RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            name="distanceByPetrolCar"
                            label={t("distance_in_kilometers")}
                            type="number"
                            required={true}
                            displayMode={
                              settings.WebSladWeglowyFormatDanych?.value
                            }
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>

                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle width={labelColumnWidth}>
                          {t("distance_by_diesel_car")}:
                        </RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            name="distanceByDieselCar"
                            label={t("distance_in_kilometers")}
                            type="number"
                            required={true}
                            displayMode={
                              settings.WebSladWeglowyFormatDanych?.value
                            }
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>

                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle width={labelColumnWidth}>
                          {t("distance_by_lpg_car")}:
                        </RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            name="distanceByLPGCar"
                            label={t("distance_in_kilometers")}
                            type="number"
                            required={true}
                            displayMode={
                              settings.WebSladWeglowyFormatDanych?.value
                            }
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>

                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle width={labelColumnWidth}>
                          {t("distance_by_electric_car")}:
                        </RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            name="distanceByElectricCar"
                            label={t("distance_in_kilometers")}
                            type="number"
                            required={true}
                            displayMode={
                              settings.WebSladWeglowyFormatDanych?.value
                            }
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>
                  </Form>
                </>
              );
            }}
          </Formik>
        </DialogContentStyled>
      )}

      <DialogActionsStyled
        errorString={errorMessage}
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={() => {
          setIsOpen(false);
        }}
        isLoading={fetchingStatePutData.isFetching}
      />
    </DialogStyled>
  );
};

export default DelegationCarbonFootprintPopup;
