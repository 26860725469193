import CompaniesInPortsContent from "../../helpersComponents/CompaniesInPorts/CompaniesInPortsContent";

const CompaniesInPortsOperationalSupport = () => {
  return (
    <CompaniesInPortsContent
      pathApiCompaniesInPorts="operational-support/companies-in-ports"
      layoutSettingName="layoutOperationalSupportComapniesRecords"
    />
  );
};

export default CompaniesInPortsOperationalSupport;
