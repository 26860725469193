import { useEffect, useState } from "react";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";

export default function useFetchingBillingsPeriods({
  selectedDate,
  selectedWorkersString,
  setFetchedBillingsPeriodsData,
}): [
  {
    isFetching: boolean;
    isError: boolean;
    response: UseFetchResponse | undefined;
  },
  () => void
] {
  const [billingsPeriodsBody, setBillingsPeriodsBody] = useState<
    false | string
  >(false);

  useEffect(() => {
    if (selectedWorkersString && selectedDate) {
      let obj = {
        timeWorkersIds: selectedWorkersString
          ? selectedWorkersString.split(",").map((e) => parseInt(e))
          : [],
        selectedMonth: selectedDate,
      };

      setBillingsPeriodsBody(JSON.stringify(obj));
    }
  }, [selectedWorkersString, selectedDate]);

  const [fetchingBillingsPeriodsState, fetchBillingsPeriodsAgain] =
    useFetchOtherThanGET({
      path: "superior-time/billings-periods",
      method: "POST",
      contentType: "application/json",
      body: billingsPeriodsBody,
      disableErrorSnackbar: true,
      disableSuccessSnackbar: true,
    });

  useEffect(() => {
    if (
      fetchingBillingsPeriodsState.isError ||
      fetchingBillingsPeriodsState.response === undefined
    )
      return;
    if (!Array.isArray(fetchingBillingsPeriodsState.response.resJson)) return;
    let data: BillingPeriod[] = fetchingBillingsPeriodsState.response.resJson;
    setFetchedBillingsPeriodsData(data);
  }, [
    fetchingBillingsPeriodsState,
    fetchingBillingsPeriodsState.isError,
    fetchingBillingsPeriodsState.response,
    setFetchedBillingsPeriodsData,
  ]);

  return [fetchingBillingsPeriodsState, fetchBillingsPeriodsAgain];
}
