import React from "react";
import PresenceContent from "./presenceContent";

function Presence() {
  return (
    <div>
      <PresenceContent />
    </div>
  );
}

export default Presence;
