import { useTranslation } from "react-i18next";
import GuestsVisitsReportContent from "../../../helpersComponents/GuestsVisitsReport/GuestsVisitsReportContent";

const SupportReportOperationalSupport = () => {
  const { t } = useTranslation();
  return (
    <div>
      <GuestsVisitsReportContent
        pathName="operational-support/guest-visits"
        layoutSettingName="layoutOperationalSupportVisitsReport"
        reportTitle={t("support_report")}
        onlySupport={true}
      />
    </div>
  );
};

export default SupportReportOperationalSupport;
