import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import DatePickerRange from "../DatePickerRange/datePickerRange";
import { CheckboxStyled } from "../MaterialUi";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import toLocaleStringCustom from "../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ChangeTrainingExpiryDatePopup from "./Popups/ChangeTrainingExpiryDate/ChangeTrainingExpiryDatePopup";

interface IProps {
  pathName: string;
  layoutSettingName: string;
  trainingExpiryDateApiPath: string;
}

const TrainingsValidityReportContent: React.FC<IProps> = ({
  pathName,
  layoutSettingName,
  trainingExpiryDateApiPath,
}) => {
  const { t } = useTranslation();
  const columns = [
    { name: "firstName", title: t("first_name") },
    { name: "lastName", title: t("last_name") },
    { name: "idNumber", title: t("id_number") },
    { name: "company", title: t("company") },
    {
      name: "expirationDate",
      title: t("training_expiration_date"),
    },
  ];

  const [changeExpiryDatePopup, setChangeExpiryDatePopup] = useState({
    isOpen: false,
    guestId: -1,
  });

  const formatExpirationDane = useCallback(
    (guestId, expirationDate) => {
      let color = "black";

      if (expirationDate) {
        let currentTime: Date = new Date();
        currentTime.setHours(0, 0, 0, 0);

        let expirationTime: Date = new Date(expirationDate);
        expirationTime.setHours(0, 0, 0, 0);

        let diffInDays: number = Math.abs(
          currentTime.getTime() - expirationTime.getTime()
        );

        diffInDays = Math.ceil(diffInDays / (1000 * 60 * 60 * 24));

        if (currentTime.getTime() > expirationTime.getTime()) {
          color = "red";
        } else if (diffInDays <= 30) {
          color = "orange";
        }
      }

      return (
        <div>
          <span
            style={{
              color: color,
              fontSize: "16px",
              fontWeight: color === "black" ? "normal" : "bold",
            }}
          >
            {toLocaleStringCustom({
              date: expirationDate,
              t,
              ifShowHours: false,
            })}
            &nbsp;
            <FontAwesomeIcon
              title={t("edit")}
              icon={faEdit}
              onClick={() => {
                setChangeExpiryDatePopup({ isOpen: true, guestId: guestId });
              }}
            />
          </span>
        </div>
      );
    },
    [t]
  );

  const visitsReportResponseCallback = useCallback(
    (responseData) => {
      const modified = responseData.map((row) => {
        return {
          ...row,
          company: row?.company ? row.company : "-",
          targetDepartment: row?.targetDepartment ? row.targetDepartment : "-",
          typeOfEntry: row?.typeOfEntry ? row.typeOfEntry : "-",
          isFinished: (
            <CheckboxStyled checked={row.exitDate ? true : false} disabled />
          ),
          notificationNumber: row?.notificationNumber
            ? row.notificationNumber
            : "-",
          expirationDate: formatExpirationDane(
            row.guestId,
            row?.expirationDate
          ),
        };
      });
      return modified;
    },
    [formatExpirationDane]
  );

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Day", { extraDays: -7 })
  );
  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Day"));

  const [rows, fetchingState, fetchAgain] = useFetchAndSetGET({
    path: `${pathName}?dateFrom=${convertDateFetch(
      startDate
    )}&dateTo=${convertDateFetch(endDate)}`,
    modifyResponseCallback: visitsReportResponseCallback,
  });

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    fetchAgain();
  }, [startDate, endDate, fetchAgain]);

  return (
    <div>
      <div>
        <TopBanner pathName={t("trainings_validity_report")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            view={["year", "month", "date"]}
            darkTheme={true}
          />
        </div>

        <div>
          {!fetchingState.isFetching && (
            <TableComponentMemo
              columns={columns}
              rows={rows || []}
              layoutSettingName={layoutSettingName}
              fetchingState={{
                isFetching: fetchingState.isFetching,
                isError: fetchingState.isError,
                fetchAgain: fetchAgain,
              }}
            />
          )}
        </div>
      </div>

      {changeExpiryDatePopup && changeExpiryDatePopup.isOpen && (
        <ChangeTrainingExpiryDatePopup
          guestId={changeExpiryDatePopup.guestId}
          trainingExpiryDateApiPath={trainingExpiryDateApiPath}
          isOpen={changeExpiryDatePopup.isOpen}
          closePopup={() => {
            setChangeExpiryDatePopup({ isOpen: false, guestId: -1 });
          }}
          successCallback={() => {
            setChangeExpiryDatePopup({ isOpen: false, guestId: -1 });
            fetchAgain();
          }}
        />
      )}
    </div>
  );
};

export default TrainingsValidityReportContent;
