import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { AutocompleteFormik, TextFieldFormik } from "../FormikInputs";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import { DialogContentStyled, Row, RowInput, RowTitle } from "../PopupHelpers";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";
import { ProfileType } from "../../../enums/profileType";
import { getProfileName } from "../../../HelpersFunctions/profileName";
import { useCallback } from "react";

const PopupContentNewCompany = ({
  rowId,
  onSubmit,
  pathApiCompaniesInPorts,
}: {
  rowId?: number;
  onSubmit: (values: ICompanyInPortsAdd) => void;
  pathApiCompaniesInPorts: string;
}) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);

  const [companies, fetchingStateCompanies, fetchingAgainCompanies] =
    useFetchAndSetGET<ICompanyInPorts[]>({
      path: pathApiCompaniesInPorts,
    });

  const [fetchedRow, fetchingStateFetchedRow, fetchAgainFetchedRow] =
    useFetchAndSetGET<ICompanyInPorts>({
      path: `${pathApiCompaniesInPorts}/${rowId}`,
      startFetchOnInitial: rowId ? true : false,
    });

  const [users, fetchingStateUsers, fetchAgainUsers] = useFetchAndSetGET<
    IACSuperiorBasicInfo[]
  >({
    path: `${getProfileName(authUser.currentProfile.type)}/ac-superiors`,
    startFetchOnInitial:
      authUser.currentProfile.type === ProfileType.PFSO ||
      authUser.currentProfile.type === ProfileType.SHIFT_DIRECTOR,
  });

  const getUsers = useCallback(() => {
    if (
      authUser.currentProfile.type === ProfileType.PFSO ||
      authUser.currentProfile.type === ProfileType.SHIFT_DIRECTOR
    ) {
      return users ?? [];
    }

    return [];
  }, [users, authUser.currentProfile.type]);

  const yupValidationSchema =
    authUser.currentProfile.type === ProfileType.PFSO ||
    authUser.currentProfile.type === ProfileType.SHIFT_DIRECTOR
      ? Yup.object({
          name: Yup.string()
            .nullable()
            .required(t("field_required"))
            .test("isNameExists", t("company_name_exists"), (value) => {
              return value ===
                companies.find(
                  (c) => c.name === value && c.type === 5 && rowId !== c.id
                )?.name
                ? false
                : true;
            }),
          selectedUser: Yup.object().nullable().required(t("field_required")),
        })
      : Yup.object({
          name: Yup.string()
            .nullable()
            .required(t("field_required"))
            .test("isNameExists", t("company_name_exists"), (value) => {
              return value ===
                companies.find((c) => c.name === value && rowId !== c.id)?.name
                ? false
                : true;
            }),
        });

  return (
    <div>
      {" "}
      <LoadingWrapper
        isLodadingProgress={
          fetchingStateCompanies.isFetching ||
          fetchingStateFetchedRow.isFetching ||
          fetchingStateUsers.isFetching
        }
        isError={
          fetchingStateCompanies.isError ||
          fetchingStateFetchedRow.isError ||
          fetchingStateUsers.isError
        }
        setIfFetchAgain={() => {
          if (fetchingStateCompanies.isError) {
            fetchingAgainCompanies();
          }
          if (fetchingStateFetchedRow.isError) {
            fetchAgainFetchedRow();
          }

          if (fetchingStateUsers.isError) {
            fetchAgainUsers();
          }
        }}
      >
        {companies && getUsers() && (fetchedRow || rowId === undefined) && (
          <DialogContentStyled>
            <Formik
              initialValues={{
                name: fetchedRow?.name ? fetchedRow.name : "",
                selectedUser: fetchedRow?.addingPersonId
                  ? users?.find((el) => el.id === fetchedRow.addingPersonId)
                  : null,
              }}
              onSubmit={(values: any) => {
                onSubmit(values);
              }}
              validationSchema={yupValidationSchema}
            >
              <Form noValidate id="submitForm">
                <Row>
                  <RowTitle>{t("name")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      ifToUpperCaseShipsAndPorts={true}
                      label={t("name")}
                      name="name"
                      type="text"
                      required={true}
                    />
                  </RowInput>
                </Row>

                {(authUser.currentProfile.type === ProfileType.PFSO ||
                  authUser.currentProfile.type ===
                    ProfileType.SHIFT_DIRECTOR) && (
                  <Row>
                    <RowTitle>{t("user")}:</RowTitle>
                    <RowInput>
                      <AutocompleteFormik
                        options={users}
                        getOptionLabel={(option) => {
                          return option.login;
                        }}
                        width="100%"
                        getOptionSelected={(option, value) => {
                          return option.id === value.id;
                        }}
                        label={t("select_user")}
                        name="selectedUser"
                        required={true}
                      />
                    </RowInput>
                  </Row>
                )}
              </Form>
            </Formik>
          </DialogContentStyled>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default PopupContentNewCompany;
