import { useTranslation } from "react-i18next";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "../../../../reducers/session";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import Button from "../../../helpersComponents/Button/button";
import { CircularProgress } from "@material-ui/core";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { useCallback, useState } from "react";
import convertSecondsToHrsMinsSeconds from "../../../../HelpersFunctions/dateAndTime/convertSecondsToHrsMinsSeconds";
import moment from "moment";

const OperationsHistory = () => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);

  const [startDate, setStartDate] = useState<Date>(
    getBeginningOfDate(new Date(), "Year")
  );
  const [endDate, setEndDate] = useState<Date>(getEndOfDate(new Date(), "Day"));

  const modifyResponseCallback = useCallback((responseData) => {
    const modified = responseData.map((row, index) => {
      return {
        ...row,
        timeDuration: row.operationEndDate
          ? convertSecondsToHrsMinsSeconds(
              (new Date(row.operationEndDate).getTime() -
                new Date(row.operationStartDate).getTime()) /
                1000
            )
          : "00:00:00",
      };
    });
    return modified;
  }, []);

  const [
    operationsHistory,
    fetchingStateOperationsHistory,
    fetchAgainOperationsHistory,
  ] = useFetchAndSetGET<IOperationsHistoryItem[]>({
    path: `worker-time/${
      authUser.currentProfile?.subjectId
    }/operations-history?dateFrom=${computeDateRangeFetch(
      startDate
    )}&dateTo=${computeDateRangeFetch(endDate)}`,
    startFetchOnInitial: false,
    modifyResponseCallback: modifyResponseCallback,
  });

  const columns = [
    { name: "operationName", title: t("operation_name") },
    { name: "operationStartDate", title: t("start_date"), type: "date_hours" },
    { name: "operationEndDate", title: t("end_date"), type: "date_hours" },
    { name: "timeDuration", title: t("time_duration") },
  ];

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {fetchingStateOperationsHistory.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <Button
            onClick={() => {
              fetchAgainOperationsHistory();
            }}
          >
            {t("get_operations")}
          </Button>
        )}
      </div>
    </>
  );

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (columnName === "operationStartDate") {
      return row.operationStartDate
        ? moment(row.operationStartDate).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    if (columnName === "operationEndDate") {
      return row.operationEndDate
        ? moment(row.operationEndDate).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    return row[columnName];
  }, []);

  return (
    <div>
      <TopBanner pathName={t("operations_history")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date", "hours", "minutes"]}
            format="dd-MM-yyyy HH:mm"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            keyboardDateTimePicker={true}
            darkTheme={true}
            setDate2={setEndDate}
            confirmButtonRight={confirmButtonRight}
          ></DatePickerRange>
        </div>
        <div>
          <LoadingWrapper
            isLodadingProgress={fetchingStateOperationsHistory.isFetching}
            isError={fetchingStateOperationsHistory.isError}
            setIfFetchAgain={() => {
              if (fetchingStateOperationsHistory.isError) {
                fetchAgainOperationsHistory();
              }
            }}
          >
            <TableComponent
              layoutSettingName="layoutTimeWorkerOperationsHistory"
              rows={operationsHistory || []}
              columns={columns || []}
              customExcelCellValue={customExportCellValue}
              customPdfCellValue={customExportCellValue}
            />
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
};

export default OperationsHistory;
