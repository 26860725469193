import ContentGuests from "./ContentGuests";

function GuestsPfso() {
  return (
    <div>
      <ContentGuests />
    </div>
  );
}

export default GuestsPfso;
