import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ButtonStyled from "../../helpersComponents/Button/button";
import "./canvas.scss";

const savePDF = () => {
  const printArea = document.getElementById("canvas");
  if (!printArea) return;
  html2canvas(printArea).then((canvas) => {
    var img = canvas.toDataURL("image/png");
    var doc = new jsPDF("p", "mm", [297, 210]);
    doc.addImage(img, "JPEG", 0, 0, 210, 297);
    doc.save("test.pdf");
  });
};

export default function TestGeneratePDF() {
  return (
    <div>
      <ButtonStyled
        onClick={() => {
          savePDF();
        }}
      >
        Generuj certyfikat
      </ButtonStyled>
      <div id="canvas">
        <h1>sdfsd</h1>
        <h3>312312312</h3>
      </div>
    </div>
  );
}
