import { makeStyles, Select } from "@material-ui/core";
import { colorNameDictionary } from "../../../ProfilesRouting/profilesDictionaries";

function SelectStyled(props) {
  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );

  const useStyles = makeStyles({
    selectOptions: {
      border: `1px solid ${
        authUser !== undefined && Object.keys(authUser).length !== 0
          ? colorNameDictionary[authUser.currentProfile?.type]
          : "#1ab394"
      }`,
      color: props.textColor ?? "white",
    },
  });

  const classes = useStyles();

  return <Select {...props} className={classes.selectOptions} />;
}

export default SelectStyled;
