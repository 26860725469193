import { DialogContent } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteFormik } from "../../../../helpersComponents/FormikInputs";
import {
  DialogActionsStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../../../helpersComponents/PopupHelpers";
// import styles from "./styles.module.scss";
import * as Yup from "yup";
import useFetch from "../../../../../hooks/fetchHooks/useFetch/useFetch";
import { useAppSelector } from "../../../../../store/hooks";
import { selectAuthUser } from "../../../../../reducers/session";
import { useSnackbar } from "notistack";
import handleFetchErrors from "../../../../../HelpersFunctions/handleFetchErrors";

interface Props {
  close: () => void;
  projectsList: any;
  copyOrMove: "copy" | "move";
  taskId: number;
  setIfFetchAgain: React.Dispatch<boolean>;
  currentProjectId: number;
}

export default function MoveCopyPopupContent({
  close,
  copyOrMove,
  projectsList,
  taskId,
  setIfFetchAgain,
  currentProjectId,
}: Props): ReactElement {
  const { t } = useTranslation();
  const formRef = useRef<any>(null);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: any = {
    targetProject: null,
  };

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  let [
    setIsPutingPosting,
    fetchingStatePutingPosting,
    fetchAgainPutingPosting,
  ] = useFetch({
    path:
      `worker-time/${authUserId}/projects/${currentProjectId}/` +
      (copyOrMove === "copy"
        ? `tasks/${taskId}/copy-task`
        : "move-task-between-projects"),
    startFetchOnInitial: false,
    method: copyOrMove === "copy" ? "POST" : "PUT",
    body: JSON.stringify(bodyRequest),
    contentType: "application/json",
  });

  useEffect(() => {
    if (fetchingStatePutingPosting.response === undefined) return;
    if (fetchingStatePutingPosting.isError) {
      handleFetchErrors({
        fetchingStateResponse: fetchingStatePutingPosting.response,
        enqueueSnackbar,
        t,
      });
      setIsPutingPosting(false);
      setBodyRequest(false);
      return;
    }
    enqueueSnackbar(t("success_action"), { variant: "success" });
    setBodyRequest(false);
    setIsPutingPosting(false);
    setIfFetchAgain(true);
    close();
  }, [
    fetchingStatePutingPosting.isError,
    fetchingStatePutingPosting.response,
    setIsPutingPosting,
    t,
    enqueueSnackbar,
    close,
    setIfFetchAgain,
  ]);

  useEffect(() => {
    if (bodyRequest !== false) {
      fetchAgainPutingPosting();
    }
  }, [bodyRequest, fetchAgainPutingPosting]);

  return (
    <>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={Yup.object({
            targetProject: Yup.object()
              .nullable(false)
              .typeError(t("field_required")),
          }).nullable()}
          onSubmit={(values) => {
            if (copyOrMove === "copy") {
              setBodyRequest({ destinationProjectId: values.targetProject.id });
            } else {
              setBodyRequest({
                destinationProjectId: values.targetProject.id,
                taskId: taskId,
              });
            }
          }}
        >
          <Form noValidate>
            <Row>
              <RowTitle>{t("select_project")}:</RowTitle>
              <RowInput>
                <AutocompleteFormik
                  options={projectsList}
                  getOptionLabel={(option: any) => {
                    return option.name;
                  }}
                  width="100%"
                  getOptionSelected={(option, value) => {
                    return option.id === value.id;
                  }}
                  label={t("select_project")}
                  name="targetProject"
                  required={true}
                />
              </RowInput>
            </Row>
          </Form>
        </Formik>
      </DialogContent>
      <DialogActionsStyled
        isLoading={fetchingStatePutingPosting.isFetching}
        errorString={
          fetchingStatePutingPosting.isError ? t("something_went_wrong") : ""
        }
        onCancel={() => close()}
        onConfirm={() => {
          if (formRef.current === null) return;
          formRef.current?.submitForm();
        }}
      />
    </>
  );
}
