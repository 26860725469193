import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ElasticForm from "../../../helpersComponents/ElasticForm";
import { HTMLControlType } from "../../../../enums/HTMLControlType";
import { EditorHTMLMode } from "../../../../enums/editorHTMLMode";

const RowDetail: React.FC = ({ row }: any) => {
  const { t } = useTranslation();
  const [elasticFormValues, setElasticFormValues] = useState<any>({});
  const [elasticFormErrors, setElasticFormErrors] = useState<any>({});
  const [ownData, setOwnData] = useState<EditorHTML | null>(null);

  useEffect(() => {
    if (row && row.data) {
      setOwnData(JSON.parse(row.data));
    }
  }, [row]);

  useEffect(() => {
    if (ownData) {
      for (let i = 0; i < ownData.controls.length; i++) {
        switch (ownData.controls[i].type) {
          case HTMLControlType.TEXTBOX:
            {
              let tempControl: TextBoxHTML = ownData.controls[i] as TextBoxHTML;

              setElasticFormValues((prev) => {
                let objToRet = { ...prev };
                objToRet[tempControl.name] = tempControl.value;
                return objToRet;
              });
            }
            break;

          case HTMLControlType.SELECT:
            {
              let tempControl: SelectionListHTML = ownData.controls[
                i
              ] as SelectionListHTML;

              setElasticFormValues((prev) => {
                let objToRet = { ...prev };
                objToRet[tempControl.name] = tempControl.value;
                return objToRet;
              });
            }
            break;
        }
      }
    }
  }, [ownData]);

  const formatTime = (time) => {
    return new Date(time).toLocaleString(t("scheduler_language"), {
      year: "numeric",
      day: "2-digit",
      month: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  return (
    <div className="detailsHoliday">
      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("type_of_request")}:</b>
          </div>
          <div>{row.templateName !== null ? row.templateName : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("request_status")}:</b>
          </div>
          <div>
            {row.statusTranslated !== null ? row.statusTranslated : "—"}
          </div>
        </div>
      </div>
      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("data_created")}:</b>
          </div>
          <div>{row.createdAt !== null ? formatTime(row.createdAt) : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("created")}:</b>
          </div>
          <div>{row.createdBy !== null ? row.createdBy : "—"}</div>
        </div>
      </div>

      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("approved_by")}:</b>
          </div>
          <div>{row.approvedBy !== null ? row.approvedBy : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("approved_date")}:</b>
          </div>
          <div>{row.approvedAt != null ? formatTime(row.approvedAt) : "—"}</div>
        </div>
      </div>

      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("rejected_by")}:</b>
          </div>
          <div>{row.rejectedBy !== null ? row.rejectedBy : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("rejected_date")}:</b>
          </div>
          <div>{row.rejectedAt != null ? formatTime(row.rejectedAt) : "—"}</div>
        </div>
      </div>

      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("data")}:</b>
          </div>
          <div className="attachment">
            <ElasticForm
              controls={ownData?.controls ? ownData.controls : []}
              mode={EditorHTMLMode.PREVIEW}
              formValues={elasticFormValues}
              setFormValues={setElasticFormValues}
              formErrors={elasticFormErrors}
              setFormErrors={setElasticFormErrors}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RowDetail;
