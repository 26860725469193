import React from "react";
import { selectAuthUser } from "../../reducers/session";
import { useAppSelector } from "../../store/hooks";
import { ProfileType } from "../../enums/profileType";
import TimeWorkerProfileContent from "./Profiles/timeWorkerProfileContent";
import ACWorkerProfileContent from "./Profiles/acWorkerProfileContent";
import ACSuperiorProfileContent from "./Profiles/acSuperiorProfileContent";
import TimeSuperiorProfileContent from "./Profiles/timeSuperiorProfileContent";

function Profile() {
  const authUser = useAppSelector(selectAuthUser);
  let acSuperiorProfiles: ProfileType[] = [];
  acSuperiorProfiles.push(ProfileType.SUPERIOR_AC);
  acSuperiorProfiles.push(ProfileType.AGENT);
  acSuperiorProfiles.push(ProfileType.RECEPTION);
  acSuperiorProfiles.push(ProfileType.GUARD);
  acSuperiorProfiles.push(ProfileType.PFSO);
  acSuperiorProfiles.push(ProfileType.SHIFT_DIRECTOR);
  acSuperiorProfiles.push(ProfileType.SECURITY);
  acSuperiorProfiles.push(ProfileType.EMPLOYEE);
  acSuperiorProfiles.push(ProfileType.OHS);
  acSuperiorProfiles.push(ProfileType.OPERATIONAL_SUPPORT);
  acSuperiorProfiles.push(ProfileType.INTEGRATOR);
  acSuperiorProfiles.push(ProfileType.EVENTS_PREVIEW);
  acSuperiorProfiles.push(ProfileType.LOCKERS_MANAGEMENT);
  acSuperiorProfiles.push(ProfileType.SMS_ADMINISTRATOR);
  acSuperiorProfiles.push(ProfileType.SMS_USER);

  return (
    <>
      <div className="flexAndCenter">
        {authUser?.currentProfile?.type === ProfileType.WORKER_TIME && (
          <TimeWorkerProfileContent />
        )}
        {authUser?.currentProfile?.type === ProfileType.WORKER_AC && (
          <ACWorkerProfileContent />
        )}
        {authUser?.currentProfile?.type === ProfileType.SUPERIOR_TIME && (
          <TimeSuperiorProfileContent />
        )}
        {acSuperiorProfiles.includes(authUser?.currentProfile?.type) && (
          <ACSuperiorProfileContent />
        )}
      </div>
    </>
  );
}

export default Profile;
