export enum HolidayRequestTypeFunction {
  NONE = 0,
  HOME_OFFICE = 1,
  OCCASIONAL_HOME_OFFICE = 2,
  BACKOFF_DAYS_FROM_HOME_OFFICE_REQUEST = 3,
  DISMISSAL_DUE_TO_FORCE_MAJEURE = 4,
  UNPAID_LEAVE = 5,
  UNPAID_CARE = 6,
  PRIVILEGED_HOME_OFFICE = 7,
}
