import { Table } from "@devexpress/dx-react-grid-material-ui";
import styles from "./cellComponent.module.scss";

const TableCell: any = Table.Cell;

const isDayColumn = (column) => {
  if (column.match(/^\d{2}.\d{2}.\d{4}$/) !== null) {
    return true;
  }

  return false;
};

const DiffCell = ({ value, row, column, style, ...restProps }) => {
  let dayColumn = isDayColumn(column.name);
  let markCell = false;

  if (dayColumn) {
    let currentValue = row[column.name];
    let oldValue = undefined;

    if (
      row.hasOwnProperty("previousRow") &&
      row["previousRow"].hasOwnProperty(column.name)
    ) {
      oldValue = row.previousRow[column.name];
    }

    if (!row.firstRow && currentValue !== oldValue) {
      markCell = true;
    }
  }

  return (
    <TableCell {...restProps}>
      <div>
        {markCell ? (
          <span className={styles.diff}>{value}</span>
        ) : (
          <span>{value}</span>
        )}
      </div>
    </TableCell>
  );
};

const Cell = (props) => {
  const { column } = props;

  if (column.name !== "version" && column.name !== "modifiedBy") {
    return <DiffCell {...props} />;
  }

  return <Table.Cell {...props} />;
};

export default Cell;
