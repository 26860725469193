import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../PopupHelpers";
import PopupContentWhiteListCompany from "./PopupContentWhiteListCompany";

const PopupWhiteListCompany = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
  pathWhiteListCompanies,
  pathCompaniesInPorts,
}: {
  isOpen: boolean;
  rowId: number | undefined;
  closePopup: () => void;
  successCallback: () => void;
  pathWhiteListCompanies: string;
  pathCompaniesInPorts: string;
}) => {
  const { t } = useTranslation();
  const [bodyRequest, setBodyRequest] = useState<string | false>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (values: IAddUpdateWhiteListCompany) => {
    setErrorMessage("");
    setBodyRequest(JSON.stringify(values));
  };

  useEffect(() => {
    if (isOpen === false) {
      setErrorMessage("");
    }
  }, [isOpen]);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${pathWhiteListCompanies}${rowId !== undefined ? `/${rowId}` : ""}`,
    method: rowId !== undefined ? "PUT" : "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  return (
    <DialogStyled open={isOpen} onClose={closePopup}>
      <DialogTitleStyled title={t("add_company")} />
      <PopupContentWhiteListCompany
        rowId={rowId}
        onSubmit={onSubmit}
        isOpen={isOpen}
        pathWhiteListCompanies={pathWhiteListCompanies}
        pathCompaniesInPorts={pathCompaniesInPorts}
      />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupWhiteListCompany;
