import React, { useState, useEffect, useRef } from "react";
// import TextField from '@material-ui/core/TextField';
// import FormGroup from '@material-ui/core/FormGroup';
import Button from "../../../helpersComponents/Button/button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DatePickerStyled } from "../../../helpersComponents/MaterialUi/index";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import CircularProgress from "@material-ui/core/CircularProgress";
import computeDate from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchPost from "../../../../hooks/fetchHooks/post/useFetchPost";
import useFetchPut from "../../../../hooks/fetchHooks/put/useFetchPut";
import useCachedFetch from "../../../../hooks/useCachedFetch/cachedFetch";
import { AutocompleteStyled } from "../../../helpersComponents/MaterialUi";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CheckboxStyled } from "../../../helpersComponents/MaterialUi/index";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { useAppSelector } from "../../../../store/hooks";
import { selectTimeWorkersSuperiorArray } from "../../../../reducers/workersLists";

const Popup: React.FC = ({
  row,
  onApplyChanges,
  onCancelChanges,
  open,
}: any) => {
  const { t } = useTranslation();

  const [eventDate, setEventDate] = useState(new Date());
  const [eventDateError, setEventDateError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [putingPostingCircularProgress, setPutingPostingCircularProgress] =
    useState<boolean>(false);
  const [responseErrorMessage, setResponseErrorMessage] = useState<string>();
  const [ifFetchEventById, setIfFetchEventById] = useState(false);
  const [postRequestBody, setPostRequestBody] = useState<any>(false);
  const [putRequestBody, setPutRequestBody] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ifClearReturnedValue, setIfClearReturnedValue] =
    useState<boolean>(false);

  let postRequest = useFetchPost(
    "superior-time/events",
    postRequestBody,
    ifClearReturnedValue
  );
  let putRequest = useFetchPut(
    "superior-time/events/" + row.id,
    putRequestBody,
    ifClearReturnedValue
  );

  const [checkbox, setCheckbox] = React.useState({
    addSector: false,
  });
  const handleChangeCheckbox = (event) => {
    setCheckbox({ ...checkbox, [event.target.name]: event.target.checked });
  };

  const [selectedTimeWorker, setSelectedTimeWorker] = useState<any>(null);
  const [selectedTimeWorkerError, setSelectedTimeWorkerError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [selectedRoomSuperior, setSelectedRoomSuperior] = useState<any>(null);
  const [selectedRoomSuperiorError, setSelectedRoomSuperiorError] =
    useState<any>({
      isError: false,
      errorMessage: "",
    });
  const [selectedEventTypeSuperior, setSelectedEventTypeSuperior] =
    useState<any>(null);
  const [selectedEventTypeSuperiorError, setSelectedEventTypeSuperiorError] =
    useState<any>({
      isError: false,
      errorMessage: "",
    });
  const [isError, setIsError] = useState<boolean>(false);
  const eventByIdResponse = useFetchGet(
    "superior-time/events/" + row.id,
    ifFetchEventById && row.id ? true : false,
    ifClearReturnedValue
  );
  const [ifCheckForUpdateCache, setIfCheckForUpdateCache] =
    useState<boolean>(false);
  const timeWorkers = useAppSelector(selectTimeWorkersSuperiorArray);
  const roomsSuperior = useCachedFetch(
    "roomsSuperiorTime",
    ifCheckForUpdateCache,
    3600,
    false,
    ifClearReturnedValue
  );
  const eventTypesSuperior = useCachedFetch(
    "eventTypesSuperiorTime",
    ifCheckForUpdateCache,
    3600,
    false,
    ifClearReturnedValue
  );

  useEffect(() => {
    if (row.hasOwnProperty("id")) {
      setIsLoading(true);
      setIfFetchEventById(true);
    }

    if (roomsSuperior === "error" || eventTypesSuperior === "error") {
      setIsError(true);
      setIfCheckForUpdateCache(false);
      return;
    }
    if (roomsSuperior === null || eventTypesSuperior === null) return;
    if (!row.hasOwnProperty("id")) setIsLoading(false);
    if (eventByIdResponse.error !== null) {
      setIsError(true);
      setIfFetchEventById(false);
      return;
    }
    if (eventByIdResponse.data !== null && row.hasOwnProperty("id")) {
      setIfFetchEventById(false);
      setEventDate(new Date(eventByIdResponse.data.time));
      setSelectedTimeWorker(timeWorkers.find((v) => row.timeWorkerId === v.id));
      setSelectedEventTypeSuperior(
        eventTypesSuperior.find((v) => row.typeId === v.id)
      );
      const room = roomsSuperior.find((v) => row.roomId === v.id);
      setSelectedRoomSuperior(room ? room : null);
      setIsLoading(false);
    }
  }, [
    row,
    eventByIdResponse,
    timeWorkers,
    roomsSuperior,
    eventTypesSuperior,
    t,
  ]);

  const applyChanges = useRef(onApplyChanges);
  useEffect(() => {
    if (postRequest.data?.status === 201) {
      applyChanges.current(true);
      setPutingPostingCircularProgress(false);
    } else if (putRequest.data?.status === 200) {
      applyChanges.current();
      setPutingPostingCircularProgress(false);
    } else if (postRequest.data?.status === 400) {
      let error = "";
      Object.keys(postRequest.data.errors).forEach((errorObject) => {
        postRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(error);
    } else if (putRequest.data?.status === 400) {
      let error = "";
      Object.keys(putRequest.data.errors).forEach((errorObject) => {
        putRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(error);
    } else if (putRequest.error !== null) {
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(t("something_went_wrong"));
    } else if (postRequest.error !== null) {
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(t("something_went_wrong"));
    }
  }, [postRequest, putRequest, t]);

  const confirm = () => {
    let ifFetch = true;
    let eventDateLocal = computeDate(eventDate);

    if (eventDate === null) {
      setEventDateError({
        isError: true,
        errorMessage: t("invalid_date"),
      });
      ifFetch = false;
    } else if (isNaN(eventDate.getTime())) {
      setEventDateError({
        isError: true,
        errorMessage: t("invalid_date"),
      });
      ifFetch = false;
    }
    if (selectedRoomSuperior === null) {
      setSelectedRoomSuperiorError({
        isError: true,
        errorMessage: "",
      });
      ifFetch = false;
    }
    if (selectedTimeWorker === null) {
      setSelectedTimeWorkerError({
        isError: true,
        errorMessage: "",
      });
      ifFetch = false;
    }
    if (selectedEventTypeSuperior === null) {
      setSelectedEventTypeSuperiorError({
        isError: true,
        errorMessage: "",
      });
      ifFetch = false;
    }
    if (!ifFetch) return;

    setPutingPostingCircularProgress(true);
    setResponseErrorMessage("");

    if (row.hasOwnProperty("id")) {
      setPutRequestBody({
        timeWorkerId: selectedTimeWorker.id,
        time: eventDateLocal,
        typeId: selectedEventTypeSuperior.id,
        roomId: selectedRoomSuperior.id,
      });
      // ifLoadDialog = false
    } else {
      setPostRequestBody({
        timeWorkerId: selectedTimeWorker.id,
        time: eventDateLocal,
        typeId: selectedEventTypeSuperior.id,
        roomId: selectedRoomSuperior.id,
        addSector: 1,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancelChanges}
      TransitionProps={{
        onExited: () => {
          setIfFetchEventById(false);
          setEventDate(new Date());
          setIsLoading(true);
          setPostRequestBody(false);
          setPutRequestBody(false);
          setResponseErrorMessage("");
          setIsError(false);
          setPutingPostingCircularProgress(false);
          setEventDateError({
            isError: false,
            errorMessage: "",
          });
          setIfClearReturnedValue(true);
          setSelectedRoomSuperior(null);
          setSelectedEventTypeSuperior(null);
          setSelectedTimeWorker(null);
          setSelectedRoomSuperiorError({
            isError: false,
            errorMessage: "",
          });
          setSelectedEventTypeSuperiorError({
            isError: false,
            errorMessage: "",
          });
          setSelectedTimeWorkerError({
            isError: false,
            errorMessage: "",
          });
          setIfCheckForUpdateCache(false);
        },
        onEnter: () => {
          setIfClearReturnedValue(false);
          setIfCheckForUpdateCache(true);
        },
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className="dialogTitleGlobal" onClick={() => {}}>
        <div className="titlePopup">{t("adding_event")}</div>
        <div className="description">{t("adding_an_application")}</div>
      </DialogTitle>

      <DialogContent>
        <LoadingWrapper
          isLodadingProgress={isLoading}
          isError={isError}
          setIfFetchAgain={(fetchAgain) => {
            setIfFetchEventById(fetchAgain);
            setIfCheckForUpdateCache(fetchAgain);
          }}
          setIsError={setIsError}
          bigSize={true}
        >
          {!isLoading && !isError && (
            <div>
              <div className="eventPopupSuperior">
                <div>
                  <div className="titleWidthEventSuperior">{t("date")}:</div>
                  <div className="inputWidthEventSuperior">
                    <DatePickerStyled
                      keyboardDateTimePicker={true}
                      view={[
                        "year",
                        "month",
                        "date",
                        "hours",
                        "minutes",
                        "second",
                      ]}
                      format="dd-MM-yyyy HH:mm:ss"
                      date={eventDate}
                      isError={eventDateError.isError}
                      setDate={setEventDate}
                    />
                  </div>
                </div>
                <div>
                  <div className="titleWidthEventSuperior">{t("room")}:</div>
                  <div className="inputWidthEventSuperior">
                    <AutocompleteStyled
                      required={true}
                      options={roomsSuperior}
                      getOptionLabel={(option: any) => {
                        return option.name;
                      }}
                      width="100%"
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      value={selectedRoomSuperior ? selectedRoomSuperior : null}
                      isError={selectedRoomSuperiorError.isError}
                      onChange={(_, newValue) => {
                        setSelectedRoomSuperior(newValue);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setSelectedRoomSuperiorError({
                            isError: true,
                            errorMessage: "",
                          });
                        } else {
                          setSelectedRoomSuperiorError({
                            isError: false,
                            errorMessage: "",
                          });
                        }
                      }}
                      label={t("room")}
                    />
                    {/* {selectedGuestError ? <div className="errorHolidayrequest">
                {t("select_guest")}

              </div>
                :
                null} */}
                  </div>
                </div>
                <div>
                  <div className="titleWidthEventSuperior">
                    {t("event_type")}:
                  </div>
                  <div className="inputWidthEventSuperior">
                    <AutocompleteStyled
                      required={true}
                      options={eventTypesSuperior}
                      getOptionLabel={(option: any) => {
                        return option.name;
                      }}
                      width="100%"
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      value={
                        selectedEventTypeSuperior
                          ? selectedEventTypeSuperior
                          : null
                      }
                      isError={selectedEventTypeSuperiorError.isError}
                      onChange={(_, newValue) => {
                        setSelectedEventTypeSuperior(newValue);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setSelectedEventTypeSuperiorError({
                            isError: true,
                            errorMessage: "",
                          });
                        } else {
                          setSelectedEventTypeSuperiorError({
                            isError: false,
                            errorMessage: "",
                          });
                        }
                      }}
                      label={t("event_type")}
                    />
                    {/* {selectedGuestError ? <div className="errorHolidayrequest">
                {t("select_guest")}

              </div>
                :
                null} */}
                  </div>
                </div>
                <div>
                  <div className="titleWidthEventSuperior">{t("worker")}:</div>
                  <div className="inputWidthEventSuperior">
                    <AutocompleteStyled
                      required={true}
                      options={timeWorkers}
                      getOptionLabel={(option: any) => {
                        return `${option.firstName} ${option.lastName} ${option.evidenceNumber}`;
                      }}
                      width="100%"
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      value={selectedTimeWorker ? selectedTimeWorker : null}
                      isError={selectedTimeWorkerError.isError}
                      onChange={(_, newValue) => {
                        setSelectedTimeWorker(newValue);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setSelectedTimeWorkerError({
                            isError: true,
                            errorMessage: "",
                          });
                        } else {
                          setSelectedTimeWorkerError({
                            isError: false,
                            errorMessage: "",
                          });
                        }
                      }}
                      label={t("worker")}
                    />
                    {/* {selectedGuestError ? <div className="errorHolidayrequest">
                {t("select_guest")}

              </div>
                :
                null} */}
                  </div>
                </div>

                {!row.hasOwnProperty("id") && (
                  <div>
                    <div className="titleWidthEventSuperior">
                      {/* {t("active")} */}
                    </div>
                    <div className="inputWidthEventSuperior">
                      <FormControlLabel
                        control={
                          <CheckboxStyled
                            checked={checkbox.addSector}
                            name="addSector"
                            onChange={handleChangeCheckbox}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label={
                          <span className="FormControlLabelStyle">
                            {t("add_as_current_event")}
                          </span>
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </LoadingWrapper>
      </DialogContent>

      <DialogActions className="dialogActionsGlobal">
        <div className="responseErrorMessage">{responseErrorMessage}</div>
        {!putingPostingCircularProgress ? (
          <>
            <Button onClick={onCancelChanges}>{t("cancel")}</Button>
            <Button
              onClick={() => {
                confirm();
              }}
            >
              {t("confirm")}
            </Button>
          </>
        ) : (
          <div className="confirmWaiting">
            <CircularProgress size={30} />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
