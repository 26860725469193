import React from "react";
import ReactDOM from "react-dom";
import "./css/index.scss";
import App from "./App";
import { i18n } from "./lang/i18n";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { I18nextProvider } from "react-i18next";
import { KeyboardStateProvider } from "./HelpersFunctions/KeyboardStateProvider";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <KeyboardStateProvider>
          <App />
        </KeyboardStateProvider>
      </I18nextProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
