import { TFunction } from "react-i18next";
import * as Yup from "yup";

const validationSchema = (t: TFunction<"translation">) => {
  const validationSchemaObj = Yup.object().shape({
    name: Yup.string().required(t("field_required")),
    company: Yup.object().nullable().required(t("field_required")),
  });

  return validationSchemaObj;
};

export default validationSchema;
