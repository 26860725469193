import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import * as Yup from "yup";
import {
  Row,
  RowInput,
  RowTitle,
} from "../../../helpersComponents/PopupHelpers";
import {
  AutocompleteFormik,
  TextFieldFormik,
} from "../../../helpersComponents/FormikInputs";

interface IProps {
  rowId?: number;
  onSubmit: (values: IAddEditReaderLine) => void;
}

const PopupContentProductionLines: React.FC<IProps> = ({ rowId, onSubmit }) => {
  const { t } = useTranslation();

  const [fetchedRow, fetchingStateFetchedRow, fetchAgainFetchedRow] =
    useFetchAndSetGET<IReaderLine>({
      path: `superior-time/readers-lines/${rowId}`,
      startFetchOnInitial: rowId ? true : false,
    });

  const [readers, fetchingStateReaders, fetchAgainReaders] = useFetchAndSetGET<
    IReader[]
  >({
    path: "superior-time/readers",
  });

  return (
    <div>
      <LoadingWrapper
        isLodadingProgress={
          fetchingStateFetchedRow.isFetching || fetchingStateReaders.isFetching
        }
        isError={
          fetchingStateFetchedRow.isError || fetchingStateReaders.isError
        }
        setIfFetchAgain={() => {
          if (fetchingStateFetchedRow.isError) {
            fetchAgainFetchedRow();
          }
          if (fetchingStateReaders.isError) {
            fetchAgainReaders();
          }
        }}
      >
        {(fetchedRow || rowId === undefined) && readers && (
          <Formik
            initialValues={{
              reader: fetchedRow?.readerId
                ? readers.find((reader) => reader.id === fetchedRow.readerId)
                : null,
              lineName: fetchedRow?.lineName ? fetchedRow.lineName : "",
              order: fetchedRow?.order ? fetchedRow.order.toString() : "",
            }}
            onSubmit={(values) => {
              onSubmit(values);
            }}
            validationSchema={Yup.object({
              reader: Yup.object().nullable().required(t("field_required")),
              lineName: Yup.string().required(t("field_required")),
            })}
          >
            {({ setFieldValue }) => {
              return (
                <Form noValidate id="submitForm">
                  <Row>
                    <RowTitle>{t("reader")}:</RowTitle>
                    <RowInput width={350}>
                      <AutocompleteFormik
                        options={readers}
                        getOptionLabel={(option: IReader) => {
                          return `${option.serialNumber} - ${option.roomName}`;
                        }}
                        width="100%"
                        getOptionSelected={(
                          option: IReader,
                          value: IReader
                        ) => {
                          return option.id === value.id;
                        }}
                        label={t("reader")}
                        name="reader"
                        ListboxProps={{ style: { maxHeight: "12rem" } }}
                      />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle>{t("line_name")}:</RowTitle>
                    <RowInput width={350}>
                      <TextFieldFormik
                        label={t("line_name")}
                        name="lineName"
                        required={true}
                      />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle>{t("order")}:</RowTitle>
                    <RowInput width={350}>
                      <TextFieldFormik
                        label={t("order")}
                        name="order"
                        onChangeOwn={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const value = e.target.value;
                          if (isNaN(+value)) return;

                          setFieldValue("order", value);
                        }}
                      />
                    </RowInput>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default PopupContentProductionLines;
