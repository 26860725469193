import { Row, RowInput, RowTitle } from "../PopupHelpers";
import { DatePickerFormik } from "../FormikInputs";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { useEffect } from "react";

interface IProps {
  guestId: number | null;
  pathGetGuestTrainingDateExpiry: string;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

function PopupRequirementFunction({
  guestId,
  pathGetGuestTrainingDateExpiry,
  setFieldValue,
}: IProps) {
  const { t } = useTranslation();

  const [currentTrainingDateExpiry] = useFetchAndSetGET<ITrainingDateExpiry>({
    path: `${pathGetGuestTrainingDateExpiry}/${guestId}`,
    startFetchOnInitial: true,
  });

  useEffect(() => {
    if (setFieldValue) {
      if (currentTrainingDateExpiry && currentTrainingDateExpiry.expiryDate) {
        setFieldValue(
          "trainingExpiryDate",
          new Date(currentTrainingDateExpiry.expiryDate)
        );
      } else {
        setFieldValue("trainingExpiryDate", new Date());
      }
    }
  }, [currentTrainingDateExpiry, setFieldValue]);

  return (
    <Row>
      <RowTitle>{t("set_guest_training_date_expiry")}</RowTitle>
      <RowInput>
        <DatePickerFormik
          label={t("training_expiry_date")}
          name={`trainingExpiryDate`}
          minDate={new Date()}
          view={["year", "month", "date"]}
          format="dd-MM-yyyy"
          required={true}
        />
      </RowInput>
    </Row>
  );
}

export default PopupRequirementFunction;
