import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponent from "../../TableComponent/tableComponent";
import styles from "./EmployeesPopup.module.scss";

interface Props {
  unitId: number;
  isACSuperior: boolean;
}

const EmployeesInfoPopupContent: React.FC<Props> = ({
  unitId,
  isACSuperior,
}) => {
  const { t } = useTranslation();

  const columns = [
    { name: "photo", title: t("photo") },
    { name: "firstName", title: t("first_name") },
    { name: "lastName", title: t("last_name") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "position", title: t("position") },
  ];

  const modifyEmployeesResponseCallback = useCallback(
    (responseData: Worker[]) => {
      const modified = responseData?.map((row: Worker) => {
        return {
          ...row,
          photo: (
            <div className={styles.employeePhotoContainer}>
              {row.photo.toString() !== "" ? (
                <img
                  src={`data:image/jpeg;base64,${row.photo}`}
                  alt={`${row.firstName} ${row.lastName}`}
                  className={styles.employeePhoto}
                />
              ) : (
                <p>{"-"}</p>
              )}
            </div>
          ),
        };
      });

      return modified;
    },
    []
  );

  const [employees, fetchingStateEmployees, fetchAgainEmployees] =
    useFetchAndSetGET<CompanyStructureAC[] | CompanyStructureTime[]>({
      path: isACSuperior
        ? `superior-ac/ac-workers-from-department?departmentId=${unitId}`
        : `superior-time/time-workers-from-unit?organizationalUnitId=${unitId}`,
      modifyResponseCallback: modifyEmployeesResponseCallback,
    });

  return (
    <div>
      <div className={styles.tableEmployees}>
        <TableComponent
          fetchingState={{
            fetchAgain: fetchAgainEmployees,
            isError: fetchingStateEmployees.isError,
            isFetching: fetchingStateEmployees.isFetching,
          }}
          layoutSettingName="layoutCompanyStructureWorkers"
          rows={employees}
          columns={columns}
          backgroundColor="#fff"
          pageSize={8}
        />
      </div>
    </div>
  );
};

export default EmployeesInfoPopupContent;
