import { useAppDispatch } from "../../store/hooks";
import { useState, useEffect } from "react";
import { setAdvancedOptions } from "../../reducers/advancedOptions";
import useFetchGet from "../fetchHooks/get/useFetchGet";
import { useTranslation } from "react-i18next";

const useUpdateAdvancedOptions = (
  ifUpdateAdvancedOptions: boolean,
  ifSetEmpty = false
) => {
  const dispatch = useAppDispatch();

  const advancedOptions = useFetchGet(
    "advanced-options",
    ifUpdateAdvancedOptions
  );

  const { i18n } = useTranslation();
  const [ifAdvancedOptionsUpdated, setIfAdvancedOptionsUpdated] =
    useState<any>(false);

  useEffect(() => {
    if (ifSetEmpty) {
      dispatch(setAdvancedOptions({}));
    }
  }, [dispatch, ifSetEmpty]);

  useEffect(() => {
    if (
      ifUpdateAdvancedOptions === false &&
      ifAdvancedOptionsUpdated?.error !== true
    ) {
      setIfAdvancedOptionsUpdated(false);
    }
  }, [ifUpdateAdvancedOptions, ifAdvancedOptionsUpdated]);

  useEffect(() => {
    if (advancedOptions.error === 401) {
      dispatch(setAdvancedOptions({ ifSettingsFetched: false }));
      return setIfAdvancedOptionsUpdated({ error: true });
    }

    if (
      advancedOptions.data?.status === 400 ||
      advancedOptions.error !== null
    ) {
      dispatch(setAdvancedOptions({ error: true }));
      return setIfAdvancedOptionsUpdated({ error: true });
    }

    if (advancedOptions.data === null) return;

    let settingsObject: any = {};
    advancedOptions.data.forEach((value) => {
      settingsObject[value.name] = value;
    });

    settingsObject["ifAdvancedOptionsFetched"] = true;
    dispatch(setAdvancedOptions(settingsObject));
    setIfAdvancedOptionsUpdated(true);
  }, [advancedOptions, i18n, dispatch]);

  return ifAdvancedOptionsUpdated;
};

export default useUpdateAdvancedOptions;
