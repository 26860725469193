import React, { useEffect, useRef } from "react";
import "./scheduler.scss";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  Appointments,
  MonthView,
  Toolbar,
  SchedulerProps,
  DateNavigator,
  WeekView,
  ViewSwitcher,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";
import { Divider, Menu, MenuItem, withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import classNames from "clsx";
import { useTranslation } from "react-i18next";
import Button from "../Button/button";
import TableCell from "@material-ui/core/TableCell";
import { useState } from "react";
import compareDatesIgnoringTime from "../../../HelpersFunctions/dateAndTime/compareDatesIgnoringTime";
import AddHomeOfficePopup from "./Popups/AddHomeOfficePopup";
import { useKeyboardState } from "../../../HelpersFunctions/KeyboardStateProvider";
import { SchedulerContextMenuItemType } from "../../../enums/SchedulerContextMenuItemType";

const initialState = {
  mouseX: null,
  mouseY: null,
};

///////////// some style /////////

const styles: any = (theme) => ({
  cell: {
    position: "relative",
    userSelect: "none",
    verticalAlign: "top",
    padding: 0,
    height: 100,
    border: "1px solid #e7eaec",
    "&:first-child": {
      borderLeft: "none",
    },
    "&:last-child": {
      paddingRight: 0,
    },
    "tr:last-child &": {
      borderBottom: "none",
    },
  },
  text: {
    padding: "0.5em",
    textAlign: "center",
  },
  appointment: {
    borderRadius: "10px",
  },
});

/////////// get date range of current view //////////
const getRange = (date, currentViewName) => {
  if (currentViewName === "Month") {
    return {
      startDate: new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0),
      endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59),
    };
  }

  if (currentViewName === "CurrentWeek") {
    let firstDay = date.getDate() - date.getDay();
    let lastDay = firstDay + 6;
    return {
      startDate: new Date(
        date.getFullYear(),
        date.getMonth(),
        firstDay,
        0,
        0,
        0
      ),
      endDate: new Date(
        date.getFullYear(),
        date.getMonth(),
        lastDay,
        23,
        59,
        59
      ),
    };
  }
  if (currentViewName === "Day") {
    return {
      startDate: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0
      ),
      endDate: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59
      ),
    };
  }
};

////////// view switch component ///////////
const SwitcherComponent =
  (t, onChange: (viewName: "Month" | "CurrentWeek" | "Day") => void) => () => {
    return (
      <div className="changeView">
        <Button
          onClick={() => {
            onChange("Month");
          }}
        >
          {t("month")}
        </Button>
        <Button
          onClick={() => {
            onChange("CurrentWeek");
          }}
        >
          {t("week")}
        </Button>
        <Button
          onClick={() => {
            onChange("Day");
          }}
        >
          {t("day")}
        </Button>
      </div>
    );
  };

interface ShedulerInterface extends SchedulerProps {
  currentViewName: "Month" | "CurrentWeek" | "Day";
  currentDate: Date;
  components: any;
  setCurrentDate: React.Dispatch<Date>;
  setCurrentViewName: (data: "Month" | "CurrentWeek" | "Day") => void;
  contextMenuOptions?: {
    optionName: React.ReactNode;
    optionType: SchedulerContextMenuItemType;
    onClick: (
      selectedRange: SchedulerContextMenuCallbackData,
      additionalData?: any
    ) => void;
  }[];
}

function Sheduler({
  currentViewName,
  currentDate,
  data,
  components,
  setCurrentDate,
  setCurrentViewName,
  contextMenuOptions,
}: ShedulerInterface) {
  const { t } = useTranslation();
  const { isCtrlPressed, isShiftPressed } = useKeyboardState();
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);

  //////////// background color appointment ////////////
  let AppointmentsAppointment: any = Appointments.Appointment;
  const Appointment: any = ({ children, style, ...restProps }) => {
    let backgroundColor = "#1ab394";
    // if (restProps.data.backgroundColor === "white") return <Appointments.Appointment
    //   {...restProps}
    //   style={{
    //     ...style,
    //     minHeight: '20px',
    //     minWidth: '20px',
    //     display: "none",
    //   }}
    //   className="customAppointment"
    // >
    //   <></>
    // </Appointments.Appointment>
    if (restProps.data.backgroundColor)
      backgroundColor = restProps.data.backgroundColor;
    return (
      <AppointmentsAppointment
        {...restProps}
        style={{
          ...style,
          minHeight: "20px",
          minWidth: "20px",
          background: backgroundColor,
        }}
        className="customAppointment"
      >
        {components[restProps.data.id]}
      </AppointmentsAppointment>
    );
  };

  ////////////////// today button ///////////////
  const Today: any = ({ children, style, ...restProps }) => {
    return (
      <Button
        onClick={() => {
          if (
            currentDate.getFullYear() === new Date().getFullYear() &&
            currentDate.getDate() === new Date().getDate() &&
            currentDate.getMonth() === new Date().getMonth()
          )
            return;
          setCurrentDate(new Date());
        }}
      >
        {t("today")}
      </Button>
    );
  };

  //////////////// context menu functions ///////////

  const [state, setState] = useState<any>(initialState);

  const handleClick = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setState(initialState);
  };

  const getSelectedDays = (): Date[] => {
    let result: Date[] = [];

    for (let i = 0; i < selectedCells.length; i++) {
      if (selectedCells[i]) {
        let tempDate = new Date(new Date(currentDate).setDate(i + 1));
        tempDate.setHours(0, 0, 0, 0);
        result.push(tempDate);
      }
    }

    return result;
  };

  const getSelectedPoints = (): SchedulerSelectedDays => {
    let result: SchedulerSelectedDays = {
      ranges: [],
    };

    let startIndex: number | null = null;
    let endIndex: number | null = null;

    for (let i = 0; i < selectedCells.length; i++) {
      if (selectedCells[i] && startIndex === null) {
        startIndex = i;
      }

      if (selectedCells[i] && startIndex !== null) {
        endIndex = i;
      }

      if (
        startIndex !== null &&
        endIndex !== null &&
        (!selectedCells[i] || i === selectedCells.length - 1)
      ) {
        const startDate: Date = new Date(
          new Date(currentDate).setDate(startIndex + 1)
        );
        startDate.setHours(0, 0, 0);

        const endDate: Date = new Date(
          new Date(currentDate).setDate(endIndex + 1)
        );
        endDate.setHours(0, 0, 0);

        let selectedPoints: any = [];
        data?.forEach((element) => {
          let elementDate = new Date(element.startDate);

          if (
            elementDate.getFullYear() >= startDate.getFullYear() &&
            elementDate.getMonth() >= startDate.getMonth() &&
            elementDate.getDate() >= startDate.getDate() &&
            elementDate.getFullYear() <= endDate.getFullYear() &&
            elementDate.getMonth() <= endDate.getMonth() &&
            elementDate.getDate() <= endDate.getDate()
          ) {
            selectedPoints.push(element);
          }
        });

        result.ranges.push({
          startDate: startDate,
          endDate: endDate,
          selectedPoints: selectedPoints,
        });

        startIndex = null;
        endIndex = null;
      }
    }

    return result;
  };

  /*const getSelectedPoints = (): SchedulerSelectedDays => {
    let result: SchedulerSelectedDays = {
      ranges: [],
    };

    let startIndex: any = null;
    let endIndex: any = null;

    console.log(`_______________getSelectedPoints__________________`);
    console.log(`selectedCells: ${JSON.stringify(selectedCells)}`);
    console.log(`data: ${JSON.stringify(data)}`);

    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (selectedCells[i] && startIndex === null) {
          startIndex = i;
        }

        if (selectedCells[i] && startIndex !== null) {
          endIndex = i;
        }

        console.log(`startIndex: ${startIndex}, endIndex: ${endIndex}`);

        if (!selectedCells[i] || i === data.length - 1) {
          if (startIndex !== null && endIndex !== null) {
            const startDate: Date = new Date(data[startIndex].startDate);
            const endDate: Date = new Date(data[endIndex].endDate);
            const selectedPoints = data.slice(startIndex, endIndex + 1);

            result.ranges.push({
              startDate: startDate,
              endDate: endDate,
              selectedPoints: selectedPoints,
            });

            startIndex = null;
            endIndex = null;
          }
        }
      }
    }

    return result;
  };*/

  /*const getSelectedStartEndDate = (): SchedulerContextMenuCallbackData => {
    let dateArrayRangeCopy = [...selectedCells];
    let startDate: Date | null = null;
    let endDate: Date | null = null;

    dateArrayRangeCopy.forEach((isSelected, index) => {
      if (startDate !== null) return;
      if (isSelected === true) {
        startDate = new Date(new Date(currentDate).setDate(index));
        startDate.setHours(0, 0, 0);
      }
    });
    dateArrayRangeCopy.reverse();
    dateArrayRangeCopy.forEach((isSelected, index) => {
      if (endDate !== null) return;
      if (isSelected === true) {
        endDate = new Date(
          new Date(currentDate).setDate(dateArrayRangeCopy.length - index - 1)
        );
        endDate.setHours(23, 59, 59);
      }
    });
    let selectedPoints: { [key in any]: any }[] = [];

    if (startDate !== null && endDate !== null) {
      data?.forEach((element) => {
        if (startDate === null) return;
        if (endDate === null) return;
        let elementDate = new Date(element.startDate);

        if (
          elementDate.getFullYear() >= startDate.getFullYear() &&
          elementDate.getMonth() >= startDate.getMonth() &&
          elementDate.getDate() >= startDate.getDate() &&
          elementDate.getFullYear() <= endDate.getFullYear() &&
          elementDate.getMonth() <= endDate.getMonth() &&
          elementDate.getDate() <= endDate.getDate()
        ) {
          selectedPoints.push(element);
        }
      });
    }
    return { startDate, endDate, selectedPoints };
  };*/

  /////////////////////// selecting cells ////////////////////////
  const startSelectionIndexRef = useRef<number | null>(null);
  const endSelectionIndexRef = useRef<number | null>(null);

  const [selectedCells, setSelectedCells] = useState<any>([]);
  const [selectedCellsCopy, setSelectedCellsCopy] = useState<any>([]);

  useEffect(() => {
    let range: any = getRange(currentDate, "Month");
    let dateArray: any = [];
    let dt = new Date(range.startDate);
    while (dt <= range.endDate) {
      dateArray.push(false);
      dt.setDate(dt.getDate() + 1);
    }

    setSelectedCells([...dateArray]);
    setSelectedCellsCopy([...dateArray]);
  }, [currentDate]);

  const handleCellSelect = (e, index) => {
    if (e.buttons !== 1) return;

    let newSelectedCells = [...selectedCells];
    let tempNewShiftSelectedCells = Array(selectedCells.length).fill(false);

    if (isCtrlPressed) {
      // Obsługa zaznaczania pojedynczych komórek z użyciem Ctrl
      newSelectedCells[index] = !newSelectedCells[index];
    } else if (isShiftPressed) {
      if (startSelectionIndexRef.current !== null) {
        endSelectionIndexRef.current = index;

        const start = Math.min(
          startSelectionIndexRef.current,
          endSelectionIndexRef.current!
        );
        const end = Math.max(
          startSelectionIndexRef.current,
          endSelectionIndexRef.current!
        );

        for (let i = start; i <= end; i++) {
          tempNewShiftSelectedCells[i] = true;
        }
      }
    } else {
      // Obsługa zaznaczania wielu komórek bez użycia Ctrl
      if (startSelectionIndexRef.current !== null) {
        endSelectionIndexRef.current = index;

        const start = Math.min(
          startSelectionIndexRef.current,
          endSelectionIndexRef.current!
        );
        const end = Math.max(
          startSelectionIndexRef.current,
          endSelectionIndexRef.current!
        );

        // Odznaczanie komórek, które nie są już w bieżącym zaznaczeniu
        for (let i = 0; i < newSelectedCells.length; i++) {
          if (i < start || i > end) {
            newSelectedCells[i] = false;
          }
        }

        // Zaznaczanie komórek w bieżącym zaznaczeniu
        for (let i = start; i <= end; i++) {
          newSelectedCells[i] = true;
        }
      } else {
        startSelectionIndexRef.current = index;
        endSelectionIndexRef.current = index;
        setSelectedCells(Array(selectedCells.length).fill(false));
        newSelectedCells[index] = true;
      }
    }

    if (isShiftPressed) {
      let newData: boolean[] = [...selectedCellsCopy];

      for (let i = 0; i < tempNewShiftSelectedCells.length; i++) {
        if (tempNewShiftSelectedCells[i]) {
          newData[i] = true;
        }
      }
      newSelectedCells = newData;
    }

    setSelectedCells(newSelectedCells);
  };

  ////////////// month view - cell /////////////
  const CellBase = ({ classes, startDate, formatDate, otherMonth }) => {
    const isFirstMonthDay = startDate.getDate() === 1;
    const formatOptions = isFirstMonthDay
      ? { day: "numeric", month: "long" }
      : { day: "numeric" };

    const dayNumber = startDate.getDate();
    const dayIndex = dayNumber - 1;

    // if day is not current month, display empty gray cell
    if (otherMonth)
      return (
        <TableCell tabIndex={0} className={classNames(classes.cell)}>
          <div className="backgronudDisabled">
            <div></div>
          </div>
        </TableCell>
      );

    return (
      <TableCell
        tabIndex={0}
        className={
          classNames(classes.cell) +
          (selectedCells[dayNumber - 1] ? " backgronudSelected" : "") +
          " cursorPointer"
        }
        onMouseDown={(e) => {
          if (
            !isShiftPressed ||
            (isShiftPressed && startSelectionIndexRef.current === null)
          ) {
            startSelectionIndexRef.current = dayIndex;
          }

          endSelectionIndexRef.current = dayIndex;

          if (!isShiftPressed) {
            setSelectedCellsCopy([...selectedCells]);
          }

          handleCellSelect(e, dayIndex);
        }}
        onMouseUp={(e) => {
          /*if (!isShiftPressed) {
            startSelectionIndexRef.current = null;
            endSelectionIndexRef.current = null;
          }*/
        }}
        onMouseEnter={(e) => {
          if (
            (startSelectionIndexRef.current !== null &&
              !isCtrlPressed &&
              !isShiftPressed) ||
            (isShiftPressed && startSelectionIndexRef.current !== null)
          ) {
            endSelectionIndexRef.current = dayIndex;
            handleCellSelect(e, dayIndex);
          }
        }}
      >
        <div className={classes.text}>
          {formatDate(startDate, formatOptions)}
        </div>
        <div
          className={"cellMonth"}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          {data?.map((value: any, i) => {
            if (compareDatesIgnoringTime(startDate, value.startDate)) {
              return (
                <span key={i} className="cursorPointer">
                  {components[value.id]}
                </span>
              );
            }
            return null;
          })}
        </div>
      </TableCell>
    );
  };

  const TimeTableCell: any = withStyles(styles, { name: "Cell" })(CellBase);

  //////////////// hide empty rows from other months ///////////
  const RowBase = ({ children }) => {
    let otherMonthLenght = 0;
    children.forEach((value) => {
      if (value.props.otherMonth) otherMonthLenght += 1;
    });
    if (otherMonthLenght === 7) {
      return <></>;
    }

    return <MonthView.TimeTableRow>{children}</MonthView.TimeTableRow>;
  };
  const TimeTableRow: any = withStyles(styles, { name: "Cell" })(RowBase);
  const [isAddHomeOfficePopupOpen, setIsAddHomeOfficePopupOpen] =
    useState<boolean>(false);

  const menuItems: any[] = [];

  contextMenuOptions?.forEach((singleMenu, index) => {
    menuItems.push(
      <MenuItem
        key={index}
        onClick={() => {
          handleClose();

          switch (singleMenu.optionType) {
            case SchedulerContextMenuItemType.ADD_HOME_OFFICE_REQUEST:
              let tempSelectedDays = getSelectedDays();

              if (tempSelectedDays.length > 0) {
                setSelectedDays(tempSelectedDays);
                setIsAddHomeOfficePopupOpen(true);
              }
              break;

            default:
              let selectedDaysDateRanges = getSelectedPoints();

              if (selectedDaysDateRanges.ranges?.length > 0) {
                singleMenu.onClick(selectedDaysDateRanges.ranges[0]);
              }
              break;
          }
        }}
      >
        {singleMenu.optionName}
      </MenuItem>
    );

    if (index !== menuItems.length - 1) {
      menuItems.push(<Divider key={`divider-${index}`} />);
    }
  });

  return (
    <Paper onContextMenu={handleClick}>
      <Scheduler
        data={data}
        locale={t("scheduler_language")}
        firstDayOfWeek={1}
      >
        <ViewState
          currentViewName={
            currentViewName === "CurrentWeek" ? "Week" : currentViewName
          }
          currentDate={currentDate}
          onCurrentDateChange={setCurrentDate}
        />
        <MonthView
          timeTableCellComponent={TimeTableCell}
          timeTableRowComponent={TimeTableRow}
        />

        <WeekView
          startDayHour={0}
          endDayHour={24}
          intervalCount={1}
          cellDuration={60}
        />
        <DayView
          name="Day"
          startDayHour={0}
          endDayHour={24}
          intervalCount={1}
          cellDuration={60}
        />
        <Toolbar />
        <ViewSwitcher
          switcherComponent={SwitcherComponent(t, setCurrentViewName)}
        />

        <DateNavigator />
        <TodayButton buttonComponent={Today} />
        {currentViewName !== "Month" ? (
          <Appointments appointmentComponent={Appointment} />
        ) : null}
      </Scheduler>

      {contextMenuOptions && contextMenuOptions?.length > 0 && (
        <Menu
          keepMounted
          open={state.mouseY !== null}
          onClose={() => {
            handleClose();
          }}
          anchorReference="anchorPosition"
          anchorPosition={
            state.mouseY !== null && state.mouseX !== null
              ? { top: state.mouseY, left: state.mouseX }
              : undefined
          }
        >
          {menuItems}
        </Menu>
      )}

      {selectedDays && isAddHomeOfficePopupOpen && (
        <AddHomeOfficePopup
          isOpen={isAddHomeOfficePopupOpen}
          closePopup={() => {
            setIsAddHomeOfficePopupOpen(false);
          }}
          days={selectedDays}
        />
      )}
    </Paper>
  );
}

export default Sheduler;
