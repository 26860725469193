import FireReportContent from "../../helpersComponents/FireReport/fireReport";
import NavigationSecurity from "../NavigationSecurity/navigationSecurity";

function FireReportSecurity() {
  return (
    <div>
      <FireReportContent
        fireReportPath="security/fire-report"
        layoutSettingName="layoutSettingNameSecurityFireReport"
        navigation={NavigationSecurity}
        fireZonesPath="security/fire-zones"
        pathPreferences="security/preferences"
        onlyGuests={true}
        targetCompaniesPath="security/guests-visits-target-companies"
      />
    </div>
  );
}

export default FireReportSecurity;
