import React from "react";
import LockersContent from "./lockersContent";

function Lockers() {
  return (
    <div>
      <LockersContent />
    </div>
  );
}

export default Lockers;
