import styles from "./buildingFloors.module.scss";
import BuildingFloorItem from "./BuildingFloorItem";
import ButtonStyled from "../../../helpersComponents/Button/button";
import { useTranslation } from "react-i18next";

const BuildingFloors = ({ fields, append, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.buildingFloorsContainer}>
      <ButtonStyled
        onClick={() => {
          append({
            name: "",
            number: "",
            isDefault: false,
            map: null,
          });
        }}
      >
        {t("add")}
      </ButtonStyled>

      <div className={styles.buildingFloorsItemsContainer}>
        {fields.map((field, index) => (
          <BuildingFloorItem
            id={field.id}
            key={field.id}
            fields={fields}
            index={index}
            {...props}
          />
        ))}
      </div>
    </div>
  );
};

export default BuildingFloors;
