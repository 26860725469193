import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../../../../helpersComponents/Button/button";
import MoveCopyPopup from "./moveCopyPopup";
import styles from "./styles.module.scss";

interface Props {
  taskId: number;
  currentProjectId: number;
  setIfFetchAgain: React.Dispatch<boolean>;
}

export default function CopyMoveRoot({
  taskId,
  setIfFetchAgain,
  currentProjectId,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [isOpenCopyMovePopup, setIsOpenCopyMovePopup] =
    useState<boolean>(false);
  const [copyOrMove, setCopyOrMove] = useState<"copy" | "move">("copy");

  return (
    <>
      <div className={styles.extraActions}>
        <ButtonStyled
          onClick={() => {
            setCopyOrMove("copy");
            setIsOpenCopyMovePopup(true);
          }}
        >
          {t("copy_task")}
        </ButtonStyled>
        <ButtonStyled
          onClick={() => {
            setCopyOrMove("move");
            setIsOpenCopyMovePopup(true);
          }}
        >
          {t("move_task")}
        </ButtonStyled>
      </div>
      {isOpenCopyMovePopup && (
        <MoveCopyPopup
          isOpen={isOpenCopyMovePopup}
          setIsOpen={setIsOpenCopyMovePopup}
          copyOrMove={copyOrMove}
          taskId={taskId}
          currentProjectId={currentProjectId}
          setIfFetchAgain={setIfFetchAgain}
        />
      )}
    </>
  );
}
