import {
  faFire,
  faTable,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import NavLinks from "./NavLinks";
import NavPopups from "./NavPopups";
import styles from "./styles.module.scss";
import { selectSecurityNotificationMenuItemName } from "../../../reducers/notificationsSummary";
import { useAppSelector } from "../../../store/hooks";

interface IProps {
  existsOnWhiteList?: boolean;
}

const NavigationSecurity: React.FC<IProps> = ({ existsOnWhiteList }) => {
  const { t } = useTranslation();
  const notificationsMenuItemName = useAppSelector(
    selectSecurityNotificationMenuItemName
  );

  const links: NavItems = {
    activeBackgroundColor: "#2f2f9c",
    items: [
      {
        to: "/security/home",
        title: notificationsMenuItemName,
        icon: faUserFriends,
      },
      {
        to: "/security/white-list",
        title: t("white_list"),
        icon: faTable,
        backgroundColor: `${existsOnWhiteList ? "#b59ee6" : ""}`,
        color: `${existsOnWhiteList ? "black" : ""}`,
        textColor: `${existsOnWhiteList ? "black" : ""}`,
      },
      {
        to: "/security/fire-report",
        title: t("fire_report"),
        icon: faFire,
        color: "#ff3232",
      },
    ],
  };

  return (
    <>
      <div className={styles.navigationContainer}>
        <NavLinks {...links} />
        <NavPopups />
      </div>
    </>
  );
};

export default NavigationSecurity;
