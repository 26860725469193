import React, { ReactElement } from "react";
import styles from "./card.module.scss";

interface Props {
  title: React.ReactNode;
  content: React.ReactNode;
}

export default function Card({ title, content }: Props): ReactElement {
  return (
    <div className={styles.cardRoot}>
      <div className={styles.title}>
        <div>{title}</div>
      </div>
      <div className={styles.content}>
        <div>{content}</div>
      </div>
    </div>
  );
}
