import React from 'react';
import MessagesContent from "./messagesContent"


function Messages() {
    return (
        <div>
            <MessagesContent/>
        </div>
    );
}

export default Messages;
