import React from 'react';
import BonusesContent from "./bonuses"


function Bonuses() {
    return (
        <div>
            <BonusesContent/>
        </div>
    );
}

export default Bonuses;
