import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import PopupShareNotificationContent from "./PopupShareNotificationContent";

interface Props {
  notificationId: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  successCallback: () => void;
}

const PopupShareNotification = ({
  notificationId,
  isOpen,
  setIsOpen,
  successCallback,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog maxWidth={"xl"} open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle className="dialogTitleGlobal">
          <div className="titlePopup">{t("share_notification")}</div>
        </DialogTitle>

        <PopupShareNotificationContent
          notificationId={notificationId}
          setIsOpen={setIsOpen}
          successCallback={successCallback}
        />
      </Dialog>
    </>
  );
};

export default PopupShareNotification;
