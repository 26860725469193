import { FormControlLabel } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import { CheckboxStyled } from "../../MaterialUi";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  question: QuestionBasics;
  setUserAnswers: React.Dispatch<Answers>;
  mulitAnswer: boolean;
  userAnswers: Answers;
}

export default function SingleQuestion({
  question,
  setUserAnswers,
  mulitAnswer,
  userAnswers,
}: Props): ReactElement {
  const { i18n } = useTranslation();
  const [questionContent, setQuestionContent] = useState("");
  const [answerAPropertyName, setAnswerAPropertyName] = useState("");
  const [answerBPropertyName, setAnswerBPropertyName] = useState("");
  const [answerCPropertyName, setAnswerCPropertyName] = useState("");
  const [answerDPropertyName, setAnswerDPropertyName] = useState("");

  useEffect(() => {
    switch (i18n.language) {
      case "en":
        setQuestionContent(question.questionEN);
        break;

      case "de":
        setQuestionContent(question.questionDE);
        break;

      case "ru":
        setQuestionContent(question.questionRU);
        break;

      case "uk":
        setQuestionContent(question.questionUK);
        break;

      default:
        setQuestionContent(question.questionPL);
        break;
    }
  }, [
    i18n.language,
    question.questionPL,
    question.questionEN,
    question.questionDE,
    question.questionRU,
    question.questionUK,
  ]);

  useEffect(() => {
    setAnswerAPropertyName(`answerA_${i18n.language.toUpperCase()}`);
    setAnswerBPropertyName(`answerB_${i18n.language.toUpperCase()}`);
    setAnswerCPropertyName(`answerC_${i18n.language.toUpperCase()}`);
    setAnswerDPropertyName(`answerD_${i18n.language.toUpperCase()}`);
  }, [i18n.language]);

  const getPropertyName = (key: string) => {
    const parts = key.split("_");
    return parts[0];
  };

  return (
    <div className={styles.questionContainer}>
      <div className={styles.questionTitle}>{questionContent}</div>
      <div className={styles.questionAnswers}>
        {Object.keys(question).map((key) => {
          if (
            key !== answerAPropertyName &&
            key !== answerBPropertyName &&
            key !== answerCPropertyName &&
            key !== answerDPropertyName
          )
            return null;
          return (
            <div
              key={getPropertyName(key)}
              className={
                styles.questionSingleAnswer +
                " " +
                (userAnswers[question.id][getPropertyName(key)]
                  ? styles.selectedAnswer
                  : "")
              }
              onClick={() => {
                let userAnswersLocal = { ...userAnswers };
                if (mulitAnswer) {
                  userAnswersLocal[question.id] = {
                    ...userAnswersLocal[question.id],
                    [getPropertyName(key)]:
                      !userAnswersLocal[question.id][getPropertyName(key)],
                  };
                  setUserAnswers(userAnswersLocal);
                } else {
                  userAnswersLocal[question.id] = {
                    ...userAnswersLocal[question.id],
                    answerA: false,
                    answerB: false,
                    answerC: false,
                    answerD: false,
                    [getPropertyName(key)]: true,
                  };
                  setUserAnswers(userAnswersLocal);
                }
              }}
            >
              <FormControlLabel
                onClick={(e) => e.preventDefault()}
                control={
                  <CheckboxStyled
                    checked={userAnswers[question.id][getPropertyName(key)]}
                    name={getPropertyName(key)}
                    ifCircle={!mulitAnswer}
                  />
                }
                label={
                  <span className="FormControlLabelStyle">{question[key]}</span>
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
