import {
  faChartBar,
  faClipboardList,
  faGripLines,
  faHome,
  faInfo,
  faSearch,
  faSitemap,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { TimeSuperiorPermission } from "../../../enums/TimeSuperiorPermission";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";
import Employees from "../../superiorTime/administration/Employees/index";
import HolidaysLimit from "../../superiorTime/administration/HolidaysLimit";
import ScheduleSuperior from "../../superiorTime/administration/Schedule";
import HolidaysValidation from "../../superiorTime/analysis/HolidaysValidation";
import CompanyStructureSuperiorTime from "../../superiorTime/CompanyStructure";
import SuperiorTime from "../../superiorTime/HomePage/index";
import AnnualHolidaysPlan from "../../superiorTime/lists/AnnualHolidaysPlan";
import BusinessExitsSuperiorTime from "../../superiorTime/lists/BusinessExits/index";
import DelegationsList from "../../superiorTime/lists/delegations/DelegationsList/index";
import EventsSuperior from "../../superiorTime/lists/Events";
import GetOvertime from "../../superiorTime/lists/GetOvertime/index";
import HolidayPlans from "../../superiorTime/lists/holidayPlans";
import HolidaysRequests from "../../superiorTime/lists/HolidaysRequests";
import HoursRequests from "../../superiorTime/lists/HoursRequests";
import Presence from "../../superiorTime/lists/Presence";
import ReceivedTrainings from "../../superiorTime/lists/ReceivedTrainings";
import WorkersRecords from "../../superiorTime/lists/WorkersRecords/index";
import DetailedPresenceOnLines from "../../superiorTime/ProductionLines/DetailedPresenceOnLines";
import PeopleOnProductionLines from "../../superiorTime/ProductionLines/PeopleOnProductionLines";
import PresenceOnLine from "../../superiorTime/ProductionLines/PresenceOnLine";
import ProductionLines from "../../superiorTime/ProductionLines/ReadersLines";
import ScheduleInCalendar from "../../superiorTime/summary/ScheduleInCalendar/index";
import Summary from "../../superiorTime/summary/Summary/index";
import TimesSpentInRooms from "../../superiorTime/summary/TimesSpentInRooms";
import ListOfPermits from "../../superiorTime/lists/ListOfPermits";
import Notifications from "../../superiorTime/lists/Notifications";
import Substitution from "../../superiorTime/administration/Substitution";
import Profile from "../../UserPanel/profile";
import UniversalRequests from "../../superiorTime/lists/UniversalRequests";
import OperationsLogs from "../../superiorTime/lists/OperationsLogs";
import AttendanceReport from "../../superiorTime/summary/AttendanceReport/AttendanceReport";
import OperationsHistory from "../../superiorTime/summary/OperationsHistory/OperationsHistory";
import OvertimesSummary from "../../superiorTime/analysis/OvertimesSummary/OvertimesSummary";
import TimeInOrganizationalUnitsReport from "../../superiorTime/analysis/TimeInOrganizationalUnitsReport/TimeInOrganizationalUnitsReport";

const Empty = () => <div>No component</div>;

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );
  let foundProfile = authUser?.tokenDecoded?.profiles?.find(
    (el) =>
      el.permissionId &&
      (el.permissionId === TimeSuperiorPermission.ADMINISTRATOR_OF_EVERYTHING ||
        el.permissionId ===
          TimeSuperiorPermission.ADMINISTRATOR_OF_SELECTED_COMPANY)
  );

  let isAdminProfile = false;
  if (foundProfile) {
    isAdminProfile = true;
  }

  const navLinksArrayTimeWorker: navLinksArrayTimeWorkerInterface = {
    profileId: 1,
    routesArrayWithComponents: [
      {
        icon: faHome,
        name: t("home_page"),
        ifHidden: settings.WebUkryjStroneGlowno?.value === "1",
        singleLink: true,
        path: "/superior-time/home",
        component: () => <SuperiorTime />,
      },
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikRCPZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/superior-time/profile",
        component: () => <Profile />,
      },
      {
        icon: faSearch,
        name: t("lists"),
        ifHidden: settings.WebUkryjEwidencje?.value === "1",
        singleLink: false,
        path: [
          {
            name: t("events"),
            path: "/superior-time/lists/events",
            ifHidden: settings.WebUkryjEwidencjaZdarzenia?.value === "1",
            component: () => <EventsSuperior />,
          },
          {
            name: t("workers_records"),
            path: "/superior-time/lists/workers-records",
            ifHidden:
              settings.WebUkryjEwidencjaEwidencjaPracownikow?.value === "1",
            component: () => <WorkersRecords />,
          },
          {
            name: t("presence"),
            path: "/superior-time/lists/presence",
            ifHidden: settings.WebUkryjEwidencjaObecnosci?.value === "1",
            component: () => <Presence />,
          },
          {
            name: t("get_overtime"),
            path: "/superior-time/lists/get-overtime",
            ifHidden: true, //settings.WebUkryjEwidencjaZlecNadgodziny?.value === "1",
            component: () => <GetOvertime />,
          },
          {
            name: t("list_of_permits"),
            path: "/superior-time/lists/list-of-permits",
            ifHidden: settings.WebUkryjEwidencjaListaZezwolen?.value === "1",
            component: () => <ListOfPermits />,
          },
          {
            name: t("holidays_requests"),
            path: "/superior-time/lists/holidays-requests",
            ifHidden: settings.WebUkryjEwidencjaWnioskiUrlopowe?.value === "1",
            component: () => <HolidaysRequests />,
          },
          {
            name: t("list_of_hours"),
            path: "/superior-time/lists/list-of-hours",
            ifHidden:
              settings.WebUkryjEwidencjaListaWnioskowGodzin?.value === "1",
            component: () => <HoursRequests />,
          },
          {
            name: t("universal_requests"),
            path: "/superior-time/lists/universal-requests",
            ifHidden:
              settings.WebUkryjEwidencjaListaWnioskowUniwersalnych?.value ===
              "1",
            component: () => <UniversalRequests />,
          },
          {
            name: t("holiday_plans"),
            path: "/superior-time/lists/holiday-plans",
            ifHidden: settings.WebUkryjEwidencjaPlanyUrlopowe?.value === "1",
            component: () => <HolidayPlans />,
          },
          {
            name: t("annual_holidays_plans"),
            path: "/superior-time/annual-holidays-plan",
            ifHidden:
              settings.WebUkryjEwidencjaRocznePlanyUrlopowe?.value === "1",
            component: () => <AnnualHolidaysPlan />,
          },
          {
            name: t("list_of_delegations"),
            path: "/superior-time/lists/list-of-delegations",
            ifHidden: settings.WebUkryjEwidencjaListaDelegacji?.value === "1",
            component: () => <DelegationsList />,
          },
          {
            name: t("list_of_notifications"),
            path: "/superior-time/lists/list-of-notifications",
            ifHidden: settings.WebUkryjEwidencjaListaAwizacji?.value === "1",
            component: () => <Notifications />,
          },
          {
            name: t("business_exits"),
            path: "/superior-time/lists/business-exits",
            ifHidden: settings.WebUkryjEwidencjaWyjsciaSluzbowe?.value === "1",
            component: () => <BusinessExitsSuperiorTime />,
          },
          {
            name: t("list_of_sent_messages"),
            path: "/superior-time/lists/list-of-sent-messages",
            ifHidden: true,
            component: () => <Empty />,
          },
          {
            name: t("operations_logs"),
            path: "/superior-time/lists/operations-logs",
            ifHidden: settings.WebUkryjEwidencjaLogiOperacji?.value === "1",
            component: () => <OperationsLogs />,
          },
        ],
      },
      {
        icon: faClipboardList,
        name: t("summary"),
        ifHidden: settings.WebUkryjPodsumowania?.value === "1",
        singleLink: false,
        path: [
          {
            name: t("operations_history"),
            path: "/superior-time/operations-history",
            ifHidden:
              settings.WebUkryjPodsumowanieHistoriaOperacjiPrzelozonyRCP
                ?.value === "1",
            component: () => <OperationsHistory />,
          },
          {
            name: t("the_sum_of_times"),
            path: "/superior-time/summary/the-sum-of-times",
            ifHidden: true,
            component: () => <Empty />,
          },
          {
            name: t("summary_in_pivote_table"),
            path: "/superior-time/summary/summary-in-pivote-table",
            ifHidden: true,
            component: () => <Empty />,
          },
          {
            name: t("summary_many_employees"),
            path: "/superior-time/summary/summaries-of-many-employees",
            ifHidden:
              settings.WebUkryjPodsumowaniePodsumowanieWieluPracownikow
                ?.value === "1",
            component: () => <Summary />,
          },
          {
            name: t("received_trainings"),
            path: "/superior-time/summary/summaries-of-trainings",
            ifHidden:
              settings.WebUkryjPodsumowanieZakonczoneSzkolenia?.value === "1",
            component: () => <ReceivedTrainings />,
          },
          {
            name: t("heat_map"),
            path: "/superior-time/summary/heat-map",
            ifHidden: true,
            component: () => <Empty />,
          },
          {
            name: t("schedule_in_calendar"),
            path: "/superior-time/summary/schedule-in-calendar",
            ifHidden:
              settings.WebUkryjPodsumowanieHarmonogramWKalendarzu?.value ===
              "1",
            component: () => <ScheduleInCalendar />,
          },
          {
            name: t("times_spent_in_rooms"),
            path: "/superior-time/summary/times-spent-in-rooms",
            ifHidden:
              settings.WebUkryjPodsumowanieCzasSpedzonyWPomieszczeniach
                ?.value === "1",
            component: () => <TimesSpentInRooms />,
          },
          {
            name: t("attendance"),
            path: "/superior-time/summary/attendance-report",
            ifHidden:
              settings.WebUkryjPodsumowanieRaportFrekwencji?.value === "1",
            component: () => <AttendanceReport />,
          },
        ],
      },
      {
        icon: faChartBar,
        name: t("analyse"),
        ifHidden: true,
        singleLink: false,
        path: [
          {
            name: t("breaks"),
            path: "/superior-time/analyse/breaks",
            ifHidden: false,
            component: () => <Empty />,
          },
          {
            name: t("time_and_breaks"),
            path: "/superior-time/analyse/time-and-breaks",
            ifHidden: false,
            component: () => <Empty />,
          },
        ],
      },
      {
        icon: faUserCog,
        name: t("administration"),
        ifHidden: settings.WebUkryjAdministracja?.value === "1",
        singleLink: false,
        path: [
          {
            name: t("employees"),
            path: "/superior-time/administration/employees",
            ifHidden: settings.WebUkryjAdministracjaPracownicy?.value === "1",
            component: () => <Employees />,
          },
          {
            name: t("holidays_limit"),
            path: "/superior-time/administration/holidays-limit",
            ifHidden:
              settings.WebUkryjAdministracjaWymiaryUrlopow?.value === "1",
            component: () => <HolidaysLimit />,
          },
          {
            name: t("schedules"),
            path: "/superior-time/administration/schedule",
            ifHidden: settings.WebUkryjAdministracjaHarmonogramy?.value === "1",
            component: () => <ScheduleSuperior />,
          },
          {
            name: t("schedule_horizontaly"),
            path: "/superior-time/administration/schedule-horizontaly",
            ifHidden: true,
            component: () => <Empty />,
          },
          {
            name: t("substitution"),
            path: "/superior-time/administration/substitutions",
            ifHidden: settings.WebUkryjAdministracjaZastepstwo,
            component: () => <Substitution />,
          },
        ],
      },
      {
        icon: faChartBar,
        name: t("analyse"),
        ifHidden: settings.WebUkryjAnalizy?.value === "1",
        singleLink: false,
        path: [
          {
            name: t("holidays_validation"),
            path: "/superior-time/analysis/holidays-validation",
            ifHidden: settings.WebUkryjAnalizyKontrolaUrlopow?.value === "1",
            component: () => <HolidaysValidation />,
          },
          {
            name: t("overtimes_summary"),
            path: "/superior-time/analyse/overtimes-summary",
            ifHidden:
              settings.WebUkryjAnalizyPodsumowanieNadgodzin?.value === "1",
            component: () => <OvertimesSummary />,
          },
          {
            name: t("time_in_organizational_units_report"),
            path: "/superior-time/analyse/time-in-organizational-units-report",
            ifHidden:
              settings.WebUkryjAnalizyRaportCzasowWKomorkachOrganizacyjnych
                ?.value === "1",
            component: () => <TimeInOrganizationalUnitsReport />,
          },
        ],
      },
      {
        icon: faSitemap,
        name: t("company_structure"),
        ifHidden: settings?.WebUkryjStrukturaFirmy?.value === "1",
        singleLink: true,
        path: "/superior-time/company-structure",
        component: () => <CompanyStructureSuperiorTime />,
      },
      {
        icon: faGripLines,
        name: t("production_lines"),
        ifHidden:
          !isAdminProfile || settings.WebUkryjLinieProdukcyjne?.value === "1",
        singleLink: false,
        path: [
          {
            name: t("production_lines"),
            path: "/superior-time/production-lines/readers-lines",
            ifHidden:
              settings.WebUkryjLinieProdukcyjneLinieProdukcyjne?.value === "1",
            component: () => <ProductionLines />,
          },
          {
            name: t("people_on_production_lines"),
            path: "/superior-time/production-lines/people-on-production-lines",
            ifHidden:
              settings.WebUkryjLinieProdukcyjneOsobyNaLiniachProdukcyjnych
                ?.value === "1",
            component: () => <PeopleOnProductionLines />,
          },
          {
            name: t("presence_on_line"),
            path: "/superior-time/production-lines/presence-on-line",
            ifHidden:
              settings.WebUkryjLinieProdukcyjneObecnoscNaLinii?.value === "1",
            component: () => <PresenceOnLine />,
          },
          {
            name: t("detailed_presence_on_lines"),
            path: "/superior-time/production-lines/detailed-presence-on-lines",
            ifHidden:
              settings.WebUkryjLinieProdukcyjneSzczegolowaObecnoscNaLiniach
                ?.value === "1",
            component: () => <DetailedPresenceOnLines />,
          },
        ],
      },
    ],
  };

  return navLinksArrayTimeWorker;
};
export default useNavLinksArray;
