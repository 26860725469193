import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import StyledBlock from "../helpersComponents/StyledBlock/styledBlock";
import { TopBanner } from "../helpersComponents/TopBanner/topBanner";
import ChangePasswordForm from "./changePasswordForm";

function ChangePassword({ token }: { token?: object }) {
  const { t } = useTranslation();
  const history = useHistory();
  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );

  useEffect(() => {
    if (!authUser?.token && !token) {
      history.push({
        pathname: "/login",
      });
    }
  }, [token, history, authUser?.token]);

  return (
    <div>
      {token === undefined && <TopBanner pathName={t("change_password")} />}
      <div className="flexAndCenter">
        <div>
          <StyledBlock title="">
            <ChangePasswordForm
              token={authUser.token}
              onClose={() => {
                history.replace("/home");
              }}
            />
          </StyledBlock>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
