import React from "react";
import LockersEventsHistoryContent from "./lockersEventsHistoryContent";

function LockersEventsHistory() {
  return (
    <div>
      <LockersEventsHistoryContent />
    </div>
  );
}

export default LockersEventsHistory;
