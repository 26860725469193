import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

const initialState: any = {
  absenceTypes: [0, null],
  timeWorkersWorkerTime: [0, null],
  timeWorkersSuperiorTime: [0, null],
  acWorkersSuperiorAc: [0, null],
  companyNotifications: [0, null],
  conferenceRoomNotifications: [0, null],
  departmentNotifications: [0, null],
  guestNotifications: [0, null],
  parkingNotifications: [0, null],
  typesOfVisits: [0, null],
  roomsSuperiorTime: [0, null],
  roomsSuperiorAc: [0, null],
  eventTypesSuperiorTime: [0, null],
  eventTypesSuperiorAc: [0, null],
};

export const cachedFetchReducer = createSlice({
  name: "setCachedFetch",
  initialState,
  reducers: {
    setAbsenceTypes: (state, action: PayloadAction<any>) => {
      state.absenceTypes = action.payload;
    },
    setTimeWorkersWorkerTime: (state, action: PayloadAction<any>) => {
      state.timeWorkersWorkerTime = action.payload;
    },
    setTimeWorkersSuperiorTime: (state, action: PayloadAction<any>) => {
      state.timeWorkersSuperiorTime = action.payload;
    },
    setAcWorkersSuperiorAc: (state, action: PayloadAction<any>) => {
      state.acWorkersSuperiorAc = action.payload;
    },
    setCompanyNotifications: (state, action: PayloadAction<any>) => {
      state.companyNotifications = action.payload;
    },
    setConferenceRoomNotifications: (state, action: PayloadAction<any>) => {
      state.conferenceRoomNotifications = action.payload;
    },
    setDepartmentNotifications: (state, action: PayloadAction<any>) => {
      state.departmentNotifications = action.payload;
    },
    setGuestNotifications: (state, action: PayloadAction<any>) => {
      state.guestNotifications = action.payload;
    },
    setParkingNotifications: (state, action: PayloadAction<any>) => {
      state.parkingNotifications = action.payload;
    },
    setTypesOfVisits: (state, action: PayloadAction<any>) => {
      state.typesOfVisits = action.payload;
    },
    setRoomsSuperiorTime: (state, action: PayloadAction<any>) => {
      state.roomsSuperiorTime = action.payload;
    },
    setRoomsSuperiorAc: (state, action: PayloadAction<any>) => {
      state.roomsSuperiorAc = action.payload;
    },
    setEventTypesSuperiorTime: (state, action: PayloadAction<any>) => {
      state.eventTypesSuperiorTime = action.payload;
    },
    setEventTypesSuperiorAc: (state, action: PayloadAction<any>) => {
      state.eventTypesSuperiorAc = action.payload;
    },
  },
});

export const selectAbsenceTypes = (state: RootState) =>
  state.cachedFetchState.absenceTypes;
export const selectTimeWorkersWorkerTime = (state: RootState) =>
  state.cachedFetchState.timeWorkersWorkerTime;
export const selectTimeWorkersSuperiorTime = (state: RootState) =>
  state.cachedFetchState.timeWorkersSuperiorTime;
export const selectAcWorkersSuperiorAc = (state: RootState) =>
  state.cachedFetchState.acWorkersSuperiorAc;
export const selectCompanyNotifications = (state: RootState) =>
  state.cachedFetchState.companyNotifications;
export const selectConferenceRoomNotifications = (state: RootState) =>
  state.cachedFetchState.conferenceRoomNotifications;
export const selectDepartmentNotifications = (state: RootState) =>
  state.cachedFetchState.departmentNotifications;
export const selectGuestNotifications = (state: RootState) =>
  state.cachedFetchState.guestNotifications;
export const selectParkingNotifications = (state: RootState) =>
  state.cachedFetchState.parkingNotifications;
export const selectTypesOfVisits = (state: RootState) =>
  state.cachedFetchState.typesOfVisits;
export const selectRoomsSuperiorTime = (state: RootState) =>
  state.cachedFetchState.roomsSuperiorTime;
export const selectRoomsSuperiorAc = (state: RootState) =>
  state.cachedFetchState.roomsSuperiorAc;
export const selectEventTypesSuperiorTime = (state: RootState) =>
  state.cachedFetchState.eventTypesSuperiorTime;
export const selectEventTypesSuperiorAc = (state: RootState) =>
  state.cachedFetchState.eventTypesSuperiorAc;
export const { setRoomsSuperiorTime } = cachedFetchReducer.actions;
export const { setRoomsSuperiorAc } = cachedFetchReducer.actions;
export const { setEventTypesSuperiorTime } = cachedFetchReducer.actions;
export const { setEventTypesSuperiorAc } = cachedFetchReducer.actions;
export const { setAbsenceTypes } = cachedFetchReducer.actions;
export const { setTimeWorkersWorkerTime } = cachedFetchReducer.actions;
export const { setTimeWorkersSuperiorTime } = cachedFetchReducer.actions;
export const { setAcWorkersSuperiorAc } = cachedFetchReducer.actions;
export const { setCompanyNotifications } = cachedFetchReducer.actions;
export const { setConferenceRoomNotifications } = cachedFetchReducer.actions;
export const { setDepartmentNotifications } = cachedFetchReducer.actions;
export const { setGuestNotifications } = cachedFetchReducer.actions;
export const { setParkingNotifications } = cachedFetchReducer.actions;
export const { setTypesOfVisits } = cachedFetchReducer.actions;
export default cachedFetchReducer.reducer;
