import { useTranslation } from "react-i18next";
import "./messages.scss";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { decrementMessages } from "../../../reducers/checkIfNewMessages";
import { useEffect } from "react";
import useFetchPatch from "../../../hooks/fetchHooks/patch/useFetchPatch";
import { useState } from "react";
import { selectAuthUser } from "../../../reducers/session";

const RowDetail: React.FC = ({ row }: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [patchBody, setPatchBody] = useState<any>(false);
  // const responseStatusUpdate =
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  useFetchPatch(`worker-time/${authUserId}/messages/` + row.id, patchBody);

  useEffect(() => {
    if (patchBody !== false) return;
    if (!row.isRead) {
      setPatchBody([
        {
          op: "replace",
          path: "/isRead",
          value: true,
        },
      ]);
      dispatch(decrementMessages());
      row.isRead = true;
    }
  }, [row, patchBody, dispatch]);

  // useEffect(() => {
  //     if (responseStatusUpdate.data === 200) {

  //     }
  // }, [responseStatusUpdate, patchBody, dispatch, row])

  return (
    <div className="detailsMessages">
      <div>
        <div className="messagesFromTo">
          <div>
            <b>{t("message_from")}:</b> {row.timeSuperiorLogin}
          </div>
          <div>
            <b>{t("message_to")}:</b> {row.timeWorkerFirstName}{" "}
            {row.timeWorkerLastName}
          </div>
        </div>
        <div>
          <b>{t("post_date")}:</b> {row.sentAtString}
        </div>
        <div>
          <b>{t("message_title")}:</b> {row.messageTitle}
        </div>
      </div>
      <div className="messageContent">{row.messageContent}</div>
    </div>
  );
};

export default RowDetail;
