import React from 'react';
import KeyPermissionsContent from "./keyPermissionsContent"


function KeyPermissions() {
    return (
        <div>
            <KeyPermissionsContent/>
        </div>
    );
}

export default KeyPermissions;
