import React from "react";
import HolidaysValidationContent from "./holidaysValidationContent";

function HolidaysValidation() {
  return (
    <div>
      <HolidaysValidationContent />
    </div>
  );
}

export default HolidaysValidation;
