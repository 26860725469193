import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../PopupHelpers";
import PopupContentSupportEmployee from "./PopupContentSupport";

const PopupSupport = ({
  pathApiListsOfEmployees,
  pathApiListOfEmployeesEmploye,
  isOpen,
  listOfEmployeesId,
  employeeId,
  closePopup,
  fetchAgainListsOfEmployees,
}: {
  pathApiListsOfEmployees: string;
  pathApiListOfEmployeesEmploye: string;
  isOpen: boolean;
  listOfEmployeesId?: number;
  employeeId?: number;
  closePopup: () => void;
  fetchAgainListsOfEmployees: () => void;
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [requestBody, setRequestBody] = useState<false | any>(false);

  const successCallbackAndClose = useCallback(() => {
    fetchAgainListsOfEmployees();
    closePopup();
  }, [fetchAgainListsOfEmployees, closePopup]);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: employeeId
      ? `${pathApiListOfEmployeesEmploye}/${employeeId}`
      : `${pathApiListOfEmployeesEmploye}`,
    method: employeeId ? "PUT" : "POST",
    body: requestBody,
    contentType: "application/json",
    setBody: setRequestBody,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, fetchAgainPostData]);

  const onSubmit = (values: IEditEmployeeInListOfEmployees) => {
    setRequestBody(JSON.stringify(values));
  };

  return (
    <DialogStyled open={isOpen} onClose={closePopup}>
      <DialogTitleStyled title={t("lists_of_employees")} />
      <PopupContentSupportEmployee
        onSubmit={onSubmit}
        employeeId={employeeId}
        listOfEmployeesId={listOfEmployeesId}
        pathApiListsOfEmployees={pathApiListsOfEmployees}
      />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupSupport;
