import { useDrag } from "react-dnd";
import styles from "./../mapArrangment.module.scss";

interface IProps {
  id: number;
  text: string;
}

const SystemObjectItem: React.FC<IProps> = ({ id, text }) => {
  const [, drag] = useDrag({
    type: "SYSTEM_OBJECT_ITEM",
    item: { id, text },
  });

  return (
    <div ref={drag} className={styles["system-object-item"]}>
      {text}
    </div>
  );
};

export default SystemObjectItem;
