import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../helpersComponents/PopupHelpers";
import * as Yup from "yup";
import { AutocompleteFormik } from "../../helpersComponents/FormikInputs";

interface Props {
  onSubmit: (values: { permissionZone: PermissionZone }) => void;
  selectedPermissionZoneId: number | null;
  permissionZonesPathApi: string;
}

const PopupPermissionZoneContent: React.FC<Props> = ({
  onSubmit,
  selectedPermissionZoneId,
  permissionZonesPathApi,
}) => {
  const { t } = useTranslation();

  const [
    permissionZones,
    fetchingStatePrmissionZones,
    fetchingAgainPrmissionZones,
  ] = useFetchAndSetGET({
    path: permissionZonesPathApi,
  });

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={fetchingStatePrmissionZones.isFetching}
        isError={fetchingStatePrmissionZones.isError}
        setIfFetchAgain={() => {
          if (fetchingStatePrmissionZones.isError) {
            fetchingAgainPrmissionZones();
          }
        }}
      >
        {permissionZones && (
          <DialogContentStyled titleWidth={200}>
            <Formik
              initialValues={{
                permissionZone: selectedPermissionZoneId
                  ? permissionZones.find(
                      (zone: PermissionZone) =>
                        zone.id === selectedPermissionZoneId
                    )
                  : null,
              }}
              onSubmit={(values) => {
                onSubmit(values);
              }}
              validationSchema={Yup.object({
                permissionZone: Yup.object()
                  .nullable()
                  .required(t("field_required")),
              })}
            >
              <Form noValidate id="submitForm">
                <Row>
                  <RowTitle>{t("permission_zone")}:</RowTitle>
                  <RowInput>
                    <AutocompleteFormik
                      options={permissionZones}
                      getOptionLabel={(option: PermissionZone) => {
                        return option.name;
                      }}
                      width="100%"
                      getOptionSelected={(
                        option: PermissionZone,
                        value: PermissionZone
                      ) => {
                        return option.id === value.id;
                      }}
                      label={t("select_permission_zone")}
                      name="permissionZone"
                      required={true}
                    />
                  </RowInput>
                </Row>
              </Form>
            </Formik>
          </DialogContentStyled>
        )}
      </LoadingWrapper>
    </>
  );
};

export default PopupPermissionZoneContent;
