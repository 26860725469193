import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../PopupHelpers";
import PopupPermissionZoneContent from "./PopupPermissionZoneContent";

interface Props {
  onCancelChanges: () => void;
  open: boolean;
  successCallback: () => void;
  pathApi: string;
  permissionZonesPathApi: string;
  selectedPermissionZoneId: number | null;
  pathNameToVariable?: string;
}

const PopupPermissionZone: React.FC<Props> = ({
  onCancelChanges,
  open,
  successCallback,
  pathApi,
  permissionZonesPathApi,
  selectedPermissionZoneId,
  pathNameToVariable,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (values: { permissionZone: PermissionZone }) => {
    setErrorMessage("");
    let body: PathProperty[] = [];
    body.push({
      path: pathNameToVariable ? pathNameToVariable : "/permissionZoneId",
      op: "replace",
      value: values.permissionZone.id,
    });

    setBodyRequest(JSON.stringify(body));
  };

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [fetchingState, fetchAgain] = useFetchOtherThanGET({
    path: pathApi,
    method: "PATCH",
    body: bodyRequest,
    setBody: setBodyRequest,
    contentType: "application/json",
    successCallback,
  });

  useEffect(() => {
    if (open === false) {
      setErrorMessage("");
    }
  }, [open]);

  useEffect(() => {
    if (bodyRequest) {
      fetchAgain();
    }
  }, [bodyRequest, fetchAgain]);

  return (
    <>
      <DialogStyled open={open} onClose={onCancelChanges}>
        <DialogTitleStyled title={t("permission_zone")} />
        <PopupPermissionZoneContent
          onSubmit={onSubmit}
          selectedPermissionZoneId={selectedPermissionZoneId}
          permissionZonesPathApi={permissionZonesPathApi}
        />
        <DialogActionsStyled
          typConfirm="submit"
          formConfirm="submitForm"
          onCancel={onCancelChanges}
          isLoading={fetchingState.isFetching}
          errorString={errorMessage}
        />
      </DialogStyled>
    </>
  );
};

export default PopupPermissionZone;
