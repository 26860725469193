import React from "react";
import LockersAssignmentHistoryContent from "./lockersAssignmentHistoryContent";

function LockersAssignmentHistory() {
  return (
    <div>
      <LockersAssignmentHistoryContent />
    </div>
  );
}

export default LockersAssignmentHistory;
