import { useEffect, useReducer, useRef } from "react";
import fetchFile from "./fetchFile";
import useLogOut from "../../LogOut/logOut";
import { saveAs } from "file-saver";

const useFetchFileReducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { data: null, isLoading: true, error: null };
    case "SUCCESS":
      return { data: action.payload, isLoading: false, error: null };
    case "ERROR":
      return { data: null, isLoading: false, error: action.payload };
    case "RESET":
      return { data: null, isLoading: null, error: null };
  }
  return state;
};

const useFetchFile = (
  path: string,
  attachmentIdAndFileName: [false | string, string],
  ifFetch: boolean = true,
  ifResetReturnedValue: boolean = false
) => {
  const [response, dispatch] = useReducer(useFetchFileReducer, {
    data: null,
    isLoading: true,
    error: null,
  });
  useLogOut(response.error);
  let isMounted = useRef(true);
  useEffect(() => {
    if (ifResetReturnedValue) dispatch({ type: "RESET" });
  }, [ifResetReturnedValue]);

  useEffect(() => {
    if (!ifFetch) return;

    if (attachmentIdAndFileName[0] === false) return;
    let finalPath = path + "/" + attachmentIdAndFileName[0];
    dispatch({ type: "FETCHING" });
    fetchFile(finalPath)
      .then((data) => {
        if (isMounted.current) {
          dispatch({ type: "SUCCESS", payload: data });
          saveAs(data, attachmentIdAndFileName[1]);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          dispatch({ type: "ERROR", payload: error });
        }
      });
  }, [path, attachmentIdAndFileName, ifFetch]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return response;
};

export default useFetchFile;
