import DelegationsListContent from "./delegationsListContent";

function DelegationsList() {
  return (
    <div>
      <DelegationsListContent />
    </div>
  );
}

export default DelegationsList;
