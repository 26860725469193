import React from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteFormik,
  TextFieldFormik,
} from "../../../../../helpersComponents/FormikInputs";
import {
  Row,
  RowInput,
  RowTitle,
} from "../../../../../helpersComponents/PopupHelpers";
import styles from "./GroupDataForm.module.scss";

interface IProps {
  companies: Company[];
}

const GroupDataForm: React.FC<IProps> = ({ companies }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <Row>
          <RowTitle>{t("name")}:</RowTitle>
          <RowInput width={350}>
            <TextFieldFormik label={t("name")} name="name" type="text" />
          </RowInput>
        </Row>

        <Row>
          <RowTitle>{t("company")}:</RowTitle>
          <RowInput width={350}>
            <AutocompleteFormik
              options={companies}
              getOptionLabel={(option: Company) => {
                return option.name;
              }}
              width="100%"
              getOptionSelected={(option: Department, value: Department) => {
                return option.id === value.id;
              }}
              label={t("company")}
              name="company"
              ListboxProps={{ style: { maxHeight: "12rem" } }}
            />
          </RowInput>
        </Row>

        <Row>
          <RowTitle>{t("description")}:</RowTitle>
          <RowInput width={350}>
            <TextFieldFormik
              label={t("description")}
              name="description"
              type="text"
              multiline
            />
          </RowInput>
        </Row>
      </div>
    </div>
  );
};

export default GroupDataForm;
