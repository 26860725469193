import React, { ReactElement } from "react";
import styles from "./popupHelpers.module.scss";

interface Props {
  children: React.ReactNode | [React.ReactNode, React.ReactNode];
  style?: React.CSSProperties;
}

export default function TwoColumnsRowsContainer({
  children,
  style,
}: Props): ReactElement {
  return (
    <div className={styles.twoRowsContainer} style={style}>
      {children}
    </div>
  );
}
