import React from 'react';
import AddressBookContent from "./addressBookContent"


function AddressBook() {
    return (
        <div>
            <AddressBookContent/>
        </div>
    );
}

export default AddressBook;
