const TooltipContent = (eventModifiedValue, typeIdClassName, t) => {
  return (
    <div className="tooltipEvents">
      <div
        className={"tooltipHeaderEvents " + typeIdClassName}
        style={{
          backgroundColor:
            eventModifiedValue.typeId >= 100000
              ? eventModifiedValue.backgroundColor
              : "",
        }}
      >
        {eventModifiedValue.roomName}
      </div>
      <div className="tooltipContentEvents">
        <div>
          <div>{t("event")}: </div>
          <div>
            <b>{eventModifiedValue.title}</b>
          </div>
        </div>
        <div>
          <div>{t("date")}: </div>
          <div>
            <b>
              {eventModifiedValue.startDate.toLocaleString(
                t("scheduler_language"),
                {
                  weekday: "long",
                  month: "long",
                  year: "numeric",
                  day: "2-digit",
                }
              )}
            </b>
          </div>
        </div>
        <div>
          <div>{t("hour")}: </div>
          <div>
            <b>
              {eventModifiedValue.startDate.toLocaleString(
                t("scheduler_language"),
                {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                }
              )}
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TooltipContent;
