import { DialogContent } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./homePage.module.scss";
import Button from "../../helpersComponents/Button/button";
import { TextFieldStyled } from "../../helpersComponents/MaterialUi";
import useFetchPatch from "../../../hooks/fetchHooks/patch/useFetchPatch";
import { useSnackbar } from "notistack";
import computeDate from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import TextEditor from "../../helpersComponents/TextEditor/textEditor";
import { selectAuthUser } from "../../../reducers/session";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setFetchAgainTasks } from "../../../reducers/homePage";

interface Props {
  setIsDialogOpen: React.Dispatch<boolean>;
  endingTask: any;
}

export default function AddEditProjectDialogContent({
  setIsDialogOpen,
  endingTask,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [name, setName] = useState<String>("");
  const [nameError, setNameError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [editorContent, setEditorContent] = useState("");
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;

  const [patchBody, setPatchBody] = useState<any>(false);
  const [putingPostingCircularProgress, setPutingPostingCircularProgress] =
    useState<boolean>(false);

  const responseStatusUpdate = useFetchPatch(
    `worker-time/${authUserId}/projects/0/tasks/${endingTask.id}`,
    patchBody
  );

  const { enqueueSnackbar } = useSnackbar();
  const [responseErrorMessage, setResponseErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (responseStatusUpdate.data?.status === 200) {
      setIsDialogOpen(false);
      enqueueSnackbar(t("task_completed"), { variant: "success" });
      setPutingPostingCircularProgress(false);
      dispatch(setFetchAgainTasks(true));
    } else if (responseStatusUpdate.data?.status === 400) {
      let error = "";
      Object.keys(responseStatusUpdate.data.errors).forEach((errorObject) => {
        responseStatusUpdate.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setResponseErrorMessage(error);
    } else if (responseStatusUpdate.error !== null) {
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(t("something_went_wrong"));
    }
  }, [responseStatusUpdate, enqueueSnackbar, setIsDialogOpen, dispatch, t]);

  const confirm = () => {
    let ifFetch = true;

    if (name === "") {
      ifFetch = false;
      setNameError({
        isError: true,
        errorMessage: "",
      });
    } else {
      setNameError({
        isError: false,
        errorMessage: "",
      });
    }

    let description = "";
    if (editorContent) description = editorContent;
    if (ifFetch === false) return;
    // return;
    setPutingPostingCircularProgress(true);

    setPatchBody([
      {
        op: "replace",
        path: "/status",
        value: 2,
      },
      {
        op: "replace",
        path: "/name",
        value: name,
      },
      {
        op: "replace",
        path: "/description",
        value: description,
      },
      {
        op: "replace",
        path: "/dateTo",
        value: computeDate(new Date()),
      },
    ]);
  };

  return (
    <>
      <DialogContent>
        <div className={styles.rows}>
          <div className={styles.formRow}>
            <div className={styles.rowtitle}>{t("name")}:</div>
            <div className={styles.rowInput}>
              <TextFieldStyled
                label={t("name")}
                multiline
                defaultValue={name}
                required={true}
                isError={nameError.isError}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setNameError({
                      isError: true,
                      errorMessage: "required",
                    });
                  } else {
                    setNameError({
                      isError: false,
                      errorMessage: "",
                    });
                  }
                  setName(e.target.value);
                }}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.editor}>
              <TextEditor
                htmlContent={editorContent}
                setHtmlContent={setEditorContent}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="dialogActionsGlobal">
        <div className="responseErrorMessage">{responseErrorMessage}</div>
        {!putingPostingCircularProgress ? (
          <>
            <Button onClick={() => setIsDialogOpen(false)}>
              {t("cancel")}
            </Button>
            <Button
              onClick={() => {
                confirm();
              }}
            >
              {t("confirm")}
            </Button>
          </>
        ) : (
          <div className={styles.confirmWaiting}>
            <CircularProgress size={30} />
          </div>
        )}
      </DialogActions>
    </>
  );
}
