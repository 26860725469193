import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useCachedFetch from "../../../../hooks/useCachedFetch/cachedFetch";
import {
  AutocompleteFormik,
  DatePickerFormik,
} from "../../../helpersComponents/FormikInputs";
import CheckboxWithLabelFormik from "../../../helpersComponents/FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../../helpersComponents/PopupHelpers";

const PopupContentEvents = ({
  onSubmit,
  rowId,
}: {
  rowId?: number;
  onSubmit: (values) => void;
}) => {
  const { t } = useTranslation();

  const [fetchedEventRow, fetchingStateFetchedEventRow, fetchAgainFetchedRow] =
    useFetchAndSetGET({
      path: `superior-ac/events/${rowId}`,
      startFetchOnInitial: rowId ? true : false,
    });

  const [ifCheckForUpdateCache, setIfCheckForUpdateCache] =
    useState<boolean>(false);
  const workers = useCachedFetch(
    "acWorkersSuperiorAc",
    ifCheckForUpdateCache,
    3600,
    false
  );

  const roomsSuperior = useCachedFetch(
    "roomsSuperiorAc",
    ifCheckForUpdateCache,
    3600,
    false
  );
  const eventTypesSuperior = useCachedFetch(
    "eventTypesSuperiorAc",
    ifCheckForUpdateCache,
    3600,
    false
  );

  useEffect(() => {
    setIfCheckForUpdateCache(true);
  }, []);

  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    if (
      workers === "error" ||
      roomsSuperior === "error" ||
      eventTypesSuperior === "error"
    ) {
      setIsError(true);
      setIfCheckForUpdateCache(false);
      return;
    }

    if (
      workers === null ||
      roomsSuperior === null ||
      eventTypesSuperior === null
    )
      return;
  }, [setIsError, eventTypesSuperior, roomsSuperior, workers]);

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={fetchingStateFetchedEventRow.isFetching}
        isError={fetchingStateFetchedEventRow.isError || isError}
        setIfFetchAgain={() => {
          if (fetchingStateFetchedEventRow.isError || isError) {
            fetchAgainFetchedRow();
            setIfCheckForUpdateCache(true);
          }
        }}
      >
        {workers &&
          roomsSuperior &&
          eventTypesSuperior &&
          (fetchedEventRow || rowId === undefined) && (
            <DialogContentStyled>
              <Formik
                initialValues={{
                  eventDate: fetchedEventRow
                    ? new Date(fetchedEventRow.time)
                    : null,
                  worker: fetchedEventRow
                    ? workers.find((w) => w.id === fetchedEventRow.acWorkerId)
                    : null,
                  eventTypeSuperior: fetchedEventRow
                    ? eventTypesSuperior.find(
                        (e) => e.id === fetchedEventRow.typeId
                      )
                    : null,
                  roomSuperior: fetchedEventRow
                    ? roomsSuperior.find((r) => r.id === fetchedEventRow.roomId)
                    : null,
                  currentEvent: false,
                }}
                onSubmit={(values) => {
                  onSubmit(values);
                }}
                validationSchema={Yup.object({
                  eventDate: Yup.date()
                    .nullable()
                    .required(t("field_required"))
                    .typeError(t("invalid_date")),
                  worker: Yup.object().nullable().required(t("field_required")),
                  eventTypeSuperior: Yup.object()
                    .nullable()
                    .required(t("field_required")),
                  roomSuperior: Yup.object()
                    .nullable()
                    .required(t("field_required")),
                })}
              >
                {(formikProps) => (
                  <Form noValidate id="submitForm">
                    <Row>
                      <RowTitle>{t("date")}:</RowTitle>
                      <RowInput>
                        <DatePickerFormik
                          label={t("date")}
                          name="eventDate"
                          view={["year", "month", "date", "hours", "minutes"]}
                          format="dd-MM-yyyy HH:mm"
                          keyboardDateTimePicker={true}
                          required={true}
                        />
                      </RowInput>
                    </Row>

                    <Row>
                      <RowTitle>{t("room")}:</RowTitle>
                      <RowInput>
                        <AutocompleteFormik
                          options={roomsSuperior}
                          getOptionLabel={(option) => option.name}
                          width="100%"
                          getOptionSelected={(option, value) => {
                            return option.id === value.id;
                          }}
                          label={t("room")}
                          name="roomSuperior"
                          required={true}
                        />
                      </RowInput>
                    </Row>

                    <Row>
                      <RowTitle>{t("event_type")}:</RowTitle>
                      <RowInput>
                        <AutocompleteFormik
                          options={eventTypesSuperior}
                          getOptionLabel={(option) => option.name}
                          width="100%"
                          getOptionSelected={(option, value) => {
                            return option.id === value.id;
                          }}
                          label={t("event_type")}
                          name="eventTypeSuperior"
                          required={true}
                        />
                      </RowInput>
                    </Row>

                    <Row>
                      <RowTitle>{t("worker")}:</RowTitle>
                      <RowInput>
                        <AutocompleteFormik
                          options={workers}
                          getOptionLabel={(option) => {
                            return `${option.firstName} ${option.lastName} - ${option.evidenceNumber}`;
                          }}
                          width="100%"
                          getOptionSelected={(option, value) => {
                            return option.id === value.id;
                          }}
                          label={t("worker")}
                          name="worker"
                          required={true}
                        />
                      </RowInput>
                    </Row>

                    {!rowId && (
                      <Row>
                        <RowTitle>{t("current_event")}:</RowTitle>
                        <RowInput>
                          <CheckboxWithLabelFormik
                            name="currentEvent"
                            label={<span>{t("add_as_current_event")}</span>}
                          />
                        </RowInput>
                      </Row>
                    )}
                  </Form>
                )}
              </Formik>
            </DialogContentStyled>
          )}
      </LoadingWrapper>
    </>
  );
};

export default PopupContentEvents;
