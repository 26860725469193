import { TFunction } from "react-i18next";
import * as Yup from "yup";
import { TypeOfVisit } from "../../../Constants/typeOfVisitInPorts";

const getValidationSchema = (
  typeOfVisitId: number | undefined,
  t: TFunction<"translation">,
  permissionZoneId?: number | null
) => {
  if (typeOfVisitId === TypeOfVisit.COMPLETION_OF_THE_WORK) {
    return Yup.object({
      isWorkPermitRequiredFalse: Yup.bool().when("isWorkPermitRequiredTrue", {
        is: false,
        then: Yup.bool().isTrue(""),
        otherwise: Yup.bool(),
      }),
      isWorkParticularlyDangerousFalse: Yup.bool().when(
        "isWorkParticularlyDangerousTrue",
        {
          is: false,
          then: Yup.bool().isTrue(""),
          otherwise: Yup.bool(),
        }
      ),
      workArea: Yup.string().when(
        ["isWorkPermitRequiredTrue", "isWorkParticularlyDangerousTrue"],
        {
          is: (isWorkPermitRequiredTrue, isWorkParticularlyDangerousTrue) =>
            isWorkPermitRequiredTrue && isWorkParticularlyDangerousTrue,
          then: Yup.string().required(t("field_required")),
          otherwise: Yup.string(),
        }
      ),
      permissionZone: Yup.object()
        .nullable()
        .test("permissionZoneTest", t("field_required"), function (value) {
          if (permissionZoneId === null) {
            return value !== null;
          }
          return true;
        }),
    });
  } else {
    return Yup.object({
      permissionZone: Yup.object()
        .nullable()
        .test("permissionZoneTest", t("field_required"), function (value) {
          if (!permissionZoneId) {
            return value !== null;
          }
          return true;
        }),
    });
  }
};

export default getValidationSchema;
