import { Switch, withStyles } from "@material-ui/core";

const SwitchStyled = withStyles({
  switchBase: {
    color: "#cccccc",
    "&$checked": {
      color: "black",
    },
    "&$checked + $track": {
      backgroundColor: "black",
    },
  },
  checked: {},
  track: {
    backgroundColor: "black",
    "$checked$checked + &": {
      backgroundColor: "black",
    },
  },
})(Switch);

export default SwitchStyled;
