import React, { useState, useRef } from "react";
import styles from "./narrowNavbar.module.scss";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { colorClassNameDictionary } from "../ProfilesRouting/profilesDictionaries";

function NarrowNavTemplate(props) {
  const anchorRef: any = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const location = useLocation();
  const currentPath = location.pathname;
  let ifColorMenu = false;

  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );
  const selectedRouteClassName = colorClassNameDictionary[
    authUser.currentProfile?.type
  ]
    ? colorClassNameDictionary[authUser.currentProfile.type]
    : styles.selectedRouteSetGreen;

  ////// check if color menu icon ////
  if (props.linksInfo.singleLink) {
    if (currentPath === props.linksInfo.path) ifColorMenu = true;
  } else {
    props.linksInfo.path.forEach((path) => {
      if (currentPath === path.path) ifColorMenu = true;
    });
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  ////// check if is single link ////
  if (props.linksInfo.singleLink)
    return (
      <div className={styles.narrowNavTemplate}>
        <div>
          <Link
            className={styles.singleLinkNavigation}
            to={props.linksInfo.path}
          >
            <div
              className={styles.narrowNavbarIcon}
              ref={anchorRef}
              onClick={() => handleToggle()}
            >
              <FontAwesomeIcon
                icon={props.linksInfo.icon}
                className={ifColorMenu ? selectedRouteClassName : ""}
              />
            </div>
          </Link>
        </div>
      </div>
    );

  ////// check if all links are hidden ////
  let ifAllHidden = true;
  props.linksInfo.path.forEach((path) => {
    if (path.ifHidden === false) ifAllHidden = false;
  });
  if (ifAllHidden) return null;

  return (
    <div className={styles.narrowNavTemplate}>
      <div>
        <div
          className={styles.narrowNavbarIcon}
          ref={anchorRef}
          onClick={() => handleToggle()}
        >
          <FontAwesomeIcon
            icon={props.linksInfo.icon}
            className={ifColorMenu ? selectedRouteClassName : ""}
          />
        </div>

        <Popper
          className={styles.setInexZ}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="right-start"
        >
          {({ TransitionProps, placement }) => {
            return (
              <Grow {...TransitionProps}>
                <div className={styles.narrowNavOpenMenuContent}>
                  <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                    <MenuList autoFocusItem={open}>
                      <div className={styles.narroNavTitle}>
                        {props.linksInfo.name}
                      </div>
                      {props.linksInfo.path.map((link, index) => {
                        if (link.ifHidden) return null;
                        return (
                          <div key={index}>
                            <Link
                              className={styles.singleLinkNavigationNarrowNav}
                              to={link.path}
                            >
                              <MenuItem
                                onClick={(e) => {
                                  handleClose(e);
                                }}
                              >
                                <div className={styles.narrowNavLinkname}>
                                  <div>
                                    <FontAwesomeIcon
                                      className={
                                        "fa-xs " +
                                        (currentPath === link.path
                                          ? selectedRouteClassName
                                          : null)
                                      }
                                      icon={faCircle}
                                    />
                                  </div>
                                  <div>{link.name}</div>
                                </div>
                              </MenuItem>
                            </Link>
                          </div>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </div>
              </Grow>
            );
          }}
        </Popper>
      </div>
    </div>
  );
}

export default NarrowNavTemplate;
