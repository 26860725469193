import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { TextFieldFormik } from "../../../helpersComponents/FormikInputs";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../../helpersComponents/PopupHelpers";

interface Props {
  onSubmit: (values: { pin: number }) => void;
}

const PopupSetPinForACWorkersContent: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <DialogContentStyled>
      <Formik
        initialValues={{
          pin: 0,
        }}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validationSchema={Yup.object({
          pin: Yup.number().required(t("field_required")),
        })}
      >
        {({ setFieldValue }) => (
          <Form noValidate id="submitForm">
            <Row>
              <RowTitle>{t("pin")}:</RowTitle>
              <RowInput>
                <TextFieldFormik
                  label={t("PIN")}
                  name="pin"
                  required={true}
                  type="password"
                  onChangeOwn={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    if (isNaN(+value)) return;

                    setFieldValue("pin", value);
                  }}
                  inputProps={{
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              </RowInput>
            </Row>
          </Form>
        )}
      </Formik>
    </DialogContentStyled>
  );
};

export default PopupSetPinForACWorkersContent;
