import React, { ReactElement, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import styles from "./projectDetails.module.scss";
import stylesProject from "./project.module.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { useTranslation } from "react-i18next";
import CommentsRoot from "../CommentsComponents/commentsRoot";
import AddEditProject from "../addEditProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import RemoveComponent from "../removeComponent";
import ProjectCollaboratorsContent from "../CollaboratorsComponents/projectCollaboratorsContent";
import CollaboratorsList from "../CollaboratorsComponents/collaboratorsList";
import projectCreatePhotosArray from "../CollaboratorsComponents/projectCreatePhotosArray";
import { useHistory } from "react-router";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import getDifferenceInTwoDates from "../../../../HelpersFunctions/dateAndTime/getDifferenceInTwoDates";
import convertSecondsToDaysHrsMinsSeconds from "../../../../HelpersFunctions/dateAndTime/convertSecondsToDaysHrsMinsSeconds";
import CopyProjectRoot from "./CopyProject/copyProjectRoot";

interface Props {
  project: any;
  setIfFetchAgain: React.Dispatch<boolean>;
}

const useStyles = makeStyles({
  accordionSummaryRoot: {
    "&.MuiAccordionSummary-root": {
      padding: 0,
    },
  },
});

const createCollaborators = (project) => {
  let collaboratorsLocal: any = [];
  project.collaborators.forEach((collaborator) => {
    collaboratorsLocal.push({
      firstName: collaborator.firstName,
      lastName: collaborator.lastName,
      timeWorkerId: collaborator.id,
    });
  });
  return collaboratorsLocal;
};

export default function ProjectDetails({
  project,
  setIfFetchAgain,
}: Props): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [isOpenAddEditProject, setIsOpenAddEditProject] =
    useState<boolean>(false);
  const [ifCollaboratorsDialogOpen, setIfCollaboratorsDialogOpen] =
    useState<boolean>(false);
  const shareString: any = t("share");
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const editProjectString: any = t("edit_project");
  const history = useHistory();
  const [collaborators, setCollaborators] = useState([]);

  useEffect(() => {
    setCollaborators(createCollaborators(project));
  }, [project]);

  const [durationTimeTasks, setDurationTimeTasks] = useState<number | null>(
    null
  );
  const [durationTimeProject, setDurationTimeProject] = useState<number | null>(
    null
  );

  useEffect(() => {
    let durationTimeTasksLocal: number | null = null;
    project.tasks.forEach((task) => {
      if (task.dateFrom && task.dateTo) {
        if (durationTimeTasksLocal === null) durationTimeTasksLocal = 0;
        durationTimeTasksLocal +=
          getDifferenceInTwoDates(
            new Date(task.dateTo),
            new Date(task.dateFrom)
          ) / 6000;
      }
    });

    let durationTimeProjectLocal: number | null = null;
    if (project.dateFrom && project.dateTo) {
      durationTimeProjectLocal =
        getDifferenceInTwoDates(
          new Date(project.dateTo),
          new Date(project.dateFrom)
        ) / 6000;
    }
    setDurationTimeProject(durationTimeProjectLocal);
    setDurationTimeTasks(durationTimeTasksLocal);
  }, [project]);

  return (
    <div className={styles.projectDetailsContainer}>
      <Accordion expanded={open} onChange={() => setOpen(!open)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={
            stylesProject.accordionSummaryreverse +
            " " +
            classes.accordionSummaryRoot
          }
        >
          <div className={styles.accordingTitle}>
            <div>{t("details_of_project")}</div>
            <div className={styles.collaboratorRootContainer}>
              <CollaboratorsList
                photos={projectCreatePhotosArray(project.collaborators)}
                collaborators={collaborators}
              />
            </div>
            {/* <div className={styles.tasksData}>
              <TasksNumberAndDurationTime
                tasksNumber={project.tasks.length}
                durationTimeTasksSeconds={durationTimeTasks}
              />
            </div> */}
            <div
              onClick={(e) => e.stopPropagation()}
              className={styles.projectActions}
            >
              <div className={styles.projectAction}>
                <Tooltip title={editProjectString}>
                  <div
                    onClick={() => {
                      setIsOpenAddEditProject(true);
                    }}
                    className={styles.faEdit}
                  >
                    <div>
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                  </div>
                </Tooltip>
              </div>
              <div className={styles.projectAction}>
                <Tooltip title={shareString}>
                  <div
                    onClick={() => {
                      setIfCollaboratorsDialogOpen(true);
                    }}
                    className={styles.faUserFriends}
                  >
                    <div>
                      <FontAwesomeIcon icon={faUserPlus} />
                    </div>
                  </div>
                </Tooltip>
              </div>
              <div>
                <RemoveComponent
                  successCallBack={() =>
                    history.push({
                      pathname: "/worker-time/projects/home",
                    })
                  }
                  path={`worker-time/${authUserId}/projects/${project.id}`}
                  confirmDialogText={t(
                    `are_you_sure_you_want_to_delete_project_with_tasks`,
                    { projectName: project.name }
                  )}
                  snackbarSuccessText={t("project_deleted")}
                  ifStopPropagation={true}
                />
              </div>
              <span>
                <ProjectCollaboratorsContent
                  setIfFetchAgain={setIfFetchAgain}
                  project={project}
                  setIsDialogOpen={setIfCollaboratorsDialogOpen}
                  isDialogOpen={ifCollaboratorsDialogOpen}
                />
                <AddEditProject
                  setIfFetchAgain={setIfFetchAgain}
                  isOpen={isOpenAddEditProject}
                  setIsOpen={setIsOpenAddEditProject}
                  project={project}
                />
              </span>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.projectDetailsContent}>
            <div className={styles.row}>
              <div className={styles.rowTitle}>
                <b>{t("actions")}:</b>
              </div>
              <div className={styles.rowValue}>
                <CopyProjectRoot projectId={project.id} />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.rowTitle}>
                <b>{t("project_name")}:</b>
              </div>
              <div className={styles.rowValue}>
                {project.name ? project.name : "-"}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.rowTitle}>
                <b>{t("owner")}:</b>
              </div>
              <div className={styles.rowValue}>
                {project.ownerFirstName
                  ? `${project.ownerFirstName} ${project.ownerLastName}`
                  : "-"}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.rowTitle}>
                <b>{t("date_from")}:</b>
              </div>
              <div className={styles.rowValue}>
                {project.dateFrom
                  ? new Date(project.dateFrom).toLocaleString(
                      t("scheduler_language"),
                      {
                        weekday: "long",
                        month: "long",
                        year: "numeric",
                        day: "2-digit",
                      }
                    )
                  : "-"}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowTitle}>
                <b>{t("date_to2")}:</b>
              </div>
              <div className={styles.rowValue}>
                {project.dateTo
                  ? new Date(project.dateTo).toLocaleString(
                      t("scheduler_language"),
                      {
                        weekday: "long",
                        month: "long",
                        year: "numeric",
                        day: "2-digit",
                      }
                    )
                  : "-"}
              </div>
            </div>

            {durationTimeProject !== null && (
              <div className={styles.row}>
                <div className={styles.rowTitle}>
                  <b>{t("project_duration_time")}:</b>
                </div>
                <div className={styles.rowValue}>
                  {convertSecondsToDaysHrsMinsSeconds(durationTimeProject, t, {
                    ifHideSeconds: true,
                  })}
                </div>
              </div>
            )}

            <div className={styles.row}>
              <div className={styles.rowTitle}>
                <b>{t("number_of_tasks")}:</b>
              </div>
              <div className={styles.rowValue}>{project.tasks.length}</div>
            </div>

            {durationTimeTasks !== null && (
              <div className={styles.row}>
                <div className={styles.rowTitle}>
                  <b>{t("all_tasks_duration_time")}:</b>
                </div>
                <div className={styles.rowValue}>
                  {convertSecondsToDaysHrsMinsSeconds(durationTimeTasks, t, {
                    ifHideSeconds: true,
                  })}
                </div>
              </div>
            )}

            <div className={styles.row}>
              <div className={styles.rowTitle}>
                <b>{t("number")}:</b>
              </div>
              <div className={styles.rowValue}>
                {project.number ? project.number : "-"}
              </div>
            </div>

            <div className={styles.commentsRootContainer}>
              <CommentsRoot
                url={`worker-time/${authUserId}/projects/${project.id}/comments`}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
