import GuestsInPortsContent from "../../helpersComponents/GuestsInPorts/GuestsInPortsContent";

const GuestsInPortsOperationalSupport = () => {
  return (
    <GuestsInPortsContent
      pathApiGuests="operational-support/guests"
      layoutSettingName="layoutOperationalSupportGuestsRecords"
    />
  );
};

export default GuestsInPortsOperationalSupport;
