import DateFnsUtils from "@date-io/date-fns";
import { createTheme, Theme } from "@material-ui/core/styles";
import {
  DatePickerView,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";
import { ThemeProvider } from "@material-ui/styles";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import plLocale from "date-fns/locale/pl";
import ruLocale from "date-fns/locale/ru";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { colorNameDictionary } from "../../../ProfilesRouting/profilesDictionaries";
import { TextFieldStyled } from "../index";
import "./datePicker.scss";

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const localeMap = {
  plLocale: plLocale,
  ruLocale: ruLocale,
  enLocale: enLocale,
  deLocale: deLocale,
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface DatePickerComponentInterface {
  isError?: boolean;
  darkTheme?: boolean;
  keyboardDateTimePicker?: boolean;
  date: Date | null;
  view: any;
  minDate?: Date;
  maxDate?: Date;
  setDate: any;
  format: string;
  width?: number | string;
  openTo?: DatePickerView;
  name?: string;
  disabled?: boolean;
  disableToolbar?: boolean;
  customLabel?: string;
  required?: boolean;
}

function DatePickerComponent({
  isError,
  darkTheme,
  keyboardDateTimePicker,
  date,
  view,
  minDate,
  maxDate,
  setDate,
  format,
  width,
  openTo,
  name,
  disabled,
  disableToolbar,
  customLabel,
  required,
}: DatePickerComponentInterface) {
  const { t } = useTranslation();
  const [ifAnimation, setIfAnimation] = useState("animation");
  const prevIsError = usePrevious(isError);
  ///////// KeyboardDatePicker every time re-render TextFieldStyled, so there is code to
  //////// prevent unwanted extra animation every re-render
  useEffect(() => {
    if (isError === true && prevIsError === true) setIfAnimation("noAnimation");
    else setIfAnimation("animation");
  }, [isError, prevIsError]);

  let localDate: Date | null = date;
  const handleBlur = (e) => {
    if (
      date instanceof Date &&
      localDate?.getFullYear() === date?.getFullYear() &&
      localDate?.getMonth() === date?.getMonth() &&
      localDate?.getDate() === date?.getDate() &&
      localDate?.getHours() === date?.getHours() &&
      localDate?.getMinutes() === date?.getMinutes() &&
      localDate?.getSeconds() === date?.getSeconds()
    )
      return;

    setDate(localDate);
  };

  const [materialTheme, setMaterialTheme] = useState<Theme>(createTheme());
  useEffect(() => {
    let authUser: authUserInfo = JSON.parse(
      localStorage.getItem("authUser") || "{}"
    );

    const materialTheme = createTheme({
      overrides: {
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor:
              authUser !== undefined && Object.keys(authUser).length !== 0
                ? colorNameDictionary[authUser.currentProfile?.type]
                : "#1ab394",
          },
        },
        MuiTabs: {
          root: {
            backgroundColor:
              authUser !== undefined && Object.keys(authUser).length !== 0
                ? `${
                    colorNameDictionary[authUser.currentProfile?.type]
                  }!important`
                : "#1ab394!important",
          },
        },
      },
    });
    setMaterialTheme(materialTheme);
  }, []);

  if (keyboardDateTimePicker) {
    return (
      <>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={localeMap[t("date_picker_locale")]}
        >
          <ThemeProvider theme={materialTheme}>
            <KeyboardDateTimePicker
              value={date}
              views={view}
              autoOk={true}
              ampm={false}
              minDate={minDate}
              disableToolbar={disableToolbar}
              maxDate={maxDate}
              openTo={openTo ? openTo : "date"}
              // onChange={(date) => setDate(date)}
              onChange={(date) => {
                localDate = date;
              }}
              onBlur={handleBlur}
              onAccept={setDate}
              format={format}
              cancelLabel={t("cancel")}
              okLabel={t("confirm")}
              variant="dialog"
              label={customLabel ? customLabel : t("select_date")}
              helperText={null}
              TextFieldComponent={(params) => {
                return (
                  <TextFieldStyled
                    {...params}
                    variant="filled"
                    darkTheme={darkTheme}
                    disabled={disabled}
                    ifAnimation={ifAnimation}
                    required={required}
                    isError={isError}
                    name={name}
                  />
                );
              }}
              style={width ? { width: width } : { width: 300 }}
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </>
    );
  } else {
    return (
      <>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={localeMap[t("date_picker_locale")]}
        >
          <ThemeProvider theme={materialTheme}>
            <KeyboardDatePicker
              value={date}
              views={view}
              autoOk={true}
              minDate={minDate}
              maxDate={maxDate}
              openTo={openTo ? openTo : "date"}
              // onChange={(date) => setDate(date)}
              onChange={(date) => {
                localDate = date;
              }}
              disableToolbar={disableToolbar}
              onBlur={handleBlur}
              onAccept={setDate}
              format={format}
              cancelLabel={t("cancel")}
              okLabel={t("confirm")}
              variant="dialog"
              label={customLabel ? customLabel : t("select_date")}
              helperText={null}
              TextFieldComponent={(params) => {
                return (
                  <TextFieldStyled
                    {...params}
                    variant="filled"
                    darkTheme={darkTheme}
                    ifAnimation={ifAnimation}
                    required={required}
                    name={name}
                    disabled={disabled}
                    isError={isError}
                  />
                );
              }}
              style={width ? { width: width } : { width: 300 }}
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </>
    );
  }
}

export default DatePickerComponent;
