import EmployeesContent from "./employeesContent";

function Employees() {
  return (
    <div>
      <EmployeesContent />
    </div>
  );
}

export default Employees;
