import { Button, CircularProgress } from "@material-ui/core";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypeOfVisit } from "../../../Constants/typeOfVisitInPorts";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import ButtonStyled from "../../helpersComponents/Button/button";
import PopupWorkingConditions from "../../helpersComponents/WorkingConditions/PopupWorkingConditions";
import styles from "./styles.module.scss";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import PopupInformationAboutGuestsFromBlackList from "../../helpersComponents/BlackList/BlackListGuests/PopupInformationAboutGuestsFromBlackList";
import { getProfileName } from "../../../HelpersFunctions/profileName";
import { ProfileType } from "../../../enums/profileType";

interface Props {
  pathApi: string;
  successCallback?: () => void;
  newStatusIdConfirm: number;
  getIsFetchingState?: (isFetchingState: boolean) => void;
  ifMaterialUiButton?: boolean;
  typeOfVisitId?: number;
  permissionZoneId?: number | null;
  permissionZonesPatchApi?: string;
  prefixPathName?: string;
  notificationId?: number;
  pathApiNotifications?: string;
  isToPfsoAndShiftDirector?: boolean;
  profileType: ProfileType;
}

export default function ConfrimButton({
  pathApi,
  newStatusIdConfirm,
  successCallback,
  ifMaterialUiButton,
  getIsFetchingState,
  typeOfVisitId,
  permissionZoneId,
  permissionZonesPatchApi,
  prefixPathName,
  notificationId,
  pathApiNotifications,
  isToPfsoAndShiftDirector,
  profileType,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [fetchingState, fetchAgain] = useFetchOtherThanGET({
    path: pathApi,
    method: "PATCH",
    body: bodyRequest,
    setBody: setBodyRequest,
    contentType: "application/json",
    successCallback,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgain();
    }
  }, [bodyRequest, fetchAgain]);

  useEffect(() => {
    if (getIsFetchingState) getIsFetchingState(fetchingState.isFetching);
  }, [getIsFetchingState, fetchingState.isFetching]);

  const [openWorkingConditions, setOpenWorkingConditions] = useState(false);

  const closeWorkingConditionsPopup = useCallback(() => {
    setOpenWorkingConditions(false);
  }, []);

  const successCallbackWorkingConditions = useCallback(() => {
    setOpenWorkingConditions(false);
    if (successCallback !== undefined) {
      successCallback();
    }
  }, [successCallback]);

  const [isGuestsFromBlackListDialogOpen, setIsGuestsFromBlackListDialogOpen] =
    useState<boolean>(false);

  const modifyNotificationsGuestsFromBlackListResponseCallback = useCallback(
    (guestsFromBlackList: IBlackListGuest[]) => {
      if (guestsFromBlackList && guestsFromBlackList.length > 0) {
        setIsGuestsFromBlackListDialogOpen(true);
      } else {
        setOpenWorkingConditions(true);
      }

      return guestsFromBlackList;
    },
    []
  );

  const [
    guestsFromBlackList, //fetchingStateGuestsFromBlackList,
    ,
    fetchAgainGuestsFromBlackList,
  ] = useFetchAndSetGET({
    path: `${getProfileName(
      profileType
    )}/notification-guests-in-ports/${notificationId}/guests-from-black-list`,
    startFetchOnInitial: false,
    modifyResponseCallback:
      modifyNotificationsGuestsFromBlackListResponseCallback,
  });

  return (
    <>
      {!fetchingState.isFetching ? (
        <>
          {ifMaterialUiButton ? (
            <Button
              className={styles.buttonConfirm}
              onClick={() => {
                if (
                  typeOfVisitId === TypeOfVisit.COMPLETION_OF_THE_WORK ||
                  (permissionZoneId === null && !isToPfsoAndShiftDirector)
                ) {
                  fetchAgainGuestsFromBlackList();
                  //setOpenWorkingConditions(true);
                } else {
                  setBodyRequest(
                    JSON.stringify([
                      {
                        path: prefixPathName
                          ? prefixPathName + "/confirmationStatus"
                          : "/confirmationStatus",
                        op: "replace",
                        value: newStatusIdConfirm,
                      },
                    ])
                  );
                }
              }}
            >
              {t("confirm")}
            </Button>
          ) : (
            <ButtonStyled
              className={styles.buttonConfirm}
              onClick={() => {
                if (
                  typeOfVisitId === TypeOfVisit.COMPLETION_OF_THE_WORK ||
                  (permissionZoneId === null && !isToPfsoAndShiftDirector)
                ) {
                  if (profileType !== ProfileType.PFSO) {
                    fetchAgainGuestsFromBlackList();
                  } else {
                    setOpenWorkingConditions(true);
                  }
                } else {
                  if (profileType !== ProfileType.PFSO) {
                    fetchAgainGuestsFromBlackList();
                  } else {
                    setBodyRequest(
                      JSON.stringify([
                        {
                          path: prefixPathName
                            ? prefixPathName + "/confirmationStatus"
                            : "/confirmationStatus",
                          op: "replace",
                          value: newStatusIdConfirm,
                        },
                      ])
                    );
                  }
                }
              }}
            >
              {t("confirm")}
            </ButtonStyled>
          )}

          {openWorkingConditions && (
            <PopupWorkingConditions
              open={openWorkingConditions}
              onCancelChanges={closeWorkingConditionsPopup}
              successCallback={successCallbackWorkingConditions}
              pathApi={pathApi}
              newStatusIdConfirm={newStatusIdConfirm}
              typeOfVisitId={typeOfVisitId}
              permissionZoneId={permissionZoneId}
              permissionZonesPatchApi={permissionZonesPatchApi}
              prefixPathName={prefixPathName}
              notificationId={notificationId}
              pathApiNotifications={pathApiNotifications}
            />
          )}

          {isGuestsFromBlackListDialogOpen &&
            notificationId &&
            pathApiNotifications && (
              <PopupInformationAboutGuestsFromBlackList
                isOpen={isGuestsFromBlackListDialogOpen}
                setIsOpen={setIsGuestsFromBlackListDialogOpen}
                guests={guestsFromBlackList}
                notificationId={notificationId}
                pathApiNotifications={pathApiNotifications}
              />
            )}
        </>
      ) : (
        <span style={{ margin: "0px 10px" }}>
          <CircularProgress size={25} />
        </span>
      )}
    </>
  );
}
