import { DialogActionsStyled, DialogStyled } from "../PopupHelpers";
import styles from "./styles.module.scss";

interface IProps {
  id?: number | undefined;
  open: boolean;
  onConfirm: (id: number | undefined) => void;
  onCancel: () => void;
  message: string;
  confirmButtonText?: string;
  confirmButtonColor?: string;
}

const ConfirmOperationDialog = ({
  id,
  open,
  onConfirm,
  onCancel,
  message,
  confirmButtonText,
  confirmButtonColor,
}: IProps) => {
  return (
    <DialogStyled open={open} onClose={onCancel} disableBackdropClick={false}>
      <div className={styles.confirmDialogContent}>
        <p>{message}</p>
      </div>

      <DialogActionsStyled
        type="button"
        onConfirm={() => onConfirm(id)}
        onCancel={onCancel}
        onConfirmText={confirmButtonText}
        onConfirmColor={confirmButtonColor}
      />
    </DialogStyled>
  );
};

export default ConfirmOperationDialog;
