import { DialogContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../helpersComponents/PopupHelpers";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import { CheckboxStyled } from "../MaterialUi";

interface IProps {
  feedbackData: IVisitReponse[] | null;
  setFeedbackData: React.Dispatch<IVisitReponse[] | null>;
  closeCallback: () => void;
}

interface IRows {
  id: number;
  firstName: any;
  lastName: any;
  dateOfBirth: string;
  email: string;
  trainingRequired: JSX.Element;
  trainingExpiryDate: string;
}

const FeedbackDialog: React.FC<IProps> = ({
  feedbackData,
  setFeedbackData,
  closeCallback,
}) => {
  const { t } = useTranslation();
  const history: any = useHistory();
  const [rows, setRows] = useState<IRows[]>([]);

  let columns: { name: string; title: string }[] = [
    { name: "firstName", title: t("first_name") },
    { name: "lastName", title: t("last_name") },
    { name: "dateOfBirth", title: t("date_of_birth") },
    { name: "email", title: t("email") },
    { name: "trainingRequired", title: t("is_training_required") },
    { name: "trainingExpiryDate", title: t("training_expiry_date") },
    {
      name: "bh3Check",
      title: t("BH3"),
    },
  ];

  useEffect(() => {
    if (feedbackData !== null) {
      let rowsLocal = feedbackData?.map((row: any, index: number) => {
        return {
          id: index,
          firstName: row.firstName,
          lastName: row.lastName,
          dateOfBirth: row.dateOfBirth
            ? new Date(row.dateOfBirth).toLocaleString(
                t("scheduler_language"),
                {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                }
              )
            : "-",
          email: row.email ? row.email : "-",
          trainingRequired: (
            <CheckboxStyled checked={row.trainingRequired} disabled />
          ),
          trainingExpiryDate: row.trainingExpiryDate
            ? new Date(row.trainingExpiryDate).toLocaleString(
                t("scheduler_language"),
                {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                }
              )
            : "-",
          bh3Check: (
            <CheckboxStyled checked={row.bH3 ? true : false} disabled />
          ),
        };
      });
      setRows(rowsLocal);
    } else {
      setRows([]);
    }
  }, [feedbackData, t]);

  return (
    <>
      <DialogStyled
        open={feedbackData !== null}
        onClose={() => setFeedbackData(null)}
      >
        <DialogTitleStyled title={t("guests_list")} />

        <DialogContent>
          <TableComponentMemo
            layoutSettingName=""
            ifHideWholeToolbalr={true}
            rows={rows}
            columns={columns}
          />
        </DialogContent>

        <DialogActionsStyled
          ifHideCancel={true}
          onConfirm={() => {
            if (history.location.state) {
              history.push({
                pathname: history.location.state,
              });
            }
            setFeedbackData(null);
            closeCallback();
          }}
          onConfirmText={t("close")}
        />
      </DialogStyled>
    </>
  );
};

export default FeedbackDialog;
