import styles from "./advancedNavbar.module.scss";
import AdvancedNavbarMenuItems from "./AdvancedNavbarMenuItems";

const DropdownContent = ({ submenus, dropdown, depthLevel }) => {
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";

  return (
    <ul
      className={`${styles["dropdown"]} ${styles[dropdownClass]} ${
        dropdown ? styles["show"] : ""
      }`}
    >
      {submenus.map((submenu, index) => (
        <AdvancedNavbarMenuItems
          items={submenu}
          key={`${depthLevel}_${index}`}
          depthLevel={depthLevel}
        />
      ))}
    </ul>
  );
};

export default DropdownContent;
