import React from "react";
// import { NavLink } from "react-router-dom";
import SelectLanguageContent from "./selectLanguageContent";

function SelectLanguage() {
  return (
    <>
      <SelectLanguageContent />
    </>
  );
}

export default SelectLanguage;
