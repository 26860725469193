import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../PopupHelpers";
import PopupDialogContent from "./poupDialogContent";

interface IProps {
  onCancelChanges: () => void;
  open: boolean;
  successCallback: () => void;
  pathApi: string;
  inspectionServicesPathApi: string;
  permissionZonesPathApi: string;
  acWorkersPathApi: string;
}

const PopupInspectionServices: React.FC<IProps> = ({
  onCancelChanges,
  open,
  successCallback,
  pathApi,
  inspectionServicesPathApi,
  permissionZonesPathApi,
  acWorkersPathApi,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (values: InspectionServiceFormValues) => {
    setErrorMessage("");
    let guests = values.guestsArray.map((guest) => {
      return {
        firstName: guest.firstName,
        lastName: guest.lastName,
        idNumber: guest.idNumber !== "" ? guest.idNumber : null,
        cardNumber: guest.cardNumber !== "" ? guest.cardNumber : null,
      };
    });

    let body = {
      inspectionServiceId: values.inspectionService?.id,
      comments: values.comments,
      permissionZoneId: values.permissionZone.id,
      registrationNumber: values.registrationNumber,
      guests,
    };

    setBodyRequest(JSON.stringify(body));
  };

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: pathApi,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallback,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  return (
    <>
      <DialogStyled
        open={open}
        onClose={onCancelChanges}
        disableBackdropClick={true}
      >
        <DialogTitleStyled
          title={t("adding_notification_inspection_service")}
        />
        <PopupDialogContent
          onSubmit={onSubmit}
          inspectionServicesPathApi={inspectionServicesPathApi}
          permissionZonesPathApi={permissionZonesPathApi}
          acWorkersPathApi={acWorkersPathApi}
          isPopupOpen={open}
        />
        <DialogActionsStyled
          typConfirm="submit"
          formConfirm="submitForm"
          onCancel={onCancelChanges}
          isLoading={fetchingStatePostData.isFetching}
          errorString={errorMessage}
        />
      </DialogStyled>
    </>
  );
};

export default PopupInspectionServices;
