import { ProfileType } from "../../../enums/profileType";
import ServicesAndDeliveriesContent from "../../helpersComponents/ServicesAndDeliveries/ServicesAndDeliveriesContent";
import { useParams } from "react-router-dom";

const ServicesAndDeliveriesPfso = () => {
  const params: any = useParams();

  return (
    <div>
      <ServicesAndDeliveriesContent
        pathApiServicesAndDeliveriesCheckPermissions={`pfso/deliveries-services/${
          params?.id ? params.id : ""
        }/check-permission-to-confirm-delivery-and-service`}
        pathApiCompaniesInPorts="pfso/companies-in-ports"
        pathApiArrivalsOfShips="pfso/arrivals-of-ships"
        pathApiTypesOfVisit="pfso/delivery-service-types"
        pathApiGuests="pfso/guests"
        pathApiChangeConfirmationStatus="pfso/delivery-service-change-status"
        pathApiServicesAndDeliveries="pfso/deliveries-services"
        layoutSettingName="layoutPfsoServicesAndDeliverie"
        profileType={ProfileType.PFSO}
        id={params?.id ? params.id : undefined}
        confirmationOnly={params?.id ? true : false}
      />
    </div>
  );
};

export default ServicesAndDeliveriesPfso;
