import React, { ReactElement } from "react";
import styles from "./popupHelpers.module.scss";

interface Props {
  children: React.ReactNode | React.ReactNode[];
  // width?: number | string;
  style?: React.CSSProperties;
}

export default function Row({ children, style }: Props): ReactElement {
  return (
    <div className={styles.row} style={style}>
      {children}
    </div>
  );
}
