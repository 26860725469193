import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faAnchor,
  faBuilding,
  faInfo,
  faShip,
  faTruck,
  faUserMinus,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import ArrivalsOfShipAgent from "../../agent/ArrivalOfShip";
import CompaniesInPortsAgent from "../../agent/CompaniesInPorts";
import CrewEntryDescent from "../../agent/CrewEntryDescent";
import GuestsInPortsAgent from "../../agent/GuestsInPorts";
import ServicesAndDeliveriesAgent from "../../agent/ServicesAndDeliveries";
import ShipsAgent from "../../agent/Ships";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const navLinksArrayTimeWorker: navLinksArrayTimeWorkerInterface = {
    profileId: 5,
    routesArrayWithComponents: [
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/agent/profile",
        component: () => <Profile />,
      },
      {
        icon: faAnchor,
        name: t("arrival_of_ships"),
        ifHidden: false,
        singleLink: true,
        path: "/agent/home",
        component: () => <ArrivalsOfShipAgent />,
      },
      {
        icon: faTruck,
        name: t("services_and_deliveries"),
        ifHidden: false,
        singleLink: true,
        path: "/agent/services-and-deliveries",
        component: () => <ServicesAndDeliveriesAgent />,
      },
      {
        icon: faUserPlus,
        name: t("crew_entry"),
        ifHidden: false,
        singleLink: true,
        path: "/agent/crew-entries",
        component: () => <CrewEntryDescent eventType={0} />,
      },
      {
        icon: faUserMinus,
        name: t("crew_descent"),
        ifHidden: false,
        singleLink: true,
        path: "/agent/crew-descents",
        component: () => <CrewEntryDescent eventType={1} />,
      },
      {
        icon: faShip,
        name: t("ships"),
        ifHidden: false,
        singleLink: true,
        path: "/agent/ships",
        component: () => <ShipsAgent />,
      },
      {
        icon: faUser,
        name: t("persons"),
        ifHidden: false,
        singleLink: true,
        path: "/agent/persons",
        component: () => <GuestsInPortsAgent />,
      },
      {
        icon: faBuilding,
        name: t("companies"),
        ifHidden: false,
        singleLink: true,
        path: "/agent/companies",
        component: () => <CompaniesInPortsAgent />,
      },
    ],
  };

  return navLinksArrayTimeWorker;
};
export default useNavLinksArray;
