import { CRS, LatLngBounds } from "leaflet";
import { useDrop } from "react-dnd";
import { ImageOverlay, MapContainer } from "react-leaflet";

interface IProps {
  map: IDownloadedImage | null;
  onSystemObjectDrop: (item: ISMSMapSystemObjectDropItem) => void;
}

const MapArrangmentMap: React.FC<IProps> = ({ map, onSystemObjectDrop }) => {
  const bounds = new LatLngBounds([0, 0], [100, 100]);

  const [, drop] = useDrop({
    accept: "SYSTEM_OBJECT_ITEM",
    drop: (item: ISMSMapSystemObjectDropItem) => onSystemObjectDrop(item),
  });

  return (
    <div ref={drop}>
      {map && map.data && (
        <MapContainer
          center={[50, 50]}
          zoom={1}
          style={{ width: "100%", height: "60vh" }}
          crs={CRS.Simple}
        >
          <ImageOverlay url={map.data} bounds={bounds} />
        </MapContainer>
      )}
    </div>
  );
};

export default MapArrangmentMap;
