import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextFieldStyled } from "../../../helpersComponents/MaterialUi";
import styles from "./comments.module.scss";
import Button from "../../../helpersComponents/Button/button";
import useFetchPost from "../../../../hooks/fetchHooks/post/useFetchPost";
import { useSnackbar } from "notistack";
import emptyProfile from "../../../Navbar/Images/empty-profile-picture.png";

interface Props {
  comments: any;
  url: string;
  setIfFetchAgain: React.Dispatch<boolean>;
}

export default function Comments({
  comments,
  url,
  setIfFetchAgain,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [commentInput, setCommentInput] = useState<String>("");
  const { enqueueSnackbar } = useSnackbar();

  const [postRequestBodyNewComment, setPostRequestBodyNewComment] =
    useState<any>(false);

  let postRequestComment = useFetchPost(url, postRequestBodyNewComment);

  useEffect(() => {
    if (postRequestComment.data?.status === 200) {
      enqueueSnackbar(t("comment_added"), { variant: "success" });
      setPostRequestBodyNewComment(false);
      setCommentInput("");
      setIfFetchAgain(true);
    } else if (postRequestComment.data?.status === 400) {
      let error = "";
      Object.keys(postRequestComment.data.errors).forEach((errorObject) => {
        postRequestComment.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      enqueueSnackbar(error, { variant: "error" });
      setPostRequestBodyNewComment(false);
    } else if (postRequestComment.error !== null) {
      setPostRequestBodyNewComment(false);
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
    }
  }, [postRequestComment, setIfFetchAgain, enqueueSnackbar, t]);

  return (
    <div>
      <div className={styles.commnetsContainer}>
        {comments.comments.map((comment) => {
          let profilePhoto = comments.photos.find(
            (photo) => photo.timeWorkerId === comment.ownerId
          );
          return (
            <div key={comment.id} className={styles.singleComment}>
              <div className={styles.profilePhoto}>
                <div>
                  {profilePhoto ? (
                    <img
                      src={"data:image/png;base64, " + profilePhoto.photo}
                      alt="Profil"
                    />
                  ) : (
                    <img src={emptyProfile} alt="Profil" />
                  )}
                </div>
              </div>
              <div>
                <div className={styles.commentHeader}>
                  <div className={styles.commentAuthor}>
                    <b>{`${comment.ownerFirstName} ${comment.ownerLastName}`}</b>
                  </div>
                  <FontAwesomeIcon
                    className={styles.faCircle}
                    icon={faCircle}
                  />
                  <div className={styles.commentcreatedAt}>
                    {new Date(comment.createdAt).toLocaleString(
                      t("scheduler_language"),
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                  </div>
                </div>
                <div className={styles.commentContent}>{comment.content}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.addCommentContainer}>
        <TextFieldStyled
          multiline
          minRows={2}
          label={t("comment")}
          value={commentInput}
          onChange={(e) => {
            setCommentInput(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <Button
                onClick={() => {
                  if (commentInput === "") return;
                  setPostRequestBodyNewComment({ content: commentInput });
                }}
              >
                {t("send")}
              </Button>
            ),
          }}
        />
      </div>
    </div>
  );
}
