import {
  faCheck,
  faEdit,
  faRedoAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { Form, Formik } from "formik";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import ButtonStyled from "../../helpersComponents/Button/button";
import {
  DatePickerFormik,
  TextFieldFormik,
} from "../../helpersComponents/FormikInputs";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../helpersComponents/PopupHelpers";
import PopupRequirements from "../../helpersComponents/PopupRequirements/PopupRequirements";
import styles from "./styles.module.scss";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";

interface IData {
  permission: boolean;
  acWorkerId: number;
  acWorkerFirstName: string;
  acWorkerLastName: string;
  evidenceNumber: string;
  date: Date;
  isTrainingRequired: boolean;
  trainingExpiryDate: Date | null;
  guestId: number | null;
  acWorkerUniversalCardNumber: string;
  whiteListGuestId: number | null;
  permissionForUnknownPlate: boolean;
}

interface IProps {
  open: boolean;
  onCancelChanges: () => void;
  data: IData | null;
  setCardTag: Dispatch<SetStateAction<string | null | undefined>>;
}

const PopupConfirmationPermission: React.FC<IProps> = ({
  open,
  onCancelChanges,
  data,
  setCardTag,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [paramsRequest, setParamsRequest] = useState<{
    acWorkerId: number | undefined;
    registrationNumber: string;
  } | null>(null);
  const settings = useAppSelector(selectSettings);

  const plateDetectionOptionName = settings.KameraOpcja?.value
    ? settings.KameraOpcja?.value || "OstatnioWykryteTablice"
    : "OstatnioWykryteTablice";

  const [
    scannedRegistrationNumber,
    fetchingStateScannedRegistrationNumber,
    fetchAgainScannedRegistrationNumber,
  ] = useFetchAndSetGET({
    path: `security/settings?settingName=${plateDetectionOptionName}&noCache=true`,
    startFetchOnInitial: data ? true : false,
  });

  useEffect(() => {
    if (open) {
      fetchAgainScannedRegistrationNumber();
    }
  }, [open, fetchAgainScannedRegistrationNumber]);

  const [
    whiteListGuest,
    fetchingStateWhiteListGuest,
    fetchAgainWhiteListGuest,
  ] = useFetchAndSetGET<IWhiteListGuest>({
    path: `security/white-list-guests/${data?.whiteListGuestId}`,
    startFetchOnInitial: data ? true : false,
  });

  useEffect(() => {
    if (data?.acWorkerId) {
      fetchAgainWhiteListGuest();
    }
  }, [data?.acWorkerId, fetchAgainWhiteListGuest]);

  const [isConditionsOK, setIsConditionsOK] = useState<{
    status: boolean | null;
    info: string;
  }>({
    status: null,
    info: "",
  });

  const checkConditions = useCallback(
    (value: string) => {
      if (data !== undefined && data !== null) {
        let registrationNumberCondition = whiteListGuest.registrationNumbers
          .map((item) => item.registrationNumber)
          .find((item) => item.trim() === value.trim())
          ? true
          : false;

        if (data.permissionForUnknownPlate) {
          registrationNumberCondition = true;
        }

        let isOk = registrationNumberCondition;
        let textInfo = "";
        if (!registrationNumberCondition) {
          textInfo += `${t("invalid_registartion_number")}\n`;
        }

        if (data.isTrainingRequired) {
          const isTrainingAvailable =
            data?.trainingExpiryDate !== null &&
            getEndOfDate(new Date(data?.trainingExpiryDate!), "Day") >=
              getEndOfDate(new Date(), "Day");

          isOk = registrationNumberCondition && isTrainingAvailable === true;
          if (!isTrainingAvailable) {
            textInfo += `${t("lack_of_training")}`;
          }
        }

        setIsConditionsOK({
          status: isOk,
          info: textInfo,
        });
      }
    },
    [whiteListGuest, data, t]
  );

  useEffect(() => {
    if (scannedRegistrationNumber?.value && whiteListGuest) {
      checkConditions(scannedRegistrationNumber?.value);
    }
  }, [checkConditions, scannedRegistrationNumber?.value, whiteListGuest]);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `security/check-registration-number?acWorkerId=${paramsRequest?.acWorkerId}&registrationNumber=${paramsRequest?.registrationNumber}`,
    method: "POST",
    contentType: "application/json",
    getErrorMessage: setErrorMessage,
    successCallback: onCancelChanges,
    disableErrorSnackbar: true,
  });

  const [openRequirementsPopup, setOpenRequirementsPopup] = useState<{
    isOpen: boolean;
    guestId: null | number;
  }>({
    isOpen: false,
    guestId: null,
  });
  const handleCloseRequirementsPopup = useCallback(() => {
    setOpenRequirementsPopup({
      isOpen: false,
      guestId: null,
    });
  }, []);

  const successChangedTrainingDateCallback = useCallback(() => {
    setCardTag(data?.acWorkerUniversalCardNumber);
  }, [setCardTag, data]);

  return (
    <>
      <DialogStyled
        open={open}
        onClose={onCancelChanges}
        disableBackdropClick={true}
      >
        <DialogTitleStyled
          title={
            data?.isTrainingRequired
              ? t("let_in_guest_from_white_list")
              : t("let_in_employee")
          }
        />
        <LoadingWrapper
          isLodadingProgress={
            fetchingStateScannedRegistrationNumber.isFetching ||
            fetchingStateWhiteListGuest.isFetching
          }
          isError={
            fetchingStateScannedRegistrationNumber.isError ||
            fetchingStateWhiteListGuest.isError
          }
          setIfFetchAgain={() => {
            if (fetchingStateScannedRegistrationNumber.isError) {
              fetchAgainScannedRegistrationNumber();
            }
            if (fetchingStateWhiteListGuest.isError) {
              fetchAgainWhiteListGuest();
            }
          }}
        >
          <Formik
            initialValues={{
              date: new Date(),
              registrationNumber: scannedRegistrationNumber?.value,
              firstName: data?.acWorkerFirstName,
              lastName: data?.acWorkerLastName,
              evidenceNumber: data?.evidenceNumber,
              trainingExpiryDate: data?.trainingExpiryDate,
            }}
            onSubmit={(values) => {
              setErrorMessage("");
              setParamsRequest({
                acWorkerId: data?.acWorkerId,
                registrationNumber: values.registrationNumber,
              });

              fetchAgainPostData();
            }}
            validationSchema={Yup.object({
              registrationNumber: Yup.string()
                .required(t("field_required"))
                .test(
                  "isValidRegistrationNumber",
                  t("invalid_data"),
                  function (value) {
                    return data?.permissionForUnknownPlate
                      ? data?.permissionForUnknownPlate
                      : whiteListGuest.registrationNumbers
                          .map((item) => item.registrationNumber)
                          .find((item) => item.trim() === value?.trim())
                      ? true
                      : false;
                  }
                ),
            })}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form noValidate>
                <DialogContentStyled titleWidth={200}>
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("plate_number")}:</RowTitle>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <RowInput>
                            <TextFieldFormik
                              ifToUpperCaseShipsAndPorts={true}
                              label={t("plate_number")}
                              name="registrationNumber"
                              type="text"
                              required={true}
                              onChangeExtra={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => checkConditions(e.target.value)}
                            />
                          </RowInput>
                        </div>

                        <div>
                          <Tooltip
                            title={t("refresh")!}
                            placement="bottom-start"
                          >
                            <ButtonStyled
                              type="button"
                              className={styles.refreshButton}
                              onClick={fetchAgainScannedRegistrationNumber}
                            >
                              <FontAwesomeIcon icon={faRedoAlt} />
                            </ButtonStyled>
                          </Tooltip>
                        </div>
                      </div>
                    </Row>

                    <Row>
                      <RowTitle>{t("date")}:</RowTitle>
                      <RowInput width={"340px"}>
                        <DatePickerFormik
                          label={t("date")}
                          name={"date"}
                          view={[
                            "year",
                            "month",
                            "date",
                            "hours",
                            "minutes",
                            "seconds",
                          ]}
                          format="dd-MM-yyyy HH:mm:ss"
                          disabled
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("first_name")}:</RowTitle>
                      <RowInput width={"340px"}>
                        <TextFieldFormik
                          ifToUpperCaseShipsAndPorts={true}
                          label={t("first_name")}
                          name="firstName"
                          type="text"
                          disabled
                        />
                      </RowInput>
                    </Row>

                    <Row>
                      <RowTitle>{t("last_name")}:</RowTitle>
                      <RowInput width={"340px"}>
                        <TextFieldFormik
                          ifToUpperCaseShipsAndPorts={true}
                          label={t("last_name")}
                          name="lastName"
                          type="text"
                          disabled
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("evidence_number")}:</RowTitle>
                      <RowInput width={"340px"}>
                        <TextFieldFormik
                          ifToUpperCaseShipsAndPorts={true}
                          label={t("evidence_number")}
                          name="evidenceNumber"
                          type="text"
                          disabled
                        />
                      </RowInput>
                    </Row>

                    {data?.isTrainingRequired && (
                      <Row>
                        <RowTitle>{t("training_expiry_date")}:</RowTitle>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <RowInput>
                              <DatePickerFormik
                                label={t("training_expiry_date")}
                                name={"trainingExpiryDate"}
                                view={["year", "month", "date"]}
                                format="dd-MM-yyyy"
                                disabled
                              />
                            </RowInput>
                          </div>
                          <div>
                            <Tooltip
                              title={
                                isConditionsOK.info.includes(
                                  t("lack_of_training")
                                )
                                  ? t("change_date")!
                                  : ""
                              }
                              placement="bottom-start"
                            >
                              <ButtonStyled
                                type="button"
                                className={styles.refreshButton}
                                disabled={
                                  !isConditionsOK.info.includes(
                                    t("lack_of_training")
                                  )
                                }
                                onClick={() => {
                                  if (data?.guestId) {
                                    setOpenRequirementsPopup({
                                      isOpen: true,
                                      guestId: data?.guestId
                                        ? data.guestId
                                        : null,
                                    });
                                  }
                                }}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </ButtonStyled>
                            </Tooltip>
                          </div>
                        </div>
                      </Row>
                    )}
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("requirements")}:</RowTitle>
                      <RowInput>
                        {isConditionsOK.status === true && (
                          <div>
                            <FontAwesomeIcon
                              className={styles.iconAgree}
                              icon={faCheck}
                              size="2x"
                            />
                          </div>
                        )}
                        {isConditionsOK.status === false && (
                          <div className={styles.requirements}>
                            <div>
                              <FontAwesomeIcon
                                className={styles.iconDisagree}
                                icon={faTimes}
                                size="2x"
                              />
                            </div>
                            <div>
                              {isConditionsOK.info
                                .split("\n")
                                .map(
                                  (text, index) =>
                                    text !== "" && <p key={index}>{text}</p>
                                )}
                            </div>
                          </div>
                        )}
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>
                </DialogContentStyled>

                <DialogActionsStyled
                  typConfirm="submit"
                  onCancel={onCancelChanges}
                  isLoading={fetchingStatePostData.isFetching}
                  disabledConfirm={
                    isSubmitting || isConditionsOK.status === false
                  }
                  errorString={errorMessage}
                />

                {openRequirementsPopup.isOpen && (
                  <PopupRequirements
                    open={openRequirementsPopup.isOpen}
                    guestId={openRequirementsPopup.guestId}
                    pathGetGuestTrainingDateExpiry="security/guest-training-date-expiry"
                    pathUpdateTrainingDateExpiry="security/guest-training-date-expiry"
                    onCancelChanges={handleCloseRequirementsPopup}
                    successCallback={successChangedTrainingDateCallback}
                    setFieldValueOption={{
                      setFieldValue: setFieldValue,
                      name: "trainingExpiryDate",
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </LoadingWrapper>
      </DialogStyled>
    </>
  );
};

export default PopupConfirmationPermission;
