import "./keyPermissions.scss";
import React, { useState, useEffect } from "react";
// import StyledBlock from "../../../helpersComponents/StyledBlock/styledBlock"
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";

function KeyPermissionsContent() {
  const { t } = useTranslation();
  const [rows, setRows] = useState<object[]>([]);
  const columns = [
    { name: "room", title: t("room") },
    { name: "days", title: t("daysKeys") },
    { name: "fromTime", title: t("fromTimeKeys") },
    { name: "toTime", title: t("toTimeKeys") },
  ];

  const [isFetching, setIsFetching] = useState(true);

  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const { data, error } = useFetchGet(
    `worker-time/${authUserId}/key-permissions`,
    ifFetchAgain
  );

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setIfFetchAgain(false);
      return;
    }
    if (data === null) return;

    let dataModified: any = [];
    let id = 0;
    data.forEach((value) => {
      let daysString = "";

      if (value.monday) daysString += t("monday") + ", ";
      if (value.tuesday) daysString += t("tuesday") + ", ";
      if (value.wednesday) daysString += t("wednesday") + ", ";
      if (value.thursday) daysString += t("thursday") + ", ";
      if (value.friday) daysString += t("friday") + ", ";
      if (value.saturday) daysString += t("saturday") + ", ";
      if (value.sunday) daysString += t("sunday") + "";

      let daysStringNoComma = daysString.replace(/,\s*$/, "");

      let modifiedElement = {
        id: id,
        room: value.room,
        days: daysStringNoComma,
        fromTime: convertMinsToHrsMins(value.fromTime),
        toTime: convertMinsToHrsMins(value.toTime),
      };

      dataModified.push(modifiedElement);
      id++;
    });

    setRows(dataModified);
    setIsFetching(false);
  }, [data, error, t]);

  return (
    <>
      <TopBanner pathName={t("key_permissions")} />
      <div className="flexAndCenter">
        <div>
          <div>
            <LoadingWrapper
              isLodadingProgress={isFetching}
              isError={isError}
              setIfFetchAgain={setIfFetchAgain}
              setIsError={setIsError}
            >
              {!isFetching && !isError && (
                <TableComponent
                  layoutSettingName="layoutWorkerTimeSummaryKeyPermissions"
                  rows={rows}
                  columns={columns}
                  // columnOrder={this.state.columnOrder}
                  ifEdit={false}
                  ifAdd={false}
                  ifDelete={false}
                />
              )}
            </LoadingWrapper>
          </div>
        </div>
      </div>
    </>
  );
}

export default KeyPermissionsContent;
