import React, { useState, useEffect } from "react";
import TimeWithDescription from "../../../helpersComponents/NumberWithDescription/numberWithDescription";
import StyledBlock from "../../../helpersComponents/StyledBlock/styledBlock";
import { useTranslation } from "react-i18next";
import Scheduler from "../../../helpersComponents/Scheduler/sheduler";
import "./events.scss";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import computeDate from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import TooltipContent from "./tooltipContent";
import getRange from "../../../../HelpersFunctions/dateAndTime/getDateRange";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import TooltipSchedule from "../../../helpersComponents/Scheduler/tooltipSchedule";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import useWorkerTimeConxtexMenuSchedule from "../../../helpersComponents/Scheduler/useWorkerTimeConxtexMenuSchedule";

function EventsContent() {
  const { t } = useTranslation();
  const [dataModified, setDataModified] = useState();
  const workerTimeConxtexMenuArray = useWorkerTimeConxtexMenuSchedule();
  const [currentDateFetch, setCurrentDateFetch] = useState(() => {
    let schedulerLastDate = JSON.parse(
      localStorage.getItem("workerTimeSchedulerLastDate") || "{}"
    );
    if (schedulerLastDate?.creationTime) {
      if (
        new Date(schedulerLastDate.creationTime).getTime() >
        new Date().getTime() - 300000
      ) {
        return new Date(schedulerLastDate.currentDateFetch);
      }
    }
    return new Date();
  });
  const [eventComponents, setEventComponents] = useState([]);
  const [startdate, setstartdate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [eventNumber, setEventNumber] = useState<number>(0);
  const [isFetching, setIsFetching] = useState(true);
  const [currentViewName, setCurrentViewName] = useState<
    "Month" | "CurrentWeek" | "Day"
  >("Month");

  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const { data, error } = useFetchGet(
    `worker-time/${authUserId}/Events?DateFrom=` +
      startdate +
      "&DateTo=" +
      endDate,
    !ifFetchAgain || startdate === null || endDate === null ? false : true
  );

  useEffect(() => {
    localStorage.setItem(
      "workerTimeSchedulerLastDate",
      JSON.stringify({
        creationTime: new Date(),
        currentDateFetch,
      })
    );
  }, [currentDateFetch]);

  useEffect(() => {
    setIsFetching(true);

    let range = getRange(currentDateFetch, currentViewName);

    if (!range) return;

    let computedData: Array<string> = [];
    computedData[0] = computeDate(range.startDate, "T00:00:00");
    computedData[1] = computeDate(range.endDate, "T23:59:59");
    setstartdate(computedData[0]);
    setEndDate(computedData[1]);
  }, [currentDateFetch, currentViewName]);

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setIfFetchAgain(false);
      return;
    }
    if (data === null) return;
    let dataSorted = data.sort(
      (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
    );

    let dataModified: any = [];
    let eventComponentsArray: any = [];
    let id = 0;
    let eventsNumberLocal = 0;
    dataSorted.forEach((value, i) => {
      let endDate = new Date(value.time);
      endDate.setSeconds(endDate.getSeconds() + 1);
      eventsNumberLocal += 1;

      let typeIdClassName;
      let colorNumber;

      if (value.typeId === 1) {
        typeIdClassName = "entryEvent";
        colorNumber = "rgb(27, 202, 129)";
      } else if (value.typeId === 8) {
        typeIdClassName = "exitEvent";
        colorNumber = "#E94F5F";
      } else {
        typeIdClassName = "otherEvent";
        colorNumber = "#1ab394";
      }

      let eventModifiedValue = {
        id: id,
        typeId: value.typeId,
        roomName: value.roomName,
        title: value.typeName,
        startDate: new Date(value.time),
        endDate: endDate,
        backgroundColor: colorNumber,
      };

      if (value.id >= 100000) {
        eventModifiedValue.roomName = value.typeName;

        if (value.typeColor) {
          eventModifiedValue.backgroundColor = value.typeColor;
        }
      }

      eventComponentsArray.push(
        <TooltipSchedule
          tooltipBody={TooltipContent(eventModifiedValue, typeIdClassName, t)}
          key={i}
        >
          <div
            className={"oneEventMonthView " + typeIdClassName}
            style={{
              backgroundColor:
                eventModifiedValue.typeId >= 100000
                  ? eventModifiedValue.backgroundColor
                  : "",
            }}
          >
            {convertMinsToHrsMins(
              eventModifiedValue.startDate.getHours() * 60 +
                eventModifiedValue.startDate.getMinutes()
            )}{" "}
            {eventModifiedValue.roomName}
          </div>
        </TooltipSchedule>
      );

      dataModified.push(eventModifiedValue);
      id++;
    });

    setEventComponents(eventComponentsArray);
    setDataModified(dataModified);
    setEventNumber(eventsNumberLocal);
    setIsFetching(false);
  }, [data, error, t]);

  return (
    <>
      <TopBanner pathName={t("events")} />
      <div className="flexAndCenter">
        <div>
          <TimeWithDescription
            parametrs={[
              { name: t("summary") },
              {
                time: eventNumber.toString(),
                description: t("amount_of_events"),
                unit: "",
              },
            ]}
          />
        </div>
        <div>
          <StyledBlock>
            <LoadingWrapper
              isLodadingProgress={isFetching}
              isError={isError}
              setIfFetchAgain={setIfFetchAgain}
              setIsError={setIsError}
            >
              {!isFetching && !isError && (
                <Scheduler
                  data={dataModified}
                  currentDate={currentDateFetch}
                  setCurrentDate={setCurrentDateFetch}
                  components={eventComponents}
                  currentViewName={currentViewName}
                  setCurrentViewName={setCurrentViewName}
                  contextMenuOptions={workerTimeConxtexMenuArray}
                />
              )}
            </LoadingWrapper>
          </StyledBlock>
        </div>
      </div>
    </>
  );
}

export default EventsContent;
