import { Accordion, AccordionSummary, makeStyles } from "@material-ui/core";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSnackbar } from "notistack";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import getDifferenceInTwoDates from "../../../../HelpersFunctions/dateAndTime/getDifferenceInTwoDates";
import useFetchPatch from "../../../../hooks/fetchHooks/patch/useFetchPatch";
import useFetchPost from "../../../../hooks/fetchHooks/post/useFetchPost";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import RemoveComponent from "../removeComponent";
import SingleTask from "../SingleTaskComponents/singleTask";
import TasksNumberAndDurationTime from "../tasksNumberAndDurationTime";
import DragBoxStages from "./dragBoxStages";
import DragBoxTasks from "./dragBoxTasks";
import styles from "./project.module.scss";

const useStyles = makeStyles({
  accordionSummaryRoot: {
    "&.MuiAccordionSummary-root": {
      padding: 0,
    },
  },
  muiAccordionSummary: {
    "&.MuiAccordionDetails-root > div": {
      width: "100%",
    },
  },
});

interface Props {
  projectStages: any;
  setProjectStages: any;
  projectId: number;
  setIfFetchAgain: (boolean) => void;
  project: any;
  kanbanView: null | string;
  filterTasks: string;
}

export default function DragAndDropStagesContainer({
  projectStages,
  setProjectStages,
  projectId,
  setIfFetchAgain,
  project,
  kanbanView,
  filterTasks,
}: Props): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expandedStages, setExpandedStages] = useState<{
    [key in number]: boolean;
  }>({});
  const { enqueueSnackbar } = useSnackbar();

  const [postRequestBodyReorder, setPostRequestBodyReorder] =
    useState<any>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  let postRequestReorder = useFetchPost(
    `worker-time/${authUserId}/projects/${projectId}/reorder-stages`,
    postRequestBodyReorder
  );

  useEffect(() => {
    if (postRequestReorder.data?.status === 200) {
      enqueueSnackbar(t("order_saved"), { variant: "success" });
      setPostRequestBodyReorder(false);
      setIfFetchAgain(true);
    } else if (postRequestReorder.data?.status === 400) {
      let error = "";
      Object.keys(postRequestReorder.data.errors).forEach((errorObject) => {
        postRequestReorder.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      enqueueSnackbar(error, { variant: "error" });
      setPostRequestBodyReorder(false);
    } else if (postRequestReorder.error !== null) {
      setPostRequestBodyReorder(false);
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
    }
  }, [postRequestReorder, enqueueSnackbar, setIfFetchAgain, t]);

  const saveOrderStage = () => {
    let newOrder: number[] = [];
    projectStages.forEach((stage) => {
      if (stage.id === 0) return;
      newOrder.push(stage.id);
    });
    setPostRequestBodyReorder({
      stagesIds: newOrder,
    });
  };

  const [taskHisNewStageId, setTaskHisNewStageId] = useState<number>();
  const [whatIsDragging, setwhatIsDragging] = useState<
    "none" | "task" | "stage"
  >("none");

  const [dragIdStage, setDragIdStage] = useState<any>();
  const handleDragStage = (e) => {
    setwhatIsDragging("stage");
    setDragIdStage(e.currentTarget.id);
  };

  const handleDropStage = (e) => {
    if (whatIsDragging === "task") {
      let newStageId;
      if (e.currentTarget.id === 0) newStageId = null;
      else newStageId = parseInt(e.currentTarget.id);
      setTaskHisNewStageId(newStageId);
      return;
    }

    const dragBox: any = projectStages.find(
      (box) => parseInt(box.id) === parseInt(dragIdStage)
    );
    const dropBox: any = projectStages.find(
      (box) => parseInt(box.id) === parseInt(e.currentTarget.id)
    );
    const dragBoxOrder = dragBox.order;
    const dropBoxOrder = dropBox.order;

    const newBoxState = projectStages.map((box) => {
      if (parseInt(box.id) === parseInt(dragIdStage)) {
        box.order = dropBoxOrder;
      }
      if (parseInt(box.id) === parseInt(e.currentTarget.id)) {
        box.order = dragBoxOrder;
      }
      return box;
    });
    setProjectStages(newBoxState);
  };

  const [dragIdTask, setDragIdTask] = useState<number>();
  const handleDragTask = (e) => {
    setwhatIsDragging("task");
    setDragIdTask(parseInt(e.currentTarget.id));
  };
  const [patchRequestBodyTaskNewStage, setPatchRequestBodyTaskNewStage] =
    useState<any>(false);
  let patchRequestNewStage = useFetchPatch(
    `worker-time/${authUserId}/projects/${projectId}/tasks/${dragIdTask}`,
    patchRequestBodyTaskNewStage
  );

  useEffect(() => {
    if (patchRequestNewStage.data?.status === 200) {
      enqueueSnackbar(t("task_stage_changed"), { variant: "success" });
      setPatchRequestBodyTaskNewStage(false);
      setIfFetchAgain(true);
    } else if (patchRequestNewStage.data?.status === 400) {
      let error = "";
      Object.keys(patchRequestNewStage.data.errors).forEach((errorObject) => {
        patchRequestNewStage.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      enqueueSnackbar(error, { variant: "error" });
      setPatchRequestBodyTaskNewStage(false);
    } else if (patchRequestNewStage.error !== null) {
      setPatchRequestBodyTaskNewStage(false);
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
    }
  }, [patchRequestNewStage, enqueueSnackbar, setIfFetchAgain, t]);

  const saveNewStageTask = (e) => {
    let selectedTask = project.tasks.find((task) => task.id === dragIdTask);
    if (
      taskHisNewStageId === undefined ||
      selectedTask.stageId === taskHisNewStageId
    )
      return;
    setPatchRequestBodyTaskNewStage([
      {
        op: "replace",
        path: "stageId",
        value: taskHisNewStageId === 0 ? null : taskHisNewStageId,
      },
    ]);
  };

  const projectStagesRef = useRef(projectStages);
  useEffect(() => {
    projectStagesRef.current.forEach((stage) => {
      setExpandedStages((prev) => ({
        ...prev,
        [stage.id]: kanbanView === "true" ? true : false,
      }));
    });
  }, [kanbanView]);

  ///// add only for first render??
  useEffect(() => {
    if (projectStages.length === 1) {
      setExpandedStages({ [projectStages[0].id]: true });
    }
  }, [projectStages]);

  return (
    <>
      {projectStages
        .sort((a, b) => a.order - b.order)
        .map((stage) => {
          let ifEmpty = true;
          let tasksInStage: any[] = [];
          let durationTimeTasks: number = 0;
          project.tasks.forEach((task) => {
            // filter tasks
            if (filterTasks.length > 2) {
              if (
                !`${task.ownerFirstName} ${task.ownerLastName}`
                  .toLowerCase()
                  .includes(filterTasks) &&
                !task.name.toLowerCase().includes(filterTasks)
              ) {
                return;
              }
            }
            //// group tasks by stage
            if (
              task.stageId === stage.id ||
              (stage.id === 0 && task.stageId === null)
            ) {
              ifEmpty = false;
              tasksInStage.push(task);
              if (task.dateFrom && task.dateTo) {
                durationTimeTasks +=
                  getDifferenceInTwoDates(
                    new Date(task.dateTo),
                    new Date(task.dateFrom)
                  ) / 6000;
              }
            }
          });

          return (
            <DragBoxStages
              key={stage.id}
              boxNumber={stage.id}
              handleDrag={handleDragStage}
              handleDrop={handleDropStage}
              disableDrag={stage.id === 0}
              saveOrder={saveOrderStage}
              saveNewStageTask={saveNewStageTask}
              setwhatIsDragging={setwhatIsDragging}
              whatIsDragging={whatIsDragging}
            >
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                expanded={
                  expandedStages[stage.id] === true || filterTasks.length > 2
                }
                onChange={() =>
                  setExpandedStages((prev) => ({
                    ...prev,
                    [stage.id]: !expandedStages[stage.id],
                  }))
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={
                    styles.accordionSummaryreverse +
                    " " +
                    classes.accordionSummaryRoot
                  }
                >
                  <div className={styles.summaryRow}>
                    <div className={styles.accordingTitle}>
                      <div>{stage.name} </div>
                      {kanbanView !== "true" && (
                        <div
                          className={styles.tasksNumberAndDurationTimeSummary}
                        >
                          <TasksNumberAndDurationTime
                            tasksNumber={tasksInStage.length}
                            durationTimeTasksSeconds={durationTimeTasks}
                          />
                        </div>
                      )}
                    </div>
                    {/* <div className={styles.moveElement}>move</div> */}
                    {stage.id === 0 ? null : (
                      <div className={styles.removeStage}>
                        <RemoveComponent
                          setIfFetchAgain={setIfFetchAgain}
                          path={`worker-time/${authUserId}/projects/${projectId}/stages/${stage.id}`}
                          confirmDialogText={t(
                            `are_you_sure_you_want_to_delete_stage_with_tasks`,
                            { stageName: stage.name }
                          )}
                          snackbarSuccessText={t("stage_deleted")}
                          ifStopPropagation={true}
                        />
                      </div>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  draggable="true"
                  onDragStart={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  className={classes.muiAccordionSummary}
                >
                  <div>
                    <div>
                      {kanbanView === "true" && (
                        <TasksNumberAndDurationTime
                          tasksNumber={tasksInStage.length}
                          durationTimeTasksSeconds={durationTimeTasks}
                        />
                      )}
                      {kanbanView !== "true" && (
                        <div
                          className={styles.tasksNumberAndDurationTimeInside}
                        >
                          <TasksNumberAndDurationTime
                            tasksNumber={tasksInStage.length}
                            durationTimeTasksSeconds={durationTimeTasks}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className={styles.tasksContainerScrollable}
                      style={{ marginTop: `${kanbanView ? "10px" : "0px"}` }}
                    >
                      {tasksInStage.map((task, index) => {
                        return (
                          <DragBoxTasks
                            key={index}
                            boxNumber={task.id}
                            handleDrag={handleDragTask}
                            setwhatIsDragging={setwhatIsDragging}
                          >
                            <SingleTask
                              task={task}
                              setIfFetchAgain={setIfFetchAgain}
                              project={project}
                              photos={project.tasksPhotos}
                              kanbanView={kanbanView}
                            />
                          </DragBoxTasks>
                        );
                      })}
                    </div>
                    {ifEmpty && <div>{t("no_tasks_for_this_stage")}</div>}
                  </div>
                </AccordionDetails>
              </Accordion>
            </DragBoxStages>
          );
        })}
    </>
  );
}
