import { useTranslation } from "react-i18next";
import styles from "./OperationItem.module.scss";
import ButtonStyled from "../../helpersComponents/Button/button";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import { useState } from "react";

const OperationItem: React.FC<IEventType & { setBodyRequest: any }> = ({
  id,
  name,
  color,
  setBodyRequest,
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <ButtonStyled
      onClick={async () => {
        let selectedbuttonId = await confirm({
          text: `${t("are_you_sure_you_want_to_start_new_operation")}`,
          buttons: [
            { buttonName: t("yes"), buttonId: 0 },
            { buttonName: t("cancel"), buttonId: 1, backgroundColor: "red" },
          ],
        });

        if ((await selectedbuttonId) === 0) {
          let operationContent = JSON.stringify({
            eventTypeId: id,
            type: 0,
          });

          setBodyRequest(operationContent);
        }
      }}
      type="button"
      className={styles.buttonItem}
      style={{ backgroundColor: color, opacity: isHovered ? 0.5 : 1 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {name}
    </ButtonStyled>
  );
};

export default OperationItem;
