import { useTranslation } from "react-i18next";
import { TextFieldStyled } from "../../../helpersComponents/MaterialUi/index";
import styles from "./projectsHome.module.scss";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../helpersComponents/Button/button";
import useFetchPostFormData from "../../../../hooks/fetchHooks/post/useFetchPostFormData";
import { useSnackbar } from "notistack";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";

interface Props {
  setIfFetchAgain: (boolean) => void;
}

const AddTaskSimple = ({ setIfFetchAgain }: Props) => {
  const { t } = useTranslation();
  const [newTaskName, setNewTaskName] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const [postRequestBodyNewTask, setPostRequestBodyNewTask] =
    useState<any>(false);

  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  let postRequestNewTaskt = useFetchPostFormData(
    `worker-time/${authUserId}/projects/0/tasks`,
    postRequestBodyNewTask
  );

  useEffect(() => {
    if (postRequestNewTaskt.data?.status === 201) {
      setPostRequestBodyNewTask(false);
      setNewTaskName("");
      setIfFetchAgain(true);
      enqueueSnackbar(t("task_added"), { variant: "success" });
    } else if (postRequestNewTaskt.data?.status === 400) {
      let error = "";
      Object.keys(postRequestNewTaskt.data.errors).forEach((errorObject) => {
        postRequestNewTaskt.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      enqueueSnackbar(error, { variant: "error" });
      setPostRequestBodyNewTask(false);
    } else if (postRequestNewTaskt.error !== null) {
      setPostRequestBodyNewTask(false);
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
    }
  }, [postRequestNewTaskt, setIfFetchAgain, enqueueSnackbar, t]);

  return (
    <div className={styles.addTaskRoot}>
      <div
        className={styles.addIcon}
        onClick={() => {
          if (newTaskName === "") return;
          let formdata: any = new FormData();
          formdata.append("requestData", JSON.stringify({ name: newTaskName }));
          setPostRequestBodyNewTask(formdata);
        }}
      >
        <Button>
          <FontAwesomeIcon className={styles.addTaskIcon} icon={faPlus} />
        </Button>
      </div>
      <div>
        <TextFieldStyled
          onKeyPress={(ev) => {
            if (ev.key === "Enter" && newTaskName !== "") {
              let formdata: any = new FormData();
              formdata.append(
                "requestData",
                JSON.stringify({ name: newTaskName })
              );
              setPostRequestBodyNewTask(formdata);
            }
          }}
          value={newTaskName}
          label={t("add_new_task_quick")}
          width={"100%"}
          onChange={(e) => {
            setNewTaskName(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default AddTaskSimple;
