import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridColDef, GridInputSelectionModel } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../../../../../helpersComponents/Button/button";
import { AutocompleteFormik } from "../../../../../helpersComponents/FormikInputs";
import StyledDataGrid from "../../../../../helpersComponents/MaterialUi/DataGrid/StyledDataGrid";
import { Row, RowInput } from "../../../../../helpersComponents/PopupHelpers";
import styles from "./ElevatorsCabinetsForm.module.scss";
import FreeCabinetPopup from "./FreeCabinetPopup";

interface IProps {
  elevatorsCabinetsList: ElevatorAndCabinet[];
  formFields: FormFieldsACWorker;
  formikValues: {
    [x: string]:
      | string
      | number
      | boolean
      | number[]
      | null
      | any[]
      | Date
      | any;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const ElevatorsCabinetsForm: React.FC<IProps> = ({
  elevatorsCabinetsList,
  formFields,
  formikValues,
  setFieldValue,
}) => {
  const { t } = useTranslation();

  const { elevatorsAndCabinetsItem, elevatorsAndCabinets } = formFields;

  const roomsColumns: GridColDef[] = [
    {
      field: "number",
      headerName: t("number"),
      flex: 1,
      renderCell: (params) => <span>{params.row.number}</span>,
    },
    {
      field: "description",
      headerName: t("description"),
      flex: 2,
      renderCell: (params) => <span>{params.row.description}</span>,
    },
  ];

  const selectFloorsHandler = (selected: number[]) => {
    const item = formikValues[
      `${elevatorsAndCabinetsItem.name}`
    ] as ElevatorAndCabinet;
    if (item) {
      item?.floors.map((floor) =>
        selected.includes(floor.id)
          ? (floor.checked = true)
          : (floor.checked = false)
      );

      let items = formikValues[
        `${elevatorsAndCabinets.name}`
      ] as ElevatorAndCabinet[];

      const exists = items.find((value) => value.id === item?.id);
      if (exists) {
        items = items.filter((value) => value.id !== exists.id);
      }

      items?.push(item);
      setFieldValue(`${elevatorsAndCabinets.name}`, items);
    }
  };

  const selectedFloors = () => {
    let floorsIds: GridInputSelectionModel = [];
    const item = formikValues[
      `${elevatorsAndCabinetsItem.name}`
    ] as ElevatorAndCabinet;

    const values = formikValues[
      `${elevatorsAndCabinets.name}`
    ] as ElevatorAndCabinet[];

    floorsIds =
      values
        ?.find((value) => value.id === item?.id)
        ?.floors.filter((floor) => floor.checked)
        .map((floor) => floor.id) ?? [];

    return floorsIds;
  };

  const getFloorsRows = useCallback(() => {
    const selectedItem = formikValues[
      `${elevatorsAndCabinetsItem.name}`
    ] as ElevatorAndCabinet;
    const items = formikValues[
      `${elevatorsAndCabinets.name}`
    ] as ElevatorAndCabinet[];

    const floorsRows = items?.find(
      (item) => item.id === selectedItem?.id
    )?.floors;

    return floorsRows ?? [];
  }, [elevatorsAndCabinets.name, elevatorsAndCabinetsItem.name, formikValues]);

  useEffect(() => {
    if (formikValues[`${elevatorsAndCabinetsItem.name}`]) {
      getFloorsRows();
    }
  }, [elevatorsAndCabinetsItem.name, formikValues, getFloorsRows]);

  const [isOpenFreeCabinetPopup, setIsOpenFreeCabinetPopup] = useState(false);

  return (
    <div className={styles.rootContainer}>
      <div className={styles.selectContainer}>
        <Row>
          <div>
            <RowInput width={400}>
              <AutocompleteFormik
                options={elevatorsCabinetsList ? elevatorsCabinetsList : null}
                name={elevatorsAndCabinetsItem.name}
                getOptionLabel={(option: ElevatorAndCabinet) => {
                  return option.number;
                }}
                width="100%"
                getOptionSelected={(
                  option: ElevatorAndCabinet,
                  value: ElevatorAndCabinet
                ) => {
                  return option.id === value.id;
                }}
                label={t("select")}
                required={true}
              />
            </RowInput>
          </div>

          <div className={styles.findVacantCabinetContainer}>
            <div>
              <ButtonStyled
                type="button"
                onClick={() => {
                  setIsOpenFreeCabinetPopup(true);
                }}
              >
                <FontAwesomeIcon icon={faWindowMaximize} />{" "}
                {` ${t("find_free_cabinet")}`}
              </ButtonStyled>
            </div>
          </div>
        </Row>

        {isOpenFreeCabinetPopup && (
          <FreeCabinetPopup
            isOpen={isOpenFreeCabinetPopup}
            closePopup={() => setIsOpenFreeCabinetPopup(false)}
          />
        )}
      </div>

      <div className={styles.gridContainer}>
        <StyledDataGrid
          rows={getFloorsRows()}
          columns={roomsColumns}
          checkboxSelection={true}
          defaultPageSize={16}
          rowsPerPageOptions={[16]}
          density="compact"
          onSelectionModelChange={(selected) => {
            selectFloorsHandler(selected as number[]);
          }}
          selectionModel={selectedFloors()}
          getRowClassName={() => styles.gridRow}
        />
      </div>
    </div>
  );
};
