import React, { createContext, ReactElement } from "react";
import { selectIsOpenLeftNavbar } from "../../../reducers/isOpenLeftNavbar";
import { useAppSelector } from "../../../store/hooks";
import styles from "./styles.module.scss";

interface Props {
  children: React.ReactNode | React.ReactNode[];
  titleWidth?: number;
  valueWidth?: number;
}

export const contextTitleValueWidth = createContext({
  titleWidth: undefined,
  valueWidth: undefined,
  isOpenLeftNavbar: undefined,
} as { titleWidth?: string | number; valueWidth?: string | number; isOpenLeftNavbar?: boolean });

export function RowDetailsContainer({
  children,
  titleWidth,
  valueWidth,
}: Props): ReactElement {
  const isOpenLeftNavbar = useAppSelector(selectIsOpenLeftNavbar);

  return (
    <contextTitleValueWidth.Provider
      value={{
        titleWidth,
        valueWidth,
        isOpenLeftNavbar,
      }}
    >
      <div className={styles.rootContainer}>{children}</div>
    </contextTitleValueWidth.Provider>
  );
}
