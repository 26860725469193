import { useTranslation } from "react-i18next";
import AccordionStyled from "../../MaterialUi/Accordion/AccordionStyled";
import { RowDetailsContainer } from "../../RowDetailsHelpers";
import TableRegistrationNumbers from "../TableRegistrationNumbers";
import styles from "./styles.module.scss";

const RowDetailWhiteListCompanies = ({ row }: { row: IWhiteListCompany }) => {
  const { t } = useTranslation();

  return (
    <RowDetailsContainer>
      <AccordionStyled title={t("registration_numbers")} defaultExpanded={true}>
        {row.registrationNumbers.length > 0 ? (
          <TableRegistrationNumbers
            registrationNumbers={row.registrationNumbers}
          />
        ) : (
          <div className={styles.emptyRegistrationNumbers}>{t("no_data")}</div>
        )}
      </AccordionStyled>
    </RowDetailsContainer>
  );
};

export default RowDetailWhiteListCompanies;
