import React from "react";
import UniversalRequestsContent from "./universalRequestsContent";

function UniversalRequests() {
  return (
    <div>
      <UniversalRequestsContent />
    </div>
  );
}

export default UniversalRequests;
