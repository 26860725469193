import { TFunction } from "react-i18next";
import * as Yup from "yup";
import formFieldsModel from "./formFieldsModel";

const {
  formFields: {
    //WORKER DATA
    firstName,
    lastName,
    evidenceNumber,
    position,
    email,
    //COMPANIES
    companies,

    //CARD
    cardNumber,
    remoteNumber,
    additionalCards,
  },
} = formFieldsModel;

//const MAX_DATE_IN_C_SHARP = "9999-12-31";
export const regexCardNumber = /^\w{8}-{1}\w{4}-{1}\w{4}-{1}\w{4}-{1}\w{12}$/;

const validationSchema = (t: TFunction<"translation">) => {
  const validationSchemaObj = Yup.object().shape({
    [firstName.name]: Yup.string().required(t("field_required")),
    [lastName.name]: Yup.string().required(t("field_required")),
    [evidenceNumber.name]: Yup.string().required(t("field_required")),
    [position.name]: Yup.string().required(t("field_required")),
    [email.name]: Yup.string().email(t("invalid_email")),
    [companies.name]: Yup.array().min(1, t("field_required")),

    [cardNumber.name]: Yup.string().test(
      "isCardNumberValid",
      t("INCORRECT_CARD_NUMBER_FORMAT"),
      function (value) {
        return isValidCardNumber(value);
      }
    ),
    [remoteNumber.name]: Yup.string().test(
      "isRemoteNumberValid",
      t("INCORRECT_CARD_NUMBER_FORMAT"),
      function (value) {
        return isValidCardNumber(value);
      }
    ),
    [additionalCards.name]: Yup.array().of(
      Yup.object().shape({
        cardNumber: Yup.string().test(
          "isCardNumberValid",
          t("INCORRECT_CARD_NUMBER_FORMAT"),
          function (value) {
            if (!value || (value && !regexCardNumber.test(value))) {
              return false;
            }
            return true;
          }
        ),
        type: Yup.number().min(0, t("value_cannot_be_below_zero")),
      })
    ),
  });

  return validationSchemaObj;
};

const isValidCardNumber = (value: string | undefined) => {
  const formattedValue = value?.replaceAll("_", "");
  if (
    formattedValue !== "----" &&
    formattedValue &&
    !regexCardNumber.test(formattedValue.trim())
  ) {
    return false;
  }
  return true;
};

export default validationSchema;
