import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import CheckboxStyled from "../../MaterialUi/Checkbox/checkbox";
import TableComponentMemo from "../../TableComponent/tableComponent";
import { TopBanner } from "../../TopBanner/topBanner";
import PopupWhiteListCompany from "./PopupWhiteListCompany";
import RowDetailWhiteListCompanies from "./RowDetailWhiteListCompanies";
import styles from "./styles.module.scss";

interface IProps {
  pathWhiteListCompanies: string;
  pathCompaniesInPorts: string;
  layoutSettingName: string;
}

const ContentWhiteListCompanies: React.FC<IProps> = ({
  pathWhiteListCompanies,
  pathCompaniesInPorts,
  layoutSettingName,
}) => {
  const { t } = useTranslation();

  const columns = [
    { name: "companyName", title: t("company") },
    { name: "dateFrom", title: t("date_from"), type: "date" },
    { name: "dateTo", title: t("date_to2"), type: "date" },
    { name: "comments", title: t("comments") },
    { name: "addingPersonFullName", title: t("adding_person") },
    { name: "createdAt", title: t("data_created"), type: "date" },
    {
      name: "permissionForUnknownPlate",
      title: t("permission_for_unknown_plate"),
    },
    {
      name: "registationNumberFilter",
      title: "registationNumberFilter",
      hide: true,
    },
  ];

  const responseCallback = useCallback((responseData: IWhiteListCompany[]) => {
    const modified = responseData?.map((row) => {
      return {
        ...row,
        comments: row.comments ? row.comments : "-",
        registationNumberFilter: row.registrationNumbers
          .map((item) => ({
            registrationNumber: item.registrationNumber,
            toString: function () {
              return this.registrationNumber;
            },
          }))
          .join(";"),
        permissionForUnknownPlate: (
          <div className={styles.centerColumnContent}>
            <CheckboxStyled checked={row.permissionForUnknownPlate} disabled />
          </div>
        ),
      };
    });
    return modified;
  }, []);

  const [
    whiteListCompanies,
    fetchingStateWhiteListCompanies,
    fetchAgainWhiteListCompanies,
  ] = useFetchAndSetGET<IWhiteListCompany[]>({
    path: pathWhiteListCompanies,
    modifyResponseCallback: responseCallback,
  });

  const [whiteListCompanyIdToRemove, setWhiteListCompanyIdToRemove] = useState<
    false | number
  >(false);

  const [fetchingStateRemove, fetchAgainRemove] = useFetchOtherThanGET({
    path: `${pathWhiteListCompanies}/${whiteListCompanyIdToRemove}`,
    method: "DELETE",
    body: whiteListCompanyIdToRemove,
    setBody: setWhiteListCompanyIdToRemove,
    successCallback: fetchAgainWhiteListCompanies,
  });

  useEffect(() => {
    if (whiteListCompanyIdToRemove !== false) {
      fetchAgainRemove();
    }
  }, [whiteListCompanyIdToRemove, fetchAgainRemove]);

  return (
    <div>
      <div>
        <TopBanner pathName={`${t("white_list")} - ${t("companies")}`} />
      </div>
      <div className="flexAndCenter">
        <div>
          <TableComponentMemo
            columns={columns}
            rows={whiteListCompanies}
            layoutSettingName={layoutSettingName}
            tableEditColumnWidth={200}
            actionsColumnUpdated={{
              popup: (props) => (
                <PopupWhiteListCompany
                  pathWhiteListCompanies={pathWhiteListCompanies}
                  pathCompaniesInPorts={pathCompaniesInPorts}
                  successCallback={fetchAgainWhiteListCompanies}
                  {...props}
                />
              ),
              successCallbackPopup: fetchAgainWhiteListCompanies,
              addButton: {
                ifShow: true,
              },
              editButton: {
                ifShow: true,
                name: t("edit"),
              },
              removeButton: {
                ifShow: true,
                onClick: (row: IWhiteListCompany) => {
                  setWhiteListCompanyIdToRemove(row.id);
                },
              },
            }}
            rowDetail={RowDetailWhiteListCompanies}
            fetchingState={{
              fetchAgain: fetchAgainWhiteListCompanies,
              isError: fetchingStateWhiteListCompanies.isError,
              isFetching:
                fetchingStateWhiteListCompanies.isFetching ||
                fetchingStateRemove.isFetching,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentWhiteListCompanies;
