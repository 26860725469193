const generateInitAnswers = (questions: QuestionBasics[]): Answers => {
  let initAnswers = {};
  questions.forEach((question) => {
    initAnswers[question.id] = {
      answerA: false,
      answerB: false,
      answerC: false,
      answerD: false,
      questionId: question.id,
    };
  });
  return initAnswers;
};

export default generateInitAnswers;
