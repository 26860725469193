import { useTranslation } from "react-i18next";
import { TopBanner } from "../../TopBanner/topBanner";
import TableComponentMemo from "../../TableComponent/tableComponent";
import { useCallback, useEffect, useState } from "react";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { ProfileType } from "../../../../enums/profileType";
import RowDetailBlackListGuests from "./RowDetailBlackListGuests";
import PopupBlackListGuest from "./PopupBlackListGuest";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";

interface IProps {
  pathBlackListGuests: string;
  pathGuests?: string;
  profileType: ProfileType;
  layoutSettingName: string;
}

const ContentBlackListGuests: React.FC<IProps> = ({
  pathBlackListGuests,
  pathGuests,
  profileType,
  layoutSettingName,
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();

  const columns = [
    { name: "guest", title: t("person") },
    { name: "guestEmail", title: t("email") },
    { name: "guestDateOfBirth", title: t("date_of_birth"), type: "date" },
    { name: "dateFrom", title: t("date_from"), type: "date" },
    { name: "dateTo", title: t("date_to2"), type: "date" },
    { name: "addingPersonFullName", title: t("adding_person") },
    { name: "createdAt", title: t("data_created"), type: "date" },
    { name: "numberOfAddAttempts", title: t("number_of_add_attempts") },
    { name: "lastAttempt", title: t("last_attempt"), type: "date_hours" },
  ];

  const responseCallback = useCallback((responseData: IBlackListGuest[]) => {
    const modified = responseData?.map((row) => {
      return {
        ...row,
        guest: `${row.guestFirstName} ${row.guestLastName}`,
        guestIdNumber: row.guestIdNumber ? row.guestIdNumber : "-",
        comments: row.comments ? row.comments : "-",
      };
    });
    return modified;
  }, []);

  const [
    blackListGuests,
    fetchingStateBlackListGuests,
    fetchAgainBlackListGuests,
  ] = useFetchAndSetGET<IBlackListGuest[]>({
    path: pathBlackListGuests,
    modifyResponseCallback: responseCallback,
  });

  const [blackListGuestIdToRemove, setBlackListGuestIdToRemove] = useState<
    false | number
  >(false);

  const [fetchingStateRemove, fetchAgainRemove] = useFetchOtherThanGET({
    path: `${pathBlackListGuests}/${blackListGuestIdToRemove}`,
    method: "DELETE",
    body: blackListGuestIdToRemove,
    setBody: setBlackListGuestIdToRemove,
    successCallback: fetchAgainBlackListGuests,
  });

  useEffect(() => {
    if (blackListGuestIdToRemove !== false) {
      fetchAgainRemove();
    }
  }, [blackListGuestIdToRemove, fetchAgainRemove]);

  const [isOpenPopup, setIsOpenPopup] = useState<{
    isOpen: boolean;
    rowId: number | undefined;
  }>({
    isOpen: false,
    rowId: undefined,
  });

  const closeIsOpenPopup = useCallback(() => {
    setIsOpenPopup({
      isOpen: false,
      rowId: undefined,
    });
  }, []);

  return (
    <div>
      <div>
        <TopBanner pathName={`${t("black_list")} - ${t("persons")}`} />
      </div>

      <div>
        <TableComponentMemo
          columns={columns}
          rows={blackListGuests}
          layoutSettingName={layoutSettingName}
          tableEditColumnWidth={profileType !== ProfileType.PFSO ? 130 : 200}
          actionsColumnUpdated={{
            popup: (props) => (
              <PopupBlackListGuest
                pathBlackListGuests={pathBlackListGuests}
                pathGuests={pathGuests}
                successCallback={fetchAgainBlackListGuests}
                isOpenPopup={isOpenPopup}
                closeIsOpenPopup={closeIsOpenPopup}
                {...props}
              />
            ),
            successCallbackPopup: fetchAgainBlackListGuests,
            addButton: {
              ifShow: profileType !== ProfileType.PFSO ? false : true,
            },
            editButton: {
              ifShow: true,
              name: t("edit"),
              onClick(row: IBlackListGuest) {
                if (profileType === ProfileType.PFSO) {
                  setIsOpenPopup({
                    isOpen: true,
                    rowId: row.id,
                  });
                }
              },
            },
            removeButton: {
              ifShow: profileType !== ProfileType.PFSO ? false : true,
              onClick: async (row: IBlackListGuest) => {
                let selectedbuttonId = await confirm({
                  text: `${t(
                    "are_you_sure_you_want_to_remove_person_from_black_list"
                  )}`,
                  buttons: [
                    { buttonName: t("remove"), buttonId: 0 },
                    { buttonName: t("cancel"), buttonId: 1 },
                  ],
                });
                if ((await selectedbuttonId) === 0) {
                  setBlackListGuestIdToRemove(row.id);
                }
              },
            },
          }}
          rowDetail={(data) => <RowDetailBlackListGuests row={data.row} />}
          fetchingState={{
            fetchAgain: fetchAgainBlackListGuests,
            isError: fetchingStateBlackListGuests.isError,
            isFetching:
              fetchingStateBlackListGuests.isFetching ||
              fetchingStateRemove.isFetching,
          }}
        />
      </div>
    </div>
  );
};

export default ContentBlackListGuests;
