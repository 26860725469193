import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxStyled } from "../../helpersComponents/MaterialUi";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import styles from "./styles.module.scss";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import erro400getTranslatedErrorString from "../../../HelpersFunctions/erro400getTranslatedErrorString";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import Button from "../../helpersComponents/Button/button";

interface ReceivedTrainingsProps {
  receivedTrainings: ReceivedTraining[];
  setTraining: (training: Training) => void;
}

export const ReceivedTrainings = ({
  receivedTrainings,
  setTraining,
}: ReceivedTrainingsProps) => {
  const { t, i18n } = useTranslation();
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;

  const useStyles = makeStyles({
    accordionSummary: {
      "&": {
        borderBottom: "1px solid lightgrey",
      },
    },
  });

  const classes = useStyles();
  const { confirm } = useConfirm();
  const [open, setOpen] = useState<boolean>(false);
  const [getTrainingPath, setGetTrainingPath] = useState<string>("");
  const [startTrainingError, setStartTrainingError] = useState("");

  const columns = [
    { name: "options", title: " " },
    { name: "name", title: t("name") },
    { name: "examStartDate", title: t("exam_start_date"), type: "date_hours" },
    { name: "examEndDate", title: t("exam_end_date"), type: "date_hours" },
    { name: "examResult", title: t("exam_result") },
    { name: "isExamPassed", title: t("is_exam_passed") },
  ];

  const [rows, setRows] = useState<any[]>([]);

  const handleErrors = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setGetTrainingPath("");
        setStartTrainingError(error);
      }
    },
    [t, setGetTrainingPath]
  );

  const [trainingData, , fetchAgainTrainingData] = useFetchAndSetGET({
    path: getTrainingPath,
    startFetchOnInitial: false,
    errorCallback: handleErrors,
  });

  useEffect(() => {
    if (trainingData) {
      setTraining(trainingData);
    }
  }, [trainingData, setTraining]);

  useEffect(() => {
    if (getTrainingPath !== "" && startTrainingError === "") {
      fetchAgainTrainingData();
    }
  }, [getTrainingPath, startTrainingError, fetchAgainTrainingData]);

  const getLocalizedName = useCallback(
    (receivedTraining: ReceivedTraining) => {
      switch (i18n.language) {
        case "en":
          return receivedTraining.nameEN;

        case "de":
          return receivedTraining.nameDE;

        case "ru":
          return receivedTraining.nameRU;

        case "uk":
          return receivedTraining.nameUK;

        default:
          return receivedTraining.namePL;
      }
    },
    [i18n.language]
  );

  const getCheckBoxControl = (state: boolean) => {
    return (
      <div>
        <FormControlLabel
          control={
            <CheckboxStyled
              checked={state}
              name="active"
              disabled
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          label={<span className="FormControlLabelStyle"></span>}
        />
      </div>
    );
  };

  useEffect(() => {
    if (receivedTrainings) {
      setRows(
        receivedTrainings.map((receivedTraining, index) => {
          return {
            ...receivedTraining,
            name: getLocalizedName(receivedTraining),
            isExamPassed: getCheckBoxControl(receivedTraining.isExamPassed),
            options: (
              <div>
                {receivedTraining.isRepeatPossible && (
                  <Button
                    onClick={async () => {
                      let selectedbuttonId = await confirm({
                        text:
                          t("are_you_sure_you_want_to_go_to_the_training") +
                          "?",
                        buttons: [
                          { buttonName: t("yes"), buttonId: 0 },
                          { buttonName: t("no"), buttonId: 1 },
                        ],
                      });

                      if (selectedbuttonId === 0) {
                        setGetTrainingPath(
                          `worker-time/${authUserId}/trainings/training-exam?trainingId=${receivedTraining.trainingId}`
                        );

                        setStartTrainingError("");
                      }
                    }}
                    type="button"
                  >
                    {t("repeat")}
                  </Button>
                )}
              </div>
            ),
          };
        })
      );
    }
  }, [
    receivedTrainings,
    t,
    i18n.language,
    getLocalizedName,
    authUserId,
    confirm,
  ]);

  return (
    <Accordion expanded={open} onChange={() => setOpen(!open)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordionSummary}
      >
        <div className={styles.accordingTitle}>
          <div>{t("received_trainings")}</div>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <TableComponentMemo
          layoutSettingName=""
          ifHideWholeToolbalr={true}
          rows={rows}
          columns={columns}
          filteringStateColumnExtensions={[
            { columnName: "options", filteringEnabled: false },
          ]}
        />
      </AccordionDetails>
    </Accordion>
  );
};
