import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import MultiSelect from "../../../../../../../helpersComponents/MultiSelect/MultiSelect";
import styles from "./shareSchema.module.scss";

interface IProps {
  employeesReportUsers: ITimeSuperiorBasicInfo[];
  selectedEmployeesReportUsers: number[];
  setSelectedEmployeesReportUsers: Dispatch<SetStateAction<number[]>>;
  onConfirmButtonClick?: () => void;
  onCancelButtonClick?: () => void;
}

export default function ShareSchema({
  employeesReportUsers,
  selectedEmployeesReportUsers,
  setSelectedEmployeesReportUsers,
  onConfirmButtonClick,
  onCancelButtonClick,
}: IProps) {
  const { t } = useTranslation();

  const onConfirmButtonClickHandler = () => {
    if (onConfirmButtonClick) {
      onConfirmButtonClick();
    }
  };

  const onCancelButtonClickHandler = () => {
    if (onCancelButtonClick) {
      onCancelButtonClick();
    }
  };

  return (
    <div className={styles.confirmContainer}>
      <div className={styles.chooseUsersContainer}>
        <div className={styles.chooseUsersLabel}>{t("users")}</div>
        <div>
          <MultiSelect
            textColor={"gray"}
            label={""}
            label_id="uzytkownicy"
            items={employeesReportUsers}
            selectedItems={selectedEmployeesReportUsers}
            setSelectedItems={setSelectedEmployeesReportUsers}
          />
        </div>
      </div>

      <div className={styles.buttonsContainer}>
        <div
          className={styles.confirmButton}
          onClick={onConfirmButtonClickHandler}
        >
          {t("confirm")}
        </div>
        <div
          className={styles.cancelButton}
          onClick={onCancelButtonClickHandler}
        >
          {t("cancel")}
        </div>
      </div>
    </div>
  );
}
