import React, { Dispatch, ReactElement, ReactNode, useState } from "react";
import styles from "./projectsHome.module.scss";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionSummary, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import taskImage2 from "../defaultProjectsImages/image_2.svg";
import { useHistory } from "react-router";
import AddEditProject from "../addEditProject";

const useStyles = makeStyles({
  removePaperStyle: {
    "&": {
      //   backgroundColor: "transparent;",
      //   boxShadow: "none",
    },
  },
  accordionSummary: {
    "&": {
      borderBottom: "1px solid lightgrey",
    },
  },
});

interface projectList {
  [x: string]: string | number | null;
}

interface Props {
  projects: projectList[];
  setIfFetchAgain: Dispatch<boolean>;
  title: ReactNode;
  defaultExpanded?: boolean;
  showAddNewProject?: boolean;
}

export default function ProjectsList({
  projects,
  setIfFetchAgain,
  title,
  defaultExpanded = false,
  showAddNewProject = false,
}: Props): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [expandedProjects, setExpandedProjects] =
    useState<boolean>(defaultExpanded);
  const [isOpenAddProject, setIsOpenAddProject] = useState<boolean>(false);
  return (
    <div className={styles.projectsRootContainer}>
      <Accordion
        className={classes.removePaperStyle}
        expanded={expandedProjects}
        onChange={() => setExpandedProjects(!expandedProjects)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          // className={expandedProjects ? classes.accordionSummary : ""}
          className={classes.accordionSummary}
        >
          <div className={styles.accordingTitle}>{title}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.projctsLists}>
            {showAddNewProject && (
              <div
                className={styles.projectCard}
                onClick={() => setIsOpenAddProject(true)}
              >
                <div className={styles.newPorjectCard}>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <div className={styles.cardTitle}>
                  <b>{t("create_project")}</b>
                </div>
              </div>
            )}
            {projects.length === 0 ? <div>{t("none")}</div> : null}
            {projects.map((project, index) => {
              return (
                <div
                  className={styles.projectCard}
                  key={index}
                  onClick={() => {
                    history.push(`/worker-time/projects/${project.id}`);
                  }}
                >
                  <div className={styles.cardImage}>
                    {project?.icon ? (
                      <img
                        src={project.icon.toString()}
                        alt={t("project_icon")}
                      />
                    ) : (
                      <img src={taskImage2} alt={t("project_icon")} />
                    )}
                  </div>
                  <div
                    className={
                      styles.cardTitle +
                      " " +
                      (project.isDefault ? styles.default : "")
                    }
                  >
                    <b>
                      {project.name}
                      {project.isDefault ? ` (${t("default")})` : null}
                    </b>
                  </div>
                </div>
              );
            })}
            {showAddNewProject && (
              <AddEditProject
                setIfFetchAgain={setIfFetchAgain}
                isOpen={isOpenAddProject}
                setIsOpen={setIsOpenAddProject}
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
