import GuestsVisitsReportContent from "../../helpersComponents/GuestsVisitsReport/GuestsVisitsReportContent";

function GuestsVisitsReportPfso() {
  return (
    <div>
      <GuestsVisitsReportContent
        pathName="pfso/guest-visits"
        layoutSettingName="layoutPfsoVisitsReport"
        pathApiAttachments="pfso/attachments-in-ports"
      />
    </div>
  );
}

export default GuestsVisitsReportPfso;
