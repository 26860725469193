import { ProfileType } from "../../../enums/profileType";
import SupportContent from "../../helpersComponents/Support/SupportContent";

const SupportShiftDirector = () => {
  return (
    <SupportContent
      pathApiListsOfEmployees="shift-director/lists-of-employees"
      layoutSettingName="layoutShiftDirectorListsOfEmployeesRecords"
      profileType={ProfileType.SHIFT_DIRECTOR}
    />
  );
};

export default SupportShiftDirector;
