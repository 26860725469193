import React from "react";
import HolidayContent from "./../Holiday/holidayContent";
import { HolidayRequestDialogMode } from "../../../../enums/holidayRequestDialogMode";
import { HolidayLimitBriefDisplayMode } from "../../../../enums/holidayLimitBriefDisplayMode";

function HomeOffice() {
  return (
    <div>
      <HolidayContent
        mode={HolidayRequestDialogMode.HOME_OFFICE_REQUEST}
        holidayLimitBriefDisplayMode={
          HolidayLimitBriefDisplayMode.ONLY_OCCASIONAL_HOME_OFFICE
        }
      />
    </div>
  );
}

export default HomeOffice;
