import { useTranslation } from "react-i18next";
import convertDateFetch from "../../../../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchAndSetGET from "../../../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../../../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../../../../helpersComponents/PopupHelpers";
import GroupReaderTimeSettingItemContent from "./GroupReaderTimeSettingItemContent";

export interface IAddSpecialPermission {
  readerId: number | null;
  daysOfWeek: IDayOfWeek[];
  startTime: string;
  endTime: string;
  value: string;
  isHoliday: boolean;
  function: IFunctionType;
  added: boolean;
}

interface IProps {
  isOpen: boolean;
  closePopup: () => void;
  formikValues: {
    [x: string]: string | number | boolean | number[] | null | any[] | Date;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  functions: IFunctionType[];
}

const GroupReaderTimeSettingItem: React.FC<IProps> = ({
  isOpen,
  closePopup,
  formikValues,
  setFieldValue,
  functions,
}) => {
  const { t } = useTranslation();

  const [readersList, fetchingStateReadersList, fetchAgainReadersList] =
    useFetchAndSetGET<IReader[]>({
      path: "superior-ac/readers?isActive=true",
    });

  const onSubmit = (values: IAddSpecialPermission) => {
    let groupReaderTimeSettings: Partial<IGroupReaderTimeSetting>[] = [
      ...(formikValues[
        "groupReaderTimeSettings"
      ] as Partial<IGroupReaderTimeSetting>[]),
    ];

    let startTimeDate: Date;
    let endTimeDate: Date;
    let today = new Date();
    const formatStartTime = values.startTime
      .split(":")
      .map((item) => Number(item));
    startTimeDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      formatStartTime[0],
      formatStartTime[1]
    );

    const formatEndTime = values.endTime.split(":").map((item) => Number(item));
    endTimeDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      formatEndTime[0],
      formatEndTime[1]
    );

    let index = groupReaderTimeSettings.length + 1;
    const reader = readersList.find((reader) => reader.id === values.readerId);
    const newTimeSetting = {
      id: index++,
      readerId: values.readerId!,
      readerName: `[${reader?.serialNumber}] ${reader?.roomName}`,
      daysValues: values.daysOfWeek.map((day) => day.value),
      daysNames: values.daysOfWeek.map((day) => day.name).join(","),
      timeFrom: convertDateFetch(startTimeDate),
      timeTo: convertDateFetch(endTimeDate),
      isHoliday: values.isHoliday,
      value: +values.value,
      functionId: values.function.id,
      added: true,
    };

    groupReaderTimeSettings.push(newTimeSetting);
    setFieldValue("groupReaderTimeSettings", groupReaderTimeSettings);
    closePopup();
  };

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      disableBackdropClick={true}
    >
      <DialogTitleStyled title={t("group_reader_time_setting")} />
      <LoadingWrapper
        isLodadingProgress={fetchingStateReadersList.isFetching}
        isError={fetchingStateReadersList.isError}
        setIfFetchAgain={() => {
          if (fetchingStateReadersList.isError) {
            fetchAgainReadersList();
          }
        }}
      >
        <GroupReaderTimeSettingItemContent
          onSubmit={onSubmit}
          readersList={readersList}
          functions={functions}
        />
      </LoadingWrapper>
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="groupReaderTimeSettingForm"
        onCancel={closePopup}
      />
    </DialogStyled>
  );
};

export default GroupReaderTimeSettingItem;
