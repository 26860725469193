import React from 'react';
import AnnualRecordsContent from "./annualRecordsContent"


function AnnualRecords() {
    return (
        <div>
            <AnnualRecordsContent/>
        </div>
    );
}

export default AnnualRecords;
