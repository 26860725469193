import { TFunction } from "react-i18next";

export const DaysOfWeek = (t: TFunction<"translation">) => {
  return [
    { value: 1, name: t("monday") },
    { value: 2, name: t("tuesday") },
    { value: 3, name: t("wednesday") },
    { value: 4, name: t("thursday") },
    { value: 5, name: t("friday") },
    { value: 6, name: t("saturday") },
    { value: 7, name: t("sunday") },
  ] as IDayOfWeek[];
};
