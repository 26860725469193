import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../../components/helpersComponents/PopupHelpers/dialogActionsStyled";
import { DialogContentStyled } from "../../../components/helpersComponents/PopupHelpers/dialogContentStyled";
import DialogStyled from "../../../components/helpersComponents/PopupHelpers/dialogStyled";
import DialogTitleStyled from "../../../components/helpersComponents/PopupHelpers/dialogTitleStyled";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  hideParallelsFetchErrorsDialog,
  selectParallelsFetchErrorsState,
} from "../../../reducers/parallelsFetchErrors";
import TableComponentMemo from "../../../components/helpersComponents/TableComponent/tableComponent";

const ParallelsFetchErrorDialog = () => {
  const { t } = useTranslation();
  const { show, columns, rows } = useAppSelector(
    selectParallelsFetchErrorsState
  );
  const dispatch = useAppDispatch();

  return (
    <>
      <DialogStyled
        open={show}
        onClose={(_, reason) => {
          dispatch(hideParallelsFetchErrorsDialog());
        }}
        TransitionProps={{
          onExited: () => {},
          onEnter: () => {},
        }}
      >
        <DialogTitleStyled title={t("validation_errors")} />
        <DialogContentStyled>
          <TableComponentMemo
            layoutSettingName=""
            hideActionsAndVisibilityColumns={true}
            ifHideWholeToolbalr={true}
            rows={rows}
            columns={columns}
          />
        </DialogContentStyled>

        <DialogActionsStyled
          ifHideConfirm={true}
          onCancelText={t("close")}
          onCancel={() => {
            dispatch(hideParallelsFetchErrorsDialog());
          }}
        />
      </DialogStyled>
    </>
  );
};

export default ParallelsFetchErrorDialog;
