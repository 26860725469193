import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";

interface Props {
  to: string;
  title: string;
  icon: IconProp;
  color?: string;
}

const NavLinkItem: React.FC<Props> = ({ to, title, icon, color }) => {
  return (
    <NavLink
      key={to}
      to={to}
      className={styles.navigationElement}
      activeStyle={{
        backgroundColor: "#1c884d",
      }}
    >
      <div>
        <p>
          <FontAwesomeIcon icon={icon} color={color ? color : "white"} />{" "}
          {title}
        </p>
      </div>
    </NavLink>
  );
};

export default NavLinkItem;
