import React from "react";
import { useTranslation } from "react-i18next";
import { ProfileType } from "../../../enums/profileType";
import classnameForNotificationStatus from "../../../HelpersFunctions/classnameForNotificationStatus";

const useColumns = (profileType: ProfileType) => {
  const { t } = useTranslation();
  let result: any = [];

  switch (profileType) {
    case ProfileType.EMPLOYEE:
      result = [
        { name: "action", title: t("action") },
        { name: "notificationNumber", title: t("number") },
        { name: "typeOfNotification", title: t("type_of_notification") },
        { name: "dateFrom", title: t("date_from"), type: "date" },
        { name: "dateTo", title: t("date_to2"), type: "date" },
        { name: "companyName", title: t("company") },
        { name: "typeOfVisitName", title: t("type_of_visit") },
        {
          name: "bh3Check",
          title: t("BH3"),
        },
        { name: "numberOfPeople", title: t("number_of_people") },
        {
          name: "confirmationStatusName",
          title: t("confirmation_status"),
          customFormatter: (row: INotificationInPortsGuest) => (
            <p
              className={`status ${classnameForNotificationStatus(
                row.notification.confirmationStatus
              )}`}
            >
              {row.confirmationStatusName}
            </p>
          ),
        },
        { name: "shared", title: "Udostępniono" },
        { name: "guestsFilter", title: "guestsFilter", hide: true },
      ];
      break;

    case ProfileType.OPERATIONAL_SUPPORT:
      result = [
        { name: "notificationNumber", title: t("number") },
        { name: "dateFrom", title: t("date_from"), type: "date" },
        { name: "dateTo", title: t("date_to2"), type: "date" },
        { name: "companyName", title: t("company") },
        { name: "typeOfVisitName", title: t("type_of_visit") },
        {
          name: "bh3Check",
          title: t("BH3"),
        },
        { name: "numberOfPeople", title: t("number_of_people") },
        {
          name: "confirmationStatusName",
          title: t("confirmation_status"),
          customFormatter: (row: INotificationInPortsGuest) => (
            <p
              className={`status ${classnameForNotificationStatus(
                row.notification.confirmationStatus
              )}`}
            >
              {row.confirmationStatusName}
            </p>
          ),
        },
        { name: "guestsFilter", title: "guestsFilter", hide: true },
      ];
      break;

    default:
      result = [
        { name: "action", title: t("action") },
        { name: "notificationNumber", title: t("number") },
        { name: "dateFrom", title: t("date_from"), type: "date" },
        { name: "dateTo", title: t("date_to2"), type: "date" },
        { name: "companyName", title: t("company") },
        { name: "typeOfVisitName", title: t("type_of_visit") },
        {
          name: "bh3Check",
          title: t("BH3"),
        },
        { name: "numberOfPeople", title: t("number_of_people") },
        {
          name: "confirmationStatusName",
          title: t("confirmation_status"),
          customFormatter: (row: INotificationInPortsGuest) => (
            <p
              className={`status ${classnameForNotificationStatus(
                row.notification.confirmationStatus
              )}`}
            >
              {row.confirmationStatusName}
            </p>
          ),
        },
        { name: "guestsFilter", title: "guestsFilter", hide: true },
      ];
      break;
  }

  return result;
};

export default useColumns;
