import { useTranslation } from "react-i18next";
import SelectMenu from "../SelectMenu/selectMenu";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";

interface IProps {
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
}

const PeriodOfTimeChooser: React.FC<IProps> = ({
  setStartDate,
  setEndDate,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <SelectMenu
      name={t("select_period")}
      items={[
        {
          optionName: t("today"),
          onClick: () => {
            let actualDate = new Date();
            setStartDate(getBeginningOfDate(actualDate, "Day"));
            setEndDate(getEndOfDate(actualDate, "Day"));
          },
        },

        {
          optionName: t("yesterday"),
          onClick: () => {
            let actualDate = new Date();
            actualDate.setDate(actualDate.getDate() - 1);
            setStartDate(getBeginningOfDate(actualDate, "Day"));
            setEndDate(getEndOfDate(actualDate, "Day"));
          },
        },
        {
          optionName: t("this_week"),
          onClick: () => {
            let actualDate = new Date();
            setStartDate(getBeginningOfDate(actualDate, "CurrentWeek"));
            setEndDate(getEndOfDate(actualDate, "CurrentWeek"));
          },
        },
        {
          optionName: t("current_month"),
          onClick: () => {
            let actualDate = new Date();
            actualDate.setMonth(actualDate.getMonth());
            setStartDate(getBeginningOfDate(actualDate, "Month"));
            setEndDate(getEndOfDate(actualDate, "Month"));
          },
        },
        {
          optionName: t("previous_month"),
          onClick: () => {
            let actualDate = new Date();
            actualDate.setMonth(actualDate.getMonth() - 1);
            setStartDate(getBeginningOfDate(actualDate, "Month"));
            setEndDate(getEndOfDate(actualDate, "Month"));
          },
        },
        {
          optionName: t("current_quarter"),
          onClick: () => {
            let actualDate = new Date();
            setStartDate(getBeginningOfDate(actualDate, "CurrentQuarter"));
            setEndDate(getEndOfDate(actualDate, "CurrentQuarter"));
          },
        },
      ]}
    />
  );
};

export default PeriodOfTimeChooser;
