import { TableRow } from "@devexpress/dx-react-grid";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { ProfileType } from "../../../../enums/profileType";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useRefreshData from "../../../../hooks/useRefreshData/useRefreshData";
import NavigationReception from "../../../reception/Navigation/navigationReception";
import NavigationSecurity from "../../../security/NavigationSecurity/navigationSecurity";
import PopupLetIn from "../../../security/Notifications/PopupLetIn";
import TableComponentMemo from "../../TableComponent/tableComponent";
import { TopBanner } from "../../TopBanner/topBanner";
import PopupWhiteListGuest from "./PopupWhiteListGuest";
import RowDetailWhiteListGuests from "./RowDetailWhiteListGuests";
import styles from "./styles.module.scss";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import { Button, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { selectSettings } from "../../../../reducers/settings";
import { useAppSelector } from "../../../../store/hooks";

interface IProps {
  pathWhiteListGuests: string;
  pathCompaniesInPorts?: string;
  pathGuests?: string;
  pathPermissionZones: string;
  pathLetInGuest?: string;
  layoutSettingName: string;
  profileType: ProfileType;
  idleTimer?: boolean;
}

const ContentWhiteListGuests: React.FC<IProps> = ({
  pathWhiteListGuests,
  pathCompaniesInPorts,
  pathGuests,
  pathPermissionZones,
  pathLetInGuest,
  layoutSettingName,
  profileType,
  idleTimer,
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const settings = useAppSelector(selectSettings);

  const columns = [
    { name: "guest", title: t("person") },
    { name: "guestIdNumber", title: t("id_number") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "companyName", title: t("company") },
    { name: "dateFrom", title: t("date_from"), type: "date" },
    { name: "dateTo", title: t("date_to2"), type: "date" },
    { name: "addingPersonFullName", title: t("adding_person") },
    { name: "createdAt", title: t("data_created"), type: "date" },
    {
      name: "registationNumberFilter",
      title: "registationNumberFilter",
      hide: true,
    },
  ];

  const responseCallback = useCallback((responseData: IWhiteListGuest[]) => {
    const modified = responseData?.map((row) => {
      return {
        ...row,
        guest: `${row.guestFirstName} ${row.guestLastName}`,
        guestIdNumber: row.guestIdNumber ? row.guestIdNumber : "-",
        evidenceNumber: row.evidenceNumber ? row.evidenceNumber : "-",
        companyName: row.companyName ? row.companyName : "-",
        comments: row.comments ? row.comments : "-",
        registationNumberFilter: row.registrationNumbers
          .map((item) => ({
            registrationNumber: item.registrationNumber,
            toString: function () {
              return this.registrationNumber;
            },
          }))
          .join(";"),
      };
    });
    return modified;
  }, []);

  const [
    whiteListGuests,
    fetchingStateWhiteListGuests,
    fetchAgainWhiteListGuests,
  ] = useFetchAndSetGET<IWhiteListGuest[]>({
    path: pathWhiteListGuests,
    modifyResponseCallback: responseCallback,
  });

  const [whiteListGuestIdToRemove, setWhiteListGuestIdToRemove] = useState<
    false | number
  >(false);

  const [fetchingStateRemove, fetchAgainRemove] = useFetchOtherThanGET({
    path: `${pathWhiteListGuests}/${whiteListGuestIdToRemove}`,
    method: "DELETE",
    body: whiteListGuestIdToRemove,
    setBody: setWhiteListGuestIdToRemove,
    successCallback: fetchAgainWhiteListGuests,
  });

  useEffect(() => {
    if (whiteListGuestIdToRemove !== false) {
      fetchAgainRemove();
    }
  }, [whiteListGuestIdToRemove, fetchAgainRemove]);

  const [isOpenPopup, setIsOpenPopup] = useState<{
    isOpen: boolean;
    rowId: number | undefined;
  }>({
    isOpen: false,
    rowId: undefined,
  });

  const closeIsOpenPopup = useCallback(() => {
    setIsOpenPopup({
      isOpen: false,
      rowId: undefined,
    });
  }, []);

  //#region RECEPTION && SECURITY
  const [openLetInGuestState, setOpenLetInGuestState] = useState<{
    isOpen: boolean;
    row: IWhiteListGuest | null;
  }>({
    isOpen: false,
    row: null,
  });
  const handleCloseLetInGuest = useCallback(() => {
    setOpenLetInGuestState({
      isOpen: false,
      row: null,
    });
  }, []);

  const { refreshingTime, handleChangeRefreshingTime } = useRefreshData(
    fetchAgainWhiteListGuests
  );

  interface ICustomRow {
    tableRow: TableRow;
    row: any;
    children: React.ReactNode;
  }

  const [tableRow, setTableRow] =
    useState<
      ({ row, tableRow, children, ...restProps }: ICustomRow) => JSX.Element
    >();

  const highlightedRows = useCallback(
    (registrationNumber: string | undefined) => {
      const TableRow = ({
        row,
        tableRow,
        children,
        ...restProps
      }: ICustomRow): JSX.Element => {
        return (
          <Table.Row
            row={row}
            tableRow={tableRow}
            children={children}
            {...restProps}
            className={
              row?.registrationNumbers
                .map((item) => item.registrationNumber)
                ?.includes(registrationNumber)
                ? styles.highlighted
                : ""
            }
          />
        );
      };
      return TableRow;
    },
    []
  );

  function arrayMoveItem(arr: any[], fromIndex: number, toIndex: number) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  const sortRows = useCallback(
    (registrationNumber: string | undefined) => {
      let index: number = 0;
      if (whiteListGuests?.length > 0) {
        for (let i = 0; i < whiteListGuests.length; i++) {
          if (
            whiteListGuests[i].registrationNumbers
              .map((item) => item.registrationNumber)
              ?.includes(registrationNumber ? registrationNumber : "")
          ) {
            arrayMoveItem(whiteListGuests, i, index);
            index++;
          }
        }
        setRows([...whiteListGuests]);
      }
    },
    [whiteListGuests]
  );

  const [rows, setRows] = useState<Object[]>([]);
  const [registrationNumber, setRegistrationNumber] = useState<string | false>(
    ""
  );

  const plateDetectionOptionName = settings.KameraOpcja?.value
    ? settings.KameraOpcja?.value || "OstatnioWykryteTablice"
    : "OstatnioWykryteTablice";

  const [scannedRegistrationNumber, , fetchAgainScannedRegistrationNumber] =
    useFetchAndSetGET({
      path: `security/settings?settingName=${plateDetectionOptionName}&noCache=true`,
      startFetchOnInitial: profileType === ProfileType.SECURITY,
    });

  const func = useCallback(() => {
    if (scannedRegistrationNumber?.value !== registrationNumber) {
      setRegistrationNumber(scannedRegistrationNumber?.value);
      setTableRow(() => highlightedRows(scannedRegistrationNumber?.value));
      sortRows(scannedRegistrationNumber?.value);
    }
  }, [
    highlightedRows,
    registrationNumber,
    scannedRegistrationNumber?.value,
    sortRows,
  ]);
  func();

  useEffect(() => {
    let interval: any;

    if (scannedRegistrationNumber?.value) {
      if (interval !== undefined) {
        clearInterval(interval);
      }
      interval = setInterval(() => {
        fetchAgainScannedRegistrationNumber();

        if (scannedRegistrationNumber.value !== registrationNumber) {
          setRegistrationNumber(scannedRegistrationNumber.value);
          setTableRow(() => highlightedRows(scannedRegistrationNumber.value));
          sortRows(scannedRegistrationNumber.value);
        }
      }, 5000);
    } else if (scannedRegistrationNumber?.value === "") {
      if (interval !== undefined) {
        clearInterval(interval);
      }
      interval = setInterval(() => {
        fetchAgainScannedRegistrationNumber();
      }, 5000);
    }

    return () => {
      if (interval !== undefined) {
        clearInterval(interval);
      }
    };
  }, [
    fetchAgainScannedRegistrationNumber,
    highlightedRows,
    whiteListGuests,
    registrationNumber,
    scannedRegistrationNumber?.value,
    sortRows,
    func,
  ]);

  useEffect(() => {
    if (whiteListGuests) {
      setRows([...whiteListGuests]);
      if (registrationNumber !== false) {
        sortRows(registrationNumber);
      }
    }
  }, [whiteListGuests, sortRows, registrationNumber]);
  //#endregion

  const history = useHistory();

  const onIdle = () => {
    history.push("/security/home");
  };

  useIdleTimer({
    onIdle,
    timeout: 60_000,
    startOnMount: idleTimer,
  });

  return (
    <div>
      <div>
        <TopBanner pathName={`${t("white_list")} - ${t("persons")}`} />
      </div>
      <div className="flexAndCenter">
        <div>
          <div>
            {profileType === ProfileType.SECURITY && <NavigationSecurity />}
            {profileType === ProfileType.RECEPTION && <NavigationReception />}
          </div>
        </div>
        <div>
          <TableComponentMemo
            columns={columns}
            customRow={ProfileType.SECURITY && tableRow}
            rows={profileType === ProfileType.SECURITY ? rows : whiteListGuests}
            layoutSettingName={layoutSettingName}
            tableEditColumnWidth={profileType !== ProfileType.PFSO ? 130 : 200}
            actionsColumnUpdated={{
              popup: (props) => (
                <PopupWhiteListGuest
                  pathWhiteListGuests={pathWhiteListGuests}
                  pathCompaniesInPorts={pathCompaniesInPorts}
                  pathGuests={pathGuests}
                  pathPermissionZones={pathPermissionZones}
                  successCallback={fetchAgainWhiteListGuests}
                  isOpenPopup={isOpenPopup}
                  closeIsOpenPopup={closeIsOpenPopup}
                  {...props}
                />
              ),
              successCallbackPopup: fetchAgainWhiteListGuests,
              addButton: {
                ifShow: profileType !== ProfileType.PFSO ? false : true,
              },

              editButton: {
                ifShow: profileType === ProfileType.PFSO,
                name: t("edit"),
                onClick(row: IWhiteListGuest) {
                  setIsOpenPopup({
                    isOpen: true,
                    rowId: row.id,
                  });
                },
              },

              customButton1: {
                ifShow: profileType !== ProfileType.PFSO,
                name: t("let_in"),
                customComponent: (row) => {
                  return (
                    <Tooltip title={t("let_in")}>
                      <Button
                        disabled={row.isGuestOnBlackList}
                        onClick={() => {
                          setOpenLetInGuestState({
                            isOpen: true,
                            row: row,
                          });
                        }}
                        style={{ fontWeight: "bold" }}
                      >
                        <FontAwesomeIcon
                          icon={faDoorOpen}
                          style={{ fontSize: "20px", color: "green" }}
                        />
                      </Button>
                    </Tooltip>
                  );
                },
              },
              removeButton: {
                ifShow: profileType !== ProfileType.PFSO ? false : true,
                onClick: async (row: IWhiteListGuest) => {
                  let selectedbuttonId = await confirm({
                    text: `${t(
                      "are_you_sure_you_want_to_remove_person_from_white_list"
                    )}`,
                    buttons: [
                      { buttonName: t("remove"), buttonId: 0 },
                      { buttonName: t("cancel"), buttonId: 1 },
                    ],
                  });

                  if ((await selectedbuttonId) === 0) {
                    setWhiteListGuestIdToRemove(row.id);
                  }
                },
              },
              refreshButton: {
                ifShow: profileType !== ProfileType.PFSO ? true : false,
                refresh: {
                  ifRefresh: true,
                  refreshingTime: refreshingTime?.value,
                  handleChangeRefreshingTime,
                },
                color: "white",
                hideInfo: true,
              },
            }}
            rowDetail={(data) => (
              <RowDetailWhiteListGuests
                row={data.row}
                hideRegistarionNumbers={profileType === ProfileType.RECEPTION}
              />
            )}
            fetchingState={{
              fetchAgain: fetchAgainWhiteListGuests,
              isError: fetchingStateWhiteListGuests.isError,
              isFetching:
                fetchingStateWhiteListGuests.isFetching ||
                fetchingStateRemove.isFetching,
            }}
          />
        </div>

        {openLetInGuestState && (
          <PopupLetIn
            open={openLetInGuestState.isOpen}
            row={openLetInGuestState.row}
            onCancelChanges={handleCloseLetInGuest}
            successCallback={fetchAgainWhiteListGuests}
            path={pathLetInGuest + `/${openLetInGuestState.row?.id}`}
            permissionZonesPathApi={pathPermissionZones}
            fromWhiteList={true}
            selectedPermissionZoneId={openLetInGuestState.row?.permissionZoneId}
            showTrainingExpiryInfo={true}
            showRegistrationNumber={profileType === ProfileType.SECURITY}
            scannedRegistrationNumber={scannedRegistrationNumber?.value}
          />
        )}
      </div>
    </div>
  );
};

export default ContentWhiteListGuests;
