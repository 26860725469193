import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import AddEditSoundPopup from "./Popups/AddEditSoundPopup";
import { formatSizeInBytes } from "../../../../HelpersFunctions/stringUtils";

const SoundsLibrarySMSAdministrator = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const [soundIdToRemove, setSoundIdToRemove] = useState(false);

  const columns = [
    { name: "name", title: t("name") },
    { name: "fileName", title: t("file_name") },
    { name: "fileSize", title: t("file_size") },
    { name: "description", title: t("description") },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "editedBy", title: t("edited") },
    { name: "editedAt", title: t("data_edited"), type: "date_hours" },
  ];

  const modifyResponseCallback = useCallback((responseData) => {
    const modified = responseData.map((row) => {
      return {
        ...row,
        fileSize: formatSizeInBytes(row.fileSize),
      };
    });
    return modified;
  }, []);

  const [sounds, fetchingStateSounds, fetchAgainSounds] = useFetchAndSetGET({
    path: `sms-administrator/sounds-library`,
    modifyResponseCallback: modifyResponseCallback,
    startFetchOnInitial: true,
  });

  const [, fetchAgainRemoveSound] = useFetchOtherThanGET({
    path: `sms-administrator/sounds-library/${soundIdToRemove}`,
    method: "DELETE",
    body: soundIdToRemove,
    setBody: setSoundIdToRemove,
    successCallback: fetchAgainSounds,
  });

  useEffect(() => {
    if (soundIdToRemove) {
      fetchAgainRemoveSound();
    }
  }, [soundIdToRemove, fetchAgainRemoveSound]);

  return (
    <div>
      <TopBanner pathName={`${t("sounds")}`} />
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isLodadingProgress={fetchingStateSounds.isFetching}
            isError={fetchingStateSounds.isError}
            setIfFetchAgain={() => {
              fetchAgainSounds();
            }}
          >
            {!fetchingStateSounds.isFetching && (
              <TableComponent
                layoutSettingName="layoutSMSAdministratorSoundsLibrary"
                rows={sounds || []}
                columns={columns}
                actionsColumnUpdated={{
                  popup: (props) => <AddEditSoundPopup {...props} />,
                  successCallbackPopup: fetchAgainSounds,
                  addButton: {
                    ifShow: true,
                  },
                  removeButton: {
                    ifShow: true,
                    name: t("delete"),
                    onClick: async (item) => {
                      let selectedbuttonId = await confirm({
                        text: `${t("confirm_delete_sound")}`,
                        buttons: [
                          { buttonName: t("remove"), buttonId: 0 },
                          { buttonName: t("cancel"), buttonId: 1 },
                        ],
                      });

                      if ((await selectedbuttonId) === 0) {
                        setSoundIdToRemove(item.id);
                      }
                    },
                  },
                  editButton: {
                    ifShow: true,
                    name: t("edit"),
                  },
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
};

export default SoundsLibrarySMSAdministrator;
