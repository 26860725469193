import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import { DialogContent } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Button from "../../../../helpersComponents/Button/button";
import LoadingWrapper from "../../../LoadingWrapper/loadingWrapper";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import styles from "./styles.module.scss";
import profileIcon from "../../../../Navbar/Images/empty-profile-picture.png";
import PopupAddEmployeeToNotificationForm from "./PopupAddEmployeeToNotificationForm";
import toLocaleStringCustom from "../../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import convertDateFetch from "../../../../../HelpersFunctions/dateAndTime/convertDateFetch";

const PopupShareNotificationContent = ({
  notificationId,
  setIsOpen,
  successCallback,
}) => {
  const { t } = useTranslation();
  const [requestBody, setRequestBody] = useState<any>(false);

  const [employeesAssignedToNotification, setEmployeesAssignedToNotification] =
    useState<ISharedNotificationInPortsItem[]>([]);

  const [employees, fetchingStateEmployees, fetchAgainEmployees] =
    useFetchAndSetGET<IEmployee[]>({
      path: `employee/employees`,
      startFetchOnInitial: false,
    });

  const modifyResponseCallback = useCallback(
    (responseData) => {
      const modified: ISharedNotificationInPortsItem[] = [];

      responseData.forEach((row: ISharedNotificationInPorts) => {
        const foundEmployee = employees.find(
          (el) => el.id === row.targetPersonId
        );

        const itemToAdd: ISharedNotificationInPortsItem = {
          id: row.id,
          name: foundEmployee?.fullName || "",
          employeeId: row.targetPersonId,
          dateFrom: row.dateFrom ? new Date(row.dateFrom) : null,
          dateTo: row.dateTo ? new Date(row.dateTo) : null,
        };

        modified.push(itemToAdd);
      });

      setEmployeesAssignedToNotification(modified);

      return responseData;
    },
    [employees]
  );

  const [, , fetchAgainNotificationsSharedByEmployee] = useFetchAndSetGET<
    ISharedNotificationInPorts[]
  >({
    path: `employee/sharing-notifications-in-ports/shared-by-employee?notificationId=${notificationId}`,
    startFetchOnInitial: false,
    modifyResponseCallback: modifyResponseCallback,
  });

  const successCallbackAndClose = useCallback(() => {
    setIsOpen(false);
    if (successCallback) {
      successCallback();
    }
  }, [setIsOpen, successCallback]);

  const [fetchingStatePutData, fetchAgainPutData] = useFetchOtherThanGET({
    path: `employee/sharing-notifications-in-ports/${notificationId}`,
    method: "PUT",
    body: requestBody,
    contentType: "application/json",
    setBody: setRequestBody,
    successCallback: successCallbackAndClose,
  });

  useEffect(() => {
    if (notificationId) {
      fetchAgainEmployees();
    }
  }, [notificationId, fetchAgainEmployees]);

  useEffect(() => {
    if (
      !fetchingStateEmployees.isFetching &&
      !fetchingStateEmployees.isError &&
      fetchingStateEmployees.response?.status === 200
    ) {
      fetchAgainNotificationsSharedByEmployee();
    }
  }, [
    fetchingStateEmployees.isFetching,
    fetchingStateEmployees.isError,
    fetchingStateEmployees.response?.status,
    fetchAgainNotificationsSharedByEmployee,
  ]);

  const onAddNewItem = useCallback(
    (item: ISharedNotificationInPortsItem) => {
      const foundItem = employeesAssignedToNotification.find(
        (el) => el.employeeId === item.employeeId
      );
      if (!foundItem) {
        let tempEmployeesAssignedToNotification = [
          ...employeesAssignedToNotification,
          item,
        ];

        setEmployeesAssignedToNotification(tempEmployeesAssignedToNotification);
      }
    },
    [employeesAssignedToNotification]
  );

  useEffect(() => {
    if (requestBody) {
      fetchAgainPutData();
    }
  }, [requestBody, fetchAgainPutData]);

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={fetchingStateEmployees.isFetching}
        isError={fetchingStateEmployees.isError}
        setIfFetchAgain={() => {
          if (fetchingStateEmployees.isError) {
            fetchAgainEmployees();
          }
        }}
        bigSize={true}
      >
        <DialogContent>
          <PopupAddEmployeeToNotificationForm
            employees={employees}
            onAddNewItem={onAddNewItem}
          />

          <div className={styles.assignetWorkersTitle}>
            <b> {t("notification_shared_for")}:</b>
          </div>

          <div className={styles.collaboratorsContainer}>
            {employeesAssignedToNotification &&
              employeesAssignedToNotification.map((collaborator) => {
                return (
                  <div
                    className={styles.collaboratorDialogRow}
                    key={collaborator.id}
                  >
                    <div>
                      <img
                        className={styles.collaboratorPhoto}
                        src={profileIcon}
                        alt="Profil"
                      />
                    </div>
                    <div className={styles.collaboratorNameDialog}>
                      {`${collaborator.name}`}
                      <br />
                      {collaborator.dateFrom && collaborator.dateTo && (
                        <span>
                          {`${toLocaleStringCustom({
                            date: new Date(collaborator.dateFrom),
                            t,
                          })} - ${toLocaleStringCustom({
                            date: new Date(collaborator.dateTo),
                            t,
                          })}`}
                        </span>
                      )}
                    </div>
                    <Button
                      onClick={() => {
                        const collaboratorCurrentIndex =
                          employeesAssignedToNotification.findIndex(
                            (el) => el.id === collaborator.id
                          );

                        let tempCollaboratorsList = [
                          ...employeesAssignedToNotification,
                        ];
                        tempCollaboratorsList.splice(
                          collaboratorCurrentIndex,
                          1
                        );

                        setEmployeesAssignedToNotification(
                          tempCollaboratorsList
                        );
                      }}
                      className={styles.removeCollaboratorButton}
                    >
                      {t("delete")}
                    </Button>
                  </div>
                );
              })}
            {employeesAssignedToNotification.length === 0 && t("none")}
          </div>
        </DialogContent>
        <DialogActions className="dialogActionsGlobal">
          {!fetchingStatePutData.isFetching ? (
            <>
              <Button onClick={() => setIsOpen(false)}>{t("cancel")}</Button>
              <Button
                disabled={false}
                onClick={() => {
                  let objToUpdate: { items: any[] } = {
                    items: [],
                  };

                  for (
                    let i = 0;
                    i < employeesAssignedToNotification.length;
                    i++
                  ) {
                    let tempObj = {
                      targetPersonId:
                        employeesAssignedToNotification[i].employeeId,
                      dateFrom: employeesAssignedToNotification[i].dateFrom
                        ? convertDateFetch(
                            employeesAssignedToNotification[i].dateFrom!
                          )
                        : null,
                      dateTo: employeesAssignedToNotification[i].dateTo
                        ? convertDateFetch(
                            employeesAssignedToNotification[i].dateTo!
                          )
                        : null,
                    };

                    objToUpdate.items.push(tempObj);
                  }

                  setRequestBody(JSON.stringify(objToUpdate));
                }}
              >
                {t("confirm")}
              </Button>
            </>
          ) : (
            <div className="confirmWaiting">
              <CircularProgress size={30} />
            </div>
          )}
        </DialogActions>
      </LoadingWrapper>
    </>
  );
};

export default PopupShareNotificationContent;
