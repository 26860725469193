import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import PopupNewGuest from "./PopupNewGuest";

interface IProps {
  pathApiGuests: string;
  layoutSettingName: string;
}

const GuestsInPortsContent: React.FC<IProps> = ({
  pathApiGuests,
  layoutSettingName,
}) => {
  const { t } = useTranslation();

  const columns = [
    { name: "firstName", title: t("first_name") },
    { name: "lastName", title: t("last_name") },
    { name: "dateOfBirth", title: t("date_of_birth"), type: "date" },
    { name: "email", title: t("email") },
    {
      name: "trainingExpiryDate",
      title: t("training_expiry_date"),
      type: "date",
    },
    { name: "createdAt", title: t("data_created"), type: "date" },
  ];

  const modifyGuestsResponseCallback = useCallback((responseData: IGuest[]) => {
    const modified = responseData.map((row) => {
      return {
        ...row,
        email: row.email ? row.email : "-",
      };
    });
    return modified;
  }, []);

  const [guestsRows, fetchingStateGuests, fetchAgainGuests] = useFetchAndSetGET<
    IGuest[]
  >({
    path: pathApiGuests,
    modifyResponseCallback: modifyGuestsResponseCallback,
  });

  const [guestIdToRemove, setGuestIdToRemove] = useState<false | number>(false);

  const [fetchingStateRemove, fetchAgainRemove] = useFetchOtherThanGET({
    path: `${pathApiGuests}/${guestIdToRemove}`,
    method: "DELETE",
    body: guestIdToRemove,
    setBody: setGuestIdToRemove,
    successCallback: fetchAgainGuests,
  });

  useEffect(() => {
    if (guestIdToRemove !== false) {
      fetchAgainRemove();
    }
  }, [guestIdToRemove, fetchAgainRemove]);

  return (
    <div>
      <div>
        <TopBanner pathName={t("guests")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <TableComponentMemo
            columns={columns}
            rows={guestsRows}
            layoutSettingName={layoutSettingName}
            actionsColumnUpdated={{
              popup: (props) => (
                <PopupNewGuest pathApiGuests={pathApiGuests} {...props} />
              ),
              successCallbackPopup: fetchAgainGuests,
              addButton: {
                ifShow: true,
              },
              editButton: {
                ifShow: true,
                name: t("edit"),
              },
              removeButton: {
                ifShow: true,
                onClick: (row: IGuest) => {
                  setGuestIdToRemove(row.id);
                },
              },
            }}
            fetchingState={{
              fetchAgain: fetchAgainGuests,
              isError: fetchingStateGuests.isError,
              isFetching:
                fetchingStateGuests.isFetching ||
                fetchingStateRemove.isFetching,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GuestsInPortsContent;
