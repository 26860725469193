import { useTranslation } from "react-i18next";
import { AutocompleteStyled } from "../../../../../helpersComponents/MaterialUi";
import useFetchAndSetGET from "../../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import LoadingWrapper from "../../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { DayFunctionType } from "../../../../../../enums/dayFunctionType";
import LabelsManager from "./LabelsManager/labelsManager";

interface IProps {
  setSelectedDayFunctionItem: React.Dispatch<DayFunctionItem | undefined>;
  setSelectedOrganizationalUnitItem: React.Dispatch<
    OrganizationalUnitItem | undefined
  >;
  setSelectedLabelItem: React.Dispatch<ScheduleDayLabel | undefined>;
}

const PopupContentSetDayFunction = (props: IProps) => {
  const { t } = useTranslation();

  const [labelToSelect, setLabelToSelect] = useState<number>(0);

  const daysFunctions: DayFunctionItem[] = [
    { id: DayFunctionType.ORGANIZATIONAL_UNIT, name: t("organizational_unit") },
    { id: DayFunctionType.LABEL, name: t("label") },
  ];

  const [selectedDayFunctionItem, setSelectedDayFunctionItem] =
    useState<DayFunctionItem>(daysFunctions[0]);
  const [selectedOrganizationalUnitItem, setSelectedOrganizationalUnitItem] =
    useState<OrganizationalUnitItem | undefined>(undefined);

  const [, setSelectedLabel] = useState<ScheduleDayLabel | undefined>();

  props.setSelectedDayFunctionItem(selectedDayFunctionItem);

  const [
    organizationalUnits,
    fetchingStateOrganizationalUnits,
    fetchAgainOrganizationalUnits,
  ] = useFetchAndSetGET<OrganizationalUnitItem[]>({
    path: "superior-time/organizational-units",
    startFetchOnInitial: false,
  });

  const [
    scheduleDaysLabels,
    fetchingStateScheduleDaysLabels,
    fetchAgainScheduleDaysLabels,
  ] = useFetchAndSetGET<ScheduleDayLabel[]>({
    path: "superior-time/schedule-days-labels",
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (
      !fetchingStateOrganizationalUnits.isFetching &&
      !fetchingStateOrganizationalUnits.isError
    ) {
      if (organizationalUnits && organizationalUnits.length > 0) {
        setSelectedOrganizationalUnitItem(organizationalUnits[0]);
        props.setSelectedOrganizationalUnitItem(organizationalUnits[0]);
      }
    }

    // eslint-disable-next-line
  }, [
    props.setSelectedOrganizationalUnitItem,
    fetchingStateOrganizationalUnits.isFetching,
    fetchingStateOrganizationalUnits.isError,
    organizationalUnits,
  ]);

  useEffect(() => {
    fetchAgainOrganizationalUnits();
  }, [fetchAgainOrganizationalUnits]);

  useEffect(() => {
    fetchAgainScheduleDaysLabels();
  }, [fetchAgainScheduleDaysLabels]);

  const onLabelDeletedSuccessfully = useCallback(() => {
    fetchAgainScheduleDaysLabels();
  }, [fetchAgainScheduleDaysLabels]);

  const onLabelUpdatedSuccessfully = useCallback(
    (id: number) => {
      setLabelToSelect(id);
      fetchAgainScheduleDaysLabels();
    },
    [fetchAgainScheduleDaysLabels]
  );

  const onLabelAddedSuccessfully = useCallback(
    (id: number) => {
      setLabelToSelect(id);
      fetchAgainScheduleDaysLabels();
    },
    [fetchAgainScheduleDaysLabels]
  );

  const onSelectedLabelChanged = (label: ScheduleDayLabel) => {
    setSelectedLabel(label);
    props.setSelectedLabelItem(label);
  };

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={
          fetchingStateOrganizationalUnits.isFetching &&
          fetchingStateScheduleDaysLabels.isFetching
        }
        isError={
          fetchingStateOrganizationalUnits.isError &&
          fetchingStateScheduleDaysLabels.isError
        }
        setIfFetchAgain={() => {
          if (fetchingStateOrganizationalUnits.isError) {
            fetchAgainOrganizationalUnits();
          }

          if (fetchingStateScheduleDaysLabels.isError) {
            fetchAgainScheduleDaysLabels();
          }
        }}
      >
        <AutocompleteStyled
          required={true}
          label={t("day_function")}
          value={selectedDayFunctionItem}
          options={daysFunctions}
          getOptionLabel={(option: any) => {
            return option.name ? option.name.toUpperCase() : "";
          }}
          getOptionSelected={(option: DayFunction, value: DayFunction) =>
            option.subjectId === value.subjectId
          }
          onChange={(_: ChangeEvent, newValue: DayFunctionItem) => {
            setSelectedDayFunctionItem(newValue);
            props.setSelectedDayFunctionItem(newValue);
          }}
        />

        {selectedDayFunctionItem &&
          selectedDayFunctionItem.id ===
            DayFunctionType.ORGANIZATIONAL_UNIT && (
            <AutocompleteStyled
              required={true}
              label={t("organizational_unit")}
              value={selectedOrganizationalUnitItem || null}
              options={organizationalUnits}
              getOptionLabel={(option: any) => {
                return option.name ? option.name.toUpperCase() : "";
              }}
              getOptionSelected={(
                option: OrganizationalUnitItem,
                value: OrganizationalUnitItem
              ) => option.id === value.id}
              onChange={(_: ChangeEvent, newValue: OrganizationalUnitItem) => {
                setSelectedOrganizationalUnitItem(newValue);
                props.setSelectedOrganizationalUnitItem(newValue);
              }}
            />
          )}

        {selectedDayFunctionItem &&
          selectedDayFunctionItem.id === DayFunctionType.LABEL &&
          !fetchingStateScheduleDaysLabels.isFetching && (
            <LabelsManager
              scheduleDaysLabels={scheduleDaysLabels}
              initValueId={labelToSelect}
              onLabelDeletedSuccessfully={onLabelDeletedSuccessfully}
              onLabelUpdatedSuccessfully={onLabelUpdatedSuccessfully}
              onLabelAddedSuccessfully={onLabelAddedSuccessfully}
              onChangeValue={onSelectedLabelChanged}
            />
          )}
      </LoadingWrapper>
    </>
  );
};

export default PopupContentSetDayFunction;
