import { useTranslation } from "react-i18next";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import AccordionStyled from "../../MaterialUi/Accordion/AccordionStyled";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../../RowDetailsHelpers";

const RowDetailWhiteListGuests = ({ row }: { row: IWhiteListGuest }) => {
  const { t } = useTranslation();
  return (
    <RowDetailsContainer>
      {/*  */}
      <AccordionStyled title={t("person")} defaultExpanded={true}>
        <>
          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("person")}:</RowDetailsTitle>
              <RowDetailsValue>{`${row?.guestFirstName} ${row?.guestLastName}`}</RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("date_of_birth")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestDateOfBirth
                  ? toLocaleStringCustom({ date: row?.guestDateOfBirth, t })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("email")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestEmail ? row.guestEmail : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("id_number")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestIdNumber ? row.guestIdNumber : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>
        </>
      </AccordionStyled>

      {/*  */}
      <AccordionStyled title={t("information")} defaultExpanded={true}>
        <>
          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("date_from")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.dateFrom
                  ? toLocaleStringCustom({
                      date: row?.dateFrom,
                      t,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("date_to2")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.dateTo
                  ? toLocaleStringCustom({
                      date: row?.dateTo,
                      t,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("comments")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.comments ? row.comments : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("adding_person")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.addingPersonFullName ? row.addingPersonFullName : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("data_created")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.createdAt
                  ? toLocaleStringCustom({
                      date: row?.createdAt,
                      t,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>
        </>
      </AccordionStyled>
    </RowDetailsContainer>
  );
};

export default RowDetailWhiteListGuests;
