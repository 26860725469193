import "./annualHolidaysPlan.scss";
import {
  DatePickerStyled,
  SnackbarStyled,
} from "../../../helpersComponents/MaterialUi";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import AnnualHolidaysPlan from "../../../helpersComponents/AnnualHolidaysPlan/annualHolidaysPlan";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useUpdateLastSelectedWorkers from "../../../../hooks/useUpdateLastSelectedWorkers/useUpdateLastSelectedWorkers";

function AnnualHolidaysPlanContent() {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([]);
  const [, setAllWorkers] = useState<any[]>([]);
  const [, setAllWorkersSelected] = useState<boolean>(false);
  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);

  const layoutSettingName = "layoutSuperiorTimeListsSelectWorkers";

  const [updateLastSelectedWorkers] =
    useUpdateLastSelectedWorkers(selectedWorkers);

  useEffect(() => {
    if (selectedWorkers && selectedWorkers.length > 0) {
      updateLastSelectedWorkers();
    }
  }, [selectedWorkers, updateLastSelectedWorkers]);

  return (
    <>
      <TopBanner pathName={t("annual_holidays_plan")} />
      <div className="flexAndCenter">
        <div>
          <WorkerList
            layoutSettingName={layoutSettingName}
            setSelectedWorkers={setSelectedWorkers}
            selectedWorkers={selectedWorkers}
            setAllWorkers={setAllWorkers}
            setAllWorkersSelected={setAllWorkersSelected}
          />
        </div>

        <div className="selectDateDarkGlobal">
          <div className="selectYear dateContainer">
            <div className="inputTitleBonuses">{t("select_date")}</div>
            <div className="selectYearInputBonuses">
              <DatePickerStyled
                view={["year"]}
                format="yyyy"
                date={date}
                setDate={setDate}
                darkTheme={true}
                width={"100%"}
              />
            </div>
          </div>
        </div>
        <div></div>
        <div>
          <AnnualHolidaysPlan
            year={date.getFullYear()}
            selectedWorkers={selectedWorkers}
            setSnackbarState={setSnackbarState}
          />
        </div>
      </div>
      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </>
  );
}

export default AnnualHolidaysPlanContent;
