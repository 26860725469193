import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../TopBanner/topBanner";
import OrganizationalChart from "./OrganizationalChart/OrganizationalChart";
import SelectCompany from "./SelectCompany";

interface Props {
  companiesPathAPI: string;
  companyStructurePathAPI: string;
}

const CompanyStructureContent: React.FC<Props> = ({
  companiesPathAPI,
  companyStructurePathAPI,
}) => {
  const { t } = useTranslation();
  const [selectedCompany, setSelectedCompany] = useState<Company>();

  return (
    <div>
      <div>
        <TopBanner pathName={t("company_structure")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <SelectCompany
            companiesPathAPI={companiesPathAPI}
            setSelectedCompany={setSelectedCompany}
          />
        </div>
        <div>
          <OrganizationalChart
            companyStructurePathAPI={companyStructurePathAPI}
            selectedCompany={selectedCompany}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyStructureContent;
