import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import SwitchStyled from "../../helpersComponents/MaterialUi/Switch/StyledSwitch";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import PopupGroups from "./PopupGroups";

const GroupsContent = () => {
  const { t } = useTranslation();
  const [isInactiveSwitchState, setIsInactiveSwitchState] = useState(false);

  const columns = [
    { name: "name", title: t("name") },
    { name: "companyName", title: t("company") },
    { name: "description", title: t("description") },
  ];

  const modifyResponseCallback = useCallback(
    (responseData: IGroup[]) => {
      const modified: IGroup[] = [];
      responseData.forEach((row) => {
        if (row.isActive === true && isInactiveSwitchState) return;
        else if (row.isActive === false && !isInactiveSwitchState) return;

        modified.push(row);
      });

      return modified;
    },
    [isInactiveSwitchState]
  );

  const [groups, fetchingStateGroups, fetchAgainGroups] = useFetchAndSetGET<
    IGroup[]
  >({
    path: "superior-ac/groups",
    modifyResponseCallback: modifyResponseCallback,
  });

  const IsActiveSwitch = (
    <div className="SwtichActive">
      {t("active")}
      <SwitchStyled
        checked={isInactiveSwitchState}
        onChange={() => setIsInactiveSwitchState(!isInactiveSwitchState)}
        name="checkedA"
      />
      {t("inactive")}
    </div>
  );

  const [groupToRemoveOrRestore, setGroupToRemoveOrRestore] = useState<{
    id: false | number;
    method: "DELETE" | "PATCH";
    body?: [
      {
        op: "replace";
        path: "/isActive";
        value: 1;
      }
    ];
  }>({
    id: false,
    method: "DELETE",
  });

  const [groupRemoveOrRestoreFetchingState, groupRemoveOrRestoreFetchAgain] =
    useFetchOtherThanGET({
      path: `superior-ac/groups/${groupToRemoveOrRestore?.id}`,
      method: groupToRemoveOrRestore.method,
      body:
        groupToRemoveOrRestore.method === "PATCH"
          ? JSON.stringify(groupToRemoveOrRestore.body)
          : null,
      setBody: setGroupToRemoveOrRestore,
      contentType: "application/json",
      successCallback: fetchAgainGroups,
    });

  useEffect(() => {
    if (groupToRemoveOrRestore.id) {
      groupRemoveOrRestoreFetchAgain();
    }
  }, [groupToRemoveOrRestore.id, groupRemoveOrRestoreFetchAgain]);

  return (
    <div>
      <div>
        <TopBanner pathName={t("groups")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <TableComponentMemo
            columns={columns}
            rows={groups}
            layoutSettingName={"acSuperiorGroups"}
            toolbarComponent={IsActiveSwitch}
            fetchingState={{
              isFetching:
                fetchingStateGroups.isFetching ||
                groups === null ||
                groupRemoveOrRestoreFetchingState.isFetching,
              isError:
                fetchingStateGroups.isError ||
                groupRemoveOrRestoreFetchingState.isError,
              fetchAgain: fetchAgainGroups,
            }}
            actionsColumnUpdated={{
              popup: PopupGroups,
              successCallbackPopup: fetchAgainGroups,
              addButton: {
                ifShow: true,
              },
              editButton: {
                ifShow: isInactiveSwitchState ? false : true,
                name: t("edit"),
              },
              removeButton: {
                ifShow: true,
                name: isInactiveSwitchState ? t("restore") : t("remove"),
                onClick: (row) => {
                  if (row?.isActive) {
                    setGroupToRemoveOrRestore({
                      id: row.id,
                      method: "DELETE",
                      body: undefined,
                    });
                  } else {
                    setGroupToRemoveOrRestore({
                      id: row.id,
                      method: "PATCH",
                      body: [
                        {
                          op: "replace",
                          path: "/isActive",
                          value: 1,
                        },
                      ],
                    });
                  }
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupsContent;
