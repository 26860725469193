import { AutocompleteStyled } from "../MaterialUi";
import styles from "./advancedSelect.module.scss";
import shareIcon from "./icons/Share_16x16.png";
import removeIcon from "./icons/Remove_16x16.png";
import editIcon from "./icons/Edit_16x16.png";
import addIcon from "./icons/Add_16x16.png";
import { ChangeEvent, useEffect, useState } from "react";

interface IProps<T> {
  label: string;
  displayShareButton?: boolean;
  displayRemoveButton?: boolean;
  displayEditButton?: boolean;
  displayAddButton?: boolean;
  onShareButtonClick?: () => void;
  onRemoveButtonClick?: () => void;
  onEditButtonClick?: () => void;
  onAddButtonClick?: () => void;
  onChangeValue?: (item: T) => void;
  dataSource: T[];
  value: T | undefined;
  displayMember: (member: T) => string;
}

export default function AdvancedSelect<T extends { id: number }>(
  props: IProps<T>
) {
  const [selectedItem, setSelectedItem] = useState<T | undefined>(undefined);

  useEffect(() => {
    if (props.value !== selectedItem) {
      setSelectedItem(props.value);
      if (props.onChangeValue && props.value !== undefined) {
        props.onChangeValue(props.value);
      }
    }
  }, [props, selectedItem]);

  const shareButtonClickHandler = () => {
    if (props && props.onShareButtonClick) {
      props.onShareButtonClick();
    }
  };

  const removeButtonClickHandler = () => {
    if (props && props.onRemoveButtonClick) {
      props.onRemoveButtonClick();
    }
  };

  const editButtonClickHandler = () => {
    if (props && props.onEditButtonClick) {
      props.onEditButtonClick();
    }
  };

  const addButtonClickHandler = () => {
    if (props && props.onAddButtonClick) {
      props.onAddButtonClick();
    }
  };

  return (
    <>
      <div className={styles.selectContainer}>
        <div className={styles.selectItem}>
          <AutocompleteStyled
            value={selectedItem || null}
            required={true}
            label={props.label}
            options={props.dataSource}
            getOptionLabel={props.displayMember}
            getOptionSelected={(option: T, value: T) => option.id === value.id}
            onChange={(_: ChangeEvent, newValue: T) => {
              setSelectedItem(newValue);
              if (props.onChangeValue) {
                props.onChangeValue(newValue);
              }
            }}
          />
        </div>

        {props.displayShareButton && (
          <button
            className={styles.selectButton}
            onClick={shareButtonClickHandler}
          >
            <img src={shareIcon} alt="" />
          </button>
        )}

        {props.displayRemoveButton && (
          <button
            className={styles.selectButton}
            onClick={removeButtonClickHandler}
          >
            <img src={removeIcon} alt="" />
          </button>
        )}

        {props.displayEditButton && (
          <button
            className={styles.selectButton}
            onClick={editButtonClickHandler}
          >
            <img src={editIcon} alt="" />
          </button>
        )}

        {props.displayAddButton && (
          <button
            className={styles.selectButton}
            onClick={addButtonClickHandler}
          >
            <img src={addIcon} alt="" />
          </button>
        )}
      </div>
    </>
  );
}
