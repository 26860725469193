import React, { useState, useEffect } from "react";
import "./myFilesContent.scss";
import StyledBlock from "../../helpersComponents/StyledBlock/styledBlock";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../hooks/fetchHooks/get/useFetchGet";
import useFetchFile from "../../../hooks/fetchHooks/get/useFetchFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faFilePdf,
  faFileExcel,
  faFileImage,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";

function DelegationsCitiesContent() {
  const { t } = useTranslation();
  // const [ifFetchAbsenceTypes, ] = useState<boolean>(true)
  const authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );
  const [attachmentIdAndFileName, setAttachmentIdAndFileName] = useState<any>([
    false,
    "",
  ]);
  const [files, setFiles] = useState<any>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;

  const responseFiles = useFetchGet(
    `worker-time/${authUserId}/time-workers/${authUser.currentProfile.subjectId}/files`,
    ifFetchAgain
  );
  const dataFile = useFetchFile(
    `worker-time/${authUserId}/time-workers/${authUser.currentProfile.subjectId}/files`,
    attachmentIdAndFileName
  );

  useEffect(() => {
    if (responseFiles.error !== null) {
      setIsError(true);
      setIfFetchAgain(false);
      return;
    }
    if (responseFiles.data === null) return;

    setIsLoading(false);
    setIfFetchAgain(false);
    setFiles(responseFiles.data);
  }, [responseFiles]);

  useEffect(() => {
    if (dataFile.data === null) return;
    setAttachmentIdAndFileName([false, ""]);
  }, [dataFile]);

  return (
    <>
      <TopBanner pathName={t("my_files")} />
      <div className="flexAndCenter">
        <div>
          <StyledBlock>
            <div>
              <LoadingWrapper
                isLodadingProgress={isLoading}
                isError={isError}
                setIfFetchAgain={setIfFetchAgain}
                setIsError={setIsError}
              >
                {!isLoading && !isError && (
                  <div className={"files"}>
                    {files.map((file) => {
                      let fileIcon = {
                        icon: faFileAlt,
                        iconClassName: "faFile",
                      };

                      if (file.type === ".pdf")
                        fileIcon = {
                          icon: faFilePdf,
                          iconClassName: "faFilePdf",
                        };
                      else if (file.type === ".xlsx")
                        fileIcon = {
                          icon: faFileExcel,
                          iconClassName: "faFileExcel",
                        };
                      else if (file.type === ".png" || file.type === ".jpg")
                        fileIcon = {
                          icon: faFileImage,
                          iconClassName: "faFileImage",
                        };
                      else if (file.type === ".7z")
                        fileIcon = {
                          icon: faFolder,
                          iconClassName: "faFolder",
                        };
                      return (
                        <div key={file.id}>
                          <div className="singleFile">
                            <div>
                              <FontAwesomeIcon
                                className={fileIcon.iconClassName}
                                icon={fileIcon.icon}
                              />
                            </div>
                            <div
                              onClick={() => {
                                setAttachmentIdAndFileName([
                                  file.id,
                                  file.name,
                                ]);
                              }}
                            >
                              {file.name}
                            </div>
                            <div></div>
                          </div>
                          <div className={"createdAt"}>
                            <b>{t("data_created")}:</b>{" "}
                            {new Date(file.createdAt).toLocaleString(
                              t("scheduler_language"),
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </div>
                          <div className={"fileDescription"}>
                            <b>{t("description")}:</b> {file.description}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </LoadingWrapper>
            </div>
          </StyledBlock>
        </div>
      </div>
    </>
  );
}

export default DelegationsCitiesContent;
