import React, { useState } from "react";
import styles from "./project.module.scss";

const DragBoxStages = ({
  children,
  boxNumber,
  handleDrag,
  handleDrop,
  disableDrag,
  saveOrder,
  setwhatIsDragging,
  whatIsDragging,
  saveNewStageTask,
}) => {
  const [dragClassName, setDragClassName] = useState<boolean>(false);

  if (disableDrag && whatIsDragging !== "task") return <div>{children}</div>;
  return (
    <div
      draggable={true}
      id={boxNumber}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDragStart={(e) => {
        if (boxNumber === 0 && whatIsDragging !== "task") return;
        setDragClassName(true);
        handleDrag(e);
      }}
      onDragEnd={(e) => {
        if (whatIsDragging === "task") {
          saveNewStageTask(e);
          return;
        }
        if (boxNumber === 0) return;
        setDragClassName(false);
        setwhatIsDragging("none");
        saveOrder();
      }}
      onDragEnter={(e) => {
        if (boxNumber === 0 && whatIsDragging !== "task") return;
        handleDrop(e);
      }}
      className={dragClassName ? styles.dragClassName : ""}
    >
      {children}
    </div>
  );
};

export default DragBoxStages;
