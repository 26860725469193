import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import AddTaskSimple from "./addTaskSimple";
import ProjectsList from "./projectsList";

interface projectList {
  [x: string]: string | number | null;
}

const ProjectsHomeContent = () => {
  const { t } = useTranslation();
  const [allProjects, setAllProjects] = useState<projectList[]>([]);

  const [myProjects, setMyProjects] = useState<projectList[]>([]);

  const [sharedForMeProjects, setSharedForMeProjects] = useState<projectList[]>(
    []
  );

  const [isFetching, setIsFetching] = useState(true);
  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const { data, error } = useFetchGet(
    `worker-time/${authUserId}/projects`,
    ifFetchAgain
  );

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setIfFetchAgain(false);
      return;
    }
    if (data === null) return;

    let authUser: authUserInfo = JSON.parse(
      localStorage.getItem("authUser") || "{}"
    );
    let authUserSubjectId = authUser.currentProfile.subjectId;
    let myProjectsLocal: projectList[] = [];
    let sharedForMeProjectsLocal: projectList[] = [];
    data.forEach((project) => {
      if (project.ownerId === authUserSubjectId) myProjectsLocal.push(project);
      else sharedForMeProjectsLocal.push(project);
    });

    setMyProjects(myProjectsLocal);
    setSharedForMeProjects(sharedForMeProjectsLocal);
    setIfFetchAgain(false);
    setAllProjects(data);
    setIsFetching(false);
  }, [data, error, t]);

  return (
    <>
      <TopBanner pathName={t("projects_home")} />
      <div>
        <AddTaskSimple setIfFetchAgain={setIfFetchAgain} />
      </div>
      <LoadingWrapper
        isLodadingProgress={isFetching}
        isError={isError}
        setIfFetchAgain={setIfFetchAgain}
        setIsError={setIsError}
      >
        {!isFetching && !isError && (
          <>
            <ProjectsList
              showAddNewProject={true}
              defaultExpanded={true}
              title={t("my_projects")}
              setIfFetchAgain={setIfFetchAgain}
              projects={myProjects}
            />
            <ProjectsList
              title={t("shared_for_me")}
              setIfFetchAgain={setIfFetchAgain}
              projects={sharedForMeProjects}
            />
            <ProjectsList
              title={t("all_projects")}
              setIfFetchAgain={setIfFetchAgain}
              projects={allProjects}
            />
          </>
        )}
      </LoadingWrapper>
    </>
  );
};

export default ProjectsHomeContent;
