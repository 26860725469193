import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import TableComponentMemo from "../../../helpersComponents/TableComponent/tableComponent";
import QuestionPopup from "./questionPopup";
import styles from "./styles.module.scss";

interface QuestionsProps {
  questions: Question[];
  setQuestions: React.Dispatch<Question[]>;
  multiSelectionInQuestions?: boolean;
}

export default function Questions({
  questions,
  setQuestions,
  multiSelectionInQuestions,
}: QuestionsProps) {
  const { t, i18n } = useTranslation();
  const { confirm } = useConfirm();

  const columns = [
    { name: "question", title: t("question") },
    { name: "answerA", title: t("answer_x", { answer: "A" }) },
    { name: "answerB", title: t("answer_x", { answer: "B" }) },
    { name: "answerC", title: t("answer_x", { answer: "C" }) },
    { name: "answerD", title: t("answer_x", { answer: "D" }) },
  ];

  const [rows, setRows] = useState<any[]>([]);

  const getLocalizedAnswerA = useCallback(
    (question: Question) => {
      switch (i18n.language) {
        case "en":
          return question.answerA_EN;

        case "de":
          return question.answerA_DE;

        case "ru":
          return question.answerA_RU;

        case "uk":
          return question.answerA_UK;

        default:
          return question.answerA_PL;
      }
    },
    [i18n.language]
  );

  const getLocalizedAnswerB = useCallback(
    (question: Question) => {
      switch (i18n.language) {
        case "en":
          return question.answerB_EN;

        case "de":
          return question.answerB_DE;

        case "ru":
          return question.answerB_RU;

        case "uk":
          return question.answerB_UK;

        default:
          return question.answerB_PL;
      }
    },
    [i18n.language]
  );

  const getLocalizedAnswerC = useCallback(
    (question: Question) => {
      switch (i18n.language) {
        case "en":
          return question.answerC_EN;

        case "de":
          return question.answerC_DE;

        case "ru":
          return question.answerC_RU;

        case "uk":
          return question.answerC_UK;

        default:
          return question.answerC_PL;
      }
    },
    [i18n.language]
  );

  const getLocalizedAnswerD = useCallback(
    (question: Question) => {
      switch (i18n.language) {
        case "en":
          return question.answerD_EN;

        case "de":
          return question.answerD_DE;

        case "ru":
          return question.answerD_RU;

        case "uk":
          return question.answerD_UK;

        default:
          return question.answerD_PL;
      }
    },
    [i18n.language]
  );

  const getLocalizedQuestion = useCallback(
    (question: Question) => {
      switch (i18n.language) {
        case "en":
          return question.questionEN;

        case "de":
          return question.questionDE;

        case "ru":
          return question.questionRU;

        case "uk":
          return question.questionUK;

        default:
          return question.questionPL;
      }
    },
    [i18n.language]
  );

  useEffect(() => {
    setRows(
      questions.map((question, index) => {
        return {
          ...question,
          id: index,
          question: getLocalizedQuestion(question),
          answerA: (
            <div>
              {question.isAnswerACorrect ? (
                <FontAwesomeIcon
                  className={styles.faCheckCircle}
                  icon={faCheckCircle}
                />
              ) : (
                <FontAwesomeIcon
                  className={styles.faTimesCircle}
                  icon={faTimesCircle}
                />
              )}
              {getLocalizedAnswerA(question)}
            </div>
          ),
          answerB: (
            <div>
              {question.isAnswerBCorrect ? (
                <FontAwesomeIcon
                  className={styles.faCheckCircle}
                  icon={faCheckCircle}
                />
              ) : (
                <FontAwesomeIcon
                  className={styles.faTimesCircle}
                  icon={faTimesCircle}
                />
              )}
              {getLocalizedAnswerB(question)}
            </div>
          ),
          answerC: (
            <div>
              {question.isAnswerCCorrect ? (
                <FontAwesomeIcon
                  className={styles.faCheckCircle}
                  icon={faCheckCircle}
                />
              ) : (
                <FontAwesomeIcon
                  className={styles.faTimesCircle}
                  icon={faTimesCircle}
                />
              )}
              {getLocalizedAnswerC(question)}
            </div>
          ),
          answerD: (
            <div>
              {question.isAnswerDCorrect ? (
                <FontAwesomeIcon
                  className={styles.faCheckCircle}
                  icon={faCheckCircle}
                />
              ) : (
                <FontAwesomeIcon
                  className={styles.faTimesCircle}
                  icon={faTimesCircle}
                />
              )}
              {getLocalizedAnswerD(question)}
            </div>
          ),
        };
      })
    );
  }, [
    questions,
    i18n.language,
    getLocalizedQuestion,
    getLocalizedAnswerA,
    getLocalizedAnswerB,
    getLocalizedAnswerC,
    getLocalizedAnswerD,
  ]);

  return (
    <>
      <div>
        <TableComponentMemo
          layoutSettingName=""
          ifHideWholeToolbalr={true}
          rows={rows}
          columns={columns}
          actionsColumnUpdated={{
            popup: (props) => (
              <QuestionPopup
                {...props}
                multiSelectionInQuestions={multiSelectionInQuestions}
              />
            ),
            addButton: {
              ifShow: true,
              successCallbackAdd: (values: Question) => {
                let questionsCopy = [...questions];
                questionsCopy.push(values);
                setQuestions(questionsCopy);
              },
            },
            editButton: {
              ifShow: true,
              name: t("edit"),
              successCallbackEdit: (values: Question, row) => {
                let questionsCopy = [...questions];
                questionsCopy[row.id] = {
                  ...values,
                  id: questions[row.id].id,
                };
                setQuestions(questionsCopy);
              },
            },
            removeButton: {
              ifShow: true,
              onClick: async (row) => {
                let selectedbuttonId = await confirm({
                  text: t("are_you_sure_you_want_to_delete_it") + "?",
                  buttons: [
                    { buttonName: t("remove"), buttonId: 0 },
                    { buttonName: t("cancel"), buttonId: 1 },
                  ],
                });
                if (selectedbuttonId === 0) {
                  let questionsCopy = [...questions];
                  questionsCopy.splice(row.id, 1);
                  setQuestions(questionsCopy);
                }
              },
            },
          }}
        />
      </div>
    </>
  );
}
