import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../PopupHelpers/dialogActionsStyled";
import DialogStyled from "../../PopupHelpers/dialogStyled";
import DialogTitleStyled from "../../PopupHelpers/dialogTitleStyled";
import { DialogContentStyled } from "../../PopupHelpers/dialogContentStyled";
import styles from "./styles.module.scss";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import { colorNameDictionary } from "../../../ProfilesRouting/profilesDictionaries";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "./../../../../reducers/session";
import { useEffect } from "react";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  guests: IBlackListGuest[];
  notificationId: number;
  pathApiNotifications: string;
}

const PopupInformationAboutGuestsFromBlackList = ({
  isOpen,
  setIsOpen,
  guests,
  notificationId,
  pathApiNotifications,
}: IProps) => {
  const { t } = useTranslation();
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;

  const [, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${pathApiNotifications}/${notificationId}/increment-number-of-attempts-for-guests-from-black-list`,
    method: "PUT",
    contentType: "application/json",
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (notificationId > 0) {
      fetchAgainPostData();
    }
  }, [notificationId, fetchAgainPostData]);

  return (
    <DialogStyled
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <DialogTitleStyled title={t("confirm_notification")} />

      <DialogContentStyled>
        <p className={styles.infoAboutContactPFSO}>
          {t(
            "cant_confirm_notification_because_it_contains_persons_from_blacklist"
          )}
          .
        </p>

        <p className={styles.infoAboutContactPFSO}>{t("contact_pfso")}!</p>

        <table className={styles.personsFromBlackListTable}>
          <thead>
            <tr style={{ backgroundColor: colorNameDictionary[profileType] }}>
              <th>{t("person")}</th>
              <th>{t("date_of_birth")}</th>
              <th>Email</th>
            </tr>
          </thead>

          <tbody>
            {guests.map((el) => {
              return (
                <tr>
                  <th>
                    {el.guestFirstName} {el.guestLastName}
                  </th>
                  <th>
                    {el.guestDateOfBirth
                      ? toLocaleStringCustom({ date: el.guestDateOfBirth, t })
                      : "-"}
                  </th>
                  <th>{el.guestEmail ? el.guestEmail : "-"}</th>
                </tr>
              );
            })}
          </tbody>
        </table>
      </DialogContentStyled>

      <DialogActionsStyled
        formConfirm="submitForm"
        ifHideCancel={true}
        typConfirm="button"
        onConfirmText={t("close")}
        onConfirm={() => {
          setIsOpen(false);
        }}
      />
    </DialogStyled>
  );
};

export default PopupInformationAboutGuestsFromBlackList;
