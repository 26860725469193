import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useLocation, withRouter } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faLock,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/hooks";
import { setAuthUser } from "../../reducers/session";
import {
  profilesRoutesDictionary,
  profileTranslationKeysDictionary,
  colorWithHoverClassNameDictionary,
} from "../ProfilesRouting/profilesDictionaries";
import { selectAuthUser } from "../../reducers/session";
import { useAppSelector } from "../../store/hooks";
import useLogout from "../../hooks/LogOut/logOut";
import { Collapse } from "@material-ui/core";
import styles from "../Navbar/navbar.module.scss";
import stylesProfile from "./profile.module.scss";
import { logIn } from "../LoginPage/logIn";
import { setIsOpenLeftNavbar } from "../../reducers/isOpenLeftNavbar";
import { ProfileType } from "../../enums/profileType";

function DropDownMenu(props) {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const dispatch = useAppDispatch();
  const [logOut, setLogOut] = useState<number>();
  const authUserProfileType = authUser.currentProfile.type;
  const location = useLocation();
  const currentPath = location.pathname;

  useLogout(logOut);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ifShowSettings = true;

  const ifShowChangePassword =
    authUserProfileType === ProfileType.WORKER_TIME ||
    authUserProfileType === ProfileType.AGENT ||
    authUserProfileType === ProfileType.RECEPTION ||
    authUserProfileType === ProfileType.SECURITY ||
    authUserProfileType === ProfileType.PFSO ||
    authUserProfileType === ProfileType.SHIFT_DIRECTOR ||
    authUserProfileType === ProfileType.EMPLOYEE ||
    authUserProfileType === ProfileType.OHS ||
    authUserProfileType === ProfileType.OPERATIONAL_SUPPORT ||
    authUserProfileType === ProfileType.EVENTS_PREVIEW;

  const currentProfile: authUserSingleProfileData = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  ).currentProfile;

  return (
    <div>
      <div
        className={styles.userName}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {authUser.displayedName} <ArrowDropDownIcon />
      </div>
      <Collapse in={isOpen}>
        <div className={`${styles.navigationLinks} ${styles.userMenuList}`}>
          {ifShowSettings ? (
            <div className={styles.linkContainerNavbar}>
              <div>
                {location.pathname === "/settings" && (
                  <div className={styles.roundingContainer}>
                    <div
                      className={
                        styles.roundingTop + " " + styles.background_4e4f52
                      }
                    ></div>
                  </div>
                )}
              </div>
              <div>
                <Link className={styles.singleLinkNavigation} to={"/settings"}>
                  <div
                    className={
                      styles.userPanelSingleLink +
                      " " +
                      (currentPath === "/settings" &&
                        styles.userPanelSelectedLink)
                    }
                  >
                    <div className={styles.userPanelLinkInner}>
                      <div>
                        <FontAwesomeIcon icon={faLock} />
                        &nbsp; {t("settings")}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div>
                {location.pathname === "/settings" && (
                  <div className={styles.roundingContainer}>
                    <div
                      className={
                        styles.roundingBottom + " " + styles.background_4e4f52
                      }
                    ></div>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {ifShowChangePassword ? (
            <div className={styles.linkContainerNavbar}>
              <div>
                {location.pathname === "/change-password" && (
                  <div className={styles.roundingContainer}>
                    <div
                      className={
                        styles.roundingTop + " " + styles.background_4e4f52
                      }
                    ></div>
                  </div>
                )}
              </div>
              <div>
                <Link
                  className={styles.singleLinkNavigation}
                  to={"/change-password"}
                >
                  <div
                    className={
                      styles.userPanelSingleLink +
                      " " +
                      (currentPath === "/change-password" &&
                        styles.userPanelSelectedLink)
                    }
                  >
                    <div className={styles.userPanelLinkInner}>
                      <div>
                        <FontAwesomeIcon icon={faLock} />
                        &nbsp; {t("password_change")}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div>
                {location.pathname === "/change-password" && (
                  <div className={styles.roundingContainer}>
                    <div
                      className={
                        styles.roundingBottom + " " + styles.background_4e4f52
                      }
                    ></div>
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <div>
            <div
              className={styles.userPanelSingleLink}
              onClick={(e) => {
                setLogOut(401);
              }}
            >
              <div className={styles.userPanelLinkInner}>
                <div>
                  <FontAwesomeIcon icon={faSignOutAlt} /> &nbsp; {t("log_out")}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.profilesAvailableCenter}>
            <div className={styles.profilesAvailable}>
              <span>{t("profiles_available")}</span>
            </div>
          </div>

          <div className={styles.changeUserButtons}>
            <div>
              {authUser.tokenDecoded.profiles.map((profile, inedx) => {
                return (
                  <NavLink
                    key={inedx}
                    className={styles.selectUserLink}
                    to={`/${profilesRoutesDictionary[profile.type]}/home`}
                  >
                    <div
                      className={styles.changeUserButton}
                      onClick={(e) => {
                        logIn({
                          token: authUser.token,
                          tokenDecoded: authUser.tokenDecoded,
                          selectedProfile: profile,
                          isChangingProfileOldProfileType: currentProfile.type,
                          isChangingProfileOldProfileSubjectId:
                            currentProfile.subjectId,

                          setAuthUserToRedux: (authUser) =>
                            dispatch(setAuthUser(authUser)),
                          resetReduxState: () => {
                            dispatch({ type: "RESET_STATE" });
                            dispatch(setIsOpenLeftNavbar(true));
                          },
                        });
                        setIsOpen(false);
                      }}
                    >
                      <div
                        className={
                          styles.selectUserType +
                          " " +
                          colorWithHoverClassNameDictionary[profile.type]
                        }
                      >
                        {t(profileTranslationKeysDictionary[profile.type])}
                        {profile?.company ? ` - ${profile.company}` : null}
                        {profile.type === authUser.currentProfile.type &&
                          profile.subjectId ===
                            authUser.currentProfile.subjectId && (
                            <div
                              className={
                                stylesProfile.currentProfileCheckCircle
                              }
                            >
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                          )}
                      </div>
                    </div>
                  </NavLink>
                );
              })}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default withRouter(DropDownMenu);
