import { CircularProgress } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import Button from "../../helpersComponents/Button/button";
import { TextFieldStyled } from "../../helpersComponents/MaterialUi/index";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../helpersComponents/PopupHelpers";
import styles from "./addShipFormStyle.module.scss";

interface IProps {
  pathApiShips: string;
  setShowAddShipForm: Dispatch<SetStateAction<any>>;
  setAddedShipId: Dispatch<SetStateAction<any>>;
  ships: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  addedShipId: number | false;
}

const FormAddNewShip: React.FC<IProps> = ({
  pathApiShips,
  setShowAddShipForm,
  setAddedShipId,
  ships,
  setFieldValue,
  addedShipId,
}) => {
  const { t } = useTranslation();

  const [shipName, setShipName] = useState("");
  const [shipNameError, setShipNameError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [shipDescription, setShipDescription] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: pathApiShips,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (addedShipId && ships) {
      let foundShip = ships.find((ship: IShip) => ship.id === addedShipId);
      if (foundShip) {
        setFieldValue("ship", foundShip);
        setAddedShipId(false);
        setShowAddShipForm(false);
      }
    }
  }, [addedShipId, ships, setFieldValue, setAddedShipId, setShowAddShipForm]);

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  const confirm = () => {
    let ifFetch = true;

    if (shipName === "") {
      setShipNameError({
        isError: true,
        errorMessage: t("field_required"),
      });
      ifFetch = false;
    }

    if (
      ships.data !== null &&
      ships.find((ship: IShip) => ship.name === shipName)
    ) {
      setShipNameError({
        isError: true,
        errorMessage: t("ship_name_exists"),
      });
      ifFetch = false;
    }

    if (!ifFetch) return;
    setBodyRequest(
      JSON.stringify({
        name: shipName,
        description: shipDescription !== "" ? shipDescription : null,
      })
    );
  };

  useEffect(() => {
    if (fetchingStatePostData.response?.status === 201) {
      if (fetchingStatePostData.response.location) {
        let id = fetchingStatePostData.response.location.substring(
          fetchingStatePostData.response.location.lastIndexOf("/") + 1
        );
        setAddedShipId(parseInt(id));
      }
    }
  }, [fetchingStatePostData.response, setShowAddShipForm, setAddedShipId]);

  return (
    <>
      <DialogContentStyled style={{ backgroundColor: "#eef4f5" }}>
        <Row>
          <RowTitle>{t("name")}:</RowTitle>
          <RowInput>
            <TextFieldStyled
              label={t("name")}
              required={true}
              isError={shipNameError.isError}
              ifToUpperCaseShipsAndPorts={true}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value === "") {
                  setShipNameError({
                    isError: true,
                    errorMessage: t("field_required"),
                  });
                } else if (
                  ships !== null &&
                  ships.find((ship: IShip) => ship.name === e.target.value)
                ) {
                  setShipNameError({
                    isError: true,
                    errorMessage: t("ship_name_exists"),
                  });
                } else {
                  setShipNameError({
                    isError: false,
                    errorMessage: "",
                  });
                }
                setShipName(e.target.value);
              }}
            />
            {shipNameError.isError && (
              <div className={styles.errorMessage}>
                {shipNameError.errorMessage}
              </div>
            )}
          </RowInput>
        </Row>
        <Row>
          <RowTitle>{t("description")}:</RowTitle>
          <RowInput>
            <TextFieldStyled
              label={t("description")}
              required={false}
              ifToUpperCaseShipsAndPorts={true}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                setShipDescription(e.target.value)
              }
            />
          </RowInput>
        </Row>
        <Row>
          <RowTitle>
            {!fetchingStatePostData.isFetching ? (
              <>
                <div className={styles.containerAddShipButton}>
                  <Button
                    className={styles.buttonAddShip}
                    onClick={() => confirm()}
                    type="button"
                  >
                    {t("add_ship")}
                  </Button>
                </div>
              </>
            ) : (
              <div className="confirmWaiting">
                <CircularProgress size={25} />
              </div>
            )}
          </RowTitle>
          {errorMessage && errorMessage !== "" && (
            <RowInput style={{ color: "red" }}>{errorMessage}</RowInput>
          )}
        </Row>
      </DialogContentStyled>
    </>
  );
};

export default FormAddNewShip;
