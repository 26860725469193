export function timeStringToHours(time: string): number {
  const timeParts = time.split(":");
  return parseInt(timeParts[0]) + parseInt(timeParts[1]) / 60;
}

export function timeStringToMinutes(time: string): number {
  const timeParts = time.split(":");
  return parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);
}

export function timeStringToHoursMinutesAndSeconds(time: string): number {
  const timeParts = time.split(":");
  return parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);
}

export function converStringTimeToMilitaryStringTime(timeString: string) {
  // Sprawdź czy czas jest w formacie AM/PM
  let isAM = timeString.includes("AM") || timeString.includes("am");
  let isPM = timeString.includes("PM") || timeString.includes("pm");

  // Jeśli to jest czas PM i nie jest to 12:00 PM, dodaj 12 godzin
  if (isPM && !timeString.startsWith("12")) {
    let timeArray = timeString.split(":");
    timeArray[0] = String(parseInt(timeArray[0]) + 12);
    timeString = timeArray.join(":");
  }

  // Jeśli to jest czas AM i jest to 12:00 AM, zamień na 00:00
  if (isAM && timeString.startsWith("12")) {
    let timeArray = timeString.split(":");
    timeArray[0] = "00";
    timeString = timeArray.join(":");
  }

  // Usuń "AM" lub "PM" z czasu
  timeString = timeString.replace(/[^\d:]/g, "");

  return timeString;
}
