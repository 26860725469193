import { useState } from "react";

const FormatRegistrationNumberInGrid = ({
  registrationNumber,
}: {
  registrationNumber: string | null;
}) => {
  const [showMore, setShowMore] = useState(false);
  if (!registrationNumber) return <p></p>;
  let array: string[] = [];
  if (registrationNumber.includes(",")) {
    array = registrationNumber.split(",");
  } else if (registrationNumber.includes(";")) {
    array = registrationNumber.split(";");
  }

  let content = array.length > 0 ? array.join(", ") : registrationNumber;
  const maxOneLineLength = 10;

  return (
    <p
      onClick={() => {
        setShowMore((prev) => !prev);
      }}
      style={{
        cursor: content.length > maxOneLineLength ? "pointer" : "auto",
      }}
    >
      {content.length > maxOneLineLength ? (
        showMore ? (
          <span>{content}</span>
        ) : (
          <span>
            {content.substring(0, maxOneLineLength)}
            <span>...</span>
          </span>
        )
      ) : (
        content
      )}
    </p>
  );
};

export default FormatRegistrationNumberInGrid;
