import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../../../../helpersComponents/PopupHelpers";
import { SpecialPermissionsValue } from "../SpecialPermissionNumber";
import PopupSpecialPermissionItemContent from "./PopupSpecialPermissionItemContent";

export interface IAddSpecialPermission {
  specialPermissionNumber: SpecialPermissionsValue;
  startTime: string;
  endTime: string;
  onlyRangeFromCheck: boolean;
  rangeFrom: Date | null;
  rangeTo: Date | null;
  daysOfWeek: IDayOfWeek[];
  isHoliday: boolean;
  roomsIds: number[];
}

interface IProps {
  isOpen: boolean;
  closePopup: () => void;
  formikValues: {
    [x: string]: string | number | boolean | number[] | null | any[] | Date;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  specialPermissionsFormField: FormField;
}

const PopupSpecialPermissionItem: React.FC<IProps> = ({
  isOpen,
  closePopup,
  formikValues,
  setFieldValue,
  specialPermissionsFormField,
}) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(true);

  const onSubmit = (values: IAddSpecialPermission, selectedRooms: Room[]) => {
    let specialPermissions: Partial<SpecialPermission>[] = [
      ...(formikValues[
        `${specialPermissionsFormField.name}`
      ] as Partial<SpecialPermission>[]),
    ];

    const daysSum = values.daysOfWeek.reduce(
      (partialSum, day) => partialSum + day.value,
      0
    );

    values.rangeFrom = values.onlyRangeFromCheck
      ? new Date(values.rangeFrom!.setHours(0, 0, 0))
      : new Date(2000, 0, 1);

    values.rangeTo = values.onlyRangeFromCheck
      ? new Date(values.rangeTo!.setHours(0, 0, 0))
      : new Date(9998, 11, 31);

    let startTimeDate: Date;
    let endTimeDate: Date;

    let today = new Date();
    const formatStartTime = values.startTime
      .split(":")
      .map((item) => Number(item));
    startTimeDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      formatStartTime[0],
      formatStartTime[1]
    );

    const formatEndTime = values.endTime.split(":").map((item) => Number(item));
    endTimeDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      formatEndTime[0],
      formatEndTime[1]
    );

    let errors = false;
    let index = specialPermissions.length + 1;
    const selectedRoomsIds = values.roomsIds;
    selectedRoomsIds.forEach((roomId) => {
      const newPermission = {
        id: index++,
        specialPermissionNumber: values.specialPermissionNumber.id,
        specialPermissionNumberName: values.specialPermissionNumber.value,
        roomId: roomId,
        roomName: selectedRooms.find((room) => room.id === roomId)?.name,
        day: daysSum,
        daysValues: values.daysOfWeek.map((day) => day.value),
        dayName: values.daysOfWeek.map((day) => day.name).join(","),
        entryFrom: startTimeDate,
        entryTo: endTimeDate,
        isHoliday: values.isHoliday,
        rangeFrom: values.rangeFrom!,
        rangeTo: values.rangeTo!,
        added: true,
      };

      // Validation  if permissions overlaps
      errors = specialPermissionsAreOverlap(specialPermissions, newPermission);
      if (!errors) {
        specialPermissions.push(newPermission);
      }
    });

    if (!errors) {
      const allPermissions = specialPermissions.sort((a, b) => a.day! - b.day!);
      setFieldValue(specialPermissionsFormField.name, allPermissions);
      closePopup();
    } else {
      setIsValid(false);
    }
  };

  const specialPermissionsAreOverlap = (
    permissions: Partial<SpecialPermission>[],
    newPermission: Partial<SpecialPermission>
  ) => {
    if (
      permissions.some((perm) => {
        return (
          perm.roomId === newPermission.roomId &&
          perm.daysValues?.some((p) => newPermission.daysValues?.includes(p)) &&
          new Date(perm.entryFrom!) <= newPermission.entryTo! &&
          newPermission.entryFrom! <= new Date(perm.entryTo!) &&
          new Date(perm.rangeFrom!) <= newPermission.rangeTo! &&
          newPermission.rangeFrom! <= new Date(perm.rangeTo!)
        );
      })
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      disableBackdropClick={true}
      maxWidth={"lg"}
    >
      <DialogTitleStyled title={t("special_permission")} />
      <PopupSpecialPermissionItemContent
        onSubmit={onSubmit}
        isValid={isValid}
      />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="specialPermissionForm"
        onCancel={closePopup}
      />
    </DialogStyled>
  );
};

export default PopupSpecialPermissionItem;
