import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import TextField from '@material-ui/core/TextField';
// import FormGroup from '@material-ui/core/FormGroup';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddEditTaskDialogContent from "./addEditTaskDialogContent";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import useFetch from "../../../../hooks/fetchHooks/useFetch/useFetch";
import { makeStyles } from "@material-ui/styles";

interface Props {
  projectId?: number;
  setIfFetchAgain: React.Dispatch<boolean>;
  taskId?: number;
  setIsDialogOpen: React.Dispatch<boolean>;
  isDialogOpen: boolean;
  projectsList?: any;
}

export default function AddEditTask({
  projectId,
  setIfFetchAgain,
  taskId,
  setIsDialogOpen,
  isDialogOpen,
  projectsList,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [ifFetchTaskStages, setIfFetchTaskStages] = useState<boolean>(false);
  const [fetchedTask, setFetchedTask] = useState<any>(false);
  const [projectStages, setProjectStages] = useState<any>([]);
  const [projectListLocal] = useState(() => {
    if (projectsList === undefined) return undefined;
    let ifDefaultExists = projectsList.find(
      (project) => project.isDefault === true
    );
    if (ifDefaultExists) return projectsList;
    else {
      let projectsListCopy = [...projectsList];
      projectsListCopy.push({ id: 0, name: t("default"), isDefault: true });
      return projectsListCopy;
    }
  });
  const [projectIdLocal, setProjectIdLocal] = useState<any>(
    projectsList
      ? () => {
          let initialSelectedId = 0;
          projectsList.forEach((project) => {
            if (project.isDefault) initialSelectedId = project.id;
          });
          return initialSelectedId;
        }
      : projectId
  );

  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const taskByIdResponse = useFetchGet(
    `worker-time/${authUserId}/projects/${projectId}/tasks/${taskId}`,
    ifFetchTaskStages && taskId && projectsList === undefined ? true : false
  );

  const projectResponseStages = useFetchGet(
    `worker-time/${authUserId}/projects/${projectIdLocal}/stages`,
    ifFetchTaskStages && isDialogOpen ? true : false
  );

  useEffect(() => {
    if (
      taskByIdResponse.error !== null ||
      projectResponseStages.error !== null
    ) {
      setIsError(true);
      setIfFetchTaskStages(false);
      return;
    }
    if (
      (taskByIdResponse.data !== null &&
        projectResponseStages.data !== null &&
        taskId !== undefined) ||
      (taskId === undefined && projectResponseStages.data !== null)
    ) {
      let sortedStages = projectResponseStages.data.sort((a, b) => {
        if (a.order < b.order) return -1;
        return 0;
      });
      setProjectStages([
        {
          id: 0,
          projectId: projectId,
          name: t("no_stage"),
          order: 0,
        },
        ...sortedStages,
      ]);
      if (taskId !== undefined) setFetchedTask(taskByIdResponse.data.tasks[0]);
      setIsLoading(false);
      setIfFetchTaskStages(false);
    }
  }, [taskByIdResponse, projectResponseStages, taskId, projectId, t]);

  const [confidentialData, setConfidentialData] = useState<string | false>(
    false
  );

  let [
    setIsFetchingConfidential,
    fetchingStateConfidential,
    fetchAgainConfidential,
  ] = useFetch({
    method: "GET",
    path: `worker-time/${authUserId}/projects/${projectId}/task-confidential-data/${taskId}`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (
      fetchingStateConfidential.isError ||
      fetchingStateConfidential.response === undefined
    )
      return;
    let data = fetchingStateConfidential.response.resJson;
    setConfidentialData(data);
    setIsFetchingConfidential(false);
  }, [
    fetchingStateConfidential.isError,
    fetchingStateConfidential.response,
    setIsFetchingConfidential,
  ]);

  useEffect(() => {
    if (isDialogOpen) {
      if (taskId) {
        fetchAgainConfidential();
      }
      setIsLoading(true);
      setIfFetchTaskStages(true);
    } else {
      setIsLoading(false);
      setIfFetchTaskStages(false);
    }
  }, [isDialogOpen, fetchAgainConfidential, taskId]);

  const useStyles = makeStyles(() => ({
    dialog: {
      height: 800,
      width: 950,
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <Dialog
        maxWidth={"xl"}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle className="dialogTitleGlobal">
          <div className="titlePopup">
            {t(taskId ? "editing_task" : "adding_task")}
          </div>
        </DialogTitle>
        <LoadingWrapper
          isLodadingProgress={isLoading || fetchingStateConfidential.isFetching}
          isError={isError || fetchingStateConfidential.isError}
          setIfFetchAgain={(v) => {
            if (isError) setIfFetchTaskStages(v);
            if (fetchingStateConfidential.isError) fetchAgainConfidential();
          }}
          setIsError={setIsError}
          bigSize={true}
        >
          {!isLoading && !isError && (
            <AddEditTaskDialogContent
              projectId={projectId}
              setIsDialogOpen={setIsDialogOpen}
              projectStages={projectStages}
              setIfFetchAgain={setIfFetchAgain}
              setIfFetchAgainTaskStages={setIfFetchTaskStages}
              setNewProjectId={setProjectIdLocal}
              task={fetchedTask}
              projectsList={projectListLocal}
              isFetchingNewStages={ifFetchTaskStages}
              confidentialData={confidentialData}
            />
          )}
        </LoadingWrapper>
      </Dialog>
    </div>
  );
}
