import React from 'react';
import EvacuationContent from "./evacuationContent"


function Evacuation() {
    return (
        <div>
            <EvacuationContent/>
        </div>
    );
}

export default Evacuation;
