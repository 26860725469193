import List, { ItemDragging } from "devextreme-react/list";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import EmployeesReportAvailableColumnItemTemplate from "./EmployeesReportAvailableColumnItemTemplate";

interface IProps {
  employeesReportAvailableColumns: Array<IEmployeesReportColumn>;
  columnsFilter: string[];
  setColumnsFilter: Dispatch<SetStateAction<string[]>>;
}

const EmployeesReportAvailableColumnsList: React.FC<IProps> = ({
  employeesReportAvailableColumns,
  columnsFilter,
  setColumnsFilter,
}) => {
  const [availableColumns, setAvailableColumns] = useState<
    IEmployeesReportColumn[]
  >([]);

  useEffect(() => {
    setAvailableColumns(
      employeesReportAvailableColumns
        ? [...employeesReportAvailableColumns]
        : []
    );
  }, [employeesReportAvailableColumns]);

  const onDragStart = useCallback(
    (e) => {
      e.itemData = availableColumns[e.fromIndex];
    },
    [availableColumns]
  );

  const onAdd = useCallback((e) => {
    setAvailableColumns((prev) => [
      ...prev.slice(0, e.toIndex),
      e.itemData,
      ...prev.slice(e.toIndex),
    ]);
  }, []);

  const onRemove = useCallback((e) => {
    setAvailableColumns((prev) => [
      ...prev.slice(0, e.fromIndex),
      ...prev.slice(e.fromIndex + 1),
    ]);
  }, []);

  const onReorder = useCallback(
    (e) => {
      onRemove(e);
      onAdd(e);
    },
    [onRemove, onAdd]
  );

  const onSelectedItemKeysChange = useCallback(
    (args) => {
      if (args.name === "selectedItemKeys") {
        setColumnsFilter([...args.value]);
      }
    },
    [setColumnsFilter]
  );

  return (
    <>
      {availableColumns && (
        <List
          dataSource={availableColumns}
          keyExpr="fieldName"
          repaintChangesOnly={true}
          itemRender={EmployeesReportAvailableColumnItemTemplate}
          height={200}
          showSelectionControls={true}
          selectionMode={"multiple"}
          selectAllMode={"allPages"}
          selectedItemKeys={columnsFilter}
          onOptionChanged={onSelectedItemKeysChange}
        >
          <ItemDragging
            allowReordering={true}
            group="availableColumns"
            data="availableColumns"
            onDragStart={onDragStart}
            onAdd={onAdd}
            onRemove={onRemove}
            onReorder={onReorder}
          ></ItemDragging>
        </List>
      )}
    </>
  );
};

export default EmployeesReportAvailableColumnsList;
