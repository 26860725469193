import {
  faMinusCircle,
  faMinusSquare,
  faPlusCircle,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { DaysOfWeek } from "../../../../../../Constants/daysOfWeek";
import toLocaleStringCustom from "../../../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import useConfirm from "../../../../../../hooks/useConfirm/useConfirm";
import StyledDataGrid from "../../../../../helpersComponents/MaterialUi/DataGrid/StyledDataGrid";
import PopupGroupPermissionItem from "./GroupPermissionItem/PopupGroupPermissionItem";
import styles from "./GroupPermissions.module.scss";
import PopupZonePermissionItem from "./ZonePermissionItem/PopupZonePermissionItem";

interface IProps {
  formikValues: {
    [x: string]:
      | string
      | number
      | boolean
      | number[]
      | null
      | any[]
      | Date
      | any;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const GroupPermissions: React.FC<IProps> = ({
  formikValues,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const daysOfWeek = DaysOfWeek(t);

  const columnsGroupPermissions: GridColDef[] = [
    {
      field: "roomName",
      headerName: t("room"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.roomName}</span>
      ),
    },
    {
      field: "dayName",
      headerName: t("day"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {daysOfWeek.find((dayItem) => dayItem.value === params.row.day)?.name}
        </span>
      ),
    },
    {
      field: "entryFrom",
      headerName: t("from"),
      flex: 0.5,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {new Date(params.row.entryFrom).toLocaleString(
            t("scheduler_language"),
            {
              hour: "2-digit",
              minute: "2-digit",
            }
          )}
        </span>
      ),
    },
    {
      field: "entryTo",
      headerName: t("to"),
      flex: 0.5,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {new Date(params.row.entryTo).toLocaleString(
            t("scheduler_language"),
            {
              hour: "2-digit",
              minute: "2-digit",
            }
          )}
        </span>
      ),
    },
    {
      field: "rangeFrom",
      headerName: t("is_valid_from"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {toLocaleStringCustom({
            date: params.row.rangeFrom,
            t,
            ifShowHours: false,
          })}
        </span>
      ),
    },
    {
      field: "rangeTo",
      headerName: t("is_valid_to"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {toLocaleStringCustom({
            date: params.row.rangeTo,
            t,
            ifShowHours: false,
          })}
        </span>
      ),
    },
    {
      field: "zoneName",
      headerName: t("from_zone"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {params.row.zoneName ? params.row.zoneName : "-"}
        </span>
      ),
    },
  ];

  const [groupPermissionItemPopup, setGroupPermissionItemPopup] = useState<{
    isOpen: boolean;
    permissionItem: IGroupPermission | null;
  }>({
    isOpen: false,
    permissionItem: null,
  });
  const closeGroupPermissionItemPoup = useCallback(() => {
    setGroupPermissionItemPopup({
      isOpen: false,
      permissionItem: null,
    });
  }, []);

  const addGroupPermissionHandler = () => {
    setGroupPermissionItemPopup({
      isOpen: true,
      permissionItem: null,
    });
  };

  const [permissionsIdsToRemove, setPermissionsIdsToRemove] = useState<
    number[]
  >([]);
  const { confirm } = useConfirm();
  const permissionRemoveHandler = async () => {
    let confirmResponse = await confirm({
      text: `${t("are_you_sure_you_want_to_remove_selected_permissions")}?`,
      buttons: [
        { buttonName: t("yes"), buttonId: 0 },
        { buttonName: t("cancel"), buttonId: 1 },
      ],
    });
    if (confirmResponse === 0) {
      const permissionsList = formikValues["permissions"] as IGroupPermission[];
      const zonesPermissionsList = formikValues[
        "zonePermissions"
      ] as IZonePermission[];

      const permissionsToRemove = permissionsList.filter((permission) =>
        permissionsIdsToRemove.includes(permission.id)
      );
      if (permissionsToRemove) {
        const permissionsRoomsIdsToRemove = permissionsToRemove.map(
          (perm) => perm.roomId
        );
        const permissionsZonesIdsToRemove = permissionsToRemove.map(
          (perm) => perm.zoneId
        );
        let zonesPermissionsIdsToRemove: number[] = [];

        permissionsToRemove.forEach((permission) => {
          if (permission.zoneId) {
            const zonePermissionToRemove = zonesPermissionsList.find(
              (perm) =>
                permissionsRoomsIdsToRemove.includes(perm.roomId) &&
                permissionsZonesIdsToRemove.includes(perm.zoneId)
            );
            if (zonePermissionToRemove) {
              zonesPermissionsIdsToRemove.push(zonePermissionToRemove.id);
            }
          }
        });
        const filteredZonesPermissions = zonesPermissionsList.filter(
          (perm) => !zonesPermissionsIdsToRemove.includes(perm.id)
        );
        setFieldValue("zonePermissions", filteredZonesPermissions);
      }

      const filteredPermissions = permissionsList.filter(
        (permission) => !permissionsIdsToRemove.includes(permission.id)
      );

      setFieldValue("permissions", filteredPermissions);
    }
  };

  const [zonePermissionItemPopup, setZonePermissionItemPopup] = useState<{
    isOpen: boolean;
    permissionItem: IGroupPermission | null;
  }>({
    isOpen: false,
    permissionItem: null,
  });
  const closeZonePermissionItemPoup = useCallback(() => {
    setZonePermissionItemPopup({
      isOpen: false,
      permissionItem: null,
    });
  }, []);

  const addZonePermissionHandler = () => {
    setZonePermissionItemPopup({
      isOpen: true,
      permissionItem: null,
    });
  };

  const zonePermissionRemoveHandler = async () => {
    let confirmResponse = await confirm({
      text: `${t("are_you_sure_you_want_to_remove_selected_permissions")}?`,
      buttons: [
        { buttonName: t("yes"), buttonId: 0 },
        { buttonName: t("cancel"), buttonId: 1 },
      ],
    });
    if (confirmResponse === 0) {
      const zonePermissionsList = formikValues[
        "zonePermissions"
      ] as IZonePermission[];

      const permissionsList = formikValues["permissions"] as IGroupPermission[];

      const permissionsToRemove = permissionsList.filter((perm) =>
        permissionsIdsToRemove.includes(perm.id)
      );

      const zonesIdsToRemove = permissionsToRemove.map((perm) => perm.zoneId);
      if (zonesIdsToRemove) {
        const filteredZonesPermissions = zonePermissionsList.filter(
          (permission) => !zonesIdsToRemove.includes(permission.zoneId)
        );
        setFieldValue("zonePermissions", filteredZonesPermissions);

        const permissionsList = formikValues[
          "permissions"
        ] as IGroupPermission[];
        const filteredPermissionsList = permissionsList.filter(
          (perm) => !zonesIdsToRemove.includes(perm.zoneId!)
        );
        setFieldValue("permissions", filteredPermissionsList);
      }
    }
  };

  return (
    <>
      <div className={styles.grid}>
        <div className={styles.titleContainer}>
          <p className={styles.gridTitle}>{t("permissions_of_group")}</p>
          <div className={styles.buttonsContainer}>
            <div className={styles.addButtonsContainer}>
              <Tooltip title={t("add_room")!}>
                <div
                  className={styles.permissionButton}
                  onClick={addGroupPermissionHandler}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    size="lg"
                    color="green"
                  />
                </div>
              </Tooltip>

              <Tooltip title={t("add_zone")!}>
                <div
                  className={styles.permissionButton}
                  onClick={addZonePermissionHandler}
                >
                  <FontAwesomeIcon
                    icon={faPlusSquare}
                    size="lg"
                    color="green"
                  />
                </div>
              </Tooltip>
            </div>

            <div className={styles.removeButtonsContainer}>
              <div>
                {permissionsIdsToRemove.length > 0 && (
                  <Tooltip title={t("delete_room")!}>
                    <div
                      className={styles.permissionButton}
                      onClick={permissionRemoveHandler}
                    >
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        size="lg"
                        color="red"
                      />
                    </div>
                  </Tooltip>
                )}
              </div>

              <div>
                {permissionsIdsToRemove.length > 0 && (
                  <Tooltip title={t("delete_zone")!}>
                    <div
                      className={styles.permissionButton}
                      onClick={zonePermissionRemoveHandler}
                    >
                      <FontAwesomeIcon
                        icon={faMinusSquare}
                        size="lg"
                        color="red"
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
        <StyledDataGrid
          rows={formikValues["permissions"] as IGroupPermission[]}
          columns={columnsGroupPermissions}
          defaultPageSize={10}
          rowsPerPageOptions={[10]}
          density="compact"
          quickFilter
          checkboxSelection={true}
          onSelectionModelChange={(selected) => {
            setPermissionsIdsToRemove(selected as number[]);
          }}
        />
      </div>

      {groupPermissionItemPopup.isOpen && (
        <PopupGroupPermissionItem
          isOpen={groupPermissionItemPopup.isOpen}
          closePopup={closeGroupPermissionItemPoup}
          formikValues={formikValues}
          setFieldValue={setFieldValue}
        />
      )}

      {zonePermissionItemPopup.isOpen && (
        <PopupZonePermissionItem
          isOpen={zonePermissionItemPopup.isOpen}
          closePopup={closeZonePermissionItemPoup}
          formikValues={formikValues}
          setFieldValue={setFieldValue}
        />
      )}
    </>
  );
};

export default GroupPermissions;
