import React from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import Map from "../../helpersComponents/Map/map";
import StyledTabs from "../../helpersComponents/MaterialUi/Tabs/StyledTabs";
import "./events.scss";

const RowDetail: React.FC = ({ row }: any) => {
  const { t } = useTranslation();

  const [fetchedEvent, fetchingStateFetchedEvent, fetchAgainEvent] =
    useFetchAndSetGET({
      path: `events-preview/events/${row.id}`,
    });

  const tabs = [
    {
      label: t("location"),
      value: 0,
      content: (
        <div>
          {fetchedEvent?.latitude && fetchedEvent?.longitude ? (
            <div className="mapEventsSuperrior">
              <Map
                position={[
                  fetchedEvent.latitude ? fetchedEvent.latitude : "",
                  fetchedEvent.longitude ? fetchedEvent.longitude : "",
                ]}
              />
            </div>
          ) : (
            <div className="emptyContainer">
              <p>{t("no_location_to_display_map")}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      label: t("photos"),
      value: 1,
      content: (
        <>
          {fetchedEvent?.photos?.length > 0 && (
            <div className="eventPhotosContainer">
              {fetchedEvent?.photos.map((data, index: number) => {
                return (
                  <div className="photoContainer" key={index}>
                    <img
                      alt={`${fetchedEvent.firstName} ${fetchedEvent.lastName}`}
                      src={`data:image/jpeg;base64,${data}`}
                      className="eventPhoto"
                    />
                  </div>
                );
              })}
            </div>
          )}
          {fetchedEvent?.photos?.length === 0 && (
            <div className="emptyContainer">
              <p>{t("no_photos")}</p>
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <div className="eventsSuperriorContainer">
      <LoadingWrapper
        isLodadingProgress={fetchingStateFetchedEvent.isFetching}
        isError={fetchingStateFetchedEvent.isError}
        setIfFetchAgain={() => {
          if (fetchingStateFetchedEvent.isError) {
            fetchAgainEvent();
          }
        }}
      >
        {fetchedEvent && <StyledTabs tabs={tabs} underlineColor="black" />}
      </LoadingWrapper>
    </div>
  );
};

export default RowDetail;
