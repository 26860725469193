import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import { statusDictionary } from "../../../../Constants/statusDictionary";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import TableAndActions from "./tableAndActions";
import useFetch from "../../../../hooks/fetchHooks/useFetch/useFetch";
import { useAppSelector } from "../../../../store/hooks";
import { selectTimeWorkersSuperiorObject } from "../../../../reducers/workersLists";
import { SnackbarStyled } from "../../../helpersComponents/MaterialUi";
import { useHistory } from "react-router-dom";
import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import AddEditHolidayPlanRequestPopup from "../../../helpersComponents/Requests/HolidayPlan/AddEditHolidayPlanRequestPopup";
import { hoursRequestsTypes } from "../../../../Constants/hoursRequestsTypes";
import AnnualHolidaysPlan from "../../../helpersComponents/AnnualHolidaysPlan/annualHolidaysPlan";

let Cell: any = TableEditColumn.Cell;

const ActionColumn: React.FC = ({
  children,
  row,
  classes,
  ...restProps
}: any) => {
  return (
    <Cell row={restProps.row} className="tableCellEditCancelStyleGlobal">
      {children}
    </Cell>
  );
};

function HolidaysRequestsContent() {
  const date = new Date();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0)
  );
  const [endDate, setEndDate] = useState(
    new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)
  );
  const [dataModified, setDataModified] = useState<any>([]);
  const timeWorkersSuperiorObject = useAppSelector(
    selectTimeWorkersSuperiorObject
  );
  const [selectedWorkers, setSelectedWorkers] = useState([2]);
  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);

  /*const [ifCheckForUpdateCache, setIfCheckForUpdateCache] =
    useState<boolean>(false);

    const [ifClearReturnedValue, setIfClearReturnedValue] =
    useState<boolean>(false);

  const timeWorkers = useCachedFetch(
    "timeWorkersSuperiorTime",
    ifCheckForUpdateCache,
    3600,
    false,
    ifClearReturnedValue
  );*/

  const history: any = useHistory();

  let [setIsFetching, fetchingState, fetchAgain] = useFetch({
    method: "GET",
    path:
      "superior-time/absence-plans-requests?dateFrom=" +
      computeDateRangeFetch(startDate, "T00:00:00") +
      "&dateTo=" +
      computeDateRangeFetch(endDate, "T23:59:59"),
  });

  const [requestIdToRemove, setRequestIdToRemove] = useState<false | number>(
    false
  );

  const [deleteRequestFetchingState, deleteRequestFetchAgain] =
    useFetchOtherThanGET({
      path: `superior-time/absence-plans-requests/${requestIdToRemove}`,
      method: "DELETE",
      body: requestIdToRemove,
      setBody: setRequestIdToRemove,
      successCallback: fetchAgain,
    });

  useEffect(() => {
    if (requestIdToRemove !== false) {
      deleteRequestFetchAgain();
    }
  }, [requestIdToRemove, deleteRequestFetchAgain]);

  const [requestsPermissions, requestsPermissionsFetchingState] =
    useFetchAndSetGET<IHolidayPlansRequestsPermissions>({
      path: `superior-time/holiday-plans-requests-permissions`,
      startFetchOnInitial: true,
    });

  useEffect(() => {
    if (fetchingState.isError || fetchingState.response === undefined) return;
    if (!Array.isArray(fetchingState.response.resJson)) return;
    let data = fetchingState.response.resJson;

    data.forEach((element, index) => {
      data[index].time = convertMinsToHrsMins(
        (new Date(element.dateTo).getTime() -
          new Date(element.dateFrom).getTime()) /
          (1000 * 60)
      );
      data[index].statusTranslated = t(statusDictionary[data[index].status]);
      data[index].typeIdString = t(hoursRequestsTypes[data[index].absenceType]);

      data[index].employee = element.firstName + " " + element.lastName;
      data[index].organizationalUnits =
        timeWorkersSuperiorObject[element.timeWorkerId]?.organizationalUnits;
      data[index].evidenceNumber =
        timeWorkersSuperiorObject[element.timeWorkerId]?.evidenceNumber;

      data[index].typeDescription = element.absenceTypeDescription;
      data[index].approvedBy = element.approvedBy;
    });

    setDataModified(data);
    setIsFetching(false);
  }, [
    fetchingState.isFetching,
    fetchingState.isError,
    timeWorkersSuperiorObject,
    fetchingState.response,
    setIsFetching,
    t,
  ]);

  useEffect(() => {
    fetchAgain();
  }, [startDate, endDate, fetchAgain]);

  const columns = [
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "dateFrom", title: t("date_from"), type: "date_hours" },
    { name: "dateTo", title: t("date_to2"), type: "date_hours" },
    { name: "employee", title: t("employee") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "organizationalUnits", title: t("organizational_units") },
    { name: "typeDescription", title: t("type_of_request") },
    { name: "statusTranslated", title: t("status") },
    { name: "approvedBy", title: t("approved_by") },
  ];

  const commitChanges = ({ added, changed, deleted }) => {
    if (deleted) {
      let row: any = dataModified.find((row) => row.id === deleted[0]);
      setRequestIdToRemove(row.id);
    } else if (added) {
      fetchAgain();
      setSnackbarState([true, "success", t("application_added")]);
    } else if (changed) {
      fetchAgain();
      setSnackbarState([true, "success", t("application_edited")]);
    }
  };

  useEffect(() => {
    if (timeWorkersSuperiorObject) {
      let timeWorkersIds: number[] = [];

      for (let key in timeWorkersSuperiorObject) {
        let tempValue = parseInt(key);
        if (!isNaN(tempValue)) {
          timeWorkersIds.push(tempValue);
        }
      }

      setSelectedWorkers(timeWorkersIds);
    }
  }, [timeWorkersSuperiorObject]);

  return (
    <>
      <TopBanner pathName={t("holiday_plans")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            darkTheme={true}
          ></DatePickerRange>
        </div>

        {!requestsPermissionsFetchingState.isFetching &&
          !fetchingState.isFetching &&
          !fetchingState.isError && (
            <TableAndActions
              rows={dataModified}
              columns={columns}
              ifAdd={requestsPermissions.canAdd}
              ifEdit={requestsPermissions.canEdit}
              ifDelete={requestsPermissions.canDelete}
              deleteCommandName={t("delete")}
              actionColumn={ActionColumn}
              popup={AddEditHolidayPlanRequestPopup}
              openPopup={history.location.state ? true : false}
              parallerPatchPath={"superior-time/absence-plans-requests"}
              settingsLayoutName={"layoutSuperiorTimeListsHolidaysRequests"}
              fetchObject={{
                fetchAgain,
                isError: fetchingState.isError,
                isFetching:
                  fetchingState.isFetching ||
                  deleteRequestFetchingState.isFetching ||
                  requestsPermissionsFetchingState.isFetching,
                response: fetchingState.response,
                setIsFetching,
              }}
              commitChanges={commitChanges}
            />
          )}

        <AnnualHolidaysPlan
          year={startDate.getFullYear()}
          selectedWorkers={selectedWorkers}
        />
      </div>
      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </>
  );
}

export default HolidaysRequestsContent;
