import { useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import handleFetchErrors from "../../../HelpersFunctions/handleFetchErrors";
import useFetch from "../useFetch/useFetch";

interface Props {
  path: string;
  successCallback?: (response: UseFetchResponse) => any;
  errorCallback?: (response: UseFetchResponse) => any;
  body?: any;
  setBody?: any;
  method: "POST" | "PUT" | "DELETE" | "PATCH";
  // startFetchOnInitial?: boolean;
  ifBlob?: boolean;
  contentType?: string;
  getErrorMessage?: React.Dispatch<string>;
  disableErrorSnackbar?: boolean;
  disableSuccessSnackbar?: boolean;
  forceSnackbar?: boolean;
}

export default function useFetchOtherThanGET({
  path,
  successCallback,
  errorCallback,
  body,
  method,
  // startFetchOnInitial,
  setBody,
  ifBlob,
  contentType,
  getErrorMessage,
  disableErrorSnackbar,
  disableSuccessSnackbar,
  forceSnackbar,
}: Props): [
  {
    isFetching: boolean;
    isError: boolean;
    response: UseFetchResponse | undefined;
  },
  () => void,
  () => void
] {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  let [setIsFetching, fetchingState, fetchAgain, clearData] = useFetch({
    path: path,
    method: method,
    startFetchOnInitial: false,
    body,
    ifBlob,
    contentType,
  });

  const showedSuccessSnackbar = useRef(false);

  useEffect(() => {
    if (fetchingState.response === undefined) return;
    if (fetchingState.isError) {
      let errorMessage = handleFetchErrors({
        fetchingStateResponse: fetchingState.response,
        enqueueSnackbar,
        t,
        disableErrorSnackbar,
      });
      if (errorCallback) {
        let feedback = fetchingState.response;
        errorCallback(feedback);
      }
      if (getErrorMessage) {
        getErrorMessage(errorMessage);
      }
      setIsFetching(false);
      if (setBody) setBody(false);
      return;
    }

    if (
      !disableSuccessSnackbar &&
      (!showedSuccessSnackbar.current || forceSnackbar === true)
    ) {
      enqueueSnackbar(t("success_action"), { variant: "success" });
      showedSuccessSnackbar.current = true;
    }
    if (setBody) setBody(false);
    setIsFetching(false);

    if (successCallback) {
      let feedback = fetchingState.response;
      successCallback(feedback);
    }
  }, [
    fetchingState.isError,
    fetchingState.response,
    setIsFetching,
    t,
    successCallback,
    setBody,
    enqueueSnackbar,
    getErrorMessage,
    errorCallback,
    disableErrorSnackbar,
    disableSuccessSnackbar,
    forceSnackbar,
  ]);

  return [fetchingState, fetchAgain, clearData];
}
