import DropdownContent from "./DropdownContent";

const Dropdown = ({ submenus, dropdown, depthLevel }) => {
  return (
    <DropdownContent
      submenus={submenus}
      dropdown={dropdown}
      depthLevel={depthLevel + 1}
    />
  );
};

export default Dropdown;
