import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import SelectMultiMenu from "../../../helpersComponents/SelectMultiMenu";

interface Props {
  acWorkersIds: number[];
  setACWorkersIds: Dispatch<SetStateAction<number[]>>;
  successCallback: () => void;
  setPopupSetPinParams: Dispatch<SetStateAction<SetPinPopup>>;
}

const WorkersSelectMenu: React.FC<Props> = ({
  acWorkersIds,
  setACWorkersIds,
  successCallback,
  setPopupSetPinParams,
}) => {
  const { t } = useTranslation();
  const [paramsRequest, setParamsRequest] = useState<ParamsRequest>({
    path: "superior-ac/ac-workers-restore",
    method: "PATCH",
    body: null,
  });

  const [, workerRemoveOrRestoreFetchAgain] = useFetchOtherThanGET({
    path: paramsRequest.path,
    method: paramsRequest.method,
    body: paramsRequest.body
      ? JSON.stringify({
          acWorkersIds: paramsRequest.body,
        })
      : null,
    setBody: setParamsRequest,
    contentType: "application/json",
    successCallback: successCallback,
  });

  useEffect(() => {
    if (paramsRequest.body) {
      workerRemoveOrRestoreFetchAgain();
      setACWorkersIds([]);
    }
  }, [paramsRequest.body, workerRemoveOrRestoreFetchAgain, setACWorkersIds]);

  const { confirm } = useConfirm();
  const handleRemoveOrRestore = async (isActive: boolean) => {
    if (isActive) {
      let confirmResponse = await confirm({
        text: `${t("whether_remove_card_for_reuse")}?`,
        buttons: [
          { buttonName: t("yes"), buttonId: 0 },
          { buttonName: t("no"), buttonId: 1 },
        ],
      });

      setParamsRequest({
        path: `superior-ac/ac-workers${
          confirmResponse === 0 ? "?removeCard=true" : ""
        }`,
        method: "DELETE",
        body: acWorkersIds,
      });
    } else {
      setParamsRequest({
        path: "superior-ac/ac-workers-restore",
        method: "PATCH",
        body: acWorkersIds,
      });
    }
  };

  return (
    <SelectMultiMenu
      name={<FontAwesomeIcon icon={faEllipsisH} color="white" />}
      disabled={acWorkersIds.length === 0}
      profile="superiorAC"
      items={[
        {
          optionName: t("restore"),
          onClick: () => handleRemoveOrRestore(false),
        },
        {
          optionName: t("remove"),
          onClick: () => handleRemoveOrRestore(true),
        },
        {
          optionName: `${t("lock_employee")} (${t("mark_as_contaminated")})`,
          onClick: () => {
            setParamsRequest({
              path: "superior-ac/ac-workers-update-contamination?isContamination=true",
              method: "PATCH",
              body: acWorkersIds,
            });
          },
        },
        {
          optionName: `${t("unlock_employee")} (${t(
            "mark_as_uncontaminated"
          )})`,
          onClick: () => {
            setParamsRequest({
              path: "superior-ac/ac-workers-update-contamination?isContamination=false",
              method: "PATCH",
              body: acWorkersIds,
            });
          },
        },
        {
          optionName: t("lock_pin"),
          onClick: () => {
            setParamsRequest({
              path: "superior-ac/ac-workers-update-pin-locked?isPinLocked=true",
              method: "PATCH",
              body: acWorkersIds,
            });
          },
        },
        {
          optionName: t("unlock_pin"),
          onClick: () => {
            setParamsRequest({
              path: "superior-ac/ac-workers-update-pin-locked?isPinLocked=false",
              method: "PATCH",
              body: acWorkersIds,
            });
          },
        },
        {
          optionName: t("remove_card_for_reuse"),
          onClick: () => {
            setParamsRequest({
              path: "superior-ac/ac-workers-remove-card",
              method: "PATCH",
              body: acWorkersIds,
            });
          },
        },
        {
          optionName: t("set_pin"),
          onClick: () => {
            setPopupSetPinParams({
              isOpen: true,
              workersIds: acWorkersIds,
            });
          },
        },
      ]}
    />
  );
};

export default WorkersSelectMenu;
