import { useTranslation } from "react-i18next";
import TextFieldStyled from "../../../../MaterialUi/TextField/textField";
import styles from "./styles.module.scss";

const ElasticFormTextBox: React.FC<TextBoxHTML> = ({
  name,
  label,
  isRequired,
  readonly,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.inputContainer}>
        <div className={styles.inputTitle}>{label}:</div>
        <div className={styles.inputField}>
          <TextFieldStyled
            label={label}
            width={"100%"}
            value={formValues[name]}
            required={isRequired}
            ifToUpperCaseShipsAndPorts={false}
            isError={!readonly && formErrors[name]}
            disabled={readonly}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormValues((prev) => {
                let objToRet = { ...prev };
                objToRet[name] = e.target.value;
                return objToRet;
              });
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (isRequired && e.target.value === "") {
                setFormErrors((prev) => {
                  let objToRet = { ...prev };
                  objToRet[name] = t("field_required");
                  return objToRet;
                });
              } else {
                setFormErrors((prev) => {
                  let objToRet = { ...prev };
                  objToRet[name] = "";
                  return objToRet;
                });
              }
            }}
          />

          {!readonly && isRequired && formErrors[name] && (
            <div className={styles.errorMessage}>{formErrors[name]}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default ElasticFormTextBox;
