import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import { CheckboxStyled } from "../../../helpersComponents/MaterialUi";
import { FormControlLabel } from "@material-ui/core";
import styles from "./absenceOfCoworkers.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import AbsenceOfCoworkersComponent from "../../../helpersComponents/AbsenceOfCoworkers";

function AbsenceOfCoworkers() {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Month")
  );
  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Month"));
  const [onlyRequests, setOnlyRequests] = useState<boolean>(false);

  return (
    <div>
      <TopBanner pathName={t("absences_of_coworkers")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            darkTheme={true}
            confirmButtonRight={
              <div className={styles.datepickerOptions}>
                <div className={styles.changeDateArrows}>
                  <div
                    onClick={() => {
                      let startDateLocal = new Date(startDate);
                      startDateLocal = getBeginningOfDate(
                        startDateLocal,
                        "Month"
                      );
                      startDateLocal.setMonth(startDateLocal.getMonth() - 1);
                      setStartDate(startDateLocal);

                      let endDateLocal = new Date(startDateLocal);
                      endDateLocal = getEndOfDate(endDateLocal, "Month");
                      setEndDate(endDateLocal);
                    }}
                  >
                    <FontAwesomeIcon
                      className={styles.faAngleArrow}
                      icon={faAngleLeft}
                    />
                  </div>
                  <div
                    onClick={() => {
                      let startDateLocal = new Date(startDate);
                      startDateLocal = getBeginningOfDate(
                        startDateLocal,
                        "Month"
                      );
                      startDateLocal.setMonth(startDateLocal.getMonth() + 1);
                      setStartDate(startDateLocal);

                      let endDateLocal = new Date(startDateLocal);
                      endDateLocal = getEndOfDate(endDateLocal, "Month");
                      setEndDate(endDateLocal);
                    }}
                  >
                    <FontAwesomeIcon
                      className={styles.faAngleArrow}
                      icon={faAngleRight}
                    />
                  </div>
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <CheckboxStyled
                        checked={onlyRequests}
                        name="active"
                        onChange={() => {
                          setOnlyRequests(!onlyRequests);
                        }}
                      />
                    }
                    label={
                      <span className={styles.checkboxLabel}>
                        {t("only_requests")}
                      </span>
                    }
                  />
                </div>
              </div>
            }
            disabledSecond={true}
          ></DatePickerRange>
        </div>
        <div>
          {(startDate || endDate || onlyRequests) && (
            <AbsenceOfCoworkersComponent
              dateFrom={startDate}
              dateTo={endDate}
              onlyRequests={onlyRequests}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AbsenceOfCoworkers;
