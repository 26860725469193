import { useTranslation } from "react-i18next";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../../../helpersComponents/RowDetailsHelpers";

const RowDetailsObjectType = ({ row }) => {
  const { t } = useTranslation();
  return (
    <div>
      {/*<table>
        <th>
          <td>{t("name")}</td>
          <td>{t("value")}</td>
          <td>{t("created")}</td>
          <td>{t("date_created")}</td>
          <td>{t("edited")}</td>
          <td>{t("data_edited")}</td>
        </th>

        {row.states.map((state, index) => (
          <tr>
            <td>{state.name}</td>
            <td>{state.value}</td>
            <td>{state.createdBy}</td>
            <td>{state.createdAt}</td>
            <td>{state?.editedBy ? state?.editedBy : "-"}</td>
            <td>{state?.editedAt ? state?.editedAt : "-"}</td>
          </tr>
        ))}
        </table>*/}

      {row.states.map((state, index) => {
        return (
          <div
            style={{
              marginTop: "20px",
              backgroundColor: index % 2 === 0 ? "#e5e4e2" : "#FFFFFF",
            }}
          >
            <RowDetailsContainer>
              <RowDetailsRow>
                <RowDetailsTitleValue>
                  <RowDetailsTitle>{t("name")}:</RowDetailsTitle>
                  <RowDetailsValue>{state.name}</RowDetailsValue>
                </RowDetailsTitleValue>

                <RowDetailsTitleValue>
                  <RowDetailsTitle>{t("value")}:</RowDetailsTitle>
                  <RowDetailsValue>{state.value}</RowDetailsValue>
                </RowDetailsTitleValue>

                <RowDetailsTitleValue>
                  <RowDetailsTitle>{t("created")}:</RowDetailsTitle>
                  <RowDetailsValue>{state.createdBy}</RowDetailsValue>
                </RowDetailsTitleValue>

                <RowDetailsTitleValue>
                  <RowDetailsTitle>{t("date_created")}:</RowDetailsTitle>
                  <RowDetailsValue>{state.createdAt}</RowDetailsValue>
                </RowDetailsTitleValue>

                <RowDetailsTitleValue>
                  <RowDetailsTitle>{t("edited")}:</RowDetailsTitle>
                  <RowDetailsValue>
                    {state.editedBy ? state.editedBy : "-"}
                  </RowDetailsValue>
                </RowDetailsTitleValue>

                <RowDetailsTitleValue>
                  <RowDetailsTitle>{t("data_edited")}:</RowDetailsTitle>
                  <RowDetailsValue>
                    {state.editedAt ? state.editedAt : "-"}
                  </RowDetailsValue>
                </RowDetailsTitleValue>
              </RowDetailsRow>
            </RowDetailsContainer>
          </div>
        );
      })}
    </div>
  );
};

export default RowDetailsObjectType;
