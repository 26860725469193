import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../helpersComponents/PopupHelpers";
import PopupDialogContent from "./PopupDialogContent";
import { InspectionCompaniesFormValues } from "./type";

interface IProps {
  onCancelChanges: () => void;
  open: boolean;
  successCallback: () => void;
  pathApi: string;
  guestsPathApi: string;
  inspectionCompaniesPathApi: string;
  permissionZonesPathApi: string;
}

const PopupInspectionServices: React.FC<IProps> = ({
  onCancelChanges,
  open,
  successCallback,
  pathApi,
  guestsPathApi,
  inspectionCompaniesPathApi,
  permissionZonesPathApi,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (values: InspectionCompaniesFormValues) => {
    setErrorMessage("");
    let guests = values.guestsArray.map((guestForm) => {
      return {
        guestId: guestForm.guest?.id,
        cardNumber: guestForm.cardNumber,
        idNumber: guestForm.idNumber,
        guestTrainingExpiryDate: guestForm.trainingExpiryDate
          ? guestForm.trainingExpiryDate
          : null,
      };
    });

    let body = {
      inspectionCompanyName: values.inspectionCompany?.name,
      permissionZoneId: values.permissionZone?.id,
      comments: values.comments,
      guests,
    };

    setBodyRequest(JSON.stringify(body));
  };

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: pathApi,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallback,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  return (
    <>
      <DialogStyled
        open={open}
        onClose={onCancelChanges}
        disableBackdropClick={true}
      >
        <DialogTitleStyled
          title={t("adding_notification_inspection_companies")}
        />
        <PopupDialogContent
          onSubmit={onSubmit}
          guestsPathApi={guestsPathApi}
          inspectionCompaniesPathApi={inspectionCompaniesPathApi}
          isPopupOpen={open}
          permissionZonesPathApi={permissionZonesPathApi}
        />
        <DialogActionsStyled
          typConfirm="submit"
          formConfirm="submitForm"
          onCancel={onCancelChanges}
          isLoading={fetchingStatePostData.isFetching}
          errorString={errorMessage}
        />
      </DialogStyled>
    </>
  );
};

export default PopupInspectionServices;
