import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import styles from "./styles.module.scss";

const HomePageGuard = () => {
  const { t } = useTranslation();

  return (
    <div>
      <TopBanner pathName={t("home_page")} />
      <div className="flexAndCenter">
        <div className={styles.contentContainer}></div>
      </div>
    </div>
  );
};

export default HomePageGuard;
