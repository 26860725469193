import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogContent } from "@mui/material";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import AutocompleteFormik from "../../../helpersComponents/FormikInputs/Autocomplete/autocomplete";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import styles from "./styles.module.scss";
import DialogActionsStyled from "../../../helpersComponents/PopupHelpers/dialogActionsStyled";
import LoadingWrapper from "./../../../helpersComponents/LoadingWrapper/loadingWrapper";

interface Props {
  isOpen: boolean;
  closePopup: () => void;
  rowId?: number;
  successCallback: () => void;
}

const PopupAddLockersToEmployee = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
}: Props) => {
  const { t } = useTranslation();
  const [requestBody, setRequestBody] = useState<any>(false);

  const successCallbackAndClose = useCallback(() => {
    closePopup();
    if (successCallback) {
      successCallback();
    }
  }, [closePopup, successCallback]);

  const [acWorkers, fetchingStateACWorkers, fetchAgainACWorkers] =
    useFetchAndSetGET<IHolidayRequestsPermissions>({
      path: `lockers-management/ac-workers`,
      startFetchOnInitial: false,
    });

  const [
    lockersModules,
    fetchingStateLockersModules,
    fetchAgainLockersModules,
  ] = useFetchAndSetGET<ILockerModule>({
    path: `lockers-management/lockers-modules`,
    startFetchOnInitial: false,
  });

  const [freeLockers, setFreeLockers] = useState<IFreeLocker[]>([]);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `lockers-management/lockers-assignment-to-employees`,
    method: "POST",
    body: requestBody,
    contentType: "application/json",
    setBody: setRequestBody,
    successCallback: successCallbackAndClose,
    forceSnackbar: true,
  });

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, fetchAgainPostData]);

  useEffect(() => {
    if (isOpen) {
      fetchAgainACWorkers();
      fetchAgainLockersModules();
    }
  }, [isOpen, fetchAgainACWorkers, fetchAgainLockersModules]);

  return (
    <>
      <Dialog maxWidth={"xl"} open={isOpen} onClose={() => closePopup()}>
        <DialogTitle className="dialogTitleGlobal">
          <div className="titlePopup">{t("adding_lockers_to_employee")}</div>
        </DialogTitle>

        <LoadingWrapper
          isLodadingProgress={
            fetchingStateACWorkers.isFetching ||
            fetchingStateLockersModules.isFetching
          }
          isError={
            fetchingStateACWorkers.isError ||
            fetchingStateLockersModules.isError
          }
          setIfFetchAgain={() => {
            if (fetchingStateACWorkers.isError) {
              fetchAgainACWorkers();
            }

            if (fetchingStateLockersModules.isError) {
              fetchAgainLockersModules();
            }
          }}
        >
          <DialogContent>
            <Formik
              initialValues={{
                acWorker: null,
                lockersModule: null,
                locker: null,
              }}
              onSubmit={(values: any) => {
                let requestObj = {
                  lockersModuleId: values.lockersModule.id,
                  acWorkerId: values.acWorker.id,
                  lockersIds: [values.locker.relayNumber],
                };

                setRequestBody(JSON.stringify(requestObj));
              }}
              validationSchema={Yup.object({
                acWorker: Yup.object()
                  .nullable(false)
                  .typeError(t("field_required")),
                lockersModule: Yup.object()
                  .nullable(false)
                  .typeError(t("field_required")),
                locker: Yup.object()
                  .nullable(false)
                  .typeError(t("field_required")),
              })}
            >
              {({ values, setFieldValue, errors }) => {
                return (
                  <Form noValidate id="submitForm">
                    <div>
                      <div className={styles.lockersAssignmentContainer}>
                        <div className={styles.inputRow}>
                          <div className={styles.titleWidthLockersAssignment}>
                            {t("select_employee")}:
                          </div>
                          <div className={styles.inputWidthLockersAssignment}>
                            <AutocompleteFormik
                              options={acWorkers}
                              getOptionLabel={(option: any) => {
                                return `${option.firstName} ${option.lastName} [${option.evidenceNumber}]`;
                              }}
                              width="100%"
                              style={{ width: "300px", marginRight: "10px" }}
                              getOptionSelected={(option: any, value: any) => {
                                return option.id === value.id;
                              }}
                              label={t("select_employee")}
                              name={`acWorker`}
                              required={true}
                              filterSelectedOptions
                            />
                          </div>
                        </div>

                        <div className={styles.inputRow}>
                          <div className={styles.titleWidthLockersAssignment}>
                            {t("select_lockers_module")}:
                          </div>
                          <div className={styles.inputWidthLockersAssignment}>
                            <AutocompleteFormik
                              options={lockersModules}
                              getOptionLabel={(option: any) => {
                                return option.name;
                              }}
                              width="100%"
                              style={{ width: "300px", marginRight: "10px" }}
                              getOptionSelected={(option: any, value: any) => {
                                return option.id === value.id;
                              }}
                              label={t("select_lockers_module")}
                              name={`lockersModule`}
                              required={true}
                              onChangeExtra={(_, value) => {
                                if (value) {
                                  setFreeLockers(value.freeLockers);
                                } else {
                                  setFreeLockers([]);
                                }
                                setFieldValue("locker", null);
                              }}
                            />
                          </div>
                        </div>

                        <div className={styles.inputRow}>
                          <div className={styles.titleWidthLockersAssignment}>
                            {t("select_locker")}:
                          </div>
                          <div className={styles.inputWidthLockersAssignment}>
                            <AutocompleteFormik
                              options={freeLockers}
                              getOptionLabel={(option: any) => {
                                return option.name;
                              }}
                              width="100%"
                              style={{ width: "300px", marginRight: "10px" }}
                              getOptionSelected={(option: any, value: any) => {
                                return option.id === value.id;
                              }}
                              label={t("select_locker")}
                              name={`locker`}
                              required={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        </LoadingWrapper>

        <DialogActionsStyled
          typConfirm="submit"
          formConfirm="submitForm"
          onCancel={() => {
            closePopup();
          }}
          isLoading={fetchingStatePostData.isFetching}
        ></DialogActionsStyled>
      </Dialog>
    </>
  );
};

export default PopupAddLockersToEmployee;
