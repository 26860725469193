import { useTranslation } from "react-i18next";
import ButtonStyled from "../../../../helpersComponents/Button/button";
import styles from "./AssigningReadersToOrganizationalUnits.module.scss";
import AssigningReadersToOrganizationalUnitsItem from "./AssigningReadersToOrganizationalUnitsItem";

const AssigningReadersToOrganizationalUnits: React.FC<any> = ({
  fields,
  append,
  remove,
  control,
  getValues,
  setValue,
  watch,
  organizationalUnits,
  readers,
}) => {
  const { t } = useTranslation();

  const addNewSettingsItem = () => {
    append({
      organizationalUnit: null,
      entryReader: null,
      exitReader: null,
    });
  };

  return (
    <div className={styles.optionsContainer}>
      <div>
        <ButtonStyled
          onClick={addNewSettingsItem}
          className={styles.addButton}
          type="button"
        >
          {t("add_option")}
        </ButtonStyled>
      </div>

      <div className={styles.optionsContent}>
        {fields.length === 0 && (
          <span className={styles.noOptionsDefinedLabel}>
            {t("no_options_defined")}
          </span>
        )}
        {fields.map((field, index) => (
          <AssigningReadersToOrganizationalUnitsItem
            id={field.id}
            key={field.id}
            fields={fields}
            index={index}
            control={control}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
            remove={remove}
            organizationalUnits={organizationalUnits}
            readers={readers}
          />
        ))}
      </div>
    </div>
  );
};

export default AssigningReadersToOrganizationalUnits;
