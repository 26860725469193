import "./events.scss";
import React, { useState, useEffect } from "react";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import Popup from "./popup";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import useFetchDelete from "../../../../hooks/fetchHooks/delete/useFetchDelete";
import RowDetail from "./rowDetail";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./according.module.scss";
import { useSnackbar } from "notistack";

let Cell: any = TableEditColumn.Cell;

const ActionColumn: React.FC = ({
  children,
  row,
  classes,
  ...restProps
}: any) => {
  return (
    <Cell row={restProps.row} className="tableCellEditCancelStyleGlobal">
      {children}
    </Cell>
  );
};

interface Props {
  startDateFetch?: Date;
  endDateFetch?: Date;
  timeWorkersIdsString?: string;
  settingsLayoutName?: string;
}

function EventsAccordion({
  startDateFetch,
  endDateFetch,
  timeWorkersIdsString,
  settingsLayoutName,
}: Props) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [fetchAgain, setFetchAgain] = useState(true);
  const [dataModified, setDataModified] = useState<any[] | null>(null);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState<boolean>(false);
  const { data, error } = useFetchGet(
    "superior-time/events?DateFrom=" +
      computeDateRangeFetch(startDateFetch ? startDateFetch : new Date()) +
      "&DateTo=" +
      computeDateRangeFetch(endDateFetch ? endDateFetch : new Date()) +
      (timeWorkersIdsString ? `&timeWorkersIds=${timeWorkersIdsString}` : ""),
    fetchAgain
  );

  const { enqueueSnackbar } = useSnackbar();
  const [idToDelete, setIdToDelete] = useState();
  const [ifFetchDelete, setIfFetchDelete] = useState<boolean>(false);
  const responseStatusDelete = useFetchDelete(
    "superior-time/events/" + idToDelete,
    ifFetchDelete
  );
  const settings = useAppSelector(selectSettings);

  const columns = [
    { name: "timeString", title: t("date_and_time") },
    { name: "roomName", title: t("room") },
    { name: "workerName", title: t("worker") },
    { name: "typeName", title: t("the_type_of_the_event") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "organizationalUnits", title: t("organizational_units") },
  ];

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setFetchAgain(false);
      return;
    }
    if (data === null) return;

    data.forEach((element, index) => {
      data[index].workerName = element.firstName + " " + element.lastName;
      data[index].timeString = new Date(element.time).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
      );
    });

    setDataModified(data);
    setIsFetching(false);
    setFetchAgain(false);
  }, [data, error, t]);

  const commitChanges = ({ added, changed, deleted }) => {
    if (deleted) {
      let row: any = dataModified?.find((row) => row.id === deleted[0]);
      setIfFetchDelete(true);
      setIdToDelete(row.id);
    } else if (added) {
      setFetchAgain(true);
      enqueueSnackbar(t("application_added"), {
        variant: "success",
      });
    } else if (changed) {
      setFetchAgain(true);
      enqueueSnackbar(t("application_edited"), {
        variant: "success",
      });
    }

    setIsFetching(true); // circ
  };

  useEffect(() => {
    if (responseStatusDelete.data === 204) {
      enqueueSnackbar(t("event_deleted"), {
        variant: "success",
      });
      setFetchAgain(true);
      setIsFetching(true); // circ
    } else if (responseStatusDelete.data?.status === 400) {
      let error = "";
      Object.keys(responseStatusDelete.data.errors).forEach((errorObject) => {
        responseStatusDelete.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setIsFetching(false); // circ
      enqueueSnackbar(error, {
        variant: "error",
      });
    } else if (responseStatusDelete.error !== null) {
      setIsFetching(false); // circ
      enqueueSnackbar(t("something_went_wrong"), {
        variant: "error",
      });
    }
    setIfFetchDelete(false);
  }, [responseStatusDelete, enqueueSnackbar, t]);

  return (
    <>
      <div>
        <LoadingWrapper
          isLodadingProgress={isFetching}
          isError={isError}
          setIfFetchAgain={setFetchAgain}
          setIsError={setIsError}
        >
          {!isFetching &&
            !isError &&
            dataModified !== null &&
            dataModified?.length > 0 && (
              <Accordion
                expanded={isExpanded}
                onChange={() => setIsExpanded(!isExpanded)}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <div className={styles.titleAccordion}>
                    {t("events")}
                    <div
                      className={
                        styles.greenBorderColor +
                        " " +
                        styles.circleAccordionTitleNumber
                      }
                    >
                      {dataModified.length}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.width100}>
                    {isExpanded && (
                      <TableComponent
                        layoutSettingName={
                          settingsLayoutName
                            ? settingsLayoutName
                            : "layoutSuperiorTimeListsEvents"
                        }
                        rows={dataModified}
                        columns={columns}
                        defaultSorting={[
                          { columnName: "timeString", direction: "asc" },
                        ]}
                        popup={Popup}
                        ifEdit={
                          settings.WebEdycjaZdarzenPrzezPrzelozonego?.value ===
                          "1"
                        }
                        ifAdd={
                          settings.WebDodawanieZdarzenPrzezPrzelozonego
                            ?.value === "1"
                        }
                        ifDelete={
                          settings.WebUsuwanieZdarzenPrzezPrzelozonego
                            ?.value === "1"
                        }
                        deleteCommandName={t("delete")}
                        commitChanges={commitChanges}
                        actionColumn={ActionColumn}
                        rowDetail={RowDetail}
                        backgroundColor={"White"}
                      />
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
        </LoadingWrapper>
      </div>
    </>
  );
}

export default EventsAccordion;
