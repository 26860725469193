import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ProfileType } from "../../../enums/profileType";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import convertMinsToHrsMins from "../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";

interface IProps {
  pathApiOperationalSupportExitReport: string;
  layoutSettingName: string;
  profileType: ProfileType;
}

const ExitReportContent: React.FC<IProps> = ({
  pathApiOperationalSupportExitReport,
  layoutSettingName,
  profileType,
}) => {
  const { t } = useTranslation();

  const columns = [
    { name: "lp", title: t("lp") },
    { name: "firstName", title: t("first_name") },
    { name: "lastName", title: t("last_name") },
    { name: "exitTime", title: t("exit_time"), type: "date_hours" },
    { name: "timeDuration", title: t("absence_time") },
  ];

  const eventsResponseCallback = useCallback((responseData) => {
    const modified = responseData?.map((row, index) => {
      return {
        ...row,
        lp: index + 1,
        timeDuration: convertMinsToHrsMins(row.timeDurationInMinutes),
      };
    });
    return modified;
  }, []);

  const [
    operationalSupportLastZoneEvent,
    fetchingStateOperationalSupportLastZoneEvent,
    ,
  ] = useFetchAndSetGET({
    path: pathApiOperationalSupportExitReport,
    startFetchOnInitial: true,
    modifyResponseCallback: eventsResponseCallback,
  });

  return (
    <div>
      <div>
        <TopBanner pathName={t("exit_report")} />
      </div>
      <div className="flexAndCenter">
        <div>
          {!fetchingStateOperationalSupportLastZoneEvent.isFetching && (
            <TableComponentMemo
              columns={columns}
              rows={operationalSupportLastZoneEvent || []}
              layoutSettingName={layoutSettingName}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ExitReportContent;
