import React from "react";
import ContentRequestsToDownload from "./contentRequestsToDownload";

function RequestsToDownload() {
  return (
    <div>
      <ContentRequestsToDownload />
    </div>
  );
}

export default RequestsToDownload;
