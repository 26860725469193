import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import { MapContainer, TileLayer } from "react-leaflet";

const BuildingsMapSMSUser = () => {
  const { t } = useTranslation();
  const position: any = [51.505, -0.09];

  return (
    <div>
      <TopBanner pathName={t("buildings_map")} />
      <div style={{ width: "100%", height: "80vh" }}>
        <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        </MapContainer>
      </div>
    </div>
  );
};

export default BuildingsMapSMSUser;
