import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import styles from "./mapArrangment.module.scss";
import MapArrangmentToolbar from "./Toolbar/MapArrangmentToolbar";
import MapArrangmentMap from "./Map/MapArrangmentMap";
import useImageLoader from "../../../hooks/fetchHooks/useImageLoader/useImageLoader";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import MapArrangmentObjectsExplorer from "./ObjectsExplorer/MapArrangmentObjectsExplorer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const MapsArrangmentSMSAdministrator = () => {
  const { t } = useTranslation();
  const [imagesToDownloadUrls, setImagesToDownloadUrls] = useState<string[]>(
    []
  );

  const [selectedBuildingGroup, setSelectedBuildingGroup] =
    useState<ISMSBuildingsGroup | null>(null);
  const [selectedBuilding, setSelectedBuilding] = useState<ISMSBuilding | null>(
    null
  );
  const [selectedFloor, setSelectedFloor] = useState<ISMSBuildingFloor | null>(
    null
  );

  const { imagesData, clearImagesData } = useImageLoader(imagesToDownloadUrls);

  useEffect(() => {
    if (selectedFloor) {
      setImagesToDownloadUrls([
        `sms-administrator/download-map/${selectedFloor?.mapId}`,
      ]);
    } else {
      setImagesToDownloadUrls([]);
      clearImagesData();
    }
  }, [selectedFloor, clearImagesData]);

  const [
    buildingsGroups,
    fetchingStateBuildingsGroups,
    fetchAgainBuildingsGroups,
  ] = useFetchAndSetGET<ISMSBuildingsGroup[]>({
    path: `sms-administrator/buildings-groups`,
    startFetchOnInitial: true,
  });

  const [buildings, fetchingStateBuildings, fetchAgainBuildings] =
    useFetchAndSetGET<ISMSBuilding[]>({
      path: `sms-administrator/buildings`,
      startFetchOnInitial: true,
    });

  useEffect(() => {
    console.log(`imagesData: ${JSON.stringify(imagesData)}`);
  }, [imagesData]);

  const handleSystemObjectDrop = (item: ISMSMapSystemObjectDropItem) => {
    console.log(`Upuszczono obiekt: ${JSON.stringify(item)}`);
  };

  return (
    <div>
      <TopBanner pathName={t("objects_types")} />
      <div className="flexAndCenter">
        <LoadingWrapper
          isLodadingProgress={
            fetchingStateBuildingsGroups.isFetching ||
            fetchingStateBuildings.isFetching
          }
          isError={
            fetchingStateBuildingsGroups.isError ||
            fetchingStateBuildings.isError
          }
          setIfFetchAgain={() => {
            fetchAgainBuildingsGroups();
            fetchAgainBuildings();
          }}
          bigSize={true}
        >
          <DndProvider backend={HTML5Backend}>
            <div className={styles.mainContainer}>
              <MapArrangmentToolbar
                buildingsGroups={buildingsGroups}
                buildings={buildings}
                selectedBuildingGroup={selectedBuildingGroup}
                setSelectedBuildingGroup={setSelectedBuildingGroup}
                selectedBuilding={selectedBuilding}
                setSelectedBuilding={setSelectedBuilding}
                selectedFloor={selectedFloor}
                setSelectedFloor={setSelectedFloor}
              />

              <div className={styles.mainContainerContent}>
                <div className={styles["main-content-column"]}>
                  {imagesData && imagesData.length > 0 && (
                    <MapArrangmentMap
                      map={imagesData[0]}
                      onSystemObjectDrop={handleSystemObjectDrop}
                    />
                  )}
                </div>
                <div className={styles["menu-content-column"]}>
                  <MapArrangmentObjectsExplorer />
                </div>
              </div>
            </div>
          </DndProvider>
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default MapsArrangmentSMSAdministrator;
