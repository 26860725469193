import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../hooks/fetchHooks/useFetch/useFetch";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import Button from "../../helpersComponents/Button/button";
import { dayOfWeekTranslationKey } from "../../../Constants/dayOfWeekTranslationKey";
import RowDetails from "./rowDetails";
import { useSnackbar } from "notistack";
import erro400getTranslatedErrorString from "../../../HelpersFunctions/erro400getTranslatedErrorString";

export default function PermittedRoomsContent(): ReactElement {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  ////////// open reader

  const [openReaderId, setopenReaderId] = useState<null | number>(null);
  const [method, setMethod] = useState<false | "POST">("POST");
  let [setIsFetchingOpenReader, fetchingStateOpenReader, fetchAgainOpenReader] =
    useFetch({
      method: method,
      path: `worker-ac/open-reader/${openReaderId}`,
      startFetchOnInitial: false,
    });

  useEffect(() => {
    if (openReaderId !== null) {
      fetchAgainOpenReader();
      setMethod("POST");
    }
  }, [openReaderId, fetchAgainOpenReader]);

  useEffect(() => {
    if (fetchingStateOpenReader.response === undefined) return;
    if (fetchingStateOpenReader.isError === true) {
      if (fetchingStateOpenReader.response.status === 400) {
        enqueueSnackbar(
          erro400getTranslatedErrorString(
            fetchingStateOpenReader.response.resJson?.errors,
            t
          ),
          { variant: "error" }
        );
      } else {
        enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
      }
      setopenReaderId(null);
      setMethod(false);
      setIsFetchingOpenReader(false);
      return;
    }
    setopenReaderId(null);
    setMethod(false);
    setIsFetchingOpenReader(false);
    enqueueSnackbar(t("success_action"), { variant: "success" });
  }, [
    fetchingStateOpenReader.response,
    fetchingStateOpenReader.isError,
    setIsFetchingOpenReader,
    t,
    enqueueSnackbar,
  ]);

  /////// fetch rows

  let [setIsFetching, fetchingState, fetchAgain] = useFetch({
    method: "GET",
    path: `worker-ac/permitted-rooms`,
  });

  const columns = [
    // { name: "day", title: t("day"), type: "date_hours" },
    // { name: "entranceFrom", title: t("entrance_from") },
    // { name: "entranceTo", title: t("entrance_to") },
    // { name: "rangeFrom", title: t("range_from"), type: "date_hours" },
    // { name: "rangeTo", title: t("range_to"), type: "date_hours" },
    { name: "room", title: t("room") },
    { name: "readerAction", title: t("action") },
  ];

  const [perrmitedRoomsRows, setperrmitedRoomsRows] = useState<any[]>([]);

  useEffect(() => {
    if (fetchingState.isError || fetchingState.response === undefined) return;
    if (!Array.isArray(fetchingState.response.resJson)) return;
    let data: any[] = fetchingState.response.resJson;

    let perrmitedRoomsRowsLocal: any[] = [];
    data.forEach((row, index) => {
      let indexArray = perrmitedRoomsRowsLocal.findIndex(
        (el) => el.readerId === row.readerId
      );

      if (indexArray !== -1) {
        perrmitedRoomsRowsLocal[indexArray].groupedRows.push({
          day: t(dayOfWeekTranslationKey[row.day - 1]),
          entranceFrom: row.entranceFrom,
          entranceTo: row.entranceTo,
        });
      } else {
        perrmitedRoomsRowsLocal.push({
          id: index,
          groupedRows: [
            {
              day: t(dayOfWeekTranslationKey[row.day - 1]),
              entranceFrom: row.entranceFrom,
              entranceTo: row.entranceTo,
              rangeFrom: new Date(row.rangeFrom).toLocaleString(
                t("scheduler_language"),
                {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              ),
              rangeTo: new Date(row.rangeTo).toLocaleString(
                t("scheduler_language"),
                {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              ),
            },
          ],
          room: row.room,
          readerId: row.readerId,
          readerAction: (
            <div className="goToTheRecordsContainer">
              <Button
                onClick={() => {
                  setopenReaderId(row.readerId);
                }}
              >
                {t("open_2")}
              </Button>
            </div>
          ),
        });
      }
    });

    setperrmitedRoomsRows(perrmitedRoomsRowsLocal);
    setIsFetching(false);
  }, [
    fetchingState.response,
    fetchingState.isError,
    t,
    setIsFetching,
    fetchAgainOpenReader,
  ]);

  return (
    <div>
      <TopBanner pathName={t("permitted_rooms")} />
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isLodadingProgress={fetchingState.isFetching}
            isError={fetchingState.isError}
            setIfFetchAgain={fetchAgain}
          >
            {!fetchingState.isFetching && !fetchingState.isError && (
              <div>
                <TableComponentMemo
                  layoutSettingName="workerAcPermittedRooms"
                  rows={perrmitedRoomsRows}
                  columns={columns}
                  rowDetail={RowDetails}
                  filteringStateColumnExtensions={[
                    { columnName: "readerId", filteringEnabled: false },
                  ]}
                />
              </div>
            )}
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
}
