import React, { createContext, useContext, useState, useEffect } from "react";

const KeyboardStateContext = createContext({
  isCtrlPressed: false,
  isShiftPressed: false,
});

export const KeyboardStateProvider = ({ children }) => {
  const [isCtrlPressed, isSetCtrlPressed] = useState(false);
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Control") {
      isSetCtrlPressed(true);
    } else if (event.key === "Shift") {
      setIsShiftPressed(true);
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "Control") {
      isSetCtrlPressed(false);
    } else if (event.key === "Shift") {
      setIsShiftPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <KeyboardStateContext.Provider
      value={{ isCtrlPressed: isCtrlPressed, isShiftPressed: isShiftPressed }}
    >
      {children}
    </KeyboardStateContext.Provider>
  );
};

export const useKeyboardState = () => {
  return useContext(KeyboardStateContext);
};
