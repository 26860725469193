import ContentSecurityNotifications from "./ContentSecurityNotifications";

function NotificationSecurity() {
  return (
    <div>
      <ContentSecurityNotifications />
    </div>
  );
}

export default NotificationSecurity;
