import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { TextFieldFormik } from "../FormikInputs";
import CheckboxWithLabelFormik from "../FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import GetCardTagPopupRow from "../GetCardTagPopupRow/getCardTagPopupRow";
import { Row, RowInput, RowTitle } from "../PopupHelpers";
import * as Yup from "yup";

interface Props {
  onSubmit: (values: Partial<ReceiveReplacementCard>) => void;
  isOpen: boolean;
  cardNumber: null | string;
}

const PopupContentReceiveReplacementCard: React.FC<Props> = ({
  onSubmit,
  isOpen,
  cardNumber,
}) => {
  const { t } = useTranslation();

  const initialValues: Partial<ReceiveReplacementCard> = {
    receiveCardUniversalNumber: "",
    isLost: false,
    returnedCardDescription: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validationSchema={Yup.object({
        receiveCardUniversalNumber: Yup.string().test(
          "isValidReceiveCardNumber",
          t("field_required"),
          function (value) {
            return !cardNumber
              ? Yup.string().required().isValid(value)
              : Yup.string().isValid(value);
          }
        ),
      })}
    >
      {(formikProps) => {
        return (
          <Form noValidate id="submitForm">
            {!cardNumber && (
              <Row>
                <GetCardTagPopupRow
                  setFieldValue={formikProps.setFieldValue}
                  name="receiveCardUniversalNumber"
                  componentId={`replacementCards/receiveCardUniversalNumber`}
                  isPopupOpen={isOpen}
                  mask={"****************"}
                  widthTitle={150}
                  widthInput={350}
                />
              </Row>
            )}

            <Row>
              <RowTitle width={150}>{t("is_it_lost")}:</RowTitle>
              <RowInput width={350}>
                <CheckboxWithLabelFormik
                  name="isLost"
                  defaultValue={false}
                  label={<span>{t("is_it_lost")}?</span>}
                />
              </RowInput>
            </Row>

            <Row>
              <RowTitle width={150}>{t("returned_card_description")}:</RowTitle>
              <RowInput width={350}>
                <TextFieldFormik
                  ifToUpperCaseShipsAndPorts={true}
                  label={t("returned_card_description")}
                  name="returnedCardDescription"
                  type="text"
                  multiline={true}
                  rows={3}
                />
              </RowInput>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PopupContentReceiveReplacementCard;
