import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FireReportMode } from "../../../enums/fireReportMode";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useRefreshData from "../../../hooks/useRefreshData/useRefreshData";
import useUpdateSettings from "../../../hooks/useUpdateSettings/useUpdateSettings";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import TimeWithDescription from "../../helpersComponents/NumberWithDescription/numberWithDescription";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import GroupCellContent from "./groupCellContent";
import SelectZone from "./SelectFireZone";
import SelectTargetCompany from "./SelectTargetCompany";
import styles from "./styles.module.scss";

interface Props {
  fireReportPath: string;
  layoutSettingName: string;
  navigation?: React.FunctionComponent<{}> | undefined;
  fireZonesPath: string;
  pathPreferences: string;
  onlyGuests?: boolean;
  targetCompaniesPath: string;
  fireReportMode?: FireReportMode;
}

const FireReport = ({
  fireReportPath,
  layoutSettingName,
  navigation,
  fireZonesPath,
  pathPreferences,
  onlyGuests,
  targetCompaniesPath,
  fireReportMode = FireReportMode.FIRE_REPORT,
}: Props) => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const OstatnioWybranaStrefaPrzeciwpozarowaId =
    fireReportMode === FireReportMode.FIRE_REPORT
      ? "RaportPozarowyOstatnioWybranaStrefaPrzeciwpozarowaId"
      : "RaportEwakuacjiOstatnioWybranaStrefaPrzeciwpozarowaId";

  const OstatnioWybranaFirmaDocelowa =
    fireReportMode === FireReportMode.FIRE_REPORT
      ? "RaportPozarowyOstatnioWybranaFirmaDocelowa"
      : "RaportEwakuacjiOstatnioWybranaFirmaDocelowa";

  const OstatnioWybranaStrefaEwakuacjiId = "OstatnioWybranaStrefaEwakuacjiId";

  const columns = [
    { name: "person", title: t("person") },
    {
      name: "organizationalUnitOrCompany",
      title: `${t("company")} / ${t("organizational_units")}`,
    },
    { name: "lastEntryTime", title: t("last_entry"), type: "date_hours" },
    { name: "room", title: t("room") },
    { name: "meetingPlace", title: t("meeting_place") },

    { name: "typeOfPerson", title: t("person") },
  ];
  const [summaryData, setSummaryData] = useState({
    total: 0,
    workers: 0,
    guests: 0,
  });

  const [expandedGroupsDefault, setExpandedGroupsDefault] = useState<string[]>(
    []
  );

  const [selectedFireZone, setSelectedFireZone] = useState<FireZone>({
    id: null,
    name: "",
  });

  const [selectedTargetCompany, setSelectedTargetCompany] =
    useState<TargetCompany>({
      name: "",
    });

  const [selectedEvacuationZone, setSelectedEvacuationZone] =
    useState<FireZone>({
      id: null,
      name: "",
    });

  const [fireZones, fetchingStateFireZones, fetchAgainFireZones] =
    useFetchAndSetGET({
      path: fireZonesPath,
    });

  const [
    targetCompanies,
    fetchingStateTargetCompanies,
    fetchAgainTargetCompanies,
  ] = useFetchAndSetGET({
    path: targetCompaniesPath,
  });

  const [personToDelete, setPersonToDelete] =
    useState<FireReportItemToDelete | null>();

  const modifyResponseCallback = useCallback(
    (responseData) => {
      let workers = 0;
      let guests = 0;
      let expandAll: string[] = [];
      const modified = responseData.map((row, i) => {
        if (row.typeOfPerson === 0) {
          expandAll.push(`${t("worker")}|${row.organizationalUnitOrCompany}`);
          workers++;
        }
        if (row.typeOfPerson === 1) {
          expandAll.push(`${t("guest")}|${row.organizationalUnitOrCompany}`);
          guests++;
        }
        return {
          ...row,
          id: i,
          personId: row.id,
          typeOfPerson: row.typeOfPerson === 0 ? t("worker") : t("guest"),
          typeOfPersonId: row.typeOfPerson,
          person: `${row.firstName} ${row.lastName}`,
        };
      });

      setExpandedGroupsDefault([
        t("guest"),
        t("worker"),
        ...new Set(expandAll),
      ]);
      setSummaryData({
        total: responseData.length,
        workers,
        guests,
      });
      return modified;
    },

    [t]
  );

  const getFireReportPath = useCallback(() => {
    let result = "";

    if (selectedFireZone.id === null || selectedFireZone.id === "") {
      result = fireReportPath;
    } else {
      result = `${fireReportPath}/${selectedFireZone.id}`;
    }

    result += onlyGuests ? "?onlyGuests=true" : "?onlyGuests=false";

    if (selectedTargetCompany && selectedTargetCompany.name) {
      result += `&targetCompanyName=${selectedTargetCompany.name}`;
    }

    if (
      fireReportMode === FireReportMode.EVACUATION_REPORT &&
      selectedEvacuationZone &&
      selectedEvacuationZone.id
    ) {
      result += `&evacuationZoneId=${selectedEvacuationZone.id}`;
    }

    return result;
  }, [
    fireReportPath,
    onlyGuests,
    fireReportMode,
    selectedFireZone.id,
    selectedTargetCompany,
    selectedEvacuationZone,
  ]);

  const [rowsFireReport, fetchingStateFireReport, fetchAgainFireReport] =
    useFetchAndSetGET({
      path: getFireReportPath(),
      modifyResponseCallback: modifyResponseCallback,
      startFetchOnInitial: false,
    });

  const NavigationComponent = navigation;

  const timeRefreshingFireReport = settings["CzasOdswiezaniaRaportuPozarowego"]
    ?.value
    ? settings["CzasOdswiezaniaRaportuPozarowego"].value
    : 60000;

  const { handleChangeRefreshingTime, setRefreshingTime } =
    useRefreshData(fetchAgainFireReport);

  const firstRunNotFetching = useRef(true);
  useEffect(() => {
    if (
      (selectedFireZone.id !== null ||
        selectedTargetCompany ||
        selectedEvacuationZone.id !== null) &&
      !firstRunNotFetching.current
    ) {
      fetchAgainFireReport();
      setRefreshingTime({
        value: timeRefreshingFireReport,
        date: new Date(),
      });
    }
    firstRunNotFetching.current = false;
  }, [
    selectedFireZone,
    selectedTargetCompany,
    selectedEvacuationZone,
    fetchAgainFireReport,
    setRefreshingTime,
    timeRefreshingFireReport,
  ]);

  //--- SAVE SELECTED ZONE ---
  const [ifUpdateSetting, setIfUpdateSetting] = useState<boolean>(false);
  const ifSettingsUpdated = useUpdateSettings(ifUpdateSetting);
  const lastSelectedFireZone = settings[OstatnioWybranaStrefaPrzeciwpozarowaId];
  const lastSelectedTargetCompanyName = settings[OstatnioWybranaFirmaDocelowa];
  const lastSelectedEvacuationZone = settings[OstatnioWybranaStrefaEwakuacjiId];

  useEffect(() => {
    setIfUpdateSetting(true);
  }, []);

  useEffect(() => {
    if (fireZones) {
      if (lastSelectedFireZone !== undefined) {
        const foundSelectedZone = fireZones.find(
          (zone: FireZone) => String(zone.id) === lastSelectedFireZone?.value
        );

        if (foundSelectedZone) {
          setSelectedFireZone(foundSelectedZone);
        } else if (!foundSelectedZone) {
          setSelectedFireZone({ id: "", name: "" });
        }
      }

      if (lastSelectedEvacuationZone !== undefined) {
        const foundSelectedZone = fireZones.find(
          (zone: FireZone) =>
            String(zone.id) === lastSelectedEvacuationZone?.value
        );

        if (foundSelectedZone) {
          setSelectedEvacuationZone(foundSelectedZone);
        } else if (!foundSelectedZone) {
          setSelectedEvacuationZone({ id: "", name: "" });
        }
      }
    }

    if (
      ifSettingsUpdated &&
      (lastSelectedFireZone === undefined ||
        lastSelectedEvacuationZone === undefined)
    ) {
      fetchAgainFireReport();
    }
  }, [
    fireZones,
    lastSelectedFireZone,
    lastSelectedEvacuationZone,
    setSelectedFireZone,
    setSelectedEvacuationZone,
    ifSettingsUpdated,
    fetchAgainFireReport,
    timeRefreshingFireReport,
  ]);

  useEffect(() => {
    if (targetCompanies && lastSelectedTargetCompanyName !== undefined) {
      const foundSelectedTargetCompany = targetCompanies.find(
        (company: TargetCompany) =>
          company.name === lastSelectedTargetCompanyName?.value
      );

      if (foundSelectedTargetCompany) {
        setSelectedTargetCompany(foundSelectedTargetCompany);
      } else if (!foundSelectedTargetCompany) {
        setSelectedTargetCompany({ name: "" });
      }
    }

    if (ifSettingsUpdated && lastSelectedTargetCompanyName === undefined) {
      fetchAgainFireReport();
    }
  }, [
    targetCompanies,
    lastSelectedTargetCompanyName,
    setSelectedTargetCompany,
    ifSettingsUpdated,
    fetchAgainFireReport,
    timeRefreshingFireReport,
  ]);

  // Updateing fire zone
  const [postFireZoneRequestBody, setPostFireZoneRequestBody] = useState<
    string | false
  >(false);

  const [, fetchAgainFireZoneRequest] = useFetchOtherThanGET({
    path: pathPreferences,
    method: "POST",
    contentType: "application/json",
    body: postFireZoneRequestBody,
    setBody: setPostFireZoneRequestBody,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (postFireZoneRequestBody) {
      fetchAgainFireZoneRequest();
      setIfUpdateSetting(true);
    }
  }, [postFireZoneRequestBody, fetchAgainFireZoneRequest]);

  // Updating target company name
  const [
    postTargetCompanyNameRequestBody,
    setPostTargetCompanyNameRequestBody,
  ] = useState<string | false>(false);

  const [, fetchAgainTargetCompanyNameRequest] = useFetchOtherThanGET({
    path: pathPreferences,
    method: "POST",
    contentType: "application/json",
    body: postTargetCompanyNameRequestBody,
    setBody: setPostTargetCompanyNameRequestBody,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (postTargetCompanyNameRequestBody) {
      fetchAgainTargetCompanyNameRequest();
      setIfUpdateSetting(true);
    }
  }, [postTargetCompanyNameRequestBody, fetchAgainTargetCompanyNameRequest]);

  // Updating evacuation zone
  const [postEvacuationZoneRequestBody, setPostEvacuationZoneRequestBody] =
    useState<string | false>(false);

  const [, fetchAgainEvacuationZoneRequest] = useFetchOtherThanGET({
    path: pathPreferences,
    method: "POST",
    contentType: "application/json",
    body: postEvacuationZoneRequestBody,
    setBody: setPostEvacuationZoneRequestBody,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (postEvacuationZoneRequestBody) {
      fetchAgainEvacuationZoneRequest();
      setIfUpdateSetting(true);
    }
  }, [postEvacuationZoneRequestBody, fetchAgainEvacuationZoneRequest]);

  const [deleteBody, setDeleteBody] = useState<boolean>(false);

  const [, deletePersonAgain] = useFetchOtherThanGET({
    path: `${fireReportPath}?personId=${personToDelete?.personId}&typeOfPerson=${personToDelete?.typeOfPerson}&entryTime=${personToDelete?.entryTime}`,
    method: "DELETE",
    body: deleteBody,
    setBody: setDeleteBody,
    contentType: "application/json",
    successCallback: fetchAgainFireReport,
  });

  useEffect(() => {
    if (deleteBody) {
      deletePersonAgain();
      setDeleteBody(false);
    }
  }, [deleteBody, deletePersonAgain]);

  // Save Fire zone
  const firstRunSaveFireZonePreferences = useRef(true);

  useEffect(() => {
    if (!firstRunSaveFireZonePreferences.current) {
      setPostFireZoneRequestBody(
        JSON.stringify({
          name: OstatnioWybranaStrefaPrzeciwpozarowaId,
          value: selectedFireZone.id ? String(selectedFireZone?.id) : "",
        })
      );
    }

    firstRunSaveFireZonePreferences.current = false;
  }, [
    lastSelectedFireZone,
    selectedFireZone?.id,
    OstatnioWybranaStrefaPrzeciwpozarowaId,
  ]);

  // Save Target company name
  const firstRunSaveTargetCompanyNamePreferences = useRef(true);

  useEffect(() => {
    if (!firstRunSaveTargetCompanyNamePreferences.current) {
      setPostTargetCompanyNameRequestBody(
        JSON.stringify({
          name: OstatnioWybranaFirmaDocelowa,
          value: selectedTargetCompany?.name || "",
        })
      );
    }

    firstRunSaveTargetCompanyNamePreferences.current = false;
  }, [
    lastSelectedTargetCompanyName,
    selectedTargetCompany?.name,
    OstatnioWybranaFirmaDocelowa,
  ]);

  // Save Evacuation zone
  const firstRunSaveEvacuationZonePreferences = useRef(true);

  useEffect(() => {
    if (!firstRunSaveEvacuationZonePreferences.current) {
      setPostEvacuationZoneRequestBody(
        JSON.stringify({
          name: OstatnioWybranaStrefaEwakuacjiId,
          value: selectedEvacuationZone.id
            ? String(selectedEvacuationZone?.id)
            : "",
        })
      );
    }

    firstRunSaveEvacuationZonePreferences.current = false;
  }, [lastSelectedEvacuationZone, selectedEvacuationZone?.id]);

  return (
    <>
      <div>
        <TopBanner
          pathName={
            fireReportMode === FireReportMode.EVACUATION_REPORT
              ? t("evacuation_report")
              : t("fire_report")
          }
        />
      </div>
      {NavigationComponent !== undefined && (
        <div>
          <NavigationComponent />
        </div>
      )}
      <div className="flexAndCenter">
        <LoadingWrapper
          isError={
            fetchingStateFireReport.isError ||
            fetchingStateFireZones.isError ||
            fetchingStateTargetCompanies.isError
          }
          isLodadingProgress={
            fetchingStateFireReport.isFetching ||
            fetchingStateFireZones.isFetching ||
            fetchingStateTargetCompanies.isFetching
          }
          setIfFetchAgain={
            fetchAgainFireReport ||
            fetchAgainFireZones ||
            fetchAgainTargetCompanies
          }
        >
          {!fetchingStateFireReport.isFetching &&
            !fetchingStateFireZones.isFetching &&
            !fetchingStateTargetCompanies.isFetching && (
              <div className={styles.topSymmaryContainer}>
                <div className={styles.topSymmary}>
                  <TimeWithDescription
                    titlePadding={"10px 20px"}
                    parametrs={[
                      {
                        name: (
                          <>
                            <div className={styles.summaryHeader}>
                              <div className={styles.zoneAndTargetCompany}>
                                <SelectZone
                                  label={t("zone")}
                                  fireZones={fireZones}
                                  selectedFireZone={selectedFireZone}
                                  setSelectedFireZone={setSelectedFireZone}
                                />
                                &nbsp;&nbsp;
                                <SelectTargetCompany
                                  companies={targetCompanies}
                                  selectedCompany={selectedTargetCompany}
                                  setSelectedCompany={setSelectedTargetCompany}
                                />
                                {fireReportMode ===
                                  FireReportMode.EVACUATION_REPORT && (
                                  <>
                                    &nbsp;&nbsp;
                                    <SelectZone
                                      label={t("evacuation_zone")}
                                      fireZones={fireZones}
                                      selectedFireZone={selectedEvacuationZone}
                                      setSelectedFireZone={
                                        setSelectedEvacuationZone
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ),
                      },
                      {
                        time: summaryData.total,
                        description: t("number_of_people"),
                      },
                      {
                        time: summaryData.workers,
                        description: t("employees"),
                      },
                      {
                        time: summaryData.guests,
                        description: t("guests"),
                      },
                    ]}
                  />
                </div>
              </div>
            )}
        </LoadingWrapper>
        <div>
          <TableComponentMemo
            columns={columns}
            rows={rowsFireReport ? rowsFireReport : []}
            layoutSettingName={layoutSettingName}
            tableEditColumnWidth={100}
            fetchingState={{
              isFetching: fetchingStateFireReport.isFetching,
              isError: fetchingStateFireReport.isError,
              fetchAgain: fetchAgainFireReport,
            }}
            grouping={[
              { columnName: "typeOfPerson" },
              { columnName: "organizationalUnitOrCompany" },
            ]}
            pageSize={50}
            groupCellContent={(props) => <GroupCellContent t={t} {...props} />}
            expandedGroupsDefault={expandedGroupsDefault}
            summaryRowProps={{
              groupItems: [
                {
                  columnName: "typeOfPerson",
                  type: "count",
                  showInGroupFooter: false,
                },
              ],
            }}
            inlineSummaryItemComponent={({ ...props }) => {
              return <>{`${t("count_number")}: ${props.summary.value}`}</>;
            }}
            refreshingButton={{
              ifShow: true,
              onClick: () => {
                fetchAgainFireReport();
                handleChangeRefreshingTime(timeRefreshingFireReport);
              },
              onlyRefreshNow: true,
            }}
            actionsColumnUpdated={{
              removeButton: {
                ifShow: true,
                onClick: (row) => {
                  let personToDelete: FireReportItemToDelete = {
                    personId: row.personId,
                    typeOfPerson: row.typeOfPersonId,
                    entryTime: row.lastEntryTime,
                  };
                  setPersonToDelete(personToDelete);
                  setDeleteBody(true);
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default FireReport;
