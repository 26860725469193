import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import styles from "./comments.module.scss";
import Comments from "./comments";
import { Tooltip } from "@mui/material";

interface Props {
  url: string;
}

export default function CommentsRoot({ url }: Props): ReactElement {
  const { t } = useTranslation();
  const [ifShowComments, setIfShowComments] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState(false);
  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const commnetsResponse = useFetchGet(url, ifFetchAgain);
  const [comments, setComments] = useState<Array<any>>([]);

  useEffect(() => {
    if (commnetsResponse.error !== null) {
      setIsError(true);
      setIfFetchAgain(false);
      return;
    }
    if (commnetsResponse.data === null) return;
    setComments(commnetsResponse.data);
    setIfFetchAgain(false);
    setIsFetching(false);
  }, [commnetsResponse, t]);

  return (
    <div>
      <div className={styles.showHideComments}>
        <Tooltip title={ifShowComments ? t("hide")! : t("show")!}>
          <div
            onClick={() => {
              let newFetchingState = !ifShowComments;
              setIfShowComments(newFetchingState);
              setIsFetching(newFetchingState);
              setIfFetchAgain(newFetchingState);
              if (newFetchingState === false) setIsError(false);
            }}
          >
            <div className={styles.showCommentsText}>{t("comments")}</div>
            <FontAwesomeIcon
              className={ifShowComments ? styles.faMinus : styles.faPlus}
              icon={ifShowComments ? faMinus : faPlus}
            />
          </div>
        </Tooltip>
      </div>

      <div className={styles.commnetsRootContainer}>
        <LoadingWrapper
          isLodadingProgress={isFetching}
          isError={isError}
          setIfFetchAgain={setIfFetchAgain}
          setIsError={setIsError}
        >
          {ifShowComments && (
            <Comments
              url={url}
              comments={comments}
              setIfFetchAgain={setIfFetchAgain}
            />
          )}
        </LoadingWrapper>
      </div>
    </div>
  );
}
