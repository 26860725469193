import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import TableComponentMemo from "../../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import PopupPreApprovedCompanies from "./PopupPreApprovedCompanies";

const PreApprovedCompaniesContent = () => {
  const { t } = useTranslation();

  const columns = [
    { name: "companyName", title: t("company") },
    { name: "comments", title: t("comments") },
    { name: "addingPersonFullName", title: t("adding_person") },
    { name: "createdAt", title: t("data_created"), type: "date" },
  ];

  const responseCallback = useCallback(
    (responseData: IPreApprovedCompany[]) => {
      const modified = responseData?.map((row) => {
        return {
          ...row,
          comments: row.comments ? row.comments : "-",
        };
      });
      return modified;
    },
    []
  );

  const [
    preApprovedCompanies,
    fetchingStatePreApprovedCompanies,
    fetchAgainPreApprovedCompanies,
  ] = useFetchAndSetGET<IPreApprovedCompany[]>({
    path: "pfso/pre-approved-companies",
    modifyResponseCallback: responseCallback,
  });

  const [preApprovedCompanyIdToRemove, setPreApprovedCompanyIdToRemove] =
    useState<false | number>(false);

  const [fetchingStateRemove, fetchAgainRemove] = useFetchOtherThanGET({
    path: `pfso/pre-approved-companies/${preApprovedCompanyIdToRemove}`,
    method: "DELETE",
    body: preApprovedCompanyIdToRemove,
    setBody: setPreApprovedCompanyIdToRemove,
    successCallback: fetchAgainPreApprovedCompanies,
  });

  useEffect(() => {
    if (preApprovedCompanyIdToRemove !== false) {
      fetchAgainRemove();
    }
  }, [preApprovedCompanyIdToRemove, fetchAgainRemove]);

  return (
    <div>
      <div>
        <TopBanner
          pathName={`${t("companies")} ${t("pre_approved").toLowerCase()}`}
        />
      </div>
      <div className="flexAndCenter">
        <div>
          <TableComponentMemo
            columns={columns}
            rows={preApprovedCompanies}
            layoutSettingName={"layoutPfsoPreApprovedCompaniesRecords"}
            tableEditColumnWidth={200}
            actionsColumnUpdated={{
              popup: PopupPreApprovedCompanies,
              successCallbackPopup: fetchAgainPreApprovedCompanies,
              addButton: {
                ifShow: true,
              },
              editButton: {
                ifShow: true,
                name: t("edit"),
              },
              removeButton: {
                ifShow: true,
                onClick: (row: IPreApprovedCompany) => {
                  setPreApprovedCompanyIdToRemove(row.id);
                },
              },
            }}
            fetchingState={{
              fetchAgain: fetchAgainPreApprovedCompanies,
              isError: fetchingStatePreApprovedCompanies.isError,
              isFetching:
                fetchingStatePreApprovedCompanies.isFetching ||
                fetchingStateRemove.isFetching,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PreApprovedCompaniesContent;
