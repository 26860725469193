export default function projectCreatePhotosArray(collaborators: any[]) {
  let photosLocal: { timeWorkerId: number; photo: string }[] = [];
  collaborators.forEach((collaborator) => {
    if (collaborator.photo !== null && collaborator.photo !== "")
      photosLocal.push({
        timeWorkerId: collaborator.id,
        photo: collaborator.photo,
      });
  });
  return photosLocal;
}
