import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { useSnackbar } from "notistack";
import useFetchGet from "../../../hooks/fetchHooks/get/useFetchGet";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import EndTaskDialogContent from "./endTaskDialogContent";
import styles from "./homePage.module.scss";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";

const EndTask = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;

  const [ifFetchProject, setIfFetchProject] = useState<boolean>(false);
  const projectResponse = useFetchGet(
    `worker-time/${authUserId}/projects/0/tasks`,
    ifFetchProject
  );
  const [quickTask, setQuickTask] = useState(false);
  useEffect(() => {
    if (projectResponse.error === 404) {
      setIfFetchProject(false);
      enqueueSnackbar(t("quick_task_does_not_exists"), { variant: "error" });
      return;
    }
    if (projectResponse.error !== null) {
      setIfFetchProject(false);
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
      return;
    }
    if (projectResponse.data === null) return;
    setIfFetchProject(false);
    let task = projectResponse.data.tasks.find((task) =>
      task.name?.startsWith("QuickTask")
    );
    if (task !== undefined) {
      setQuickTask(task);
      setIsOpen(true);
      return;
    } else
      enqueueSnackbar(t("quick_task_does_not_exists"), { variant: "error" });
  }, [projectResponse, enqueueSnackbar, t]);

  return (
    <>
      <div
        className={styles.blueButton}
        onClick={() => {
          setIfFetchProject(true);
        }}
      >
        <div>
          <FontAwesomeIcon className="fa-2x" icon={faSignInAlt} />
        </div>
        <div>{t("end_task")}</div>
      </div>
      <Dialog maxWidth={"xl"} open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle className="dialogTitleGlobal">
          <div className="titlePopup">{t("ending_task")}</div>
        </DialogTitle>
        <EndTaskDialogContent
          setIsDialogOpen={setIsOpen}
          endingTask={quickTask}
        />
      </Dialog>
    </>
  );
};

export default EndTask;
