import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

const initialState: any = {
  timeWorkerProfile: { ifFetched: false },
  acWorkerProfile: { ifFetched: false },
  acSuperiorProfile: { ifFetched: false },
  timeSuperiorProfile: { ifFetched: false },
};

export const profilesReducer = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    setTimeWorkerProfile: (state, action: PayloadAction<any>) => {
      state.timeWorkerProfile = action.payload;
    },
    setACWorkerProfile: (state, action: PayloadAction<any>) => {
      state.acWorkerProfile = action.payload;
    },
    setACSuperiorProfile: (state, action: PayloadAction<any>) => {
      state.acSuperiorProfile = action.payload;
    },
    setTimeSuperiorProfile: (state, action: PayloadAction<any>) => {
      state.timeSuperiorProfile = action.payload;
    },
  },
});

export const selectTimeWorkerProfile = (state: RootState) =>
  state.profiles.timeWorkerProfile;
export const { setTimeWorkerProfile } = profilesReducer.actions;

export const selectACWorkerProfile = (state: RootState) =>
  state.profiles.acWorkerProfile;
export const { setACWorkerProfile } = profilesReducer.actions;

export const selectACSuperiorProfile = (state: RootState) =>
  state.profiles.acSuperiorProfile;
export const { setACSuperiorProfile } = profilesReducer.actions;

export const selectTimeSuperiorProfile = (state: RootState) =>
  state.profiles.timeSuperiorProfile;
export const { setTimeSuperiorProfile } = profilesReducer.actions;

export default profilesReducer.reducer;
