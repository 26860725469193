import GuestsVisitsReportContent from "../../helpersComponents/GuestsVisitsReport/GuestsVisitsReportContent";

function GuestsVisitsReportEmployee() {
  return (
    <div>
      <GuestsVisitsReportContent
        pathName="employee/guest-visits"
        layoutSettingName="layoutEmployeeVisitsReport"
        pathApiAttachments="employee/attachments-in-ports"
      />
    </div>
  );
}

export default GuestsVisitsReportEmployee;
