import Dropdown from "react-multilevel-dropdown";
import MenuItem from "./MenuItem";
import styles from "./styles.module.scss";

interface IProps {
  name: React.ReactNode;
  position?: "left" | "right" | "bottom" | "left-top" | "right-top";
  items: ISelectMenuItem[];
  disabled?: boolean;
  profile?: "superiorTime" | "superiorAC";
}

const SelectMultiMenu: React.FC<IProps> = ({
  name,
  items,
  position,
  disabled,
  profile,
}) => {
  return (
    <Dropdown
      title={name}
      position={"right"}
      isDisabled={disabled}
      buttonClassName={`${styles.button} ${
        profile ? styles[`${profile}`] : ""
      }`}
      menuClassName={styles.dropdownMenu}
    >
      {items.map((item, index) => {
        return <MenuItem item={item} position={position} key={index} />;
      })}
    </Dropdown>
  );
};

export default SelectMultiMenu;
