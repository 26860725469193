import { useEffect, useState } from "react";
import useFetch from "../../../../hooks/fetchHooks/useFetch/useFetch";

export default function useFetchingSchemas(setSelectedScheme) {
  const [schemas, setSchemas] = useState<any[] | null>(null);

  let [setIsFetchingSchemas, fetchingStateSchemas, fetchAgainSchemas] =
    useFetch({
      method: "GET",
      path: "superior-time/daily-schedule",
    });

  useEffect(() => {
    if (
      fetchingStateSchemas.isError ||
      fetchingStateSchemas.response === undefined
    )
      return;
    if (!Array.isArray(fetchingStateSchemas.response.resJson)) return;
    let data: any[] = fetchingStateSchemas.response.resJson;

    setSchemas(data);
    setSelectedScheme(data[0]);
    setIsFetchingSchemas(false);
  }, [
    fetchingStateSchemas.response,
    fetchingStateSchemas.isError,
    setIsFetchingSchemas,
    setSelectedScheme,
  ]);

  return { schemas, fetchingStateSchemas, fetchAgainSchemas };
}
