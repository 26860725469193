import { faSadTear, faSmile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../Button/button";
import styles from "./styles.module.scss";

type Props = {
  quizResults: any;
  resultViewButtonClick: (result) => void;
};

export default function QuizResults({
  quizResults,
  resultViewButtonClick,
}: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <h1>
        {quizResults.passed ? (
          <div className={styles.resultContainer}>
            <div>
              <FontAwesomeIcon className={styles.faSmile} icon={faSmile} />
            </div>
            <div>{t("test_passed")}!</div>
          </div>
        ) : (
          <div className={styles.resultContainer}>
            <div>
              <FontAwesomeIcon className={styles.faSadTear} icon={faSadTear} />
            </div>
            <div>{t("test_failed")}</div>
          </div>
        )}
      </h1>
      <div className={styles.callbackresult}>
        <ButtonStyled onClick={() => resultViewButtonClick(quizResults)}>
          {t("back")}
        </ButtonStyled>
      </div>
    </div>
  );
}
