import { useTranslation } from "react-i18next";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../PopupHelpers";
import EmployeesInfoPopupContent from "./EmployeesInfoPopupContent";

interface Props {
  unit: Unit;
  isACSuperior: boolean;
  isPopupOpen: boolean;
  closeEmployeePopup: () => void;
}

const EmployeesInfoPopup: React.FC<Props> = ({
  unit,
  isACSuperior,
  isPopupOpen,
  closeEmployeePopup,
}) => {
  const { t } = useTranslation();

  const { id: unitId, name: unitName, code: unitCode } = unit;

  return (
    <DialogStyled open={isPopupOpen} onClose={closeEmployeePopup} maxWidth="lg">
      <DialogTitleStyled title={`${unitName} [${unitCode}]`} />
      <EmployeesInfoPopupContent unitId={unitId} isACSuperior={isACSuperior} />
      <DialogActionsStyled
        ifHideConfirm={true}
        onCancel={closeEmployeePopup}
        onCancelText={t("close")}
      />
    </DialogStyled>
  );
};

export default EmployeesInfoPopup;
