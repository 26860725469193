import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import { filterTableRows } from "./filterTableRows";

interface Props {
  columns: ColumnTable[];
  rows: { [key in string]: any }[];
  title: any;
  layoutSettingName: string;
  filterTablesByDate: FilterTablesByDateType;
}

export default function AccordingSummaryWithTable({
  columns,
  rows,
  title,
  layoutSettingName,
  filterTablesByDate,
}: Props): ReactElement {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [rowsFiltered, setRowsFiltered] = useState<{}[] | null>([]);

  useEffect(() => {
    let filteredRows = filterTableRows(filterTablesByDate, rows, "date").map(
      (row, index) => {
        let rowLocal = { ...row };
        rowLocal["lp"] = index + 1;
        return rowLocal;
      }
    );
    setRowsFiltered(filteredRows);
  }, [filterTablesByDate, rows]);

  return (
    <div>
      {rowsFiltered !== null && title && (
        <Accordion
          expanded={isExpanded}
          onChange={() => setIsExpanded(!isExpanded)}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            expandIcon={<ExpandMoreIcon />}
          >
            {title(rowsFiltered.length)}
          </AccordionSummary>
          <AccordionDetails>
            <TableComponentMemo
              layoutSettingName={layoutSettingName}
              rows={rowsFiltered}
              columns={columns}
              ifEdit={false}
              ifAdd={false}
              ifDelete={false}
              backgroundColor={"White"}
              columnsWidth={[{ columnName: "lp", width: "100" }]}
              filteringStateColumnExtensions={[
                { columnName: "goToRheRecords", filteringEnabled: false },
              ]}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}
