import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

interface Props {
  lastEvent: any;
  eightHoursLastEvent: any;
  remoteDutyLastEvent: any;
  homeOfficeLastEvent: any;
  fetchAgainTasks: boolean;
}
const initialState: Props = {
  lastEvent: false,
  eightHoursLastEvent: false,
  remoteDutyLastEvent: false,
  homeOfficeLastEvent: false,
  fetchAgainTasks: true,
};

export const homePageReducer = createSlice({
  name: "homePage",
  initialState,
  reducers: {
    setLastEvent: (state, action: PayloadAction<any>) => {
      state.lastEvent = action.payload;
    },
    setEightHoursLastEvent: (state, action: PayloadAction<any>) => {
      state.eightHoursLastEvent = action.payload;
    },
    setRemoteDutyLastEvent: (state, action: PayloadAction<any>) => {
      state.remoteDutyLastEvent = action.payload;
    },
    setHomeOfficeLastEvent: (state, action: PayloadAction<any>) => {
      state.homeOfficeLastEvent = action.payload;
    },
    setFetchAgainTasks: (state, action: PayloadAction<boolean>) => {
      state.fetchAgainTasks = action.payload;
    },
  },
});

export const selectLastEvent = (state: RootState) => state.homePage.lastEvent;
export const selectEightHoursLastEvent = (state: RootState) =>
  state.homePage.eightHoursLastEvent;
export const selectRemoteDutyLastEvent = (state: RootState) =>
  state.homePage.remoteDutyLastEvent;
export const selectHomeOfficeLastEvent = (state: RootState) =>
  state.homePage.homeOfficeLastEvent;

export const selectFetchAgainTasks = (state: RootState) =>
  state.homePage.fetchAgainTasks;
export const {
  setLastEvent,
  setEightHoursLastEvent,
  setRemoteDutyLastEvent,
  setHomeOfficeLastEvent,
  setFetchAgainTasks,
} = homePageReducer.actions;

export default homePageReducer.reducer;
