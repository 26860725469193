import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../helpersComponents/PopupHelpers";
import FromAddNewGuest from "./FromAddNewGuest";

const PopupAddNewGuest: React.FC = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
}: any) => {
  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [bodyRequest, setBodyRequest] = useState<false | any>(false);

  const onSubmit = (values) => {
    setErrorMessage("");
    let body = JSON.stringify({
      firstName: values.firstName,
      lastName: values.lastName,
      idNumber: "",
      companyName: "",
      carRegistrationNumber: "",
      carBrand: "",
      isHighlighted: false,
      highlightedDescription: "",
      highlightedBy: "",
      citizenship: "",
      phoneNumber: "",
      dateOfBirth: values.isSelectedDateOfBirth
        ? convertDateFetch(values.dateOfBirth)
        : null,
      email: values.isSelectedEmail ? values.email : null,
    });
    setBodyRequest(body);
  };

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `pfso/guests${rowId !== undefined ? `/${rowId}` : ""}`,
    method: rowId !== undefined ? "PUT" : "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  useEffect(() => {
    if (isOpen === false) {
      setErrorMessage("");
    }
  }, [isOpen]);

  return (
    <DialogStyled open={isOpen} onClose={closePopup}>
      <DialogTitleStyled title={t("add_guest")} />
      <DialogContentStyled>
        <FromAddNewGuest rowId={rowId} onSubmit={onSubmit} />
      </DialogContentStyled>
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupAddNewGuest;
