import {
  faCaretDown,
  faRedoAlt,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import convertMsToMin from "../../../HelpersFunctions/convertMsToMin";
import ButtonStyled from "../Button/button";
import styles from "./styles.module.scss";

interface Props {
  handleChangeRefreshingTime: ((value: number | null) => void) | undefined;
  refreshingTime: null | number | undefined;
  icon?: IconDefinition;
  hideInfo?: boolean;
  color?: string;
}

const RefreshButtonMenu = (props: Props) => {
  const { t } = useTranslation();
  const { refreshingTime, icon, handleChangeRefreshingTime, hideInfo } = props;

  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>(
    null
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<EventTarget & HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const refreshOptions = [
    {
      name: t("refresh"),
      value: 0,
    },
    {
      name: t("none"),
      value: null,
    },
    {
      name: `1 ${t("minute_abbreviation")}`,
      value: 60_000,
    },
    {
      name: `3 ${t("minute_abbreviation")}`,
      value: 180_000,
    },
    {
      name: `5 ${t("minute_abbreviation")}`,
      value: 300_000,
    },
  ];

  let refreshingTimeInMin = refreshingTime
    ? convertMsToMin(refreshingTime)
    : null;

  return (
    <div>
      <div
        className={styles.buttonContainer}
        style={{ color: props.color ? props.color : "black" }}
      >
        {!hideInfo && (
          <Tooltip title={t("refreshing")!} placement="top">
            <div>
              <FontAwesomeIcon icon={faRedoAlt} />
              {` ${
                refreshingTimeInMin
                  ? refreshingTimeInMin + " " + t("minute_abbreviation")
                  : t("none")
              }`}
            </div>
          </Tooltip>
        )}

        <Tooltip
          title={
            hideInfo && refreshingTimeInMin
              ? `${refreshingTimeInMin + " " + t("minute_abbreviation")}`
              : hideInfo && !refreshingTimeInMin
              ? t("none")!
              : ""
          }
          placement="top"
        >
          <div>
            <ButtonStyled onClick={handleClick}>
              {t("refresh")}{" "}
              <FontAwesomeIcon icon={icon ? icon : faCaretDown} />
            </ButtonStyled>
          </div>
        </Tooltip>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {refreshOptions.map((option) => (
          <MenuItem
            style={{
              backgroundColor:
                option.value === refreshingTime ? "#e3e7e6" : "transparent",
            }}
            key={option.name}
            onClick={() => {
              handleChangeRefreshingTime!(option.value);
              handleClose();
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default RefreshButtonMenu;
