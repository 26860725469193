import { Form, Formik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";

import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../PopupHelpers";
import PopupRequirementContent from "./PopupRequirementContent";

interface IProps {
  open: boolean;
  guestId: number | null;
  pathGetGuestTrainingDateExpiry: string;
  pathUpdateTrainingDateExpiry: string;
  onCancelChanges: () => void;
  successCallback: () => void;
  setFieldValueOption?: {
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void;
    name: string;
  };
}

const PopupRequirements = ({
  open,
  guestId,
  pathGetGuestTrainingDateExpiry,
  pathUpdateTrainingDateExpiry,
  onCancelChanges,
  successCallback,
  setFieldValueOption,
}: IProps) => {
  const { t } = useTranslation();
  const setSubmittingRef = useRef<(v: boolean) => void>();
  const [errorMessage, setErrorMessage] = useState("");
  const [bodyRequest, setBodyRequest] = useState<false | any>(false);

  const setSubmitting = useCallback(() => {
    if (setSubmittingRef?.current) setSubmittingRef.current(false);
  }, []);

  const successCallbackPost = useCallback(() => {
    onCancelChanges();
    successCallback();
  }, [successCallback, onCancelChanges]);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${pathUpdateTrainingDateExpiry}/${guestId}`,
    method: "PATCH",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    disableErrorSnackbar: true,
    successCallback: successCallbackPost,
    errorCallback: setSubmitting,
  });

  useEffect(() => {
    if (open === false) {
      setErrorMessage("");
    }
  }, [open]);

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  const [trainingDateExpiry, setTrainingDateExpiry] = useState<null | Date>(
    null
  );

  useEffect(() => {
    if (
      fetchingStatePostData.response?.status === 200 &&
      setFieldValueOption &&
      trainingDateExpiry &&
      setFieldValueOption
    ) {
      setFieldValueOption.setFieldValue(
        setFieldValueOption.name,
        trainingDateExpiry
      );
    }
  }, [
    fetchingStatePostData.response?.status,
    setFieldValueOption,
    trainingDateExpiry,
  ]);

  return (
    <>
      <DialogStyled
        open={open}
        onClose={onCancelChanges}
        disableBackdropClick={true}
      >
        <DialogTitleStyled title={t("requirements_not_met")} />
        <Formik
          initialValues={{
            trainingExpiryDate: new Date(),
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmittingRef.current = setSubmitting;
            setErrorMessage("");
            setBodyRequest(
              JSON.stringify([
                {
                  path: "/trainingExpiryDate",
                  op: "replace",
                  value: getEndOfDate(values.trainingExpiryDate, "Day"),
                },
              ])
            );
            setTrainingDateExpiry(
              getEndOfDate(values.trainingExpiryDate, "Day")
            );
          }}
          validationSchema={Yup.object({
            trainingExpiryDate: Yup.date()
              .typeError(t("invalid_date"))
              .min(
                getBeginningOfDate(new Date(), "Day"),
                `${t("date")} ${`${t("must_be_later_than")} ${t(
                  "yesterday"
                )}`.toLocaleLowerCase()}
             `
              ),
          })}
        >
          {({ isSubmitting, setFieldValue }) => {
            return (
              <Form noValidate>
                <DialogContentStyled titleWidth={200}>
                  <PopupRequirementContent
                    guestId={guestId}
                    pathGetGuestTrainingDateExpiry={
                      pathGetGuestTrainingDateExpiry
                    }
                    setFieldValue={setFieldValue}
                  />
                </DialogContentStyled>
                <DialogActionsStyled
                  typConfirm="submit"
                  onCancel={onCancelChanges}
                  isLoading={fetchingStatePostData.isFetching}
                  disabledConfirm={isSubmitting}
                  errorString={errorMessage}
                />
              </Form>
            );
          }}
        </Formik>
      </DialogStyled>
    </>
  );
};

export default PopupRequirements;
