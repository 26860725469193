import React, { useState, useEffect } from "react";
import "./hoursRequest.scss";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import { statusDictionary } from "../../../../Constants/statusDictionary";
import { hoursRequestsTypes } from "../../../../Constants/hoursRequestsTypes";
import AddEditHoursRequestPopup from "./../../../helpersComponents/Requests/Hours/AddEditHoursRequestPopup";
import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import RowDetail from "./rowDetail";
import useFetchPatch from "../../../../hooks/fetchHooks/patch/useFetchPatch";
import { SnackbarStyled } from "../../../helpersComponents/MaterialUi";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { useHistory } from "react-router-dom";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import {
  getJoinedRequestsName,
  isJoinedRequestType,
} from "../../../../HelpersFunctions/requests/hoursRequestsHelpers";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";

let Cell: any = TableEditColumn.Cell;

const ActionColumn: React.FC = ({
  children,
  row,
  classes,
  ...restProps
}: any) => {
  if (row.status === 1 || row.status === 4) {
    return (
      <Cell row={restProps.row} className="tableCellEditCancelStyleGlobal">
        {children}
      </Cell>
    );
  }
  return (
    <Cell row={restProps.row} className="tableCellDisabledGlobal">
      {children}
    </Cell>
  );
};

function HoursRequestContent() {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1, 0, 0, 0)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31, 0, 0)
  );
  const [fetchAgain, setFetchAgain] = useState(true);
  const [dataModified, setDataModified] = useState<any>([]);
  const [isFetching, setIsFetching] = useState(true);
  const history: any = useHistory();

  const [isError, setIsError] = useState<boolean>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const settings = useAppSelector(selectSettings);

  const { data, error } = useFetchGet(
    `worker-time/${authUserId}/hour-requests?DateFrom=` +
      computeDateRangeFetch(startDate, "T00:00:00") +
      "&DateTo=" +
      computeDateRangeFetch(endDate),
    fetchAgain
  );
  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);
  const [newStatusBody, setNewStatusBody] = useState<any>(false);
  const [idUpdateStatus, setIdUpdateStatus] = useState();
  const responseStatusUpdate = useFetchPatch(
    `worker-time/${authUserId}/hour-requests/` + idUpdateStatus,
    newStatusBody
  );

  const columns = [
    {
      name: "dateFrom",
      title: t("date_from"),
      type: "date_hours",
      customFormatter: (row) => (
        <span style={{ color: "green" }}>
          {toLocaleStringCustom({
            date: row.dateFrom ? new Date(row.dateFrom) : false,
            t,
            ifShowHours: true,
          })}
        </span>
      ),
    },
    {
      name: "dateTo",
      title: t("date_to2"),
      type: "date_hours",
      customFormatter: (row) => (
        <span style={{ color: "red" }}>
          {toLocaleStringCustom({
            date: row.dateTo ? new Date(row.dateTo) : false,
            t,
            ifShowHours: true,
          })}
        </span>
      ),
    },
    { name: "time", title: t("time") },
    { name: "realExitDate", title: t("real_exit_date"), type: "date_hours" },
    { name: "realEntryDate", title: t("real_entry_date"), type: "date_hours" },
    { name: "realTime", title: t("real_time") },
    { name: "typeIdString", title: t("type_of_request") },
    { name: "statusTranslated", title: t("request_status") },
    { name: "comment", title: t("comment") },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
  ];

  useEffect(() => {
    setIsError(false);
    setFetchAgain(true);
    setIsFetching(true); // circ
  }, [startDate, endDate]);

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setFetchAgain(false);
      return;
    }
    if (data === null) return;

    data.forEach((element, index) => {
      data[index].time = convertMinsToHrsMins(
        (new Date(element.dateTo).getTime() -
          new Date(element.dateFrom).getTime()) /
          (1000 * 60)
      );

      if (isJoinedRequestType(data[index].typeId)) {
        data[index].time2 = convertMinsToHrsMins(
          (new Date(element.date2To).getTime() -
            new Date(element.date2From).getTime()) /
            (1000 * 60)
        );
      }

      data[index].statusTranslated = t(statusDictionary[data[index].status]);
      data[index].typeIdString = t(hoursRequestsTypes[data[index].typeId]);
      data[index].isJoinedRequest = isJoinedRequestType(data[index].typeId);
      data[index].joinedRequest1Name = getJoinedRequestsName(
        data[index].typeId,
        t
      )[0];
      data[index].joinedRequest2Name = getJoinedRequestsName(
        data[index].typeId,
        t
      )[1];

      data[index].createdAtString = new Date(element.createdAt).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );
      data[index].dateFromString = new Date(element.dateFrom).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );
      data[index].dateToString = new Date(element.dateTo).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );

      if (element.date2From && element.date2To) {
        data[index].date2FromString = new Date(
          element.date2From
        ).toLocaleString(t("scheduler_language"), {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
        data[index].date2ToString = new Date(element.date2To).toLocaleString(
          t("scheduler_language"),
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }
        );
      }

      data[index].approvedAtString = element?.approvedAt
        ? new Date(element.approvedAt).toLocaleString(t("scheduler_language"), {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })
        : "—";
    });

    setDataModified(data);
    setIsFetching(false);
    setFetchAgain(false);
  }, [data, error, t]);

  const commitChanges = ({ added, changed, deleted }) => {
    if (deleted) {
      let row: any = dataModified.find((row) => row.id === deleted[0]);
      setNewStatusBody([
        {
          op: "replace",
          path: "/status",
          value: 7,
        },
      ]);
      setIdUpdateStatus(row.id);
    } else if (added) {
      setFetchAgain(true);
      setSnackbarState([true, "success", t("application_added")]);
    } else if (changed) {
      setFetchAgain(true);
      setSnackbarState([true, "success", t("application_edited")]);
    }

    setIsFetching(true); // circ
  };

  useEffect(() => {
    if (settings.WebWnioskiGodzinowePracownikDaneMiesieczne?.value === "1") {
      let currentDate = new Date();
      setStartDate(
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0)
      );
      setEndDate(new Date(getEndOfDate(currentDate, "Month")));
    }
  }, [settings.WebWnioskiGodzinowePracownikDaneMiesieczne?.value]);

  useEffect(() => {
    if (responseStatusUpdate.data?.status === 200) {
      setSnackbarState([true, "success", t("application_canceled")]);
      setFetchAgain(true);
      setIsFetching(true); // circ
    } else if (responseStatusUpdate.data?.status === 400) {
      let error = "";
      Object.keys(responseStatusUpdate.data.errors).forEach((errorObject) => {
        responseStatusUpdate.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setIsFetching(false); // circ
      setSnackbarState([true, "error", error]);
    } else if (responseStatusUpdate.error !== null) {
      setIsFetching(false); // circ
      setSnackbarState([true, "error", t("something_went_wrong")]);
    }
    setNewStatusBody(false);
  }, [responseStatusUpdate, t]);

  return (
    <>
      <TopBanner pathName={t("applications_for_hour")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
          ></DatePickerRange>
        </div>
        <div>
          <LoadingWrapper
            isLodadingProgress={isFetching}
            isError={isError}
            setIfFetchAgain={setFetchAgain}
            setIsError={setIsError}
          >
            {!isFetching && !isError && (
              <TableComponent
                layoutSettingName="layoutWorkerTimeRequestsHoursRequest"
                rows={dataModified}
                columns={columns}
                popup={AddEditHoursRequestPopup}
                ifEdit={true}
                ifAdd={true}
                ifDelete={true}
                deleteCommandName={t("delete")}
                commitChanges={commitChanges}
                rowDetail={RowDetail}
                actionColumn={ActionColumn}
                openPopup={history.location.state ? true : false}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </>
  );
}

export default HoursRequestContent;
