import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../../../helpersComponents/PopupHelpers";

interface IProps {
  isOpen: boolean;
  closePopup: () => void;
}

const FreeCabinetPopup: React.FC<IProps> = ({ isOpen, closePopup }) => {
  const { t } = useTranslation();
  const [freeCabinet, fetchingStateFreeCabinet, fetchAgainFreeCabinet] =
    useFetchAndSetGET<FreeCabinet>({
      path: "superior-ac/elevators-cabinets/find-free",
      startFetchOnInitial: true,
    });

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      disableBackdropClick={true}
    >
      <DialogTitleStyled title={t("find_free_cabinet")} />
      <DialogContentStyled>
        <LoadingWrapper
          isLodadingProgress={fetchingStateFreeCabinet.isFetching}
          isError={fetchingStateFreeCabinet.isError}
          setIfFetchAgain={() => {
            if (fetchingStateFreeCabinet.isError) {
              fetchAgainFreeCabinet();
            }
          }}
        >
          <p>
            {freeCabinet?.freeCabinet
              ? t("empty_cabinet_was_found")
              : t("no_free_cabinet")}
            : {freeCabinet?.freeCabinet}
          </p>
          <p>
            {t("left")}: {freeCabinet?.numberOfFreeCabinets}
          </p>
        </LoadingWrapper>
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="button"
        onConfirmText="OK"
        onConfirm={closePopup}
        ifHideCancel={true}
      />
    </DialogStyled>
  );
};

export default FreeCabinetPopup;
