import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import { selectSettings } from "../../../../reducers/settings";
import { useAppSelector } from "../../../../store/hooks";
import {
  activeColorNameDictionary,
  colorNameDictionary,
} from "../../../ProfilesRouting/profilesDictionaries";
import "./textField.scss";

const useStyles = (authUser: authUserInfo) =>
  makeStyles((theme) => ({
    root: {
      border: "1px solid #e2e2e1",
      overflow: "hidden",
      borderRadius: 4,
      backgroundColor: "White",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:hover": {
        backgroundColor: "#fff",
      },
      "&$focused": {
        backgroundColor: "#fff",
        borderColor:
          activeColorNameDictionary[authUser.currentProfile?.type] !== undefined
            ? activeColorNameDictionary[authUser.currentProfile.type]
            : "#1AB394",
      },
    },
    disabled: {
      pointerEvents: "none",
    },
    focused: {},
  }));

const useStylesError = makeStyles((theme) => ({
  root: {
    border: "1px solid red",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "White",
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      borderColor: "red",
    },
  },
  disabled: {
    pointerEvents: "none",
  },
  focused: {},
}));

const useStylesErrorAnimation = makeStyles((theme) => ({
  root: {
    border: "1px solid red",
    animation: "$shake 0.32s cubic-bezier(.36,.07,.19,.97) both;", //////
    transform: "translate3d(0, 0, 0);", /////
    backfaceVisibility: "hidden", //////
    perspective: "1000px;", ////
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "White",
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      borderColor: "red",
    },
  },
  disabled: {
    pointerEvents: "none",
  },
  focused: {},
  "@keyframes shake": {
    "10%, 90%": {
      transform: "translate3d(-1px, 0, 0);",
    },

    "20%, 80%": {
      transform: "translate3d(1px, 0, 0);",
    },

    "30%, 50%, 70%": {
      transform: "translate3d(-1px, 0, 0);",
    },

    "40%, 60%": {
      transform: "translate3d(1px, 0, 0);",
    },
  },
}));

const useStylesDarkTheme = (authUser: authUserInfo) =>
  makeStyles((theme) => ({
    root: {
      border:
        authUser !== undefined || authUser !== null
          ? `1px solid ${colorNameDictionary[authUser.currentProfile?.type]}`
          : "1px solid #1acc95",
      overflow: "hidden",
      borderRadius: 4,
      backgroundColor: "#36373C",
      color: "White",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:hover": {
        // backgroundColor: '#fff',
      },
      "&$focused": {
        // backgroundColor: '#fff',
        borderColor:
          activeColorNameDictionary[authUser.currentProfile?.type] !== undefined
            ? activeColorNameDictionary[authUser.currentProfile.type]
            : "#1AB394",
      },
    },
    disabled: {
      pointerEvents: "none",
      color: "lightgray",
    },
    focused: {},
  }));

const useStylesHelperText = makeStyles((theme) => ({
  textField: {
    "& p": {
      color: "red",
    },
  },
}));

function TextFieldStyled(props) {
  const {
    step,
    startAdornment,
    endAdornment,
    hiddenLabel,
    size,
    InputProps,
    isError,
    error,
    ownCheckIfThereIsError,
    onChangeOwn,
    onBlur,
    ifAnimation,
    darkTheme,
    ifToUpperCaseShipsAndPorts,
    onChange,
    mask,
    ...restprops
  } = props;
  const settings = useAppSelector(selectSettings);
  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );
  const classesNoError = useStyles(authUser)();
  const classesError = useStylesError();
  const classesHelperText = useStylesHelperText();
  const classesErrorAnimation = useStylesErrorAnimation();
  const classesDarkTheme = useStylesDarkTheme(authUser)();

  let classes = classesNoError;
  if (isError && ifAnimation === "noAnimation") {
    classes = classesError;
  } else if (isError && ifAnimation === "animation") {
    classes = classesErrorAnimation;
  } else if (isError) classes = classesErrorAnimation;
  else if (isError) classes = classesErrorAnimation;

  let darkThemeClass = "";
  if (darkTheme === true) {
    classes = classesDarkTheme;
    darkThemeClass = " darkTheme";
  }

  return mask ? (
    <InputMask
      mask={mask}
      maskChar="_"
      onChange={(e) => {
        e.persist();
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;

        if (onChange) {
          if (
            ifToUpperCaseShipsAndPorts &&
            settings["WebPortyStatkiWylaczWielkieLitery"]?.value !== "1"
          ) {
            e.target.value = e.target.value.toUpperCase();
          }
          onChange(e);
        }

        try {
          e.target.setSelectionRange(caretStart, caretEnd);
        } catch (_) {}
      }}
      onBlur={(e) => {
        if (onBlur) {
          if (
            ifToUpperCaseShipsAndPorts &&
            settings["WebPortyStatkiWylaczWielkieLitery"]?.value !== "1"
          ) {
            e.target.value = e.target.value.toUpperCase();
          }
          onBlur(e);
        }
      }}
      {...restprops}
    >
      {(maskProps) => (
        <TextField
          {...maskProps}
          variant="filled"
          size={"small"}
          className={classesHelperText.textField + darkThemeClass}
          hiddenLabel={hiddenLabel}
          InputProps={{
            disableUnderline: true,
            startAdornment: startAdornment,
            endAdornment: endAdornment,
            ...InputProps,
            classes,
          }}
          style={
            restprops?.width ? { width: restprops.width } : { width: "100%" }
          }
        />
      )}
    </InputMask>
  ) : (
    <TextField
      variant="filled"
      size={"small"}
      step={step}
      className={classesHelperText.textField + darkThemeClass}
      hiddenLabel={hiddenLabel}
      InputProps={{
        disableUnderline: true,
        startAdornment: startAdornment,
        endAdornment: endAdornment,
        ...InputProps,
        classes,
      }}
      onChange={(e) => {
        e.persist();
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;

        if (onChange) {
          if (
            ifToUpperCaseShipsAndPorts &&
            settings["WebPortyStatkiWylaczWielkieLitery"]?.value !== "1"
          ) {
            e.target.value = e.target.value.toUpperCase();
          }
          onChange(e);
        }

        try {
          e.target.setSelectionRange(caretStart, caretEnd);
        } catch (_) {}
      }}
      onBlur={(e) => {
        if (onBlur) {
          if (
            ifToUpperCaseShipsAndPorts &&
            settings["WebPortyStatkiWylaczWielkieLitery"]?.value !== "1"
          ) {
            e.target.value = e.target.value.toUpperCase();
          }
          onBlur(e);
        }
      }}
      style={restprops?.width ? { width: restprops.width } : { width: "100%" }}
      {...restprops}
    />
  );
}

export default TextFieldStyled;
