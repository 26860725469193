import { useTranslation } from "react-i18next";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../../../helpersComponents/RowDetailsHelpers";

const PresentationRowDetails: React.FC = ({ row }: any) => {
  const { t } = useTranslation();
  return (
    <RowDetailsContainer>
      <RowDetailsRow>
        <RowDetailsTitleValue>
          <RowDetailsTitle>{t("title")}:</RowDetailsTitle>
          <RowDetailsValue>{row?.title ? row.title : ""}</RowDetailsValue>
        </RowDetailsTitleValue>
        <RowDetailsTitleValue>
          <RowDetailsTitle>{t("minimum_display_time")}:</RowDetailsTitle>
          <RowDetailsValue>
            {row?.minTimeString ? row.minTimeString : "-"}
          </RowDetailsValue>
        </RowDetailsTitleValue>
      </RowDetailsRow>
      <RowDetailsRow>
        <RowDetailsTitleValue>
          <RowDetailsTitle>{t("order")}:</RowDetailsTitle>
          <RowDetailsValue>{row?.order ? row.order : ""}</RowDetailsValue>
        </RowDetailsTitleValue>
        <RowDetailsTitleValue>
          <RowDetailsTitle>{t("movie")}:</RowDetailsTitle>
          <RowDetailsValue>
            {row?.fileName ? (
              <>
                {row.fileName} {row.isMovieIcon}
              </>
            ) : (
              row.isMovieIcon
            )}
          </RowDetailsValue>
        </RowDetailsTitleValue>
      </RowDetailsRow>
      <RowDetailsRow>
        <RowDetailsTitleValue>
          <RowDetailsTitle>{t("description")}:</RowDetailsTitle>
          <RowDetailsValue>
            {row?.description ? row.description : ""}
          </RowDetailsValue>
        </RowDetailsTitleValue>
      </RowDetailsRow>
      <RowDetailsRow>
        <RowDetailsTitleValue>
          <RowDetailsTitle>{t("content")}:</RowDetailsTitle>
          <RowDetailsValue>{row?.content ? row.content : "-"}</RowDetailsValue>
        </RowDetailsTitleValue>
      </RowDetailsRow>
    </RowDetailsContainer>
  );
};

export default PresentationRowDetails;
