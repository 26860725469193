// import { withStyles } from '@material-ui/core/styles';
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { Dispatch, SetStateAction } from "react";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "35px",
  },
  icon: {
    "& .MuiAlert-icon": {
      fontSize: 30,
      alignItems: "center",
    },
  },
  action: {
    "& .MuiAlert-action": {
      // width: 50
    },
  },
}));

interface SnackbarInterface {
  snackbarState: [boolean, string, string];
  setSnackbarState: Dispatch<SetStateAction<any>>;
  autoHideDuration?: number;
  anchorOrigin?: SnackbarOrigin;
}

export default function SnackbarStyled({
  snackbarState,
  setSnackbarState,
  autoHideDuration,
  anchorOrigin,
}: SnackbarInterface) {
  const classes = useStyles();
  const handleClose = (event, reason) => {
    // return
    // if (reason === 'clickaway') {
    //     return;
    // }
    let SnackbarStateCopy = [...snackbarState];
    SnackbarStateCopy[0] = false;
    setSnackbarState(SnackbarStateCopy);
    // setSnackbarState([false, "error", ""]);
  };

  return (
    <div>
      <Snackbar
        open={snackbarState[0]}
        autoHideDuration={autoHideDuration ? autoHideDuration : 3000}
        onClose={handleClose}
        anchorOrigin={anchorOrigin ? anchorOrigin : undefined}
      >
        <Alert
          className={`${classes.root}  ${classes.icon}`}
          onClose={handleClose}
          severity={snackbarState[1]}
        >
          {snackbarState[2]}
        </Alert>
      </Snackbar>
    </div>
  );
}
