import React, { useEffect, useState } from "react";
import styles from "./scheduleInCalendar.module.scss";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import StyledBlock from "../../../helpersComponents/StyledBlock/styledBlock";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import Scheduler from "../../../helpersComponents/Scheduler/sheduler";
import { AppointmentModel } from "@devexpress/dx-react-scheduler";
import getDateRange from "../../../../HelpersFunctions/dateAndTime/getDateRange";
import convertDateFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import ButtonStyled from "../../../helpersComponents/Button/button";
import TooltipSchedule from "../../../helpersComponents/Scheduler/tooltipSchedule";
import { useAppSelector } from "../../../../store/hooks";
import { selectTimeWorkersSuperiorObject } from "../../../../reducers/workersLists";
import { getMinutesFromDatesHoursAndMinutes } from "../../../../HelpersFunctions/dateAndTime/getMinutesFromDatesHoursAndMinutes";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useUpdateLastSelectedWorkers from "../../../../hooks/useUpdateLastSelectedWorkers/useUpdateLastSelectedWorkers";

const ScheduleInCalendarContent = ({ ...props }) => {
  const { t } = useTranslation();

  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([]);
  const [allWorkers, setAllWorkers] = useState<any[]>([]);
  const [scheduleData, setScheduleData] = useState<AppointmentModel[]>([]);
  const [scheduleCurrentDate, setScheduleCurrentDate] = useState<Date>(
    new Date()
  );
  const [allWorkersSelected, setAllWorkersSelected] = useState<boolean>(false);
  const [currentViewName, setCurrentViewName] = useState<
    "Month" | "CurrentWeek" | "Day"
  >("Month");
  const [scheduleDataComponents, setScheduleDataComponents] = useState([]);
  const [startdate, setstartdate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [selectedWorkersString, setSelectedWorkersString] = useState<string>();
  const timeWorkersSuperiorObject = useAppSelector(
    selectTimeWorkersSuperiorObject
  );

  const [schedulesBody, setSchedulesBody] = useState<false | string>(false);

  const [fetchingState, fetchAgain] = useFetchOtherThanGET({
    path: "superior-time/schedules",
    method: "POST",
    contentType: "application/json",
    body: schedulesBody,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  const layoutSettingName =
    "layoutSuperiorTimeSummarySummariesOfManyEmployeesEmployees";

  const [updateLastSelectedWorkers] =
    useUpdateLastSelectedWorkers(selectedWorkers);

  useEffect(() => {
    let requestBodyObj = {
      dateFrom: startdate,
      dateTo: endDate,
      timeWorkersIds: allWorkersSelected
        ? []
        : selectedWorkersString
        ? selectedWorkersString.split(",").map((e) => parseInt(e))
        : [],
    };

    setSchedulesBody(JSON.stringify(requestBodyObj));
  }, [startdate, endDate, selectedWorkersString, allWorkersSelected]);

  useEffect(() => {
    let range = getDateRange(scheduleCurrentDate, currentViewName);
    if (!range) return;

    let computedData: Array<string> = [];
    computedData[0] = convertDateFetch(range.startDate);
    computedData[1] = convertDateFetch(range.endDate);
    setstartdate(computedData[0]);
    setEndDate(computedData[1]);
  }, [scheduleCurrentDate, currentViewName]);

  ///// forming data schedule recived from server
  useEffect(() => {
    if (fetchingState.isError || fetchingState.response === undefined) return;
    if (!Array.isArray(fetchingState.response.resJson)) return;
    let data = fetchingState.response.resJson;

    let scheduleDataLocal: AppointmentModel[] = [];
    let scheduleDataComponentsLocal: any = [];

    data.forEach((value, index) => {
      let startDate = new Date(value.day);
      let endDate = new Date(value.day);
      let startDateMinutes = getMinutesFromDatesHoursAndMinutes(
        new Date(value.dateFrom)
      );
      let endDateMinutes = getMinutesFromDatesHoursAndMinutes(
        new Date(value.dateTo)
      );
      startDate.setHours(startDateMinutes / 60);
      endDate.setHours(endDateMinutes / 60);

      let scheduleDataLocalSingle: AppointmentModel = {
        startDate: startDate,
        endDate: endDate,
        backgroundColor: "#0a95b8",
        workerId: value.workerId,
        day: value.day,
      };

      if (value.norm > 0) {
        scheduleDataLocal.push(scheduleDataLocalSingle);
      }
    });

    //////////// sorting formated data by date then by last name
    scheduleDataLocal.sort((a, b) => {
      if (new Date(b.startDate).getTime() !== new Date(a.startDate).getTime()) {
        return (
          new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
        );
      }
      return timeWorkersSuperiorObject[a.workerId]?.lastName.localeCompare(
        timeWorkersSuperiorObject[b.workerId]?.lastName
      );
    });

    /////////////  creating components / popups
    scheduleDataLocal.forEach((value, index) => {
      let startDateMinutes = getMinutesFromDatesHoursAndMinutes(
        new Date(value.startDate)
      );
      let endDateMinutes = getMinutesFromDatesHoursAndMinutes(
        new Date(value.endDate)
      );
      scheduleDataLocal[index]["id"] = index;
      scheduleDataComponentsLocal.push(
        <TooltipSchedule tooltipBody={<div>body</div>} key={index}>
          <div className={styles.singleElementBackground}>
            <div>
              {`${timeWorkersSuperiorObject[value.workerId]?.firstName} ${
                timeWorkersSuperiorObject[value.workerId]?.lastName
              }`}
            </div>
            <div>
              {convertMinsToHrsMins(startDateMinutes)} -{" "}
              {convertMinsToHrsMins(endDateMinutes)}
            </div>
          </div>
        </TooltipSchedule>
      );
    });

    setScheduleDataComponents(scheduleDataComponentsLocal);
    setScheduleData(scheduleDataLocal);
  }, [
    fetchingState.isError,
    timeWorkersSuperiorObject,
    fetchingState.response,
    t,
  ]);

  ///// confirm selected workers
  const confirmFilters = () => {
    if (allWorkers === undefined) return;
    let selectedWorkersLocal = [...selectedWorkers];
    if (selectedWorkers.length === 0) {
      allWorkers.forEach((worker) => {
        selectedWorkersLocal.push(worker.id);
      });
    }
    let selectedWorkersStringLocal: string = "";
    selectedWorkersLocal.forEach((selectedId) => {
      selectedWorkersStringLocal =
        selectedWorkersStringLocal + selectedId.toString() + ",";
    });
    selectedWorkersStringLocal = selectedWorkersStringLocal.replace(
      /,\s*$/,
      ""
    );
    setSelectedWorkersString(selectedWorkersStringLocal);
    updateLastSelectedWorkers();
  };

  //////////////////////// fetch schedules after creating workers ids string
  useEffect(() => {
    if (selectedWorkersString) {
      fetchAgain();
    }
  }, [selectedWorkersString, startdate, endDate, fetchAgain]);

  return (
    <>
      <TopBanner pathName={t("schedule_in_calendar")} />
      <div className="flexAndCenter">
        <div>
          <WorkerList
            layoutSettingName={layoutSettingName}
            setSelectedWorkers={setSelectedWorkers}
            selectedWorkers={selectedWorkers}
            setAllWorkers={setAllWorkers}
            setAllWorkersSelected={setAllWorkersSelected}
          />
        </div>
        <div>
          <ButtonStyled
            onClick={() => {
              confirmFilters();
            }}
          >
            {t("get_employees")}
          </ButtonStyled>
        </div>
        <div>
          <StyledBlock>
            <LoadingWrapper
              isLodadingProgress={fetchingState.isFetching}
              isError={fetchingState.isError}
              setIfFetchAgain={fetchAgain}
            >
              {!fetchingState.isFetching && !fetchingState.isError && (
                <Scheduler
                  data={scheduleData}
                  currentDate={scheduleCurrentDate}
                  setCurrentDate={setScheduleCurrentDate}
                  components={scheduleDataComponents}
                  currentViewName={currentViewName}
                  setCurrentViewName={setCurrentViewName}
                />
              )}
            </LoadingWrapper>
          </StyledBlock>
        </div>
      </div>
    </>
  );
};

export default ScheduleInCalendarContent;
