import { TFunction } from "react-i18next";
import * as Yup from "yup";
import { TypeOfVisit } from "../../../../Constants/typeOfVisitInPorts";

const getValidationSchema = (
  t: TFunction<"translation">,
  ifRegistrationNumber
) => {
  return Yup.object({
    companyName: Yup.string().required(t("field_required")),
    permissionZone: Yup.object().nullable().required(t("field_required")),
    typeOfVisit: Yup.object().nullable().required(t("field_required")),
    registrationNumber: ifRegistrationNumber
      ? Yup.string().required(t("field_required"))
      : Yup.string(),
    guestsArray: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required(t("field_required")),
        lastName: Yup.string().required(t("field_required")),
        idNumber: Yup.string().required(t("field_required")),
        cardNumber: Yup.string()
          .test("isRequired", t("field_required"), function (value) {
            if (this.parent.bh3 || (value && value.length > 0)) {
              return true;
            }
            return false;
          })
          .test("length", t("card_number_have_to_16_chars"), function (val) {
            if (this.parent.bh3) return true;

            const val_length_without_dashes = val?.replace(/-|_/g, "").length;
            return val_length_without_dashes === 16;
          }),
        dateOfBirth: Yup.date()
          .nullable()
          .test("isRequired", t("field_required"), function (value) {
            const { from } = this as Yup.TestContext & any;
            const typeOfVisit = from[1].value
              .typeOfVisit as TypeOfVisitInterface;

            if (
              typeOfVisit?.id === TypeOfVisit.COMPLETION_OF_THE_WORK &&
              this.parent.isTrainingNotRequired
            ) {
              return true;
            }

            if (
              typeOfVisit?.id === TypeOfVisit.COMPLETION_OF_THE_WORK &&
              !value &&
              !this.parent.email &&
              !this.parent.trainingExpiryDate
            ) {
              return false;
            }

            return true;
          })
          .test(
            "isValidBirthOfDate",
            `${t("date_must_be_earlier_than")} ${t(
              "today"
            ).toLocaleLowerCase()}`,
            function (value) {
              if (value) {
                let yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return Yup.object()
                  .nullable()
                  .shape({
                    date: Yup.date()
                      .typeError(t("invalid_date"))
                      .max(
                        yesterday,
                        `${t("date")} ${t(
                          `${t("date_must_be_earlier_than")} ${t(
                            "today"
                          ).toLocaleLowerCase()}`
                        )}`
                      ),
                  })
                  .isValid({ date: value });
              }
              return true;
            }
          ),
        email: Yup.string().test(
          "isRequired",
          t("field_required"),
          function (value) {
            const { from } = this as Yup.TestContext & any;
            const typeOfVisit = from[1].value
              .typeOfVisit as TypeOfVisitInterface;

            if (
              typeOfVisit?.id === TypeOfVisit.COMPLETION_OF_THE_WORK &&
              this.parent.isTrainingNotRequired
            ) {
              return true;
            }

            if (
              typeOfVisit?.id === TypeOfVisit.COMPLETION_OF_THE_WORK &&
              !value &&
              !this.parent.dateOfBirth &&
              !this.parent.trainingExpiryDate
            ) {
              return false;
            }

            return true;
          }
        ),
        trainingExpiryDate: Yup.date()
          .nullable()
          .test("isRequired", t("field_required"), function (value) {
            const { from } = this as Yup.TestContext & any;
            const typeOfVisit = from[1].value
              .typeOfVisit as TypeOfVisitInterface;
            if (
              typeOfVisit?.id === TypeOfVisit.COMPLETION_OF_THE_WORK &&
              !this.parent.isTrainingNotRequired &&
              !this.parent.email &&
              !this.parent.dateOfBirth
            ) {
              return !value ? false : true;
            }
            return true;
          })
          .test(
            "isValid",
            `${t("must_be_later_than")} ${t("yesterday").toLowerCase()}`,
            function (value) {
              const { from } = this as Yup.TestContext & any;
              const typeOfVisit = from[1].value
                .typeOfVisit as TypeOfVisitInterface;

              if (
                typeOfVisit?.id === TypeOfVisit.COMPLETION_OF_THE_WORK &&
                value &&
                !this.parent.isTrainingNotRequired
              ) {
                const today = new Date().setHours(0, 0, 0, 0);

                if (today > value.setHours(0, 0, 0, 0)) {
                  return false;
                }
              }
              return true;
            }
          ),
      })
    ),
  });
};

export default getValidationSchema;
