import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../../../helpersComponents/PopupHelpers/dialogActionsStyled";
import DialogStyled from "../../../../helpersComponents/PopupHelpers/dialogStyled";
import { DialogContentStyled } from "../../../../helpersComponents/PopupHelpers/dialogContentStyled";
import DialogTitleStyled from "../../../../helpersComponents/PopupHelpers/dialogTitleStyled";
import { useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AddEditObjectTypePopupContent from "./AddEditObjectTypePopupContent";
import { makeStyles } from "@material-ui/styles";

const AddEditObjectTypePopup: React.FC<any> = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const schema = Yup.object({
    name: Yup.string().required(t("field_required")),
    description: Yup.string().required(t("field_required")),
    states: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          name: Yup.string().required(t("field_required")),
          value: Yup.string().required(t("field_required")),
        })
      )
      .min(1, t("at_least_one_state_required")),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(schema), mode: "onBlur" });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "states",
  });

  const useStyles = makeStyles(() => ({
    dialog: {
      width: 600,
      minHeight: 400,
      margin: 10,
    },
  }));

  const classes = useStyles();

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      TransitionProps={{
        onExited: () => {
          reset({ states: [] });
        },
        onEnter: () => {
          setErrorMessage("");
        },
      }}
      classes={{ paper: classes.dialog }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitleStyled
        title={t("objects_types")}
        subtitle={rowId ? t("editing_object_type") : t("adding_object_type")}
      />

      <DialogContentStyled>
        <AddEditObjectTypePopupContent
          rowId={rowId}
          control={control}
          register={register}
          errors={errors}
          fields={fields}
          append={append}
          remove={remove}
          setValue={setValue}
          getValues={getValues}
          successCallback={successCallback}
          setErrorMessage={setErrorMessage}
          handleSubmit={handleSubmit}
          setIsFetching={setIsFetching}
          watch={watch}
        />
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onConfirmText={rowId ? t("confirm") : t("add")}
        onCancel={() => {
          closePopup();
        }}
        isLoading={isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default AddEditObjectTypePopup;
