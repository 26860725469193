import { ProfileType } from "../../../enums/profileType";
import ContentArrivalsOfShips from "../../helpersComponents/ArrivalsOfShips/ContentArrivalsOfShips";
import NavigationReception from "../Navigation/navigationReception";

const ArrivalsOfShipsReception = () => {
  return (
    <div>
      <ContentArrivalsOfShips
        pathApiArrivalsOfShips="reception/arrivals-of-ships"
        pathApiAttachmentsInPorts="reception/attachments-in-ports"
        layoutSettingsName="layoutReceptionArrivalsOfShips"
        hideRefreshingButton={true}
        navigationComponent={NavigationReception}
        hideActionsAndVisibilityColumns={true}
        profileType={ProfileType.RECEPTION}
      />
    </div>
  );
};

export default ArrivalsOfShipsReception;
