import { DialogContent } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../../../hooks/fetchHooks/useFetch/useFetch";
import { selectAuthUser } from "../../../../../reducers/session";
import { useAppSelector } from "../../../../../store/hooks";
import { TextFieldFormik } from "../../../../helpersComponents/FormikInputs";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../../../helpersComponents/PopupHelpers";
// import * as Yup from "yup";

interface Props {
  isOpen: boolean;
  projectId: number;
  setIsOpen: React.Dispatch<boolean>;
}

export default function CopyProjectPopup({
  isOpen,
  projectId,
  setIsOpen,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  let [setIsFetchingPostData, fetchingStatePostData, fetchAgainPostData] =
    useFetch({
      path: `worker-time/${authUserId}/projects/${projectId}/copy-project`,
      startFetchOnInitial: false,
      method: "POST",
      body: bodyRequest,
      contentType: "application/json",
    });

  useEffect(() => {
    if (fetchingStatePostData.response === undefined) return;
    if (fetchingStatePostData.isError) {
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
      setIsFetchingPostData(false);
      setBodyRequest(false);
      return;
    }
    enqueueSnackbar(t("success_action"), { variant: "success" });
    setBodyRequest(false);
    setIsFetchingPostData(false);
    setIsOpen(false);
  }, [
    fetchingStatePostData.isError,
    fetchingStatePostData.response,
    setIsFetchingPostData,
    t,
    enqueueSnackbar,
    setIsOpen,
  ]);

  useEffect(() => {
    if (bodyRequest !== false) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  const confirmForm = ({ projectName }: { projectName: string }) => {
    setBodyRequest(
      JSON.stringify({
        projectName: projectName,
      })
    );
  };

  return (
    <DialogStyled open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitleStyled title={t("copy_project")} />
      <DialogContent>
        <Formik
          initialValues={{
            projectName: "",
          }}
          onSubmit={(values) => {
            confirmForm({ projectName: values.projectName });
          }}
        >
          <Form noValidate id="copyProjectSubmit">
            <Row>
              <RowTitle>{t("project_name")}:</RowTitle>
              <RowInput>
                <TextFieldFormik
                  label={t("project_name")}
                  name="projectName"
                  type="text"
                />
              </RowInput>
            </Row>
          </Form>
        </Formik>
      </DialogContent>
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="copyProjectSubmit"
        isLoading={fetchingStatePostData.isFetching}
        onCancel={() => setIsOpen(false)}
      />
    </DialogStyled>
  );
}
