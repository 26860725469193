import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { colorClassNameDictionary } from "../ProfilesRouting/profilesDictionaries";
import styles from "./navbar.module.scss";

export const SingleLink = ({
  path,
  name,
  numberUnreadMessages = null,
}: any) => {
  const location = useLocation();

  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );
  const selectedRouteClassName = colorClassNameDictionary[
    authUser.currentProfile?.type
  ]
    ? colorClassNameDictionary[authUser.currentProfile.type]
    : styles.selectedRouteSetGreen;

  return (
    <div className={styles.linkContainerNavbar}>
      <div>
        {location.pathname === path && (
          <div className={styles.roundingContainer}>
            <div className={styles.roundingTop}></div>
          </div>
        )}
      </div>
      <div>
        <Link className={styles.singleLinkNavigation} to={path}>
          <div
            className={
              styles.navbarSingleLink +
              " " +
              (location.pathname === path && styles.navbarSingleLinkSelected)
            }
          >
            <div>
              <FontAwesomeIcon
                className={
                  "fa-xs " +
                  (location.pathname === path && selectedRouteClassName)
                }
                icon={faCircle}
              />
            </div>
            <div>{name}</div>
            {numberUnreadMessages !== 0 && numberUnreadMessages !== null ? (
              <div className={styles.messagesAlert}>
                <div>{numberUnreadMessages}</div>
              </div>
            ) : null}
          </div>
        </Link>
      </div>
      <div>
        {location.pathname === path && (
          <div className={styles.roundingContainer}>
            <div className={styles.roundingBottom}></div>
          </div>
        )}
      </div>
    </div>
  );
};
