import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchDelete from "../../../hooks/fetchHooks/delete/useFetchDelete";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import styles from "./projects.module.scss";

interface Props {
  setIfFetchAgain?: React.Dispatch<boolean>;
  path: string;
  confirmDialogText: string;
  snackbarSuccessText: string;
  OwnButton?: ReactNode;
  ifStopPropagation?: boolean;
  successCallBack?: () => void;
}

export default function RemoveComponent({
  setIfFetchAgain,
  path,
  confirmDialogText,
  snackbarSuccessText,
  OwnButton,
  ifStopPropagation = false,
  successCallBack,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [ifFetchDelete, setIfFetchDelete] = useState<boolean>(false);
  const responseStatusDelete = useFetchDelete(path, ifFetchDelete);

  const { confirm } = useConfirm();

  useEffect(() => {
    if (
      responseStatusDelete.data === 200 ||
      responseStatusDelete.data === 204
    ) {
      enqueueSnackbar(snackbarSuccessText, { variant: "success" });
      if (setIfFetchAgain) setIfFetchAgain(true);
      if (successCallBack) successCallBack();
    } else if (responseStatusDelete.data?.status === 400) {
      let error = "";
      Object.keys(responseStatusDelete.data.errors).forEach((errorObject) => {
        responseStatusDelete.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      enqueueSnackbar(error, { variant: "error" });
    } else if (responseStatusDelete.error !== null) {
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
    }
    setIfFetchDelete(false);
  }, [
    responseStatusDelete,
    snackbarSuccessText,
    setIfFetchAgain,
    enqueueSnackbar,
    successCallBack,
    t,
  ]);

  const deleteString: any = t("delete");
  return (
    <>
      <Tooltip title={deleteString}>
        <div
          onClick={async (e) => {
            if (ifStopPropagation) e.stopPropagation();
            let confirmResponse = await confirm({
              text: confirmDialogText,
              buttons: [
                { buttonName: t("delete"), buttonId: 0 },
                { buttonName: t("cancel"), buttonId: 1 },
              ],
            });
            if (confirmResponse === 0) setIfFetchDelete(true);
          }}
        >
          {OwnButton ? (
            <div>{OwnButton}</div>
          ) : (
            <div className={styles.removeComponentIcon}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </div>
          )}
        </div>
      </Tooltip>
    </>
  );
}
