import ContentPfsoCompanies from "./ContentPfsoCompanies";

function CompaniesPfso() {
  return (
    <div>
      <ContentPfsoCompanies />
    </div>
  );
}

export default CompaniesPfso;
