import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import SelectMultiMenu from "../../../../helpersComponents/SelectMultiMenu";
import { selectAuthUser } from "../../../../../reducers/session";
import { useAppSelector } from "../../../../../store/hooks";
import { ProfileType } from "../../../../../enums/profileType";

interface Props {
  rows: { [key in string]: any }[] | null;
  selectedRows: string[];

  setPopupAddHolidayRequestParams?: Dispatch<
    SetStateAction<IAddHolidayRequestPopupParams>
  >;

  setPopupAddHourRequestParams?: Dispatch<
    SetStateAction<IAddHourRequestPopupParams>
  >;

  setPopupCompleteAccordingToTheNormRequestParams?: Dispatch<
    SetStateAction<ICompleteAccordingToTheNormRequestPopupParams>
  >;

  setPopupAddCommentRequestParams?: Dispatch<
    SetStateAction<IAddCommentRequestPopupParams>
  >;

  btnLockSelectedDaysClick?: Dispatch<SetStateAction<number[]>>;
  btnUnlockSelectedDaysClick: Dispatch<SetStateAction<number[]>>;
  btnLockDayAndAllPreviousDaysClick: Dispatch<SetStateAction<number[]>>;
  btnUnlockDayAndAllNextDaysClick: Dispatch<SetStateAction<number[]>>;
  btnAcceptClick: Dispatch<SetStateAction<number[]>>;
  btnAcceptAndLockClick: Dispatch<SetStateAction<number[]>>;
}

const WorkersSelectMenu: React.FC<Props> = ({
  rows,
  selectedRows,
  setPopupAddHolidayRequestParams,
  setPopupAddHourRequestParams,
  setPopupCompleteAccordingToTheNormRequestParams,
  setPopupAddCommentRequestParams,
  btnLockSelectedDaysClick,
  btnUnlockSelectedDaysClick,
  btnLockDayAndAllPreviousDaysClick,
  btnUnlockDayAndAllNextDaysClick,
  btnAcceptClick,
  btnAcceptAndLockClick,
}) => {
  const { t } = useTranslation();
  const currentProfile = useAppSelector(selectAuthUser).currentProfile;

  const getDefaultDay = useCallback(() => {
    if (rows && rows.length > 0 && selectedRows && selectedRows.length > 0) {
      const rowId = selectedRows[0].split("_")[0];
      return rows[rowId].day;
    }

    return undefined;
  }, [rows, selectedRows]);

  const getSelectedRowsSummariesIds = useCallback(() => {
    let summariesIds: number[] = [];

    if (rows && rows.length > 0 && selectedRows && selectedRows.length > 0) {
      for (let i = 0; i < selectedRows.length; i++) {
        const rowIndex = selectedRows[i].split("_")[0];
        if (rows[rowIndex] && rows[rowIndex].summaryId) {
          summariesIds.push(rows[rowIndex].summaryId);
        }
      }
    }
    return summariesIds;
  }, [rows, selectedRows]);

  const getSelectedTimeWorkersIds = useCallback(() => {
    let timeWorkersIds: number[] = [];

    if (rows && rows.length > 0 && selectedRows && selectedRows.length > 0) {
      for (let i = 0; i < selectedRows.length; i++) {
        const rowIndex = selectedRows[i].split("_")[0];
        if (rows[rowIndex] && rows[rowIndex].summaryId) {
          timeWorkersIds.push(parseInt(rows[rowIndex].timeWorkerId));
        }
      }
    }

    return timeWorkersIds;
  }, [rows, selectedRows]);

  let conditionalLockingMenuOptions: any[] = [];

  if (currentProfile.type === ProfileType.SUPERIOR_TIME) {
    if (
      currentProfile.permissionId === 1 ||
      currentProfile.permissionId === 2
    ) {
      conditionalLockingMenuOptions.push({
        optionName: t("lock_selected_days"),
        onClick: () => {
          if (btnLockSelectedDaysClick) {
            btnLockSelectedDaysClick(getSelectedRowsSummariesIds());
          }
        },
        isDisabled: false,
      });

      conditionalLockingMenuOptions.push({
        optionName: t("unlock_selected_days"),
        onClick: () => {
          if (btnUnlockSelectedDaysClick) {
            btnUnlockSelectedDaysClick(getSelectedRowsSummariesIds());
          }
        },
        isDisabled: false,
      });

      conditionalLockingMenuOptions.push({
        optionName: t("lock_day_and_all_previous"),
        onClick: () => {
          if (btnLockDayAndAllPreviousDaysClick) {
            btnLockDayAndAllPreviousDaysClick(getSelectedRowsSummariesIds());
          }
        },
        isDisabled: false,
      });

      conditionalLockingMenuOptions.push({
        optionName: t("unlock_day_and_all_next"),
        onClick: () => {
          if (btnUnlockDayAndAllNextDaysClick) {
            btnUnlockDayAndAllNextDaysClick(getSelectedRowsSummariesIds());
          }
        },
        isDisabled: false,
      });

      conditionalLockingMenuOptions.push({
        optionName: t("accept_days"),
        onClick: () => {
          if (btnAcceptClick) {
            btnAcceptClick(getSelectedRowsSummariesIds());
          }
        },
        isDisabled: false,
      });

      conditionalLockingMenuOptions.push({
        optionName: t("accept_and_lock_days"),
        onClick: () => {
          if (btnAcceptAndLockClick) {
            btnAcceptAndLockClick(getSelectedRowsSummariesIds());
          }
        },
        isDisabled: false,
      });
    } else if (
      currentProfile.permissionId === 3 ||
      currentProfile.permissionId === 4
    ) {
      conditionalLockingMenuOptions.push({
        optionName: t("lock_selected_days"),
        onClick: () => {
          if (btnLockSelectedDaysClick) {
            btnLockSelectedDaysClick(getSelectedRowsSummariesIds());
          }
        },
        isDisabled: false,
      });

      conditionalLockingMenuOptions.push({
        optionName: t("lock_day_and_all_previous"),
        onClick: () => {
          if (btnLockDayAndAllPreviousDaysClick) {
            btnLockDayAndAllPreviousDaysClick(getSelectedRowsSummariesIds());
          }
        },
        isDisabled: false,
      });

      conditionalLockingMenuOptions.push({
        optionName: t("accept_days"),
        onClick: () => {
          if (btnAcceptClick) {
            btnAcceptClick(getSelectedRowsSummariesIds());
          }
        },
        isDisabled: false,
      });

      conditionalLockingMenuOptions.push({
        optionName: t("accept_and_lock_days"),
        onClick: () => {
          if (btnAcceptAndLockClick) {
            btnAcceptAndLockClick(getSelectedRowsSummariesIds());
          }
        },
        isDisabled: false,
      });
    } else if (
      currentProfile.permissionId === 5 ||
      currentProfile.permissionId === 6
    ) {
      conditionalLockingMenuOptions.push({
        optionName: t("accept_days"),
        onClick: () => {
          if (btnAcceptClick) {
            btnAcceptClick(getSelectedRowsSummariesIds());
          }
        },
        isDisabled: false,
      });
    }
  }

  return (
    <SelectMultiMenu
      name={<FontAwesomeIcon icon={faEllipsisH} color="white" />}
      disabled={selectedRows.length === 0}
      profile="superiorTime"
      position="right"
      items={[
        {
          optionName: t("add_holiday_request"),
          onClick: () => {
            if (setPopupAddHolidayRequestParams) {
              setPopupAddHolidayRequestParams({
                isOpen: true,
                timeWorkerId: 0,
                defaultDay: getDefaultDay(),
              });
            }
          },
          isDisabled: false,
        },
        {
          optionName: t("add_hour_request"),
          onClick: () => {
            if (setPopupAddHourRequestParams) {
              setPopupAddHourRequestParams({
                isOpen: true,
                timeWorkersIds: getSelectedTimeWorkersIds(),
                defaultDay: getDefaultDay(),
              });
            }
          },
          isDisabled: false,
        },
        {
          optionName: t("complete_according_to_the_standard"),
          onClick: () => {
            if (setPopupCompleteAccordingToTheNormRequestParams) {
              setPopupCompleteAccordingToTheNormRequestParams({
                isOpen: true,
                summariesIds: getSelectedRowsSummariesIds(),
              });
            }
          },
          isDisabled: false,
        },
        {
          optionName: t("add_comment"),
          onClick: () => {
            if (setPopupAddCommentRequestParams) {
              setPopupAddCommentRequestParams({
                isOpen: true,
                summariesIds: getSelectedRowsSummariesIds(),
              });
            }
          },
          isDisabled: false,
        },
        {
          optionName: t("locking_or_accepting_day"),
          children: conditionalLockingMenuOptions,
        },
      ]}
    />
  );
};

export default WorkersSelectMenu;
