import ArrivalsOfShipsReception from "../../reception/ArrivalsOfShips";
import FireReportReception from "../../reception/FireReport";
import NotificationReception from "../../reception/Notifications";
import ReplacementCardsReception from "../../reception/ReplacementCards";
import SupportReception from "../../reception/Support";
import UnreturnedCardsReception from "../../reception/UnreturnedCards";
import WhiteListGuestsReception from "../../reception/WhiteList/WhiteListGuestsReception";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";
import { faHome, faInfo } from "@fortawesome/free-solid-svg-icons";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const navLinksArrayPorter: navLinksArrayTimeWorkerInterface = {
    profileId: 6,
    routesArrayWithComponents: [
      {
        icon: faHome,
        name: t("home_page"),
        ifHidden: settings.WebUkryjUzytkownikKDStronaGlowna?.value === "1",
        singleLink: true,
        path: "/reception/home",
        component: (props) => <NotificationReception {...props} />,
      },
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/reception/profile",
        component: () => <Profile />,
      },
    ],
    hidenInNavBar: [
      {
        ifHidden: false,
        path: "/reception/unreturned-cards",
        component: () => <UnreturnedCardsReception />,
      },
      {
        ifHidden: false,
        path: "/reception/fire-report",
        component: () => <FireReportReception />,
      },
      {
        ifHidden: false,
        path: "/reception/white-list",
        component: () => <WhiteListGuestsReception />,
      },
      {
        ifHidden: false,
        path: "/reception/replacement-cards",
        component: () => <ReplacementCardsReception />,
      },
      {
        ifHidden: false,
        path: "/reception/arrival-of-ships",
        component: () => <ArrivalsOfShipsReception />,
      },
      {
        ifHidden: false,
        path: "/reception/lists-of-employees",
        component: () => <SupportReception />,
      },
    ],
  };

  return navLinksArrayPorter;
};
export default useNavLinksArray;
