import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { useAppSelector } from "../../../store/hooks";
import { ReceivedTrainings } from "./receivedTrainings";
import { selectAuthUser } from "../../../reducers/session";
import { TrainingsToReceive } from "./trainingsToReceive";
import styles from "./styles.module.scss";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import Training from "../../helpersComponents/Training/training";
import { useState } from "react";
import { TrainingPersonType } from "../../../Constants/trainingPersonType";

export const TrainingsList = () => {
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;

  const [
    receivedTrainings,
    fetchingStateReceivedTrainings,
    fetchAgainReceivedTrainings,
  ] = useFetchAndSetGET<ReceivedTraining[]>({
    path: `worker-time/${authUserId}/trainings/received-trainings`,
    startFetchOnInitial: true,
  });

  const [
    trainingsToReceive,
    fetchingStateTrainingsToReceive,
    fetchAgainTrainingsToReceive,
  ] = useFetchAndSetGET<TrainingToReceive[]>({
    path: `worker-time/${authUserId}/trainings/trainings-to-receive`,
    startFetchOnInitial: true,
  });

  const [typesOfVisits, fetchingStateTypesOfVisitst, fetchAgainTypesOfVisitst] =
    useFetchAndSetGET<TypeOfVisit[]>({
      path: `worker-time/${authUserId}/types-of-visits`,
    });

  const [training, setTraining] = useState<Training | null>(null);

  return (
    <>
      {!training && (
        <LoadingWrapper
          isError={
            fetchingStateReceivedTrainings.isError ||
            fetchingStateTrainingsToReceive.isError ||
            fetchingStateTypesOfVisitst.isError
          }
          isLodadingProgress={
            fetchingStateReceivedTrainings.isFetching ||
            fetchingStateTrainingsToReceive.isFetching ||
            fetchingStateTypesOfVisitst.isFetching
          }
          setIfFetchAgain={() => {
            if (fetchingStateReceivedTrainings.isError) {
              fetchAgainReceivedTrainings();
            }

            if (fetchingStateTrainingsToReceive.isError) {
              fetchAgainTrainingsToReceive();
            }

            if (fetchingStateTypesOfVisitst.isError) {
              fetchAgainTypesOfVisitst();
            }
          }}
        >
          <div className={styles.trainings_item}>
            <ReceivedTrainings
              receivedTrainings={receivedTrainings}
              setTraining={setTraining}
            />
          </div>
          <div className={styles.trainings_item}>
            <TrainingsToReceive
              trainingsToReceive={trainingsToReceive}
              typesOfVisits={typesOfVisits}
              setTraining={setTraining}
            />
          </div>
        </LoadingWrapper>
      )}

      {training && (
        <Training
          resultViewButtonClick={() => {
            setTraining(null);
            fetchAgainReceivedTrainings();
            fetchAgainTrainingsToReceive();
            fetchAgainTypesOfVisitst();
          }}
          backButtonVisible={true}
          backButtonClick={() => {
            setTraining(null);
            fetchAgainReceivedTrainings();
            fetchAgainTrainingsToReceive();
            fetchAgainTypesOfVisitst();
          }}
          training={training}
          trainingPersonType={TrainingPersonType.Employee}
        />
      )}
    </>
  );
};
