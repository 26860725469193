import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import { colorBackgroundClassNameDictionary } from "../../ProfilesRouting/profilesDictionaries";
import styles from "./attachments.module.scss";
import FilesListWithFetch from "./filesListWithFetch";

interface Props {
  title: string;
  path: string;
  files: { id: number; fileName: string; type?: string }[];
  blockDownload?: boolean;
}

const AttachmentsListInRowDetail = ({
  title,
  path,
  files,
  blockDownload,
}: Props) => {
  const authUser = useAppSelector(selectAuthUser);

  return (
    <>
      <div
        className={`${styles.detailsTableListRowFiles} ${
          colorBackgroundClassNameDictionary[authUser.currentProfile?.type]
        }`}
      >
        <div>
          <div>
            <b>{title}</b>
          </div>
        </div>
      </div>
      <div className={styles.tableRowFiles}>
        <FilesListWithFetch
          files={files}
          path={path}
          blockDownload={blockDownload}
        />
      </div>
    </>
  );
};

export default AttachmentsListInRowDetail;
