import {
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { CheckboxStyled } from "../MaterialUi";
import SelectStyled from "../MaterialUi/Select/select";
import styles from "./styles.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.2 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 7,
    horizontal: 0,
  },
};

const MultiSelect = ({
  label,
  label_id,
  items,
  selectedItems,
  setSelectedItems,
  textColor,
}: {
  label: string;
  label_id: string;
  items: MultiSelectItem[];
  selectedItems: number[];
  setSelectedItems: Dispatch<SetStateAction<number[]>>;
  textColor?: string;
}) => {
  const handleItemChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedItemsIds = event.target.value as number[];
    setSelectedItems(selectedItemsIds);
  };

  const getSelectedItemsName = (selectedItemsIds: number[]) => {
    let result = "";

    items.forEach((el) => {
      if (selectedItemsIds.includes(el.id)) {
        result += el.name + ",";
      }
    });

    if (result && result.length > 0) {
      result = result.substring(0, result.length - 1);
    }

    return result;
  };

  return (
    <div className={styles.multiSelectContainer}>
      <div className={styles.selectInputLabel}>
        <p>{label}</p>
      </div>
      <div className={styles.selectInput}>
        <FormControl variant="outlined">
          <SelectStyled
            labelId={label_id}
            value={selectedItems}
            onChange={handleItemChange}
            renderValue={(selectedItemsIds: number[]) =>
              getSelectedItemsName(selectedItemsIds)
            }
            MenuProps={MenuProps}
            textColor={textColor}
            multiple
          >
            <MenuItem data-button-all>
              <ListItemIcon>
                <CheckboxStyled
                  checked={
                    items.length > 0 && selectedItems.length === items.length
                  }
                  indeterminate={
                    selectedItems.length > 0 &&
                    selectedItems.length < items.length
                  }
                  onClick={(event) => {
                    event.stopPropagation();

                    if (
                      items.length > 0 &&
                      selectedItems.length === items.length
                    ) {
                      setSelectedItems([]);
                    } else {
                      setSelectedItems(items.map((i) => i.id));
                    }
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Select All"
                onClick={(event) => {
                  event.stopPropagation();

                  if (
                    items.length > 0 &&
                    selectedItems.length === items.length
                  ) {
                    setSelectedItems([]);
                  } else {
                    setSelectedItems(items.map((i) => i.id));
                  }
                }}
              />
            </MenuItem>

            {items.map((item: MultiSelectItem) => (
              <MenuItem key={item.id} value={item.id}>
                <CheckboxStyled
                  ifCircle={true}
                  checked={selectedItems.includes(item.id)}
                />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </SelectStyled>
        </FormControl>
      </div>
    </div>
  );
};

export default MultiSelect;
