import "./styledBlock.scss";
// import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  children: React.ReactNode;
  titleRightElement?: React.ReactElement;
}

const StyledBlock: React.FC<Props> = ({
  children,
  title,
  titleRightElement,
}) => {
  // const { t } = useTranslation();

  return (
    <div className="styledBlock">
      {!title ? null : (
        <div className="titleStyledBlockTableGlobal">
          <div>{title}</div>
          <div>{titleRightElement}</div>
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

export default StyledBlock;
