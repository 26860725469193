import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

const useRefreshData = (
  fetchAgain: () => void,
  initialRefreshingTime?: number
) => {
  const [refreshingTime, setRefreshingTime] = useState<{
    value: number | null;
    date: Date | null;
  } | null>({
    value: initialRefreshingTime ? initialRefreshingTime : null,
    date: null,
  });

  let intervalRef: MutableRefObject<
    undefined | ReturnType<typeof setInterval>
  > = useRef();

  const handleChangeRefreshingTime = (value: number | null) => {
    if (value === 0) {
      fetchAgain();

      if (
        refreshingTime !== null &&
        refreshingTime?.value !== null &&
        refreshingTime?.value !== 0
      ) {
        clearInterval(intervalRef.current!);
        intervalRef.current = setInterval(() => {
          fetchAgain();
        }, refreshingTime.value);
      }
    } else {
      let newValue = {
        value,
        date: new Date(),
      };
      setRefreshingTime(newValue);
    }
  };

  const refreshData = useCallback(() => {
    if (refreshingTime?.value === null) {
      clearInterval(intervalRef.current!);
    } else if (refreshingTime?.value !== 0 && refreshingTime?.value !== null) {
      intervalRef.current = setInterval(
        () => {
          fetchAgain();
        },
        refreshingTime?.value ? refreshingTime.value : 0
      );
    }
    return intervalRef.current;
  }, [fetchAgain, refreshingTime]);

  useEffect(() => {
    const interval = refreshData();

    return () => {
      clearInterval(interval!);
      if (intervalRef.current !== undefined) clearInterval(intervalRef.current);
    };
  }, [refreshData]);

  return { refreshingTime, handleChangeRefreshingTime, setRefreshingTime };
};

export default useRefreshData;
