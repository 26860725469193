import { DialogContentStyled } from "../PopupHelpers";
import FormTabs from "./FormTabs";

interface Props {
  rowId?: number;
  onSubmit: (values: any) => void;
  apiPaths: ApiPaths;
}

const PopupContentUsersManagement = ({ rowId, onSubmit, apiPaths }: Props) => {
  return (
    <>
      <DialogContentStyled>
        <FormTabs onSubmit={onSubmit} rowId={rowId} apiPaths={apiPaths} />
      </DialogContentStyled>
    </>
  );
};

export default PopupContentUsersManagement;
