import { useCallback, useEffect, useState } from "react";
import LoadingWrapper from "../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import Row from "../../../../helpersComponents/PopupHelpers/row";
import RowInput from "../../../../helpersComponents/PopupHelpers/rowInput";
import RowTitle from "../../../../helpersComponents/PopupHelpers/rowTitle";
import TwoColumnsRowsContainer from "../../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import erro400getTranslatedErrorString from "../../../../../HelpersFunctions/erro400getTranslatedErrorString";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { useTranslation } from "react-i18next";
//import { useAppSelector } from "../../../../../store/hooks";
//import { selectAuthUser } from "../../../../../reducers/session";
//import { selectSettings } from "../../../../../reducers/settings";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import UploadFileFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/UploadFileField/uploadFileField";

const AddEditSoundPopupContent: React.FC<any> = ({
  rowId,
  control,
  register,
  /*errors,
  fields,
  append,
  remove,*/
  setValue,
  getValues,
  setErrorMessage,
  successCallback,
  handleSubmit,
  setIsFetching,
  watch,
}) => {
  const { t } = useTranslation();
  //const authUserType = useAppSelector(selectAuthUser).currentProfile?.type;
  //const settings = useAppSelector(selectSettings);
  const [bodyRequest, setBodyRequest] = useState<any>(false);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
  }, [successCallback]);

  /*const soundModifyResponseCallback = useCallback((responseData) => {
    setBuildingsGroupsLoaded(true);
    return responseData;
  }, []);*/

  const [sound, fetchingStateSound, fetchAgainSound] = useFetchAndSetGET({
    path: `sms-administrator/sounds-library/${rowId}`,
    startFetchOnInitial: false,
    //modifyResponseCallback: buildingModifyResponseCallback,
  });

  useEffect(() => {
    if (rowId) {
      fetchAgainSound();
    }
  }, [rowId, fetchAgainSound]);

  const errorCallback = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setErrorMessage(error);
      }
    },
    [t, setErrorMessage]
  );

  const [fetchingStateAddEditSound, fetchAgainAddEditSound] =
    useFetchOtherThanGET({
      path: `sms-administrator/sounds-library${rowId ? "/" + rowId : ""}`,
      method: rowId ? "PUT" : "POST",
      body: bodyRequest,
      setBody: setBodyRequest,
      successCallback: successCallbackAndClose,
      errorCallback,
      disableErrorSnackbar: true,
    });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainAddEditSound();
    }
  }, [bodyRequest, fetchAgainAddEditSound]);

  useEffect(() => {
    setIsFetching(fetchingStateAddEditSound.isFetching);
  }, [fetchingStateAddEditSound, setIsFetching]);

  const onSubmit = (data: any) => {
    let jsonDataToSend: any = {
      name: data.name,
      description: data.description,
    };

    let formData = new FormData();
    formData.append("requestSoundData", JSON.stringify(jsonDataToSend));

    if (data.sound) {
      formData.append("requestSound", data.sound[0]);
    }

    setBodyRequest(formData);
  };

  useEffect(() => {
    if (
      rowId &&
      !fetchingStateSound.isFetching &&
      fetchingStateSound.response?.status === 200
    ) {
      setValue("name", sound.name);
      setValue("description", sound.description);

      if (sound.fileName && sound.fileSize > 0) {
        setValue("sound", [
          {
            name: sound.fileName,
            size: sound.fileSize,
          },
        ]);
      }
    }
  }, [
    fetchingStateSound.isFetching,
    fetchingStateSound.response?.status,
    rowId,
    setValue,
    sound?.name,
    sound?.description,
    sound?.fileName,
    sound?.fileSize,
  ]);

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={fetchingStateSound.isFetching}
        isError={fetchingStateSound.isError}
        setIfFetchAgain={() => {
          if (fetchingStateSound.isError) {
            fetchAgainSound();
          }
        }}
      >
        <form
          key={"add_building_form"}
          onSubmit={handleSubmit(onSubmit)}
          id="submitForm"
          noValidate
        >
          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("name")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"name"}
                  control={control}
                  label={t("sound_name")}
                  required={true}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("description")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"description"}
                  control={control}
                  label={t("sound_description")}
                  required={true}
                  multiline
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("file")}:</RowTitle>
              <RowInput>
                <UploadFileFieldReactHookForm
                  id="sound"
                  register={register}
                  name={"sound"}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  required={true}
                  accept=".mp3, .wav"
                  watch={watch}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>
        </form>
      </LoadingWrapper>
    </>
  );
};

export default AddEditSoundPopupContent;
