import { CircularProgress } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../../../helpersComponents/Button/button";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useUpdateLastSelectedWorkers from "../../../../hooks/useUpdateLastSelectedWorkers/useUpdateLastSelectedWorkers";
import AutocompleteStyled from "../../../helpersComponents/MaterialUi/Autocomplete/autocomplete";
import styles from "./OvertimesSummaryContent.module.scss";
import OvertimesSummaryChart from "./OvertimesSummaryChart";
import { OvertimesSummaryChartDisplayMode } from "../../../../enums/OvertimesSummaryChartDisplayMode";

function OvertimesSummaryContent() {
  const { t } = useTranslation();
  const layoutSettingName = "layoutSuperiorTimeAnalysisOvertimesSummary";

  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([]);
  const [allWorkersSelected, setAllWorkersSelected] = useState<boolean>(false);
  const [allWorkers, setAllWorkers] = useState<any[]>([]);

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Year")
  );

  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Day"));

  const [selectedWorkersString, setSelectedWorkersString] = useState<
    string | undefined
  >();

  const [overtimesSummaryRows, setOvertimesSummaryRows] = useState<
    IOvertimesSummaryItem[]
  >([]);

  const modifyResponseCallback = useCallback((responseData) => {
    if (responseData && responseData.resJson) {
      setOvertimesSummaryRows(responseData.resJson);
      return responseData.resJson;
    }

    return [];
  }, []);

  const [overtimesSummaryBody, setOvertimesSummaryBody] = useState<
    false | string
  >(false);

  const [fetchingStateOvertimesSummary, fetchAgainOvertimesSummary] =
    useFetchOtherThanGET({
      path: "superior-time/overtimes-summary",
      method: "POST",
      contentType: "application/json",
      body: overtimesSummaryBody,
      setBody: setOvertimesSummaryBody,
      disableErrorSnackbar: true,
      disableSuccessSnackbar: true,
      successCallback: modifyResponseCallback,
    });

  useEffect(() => {
    let requestBodyObj = {
      dateFrom: new Date(startDate),
      dateTo: new Date(endDate),
      timeWorkersIds: allWorkersSelected
        ? []
        : selectedWorkersString
        ? selectedWorkersString.split(",").map((e) => parseInt(e))
        : [],
    };

    setOvertimesSummaryBody(JSON.stringify(requestBodyObj));
  }, [startDate, endDate, allWorkersSelected, selectedWorkersString]);

  useEffect(() => {
    if (startDate && endDate && selectedWorkersString) {
      fetchAgainOvertimesSummary();
    }
  }, [startDate, endDate, selectedWorkersString, fetchAgainOvertimesSummary]);

  const [updateLastSelectedWorkers] =
    useUpdateLastSelectedWorkers(selectedWorkers);

  const confirmFilters = () => {
    if (allWorkers === undefined) return;
    let selectedWorkersLocal = [...selectedWorkers];

    let selectedWorkersStringLocal: string = "";
    selectedWorkersLocal.forEach((selectedId) => {
      selectedWorkersStringLocal =
        selectedWorkersStringLocal + selectedId.toString() + ",";
    });
    selectedWorkersStringLocal = selectedWorkersStringLocal.replace(
      /,\s*$/,
      ""
    );
    setSelectedWorkersString(selectedWorkersStringLocal);
    updateLastSelectedWorkers();
  };

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {fetchingStateOvertimesSummary.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <ButtonStyled
            onClick={() => {
              confirmFilters();
            }}
          >
            {t("get")}
          </ButtonStyled>
        )}
      </div>
      <span className="errorMessaggeSummarySuperior">
        {fetchingStateOvertimesSummary.isError && t("something_went_wrong")}
      </span>
    </>
  );

  const [
    selectedOvertimesSummaryChartMode,
    setSelectedOvertimesSummaryChartMode,
  ] = useState<IOvertimesSummaryChartMode | null>(null);

  const overtimesSummaryChartModes: any[] = [
    {
      id: OvertimesSummaryChartDisplayMode.SUMMARY,
      name: t("ovetimes_summary_chart_display_mode_summary"),
    },
    {
      id: OvertimesSummaryChartDisplayMode.SUMMARY_PER_WORKER,
      name: t("ovetimes_summary_chart_display_mode_summary_per_worker"),
    },
    {
      id: OvertimesSummaryChartDisplayMode.DAILY,
      name: t("ovetimes_summary_chart_display_mode_daily"),
    },
    {
      id: OvertimesSummaryChartDisplayMode.WEEKLY,
      name: t("ovetimes_summary_chart_display_mode_weekly"),
    },
    {
      id: OvertimesSummaryChartDisplayMode.MONTHLY,
      name: t("ovetimes_summary_chart_display_mode_monthly"),
    },
  ];

  return (
    <>
      <TopBanner pathName={t("overtimes_summary")} />
      <div className="flexAndCenter">
        <div>
          <WorkerList
            layoutSettingName={layoutSettingName}
            setSelectedWorkers={setSelectedWorkers}
            selectedWorkers={selectedWorkers}
            setAllWorkers={setAllWorkers}
            setAllWorkersSelected={setAllWorkersSelected}
          />
        </div>
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
            confirmButtonRight={confirmButtonRight}
          />
        </div>

        {overtimesSummaryRows && overtimesSummaryRows.length > 0 && (
          <div className={styles.chartContainer}>
            <div className={styles.displayModeContainer}>
              <AutocompleteStyled
                darkTheme={true}
                id="combo-box-demo1"
                options={overtimesSummaryChartModes}
                getOptionLabel={(option: any) => option.name}
                width={"300px"}
                getOptionSelected={(option, value) => {
                  return option.id === value.id;
                }}
                value={
                  selectedOvertimesSummaryChartMode
                    ? selectedOvertimesSummaryChartMode
                    : null
                }
                onChange={(_, newValue) => {
                  setSelectedOvertimesSummaryChartMode(newValue);
                }}
                label={t("display_mode")}
                required={true}
              />
            </div>
            <div className={styles.chart}>
              {selectedOvertimesSummaryChartMode && (
                <OvertimesSummaryChart
                  data={[...overtimesSummaryRows]}
                  displayMode={selectedOvertimesSummaryChartMode.id}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OvertimesSummaryContent;
