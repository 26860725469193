import { ProfileType } from "../../../enums/profileType";
import ContentBlackListGuests from "../../helpersComponents/BlackList/BlackListGuests/ContentBlackListGuests";

const BlackListGuestsPfso = () => {
  return (
    <ContentBlackListGuests
      pathBlackListGuests="pfso/black-list-guests"
      pathGuests="pfso/guests"
      profileType={ProfileType.PFSO}
      layoutSettingName="layoutPfsoBlackListGuestsRecords"
    />
  );
};
export default BlackListGuestsPfso;
