import CompanyStructureContent from "../../helpersComponents/CompanyStructure/CompanyStructureContent";

const CompanyStructureSuperiorAC = () => {
  return (
    <CompanyStructureContent
      companiesPathAPI="superior-ac/ac-companies?isActive=true"
      companyStructurePathAPI="superior-ac/company-structure"
    />
  );
};

export default CompanyStructureSuperiorAC;
