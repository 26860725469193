import { ProfileType } from "../../../enums/profileType";
import ContentUnreturnedCards from "../../helpersComponents/UnreturnedCards/ContentUnreturnedCards";

const UnreturnedCardsReception = () => {
  return (
    <div>
      <ContentUnreturnedCards
        pathApi="reception/unreturned-cards"
        profileType={ProfileType.RECEPTION}
        layoutSettingName="layoutReceptionUnreturnedCards"
        pathApiEndVisit="reception/guest-visits"
      />
    </div>
  );
};

export default UnreturnedCardsReception;
