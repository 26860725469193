import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypeOfVisit } from "../../../Constants/typeOfVisitInPorts";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../PopupHelpers";
import FeedbackDialog from "./FeedbackDialog";
import PopupDialogContent from "./poupDialogContent";

interface IProps {
  onCancelChanges: () => void;
  open: boolean;
  successCallback: () => void;
  pathApi: string;
  ifRegistrationNumber: boolean;
  permissionZonesPathApi: string;
  typesOfVisitPathApi: string;
  checkGuestsOnBlackListPathApi: string;
}

const PopupEntryUnauthorized: React.FC<IProps> = ({
  onCancelChanges,
  open,
  successCallback,
  pathApi,
  ifRegistrationNumber,
  permissionZonesPathApi,
  typesOfVisitPathApi,
  checkGuestsOnBlackListPathApi,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [feedbackData, setFeedbackData] = useState<IVisitReponse[] | null>(
    null
  );
  const successCallbackRef: any = useRef();

  const onSubmit = (values: PopupEntryUnauthorizedFormValues) => {
    setErrorMessage("");

    let dataToSend: any = {
      typeOfVisitId: values.typeOfVisit?.id ? values.typeOfVisit.id : -1,
      guestsToCheck: [],
    };

    for (let i = 0; i < values.guestsArray.length; i++) {
      dataToSend.guestsToCheck.push({
        firstName: values.guestsArray[i].firstName,
        lastName: values.guestsArray[i].lastName,
        dateOfBirth: values.guestsArray[i].dateOfBirth,
        email: values.guestsArray[i].email,
      });
    }

    let formData = new FormData();
    formData.append("requestData", JSON.stringify(dataToSend));

    const successCallback = () => {
      let guests = values.guestsArray.map((guest) => ({
        firstName: guest.firstName,
        lastName: guest.lastName,
        dateOfBirth: guest.dateOfBirth,
        email: guest.email,
        idNumber: guest.idNumber,
        cardNumber: guest.cardNumber,
        isTrainingNotRequired:
          values.typeOfVisit?.id !== TypeOfVisit.COMPLETION_OF_THE_WORK
            ? null
            : guest.isTrainingNotRequired,
        trainingExpiryDate:
          guest.isTrainingNotRequired === true
            ? null
            : guest.trainingExpiryDate,
        bh3: guest.bh3,
      }));

      let body = {
        guests: guests,
        comments: values.comments,
        companyName: values.companyName,
        permissionZoneId: values.permissionZone?.id,
        typeOfVisitId: values.typeOfVisit?.id,
        registrationNumber: values.registrationNumber,
      };

      setBodyRequest(JSON.stringify(body));
    };

    successCallbackRef.current = successCallback;
    setBodyCheckGuestsOnBlackListRequest(formData);
  };

  const successFeedbackCallback = useCallback((response: UseFetchResponse) => {
    setFeedbackData(response.resJson);
  }, []);

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: pathApi,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successFeedbackCallback,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  useEffect(() => {
    if (open === false) {
      setErrorMessage("");
    }
  }, [open]);

  const [guestsOnBlacklist, setGuestsOnBlacklist] = useState<any[]>();
  const [
    bodyCheckGuestsOnBlackListRequest,
    setBodyCheckGuestsOnBlackListRequest,
  ] = useState<any>(false);

  let checkGuestsOnBlackListSuccessCallback = useCallback(
    (response: UseFetchResponse) => {
      let tempGuests: any[] = [];
      let tempGuestsInImportedFile: any[] = [];

      if (response.resJson) {
        if (response.resJson.guests) {
          for (let i = 0; i < response.resJson.guests.length; i++) {
            tempGuests.push({
              firstName: response.resJson.guests[i].firstName,
              lastName: response.resJson.guests[i].lastName,
              dateOfBirth: response.resJson.guests[i].dateOfBirth,
              email: response.resJson.guests[i].email,
            });
          }
        }

        if (response.resJson.importedGuests) {
          for (let i = 0; i < response.resJson.importedGuests.length; i++) {
            tempGuestsInImportedFile.push({
              firstName: response.resJson.importedGuests[i].firstName,
              lastName: response.resJson.importedGuests[i].lastName,
              dateOfBirth: response.resJson.importedGuests[i].dateOfBirth,
              email: response.resJson.importedGuests[i].email,
            });
          }
        }
      }

      setGuestsOnBlacklist(tempGuests);

      if (tempGuests.length === 0 && tempGuestsInImportedFile.length === 0) {
        if (successCallbackRef.current) {
          successCallbackRef.current();
        }
      }

      successCallbackRef.current = undefined;
    },
    []
  );

  const [, fetchAgainCheckGuestsOnBlackList] = useFetchOtherThanGET({
    path: checkGuestsOnBlackListPathApi,
    method: "POST",
    body: bodyCheckGuestsOnBlackListRequest,
    setBody: setBodyCheckGuestsOnBlackListRequest,
    successCallback: checkGuestsOnBlackListSuccessCallback,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (bodyCheckGuestsOnBlackListRequest) {
      fetchAgainCheckGuestsOnBlackList();
    }
  }, [bodyCheckGuestsOnBlackListRequest, fetchAgainCheckGuestsOnBlackList]);

  return (
    <>
      <div>
        <FeedbackDialog
          feedbackData={feedbackData}
          setFeedbackData={setFeedbackData}
          closeCallback={successCallback}
        />
      </div>

      <div>
        <DialogStyled
          open={open}
          onClose={onCancelChanges}
          disableBackdropClick={true}
        >
          <DialogTitleStyled title={t("adding_unauthorized_entry")} />
          <PopupDialogContent
            onSubmit={onSubmit}
            isPopupOpen={open}
            ifRegistrationNumber={ifRegistrationNumber}
            permissionZonesPathApi={permissionZonesPathApi}
            typesOfVisitPathApi={typesOfVisitPathApi}
            guestsOnBlacklist={guestsOnBlacklist}
          />
          <DialogActionsStyled
            typConfirm="submit"
            formConfirm="submitForm"
            onCancel={onCancelChanges}
            isLoading={fetchingStatePostData.isFetching}
            errorString={errorMessage}
          />
        </DialogStyled>
      </div>
    </>
  );
};

export default PopupEntryUnauthorized;
