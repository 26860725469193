import { TFunction } from "react-i18next";

export function getMonthName(month, t: TFunction<"translation">): string {
  switch (month) {
    case 0:
      return t("january");

    case 1:
      return t("february");

    case 2:
      return t("march");

    case 3:
      return t("april");

    case 4:
      return t("may");

    case 5:
      return t("june");

    case 6:
      return t("july");

    case 7:
      return t("august");

    case 8:
      return t("september");

    case 9:
      return t("october");

    case 10:
      return t("november");

    case 11:
      return t("december");

    default:
      return "";
  }
}

export function getMonthIndex(monthName: string): number {
  switch (monthName) {
    case "january":
      return 0;

    case "february":
      return 1;

    case "march":
      return 2;

    case "april":
      return 3;

    case "may":
      return 4;

    case "june":
      return 5;

    case "july":
      return 6;

    case "august":
      return 7;

    case "september":
      return 8;

    case "october":
      return 9;

    case "november":
      return 10;

    case "december":
      return 11;

    default:
      return 0;
  }
}

export function getArrayOfMonths(): string[] {
  const columns = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  return columns;
}
