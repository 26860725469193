import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../../store/hooks";
import { selectAdvancedOptions } from "../../../../../../reducers/advancedOptions";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../../../helpersComponents/PopupHelpers";
import PopupContentSetTime from "./PopupContentSetTime";
import { timeStringToMinutes } from "../../../../../../HelpersFunctions/dateAndTime/timeStrings";

const PopupSetTime = ({
  isOpen,
  closePopup,
  confirm,
}: {
  isOpen: boolean;
  closePopup: () => void;
  confirm: (time: string) => void;
}) => {
  const { t } = useTranslation();
  const advancedOptions = useAppSelector(selectAdvancedOptions);
  const [time, setTime] = useState<string>("08:00-16:00");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (isOpen === false) {
      setErrorMessage("");
    }
  }, [isOpen]);

  const validateForm = () => {
    let validationStatus = true;

    const timeParts = time.split("-");
    if (timeParts && timeParts.length === 2) {
      if (timeParts[0].length === 5 && timeParts[1].length === 5) {
        let timeFrom = timeStringToMinutes(timeParts[0]);
        let timeTo = timeStringToMinutes(timeParts[1]);

        let timeDiffInHours = timeTo - timeFrom;
        if (timeFrom > timeTo) {
          timeDiffInHours = timeTo + 24 * 60 - timeFrom;
        }

        if (
          timeDiffInHours > 12 * 60 &&
          advancedOptions.WylaczLimit12Godzin?.value !== "1"
        ) {
          setErrorMessage(t("exceeded_limit_twelve_hours"));
          validationStatus = false;
        }
      } else {
        setErrorMessage(t("invalid_value_of_time"));
        validationStatus = false;
      }
    }

    return validationStatus;
  };

  return (
    <DialogStyled open={isOpen} onClose={closePopup}>
      <DialogTitleStyled title={t("set_time")} />
      <PopupContentSetTime onChangeTime={setTime} />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        onConfirm={() => {
          if (validateForm()) {
            confirm(time);
            closePopup();
          }
        }}
        isLoading={false}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupSetTime;
