import React from "react";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";
import AdvancedNavbar from "../../AdvancedNavbar/AdvancedNavbar";
import useSMSUserMenuBuilder from "../../../hooks/sms/useSMSUserMenuBuilder/useSMSUserMenuBuilder";

const Navigation = () => {
  const settings = useAppSelector(selectSettings);
  const { menuData } = useSMSUserMenuBuilder();

  if (!settings.ifSettingsFetched || !menuData || menuData.length === 0)
    return <div></div>;

  return <AdvancedNavbar menuData={menuData} />;
};
export default Navigation;
