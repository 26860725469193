import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";
import {
  faBook,
  faBuilding,
  faHome,
  faInfo,
  faMap,
  faObjectGroup,
} from "@fortawesome/free-solid-svg-icons";
import HomePageSMSAdministrator from "../../smsAdministrator/Home";
import BuildingsGroupsSMSAdministrator from "../../smsAdministrator/BuildingsGroups";
import BuildingsSMSAdministrator from "../../smsAdministrator/Buildings";
import ObjectsTypesLibrarySMSAdministrator from "../../smsAdministrator/Libraries/ObjectsTypesLibrary";
import SoundsLibrarySMSAdministrator from "../../smsAdministrator/Libraries/SoundsLibrary";
import MapsArrangmentSMSAdministrator from "../../smsAdministrator/MapsArrangement";
import ImagesLibrarySMSAdministrator from "../../smsAdministrator/Libraries/ImagesLibrary";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const navLinksArraySMSAdministrator: navLinksArrayTimeWorkerInterface = {
    profileId: 17,
    routesArrayWithComponents: [
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/sms-administrator/profile",
        component: () => <Profile />,
      },
      {
        icon: faHome,
        name: t("home_page"),
        ifHidden: false,
        singleLink: true,
        path: "/sms-administrator/home",
        component: (props) => <HomePageSMSAdministrator {...props} />,
      },
      {
        icon: faObjectGroup,
        name: t("buildings_groups"),
        ifHidden: settings.WebSMSAdministratorUkryjGrupyBudynkow?.value === "1",
        singleLink: true,
        path: "/sms-administrator/buildings-groups",
        component: (props) => <BuildingsGroupsSMSAdministrator {...props} />,
      },
      {
        icon: faBuilding,
        name: t("buildings"),
        ifHidden: settings.WebSMSAdministratorUkryjBudynki?.value === "1",
        singleLink: true,
        path: "/sms-administrator/buildings",
        component: (props) => <BuildingsSMSAdministrator {...props} />,
      },

      {
        icon: faMap,
        name: t("maps_arrangement"),
        ifHidden: settings.WebSMSAdministratorUkryjAranzacjeMap?.value === "1",
        singleLink: true,
        path: "/sms-administrator/maps-arrangement",
        component: (props) => <MapsArrangmentSMSAdministrator {...props} />,
      },
      {
        icon: faBook,
        name: t("libraries"),
        ifHidden: settings.WebSMSAdministratorUkryjBiblioteki?.value === "1",
        singleLink: false,
        path: [
          {
            name: t("objects_types"),
            path: "/sms-administrator/objects-types-library",
            ifHidden:
              settings.WebSMSAdministratorUkryjBibliotekeTypowObiektow
                ?.value === "1",
            component: () => <ObjectsTypesLibrarySMSAdministrator />,
          },
          {
            name: t("sounds"),
            path: "/sms-administrator/sounds-library",
            ifHidden:
              settings.WebSMSAdministratorUkryjBibliotekeDzwiekow?.value ===
              "1",
            component: () => <SoundsLibrarySMSAdministrator />,
          },
          {
            name: t("images"),
            path: "/sms-administrator/images-library",
            ifHidden:
              settings.WebSMSAdministratorUkryjBibliotekeObrazow?.value === "1",
            component: () => <ImagesLibrarySMSAdministrator />,
          },
        ],
      },
    ],
  };

  return navLinksArraySMSAdministrator;
};

export default useNavLinksArray;
