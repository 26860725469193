import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../helpersComponents/PopupHelpers";
import PopupContentEvents from "./PopupContentEvents";

const PopupEvents: React.FC = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
}: any) => {
  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [requestBody, setRequestBody] = useState<false | any>(false);

  const onSubmit = (values) => {
    setErrorMessage("");
    let body;
    if (!rowId) {
      body = JSON.stringify({
        acWorkerId: values.worker.id,
        time: values.eventDate,
        typeId: values.eventTypeSuperior.id,
        roomId: values.roomSuperior.id,
        addSector: values.currentEvent,
      });
    } else {
      body = JSON.stringify({
        acWorkerId: values.worker.id,
        time: values.eventDate,
        typeId: values.eventTypeSuperior.id,
        roomId: values.roomSuperior.id,
      });
    }

    setRequestBody(body);
  };

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `superior-ac/events${rowId !== undefined ? `/${rowId}` : ""}`,
    method: rowId !== undefined ? "PUT" : "POST",
    body: requestBody,
    contentType: "application/json",
    setBody: setRequestBody,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, fetchAgainPostData]);

  return (
    <DialogStyled open={isOpen} onClose={closePopup}>
      <DialogTitleStyled title={t("adding_event")} />
      <PopupContentEvents rowId={rowId} onSubmit={onSubmit} />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupEvents;
