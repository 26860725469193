import React, { ReactElement } from "react";
import { TextFieldStyled } from "../../MaterialUi";
import { Controller } from "react-hook-form";

export default function TextFieldReactHookForm({
  name,
  label,
  showTopLabel,
  control,
  ...props
}: any): ReactElement {
  return (
    <div>
      {showTopLabel && (
        <div>
          <b>{label}:</b>
        </div>
      )}

      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
          formState,
        }) => (
          <TextFieldStyled
            helperText={error ? error.message : null}
            label={label}
            {...props}
            value={value}
            isError={!!error}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />
    </div>
  );
}
