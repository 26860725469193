import { useTranslation } from "react-i18next";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import styles from "./objectTypeStateItem.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const ObjectTypeStateItem: React.FC<any> = ({
  key,
  fields,
  index,
  control,
  register,
  setValue,
  remove,
  errors,
  getValues,
  watch,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.objectTypeStatesItemTitle}>
        <p>
          {`${t("state")} ${index + 1}`}
          <FontAwesomeIcon
            className="faTrash"
            icon={faTimesCircle}
            onClick={() => {
              remove(index);
            }}
          />
        </p>
      </div>
      <div className={styles["form-grid"]}>
        <div className={styles["form-grid-row-item"]}>
          <TextFieldReactHookForm
            id={`states.${index}.name`}
            name={`states.${index}.name`}
            control={control}
            label={t("name")}
            required={true}
          />
        </div>

        <div className={styles["form-grid-row-item"]}>
          <TextFieldReactHookForm
            id={`states.${index}.value`}
            name={`states.${index}.value`}
            type="number"
            control={control}
            label={t("value")}
            required={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ObjectTypeStateItem;
