import React from 'react';
import EquipmentContent from "./equipmentContent"


function Equipment() {
    return (
        <div>
            <EquipmentContent/>
        </div>
    );
}

export default Equipment;
