import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArrayRenderProps } from "formik";
import { useTranslation } from "react-i18next";
import { DatePickerFormik, TextFieldFormik } from "../../FormikInputs";
import CheckboxWithLabelFormik from "../../FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import {
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../PopupHelpers";
import Row from "../../PopupHelpers/row";
import styles from "./styles.module.scss";

const RegistrationNumberForm = ({
  index,
  arrayHelpers,
  values,
}: {
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  values: any;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.registrationNumberTitle}>
        <b>
          {t("plate_number")} {index + 1}
        </b>
        <FontAwesomeIcon
          className={styles.removeIcon}
          icon={faTimesCircle}
          onClick={() => {
            arrayHelpers.remove(index);
          }}
        />
      </div>

      <Row>
        <RowTitle>{t("plate_number")}:</RowTitle>
        <RowInput>
          <TextFieldFormik
            label={t("plate_number")}
            name={`registrationNumbers.${index}.registrationNumber`}
            type="text"
            ifToUpperCaseShipsAndPorts={true}
          />
        </RowInput>
      </Row>

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle>
            <CheckboxWithLabelFormik
              name={`registrationNumbers.${index}.dateRange`}
              ifCircle={true}
              label={
                <span style={{ fontSize: "0.9rem" }}>{t("date_range")}</span>
              }
            />
          </RowTitle>
          {values["registrationNumbers"][index].dateRange && (
            <RowInput>
              <DatePickerFormik
                customLabel={t("date_from")}
                name={`registrationNumbers.${index}.dateFrom`}
                view={["year", "month", "date"]}
                format="dd-MM-yyyy"
                required={true}
              />
            </RowInput>
          )}
        </Row>

        {values["registrationNumbers"][index].dateRange && (
          <Row>
            <RowTitle>{}</RowTitle>
            <RowInput>
              <DatePickerFormik
                customLabel={t("date_to2")}
                name={`registrationNumbers.${index}.dateTo`}
                view={["year", "month", "date"]}
                format="dd-MM-yyyy"
                required={true}
              />
            </RowInput>
          </Row>
        )}
      </TwoColumnsRowsContainer>
    </div>
  );
};

export default RegistrationNumberForm;
