import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteFormik,
  DatePickerFormik,
  TextFieldFormik,
} from "../../../helpersComponents/FormikInputs";
import CheckboxWithLabelFormik from "../../../helpersComponents/FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../../helpersComponents/PopupHelpers";
import * as Yup from "yup";
import ButtonStyled from "../../../helpersComponents/Button/button";
import yupIsValidDate from "../../../../HelpersFunctions/yupSchemes/isValidDate";
import styles from "./styles.module.scss";

interface Props {
  typesOfVisits: TypeOfVisit[];
  training: TrainingObjectPutPost;
  setBasicInformations: React.Dispatch<BasicInfoObject>;
  setcurrentTabName: React.Dispatch<TabsInterface>;
  ifDisableMultiSelectionInQuestionsCheckbox: boolean;
}

export default function BasicInformations({
  typesOfVisits,
  training,
  setBasicInformations,
  setcurrentTabName,
  ifDisableMultiSelectionInQuestionsCheckbox,
}: Props) {
  const { t, i18n } = useTranslation();
  const types = [
    { id: 0, name: t("guest") },
    { id: 1, name: t("worker") },
  ];

  let initState: BasicInfoObject = {
    id: 0,
    namePL: "",
    nameEN: "",
    nameDE: "",
    nameRU: "",
    nameUK: "",
    type: null,
    kindOfVisit: null,
    validFrom: new Date(),
    validTo: new Date(),
    importance: 0,
    backToPreviousQuestionPossible: false,
    numberOfQuestions: 0,
    multiSelectionInQuestions: false,
    isRequired: false,
    descriptionPL: "",
    descriptionEN: "",
    descriptionDE: "",
    descriptionRU: "",
    descriptionUK: "",
    order: 0,
    isRepeatPossible: false,
    passTresholdInPercent: 70,
  };

  if (training) {
    let { type, kindOfVisit, validFrom, validTo } = training;
    initState = {
      ...training,
      validFrom: new Date(validFrom),
      validTo: new Date(validTo),
      type: types.find((typeLocal) => typeLocal.id === type) ?? null,
      kindOfVisit:
        typesOfVisits.find((visit) => visit.id === kindOfVisit) ?? null,
    };
  }

  const getTypeOfVisitPropertyName = useCallback(() => {
    switch (i18n.language) {
      case "en":
        return "nameEN";

      case "de":
        return "nameDE";

      case "ru":
        return "nameRU";

      case "uk":
        return "nameUK";

      default:
        return "namePL";
    }
  }, [i18n.language]);

  return (
    <>
      <DialogContentStyled ifInsidePopup={false} inputWidth={290}>
        <Formik
          initialValues={initState}
          onSubmit={(values) => {
            let windowAny: any = window;
            let submitButtonName = windowAny.event?.submitter?.name;
            setBasicInformations(values);
            if (submitButtonName === "presentation") {
              setcurrentTabName("presentation");
            } else if (submitButtonName === "questions") {
              setcurrentTabName("questions");
            } else {
              setcurrentTabName("presentation");
            }
          }}
          validationSchema={Yup.object({
            validFrom: yupIsValidDate({ t }),
            type: Yup.object().nullable(false).typeError(t("field_required")),
            validTo: Yup.date().when("validFrom", (validFrom, schema) => {
              let dateFromCopy: any = new Date(validFrom);
              if (
                validFrom === "Invalid Date" ||
                dateFromCopy === "Invalid Date" ||
                validFrom === null
              ) {
                return schema.typeError(t("invalid_date"));
              }
              dateFromCopy.setHours(0, 0, 0, 0);
              return (
                validFrom &&
                schema
                  .min(dateFromCopy, t("date_to_must_be_later_than_date_from"))
                  .typeError(t("invalid_date"))
              );
            }),
            kindOfVisit: Yup.object()
              .nullable(false)
              .typeError(t("field_required")),
          })}
        >
          <Form noValidate id="submitBasics">
            <fieldset className={styles.field_set}>
              <legend>{t("name")}</legend>
              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle>{t("name_pl")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("name_pl")}
                      name="namePL"
                      type="text"
                    />
                  </RowInput>
                </Row>

                <Row>
                  <RowTitle>{t("name_en")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("name_en")}
                      name="nameEN"
                      type="text"
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle>{t("name_de")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("name_de")}
                      name="nameDE"
                      type="text"
                    />
                  </RowInput>
                </Row>

                <Row>
                  <RowTitle>{t("name_ru")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("name_ru")}
                      name="nameRU"
                      type="text"
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle>{t("name_uk")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("name_uk")}
                      name="nameUK"
                      type="text"
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>
            </fieldset>

            <fieldset className={styles.field_set}>
              <legend>{t("description")}</legend>
              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle>{t("description_pl")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("description_pl")}
                      name="descriptionPL"
                      type="text"
                      multiline
                    />
                  </RowInput>
                </Row>

                <Row>
                  <RowTitle>{t("description_en")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("description_en")}
                      name="descriptionEN"
                      type="text"
                      multiline
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle>{t("description_de")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("description_de")}
                      name="descriptionDE"
                      type="text"
                      multiline
                    />
                  </RowInput>
                </Row>

                <Row>
                  <RowTitle>{t("description_ru")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("description_ru")}
                      name="descriptionRU"
                      type="text"
                      multiline
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle>{t("description_uk")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("description_uk")}
                      name="descriptionUK"
                      type="text"
                      multiline
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>
            </fieldset>

            <fieldset className={styles.field_set}>
              <legend>{t("remaining")}</legend>
              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle>{t("valid_from")}:</RowTitle>
                  <RowInput>
                    <DatePickerFormik
                      label={t("valid_from")}
                      name="validFrom"
                      view={["year", "month", "date"]}
                      format="dd-MM-yyyy"
                      required={true}
                    />
                  </RowInput>
                </Row>
                <Row>
                  <RowTitle>{t("valid_to")}:</RowTitle>
                  <RowInput>
                    <DatePickerFormik
                      label={t("valid_to")}
                      name="validTo"
                      view={["year", "month", "date"]}
                      format="dd-MM-yyyy"
                      required={true}
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle>{t("type_of_visit")}:</RowTitle>
                  <RowInput>
                    <AutocompleteFormik
                      options={typesOfVisits}
                      getOptionLabel={(option: any) => {
                        let propertyName = getTypeOfVisitPropertyName();
                        return option[propertyName];
                      }}
                      width="100%"
                      getOptionSelected={(option, value) => {
                        return option.id === value.id;
                      }}
                      label={t("type_of_visit")}
                      name="kindOfVisit"
                      required={true}
                    />
                  </RowInput>
                </Row>
                <Row>
                  <RowTitle>{t("validity_of__test")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("validity_of__test")}
                      name="importance"
                      type="number"
                      required={true}
                      onChangeExtra={(e) => {
                        let value = parseInt(e.target.value, 10);
                        if (e.target.value === "") value = 0;
                        if (value < 0) value = 0;
                        e.target.value = value;
                      }}
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle>
                    {t("how_many_questions_to_draw_in_the_exam")}:
                  </RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("how_many_questions_to_draw_in_the_exam")}
                      name="numberOfQuestions"
                      type="number"
                      required={true}
                      onChangeExtra={(e) => {
                        let value = parseInt(e.target.value, 10);
                        if (e.target.value === "") value = 0;
                        if (value < 0) value = 0;
                        e.target.value = value;
                      }}
                    />
                  </RowInput>
                </Row>
                <Row>
                  <Row>
                    <RowTitle>{t("type")}:</RowTitle>
                    <RowInput>
                      <AutocompleteFormik
                        options={types}
                        getOptionLabel={(option: any) => {
                          return option.name;
                        }}
                        width="100%"
                        getOptionSelected={(option, value) => {
                          return option.id === value.id;
                        }}
                        label={t("type")}
                        name="type"
                        required={true}
                      />
                    </RowInput>
                  </Row>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowTitle>{t("order")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("order")}
                      name="order"
                      type="number"
                      required={true}
                      onChangeExtra={(e) => {
                        let value = parseInt(e.target.value, 10);
                        if (e.target.value === "") value = 0;
                        if (value < 0) value = 0;
                        e.target.value = value;
                      }}
                    />
                  </RowInput>
                </Row>
                <Row>
                  <RowTitle>{t("pass_threshold")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("pass_threshold")}
                      name="passTresholdInPercent"
                      type="number"
                      required={true}
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: 100,
                          step: 1,
                        },
                      }}
                      onChangeExtra={(e) => {
                        let value = parseInt(e.target.value, 10);
                        if (e.target.value === "") value = 0;
                        if (value > 100) value = 100;
                        if (value < 0) value = 0;
                        e.target.value = value;
                      }}
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowInput>
                    <CheckboxWithLabelFormik
                      name={"backToPreviousQuestionPossible"}
                      ifCircle={true}
                      label={t("possibility_to_return_to_previous_questions")}
                    />
                  </RowInput>
                </Row>
                <Row>
                  <RowInput>
                    <CheckboxWithLabelFormik
                      name={"multiSelectionInQuestions"}
                      ifCircle={true}
                      disabled={ifDisableMultiSelectionInQuestionsCheckbox}
                      label={t("multiple_choice")}
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>

              <TwoColumnsRowsContainer>
                <Row>
                  <RowInput>
                    <CheckboxWithLabelFormik
                      name={"isRepeatPossible"}
                      ifCircle={true}
                      label={t("possibility_to_repeat_test")}
                    />
                  </RowInput>
                </Row>
                <Row>
                  <RowInput>
                    <CheckboxWithLabelFormik
                      name={`isRequired`}
                      ifCircle={true}
                      label={t("is_required")}
                    />
                  </RowInput>
                </Row>
              </TwoColumnsRowsContainer>
            </fieldset>

            <br />
            <ButtonStyled type="submit">
              {t("move_to_presentations")}
            </ButtonStyled>
          </Form>
        </Formik>
      </DialogContentStyled>
    </>
  );
}
