import styles from "./stylesInspectionServices.module.scss";

const InspectionService = ({
  item,
  selectedService,
}: {
  item: InspectionService;
  selectedService: { id: number; name: string } | null;
}) => {
  const containerClass =
    styles.logoCard + " " + (selectedService === item && styles.logoCardActive);

  return (
    <div className={containerClass}>
      <div className={styles.logo}>
        <img src={"data:image/png;base64, " + item.logo} alt={item.name} />
      </div>
      <div className={styles.logoName}>
        <p>{item.name}</p>
      </div>
    </div>
  );
};

export default InspectionService;
