import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrewEntryDescentType } from "../../../Constants/crewEntryDescentTypes";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../helpersComponents/PopupHelpers";
import PopupContentCrewEntryDescent from "./PopupContentCrewEntryDescent";
import RejectPopup from "../../pfso/OperationButtons/RejectPopup";
import { StatusInPortsType } from "../../../Constants/statusInPortsType";
import { useHistory } from "react-router-dom";

interface IProps {
  isOpen: boolean;
  closePopup: () => void;
  rowEditId: undefined | number;
  isOpenEditPopup: boolean;
  closeEditPopup: () => void;
  successCallback: () => void;
  eventType: CrewEntryDescentType;
  profileName: string;
  confirmationOnly: boolean;
  pathApiChangeConfirmationStatus: string;
}

const PopupCrewEntryDescentAdd: React.FC<IProps> = ({
  isOpen,
  closePopup,
  rowEditId,
  isOpenEditPopup,
  closeEditPopup,
  successCallback,
  eventType,
  profileName,
  confirmationOnly,
  pathApiChangeConfirmationStatus,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpenRejectPopup, setIsOpenRejectPopup] = useState(false);
  const [
    changeConfirmationStatusBodyRequest,
    setChangeConfirmationStatusBodyRequest,
  ] = useState<string | boolean>(false);

  const successCallbackAndClose = useCallback(() => {
    if (successCallback) {
      successCallback();
    }
    closePopup();
    closeEditPopup();

    if (
      confirmationOnly &&
      (profileName === "pfso" || profileName === "shift-director")
    ) {
      if (eventType === CrewEntryDescentType.ENTRY) {
        history.replace(`/${profileName}/crew-entries`);
      } else if (eventType === CrewEntryDescentType.DESCENT) {
        history.replace(`/${profileName}/crew-descents`);
      }
    }
  }, [
    successCallback,
    closePopup,
    closeEditPopup,
    confirmationOnly,
    eventType,
    history,
    profileName,
  ]);

  const [, fetchAgainChangeNotificationStatus] = useFetchOtherThanGET({
    path: `${pathApiChangeConfirmationStatus}/${rowEditId}`,
    method: "PATCH",
    body: changeConfirmationStatusBodyRequest,
    setBody: setChangeConfirmationStatusBodyRequest,
    contentType: "application/json",
    successCallback: successCallbackAndClose,
  });

  useEffect(() => {
    if (changeConfirmationStatusBodyRequest) {
      fetchAgainChangeNotificationStatus();
    }
  }, [changeConfirmationStatusBodyRequest, fetchAgainChangeNotificationStatus]);

  const handleCloseRejectPopup = useCallback(() => {
    setIsOpenRejectPopup(false);
  }, []);

  const handleOpenRejectPopup = useCallback(() => {
    setIsOpenRejectPopup(true);
  }, []);

  const onSubmit = (values: IAddCrewEntryDescent) => {
    if (confirmationOnly) {
      let tempBody = [
        {
          path: "/crewEntryDescent/confirmationStatus",
          op: "replace",
          value: StatusInPortsType.APPROVED,
        },
      ];

      setChangeConfirmationStatusBodyRequest(JSON.stringify(tempBody));
    } else {
      setErrorMessage("");
      setBodyRequest(JSON.stringify(values));
    }
  };

  const [bodyRequest, setBodyRequest] = useState<false | string>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${profileName}/crew-entries-descents${
      rowEditId !== undefined ? `/${rowEditId}` : ""
    }`,
    method: rowEditId !== undefined ? "PUT" : "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  useEffect(() => {
    if (isOpenEditPopup === false || isOpen === false) {
      setErrorMessage("");
    }
  }, [isOpenEditPopup, isOpen]);

  return (
    <DialogStyled
      open={isOpen || isOpenEditPopup}
      onClose={() => {
        closePopup();
        closeEditPopup();
      }}
    >
      <DialogTitleStyled
        title={
          confirmationOnly
            ? eventType === CrewEntryDescentType.ENTRY
              ? t("confirm_crew_entry")
              : t("confirm_crew_descent")
            : eventType === CrewEntryDescentType.ENTRY
            ? t("add_crew_entry")
            : t("add_crew_descent")
        }
      />
      <PopupContentCrewEntryDescent
        rowId={rowEditId}
        onSubmit={onSubmit}
        eventType={eventType}
        profileName={profileName}
        confirmationOnly={confirmationOnly}
      />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        ifHideReject={confirmationOnly ? false : true}
        onRejectText={t("refuse")}
        onRejectColor="red"
        onConfirmColor={confirmationOnly ? "green" : ""}
        onCancel={() => {
          closePopup();
          closeEditPopup();
        }}
        onReject={() => {
          setIsOpenRejectPopup(true);
        }}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />

      <div>
        {isOpenRejectPopup && (
          <RejectPopup
            patchApi={`${pathApiChangeConfirmationStatus}/${rowEditId}`}
            prefixPathName="/crewEntryDescent"
            isOpen={isOpenRejectPopup}
            successCallback={successCallbackAndClose}
            closePopup={handleCloseRejectPopup}
            openPopup={handleOpenRejectPopup}
            isSecurity={false}
          />
        )}
      </div>
    </DialogStyled>
  );
};

export default PopupCrewEntryDescentAdd;
