import { useCallback, useEffect, useState } from "react";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { useTranslation } from "react-i18next";
import AddSubstitutionPopup from "./Popups";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import { Button } from "@mui/material";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import SubstitutionSelectMenu from "./SelectMenus/SubstitutionSelectMenu";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SubstitutionConten() {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const { confirm } = useConfirm();

  const columns = [
    { name: "dateFrom", title: t("date_from"), type: "date_hours" },
    { name: "dateTo", title: t("date_to2"), type: "date_hours" },
    { name: "substitutionName", title: t("substitution") },
  ];

  const [substitution, fetchingStatesSubstitution, fetchAgainSubstitution] =
    useFetchAndSetGET({
      path: `superior-time/substitutions`,
    });

  const [idToRemove, setIdToRemove] = useState();

  const [, fetchAgainRemoveSubstitution] = useFetchOtherThanGET({
    path: `superior-time/substitutions/${idToRemove}`,
    method: "DELETE",
    body: idToRemove,
    setBody: setIdToRemove,
    successCallback: fetchAgainSubstitution,
  });

  useEffect(() => {
    if (idToRemove) {
      fetchAgainRemoveSubstitution();
    }
  }, [idToRemove, fetchAgainRemoveSubstitution]);

  const removeButtonClick = useCallback((ids) => {
    if (ids) {
      setIdToRemove(ids.join(","));
    }
  }, []);

  return (
    <>
      <TopBanner pathName={t("substitution")} />
      <div className="flexAndCenter">
        <div>
          {!fetchingStatesSubstitution.isFetching && (
            <TableComponent
              fetchingState={{
                fetchAgain: fetchAgainSubstitution,
                isError: fetchingStatesSubstitution.isError,
                isFetching: fetchingStatesSubstitution.isFetching,
              }}
              layoutSettingName="layoutSubstitution"
              rows={substitution}
              columns={columns}
              backgroundColor="#fff"
              pageSize={8}
              hideActionsAndVisibilityColumns={true}
              selectData={(ids) => setSelectedRows(ids as number[])}
              selectedData={selectedRows}
              actionsColumnUpdated={{
                popup: AddSubstitutionPopup,
                successCallbackPopup: fetchAgainSubstitution,

                leftToolbarComponent: {
                  ifShow: true,
                  customComponent: () => {
                    return (
                      <SubstitutionSelectMenu
                        substitutionIds={selectedRows}
                        removeButtonClick={removeButtonClick}
                      />
                    );
                  },
                },

                addButton: {
                  ifShow: true,
                  showInToolbar: false,
                },

                removeButton: {
                  ifShow: true,
                  name: t("remove"),
                  customComponent(row) {
                    return (
                      <Button
                        onClick={async () => {
                          let selectedbuttonId = await confirm({
                            text: t("confirm_delete_element"),
                            buttons: [
                              { buttonName: t("yes"), buttonId: 0 },
                              { buttonName: t("no"), buttonId: 1 },
                            ],
                            disableBackdropClick: true,
                          });

                          if (selectedbuttonId === 0) {
                            setIdToRemove(row.id);
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          size="lg"
                          title={t("remove")}
                        />
                      </Button>
                    );
                  },
                },

                editButton: {
                  ifShow: true,
                  name: t("edit"),
                },
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default SubstitutionConten;
