const getBeginningOfDate = (
  date: Date,
  beginningOfType: "Year" | "CurrentQuarter" | "Month" | "CurrentWeek" | "Day",
  addToDate?: {
    extraDays?: number;
    extraHours?: number;
  }
): Date => {
  let extraDays = addToDate?.extraDays ?? 0;
  let extraHours = addToDate?.extraHours ?? 0;

  if (beginningOfType === "Month") {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      1 + extraDays,
      0 + extraHours,
      0,
      0
    );
  } else if (beginningOfType === "CurrentWeek") {
    let firstDay = date.getDate() - date.getDay();
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      firstDay + 1 + extraDays,
      0 + extraHours,
      0,
      0
    );
  } else if (beginningOfType === "Year") {
    return new Date(date.getFullYear(), 0, 1 + extraDays, 0 + extraHours, 0, 0);
  } else if (beginningOfType === "Day") {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + extraDays,
      0 + extraHours,
      0,
      0
    );
  } else if (beginningOfType === "CurrentQuarter") {
    let actualDate = new Date();
    let currentQuarter = Math.floor(actualDate.getMonth() / 3) + 1;
    let startMonth = currentQuarter * 3 - 3;
    return new Date(
      actualDate.getFullYear(),
      startMonth,
      1 + extraDays,
      0 + extraHours,
      0,
      0
    );
  }
  return date;
};

export default getBeginningOfDate;
