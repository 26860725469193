import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./myTasks.module.scss";
import { useTranslation } from "react-i18next";
import SingleTask from "../SingleTaskComponents/singleTask";
import getDifferenceInTwoDates from "../../../../HelpersFunctions/dateAndTime/getDifferenceInTwoDates";
import TasksNumberAndDurationTime from "../tasksNumberAndDurationTime";

const useStyles = makeStyles({
  accordionSummaryRoot: {
    "&.MuiAccordionSummary-root": {
      //   padding: 0,
    },
  },
  muiAccordionSummary: {
    "&.MuiAccordionDetails-root > div": {
      width: "100%",
    },
  },
});

interface Props {
  photos: {
    [key in number]: string;
  };
  projects: {
    [key in number]: {};
  };
  category: {
    name: string;
    isExpanded: boolean;
    tasks: { [key in string]: any }[];
  };
  setIfFetchAgain: React.Dispatch<boolean>;
  filterTasks: string;
  tasksFiltered: any[];
}

export default function TasksCategory({
  photos,
  projects,
  category,
  setIfFetchAgain,
  filterTasks,
  tasksFiltered,
}: Props): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(category.isExpanded);
  const [durationTimeTasks, setdurationTimeTasks] = useState<number>(0);

  useEffect(() => {
    let durationTimeTasksLocal: number = 0;
    tasksFiltered.forEach((task) => {
      if (task.dateFrom && task.dateTo) {
        durationTimeTasksLocal +=
          getDifferenceInTwoDates(
            new Date(task.dateTo),
            new Date(task.dateFrom)
          ) / 6000;
      }
    });
    setdurationTimeTasks(durationTimeTasksLocal);
  }, [tasksFiltered]);

  return (
    <div>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={isExpanded || filterTasks.length > 2}
        onChange={() => setIsExpanded(!isExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={
            styles.accordionSummaryreverse + " " + classes.accordionSummaryRoot
          }
        >
          <div className={styles.summaryRow}>
            <div className={styles.accordingTitle}>
              <b>{category.name}</b>
            </div>
            <div>
              <TasksNumberAndDurationTime
                tasksNumber={tasksFiltered.length}
                durationTimeTasksSeconds={durationTimeTasks}
              />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.muiAccordionSummary}>
          <div className={styles.tasksList}>
            {tasksFiltered.map((task) => {
              return (
                <SingleTask
                  key={task.id}
                  task={task}
                  setIfFetchAgain={setIfFetchAgain}
                  project={projects[task.projectId]}
                  photos={photos}
                  ifShowProjectDetails={true}
                />
              );
            })}
            {tasksFiltered.length === 0 && (
              <div>{t("no_tasks_for_this_category")}</div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
