import { faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useConfirm from "../../../../../../hooks/useConfirm/useConfirm";
import CheckboxStyled from "../../../../../helpersComponents/MaterialUi/Checkbox/checkbox";
import StyledDataGrid from "../../../../../helpersComponents/MaterialUi/DataGrid/StyledDataGrid";
import GroupReaderTimeSettingItem from "./GroupReaderTimeSettingItem/GroupReaderTimeSettingItem";
import styles from "./GroupReaderTimeSettings.module.scss";

interface IProps {
  formikValues: {
    [x: string]:
      | string
      | number
      | boolean
      | number[]
      | null
      | any[]
      | Date
      | any;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const GroupReaderTimeSettings: React.FC<IProps> = ({
  formikValues,
  setFieldValue,
}) => {
  const { t } = useTranslation();

  const functions = [
    {
      id: 1,
      name: t("change_event_type"),
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "readerName",
      headerName: t("reader"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.readerName}</span>
      ),
    },
    {
      field: "daysNames",
      headerName: t("days"),
      flex: 3,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.daysNames}</span>
      ),
    },
    {
      field: "isHoliday",
      headerName: t("or_on_holiday"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          <CheckboxStyled checked={params.row.isHoliday} disabled />
        </span>
      ),
    },
    {
      field: "timeFrom",
      headerName: t("from"),
      flex: 0.5,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {new Date(params.row.timeFrom).toLocaleString(
            t("scheduler_language"),
            {
              hour: "2-digit",
              minute: "2-digit",
            }
          )}
        </span>
      ),
    },
    {
      field: "timeTo",
      headerName: t("to"),
      flex: 0.5,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {new Date(params.row.timeTo).toLocaleString(t("scheduler_language"), {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
      ),
    },
    {
      field: "function",
      headerName: t("operation"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {functions.find((f) => f.id === params.row.functionId)?.name}
        </span>
      ),
    },
    {
      field: "value",
      headerName: t("value"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.value}</span>
      ),
    },
  ];

  const [groupReaderTimeSettingItemPopup, setGroupReaderTimeSettingItemPopup] =
    useState(false);
  const closeGroupReaderTimeSettingItemPopup = useCallback(() => {
    setGroupReaderTimeSettingItemPopup(false);
  }, []);

  const addTimeSettingHandler = () => {
    setGroupReaderTimeSettingItemPopup(true);
  };

  const [timeSettingsIdsToRemove, setTimeSettingsIdsToRemove] = useState<
    number[]
  >([]);
  const { confirm } = useConfirm();
  const removeTimeSettingHandler = async () => {
    let confirmResponse = await confirm({
      text: `${t("are_you_sure_you_want_to_remove_selected_items")}?`,
      buttons: [
        { buttonName: t("yes"), buttonId: 0 },
        { buttonName: t("cancel"), buttonId: 1 },
      ],
    });
    if (confirmResponse === 0) {
      const timeSettingsList = formikValues[
        `groupReaderTimeSettings`
      ] as IGroupReaderTimeSetting[];
      const filtered = timeSettingsList.filter(
        (timeSetting) => !timeSettingsIdsToRemove.includes(timeSetting.id)
      );
      setFieldValue("groupReaderTimeSettings", filtered);
    }
  };

  return (
    <div className={styles.grid}>
      <div className={styles.titleContainer}>
        <p className={styles.gridTitle}>{t("group_reader_time_settings")}</p>
        <div className={styles.buttonsContainer}>
          <div>
            <Tooltip title={t("add")!}>
              <div
                className={styles.permissionButton}
                onClick={addTimeSettingHandler}
              >
                <FontAwesomeIcon icon={faPlusCircle} size="lg" color="green" />
              </div>
            </Tooltip>
          </div>

          <div>
            {timeSettingsIdsToRemove.length > 0 && (
              <Tooltip title={t("delete")!}>
                <div
                  className={styles.permissionButton}
                  onClick={removeTimeSettingHandler}
                >
                  <FontAwesomeIcon icon={faTrashAlt} size="lg" color="red" />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <StyledDataGrid
        rows={
          formikValues["groupReaderTimeSettings"] as IGroupReaderTimeSetting[]
        }
        columns={columns}
        defaultPageSize={10}
        rowsPerPageOptions={[10]}
        density="compact"
        quickFilter
        checkboxSelection={true}
        onSelectionModelChange={(selected) => {
          setTimeSettingsIdsToRemove(selected as number[]);
        }}
      />

      {groupReaderTimeSettingItemPopup && (
        <GroupReaderTimeSettingItem
          isOpen={groupReaderTimeSettingItemPopup}
          closePopup={closeGroupReaderTimeSettingItemPopup}
          formikValues={formikValues}
          setFieldValue={setFieldValue}
          functions={functions}
        />
      )}
    </div>
  );
};

export default GroupReaderTimeSettings;
