import React from 'react';
import BusinessExitsContent from "./businessExitsContent"


function BusinessExits() {
    return (
        <div>
            <BusinessExitsContent/>
        </div>
    );
}

export default BusinessExits;
