import { DialogContent } from "@material-ui/core";
import { Form, Formik } from "formik";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TextFieldFormik from "../../../../../helpersComponents/FormikInputs/TextField/textField";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../../../../helpersComponents/PopupHelpers";
import * as Yup from "yup";
import useFetchOtherThanGET from "../../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";

interface Props {
  open: boolean;
  summariesIds: number[];
  onApplyChanges: any;
  onCancelChanges: any;
}

const AddCommentPopup = ({
  open,
  summariesIds,
  onApplyChanges,
  onCancelChanges,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const [bodyRequest, setBodyRequest] = useState<any>(false);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: "superior-time/summaries-comments",
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    disableErrorSnackbar: false,
    disableSuccessSnackbar: false,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  const applyChanges = useRef(onApplyChanges);

  useEffect(() => {
    if (
      !fetchingStatePostData.isFetching &&
      fetchingStatePostData.response?.status === 201
    ) {
      if (applyChanges && applyChanges.current) {
        setBodyRequest(false);
        applyChanges.current();
      }
    }
  }, [
    fetchingStatePostData.isFetching,
    fetchingStatePostData.response?.status,
  ]);

  return (
    <DialogStyled open={open} onClose={onCancelChanges}>
      <DialogTitleStyled title={t("adding_comment")} />
      <DialogContent>
        <Formik
          initialValues={{
            comment: "",
          }}
          onSubmit={(values) => {
            setBodyRequest(
              JSON.stringify({
                summariesIds: summariesIds,
                comment: values.comment,
              })
            );

            /*if (applyChanges && applyChanges.current) {
              applyChanges.current();
            }*/
          }}
          validationSchema={Yup.object({
            comment: Yup.string().required(t("field_required")),
          })}
        >
          {() => (
            <Form noValidate id="submitForm">
              <Row>
                <RowTitle>{t("comment")}:</RowTitle>
                <RowInput>
                  <TextFieldFormik
                    ifToUpperCaseShipsAndPorts={true}
                    label={t("comment")}
                    name="comment"
                    type="text"
                    required={true}
                  />
                </RowInput>
              </Row>
            </Form>
          )}
        </Formik>
      </DialogContent>

      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={onCancelChanges}
        isLoading={fetchingStatePostData.isFetching}
      />
    </DialogStyled>
  );
};

export default AddCommentPopup;
