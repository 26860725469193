import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import { statusDictionary } from "../../../../Constants/statusDictionary";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import FilesListWithFetch from "../../../helpersComponents/Attachments/filesListWithFetch";
import TableAndActions from "./tableAndActions";
import useFetch from "../../../../hooks/fetchHooks/useFetch/useFetch";
import { useAppSelector } from "../../../../store/hooks";
import { selectTimeWorkersSuperiorObject } from "../../../../reducers/workersLists";
import AddEditHolideyRequestPopup from "./../../../helpersComponents/Requests/Holiday/AddEditHolidayRequestPopup";
import { SnackbarStyled } from "../../../helpersComponents/MaterialUi";
import { useHistory } from "react-router-dom";
import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { HolidayRequestTypeFunction } from "../../../../enums/holidayRequestTypeFunction";
import { RequestStatus } from "../../../../Constants/requestStatus";
import BackOffDaysFromHomeOfficePopup from "../../../helpersComponents/Requests/Holiday/BackOffDaysFromHomeOfficePopup";
import getDateWithoutTime from "../../../../HelpersFunctions/dateAndTime/getDateWithoutTime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FormControlLabel } from "@material-ui/core";
import { CheckboxStyled } from "../../../helpersComponents/MaterialUi";
import AbsenceOfCoworkersComponent from "../../../helpersComponents/AbsenceOfCoworkers";
import styles from "./holidaysRequests.module.scss";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import RowDetail from "./rowDetail";
import { Button as MaterialUIButton } from "@material-ui/core";
import { ProfileType } from "../../../../enums/profileType";
import { selectAuthUser } from "../../../../reducers/session";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";

let Cell: any = TableEditColumn.Cell;

const ActionColumn: React.FC = ({
  children,
  row,
  classes,
  ...restProps
}: any) => {
  const { t } = useTranslation();

  let displayBackoffButton = (row: any) => {
    if (row) {
      let allowedRequestStatus: RequestStatus[] = [];
      allowedRequestStatus.push(RequestStatus.INTRODUCTED);
      allowedRequestStatus.push(RequestStatus.APPROVED);
      allowedRequestStatus.push(RequestStatus.FINALLY_APPROWED);

      const currentDate = getDateWithoutTime(new Date());
      const requestDateTo = getDateWithoutTime(new Date(row.dateTo));

      if (
        currentDate <= requestDateTo &&
        allowedRequestStatus.includes(row.status) &&
        (row.typyFunctionId === HolidayRequestTypeFunction.HOME_OFFICE ||
          row.typyFunctionId ===
            HolidayRequestTypeFunction.OCCASIONAL_HOME_OFFICE)
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <Cell
      row={restProps.row}
      className="tableCellEditCancelStyleGlobal"
      style={{ textAlign: "left" }}
    >
      {children}

      {displayBackoffButton(row) && (
        <MaterialUIButton
          style={{
            maxWidth: "30px",
            maxHeight: "30px",
            minWidth: "30px",
            minHeight: "30px",
          }}
          onClick={() => {
            if (restProps.setCurrentRow) {
              restProps.setCurrentRow(row);
            }

            if (restProps.setOpenBackOffDaysDialog) {
              restProps.setOpenBackOffDaysDialog(true);
            }
          }}
        >
          <FontAwesomeIcon
            icon={faArrowCircleLeft}
            size="lg"
            title={t("backoff_days")}
            className="backoffDaysIcon"
          />
        </MaterialUIButton>
      )}
    </Cell>
  );
};

function HolidaysRequestsContent() {
  const date = new Date();
  const { t } = useTranslation();
  const authUserType = useAppSelector(selectAuthUser).currentProfile.type;
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0)
  );
  const [endDate, setEndDate] = useState(
    new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)
  );
  const [onlyRequests, setOnlyRequests] = useState<boolean>(false);
  const [onlyIntroduced, setOnlyIntroduced] = useState<boolean>(false);

  const [dataModified, setDataModified] = useState<any>([]);
  const timeWorkersSuperiorObject = useAppSelector(
    selectTimeWorkersSuperiorObject
  );

  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);

  const history: any = useHistory();

  let [setIsFetching, fetchingState, fetchAgain] = useFetch({
    method: "GET",
    path:
      "superior-time/holiday-requests?DateFrom=" +
      computeDateRangeFetch(startDate, "T00:00:00") +
      "&DateTo=" +
      computeDateRangeFetch(endDate) +
      `&onlyIntroduced=${onlyIntroduced}`,
  });

  const [requestIdToRemove, setRequestIdToRemove] = useState<false | number>(
    false
  );

  const [deleteRequestFetchingState, deleteRequestFetchAgain] =
    useFetchOtherThanGET({
      path: `superior-time/holiday-requests/${requestIdToRemove}`,
      method: "DELETE",
      body: requestIdToRemove,
      setBody: setRequestIdToRemove,
      successCallback: fetchAgain,
    });

  useEffect(() => {
    if (requestIdToRemove !== false) {
      deleteRequestFetchAgain();
    }
  }, [requestIdToRemove, deleteRequestFetchAgain]);

  useEffect(() => {
    fetchAgain();
  }, [onlyIntroduced, fetchAgain]);

  const [requestsPermissions, requestsPermissionsFetchingState] =
    useFetchAndSetGET<IHolidayRequestsPermissions>({
      path: `superior-time/holiday-requests-permissions`,
      startFetchOnInitial: true,
    });

  useEffect(() => {
    if (fetchingState.isError || fetchingState.response === undefined) return;
    if (!Array.isArray(fetchingState.response.resJson)) return;
    let data = fetchingState.response.resJson;
    let lpHolidays = 1;

    data.forEach((element, index) => {
      data[index].time = convertMinsToHrsMins(
        (new Date(element.dateTo).getTime() -
          new Date(element.dateFrom).getTime()) /
          (1000 * 60)
      );

      data[index].statusTranslated = t(statusDictionary[data[index].status]);
      data[index].employee = element.firstName + " " + element.lastName;
      data[index].organizationalUnits =
        timeWorkersSuperiorObject[element.timeWorkerId]?.organizationalUnits;
      data[index].evidenceNumber =
        timeWorkersSuperiorObject[element.timeWorkerId]?.evidenceNumber;

      data[index].createdAtString = new Date(element.createdAt).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );

      data[index].fromTime = new Date(element.dateFrom).toLocaleString(
        t("scheduler_language"),
        {
          hour: "2-digit",
          minute: "2-digit",
        }
      );
      data[index].tillHour = new Date(element.dateTo).toLocaleString(
        t("scheduler_language"),
        {
          hour: "2-digit",
          minute: "2-digit",
        }
      );

      data[index].dateToDetails = new Date(element.dateTo).toLocaleString(
        t("scheduler_language"),
        {
          weekday: "long",
          month: "long",
          year: "numeric",
          day: "2-digit",
        }
      );
      data[index].dateFromDetails = new Date(element.dateFrom).toLocaleString(
        t("scheduler_language"),
        {
          weekday: "long",
          month: "long",
          year: "numeric",
          day: "2-digit",
        }
      );

      data[index].creationDateDetails = new Date(
        element.createdAt
      ).toLocaleString(t("scheduler_language"), {
        weekday: "long",
        month: "long",
        year: "numeric",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      data[index].attachments = element.attachments;
      data[index].attachmentsList =
        data[index].attachments?.length === 0 ? (
          t("none")
        ) : (
          <div>
            <FilesListWithFetch
              files={data[index].attachments}
              path={
                authUserType === ProfileType.SUPERIOR_TIME
                  ? `superior-time/holiday-requests/${element.id}/attachments`
                  : `worker-time/${data[index].timeWorkerId}/holiday-requests/${data[index].id}/attachments`
              }
            />
          </div>
        );
      data[index].lp = lpHolidays;
      lpHolidays++;
    });

    setDataModified(data);
    setIsFetching(false);
  }, [
    fetchingState.isFetching,
    fetchingState.isError,
    timeWorkersSuperiorObject,
    fetchingState.response,
    setIsFetching,
    t,
    authUserType,
  ]);

  useEffect(() => {
    fetchAgain();
  }, [startDate, endDate, fetchAgain]);

  const columns = [
    { name: "lp", title: "L.p." },
    {
      name: "dateFrom",
      title: t("date_from"),
      type: "date_hours",
      customFormatter: (row) => (
        <span style={{ color: "green" }}>
          {toLocaleStringCustom({
            date: row.dateFrom ? new Date(row.dateFrom) : false,
            t,
            ifShowHours: true,
          })}
        </span>
      ),
    },
    {
      name: "dateTo",
      title: t("date_to2"),
      type: "date_hours",
      customFormatter: (row) => (
        <span style={{ color: "red" }}>
          {toLocaleStringCustom({
            date: row.dateTo ? new Date(row.dateTo) : false,
            t,
            ifShowHours: true,
          })}
        </span>
      ),
    },
    { name: "employee", title: t("employee") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "organizationalUnits", title: t("organizational_units") },
    { name: "alternate", title: t("substitution") },
    { name: "typeDescription", title: t("type_of_request") },
    { name: "days", title: t("days_request") },
    { name: "holidayLeft", title: t("holiday_left") },
    { name: "statusTranslated", title: t("status") },
    { name: "comment", title: t("comment") },
    { name: "approvedBy", title: t("approved_by") },
    { name: "attachmentsList", title: t("attachments") },
    { name: "rejectionReason", title: t("rejection_reason") },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
  ];

  const commitChanges = ({ added, changed, deleted }) => {
    if (deleted) {
      let row: any = dataModified.find((row) => row.id === deleted[0]);
      setRequestIdToRemove(row.id);
    } else if (added) {
      fetchAgain();
      setSnackbarState([true, "success", t("application_added")]);
    } else if (changed) {
      fetchAgain();
      setSnackbarState([true, "success", t("application_edited")]);
    }
  };

  const [openBackOffDaysDialog, setOpenBackOffDaysDialog] = useState(false);
  const [currentRow, setCurrentRow] = useState<any>(null);

  const ActionColumnEnhanced: React.FC = (props) => {
    let newProps = {
      ...props,
      openBackOffDaysDialog,
      setOpenBackOffDaysDialog,
      setCurrentRow,
    };

    return ActionColumn(newProps);
  };

  return (
    <>
      <TopBanner pathName={t("holiday_applications")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            darkTheme={true}
            confirmButtonRight={
              <div className={styles.datepickerOptions}>
                <div className={styles.changeDateArrows}>
                  <div
                    onClick={() => {
                      let startDateLocal = new Date(startDate);
                      startDateLocal = getBeginningOfDate(
                        startDateLocal,
                        "Month"
                      );
                      startDateLocal.setMonth(startDateLocal.getMonth() - 1);
                      setStartDate(startDateLocal);

                      let endDateLocal = new Date(startDateLocal);
                      endDateLocal = getEndOfDate(endDateLocal, "Month");
                      setEndDate(endDateLocal);
                    }}
                  >
                    <FontAwesomeIcon
                      className={styles.faAngleArrow}
                      icon={faAngleLeft}
                    />
                  </div>
                  <div
                    onClick={() => {
                      let startDateLocal = new Date(startDate);
                      startDateLocal = getBeginningOfDate(
                        startDateLocal,
                        "Month"
                      );
                      startDateLocal.setMonth(startDateLocal.getMonth() + 1);
                      setStartDate(startDateLocal);

                      let endDateLocal = new Date(startDateLocal);
                      endDateLocal = getEndOfDate(endDateLocal, "Month");
                      setEndDate(endDateLocal);
                    }}
                  >
                    <FontAwesomeIcon
                      className={styles.faAngleArrow}
                      icon={faAngleRight}
                    />
                  </div>
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <CheckboxStyled
                        checked={onlyRequests}
                        name="active"
                        onChange={() => {
                          setOnlyRequests(!onlyRequests);
                        }}
                      />
                    }
                    label={
                      <span className={styles.checkboxLabel}>
                        {t("only_requests")}
                      </span>
                    }
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <CheckboxStyled
                        checked={onlyIntroduced}
                        name="active"
                        onChange={() => {
                          setOnlyIntroduced((prev) => !prev);
                        }}
                      />
                    }
                    label={
                      <span className={styles.checkboxLabel}>
                        {t("only_introduced")}
                      </span>
                    }
                  />
                </div>
              </div>
            }
          ></DatePickerRange>
        </div>

        {!requestsPermissionsFetchingState.isFetching &&
          !fetchingState.isFetching &&
          !fetchingState.isError && (
            <TableAndActions
              rows={dataModified}
              columns={columns}
              ifAdd={requestsPermissions.canAdd}
              ifEdit={requestsPermissions.canEdit}
              ifDelete={requestsPermissions.canDelete}
              deleteCommandName={t("delete")}
              actionColumn={ActionColumnEnhanced}
              popup={AddEditHolideyRequestPopup}
              openPopup={history.location.state ? true : false}
              parallerPatchPath={"superior-time/holiday-requests"}
              settingsLayoutName={"layoutSuperiorTimeListsHolidaysRequests"}
              rowDetail={RowDetail}
              fetchObject={{
                fetchAgain,
                isError: fetchingState.isError,
                isFetching:
                  fetchingState.isFetching ||
                  deleteRequestFetchingState.isFetching ||
                  requestsPermissionsFetchingState.isFetching,
                response: fetchingState.response,
                setIsFetching,
              }}
              commitChanges={commitChanges}
            />
          )}
      </div>
      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />

      <div>
        {(startDate || endDate || onlyRequests) && (
          <AbsenceOfCoworkersComponent
            dateFrom={startDate}
            dateTo={endDate}
            onlyRequests={onlyRequests}
          />
        )}
      </div>

      <BackOffDaysFromHomeOfficePopup
        row={currentRow}
        open={openBackOffDaysDialog}
        onApplyChanges={() => {
          setOpenBackOffDaysDialog(false);
          setCurrentRow(null);
          fetchAgain();
        }}
        onCancelChanges={() => {
          setOpenBackOffDaysDialog(false);
          setCurrentRow(null);
        }}
      />
    </>
  );
}

export default HolidaysRequestsContent;
