import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useRefreshData from "../../../hooks/useRefreshData/useRefreshData";
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import RowDetail from "./RowDetail";
import styles from "./styles.module.scss";

const HomePageContent = () => {
  const { t } = useTranslation();

  const columns = [
    { name: "time", title: t("date_and_time"), type: "date_hours" },
    { name: "typeName", title: t("the_type_of_the_event") },
    { name: "roomName", title: t("room") },
    { name: "relay", title: t("relay_opened") },
  ];

  const eventsResponseCallback = useCallback((responseData: Event[]) => {
    const modified = responseData?.map((row: Event) => {
      return {
        ...row,
        roomName: row.roomName ? row.roomName : "-",
        workerName: row.firstName + " " + row.lastName,
      };
    });
    return modified;
  }, []);

  const [eventsRows, fetchingStateEvents, fetchAgainEvents] = useFetchAndSetGET<
    Event[]
  >({
    path: "worker-ac/events/newest",
    modifyResponseCallback: eventsResponseCallback,
  });

  const { refreshingTime, handleChangeRefreshingTime } =
    useRefreshData(fetchAgainEvents);

  return (
    <div>
      <TopBanner pathName={t("home_page")} />
      <div className="flexAndCenter">
        <div className={styles.container}>
          <div className={styles.headerContainer}>{t("recent_events")}</div>

          <div className={styles.contentValueContainerBlockPaper}>
            <TableComponent
              rows={eventsRows}
              columns={columns}
              layoutSettingName="layoutWorkerACNewestEvents"
              rowDetail={RowDetail}
              fetchingState={{
                fetchAgain: fetchAgainEvents,
                isError: fetchingStateEvents.isError,
                isFetching: fetchingStateEvents.isFetching,
              }}
              backgroundColor="white"
              filteringRowDefaultHidden={true}
              refreshingButton={{
                ifShow: true,
                refreshingTime: refreshingTime?.value,
                handleChangeRefreshingTime,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageContent;
