import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import ButtonStyled from "../../../helpersComponents/Button/button";
import BasicInformations from "./basicInformations";
import Questions from "./questions";
import SelectTab from "./selectTab";
import Presentations from "./presentations";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import { CircularProgress } from "@material-ui/core";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import useSubmitAddEdit from "./useSubmitAddEdit";
import styles from "./styles.module.scss";

export default function AddEditTraining({ match }) {
  const { t, i18n } = useTranslation();
  const trainingId = match.params?.trainingId;
  const { confirm } = useConfirm();
  const history = useHistory();

  /////////// tabs state
  const [currentTabName, setCurrentTabName] = useState<TabsInterface>("basics");
  const [questions, setQuestions] = useState<Question[]>([]);
  const [presentationsStore, setPresentationsStore] = useState<
    PresentationObject[]
  >([]);
  const [basicInformations, setBasicInformations] = useState<
    BasicInfoObject | undefined
  >();

  ////////// fetch types of visists
  const [typesOfVisits, fetchingStateTypesOfVisitst, fetchAgainTypesOfVisitst] =
    useFetchAndSetGET<TypeOfVisit[]>({
      path: `superior-ac/types-of-visits`,
    });

  //////////////////////// fetch training if editing
  const setFetchedData = useCallback((data) => {
    let presentationsCopy = [...data.presentations];
    presentationsCopy.forEach((presentation, i) => {
      let minTime = getBeginningOfDate(new Date(), "Day");
      minTime.setSeconds(presentation.minTime);
      presentationsCopy[i]["minTime"] = minTime;
    });
    setQuestions(data.questions);
    setPresentationsStore(presentationsCopy);
    return data;
  }, []);

  const [training, fetchingStateTraining, fetchAgainTraining] =
    useFetchAndSetGET<TrainingObjectPutPost>({
      path: `superior-ac/trainings/${trainingId}`,
      startFetchOnInitial: trainingId ? true : false,
      modifyResponseCallback: setFetchedData,
    });

  ////////////////////////////////// submit whole form

  const [submit, submitingState] = useSubmitAddEdit({
    setCurrentTabName,
    training,
    questions,
    presentationsStore,
  });

  ///////////////////// check if disable multiSelectionInQuestions checkbox
  const [
    ifDisableMultiSelectionInQuestionsCheckbox,
    setIfDisableMultiSelectionInQuestionsCheckbox,
  ] = useState(false);

  useEffect(() => {
    if (currentTabName === "basics") {
      let ifDisabled = questions.some((question) => {
        let numberSelectedAnswers = 0;
        if (question.isAnswerACorrect) numberSelectedAnswers += 1;
        if (question.isAnswerBCorrect) numberSelectedAnswers += 1;
        if (question.isAnswerCCorrect) numberSelectedAnswers += 1;
        if (question.isAnswerDCorrect) numberSelectedAnswers += 1;
        return numberSelectedAnswers > 1;
      });
      setIfDisableMultiSelectionInQuestionsCheckbox(ifDisabled);
    }
  }, [questions, currentTabName]);

  const getLocalizedTrainingName = useCallback(() => {
    if (training) {
      switch (i18n.language) {
        case "en":
          return training.nameEN;

        case "de":
          return training.nameDE;

        case "ru":
          return training.nameRU;

        case "uk":
          return training.nameUK;

        default:
          return training.namePL;
      }
    }

    return "";
  }, [i18n.language, training]);

  return (
    <div>
      <TopBanner
        pathName={
          trainingId
            ? t("editing_training") + ` - ${getLocalizedTrainingName() ?? ""}`
            : t("adding_training")
        }
        extraElementNextToPath={
          <ButtonStyled
            onClick={async () => {
              let selectedbuttonId = await confirm({
                text:
                  t(
                    "are_you_sure_you_want_to_come_back_unsaved_changes_will_be_lost"
                  ) + ".",
                buttons: [
                  { buttonName: t("cancel"), buttonId: 0 },
                  { buttonName: t("back"), buttonId: 1 },
                ],
              });
              if (selectedbuttonId === 1) {
                history.push({
                  pathname: `/superior-ac/trainings`,
                });
              }
            }}
          >
            {t("back")}
          </ButtonStyled>
        }
      />

      <div className="flexAndCenter">
        <div>
          {currentTabName === null ? (
            <CircularProgress size={30} />
          ) : (
            <SelectTab
              currentTabName={currentTabName}
              setCurrentTabName={setCurrentTabName}
            />
          )}
        </div>
        <LoadingWrapper
          isLodadingProgress={
            fetchingStateTypesOfVisitst.isFetching ||
            (fetchingStateTraining.isFetching && trainingId)
          }
          isError={
            fetchingStateTypesOfVisitst.isError || fetchingStateTraining.isError
          }
          setIfFetchAgain={() => {
            if (fetchingStateTypesOfVisitst.isError) {
              fetchAgainTypesOfVisitst();
            }
            if (fetchingStateTraining.isError) {
              fetchAgainTraining();
            }
          }}
        >
          <span style={currentTabName === "basics" ? {} : { display: "none" }}>
            <BasicInformations
              typesOfVisits={typesOfVisits}
              training={training}
              setBasicInformations={setBasicInformations}
              setcurrentTabName={setCurrentTabName}
              ifDisableMultiSelectionInQuestionsCheckbox={
                ifDisableMultiSelectionInQuestionsCheckbox
              }
            />
          </span>
          {currentTabName === "presentation" && (
            <>
              <Presentations
                presentationsStore={presentationsStore}
                setPresentationsStore={setPresentationsStore}
              />
              <div>
                <div className={styles.bottomButtons}>
                  <ButtonStyled
                    onClick={() => {
                      setCurrentTabName("basics");
                    }}
                  >
                    {t("back")}
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={() => {
                      setCurrentTabName("questions");
                    }}
                  >
                    {t("go_to_the_questions")}
                  </ButtonStyled>
                </div>
              </div>
            </>
          )}
          {currentTabName === "questions" && (
            <Questions
              questions={questions}
              setQuestions={setQuestions}
              multiSelectionInQuestions={
                basicInformations?.multiSelectionInQuestions
              }
            />
          )}
        </LoadingWrapper>
        {currentTabName === "questions" && (
          <div>
            <div className={styles.bottomButtons}>
              <ButtonStyled
                onClick={() => {
                  setCurrentTabName("presentation");
                }}
              >
                {t("back")}
              </ButtonStyled>

              {submitingState.isFetching ? (
                <div>
                  <CircularProgress size={30} />
                </div>
              ) : (
                <ButtonStyled
                  onClick={() => {
                    if (basicInformations) {
                      submit({ values: basicInformations });
                    }
                  }}
                >
                  {t("confirm")}
                </ButtonStyled>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
