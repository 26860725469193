import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import { useSnackbar } from "notistack";

const SnackbarCloseButton = ({ id }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(id)}>
      <FontAwesomeIcon icon={faTimes} color="white" size="sm" />
    </IconButton>
  );
};

export default SnackbarCloseButton;
