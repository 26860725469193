import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import TableComponentMemo from "../../../helpersComponents/TableComponent/tableComponent";
import PresentationPopup from "./presentationPopup";
import PresentationRowDetails from "./presentationRowDetails";
import styles from "./styles.module.scss";

interface Props {
  presentationsStore: PresentationObject[];
  setPresentationsStore: React.Dispatch<PresentationObject[]>;
}

export default function Presentations({
  setPresentationsStore,
  presentationsStore,
}: Props) {
  const { t, i18n } = useTranslation();
  const { confirm } = useConfirm();

  const columns = [
    { name: "order", title: t("order") },
    { name: "title", title: t("name") },
    { name: "isMovieIcon", title: t("movie") },
    { name: "minTimeString", title: t("minimum_display_time") },
  ];

  const [rows, setRows] = useState<any[]>([]);

  const checkIsMovie = useCallback(
    (presentation: PresentationObject) => {
      switch (i18n.language) {
        case "en":
          return presentation.isMovieEN;

        case "de":
          return presentation.isMovieDE;

        case "ru":
          return presentation.isMovieRU;

        case "uk":
          return presentation.isMovieUK;

        default:
          return presentation.isMoviePL;
      }
    },
    [i18n.language]
  );

  const getLocalizedTitle = useCallback(
    (presentation: PresentationObject) => {
      switch (i18n.language) {
        case "en":
          return presentation.titleEN;

        case "de":
          return presentation.titleDE;

        case "ru":
          return presentation.titleRU;

        case "uk":
          return presentation.titleUK;

        default:
          return presentation.titlePL;
      }
    },
    [i18n.language]
  );

  useEffect(() => {
    setRows(
      presentationsStore.map((presentation, index) => {
        return {
          ...presentation,
          id: index,
          title: getLocalizedTitle(presentation),
          isMovieIcon: checkIsMovie(presentation) ? (
            <FontAwesomeIcon
              className={styles.faCheckCircle}
              icon={faCheckCircle}
            />
          ) : (
            <FontAwesomeIcon
              className={styles.faTimesCircle}
              icon={faTimesCircle}
            />
          ),
          minTimeString: new Date(presentation.minTime).toLocaleString(
            t("scheduler_language"),
            {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }
          ),
        };
      })
    );
  }, [presentationsStore, checkIsMovie, t, i18n.language, getLocalizedTitle]);

  return (
    <>
      <div>
        <TableComponentMemo
          layoutSettingName=""
          ifHideWholeToolbalr={true}
          rows={rows}
          rowDetail={PresentationRowDetails}
          columns={columns}
          actionsColumnUpdated={{
            popup: PresentationPopup,
            addButton: {
              ifShow: true,
              successCallbackAdd: (values: PresentationObject) => {
                let presentationsStoreCopy = [...presentationsStore];
                presentationsStoreCopy.push(values);
                setPresentationsStore(presentationsStoreCopy);
              },
            },
            editButton: {
              ifShow: true,
              name: t("edit"),
              successCallbackEdit: (values: PresentationObject, row) => {
                let presentationsStoreCopy = [...presentationsStore];
                presentationsStoreCopy[row.id] = {
                  ...values,
                  id: presentationsStore[row.id].id,
                };
                setPresentationsStore(presentationsStoreCopy);
              },
            },
            removeButton: {
              ifShow: true,
              onClick: async (row) => {
                let selectedbuttonId = await confirm({
                  text: t("are_you_sure_you_want_to_delete_it") + "?",
                  buttons: [
                    { buttonName: t("remove"), buttonId: 0 },
                    { buttonName: t("cancel"), buttonId: 1 },
                  ],
                });
                if (selectedbuttonId === 0) {
                  let presentationsStoreCopy = [...presentationsStore];
                  presentationsStoreCopy.splice(row.id, 1);
                  setPresentationsStore(presentationsStoreCopy);
                }
              },
            },
          }}
        />
      </div>
    </>
  );
}
