import { useTranslation } from "react-i18next";
import { TypeOfVisit } from "../../../../Constants/typeOfVisitInPorts";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import AttachmentsListInRowDetail from "../../Attachments/attachmentsListInRowDetail";
import AccordionStyled from "../../MaterialUi/Accordion/AccordionStyled";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../../RowDetailsHelpers";

const RowDetailNotification = ({
  row,
  pathApiAttachments,
}: {
  row: GuestVisit;
  pathApiAttachments: string;
}) => {
  const { t } = useTranslation();

  return (
    <RowDetailsContainer>
      {/*  */}
      <AccordionStyled title={t("person")} defaultExpanded={true}>
        <>
          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("person")}:</RowDetailsTitle>
              <RowDetailsValue>{`${row?.guestFirstName} ${row?.guestLastName}`}</RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("date_of_birth")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestDateOfBirth
                  ? toLocaleStringCustom({ date: row?.guestDateOfBirth, t })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("email")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestEmail ? row.guestEmail : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("id_number")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestIDNumber ? row.guestIDNumber : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("training_expiry_date")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestTrainingExpiryDate
                  ? toLocaleStringCustom({
                      date: row?.guestTrainingExpiryDate,
                      t,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("company")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.companyName ? row.companyName : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>
        </>
      </AccordionStyled>

      {/*  */}
      <AccordionStyled title={t("information")} defaultExpanded={true}>
        <>
          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("entry_company_area")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.entryDate
                  ? toLocaleStringCustom({
                      date: row.entryDate,
                      t,
                      ifShowHours: true,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("exit_company_area")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.exitDate
                  ? toLocaleStringCustom({
                      date: row.exitDate,
                      t,
                      ifShowHours: true,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("type_of_entry")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.typeOfEntry ? row.typeOfEntry : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("card_tag")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.cardUniversalNumber ? row.cardUniversalNumber : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("notification_number")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.notificationNumber ? row.notificationNumber : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("plate_number")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.registrationNumber ? row.registrationNumber : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("permission_zone")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.permissionZoneName ? row.permissionZoneName : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("type_of_visit")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.typeOfVisit
                  ? row.typeOfVisitId === TypeOfVisit.BUSINESS &&
                    row.isToPfsoAndShiftDirector
                    ? t("application_for_training")
                    : row.typeOfVisit
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("confirmation_employee")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.employeeFullName ? row.employeeFullName + ", " : ""}
                {row.employeeDateConfirmation
                  ? toLocaleStringCustom({
                      date: row.employeeDateConfirmation,
                      t,
                      ifShowHours: true,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("confirmation_pfso")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.pfsoFullName ? row.pfsoFullName + ", " : ""}
                {row.pfsoDateConfirmation
                  ? toLocaleStringCustom({
                      date: row.pfsoDateConfirmation,
                      t,
                      ifShowHours: true,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("adding_person")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.addingPersonFullName ? row.addingPersonFullName : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("comments")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.comments ? row.comments : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/* ZAŁĄCZNIKI */}
          {row?.attachments && row.attachments?.length > 0 && (
            <AttachmentsListInRowDetail
              title={t("attachments")}
              files={row?.attachments}
              path={pathApiAttachments}
            />
          )}
        </>
      </AccordionStyled>

      {/* WARUNKI PRACY */}
      {row?.typeOfVisitId === TypeOfVisit.COMPLETION_OF_THE_WORK &&
        row?.isWorkPermitRequired !== null && (
          <div>
            <AccordionStyled
              title={t("working_conditions")}
              defaultExpanded={true}
            >
              <>
                <RowDetailsRow>
                  <RowDetailsTitleValue>
                    <RowDetailsTitle>
                      {t("is_work_permit_required")}:
                    </RowDetailsTitle>
                    <RowDetailsValue>
                      {row?.isWorkPermitRequired === null
                        ? "-"
                        : row?.isWorkPermitRequired
                        ? t("yes")
                        : t("no")}
                    </RowDetailsValue>
                  </RowDetailsTitleValue>

                  <RowDetailsTitleValue>
                    <RowDetailsTitle>
                      {t("is_work_particularly_dangerous")}:
                    </RowDetailsTitle>
                    <RowDetailsValue>
                      {row?.isWorkParticularlyDangerous === null
                        ? "-"
                        : row?.isWorkParticularlyDangerous
                        ? t("yes")
                        : t("no")}
                    </RowDetailsValue>
                  </RowDetailsTitleValue>
                </RowDetailsRow>

                {/*  */}
                <RowDetailsRow>
                  <RowDetailsTitleValue>
                    <RowDetailsTitle>
                      {t("work_permit_number")}:
                    </RowDetailsTitle>
                    <RowDetailsValue>
                      {row?.workPermitNumber ? row?.workPermitNumber : "-"}
                    </RowDetailsValue>
                  </RowDetailsTitleValue>

                  <RowDetailsTitleValue>
                    <RowDetailsTitle>{t("work_area")}:</RowDetailsTitle>
                    <RowDetailsValue>
                      {row?.workArea ? row?.workArea : "-"}
                    </RowDetailsValue>
                  </RowDetailsTitleValue>
                </RowDetailsRow>

                {/*  */}
                <RowDetailsRow>
                  <RowDetailsTitleValue>
                    <RowDetailsTitle>
                      {t("work_particularly_dangerous_comments")}:
                    </RowDetailsTitle>
                    <RowDetailsValue>
                      {row?.workParticularlyDangerousComments
                        ? row?.workParticularlyDangerousComments
                        : "-"}
                    </RowDetailsValue>
                  </RowDetailsTitleValue>
                </RowDetailsRow>
              </>
            </AccordionStyled>
          </div>
        )}
    </RowDetailsContainer>
  );
};

export default RowDetailNotification;
