import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  children: React.ReactNode;
  rightActions?: React.ReactNode;
}

export default function SelectDateStyledContainer({
  title,
  children,
  rightActions,
}: Props): ReactElement {
  return (
    <div className="selectDateDarkGlobal">
      <div className={styles.selectContainer}>
        <div className={styles.inputTitle}>{title}</div>
        <div className={styles.selectInput}>{children}</div>
        <>{rightActions && rightActions}</>
      </div>
    </div>
  );
}
