import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import TwoColumnsRowsContainer from "../../../PopupHelpers/twoColumnsRowsContainer";
import Row from "../../../PopupHelpers/row";
import RowTitle from "../../../PopupHelpers/rowTitle";
import CheckboxWithLabelFormik from "../../../FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import RowInput from "../../../PopupHelpers/rowInput";
import DatePickerFormik from "../../../FormikInputs/DatePicker/datePicker";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AutocompleteFormik from "../../../FormikInputs/Autocomplete/autocomplete";
import ButtonStyled from "../../../Button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  employees: IEmployee[];
  onAddNewItem?: (item: ISharedNotificationInPortsItem) => void;
}

const PopupAddEmployeeToNotificationForm = ({
  employees,
  onAddNewItem,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Formik
        initialValues={{
          employee: null,
          dateFrom: null,
          dateTo: null,
        }}
        onSubmit={(values: any) => {
          let tempItem: ISharedNotificationInPortsItem = {
            id: 0,
            name: values.employee?.fullName,
            employeeId: values.employee?.id,
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
          };

          if (onAddNewItem) {
            onAddNewItem(tempItem);
          }
        }}
        validationSchema={Yup.object({
          employee: Yup.object().nullable().required(t("field_required")),
          dateFrom: Yup.date()
            .nullable()
            .when("dateRange", {
              is: true,
              then: Yup.date().nullable().required(t("field_required")),
            }),
          dateTo: Yup.date()
            .nullable()
            .when("dateRange", {
              is: true,
              then: Yup.date().nullable().required(t("field_required")),
            })
            .min(
              Yup.ref("dateFrom"),
              t("date_to_must_be_later_than_date_from")
            ),
        })}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form noValidate id="submitForm">
              <div className={styles.collaboratorContainer}>
                <div>
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("select_employee")}:</RowTitle>
                      <RowInput>
                        <AutocompleteFormik
                          options={employees || []}
                          getOptionLabel={(option: IEmployee) => {
                            return `${option.fullName} ${
                              option.email ? "[" + option.email + "]" : ""
                            } `;
                          }}
                          width="100%"
                          style={{ width: "300px", marginRight: "10px" }}
                          getOptionSelected={(
                            option: IEmployee,
                            value: IEmployee
                          ) => {
                            return option.id === value.id;
                          }}
                          label={t("select_employee")}
                          name={"employee"}
                          required={true}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>
                </div>

                <div>
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>
                        <CheckboxWithLabelFormik
                          name={"dateRange"}
                          ifCircle={true}
                          label={
                            <span style={{ fontSize: "0.9rem" }}>
                              {t("date_range")}
                            </span>
                          }
                        />
                      </RowTitle>

                      {values["dateRange"] && (
                        <RowInput>
                          <DatePickerFormik
                            customLabel={t("date_from")}
                            name="dateFrom"
                            view={["year", "month", "date"]}
                            format="dd-MM-yyyy"
                            required={true}
                          />
                        </RowInput>
                      )}
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{}</RowTitle>

                      {values["dateRange"] && (
                        <RowInput>
                          <DatePickerFormik
                            customLabel={t("date_to2")}
                            name="dateTo"
                            view={["year", "month", "date"]}
                            format="dd-MM-yyyy"
                            required={true}
                          />
                        </RowInput>
                      )}
                    </Row>
                  </TwoColumnsRowsContainer>
                </div>

                <div className={styles.add_employee_to_list_button}>
                  <div>
                    <ButtonStyled type="submit">
                      {t("add_employee_to_list")}{" "}
                      <FontAwesomeIcon icon={faPlus} />
                    </ButtonStyled>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PopupAddEmployeeToNotificationForm;
