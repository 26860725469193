import { useCallback, useEffect, useState } from "react";
import LoadingWrapper from "../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import Row from "../../../../helpersComponents/PopupHelpers/row";
import RowInput from "../../../../helpersComponents/PopupHelpers/rowInput";
import RowTitle from "../../../../helpersComponents/PopupHelpers/rowTitle";
import TwoColumnsRowsContainer from "../../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import erro400getTranslatedErrorString from "../../../../../HelpersFunctions/erro400getTranslatedErrorString";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { useTranslation } from "react-i18next";
//import { useAppSelector } from "../../../../../store/hooks";
//import { selectAuthUser } from "../../../../../reducers/session";
//import { selectSettings } from "../../../../../reducers/settings";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import UploadFileFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/UploadFileField/uploadFileField";

const AddEditImagePopupContent: React.FC<any> = ({
  rowId,
  control,
  register,
  /*errors,
  fields,
  append,
  remove,*/
  setValue,
  getValues,
  setErrorMessage,
  successCallback,
  handleSubmit,
  setIsFetching,
  watch,
}) => {
  const { t } = useTranslation();
  //const authUserType = useAppSelector(selectAuthUser).currentProfile?.type;
  //const settings = useAppSelector(selectSettings);
  const [bodyRequest, setBodyRequest] = useState<any>(false);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
  }, [successCallback]);

  const [image, fetchingStateImage, fetchAgainImage] = useFetchAndSetGET({
    path: `sms-administrator/images-library/${rowId}`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (rowId) {
      fetchAgainImage();
    }
  }, [rowId, fetchAgainImage]);

  const errorCallback = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setErrorMessage(error);
      }
    },
    [t, setErrorMessage]
  );

  const [fetchingStateAddEditImage, fetchAgainAddEditImage] =
    useFetchOtherThanGET({
      path: `sms-administrator/images-library${rowId ? "/" + rowId : ""}`,
      method: rowId ? "PUT" : "POST",
      body: bodyRequest,
      setBody: setBodyRequest,
      successCallback: successCallbackAndClose,
      errorCallback,
      disableErrorSnackbar: true,
    });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainAddEditImage();
    }
  }, [bodyRequest, fetchAgainAddEditImage]);

  useEffect(() => {
    setIsFetching(fetchingStateAddEditImage.isFetching);
  }, [fetchingStateAddEditImage, setIsFetching]);

  const onSubmit = (data: any) => {
    let jsonDataToSend: any = {
      name: data.name,
      description: data.description,
    };

    let formData = new FormData();
    formData.append("requestImageData", JSON.stringify(jsonDataToSend));

    if (data.image) {
      formData.append("requestImage", data.image[0]);
    }

    setBodyRequest(formData);
  };

  useEffect(() => {
    if (
      rowId &&
      !fetchingStateImage.isFetching &&
      fetchingStateImage.response?.status === 200
    ) {
      setValue("name", image.name);
      setValue("description", image.description);

      if (image.fileName && image.fileSize > 0) {
        setValue("image", [
          {
            name: image.fileName,
            size: image.fileSize,
          },
        ]);
      }
    }
  }, [
    fetchingStateImage.isFetching,
    fetchingStateImage.response?.status,
    rowId,
    setValue,
    image?.name,
    image?.description,
    image?.fileName,
    image?.fileSize,
  ]);

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={fetchingStateImage.isFetching}
        isError={fetchingStateImage.isError}
        setIfFetchAgain={() => {
          if (fetchingStateImage.isError) {
            fetchAgainImage();
          }
        }}
      >
        <form
          key={"add_image_to_library_form"}
          onSubmit={handleSubmit(onSubmit)}
          id="submitForm"
          noValidate
        >
          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("name")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"name"}
                  control={control}
                  label={t("image_name")}
                  required={true}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("description")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"description"}
                  control={control}
                  label={t("image_description")}
                  required={true}
                  multiline
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("file")}:</RowTitle>
              <RowInput>
                <UploadFileFieldReactHookForm
                  id="image"
                  register={register}
                  name={"image"}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  required={true}
                  accept=".mp3, .wav"
                  watch={watch}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>
        </form>
      </LoadingWrapper>
    </>
  );
};

export default AddEditImagePopupContent;
