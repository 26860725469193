import { TFunction } from "react-i18next";

export default function erro400getTranslatedErrorString(
  errors,
  t: TFunction<"translation">
) {
  if (errors === undefined) return "";
  let error = "";
  Object.keys(errors).forEach((errorObject) => {
    errors[errorObject].forEach((errorString) => {
      error += t(errorString) + " ";
    });
  });
  return error;
}
