//import "./events.scss";
import React, { useState, useEffect, useCallback } from "react";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import Button from "../../../helpersComponents/Button/button";
import { CircularProgress } from "@material-ui/core";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import convertDateFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useUpdateLastSelectedWorkers from "../../../../hooks/useUpdateLastSelectedWorkers/useUpdateLastSelectedWorkers";

function ListOfPermitsContent() {
  const { t } = useTranslation();

  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([]);
  const [, setSelectedWorkersString] = useState<string | undefined>(undefined);
  const [, setAllWorkersSelected] = useState<boolean>(false);

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Month")
  );
  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Month"));

  const [dataModified, setDataModified] = useState<null | any[]>(null);

  const columns = [
    { name: "createdAt", title: t("date_created"), type: "date_hours" },
    { name: "employee", title: t("employee") },
    { name: "dateFrom", title: t("from"), type: "date_hours" },
    { name: "dateTo", title: t("to"), type: "date_hours" },
    { name: "permissionTypeName", title: t("type_of_permit") },
    { name: "reason", title: t("reason") },
    { name: "description", title: t("description") },
  ];

  const successCallback = useCallback((responseData) => {
    setDataModified(responseData.resJson);
  }, []);

  const [bodyRequest, setBodyRequest] = useState<false | string>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `superior-time/list-of-permits`,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
    successCallback: successCallback,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  const layoutSettingName = "layoutSuperiorTimePermitsListSelectWorkers";

  const [updateLastSelectedWorkers] =
    useUpdateLastSelectedWorkers(selectedWorkers);

  const confirmFilters = () => {
    let selectedWorkersLocal = [...selectedWorkers];
    let selectedWorkersStringLocal: string = "";
    selectedWorkersLocal.forEach((selectedId) => {
      selectedWorkersStringLocal =
        selectedWorkersStringLocal + selectedId.toString() + ",";
    });
    selectedWorkersStringLocal = selectedWorkersStringLocal.replace(
      /,\s*$/,
      ""
    );

    setSelectedWorkersString(selectedWorkersStringLocal);
    if (!selectedWorkersStringLocal) {
      return;
    }

    setDataModified(null);

    let queryObj = {
      dateFrom: convertDateFetch(startDate),
      dateTo: convertDateFetch(endDate),
      timeWorkersIds: selectedWorkersStringLocal.split(","),
    };

    setBodyRequest(JSON.stringify(queryObj));
    updateLastSelectedWorkers();
  };

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {fetchingStatePostData.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <Button
            onClick={() => {
              confirmFilters();
            }}
          >
            {t("get_employees")}
          </Button>
        )}
      </div>
    </>
  );

  return (
    <>
      <TopBanner pathName={t("list_of_permits")} />
      <div className="flexAndCenter">
        <div>
          <div>
            <WorkerList
              layoutSettingName={layoutSettingName}
              setSelectedWorkers={setSelectedWorkers}
              selectedWorkers={selectedWorkers}
              pageSize={10}
              setAllWorkersSelected={setAllWorkersSelected}
            />
          </div>
        </div>
        <div>
          <DatePickerRange
            view={["year", "month", "date", "hours", "minutes"]}
            format="dd-MM-yyyy HH:mm"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            keyboardDateTimePicker={true}
            darkTheme={true}
            confirmButtonRight={confirmButtonRight}
            setDate2={setEndDate}
          ></DatePickerRange>
        </div>
        <div>
          <LoadingWrapper
            isLodadingProgress={fetchingStatePostData.isFetching}
            isError={fetchingStatePostData.isError}
            setIfFetchAgain={() => {
              if (fetchingStatePostData.isError) {
                fetchAgainPostData();
              }
            }}
          >
            {!fetchingStatePostData.isFetching &&
              !fetchingStatePostData.isError &&
              dataModified !== null && (
                <TableComponent
                  layoutSettingName="layoutSuperiorTimePermitsList"
                  rows={dataModified}
                  columns={columns}
                />
              )}
          </LoadingWrapper>
        </div>
      </div>
    </>
  );
}

export default ListOfPermitsContent;
