import { ProfileType } from "../../../enums/profileType";
import SupportContent from "../../helpersComponents/Support/SupportContent";

const SupportPfso = () => {
  return (
    <SupportContent
      pathApiListsOfEmployees="pfso/lists-of-employees"
      layoutSettingName="layoutPFSOListsOfEmployeesRecords"
      profileType={ProfileType.PFSO}
    />
  );
};

export default SupportPfso;
