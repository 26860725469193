import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../PopupHelpers";
import PopupContentArrivalOfShip from "./PopupContentArrivalOfShip";

interface IProps {
  isOpen: boolean;
  rowId?: number;
  closePopup: () => void;
  successCallback: () => void;
  pathApiArrivalsOfShips: string;
  pathApiShips: string;
  pathApiAttachmentsInPorts: string;
}

const PopuArrivalOfShip: React.FC<IProps> = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
  pathApiArrivalsOfShips,
  pathApiShips,
  pathApiAttachmentsInPorts,
}) => {
  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [requestBody, setRequestBody] = useState<false | any>(false);

  const [crewListAdded, setCrewListAdded] = useState<boolean | null>(null);

  const validateCrewList = useCallback(
    (rowId, crewList, crewListToRemove, oldCrewListFile) => {
      if (
        (!rowId && (!crewList || crewList?.length === 0)) ||
        (rowId && !oldCrewListFile) ||
        (rowId &&
          (!crewList || crewList?.length === 0) &&
          crewListToRemove?.length === oldCrewListFile.length)
      ) {
        return false;
      }

      return true;
    },
    []
  );

  const onSubmit = (values: AddArrivalOfShip) => {
    setErrorMessage("");

    const isValidate = validateCrewList(
      rowId,
      values.crewList,
      values.crewListToRemove,
      values.oldCrewListFile
    );

    setCrewListAdded(isValidate);
    if (!isValidate) return;

    if (rowId) {
      let formData: any = new FormData();
      const filesToRemove = values.shipAttachmentsToRemove?.concat(
        values.crewListToRemove ? values.crewListToRemove : []
      );
      formData.append(
        "requestData",
        JSON.stringify({
          id: rowId,
          shipId: values.ship.id,
          dateOfArrival: convertDateFetch(values.dateOfArrival),
          shipAttachmentsToRemove: filesToRemove,
          requiredFilesName: values.crewList
            ? values.crewList?.map((attachment) => attachment.name)
            : [],
        })
      );

      const attachments = values.shipAttachments?.concat(values.crewList);
      attachments?.forEach((file: AddShipAttachment) => {
        formData.append("Attachments", file);
      });

      setRequestBody(formData);
    } else {
      let formData: any = new FormData();
      formData.append(
        "requestData",
        JSON.stringify({
          shipId: values.ship.id,
          dateOfArrival: convertDateFetch(new Date(values.dateOfArrival)),
          requiredFilesName: values.crewList
            ? values.crewList?.map((attachment) => attachment.name)
            : [],
        })
      );

      const attachments = values.shipAttachments?.concat(values.crewList);
      attachments?.forEach((file: AddShipAttachment) => {
        formData.append("Attachments", file);
      });

      setRequestBody(formData);
    }
  };

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${pathApiArrivalsOfShips}${rowId !== undefined ? `/${rowId}` : ""}`,
    method: rowId !== undefined ? "PUT" : "POST",
    body: requestBody,
    setBody: setRequestBody,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, fetchAgainPostData]);

  return (
    <DialogStyled open={isOpen} onClose={closePopup}>
      <DialogTitleStyled title={t("add_arrival_of_ship")} />
      <PopupContentArrivalOfShip
        rowId={rowId}
        onSubmit={onSubmit}
        crewListAdded={crewListAdded}
        setCrewListAdded={setCrewListAdded}
        pathApiArrivalsOfShips={pathApiArrivalsOfShips}
        pathApiShips={pathApiShips}
        pathApiAttachmentsInPorts={pathApiAttachmentsInPorts}
      />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopuArrivalOfShip;
