import { ComponentType, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileType } from "../../../enums/profileType";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useRefreshData from "../../../hooks/useRefreshData/useRefreshData";
import ImportFile from "../../agent/ImportFile";
import DatePickerRange from "../DatePickerRange/datePickerRange";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import PopupArrivalOfShip from "./PopupArrivalOfShip";
import RowDetailArrivalOfShip from "./RowDetailArrivalOfShip";
import useColumns from "./useColumns";

interface IProps {
  pathApiArrivalsOfShips: string;
  pathApiAttachmentsInPorts: string;
  pathApiImportFromFile?: string;
  pathApiShips?: string;
  layoutSettingsName: string;
  navigationComponent?: ComponentType<any>;
  hideActionsAndVisibilityColumns?: boolean;
  hideRefreshingButton?: boolean;
  profileType: ProfileType;
}

const ContentArrivalsOfShips: React.FC<IProps> = ({
  pathApiArrivalsOfShips,
  pathApiAttachmentsInPorts,
  pathApiImportFromFile,
  pathApiShips,
  layoutSettingsName,
  navigationComponent,
  hideActionsAndVisibilityColumns,
  hideRefreshingButton,
  profileType,
}) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date>(
    getBeginningOfDate(new Date(), "Day")
  );
  const [endDate, setEndDate] = useState<Date>(
    getEndOfDate(new Date(), "Day", { extraDays: 7 })
  );

  const columns = useColumns(profileType);

  const [
    arrivalOfShipsRows,
    fetchingStateArrivalOfShips,
    fetchAgainArrivalOfShips,
  ] = useFetchAndSetGET<ArrivalOfShip[]>({
    path: `${pathApiArrivalsOfShips}?DateFrom=${convertDateFetch(
      startDate
    )}&DateTo=${convertDateFetch(endDate)}`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    fetchAgainArrivalOfShips();
  }, [startDate, endDate, fetchAgainArrivalOfShips]);

  const [arrivalOfShipIdToRemove, setArrivalOfShipIdToRemove] = useState<
    false | number
  >(false);

  const [removeFetchingState, removeFetchAgain] = useFetchOtherThanGET({
    path: `${pathApiArrivalsOfShips}/${arrivalOfShipIdToRemove}`,
    method: "DELETE",
    body: arrivalOfShipIdToRemove,
    setBody: setArrivalOfShipIdToRemove,
    successCallback: fetchAgainArrivalOfShips,
  });

  useEffect(() => {
    if (arrivalOfShipIdToRemove !== false) {
      removeFetchAgain();
    }
  }, [arrivalOfShipIdToRemove, removeFetchAgain]);

  const { refreshingTime, handleChangeRefreshingTime } = useRefreshData(
    fetchAgainArrivalOfShips
  );

  const exampleFile = {
    name: "przybycie_statku_example",
    filePath: "Import_przybycia_statku_przyklad.xls",
  };

  const NavigationComponent = navigationComponent;

  return (
    <div>
      <div>
        <TopBanner pathName={t("arrival_of_ships")} />
      </div>
      <div className="flexAndCenter">
        {NavigationComponent !== undefined && (
          <div>
            <NavigationComponent />
          </div>
        )}

        <div>
          <DatePickerRange
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            view={["year", "month", "date"]}
            darkTheme={true}
          >
            <div>
              {pathApiImportFromFile && (
                <ImportFile
                  setFetchAgain={fetchAgainArrivalOfShips}
                  exampleFile={exampleFile}
                  address={pathApiImportFromFile}
                />
              )}
            </div>
          </DatePickerRange>
        </div>

        <div>
          <TableComponentMemo
            columns={columns}
            rows={arrivalOfShipsRows}
            layoutSettingName={layoutSettingsName}
            hideActionsAndVisibilityColumns={
              (hideActionsAndVisibilityColumns && true) as true
            }
            rowDetail={(props) => (
              <RowDetailArrivalOfShip
                pathApiAttachmentsInPorts={pathApiAttachmentsInPorts}
                {...props}
              />
            )}
            fetchingState={{
              isFetching:
                fetchingStateArrivalOfShips.isFetching ||
                removeFetchingState.isFetching,
              isError: fetchingStateArrivalOfShips.isError,
              fetchAgain: fetchAgainArrivalOfShips,
            }}
            refreshingButton={{
              ifShow: hideRefreshingButton ? false : true,
              refreshingTime: refreshingTime?.value,
              handleChangeRefreshingTime,
            }}
            actionsColumnUpdated={{
              popup: (props) => {
                return (
                  <PopupArrivalOfShip
                    pathApiArrivalsOfShips={pathApiArrivalsOfShips}
                    pathApiShips={pathApiShips}
                    pathApiAttachmentsInPorts={pathApiAttachmentsInPorts}
                    {...props}
                  />
                );
              },
              successCallbackPopup: fetchAgainArrivalOfShips,
              addButton: {
                ifShow:
                  profileType === ProfileType.AGENT ||
                  profileType === ProfileType.OPERATIONAL_SUPPORT
                    ? true
                    : false,
              },
              editButton: {
                ifShow:
                  profileType === ProfileType.AGENT ||
                  profileType === ProfileType.OPERATIONAL_SUPPORT
                    ? true
                    : false,
                name: t("edit"),
              },
              removeButton: {
                ifShow:
                  profileType === ProfileType.AGENT ||
                  profileType === ProfileType.OPERATIONAL_SUPPORT
                    ? true
                    : false,
                onClick: (row) => {
                  setArrivalOfShipIdToRemove(row.id);
                },
              },
              refreshButton: {
                ifShow: hideActionsAndVisibilityColumns ? true : false,
                refresh: {
                  ifRefresh: true,
                  handleChangeRefreshingTime: handleChangeRefreshingTime,
                  refreshingTime: refreshingTime?.value,
                },
                color: "white",
              },
            }}
            tableEditColumnWidth={
              profileType === ProfileType.AGENT ||
              profileType === ProfileType.OPERATIONAL_SUPPORT
                ? 200
                : 180
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ContentArrivalsOfShips;
