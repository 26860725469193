import React from "react";
import { useTranslation } from "react-i18next";
import GuestsVisitsReportContent from "../../../helpersComponents/GuestsVisitsReport/GuestsVisitsReportContent";

const SupportReportShiftDirector = () => {
  const { t } = useTranslation();
  return (
    <div>
      <GuestsVisitsReportContent
        pathName="shift-director/guest-visits"
        layoutSettingName="layoutShiftDirectorVisitsReport"
        reportTitle={t("support_report")}
        onlySupport={true}
      />
    </div>
  );
};

export default SupportReportShiftDirector;
