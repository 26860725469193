import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useRefreshData from "../../../hooks/useRefreshData/useRefreshData";
import DatePickerRange from "../DatePickerRange/datePickerRange";
import { CheckboxStyled } from "../MaterialUi";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import RowDetailListOfEmployees from "./RowDetails/RowDetailListOfEmployees";
import RowDetailNotification from "./RowDetails/RowDetailNotification";
import RowDetailServicesAndDeliveries from "./RowDetails/RowDetailServicesAndDeliveries";
import RowDetailsVisits from "./RowDetails/RowDetailsVisits";
import useColumns from "./useColumns";
import moment from "moment";

interface IProps {
  pathName: string;
  layoutSettingName: string;
  pathApiAttachments?: string;
  reportTitle?: string;
  onlySupport?: boolean;
}

const GuestsVisitsReportContent: React.FC<IProps> = ({
  pathName,
  layoutSettingName,
  pathApiAttachments,
  reportTitle,
  onlySupport,
}) => {
  const { t } = useTranslation();
  const columns = useColumns(onlySupport);

  const visitsReportResponseCallback = useCallback(
    (responseData: GuestVisit[]) => {
      const modified = responseData.map((row) => {
        return {
          ...row,
          companyName: row?.companyName ? row.companyName : "-",
          targetDepartment: row?.targetDepartment ? row.targetDepartment : "-",
          typeOfEntry: row?.typeOfEntry ? row.typeOfEntry : "-",
          isFinished: (
            <CheckboxStyled checked={row.exitDate ? true : false} disabled />
          ),
          notificationNumber: row?.notificationNumber
            ? row.notificationNumber
            : "-",
        };
      });
      return modified;
    },
    []
  );

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Day", { extraDays: -7 })
  );
  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Day"));

  const [rows, fetchingState, fetchAgain] = useFetchAndSetGET<GuestVisit[]>({
    path: `${pathName}?EntryDateFrom=${convertDateFetch(
      startDate
    )}&EntryDateTo=${convertDateFetch(endDate)}`,
    modifyResponseCallback: visitsReportResponseCallback,
  });

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    fetchAgain();
  }, [startDate, endDate, fetchAgain]);

  const { refreshingTime, handleChangeRefreshingTime } =
    useRefreshData(fetchAgain);

  const customExportCellValue = useCallback(
    (row: any, columnName: string) => {
      if (columnName === "isFinished") {
        return row.exitDate ? t("yes") : t("no");
      }

      if (columnName === "listOfEmployeeDate") {
        return row.listOfEmployeeDate
          ? moment(row.listOfEmployeeDate).format("YYYY-MM-DD")
          : "";
      }

      if (columnName === "entryDate") {
        return row.entryDate
          ? moment(row.entryDate).format("YYYY-MM-DD HH:mm:ss")
          : "";
      }

      if (columnName === "exitDate") {
        return row.exitDate
          ? moment(row.exitDate).format("YYYY-MM-DD HH:mm:ss")
          : "";
      }

      return row[columnName];
    },
    [t]
  );

  return (
    <div>
      <div>
        <TopBanner pathName={reportTitle ? reportTitle : t("visits_report")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            view={["year", "month", "date"]}
            darkTheme={true}
          />
        </div>

        <div>
          <TableComponentMemo
            columns={columns}
            rows={rows}
            layoutSettingName={layoutSettingName}
            rowDetail={(data) => {
              const rowInfo = data?.row as GuestVisit;
              if (rowInfo.listOfEmployeesEmployeeId) {
                return <RowDetailListOfEmployees row={rowInfo} />;
              } else if (rowInfo.notificationId) {
                return (
                  <RowDetailNotification
                    row={rowInfo}
                    pathApiAttachments={pathApiAttachments!}
                  />
                );
              } else if (rowInfo.deliveryServiceId) {
                return <RowDetailServicesAndDeliveries row={rowInfo} />;
              }

              return <RowDetailsVisits row={rowInfo} />;
            }}
            fetchingState={{
              isFetching: fetchingState.isFetching,
              isError: fetchingState.isError,
              fetchAgain: fetchAgain,
            }}
            defaultSorting={[
              { columnName: "entryDate", direction: "asc" },
              { columnName: "exitDate", direction: "asc" },
              { columnName: "guestLastName", direction: "asc" },
              { columnName: "companyName", direction: "asc" },
            ]}
            refreshingButton={{
              ifShow: true,
              refreshingTime: refreshingTime?.value,
              handleChangeRefreshingTime,
            }}
            customExcelCellValue={customExportCellValue}
            customPdfCellValue={customExportCellValue}
          />
        </div>
      </div>
    </div>
  );
};

export default GuestsVisitsReportContent;
