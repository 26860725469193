import "./annualHolidaysPlan.scss";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RequestStatus } from "../../../Constants/requestStatus";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  getArrayOfMonths,
  getMonthIndex,
} from "../../../HelpersFunctions/dateAndTime/monthsHelpers";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import DataGrid, { Column } from "devextreme-react/data-grid";
import AddEditHolidayPlanRequestPopup from "../../helpersComponents/Requests/HolidayPlan/AddEditHolidayPlanRequestPopup";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import { ProfileType } from "../../../enums/profileType";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";

function AnnualHolidaysPlan({
  year,
  selectedWorkers,
  setSnackbarState,
}: {
  year: number;
  selectedWorkers?: number[];
  setSnackbarState?: React.Dispatch<any>;
}) {
  const { i18n, t } = useTranslation();
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;
  const [popupDate, setPopupDate] = useState(new Date());
  const [isPopupOpen, setIsPopupOpen] = useState<boolean | undefined>(false);
  const [rows, setRows] = useState<HolidaysPlanItem[]>([]);
  const [row, setRow] = useState<any>({});

  const [annualHolidaysPlansBody, setAnnualHolidaysPlansBody] = useState<
    false | string
  >(false);

  const annualHolidaysPlansSuccessCallback = useCallback((data) => {
    const modified: HolidaysPlanItem[] = [];
    let counter: number = 1;
    data.resJson.forEach((row: HolidaysPlan) => {
      const itemToAdd: HolidaysPlanItem = {
        lp: counter,
        id: row.id,
        dateFrom: new Date(row.dateFrom),
        dateTo: new Date(row.dateTo),
        timeWorkerId: row.timeWorkerId,
        firstName: row.firstName,
        lastName: row.lastName,
        evidenceNumber: row.evidenceNumber,
        overdueLeave: row.overdueLeave,
        leave: row.leaveDuration,
        monthsData: [[], [], [], [], [], [], [], [], [], [], [], []],
        monthsSum: ["", "", "", "", "", "", "", "", "", "", "", ""],
        sum: "",
      };

      let allMonthsSum = 0;
      for (let i = 0; i < 12; i++) {
        for (let j = 0; j < row.monthsData[i].length; j++) {
          let requestId = row.monthsData[i][j].requestId;
          let requestStatus = row.monthsData[i][j].requestStatus;
          let dayFrom = new Date(row.monthsData[i][j].dayFrom).getDate();
          let dayTo = new Date(row.monthsData[i][j].dayTo).getDate();
          let dateRange = "";

          if (dayFrom === dayTo) {
            dateRange = dayFrom.toString();
          } else {
            dateRange = `${dayFrom}-${dayTo}`;
          }

          const tempObj: HolidaysPlanRequestItem = {
            requestId: requestId,
            requestStatus: requestStatus,
            dateRange: dateRange,
          };

          itemToAdd.monthsData[i].push(tempObj);
        }

        if (row.monthsSum[i] > 0) {
          itemToAdd.monthsSum[i] = row.monthsSum[i].toString();
          allMonthsSum += row.monthsSum[i];
        }
      }

      itemToAdd.sum = allMonthsSum.toString();
      modified.push(itemToAdd);
      counter += 1;
    });

    setRows(modified);
    setAnnualHolidaysPlansBody(false);
  }, []);

  const modifyResponseCallback = useCallback((responseData) => {
    const modified: HolidaysPlanItem[] = [];
    let counter: number = 1;
    responseData.forEach((row: HolidaysPlan) => {
      const itemToAdd: HolidaysPlanItem = {
        lp: counter,
        id: row.id,
        dateFrom: new Date(row.dateFrom),
        dateTo: new Date(row.dateTo),
        timeWorkerId: row.timeWorkerId,
        firstName: row.firstName,
        lastName: row.lastName,
        evidenceNumber: row.evidenceNumber,
        overdueLeave: row.overdueLeave,
        leave: row.leaveDuration,
        monthsData: [[], [], [], [], [], [], [], [], [], [], [], []],
        monthsSum: ["", "", "", "", "", "", "", "", "", "", "", ""],
        sum: "",
      };

      let allMonthsSum = 0;
      for (let i = 0; i < 12; i++) {
        for (let j = 0; j < row.monthsData[i].length; j++) {
          let requestId = row.monthsData[i][j].requestId;
          let requestStatus = row.monthsData[i][j].requestStatus;
          let dayFrom = new Date(row.monthsData[i][j].dayFrom).getDate();
          let dayTo = new Date(row.monthsData[i][j].dayTo).getDate();
          let dateRange = "";

          if (dayFrom === dayTo) {
            dateRange = dayFrom.toString();
          } else {
            dateRange = `${dayFrom}-${dayTo}`;
          }

          const tempObj: HolidaysPlanRequestItem = {
            requestId: requestId,
            requestStatus: requestStatus,
            dateRange: dateRange,
          };

          itemToAdd.monthsData[i].push(tempObj);
        }

        if (row.monthsSum[i] > 0) {
          itemToAdd.monthsSum[i] = row.monthsSum[i].toString();
          allMonthsSum += row.monthsSum[i];
        }
      }

      itemToAdd.sum = allMonthsSum.toString();
      modified.push(itemToAdd);
      counter += 1;
    });

    return modified;
  }, []);

  const [
    timeWorkerAnnulalHolidaysPlan,
    timeWorkerAnnulalHolidaysPlanFetchingState,
    timeWorkerAnnulalHolidaysPlanFetchAgain,
  ] = useFetchAndSetGET({
    path: `worker-time/${authUserId}/annual-absence-plans-requests?year=${year}`,
    startFetchOnInitial: false,
    modifyResponseCallback,
  });

  const [annualHolidaysPlansFetchingState, annualHolidaysPlansFetchAgain] =
    useFetchOtherThanGET({
      path: "superior-time/annual-absence-plans-requests",
      method: "POST",
      contentType: "application/json",
      body: annualHolidaysPlansBody,
      setBody: setAnnualHolidaysPlansBody,
      successCallback: annualHolidaysPlansSuccessCallback,
      disableErrorSnackbar: true,
      disableSuccessSnackbar: true,
    });

  const refreshData = useCallback(() => {
    if (profileType === ProfileType.SUPERIOR_TIME) {
      let requestObj: {
        year: number;
        timeWorkersIds: number[];
      } = {
        year: year,
        timeWorkersIds: [],
      };

      if (selectedWorkers) {
        for (let i = 0; i < selectedWorkers.length; i++) {
          requestObj.timeWorkersIds.push(selectedWorkers[i]);
        }
      }
      setAnnualHolidaysPlansBody(JSON.stringify(requestObj));
    } else if (profileType === ProfileType.WORKER_TIME) {
      timeWorkerAnnulalHolidaysPlanFetchAgain();
    }
  }, [
    year,
    selectedWorkers,
    timeWorkerAnnulalHolidaysPlanFetchAgain,
    profileType,
  ]);

  useEffect(() => {
    refreshData();
  }, [year, selectedWorkers, refreshData]);

  useEffect(() => {
    if (annualHolidaysPlansBody) {
      annualHolidaysPlansFetchAgain();
    }
  }, [annualHolidaysPlansBody, annualHolidaysPlansFetchAgain]);

  const getLocalizedDay = (day) => {
    if (i18n.language === "pl" && day === 1) {
      return t("day").toLocaleLowerCase();
    }

    return t("days").toLocaleLowerCase();
  };

  const formatLeave = (overdueLeave, leave) => {
    const overdueLeaveInDays = overdueLeave / 8;
    const leaveInDays = leave / 8;

    return (
      <>
        {overdueLeaveInDays} {t("for")} {year - 1}{" "}
        {t("year").toLocaleLowerCase()}
        <br />
        {leaveInDays} {getLocalizedDay(leaveInDays)}
      </>
    );
  };

  const applyChanges = () => {
    setIsPopupOpen(false);
    refreshData();

    if (setSnackbarState) {
      setSnackbarState([true, "success", t("application_added")]);
    }
  };

  const cancelChanges = () => {
    setIsPopupOpen(false);
  };

  const handleOnMonthHeaderCellClick = (params) => {
    const columns = getArrayOfMonths();

    if (columns.includes(params.column.dataField)) {
      setPopupDate(new Date(year, getMonthIndex(params.column.dataField), 1));

      setRow({});
      setIsPopupOpen(true);
    }
  };

  const handleOnCellClick = (
    timeWorkerId: number,
    requestId: number,
    requestStatus: RequestStatus
  ) => {
    setRow({ id: requestId, timeWorkerId: timeWorkerId });
    setIsPopupOpen(true);
  };

  const getColorForRequestStatus = (requestStatus: RequestStatus) => {
    switch (requestStatus) {
      case RequestStatus.APPROVED:
      case RequestStatus.FINALLY_APPROWED:
        return "#00FF00";
      case RequestStatus.REJECTED:
      case RequestStatus.FINALLY_REJECTED:
      case RequestStatus.CANCELED:
        return "#FF0000";
      case RequestStatus.TO_CHANGE:
        return "#FF8C00";
      default:
        return "#000000";
    }
  };

  const dataRow = function (rowInfo) {
    return (
      <React.Fragment>
        <tr className="main-row">
          <td>{rowInfo.data.lp}</td>
          <td>
            {rowInfo.data.firstName} {rowInfo.data.lastName}
          </td>
          <td>{rowInfo.data.evidenceNumber}</td>
          <td>{formatLeave(rowInfo.data.overdueLeave, rowInfo.data.leave)}</td>

          {rowInfo.data.monthsData.map((el, index) => {
            return (
              <td>
                {el.map((el2) => {
                  return (
                    <>
                      <span
                        className="tableCellDateRange"
                        onClick={(e) =>
                          handleOnCellClick(
                            rowInfo.data.timeWorkerId,
                            el2.requestId,
                            el2.requestStatus
                          )
                        }
                        style={{
                          cursor: "pointer",
                          color: getColorForRequestStatus(el2.requestStatus),
                        }}
                      >
                        {el2.dateRange}
                      </span>
                      <br />
                    </>
                  );
                })}
              </td>
            );
          })}

          <td></td>
        </tr>

        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>

          {rowInfo.data.monthsSum.map((el) => {
            return (
              <td>
                <b>{el}</b>
              </td>
            );
          })}

          <td>
            <b>{rowInfo.data.sum}</b>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isLodadingProgress={
              (profileType === ProfileType.SUPERIOR_TIME &&
                annualHolidaysPlansFetchingState.isFetching) ||
              (profileType === ProfileType.WORKER_TIME &&
                timeWorkerAnnulalHolidaysPlanFetchingState.isFetching)
            }
            isError={
              (profileType === ProfileType.SUPERIOR_TIME &&
                annualHolidaysPlansFetchingState.isError) ||
              (profileType === ProfileType.WORKER_TIME &&
                timeWorkerAnnulalHolidaysPlanFetchingState.isError)
            }
            setIfFetchAgain={() => {
              if (profileType === ProfileType.SUPERIOR_TIME) {
                annualHolidaysPlansFetchAgain();
              } else if (profileType === ProfileType.WORKER_TIME) {
                timeWorkerAnnulalHolidaysPlanFetchAgain();
              }
            }}
          >
            {!annualHolidaysPlansFetchingState.isFetching &&
              !annualHolidaysPlansFetchingState.isError &&
              !timeWorkerAnnulalHolidaysPlanFetchingState.isFetching &&
              !timeWorkerAnnulalHolidaysPlanFetchingState.isError && (
                <DataGrid
                  id="dataGrid"
                  dataSource={
                    profileType === ProfileType.SUPERIOR_TIME
                      ? rows
                      : timeWorkerAnnulalHolidaysPlan
                  }
                  rowAlternationEnabled={true}
                  hoverStateEnabled={true}
                  showRowLines={true}
                  showColumnLines={true}
                  showBorders={true}
                  columnAutoWidth={true}
                  rowRender={dataRow}
                  onCellClick={handleOnMonthHeaderCellClick}
                >
                  <Column caption={t("lp")}></Column>
                  <Column caption={t("first_and_last_name")}></Column>
                  <Column caption={t("evidence_number")}></Column>
                  <Column caption={t("leave_duration_in_days")}></Column>
                  <Column caption={t("holidays_plan_in_months_and_days")}>
                    <Column
                      caption={"I"}
                      dataField={"january"}
                      allowSorting={false}
                    />
                    <Column
                      caption={"II"}
                      dataField={"february"}
                      allowSorting={false}
                    />
                    <Column
                      caption={"III"}
                      dataField={"march"}
                      allowSorting={false}
                    />
                    <Column
                      caption={"IV"}
                      dataField={"april"}
                      allowSorting={false}
                    />
                    <Column
                      caption={"V"}
                      dataField={"may"}
                      allowSorting={false}
                    />
                    <Column
                      caption={"VI"}
                      dataField={"june"}
                      allowSorting={false}
                    />
                    <Column
                      caption={"VII"}
                      dataField={"july"}
                      allowSorting={false}
                    />
                    <Column
                      caption={"VIII"}
                      dataField={"august"}
                      allowSorting={false}
                    />
                    <Column
                      caption={"IX"}
                      dataField={"september"}
                      allowSorting={false}
                    />
                    <Column
                      caption={"X"}
                      dataField={"october"}
                      allowSorting={false}
                    />
                    <Column
                      caption={"XI"}
                      dataField={"november"}
                      allowSorting={false}
                    />
                    <Column
                      caption={"XII"}
                      dataField={"december"}
                      allowSorting={false}
                    />
                  </Column>
                  <Column caption={t("sum").toUpperCase()} dataField={"sum"} />
                </DataGrid>
              )}

            <AddEditHolidayPlanRequestPopup
              row={row}
              open={isPopupOpen}
              onApplyChanges={applyChanges}
              onCancelChanges={cancelChanges}
              date={popupDate}
            />
          </LoadingWrapper>
        </div>
      </div>
    </>
  );
}

export default AnnualHolidaysPlan;
