import { faHome, faInfo } from "@fortawesome/free-solid-svg-icons";
import FireReportSecurity from "../../security/FireReport";
import NotificationSecurity from "../../security/Notifications";
import WhiteListGuestsSecurity from "../../security/WhiteList/WhiteListGuestsSecurity";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const navLinksArraySecurity: navLinksArrayTimeWorkerInterface = {
    profileId: 10,
    routesArrayWithComponents: [
      {
        icon: faHome,
        name: t("home_page"),
        ifHidden: settings.WebUkryjUzytkownikKDStronaGlowna?.value === "1",
        singleLink: true,
        path: "/security/home",
        component: (props) => <NotificationSecurity {...props} />,
      },
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/security/profile",
        component: () => <Profile />,
      },
    ],
    hidenInNavBar: [
      {
        ifHidden: false,
        path: "/security/fire-report",
        component: () => <FireReportSecurity />,
      },
      {
        ifHidden: false,
        path: "/security/white-list",
        component: () => <WhiteListGuestsSecurity />,
      },
    ],
  };

  return navLinksArraySecurity;
};
export default useNavLinksArray;
