import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchFile from "../../../../hooks/fetchHooks/get/useFetchFile";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import ElasticForm from "../../../helpersComponents/ElasticForm";
import { HTMLControlType } from "../../../../enums/HTMLControlType";
import { EditorHTMLMode } from "../../../../enums/editorHTMLMode";
import RequestApprovalsDetails from "../../../helpersComponents/Requests/RequestApprovalsDetails/RequestApprovalsDetails";

const RowDetail: React.FC = ({ row }: any) => {
  const { t } = useTranslation();
  const [attachmentIdAndFileName, setAttachmentIdAndFileName] = useState<any>([
    false,
    "",
  ]);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const { data } = useFetchFile(
    `worker-time/${authUserId}/holiday-requests/${row.id}/attachments`,
    attachmentIdAndFileName
  );

  useEffect(() => {
    if (data === null) return;
    setAttachmentIdAndFileName([false, ""]);
  }, [data]);

  const [elasticFormValues, setElasticFormValues] = useState<any>({});
  const [elasticFormErrors, setElasticFormErrors] = useState<any>({});

  const [ownData, setOwnData] = useState<EditorHTML | null>(null);

  useEffect(() => {
    if (row && row.declarationData) {
      setOwnData(JSON.parse(row.declarationData));
    }
  }, [row]);

  useEffect(() => {
    if (ownData) {
      for (let i = 0; i < ownData.controls.length; i++) {
        switch (ownData.controls[i].type) {
          case HTMLControlType.TEXTBOX:
            {
              let tempControl: TextBoxHTML = ownData.controls[i] as TextBoxHTML;

              setElasticFormValues((prev) => {
                let objToRet = { ...prev };
                objToRet[tempControl.name] = tempControl.value;
                return objToRet;
              });
            }
            break;

          case HTMLControlType.SELECT:
            {
              let tempControl: SelectionListHTML = ownData.controls[
                i
              ] as SelectionListHTML;

              setElasticFormValues((prev) => {
                let objToRet = { ...prev };
                objToRet[tempControl.name] = tempControl.value;
                return objToRet;
              });
            }
            break;
        }
      }
    }
  }, [ownData]);

  return (
    <div className="detailsHoliday">
      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("type_of_request")}:</b>
          </div>
          <div>{row.typeDescription !== null ? row.typeDescription : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("request_status")}:</b>
          </div>
          <div>
            {row.statusTranslated !== null ? row.statusTranslated : "—"}
          </div>
        </div>
      </div>
      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("created")}:</b>
          </div>
          <div>{row.createdBy !== null ? row.createdBy : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("data_created")}:</b>
          </div>
          <div>
            {row.creationDateDetails !== null ? row.creationDateDetails : "—"}
          </div>
        </div>
      </div>
      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("from_date")}:</b>
          </div>
          <div>{row.dateFromDetails !== null ? row.dateFromDetails : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("till_date")}:</b>
          </div>
          <div>{row.dateToDetails !== null ? row.dateToDetails : "—"}</div>
        </div>
      </div>
      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("from_time")}:</b>
          </div>
          <div>{row.fromTime !== null ? row.fromTime : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("till_hour")}:</b>
          </div>
          <div>{row.tillHour !== null ? row.tillHour : "—"}</div>
        </div>
      </div>
      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("days_request")}:</b>
          </div>
          <div>{row.days !== null ? row.days : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("substitution")}:</b>
          </div>
          <div>{row.alternateId !== null ? row.alternate : "—"}</div>
        </div>
      </div>

      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("approved_by")}:</b>
          </div>
          <div>{row.approvedBy !== null ? row.approvedBy : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("finnal_approved_by")}:</b>
          </div>
          <div>
            {row.finallyApprovedBy !== null ? row.finallyApprovedBy : "—"}
          </div>
        </div>
      </div>

      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("comment")}:</b>
          </div>
          <div>{row.comment !== "" ? row.comment : "—"}</div>
        </div>
      </div>
      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("attachments")}:</b>
          </div>
          <div className="attachment">
            {row.attachments.map((attachment) => {
              return (
                <div
                  key={attachment.id}
                  onClick={() => {
                    setAttachmentIdAndFileName([
                      attachment.id,
                      attachment.fileName,
                    ]);
                  }}
                >
                  {attachment.fileName}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="detailsHolidayRow">
        <div>
          <div>
            <b>{t("declaration")}:</b>
          </div>
          <div className="attachment">
            <ElasticForm
              controls={ownData?.controls ? ownData.controls : []}
              mode={EditorHTMLMode.PREVIEW}
              formValues={elasticFormValues}
              setFormValues={setElasticFormValues}
              formErrors={elasticFormErrors}
              setFormErrors={setElasticFormErrors}
            />
          </div>
        </div>
      </div>

      {row.configuredApprovalLevel > 0 && <RequestApprovalsDetails row={row} />}
    </div>
  );
};

export default RowDetail;
