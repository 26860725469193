import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  DatePickerFormik,
  TextFieldFormik,
} from "../../../helpersComponents/FormikInputs";
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../../helpersComponents/PopupHelpers";
import SelectFileInput from "../../../helpersComponents/Attachments/selectFileInput";
import DisplaySingleFileWithDeleteButton from "../../../helpersComponents/Attachments/displaySingleFileWithDeleteButton";
import { useRef } from "react";

import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import React from "react";
import HtmlContentEditor from "../../../helpersComponents/HtmlContentEditor/htmlContentEditor";

export default function PresentationPopup({
  isOpen,
  row,
  closePopup,
  successCallbackAdd,
  successCallbackEdit,
}) {
  const { t } = useTranslation();
  const isSubmittingRef = useRef(false);

  let defaultTime = new Date();
  defaultTime.setHours(0);
  defaultTime.setMinutes(0);
  defaultTime.setSeconds(15);

  let initValues: PresentationObject = {
    id: 0,
    titlePL: "",
    titleEN: "",
    titleDE: "",
    titleRU: "",
    titleUK: "",
    descriptionPL: "",
    descriptionEN: "",
    descriptionDE: "",
    descriptionRU: "",
    descriptionUK: "",
    isMoviePL: false,
    isMovieEN: false,
    isMovieDE: false,
    isMovieRU: false,
    isMovieUK: false,
    moviePL: [],
    movieEN: [],
    movieDE: [],
    movieRU: [],
    movieUK: [],
    contentPL: "",
    contentEN: "",
    contentDE: "",
    contentRU: "",
    contentUK: "",
    minTime: defaultTime,
    order: 0,
    formFieldPLId: "",
    formFieldENId: "",
    formFieldDEId: "",
    formFieldRUId: "",
    formFieldUKId: "",
    fileNamePL: "",
    fileNameEN: "",
    fileNameDE: "",
    fileNameRU: "",
    fileNameUK: "",
  };

  if (row) {
    initValues = {
      id: row.id,

      titlePL: row.titlePL,
      titleEN: row.titleEN,
      titleDE: row.titleDE,
      titleRU: row.titleRU,
      titleUK: row.titleUK,

      descriptionPL: row.descriptionPL,
      descriptionEN: row.descriptionEN,
      descriptionDE: row.descriptionDE,
      descriptionRU: row.descriptionRU,
      descriptionUK: row.descriptionUK,

      isMoviePL: row.isMoviePL,
      isMovieEN: row.isMovieEN,
      isMovieDE: row.isMovieDE,
      isMovieRU: row.isMovieRU,
      isMovieUK: row.isMovieUK,

      contentPL: row.contentPL,
      contentEN: row.contentEN,
      contentDE: row.contentDE,
      contentRU: row.contentRU,
      contentUK: row.contentUK,

      minTime: row.minTime,
      order: row.order,
      formFieldPLId: row.formFieldPLId,
      formFieldENId: row.formFieldENId,
      formFieldDEId: row.formFieldDEId,
      formFieldRUId: row.formFieldRUId,
      formFieldUKId: row.formFieldUKId,

      moviePL: row?.moviePL
        ? row.moviePL
        : row?.fileNamePL && row.isMoviePL
          ? [{ name: row.fileNamePL, size: 0 }]
          : [],

      movieEN: row?.movieEN
        ? row.movieEN
        : row?.fileNameEN && row.isMovieEN
          ? [{ name: row.fileNameEN, size: 0 }]
          : [],

      movieDE: row?.movieDE
        ? row.movieDE
        : row?.fileNameDE && row.isMovieDE
          ? [{ name: row.fileNameDE, size: 0 }]
          : [],

      movieRU: row?.movieRU
        ? row.movieRU
        : row?.fileNameRU && row.isMovieRU
          ? [{ name: row.fileNameRU, size: 0 }]
          : [],

      movieUK: row?.movieUK
        ? row.movieUK
        : row?.fileNameUK && row.isMovieUK
          ? [{ name: row.fileNameUK, size: 0 }]
          : [],

      fileNamePL: row.fileNamePL,
      fileNameEN: row.fileNameEN,
      fileNameDE: row.fileNameDE,
      fileNameRU: row.fileNameRU,
      fileNameUK: row.fileNameUK,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [value, setValue] = React.useState(0);

  const handleChangeTabControl = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      disableEnforceFocus
      fullWidth={true}
    >
      <DialogTitleStyled
        title={row ? t("editing_presentation") : t("adding_presentation")}
      />
      <DialogContentStyled>
        <Formik
          initialValues={initValues}
          onSubmit={(values) => {
            if (row) successCallbackEdit(values, row);
            else successCallbackAdd(values);
            closePopup();
            isSubmittingRef.current = false;
          }}
        >
          {({ values, resetForm, handleChange, setFieldValue, ...props }) => {
            isSubmittingRef.current = props.isSubmitting;
            return (
              <Form noValidate id={"submitPresentation"}>
                <TwoColumnsRowsContainer>
                  <Row>
                    <RowTitle>{t("minimum_display_time")}:</RowTitle>
                    <RowInput>
                      <DatePickerFormik
                        customLabel={t("minimum_display_time")}
                        name="minTime"
                        view={["hours", "minutes", "seconds"]}
                        format="HH:mm:ss"
                        required={true}
                        keyboardDateTimePicker={true}
                        disableToolbar={true}
                      />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle>{t("order")}:</RowTitle>
                    <RowInput>
                      <TextFieldFormik
                        label={t("order")}
                        name="order"
                        type="number"
                        required={true}
                        onChangeExtra={(e) => {
                          let value = parseInt(e.target.value, 10);
                          if (e.target.value === "") value = 0;
                          if (value < 0) value = 0;
                          e.target.value = value;
                        }}
                      />
                    </RowInput>
                  </Row>
                </TwoColumnsRowsContainer>

                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="disabled tabs example"
                  value={value}
                  onChange={handleChangeTabControl}
                >
                  <Tab label="PL" {...a11yProps(0)} />
                  <Tab label="EN" {...a11yProps(1)} />
                  <Tab label="DE" {...a11yProps(2)} />
                  <Tab label="RU" {...a11yProps(3)} />
                  <Tab label="UK" {...a11yProps(4)} />
                </Tabs>

                <TabPanel value={value} index={0}>
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("title")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("title")}
                          name={`titlePL`}
                          type="text"
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>{t("movie")}:</RowTitle>
                      <RowInput>
                        {values.moviePL.length === 0 && (
                          <SelectFileInput
                            setSelectedFiles={(files: File[]) => {
                              if (files && files.length > 0) {
                                setFieldValue("moviePL", files);
                                setFieldValue("isMoviePL", true);
                                setFieldValue(
                                  "formFieldPLId",
                                  new Date().getTime()
                                );
                              }
                            }}
                            title={t("import")}
                            accept={".mp4"}
                            multiple={false}
                          />
                        )}
                        {values.moviePL.length > 0 && (
                          <DisplaySingleFileWithDeleteButton
                            file={values.moviePL[0]}
                            fileIndex={0}
                            allFiles={values.moviePL}
                            ifHideFileSize={values.moviePL[0].size === 0}
                            setUpadteAllFilesAfterDelete={(files: File[]) => {
                              setFieldValue("moviePL", files);
                              setFieldValue("isMoviePL", false);
                              setFieldValue("formFieldPLId", "");
                            }}
                          />
                        )}
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("description")}:</RowTitle>
                      <RowInput width={"85vw"}>
                        <TextFieldFormik
                          label={t("description")}
                          name={"descriptionPL"}
                          type="text"
                          multiline
                          rows={2}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  {!values.isMoviePL && (
                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle>{t("content")}:</RowTitle>
                        <RowInput width={"85vw"}>
                          <HtmlContentEditor
                            initValue={values.contentPL}
                            setContent={(value) => {
                              values.contentPL = value;
                            }}
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>
                  )}
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("title")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("title")}
                          name={`titleEN`}
                          type="text"
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>{t("movie")}:</RowTitle>
                      <RowInput>
                        {values.movieEN.length === 0 && (
                          <SelectFileInput
                            setSelectedFiles={(files: File[]) => {
                              if (files && files.length > 0) {
                                setFieldValue("movieEN", files);
                                setFieldValue("isMovieEN", true);
                                setFieldValue(
                                  "formFieldENId",
                                  new Date().getTime()
                                );
                              }
                            }}
                            title={t("import")}
                            accept={".mp4"}
                            multiple={false}
                          />
                        )}
                        {values.movieEN.length > 0 && (
                          <DisplaySingleFileWithDeleteButton
                            file={values.movieEN[0]}
                            fileIndex={0}
                            allFiles={values.movieEN}
                            ifHideFileSize={values.movieEN[0].size === 0}
                            setUpadteAllFilesAfterDelete={(files: File[]) => {
                              setFieldValue("movieEN", files);
                              setFieldValue("isMovieEN", false);
                              setFieldValue("formFieldENId", "");
                            }}
                          />
                        )}
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("description")}:</RowTitle>
                      <RowInput width={"85vw"}>
                        <TextFieldFormik
                          label={t("description")}
                          name={"descriptionEN"}
                          type="text"
                          multiline
                          rows={2}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  {!values.isMovieEN && (
                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle>{t("content")}:</RowTitle>
                        <RowInput width={"85vw"}>
                          <HtmlContentEditor
                            initValue={values.contentEN}
                            setContent={(value) => {
                              values.contentEN = value;
                            }}
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>
                  )}
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("title")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("title")}
                          name={`titleDE`}
                          type="text"
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>{t("movie")}:</RowTitle>
                      <RowInput>
                        {values.movieDE.length === 0 && (
                          <SelectFileInput
                            setSelectedFiles={(files: File[]) => {
                              if (files && files.length > 0) {
                                setFieldValue("movieDE", files);
                                setFieldValue("isMovieDE", true);
                                setFieldValue(
                                  "formFieldDEId",
                                  new Date().getTime()
                                );
                              }
                            }}
                            title={t("import")}
                            accept={".mp4"}
                            multiple={false}
                          />
                        )}
                        {values.movieDE.length > 0 && (
                          <DisplaySingleFileWithDeleteButton
                            file={values.movieDE[0]}
                            fileIndex={0}
                            allFiles={values.movieDE}
                            ifHideFileSize={values.movieDE[0].size === 0}
                            setUpadteAllFilesAfterDelete={(files: File[]) => {
                              setFieldValue("movieDE", files);
                              setFieldValue("isMovieDE", false);
                              setFieldValue("formFieldDEId", "");
                            }}
                          />
                        )}
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("description")}:</RowTitle>
                      <RowInput width={"85vw"}>
                        <TextFieldFormik
                          label={t("description")}
                          name={"descriptionDE"}
                          type="text"
                          multiline
                          rows={2}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  {!values.isMovieDE && (
                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle>{t("content")}:</RowTitle>
                        <RowInput width={"85vw"}>
                          <HtmlContentEditor
                            initValue={values.contentDE}
                            setContent={(value) => {
                              values.contentDE = value;
                            }}
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>
                  )}
                </TabPanel>

                <TabPanel value={value} index={3}>
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("title")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("title")}
                          name={`titleRU`}
                          type="text"
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>{t("movie")}:</RowTitle>
                      <RowInput>
                        {values.movieRU.length === 0 && (
                          <SelectFileInput
                            setSelectedFiles={(files: File[]) => {
                              if (files && files.length > 0) {
                                setFieldValue("movieRU", files);
                                setFieldValue("isMovieRU", true);
                                setFieldValue(
                                  "formFieldRUId",
                                  new Date().getTime()
                                );
                              }
                            }}
                            title={t("import")}
                            accept={".mp4"}
                            multiple={false}
                          />
                        )}
                        {values.movieRU.length > 0 && (
                          <DisplaySingleFileWithDeleteButton
                            file={values.movieRU[0]}
                            fileIndex={0}
                            allFiles={values.movieRU}
                            ifHideFileSize={values.movieRU[0].size === 0}
                            setUpadteAllFilesAfterDelete={(files: File[]) => {
                              setFieldValue("movieRU", files);
                              setFieldValue("isMovieRU", false);
                              setFieldValue("formFieldRUId", "");
                            }}
                          />
                        )}
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("description")}:</RowTitle>
                      <RowInput width={"85vw"}>
                        <TextFieldFormik
                          label={t("description")}
                          name={"descriptionRU"}
                          type="text"
                          multiline
                          rows={2}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  {!values.isMovieRU && (
                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle>{t("content")}:</RowTitle>
                        <RowInput width={"85vw"}>
                          <HtmlContentEditor
                            initValue={values.contentRU}
                            setContent={(value) => {
                              values.contentRU = value;
                            }}
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>
                  )}
                </TabPanel>

                <TabPanel value={value} index={4}>
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("title")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("title")}
                          name={`titleUK`}
                          type="text"
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>{t("movie")}:</RowTitle>
                      <RowInput>
                        {values.movieUK.length === 0 && (
                          <SelectFileInput
                            setSelectedFiles={(files: File[]) => {
                              if (files && files.length > 0) {
                                setFieldValue("movieUK", files);
                                setFieldValue("isMovieUK", true);
                                setFieldValue(
                                  "formFieldUKId",
                                  new Date().getTime()
                                );
                              }
                            }}
                            title={t("import")}
                            accept={".mp4"}
                            multiple={false}
                          />
                        )}
                        {values.movieUK.length > 0 && (
                          <DisplaySingleFileWithDeleteButton
                            file={values.movieUK[0]}
                            fileIndex={0}
                            allFiles={values.movieUK}
                            ifHideFileSize={values.movieUK[0].size === 0}
                            setUpadteAllFilesAfterDelete={(files: File[]) => {
                              setFieldValue("movieUK", files);
                              setFieldValue("isMovieUK", false);
                              setFieldValue("formFieldUKId", "");
                            }}
                          />
                        )}
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("description")}:</RowTitle>
                      <RowInput width={"85vw"}>
                        <TextFieldFormik
                          label={t("description")}
                          name={"descriptionUK"}
                          type="text"
                          multiline
                          rows={2}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  {!values.isMovieUK && (
                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle>{t("content")}:</RowTitle>
                        <RowInput width={"85vw"}>
                          <HtmlContentEditor
                            initValue={values.contentUK}
                            setContent={(value) => {
                              values.contentUK = value;
                            }}
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>
                  )}
                </TabPanel>
              </Form>
            );
          }}
        </Formik>
      </DialogContentStyled>
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitPresentation"
        disabledConfirm={isSubmittingRef.current}
        onCancel={closePopup}
      />
    </DialogStyled>
  );
}
