import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
// import { useTranslation } from "react-i18next";
import styles from "./permittedRooms.module.scss";

export default function RowDetails({ row }: any): ReactElement {
  const { t } = useTranslation();
  return (
    <div className={styles.rowDetailsRoot}>
      <div className={styles.singleRow + " " + styles.header}>
        <div>
          <b>{t("day")}</b>
        </div>
        <div>
          <b>{t("entrance_from")}</b>
        </div>
        <div>
          <b>{t("entrance_to")}</b>
        </div>
        <div>
          <b>{t("range_from")}</b>
        </div>
        <div>
          <b>{t("range_to")}</b>
        </div>
      </div>
      {row.groupedRows.map((groupedRow, index) => {
        return (
          <div key={index} className={styles.singleRow}>
            {/* <div>
              <b>{groupedRow.day}</b>
            </div> */}
            <div className={styles.rowCell + " " + styles.cardHeader}>
              <div>{/* <b>{t("day")}</b> */}</div>
              <div>{groupedRow.day}</div>
            </div>
            <div className={styles.rowCell}>
              <div>
                <b>{t("entrance_from")}</b>
              </div>
              <div>{groupedRow.entranceFrom}</div>
            </div>
            <div className={styles.rowCell}>
              <div>
                <b>{t("entrance_to")}</b>
              </div>
              <div>{groupedRow.entranceTo}</div>
            </div>
            <div className={styles.rowCell}>
              <div>
                <b>{t("range_from")}</b>
              </div>
              <div>{groupedRow.rangeFrom}</div>
            </div>
            <div className={styles.rowCell}>
              <div>
                <b>{t("range_to")}</b>
              </div>
              <div>{groupedRow.rangeTo}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
