import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { i18n } from "./../lang/i18n";

const initialState: any = {
  acSuperiorNotificationMenuItemName: i18n.t("notifications"),
  employeeNotificationMenuItemName: i18n.t("notifications"),
  ohsNotificationMenuItemName: i18n.t("notifications"),
  operationalSupportNotificationMenuItemName: i18n.t("notifications"),
  pfsoNotificationMenuItemName: i18n.t("notifications"),
  pfsoServicesAndDeliveriesMenuItemName: i18n.t("services_and_deliveries"),
  pfsoCrewEntriesMenuItemName: i18n.t("crew_entry"),
  pfsoCrewDescentsMenuItemName: i18n.t("crew_descent"),

  receptionNotificationMenuItemName: i18n.t("list_of_notifications"),
  securityNotificationMenuItemName: i18n.t("list_of_notifications"),

  shiftDirectorNotificationMenuItemName: i18n.t("notifications"),
  shiftDirectorServicesAndDeliveriesMenuItemName: i18n.t(
    "services_and_deliveries"
  ),
  shiftDirectorCrewEntriesMenuItemName: i18n.t("crew_entry"),
  shiftDirectorCrewDescentsMenuItemName: i18n.t("crew_descent"),
};

export const notificationsSummaryReducer = createSlice({
  name: "notificationsSummary",
  initialState,
  reducers: {
    // AC-SUPERIOR
    setACSuperiorNotificationMenuItemName: (
      state,
      action: PayloadAction<any>
    ) => {
      state.acSuperiorNotificationMenuItemName = action.payload;
    },

    // EMPLOYEE
    setEmployeeNotificationMenuItemName: (
      state,
      action: PayloadAction<any>
    ) => {
      state.employeeNotificationMenuItemName = action.payload;
    },

    // OHS
    setOHSNotificationMenuItemName: (state, action: PayloadAction<any>) => {
      state.ohsNotificationMenuItemName = action.payload;
    },

    // OPERATIONAL-SUPPORT
    setOperationalSupportNotificationMenuItemName: (
      state,
      action: PayloadAction<any>
    ) => {
      state.operationalSupportNotificationMenuItemName = action.payload;
    },

    // PFSO
    setPFSONotificationMenuItemName: (state, action: PayloadAction<any>) => {
      state.pfsoNotificationMenuItemName = action.payload;
    },

    setPFSOServicesAndDeliveriesMenuItemName: (
      state,
      action: PayloadAction<any>
    ) => {
      state.pfsoServicesAndDeliveriesMenuItemName = action.payload;
    },

    setPFSOCrewEntriesMenuItemName: (state, action: PayloadAction<any>) => {
      state.pfsoCrewEntriesMenuItemName = action.payload;
    },

    setPFSOCrewDescentsMenuItemName: (state, action: PayloadAction<any>) => {
      state.pfsoCrewDescentsMenuItemName = action.payload;
    },

    // RECEPTION
    setReceptionNotificationMenuItemName: (
      state,
      action: PayloadAction<any>
    ) => {
      state.receptionNotificationMenuItemName = action.payload;
    },

    // SECURITY
    setSecurityNotificationMenuItemName: (
      state,
      action: PayloadAction<any>
    ) => {
      state.securityNotificationMenuItemName = action.payload;
    },

    // SHIFT-DIRECTOR
    setShiftDirectorNotificationMenuItemName: (
      state,
      action: PayloadAction<any>
    ) => {
      state.shiftDirectorNotificationMenuItemName = action.payload;
    },

    setShiftDirectorServicesAndDeliveriesMenuItemName: (
      state,
      action: PayloadAction<any>
    ) => {
      state.shiftDirectorServicesAndDeliveriesMenuItemName = action.payload;
    },

    setShiftDirectorCrewEntriesMenuItemName: (
      state,
      action: PayloadAction<any>
    ) => {
      state.shiftDirectorCrewEntriesMenuItemName = action.payload;
    },

    setShiftDirectorCrewDescentsMenuItemName: (
      state,
      action: PayloadAction<any>
    ) => {
      state.shiftDirectorCrewDescentsMenuItemName = action.payload;
    },
  },
});

// AC_SUPERIOR
export const selectACSuperiorNotificationMenuItemName = (state: RootState) =>
  state.notificationsSummary.acSuperiorNotificationMenuItemName;
export const { setACSuperiorNotificationMenuItemName } =
  notificationsSummaryReducer.actions;

// EMPLOYEE
export const selectEmployeeNotificationMenuItemName = (state: RootState) =>
  state.notificationsSummary.employeeNotificationMenuItemName;
export const { setEmployeeNotificationMenuItemName } =
  notificationsSummaryReducer.actions;

// OHS
export const selectOHSNotificationMenuItemName = (state: RootState) =>
  state.notificationsSummary.ohsNotificationMenuItemName;
export const { setOHSNotificationMenuItemName } =
  notificationsSummaryReducer.actions;

// OPERATIONAL-SUPPORT
export const selectOperationalSupportNotificationMenuItemName = (
  state: RootState
) => state.notificationsSummary.operationalSupportNotificationMenuItemName;
export const { setOperationalSupportNotificationMenuItemName } =
  notificationsSummaryReducer.actions;

export const selectPFSOServicesAndDeliveriesMenuItemName = (state: RootState) =>
  state.notificationsSummary.pfsoServicesAndDeliveriesMenuItemName;
export const { setPFSOServicesAndDeliveriesMenuItemName } =
  notificationsSummaryReducer.actions;

export const selectPFSOCrewEntriesMenuItemName = (state: RootState) =>
  state.notificationsSummary.pfsoCrewEntriesMenuItemName;
export const { setPFSOCrewEntriesMenuItemName } =
  notificationsSummaryReducer.actions;

export const selectPFSOCrewDescentsMenuItemName = (state: RootState) =>
  state.notificationsSummary.pfsoCrewDescentsMenuItemName;
export const { setPFSOCrewDescentsMenuItemName } =
  notificationsSummaryReducer.actions;

// PFSO
export const selectPFSONotificationMenuItemName = (state: RootState) =>
  state.notificationsSummary.pfsoNotificationMenuItemName;
export const { setPFSONotificationMenuItemName } =
  notificationsSummaryReducer.actions;

// RECEPTION
export const selectReceptionNotificationMenuItemName = (state: RootState) =>
  state.notificationsSummary.receptionNotificationMenuItemName;
export const { setReceptionNotificationMenuItemName } =
  notificationsSummaryReducer.actions;

// SECURITY
export const selectSecurityNotificationMenuItemName = (state: RootState) =>
  state.notificationsSummary.securityNotificationMenuItemName;
export const { setSecurityNotificationMenuItemName } =
  notificationsSummaryReducer.actions;

// SHIFT-DIRECTOR
export const selectShiftDirectorNotificationMenuItemName = (state: RootState) =>
  state.notificationsSummary.shiftDirectorNotificationMenuItemName;
export const { setShiftDirectorNotificationMenuItemName } =
  notificationsSummaryReducer.actions;

export const selectShiftDirectorServicesAndDeliveriesMenuItemName = (
  state: RootState
) => state.notificationsSummary.shiftDirectorServicesAndDeliveriesMenuItemName;
export const { setShiftDirectorServicesAndDeliveriesMenuItemName } =
  notificationsSummaryReducer.actions;

export const selectShiftDirectorCrewEntriesMenuItemName = (state: RootState) =>
  state.notificationsSummary.shiftDirectorCrewEntriesMenuItemName;
export const { setShiftDirectorCrewEntriesMenuItemName } =
  notificationsSummaryReducer.actions;

export const selectShiftDirectorCrewDescentsMenuItemName = (state: RootState) =>
  state.notificationsSummary.shiftDirectorCrewDescentsMenuItemName;
export const { setShiftDirectorCrewDescentsMenuItemName } =
  notificationsSummaryReducer.actions;

export default notificationsSummaryReducer.reducer;
