import "./delegationsList.scss";
import { useCallback, useEffect, useState } from "react";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import Popup from "./popup";
import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "../../../../reducers/session";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import convertDateFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import RowDetail from "./rowDetail";
import requestStatusName from "../../../../HelpersFunctions/requestStatusName";
import delegationPurposeName from "../../../../HelpersFunctions/delegationPurposeName";
import Button from "../../../helpersComponents/Button/button";
import { CircularProgress } from "@material-ui/core";
import useFetchFile from "../../../../hooks/fetchHooks/get/useFetchFile";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import DelegationCarbonFootprintPopup from "../../../helpersComponents/Delegations/DelegationCarbonFootprintPopup";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RequestStatus } from "../../../../Constants/requestStatus";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { useHistory } from "react-router-dom";

function DelegationsListContent() {
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const userName = useAppSelector(selectAuthUser).displayedName;
  const { t } = useTranslation();
  const [fileIdAndName, setFileIdAndName] = useState<[false | string, string]>([
    false,
    "",
  ]);
  const { confirm } = useConfirm();
  const [delegationIdToUpdate, setDelegationIdToUpdate] = useState<number>(0);
  const [newStatusBody, setNewStatusBody] = useState<any>(false);
  const history: any = useHistory();

  let currentLanguage = JSON.parse(
    localStorage.getItem("languageAndOptionId") || "{}"
  );

  if (currentLanguage) {
    currentLanguage = currentLanguage[0];
  } else {
    currentLanguage = "pl";
  }

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Year")
  );
  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Year"));

  const columns = [
    {
      name: "delegationPurpose",
      title: t("trip_target"),
      customFormatter: (row: TimeWorkerDelegationRow) => (
        <p>{delegationPurposeName(row.delegationPurposeId)}</p>
      ),
    },
    {
      name: "countryName",
      title: t("country"),
    },
    {
      name: "cityName",
      title: t("ending_city"),
    },
    {
      name: "status",
      title: t("status"),
      customFormatter: (row: TimeWorkerDelegationRow) => (
        <p>{requestStatusName(row.status)}</p>
      ),
    },
    { name: "startDate", title: t("being_date"), type: "date" },
    { name: "endDate", title: t("ending_time"), type: "date" },
  ];

  const [rows, setRows] = useState<TimeWorkerDelegationRow[]>([]);
  const [rowId, setRowId] = useState<number>(-1);
  const [isOpenCarbonFootprintDialog, setIsOpenCarbonFootprintDialog] =
    useState(false);

  const delegationsResponseCallback = useCallback(
    (responseData: FetchedDelegation[] | null) => {
      if (!responseData) {
        setRows([]);
        return;
      }

      const modified: TimeWorkerDelegationRow[] = responseData?.map(
        (fetchedDelegation: FetchedDelegation) => {
          let delegationRow: TimeWorkerDelegationRow = {
            id: fetchedDelegation.id,
            mpk: fetchedDelegation.mpk,
            delegationNumber: fetchedDelegation.delegationNumber,
            delegationPurposeId: fetchedDelegation.delegationPurposeId,
            countryName: fetchedDelegation.countryName ?? "",
            cityName: fetchedDelegation.cityName ?? "",
            startCityName: fetchedDelegation.startCityName ?? "",
            endCityName: fetchedDelegation.endCityName ?? "",
            status: fetchedDelegation.status,
            startDate: fetchedDelegation.startDate,
            endDate: fetchedDelegation.endDate,
            approverName: fetchedDelegation.approverName ?? "",
            secondApproverName: fetchedDelegation.secondApproverName ?? "",
            approvedAt: fetchedDelegation.approvedAt,
            createdAt: fetchedDelegation.createdAt,
          };

          return delegationRow;
        }
      );

      setRows(modified);
      return modified;
    },
    []
  );

  const [, delegationsFetchingState, delegationsFetchAgain] = useFetchAndSetGET(
    {
      path:
        `worker-time/${authUserId}/delegations?dateFrom=` +
        convertDateFetch(startDate) +
        "&dateTo=" +
        convertDateFetch(endDate),
      modifyResponseCallback: delegationsResponseCallback,
    }
  );

  const successCallback = useCallback(() => {
    delegationsFetchAgain();
  }, [delegationsFetchAgain]);

  const [, fetchAgainChangeNotificationStatus] = useFetchOtherThanGET({
    path: `worker-time/${authUserId}/delegations/${delegationIdToUpdate}`,
    method: "PATCH",
    body: newStatusBody,
    setBody: setNewStatusBody,
    contentType: "application/json",
    successCallback: successCallback,
  });

  useEffect(() => {
    if (delegationIdToUpdate && delegationIdToUpdate > 0 && newStatusBody) {
      fetchAgainChangeNotificationStatus();
    }
  }, [delegationIdToUpdate, newStatusBody, fetchAgainChangeNotificationStatus]);

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {delegationsFetchingState.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <>
            <Button
              onClick={() => {
                filterRange();
              }}
            >
              {t("filter")}
            </Button>
          </>
        )}
      </div>
    </>
  );

  const filterRange = () => {
    delegationsFetchAgain();
  };

  const { data } = useFetchFile(
    `worker-time/${authUserId}/delegations-pdf-generator`,
    fileIdAndName
  );

  useEffect(() => {
    if (data === null) return;
    setFileIdAndName([false, ""]);
  }, [data]);

  const [isOpenEditPopup, setIsOpenEditPopup] = useState<{
    isOpen: boolean;
    row: any;
  }>({
    isOpen: false,
    row: undefined,
  });

  const closeEditPopup = useCallback(() => {
    setIsOpenEditPopup({
      isOpen: false,
      row: undefined,
    });
  }, []);

  useEffect(() => {
    if (history.location.state) {
      setIsOpenEditPopup({
        row: undefined,
        isOpen: true,
      });
    }
  }, [history.location.state]);

  return (
    <>
      <TopBanner pathName={t("list_of_delegations")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
            confirmButtonRight={confirmButtonRight}
          ></DatePickerRange>
        </div>
        <div>
          <div>
            {!delegationsFetchingState.isFetching && (
              <TableComponent
                layoutSettingName="layoutWorkerTimeDelegationsList"
                rows={rows}
                columns={columns}
                rowDetail={RowDetail}
                actionsColumnUpdated={{
                  popup: (props) => {
                    return (
                      <Popup
                        {...props}
                        row={isOpenEditPopup.row}
                        isOpen={isOpenEditPopup.isOpen}
                        successCallback={delegationsFetchAgain}
                        closePopup={closeEditPopup}
                      />
                    );
                  },

                  addButton: {
                    ifShow: true,
                    onClick(row) {
                      setIsOpenEditPopup({
                        row: undefined,
                        isOpen: true,
                      });
                    },
                  },
                  removeButton: {
                    ifShow: true,
                    onClick: async (row) => {
                      if (row.status === RequestStatus.INTRODUCTED) {
                        let selectedbuttonId = await confirm({
                          text: `${t(
                            "are_you_sure_you_want_to_remove_delegation"
                          )}`,
                          buttons: [
                            { buttonName: t("remove"), buttonId: 0 },
                            { buttonName: t("cancel"), buttonId: 1 },
                          ],
                        });

                        if ((await selectedbuttonId) === 0) {
                          setDelegationIdToUpdate(row.id);
                          setNewStatusBody(
                            JSON.stringify([
                              {
                                op: "replace",
                                path: "/status",
                                value: 7,
                              },
                            ])
                          );
                        }
                      }
                    },
                  },
                  editButton: {
                    ifShow: true,
                    name: t("edit"),
                    onClick(row) {
                      if (row.status === RequestStatus.INTRODUCTED) {
                        setIsOpenEditPopup({
                          row: row,
                          isOpen: true,
                        });
                      }
                    },
                  },
                  printButton: {
                    ifShow: true,
                    name: t("print"),
                    onClick: (row) => {
                      const userLastAndFirstName = userName
                        .split(" ")
                        .reverse()
                        .join("");
                      let fileName = `${t(
                        "Delegation"
                      )}_${userLastAndFirstName}_${row.countryName}_${
                        row.cityName
                      }_${toLocaleStringCustom({
                        date: row.startDate,
                        t,
                      })}_${toLocaleStringCustom({
                        date: row.endDate,
                        t,
                      })}.pdf`;

                      setFileIdAndName([row.id.toString(), fileName]);
                    },
                  },
                  customButton1: {
                    ifShow: true,
                    name: t("settle"),
                    icon: (
                      <FontAwesomeIcon
                        icon={faCalculator}
                        size="lg"
                        title={t("settle")}
                        style={{ color: "#0099ff" }}
                      />
                    ),

                    onClick: (row) => {
                      if (
                        row.status !== RequestStatus.CANCELED &&
                        row.status !== RequestStatus.REJECTED &&
                        row.status !== RequestStatus.FINALLY_REJECTED
                      ) {
                        setRowId(row.id);
                        setIsOpenCarbonFootprintDialog(true);
                      }
                    },
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>

      {isOpenCarbonFootprintDialog && (
        <DelegationCarbonFootprintPopup
          rowId={rowId}
          isOpen={isOpenCarbonFootprintDialog}
          setIsOpen={setIsOpenCarbonFootprintDialog}
        />
      )}
    </>
  );
}

export default DelegationsListContent;
