import React from 'react';
import NotificationListContent from "./notificationListContent"


function NotificationList() {
    return (
        <div>
            <NotificationListContent/>
        </div>
    );
}

export default NotificationList;
