import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type Props = {
  setCurrentTabName: React.Dispatch<TabsInterface>;
  currentTabName: TabsInterface;
};

export default function SelectTab({
  currentTabName,
  setCurrentTabName,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles.tabsContainer}>
      <button
        disabled={currentTabName === "basics"}
        className={
          styles.tab +
          " " +
          (currentTabName === "basics" ? styles.tabSelected : "")
        }
        type="submit"
        onClick={() => setCurrentTabName("basics")}
      >
        {t("basic_information_2")}
      </button>

      <button
        disabled={currentTabName === "presentation"}
        className={
          styles.tab +
          " " +
          (currentTabName === "presentation" ? styles.tabSelected : "")
        }
        type="submit"
        form="submitBasics"
        name="presentation"
      >
        {t("presentations")}
      </button>

      <button
        disabled={currentTabName === "questions"}
        type="submit"
        name="questions"
        className={
          styles.tab +
          " " +
          (currentTabName === "questions" ? styles.tabSelected : "")
        }
        form="submitBasics"
      >
        {t("questions")}
      </button>
    </div>
  );
}
