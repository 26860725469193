import React from "react";
import MyTasksContent from "./myTasksContent";

function MyTasks() {
  return (
    <div>
      <MyTasksContent />
    </div>
  );
}

export default MyTasks;
