import { useEffect, useState } from "react";
import styles from "./OperationsAutoLogoutIn.module.scss";
import { useTranslation } from "react-i18next";
import useLogout from "../../../hooks/LogOut/logOut";
import ButtonStyled from "../../helpersComponents/Button/button";

interface IProps {
  timeToLogout: number;
  setAutoLogoutEnabled: React.Dispatch<any>;
}

const OperationsAutoLogoutIn: React.FC<IProps> = ({
  timeToLogout,
  setAutoLogoutEnabled,
}) => {
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(timeToLogout);
  const [logoutErrorCode, setLogoutErrorCode] = useState(200);
  const [isCancelButtonHovered, setIsCancelButtonHovered] = useState(false);
  useLogout(logoutErrorCode);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(intervalId);
          setLogoutErrorCode(401);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeToLogout]);

  useEffect(() => {}, [timeToLogout]);

  return (
    <div>
      <p className={styles["auto_logout_label"]}>
        {t("auto_logout_in")} {seconds}s
      </p>

      <ButtonStyled
        style={{
          backgroundColor: "red",
          fontSize: "24px",
          padding: "20px",
          opacity: isCancelButtonHovered ? 0.5 : 1,
        }}
        onMouseEnter={() => setIsCancelButtonHovered(true)}
        onMouseLeave={() => setIsCancelButtonHovered(false)}
        onClick={async () => {
          setAutoLogoutEnabled(false);
        }}
      >
        {t("Anuluj")}
      </ButtonStyled>
    </div>
  );
};

export default OperationsAutoLogoutIn;
