import { StatusInPortsType } from "../Constants/statusInPortsType";

const classnameForNotificationStatus = (statusId: number) => {
  switch (statusId) {
    case StatusInPortsType.APPROVED:
      return "approved";
    case StatusInPortsType.REJECTED:
      return "rejected";
    default:
      return "";
  }
};

export default classnameForNotificationStatus;
