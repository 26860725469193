export enum ProfileType {
  SUPERIOR_AC = 0,
  SUPERIOR_TIME = 1,
  WORKER_AC = 2,
  WORKER_TIME = 3,
  ADMINISTRATOR_AC = 4,
  AGENT = 5,
  RECEPTION = 6,
  GUARD = 7,
  PFSO = 8,
  SHIFT_DIRECTOR = 9,
  SECURITY = 10,
  EMPLOYEE = 11,
  OHS = 12,
  OPERATIONAL_SUPPORT = 13,
  INTEGRATOR = 14,
  EVENTS_PREVIEW = 15,
  LOCKERS_MANAGEMENT = 16,
  SMS_ADMINISTRATOR = 17,
  SMS_USER = 18,
}
