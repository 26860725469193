import CompanyStructureContent from "../../helpersComponents/CompanyStructure/CompanyStructureContent";

const CompanyStructureSuperiorTime = () => {
  return (
    <CompanyStructureContent
      companiesPathAPI="superior-time/time-companies?isActive=true"
      companyStructurePathAPI="superior-time/company-structure"
    />
  );
};

export default CompanyStructureSuperiorTime;
