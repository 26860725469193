import React from "react";
import SubstitutionContent from "./substitutionContent";

function Schedule() {
  return (
    <div>
      <SubstitutionContent />
    </div>
  );
}

export default Schedule;
