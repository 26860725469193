import React from 'react';
import GetOvertimeContent from "./getOvertimeContent"


function GetOvertime() {
    return (
        <div>
            <GetOvertimeContent/>
        </div>
    );
}

export default GetOvertime;
