import Checkbox from "@material-ui/core/Checkbox";
import { colorNameDictionary } from "../../../ProfilesRouting/profilesDictionaries";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

export default function CheckboxStyled(props) {
  const authUser = useAppSelector(selectAuthUser);
  const { ifCircle, overrideColor, isError, ...restProps } = props;

  let color = !(
    Object.keys(authUser).length === 0 && authUser.constructor === Object
  )
    ? colorNameDictionary[authUser.currentProfile?.type]
    : "#1C84C6";
  return (
    <Checkbox
      style={{
        color: isError ? "red" : overrideColor ? overrideColor : color,
      }}
      icon={ifCircle ? <CircleUnchecked /> : undefined}
      checkedIcon={ifCircle ? <CircleCheckedFilled /> : undefined}
      {...restProps}
    />
  );
}
