import FireReportContent from "../../helpersComponents/FireReport/fireReport";

function FireReportPfso() {
  return (
    <div>
      <FireReportContent
        fireReportPath="pfso/fire-report"
        layoutSettingName="layoutSettingNamePfsoFireReport"
        fireZonesPath="pfso/fire-zones"
        pathPreferences="pfso/preferences"
        targetCompaniesPath="pfso/guests-visits-target-companies"
      />
    </div>
  );
}

export default FireReportPfso;
