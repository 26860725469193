import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import CollaboratorsDialogContent from "./collaboratorsDialogContent";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";

interface Props {
  setIfFetchAgain: React.Dispatch<boolean>;
  taskId: number;
  setIsDialogOpen: React.Dispatch<boolean>;
  isDialogOpen: boolean;
  project: any;
}

export default function TaskCollaboratorsContent({
  setIfFetchAgain,
  taskId,
  setIsDialogOpen,
  isDialogOpen,
  project,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [ifFetch, setIfFetch] = useState<boolean>(false);
  const [fetchedCollaborators, setFetchedCollaborators] = useState<any>(false);
  const [allPossibleCollaborators, setAllPossibleCollaborators] =
    useState<any>(false);

  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const collaboratorsResponse = useFetchGet(
    `worker-time/${authUserId}/projects/${project.id}/tasks/${taskId}/collaborators`,
    ifFetch
  );

  useEffect(() => {
    if (isDialogOpen) {
      setIsLoading(true);
      setIfFetch(true);
    } else {
      setIsLoading(false);
      setIfFetch(false);
    }
  }, [isDialogOpen]);

  useEffect(() => {
    if (collaboratorsResponse.error !== null) {
      setIsError(true);
      setIfFetch(false);
      return;
    } else if (collaboratorsResponse.data !== null) {
      setAllPossibleCollaborators(project.collaborators);
      let collaboratorsModified: any = [];
      collaboratorsResponse.data.collaborators.forEach((collaborator) => {
        let collaboratorId = collaborator.id;
        let timeWorkerId = collaborator.timeWorkerId;
        collaboratorsModified.push({
          ...collaborator,
          id: timeWorkerId,
          collaboratorId: collaboratorId,
        });
      });
      setFetchedCollaborators({
        photos: collaboratorsResponse.data.photos,
        collaborators: collaboratorsModified,
      });
      setIsLoading(false);
      setIfFetch(false);
    }
  }, [collaboratorsResponse, taskId, project, t]);

  return (
    <div>
      <Dialog
        maxWidth={"xl"}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogTitle className="dialogTitleGlobal">
          <div className="titlePopup">{t("assign_workers")}</div>
        </DialogTitle>
        <LoadingWrapper
          isLodadingProgress={isLoading}
          isError={isError}
          setIfFetchAgain={setIfFetch}
          setIsError={setIsError}
          bigSize={true}
        >
          <CollaboratorsDialogContent
            projectId={project.id}
            setIsDialogOpen={setIsDialogOpen}
            setIfFetchAgain={setIfFetchAgain}
            currentAssignedCollaborators={fetchedCollaborators.collaborators}
            photos={fetchedCollaborators.photos}
            taskId={taskId}
            allPossibleCollaborators={allPossibleCollaborators}
          />
        </LoadingWrapper>
      </Dialog>
    </div>
  );
}
