import React, { ReactElement } from "react";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
// import { useTranslation } from "react-i18next";
import styles from "./workersRecords.module.scss";
import HoursRequestsAccordion from "../HoursRequests/hoursRequestsAccordion";
import HolidaysRequestsAccordion from "../HolidaysRequests/holidaysRequestsAccordion";
import EventsAccordion from "../Events/eventsContentAccordion";
import { useAppSelector } from "../../../../store/hooks";
import { selectIsOpenLeftNavbar } from "../../../../reducers/isOpenLeftNavbar";

export default function RowDetails({ row }: any): ReactElement {
  let beginningOfDay = getBeginningOfDate(new Date(row.day), "Day");
  let endOfDay = getEndOfDate(new Date(row.day), "Day");
  let beginningOfDayPlus32Hours = getBeginningOfDate(new Date(row.day), "Day");
  beginningOfDayPlus32Hours.setHours(beginningOfDayPlus32Hours.getHours() + 32);
  const isOpenLeftNavbar = useAppSelector(selectIsOpenLeftNavbar);

  return (
    <div className={styles.rowDetailsRoot}>
      <div
        className={
          styles.rowDetailsAccordionsWithTables +
          " " +
          (isOpenLeftNavbar ? "" : styles.hiddenNavbar)
        }
      >
        <div>
          <HoursRequestsAccordion
            startDateFetch={beginningOfDay}
            endDateFetch={beginningOfDayPlus32Hours}
            timeWorkersIdsString={row.timeWorkerId}
            settingsLayoutName={
              "layoutSuperiorTimeListsHolidaysRequestsWorkersRecords"
            }
          />
        </div>
        <div>
          <HolidaysRequestsAccordion
            startDateFetch={beginningOfDay}
            endDateFetch={endOfDay}
            timeWorkersIdsString={row.timeWorkerId}
            settingsLayoutName={
              "layoutSuperiorTimeListsHolidaysRequestsWorkersRecords"
            }
          />
        </div>
        <div>
          <EventsAccordion
            startDateFetch={beginningOfDay}
            endDateFetch={beginningOfDayPlus32Hours}
            timeWorkersIdsString={row.timeWorkerId}
            settingsLayoutName={"layoutSuperiorTimeListsEventsWorkersRecords"}
          />
        </div>
      </div>
    </div>
  );
}
