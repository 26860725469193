import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "./map.scss";
import markerIconImage from "./images/marker-icon.png";
import L from "leaflet";

const LeafletMap = ({ position }: { position: [number, number] }) => {
  let markerIcon = L.icon({
    iconUrl: markerIconImage,
    iconAnchor: [12.5, 41],
    iconSize: [25, 41],
  });
  return (
    <>
      <MapContainer
        className="mapContainer"
        center={position}
        zoom={13}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={markerIcon}></Marker>
      </MapContainer>
    </>
  );
};

export default LeafletMap;
