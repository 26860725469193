import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { Collapse, FormControlLabel } from "@mui/material";
import { DataGrid } from "devextreme-react";
import {
  Column,
  FilterRow,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  OperationDescriptions,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/data-grid";
import { ChangeEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import addDaysToDate from "../../../../HelpersFunctions/dateAndTime/addDaysToDate";
import convertDateFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  CheckboxStyled,
  DatePickerStyled,
} from "../../../helpersComponents/MaterialUi";
import AutocompleteStyled from "../../../helpersComponents/MaterialUi/Autocomplete/autocomplete";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import { addTimes } from "../ComponentsReport/AdditionalFunctions/AddTimes";
import HeaderButtons from "../ComponentsReport/GridHeader/HeaderButtons";
import "../ComponentsReport/gridStyles.scss";
import GroupCellByLineName from "../ComponentsReport/GroupCell/GroupCellByLineName";
import styles from "./PresenceOnLine.module.scss";

const PresenceOnLineContent = () => {
  const { t } = useTranslation();

  const [readersLines, fetchingStateReadersLines, fetchAgainReadersLines] =
    useFetchAndSetGET<IReaderLine[]>({
      path: "superior-time/readers-lines",
    });

  const [selectedLines, setSelectedLines] = useState<IReaderLine[]>([]);
  const [selectedLinesError, setSelectedLinesError] = useState(false);

  const yesterday = addDaysToDate(new Date(), -1);
  const [dateFrom, setDateFrom] = useState(
    getBeginningOfDate(yesterday, "Day")
  );
  const [dateTo, setDateTo] = useState(getEndOfDate(yesterday, "Day"));
  const [dateFromError, setDateFromError] = useState(false);
  const [dateToError, setDateToError] = useState(false);

  const [
    presenceOnLine,
    fetchingStatePresenceOnLine,
    fetchAgainPresenceOnLine,
  ] = useFetchAndSetGET<IPresenceOnProductionLine[]>({
    path: `superior-time/production-lines/presence-on-production-lines?DateFrom=${convertDateFetch(
      dateFrom
    )}&DateTo=${convertDateFetch(dateTo)}${selectedLines
      .map((item) => `&ProductionLinesIds=${item.id}`)
      .join("")}`,
    startFetchOnInitial: false,
    contentType: "application/json",
  });

  const refreshReport = () => {
    let error: boolean = false;

    if (selectedLines.length === 0) {
      setSelectedLinesError(true);
      error = true;
    }
    if (!dateFrom) {
      setDateFromError(true);
      error = true;
    }
    if (!dateTo) {
      setDateToError(true);
      error = true;
    }
    if (error) return;

    if (dateFrom > dateTo) {
      setDateFromError(true);
      setDateToError(true);
      error = true;
    }
    if (error) return;

    fetchAgainPresenceOnLine();
  };

  const [gridInstance, setGridInstance] = useState(null);
  const gridRef = useCallback((ref) => {
    if (ref?.instance) {
      setGridInstance(ref.instance);
    }
  }, []);

  const [isCollapsedGrid, setIsCollapsedGrid] = useState(true);

  let workersIds: number[] = [];
  let times: string[] = [];
  const customSummaries = (options) => {
    if (options.name === "customWorkersCount") {
      switch (options.summaryProcess) {
        case "start":
          workersIds = [];
          options.totalValue = 0;
          break;
        case "calculate":
          if (!workersIds.includes(options.value)) {
            workersIds.push(options.value);
            options.totalValue += 1;
          }
          break;
      }
    } else if (options.name === "customTotalTime") {
      switch (options.summaryProcess) {
        case "start":
          times = [];
          options.totalValue = 0;
          break;
        case "calculate":
          times.push(options.value);
          break;
        case "finalize":
          options.totalValue = addTimes(times);
          break;
      }
    }
  };

  const [isSelectAllLines, setIsSelectedAllLines] = useState(false);
  const [showHeaderContainer, setShowHeaderContainer] = useState(true);

  return (
    <div>
      <TopBanner pathName={t("presence_on_line")} />

      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div>
            <Tooltip
              title={!showHeaderContainer ? t("expand")! : t("collapse")!}
            >
              <div style={{ width: "fit-content" }}>
                <FontAwesomeIcon
                  icon={showHeaderContainer ? faCaretDown : faCaretUp}
                  onClick={() =>
                    setShowHeaderContainer((prevValue) => !prevValue)
                  }
                  color="#50b8ca"
                  size="2x"
                  className={styles.collpaseHeaderIcon}
                />
              </div>
            </Tooltip>
          </div>

          <Collapse in={showHeaderContainer}>
            <div className={styles.formContainer}>
              <div className={styles.selectLineContainer}>
                <LoadingWrapper
                  isLodadingProgress={fetchingStateReadersLines.isFetching}
                  isError={fetchingStateReadersLines.isError}
                  setIfFetchAgain={() => {
                    if (fetchingStateReadersLines.isError) {
                      fetchAgainReadersLines();
                    }
                  }}
                >
                  <FormControlLabel
                    control={
                      <CheckboxStyled
                        checked={isSelectAllLines}
                        name="tillHourCheckbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedLines(readersLines);
                          } else {
                            setSelectedLines([]);
                          }
                          setIsSelectedAllLines(e.target.checked);
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={t("all_lines")}
                  />

                  <AutocompleteStyled
                    required={true}
                    label={t("line_name")}
                    options={readersLines}
                    value={selectedLines}
                    multiple
                    disableCloseOnSelect
                    filterSelectedOptions
                    limitTags={5}
                    getOptionLabel={(option: any) => {
                      return option.lineName;
                    }}
                    getOptionSelected={(
                      option: IReaderLine,
                      value: IReaderLine
                    ) => {
                      return option.id === value.id;
                    }}
                    onChange={(_: ChangeEvent, newValue: IReaderLine[]) => {
                      setSelectedLines(newValue);
                      setSelectedLinesError(false);
                    }}
                    isError={selectedLinesError}
                  />
                </LoadingWrapper>
              </div>

              <div className={styles.dateContainer}>
                <div className={styles.dateFromContainer}>
                  <DatePickerStyled
                    customLabel={t("date_from")}
                    keyboardDateTimePicker={true}
                    view={["year", "month", "date", "hours", "minutes"]}
                    format="dd-MM-yyyy HH:mm"
                    date={dateFrom}
                    width="100%"
                    setDate={(date: Date) => {
                      setDateFrom(date);
                      setDateFromError(false);
                    }}
                    required={true}
                    isError={dateFromError}
                  />
                </div>

                <div className={styles.dateToContainer}>
                  <DatePickerStyled
                    customLabel={t("date_to2")}
                    keyboardDateTimePicker={true}
                    view={["year", "month", "date", "hours", "minutes"]}
                    format="dd-MM-yyyy HH:mm"
                    date={dateTo}
                    width="100%"
                    setDate={(date: Date) => {
                      setDateTo(date);
                      setDateToError(false);
                    }}
                    required={true}
                    isError={dateToError}
                  />
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        {dateFromError && dateToError ? (
          <div className={styles.datesErrorContainer}>
            <div>{t("date_to_must_be_later_than_date_from")}</div>
          </div>
        ) : null}

        <>
          <LoadingWrapper
            isLodadingProgress={fetchingStatePresenceOnLine.isFetching}
            isError={fetchingStatePresenceOnLine.isError}
            setIfFetchAgain={() => {
              if (fetchingStatePresenceOnLine.isError) {
                fetchAgainPresenceOnLine();
              }
            }}
          >
            <>
              {gridInstance && (
                <HeaderButtons
                  dataGridInstance={gridInstance}
                  refreshHandler={refreshReport}
                  isCollapsedGrid={isCollapsedGrid}
                  setIsCollapsedGrid={setIsCollapsedGrid}
                  exportDocHeader={`${t(
                    "reporting_time"
                  )}: ${toLocaleStringCustom({
                    date: dateFrom,
                    t,
                    ifShowHours: true,
                  })} - ${toLocaleStringCustom({
                    date: dateTo,
                    t,
                    ifShowHours: true,
                  })}`}
                />
              )}

              <div className={styles.reportContainer}>
                <DataGrid
                  ref={gridRef}
                  dataSource={presenceOnLine}
                  keyExpr="id"
                  twoWayBindingEnabled={false}
                  className={styles.grid}
                  noDataText={t("no_data")}
                >
                  <GroupPanel visible={true} allowColumnDragging={false} />

                  <SearchPanel visible={true} placeholder={t("search")} />
                  <FilterRow
                    visible={true}
                    resetOperationText={t("reset")}
                    showAllText={t("all")}
                  >
                    <OperationDescriptions
                      startsWith={t("startsWith")}
                      endsWith={t("endsWith")}
                      contains={t("contains")}
                      notContains={t("notContains")}
                      equal={t("equal")}
                      notEqual={t("notEqual")}
                    />
                  </FilterRow>

                  <HeaderFilter
                    visible={true}
                    texts={{ cancel: t("cancel") }}
                  />

                  <Selection mode="single" />

                  <Column
                    dataField="lineName"
                    caption={t("line_name")}
                    groupIndex={0}
                    autoExpandGroup={!isCollapsedGrid}
                    groupCellRender={(options) =>
                      GroupCellByLineName(options, t, false)
                    }
                    showWhenGrouped={true}
                    allowSorting={true}
                    width={250}
                  />

                  <Column
                    dataField="workerFirstName"
                    caption={t("first_name")}
                    allowFiltering={true}
                    width={200}
                  />
                  <Column
                    dataField="workerLastName"
                    caption={t("last_name")}
                    allowFiltering={true}
                    width={200}
                  />
                  <Column
                    dataField="evidenceNumber"
                    caption={t("evidence_number")}
                    allowFiltering={true}
                  />
                  <Column
                    dataField="company"
                    caption={t("company")}
                    allowFiltering={true}
                  />
                  <Column
                    dataField="organizationalUnit"
                    caption={t("organizational_unit")}
                    allowFiltering={true}
                  />

                  <Column dataField="from" caption={t("from")} />
                  <Column dataField="to" caption={t("to")} />
                  <Column dataField="time" caption={t("time")} />

                  <Column
                    dataField="comment"
                    caption={t("comment")}
                    allowFiltering={true}
                    width={200}
                  />

                  <Summary calculateCustomSummary={customSummaries}>
                    <GroupItem
                      name="customWorkersCount"
                      column="timeWorkerId"
                      summaryType="custom"
                      showInColumn="to"
                      alignByColumn={true}
                      displayFormat={`${t("total_people")}: {0}`}
                    />

                    <GroupItem
                      name="customTotalTime"
                      column="time"
                      summaryType="custom"
                      showInColumn="time"
                      alignByColumn={true}
                      displayFormat={`${t("total_time")}: {0}`}
                    />
                  </Summary>
                  <SortByGroupSummaryInfo summaryItem="Group" />

                  <Paging defaultPageSize={100} />
                  <Pager
                    visible={true}
                    allowedPageSizes={[20, 50, 100, 200]}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                    infoText={`${t("page")} {0} ${t("of")} {1} ({2} ${t(
                      "items"
                    )})`}
                  />
                </DataGrid>
              </div>
            </>
          </LoadingWrapper>
        </>
      </div>
    </div>
  );
};

export default PresenceOnLineContent;
