import { DialogContent, DialogContentProps } from "@material-ui/core";
import React, { createContext, ReactElement } from "react";

interface Props extends DialogContentProps {
  children: React.ReactNode | React.ReactNode[];
  inputWidth?: number;
  titleWidth?: number;
  ifInsidePopup?: boolean;
}

export const contextInputTitleWidth = createContext({
  inputWidth: undefined,
  titleWidth: undefined,
} as { inputWidth?: string | number; titleWidth?: string | number });

export function DialogContentStyled({
  children,
  titleWidth,
  inputWidth,
  ifInsidePopup = true,
  ...restProps
}: Props): ReactElement {
  return (
    <contextInputTitleWidth.Provider
      value={{
        inputWidth,
        titleWidth,
      }}
    >
      <div aria-disabled={true}>
        {ifInsidePopup ? (
          <DialogContent {...restProps}>{children}</DialogContent>
        ) : (
          <>{children}</>
        )}
      </div>
    </contextInputTitleWidth.Provider>
  );
}
