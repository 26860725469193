import {
  faArrowDown,
  faArrowUp,
  faExclamationCircle,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Tooltip } from "@material-ui/core";
import { FormikErrors } from "formik";
import { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../../../../../helpersComponents/Button/button";
import { TextFieldFormik } from "../../../../../helpersComponents/FormikInputs";
import {
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../../../../helpersComponents/PopupHelpers";
import styles from "./MainForm.module.scss";

interface Props {
  formFields: FormFieldsACWorker;
  formikErrors: FormikErrors<{
    [x: string]: string | number | boolean | number[] | null;
  }>;
  acWorkerFetchedPhoto: string;
  photo: File | null;
  setPhoto: Dispatch<SetStateAction<File | null>>;
}

const MainForm: React.FC<Props> = ({
  formFields,
  formikErrors,
  acWorkerFetchedPhoto,
  photo,
  setPhoto,
}) => {
  const { t } = useTranslation();
  const { firstName, lastName, evidenceNumber } = formFields;

  const inputRef = useRef<HTMLInputElement>(null);
  const onPhotoChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const photo = event.target.files[0];
      setPhoto(photo);
    }
  };

  const [showMainForm, setShowMainForm] = useState(true);

  return (
    <>
      <div className={styles.collapseContainer}>
        <Tooltip title={showMainForm ? t("hide")! : t("show")!}>
          <div
            className={styles.arrowShowForm}
            onClick={() => setShowMainForm((prevValue) => !prevValue)}
          >
            <ButtonStyled type="button">
              <FontAwesomeIcon icon={showMainForm ? faArrowUp : faArrowDown} />
            </ButtonStyled>
          </div>
        </Tooltip>
        <div>
          {(formikErrors.firstName ||
            formikErrors.lastName ||
            formikErrors.evidenceNumber) &&
            !showMainForm && (
              <Tooltip title={t("invalid_data")!}>
                <div>
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color="#f44336"
                    size="2x"
                  />
                </div>
              </Tooltip>
            )}
        </div>
      </div>

      <Collapse in={showMainForm}>
        <div className={styles.mainForm}>
          <div className={styles.leftForm}>
            <div className={styles.photoContainer}>
              {acWorkerFetchedPhoto && !photo && (
                <img
                  src={`data:image/png;base64,${acWorkerFetchedPhoto}`}
                  alt=""
                  className={styles.photo}
                />
              )}

              {photo && (
                <img
                  src={URL.createObjectURL(photo)}
                  alt=""
                  className={styles.photo}
                />
              )}
            </div>
            <div className={styles.inputPhotoContainer}>
              <div>
                <Tooltip title={t("upload")!}>
                  <ButtonStyled
                    type="button"
                    onClick={() => inputRef.current?.click()}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faUpload} size="1x" />
                  </ButtonStyled>
                </Tooltip>
                <input
                  type="file"
                  className={styles.inputPhoto}
                  name="photo"
                  ref={inputRef}
                  onChange={onPhotoChange}
                  accept={".jpg, .png, .jpeg"}
                />
              </div>
              {photo && (
                <div>
                  <Tooltip title={t("delete")!}>
                    <ButtonStyled
                      type="button"
                      onClick={() => {
                        setPhoto(null);
                        inputRef!.current!.value = "";
                      }}
                      style={{ background: "#DC143C" }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faTrash} size="1x" />
                    </ButtonStyled>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
          <div>
            <TwoColumnsRowsContainer>
              <Row>
                <RowTitle width={125}>{t(firstName.label)}:</RowTitle>
                <RowInput>
                  <TextFieldFormik
                    label={t(firstName.label)}
                    name={firstName.name}
                    type="text"
                    required={true}
                  />
                </RowInput>
              </Row>

              <Row>
                <RowTitle width={125}>{t(lastName.label)}:</RowTitle>
                <RowInput>
                  <TextFieldFormik
                    label={t(lastName.label)}
                    name={lastName.name}
                    type="text"
                    required={true}
                  />
                </RowInput>
              </Row>
            </TwoColumnsRowsContainer>

            <Row>
              <RowTitle width={125}>{t(evidenceNumber.label)}:</RowTitle>
              <RowInput>
                <TextFieldFormik
                  label={t(evidenceNumber.label)}
                  name={evidenceNumber.name}
                  type="text"
                  required={true}
                />
              </RowInput>
            </Row>
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default MainForm;
