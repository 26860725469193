import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";

const NavLinks = ({ activeBackgroundColor, items }: NavItems) => {
  return (
    <>
      {items.map((item) => {
        return (
          <NavLink
            key={item.to}
            to={item.to}
            className={styles.navigationElement}
            style={{
              background: `${item.backgroundColor ? item.backgroundColor : ""}`,
              color: `${item.textColor ? item.textColor : "white"}`,
            }}
            activeStyle={{
              backgroundColor: activeBackgroundColor,
            }}
          >
            <div>
              <p>
                <FontAwesomeIcon
                  icon={item.icon}
                  color={item.color ? item.color : "white"}
                />{" "}
                {item.title}
              </p>
            </div>
          </NavLink>
        );
      })}
    </>
  );
};

export default NavLinks;
