import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import ButtonStyled from "../Button/button";
import { AutocompleteFormik, TextFieldFormik } from "../FormikInputs";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../PopupHelpers";
import GuestForm from "./guestForm";
import InspectionService from "./InspectionService";
import styles from "./stylesInspectionServices.module.scss";

interface IProps {
  onSubmit: (values: InspectionServiceFormValues) => void;
  inspectionServicesPathApi: string;
  permissionZonesPathApi: string;
  acWorkersPathApi: string;
  isPopupOpen: boolean;
}

const PopupDialogContent: React.FC<IProps> = ({
  onSubmit,
  inspectionServicesPathApi,
  permissionZonesPathApi,
  acWorkersPathApi,
  isPopupOpen,
}) => {
  const { t } = useTranslation();

  const [
    inspectionServices,
    fetchingStateInspectionServices,
    fetchAgainInspectionServices,
  ] = useFetchAndSetGET<InspectionService[]>({
    path: inspectionServicesPathApi,
  });

  const [
    permissionZones,
    fetchingStatePermissionZones,
    fetchAgainPermissionZones,
  ] = useFetchAndSetGET<PermissionZone[]>({
    path: permissionZonesPathApi,
  });

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={
          fetchingStateInspectionServices.isFetching ||
          fetchingStatePermissionZones.isFetching
        }
        isError={
          fetchingStateInspectionServices.isError ||
          fetchingStatePermissionZones.isError
        }
        setIfFetchAgain={() => {
          if (fetchingStateInspectionServices.isError) {
            fetchAgainInspectionServices();
          }
          if (fetchingStatePermissionZones.isError) {
            fetchAgainPermissionZones();
          }
        }}
      >
        {inspectionServices && permissionZones && (
          <DialogContentStyled titleWidth={200}>
            <Formik
              initialValues={{
                guestsArray: [
                  {
                    firstName: "",
                    lastName: "",
                    idNumber: "",
                    cardNumber: "",
                  },
                ],
                permissionZone: permissionZones[0],
                registrationNumber: "",
                inspectionService: null,
                comments: "",
              }}
              onSubmit={(values) => {
                onSubmit(values);
              }}
              validationSchema={Yup.object({
                inspectionService: Yup.object()
                  .nullable(false)
                  .typeError(t("field_required")),
                permissionZone: Yup.object()
                  .nullable()
                  .required(t("field_required")),
                guestsArray: Yup.array().of(
                  Yup.object().shape({
                    firstName: Yup.string().required(t("field_required")),
                    lastName: Yup.string().required(t("field_required")),
                  })
                ),
              })}
            >
              {({
                values,
                setFieldValue,
                setFieldError,
                setFieldTouched,
                touched,
                errors,
              }) => (
                <Form noValidate id="submitForm">
                  <Row>
                    <RowTitle width={"200px"}>
                      {t("inspection_service")}:
                    </RowTitle>
                  </Row>
                  <div
                    className={`${styles.inspectionServiceContainer} ${
                      touched.inspectionService && errors.inspectionService
                        ? styles.inspectionServiceContainerError
                        : ""
                    }`}
                  >
                    <p>{t("select_inspection_service")}*</p>
                    <div className={styles.logosContainer}>
                      <div className={styles.logoCardContainer}>
                        {inspectionServices.map((item: InspectionService) => {
                          return (
                            <div
                              onClick={() => {
                                if (values.inspectionService === item) {
                                  setFieldValue("inspectionService", null);
                                } else {
                                  setFieldValue("inspectionService", item);
                                }
                              }}
                              key={item.id}
                            >
                              <InspectionService
                                item={item}
                                selectedService={values.inspectionService}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {touched.inspectionService && errors.inspectionService && (
                    <div className={styles.error}>
                      <p>{t("field_required")}</p>
                    </div>
                  )}

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("permission_zone")}:</RowTitle>
                      <RowInput>
                        <AutocompleteFormik
                          options={permissionZones}
                          getOptionLabel={(option: PermissionZone) => {
                            return option.name;
                          }}
                          width="100%"
                          getOptionSelected={(
                            option: PermissionZone,
                            value: PermissionZone
                          ) => {
                            return option.id === value.id;
                          }}
                          label={t("select_permission_zone")}
                          name="permissionZone"
                          required={true}
                        />
                      </RowInput>
                    </Row>

                    <Row>
                      <RowTitle>{t("plate_number")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("plate_number")}
                          name="registrationNumber"
                          type="text"
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <Row>
                    <RowTitle>{t("comments")}:</RowTitle>
                    <RowInput>
                      <TextFieldFormik
                        label={t("comments")}
                        name="comments"
                        type="text"
                        multiline
                        rows={3}
                      />
                    </RowInput>
                  </Row>

                  <FieldArray
                    name="guestsArray"
                    render={(arrayHelpers) => (
                      <>
                        {values.guestsArray.map((guestData, index) => {
                          return (
                            <React.Fragment key={index}>
                              <GuestForm
                                guestIndex={index}
                                arrayHelpers={arrayHelpers}
                                numberOfGuests={values.guestsArray.length}
                                setFieldValue={setFieldValue}
                                setFieldError={setFieldError}
                                setFieldTouched={setFieldTouched}
                                isPopupOpen={isPopupOpen}
                                guestsArray={values.guestsArray}
                                acWorkersPathApi={acWorkersPathApi}
                              />
                            </React.Fragment>
                          );
                        })}

                        <div className={styles.addAnotherGuest}>
                          <ButtonStyled
                            onClick={() =>
                              arrayHelpers.push({
                                firstName: "",
                                lastName: "",
                                dateOfBirth: new Date(),
                                idNumber: "",
                                cardNumber: "",
                              })
                            }
                            type="button"
                          >
                            {`${t("add_another_person")} `}
                            <FontAwesomeIcon icon={faPlus} />
                          </ButtonStyled>
                        </div>
                      </>
                    )}
                  />
                </Form>
              )}
            </Formik>
          </DialogContentStyled>
        )}
      </LoadingWrapper>
    </>
  );
};

export default PopupDialogContent;
