import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CircularProgress,
  InputAdornment,
  styled,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useGetCardTag from "../../../hooks/fetchHooks/useGetCardTag/useGetCardTag";
import {
  selectBlockAllTimer,
  selectCurrentReaderId,
  setBlockAllTimer,
  setCurrentReaderId,
} from "../../../reducers/cardTagReader/cardTagReader";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import ButtonStyled from "../Button/button";
import { TextFieldFormik } from "../FormikInputs";
import { Row, RowInput, RowTitle } from "../PopupHelpers";
import styles from "./styles.module.scss";

type Props = {
  isPopupOpen?: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  name: string;
  componentId: string;
  checkForTagsInOtherInputs?: {
    allInputs: { cardNumber: string }[];
    thisInputIndex: number;
  };
  mask?: string | RegExp[];
  required?: boolean;
  widthTitle?: number;
  widthInput?: number;
  successCallback?: (response: string) => void;
  startOnInitail?: boolean;
};

export default function GetCardTagPopupRow({
  isPopupOpen,
  setFieldValue,
  name,
  componentId,
  checkForTagsInOtherInputs,
  mask,
  required,
  widthTitle,
  widthInput,
  successCallback,
  startOnInitail,
}: Props) {
  const { t } = useTranslation();
  const [gettingCardTagState, startListeningForCardTag] = useGetCardTag({
    startOnInitail: startOnInitail,
  });
  const dispatch = useAppDispatch();
  const currentReaderId = useAppSelector(selectCurrentReaderId);
  const blockAllTimer = useAppSelector(selectBlockAllTimer);

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      maxWidth: "unset",
      padding: "15px 20px",
    },
  }));

  useEffect(() => {
    let timer;
    if (blockAllTimer === 0) {
      dispatch(setBlockAllTimer(false));
    }
    if (blockAllTimer !== false) {
      timer = setTimeout(
        () => dispatch(setBlockAllTimer(blockAllTimer - 1)),
        1000
      );
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [blockAllTimer, dispatch]);

  useEffect(() => {
    if (gettingCardTagState.isError) {
      dispatch(setCurrentReaderId(false));
    }
  }, [gettingCardTagState.isError, dispatch]);

  useEffect(() => {
    return () => {
      if (currentReaderId === componentId) {
        dispatch(setCurrentReaderId(false));
      }
      if (currentReaderId === false) {
        dispatch(setBlockAllTimer(false));
      }
    };
  }, [startListeningForCardTag, dispatch, componentId, currentReaderId]);

  useEffect(() => {
    if (isPopupOpen === undefined) return;
    if (!isPopupOpen && gettingCardTagState.isListeningForTagCard) {
      startListeningForCardTag("STOP");
    }
  }, [
    isPopupOpen,
    startListeningForCardTag,
    gettingCardTagState.isListeningForTagCard,
  ]);

  useEffect(() => {
    if (gettingCardTagState.cardTag) {
      setFieldValue(name, gettingCardTagState.cardTag);
      dispatch(setCurrentReaderId(false));
      dispatch(setBlockAllTimer(2));
      if (successCallback) successCallback(gettingCardTagState.cardTag);
    } else {
      setFieldValue(name, "");
    }
  }, [
    gettingCardTagState.cardTag,
    setFieldValue,
    dispatch,
    name,
    successCallback,
  ]);

  let warnings = 0;
  checkForTagsInOtherInputs?.allInputs.forEach((inputData, index) => {
    if (index === checkForTagsInOtherInputs.thisInputIndex) return;
    if (
      inputData.cardNumber ===
        checkForTagsInOtherInputs.allInputs[
          checkForTagsInOtherInputs.thisInputIndex
        ].cardNumber &&
      inputData.cardNumber !== ""
    ) {
      warnings += 1;
    }
  });

  return (
    <>
      <Row>
        <RowTitle width={widthTitle}>
          {gettingCardTagState.isListeningForTagCard ? (
            <div className={styles.titleIsListening}>
              <div>
                <ButtonStyled
                  type="button"
                  onClick={() => {
                    dispatch(setBlockAllTimer(3));
                    startListeningForCardTag("STOP");
                    dispatch(setCurrentReaderId(false));
                  }}
                  width={150}
                >
                  {t("cancel")}
                </ButtonStyled>
              </div>
              <div>
                <CircularProgress size={20} />
              </div>
            </div>
          ) : (
            <div>
              <ButtonStyled
                type="button"
                onClick={() => {
                  dispatch(setCurrentReaderId(componentId));
                  startListeningForCardTag("START_LISTENING");
                }}
                width={150}
                disabled={
                  (currentReaderId !== componentId &&
                    currentReaderId !== false) ||
                  blockAllTimer !== false
                }
              >
                {t("take_the_card")} {blockAllTimer}
              </ButtonStyled>
            </div>
          )}
        </RowTitle>
        <RowInput width={widthInput}>
          <TextFieldFormik
            label={t("card_tag")}
            name={name}
            ifToUpperCaseShipsAndPorts={true}
            type="text"
            mask={mask}
            required={required !== undefined ? required : false}
            InputProps={
              warnings
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <CustomTooltip
                          arrow={true}
                          title={
                            <span className={styles.tootlipTitle}>
                              {t("this_card_tag_is_in_use")}
                            </span>
                          }
                        >
                          <span>
                            <FontAwesomeIcon
                              className={styles.warning}
                              icon={faExclamationTriangle}
                            />
                          </span>
                        </CustomTooltip>
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
          />
        </RowInput>
      </Row>
    </>
  );
}
