import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ReplacementCardsContent from "../../helpersComponents/ReplacementCards/ReplacementCardsContent";
import NavigationReception from "../Navigation/navigationReception";

const SwitchStyled = withStyles({
  switchBase: {
    color: "#2bae66ff",
    "&$checked": {
      color: "#2bae66ff",
    },
    "&$checked + $track": {
      backgroundColor: "#2bae66ff",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#2bae66ff",
    "$checked$checked + &": {
      backgroundColor: "#2bae66ff",
    },
  },
})(Switch);

const ReplacementCardsReception = () => {
  return (
    <div>
      <ReplacementCardsContent
        pathApi="reception/replacement-cards"
        pathWorkersApi="reception/ac-workers"
        navigationComponent={NavigationReception}
        layoutSettingName="layoutReceptionReplacementCards"
        SwitchStyled={SwitchStyled}
      />
    </div>
  );
};

export default ReplacementCardsReception;
