import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import { useLocation, useParams } from "react-router-dom";
import styles from "./buildingsGroup.module.scss";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { useCallback, useEffect, useState } from "react";
import useImageLoader from "../../../hooks/fetchHooks/useImageLoader/useImageLoader";

const BuildingsGroupSMSUser = (props) => {
  const routeParams: any = useParams();
  const location = useLocation();
  const queryString = location.search;
  const queryParams = new URLSearchParams(queryString);
  const [imageUrls, setImageUrls] = useState([]);
  const { imagesData } = useImageLoader(imageUrls);

  const modifyBuildingsInGroupResponseCallback = useCallback(
    (responseData: ISMSBuilding[]) => {
      let urls: any = [];

      for (let i = 0; i < responseData.length; i++) {
        urls.push(`sms-user/download-building-photo/${responseData[i].id}`);
      }

      setImageUrls(urls);

      return responseData;
    },
    []
  );

  const [buildingsInGroup, , fetchAgainBuildingsInGroup] = useFetchAndSetGET<
    ISMSBuilding[]
  >({
    path: `sms-user/buildings?buildingsGroupId=${routeParams.id}`,
    modifyResponseCallback: modifyBuildingsInGroupResponseCallback,
  });

  useEffect(() => {
    if (routeParams.id) {
      fetchAgainBuildingsInGroup();
    }
  }, [routeParams.id, fetchAgainBuildingsInGroup]);

  return (
    <div>
      <TopBanner pathName={queryParams.get("menuItemName")} />
      <div className="flexAndCenter">
        <div className={styles["image-grid"]}>
          {imagesData &&
            buildingsInGroup?.map((building, index) => {
              let buildingPhotoUrl = `sms-user/download-building-photo/${building.id}`;

              let foundPhoto = imagesData.find(
                (el) => el.url === buildingPhotoUrl
              );

              return (
                <div key={index} className={styles["image-item"]}>
                  <figure>
                    <figcaption>{building.name}</figcaption>
                    {foundPhoto?.size ? (
                      <img
                        src={foundPhoto.data}
                        alt={`Budynek: ${building.name}`}
                      />
                    ) : (
                      <img
                        src={"no-image.jpg"}
                        alt={`Budynek: ${building.name}`}
                      />
                    )}
                  </figure>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default BuildingsGroupSMSUser;
