import React from "react";
import HourRequestValidationErrorsItem from "./HourRequestValidationErrorsItem";

interface IProps {
  errors: IHourRequestValidationErrors[];
  timeWorkers: any[];
}

const HourRequestValidationErrors: React.FC<IProps> = ({
  errors,
  timeWorkers,
}) => {
  return (
    <div>
      {errors?.map((el) => {
        let firstName = "";
        let lastName = "";
        let errorsList: string[] = [];

        let objectKeys = Object.keys(el);
        if (objectKeys && objectKeys.length > 0) {
          let timeWorkerId = objectKeys[0];

          let foundTimeWorker = timeWorkers?.find(
            (el) => el.id === parseInt(timeWorkerId)
          );

          if (foundTimeWorker) {
            firstName = foundTimeWorker.firstName;
            lastName = foundTimeWorker.lastName;
          }

          errorsList = el[timeWorkerId];
        }

        return (
          <HourRequestValidationErrorsItem
            firstName={firstName}
            lastName={lastName}
            errors={errorsList}
          />
        );
      })}
    </div>
  );
};

export default HourRequestValidationErrors;
