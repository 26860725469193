import React, { ReactElement, useCallback, useState } from "react";
import { TrainingPersonType } from "../../../Constants/trainingPersonType";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import Presentations from "./presentations/presentations";
import Preview from "./preview";
import Questions from "./questions/questions";
import QuizResults from "./quizResults";

interface Props {
  training: Training;
  trainingPersonType: TrainingPersonType;
  resultViewButtonClick: (result) => void;
  backButtonVisible: boolean;
  backButtonClick: () => void;
}

export default function Training({
  training,
  trainingPersonType,
  backButtonVisible,
  backButtonClick,
  resultViewButtonClick,
}: Props): ReactElement {
  const [stage, setStage] = useState<Stage>("preview");
  const [examStartDate, setExamStartDate] = useState(new Date());
  const [presentationStartDate, setPresentationStartDate] = useState(
    new Date()
  );
  const [quizResults, setQuizResults] = useState("");

  const endQuizCallback = useCallback((res) => {
    setStage("result");
    setQuizResults(res.resJson);
  }, []);

  const [typesOfVisits, fetchingStateTypesOfVisitst, fetchAgainTypesOfVisitst] =
    useFetchAndSetGET<TypeOfVisit[]>({
      path: `types-of-visits`,
    });

  return (
    <>
      <div className="flexAndCenter">
        <LoadingWrapper
          isLodadingProgress={fetchingStateTypesOfVisitst.isFetching}
          isError={fetchingStateTypesOfVisitst.isError}
          setIfFetchAgain={() => {
            if (fetchingStateTypesOfVisitst.isError) {
              fetchAgainTypesOfVisitst();
            }
          }}
        >
          <div>
            {stage === "preview" && (
              <Preview
                setPresentationStartDate={setPresentationStartDate}
                setStage={setStage}
                training={training}
                typesOfVisits={typesOfVisits}
                backButtonVisible={backButtonVisible}
                backButtonClick={backButtonClick}
              />
            )}
            {stage === "presentaions" && (
              <Presentations
                setStage={setStage}
                training={training}
                setExamStartDate={setExamStartDate}
              />
            )}
            {stage === "questions" && (
              <Questions
                examStartDate={examStartDate}
                presentationStartDate={presentationStartDate}
                training={training}
                trainingPersonType={trainingPersonType}
                endQuizCallback={endQuizCallback}
              />
            )}
            {stage === "result" && (
              <QuizResults
                resultViewButtonClick={resultViewButtonClick}
                quizResults={quizResults}
              />
            )}
          </div>
        </LoadingWrapper>
      </div>
    </>
  );
}

Training.defaultProps = {
  trainingPersonType: TrainingPersonType.Guest,
  backButtonVisible: false,
  backButtonClick: () => {},
};
