import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../../../hooks/fetchHooks/useFetch/useFetch";
import { selectAuthUser } from "../../../../../reducers/session";
import { useAppSelector } from "../../../../../store/hooks";
import LoadingWrapper from "../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  DialogStyled,
  DialogTitleStyled,
} from "../../../../helpersComponents/PopupHelpers";
import MoveCopyPopupContent from "./moveCopyPopupContent";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  copyOrMove: "copy" | "move";
  setIfFetchAgain: React.Dispatch<boolean>;
  taskId: number;
  currentProjectId: number;
}

export default function MoveCopyPopup({
  isOpen,
  copyOrMove,
  setIsOpen,
  setIfFetchAgain,
  taskId,
  currentProjectId,
}: Props): ReactElement {
  const { t } = useTranslation();

  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  let [
    setIsFetchingProjectsList,
    fetchingStateProjectsList,
    fetchAgainProjectsList,
  ] = useFetch({
    method: "GET",
    path: `worker-time/${authUserId}/projects`,
    startFetchOnInitial: true,
  });
  const [projectsList, setProjectsList] = useState<any[]>([]);

  useEffect(() => {
    if (
      fetchingStateProjectsList.isError ||
      fetchingStateProjectsList.response === undefined
    )
      return;
    if (!Array.isArray(fetchingStateProjectsList.response.resJson)) return;
    let data = fetchingStateProjectsList.response.resJson;

    setProjectsList(data);
    setIsFetchingProjectsList(false);
  }, [
    fetchingStateProjectsList.isError,
    fetchingStateProjectsList.response,
    setIsFetchingProjectsList,
  ]);

  return (
    <div>
      <DialogStyled open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitleStyled
          title={
            copyOrMove === "copy"
              ? t("copy_tasks_to_another_project")
              : t("move_tasks_to_another_project")
          }
        />
        <LoadingWrapper
          isLodadingProgress={fetchingStateProjectsList.isFetching}
          isError={fetchingStateProjectsList.isError}
          setIfFetchAgain={() => {
            fetchAgainProjectsList();
          }}
        >
          {!fetchingStateProjectsList.isError &&
            !fetchingStateProjectsList.isFetching && (
              <MoveCopyPopupContent
                close={() => setIsOpen(false)}
                projectsList={projectsList}
                taskId={taskId}
                setIfFetchAgain={setIfFetchAgain}
                copyOrMove={copyOrMove}
                currentProjectId={currentProjectId}
              />
            )}
        </LoadingWrapper>
      </DialogStyled>
    </div>
  );
}
