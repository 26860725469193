import { TFunction } from "react-i18next";

export enum SpecialPermissionNumber {
  REQUIRE_WITH_DOUBLE_AUTHORIZATION = 0,
  OPEN_DOUBLE_AUTHORIZATIONS_ONE_BY_ONE = 1,
  TURN_ON_BISTABLE_MODE = 2,
  TURN_ON_OFFICE_MODE = 3,
}

export interface SpecialPermissionsValue {
  id: SpecialPermissionNumber;
  value: string;
}

export const SpecialPermissionsValues = (t: TFunction<"translation">) => [
  {
    id: SpecialPermissionNumber.REQUIRE_WITH_DOUBLE_AUTHORIZATION,
    value: t("REQUIRE_WITH_DOUBLE_AUTHORIZATION"),
  },
  {
    id: SpecialPermissionNumber.OPEN_DOUBLE_AUTHORIZATIONS_ONE_BY_ONE,
    value: t("OPEN_DOUBLE_AUTHORIZATIONS_ONE_BY_ONE"),
  },
  {
    id: SpecialPermissionNumber.TURN_ON_BISTABLE_MODE,
    value: t("TURN_ON_BISTABLE_MODE"),
  },
  {
    id: SpecialPermissionNumber.TURN_ON_OFFICE_MODE,
    value: t("TURN_ON_OFFICE_MODE"),
  },
];
