import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

interface stateInterface {
  buildingsGroups: ISMSBuildingsGroup[];
  buildings: ISMSBuilding[];
  buildingsGroupsLoaded: boolean;
  buildingsLoaded: boolean;
}

const initialState: stateInterface = {
  buildingsGroups: [],
  buildings: [],
  buildingsGroupsLoaded: false,
  buildingsLoaded: false,
};

export const visualizationDataReducer = createSlice({
  name: "visualizationData",
  initialState,
  reducers: {
    setBuildingsGroups: (state, action: PayloadAction<any>) => {
      state.buildingsGroups = action.payload;
      state.buildingsGroupsLoaded = true;
    },
    setBuildings: (state, action: PayloadAction<any>) => {
      state.buildings = action.payload;
      state.buildingsLoaded = true;
    },
  },
});

export const selectBuildingsGroups = (state: RootState) =>
  state.visualizationData.buildingsGroups;

export const selectBuildings = (state: RootState) =>
  state.visualizationData.buildings;

export const selectBuildingsGroupsAndBuildingsLoaded = (state: RootState) =>
  state.visualizationData.buildingsGroupsLoaded &&
  state.visualizationData.buildingsLoaded;

export const { setBuildingsGroups, setBuildings } =
  visualizationDataReducer.actions;
export default visualizationDataReducer.reducer;
