import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TreeNode } from "react-organizational-chart";
import EmployeesInfoPopup from "../../EmployeesInfo/EmployeesInfoPopup";
import { adjust } from "../colors";
import styles from "./ChartNode.module.scss";

interface Props {
  data: CompanyStructureAC | CompanyStructureTime;
  isACSuperior: boolean;
  color: string;
}

const ChartNode: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  let name: string;
  let numberOfEmployees: number;
  let childrens: CompanyStructureAC[] | CompanyStructureTime[];

  if (props.isACSuperior) {
    const data = props.data as CompanyStructureAC;
    name = data.department.departmentName;
    numberOfEmployees = data.countACWorkers;
    childrens = data.departmentsChildren;
  } else {
    const data = props.data as CompanyStructureTime;
    name = data.organizationalUnit.organizationalUnitName;
    numberOfEmployees = data.countTimeWorkers;
    childrens = data.organizationalUnitsChildren;
  }

  const [openEmployeesPopup, setOpenEmployeesPopup] = useState(false);
  const handleCloseEmployeePopup = () => {
    setOpenEmployeesPopup(false);
  };
  const [unit, setUnit] = useState<Unit>({
    id: 0,
    name: "",
    code: "",
  });

  const handleDepartmentChoice = (data: CompanyStructureAC) => {
    if (data.countACWorkers > 0) {
      setUnit({
        id: data.department.departmentId,
        name: data.department.departmentName,
        code: data.department.departmentCode,
      });
      setOpenEmployeesPopup(true);
    }
  };

  const handleOrganizationUnitChoide = (data: CompanyStructureTime) => {
    if (data.countTimeWorkers > 0) {
      setUnit({
        id: data.organizationalUnit.organizationalUnitId,
        name: data.organizationalUnit.organizationalUnitName,
        code: data.organizationalUnit.organizationalUnitCode,
      });
      setOpenEmployeesPopup(true);
    }
  };

  return (
    <>
      <TreeNode
        className={styles.chart}
        label={
          <div className={styles.chartNode} style={{ background: props.color }}>
            <div className={styles.unitName}>
              <div>{name}</div>
            </div>
            <div className={styles.numberOfEmployeesContainer}>
              <Tooltip title={t("employees")!}>
                <div
                  className={styles.numberOfEmployees}
                  onClick={
                    props.isACSuperior
                      ? () =>
                          handleDepartmentChoice(
                            props.data as CompanyStructureAC
                          )
                      : () =>
                          handleOrganizationUnitChoide(
                            props.data as CompanyStructureTime
                          )
                  }
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faUserFriends}
                      size={"1x"}
                      color={"#323232"}
                    />
                  </div>
                  <div>{numberOfEmployees}</div>
                </div>
              </Tooltip>
            </div>
          </div>
        }
      >
        {childrens?.map(
          (item: CompanyStructureTime | CompanyStructureAC, index: number) => {
            return (
              <ChartNode
                key={index}
                data={item}
                isACSuperior={props.isACSuperior}
                color={adjust(props.color, 10)}
              />
            );
          }
        )}
      </TreeNode>

      <EmployeesInfoPopup
        unit={unit}
        isACSuperior={props.isACSuperior}
        isPopupOpen={openEmployeesPopup}
        closeEmployeePopup={handleCloseEmployeePopup}
      />
    </>
  );
};

export default ChartNode;
