import { useTranslation } from "react-i18next";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import AccordionStyled from "../../MaterialUi/Accordion/AccordionStyled";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../../RowDetailsHelpers";
import TableRegistrationNumbers from "../TableRegistrationNumbers";
import styles from "./styles.module.scss";

const RowDetailWhiteListGuests = ({
  row,
  hideRegistarionNumbers,
}: {
  row: IWhiteListGuest;
  hideRegistarionNumbers?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <RowDetailsContainer>
      {/*  */}
      <AccordionStyled title={t("person")} defaultExpanded={true}>
        <>
          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("person")}:</RowDetailsTitle>
              <RowDetailsValue>{`${row?.guestFirstName} ${row?.guestLastName}`}</RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("date_of_birth")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestDateOfBirth
                  ? toLocaleStringCustom({ date: row?.guestDateOfBirth, t })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("email")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestEmail ? row.guestEmail : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("training_expiry_date")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestTrainingExpiryDate
                  ? toLocaleStringCustom({
                      date: row?.guestTrainingExpiryDate,
                      t,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("id_number")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestIdNumber ? row.guestIdNumber : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("evidence_number")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.evidenceNumber ? row.evidenceNumber : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>
        </>
      </AccordionStyled>

      {/*  */}
      <AccordionStyled title={t("information")} defaultExpanded={true}>
        <>
          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("date_from")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.dateFrom
                  ? toLocaleStringCustom({
                      date: row?.dateFrom,
                      t,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("date_to2")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.dateTo
                  ? toLocaleStringCustom({
                      date: row?.dateTo,
                      t,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("company")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.companyName ? row.companyName : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("comments")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.comments ? row.comments : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("permission_zone")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.permissionZoneName ? row.permissionZoneName : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("unnecessary_training")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.isTrainingNotRequired
                  ? row.isTrainingNotRequired
                    ? t("yes")
                    : t("no")
                  : t("no")}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("adding_person")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.addingPersonFullName ? row.addingPersonFullName : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("data_created")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.createdAt
                  ? toLocaleStringCustom({
                      date: row?.createdAt,
                      t,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>
        </>
      </AccordionStyled>

      {/*  */}
      {!hideRegistarionNumbers && (
        <AccordionStyled
          title={t("registration_numbers")}
          defaultExpanded={true}
        >
          {row.registrationNumbers.length > 0 ? (
            <TableRegistrationNumbers
              registrationNumbers={row.registrationNumbers}
            />
          ) : (
            <div className={styles.emptyRegistrationNumbers}>
              {t("no_data")}
            </div>
          )}
        </AccordionStyled>
      )}
    </RowDetailsContainer>
  );
};

export default RowDetailWhiteListGuests;
