import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useParallelFetchJson from "./useParallelFetchJson";

interface Props {
  path: string;
  method: "PUT" | "POST" | "PATCH" | `DELETE`;
  selectedRows: number[];
  allGoodCallback?: (result) => void;
  allFailGoodCallback?: (result) => void;
  notAllGoodCallback?: (result) => void;
  hideSnackbarMessage?: boolean;
}

export default function useParallelFetchOverlay({
  path,
  selectedRows,
  method,
  allGoodCallback,
  allFailGoodCallback,
  notAllGoodCallback,
  hideSnackbarMessage,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [bodyPatch, setBodyPatch] = useState<any>(false);
  const result = useParallelFetchJson(method, path, bodyPatch, selectedRows);

  useEffect(() => {
    if (result?.isFetching) return;
    setBodyPatch(false);

    if (result.isAllGood) {
      if (allGoodCallback) allGoodCallback(result);
      if (!hideSnackbarMessage) {
        enqueueSnackbar(t("actions_completed_successfully"), {
          variant: "success",
        });
      }
    } else if (result.isAllFail) {
      if (allFailGoodCallback) allFailGoodCallback(result);

      if (!hideSnackbarMessage) {
        enqueueSnackbar(t("all_actions_failed"), { variant: "error" });
      }
    } else {
      if (notAllGoodCallback) notAllGoodCallback(result);

      if (!hideSnackbarMessage) {
        enqueueSnackbar(t("not_all_actions_were_successful"), {
          variant: "warning",
        });
      }
    }
  }, [
    result,
    allGoodCallback,
    allFailGoodCallback,
    notAllGoodCallback,
    enqueueSnackbar,
    t,
    hideSnackbarMessage,
  ]);

  return [bodyPatch !== false, setBodyPatch, result];
}
