import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useConfirm from "../../../../../../hooks/useConfirm/useConfirm";
import ButtonStyled from "../../../../../helpersComponents/Button/button";
import StyledDataGrid from "../../../../../helpersComponents/MaterialUi/DataGrid/StyledDataGrid";
import styles from "./GroupsForm.module.scss";

interface IProps {
  formFields: FormFieldsACWorker;
  groupsList: Group[];
  companiesList: Company[];
  formikValues: {
    [x: string]:
      | string
      | number
      | boolean
      | number[]
      | null
      | any[]
      | Date
      | any;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const GroupsForm: React.FC<IProps> = ({
  formFields,
  groupsList,
  companiesList,
  formikValues,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("name"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.name}</span>
      ),
    },
    {
      field: "description",
      headerName: t("description"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.description}</span>
      ),
    },
    {
      field: "companyName",
      headerName: t("company_name"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.companyName}</span>
      ),
    },
  ];

  const { groups, companies } = formFields;
  const [groupsToChoice, setGroupsToChoice] = useState<Group[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>(
    formikValues[`${groups.name}`] as Group[]
  );
  const [selectedGroupsIds, setSelectedGroupsIds] = useState<number[]>([]);
  const [selectedGroupsIdsToRemove, setSelectedGroupsIdsToRemove] = useState<
    number[]
  >([]);

  useEffect(() => {
    const array = groupsList.filter(
      (company) => !selectedGroups.includes(company)
    );
    setGroupsToChoice(array);
  }, [groupsList, selectedGroups]);

  const addNewGroup = async () => {
    const filteredGroups = groupsList.filter((group) =>
      selectedGroupsIds.includes(group.id)
    );

    //#region VALIDATION WITH COMPANIES
    const selectedCompanies = formikValues[`${companies.name}`] as Company[];
    let selectedComapniesIds = selectedCompanies.map((company) => company.id);

    let companiesToAdd: Partial<Company>[] = [];
    filteredGroups.forEach((group) => {
      if (!selectedComapniesIds.includes(group.companyId)) {
        companiesToAdd.push(
          companiesList.find((company) => company.id === group.companyId)!
        );
        selectedComapniesIds.push(group.companyId);
      }
    });

    if (companiesToAdd.length > 0) {
      for (let i = 0; i < companiesToAdd.length; i++) {
        let company = companiesToAdd[i];
        let confirmResponse = await confirm({
          text: `${t("to_add_group_add_a_company")}: ${company.name}?`,
          buttons: [
            { buttonName: t("yes"), buttonId: 0 },
            { buttonName: t("no"), buttonId: 1 },
          ],
        });
        if (confirmResponse === 0) {
          const addedCompanies = formikValues[
            `${companies.name}`
          ] as Partial<Company>[];
          addedCompanies.push(company);
          setFieldValue(companies.name, addedCompanies);

          const newGroups = [...selectedGroups, ...filteredGroups];
          setSelectedGroups(newGroups);
          setFieldValue(groups.name, newGroups);
        }
      }
    } else {
      const newGroups = [...selectedGroups, ...filteredGroups];
      setSelectedGroups(newGroups);
      setFieldValue(groups.name, newGroups);
    }
    //#endregion
  };

  const removeGroup = () => {
    const filteredGroups = selectedGroups.filter(
      (group) => !selectedGroupsIdsToRemove.includes(group.id)
    );
    setSelectedGroups(filteredGroups);
    setFieldValue(groups.name, filteredGroups);
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerGrid}>
        <p className={styles.gridTitle}>{t("groups_to_choose_from")}</p>
        <StyledDataGrid
          rows={groupsToChoice}
          columns={columns}
          defaultPageSize={5}
          rowsPerPageOptions={[5]}
          density="compact"
          checkboxSelection={true}
          onSelectionModelChange={(selected) => {
            setSelectedGroupsIds(selected as number[]);
          }}
          quickFilter
        />
      </div>
      <div className={styles.arrowButtons}>
        <ButtonStyled
          type="button"
          onClick={addNewGroup}
          disabled={groupsToChoice.length === 0}
          aria-label="move selected right"
          width={50}
          height={40}
        >
          &gt;
        </ButtonStyled>
        <ButtonStyled
          type="button"
          onClick={removeGroup}
          disabled={selectedGroups.length === 0}
          aria-label="move selected left"
          width={50}
          height={40}
        >
          &lt;
        </ButtonStyled>
      </div>
      <div className={styles.containerGrid}>
        <p className={styles.gridTitle}>{t("selected_groups")}</p>
        <StyledDataGrid
          rows={selectedGroups}
          columns={columns}
          defaultPageSize={5}
          rowsPerPageOptions={[5]}
          density="compact"
          checkboxSelection={true}
          onSelectionModelChange={(selected) => {
            setSelectedGroupsIdsToRemove(selected as number[]);
          }}
        />
      </div>
    </div>
  );
};

export default GroupsForm;
