import { ProfileType } from "../../../enums/profileType";
import ContentWhiteListGuests from "../../helpersComponents/WhiteList/WhiteListGuests/ContentWhiteListGuests";

const WhiteListGuestsSecurity = () => {
  return (
    <ContentWhiteListGuests
      pathWhiteListGuests="security/white-list-guests?current=true"
      pathPermissionZones="security/permission-zones"
      layoutSettingName="layoutSecurityWhiteListGuestsRecords"
      pathLetInGuest="security/guest-visit-from-white-list"
      profileType={ProfileType.SECURITY}
      idleTimer={true}
    />
  );
};

export default WhiteListGuestsSecurity;
