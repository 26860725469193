export {};

declare global {
  interface Array<T> {
    take(num: number): Array<T>;
    skip(num: number): Array<T>;
    first(): T | null;
  }
}

Array.prototype.take = function (num: number) {
  return this.filter((x, i) => {
    if (i <= num - 1) {
      return true;
    }

    return false;
  });
};

Array.prototype.skip = function (num: number) {
  return this.filter((x, i) => {
    if (i > num - 1) {
      return true;
    }

    return false;
  });
};

Array.prototype.first = function () {
  if (this.length > 0) {
    return this[0];
  }

  return null;
};
