import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Tree } from "react-organizational-chart";
import { selectAuthUser } from "../../../../../reducers/session";
import { useAppSelector } from "../../../../../store/hooks";
import { colorNameDictionary } from "../../../../ProfilesRouting/profilesDictionaries";
import styles from "./ChartRoot.module.scss";

interface Props {
  companyName: string;
  amountOfEmployees: number;
}

const ChartRoot: React.FC<Props> = ({
  companyName,
  amountOfEmployees,
  children,
}) => {
  const { t } = useTranslation();

  const authUser = useAppSelector(selectAuthUser);
  const color = colorNameDictionary[authUser.currentProfile?.type];

  return (
    <Tree
      lineWidth="5px"
      lineColor={color}
      lineBorderRadius="10px"
      label={
        <div className={styles.companyContainer}>
          <div className={styles.company}>
            <div className={styles.companyName}>
              <div>{companyName}</div>
            </div>
            <div className={styles.companyEmployeesContainer}>
              <Tooltip title={t("employees")!}>
                <div className={styles.companyEmployees}>
                  <div>
                    <FontAwesomeIcon
                      icon={faUserFriends}
                      size={"1x"}
                      color={"#323232"}
                    />
                  </div>
                  <div>{amountOfEmployees}</div>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      }
    >
      {children}
    </Tree>
  );
};

export default ChartRoot;
