import { i18n } from "../../lang/i18n";

export function getLocalizedHoursString(
  value: number,
  language: string
): string {
  let hours = i18n.t("hour").toLowerCase();

  switch (language) {
    case "pl":
      if (value === 2 || value === 3 || value === 4) {
        hours = i18n.t("hours_3").toLowerCase();
      } else if (value === 0 || value > 4) {
        hours = i18n.t("hours_2").toLowerCase();
      }
      break;

    case "en":
    case "de":
    case "ru":
      if (value > 1) {
        hours = i18n.t("hours_2").toLowerCase();
      }
      break;
  }

  return hours;
}
