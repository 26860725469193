import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useGetCardTagConfirmationRostrumReader from "../../../hooks/fetchHooks/useGetCardTag/useGetCardTagConfirmationRostrumReader";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";
import PopupConfirmationPermission from "./PopupConfirmationPermission";
import styles from "./styles.module.scss";

interface IProps {
  firstRef: React.MutableRefObject<boolean>;
}

const ConfirmationRostrumReader: React.FC<IProps> = ({ firstRef }) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [errorRequest, setErrorRequest] = useState<boolean>();
  const [errorRequestMessage, setErrorRequestMessage] = useState<string>("");
  const [errorCounter, setErrorCounter] = useState(0);
  const settings = useAppSelector(selectSettings);
  const timeToResumeReaderOperation = settings[
    "CzasDoWznowieniaDzialaniaCzytnikaMownicy"
  ]?.value
    ? settings["CzasDoWznowieniaDzialaniaCzytnikaMownicy"]?.value
    : 180_000;
  const timeToResumeReaderOperationInMinutes = Math.round(
    Number(timeToResumeReaderOperation) * 1.67 * Math.pow(10, -5)
  );
  const errorReaderText = `${t("rostrum_reader_error")}.\n${t(
    "rostrum_reader_error_info"
  )} ${timeToResumeReaderOperationInMinutes} ${t("minutes")}.`;

  const [cardTag, setCardTag] = useState<string | null | undefined>(null);
  const [bufferedCard, setBufferedCard] = useState(false);
  const [gettingCardTagState, startListeningForCardTag] =
    useGetCardTagConfirmationRostrumReader(
      setCardTag,
      setBufferedCard,
      setErrorCounter
    );

  useEffect(() => {
    startListeningForCardTag("START_LISTENING");

    return () => {
      startListeningForCardTag("STOP");
    };
  }, [startListeningForCardTag, gettingCardTagState.isError]);

  const [permission, fetchingStatePermission, fetchAgainPermission] =
    useFetchAndSetGET({
      path: `security/check-permission?cardNumber=${cardTag}`,
      startFetchOnInitial: false,
      errorCallback: (err) => {
        err?.isError && setErrorRequestMessage(t("something_went_wrong"));
      },
    });

  const [openPopupConfirmationPermission, setOpenPopupConfirmationPermission] =
    useState({
      isOpen: false,
      data: null,
    });

  const handleClosePopup = useCallback(() => {
    setOpenPopupConfirmationPermission({
      isOpen: false,
      data: null,
    });
    firstRef.current = false;
  }, [firstRef]);

  useEffect(() => {
    if (fetchingStatePermission?.response?.status === 200 && firstRef.current) {
      const resJson = fetchingStatePermission.response.resJson;

      if (resJson.whiteListGuestId) {
        setOpenPopupConfirmationPermission({
          isOpen: true,
          data: resJson,
        });
      }
    } else if (fetchingStatePermission?.response?.status === 403) {
      enqueueSnackbar(t("no_access") + "!", {
        variant: "error",
        autoHideDuration: 10000,
        className: styles.snackbar,
      });
    } else if (fetchingStatePermission?.response?.isError) {
      setErrorRequest(true);
    }
  }, [
    permission,
    fetchingStatePermission.response,
    enqueueSnackbar,
    t,
    firstRef,
  ]);

  useEffect(() => {
    if (errorRequest) {
      enqueueSnackbar(errorRequestMessage, {
        variant: "error",
        autoHideDuration: 10000,
        className: styles.snackbar,
      });
      setErrorRequest(false);
    }

    let interval: ReturnType<typeof setInterval>;
    if (errorCounter >= 10) {
      enqueueSnackbar(errorReaderText, {
        variant: "error",
        className: styles.snackbar,
      });
      startListeningForCardTag("STOP");
      interval = setInterval(() => {
        setErrorCounter(0);
        startListeningForCardTag("START_LISTENING");
      }, timeToResumeReaderOperation);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [
    errorRequest,
    errorRequestMessage,
    enqueueSnackbar,
    errorCounter,
    startListeningForCardTag,
    timeToResumeReaderOperation,
    timeToResumeReaderOperationInMinutes,
    errorReaderText,
    t,
  ]);

  useEffect(() => {
    if (cardTag !== null && cardTag !== undefined && cardTag !== "") {
      firstRef.current = true;
      fetchAgainPermission();
    }
  }, [cardTag, fetchAgainPermission, firstRef]);

  useEffect(() => {
    if (bufferedCard) {
      enqueueSnackbar(`${t("buffered_card")}.\n${t("wait_a_minute")}.`, {
        variant: "warning",
        autoHideDuration: 5000,
        className: styles.snackbar,
      });
    }
  }, [bufferedCard, enqueueSnackbar, t]);

  return (
    <>
      {openPopupConfirmationPermission.isOpen && (
        <PopupConfirmationPermission
          open={openPopupConfirmationPermission.isOpen}
          onCancelChanges={handleClosePopup}
          data={openPopupConfirmationPermission.data}
          setCardTag={setCardTag}
        />
      )}
    </>
  );
};

export default ConfirmationRostrumReader;
