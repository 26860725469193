import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { DISABLED_LANGUAGES } from "../components/SelectLanguage/useDisabledLanguauge";
import TRANSLATIONS_DE from "./de/translation.json";
import TRANSLATIONS_EN from "./en/translation.json";
import TRANSLATIONS_PL from "./pl/translation.json";
import TRANSLATIONS_RU from "./ru/translation.json";
import TRANSLATIONS_UK from "./uk/translation.json";

const isDisabledLanguage = (langValue) => {
  return DISABLED_LANGUAGES?.some((disabledLang) => disabledLang === langValue)
    ? true
    : false;
};

let initialLanguage = "pl";
if (localStorage.getItem("languageAndOptionId") !== null) {
  initialLanguage = JSON.parse(localStorage.getItem("languageAndOptionId"))[0];
  if (isDisabledLanguage(initialLanguage)) {
    initialLanguage = "pl";
  }
}

i18n.use(initReactI18next).init({
  returnEmptyString: false,
  fallbackLng: initialLanguage,
  //debug: true,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    pl: {
      translation: TRANSLATIONS_PL,
    },
    en: {
      translation: TRANSLATIONS_EN,
    },
    de: {
      translation: TRANSLATIONS_DE,
    },
    ru: {
      translation: TRANSLATIONS_RU,
    },
    uk: {
      translation: TRANSLATIONS_UK,
    },
  },
});

export { i18n };
