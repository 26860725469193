import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TFunction } from "react-i18next";
import ErrorTooltip from "./errorTooltip";
import styles from "./schedule.module.scss";

interface Props {
  cellInRow: GridTemplate;
  cellNumber: number;
  errorName: string;
  t: TFunction<"translation">;
}

export default function setCurrentCellError({
  cellInRow,
  cellNumber,
  errorName,
  t,
}: Props) {
  if (typeof cellInRow.cellValue === "string") {
    cellInRow.cellValueString = cellInRow.cellValue;
    cellInRow.cellValue = (
      <div>
        {cellInRow.cellValue}
        <ErrorTooltip
          key={cellNumber}
          tooltipBody={
            <div>
              {errorName.split(";").map((e) => {
                return <div>{e}</div>;
              })}
            </div>
          }
        >
          <FontAwesomeIcon
            className={styles.faExclamationTriangleWithHover}
            icon={faExclamationTriangle}
          />
        </ErrorTooltip>
      </div>
    );
  }
  cellInRow.border = "1px solid red";
}
