import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../Button/button";
import styles from "./styles.module.scss";

interface Props {
  training: Training;
  typesOfVisits: TypeOfVisit[];
  setStage: React.Dispatch<Stage>;
  setPresentationStartDate: React.Dispatch<Date>;
  backButtonVisible: boolean;
  backButtonClick: () => void;
}

export default function Preview({
  training,
  typesOfVisits,
  setStage,
  setPresentationStartDate,
  backButtonVisible,
  backButtonClick,
}: Props) {
  const { t, i18n } = useTranslation();

  const trainingType = {
    0: "guest",
    1: "worker",
  };

  const getLocalizedTrainingName = useCallback(() => {
    switch (i18n.language) {
      case "en":
        return training.nameEN;

      case "de":
        return training.nameDE;

      case "ru":
        return training.nameRU;

      case "uk":
        return training.nameUK;

      default:
        return training.namePL;
    }
  }, [
    i18n.language,
    training.namePL,
    training.nameEN,
    training.nameDE,
    training.nameRU,
    training.nameUK,
  ]);

  const getLocalizedTrainingDescription = useCallback(() => {
    switch (i18n.language) {
      case "en":
        return training.descriptionEN;

      case "de":
        return training.descriptionDE;

      case "ru":
        return training.descriptionRU;

      case "uk":
        return training.descriptionUK;

      default:
        return training.descriptionPL;
    }
  }, [
    i18n.language,
    training.descriptionPL,
    training.descriptionEN,
    training.descriptionDE,
    training.descriptionRU,
    training.descriptionUK,
  ]);

  const getLocalizedTypeOfVisitName = useCallback(
    (typeOfVisit: number) => {
      let typeOfVisitObj = typesOfVisits.find((e) => e.id === typeOfVisit);

      if (typeOfVisitObj) {
        switch (i18n.language) {
          case "en":
            return typeOfVisitObj.nameEN;

          case "de":
            return typeOfVisitObj.nameDE;

          case "ru":
            return typeOfVisitObj.nameRU;

          case "uk":
            return typeOfVisitObj.nameUK;

          default:
            return typeOfVisitObj.namePL;
        }
      }

      return "";
    },
    [i18n.language, typesOfVisits]
  );

  return (
    <div className={styles.previewRoot}>
      <div>
        <h1>{t("basic_information_2")}:</h1>
      </div>
      <div>
        <ul className={styles.ul}>
          <li>
            {t("name")} - <b>{getLocalizedTrainingName()}</b>
          </li>
          <li>
            {t("number_of_questions")} - <b>{training.questions.length}</b>
          </li>
          <li>
            {t("multiple_choice")} -{" "}
            <b>{training.multiSelectionInQuestions ? t("yes") : t("no")}</b>
          </li>
          <li>
            {t("pass_threshold")} - <b>{training.passTresholdInPercent}%</b>
          </li>
          <li>
            {t("description")} - <b>{getLocalizedTrainingDescription()}</b>
          </li>
          <li>
            {t("validity_of__test")} [{t("days")}] -{" "}
            <b>{training.importance}</b>
          </li>
          <li>
            {t("possibility_to_repeat_test")} -{" "}
            <b>{training.isRepeatPossible ? t("yes") : t("no")}</b>
          </li>
          <li>
            {t("type_of_visit")} -{" "}
            <b>{getLocalizedTypeOfVisitName(training.kindOfVisit)}</b>
          </li>
          <li>
            {t("type")} - <b>{t(trainingType[training.type])}</b>
          </li>
          <li>
            <b>
              {training.backToPreviousQuestionPossible
                ? t("possibility_to_return_to_previous_questions")
                : t("unable_to_return_to_previous_questions")}
            </b>
          </li>
        </ul>
      </div>
      <div>
        <div className={styles.buttonsContainer}>
          <ButtonStyled
            onClick={() => {
              setPresentationStartDate(new Date());
              setStage("presentaions");
            }}
          >
            {t("move_to_presentations")}
          </ButtonStyled>

          {backButtonVisible && (
            <ButtonStyled onClick={backButtonClick}>{t("back")}</ButtonStyled>
          )}
        </div>
      </div>
    </div>
  );
}

Preview.defaultProps = {
  backButtonVisible: false,
  backButtonClick: () => { },
};
