import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

interface Props {
  x: any;
}
const initialState: Props = {
  x: false,
};

export const homePageSuperiorReducer = createSlice({
  name: "homePageSuperior",
  initialState,
  reducers: {
    setLastEvent: (state, action: PayloadAction<any>) => {
      state.x = action.payload;
    },
  },
});

export const selectx = (state: RootState) => state.homePageSuperior.x;
export const selectFetchAgainTasks = (state: RootState) =>
  state.homePage.fetchAgainTasks;
export const { setLastEvent } = homePageSuperiorReducer.actions;
export default homePageSuperiorReducer.reducer;
