import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableAndActions from "./tableAndActions";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import useFetch from "../../../../hooks/fetchHooks/useFetch/useFetch";
import { useTranslation } from "react-i18next";
import { statusDictionary } from "../../../../Constants/statusDictionary";
import FilesListWithFetch from "../../../helpersComponents/Attachments/filesListWithFetch";
import convertDateFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import styles from "./according.module.scss";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import addMonthsToDate from "../../../../HelpersFunctions/dateAndTime/addMonthsToDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import RowDetail from "./rowDetail";

const HolidaysRequestsAccordion = React.memo(
  function HolidaysRequestsAccordion({
    startDateFetch,
    endDateFetch,
    ifOnlyIntroduced,
    timeWorkersIdsString,
    settingsLayoutName,
  }: {
    startDateFetch: Date;
    endDateFetch?: Date;
    ifOnlyIntroduced?: boolean;
    timeWorkersIdsString?: string;
    settingsLayoutName: string;
  }): ReactElement {
    const [isExpanded, setIsExpanded] = useState<boolean>(true);
    const { t } = useTranslation();
    const [dataModified, setDataModified] = useState<any>(null);

    let [setIsFetching, fetchingState, fetchAgain] = useFetch({
      method: "GET",
      path:
        "superior-time/holiday-requests?source=timeSuperiorHomePageHolidaysRequests&dateFrom=" +
        convertDateFetch(startDateFetch, "T00:00:00") +
        "&dateTo=" +
        (endDateFetch
          ? convertDateFetch(endDateFetch, "T23:59:59")
          : convertDateFetch(
              addMonthsToDate(getEndOfDate(new Date(), "Day"), 12)
            )) +
        (timeWorkersIdsString ? `&timeWorkersIds=${timeWorkersIdsString}` : ""),
    });

    useEffect(() => {
      if (fetchingState.isError || fetchingState.response === undefined) return;
      if (!Array.isArray(fetchingState.response.resJson)) return;

      let data = fetchingState.response.resJson;
      let datamodifiedLocal: any = [];
      let lpHolidays = 1;

      data.forEach((element, index) => {
        data[index].time = convertMinsToHrsMins(
          (new Date(element.dateTo).getTime() -
            new Date(element.dateFrom).getTime()) /
            (1000 * 60)
        );
        data[index].lp = lpHolidays;
        data[index].statusTranslated = t(statusDictionary[data[index].status]);
        data[index].employee = element.firstName + " " + element.lastName;

        data[index].createdAtString = new Date(
          element.createdAt
        ).toLocaleString(t("scheduler_language"), {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });

        data[index].fromTime = new Date(element.dateFrom).toLocaleString(
          t("scheduler_language"),
          {
            hour: "2-digit",
            minute: "2-digit",
          }
        );
        data[index].tillHour = new Date(element.dateTo).toLocaleString(
          t("scheduler_language"),
          {
            hour: "2-digit",
            minute: "2-digit",
          }
        );

        data[index].dateToDetails = new Date(element.dateTo).toLocaleString(
          t("scheduler_language"),
          {
            weekday: "long",
            month: "long",
            year: "numeric",
            day: "2-digit",
          }
        );
        data[index].dateFromDetails = new Date(element.dateFrom).toLocaleString(
          t("scheduler_language"),
          {
            weekday: "long",
            month: "long",
            year: "numeric",
            day: "2-digit",
          }
        );

        data[index].creationDateDetails = new Date(
          element.createdAt
        ).toLocaleString(t("scheduler_language"), {
          weekday: "long",
          month: "long",
          year: "numeric",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });

        data[index].attachmentsList =
          data[index].attachments?.length === 0 ? (
            t("none")
          ) : (
            <div>
              <FilesListWithFetch
                files={data[index].attachments}
                path={`worker-time/${data[index].timeWorkerId}/holiday-requests/${data[index].id}/attachments`}
              />
            </div>
          );
        lpHolidays++;
        datamodifiedLocal.push(data[index]);
      });

      setDataModified(datamodifiedLocal);
      setIsFetching(false);
    }, [
      fetchingState.isError,
      fetchingState.response,
      ifOnlyIntroduced,
      setIsFetching,
      t,
    ]);

    const columns = [
      { name: "lp", title: "L.p." },
      {
        name: "dateFrom",
        title: t("date_from"),
        type: "date_hours",
        customFormatter: (row) => (
          <span style={{ color: "green" }}>
            {toLocaleStringCustom({
              date: row.dateFrom ? new Date(row.dateFrom) : false,
              t,
              ifShowHours: true,
            })}
          </span>
        ),
      },
      {
        name: "dateTo",
        title: t("date_to2"),
        type: "date_hours",
        customFormatter: (row) => (
          <span style={{ color: "red" }}>
            {toLocaleStringCustom({
              date: row.dateTo ? new Date(row.dateTo) : false,
              t,
              ifShowHours: true,
            })}
          </span>
        ),
      },
      { name: "employee", title: t("employee") },
      { name: "alternate", title: t("substitution") },
      { name: "typeDescription", title: t("type_of_request") },
      { name: "days", title: t("days_request") },
      { name: "holidayLeft", title: t("holiday_left") },
      { name: "statusTranslated", title: t("status") },
      { name: "comment", title: t("comment") },
      { name: "approvedBy", title: t("approved_by") },
      { name: "evidenceNumber", title: t("evidence_number") },
      { name: "attachmentsList", title: t("attachments") },
      { name: "createdBy", title: t("created") },
      { name: "createdAt", title: t("data_created"), type: "date_hours" },
    ];

    return (
      <div>
        <LoadingWrapper
          isLodadingProgress={fetchingState.isFetching}
          isError={fetchingState.isError}
          setIfFetchAgain={fetchAgain}
        >
          {!fetchingState.isFetching &&
            !fetchingState.isError &&
            dataModified !== null &&
            dataModified?.length >= 0 && (
              <Accordion
                expanded={isExpanded}
                onChange={() => setIsExpanded(!isExpanded)}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <div className={styles.titleAccordion}>
                    {t("holidays_requests")}
                    <div
                      className={
                        styles.greenBorderColor +
                        " " +
                        styles.circleAccordionTitleNumber
                      }
                    >
                      {dataModified.length}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.width100}>
                    {isExpanded && (
                      <TableAndActions
                        rows={dataModified}
                        columns={columns}
                        parallerPatchPath={"superior-time/holiday-requests"}
                        ifWhite={true}
                        fetchObject={{
                          fetchAgain,
                          isError: fetchingState.isError,
                          isFetching: fetchingState.isFetching,
                          response: fetchingState.response,
                          setIsFetching,
                        }}
                        settingsLayoutName={settingsLayoutName}
                        rowDetail={RowDetail}
                      />
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
        </LoadingWrapper>
      </div>
    );
  }
);

export default HolidaysRequestsAccordion;
