import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../helpersComponents/PopupHelpers";
import PopupContentProductionLines from "./PopupContentProductionLines";

interface IProps {
  isOpen: boolean;
  closePopup: () => void;
  rowId?: number;
  successCallback: () => void;
}

const PopupProductionLines: React.FC<IProps> = ({
  isOpen,
  closePopup,
  rowId,
  successCallback,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [requestBody, setRequestBody] = useState<false | any>(false);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `superior-time/readers-lines${
      rowId !== undefined ? `/${rowId}` : ""
    }`,
    method: rowId !== undefined ? "PUT" : "POST",
    contentType: "application/json",
    body: requestBody,
    setBody: setRequestBody,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, fetchAgainPostData]);

  const onSubmit = (values: IAddEditReaderLine) => {
    const object = {
      readerId: values.reader?.id,
      lineName: values.lineName,
      order: values.order ? Number(values.order) : null,
    };
    setRequestBody(JSON.stringify(object));
  };

  return (
    <DialogStyled open={isOpen} onClose={closePopup} disableEnforceFocus>
      <DialogTitleStyled title={t("production_line")} />
      <DialogContentStyled>
        <PopupContentProductionLines rowId={rowId} onSubmit={onSubmit} />
      </DialogContentStyled>
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupProductionLines;
