import { useCallback, useEffect, useState } from "react";
import LoadingWrapper from "../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import Row from "../../../../helpersComponents/PopupHelpers/row";
import RowInput from "../../../../helpersComponents/PopupHelpers/rowInput";
import RowTitle from "../../../../helpersComponents/PopupHelpers/rowTitle";
import TwoColumnsRowsContainer from "../../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import erro400getTranslatedErrorString from "../../../../../HelpersFunctions/erro400getTranslatedErrorString";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../store/hooks";
import { selectAuthUser } from "../../../../../reducers/session";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import { Tab, Tabs, Tooltip } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import { colorNameDictionary } from "./../../../../ProfilesRouting/profilesDictionaries";
import ObjectTypeStatesContent from "./ObjectTypeStatesContent";

const AddEditObjectTypePopupContent: React.FC<any> = ({
  rowId,
  control,
  register,
  errors,
  fields,
  append,
  remove,
  setValue,
  getValues,
  setErrorMessage,
  successCallback,
  handleSubmit,
  setIsFetching,
  watch,
}) => {
  const { t } = useTranslation();
  const authUserType = useAppSelector(selectAuthUser).currentProfile?.type;
  //const settings = useAppSelector(selectSettings);
  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const [valueTab, setValueTab] = useState<number>(0);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
  }, [successCallback]);

  /*const soundModifyResponseCallback = useCallback((responseData) => {
    setBuildingsGroupsLoaded(true);
    return responseData;
  }, []);*/

  const [objectType, fetchingStateObjectType, fetchAgainObjectType] =
    useFetchAndSetGET({
      path: `sms-administrator/objects-types-library/${rowId}`,
      startFetchOnInitial: false,
      //modifyResponseCallback: buildingModifyResponseCallback,
    });

  useEffect(() => {
    if (rowId) {
      fetchAgainObjectType();
    }
  }, [rowId, fetchAgainObjectType]);

  const errorCallback = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setErrorMessage(error);
      }
    },
    [t, setErrorMessage]
  );

  const [fetchingStateAddEditObjectType, fetchAgainAddEditObjectType] =
    useFetchOtherThanGET({
      path: `sms-administrator/objects-types-library${
        rowId ? "/" + rowId : ""
      }`,
      method: rowId ? "PUT" : "POST",
      contentType: "application/json",
      body: bodyRequest,
      setBody: setBodyRequest,
      successCallback: successCallbackAndClose,
      errorCallback,
      disableErrorSnackbar: true,
    });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainAddEditObjectType();
    }
  }, [bodyRequest, fetchAgainAddEditObjectType]);

  useEffect(() => {
    setIsFetching(fetchingStateAddEditObjectType.isFetching);
  }, [fetchingStateAddEditObjectType, setIsFetching]);

  const onSubmit = (data: any) => {
    let jsonDataToSend: any = {
      name: data.name,
      description: data.description,
      states: [],
    };

    data.states.forEach((state) => {
      jsonDataToSend.states.push({
        id: state.id,
        name: state.name,
        value: state.value,
      });
    });

    setBodyRequest(JSON.stringify(jsonDataToSend));
  };

  useEffect(() => {
    if (
      rowId &&
      !fetchingStateObjectType.isFetching &&
      fetchingStateObjectType.response?.status === 200
    ) {
      setValue("name", objectType.name);
      setValue("description", objectType.description);

      if (objectType.states) {
        for (let i = 0; i < objectType.states.length; i++) {
          append({
            id: objectType.states[i].id,
            name: objectType.states[i].name,
            value: objectType.states[i].value,
          });
        }
      }
    }
  }, [
    fetchingStateObjectType.isFetching,
    fetchingStateObjectType.response?.status,
    rowId,
    setValue,
    objectType?.name,
    objectType?.description,
    objectType?.fileName,
    objectType?.fileSize,
    objectType?.states,
    append,
  ]);

  const isBasicDataValidationErrors = useCallback(() => {
    if (
      (errors.name && errors.name?.ref?.name === "name") ||
      (errors.description && errors.description?.ref?.name === "description")
    ) {
      return true;
    }

    return false;
  }, [errors.name, errors.description]);

  const isStatesValidationErrors = useCallback(() => {
    if (errors.states) return true;
    return false;
  }, [errors.states]);

  const handleChangeValueTab = useCallback(
    (event: React.ChangeEvent<any>, newValue: number) => {
      setValueTab(newValue);
    },
    []
  );

  const TabPanel = useCallback(
    (props: { children?: React.ReactNode; index: number; value: number }) => {
      const { children, value, index, ...other } = props;

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          {...other}
        >
          {value === index && <div>{children}</div>}
        </div>
      );
    },
    []
  );

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={fetchingStateObjectType.isFetching}
        isError={fetchingStateObjectType.isError}
        setIfFetchAgain={() => {
          if (fetchingStateObjectType.isError) {
            fetchAgainObjectType();
          }
        }}
      >
        <form
          key={"add_building_form"}
          onSubmit={handleSubmit(onSubmit)}
          id="submitForm"
          noValidate
        >
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            aria-label="disabled tabs example"
            value={valueTab}
            onChange={handleChangeValueTab}
            TabIndicatorProps={{
              style: {
                backgroundColor: `${colorNameDictionary[authUserType]}`,
              },
            }}
            variant="fullWidth"
          >
            <Tab
              label={t("basic_data")}
              value={0}
              icon={
                isBasicDataValidationErrors() ? (
                  <Tooltip title={t("invalid_data")!} placement="top">
                    <Error color="error" />
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            />
            <Tab
              label={t("states")}
              value={1}
              icon={
                isStatesValidationErrors() ? (
                  <Tooltip title={t("invalid_data")!} placement="top">
                    <Error color="error" />
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            />
          </Tabs>

          <TabPanel value={valueTab} index={0}>
            <TwoColumnsRowsContainer>
              <Row>
                <RowTitle width={"100px"}>{t("name")}:</RowTitle>
                <RowInput>
                  <TextFieldReactHookForm
                    name={"name"}
                    control={control}
                    label={t("object_type_name")}
                    required={true}
                  />
                </RowInput>
              </Row>
            </TwoColumnsRowsContainer>

            <TwoColumnsRowsContainer>
              <Row>
                <RowTitle width={"100px"}>{t("description")}:</RowTitle>
                <RowInput>
                  <TextFieldReactHookForm
                    name={"description"}
                    control={control}
                    label={t("object_type_description")}
                    required={true}
                    multiline
                  />
                </RowInput>
              </Row>
            </TwoColumnsRowsContainer>
          </TabPanel>

          <TabPanel value={valueTab} index={1}>
            <ObjectTypeStatesContent
              control={control}
              register={register}
              fields={fields}
              append={append}
              remove={remove}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              watch={watch}
            />
          </TabPanel>
        </form>
      </LoadingWrapper>
    </>
  );
};

export default AddEditObjectTypePopupContent;
