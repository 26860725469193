import React from "react";
import { selectSettings } from "../../../reducers/settings";
// import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHome } from '@fortawesome/free-solid-svg-icons';
import NarrowNavTemplate from "../../Navbar/narrowNavTemplate";
import useNavLinksArray from "./navLinksArray";
// import { selectTimeWorkerProfile } from '../../../reducers/profiles';
// import UserPanel from '../UserPanel';
// import { faHome } from '@fortawesome/free-solid-svg-icons';
// import { MenuItem } from '@material-ui/core';
import styles from "../../Navbar/narrowNavbar.module.scss";

const NarrowNav = (props) => {
  // const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);
  const navLinksArray = useNavLinksArray();
  // const timeWorkerProfile = useAppSelector(selectTimeWorkerProfile);
  // if (timeWorkerProfile?.ifFetched === false) return null
  if (settings.ifSettingsFetched === false) return <div></div>;
  return (
    <>
      <div className={styles.narrowNavbar}>
        <div>
          {navLinksArray.routesArrayWithComponents.map((linksInfo, index) => {
            if (linksInfo.ifHidden) return null;
            return <NarrowNavTemplate key={index} linksInfo={linksInfo} />;
          })}
        </div>
      </div>
    </>
  );
};
export default NarrowNav;
