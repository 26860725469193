import TrainingsValidityReportContent from "../../../helpersComponents/TrainingsValidityReport/TrainingsValidityReportContext";

const TrainingValidityReportShiftDirector = () => {
  return (
    <div>
      <TrainingsValidityReportContent
        pathName="shift-director/trainings-validity-report"
        layoutSettingName="layoutShiftDirectorTrainingValidityReport"
        trainingExpiryDateApiPath="shift-director/trainings-validity-report"
      />
    </div>
  );
};

export default TrainingValidityReportShiftDirector;
