import { Popper } from "@material-ui/core";
import { ChangeEvent } from "devextreme/ui/text_box";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import { AutocompleteStyled } from "../MaterialUi";
import styles from "./SelectCompany.module.scss";

interface Props {
  companiesPathAPI: string;
  setSelectedCompany: Dispatch<SetStateAction<Company | undefined>>;
}

const PopperMy = function (props) {
  return (
    <Popper
      {...props}
      style={{ wordBreak: "break-word", maxWidth: "300px" }}
      placement="bottom-start"
    />
  );
};

const SelectCompany: React.FC<Props> = ({
  companiesPathAPI,
  setSelectedCompany,
}) => {
  const { t } = useTranslation();

  const [acComapnies, fetchingStateACComapnies, fetchAgainACComapnies] =
    useFetchAndSetGET<Company[]>({
      path: companiesPathAPI,
      startFetchOnInitial: false,
    });

  useEffect(() => {
    fetchAgainACComapnies();
  }, [fetchAgainACComapnies]);

  return (
    <div className={styles.card}>
      <div className={styles.title}>{t("select_company")}</div>
      <div className={styles.select}>
        <LoadingWrapper
          isLodadingProgress={fetchingStateACComapnies.isFetching}
          isError={fetchingStateACComapnies.isError}
          setIfFetchAgain={() => {
            if (fetchingStateACComapnies.isError) {
              fetchAgainACComapnies();
            }
          }}
        >
          {acComapnies && (
            <AutocompleteStyled
              required={true}
              label={t("company")}
              options={acComapnies}
              getOptionLabel={(option: any) => {
                return option.name.toUpperCase();
              }}
              getOptionSelected={(option: Company, value: Company) =>
                option.id === value.id
              }
              onChange={(_: ChangeEvent, newValue: Company) => {
                setSelectedCompany(newValue);
              }}
              PopperComponent={PopperMy}
            />
          )}
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default SelectCompany;
