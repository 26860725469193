import { FormControlLabel } from "@material-ui/core";
import { useField } from "formik";
import React, { ReactElement } from "react";
import { CheckboxStyled } from "../../MaterialUi";
import ErrorLabel from "../ErrorLabel/errorLabel";

export default function CheckboxWithLabelFormik({
  label,
  showTopLabel,
  onChangeExtra,
  ...props
}: any): ReactElement {
  const [field, meta] = useField(props);
  let { value } = field;
  const { onChange } = field;
  return (
    <>
      <div>
        {showTopLabel && (
          <div>
            <b>{label}:</b>
          </div>
        )}
        <FormControlLabel
          control={
            <CheckboxStyled
              inputProps={{ "aria-label": "primary checkbox" }}
              label={label}
              type="checkbox"
              checked={value}
              {...field}
              {...props}
              onChange={(e) => {
                if (onChangeExtra) onChangeExtra(e);
                onChange(e);
              }}
            />
          }
          label={<span>{label}</span>}
        />
        {meta.touched && meta.error ? (
          <ErrorLabel errorName={meta.error} />
        ) : null}
      </div>
    </>
  );
}
