import { useTranslation } from "react-i18next";
import { ProfileType } from "../../enums/profileType";
import { selectAuthUser } from "../../reducers/session";
import { selectSettings } from "../../reducers/settings";
import { useAppSelector } from "../../store/hooks";
import LoadingWrapper from "../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../helpersComponents/TopBanner/topBanner";
import styles from "./settings.module.scss";
import DefaultCompanyInSupportSetting from "./Settings/DefaultCompanyInSupportSetting.tsx";
import FCMSetting from "./Settings/FCMSetting";
import FCMSetting2 from "./Settings/FCMSetting2";
import HolidayRequestsSettings from "./Settings/RequestsSettings/HolidayRequestsSettings";
import HourRequestsSettings from "./Settings/RequestsSettings/HourRequestsSettings";
import TwoFactorAuthenticationSetting from "./Settings/TwoFactorAuthenticationSetting";
import HolidaysLimitsSettings from "./Settings/HolidaysLimitsSettings";
import HomeOfficeAddressesSettings from "./Settings/HomeOfficeAddressesSettings";
import TimeInOrganizationalUnitsReportSettings from "./Settings/TimeInOrganizationalUnitsReportSettings/TimeInOrganizationalUnitsReportSettings";

function Settings() {
  const { t } = useTranslation();

  const authUserType = useAppSelector(selectAuthUser)?.currentProfile.type;
  const userPermission =
    useAppSelector(selectAuthUser)?.currentProfile.permissionId;

  const FCMSettingEnabled = authUserType === ProfileType.PFSO;
  const defaultCompanyInSupportEnabled =
    authUserType === ProfileType.OPERATIONAL_SUPPORT;

  const settings = useAppSelector(selectSettings);

  return (
    <div>
      <TopBanner pathName={t("settings")} />
      <div className={styles.settingsContainer}>
        <LoadingWrapper
          isLodadingProgress={settings?.ifSettingsFetched === false}
          isError={false}
          setIfFetchAgain={() => {}}
        >
          <div>
            <div className={styles.settingSection}>
              <TwoFactorAuthenticationSetting />
            </div>

            {authUserType === ProfileType.SUPERIOR_TIME &&
              (userPermission === 1 || userPermission === 2) && (
                <>
                  <div className={styles.settingSection}>
                    <HolidayRequestsSettings />
                  </div>

                  <div className={styles.settingSection}>
                    <HourRequestsSettings />
                  </div>
                </>
              )}

            {authUserType === ProfileType.SUPERIOR_TIME &&
              (userPermission === 1 || userPermission === 2) && (
                <>
                  <div className={styles.settingSection}>
                    <HolidaysLimitsSettings />
                  </div>
                  <div className={styles.settingSection}>
                    <HomeOfficeAddressesSettings />
                  </div>
                </>
              )}

            {authUserType === ProfileType.SUPERIOR_TIME &&
              (userPermission === 1 || userPermission === 2) && (
                <>
                  <div className={styles.settingSection}>
                    <TimeInOrganizationalUnitsReportSettings />
                  </div>
                </>
              )}

            {FCMSettingEnabled && (
              <>
                <div className={styles.settingSection}>
                  <FCMSetting
                    FCMSettingValue={
                      settings["PowiadomieniaOAwizacjiNaTelefon"]?.value
                    }
                  />
                </div>

                <div className={styles.settingSection}>
                  <FCMSetting2
                    FCMSettingValue={
                      settings["PowiadomieniaOWejsciuZejsciuZalogiNaTelefon"]
                        ?.value
                    }
                  />
                </div>
              </>
            )}

            {defaultCompanyInSupportEnabled && (
              <div className={styles.settingSection}>
                <DefaultCompanyInSupportSetting
                  defaultCompanySettingValue={
                    settings["DomyslnaFirmaListaPracownikow"]?.value
                  }
                  defaultComapnySettingId={
                    settings["DomyslnaFirmaListaPracownikow"]?.id
                  }
                />
              </div>
            )}
          </div>
        </LoadingWrapper>
      </div>
    </div>
  );
}

export default Settings;
