import { makeStyles } from "@material-ui/styles";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../PopupHelpers";
import formFieldsModel from "./FormModel/formFieldsModel";
import PopupContentUsersManagement from "./PopupContentUsersManagement";

interface Props {
  isOpen: boolean;
  rowId?: number;
  closePopup: () => void;
  successCallback: () => void;
  extraInfo: ApiPaths;
}

const PopupUsersManagement: React.FC<Props> = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
  extraInfo: apiPaths,
}: Props) => {
  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [requestBody, setRequestBody] = useState<false | any>(false);

  const {
    formFields: {
      //user data
      login,
      permissions,
      leaveCurrentPassword,
      password,
      repeatPassword,
      fullName,
      phoneNumber,
      email,
      //permissions
      departments,
      groups,
      //password policies
      passwordPoliciesArray,
    },
  } = formFieldsModel;

  const onSubmit = (values: any) => {
    setErrorMessage("");
    const body = JSON.stringify({
      login: values[login.name],
      permissionId: values[permissions.name].id,
      leaveCurrentPassword: values[leaveCurrentPassword.name],
      password: values[password.name],
      passwordRepeat: values[repeatPassword.name],
      fullName: values[fullName.name] ? values[fullName.name] : "",
      phoneNumber: values[phoneNumber.name]
        ? values[phoneNumber.name].toString()
        : "",
      email: values[email.name] ? values[email.name] : "",
      isChangePasswordRequired: values.isChangePasswordRequired,
      departmentsIds: values[departments.name]
        ? values[departments.name].map((dep: Department) => dep.id)
        : null,
      groupsIds: values[groups.name]
        ? values[groups.name].map((gr: Group) => gr.id)
        : null,
      policies:
        values[passwordPoliciesArray.name] &&
        values[passwordPoliciesArray.name].length > 0
          ? values[passwordPoliciesArray.name].map((pp: ACUserPolicy) => ({
              policyId: pp.policy?.id,
              policyDateFrom: pp.policyDateFrom,
              policyDateTo: pp.policyDateTo,
            }))
          : null,
    });

    setRequestBody(body);
  };

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${apiPaths.pathUsers}${rowId !== undefined ? `/${rowId}` : ""}`,
    method: rowId !== undefined ? "PUT" : "POST",
    contentType: "application/json",
    body: requestBody,
    setBody: setRequestBody,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, fetchAgainPostData]);

  const useStyles = makeStyles(() => ({
    dialog: {
      height: 620,
      width: 1050,
      margin: 10,
    },
  }));

  const classes = useStyles();

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitleStyled title={t("manage_user")} />
      <PopupContentUsersManagement
        rowId={rowId}
        onSubmit={onSubmit}
        apiPaths={apiPaths}
      />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={() => {
          setErrorMessage("");
          closePopup();
        }}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupUsersManagement;
