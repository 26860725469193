import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import AddEditObjectTypePopup from "./Popups/AddEditObjectTypePopup";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import RowDetailsObjectType from "./rowDetailsObjectType";

const ObjectsTypesLibrarySMSAdministrator = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const [objectTypeIdToRemove, setObjectTypeIdToRemove] = useState(false);

  const columns = [
    { name: "name", title: t("name") },
    { name: "description", title: t("description") },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "editedBy", title: t("edited") },
    { name: "editedAt", title: t("data_edited"), type: "date_hours" },
  ];

  const [objectsTypes, fetchingStateObjectsTypes, fetchAgainObjectsTypes] =
    useFetchAndSetGET({
      path: `sms-administrator/objects-types-library`,
      startFetchOnInitial: true,
    });

  const [, fetchAgainRemoveObjectType] = useFetchOtherThanGET({
    path: `sms-administrator/objects-types-library/${objectTypeIdToRemove}`,
    method: "DELETE",
    body: objectTypeIdToRemove,
    setBody: setObjectTypeIdToRemove,
    successCallback: fetchAgainObjectsTypes,
  });

  useEffect(() => {
    if (objectTypeIdToRemove) {
      fetchAgainRemoveObjectType();
    }
  }, [objectTypeIdToRemove, fetchAgainRemoveObjectType]);

  return (
    <div>
      <TopBanner pathName={t("objects_types")} />
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isLodadingProgress={fetchingStateObjectsTypes.isFetching}
            isError={fetchingStateObjectsTypes.isError}
            setIfFetchAgain={() => {
              fetchAgainObjectsTypes();
            }}
          >
            {!fetchingStateObjectsTypes.isFetching && (
              <TableComponent
                layoutSettingName="layoutSMSAdministratorObjectsTypesLibrary"
                rows={objectsTypes || []}
                columns={columns}
                rowDetail={RowDetailsObjectType}
                actionsColumnUpdated={{
                  popup: (props) => <AddEditObjectTypePopup {...props} />,
                  successCallbackPopup: fetchAgainObjectsTypes,
                  addButton: {
                    ifShow: true,
                  },
                  removeButton: {
                    ifShow: true,
                    name: t("delete"),
                    onClick: async (item) => {
                      let selectedbuttonId = await confirm({
                        text: `${t("confirm_delete_object_type")}`,
                        buttons: [
                          { buttonName: t("remove"), buttonId: 0 },
                          { buttonName: t("cancel"), buttonId: 1 },
                        ],
                      });

                      if ((await selectedbuttonId) === 0) {
                        setObjectTypeIdToRemove(item.id);
                      }
                    },
                  },
                  editButton: {
                    ifShow: true,
                    name: t("edit"),
                  },
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
};

export default ObjectsTypesLibrarySMSAdministrator;
