import { useTranslation } from "react-i18next";
import toLocaleStringCustom from "../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import AccordionStyled from "../MaterialUi/Accordion/AccordionStyled";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../RowDetailsHelpers";

const RowDetails = ({ row }: { row: IGuestEvent }) => {
  const { t } = useTranslation();

  return (
    <RowDetailsContainer>
      {/*  */}
      <AccordionStyled title={t("person")} defaultExpanded={true}>
        <>
          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("person")}:</RowDetailsTitle>
              <RowDetailsValue>{`${row?.guestFirstName} ${row?.guestLastName}`}</RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("date_of_birth")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestDateOfBirth
                  ? toLocaleStringCustom({ date: row?.guestDateOfBirth, t })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("email")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestEmail ? row.guestEmail : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("training_expiry_date")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestTrainingExpiryDate
                  ? toLocaleStringCustom({
                      date: row?.guestTrainingExpiryDate,
                      t,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>
        </>
      </AccordionStyled>

      {/*  */}
      <AccordionStyled title={t("information")} defaultExpanded={true}>
        <>
          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("event_time")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.eventTime
                  ? toLocaleStringCustom({
                      date: row.eventTime,
                      ifShowHours: true,
                      t,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("room")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.roomName ? row.roomName : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("card_tag")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.cardUniversalNumber !== "0000000000000000"
                  ? row.cardUniversalNumber
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("company")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestCompanyName ? row.guestCompanyName : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("entry_date")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.entryDate
                  ? toLocaleStringCustom({
                      date: row.entryDate,
                      ifShowHours: true,
                      t,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("exit_date")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.exitDate
                  ? toLocaleStringCustom({
                      date: row.exitDate,
                      ifShowHours: true,
                      t,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("type_of_entry")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.typeOfEntry ? row.typeOfEntry : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("reader")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.readerSerialNumber ? row.readerSerialNumber : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("worker")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.workerFirstName
                  ? `${row?.workerFirstName} ${row?.workerLastName}`
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("worker_evidence_number")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.workerEvidenceNumber ? row.workerEvidenceNumber : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>
        </>
      </AccordionStyled>
    </RowDetailsContainer>
  );
};

export default RowDetails;
