import { useEffect, useReducer, useRef } from "react";
import fetchDelete from "./fetchDelete";
import useLogOut from "../../LogOut/logOut";

const useFetchDeleteReducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { data: null, isLoading: true, error: null };
    case "SUCCESS":
      return { data: action.payload, isLoading: false, error: null };
    case "ERROR":
      return { data: null, isLoading: false, error: action.payload };
  }
  return state;
};

const useFetchDelete = (path: string, ifFetch: boolean = true) => {
  const [response, dispatch] = useReducer(useFetchDeleteReducer, {
    data: null,
    isLoading: true,
    error: null,
  });
  let isMounted = useRef(true);
  useLogOut(response.error);
  useEffect(() => {
    if (!ifFetch) return;

    dispatch({ type: "FETCHING" });
    fetchDelete(path)
      .then((data) => {
        if (isMounted.current) {
          dispatch({ type: "SUCCESS", payload: data });
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          dispatch({ type: "ERROR", payload: error });
        }
      });
  }, [path, ifFetch]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return response;
};

export default useFetchDelete;
