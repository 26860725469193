import React from "react";
import { useAppSelector } from "../../../store/hooks";
import useSMSUserMenuBuilder from "../../../hooks/sms/useSMSUserMenuBuilder/useSMSUserMenuBuilder";
import AdvancedNarrowNavbar from "../../AdvancedNarrowNavbar/AdvancedNarrowNavbar";
import { selectSettings } from "../../../reducers/settings";

const NarrowNav = () => {
  const settings = useAppSelector(selectSettings);
  const { menuData, menuDataLoaded } = useSMSUserMenuBuilder();

  if (!settings.ifSettingsFetched || !menuDataLoaded) return <div></div>;

  return <AdvancedNarrowNavbar menuData={menuData} />;
};
export default NarrowNav;
