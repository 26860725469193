import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-multilevel-dropdown";
import styles from "./styles.module.scss";

interface IProps {
  item: ISelectMenuItem;
  isDisabled?: boolean;
  position?: "left" | "right" | "bottom" | "left-top" | "right-top";
}

const MenuItem: React.FC<IProps> = ({ item, position, isDisabled }) => {
  return !item.hidden ? (
    <Dropdown.Item
      onClick={item.onClick}
      className={styles.menuItem}
      isDisabled={item.isDisabled}
    >
      {item.optionName}
      {item.children && item.children.length > 0 && (
        <>
          <div>
            <FontAwesomeIcon icon={faCaretRight} color="black" size="lg" />
          </div>
          <Dropdown.Submenu
            position={position ? position : "bottom"}
            className={styles.dropdownMenu}
          >
            {item.children.map((child, indexChild) => (
              <MenuItem item={child} position={position} key={indexChild} />
            ))}
          </Dropdown.Submenu>
        </>
      )}
    </Dropdown.Item>
  ) : (
    <></>
  );
};

export default MenuItem;
