export enum EventType {
    ENTRY = 1,
    BUSINESS_ENTRY_EXIT = 2,
    AC_ENTRY = 4,
    EXIT = 8,
    REMOTE_ENTRY = 11,
    REMOTE_EXIT = 18,
    CANCELED_ENTRY = 1001,
    CANCELED_BUSINESS_EXIT = 1002,
    CANCELED_EXIT = 1008,

    END_WORK_ON_LINE = 2009,
}