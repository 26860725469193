import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../../helpersComponents/PopupHelpers/dialogActionsStyled";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import DialogStyled from "../../../helpersComponents/PopupHelpers/dialogStyled";
import { DialogContentStyled } from "../../../helpersComponents/PopupHelpers/dialogContentStyled";
import DialogTitleStyled from "../../../helpersComponents/PopupHelpers/dialogTitleStyled";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import TwoColumnsRowsContainer from "../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import Row from "../../../helpersComponents/PopupHelpers/row";
import RowTitle from "../../../helpersComponents/PopupHelpers/rowTitle";
import RowInput from "../../../helpersComponents/PopupHelpers/rowInput";
import erro400getTranslatedErrorString from "../../../../HelpersFunctions/erro400getTranslatedErrorString";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";

interface IFormInput {
  buildingGroupName: string;
  buildingGroupOrder: string;
}

const AddEditBuildingGroupPopup: React.FC<any> = ({
  /*row,
  onApplyChanges,
  onCancelChanges,
  open,*/
  isOpen,
  rowId,
  closePopup,
  successCallback,
}) => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);
  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const successCallbackAndClose = useCallback(() => {
    successCallback();
  }, [successCallback]);

  const errorCallback = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setErrorMessage(error);
      }
    },
    [t]
  );

  const [fetchingStateAddEditBuildingGroup, fetchAgainAddEditBuildingGroup] =
    useFetchOtherThanGET({
      path: `sms-administrator/buildings-groups${rowId ? "/" + rowId : ""}`,
      method: rowId ? "PUT" : "POST",
      body: bodyRequest,
      setBody: setBodyRequest,
      contentType: "application/json",
      successCallback: successCallbackAndClose,
      errorCallback,
      disableErrorSnackbar: true,
    });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainAddEditBuildingGroup();
    }
  }, [bodyRequest, fetchAgainAddEditBuildingGroup]);

  const schema = yup.object({
    buildingGroupName: yup.string().required(t("field_required")),
    buildingGroupOrder: yup.string().required(t("field_required")),
  });

  const {
    //register,
    handleSubmit,
    reset,
    control,
    setValue,
    //formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const [buildingGroup, fetchingStateBuildingGroup, fetchAgainBuildingGroup] =
    useFetchAndSetGET({
      path: `sms-administrator/buildings-groups/${rowId}`,
      startFetchOnInitial: false,
    });

  useEffect(() => {
    if (rowId) {
      fetchAgainBuildingGroup();
    }
  }, [rowId, fetchAgainBuildingGroup]);

  useEffect(() => {
    if (!fetchingStateBuildingGroup.isFetching && buildingGroup) {
      setValue("buildingGroupName", buildingGroup.name);
      setValue("buildingGroupOrder", buildingGroup.order);
    }
  }, [buildingGroup, fetchingStateBuildingGroup.isFetching, setValue]);

  const onSubmit = (data) => {
    let dataToSend = {
      name: data.buildingGroupName,
      order:
        settings.WebSMSAdministratorUkryjPoleKolejnoscGrupyBudynkow?.value ===
        "1"
          ? 0
          : data.buildingGroupOrder,
    };

    setBodyRequest(JSON.stringify(dataToSend));
  };

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      TransitionProps={{
        onExited: () => {
          reset();
        },
        onEnter: () => {
          setErrorMessage("");
        },
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitleStyled
        title={t("buildings_group")}
        subtitle={
          rowId
            ? t("editing_group_of_buildings")
            : t("adding_group_of_buildings")
        }
      />

      <DialogContentStyled>
        <form onSubmit={handleSubmit(onSubmit)} id="submitForm">
          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("name")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"buildingGroupName"}
                  control={control}
                  label={t("buildings_group_name")}
                  required={true}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          {settings.WebSMSAdministratorUkryjPoleKolejnoscGrupyBudynkow
            ?.value !== "1" && (
            <TwoColumnsRowsContainer>
              <Row>
                <RowTitle width={"100px"}>{t("order")}:</RowTitle>
                <RowInput>
                  <TextFieldReactHookForm
                    name="buildingGroupOrder"
                    type="number"
                    control={control}
                    label={t("buildings_group_order")}
                    required={true}
                  />
                </RowInput>
              </Row>
            </TwoColumnsRowsContainer>
          )}
        </form>
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onConfirmText={rowId ? t("confirm") : t("add")}
        onCancel={() => {
          closePopup();
        }}
        isLoading={fetchingStateAddEditBuildingGroup.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default AddEditBuildingGroupPopup;
