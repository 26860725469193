import { useTranslation } from "react-i18next";
import styles from "./importFileStyle.module.scss";
import OwnButton from "../../helpersComponents/Button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import useFetchPostFormData from "../../../hooks/fetchHooks/post/useFetchPostFormData";
import { useSnackbar } from "notistack";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { profilesRoutesDictionary } from "../../ProfilesRouting/profilesDictionaries";
import { saveAs } from "file-saver";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";

interface FileToImport {
  setIsFetching?: React.Dispatch<React.SetStateAction<any>>;
  setFetchAgain?: React.Dispatch<React.SetStateAction<any>>;
  callback?: () => void;
  exampleFile: any;
  hideExampleFile?: boolean;
  address: string;
  displayDeclarationDialog?: boolean;
}

const ImportFile = (props: FileToImport) => {
  const [selectedFile, setSelectedFile] = useState<any>(false);
  const [postFileBody, setPostFileBody] = useState<boolean>(false);
  const [importFileCircualProgress, setImportFileCircualProgress] =
    useState<boolean>(false);
  const ref: any = useRef();
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;

  const {
    setIsFetching,
    callback,
    setFetchAgain,
    exampleFile,
    hideExampleFile,
    address,
    displayDeclarationDialog,
  } = props;
  let postFileRequest = useFetchPostFormData(address, postFileBody);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (postFileRequest.data?.status === 200) {
      enqueueSnackbar(t("successful_import"), { variant: "success" });
      if (setFetchAgain) setFetchAgain(true);
      if (setIsFetching) setIsFetching(true);
      if (callback) callback();
      setSelectedFile(false);
      setPostFileBody(false);
    } else if (postFileRequest.data?.status === 400) {
      let error = "";
      Object.keys(postFileRequest.data.errors).forEach((errorObject) => {
        postFileRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      if (setIsFetching) setIsFetching(true);
      enqueueSnackbar(error, { variant: "error" });
    } else if (postFileRequest.error !== null) {
      if (setIsFetching) setIsFetching(true);
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
    }
    setImportFileCircualProgress(false);
  }, [
    postFileRequest,
    setFetchAgain,
    callback,
    setIsFetching,
    enqueueSnackbar,
    t,
  ]);

  const [importedGuestsOnBlacklist, setImportedGuestsOnBlacklist] =
    useState<any[]>();

  const [
    bodyCheckGuestsOnBlackListRequest,
    setBodyCheckGuestsOnBlackListRequest,
  ] = useState<any>(false);

  const successCallbackRef: any = useRef();

  const checkGuestsOnBlackListSuccessCallback = useCallback(
    async (response: UseFetchResponse) => {
      let tempGuestsInImportedFile: any[] = [];

      if (response.resJson) {
        if (response.resJson.importedGuests) {
          for (let i = 0; i < response.resJson.importedGuests.length; i++) {
            tempGuestsInImportedFile.push({
              firstName: response.resJson.importedGuests[i].firstName,
              lastName: response.resJson.importedGuests[i].lastName,
              dateOfBirth: response.resJson.importedGuests[i].dateOfBirth,
              email: response.resJson.importedGuests[i].email,
            });
          }
        }
      }

      setImportedGuestsOnBlacklist(tempGuestsInImportedFile);

      if (tempGuestsInImportedFile.length === 0) {
        if (successCallbackRef.current) {
          successCallbackRef.current();
        }
      }

      successCallbackRef.current = undefined;
    },
    []
  );

  const [, fetchAgainCheckGuestsOnBlackList] = useFetchOtherThanGET({
    path: `operational-support/notification-guests-in-ports/check-guests-on-black-list`,
    method: "POST",
    body: bodyCheckGuestsOnBlackListRequest,
    setBody: setBodyCheckGuestsOnBlackListRequest,
    successCallback: checkGuestsOnBlackListSuccessCallback,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (bodyCheckGuestsOnBlackListRequest) {
      fetchAgainCheckGuestsOnBlackList();
    }
  }, [bodyCheckGuestsOnBlackListRequest, fetchAgainCheckGuestsOnBlackList]);

  useEffect(() => {
    if (importedGuestsOnBlacklist && importedGuestsOnBlacklist.length > 0) {
      confirm({
        typeDialog: "info",
        text: t("file_contains_person_from_black_list"),
        buttons: [{ buttonName: t("ok"), buttonId: 0 }],
      });
    }
  }, [confirm, importedGuestsOnBlacklist, t]);

  const profileName = profilesRoutesDictionary[profileType];

  const modifyResponseCallback = useCallback(
    (response) => {
      saveAs(response, exampleFile.filePath);
    },
    [exampleFile.filePath]
  );

  const [, , fetchAgainExampleFile] = useFetchAndSetGET({
    path: `${profileName}/example-files?fileName=${exampleFile.filePath}`,
    startFetchOnInitial: false,
    ifBlob: true,
    modifyResponseCallback: modifyResponseCallback,
  });

  return (
    <div className={styles.containerImportFile}>
      <div className={styles.containerHeader}>
        <div>
          <b>{t("import_from_file")}</b>
        </div>
        {!hideExampleFile && (
          <div className={styles.containerExampleFile}>
            <a
              href={exampleFile.filePath}
              className="importLink"
              download={exampleFile.name}
            >
              <Button
                onClick={() => {
                  fetchAgainExampleFile();
                }}
              >
                <div className="exampleFileNotifications">
                  (<div>{t("example_file_to_import")}</div>
                  <div>
                    <FontAwesomeIcon icon={faArrowDown} />
                  </div>
                  )
                </div>
              </Button>
            </a>
          </div>
        )}
      </div>
      <div className={styles.containerInput}>
        <div className={styles.selectedFiles}>
          <input
            type="file"
            style={{ display: "none" }}
            accept={".xls, .xlsx"}
            onClick={(e: any) => (e.target.value = null)}
            onChange={(e) => {
              if (!e.target.files) return;
              if (!e.target.files?.[0]) {
                return setSelectedFile(false);
              }
              setSelectedFile(e.target.files?.[0]);
            }}
            ref={ref}
          />
          <OwnButton
            onClick={() => {
              ref.current.click();
            }}
          >
            {t("select_file")}
          </OwnButton>
        </div>
        {selectedFile ? (
          <div className={styles.selectedFile}>
            <div>
              <b>{selectedFile.name}</b>{" "}
              {(selectedFile.size * 0.001).toFixed(2)} kB
            </div>
            <div>
              {!importFileCircualProgress ? (
                <OwnButton
                  onClick={async () => {
                    let importFile = true;

                    if (displayDeclarationDialog) {
                      importFile = false;

                      let selectedbuttonId = await confirm({
                        text: `${t(
                          "operational_support_import_employee_declaration"
                        )}`,
                        buttons: [
                          { buttonName: t("yes"), buttonId: 0 },
                          { buttonName: t("cancel"), buttonId: 1 },
                        ],
                      });
                      if ((await selectedbuttonId) === 0) {
                        importFile = true;
                      }
                    }

                    if (importFile) {
                      if (!selectedFile) return;

                      let dataToSend: any = {
                        guestsToCheck: [],
                        checkOnlyFirstAndLastName: true,
                      };

                      let formData = new FormData();
                      formData.append(
                        "requestData",
                        JSON.stringify(dataToSend)
                      );

                      if (selectedFile) {
                        formData.append("importedFile", selectedFile);
                      }

                      const successCallback = () => {
                        let formdata: any = new FormData();
                        formdata.append("importedFile", selectedFile);
                        setPostFileBody(formdata);
                        if (setIsFetching) setIsFetching(true);
                        setImportFileCircualProgress(true);
                      };

                      successCallbackRef.current = successCallback;
                      setBodyCheckGuestsOnBlackListRequest(formData);
                    }
                  }}
                >
                  {t("import")}
                </OwnButton>
              ) : (
                <div className={styles.circularProgressImport}>
                  <CircularProgress size={25} />
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ImportFile;
