import fetchPost from "../../hooks/fetchHooks/post/fetchPost";

interface logInInterface {
  token: string;
  tokenDecoded: tokenDecodedInterface;
  selectedProfile: authUserSingleProfileData;
  isChangingProfileOldProfileType: false | number;
  isChangingProfileOldProfileSubjectId: false | number;
  setAuthUserToRedux: (authUser: authUserInfo) => void;
  resetReduxState: (() => void) | false;
}

export const logIn = ({
  token,
  tokenDecoded,
  selectedProfile,
  isChangingProfileOldProfileType,
  isChangingProfileOldProfileSubjectId,
  setAuthUserToRedux,
  resetReduxState,
}: logInInterface) => {
  let displayedName;
  if (selectedProfile?.userName) {
    displayedName = selectedProfile.userName;
  } else {
    displayedName = tokenDecoded.firstName + " " + tokenDecoded.lastName;
  }

  let authUser: authUserInfo = {
    token: token,
    currentProfile: selectedProfile,
    displayedName: displayedName,
    tokenDecoded: tokenDecoded,
  };
  localStorage.setItem("authUser", JSON.stringify(authUser));

  if (
    isChangingProfileOldProfileType !== false &&
    isChangingProfileOldProfileSubjectId !== false
  ) {
    fetchPost("account/logged-out-log", {
      profileType: isChangingProfileOldProfileType,
      subjectId: isChangingProfileOldProfileSubjectId,
    });
  }

  setTimeout(() => {
    fetchPost("account/logged-in-log", {
      profileType: selectedProfile.type,
      subjectId: selectedProfile.subjectId,
    });
  }, 500);

  if (resetReduxState !== false) resetReduxState();
  setAuthUserToRedux(authUser);
};
