import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import {
  faAddressBook,
  faBullhorn,
  faClipboardList,
  faEdit,
  faEnvelope,
  faFolder,
  faHome,
  faInfo,
  faTasks,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import HomePage from "../../workerTime/HomePage/index";
import MyFiles from "../../workerTime/MyFiles/index";
import Worktime from "../../workerTime/summary/Worktime/index";
import AbsenceOfCoworkers from "../../workerTime/summary/AbsenceOfCoworkers/index";
import AnnualRecords from "../../workerTime/summary/AnnualRecords/index";
import BusinessExits from "../../workerTime/requests/BusinessExits/index";
import DelegationsCities from "../../workerTime/delegations/DelegationsCities/index";
import DelegationsList from "../../workerTime/delegations/DelegationsList/index";
import Equipment from "../../workerTime/summary/Equipment/index";
import Events from "../../workerTime/summary/Events/index";
import Holiday from "../../workerTime/requests/Holiday/index";
import HolidaysPlan from "../../workerTime/requests/HolidaysPlan/index";
import HoursRequest from "../../workerTime/requests/HoursRequest/index";
import KeyPermissions from "../../workerTime/summary/KeyPermissions/index";
import Bonuses from "../../workerTime/summary/Bonuses/index";
import Messages from "../../workerTime/Messages/index";
import NotificationGuests from "../../workerTime/notification/NotificationGuests/index";
import NotificationList from "../../workerTime/notification/NotificationList/index";
import Schedule from "../../workerTime/summary/Schedule/index";
import AddressBook from "../../workerTime/addressBook/index";
import ProjectsHome from "../../workerTime/projects/Home/index";
import Project from "../../workerTime/projects/Project/index";
import MyTasks from "../../workerTime/projects/MyTasks/index";
import Profile from "../../UserPanel/profile";
import TestGeneratePDF from "../../workerTime/TestGeneratePDF/testGeneratePDF";
import Trainings from "../../workerTime/Trainings";
import AnnualHolidaysPlan from "../../workerTime/requests/AnnualHolidaysPlan";
import RequestsToDownload from "../../workerTime/requests/RequestsToDownload";
import HomeOffice from "../../workerTime/requests/HomeOffice";
import UniversalRequests from "../../workerTime/requests/UniversalRequests";
import Operations from "../../workerTime/Operations/Operations";
import OperationsHistory from "../../workerTime/summary/OperationsHistory/OperationsHistory";

// const Empty = () => <div>No component</div>;

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);
  const navLinksArrayTimeWorker: navLinksArrayTimeWorkerInterface = {
    profileId: 3,
    hidenInNavBar: [
      {
        ifHidden: settings.WebProjektyUkryjZakladkeProjekty?.value === "1",
        path: "/worker-time/projects/:projectId",
        component: (props) => <Project {...props} />,
      },
      {
        ifHidden: false,
        path: "/worker-time/TestGeneratePDF",
        component: (props) => <TestGeneratePDF {...props} />,
      },
    ],
    routesArrayWithComponents: [
      {
        icon: faHome,
        name: t("home_page"),
        ifHidden: settings.WebUkryjStroneGlowno?.value === "1",
        singleLink: true,
        path: "/worker-time/home",
        component: () => <HomePage />,
      },
      {
        icon: faTasks,
        name: t("operations"),
        ifHidden: settings.WebUkryjOperacje?.value === "1",
        singleLink: true,
        path: "/worker-time/operations",
        component: () => <Operations />,
      },
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/worker-time/profile",
        component: () => <Profile />,
      },
      {
        icon: faClipboardList,
        name:
          settings.WebNazwaPodsumowanie?.value !== "0" &&
          settings.WebNazwaPodsumowanie?.value !== undefined
            ? settings.WebNazwaPodsumowanie.value
            : t("summary"),
        ifHidden: settings.WebUkryjPodsumowaniaPracownik?.value === "1",
        singleLink: false,
        path: [
          {
            name: t("operations_history"),
            path: "/worker-time/operations-history",
            ifHidden:
              settings.WebUkryjPodsumowanieHistoriaOperacji?.value === "1",
            component: () => <OperationsHistory />,
          },

          {
            name: t("worktime"),
            path: "/worker-time/worktime",
            ifHidden: settings.WebUkryjPodsumowanieCzasPracy?.value === "1",
            component: () => <Worktime />,
          },
          {
            name: t("events"),
            path: "/worker-time/events",
            ifHidden: settings.WebUkryjPodsumowanieZdarzenia?.value === "1",
            component: () => <Events />,
          },
          {
            name: t("schedule"),
            path: "/worker-time/schedule",
            ifHidden: settings.WebUkryjPodsumowanieHarmonogram?.value === "1",
            component: () => <Schedule />,
          },
          {
            name: t("absences_of_coworkers"),
            path: "/worker-time/absence-of-coworkers",
            ifHidden:
              settings.WebUkryjPodsumowanieNieobecnoscWspolpracownikow
                ?.value === "1",
            component: () => <AbsenceOfCoworkers />,
          },
          {
            name: t("key_permissions"),
            path: "/worker-time/key-permissions",
            ifHidden:
              settings.WebUkryjPodsumowanieUprawnieniaKlucze?.value === "1",
            component: () => <KeyPermissions />,
          },
          {
            name: t("health_and_safety"),
            path: "/worker-time/equipment",
            ifHidden: settings.WebUkryjPosiadanaOdziezBHPISprzet?.value === "1",
            component: () => <Equipment />,
          },
          {
            name: t("bonuses"),
            path: "/worker-time/bonuses",
            ifHidden: settings.WebUkryjPodsumowaniePremie?.value === "1",
            // WebUkryjPremie
            component: () => <Bonuses />,
          },
          {
            name: t("annual_records"),
            path: "/worker-time/annual-records",
            ifHidden:
              settings.WebUkryjPodsumowanieEwidencjaRoczna?.value === "1",
            component: () => <AnnualRecords />,
          },
        ],
      },
      {
        icon: faTasks,
        name: t("projects"),
        ifHidden: settings.WebProjektyUkryjZakladkeProjekty?.value === "1",
        singleLink: false,
        path: [
          {
            name: t("projects_home"),
            path: "/worker-time/projects/home",
            ifHidden: settings.WebProjektyUkryjProjekty?.value === "1",
            component: () => <ProjectsHome />,
          },
          {
            name: t("my_tasks"),
            path: "/worker-time/projects/my-tasks",
            ifHidden: settings.WebProjektyUkryjMojeZadania?.value === "1",
            component: () => <MyTasks />,
          },
        ],
      },
      {
        icon: faBullhorn,
        name: t("notification"),
        ifHidden:
          settings.WebUkryjPracownikAnonse?.value === "1" ||
          settings.ZablokujAnonse?.value === "1",
        singleLink: false,
        path: [
          {
            name: t("list"),
            path: "/worker-time/notification-list",
            ifHidden: settings.WebUkryjPracownikAnonseLista?.value === "1",
            component: () => <NotificationList />,
          },
          {
            name: t("guests"),
            path: "/worker-time/notification-guests",
            ifHidden: settings.WebUkryjPracownikAnonseGoscie?.value === "1",
            component: () => <NotificationGuests />,
          },
        ],
      },
      {
        icon: faUserTie,
        name: t("delegations"),
        ifHidden: settings.WebUkryjPracownikDelegacja?.value === "1",
        singleLink: false,
        path: [
          {
            name: t("list"),
            path: "/worker-time/delegations-list",
            ifHidden: settings.WebUkryjPracownikDelegacjaLista?.value === "1",
            component: () => <DelegationsList />,
          },
          {
            name: t("cities"),
            path: "/worker-time/delegations-cities",
            ifHidden: settings.WebUkryjPracownikDelegacjaMiasta?.value === "1",
            component: () => <DelegationsCities />,
          },
        ],
      },
      {
        icon: faFolder,
        name: t("trainings"),
        ifHidden: settings.WebUkryjPracownikSzkolenia?.value === "1",
        singleLink: true,
        path: "/worker-time/trainings",
        component: () => <Trainings />,
      },
      {
        icon: faEdit,
        name: t("requests"),
        ifHidden: settings.WebUkryjPracownikWnioski?.value === "1",
        singleLink: false,
        path: [
          {
            name: t("holidays_plan"),
            path: "/worker-time/holidays-plan",
            ifHidden:
              settings.WebUkryjPracownikWnioskiPlanUrlopu?.value === "1",
            component: () => <HolidaysPlan />,
          },
          {
            name: t("annual_holidays_plan"),
            path: "/worker-time/annual-holidays-plan",
            ifHidden:
              settings.WebUkryjPracownikWnioskiRocznyPlanUrlopu?.value === "1",
            component: () => <AnnualHolidaysPlan />,
          },
          {
            name: t("holiday"),
            path: "/worker-time/holiday",
            ifHidden: settings.WebUkryjPracownikWnioskiUrlopowe?.value === "1",
            component: () => <Holiday />,
          },
          {
            name: t("home_office"),
            path: "/worker-time/home-office-requests",
            ifHidden:
              settings.WebUkryjPracownikWnioskiPracaZdalna?.value === "1",
            component: () => <HomeOffice />,
          },
          {
            name: t("for_hour"),
            path: "/worker-time/hours-request",
            ifHidden: settings.WebUkryjPracownikWnioskiGodzin?.value === "1",
            component: () => <HoursRequest />,
          },
          {
            name: t("universal"),
            path: "/worker-time/universal-requests",
            ifHidden:
              settings.WebUkryjPracownikWnioskiUniwersalne?.value === "1",
            component: () => <UniversalRequests />,
          },
          {
            name: t("business_exits"),
            path: "/worker-time/business-exits",
            ifHidden: settings.WebUkryjPracownikWyjsciaSluzbowe?.value === "1",
            component: () => <BusinessExits />,
          },
          {
            name: t("to_download"),
            path: "/worker-time/requests-to-download",
            ifHidden: settings.WebUkryjWnioskiDoPobrania?.value === "1",
            component: () => <RequestsToDownload />,
          },
        ],
      },
      {
        icon: faEnvelope,
        name: t("messages"),
        ifHidden: settings.WebUkryjPracownikWiadomości?.value === "1",
        singleLink: true,
        path: "/worker-time/messages",
        component: () => <Messages />,
      },
      {
        icon: faAddressBook,
        name: t("address_book"),
        ifHidden: settings.WebUkryjPracownikKsiazkaAdresowa?.value === "1",
        singleLink: true,
        path: "/worker-time/address-book",
        component: () => <AddressBook />,
      },
      {
        icon: faFolder,
        name: t("my_files"),
        ifHidden: settings.WebUkryjPracownikMojePliki?.value === "1",
        singleLink: true,
        path: "/worker-time/my-files",
        component: () => <MyFiles />,
      },
    ],
  };

  if (settings.WyswietlajWMenuTylkoZadania?.value === "1") {
    navLinksArrayTimeWorker.routesArrayWithComponents = [];

    navLinksArrayTimeWorker.routesArrayWithComponents.push({
      icon: faTasks,
      singleLink: true,
      name: t("projects_home"),
      path: "/worker-time/projects/home",
      ifHidden: settings.WebProjektyUkryjProjekty?.value === "1",
      component: () => <ProjectsHome />,
    });

    navLinksArrayTimeWorker.routesArrayWithComponents.push({
      icon: faTasks,
      singleLink: true,
      name: t("my_tasks"),
      path: "/worker-time/projects/my-tasks",
      ifHidden: settings.WebProjektyUkryjMojeZadania?.value === "1",
      component: () => <MyTasks />,
    });
  }

  return navLinksArrayTimeWorker;
};
export default useNavLinksArray;
