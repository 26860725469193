import { useField } from "formik";
import React, { ReactElement } from "react";
import { AutocompleteStyled } from "../../MaterialUi";
import ErrorLabel from "../ErrorLabel/errorLabel";

export default function AutocompleteFormik({
  label,
  showTopLabel,
  onChangeExtra,
  ...props
}: any): ReactElement {
  const [field, meta, helpers] = useField(props);
  return (
    <div>
      {showTopLabel && <div>{label}</div>}
      <AutocompleteStyled
        isError={meta.touched && meta.error}
        {...field}
        {...props}
        onChange={(event, value) => {
          if (onChangeExtra) onChangeExtra(event, value);
          helpers.setValue(value);
        }}
        onBlur={() => helpers.setTouched(true)}
        label={label}
      />
      {meta.touched && meta.error ? (
        <ErrorLabel errorName={meta.error} />
      ) : null}
    </div>
  );
}
