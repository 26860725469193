import { bankersRound } from "../math/rounding/bankersRound";

export function convertTime(valueInHours, isDay, holidayHoursLimitInDays) {
  let timeToRet = valueInHours;

  // jeśli oryginalna jednostka to dni
  if (isDay) {
    timeToRet /= 8;
  } // jeśli oryginalna jednostka to godziny
  else {
    if (holidayHoursLimitInDays) {
      timeToRet /= 8;
    }
  }

  return bankersRound(timeToRet, 2);
}
