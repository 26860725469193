import ContentNotifications from "./ContentNotifications";

function NotificationReception() {
  return (
    <div>
      <ContentNotifications />
    </div>
  );
}

export default NotificationReception;
