import { DayOfWeek } from "../../../../enums/dayOfWeek";
import { DaysSchemasValidationError } from "../../../../enums/daysSchemasValidationError";
import { GlobalCalendarDayType } from "../../../../enums/globalCalendarDayType";
import { WorkShiftDayType } from "../../../../enums/workShiftDayType";
import { WorkShiftRequireWork } from "../../../../enums/workShiftRequireWork";
import addDaysToDate from "../../../../HelpersFunctions/dateAndTime/addDaysToDate";
import compareDatesIgnoringTime from "../../../../HelpersFunctions/dateAndTime/compareDatesIgnoringTime";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import {
  totalHoursDiff,
  totalMinutesDiff,
} from "../../../../HelpersFunctions/dateAndTime/timeDiffs";

export class SchedulesValidator {
  private _slGlobalCalendarDaysTypes: { [key: number]: GlobalCalendarDayType };

  constructor(
    private dontDisplayNoThirtyFiftHoursBreakInWeek: string,
    private dontDisplayNoElevenHoursOfBreak: string,
    private dontDisplayMaxSixDaysInRowExceeded: string,
    private dontDisplayWorkingDayLimitExceeded: string,
    private dontDisplayEveryFourthSundayFree: string
  ) {
    this._slGlobalCalendarDaysTypes = {};
  }

  validate(
    billingPeriodFrom: Date,
    billingPeriodTo: Date,
    timeWorkerId: number,
    selectedMonth: Date,
    daysSchemas: Array<DaySchema>,
    slGlobalCalendarsDaysTypes: { [key: number]: GlobalCalendarDayType }
  ): Array<DaysSchemasValidationErrorModel> {
    let validationErrorsResult: Array<DaysSchemasValidationErrorModel> =
      new Array<DaysSchemasValidationErrorModel>();

    this._slGlobalCalendarDaysTypes = slGlobalCalendarsDaysTypes;

    if (this.dontDisplayNoThirtyFiftHoursBreakInWeek !== "1") {
      let validationErrors: DaysSchemasValidationErrorModel | null =
        this.noThirtyFiftHoursBreakInWeek(
          billingPeriodFrom,
          billingPeriodTo,
          new Date(selectedMonth),
          daysSchemas
        );

      if (validationErrors) {
        validationErrorsResult.push(validationErrors);
      }
    }

    if (this.dontDisplayNoElevenHoursOfBreak !== "1") {
      let validationErrors: DaysSchemasValidationErrorModel | null =
        this.noElevenHoursOfBreak(selectedMonth, daysSchemas);

      if (validationErrors) {
        validationErrorsResult.push(validationErrors);
      }
    }

    if (this.dontDisplayMaxSixDaysInRowExceeded !== "1") {
      let validationErrors: DaysSchemasValidationErrorModel | null =
        this.maxSixDaysInRowExceeded(selectedMonth, daysSchemas);

      if (validationErrors) {
        validationErrorsResult.push(validationErrors);
      }
    }

    if (this.dontDisplayWorkingDayLimitExceeded !== "1") {
      let validationErrors: DaysSchemasValidationErrorModel | null =
        this.workingDayLimitExceeded(selectedMonth, daysSchemas);

      if (validationErrors) {
        validationErrorsResult.push(validationErrors);
      }
    }

    if (this.dontDisplayEveryFourthSundayFree !== "1") {
      let validationErrors: DaysSchemasValidationErrorModel | null =
        this.noEveryFourthSundayFree(selectedMonth, daysSchemas);

      if (validationErrors) {
        validationErrorsResult.push(validationErrors);
      }
    }

    return validationErrorsResult;
  }

  noThirtyFiftHoursBreakInWeek(
    billingPeriodFrom: Date,
    billingPeriodTo: Date,
    selectedMonth: Date,
    daysSchemas: Array<DaySchema>
  ): DaysSchemasValidationErrorModel | null {
    let validationError: DaysSchemasValidationErrorModel | null = null;
    let timeFromPreviousDayInMinutes = 0;
    let timeFromNextDayInMinutes = 0;

    let firstDayOfWeek: Date = this.findFirstDayOfWeekOfBillingPeriod(
      billingPeriodFrom,
      billingPeriodTo,
      selectedMonth
    );

    let firstDayOfWeekPosition: number = this.getPosition(
      firstDayOfWeek,
      daysSchemas
    );

    let firstDayOfMonth: Date = getBeginningOfDate(selectedMonth, "Month");
    let firstDayOfMonthPosition: number = this.getPosition(
      firstDayOfMonth,
      daysSchemas
    );
    let numberOfElementsBeforeMonth: number = Math.max(
      0,
      firstDayOfMonthPosition
    );
    let numberOfElementsStickingOutOfMonth: number =
      (7 - (firstDayOfWeekPosition - firstDayOfMonthPosition)) % 7;
    let offset: number = Math.max(
      0,
      numberOfElementsBeforeMonth - numberOfElementsStickingOutOfMonth
    );

    while (true) {
      let weekPosition: Array<DaySchema> = daysSchemas.skip(offset).take(7);
      let dayBefore: DaySchema | null =
        offset > 0 ? daysSchemas.skip(offset - 1).first() : null;
      let dayAfter: DaySchema | null = daysSchemas.skip(offset + 7).first();

      if (dayBefore != null) {
        if (this.isDayOff(dayBefore)) {
          timeFromPreviousDayInMinutes = 24 * 60;
        } else {
          timeFromPreviousDayInMinutes = totalMinutesDiff(
            getEndOfDate(new Date(dayBefore.end), "Day"),
            new Date(dayBefore.end)
          );
        }
      }

      if (dayAfter != null) {
        if (this.isDayOff(weekPosition[weekPosition.length - 1])) {
          if (
            new Date(dayAfter.begin) >
            new Date(weekPosition[weekPosition.length - 1].day)
          ) {
            timeFromNextDayInMinutes = totalMinutesDiff(
              new Date(dayAfter.begin),
              getBeginningOfDate(new Date(dayAfter.begin), "Day")
            );
          }
        } else {
          timeFromNextDayInMinutes = totalMinutesDiff(
            new Date(dayAfter.begin),
            new Date(weekPosition[weekPosition.length - 1].end)
          );
        }
      }

      if (weekPosition.length === 7) {
        if (
          this.noThirtyFiftHoursBreakInWeekCheckChunk(
            timeFromPreviousDayInMinutes,
            timeFromNextDayInMinutes,
            weekPosition
          )
        ) {
          if (validationError == null) {
            validationError = new DaysSchemasValidationErrorModel();
            validationError.error =
              DaysSchemasValidationError.NO_THIRTY_FIFT_HOURS_BREAK_IN_WEEK;
          }

          let rangeFrom: Date = getBeginningOfDate(
            new Date(weekPosition[0].day),
            "Day"
          );
          let rangeTo: Date = getBeginningOfDate(
            new Date(weekPosition[weekPosition.length - 1].day),
            "Day"
          );
          let range: DateTimeRange = this.getRangeInSelectedMonth(
            selectedMonth,
            rangeFrom,
            rangeTo
          );

          validationError.ranges.push({
            dateFrom: getBeginningOfDate(range.dateFrom, "Day"),
            dateTo: getBeginningOfDate(range.dateTo, "Day"),
          });
        }

        offset += weekPosition.length;
      } else {
        break;
      }
    }

    return validationError;
  }

  noThirtyFiftHoursBreakInWeekCheckChunk(
    timeFromPreviousDayInMinutes: number,
    timeFromNextDayInMinutes: number,
    daysSchemas: Array<DaySchema>
  ): boolean {
    let dayOff = false;
    let freeTime = timeFromPreviousDayInMinutes;
    const thirtyFiftHoursInMinutes = 35 * 60;

    for (let i = 0; i < daysSchemas.length; i++) {
      if (this.isDayOff(daysSchemas[i])) {
        dayOff = true;
        freeTime += 24 * 60;

        if (i === daysSchemas.length - 1) {
          freeTime += timeFromNextDayInMinutes;
        }

        if (freeTime >= thirtyFiftHoursInMinutes) {
          return false;
        }
      } else {
        if (dayOff) {
          dayOff = false;
          freeTime += totalMinutesDiff(
            new Date(daysSchemas[i].begin),
            getBeginningOfDate(new Date(daysSchemas[i].begin), "Day")
          );

          if (freeTime >= thirtyFiftHoursInMinutes) {
            return false;
          }
        }

        freeTime =
          totalMinutesDiff(
            getEndOfDate(new Date(daysSchemas[i].end), "Day"),
            new Date(daysSchemas[i].end)
          ) + 1;
      }
    }

    return true;
  }

  noElevenHoursOfBreak(
    selectedMonth: Date,
    daysSchemas: Array<DaySchema>
  ): DaysSchemasValidationErrorModel | null {
    let validationError: DaysSchemasValidationErrorModel | null = null;

    if (daysSchemas != null && daysSchemas.length > 0) {
      for (let i = 0; i < daysSchemas.length; i++) {
        if (
          daysSchemas[i] &&
          !this.isDayOff(daysSchemas[i]) &&
          daysSchemas[i - 1] &&
          !this.isDayOff(daysSchemas[i - 1]) &&
          totalHoursDiff(
            new Date(daysSchemas[i].begin),
            new Date(daysSchemas[i - 1].end)
          ) < 11
        ) {
          let rangeFrom = getBeginningOfDate(
            new Date(daysSchemas[i - 1].day),
            "Day"
          );
          let rangeTo = getBeginningOfDate(new Date(daysSchemas[i].day), "Day");
          let range: DateTimeRange = this.getRangeInSelectedMonth(
            selectedMonth,
            rangeFrom,
            rangeTo
          );

          if (
            this.isDateInMonth(range.dateFrom, selectedMonth) &&
            this.isDateInMonth(range.dateTo, selectedMonth)
          ) {
            if (validationError == null) {
              validationError = new DaysSchemasValidationErrorModel();
              validationError.error =
                DaysSchemasValidationError.NO_ELEVEN_HOURS_OF_BREAK;
            }

            validationError.ranges.push({
              dateFrom: getBeginningOfDate(range.dateFrom, "Day"),
              dateTo: getBeginningOfDate(range.dateTo, "Day"),
            });
          }
        }
      }
    }

    return validationError;
  }

  maxSixDaysInRowExceeded(
    selectedMonth: Date,
    daysSchemas: Array<DaySchema>
  ): DaysSchemasValidationErrorModel | null {
    let validationError: DaysSchemasValidationErrorModel | null = null;
    let counter = 0;

    if (daysSchemas != null && daysSchemas.length > 0) {
      for (let i = 0; i < daysSchemas.length; i++) {
        if (!daysSchemas[i]) {
          break;
        }

        if (this.isDayOff(daysSchemas[i])) {
          counter = 0;
        } else {
          counter++;
        }

        if (counter > 6) {
          let rangeFrom = getBeginningOfDate(
            new Date(daysSchemas[i].day),
            "Day",
            {
              extraDays: -6,
            }
          );
          let rangeTo = getBeginningOfDate(new Date(daysSchemas[i].day), "Day");
          let range: DateTimeRange = this.getRangeInSelectedMonth(
            selectedMonth,
            rangeFrom,
            rangeTo
          );

          if (
            this.isDateInMonth(range.dateFrom, selectedMonth) &&
            this.isDateInMonth(range.dateTo, selectedMonth)
          ) {
            if (validationError == null) {
              validationError = new DaysSchemasValidationErrorModel();
              validationError.error =
                DaysSchemasValidationError.MAX_SIX_DAYS_IN_ROW_EXCEEDED;
            }

            validationError.ranges.push({
              dateFrom: getBeginningOfDate(range.dateFrom, "Day"),
              dateTo: getBeginningOfDate(range.dateTo, "Day"),
            });
          }

          counter = 0;
        }
      }
    }

    return validationError;
  }

  workingDayLimitExceeded(
    selectedMonth: Date,
    daysSchemas: Array<DaySchema>
  ): DaysSchemasValidationErrorModel | null {
    let validationError: DaysSchemasValidationErrorModel | null = null;

    if (daysSchemas != null && daysSchemas.length > 0) {
      for (let i = 0; i < daysSchemas.length; i++) {
        if (
          daysSchemas[i] &&
          daysSchemas[i].norm > 0 &&
          daysSchemas[i - 1] &&
          daysSchemas[i - 1].norm > 0 &&
          totalHoursDiff(
            new Date(daysSchemas[i].begin),
            new Date(daysSchemas[i - 1].begin)
          ) < 24
        ) {
          let rangeFrom = getBeginningOfDate(
            new Date(daysSchemas[i - 1].begin),
            "Day"
          );
          let rangeTo = getBeginningOfDate(new Date(daysSchemas[i].end), "Day");

          let range: DateTimeRange = this.getRangeInSelectedMonth(
            selectedMonth,
            rangeFrom,
            rangeTo
          );

          if (
            this.isDateInMonth(range.dateFrom, selectedMonth) &&
            this.isDateInMonth(range.dateTo, selectedMonth)
          ) {
            if (validationError == null) {
              validationError = new DaysSchemasValidationErrorModel();
              validationError.error =
                DaysSchemasValidationError.WORKING_DAY_LIMIT_EXCEEDED;
            }

            validationError.ranges.push({
              dateFrom: getBeginningOfDate(range.dateFrom, "Day"),
              dateTo: getBeginningOfDate(range.dateTo, "Day"),
            });
          }
        }
      }
    }

    return validationError;
  }

  noEveryFourthSundayFree(
    selectedMonth: Date,
    daysSchemas: Array<DaySchema>
  ): DaysSchemasValidationErrorModel | null {
    let validationError: DaysSchemasValidationErrorModel | null = null;

    if (daysSchemas != null && daysSchemas.length > 0) {
      let sundaysCounter = 0;

      for (let i = 0; i < daysSchemas.length; i++) {
        if (!daysSchemas[i]) {
          break;
        }

        if (
          new Date(daysSchemas[i].day).getDay() === DayOfWeek.SUNDAY &&
          !this.isDayOff(daysSchemas[i])
        ) {
          sundaysCounter++;
        }

        if (
          new Date(daysSchemas[i].day).getDay() === DayOfWeek.SUNDAY &&
          this.isDayOff(daysSchemas[i])
        ) {
          sundaysCounter = 0;
        }

        if (sundaysCounter > 3) {
          if (
            this.isDateInMonth(
              getBeginningOfDate(new Date(daysSchemas[i].day), "Day"),
              selectedMonth
            )
          ) {
            if (validationError == null) {
              validationError = new DaysSchemasValidationErrorModel();
              validationError.error =
                DaysSchemasValidationError.NO_EVERY_FOURTH_SUNDAY_FREE;
            }

            validationError.ranges.push({
              dateFrom: getBeginningOfDate(new Date(daysSchemas[i].day), "Day"),
              dateTo: getBeginningOfDate(new Date(daysSchemas[i].day), "Day"),
            });

            sundaysCounter = 0;
          }
        }
      }
    }

    return validationError;
  }

  findFirstDayOfWeekOfBillingPeriod(
    billingPeriodFrom: Date,
    billingPeriodTo: Date,
    selectedMonth: Date
  ): Date {
    const beginOfMonth = getBeginningOfDate(selectedMonth, "Month");
    const endOfMonth = getEndOfDate(selectedMonth, "Month");

    if (billingPeriodFrom < beginOfMonth) {
      let time: Date = billingPeriodFrom;
      while (time < beginOfMonth) {
        time = addDaysToDate(time, 7);
        if (time >= beginOfMonth && time <= endOfMonth) {
          return time;
        }
      }
    }

    return beginOfMonth;
  }

  getRangeInSelectedMonth(
    selectedMonth: Date,
    rangeFrom: Date,
    rangeTo: Date
  ): DateTimeRange {
    const beginOfMonth = getBeginningOfDate(selectedMonth, "Month");
    const endOfMonth = getEndOfDate(selectedMonth, "Month");

    if (rangeFrom < beginOfMonth && rangeTo >= beginOfMonth) {
      rangeFrom = beginOfMonth;
    }

    if (rangeTo > endOfMonth && rangeFrom <= endOfMonth) {
      rangeTo = endOfMonth;
    }

    return { dateFrom: rangeFrom, dateTo: rangeTo };
  }

  isDateInMonth(date: Date, selectedMonth: Date): boolean {
    const beginOfMonth = new Date(getBeginningOfDate(selectedMonth, "Month"));
    const endOfMonth = new Date(getEndOfDate(selectedMonth, "Month"));

    if (date >= beginOfMonth && date <= endOfMonth) {
      return true;
    }

    return false;
  }

  getPosition(day: Date, daysSchemas: Array<DaySchema>): number {
    let position = 0;

    for (let i = 0; i < daysSchemas.length; i++) {
      const date1 = getBeginningOfDate(new Date(daysSchemas[i].day), "Day");
      const date2 = getBeginningOfDate(new Date(day), "Day");

      if (compareDatesIgnoringTime(date1, date2)) {
        position = i;
        break;
      }
    }

    return position;
  }

  isDayOff(daySchema: DaySchema): boolean {
    let globalCalendar = false;
    let dayOff = false;
    let sundayAndHoliday = false;

    const dayNum = new Date(daySchema.day).getTime();

    if (this._slGlobalCalendarDaysTypes[dayNum]) {
      globalCalendar = true;
      let globalCalendarDayType: GlobalCalendarDayType =
        this._slGlobalCalendarDaysTypes[dayNum];

      if (globalCalendarDayType === GlobalCalendarDayType.DAY_OFF) {
        dayOff = true;
      } else if (
        globalCalendarDayType === GlobalCalendarDayType.SUNDAY_AND_HOLIDAY
      ) {
        sundayAndHoliday = true;
      }
    }

    if (
      daySchema.norm === 0 ||
      (daySchema.workRequired === WorkShiftRequireWork.IN_WORKING_DAYS &&
        daySchema.dayType === WorkShiftDayType.FROM_GLOBAL_CALENDAR &&
        globalCalendar &&
        (dayOff || sundayAndHoliday))
    ) {
      return true;
    }
    return false;
  }
}

class DaySchema {
  constructor(
    public day: Date,
    public begin: Date,
    public end: Date,
    public norm: number,
    public workRequired: WorkShiftRequireWork,
    public dayType: WorkShiftDayType
  ) {}
}

export class DaysSchemasValidationErrorModel {
  public ranges: Array<DateTimeRange>;
  public error: DaysSchemasValidationError;

  constructor() {
    this.ranges = [];
    this.error = 0;
  }
}
