import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { CompanyInPortsType } from "../../../Constants/CompanyInPortsType";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import ButtonStyled from "../Button/button";
import { AutocompleteFormik, TextFieldFormik } from "../FormikInputs";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../PopupHelpers";
import FormAddNewCompany from "./FormAddNewCompany";
import GuestForm from "./guestForm";
import styles from "./styles.module.scss";

interface IProps {
  isPopupOpen: boolean;
  onSubmit: (values: DeliveryAndGarbageFormValues) => void;
  companiesInPortsPathApi: string;
  permissionZonesPathApi: string;
  registrationNumber?: boolean;
  onlyGarbageCompany?: boolean;
}

const PopupDialogContent: React.FC<IProps> = ({
  onSubmit,
  isPopupOpen,
  companiesInPortsPathApi,
  permissionZonesPathApi,
  registrationNumber,
  onlyGarbageCompany,
}) => {
  const { t } = useTranslation();

  const [
    companiesInPorts,
    fetchingStateCompaniesInPorts,
    fetchAgainCompaniesInPorts,
  ] = useFetchAndSetGET<ICompanyInPorts[]>({
    path: companiesInPortsPathApi,
  });

  const [
    permissionZones,
    fetchingStatePermissionZones,
    fetchAgainPermissionZones,
  ] = useFetchAndSetGET<PermissionZone[]>({
    path: permissionZonesPathApi,
  });

  const [showAddCompanyForm, setShowAddCompanyForm] = useState(false);
  const [addedCompanyId, setAddedCompanyId] = useState<false | number>(false);

  useEffect(() => {
    if (addedCompanyId !== false) {
      fetchAgainCompaniesInPorts();
    }
  }, [addedCompanyId, fetchAgainCompaniesInPorts]);

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={fetchingStatePermissionZones.isFetching}
        isError={fetchingStatePermissionZones.isError}
        setIfFetchAgain={() => {
          if (fetchingStatePermissionZones.isError) {
            fetchAgainPermissionZones();
          }
        }}
      >
        {permissionZones && (
          <DialogContentStyled titleWidth={200}>
            <Formik
              initialValues={{
                guestsArray: [
                  {
                    firstName: "",
                    lastName: "",
                    dateOfBirth: null,
                    idNumber: "",
                    cardNumber: "",
                    trainingExpiryDate: null,
                    bh3: false,
                  },
                ],
                company: null,
                permissionZone: onlyGarbageCompany ? permissionZones[0] : null,
                registrationNumber: "",
                comments: "",
              }}
              onSubmit={(values) => {
                onSubmit(values);
              }}
              validationSchema={Yup.object({
                company: Yup.object()
                  .nullable(false)
                  .typeError(t("field_required")),
                permissionZone: Yup.object()
                  .nullable()
                  .required(t("field_required")),
                guestsArray: Yup.array().of(
                  Yup.object().shape({
                    firstName: Yup.string().required(t("field_required")),
                    lastName: Yup.string().required(t("field_required")),
                    idNumber: Yup.string().required(t("field_required")),
                    cardNumber: Yup.string()
                      .required(t("field_required"))
                      .test(
                        "length",
                        t("card_number_have_to_16_chars"),
                        function (val) {
                          const val_length_without_dashes = val?.replace(
                            /-|_/g,
                            ""
                          ).length;
                          return val_length_without_dashes === 16;
                        }
                      ),
                    dateOfBirth: Yup.string()
                      .nullable()
                      .test(
                        "isRequired",
                        t("field_required"),
                        function (value) {
                          if (
                            //wymagane, gdy kurier, czyli typ firmy = false
                            (this as any).from[1].value.company?.type ===
                              CompanyInPortsType.Courier &&
                            !this.parent.trainingExpiryDate
                          ) {
                            if (!value) {
                              return false;
                            }
                          }
                          return true;
                        }
                      )
                      .test(
                        "isaValidBirthOfDate",
                        `${t("date_must_be_earlier_than")} ${t(
                          "today"
                        ).toLocaleLowerCase()}`,
                        function (value) {
                          if (
                            //gdy kurier, czyli typ firmy = false
                            (this as any).from[1].value.company?.type ===
                              CompanyInPortsType.Courier &&
                            !this.parent.trainingExpiryDate
                          ) {
                            if (this.parent.isSelectedDateOfBirth === false) {
                              return true;
                            }
                            let yesterday = new Date();
                            yesterday.setDate(yesterday.getDate() - 1);
                            return Yup.object()
                              .nullable()
                              .shape({
                                date: Yup.date()
                                  .typeError(t("invalid_date"))
                                  .max(
                                    yesterday,
                                    `${t("date")} ${t(
                                      `${t("date_must_be_earlier_than")} ${t(
                                        "today"
                                      ).toLocaleLowerCase()}`
                                    )}`
                                  ),
                              })
                              .isValid({ date: value });
                          }
                          return true;
                        }
                      ),
                    trainingExpiryDate: Yup.date()
                      .nullable()
                      .test(
                        "isaValidBirthOfDate",
                        `${t("must_be_later_than")} ${t(
                          "yesterday".toLowerCase()
                        ).toLocaleLowerCase()}`,
                        function (value) {
                          if (
                            //gdy kurier, czyli typ firmy = false
                            (this as any).from[1].value.company?.type ===
                            CompanyInPortsType.Courier
                          ) {
                            if (
                              this.parent.isSelectedDateOfBirth === false ||
                              value === null
                            ) {
                              return true;
                            }
                            return Yup.object()
                              .nullable()
                              .shape({
                                date: Yup.date()
                                  .typeError(t("invalid_date"))
                                  .min(
                                    new Date(),
                                    `${t("date")} ${t(
                                      `${t("must_be_later_than")} ${t(
                                        "yesterday".toLowerCase()
                                      ).toLocaleLowerCase()}`
                                    )}`
                                  ),
                              })
                              .isValid({ date: value });
                          }
                          return true;
                        }
                      ),
                  })
                ),
              })}
            >
              {({ values, setFieldValue, setFieldError, setFieldTouched }) => (
                <Form noValidate id="submitForm">
                  <TwoColumnsRowsContainer>
                    {
                      <LoadingWrapper
                        isLodadingProgress={
                          fetchingStateCompaniesInPorts.isFetching
                        }
                        isError={fetchingStateCompaniesInPorts.isError}
                        setIfFetchAgain={() => {
                          if (fetchingStateCompaniesInPorts.isError) {
                            fetchAgainCompaniesInPorts();
                          }
                        }}
                      >
                        <Row>
                          <RowTitle>{t("company")}:</RowTitle>
                          <RowInput>
                            <AutocompleteFormik
                              options={companiesInPorts}
                              getOptionLabel={(option: any) => {
                                return option.name;
                              }}
                              width="100%"
                              getOptionSelected={(option, value) => {
                                return option.id === value.id;
                              }}
                              label={t("select_company")}
                              name="company"
                              required={true}
                            />
                          </RowInput>

                          <div
                            className={styles.iconShowCompanyForm}
                            onClick={() => {
                              setShowAddCompanyForm(!showAddCompanyForm);
                            }}
                          >
                            {showAddCompanyForm ? (
                              <FontAwesomeIcon
                                className="faMinusNotifications fa-lg"
                                icon={faMinus}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="faPlusNotificationsAgent fa-lg"
                                icon={faPlus}
                              />
                            )}
                          </div>
                        </Row>
                      </LoadingWrapper>
                    }
                  </TwoColumnsRowsContainer>

                  {showAddCompanyForm && (
                    <FormAddNewCompany
                      companiesInPortsPathApi={companiesInPortsPathApi}
                      setShowAddCompanyForm={setShowAddCompanyForm}
                      setAddedCompanyId={setAddedCompanyId}
                      addedCompanyId={addedCompanyId}
                      companies={companiesInPorts}
                      setFieldValue={setFieldValue}
                      onlyGarbageCompany={onlyGarbageCompany}
                    />
                  )}

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("permission_zone")}:</RowTitle>
                      <RowInput>
                        <AutocompleteFormik
                          options={permissionZones}
                          getOptionLabel={(option: PermissionZone) => {
                            return option.name;
                          }}
                          width="100%"
                          getOptionSelected={(
                            option: PermissionZone,
                            value: PermissionZone
                          ) => {
                            return option.id === value.id;
                          }}
                          label={t("select_permission_zone")}
                          name="permissionZone"
                          required={true}
                        />
                      </RowInput>
                    </Row>

                    {registrationNumber ? (
                      <Row>
                        <RowTitle>{t("plate_number")}:</RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            label={t("plate_number")}
                            name="registrationNumber"
                            type="text"
                            ifToUpperCaseShipsAndPorts={true}
                          />
                        </RowInput>
                      </Row>
                    ) : (
                      <Row>
                        <RowTitle>{t("comments")}:</RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            label={t("comments")}
                            name="comments"
                            type="text"
                            multiline
                            rows={3}
                            ifToUpperCaseShipsAndPorts={true}
                          />
                        </RowInput>
                      </Row>
                    )}
                  </TwoColumnsRowsContainer>

                  {registrationNumber && (
                    <Row>
                      <RowTitle>{t("comments")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("comments")}
                          name="comments"
                          type="text"
                          multiline
                          rows={3}
                          ifToUpperCaseShipsAndPorts={true}
                        />
                      </RowInput>
                    </Row>
                  )}

                  <FieldArray
                    name="guestsArray"
                    render={(arrayHelpers) => (
                      <>
                        {values.guestsArray.map((guestData, index) => {
                          return (
                            <React.Fragment key={index}>
                              <GuestForm
                                guestIndex={index}
                                arrayHelpers={arrayHelpers}
                                numberOfGuests={values.guestsArray.length}
                                setFieldValue={setFieldValue}
                                setFieldError={setFieldError}
                                setFieldTouched={setFieldTouched}
                                isPopupOpen={isPopupOpen}
                                guestsArray={values.guestsArray}
                                hideDateOfBirth={
                                  (values.company as ICompanyInPorts | null)
                                    ?.type ===
                                  CompanyInPortsType.GarbageDisposal
                                }
                                hideTrainingExpirydate={
                                  (values.company as ICompanyInPorts | null)
                                    ?.type ===
                                  CompanyInPortsType.GarbageDisposal
                                }
                              />
                            </React.Fragment>
                          );
                        })}

                        <div className={styles.addAnotherGuest}>
                          <ButtonStyled
                            onClick={() =>
                              arrayHelpers.push({
                                firstName: "",
                                lastName: "",
                                dateOfBirth: null,
                                idNumber: "",
                                cardNumber: "",
                              })
                            }
                            type="button"
                          >
                            {t("add_another_person")}{" "}
                            <FontAwesomeIcon icon={faPlus} />
                          </ButtonStyled>
                        </div>
                      </>
                    )}
                  />
                </Form>
              )}
            </Formik>
          </DialogContentStyled>
        )}
      </LoadingWrapper>
    </>
  );
};

export default PopupDialogContent;
