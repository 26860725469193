import React, { ReactElement, useEffect, useRef, useState } from "react";
import Button from "../../Button/button";
import { useTranslation } from "react-i18next";
import { formatSizeInBytes } from "../../../../HelpersFunctions/stringUtils";
import styles from "./uploadFileFIeld.module.scss";
import IconButton from "@material-ui/core/IconButton/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Controller } from "react-hook-form";

export default function UploadFileFieldReactHookForm({
  register,
  name,
  label,
  showTopLabel,
  control,
  setValue,
  getValues,
  watch,
  ...props
}: any): ReactElement {
  const { t } = useTranslation();
  const fileUploadFieldRef = useRef<HTMLInputElement>();
  const { ref: registerRef } = register(name);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleFileUploadButtonClick = () => {
    fileUploadFieldRef.current?.click();
  };

  const handleFileSelect = (e) => {
    const files = e.target.files;

    let tempSelectedFiles: any = Array.from(files) || [];
    setValue(name, tempSelectedFiles.length > 0 ? tempSelectedFiles : null);
    setSelectedFiles([...tempSelectedFiles]);
  };

  useEffect(() => {
    let value = getValues(name);
    let tempSelectedFiles: any = [];

    if (value) {
      tempSelectedFiles = Array.from(value) || [];
      setSelectedFiles([...tempSelectedFiles]);
    }
  }, [getValues, name]);

  const watchedValue = watch(name);

  useEffect(() => {
    if (watchedValue && Object.entries(watchedValue).length > 0) {
      setSelectedFiles([...watchedValue]);
    }
  }, [watchedValue]);

  return (
    <div>
      {showTopLabel && (
        <div>
          <b>{label}:</b>
        </div>
      )}

      <Button type="button" onClick={handleFileUploadButtonClick}>
        {t("select_file")}
      </Button>

      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
          formState,
        }) => (
          <>
            <input
              ref={(e: any) => {
                registerRef(e);
                fileUploadFieldRef.current = e;
              }}
              type="file"
              hidden={true}
              onChange={(e) => {
                onChange();
                handleFileSelect(e);
              }}
              {...props}
              name={name}
            />
            {error && <p style={{ color: "red" }}>{error.message}</p>}
          </>
        )}
      />

      <ul className={styles.selectedFilesList}>
        {selectedFiles &&
          selectedFiles.map((selectedFile, index) => {
            return (
              <li>
                <span className={styles.selectedFileName}>
                  {selectedFile.name}
                </span>
                <span className={styles.selectedFileSize}>
                  {formatSizeInBytes(selectedFile.size)}
                </span>

                <IconButton
                  onClick={() => {
                    let cancelFile = selectedFiles.findIndex(
                      (cancelFile) => cancelFile.name === selectedFile.name
                    );

                    let newFiles = [...selectedFiles];
                    newFiles.splice(cancelFile, 1);
                    setSelectedFiles(newFiles);
                    setValue(name, newFiles.length > 0 ? newFiles : null);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="sm"
                    color="red"
                    title={t("delete")}
                  />
                </IconButton>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
