import React, { ReactElement } from "react";

interface Props {
  value?: React.ReactNode;
  unit?: React.ReactNode;
  description?: React.ReactNode;
}

export default function ValueUnitDescription({
  value,
  unit,
  description,
}: Props): ReactElement {
  return (
    <div>
      <div className="days">
        {value} {unit}
      </div>
      <div className="daysDescription">{description}</div>
    </div>
  );
}
