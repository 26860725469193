import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useRefreshData from "../../../../hooks/useRefreshData/useRefreshData";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import TableComponentMemo from "../../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";

const ContentAlerts = () => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date>(
    getBeginningOfDate(new Date(), "Day")
  );
  const [endDate, setEndDate] = useState<Date>(
    getEndOfDate(new Date(), "Day", { extraDays: 7 })
  );

  const columns = [
    { name: "time", title: t("time"), type: "date_hours" },
    { name: "description", title: t("description") },
    { name: "alertTypeName", title: t("the_type_of_the_alert") },
    { name: "subjectTypeName", title: t("the_type_of_the_subject") },
  ];

  const [alertsRows, fetchingStateAlerts, fetchAgainAlerts] = useFetchAndSetGET<
    Alert[]
  >({
    path:
      `superior-ac/alerts?dateFrom=` +
      computeDateRangeFetch(startDate) +
      "&dateTo=" +
      computeDateRangeFetch(endDate),
    startFetchOnInitial: false,
  });

  useEffect(() => {
    fetchAgainAlerts();
  }, [startDate, endDate, fetchAgainAlerts]);

  const { refreshingTime, handleChangeRefreshingTime } =
    useRefreshData(fetchAgainAlerts);

  return (
    <>
      <div>
        <TopBanner pathName={t("alerts")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date", "hours", "minutes"]}
            format="dd-MM-yyyy HH:mm"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            darkTheme={true}
            keyboardDateTimePicker={true}
          />
        </div>
        <div>
          <TableComponentMemo
            columns={columns}
            rows={alertsRows}
            layoutSettingName="layoutACSuperiorAlerts"
            fetchingState={{
              fetchAgain: fetchAgainAlerts,
              isError: fetchingStateAlerts.isError,
              isFetching: fetchingStateAlerts.isFetching,
            }}
            refreshingButton={{
              ifShow: true,
              refreshingTime: refreshingTime?.value,
              handleChangeRefreshingTime,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ContentAlerts;
