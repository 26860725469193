import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, Form, Formik } from "formik";
import React from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import ButtonStyled from "../../Button/button";
import { DatePickerFormik, TextFieldFormik } from "../../FormikInputs";
import CheckboxWithLabelFormik from "../../FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import LoadingWrapper from "../../LoadingWrapper/loadingWrapper";
import AccordionStyled from "../../MaterialUi/Accordion/AccordionStyled";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../PopupHelpers";
import GuestForm from "./GuestForm";
import styles from "./styles.module.scss";

const PopupContentBlackListGuest = ({
  rowId,
  onSubmit,
  isOpen,
  pathBlackListGuests,
  pathGuests,
}: {
  rowId: number | undefined;
  onSubmit: (values: IAddBlackListGuest | IUpdateBlackListGuest) => void;
  isOpen: boolean;
  pathBlackListGuests: string;
  pathGuests: string;
}) => {
  const { t } = useTranslation();

  const modifyResponseGuestsCallback = useCallback(
    (responseData) => {
      const modified = responseData.map((row: IGuest) => {
        return {
          ...row,
          dateOfBirth: row.dateOfBirth
            ? toLocaleStringCustom({
                date: new Date(row.dateOfBirth),
                t,
              })
            : null,
        };
      });
      return modified;
    },
    [t]
  );

  const [guests, fetchingStateGuests, fetchAgainGuests] = useFetchAndSetGET<
    IGuest[]
  >({
    path: pathGuests,
    modifyResponseCallback: modifyResponseGuestsCallback,
  });

  const [fetchedRow, fetchingStateFetchedRow, fetchAgainFetchedRow] =
    useFetchAndSetGET<IBlackListGuest>({
      path: `${pathBlackListGuests}/${rowId}`,
      startFetchOnInitial: rowId ? true : false,
    });

  return (
    <div>
      <LoadingWrapper
        isLodadingProgress={fetchingStateFetchedRow.isFetching}
        isError={fetchingStateFetchedRow.isError}
        setIfFetchAgain={() => {
          if (fetchingStateFetchedRow.isError) {
            fetchAgainFetchedRow();
          }
        }}
      >
        {(fetchedRow || rowId === undefined) && (
          <DialogContentStyled>
            <Formik
              initialValues={{
                dateRange: fetchedRow
                  ? fetchedRow.dateFrom && fetchedRow.dateTo
                    ? true
                    : false
                  : false,
                dateFrom: fetchedRow?.dateFrom
                  ? new Date(fetchedRow.dateFrom)
                  : null,
                dateTo: fetchedRow?.dateTo ? new Date(fetchedRow.dateTo) : null,
                comments: fetchedRow?.comments ? fetchedRow.comments : "",
                guestsArray: [
                  {
                    guest: fetchedRow
                      ? {
                          id: fetchedRow.guestId,
                          firstName: fetchedRow.guestFirstName,
                          lastName: fetchedRow.guestLastName,
                        }
                      : null,
                  },
                ],
              }}
              onSubmit={(values) => {
                if (!rowId) {
                  const object = {
                    dateFrom:
                      values.dateRange && values.dateFrom
                        ? values.dateFrom
                        : null,
                    dateTo:
                      values.dateRange && values.dateTo ? values.dateTo : null,
                    comments: values.comments ? values.comments : null,
                    guestsIds: values.guestsArray
                      ? values.guestsArray.map((item) => item.guest?.id)
                      : [],
                  };
                  onSubmit(object as IAddBlackListGuest);
                } else {
                  const object = {
                    dateFrom:
                      values.dateRange && values.dateFrom
                        ? values.dateFrom
                        : null,
                    dateTo:
                      values.dateRange && values.dateTo ? values.dateTo : null,
                    comments: values.comments ? values.comments : null,
                  };

                  onSubmit(object as IUpdateBlackListGuest);
                }
              }}
              validationSchema={Yup.object({
                guestsArray: Yup.array().of(
                  Yup.object().shape({
                    guest: Yup.object()
                      .nullable(false)
                      .typeError(t("field_required")),
                  })
                ),
                dateFrom: Yup.date()
                  .nullable()
                  .when("dateRange", {
                    is: true,
                    then: Yup.date().nullable().required(t("field_required")),
                  }),
                dateTo: Yup.date()
                  .nullable()
                  .when("dateRange", {
                    is: true,
                    then: Yup.date().nullable().required(t("field_required")),
                  })
                  .min(
                    Yup.ref("dateFrom"),
                    t("date_to_must_be_later_than_date_from")
                  ),
              })}
            >
              {({ values, setFieldValue, errors }) => {
                return (
                  <Form noValidate id="submitForm">
                    {/* Info */}
                    <AccordionStyled
                      title={t("information")}
                      defaultExpanded={true}
                      backgroundColor="#FBFBFB"
                      isError={errors.dateFrom || errors.dateTo ? true : false}
                    >
                      <div className={styles.informationContainer}>
                        <TwoColumnsRowsContainer>
                          <Row>
                            <RowTitle>
                              <CheckboxWithLabelFormik
                                name={"dateRange"}
                                ifCircle={true}
                                label={
                                  <span style={{ fontSize: "0.9rem" }}>
                                    {t("date_range")}
                                  </span>
                                }
                              />
                            </RowTitle>
                            {values["dateRange"] && (
                              <RowInput>
                                <DatePickerFormik
                                  customLabel={t("date_from")}
                                  name="dateFrom"
                                  view={["year", "month", "date"]}
                                  format="dd-MM-yyyy"
                                  required={true}
                                />
                              </RowInput>
                            )}
                          </Row>

                          {values["dateRange"] && (
                            <Row>
                              <RowTitle>{}</RowTitle>
                              <RowInput>
                                <DatePickerFormik
                                  customLabel={t("date_to2")}
                                  name="dateTo"
                                  view={["year", "month", "date"]}
                                  format="dd-MM-yyyy"
                                  required={true}
                                />
                              </RowInput>
                            </Row>
                          )}
                        </TwoColumnsRowsContainer>

                        <TwoColumnsRowsContainer>
                          <Row>
                            <RowTitle>{t("comments")}:</RowTitle>
                            <RowInput>
                              <TextFieldFormik
                                label={t("comments")}
                                name="comments"
                                type="text"
                                required={false}
                                multiline
                              />
                            </RowInput>
                          </Row>
                        </TwoColumnsRowsContainer>
                      </div>
                    </AccordionStyled>

                    {/* Guests  */}
                    <AccordionStyled
                      title={t("persons")}
                      defaultExpanded={true}
                      backgroundColor="#FBFBFB"
                      isError={errors.guestsArray ? true : false}
                    >
                      <div className={styles.guestsContainer}>
                        <FieldArray
                          name="guestsArray"
                          render={(arrayHelpers) => (
                            <>
                              {values.guestsArray.map((guestData, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <GuestForm
                                      index={index}
                                      arrayHelpers={arrayHelpers}
                                      numberOfGuests={values.guestsArray.length}
                                      guestsArray={values.guestsArray}
                                      guests={guests}
                                      fetchAgainGuests={fetchAgainGuests}
                                      fetchingStateGuests={fetchingStateGuests}
                                      setFieldValue={setFieldValue}
                                      isEditing={rowId !== undefined}
                                    />
                                  </React.Fragment>
                                );
                              })}

                              {!rowId && (
                                <div className={styles.addAnotherGuest}>
                                  <ButtonStyled
                                    onClick={() =>
                                      arrayHelpers.push({
                                        guest: null,
                                        idNumber: "",
                                        isTrainingNotRequired: false,
                                      })
                                    }
                                    type="button"
                                  >
                                    {t("add_another_person")}{" "}
                                    <FontAwesomeIcon icon={faPlus} />
                                  </ButtonStyled>
                                </div>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </AccordionStyled>
                  </Form>
                );
              }}
            </Formik>
          </DialogContentStyled>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default PopupContentBlackListGuest;
