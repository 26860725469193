import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import LoadingWrapper from "../../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../../../helpersComponents/PopupHelpers";
import PopupGenerateEmployeesReportContent from "./PopupGenerateEmployeesReportContent";
import { makeStyles } from "@material-ui/core/styles";
import { saveAs } from "file-saver";
import { padNumberTo2Digits } from "../../../../../../HelpersFunctions/padNumber";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: "600px",
    width: "440px",
  },
}));

interface IProps {
  popupEmployeesReportParams: IGenerateEmployeesReportPopupParams;
  closePopup: () => void;
}

const PopupGenerateEmployeesReport: React.FC<IProps> = ({
  popupEmployeesReportParams,
  closePopup,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [
    generateEmployeesReportBodyRequest,
    setGenerateEmployeesReportBodyRequest,
  ] = useState<any>(false);

  const [columnsFilter, setColumnsFilter] = useState<string[]>([]);
  const [availableColumns, setAvailableColumns] = useState<
    IEmployeesReportColumn[]
  >([]);
  const [selectedSchemaName, setSelectedSchemaName] = useState<string>("");

  const successCallbackAndClose = useCallback(
    (response) => {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = padNumberTo2Digits(currentDate.getMonth() + 1);
      const day = padNumberTo2Digits(currentDate.getDate());
      const hour = padNumberTo2Digits(currentDate.getHours());
      const minutes = padNumberTo2Digits(currentDate.getMinutes());
      const seconds = padNumberTo2Digits(currentDate.getSeconds());

      saveAs(
        response.resJson,
        `${t(
          "employees_report"
        )}_${year}_${month}_${day}_${hour}_${minutes}_${seconds}.xlsx`
      );
      closePopup();
    },
    [closePopup, t]
  );

  const modifyEmployeesReportColumnsSchemas = useCallback((responseData) => {
    //setAvailableColumns([...responseData]);
    return responseData;
  }, []);

  const [
    employeesReportColumnsSchemas,
    employeesReportColumnsSchemasFetchingState,
    employeesReportColumnsSchemasFetchAgain,
  ] = useFetchAndSetGET({
    path: `superior-time/employees-report-columns-schemas`,
    startFetchOnInitial: false,
    modifyResponseCallback: modifyEmployeesReportColumnsSchemas,
  });

  const modifyEmployeesReportAvailableColumnsResponseCallback = useCallback(
    (responseData) => {
      setAvailableColumns([...responseData]);
      return responseData;
    },
    []
  );

  let [
    employeesReportAvailableColumns,
    employeesReportAvailableColumnsFetchingState,
    employeesReportAvailableColumnsFetchAgain,
  ] = useFetchAndSetGET<IEmployeesReportColumn[]>({
    path: `superior-time/employees-report-available-columns`,
    startFetchOnInitial: false,

    modifyResponseCallback:
      modifyEmployeesReportAvailableColumnsResponseCallback,
  });

  const [
    generateEmployeesReportFetchingState,
    generateEmployeesReportFetchAgain,
  ] = useFetchOtherThanGET({
    path: `superior-time/employees-report`,
    method: "POST",
    ifBlob: true,
    body: generateEmployeesReportBodyRequest,
    setBody: setGenerateEmployeesReportBodyRequest,
    contentType: "application/json",
    successCallback: successCallbackAndClose,
  });

  useEffect(() => {
    if (generateEmployeesReportBodyRequest) {
      generateEmployeesReportFetchAgain();
    }
  }, [generateEmployeesReportBodyRequest, generateEmployeesReportFetchAgain]);

  useEffect(() => {
    employeesReportColumnsSchemasFetchAgain();
  }, [employeesReportColumnsSchemasFetchAgain]);

  useEffect(() => {
    employeesReportAvailableColumnsFetchAgain();
  }, [employeesReportAvailableColumnsFetchAgain]);

  const onSchemaDeletedSuccessfully = useCallback(() => {
    employeesReportColumnsSchemasFetchAgain();
  }, [employeesReportColumnsSchemasFetchAgain]);

  const onSchemaAddedSuccessfully = useCallback(
    (schemaName: string) => {
      employeesReportColumnsSchemasFetchAgain();
      setSelectedSchemaName(schemaName);
    },
    [employeesReportColumnsSchemasFetchAgain]
  );

  const classes = useStyles();

  const onSchemasCurrentValueChanged = (
    value: IEmployeesReportColumnsSchema
  ) => {
    let newColumns: any = [];

    if (
      employeesReportAvailableColumns != null &&
      employeesReportAvailableColumns.length > 0
    ) {
      for (let i = 0; i < value.columns.length; i++) {
        for (let j = 0; j < employeesReportAvailableColumns.length; j++) {
          if (
            value.columns[i] === employeesReportAvailableColumns[j].fieldName
          ) {
            newColumns.push(employeesReportAvailableColumns[j]);
            break;
          }
        }
      }

      for (let i = 0; i < employeesReportAvailableColumns.length; i++) {
        if (
          !value.columns.includes(employeesReportAvailableColumns[i].fieldName)
        ) {
          newColumns.push(employeesReportAvailableColumns[i]);
        }
      }
    }

    setAvailableColumns(newColumns);
  };

  return (
    <>
      <DialogStyled
        open={popupEmployeesReportParams.isOpen}
        onClose={closePopup}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitleStyled title={t("employees_report")} />

        <LoadingWrapper
          isLodadingProgress={
            employeesReportColumnsSchemasFetchingState.isFetching ||
            employeesReportAvailableColumnsFetchingState.isFetching
          }
          isError={
            employeesReportColumnsSchemasFetchingState.isError ||
            employeesReportAvailableColumnsFetchingState.isError
          }
          setIfFetchAgain={() => {
            if (employeesReportColumnsSchemasFetchingState.isError) {
              employeesReportColumnsSchemasFetchAgain();
            }

            if (employeesReportAvailableColumnsFetchingState.isError) {
              employeesReportAvailableColumnsFetchAgain();
            }
          }}
        >
          <PopupGenerateEmployeesReportContent
            onSchemasCurrentValueChanged={onSchemasCurrentValueChanged}
            employeesReportColumnsSchemas={employeesReportColumnsSchemas}
            employeesReportAvailableColumns={availableColumns}
            columnsFilter={columnsFilter}
            setColumnsFilter={setColumnsFilter}
            onSchemaDeletedSuccessfully={onSchemaDeletedSuccessfully}
            selectedSchemaName={selectedSchemaName}
            setSelectedSchemaName={setSelectedSchemaName}
            onSchemaAddedSuccessfully={onSchemaAddedSuccessfully}
            setErrorMessage={setErrorMessage}
          />
        </LoadingWrapper>

        <DialogActionsStyled
          onConfirmText={t("generate_report")}
          onConfirm={() => {
            if (columnsFilter.length === 0) {
              setErrorMessage(t("no_columns_selected"));
              return;
            }

            let generateEmployeesReportQuery: IGenerateEmployeesReportQuery = {
              timeWorkersIds: popupEmployeesReportParams.workersIds,
              columns: columnsFilter,
            };

            setGenerateEmployeesReportBodyRequest(
              JSON.stringify(generateEmployeesReportQuery)
            );
          }}
          onCancel={() => {
            setErrorMessage("");
            closePopup();
          }}
          isLoading={generateEmployeesReportFetchingState.isFetching}
          errorString={errorMessage}
        />
      </DialogStyled>
    </>
  );
};

export default PopupGenerateEmployeesReport;
