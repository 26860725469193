import { useTranslation } from "react-i18next";
import { DelegationPurpose } from "../Constants/delegationPurpose";

const DelegationPurposeName = (delegationPurposeId: DelegationPurpose) => {
  const { t } = useTranslation();

  switch (delegationPurposeId) {
    case DelegationPurpose.MARKETING:
      return t("delegation_purpose_marketing");
    case DelegationPurpose.TRAINING:
      return t("delegation_purpose_training");
    case DelegationPurpose.COMPLAINTS:
      return t("delegation_purpose_complaints");
    case DelegationPurpose.MANAGEMENT:
      return t("delegation_purpose_management");
    case DelegationPurpose.GROUP_SERVICE:
      return t("delegation_purpose_group_service");
    case DelegationPurpose.OTHER:
      return t("delegation_purpose_other");
    default:
      return "";
  }
};

export default DelegationPurposeName;
