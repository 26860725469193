import { createTheme, Tab, Tabs, ThemeProvider } from "@material-ui/core";
import { useState } from "react";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import { colorNameDictionary } from "../../../ProfilesRouting/profilesDictionaries";

function TabPanel(props: {
  children?: React.ReactNode;
  index: number;
  value: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className="xd"
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

interface ITabs {
  label: string;
  value: number;
  content: React.ReactNode;
}

interface IProps {
  tabs: ITabs[];
  underlineColor?: string;
}

const StyledTabs: React.FC<IProps> = ({ tabs, underlineColor }) => {
  const [valueTab, setValueTab] = useState<number>(0);
  const handleChangeValueTab = (
    event: React.ChangeEvent<any>,
    newValue: number
  ) => {
    setValueTab(newValue);
  };
  const authUserType = useAppSelector(selectAuthUser).currentProfile?.type;
  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );

  const materialTheme = createTheme({
    overrides: {
      MuiTabs: {
        root: {
          backgroundColor:
            authUser !== undefined && Object.keys(authUser).length !== 0
              ? `${
                  colorNameDictionary[authUser.currentProfile?.type]
                }!important`
              : "#1ab394!important",
          color: "white",
        },
      },
    },
  });

  return (
    <div>
      <ThemeProvider theme={materialTheme}>
        <Tabs
          value={valueTab}
          onChange={handleChangeValueTab}
          TabIndicatorProps={{
            style: {
              backgroundColor: underlineColor
                ? underlineColor
                : `${colorNameDictionary[authUserType]}`,
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab label={tab.label} value={tab.value} key={index} />
          ))}
        </Tabs>
      </ThemeProvider>

      {tabs.map((tab, index) => (
        <TabPanel value={valueTab} index={tab.value} key={index}>
          {tab.content}
        </TabPanel>
      ))}
    </div>
  );
};

export default StyledTabs;
