import { useAppSelector } from "../../store/hooks";
import { useState, useEffect, useCallback } from "react";
import { selectAuthUser } from "../../reducers/session";
import { ProfileType } from "../../enums/profileType";
import useFetchOtherThanGET from "../fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { profilesRoutesDictionary } from "../../components/ProfilesRouting/profilesDictionaries";
import useUpdateSettings from "../useUpdateSettings/useUpdateSettings";

const useUpdateLastSelectedWorkers = (selectedWorkersIds) => {
  const authUser = useAppSelector(selectAuthUser);
  const [lastSelectedWorkersRequestBody, setLastSelectedWorkersRequestBody] =
    useState<any>(false);

  let ifWorkerTimeId = "";
  if (authUser.currentProfile?.type === ProfileType.WORKER_TIME) {
    ifWorkerTimeId = `/${authUser.currentProfile.subjectId}`;
  }

  const [ifUpdateSetting, setIfUpdateSetting] = useState<boolean>(false);

  const successCallback = useCallback(() => {
    setIfUpdateSetting(true);
  }, []);

  const [, fetchAgainUpdateLastSelectedWorkers] = useFetchOtherThanGET({
    path: `${
      profilesRoutesDictionary[authUser.currentProfile?.type]
    }${ifWorkerTimeId}/preferences`,
    method: "POST",
    body: lastSelectedWorkersRequestBody,
    setBody: setLastSelectedWorkersRequestBody,
    contentType: "application/json",
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
    successCallback: successCallback,
  });

  const updateFunc = () => {
    let body = {
      name: `lastSelectedWorkers_${authUser.currentProfile?.type}_${authUser.currentProfile?.subjectId}`,
      value: selectedWorkersIds ? selectedWorkersIds.join(",") : "",
    };

    setLastSelectedWorkersRequestBody(JSON.stringify(body));
  };

  useEffect(() => {
    if (lastSelectedWorkersRequestBody) {
      fetchAgainUpdateLastSelectedWorkers();
    }
  }, [lastSelectedWorkersRequestBody, fetchAgainUpdateLastSelectedWorkers]);

  const ifSettingsUpdated = useUpdateSettings(ifUpdateSetting);

  useEffect(() => {
    setIfUpdateSetting(false);
  }, [ifSettingsUpdated]);

  return [updateFunc];
};

export default useUpdateLastSelectedWorkers;
