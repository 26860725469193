import CompaniesInPortsContent from "../../helpersComponents/CompaniesInPorts/CompaniesInPortsContent";

const CompaniesInPortsPFSO = () => {
  return (
    <CompaniesInPortsContent
      pathApiCompaniesInPorts="pfso/companies-in-ports"
      layoutSettingName="layoutOperationalSupportComapniesRecords"
    />
  );
};

export default CompaniesInPortsPFSO;
