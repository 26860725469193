import FireReportContent from "../../helpersComponents/FireReport/fireReport";

function FireReportSuperiorAc() {
  return (
    <div>
      <FireReportContent
        fireReportPath="superior-ac/fire-report"
        layoutSettingName="layoutSettingNameSuperiorAcFireReport"
        fireZonesPath="superior-ac/fire-zones"
        pathPreferences="superior-ac/preferences"
        targetCompaniesPath="superior-ac/guests-visits-target-companies"
      />
    </div>
  );
}

export default FireReportSuperiorAc;
