import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import PopupAddNewGuest from "./PopupAddNewGuest";

const ContentGuests = () => {
  const { t } = useTranslation();

  const columns = [
    { name: "firstName", title: t("first_name") },
    { name: "lastName", title: t("last_name") },
    { name: "dateOfBirth", title: t("date_of_birth"), type: "date" },
    { name: "email", title: t("email") },
    {
      name: "trainingExpiryDate",
      title: t("training_expiry_date"),
      type: "date",
    },
    { name: "createdAt", title: t("data_created"), type: "date" },
  ];

  const modifyGuestsResponseCallback = useCallback((responseData) => {
    const modified = responseData.map((row) => {
      return {
        ...row,
        email: row.email ? row.email : "-",
      };
    });
    return modified;
  }, []);

  const [guestsRows, fetchingGuestsList, fetchAgainGuests] = useFetchAndSetGET({
    path: `pfso/guests`,
    modifyResponseCallback: modifyGuestsResponseCallback,
  });

  const [guestIdToRemove, setGuestIdToRemove] = useState<false | number>(false);

  const [fetchingState, fetchAgain] = useFetchOtherThanGET({
    path: `pfso/guests/${guestIdToRemove}`,
    method: "DELETE",
    body: guestIdToRemove,
    setBody: setGuestIdToRemove,
    successCallback: fetchAgainGuests,
  });

  useEffect(() => {
    if (guestIdToRemove !== false) {
      fetchAgain();
    }
  }, [guestIdToRemove, fetchAgain]);

  return (
    <>
      <div>
        <TopBanner pathName={t("persons")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <TableComponentMemo
            columns={columns}
            rows={guestsRows}
            layoutSettingName="layoutPfsoGuestsRecords"
            actionsColumnUpdated={{
              popup: PopupAddNewGuest,
              successCallbackPopup: fetchAgainGuests,
              addButton: {
                ifShow: true,
              },
              editButton: {
                ifShow: true,
                name: t("edit"),
              },
              removeButton: {
                ifShow: true,
                onClick: (row) => {
                  setGuestIdToRemove(row.id);
                },
              },
            }}
            fetchingState={{
              fetchAgain: fetchAgainGuests,
              isError: fetchingGuestsList.isError,
              isFetching:
                fetchingGuestsList.isFetching || fetchingState.isFetching,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ContentGuests;
