import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";
import {
  faCalendar,
  faHistory,
  faHome,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import HomePageLockersManagement from "../../lockersManagement/HomePage";
import LockersEventstHistory from "../../lockersManagement/LockersEventsHistory";
import LockersAssignmentHistory from "../../lockersManagement/LockersAssignmentHistory";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const navLinksArrayLockersManagement: navLinksArrayTimeWorkerInterface = {
    profileId: 16,

    routesArrayWithComponents: [
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/lockers-management/profile",
        component: () => <Profile />,
      },
      {
        icon: faHome,
        name: t("lockers"),
        ifHidden: false,
        singleLink: true,
        path: "/lockers-management/home",
        component: (props) => <HomePageLockersManagement {...props} />,
      },
      {
        icon: faCalendar,
        name: t("lockers_events"),
        ifHidden: false,
        singleLink: true,
        path: "/lockers-management/lockers-events-history",
        component: (props) => <LockersEventstHistory {...props} />,
      },
      {
        icon: faHistory,
        name: t("lockers_assignment_history"),
        ifHidden: false,
        singleLink: true,
        path: "/lockers-management/lockers-assignment-history",
        component: (props) => <LockersAssignmentHistory {...props} />,
      },
    ],
  };

  return navLinksArrayLockersManagement;
};
export default useNavLinksArray;
