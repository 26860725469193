import { StatusInPortsType } from "../../../Constants/statusInPortsType";
import { ProfileType } from "../../../enums/profileType";
import ContentNotificationsInPorts from "../../helpersComponents/NotificationsInPorts/ContentNotificationsInPorts";

const NotificationInPortsPFSO = () => {
  return (
    <ContentNotificationsInPorts
      pathApiNotifications="pfso/notification-guests-in-ports"
      pathApiChangeConfirmationStatus="pfso/notification-change-status"
      pathApiPermissionZones="pfso/permission-zones?isActive=true"
      pathApiAttachments="pfso/attachments-in-ports"
      pathApiChangeWorkingConditions="pfso/notification-change-working-conditions"
      statusConfirmationId={StatusInPortsType.APPROVED}
      ifHideConfirmCondition={(row, user, subjectId) => {
        return (
          row.notification.confirmationStatus === StatusInPortsType.APPROVED ||
          (row.notification.pfsoConfirmationId != null &&
            row.notification.pfsoConfirmationId !== subjectId) ||
          (row.notification.shiftDirectorConfirmationId != null &&
            row.notification.shiftDirectorConfirmationId !== subjectId) ||
          row.guests.find((guest) => guest.isLetIn) != null
        );
      }}
      ifHideRejectCondition={(row, user, subjectId) => {
        return (
          row.notification.confirmationStatus === StatusInPortsType.REJECTED ||
          (row.notification.pfsoConfirmationId != null &&
            row.notification.pfsoConfirmationId !== subjectId) ||
          (row.notification.shiftDirectorConfirmationId != null &&
            row.notification.shiftDirectorConfirmationId !== subjectId) ||
          row.guests.find((guest) => guest.isLetIn) != null
        );
      }}
      layoutSettingName="layoutPFSONotificationsRecords"
      switchConfirmationStatuses={true}
      profileType={ProfileType.PFSO}
      notificationPathName="pfso/home"
    />
  );
};

export default NotificationInPortsPFSO;
