import { ProfileType } from "../../../enums/profileType";
import SupportContent from "../../helpersComponents/Support/SupportContent";

const SupportOperationalSupport = () => {
  return (
    <SupportContent
      pathApiListsOfEmployees="operational-support/lists-of-employees"
      pathApiImportFromFile="operational-support/lists-of-employees/import"
      pathApiListOfEmployeesEmploye="operational-support/lists-of-employees-employee"
      layoutSettingName="layoutOperationalSupportListsOfEmployeesRecords"
      profileType={ProfileType.OPERATIONAL_SUPPORT}
    />
  );
};

export default SupportOperationalSupport;
