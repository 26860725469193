import { Form, Formik } from "formik";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../../../../helpersComponents/PopupHelpers";
import EmployeesReportAvailableColumnsList from "./EmployeesReportAvailableColumnsList";
import EmployeesReportColumnsSchemas from "./EmployeesReportColumnsSchemas";
import styles from "./popupGenerateEmployeesReport.module.scss";
import EmployeesReportAddColumnsSchema from "./EmployeesReportAddColumnsSchema";

interface IProps {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  employeesReportColumnsSchemas: IEmployeesReportColumnsSchema[];
  employeesReportAvailableColumns: IEmployeesReportColumn[];
  columnsFilter: string[];
  setColumnsFilter: Dispatch<SetStateAction<string[]>>;
  onSchemasCurrentValueChanged: (value: IEmployeesReportColumnsSchema) => void;
  onSchemaAddedSuccessfully?: (schemaName: string) => void;
  selectedSchemaName: string;
  setSelectedSchemaName: Dispatch<SetStateAction<string>>;
  onSchemasSharedSuccessfully?: () => void;
  onSchemaDeletedSuccessfully?: () => void;
}

const PopupGenerateEmployeesReportContent: React.FC<IProps> = ({
  setErrorMessage,
  employeesReportAvailableColumns,
  employeesReportColumnsSchemas,
  columnsFilter,
  setColumnsFilter,
  onSchemaAddedSuccessfully,
  selectedSchemaName,
  setSelectedSchemaName,
  onSchemasSharedSuccessfully,
  onSchemaDeletedSuccessfully,
  onSchemasCurrentValueChanged,
}) => {
  const { t } = useTranslation();

  const onChangeValue = (value: IEmployeesReportColumnsSchema) => {
    setColumnsFilter(value ? value.columns : []);
    if (onSchemasCurrentValueChanged) {
      onSchemasCurrentValueChanged(value);
    }
  };

  return (
    <DialogContentStyled>
      <Formik
        initialValues={{}}
        onSubmit={() => {}}
        validationSchema={Yup.object({})}
      >
        <Form noValidate id="submitForm">
          <Row>
            <RowTitle>
              <div className={styles.labelMargin}>{t("select_schema")}:</div>
            </RowTitle>
            <RowInput>
              <EmployeesReportColumnsSchemas
                columnsSchemas={employeesReportColumnsSchemas}
                selectedSchemaName={selectedSchemaName}
                setSelectedSchemaName={setSelectedSchemaName}
                onSchemasSharedSuccessfully={onSchemasSharedSuccessfully}
                onSchemaDeletedSuccessfully={onSchemaDeletedSuccessfully}
                onChangeValue={onChangeValue}
              />
            </RowInput>
          </Row>

          <Row style={{ marginTop: "20px" }}>
            <RowTitle>
              <div>{t("columns")}:</div>
            </RowTitle>
            <RowInput>
              <EmployeesReportAvailableColumnsList
                employeesReportAvailableColumns={
                  employeesReportAvailableColumns
                }
                columnsFilter={columnsFilter}
                setColumnsFilter={setColumnsFilter}
              />
            </RowInput>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <RowTitle>
              <div className={styles.labelMargin}>{t("schema_name")}:</div>
            </RowTitle>
            <RowInput>
              <EmployeesReportAddColumnsSchema
                columns={columnsFilter}
                successCallback={onSchemaAddedSuccessfully}
              />
            </RowInput>
          </Row>
        </Form>
      </Formik>
    </DialogContentStyled>
  );
};

export default PopupGenerateEmployeesReportContent;
