import CompaniesInPortsContent from "../../helpersComponents/CompaniesInPorts/CompaniesInPortsContent";

const CompaniesInPortsAgent = () => {
  return (
    <CompaniesInPortsContent
      pathApiCompaniesInPorts="agent/companies-in-ports"
      layoutSettingName="layoutAgentCompaniesInPortsRecords"
    />
  );
};

export default CompaniesInPortsAgent;
