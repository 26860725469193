import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  ArgumentAxis,
  ValueAxis,
  Title,
  Label,
  Tooltip,
} from "devextreme-react/chart";
import { OvertimesSummaryChartDisplayMode } from "../../../../enums/OvertimesSummaryChartDisplayMode";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { dateToWeekNumber } from "../../../../HelpersFunctions/dateAndTime/dateToWeekNumber";
import { getFirstDayOfWeek } from "../../../../HelpersFunctions/dateAndTime/firstDayOfWeek";

interface IProps {
  data: IOvertimesSummaryItem[];
  displayMode: OvertimesSummaryChartDisplayMode;
}

const OvertimesSummaryChart: React.FC<IProps> = ({ data, displayMode }) => {
  const { t } = useTranslation();

  function getMonthKey(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    return `${year}-${month}`;
  }

  function getDataForMode(
    data: IOvertimesSummaryItem[],
    mode: OvertimesSummaryChartDisplayMode
  ): any {
    let result: any = [];

    switch (mode) {
      case OvertimesSummaryChartDisplayMode.SUMMARY:
        {
          let summaryData = data.reduce(
            (acc, currentValue) => {
              acc.overtime50 += currentValue.overtime50;
              acc.overtime100 += currentValue.overtime100;
              acc.overtime50PercentToTake +=
                currentValue.overtime50PercentToTake;
              acc.overtime100PercentToTake +=
                currentValue.overtime100PercentToTake;
              acc.overtime50PercentToPay += currentValue.overtime50PercentToPay;
              acc.overtime100PercentToPay +=
                currentValue.overtime100PercentToPay;
              return acc;
            },
            {
              overtime50: 0,
              overtime100: 0,
              overtime50PercentToTake: 0,
              overtime100PercentToTake: 0,
              overtime50PercentToPay: 0,
              overtime100PercentToPay: 0,
            }
          );

          result.push({ category: "N50", overtime50: summaryData.overtime50 });
          result.push({
            category: "N100",
            overtime100: summaryData.overtime100,
          });

          result.push({
            category: "NDO50",
            overtime50PercentToTake: summaryData.overtime50PercentToTake,
          });
          result.push({
            category: "NDO100",
            overtime100PercentToTake: summaryData.overtime100PercentToTake,
          });

          result.push({
            category: "NDW50",
            overtime50PercentToPay: summaryData.overtime50PercentToPay,
          });
          result.push({
            category: "NDW100",
            overtime100PercentToPay: summaryData.overtime100PercentToPay,
          });
        }
        break;

      case OvertimesSummaryChartDisplayMode.SUMMARY_PER_WORKER:
        {
          const workerData = data.reduce((accumulator, currentValue) => {
            const workerId = currentValue.timeWorkerId;

            if (!accumulator[workerId]) {
              accumulator[workerId] = {
                firstName: currentValue.firstName,
                lastName: currentValue.lastName,
                evidenceNumber: currentValue.evidenceNumber,
                overtime50: 0,
                overtime100: 0,
                overtime50PercentToTake: 0,
                overtime100PercentToTake: 0,
                overtime50PercentToPay: 0,
                overtime100PercentToPay: 0,
              };
            }

            accumulator[workerId].overtime50 += currentValue.overtime50;
            accumulator[workerId].overtime100 += currentValue.overtime100;
            accumulator[workerId].overtime50PercentToTake +=
              currentValue.overtime50PercentToTake;
            accumulator[workerId].overtime100PercentToTake +=
              currentValue.overtime100PercentToTake;
            accumulator[workerId].overtime50PercentToPay +=
              currentValue.overtime50PercentToPay;
            accumulator[workerId].overtime100PercentToPay +=
              currentValue.overtime100PercentToPay;

            return accumulator;
          }, {});

          Object.keys(workerData).forEach((key) => {
            result.push({
              category: `${workerData[key].firstName} ${workerData[key].lastName}`,
              overtime50: workerData[key].overtime50,
              overtime100: workerData[key].overtime100,
              overtime50PercentToTake: workerData[key].overtime50PercentToTake,
              overtime100PercentToTake:
                workerData[key].overtime100PercentToTake,
              overtime50PercentToPay: workerData[key].overtime50PercentToPay,
              overtime100PercentToPay: workerData[key].overtime100PercentToPay,
            });
          });
        }
        break;

      case OvertimesSummaryChartDisplayMode.DAILY:
        {
          let dailyData: any = {};

          data.forEach((entry) => {
            const day = moment(new Date(entry.day)).format("YYYY-MM-DD");

            if (!dailyData[day]) {
              dailyData[day] = {
                overtime50: 0,
                overtime100: 0,
                overtime50PercentToTake: 0,
                overtime100PercentToTake: 0,
                overtime50PercentToPay: 0,
                overtime100PercentToPay: 0,
              };
            }

            dailyData[day].overtime50 += entry.overtime50;
            dailyData[day].overtime100 += entry.overtime100;
            dailyData[day].overtime50PercentToTake +=
              entry.overtime50PercentToTake;
            dailyData[day].overtime100PercentToTake +=
              entry.overtime100PercentToTake;
            dailyData[day].overtime50PercentToPay +=
              entry.overtime50PercentToPay;
            dailyData[day].overtime100PercentToPay +=
              entry.overtime100PercentToPay;
          });

          Object.keys(dailyData).forEach((key) => {
            result.push({
              category: key,
              overtime50: dailyData[key].overtime50,
              overtime100: dailyData[key].overtime100,
              overtime50PercentToTake: dailyData[key].overtime50PercentToTake,
              overtime100PercentToTake: dailyData[key].overtime100PercentToTake,
              overtime50PercentToPay: dailyData[key].overtime50PercentToPay,
              overtime100PercentToPay: dailyData[key].overtime100PercentToPay,
            });
          });
        }
        break;

      case OvertimesSummaryChartDisplayMode.WEEKLY:
        {
          const weeklyData = data.reduce((accumulator, currentValue) => {
            const weekNumber = dateToWeekNumber(currentValue.day);
            const firstDayOfWeek = getFirstDayOfWeek(currentValue.day);

            if (!accumulator[weekNumber]) {
              accumulator[weekNumber] = {
                weekNumber: weekNumber,
                firstDayOfWeek: moment(new Date(firstDayOfWeek)).format(
                  "YYYY-MM-DD"
                ),
                overtime50: currentValue.overtime50,
                overtime100: currentValue.overtime100,
                overtime50PercentToTake: currentValue.overtime50PercentToTake,
                overtime100PercentToTake: currentValue.overtime100PercentToTake,
                overtime50PercentToPay: currentValue.overtime50PercentToPay,
                overtime100PercentToPay: currentValue.overtime100PercentToPay,
              };
            } else {
              accumulator[weekNumber].overtime50 += currentValue.overtime50;
              accumulator[weekNumber].overtime100 += currentValue.overtime100;
              accumulator[weekNumber].overtime50PercentToTake +=
                currentValue.overtime50PercentToTake;
              accumulator[weekNumber].overtime100PercentToTake +=
                currentValue.overtime100PercentToTake;
              accumulator[weekNumber].overtime50PercentToPay +=
                currentValue.overtime50PercentToPay;
              accumulator[weekNumber].overtime100PercentToPay +=
                currentValue.overtime100PercentToPay;
            }

            return accumulator;
          }, {});

          Object.keys(weeklyData).forEach((key) => {
            result.push({
              category: weeklyData[key].firstDayOfWeek,
              overtime50: weeklyData[key].overtime50,
              overtime100: weeklyData[key].overtime100,
              overtime50PercentToTake: weeklyData[key].overtime50PercentToTake,
              overtime100PercentToTake:
                weeklyData[key].overtime100PercentToTake,
              overtime50PercentToPay: weeklyData[key].overtime50PercentToPay,
              overtime100PercentToPay: weeklyData[key].overtime100PercentToPay,
            });
          });

          result.sort((a, b) => {
            const dateA: any = new Date(a.category);
            const dateB: any = new Date(b.category);

            return dateA - dateB;
          });
        }
        break;

      case OvertimesSummaryChartDisplayMode.MONTHLY:
        {
          const monthlyData = data.reduce((accumulator, currentValue) => {
            const monthNumber = getMonthKey(currentValue.day);
            const firstDayOfMonth = moment(currentValue.day)
              .startOf("month")
              .toDate();
            const formattedFirstDayOfMonth =
              moment(firstDayOfMonth).format("YYYY-MM-DD");

            if (!accumulator[monthNumber]) {
              accumulator[monthNumber] = {
                monthNumber: monthNumber,
                firstDayOfMonth: formattedFirstDayOfMonth,
                overtime50: currentValue.overtime50,
                overtime100: currentValue.overtime100,
                overtime50PercentToTake: currentValue.overtime50PercentToTake,
                overtime100PercentToTake: currentValue.overtime100PercentToTake,
                overtime50PercentToPay: currentValue.overtime50PercentToPay,
                overtime100PercentToPay: currentValue.overtime100PercentToPay,
              };
            } else {
              accumulator[monthNumber].overtime50 += currentValue.overtime50;
              accumulator[monthNumber].overtime100 += currentValue.overtime100;
              accumulator[monthNumber].overtime50PercentToTake +=
                currentValue.overtime50PercentToTake;
              accumulator[monthNumber].overtime100PercentToTake +=
                currentValue.overtime100PercentToTake;
              accumulator[monthNumber].overtime50PercentToPay +=
                currentValue.overtime50PercentToPay;
              accumulator[monthNumber].overtime100PercentToPay +=
                currentValue.overtime100PercentToPay;
            }

            return accumulator;
          }, {});

          Object.keys(monthlyData).forEach((key) => {
            result.push({
              category: monthlyData[key].firstDayOfMonth,
              overtime50: monthlyData[key].overtime50,
              overtime100: monthlyData[key].overtime100,
              overtime50PercentToTake: monthlyData[key].overtime50PercentToTake,
              overtime100PercentToTake:
                monthlyData[key].overtime100PercentToTake,
              overtime50PercentToPay: monthlyData[key].overtime50PercentToPay,
              overtime100PercentToPay: monthlyData[key].overtime100PercentToPay,
            });
          });

          result.sort((a, b) => {
            const dateA: any = new Date(a.category);
            const dateB: any = new Date(b.category);

            return dateA - dateB;
          });
        }
        break;

      default:
        return [];
    }

    return result;
  }

  const chartData = getDataForMode(data, displayMode);

  const customizeLabelValueAxis = (arg) => {
    const hours = Math.floor(arg.value / 60);
    const minutes = arg.value % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  const customizeTooltip = (info) => {
    const hours = Math.floor(info.value / 60);
    const minutes = info.value % 60;

    const tempTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return {
      html: `<div style="font-size: 18px;">${info.seriesName}</div><div style="margin-top: 5px; font-size: 18px;">${tempTime}</div>`,
    };
  };

  return (
    <Chart dataSource={chartData} title={t("overtimes_summary")}>
      <CommonSeriesSettings
        argumentField="category"
        type="bar"
        ignoreEmptyPoints={true}
        barWidth={40}
      />

      <Series valueField="overtime50" name={t("overtime_50")} type="bar" />
      <Series valueField="overtime100" name={t("overtime_100")} type="bar" />

      <Series
        valueField="overtime50PercentToTake"
        name={t("overtime_50_percent_to_take")}
        type="bar"
      />
      <Series
        valueField="overtime100PercentToTake"
        name={t("overtime_100_percent_to_take")}
        type="bar"
      />

      <Series
        valueField="overtime50PercentToPay"
        name={t("overtime_50_percent_to_pay")}
        type="bar"
      />

      <Series
        valueField="overtime100PercentToPay"
        name={t("overtime_100_percent_to_pay")}
        type="bar"
      />

      <ArgumentAxis>
        <Title text={t("overtime")} />
      </ArgumentAxis>

      <ValueAxis>
        <Title text={t("time")} />
        <Label customizeText={customizeLabelValueAxis} />
      </ValueAxis>

      <Legend />
      <Tooltip
        enabled={true}
        //shared={true}
        customizeTooltip={customizeTooltip}
      />
    </Chart>
  );
};

export default OvertimesSummaryChart;
