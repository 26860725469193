import styles from "./popupContentSetTime.module.scss";
import { useAppSelector } from "../../../../../../store/hooks";
import { selectAdvancedOptions } from "../../../../../../reducers/advancedOptions";
import React, { useState } from "react";
import TextField from "../../../../../helpersComponents/MaterialUi/TextField/textField";
import { useTranslation } from "react-i18next";

const PopupContentSetTime = ({
  onChangeTime,
}: {
  onChangeTime: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const advancedOptions = useAppSelector(selectAdvancedOptions);

  const [timeFrom, setTimeFrom] = useState<string>("08:00");
  const [timeTo, setTimeTo] = useState<string>("16:00");

  const handleAnchorClick = (e) => {
    e.preventDefault();

    let timeParts = e.target.innerText.split("-");
    if (timeParts && timeParts.length === 2) {
      setTimeFrom(timeParts[0]);
      setTimeTo(timeParts[1]);
      onChangeTime(`${timeParts[0]}-${timeParts[1]}`);
    }
  };

  return (
    <>
      <ul className={styles.proposedHoursList}>
        <li>
          <a href="/#" onClick={handleAnchorClick}>
            {advancedOptions.WartoscCzasow1
              ? advancedOptions.WartoscCzasow1.value
              : "06:00-14:00"}
          </a>
        </li>
        <li>
          <a href="/#" onClick={handleAnchorClick}>
            {advancedOptions.WartoscCzasow2
              ? advancedOptions.WartoscCzasow2.value
              : "07:00-15:00"}
          </a>
        </li>
        <li>
          <a href="/#" onClick={handleAnchorClick}>
            {advancedOptions.WartoscCzasow3
              ? advancedOptions.WartoscCzasow3.value
              : "08:00-16:00"}
          </a>
        </li>
        <li>
          <a href="/#" onClick={handleAnchorClick}>
            {advancedOptions.WartoscCzasow4
              ? advancedOptions.WartoscCzasow4.value
              : "14:00-22:00"}
          </a>
        </li>
        <li>
          <a href="/#" onClick={handleAnchorClick}>
            {advancedOptions.WartoscCzasow5
              ? advancedOptions.WartoscCzasow5.value
              : "15:00-23:00"}
          </a>
        </li>
        <li>
          <a href="/#" onClick={handleAnchorClick}>
            {advancedOptions.WartoscCzasow6
              ? advancedOptions.WartoscCzasow6.value
              : "22:00-06:00"}
          </a>
        </li>
        <li>
          <a href="/#" onClick={handleAnchorClick}>
            {advancedOptions.WartoscCzasow7
              ? advancedOptions.WartoscCzasow7.value
              : "23:00-07:00"}
          </a>
        </li>
      </ul>

      <ul className={styles.proposedHoursList}>
        <li>
          <a href="/#" onClick={handleAnchorClick}>
            {advancedOptions.WartoscCzasow8
              ? advancedOptions.WartoscCzasow8.value
              : "06:00-14:30"}
          </a>
        </li>
        <li>
          <a href="/#" onClick={handleAnchorClick}>
            {advancedOptions.WartoscCzasow9
              ? advancedOptions.WartoscCzasow9.value
              : "07:00-15:30"}
          </a>
        </li>
        <li>
          <a href="/#" onClick={handleAnchorClick}>
            {advancedOptions.WartoscCzasow10
              ? advancedOptions.WartoscCzasow10.value
              : "08:00-16:00"}
          </a>
        </li>
        <li>
          <a href="/#" onClick={handleAnchorClick}>
            {advancedOptions.WartoscCzasow11
              ? advancedOptions.WartoscCzasow11.value
              : "13:00-21:00"}
          </a>
        </li>
        <li>
          <a href="/#" onClick={handleAnchorClick}>
            {advancedOptions.WartoscCzasow12
              ? advancedOptions.WartoscCzasow12.value
              : "14:30-21:30"}
          </a>
        </li>
        <li>
          <a href="/#" onClick={handleAnchorClick}>
            {advancedOptions.WartoscCzasow13
              ? advancedOptions.WartoscCzasow13.value
              : "21:00-05:00"}
          </a>
        </li>
        <li>
          <a href="/#" onClick={handleAnchorClick}>
            {advancedOptions.WartoscCzasow14
              ? advancedOptions.WartoscCzasow14.value
              : "22:30-07:00"}
          </a>
        </li>
      </ul>

      <div className={styles.times_controls}>
        <div>
          <TextField
            id="time_from"
            label={t("time_from")}
            type="time"
            defaultValue={timeFrom}
            value={timeFrom}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTimeFrom(e.target.value);
              onChangeTime(`${e.target.value}-${timeTo}`);
              //onChangeTime(`${timeFrom}-${timeTo}`);
            }}
          />
        </div>

        <div>
          <TextField
            id="time_to"
            label={t("time_to")}
            type="time"
            defaultValue={timeTo}
            value={timeTo}
            InputLabelProps={{
              shrink: true,
            }}
            /*inputProps={{
              step: 300, // 5 min
            }}*/
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTimeTo(e.target.value);
              onChangeTime(`${timeFrom}-${e.target.value}`);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PopupContentSetTime;
