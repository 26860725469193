import { FormControlLabel } from "@material-ui/core";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AccordionStyled from "../../../helpersComponents/MaterialUi/Accordion/AccordionStyled";
import ButtonStyled from "../../../helpersComponents/Button/button";
import { CheckboxStyled } from "../../../helpersComponents/MaterialUi";
import styles from "./settings.module.scss";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";

const HomeOfficeAddressesSettings: React.FC<any> = () => {
  const { t } = useTranslation();

  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );

  let isAdminProfile = false;

  let foundProfile = authUser.tokenDecoded.profiles.find(
    (el) => el.permissionId && (el.permissionId === 1 || el.permissionId === 2)
  );

  if (foundProfile) {
    isAdminProfile = true;
  }

  const [
    holidayLimitsSettingsAccordionExpanded,
    setHolidayLimitsSettingsAccordionExpanded,
  ] = useState<boolean>(false);

  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const [
    homeOfficeAddressesSettings,
    fetchingStateHomeOfficeAddressesSettings,
    fetchAgainHomeOfficeAddressesSettings,
  ] = useFetchAndSetGET<IHomeOfficeAddressesSettings>({
    path: `superior-time/home-office-addresses-settings`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (holidayLimitsSettingsAccordionExpanded) {
      fetchAgainHomeOfficeAddressesSettings();
    }
  }, [
    holidayLimitsSettingsAccordionExpanded,
    fetchAgainHomeOfficeAddressesSettings,
  ]);

  useEffect(() => {
    if (
      !fetchingStateHomeOfficeAddressesSettings.isFetching &&
      !fetchingStateHomeOfficeAddressesSettings.isError &&
      fetchingStateHomeOfficeAddressesSettings.response?.status === 200 &&
      !dataLoaded
    ) {
      setDataLoaded(true);
    }
  }, [fetchingStateHomeOfficeAddressesSettings, dataLoaded]);

  const [checkbox, setCheckbox] = React.useState({
    addingHomeOfficeAddresses: false,
    editingHomeOfficeAddresses: false,
  });

  const handleChangeCheckbox = (event) => {
    setCheckbox((prev) => {
      return { ...prev, [event.target.name]: event.target.checked };
    });
  };

  const [
    saveSettingsHomeOfficeAddressesBody,
    setSaveSettingsHomeOfficeAddressesBody,
  ] = useState<false | any>(false);

  const [, saveSettingsPutDataFetchAgain] = useFetchOtherThanGET({
    path: "superior-time/home-office-addresses-settings",
    method: "PUT",
    body: saveSettingsHomeOfficeAddressesBody,
    contentType: "application/json",
    setBody: setSaveSettingsHomeOfficeAddressesBody,
    forceSnackbar: true,
  });

  useEffect(() => {
    if (saveSettingsHomeOfficeAddressesBody) {
      saveSettingsPutDataFetchAgain();
    }
  }, [saveSettingsHomeOfficeAddressesBody, saveSettingsPutDataFetchAgain]);

  const saveSettings = useCallback(() => {
    let objToSend: IUpdateHomeOfficeAddressesSettings = {
      canAdd: checkbox.addingHomeOfficeAddresses,
      canEdit: checkbox.editingHomeOfficeAddresses,
    };

    setSaveSettingsHomeOfficeAddressesBody(JSON.stringify(objToSend));
  }, [checkbox]);

  useEffect(() => {
    if (dataLoaded === true) {
      setCheckbox({
        addingHomeOfficeAddresses: homeOfficeAddressesSettings.canAdd,
        editingHomeOfficeAddresses: homeOfficeAddressesSettings.canEdit,
      });
    }
  }, [dataLoaded, homeOfficeAddressesSettings]);

  if (!isAdminProfile) {
    return <></>;
  }

  return (
    <AccordionStyled
      title={t("home_office_addresses")}
      defaultExpanded={false}
      onChange={(event: ChangeEvent<{}>, expanded: boolean) => {
        setHolidayLimitsSettingsAccordionExpanded(expanded);
      }}
    >
      {dataLoaded && (
        <div>
          <div className={styles.settingsContainer}>
            <div className={styles.requestsTypesFilterContainer}>
              <div className={styles.hr_requests}>
                {t("time_worker_permissions_for_home_office_addresses")}:
              </div>
              <div>
                <FormControlLabel
                  control={
                    <CheckboxStyled
                      name="addingHomeOfficeAddresses"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      checked={checkbox.addingHomeOfficeAddresses}
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label={
                    <span className="FormControlLabelStyle">{t("adding")}</span>
                  }
                />

                <FormControlLabel
                  control={
                    <CheckboxStyled
                      name="editingHomeOfficeAddresses"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      checked={checkbox.editingHomeOfficeAddresses}
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label={
                    <span className="FormControlLabelStyle">
                      {t("editing")}
                    </span>
                  }
                />
              </div>
            </div>
          </div>

          <div className={styles.saveChanges}>
            <ButtonStyled onClick={saveSettings} className={styles.saveButton}>
              {t("save")}
            </ButtonStyled>
          </div>
        </div>
      )}
    </AccordionStyled>
  );
};

export default HomeOfficeAddressesSettings;
