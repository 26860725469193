import { ImageOverlay, MapContainer } from "react-leaflet";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import { useLocation, useParams } from "react-router-dom";
import useImageLoader from "../../../hooks/fetchHooks/useImageLoader/useImageLoader";
import { useCallback, useEffect, useState } from "react";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { LatLngBounds, CRS } from "leaflet";

const BuildingFloorSMSUser = (props) => {
  const routeParams: any = useParams();
  const location = useLocation();
  const queryString = location.search;
  const [imageUrls, setImageUrls] = useState([]);
  const queryParams = new URLSearchParams(queryString);
  const { imagesData } = useImageLoader(imageUrls);

  const modifyBuildingFloorResponseCallback = useCallback(
    (responseData: ISMSBuildingFloor) => {
      let urls: any = [];

      urls.push(`sms-user/download-map/${responseData.mapId}`);

      setImageUrls(urls);
      return responseData;
    },
    []
  );

  const [, , fetchAgainBuildingFloor] = useFetchAndSetGET<ISMSBuildingFloor>({
    path: `sms-user/buildings-floors/${routeParams.id}`,
    modifyResponseCallback: modifyBuildingFloorResponseCallback,
  });

  useEffect(() => {
    if (routeParams.id) {
      fetchAgainBuildingFloor();
    }
  }, [routeParams.id, fetchAgainBuildingFloor]);

  const bounds = new LatLngBounds([0, 0], [100, 100]);

  return (
    <div>
      <TopBanner pathName={queryParams.get("menuItemName")} />
      <div className="flexAndCenter">
        {imagesData && imagesData.length > 0 && (
          <MapContainer
            center={[50, 50]}
            zoom={1}
            style={{ width: "100%", height: "60vh" }}
            crs={CRS.Simple}
          >
            <ImageOverlay url={imagesData[0].data} bounds={bounds} />
          </MapContainer>
        )}
      </div>
    </div>
  );
};

export default BuildingFloorSMSUser;
