import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../PopupHelpers";
import PopupContentNewGuest from "./PopupContentNewGuest";

const PopupNewGuest = ({
  isOpen,
  rowId,
  pathApiGuests,
  closePopup,
  successCallback,
}: {
  isOpen: boolean;
  rowId?: number;
  pathApiGuests: string;
  closePopup: () => void;
  successCallback: () => void;
}) => {
  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [bodyRequest, setBodyRequest] = useState<false | string>(false);

  const onSubmit = (values: IGuestAdd) => {
    const body = JSON.stringify({
      firstName: values.firstName,
      lastName: values.lastName,
      idNumber: "",
      companyName: "",
      carRegistrationNumber: "",
      carBrand: "",
      isHighlighted: false,
      highlightedDescription: "",
      highlightedBy: "",
      citizenship: "",
      phoneNumber: "",
      dateOfBirth:
        values.isSelectedDateOfBirth && values.dateOfBirth
          ? convertDateFetch(values.dateOfBirth)
          : null,
      email: values.isSelectedEmail ? values.email : null,
    });
    setBodyRequest(body);
  };

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${pathApiGuests}${rowId !== undefined ? `/${rowId}` : ""}`,
    method: rowId !== undefined ? "PUT" : "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  useEffect(() => {
    if (isOpen === false) {
      setErrorMessage("");
    }
  }, [isOpen]);

  return (
    <DialogStyled open={isOpen} onClose={closePopup}>
      <DialogTitleStyled title={t("add_person")} />
      <PopupContentNewGuest
        rowId={rowId}
        onSubmit={onSubmit}
        pathApiGuests={pathApiGuests}
      />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupNewGuest;
