import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../helpersComponents/PopupHelpers";
import PopupSetPinForACWorkersContent from "./PopupSetPinForACWorkersContent";

interface Props {
  popupSetPinParams: SetPinPopup;
  closePopup: () => void;
  successCallback: () => void;
}

const PopupSetPinForACWorkers: React.FC<Props> = ({
  popupSetPinParams,
  closePopup,
  successCallback,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [bodyRequest, setBodyRequest] = useState<{ acWorkersIds: number[] }>();

  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const [PIN, setPIN] = useState(0);

  const [acWorkerChangePinFetchingState, fetchAgainACWorkerChangePin] =
    useFetchOtherThanGET({
      path: `superior-ac/ac-workers-update-pin?PIN=${PIN}`,
      method: "PATCH",
      body: JSON.stringify(bodyRequest),
      contentType: "application/json",
      successCallback: successCallbackAndClose,
    });

  const onSubmit = (values: { pin: number }) => {
    setErrorMessage("");
    const body = {
      acWorkersIds: popupSetPinParams.workersIds,
    };
    setPIN(values.pin);

    setBodyRequest(body);
  };

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainACWorkerChangePin();
    }
  }, [bodyRequest, fetchAgainACWorkerChangePin]);

  return (
    <DialogStyled open={popupSetPinParams.isOpen} onClose={closePopup}>
      <DialogTitleStyled title={t("set_pin_for_selected")} />
      <PopupSetPinForACWorkersContent onSubmit={onSubmit} />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={() => {
          setErrorMessage("");
          closePopup();
        }}
        isLoading={acWorkerChangePinFetchingState.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupSetPinForACWorkers;
