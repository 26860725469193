// import { useState } from "react";


document.onmousedown = mouseDown;
document.onmouseup = mouseUp;
let mouseStateString: "up" | "down" = "up";

function mouseDown() {
    mouseStateString = "down";
}

function mouseUp() {
    mouseStateString = "up";
}

let mouseState = () => mouseStateString

// const MouseStateHook = () => {
//     const [mouseState, setMouseState] = useState()

//     return 
// }

export default mouseState