import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import Button from "../../../../../helpersComponents/Button/button";
import TableComponent from "../../../../../helpersComponents/TableComponent/tableComponent";
import computeDateRangeFetch from "../../../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getDaysInMonth from "../../../../../../HelpersFunctions/dateAndTime/getDaysInMonth";
import toLocaleStringCustom from "../../../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import LoadingWrapper from "../../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import CellComponent from "./cellComponent"

const PopupScheduleVersions: React.FC<{
  isOpen: boolean;
  closePopup: () => void;
  timeWorkerId: number;
  selectedMonth: Date;
}> = ({ isOpen, closePopup, timeWorkerId, selectedMonth }: any) => {
  const { t } = useTranslation();
  const [scheduleVersionsData, setScheduleVersionsData] = useState<any>([]);
  const [columns, setColumns] = useState<Array<any>>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [, setFetchAgain] = useState(true);
  const [isFetching, setIsFetching] = useState(true);

  const prepareColumnsInTable = useCallback(()=>{
    let columns = [
      {
        name: "version",
        title: t("version"),
        type: "string",
      },
      {
        name: "modifiedBy",
        title: t("added_by"),
        type: "string",
      },
    ]

    for (let i: number = 1; i <= getDaysInMonth(selectedMonth); i++)
    {
      let currentDate: Date = getBeginningOfDate(selectedMonth, "Month", {extraDays: (i-1)})
      columns.push({
        name: toLocaleStringCustom({date: currentDate, ifShowHours: false, t}),
        title: toLocaleStringCustom({date: currentDate, ifShowHours: false, t}),
        type: "string",
      });
    }

    setColumns(columns);
  }, [selectedMonth, t]);

  const responseCallback = useCallback((response: ScheduleVersions[]) => {
    let result: Array<any> = [];

    prepareColumnsInTable();

    if (response)
    {

      response.forEach((el)=>{

        let tempObj = {
          version: `${el.version} ${toLocaleStringCustom({date: el.modifiedAt, ifShowHours: true, t})}`,
          modifiedBy: el.modifiedBy,
          firstRow: false
        }

        if (el.version === 0)
        {
          tempObj.firstRow = true;
          tempObj.version = t("current_version");
        }

        el.daySchemas.forEach((ds)=>{
          tempObj[toLocaleStringCustom({date: ds.day, ifShowHours: false, t})] = ds.schemaName;
        })

        result.push(tempObj); 
      })

      if (result !== undefined && result.length > 0)
      {
        for (let i=1; i<result.length; i++)
        {
          let {previousRow, ...newPayload} = result[i - 1];
          result[i].previousRow = newPayload;
        }
      }
    }

    setScheduleVersionsData(result);
    setIsFetching(false);
    setIsError(false);
    return response;
  }, [prepareColumnsInTable, t]);

  const [
    ,
    ,
    fetchScheduleVersionsAgain,
  ] = useFetchAndSetGET<ScheduleVersions[]>({
    path:
      "superior-time/schedules-versions/" +
      timeWorkerId +
      "?selectedMonth=" +
      computeDateRangeFetch(getBeginningOfDate(selectedMonth, "Month")),
    startFetchOnInitial: false,
    modifyResponseCallback: responseCallback,
  });

  useEffect(()=>{
    if (isOpen)
    {
      setIsFetching(true);
      setFetchAgain(true);
      setIsError(false);
      fetchScheduleVersionsAgain();
    }
    else
    {
      setScheduleVersionsData([]);
      setIsFetching(false);
      setFetchAgain(false);
      setIsError(false);
    }
  }, [isOpen, fetchScheduleVersionsAgain])

  return (
    <Dialog open={isOpen} onClose={closePopup} maxWidth="lg">
      <DialogTitle className="dialogTitleGlobal">
        <div className="titlePopup">{t("schedule_versions")}</div>
      </DialogTitle>

      <DialogContent>
        <LoadingWrapper 
        isLodadingProgress={isFetching}
        isError={isError}
        setIfFetchAgain={setFetchAgain}
        setIsError={setIsError}
        >
          {!isFetching && !isError &&
        (<TableComponent
          title={t("list_of_holidays_requests")}
          layoutSettingName="layoutSuperiorTimeScheduleVersions"
          rows={scheduleVersionsData}
          columns={columns}
          ifEdit={false}
          ifAdd={false}
          ifDelete={false}
          filteringRowDefaultHidden={true}
          ifHideWholeToolbalr={true}
          pageSize={100}
          columnsWidth={[{columnName: "version", width: "200px"}]}
          tableCellComponent={CellComponent}
        />)}
        </LoadingWrapper>
      </DialogContent>

      <DialogActions className="dialogActionsGlobal">
        <Button onClick={closePopup}>{t("close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupScheduleVersions;
