const doFetch = async (path: string, bodyPost: any) => {
  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );
  let language = JSON.parse(
    localStorage.getItem("languageAndOptionId") || "{}"
  );

  const res = await fetch(window.globalConfig.API_URL + "/api/" + path, {
    method: "PUT",
    credentials: window.globalConfig?.LOG_BY_WINDOWS_DOMAIN
      ? "include"
      : "omit",
    headers: {
      Authorization: "bearer " + authUser.token,
      "Content-Type": "application/json",
      "Accept-Language": language ? language[0] : "pl",
    },
    body: JSON.stringify(bodyPost),
  });

  if (res.ok) {
    return {
      status: res.status,
    };
  } else if (res.status === 400) {
    return res.json();
  }
  throw res.status;
};

export default doFetch;
