import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DayFunctionType } from "../../../../../../enums/dayFunctionType";
import { makeStyles } from "@material-ui/core/styles";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../../../helpersComponents/PopupHelpers";
import PopupContentSetDayFunction from "./PopupContentSetDayFunction";
import { DialogContent } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: "400px",
    width: "420px",
  },
}));

const PopupSetDayFunction = ({
  isOpen,
  closePopup,
  confirm,
}: {
  isOpen: boolean;
  closePopup: () => void;
  confirm: (dayFunction: DayFunction) => void;
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedDayFunctionItem, setSelectedDayFunctionItem] = useState<
    DayFunctionItem | undefined
  >(undefined);
  const [selectedOrganizationalUnitItem, setSelectedOrganizationalUnitItem] =
    useState<OrganizationalUnitItem | undefined>(undefined);
  const [selectedLabelItem, setSelectedLabelItem] = useState<
    ScheduleDayLabel | undefined
  >(undefined);

  useEffect(() => {
    if (isOpen === false) {
      setErrorMessage("");
      setSelectedDayFunctionItem(undefined);
      setSelectedOrganizationalUnitItem(undefined);
      setSelectedLabelItem(undefined);
    }
  }, [isOpen]);

  const validateForm = () => {
    if (!selectedDayFunctionItem) {
      setErrorMessage(t("day_function_not_selected"));
      return false;
    }

    if (selectedDayFunctionItem.id === 0 && !selectedOrganizationalUnitItem) {
      setErrorMessage(t("organizational_unit_not_selected"));
      return false;
    }

    if (selectedDayFunctionItem.id === 1 && !selectedLabelItem) {
      setErrorMessage(t("label_not_selected"));
      return false;
    }

    return true;
  };

  const classes = useStyles();

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitleStyled title={t("assign_function_to_day")} />
      <DialogContent>
        <PopupContentSetDayFunction
          setSelectedDayFunctionItem={setSelectedDayFunctionItem}
          setSelectedOrganizationalUnitItem={setSelectedOrganizationalUnitItem}
          setSelectedLabelItem={setSelectedLabelItem}
        />
      </DialogContent>
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        onConfirm={() => {
          if (validateForm()) {
            let dayFunctionType = selectedDayFunctionItem!
              .id as DayFunctionType;
            let subjectId = 0;
            let subjectName = "";
            let color = "";

            if (dayFunctionType === DayFunctionType.ORGANIZATIONAL_UNIT) {
              subjectId = selectedOrganizationalUnitItem!.id;
              subjectName = selectedOrganizationalUnitItem!.name;
              color = selectedOrganizationalUnitItem!.color;
            } else if (dayFunctionType === DayFunctionType.LABEL) {
              subjectId = selectedLabelItem!.id;
              subjectName = selectedLabelItem!.name;
              color = selectedLabelItem!.color;
            }

            let dayFunction: DayFunction = {
              day: new Date(),
              subjectId: subjectId,
              subjectName: subjectName,
              functionType: dayFunctionType,
              color: color,
            };

            confirm(dayFunction);
            closePopup();
          }
        }}
        isLoading={false}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupSetDayFunction;
