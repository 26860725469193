import "./holidaysLimit.scss";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import TimeWorkersHolidaysLimits from "../../../helpersComponents/TimeWorkersHolidaysLimits/timeWorkersHolidaysLimits";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import { DatePickerStyled } from "../../../helpersComponents/MaterialUi";
import useUpdateLastSelectedWorkers from "../../../../hooks/useUpdateLastSelectedWorkers/useUpdateLastSelectedWorkers";

function HolidaysLimitContent() {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([]);
  const [, setAllWorkers] = useState<any[]>([]);
  const [, setAllWorkersSelected] = useState<boolean>(false);

  const layoutSettingName =
    "layoutSuperiorTimeAdministrationsHolidaysLimitsEmployees";

  const [updateLastSelectedWorkers] =
    useUpdateLastSelectedWorkers(selectedWorkers);

  useEffect(() => {
    if (selectedWorkers && selectedWorkers.length > 0) {
      updateLastSelectedWorkers();
    }
  }, [selectedWorkers, updateLastSelectedWorkers]);

  return (
    <>
      <TopBanner pathName={t("holidays_limit")} />
      <div className="flexAndCenter">
        <div>
          <WorkerList
            layoutSettingName={layoutSettingName}
            setSelectedWorkers={setSelectedWorkers}
            selectedWorkers={selectedWorkers}
            setAllWorkers={setAllWorkers}
            setAllWorkersSelected={setAllWorkersSelected}
          />
        </div>
        <div className="selectDateDarkGlobal">
          <div className="selectYear dateContainer">
            <div className="inputTitleBonuses">{t("select_date")}</div>
            <div className="selectYearInputBonuses">
              <DatePickerStyled
                view={["year"]}
                format="yyyy"
                date={date}
                setDate={setDate}
                darkTheme={true}
                width={"100%"}
              />
            </div>
          </div>
        </div>

        <TimeWorkersHolidaysLimits
          year={date.getFullYear()}
          timeWorkersIds={selectedWorkers || []}
        />
      </div>
    </>
  );
}

export default HolidaysLimitContent;
