import {
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import { colorNameDictionary } from "../../../ProfilesRouting/profilesDictionaries";
import ButtonStyled from "../../Button/button";
import styles from "./TransferList.module.scss";

function not<ArrayItem>(a: readonly ArrayItem[], b: readonly ArrayItem[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection<ArrayItem>(
  a: readonly ArrayItem[],
  b: readonly ArrayItem[]
) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union<ArrayItem>(a: readonly ArrayItem[], b: readonly ArrayItem[]) {
  return [...a, ...not(b, a)];
}

interface Props<ArrayItem> {
  leftArray: ArrayItem[];
  extraLeftFunctions?: (item: any) => boolean;
  setLeftArray: Dispatch<SetStateAction<ArrayItem>>;
  rightArray: ArrayItem[];
  extraRightFunction?: (item: any) => boolean;
  setRightArray: Dispatch<SetStateAction<ArrayItem>>;
  displayPropertyFromObject?: string;
}

const TransferList: React.FC<Props<any>> = ({
  leftArray,
  extraLeftFunctions,
  setLeftArray,
  rightArray,
  extraRightFunction,
  setRightArray,
  displayPropertyFromObject,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<readonly any[]>([]);
  const leftChecked = intersection(checked, leftArray);
  const rightChecked = intersection(checked, rightArray);

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly any[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: readonly any[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    if (extraRightFunction && !extraRightFunction(leftChecked)) {
      return;
    }

    setRightArray(rightArray.concat(leftChecked));
    setLeftArray(not(leftArray, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    if (extraLeftFunctions && !extraLeftFunctions(rightChecked)) {
      return;
    }

    setLeftArray(leftArray.concat(rightChecked));
    setRightArray(not(rightArray, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const authUserType = useAppSelector(selectAuthUser).currentProfile.type;
  const color = colorNameDictionary[authUserType];

  const customList = (title: React.ReactNode, items: any) => (
    <Card>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            style={{ color: "#fff" }}
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        className={styles.cardHeader}
        style={{ backgroundColor: color }}
        subheader={`${numberOfChecked(items)}/${items.length} ${t("selected")}`}
      />
      <Divider />
      <List className={styles.list} dense component="div" role="list">
        {items.map((value: any, index: number) => {
          const labelId = `transfer-list-all-item-${index}-label`;

          return (
            <ListItem
              key={index}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                  style={{ color: color }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${
                  !displayPropertyFromObject
                    ? value
                    : value[`${displayPropertyFromObject}`]
                }`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <div className={styles.transferList}>
      <div className={styles.transferListContent}>
        <div className={styles.tableListLeft}>
          {customList(t("other_to_select"), leftArray)}
        </div>
        <div className={styles.arrowButtons}>
          <ButtonStyled
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
            width={50}
            height={40}
            type="button"
          >
            &gt;
          </ButtonStyled>
          <ButtonStyled
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
            width={50}
            height={40}
            type="button"
          >
            &lt;
          </ButtonStyled>
        </div>
        <div className={styles.tableListRight}>
          {customList(t("selected_2"), rightArray)}
        </div>
      </div>
    </div>
  );
};

export default TransferList;
