import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

const initialState: any = {
  settings: { ifSettingsFetched: false },
};

export const settingsReducer = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<any>) => {
      state.settings = action.payload;
    },
  },
});

export const selectSettings = (state: RootState) =>
  state.settingsState.settings;
export const { setSettings } = settingsReducer.actions;
export default settingsReducer.reducer;
