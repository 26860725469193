import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../../Button/button";
import styles from "./styles.module.scss";

type Props = {
  presentation: Presentation;
  setLoaded: React.Dispatch<boolean>;
};

export default function SinglePresentation({ presentation, setLoaded }: Props) {
  const { t, i18n } = useTranslation();
  const [viedoError, setViedoError] = useState(false);
  const elementRef: any = useRef();
  const [isMovie, setIsMovie] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    let isMovie = false;
    switch (i18n.language) {
      case "en":
        if (presentation.isMovieEN) {
          isMovie = true;
        }

        setIsMovie(presentation.isMovieEN);
        setTitle(presentation.titleEN);
        setDescription(presentation.descriptionEN);
        break;

      case "de":
        if (presentation.isMovieDE) {
          isMovie = true;
        }

        setIsMovie(presentation.isMovieDE);
        setTitle(presentation.titleDE);
        setDescription(presentation.descriptionDE);
        break;

      case "ru":
        if (presentation.isMovieRU) {
          isMovie = true;
        }

        setIsMovie(presentation.isMovieRU);
        setTitle(presentation.titleRU);
        setDescription(presentation.descriptionRU);
        break;

      case "uk":
        if (presentation.isMovieUK) {
          isMovie = true;
        }

        setIsMovie(presentation.isMovieUK);
        setTitle(presentation.titleUK);
        setDescription(presentation.descriptionUK);
        break;

      default:
        if (presentation.isMoviePL) {
          isMovie = true;
        }

        setIsMovie(presentation.isMoviePL);
        setTitle(presentation.titlePL);
        setDescription(presentation.descriptionPL);
        break;
    }

    if (!isMovie) setLoaded(true);

    return () => {
      setLoaded(false);
    };
  }, [
    presentation,
    setLoaded,
    i18n.language,
    setIsMovie,
    setTitle,
    setDescription,
    elementRef,
  ]);

  useEffect(() => {
    if (!isMovie) {
      switch (i18n.language) {
        case "en":
          if (elementRef?.current) {
            elementRef.current.innerHTML = presentation.contentEN;
          }
          break;

        case "de":
          if (elementRef?.current) {
            elementRef.current.innerHTML = presentation.contentDE;
          }
          break;

        case "ru":
          if (elementRef?.current) {
            elementRef.current.innerHTML = presentation.contentRU;
          }
          break;

        case "uk":
          if (elementRef?.current) {
            elementRef.current.innerHTML = presentation.contentUK;
          }
          break;

        default:
          if (elementRef?.current) {
            elementRef.current.innerHTML = presentation.contentPL;
          }
          break;
      }
    }
  }, [
    isMovie,
    i18n.language,
    presentation.contentPL,
    presentation.contentEN,
    presentation.contentDE,
    presentation.contentRU,
    presentation.contentUK,
  ]);

  return (
    <div>
      <div className={styles.titleDescription}>
        <b>{title}</b> - {description}
      </div>

      {!isMovie && <div className={styles.content} ref={elementRef}></div>}

      {isMovie && (
        <>
          {viedoError === false ? (
            <div className={styles.viedoContainer}>
              <video
                controls
                onError={(e) => {
                  setViedoError(true);
                }}
                onLoadedData={() => {
                  setLoaded(true);
                }}
                key={i18n.language}
              >
                <source
                  src={`${window.globalConfig.API_URL}/api/trainings-presentations-movies/${presentation.id}?lang=${i18n.language}`}
                  type="video/mp4"
                />
                Your browser does not support HTML video.
              </video>
            </div>
          ) : (
            <ButtonStyled
              onClick={() => {
                setViedoError(false);
              }}
            >
              {t("something_went_wrong")}
            </ButtonStyled>
          )}
        </>
      )}
    </div>
  );
}
