import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DaysOfWeek } from "../../../../../../../Constants/daysOfWeek";
import convertDateFetch from "../../../../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../../../../helpersComponents/PopupHelpers";
import PopupZonePermissionItemContent from "./PopupZonePermissionItemContent";

export interface IAddEditZonePermission {
  startTime: string;
  endTime: string;
  onlyRangeFromCheck: boolean;
  rangeFrom: Date | null;
  rangeTo: Date | null;
  daysOfWeek: IDayOfWeek[];
  canAlwaysEnter: boolean;
  zones: IZone[];
  added: boolean;
}

interface IProps {
  isOpen: boolean;
  closePopup: () => void;
  formikValues: {
    [x: string]: string | number | boolean | number[] | null | any[] | Date;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const PopupZonePermissionItem: React.FC<IProps> = ({
  isOpen,
  closePopup,
  setFieldValue,
  formikValues,
}) => {
  const { t } = useTranslation();

  const [isValid, setIsValid] = useState(true);

  const onSubmit = (values: IAddEditZonePermission) => {
    const daysOfWeek = DaysOfWeek(t);
    let zonePermissions: Partial<IZonePermission>[] = [
      ...(formikValues["zonePermissions"] as Partial<IZonePermission>[]),
    ];
    let permissions: Partial<IGroupPermission>[] = [
      ...(formikValues["permissions"] as Partial<IGroupPermission>[]),
    ];

    let daysOfWeekIds: number[] = [];

    let startTimeDate: Date;
    let endTimeDate: Date;

    if (values.canAlwaysEnter) {
      daysOfWeekIds = [1, 2, 3, 4, 5, 6, 7];
      startTimeDate = new Date(new Date().setHours(0, 0, 0));
      endTimeDate = new Date(new Date().setHours(23, 59, 59));
    } else {
      daysOfWeekIds = values.daysOfWeek.map((day) => day.value);

      let today = new Date();
      const formatStartTime = values.startTime
        .split(":")
        .map((item) => Number(item));
      startTimeDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        formatStartTime[0],
        formatStartTime[1]
      );

      const formatEndTime = values.endTime
        .split(":")
        .map((item) => Number(item));
      endTimeDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        formatEndTime[0],
        formatEndTime[1]
      );
    }

    if (!values.onlyRangeFromCheck) {
      values.rangeFrom = new Date(2000, 0, 1);
      values.rangeTo = new Date(9998, 11, 31);
    }

    let errors = false;
    let index = zonePermissions.length + permissions.length + 1;

    values.zones.forEach((zone) => {
      zone.rooms.forEach((room) => {
        daysOfWeekIds.forEach((day) => {
          const newPermissionZone = {
            id: index++,
            day: day,
            dayName: daysOfWeek.find((dayItem) => dayItem.value === day)?.name,
            entryFrom: convertDateFetch(startTimeDate),
            entryTo: convertDateFetch(endTimeDate),
            rangeFrom: convertDateFetch(values.rangeFrom!),
            rangeTo: convertDateFetch(values.rangeTo!),
            added: true,
            zoneId: zone.id,
            roomId: room.id,
          };
          //Validation - if permissions overlaps
          errors = permissionsAreOverlap(permissions, newPermissionZone);

          if (!errors) {
            zonePermissions.push(newPermissionZone);

            const newPermission = {
              id: index++,
              day: day,
              dayName: daysOfWeek.find((dayItem) => dayItem.value === day)
                ?.name,
              entryFrom: convertDateFetch(startTimeDate),
              entryTo: convertDateFetch(endTimeDate),
              rangeFrom: convertDateFetch(values.rangeFrom!),
              rangeTo: convertDateFetch(values.rangeTo!),
              added: true,
              roomId: room.id,
              roomName: room.name,
              zoneId: zone.id,
              zoneName: zone.name,
            };
            permissions.push(newPermission);
          }
        });
      });
    });

    if (!errors) {
      setFieldValue("zonePermissions", zonePermissions);

      const allPermissions = permissions.sort((a, b) => a.day! - b.day!);
      setFieldValue("permissions", allPermissions);

      closePopup();
    } else {
      setIsValid(false);
    }
  };

  const permissionsAreOverlap = (
    permissions: Partial<IGroupPermission>[],
    newPermission: Partial<IGroupPermission>
  ) => {
    if (
      permissions.some((perm) => {
        return (
          perm.roomId === newPermission.roomId &&
          perm.day === newPermission.day &&
          new Date(perm.entryFrom!) <= new Date(newPermission.entryTo!) &&
          new Date(newPermission.entryFrom!) <= new Date(perm.entryTo!) &&
          new Date(perm.rangeFrom!) <= new Date(newPermission.rangeTo!) &&
          new Date(newPermission.rangeFrom!) <= new Date(perm.rangeTo!)
        );
      })
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      disableBackdropClick={true}
      maxWidth={"lg"}
    >
      <DialogTitleStyled title={t("permission")} />
      <PopupZonePermissionItemContent onSubmit={onSubmit} isValid={isValid} />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="zonePermissionForm"
        onCancel={closePopup}
      />
    </DialogStyled>
  );
};

export default PopupZonePermissionItem;
