import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionSummary, Tooltip } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import { colorNameDictionary } from "../../../ProfilesRouting/profilesDictionaries";
import styles from "./styles.module.scss";

interface IProps {
  title: string | JSX.Element;
  defaultExpanded?: boolean;
  backgroundColor?: string;
  isError?: boolean;
  onChange?: ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const AccordionStyled: React.FC<IProps> = ({
  title,
  defaultExpanded,
  backgroundColor,
  isError,
  children,
  onChange,
}) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const color = colorNameDictionary[authUser.currentProfile?.type];
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      style={{ backgroundColor: backgroundColor ? backgroundColor : "" }}
      onChange={onChange}
    >
      <AccordionSummary
        className={styles.accordionSummary}
        expandIcon={
          <FontAwesomeIcon icon={faArrowAltCircleDown} color={color} />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={styles.titleCotainer}>
          <p className={styles.title}>{title}</p>
          {isError && (
            <Tooltip title={t("invalid_data")!} placement="bottom">
              <Error color="error" />
            </Tooltip>
          )}
        </div>
      </AccordionSummary>
      {children}
    </Accordion>
  );
};

export default AccordionStyled;
