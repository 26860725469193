import { useTranslation } from "react-i18next";
import styles from "./HourRequestValidationErrors.module.scss";

interface IProps {
  firstName: string;
  lastName: string;
  errors: string[];
}

const HourRequestValidationErrorsItem: React.FC<IProps> = ({
  firstName,
  lastName,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <span className={styles.errorItemWorker}>
          {firstName} {lastName}
        </span>
      </div>
      <div>
        <ul>
          {errors?.map((el) => (
            <li className={styles.errorItemLabel}>{t(el)}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default HourRequestValidationErrorsItem;
