import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getSecondsFromDatesHoursAndMinutes } from "../../../../HelpersFunctions/dateAndTime/getSecondsFromDatesHoursAndMinutes";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";

type Props = {
  setCurrentTabName: React.Dispatch<TabsInterface>;
  training: any;
  questions: Question[];
  presentationsStore: PresentationObject[];
};

type ReturnValues = [
  ({ values }: { values: BasicInfoObject }) => void,
  {
    isFetching: boolean;
    isError: boolean;
    response: UseFetchResponse | undefined;
  }
];

export default function useSubmitAddEdit({
  setCurrentTabName,
  training,
  questions,
  presentationsStore,
}: Props): ReturnValues {
  const history = useHistory();

  const successCallback = useCallback(() => {
    history.push({
      pathname: `/superior-ac/trainings`,
    });
  }, [history]);

  const errorCallback = useCallback(() => {
    setCurrentTabName("questions");
  }, [setCurrentTabName]);

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [fetchingStatePutPostData, fetchAgainPutPostData] =
    useFetchOtherThanGET({
      path: `superior-ac/trainings${training?.id ? `/${training.id}` : ""}`,
      method: training ? "PUT" : "POST",
      body: bodyRequest,
      setBody: setBodyRequest,
      successCallback: successCallback,
      errorCallback: errorCallback,
    });

  useEffect(() => {
    if (fetchingStatePutPostData.isFetching) {
      setCurrentTabName(null);
    }
  }, [fetchingStatePutPostData.isFetching, setCurrentTabName]);

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPutPostData();
    }
  }, [bodyRequest, fetchAgainPutPostData]);

  const confirmFetching = ({ values }: { values: BasicInfoObject }) => {
    let presentationsCopy: Presentation[] = presentationsStore.map(
      (presentation, i) => {
        const {
          minTime,
          moviePL,
          movieEN,
          movieDE,
          movieRU,
          movieUK,
          ...rest
        } = presentation;
        return {
          ...rest,
          minTime: getSecondsFromDatesHoursAndMinutes(new Date(minTime)),
        };
      }
    );

    let body: TrainingObjectPutPost = {
      id: training ? training.id : 0,
      namePL: values.namePL,
      nameEN: values.nameEN,
      nameDE: values.nameDE,
      nameRU: values.nameRU,
      nameUK: values.nameUK,
      type: values.type?.id ? values.type.id : 0,
      kindOfVisit: values.kindOfVisit?.id ? values.kindOfVisit.id : 0,
      validFrom: values.validFrom,
      validTo: values.validTo,
      importance: values.importance,
      backToPreviousQuestionPossible: values.backToPreviousQuestionPossible,
      numberOfQuestions: values.numberOfQuestions,
      multiSelectionInQuestions: values.multiSelectionInQuestions,
      isRequired: values.isRequired,
      descriptionPL: values.descriptionPL,
      descriptionEN: values.descriptionEN,
      descriptionDE: values.descriptionDE,
      descriptionRU: values.descriptionRU,
      descriptionUK: values.descriptionUK,
      order: values.order,
      isRepeatPossible: values.isRepeatPossible,
      passTresholdInPercent: values.passTresholdInPercent,
      questions: questions,
      presentations: presentationsCopy,
    };

    let formData = new FormData();
    formData.append("requestData", JSON.stringify(body));

    presentationsStore.forEach((presentation) => {
      if (presentation?.moviePL && presentation.isMoviePL) {
        formData.append(presentation.formFieldPLId, presentation.moviePL[0]);
      }

      if (presentation?.movieEN && presentation.isMovieEN) {
        formData.append(presentation.formFieldENId, presentation.movieEN[0]);
      }

      if (presentation?.movieDE && presentation.isMovieDE) {
        formData.append(presentation.formFieldDEId, presentation.movieDE[0]);
      }

      if (presentation?.movieRU && presentation.isMovieRU) {
        formData.append(presentation.formFieldRUId, presentation.movieRU[0]);
      }

      if (presentation?.movieUK && presentation.isMovieUK) {
        formData.append(presentation.formFieldUKId, presentation.movieUK[0]);
      }
    });

    setBodyRequest(formData);
  };

  return [confirmFetching, fetchingStatePutPostData];
}
