import { useAppDispatch } from "../../store/hooks";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { setAuthUser } from "../../reducers/session";
import fetchPost from "../fetchHooks/post/fetchPost";
import { setIsOpenLeftNavbar } from "../../reducers/isOpenLeftNavbar";

const useLogout = (error = 200) => {
  let history = useHistory();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const currentProfile: authUserSingleProfileData = JSON.parse(
      localStorage.getItem("authUser") || "{}"
    ).currentProfile;

    if (history?.location.pathname === "/login") return;
    if (error === 401) {
      fetchPost("account/logged-out-log", {
        profileType: currentProfile.type,
        subjectId: currentProfile.subjectId,
      });

      localStorage.removeItem("authUser");
      dispatch({ type: "RESET_STATE" });
      dispatch(
        setIsOpenLeftNavbar(localStorage.getItem("isOpenNavbar") === "true")
      );
      dispatch(
        setAuthUser(JSON.parse(localStorage.getItem("authUser") || "{}"))
      );
      history?.push("/login");
    }
  }, [error, dispatch, history]);
};

export default useLogout;
