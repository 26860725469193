import { HolidayRequestTypeFunction } from "../../../../enums/holidayRequestTypeFunction";

/// if there is option WebWnioskiBezGodzin, set hours required for all request besides requests included in this option (ignoring WebWniosekUrlopowyWyborGodzinyDlaOpiekiWymagany)
export const settingIfHoursRequired = (
  WebWnioskiBezGodzin,
  setIfHoursRequired,
  absenceTypes
) => {
  if (!WebWnioskiBezGodzin?.value) return;
  let listOfrequestIncludedInWebWnioskiBezGodzin =
    WebWnioskiBezGodzin.value.split(";");
  let ifHoursRequiredLocal: { [key in number]: "1" } = {};
  absenceTypes.forEach((absenceType) => {
    if (
      !(
        absenceType.function === HolidayRequestTypeFunction.HOME_OFFICE ||
        absenceType.function ===
          HolidayRequestTypeFunction.OCCASIONAL_HOME_OFFICE
      )
    ) {
      if (
        !listOfrequestIncludedInWebWnioskiBezGodzin.includes(
          absenceType.nameAbbrev
        )
      ) {
        ifHoursRequiredLocal[absenceType.id] = "1";
      }
    }
  });
  setIfHoursRequired(ifHoursRequiredLocal);
};
