import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

//// moved to cached Fetch
const initialState: any = {
  timeWorkersSuperiorArray: undefined,
  timeWorkersSuperiorObject: undefined,
};

export const workersListsReducer = createSlice({
  name: "setTimeWorkers",
  initialState,
  reducers: {
    setTimeWorkersSuperior: (state, action: PayloadAction<any>) => {
      let timeWorkersSuperiorObject = { updateTime: action.payload[1] };
      action.payload.forEach((timeWorker) => {
        timeWorkersSuperiorObject[timeWorker.id] = timeWorker;
      });
      state.timeWorkersSuperiorArray = action.payload;
      state.timeWorkersSuperiorObject = timeWorkersSuperiorObject;
    },
  },
});

export const selectTimeWorkersSuperiorArray = (state: RootState) =>
  state.workersLists.timeWorkersSuperiorArray;
export const selectTimeWorkersSuperiorObject = (state: RootState) =>
  state.workersLists.timeWorkersSuperiorObject;
export const { setTimeWorkersSuperior } = workersListsReducer.actions;
export default workersListsReducer.reducer;
