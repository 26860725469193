import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import AddEditBuildingGroupPopup from "./Popups/AddEditBuildingGroupPopup";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";

const BuildingsGroupsSMSAdministrator = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const [buildingGroupIdToRemove, setBuildingGroupIdToRemove] = useState(false);

  const columns = [
    { name: "name", title: t("name") },
    { name: "order", title: t("order") },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "editedBy", title: t("edited") },
    { name: "editedAt", title: t("data_edited"), type: "date_hours" },
  ];

  const [
    buildingsGroups,
    fetchingStateBuildingsGroups,
    fetchAgainBuildingsGroups,
  ] = useFetchAndSetGET({
    path: `sms-administrator/buildings-groups`,
    startFetchOnInitial: true,
  });

  const [, fetchAgainRemoveBuildingGroup] = useFetchOtherThanGET({
    path: `sms-administrator/buildings-groups/${buildingGroupIdToRemove}`,
    method: "DELETE",
    body: buildingGroupIdToRemove,
    setBody: setBuildingGroupIdToRemove,
    successCallback: fetchAgainBuildingsGroups,
  });

  useEffect(() => {
    if (buildingGroupIdToRemove) {
      fetchAgainRemoveBuildingGroup();
    }
  }, [buildingGroupIdToRemove, fetchAgainRemoveBuildingGroup]);

  return (
    <div>
      <TopBanner pathName={t("buildings_groups")} />
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isLodadingProgress={fetchingStateBuildingsGroups.isFetching}
            isError={fetchingStateBuildingsGroups.isError}
            setIfFetchAgain={() => {
              fetchAgainBuildingsGroups();
            }}
          >
            {!fetchingStateBuildingsGroups.isFetching && (
              <TableComponent
                layoutSettingName="layoutSMSAdministratorBuildingsGroups"
                rows={buildingsGroups || []}
                columns={columns}
                actionsColumnUpdated={{
                  popup: (props) => <AddEditBuildingGroupPopup {...props} />,
                  successCallbackPopup: fetchAgainBuildingsGroups,
                  addButton: {
                    ifShow: true,
                  },
                  removeButton: {
                    ifShow: true,
                    name: t("delete"),
                    onClick: async (item) => {
                      let selectedbuttonId = await confirm({
                        text: `${t("confirm_delete_building_group")}`,
                        buttons: [
                          { buttonName: t("remove"), buttonId: 0 },
                          { buttonName: t("cancel"), buttonId: 1 },
                        ],
                      });

                      if ((await selectedbuttonId) === 0) {
                        setBuildingGroupIdToRemove(item.id);
                      }
                    },
                  },
                  editButton: {
                    ifShow: true,
                    name: t("edit"),
                  },
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
};

export default BuildingsGroupsSMSAdministrator;
