import { faArrowsAlt, faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import styles from "./buttons.module.scss";

interface Props {
  disablePan: boolean;
  setDisablePan: Dispatch<SetStateAction<boolean>>;
}

const MoveChartButton: React.FC<Props> = ({ disablePan, setDisablePan }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("moving")!}>
      <div className={styles.buttonContainer}>
        <FontAwesomeIcon
          icon={!disablePan ? faArrowsAlt : faBan}
          style={{ color: !disablePan ? "#383838" : "red" }}
          size={"1x"}
          className={styles.button}
          onClick={() => {
            setDisablePan((prevState) => !prevState);
          }}
        />
      </div>
    </Tooltip>
  );
};

export default MoveChartButton;
