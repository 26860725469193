import React from "react";
import ContentBusinessExits from "./ContentBusinessExits";

function BusinessExits() {
  return (
    <div>
      <ContentBusinessExits />
    </div>
  );
}

export default BusinessExits;
