import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import Navigation from "../Navigation/navigationReception";

import RowDetailSupportListsOfEmployees from "./RowDetailSupportListsOfEmployees";

interface IProps {
  pathApiLetIn: string;
  pathApiReject: string;
  pathApiListsOfEmployees: string;
}

const SupportContent: React.FC<IProps> = ({
  pathApiLetIn,
  pathApiReject,
  pathApiListsOfEmployees,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const columns = [
    { name: "id", title: t("number") },
    { name: "date", title: t("date"), type: "date" },
    { name: "timeFrom", title: t("time_from") },
    { name: "timeTo", title: t("time_to") },
    { name: "numberOfEmployees", title: t("number_of_people") },
    { name: "companies", title: t("companies") },
    { name: "createdAt", title: t("data_created"), type: "date" },
    { name: "employeesFilter", title: "employeesFilter", hide: true },
  ];

  const modifyResponseCallback = useCallback(
    (responseData: IListOfEmployees[]) => {
      const modified = responseData.map((row) => {
        return {
          ...row,
          numberOfEmployees: row.employess?.length,
          timeTo: row.timeTo ? row.timeTo : "-",
          companies: [
            ...new Set(row.employess.map((employee) => employee.company)),
          ].join("; "),
          employeesFilter: row.employess
            .map((item) => ({
              employeeFirstName: item.employeeFirstName,
              employeeLastName: item.employeeLastName,
              toString: function () {
                return this.employeeFirstName + " " + this.employeeLastName;
              },
            }))
            .join(";"),
        };
      });
      return modified;
    },
    []
  );

  const [
    listsOfEmployeesRows,
    fetchingStateListsOfEmployees,
    fetchAgainListsOfEmployees,
  ] = useFetchAndSetGET<IListOfEmployees[]>({
    path: `${pathApiListsOfEmployees}?date=${convertDateFetch(new Date())}`,
    modifyResponseCallback: modifyResponseCallback,
  });

  return (
    <div>
      <div>
        <TopBanner pathName={t("lists_of_employees")} />
      </div>
      <div>
        <Navigation />
      </div>

      <div>
        <TableComponentMemo
          columns={columns}
          rows={listsOfEmployeesRows}
          layoutSettingName={"layoutReceptionistsOfEmployeesRecords"}
          tableEditColumnWidth={230}
          rowDetail={(props) => (
            <RowDetailSupportListsOfEmployees
              pathApiLetIn={pathApiLetIn}
              pathApiReject={pathApiReject}
              fetchAgainListsOfEmployees={fetchAgainListsOfEmployees}
              {...props}
            />
          )}
          fetchingState={{
            isFetching: fetchingStateListsOfEmployees.isFetching,
            isError: fetchingStateListsOfEmployees.isError,
            fetchAgain: fetchAgainListsOfEmployees,
          }}
          actionsColumnUpdated={{
            addButton: {
              ifShow: true,
              icon: faPlus,
              onClick: () => {
                history.push("/reception/add-operational-support-notification");
              },
            },

            manualRefreshButton: {
              ifShow: true,
              onClick: () => {
                fetchAgainListsOfEmployees();
              },
            },
            successCallbackPopup: fetchAgainListsOfEmployees,
          }}
        />
      </div>
    </div>
  );
};

export default SupportContent;
