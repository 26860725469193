import { makeStyles } from "@material-ui/styles";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../helpersComponents/PopupHelpers";
import PopupContentGroups from "./PopupContentGroups";

interface Props {
  isOpen: boolean;
  rowId: number | undefined;
  closePopup: () => void;
  successCallback: () => void;
}

const PopupGroups: React.FC<Props> = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [requestBody, setRequestBody] = useState<false | any>(false);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `superior-ac/groups${rowId !== undefined ? `/${rowId}` : ""}`,
    method: rowId !== undefined ? "PUT" : "POST",
    contentType: "application/json",
    body: requestBody,
    setBody: setRequestBody,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, fetchAgainPostData]);

  const onSubmit = (values) => {
    setErrorMessage("");

    let object;
    if (!rowId) {
      object = {
        name: values.name,
        companyId: values.company.id,
        description: values.description,
        permissions: values.permissions
          ? values.permissions.filter((permission) => !permission.zoneId)
          : null,
        zonePermissions: values.zonePermissions ? values.zonePermissions : null,
        groupReaderTimeSettings: values.groupReaderTimeSettings
          ? values.groupReaderTimeSettings
          : null,
      };
    } else {
      object = {
        name: values.name,
        companyId: values.company.id,
        description: values.description,
        permissions: values.permissions ? values.permissions : null,
        zonePermissions: values.zonePermissions ? values.zonePermissions : null,
        groupReaderTimeSettings: values.groupReaderTimeSettings
          ? values.groupReaderTimeSettings
          : null,
      };
    }

    setRequestBody(JSON.stringify(object));
  };

  const useStyles = makeStyles(() => ({
    dialog: {
      width: 1550,
      margin: 10,
    },
  }));
  const classes = useStyles();

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      disableEnforceFocus
      classes={{ paper: classes.dialog }}
    >
      <DialogTitleStyled title={t("group")} />
      <PopupContentGroups rowId={rowId} onSubmit={onSubmit} />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupGroups;
