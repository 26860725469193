import TwoColumnsRowsContainer from "../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import Row from "../../../helpersComponents/PopupHelpers/row";
import RowTitle from "../../../helpersComponents/PopupHelpers/rowTitle";
import RowInput from "../../../helpersComponents/PopupHelpers/rowInput";
import SelectFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/SelectField/selectFIeld";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "../../../../reducers/session";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { HolidayRequestTypeFunction } from "../../../../enums/holidayRequestTypeFunction";
import { toUpperFirstLetter } from "../../../../HelpersFunctions/stringUtils";
import HomeOfficeDayItem from "./HomeOfficeDayItem";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";

const AddHomeOfficePopupContent: React.FC<any> = ({
  control,
  removeDay,
  handleSubmit,
  setValue,
  watch,
  getValues,
  closePopup,
  setErrorMessage,
}) => {
  const { t } = useTranslation();
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const [validationErrors, setValidationErrors] = useState<
    IHomeOfficeRequestsErrors[]
  >([]);

  const modifyTypesOfRequestsResponseCallback = useCallback((responseData) => {
    let allowedRequestsTypeFunctions = [
      HolidayRequestTypeFunction.HOME_OFFICE,
      HolidayRequestTypeFunction.OCCASIONAL_HOME_OFFICE,
      HolidayRequestTypeFunction.PRIVILEGED_HOME_OFFICE,
    ];

    return responseData.filter((el) =>
      allowedRequestsTypeFunctions.includes(el.function)
    );
  }, []);

  const [requestsTypes, fetchingStateRequestsTypes, fetchAgainRequestsTypes] =
    useFetchAndSetGET({
      path: `worker-time/${authUserId}/holiday-requests-types`,
      startFetchOnInitial: true,
      modifyResponseCallback: modifyTypesOfRequestsResponseCallback,
    });

  const [
    homeOfficeAddresses,
    fetchingStateHomeOfficeAddresses,
    fetchAgainHomeOfficeAddresses,
  ] = useFetchAndSetGET({
    path: `worker-time/${authUserId}/home-office-addresses`,
    startFetchOnInitial: true,
  });

  const successCallbackAndClose = useCallback(() => {
    closePopup();
  }, [closePopup]);

  const errorCallback = useCallback(
    (response) => {
      if (response?.resJson?.errors.HomeOfficeErrors) {
        let errorsObjects: IHomeOfficeRequestsErrors[] = [];
        let limitExceeded: boolean = false;

        for (
          let i = 0;
          i < response?.resJson?.errors.HomeOfficeErrors.length;
          i++
        ) {
          let errorObj = JSON.parse(
            response?.resJson?.errors.HomeOfficeErrors[i]
          ) as IHomeOfficeRequestsErrors;

          if (errorObj) {
            errorsObjects.push(errorObj);
          }
        }

        if (limitExceeded) {
          setErrorMessage(t("LIMIT_EXCEEDED"));
        } else if (errorsObjects.length > 0) {
          setErrorMessage(t("REQUESTS_DAYS_VALIDATION_ERRORS"));
        }

        setValidationErrors(errorsObjects);
      }
    },
    [t, setErrorMessage]
  );

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [, fetchAgainPostData] = useFetchOtherThanGET({
    path: `worker-time/${authUserId}/home-office-requests`,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    successCallback: successCallbackAndClose,
    errorCallback: errorCallback,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  watch("days");

  const onSubmit = (data: any) => {
    let jsonDataToSend: any = {
      requestTypeId: data.requestType.id,
      homeOfficeAddressId: data.homeOfficeAddress.id,
      days: data.days,
    };

    setBodyRequest(JSON.stringify(jsonDataToSend));
  };

  return (
    <LoadingWrapper
      isLodadingProgress={
        fetchingStateRequestsTypes.isFetching ||
        fetchingStateHomeOfficeAddresses.isFetching
      }
      isError={
        fetchingStateRequestsTypes.isError ||
        fetchingStateHomeOfficeAddresses.isError
      }
      setIfFetchAgain={() => {
        if (fetchingStateRequestsTypes.isError) {
          fetchAgainRequestsTypes();
        }

        if (fetchingStateHomeOfficeAddresses.isError) {
          fetchAgainHomeOfficeAddresses();
        }
      }}
    >
      <form
        key={"add_holiday_request_form"}
        onSubmit={handleSubmit(onSubmit)}
        id="submitAddHomeOfficeRequestForm"
        noValidate
      >
        <TwoColumnsRowsContainer>
          <Row>
            <RowTitle width={"100px"}>{t("request_type")}:</RowTitle>
            <RowInput>
              <SelectFieldReactHookForm
                name={"requestType"}
                control={control}
                label={t("select_request_type")}
                required={true}
                options={requestsTypes || []}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                getOptionSelected={(option, value) => {
                  return option.id === value.id;
                }}
                setValue={setValue}
                watch={watch}
              />
            </RowInput>
          </Row>
        </TwoColumnsRowsContainer>

        <TwoColumnsRowsContainer>
          <Row>
            <RowTitle width={"100px"}>{t("homeOfficeAddress")}:</RowTitle>
            <RowInput>
              <SelectFieldReactHookForm
                name={"homeOfficeAddress"}
                control={control}
                label={t("select_homeOfficeAddress")}
                required={true}
                options={homeOfficeAddresses || []}
                getOptionLabel={(option) => {
                  return `${option.street} ${option.city}`;
                }}
                getOptionSelected={(option, value) => {
                  return option.id === value.id;
                }}
                setValue={setValue}
                watch={watch}
              />
            </RowInput>
          </Row>
        </TwoColumnsRowsContainer>

        <TwoColumnsRowsContainer>
          <Row>
            <RowTitle width={"100px"}>
              {toUpperFirstLetter(t("days"))}:
            </RowTitle>
            <RowInput>
              {getValues().days?.map((day, index) => {
                return (
                  <HomeOfficeDayItem
                    key={day}
                    day={day}
                    removeDay={() => {
                      if (getValues().days.length > 1) {
                        removeDay(index);
                      }
                    }}
                    validationErrors={validationErrors}
                  />
                );
              })}
            </RowInput>
          </Row>
        </TwoColumnsRowsContainer>
      </form>
    </LoadingWrapper>
  );
};

export default AddHomeOfficePopupContent;
