import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import SelectMultiMenu from "../../../../helpersComponents/SelectMultiMenu";

interface Props {
  timeWorkersIds: number[];
  setTimeWorkersIds: Dispatch<SetStateAction<number[]>>;
  successCallback?: () => void;
  setPopupGenerateEmployeesReportParams?: Dispatch<
    SetStateAction<IGenerateEmployeesReportPopupParams>
  >;
}

const WorkersSelectMenu: React.FC<Props> = ({
  timeWorkersIds,
  setTimeWorkersIds,
  successCallback,
  setPopupGenerateEmployeesReportParams,
}) => {
  const { t } = useTranslation();

  return (
    <SelectMultiMenu
      name={<FontAwesomeIcon icon={faEllipsisH} color="white" />}
      disabled={timeWorkersIds.length === 0}
      profile="superiorTime"
      items={[
        {
          optionName: t("employees_report"),
          onClick: () => {
            if (setPopupGenerateEmployeesReportParams) {
              setPopupGenerateEmployeesReportParams({
                isOpen: true,
                workersIds: timeWorkersIds,
              });
            }
          },
        },
      ]}
    />
  );
};

export default WorkersSelectMenu;
