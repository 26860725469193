import { Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { removeDiacritics } from "../../../../HelpersFunctions/stringUtils";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import AutocompleteFormik from "../../../helpersComponents/FormikInputs/Autocomplete/autocomplete";
import GetCardTagPopupRow from "../../../helpersComponents/GetCardTagPopupRow/getCardTagPopupRow";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../../helpersComponents/PopupHelpers";
import ReadIdCardNumberFormikPopupRow from "../../../helpersComponents/ReadIdCardNumberFormikPopupRow/readIdCardNumberFormikPopupRow";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";

interface IFormikValues {
  idNumber: string;
  cardTag: string;
  permissionZone: PermissionZone | null;
}

interface IProps {
  pathApiLetIn: string;
  isOpen: boolean;
  employee: IListOfEmployeesEmployee;
  permissionZonesPathApi: string;
  onCancelChanges: () => void;
  successCallback: () => void;
}

interface IVerifyOperationalSupportWorker {
  firstName: string;
  lastName: string;
  method?: () => void | null;
}

const LetInEmployeePopup: React.FC<IProps> = ({
  pathApiLetIn,
  isOpen,
  employee,
  permissionZonesPathApi,
  onCancelChanges,
  successCallback,
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();

  const isForeman = useCallback(
    () => employee.position.trim().toLowerCase() === "brygadzista",
    [employee.position]
  );

  /////////////// check if first/last name data match with read id card
  const [errorMessageReadCardId, setErrorMessageReadCardId] = useState("");
  const [readFirstLastName, setReadFirstLastName] = useState<{
    firstName: string;
    lastName: string;
  } | null>(null);
  const getIdCardCallback = useCallback((readCardData) => {
    setReadFirstLastName({
      firstName: readCardData.fields.firstName,
      lastName: readCardData.fields.lastName,
    });
  }, []);

  useEffect(() => {
    if (!employee || readFirstLastName === null) return;
    const readFirstName = removeDiacritics(
      readFirstLastName.firstName.toLocaleLowerCase()
    );
    const readLastName = removeDiacritics(
      readFirstLastName.lastName.toLocaleLowerCase()
    );
    const dbFirstName = removeDiacritics(
      employee.employeeFirstName.toLocaleLowerCase()
    );
    const dbLastName = removeDiacritics(
      employee.employeeLastName.toLocaleLowerCase()
    );

    if (
      !readFirstName.includes(dbFirstName) ||
      !readLastName.includes(dbLastName)
    ) {
      setErrorMessageReadCardId(
        `${t("firstname_lastname_from_card_dont_agree")}, ${t(
          "first_name"
        )}: ${dbFirstName}, ${t("readed")}: ${readFirstName}; ${t(
          "last_name"
        )}: ${dbLastName}, ${t("readed")}: ${readLastName}`
      );
    } else setErrorMessageReadCardId("");
  }, [readFirstLastName, employee, t]);
  /////////////////////////////////////////////////////////////////

  const [errorMessage, setErrorMessage] = useState("");
  const [bodyRequest, setBodyRequest] = useState<false | any>({
    rowId: null,
    body: false,
  });

  const onSubmit = (values: IFormikValues) => {
    setErrorMessage("");

    let body = {
      cardNumber: values?.cardTag,
      idNumber: values?.idNumber,
    };

    if (isForeman() && values.permissionZone?.id) {
      body["permissionZoneId"] = values.permissionZone.id;
    }

    setBodyRequest({
      body: JSON.stringify(body),
    });
  };

  const successCallbackPost = useCallback(() => {
    onCancelChanges();
    successCallback();
  }, [successCallback, onCancelChanges]);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${pathApiLetIn}/${employee.id}`,
    method: "POST",
    body: bodyRequest.body,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackPost,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (isOpen === false) {
      setReadFirstLastName(null);
      setErrorMessage("");
      setErrorMessageReadCardId("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (bodyRequest.body) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  const [
    permissionZones,
    fetchingStatePermissionZones,
    fetchingAgainPermissionZones,
  ] = useFetchAndSetGET({
    path: permissionZonesPathApi,
  });

  const [workerData, setWorkerData] = useState<IVerifyOperationalSupportWorker>(
    {
      firstName: "",
      lastName: "",
      method: () => {},
    }
  );

  const responseCallback = useCallback(
    async (responseData) => {
      if (responseData.isBlockedByEntryFromAnotherCompany) {
        let selectedbuttonId = await confirm({
          text: `${t(
            "are_you_sure_you_want_to_allow_employee_entry_from_another_company"
          )}`,
          buttons: [
            { buttonName: t("let_in_employee"), buttonId: 0 },
            { buttonName: t("cancel"), buttonId: 1 },
          ],
        });
        if ((await selectedbuttonId) === 0) {
          if (workerData?.method) {
            workerData.method();
          }
        }
      } else {
        if (workerData?.method) {
          workerData.method();
        }
      }

      return responseData;
    },
    [confirm, t, workerData]
  );

  const [, , fetchAgainEmployeeVerificationResult] = useFetchAndSetGET({
    path: `reception/verify-operational-support-worker?firstName=${workerData.firstName}&lastName=${workerData.lastName}`,
    startFetchOnInitial: false,
    modifyResponseCallback: responseCallback,
  });

  useEffect(() => {
    if (workerData && workerData.firstName && workerData.lastName) {
      fetchAgainEmployeeVerificationResult();
    }
  }, [workerData, fetchAgainEmployeeVerificationResult]);

  return (
    <>
      <DialogStyled
        open={isOpen}
        onClose={onCancelChanges}
        disableBackdropClick={true}
      >
        <DialogTitleStyled title={t("admitting_guest")} />

        <LoadingWrapper
          isLodadingProgress={fetchingStatePermissionZones.isFetching}
          isError={fetchingStatePermissionZones.isError}
          setIfFetchAgain={() => {
            if (fetchingStatePermissionZones.isError) {
              fetchingAgainPermissionZones();
            }
          }}
        >
          <DialogContentStyled titleWidth={200}>
            <Formik
              initialValues={{
                idNumber: "",
                cardTag: "",
                permissionZone: null,
              }}
              onSubmit={(values) => {
                setWorkerData({
                  firstName: employee.employeeFirstName,
                  lastName: employee.employeeLastName,
                  method: () => onSubmit(values),
                });
              }}
              validationSchema={Yup.object({
                idNumber: Yup.string().required(t("field_required")),
                cardTag: Yup.string().required(t("field_required")),
                permissionZone: Yup.object()
                  .nullable()
                  .test(
                    "permissionZoneTest",
                    t("field_required"),
                    function (value) {
                      if (isForeman() && !value) {
                        return false;
                      }

                      return true;
                    }
                  ),
              })}
            >
              {({ setFieldValue, setFieldError, setFieldTouched, values }) => {
                return (
                  <Form noValidate id="submitForm">
                    <Row>
                      <RowTitle>
                        <span style={{ fontSize: "20px" }}>
                          {t("position")}:
                        </span>
                      </RowTitle>
                      <RowInput>
                        <span style={{ fontSize: "20px" }}>
                          {employee.position}
                        </span>
                      </RowInput>
                    </Row>
                    <ReadIdCardNumberFormikPopupRow
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                      name={"idNumber"}
                      onChangeExtra={() => {
                        if (readFirstLastName !== null) {
                          setReadFirstLastName(null);
                          setErrorMessageReadCardId("");
                        }
                      }}
                      successCallback={getIdCardCallback}
                      startListeningInit={
                        true
                        //employee?.employeeIDNumber ? false : true
                      }
                      required={true}
                    />
                    <GetCardTagPopupRow
                      setFieldValue={setFieldValue}
                      name={"cardTag"}
                      isPopupOpen={isOpen}
                      mask="****************"
                      componentId="reception/lists-of-employees"
                      required={true}
                    />

                    {isForeman() && (
                      <Row>
                        <RowTitle>{t("permission_zone")}:</RowTitle>
                        <RowInput>
                          <AutocompleteFormik
                            options={permissionZones}
                            getOptionLabel={(option: PermissionZone) => {
                              return option.name;
                            }}
                            width="100%"
                            getOptionSelected={(
                              option: PermissionZone,
                              value: PermissionZone
                            ) => {
                              return option.id === value.id;
                            }}
                            label={t("select_permission_zone")}
                            name="permissionZone"
                            required={true}
                          />
                        </RowInput>
                      </Row>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </DialogContentStyled>
        </LoadingWrapper>
        <DialogActionsStyled
          typConfirm="submit"
          formConfirm="submitForm"
          onCancel={onCancelChanges}
          isLoading={fetchingStatePostData.isFetching}
          errorString={`${errorMessage} ${errorMessageReadCardId}`}
        />
      </DialogStyled>
    </>
  );
};

export default LetInEmployeePopup;
