import { selectNumberUnreadMessages } from "../../../reducers/checkIfNewMessages";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";
import MultipleLinksTemplate from "../../Navbar/multipleLinksTemplate";
import { SingleLink } from "../../Navbar/singleLink";
import useNavLinksArray from "./navLinksArray";

const Navigation = () => {
  const numberUnreadMessages = useAppSelector(selectNumberUnreadMessages);
  const settings = useAppSelector(selectSettings);
  const navLinksArray = useNavLinksArray();

  if (settings.ifSettingsFetched === false) return <div></div>;
  return (
    <>
      <div className="navigation">
        {navLinksArray.routesArrayWithComponents.map((linkInfo) => {
          if (linkInfo.ifHidden) return null;
          if (!linkInfo.singleLink)
            return (
              <MultipleLinksTemplate
                name={linkInfo.name}
                links={linkInfo.path}
                key={linkInfo.name}
              />
            );
          let numberUnreadMessagesLocal = null;
          if (linkInfo.path === "/worker-time/messages") {
            numberUnreadMessagesLocal = numberUnreadMessages;
          }
          return (
            <SingleLink
              key={linkInfo.path}
              path={linkInfo.path}
              name={linkInfo.name}
              numberUnreadMessages={numberUnreadMessagesLocal}
            />
          );
        })}
      </div>
    </>
  );
};
export default Navigation;
