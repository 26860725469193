import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput as MuiOutlinedInput,
  Select,
  withStyles,
} from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.2 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 7,
    horizontal: 0,
  },
};
const OutlinedInput = withStyles((theme) => ({
  notchedOutline: {
    borderColor: "#676a6c !important",
    marginTop: 2,
  },
}))(MuiOutlinedInput);

const SelectTargetCompany = ({
  companies,
  selectedCompany,
  setSelectedCompany,
}: {
  companies: any;
  selectedCompany: TargetCompany | null;
  setSelectedCompany: Dispatch<SetStateAction<TargetCompany>>;
}) => {
  const { t } = useTranslation();

  const handleChangeTargetCompany = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const targetCompany = JSON.parse(
      event.target.value as string
    ) as TargetCompany;
    if (targetCompany.name === selectedCompany?.name)
      setSelectedCompany({
        name: "",
      });
    else {
      setSelectedCompany(targetCompany);
    }
  };

  return (
    <div className={styles.selectZoneContainer}>
      <FormControl variant="outlined">
        <InputLabel id="select-zone" style={{ color: "#1ab394" }}>
          {t("target_company")}
        </InputLabel>
        <Select
          labelId="select-target-company"
          value={selectedCompany}
          onChange={handleChangeTargetCompany}
          input={<OutlinedInput label={t("target_company")} />}
          renderValue={(selected: any) => {
            return selected.name;
          }}
          MenuProps={MenuProps}
          style={{ minWidth: "220px" }}
        >
          {companies.map((targetCompany: TargetCompany) => (
            <MenuItem
              key={targetCompany.name}
              value={JSON.stringify(targetCompany)}
              autoFocus={
                targetCompany.name === selectedCompany?.name ? true : false
              }
            >
              <ListItemText primary={targetCompany.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectTargetCompany;
