import { CircularProgress } from "@material-ui/core";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import Button from "../../helpersComponents/Button/button";
import { TextFieldFormik } from "../FormikInputs";
import CheckboxWithLabelFormik from "../FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import { RowInput, RowTitle } from "../PopupHelpers";
import Row from "../PopupHelpers/row";
import styles from "./styles.module.scss";

interface Props {
  companiesInPortsPathApi: string;
  setShowAddCompanyForm: React.Dispatch<React.SetStateAction<boolean>>;
  setAddedCompanyId: React.Dispatch<React.SetStateAction<number | false>>;
  addedCompanyId: number | false;
  companies: ICompanyInPorts[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  onlyGarbageCompany?: boolean;
}

const FormAddNewCompany: React.FC<Props> = ({
  companiesInPortsPathApi,
  setShowAddCompanyForm,
  setAddedCompanyId,
  addedCompanyId,
  companies,
  setFieldValue,
  onlyGarbageCompany,
}) => {
  const { t } = useTranslation();

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: companiesInPortsPathApi,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  const confirm = (values: AddNewComapny) => {
    const object = {
      name: values.companyName,
      shortName: "",
      type: values.typeDelivery ? 0 : 1,
      description: null,
    };

    setBodyRequest(JSON.stringify(object));
  };

  useEffect(() => {
    if (addedCompanyId && companies) {
      let foundCompany = companies.find(
        (company: ICompanyInPorts) => company.id === addedCompanyId
      );
      if (foundCompany) {
        setFieldValue("company", foundCompany);
        setAddedCompanyId(false);
        setShowAddCompanyForm(false);
      }
    }
  }, [
    addedCompanyId,
    companies,
    setFieldValue,
    setAddedCompanyId,
    setShowAddCompanyForm,
  ]);

  useEffect(() => {
    if (fetchingStatePostData.response?.status === 201) {
      if (fetchingStatePostData.response.location) {
        let id = fetchingStatePostData.response.location.substring(
          fetchingStatePostData.response.location.lastIndexOf("/") + 1
        );
        setAddedCompanyId(parseInt(id));
      }
    }
  }, [fetchingStatePostData.response, setAddedCompanyId]);

  return (
    <div className={styles.innerForm}>
      <Formik
        initialValues={{
          typeDelivery: !onlyGarbageCompany ? true : false,
          typeGarbageDisposal: onlyGarbageCompany ? true : false,
          companyName: "",
        }}
        onSubmit={(values) => {
          confirm(values);
        }}
        validationSchema={Yup.object({
          companyName: Yup.string()
            .nullable()
            .required(t("field_required"))
            .test("isNameExists", t("company_name_exists"), (value) => {
              return value === companies.find((c) => c.name === value)?.name
                ? false
                : true;
            }),
        })}
      >
        {(formikProps) => {
          return (
            <div id="submitForm">
              {!onlyGarbageCompany && (
                <Row>
                  <RowTitle>{t("type")}:</RowTitle>
                  <RowInput>
                    <CheckboxWithLabelFormik
                      name={"typeDelivery"}
                      ifCircle={true}
                      label={<span>{t("delivery_man")}</span>}
                      onChangeExtra={(e) => {
                        if (formikProps.values.typeGarbageDisposal === false) {
                          formikProps.setFieldValue(
                            "typeGarbageDisposal",
                            true
                          );
                        } else {
                          formikProps.setFieldValue(
                            "typeGarbageDisposal",
                            false
                          );
                        }
                      }}
                    />

                    <CheckboxWithLabelFormik
                      name={"typeGarbageDisposal"}
                      ifCircle={true}
                      label={<span>{t("garbage_disposal_company")}</span>}
                      onChangeExtra={(e) => {
                        if (formikProps.values.typeDelivery === false) {
                          formikProps.setFieldValue("typeDelivery", true);
                        } else {
                          formikProps.setFieldValue("typeDelivery", false);
                        }
                      }}
                    />
                  </RowInput>
                </Row>
              )}

              <Row>
                <RowTitle>{t("name")}:</RowTitle>
                <RowInput>
                  <TextFieldFormik
                    ifToUpperCaseShipsAndPorts={true}
                    label={t("name")}
                    name="companyName"
                    type="text"
                    required={true}
                  />
                </RowInput>
              </Row>

              <Row>
                <RowTitle>
                  {!fetchingStatePostData.isFetching ? (
                    <>
                      <div className={styles.containerAddShipButton}>
                        <Button
                          className={styles.buttonAddShip}
                          onClick={() => formikProps.submitForm()}
                          type="button"
                        >
                          {t("add_company")}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div className="confirmWaiting">
                      <CircularProgress size={25} />
                    </div>
                  )}
                </RowTitle>
                {errorMessage && errorMessage !== "" && (
                  <RowInput style={{ color: "red" }}>{errorMessage}</RowInput>
                )}
              </Row>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default FormAddNewCompany;
