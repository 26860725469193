import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useRefreshData from "../../../../hooks/useRefreshData/useRefreshData";
import { selectSettings } from "../../../../reducers/settings";
import { useAppSelector } from "../../../../store/hooks";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import "./events.scss";
import PopupEvents from "./PopupEvents";
import RowDetail from "./rowDetail";

function EventsContent() {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date>(
    getBeginningOfDate(new Date(), "Day")
  );
  const [endDate, setEndDate] = useState<Date>(getEndOfDate(new Date(), "Day"));

  const eventsResponseCallback = useCallback((responseData) => {
    const modified = responseData?.map((row: Event) => {
      return {
        ...row,
        department: row.department ? row.department : "-",
        roomName: row.roomName ? row.roomName : "-",
        workerName: row.firstName + " " + row.lastName,
      };
    });
    return modified;
  }, []);

  const [eventsRows, fetchingStateEvents, fetchAgainEvents] = useFetchAndSetGET(
    {
      path:
        `superior-ac/events?DateFrom=` +
        computeDateRangeFetch(startDate) +
        "&DateTo=" +
        computeDateRangeFetch(endDate),
      modifyResponseCallback: eventsResponseCallback,
      startFetchOnInitial: false,
    }
  );

  const [eventIdToRemove, setEventIdToRemove] = useState<false | number>(false);

  const [fetchingState, fetchAgain] = useFetchOtherThanGET({
    path: `superior-ac/events/${eventIdToRemove}`,
    method: "DELETE",
    body: eventIdToRemove,
    setBody: setEventIdToRemove,
    successCallback: fetchAgainEvents,
  });

  useEffect(() => {
    if (eventIdToRemove !== false) {
      fetchAgain();
    }
  }, [eventIdToRemove, fetchAgain]);

  const settings = useAppSelector(selectSettings);

  const columns = [
    { name: "time", title: t("date_and_time"), type: "date_hours" },
    { name: "typeName", title: t("the_type_of_the_event") },
    { name: "workerName", title: t("worker") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "department", title: t("department") },
    { name: "roomName", title: t("room") },
    { name: "relay", title: t("relay_opened") },
  ];

  useEffect(() => {
    fetchAgainEvents();
  }, [startDate, endDate, fetchAgainEvents]);

  const { refreshingTime, handleChangeRefreshingTime } =
    useRefreshData(fetchAgainEvents);

  return (
    <>
      <TopBanner pathName={t("events")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date", "hours", "minutes"]}
            format="dd-MM-yyyy HH:mm"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            keyboardDateTimePicker={true}
            darkTheme={true}
            setDate2={setEndDate}
          ></DatePickerRange>
        </div>
        <div>
          <TableComponent
            rows={eventsRows}
            columns={columns}
            layoutSettingName="layoutSuperiorAcListsEvents"
            actionsColumnUpdated={{
              popup: PopupEvents,
              successCallbackPopup: fetchAgainEvents,
              addButton: {
                ifShow:
                  settings?.WebDodawanieZdarzenPrzezPrzelozonego?.value === "1",
              },
              editButton: {
                ifShow:
                  settings?.WebEdycjaZdarzenPrzezPrzelozonego?.value === "1",
                name: t("edit"),
              },
              removeButton: {
                ifShow:
                  settings?.WebUsuwanieZdarzenPrzezPrzelozonego?.value === "1",
                name: t("delete"),
                onClick: (row) => {
                  setEventIdToRemove(row.id);
                },
              },
            }}
            rowDetail={RowDetail}
            fetchingState={{
              fetchAgain: fetchAgainEvents,
              isError: fetchingStateEvents.isError,
              isFetching:
                fetchingStateEvents.isFetching || fetchingState.isFetching,
            }}
            refreshingButton={{
              ifShow: true,
              refreshingTime: refreshingTime?.value,
              handleChangeRefreshingTime,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default EventsContent;
