import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, FormikErrors } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../../Button/button";
import styles from "../styles.module.scss";
import PasswordPolicyForm from "./PasswordPolicyForm";

interface Props {
  formFields: FormFields;
  formikValues: any;
  formikErrors: FormikErrors<{
    [x: string]: string | boolean | never[] | null;
  }>;
  policiesList: Policy[];
}

const PasswordPoliciesForm = ({
  formFields,
  formikValues,
  formikErrors,
  policiesList,
}: Props) => {
  const { t } = useTranslation();

  const {
    passwordPoliciesArray,
    passwordPolicy,
    passwordPolicyDateFrom,
    passwordPolicyDateTo,
  } = formFields;

  return (
    <>
      <FieldArray
        name={passwordPoliciesArray.name}
        render={(arrayHelpers) => (
          <>
            {formikValues.passwordPoliciesArray.map(
              (passwordPolicy: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <PasswordPolicyForm
                      formFields={formFields}
                      formikErrors={formikErrors}
                      index={index}
                      arrayHelpers={arrayHelpers}
                      policiesList={policiesList}
                    />
                  </React.Fragment>
                );
              }
            )}

            <div className={styles.addPasswordPolicyContainer}>
              <ButtonStyled
                onClick={() =>
                  arrayHelpers.push({
                    [passwordPolicy.name]: null,
                    [passwordPolicyDateFrom.name]: null,
                    [passwordPolicyDateTo.name]: null,
                  })
                }
                type="button"
              >
                {t("add_policy")} <FontAwesomeIcon icon={faPlus} />
              </ButtonStyled>
            </div>
          </>
        )}
      />
    </>
  );
};

export default PasswordPoliciesForm;
