import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControlLabel, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { DaysOfWeek } from "../../../../../../../Constants/daysOfWeek";
import {
  AutocompleteFormik,
  TextFieldFormik,
} from "../../../../../../helpersComponents/FormikInputs";
import CheckboxWithLabelFormik from "../../../../../../helpersComponents/FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import { CheckboxStyled } from "../../../../../../helpersComponents/MaterialUi";
import StyledDataGrid from "../../../../../../helpersComponents/MaterialUi/DataGrid/StyledDataGrid";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../../../../../helpersComponents/PopupHelpers";
import { IAddSpecialPermission } from "./GroupReaderTimeSettingItem";
import styles from "./GroupReaderTimeSettingItem.module.scss";

interface IInitialStateFormValues {
  startTime: string;
  endTime: string;
  daysOfWeek: IDayOfWeek[];
  isHoliday: boolean;
  function: IFunctionType;
  readerId: number | null;
  value: string;
  added: boolean;
}

interface IProps {
  onSubmit: (values: IAddSpecialPermission) => void;
  readersList: IReader[];
  functions: IFunctionType[];
}

const GroupReaderTimeSettingItemContent: React.FC<IProps> = ({
  onSubmit,
  readersList,
  functions,
}) => {
  const { t } = useTranslation();

  const readersColumns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: t("serial_number"),
      flex: 1,
      renderCell: (params) => <span>{params.row.serialNumber}</span>,
    },
    {
      field: "roomName",
      headerName: t("room"),
      flex: 1,
      renderCell: (params) => <span>{params.row.roomName}</span>,
    },
    {
      field: "building",
      headerName: t("building"),
      flex: 1,
      renderCell: (params) => <span>{params.row.buildingName}</span>,
    },
    {
      field: "portName",
      headerName: t("port"),
      flex: 1,
      renderCell: (params) => <span>{params.row.portName}</span>,
    },
  ];

  const daysOfWeek = DaysOfWeek(t);

  const initialFormValues: IInitialStateFormValues = {
    readerId: null,
    startTime: "00:00",
    endTime: "23:59",
    daysOfWeek: [],
    function: functions[0],
    isHoliday: false,
    added: true,
    value: "",
  };

  const [selectAllDays, setSelectAllDays] = useState(false);
  const handleSelectAllDays = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const checked = event.target.checked;
    setSelectAllDays(checked);
    if (checked) {
      setFieldValue("daysOfWeek", daysOfWeek);
    } else {
      setFieldValue("daysOfWeek", []);
    }
  };

  return (
    <DialogContentStyled>
      <Formik
        initialValues={initialFormValues}
        onSubmit={(values: IInitialStateFormValues) => {
          onSubmit(values);
        }}
        validationSchema={Yup.object({
          readerId: Yup.number().nullable().required(t("field_required")),
          startTime: Yup.string().nullable().required(t("field_required")),
          endTime: Yup.string()
            .nullable()
            .required(t("field_required"))
            .test(
              "isValidEndTime",
              t("time_to_must_be_greater_than_the_time_from"),
              function (value) {
                const { startTime } = this.parent;
                if (value && startTime) {
                  return startTime <= value;
                }

                return true;
              }
            ),
          daysOfWeek: Yup.array().min(1, t("field_required")),
          function: Yup.object().nullable().required(t("field_required")),
          value: Yup.string().required(t("field_required")),
        })}
      >
        {(formikProps) => {
          return (
            <Form noValidate id="groupReaderTimeSettingForm">
              <div className={styles.rootContainer}>
                {/* Readers */}
                <div className={styles.readersContainer}>
                  <div className={styles.readersGridTitleContainer}>
                    <p className={styles.readersGridTitle}>{t("readers")}*</p>
                    {formikProps.errors["readerId"] && (
                      <Tooltip title={t("field_required")!}>
                        <div>
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            color="red"
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <StyledDataGrid
                    rows={readersList}
                    columns={readersColumns}
                    defaultPageSize={10}
                    rowsPerPageOptions={[10]}
                    density="compact"
                    quickFilter={true}
                    onSelectionModelChange={(selected) => {
                      formikProps.setFieldValue(
                        "readerId",
                        selected[0] as number
                      );
                    }}
                  />
                </div>

                {/*  */}
                <div className={styles.formContainer}>
                  <Row>
                    <RowTitle width={220}>
                      {t("permission_start_time")}:
                    </RowTitle>
                    <RowInput>
                      <TextFieldFormik
                        name="startTime"
                        hiddenLabel
                        type="time"
                      />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle width={220}>{t("permission_end_time")}:</RowTitle>
                    <RowInput>
                      <TextFieldFormik name="endTime" hiddenLabel type="time" />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle width={220}>{t("operation")}:</RowTitle>
                    <RowInput>
                      <AutocompleteFormik
                        options={functions}
                        name="function"
                        getOptionLabel={(option: {
                          id: number;
                          name: string;
                        }) => {
                          return option.name;
                        }}
                        width="100%"
                        getOptionSelected={(
                          option: IFunctionType,
                          value: IFunctionType
                        ) => {
                          return option.id === value.id;
                        }}
                        label={t("select")}
                        required={true}
                      />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle width={220}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {t("day_of_the_week")}:
                        <FormControlLabel
                          control={
                            <CheckboxStyled
                              ifCircle={true}
                              checked={selectAllDays}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                handleSelectAllDays(
                                  event,
                                  formikProps.setFieldValue
                                )
                              }
                            />
                          }
                          label={<span>{t("all_days")}</span>}
                        />
                      </div>
                    </RowTitle>
                    <RowInput>
                      <AutocompleteFormik
                        options={daysOfWeek}
                        name="daysOfWeek"
                        getOptionLabel={(option: {
                          value: number;
                          name: string;
                        }) => {
                          return option.name;
                        }}
                        width="100%"
                        getOptionSelected={(
                          option: IDayOfWeek,
                          value: IDayOfWeek
                        ) => {
                          return option.value === value.value;
                        }}
                        label={t("select")}
                        required={true}
                        multiple
                        disableCloseOnSelect
                        filterSelectedOptions
                        ListboxProps={{ style: { maxHeight: "25rem" } }}
                      />
                    </RowInput>
                  </Row>

                  <Row>
                    <RowTitle width={220}>
                      <CheckboxWithLabelFormik
                        name="isHoliday"
                        ifCircle={true}
                        label={
                          <span style={{ fontSize: "0.9rem" }}>
                            {t("or_on_holiday")}
                          </span>
                        }
                      />
                    </RowTitle>
                  </Row>

                  <Row>
                    <RowTitle width={220}>{t("value")}:</RowTitle>
                    <RowInput>
                      <TextFieldFormik
                        label={t("value")}
                        name="value"
                        type="text"
                        onChangeOwn={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const value = e.target.value;
                          if (isNaN(+value)) return;

                          formikProps.setFieldValue("value", value);
                        }}
                      />
                    </RowInput>
                  </Row>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </DialogContentStyled>
  );
};

export default GroupReaderTimeSettingItemContent;
