import ContentPerformer from "./ContentPerformer";

function Performer() {
  return (
    <div>
      <ContentPerformer />
    </div>
  );
}

export default Performer;
