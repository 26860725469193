import { PolicyError } from "../../../../enums/policyError";

export const validatePasswordWithPolicy = (
  newPassword: string | undefined,
  policy: Policy | null
): PolicyError[] => {
  let result: PolicyError[] = [];

  if (newPassword && policy) {
    if (newPassword.length < policy.passwordLength) {
      result.push(PolicyError.TooShort);
    }

    if (policy.isUpperCase && !(newPassword.toLowerCase() !== newPassword)) {
      result.push(PolicyError.NoUpperCaseCharacter);
    }

    if (policy.isLowerCase && !(newPassword.toUpperCase() !== newPassword)) {
      result.push(PolicyError.NoLowerCaseCharacter);
    }

    if (policy.isDigit && !passwordHasNumber(newPassword)) {
      result.push(PolicyError.NoDigit);
    }

    if (
      policy.isSpecialCharacter &&
      !passwordHasSpecialcharacter(newPassword)
    ) {
      result.push(PolicyError.NoSpecialCharacter);
    }
  }

  return result;
};

const passwordHasNumber = (password: string): boolean => {
  const numbers = /\d/;
  return numbers.test(password);
};

const passwordHasSpecialcharacter = (password: string): boolean => {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return specialChars.test(password);
};
