import React, { useState, useEffect, useCallback, useMemo } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./delegationsCities.scss";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import AutocompleteFormik from "../../../helpersComponents/FormikInputs/Autocomplete/autocomplete";
import { TextFieldFormik } from "../../../helpersComponents/FormikInputs";
import { DialogContentStyled } from "../../../helpersComponents/PopupHelpers/dialogContentStyled";
import { DialogActionsStyled } from "../../../helpersComponents/PopupHelpers";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { AddUpdateDelegationCity, DelegationCity } from "./type";

interface IProps {
  row: City | undefined;
  isOpen: boolean;
  closePopup: () => void;
  successCallback: () => void;
}

const Popup: React.FC<IProps> = ({
  row,
  isOpen,
  closePopup,
  successCallback,
}) => {
  const { t } = useTranslation();
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [requestBody, setRequestBody] = useState<false | any>(false);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const [countries, countriesFetchingState, countriesFetchAgain] =
    useFetchAndSetGET({
      path: `worker-time/${authUserId}/countries`,
      startFetchOnInitial: false,
    });

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `worker-time/${authUserId}/cities${
      row && row.id ? `/${row.id}` : ""
    }`,
    method: row && row.id ? "PUT" : "POST",
    contentType: "application/json",
    body: requestBody,
    setBody: setRequestBody,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    countriesFetchAgain();
  }, [countriesFetchAgain]);

  useEffect(() => {
    if (requestBody) {
      fetchAgainPostData();
    }
  }, [requestBody, setRequestBody, fetchAgainPostData]);

  let initialValues: DelegationCity = useMemo(() => {
    return {
      country: null,
      city: row && row.id ? row.name : "",
      order: row && row.id ? row.order : 1000,
    };
  }, [row]);

  useEffect(() => {
    if (!countriesFetchingState.isFetching && row && row.id) {
      if (countries) {
        initialValues.country = countries.find((el) => el.id === row.countryId);
      }
    }
  }, [
    row,
    countriesFetchingState,
    countriesFetchingState.isFetching,
    countries,
    initialValues,
  ]);

  return (
    <Dialog
      open={isOpen}
      onClose={closePopup}
      TransitionProps={{
        onExited: () => {},
        onEnter: () => {},
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className="dialogTitleGlobal">
        <div className="titlePopup">{t("delegations_city")}</div>
        <div className="description">{t("adding_delegations_city")}</div>
      </DialogTitle>

      <DialogContentStyled>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(value: DelegationCity) => {
            if (value && value.country) {
              let obj: AddUpdateDelegationCity = {
                name: value.city,
                order: value.order,
                countryId: value.country.id,
              };

              if (row && row.id) {
                obj["id"] = row.id;
              }

              setRequestBody(JSON.stringify(obj));
            }
          }}
          validationSchema={Yup.object({
            country: Yup.object().nullable().required(),
            city: Yup.string().required(),
            order: Yup.number().required(),
          })}
        >
          {(formikProps) =>
            !countriesFetchingState.isFetching ? (
              <Form id="submitForm">
                <div className="popupContentDeleCities">
                  <div className="imputTextDeleCities">
                    <div className="titleWidthDeleCities">{t("country")}:</div>
                    <div className="inputWidthDeleCities">
                      <AutocompleteFormik
                        name="country"
                        options={countries}
                        getOptionLabel={(option: Country) => {
                          return t(option.name);
                        }}
                        getOptionSelected={(
                          option: Country,
                          value: Country
                        ) => {
                          return option.id === value.id;
                        }}
                        style={{ width: 250 }}
                        label={t("select_country")}
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="imputTextDeleCities">
                    <div className="titleWidthDeleCities">{t("city")}:</div>
                    <div>
                      <TextFieldFormik
                        ifToUpperCaseShipsAndPorts={true}
                        label={t("city")}
                        name="city"
                        defaultValue=""
                        style={{ width: 250 }}
                        type="string"
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="imputTextDeleCities">
                    <div className="titleWidthDeleCities">{t("order")}:</div>
                    <div>
                      <TextFieldFormik
                        ifToUpperCaseShipsAndPorts={true}
                        label={t("order")}
                        name="order"
                        defaultValue={1000}
                        style={{ width: 250 }}
                        type="number"
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            ) : (
              <div className="circularProgress">
                <CircularProgress />
              </div>
            )
          }
        </Formik>
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </Dialog>
  );
};

export default Popup;
