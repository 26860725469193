import AutocompleteStyled from "../../../../MaterialUi/Autocomplete/autocomplete";
import styles from "./styles.module.scss";
import useFetchAndSetGET from "../../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { useAppSelector } from "../../../../../../store/hooks";
import { selectAuthUser } from "./../../../../../../reducers/session";
import { useCallback, useEffect, useState } from "react";
import useFetchOtherThanGET from "../../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { BuiltInDictionaryType } from "../../../../../../enums/builtInDictionaryType";
import { BuiltInDictionaryParamType } from "../../../../../../enums/builtInDictionaryParamType";
import { ProfileType } from "../../../../../../enums/profileType";
import { useTranslation } from "react-i18next";

const ElasticFormSelectionList: React.FC<SelectionListHTML> = ({
  name,
  label,
  isRequired,
  dictionary,
  readonly,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  timeWorkerId,
}) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const [dictionaryPath, setDictionaryPath] = useState<string>("");
  const [dictionaryData, setDictionaryData] = useState<DictionaryItem[]>([]);
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;

  const customDictionaryResponseCallback = useCallback(
    (responseData: CustomDictionary) => {
      let tempResult: DictionaryItem[] = [];

      responseData.items.forEach((el) => {
        if (el.valueStr) {
          tempResult.push({ name: el.name, value: el.valueStr });
        } else if (el.valueInt) {
          tempResult.push({ name: el.name, value: el.valueInt.toString() });
        } else if (el.valueFloat) {
          tempResult.push({ name: el.name, value: el.valueFloat.toString() });
        }
      });

      setDictionaryData(tempResult);
      return responseData;
    },
    []
  );

  const builtInDictionarySuccessCallback = useCallback(
    (response: UseFetchResponse) => {
      let builtInDictionary: BuiltInDictionary = response.resJson;
      let tempResult: DictionaryItem[] = [];

      if (builtInDictionary) {
        builtInDictionary.items.forEach((el: BuiltInDictionaryItem) => {
          tempResult.push({ name: el.name, value: el.value });
        });

        setDictionaryData(tempResult);
      }
    },
    []
  );

  const [, , fetchAgainCustomDictionary] = useFetchAndSetGET({
    path: dictionaryPath,
    modifyResponseCallback: customDictionaryResponseCallback,
    startFetchOnInitial: false,
  });

  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const [, fetchAgainPostBuiltInDictionary] = useFetchOtherThanGET({
    path: dictionaryPath,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
    successCallback: builtInDictionarySuccessCallback,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostBuiltInDictionary();
    }
  }, [bodyRequest, fetchAgainPostBuiltInDictionary]);

  useEffect(() => {
    if (dictionary) {
      if (isNaN(parseInt(dictionary))) {
        setDictionaryPath(
          profileType === ProfileType.WORKER_TIME
            ? `worker-time/${authUser.currentProfile?.subjectId}/custom-dictionaries/${dictionary}`
            : `superior-time/custom-dictionaries/${dictionary}`
        );
      } else {
        setDictionaryPath(
          profileType === ProfileType.WORKER_TIME
            ? `worker-time/${authUser.currentProfile?.subjectId}/built-in-dictionaries`
            : `superior-time/built-in-dictionaries`
        );
      }
    }
  }, [
    authUser.currentProfile.subjectId,
    authUser.currentProfile?.type,
    dictionary,
    fetchAgainCustomDictionary,
    profileType,
  ]);

  useEffect(() => {
    if (dictionaryPath) {
      if (dictionaryPath.includes("custom-dictionaries")) {
        fetchAgainCustomDictionary();
      } else if (dictionaryPath.includes("built-in-dictionaries")) {
        let dictionaryId = parseInt(dictionary);

        if (
          dictionaryId ===
            BuiltInDictionaryType.AC_SUPERIOR_ACCEPTING_REQUEST &&
          (authUser.currentProfile?.type === ProfileType.WORKER_TIME ||
            authUser.currentProfile?.type === ProfileType.SUPERIOR_TIME)
        ) {
          let dataToSend: BuiltInDictionaryQuery = {
            type: BuiltInDictionaryType.AC_SUPERIOR_ACCEPTING_REQUEST,
            params: [],
          };

          dataToSend.params.push({
            type: BuiltInDictionaryParamType.TIME_WORKER_ID,
            value:
              timeWorkerId && timeWorkerId > 0
                ? timeWorkerId.toString()
                : authUser.currentProfile.type === ProfileType.WORKER_TIME
                ? authUser.currentProfile.subjectId.toString()
                : "-1",
          });

          setBodyRequest(JSON.stringify(dataToSend));
        }
      }
    }
  }, [
    dictionaryPath,
    authUser.currentProfile.subjectId,
    authUser.currentProfile?.type,
    dictionary,
    fetchAgainCustomDictionary,
    timeWorkerId,
  ]);

  const [selectedItem, setSelectedItem] = useState<DictionaryItem | null>();

  useEffect(() => {
    if (dictionaryData && formValues[name]) {
      let foundObj = dictionaryData.find(
        (el) => el.value.toString() === formValues[name].toString()
      );
      if (foundObj) {
        setSelectedItem(foundObj);
      } else {
        setSelectedItem(null);
      }
    }
  }, [dictionaryData, formValues, name]);

  return (
    <>
      <div className={styles.inputContainer}>
        <div className={styles.inputTitle}>{label}:</div>
        <div className={styles.inputField}>
          {dictionaryData && (
            <AutocompleteStyled
              disabled={readonly}
              options={dictionaryData}
              getOptionLabel={(option: DictionaryItem) => {
                if (typeof option === "string") {
                  return "TEST";
                }

                return option.name;
              }}
              getOptionSelected={(option, value) =>
                option.value.toString() === value.value.toString()
              }
              value={selectedItem ? selectedItem : null}
              width={"100%"}
              isError={formErrors[name]}
              onChange={(_, newValue) => {
                setSelectedItem(newValue);

                setFormValues((prev) => {
                  let objToRet = { ...prev };
                  objToRet[name] = newValue?.value ? newValue.value : undefined;

                  return objToRet;
                });
              }}
              label={label}
              required={isRequired}
              onBlur={(e) => {
                if (isRequired && e.target.value === "") {
                  setFormErrors((prev) => {
                    let objToRet = { ...prev };
                    objToRet[name] = t("field_required");
                    return objToRet;
                  });
                } else {
                  setFormErrors((prev) => {
                    let objToRet = { ...prev };
                    objToRet[name] = "";
                    return objToRet;
                  });
                }
              }}
            />
          )}
          {readonly && isRequired && formErrors[name] && (
            <div className={styles.errorMessage}>{formErrors[name]}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default ElasticFormSelectionList;
