import { useEffect, useState } from "react";
import {
  selectBuildings,
  selectBuildingsGroups,
  selectBuildingsGroupsAndBuildingsLoaded,
} from "../../../reducers/sms/visualizationData";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faLayerGroup,
  faMapMarked,
  faObjectGroup,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

function createMenuItemsTreePath(
  menuData: AdvancedNavbarMenuItem[],
  parentAddress = ""
) {
  menuData.forEach((item, index) => {
    const currentAddress =
      parentAddress + (index + 1).toString().padStart(3, "0");

    item.path = currentAddress;

    if (item.submenu && Array.isArray(item.submenu)) {
      createMenuItemsTreePath(item.submenu, currentAddress);
    }
  });
}

const enableIcon: boolean = true;

const useSMSUserMenuBuilder = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);
  const buildingsGroups = useAppSelector(selectBuildingsGroups);
  const buildings = useAppSelector(selectBuildings);
  const buildingsDataLoaded = useAppSelector(
    selectBuildingsGroupsAndBuildingsLoaded
  );

  const [menuData, setMenuData] = useState<AdvancedNavbarMenuItem[]>();
  const [menuDataLoaded, setMenuDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!settings.ifSettingsFetched || !buildingsDataLoaded) {
      setMenuData([]);
      return;
    }

    if (menuDataLoaded) {
      return;
    }

    let result: AdvancedNavbarMenuItem[] = [];

    result.push({
      icon: enableIcon ? <FontAwesomeIcon icon={faUser} /> : null,
      title: t("employee_profile"),
      url: "/sms-user/profile",
    });

    // Dodajemy pozycję z mapami budynków jeśli nie ukryto
    if (settings.WebSMSUserUkryjMapeBudynkow?.value !== "1") {
      result.push({
        icon: enableIcon ? <FontAwesomeIcon icon={faMapMarked} /> : null,
        title: t("buildings_map"),
        url: "/sms-user/buildings-map",
      });
    }

    /*
      Tworzymy pustą kolekcję na grupy budynków
    */
    let buildingsGroupsDictionary: any = {};
    buildingsGroupsDictionary["other"] = [];

    if (buildings) {
      for (let i = 0; i < buildings.length; i++) {
        let tempGroupId = buildings[i].buildingsGroupId;

        if (settings.WebSMSWylaczGrupyBudynkow?.value !== "1") {
          if (tempGroupId && !buildingsGroupsDictionary[tempGroupId]) {
            buildingsGroupsDictionary[tempGroupId] = [];
          }

          if (tempGroupId) {
            buildingsGroupsDictionary[tempGroupId].push(buildings[i]);
          } else {
            buildingsGroupsDictionary["other"].push(buildings[i]);
          }
        } else {
          buildingsGroupsDictionary["other"].push(buildings[i]);
        }
      }
    }

    // Sortujemy grupy budynków
    var buildingsGroupsArray = Object.keys(buildingsGroupsDictionary).map(
      (key) => {
        let order = 999999;
        let foundBuildingsGroup = buildingsGroups.find(
          (el) => el.id.toString() === key
        );

        if (foundBuildingsGroup) {
          order = foundBuildingsGroup.order;
        }

        return [key, order, buildingsGroupsDictionary[key]];
      }
    );

    buildingsGroupsArray.sort((first, second) => {
      return first[1] - second[1];
    });

    // Na podstawie grup budynków budujemy drzewo obiektów
    for (let i = 0; i < buildingsGroupsArray.length; i++) {
      let buildingsGroupId = parseInt(buildingsGroupsArray[i][0]);

      if (!isNaN(buildingsGroupId)) {
        let foundBuildingsGroup = buildingsGroups.find(
          (el) => el.id === buildingsGroupId
        );

        if (foundBuildingsGroup) {
          let buildingsGroupToAdd: any = {
            icon: enableIcon ? <FontAwesomeIcon icon={faObjectGroup} /> : null,
            title: foundBuildingsGroup.name,
            url: `/sms-user/buildings-groups/${foundBuildingsGroup.id}`,
            submenu: [],
          };

          let buildingsInGroup = buildingsGroupsArray[i][2];
          for (let j = 0; j < buildingsInGroup.length; j++) {
            let buildingToAdd: any = {
              icon: enableIcon ? <FontAwesomeIcon icon={faBuilding} /> : null,
              title: buildingsInGroup[j].name,
              url: `/sms-user/buildings/${buildingsInGroup[j].id}`,
              submenu: [],
            };

            for (let k = 0; k < buildingsInGroup[j].floors.length; k++) {
              buildingToAdd.submenu.push({
                icon: enableIcon ? (
                  <FontAwesomeIcon icon={faLayerGroup} />
                ) : null,
                title: buildingsInGroup[j].floors[k].name,
                url: `/sms-user/building-floors/${buildingsInGroup[j].floors[k].id}`,
              });
            }

            buildingsGroupToAdd.submenu.push(buildingToAdd);
          }

          result.push(buildingsGroupToAdd);
        }
      } else {
        let buildingsInGroup = buildingsGroupsArray[i][2];
        for (let j = 0; j < buildingsInGroup.length; j++) {
          let buildingToAdd: any = {
            icon: enableIcon ? <FontAwesomeIcon icon={faBuilding} /> : null,
            title: buildingsInGroup[j].name,
            url: `/sms-user/buildings/${buildingsInGroup[j].id}`,
            submenu: [],
          };

          for (let k = 0; k < buildingsInGroup[j].floors.length; k++) {
            buildingToAdd.submenu.push({
              icon: enableIcon ? <FontAwesomeIcon icon={faLayerGroup} /> : null,
              title: buildingsInGroup[j].floors[k].name,
              url: `/sms-user/building-floors/${buildingsInGroup[j].floors[k].id}`,
            });
          }

          result.push(buildingToAdd);
        }
      }
    }

    createMenuItemsTreePath(result);
    setMenuData(result);
    setMenuDataLoaded(true);
  }, [
    buildingsGroups,
    buildings,
    buildingsDataLoaded,
    menuDataLoaded,
    settings.ifSettingsFetched,
    settings.WebSMSUserUkryjMapeBudynkow?.value,
    settings.WebSMSWylaczGrupyBudynkow?.value,
    t,
  ]);

  return { menuData, menuDataLoaded };
};

export default useSMSUserMenuBuilder;
