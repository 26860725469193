import ContentGuestsEventsReport from "../../helpersComponents/GuestsEventsReport/ContentGuestsEvents";

const GuestsEventsReportPfso = () => {
  return (
    <div>
      <ContentGuestsEventsReport
        pathName="pfso/guests-events"
        layoutSettingName="layoutPfsoGuestsEventsReport"
      />
    </div>
  );
};

export default GuestsEventsReportPfso;
