import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import FormAddNewCompany from "../../../agent/FromAddNewCompany/FormAddNewCompany";
import ButtonStyled from "../../Button/button";
import {
  AutocompleteFormik,
  DatePickerFormik,
  TextFieldFormik,
} from "../../FormikInputs";
import CheckboxWithLabelFormik from "../../FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import LoadingWrapper from "../../LoadingWrapper/loadingWrapper";
import AccordionStyled from "../../MaterialUi/Accordion/AccordionStyled";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../PopupHelpers";
import RegistrationNumberForm from "./RegistrationNumberForm";
import styles from "./styles.module.scss";

export interface IFormRegistrationNumber {
  registrationNumber: string;
  dateFrom: Date | null;
  dateTo: Date | null;
}

const PopupContentWhiteListCompany = ({
  rowId,
  onSubmit,
  isOpen,
  pathWhiteListCompanies,
  pathCompaniesInPorts,
}: {
  rowId: number | undefined;
  onSubmit: (values: IAddUpdateWhiteListCompany) => void;
  isOpen: boolean;
  pathWhiteListCompanies: string;
  pathCompaniesInPorts: string;
}) => {
  const { t } = useTranslation();
  const [companies, fetchingStateCompanies, fetchAgainCompanies] =
    useFetchAndSetGET<ICompanyInPorts[]>({
      path: pathCompaniesInPorts,
    });

  const [fetchedRow, fetchingStateFetchedRow, fetchAgainFetchedRow] =
    useFetchAndSetGET<IWhiteListCompany>({
      path: `${pathWhiteListCompanies}/${rowId}`,
      startFetchOnInitial: rowId ? true : false,
    });

  const [showAddCompanyForm, setShowAddCompanyForm] = useState(false);
  const [addedCompanyId, setAddedCompanyId] = useState<false | number>(false);

  useEffect(() => {
    if (addedCompanyId !== false) {
      fetchAgainCompanies();
    }
  }, [addedCompanyId, fetchAgainCompanies]);

  return (
    <LoadingWrapper
      isLodadingProgress={fetchingStateFetchedRow.isFetching}
      isError={fetchingStateFetchedRow.isError}
      setIfFetchAgain={() => {
        if (fetchingStateFetchedRow.isError) {
          fetchAgainFetchedRow();
        }
      }}
    >
      <DialogContentStyled>
        <Formik
          initialValues={{
            company:
              companies !== null && fetchedRow !== null
                ? companies.find(
                    (company) => company.id === fetchedRow.companyId
                  )
                : companies !== null && addedCompanyId !== false
                ? companies.find((company) => company.id === addedCompanyId)
                : null,
            comments:
              fetchedRow && fetchedRow.comments ? fetchedRow.comments : "",
            dateRange: fetchedRow
              ? fetchedRow.dateFrom && fetchedRow.dateTo
                ? true
                : false
              : false,
            dateFrom:
              fetchedRow && fetchedRow.dateFrom
                ? new Date(fetchedRow.dateFrom)
                : null,
            dateTo:
              fetchedRow && fetchedRow.dateTo
                ? new Date(fetchedRow.dateTo)
                : null,

            registrationNumbers:
              fetchedRow && fetchedRow.registrationNumbers.length > 0
                ? fetchedRow.registrationNumbers.map((item) => {
                    return {
                      registrationNumber: item.registrationNumber,
                      dateRange: item.dateFrom ? true : false,
                      dateFrom: item.dateFrom ? new Date(item.dateFrom) : null,
                      dateTo: item.dateTo ? new Date(item.dateTo) : null,
                    };
                  })
                : [],
            permissionForUnknownPlate:
              fetchedRow && fetchedRow.permissionForUnknownPlate
                ? fetchedRow.permissionForUnknownPlate
                : false,
          }}
          onSubmit={(values) => {
            const object = {
              companyId: values.company!.id,
              dateFrom:
                values.dateRange && values.dateFrom ? values.dateFrom : null,
              dateTo: values.dateRange && values.dateTo ? values.dateTo : null,
              comments: values.comments ? values.comments : null,
              registrationNumbers: values.registrationNumbers
                ? values.registrationNumbers.map((item) => {
                    return {
                      registrationNumber: item.registrationNumber,
                      dateFrom:
                        item.dateRange && item.dateFrom
                          ? new Date(item.dateFrom)
                          : null,
                      dateTo:
                        item.dateRange && item.dateTo
                          ? new Date(item.dateTo)
                          : null,
                    };
                  })
                : [],
              permissionForUnknownPlate: values.permissionForUnknownPlate,
            };

            onSubmit(object);
          }}
          validationSchema={Yup.object({
            company: Yup.object().nullable().required(t("field_required")),
            dateFrom: Yup.date()
              .nullable()
              .when("dateRange", {
                is: true,
                then: Yup.date().nullable().required(t("field_required")),
              }),
            dateTo: Yup.date()
              .nullable()
              .when("dateRange", {
                is: true,
                then: Yup.date().nullable().required(t("field_required")),
              })
              .min(
                Yup.ref("dateFrom"),
                t("date_to_must_be_later_than_date_from")
              ),

            registrationNumbers: Yup.array().of(
              Yup.object().shape({
                registrationNumber: Yup.string()
                  .nullable()
                  .required(t("field_required")),
                dateFrom: Yup.date()
                  .nullable()
                  .when("dateRange", {
                    is: true,
                    then: Yup.date().nullable().required(t("field_required")),
                  }),
                dateTo: Yup.date()
                  .nullable()
                  .when("dateRange", {
                    is: true,
                    then: Yup.date().nullable().required(t("field_required")),
                  })
                  .min(
                    Yup.ref("dateFrom"),
                    t("date_to_must_be_later_than_date_from")
                  ),
              })
            ),
          })}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form noValidate id="submitForm">
                {/*  */}
                {/* Companies */}
                <AccordionStyled
                  title={t("company")}
                  defaultExpanded={true}
                  backgroundColor="#FBFBFB"
                  isError={
                    errors.company || errors.dateFrom || errors.dateTo
                      ? true
                      : false
                  }
                >
                  <div className={styles.companyContainer}>
                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle>{t("company")}:</RowTitle>
                        <RowInput style={{ display: "flex" }}>
                          <LoadingWrapper
                            isLodadingProgress={
                              fetchingStateCompanies.isFetching
                            }
                            isError={fetchingStateCompanies.isError}
                            setIfFetchAgain={() => {
                              if (fetchingStateCompanies.isError) {
                                fetchAgainCompanies();
                              }
                            }}
                          >
                            <AutocompleteFormik
                              options={companies}
                              getOptionLabel={(option: ICompanyInPorts) => {
                                return option?.name;
                              }}
                              width="100%"
                              getOptionSelected={(
                                option: ICompanyInPorts,
                                value: ICompanyInPorts
                              ) => {
                                return option.id === value.id;
                              }}
                              label={t("select_company")}
                              name="company"
                              required={true}
                              style={{ width: "275px", marginRight: "10px" }}
                            />
                            <div
                              className={styles.iconShowCompanyForm}
                              onClick={() => {
                                setShowAddCompanyForm(!showAddCompanyForm);
                              }}
                            >
                              {showAddCompanyForm ? (
                                <FontAwesomeIcon
                                  className="faMinusNotifications fa-lg"
                                  icon={faMinus}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="faPlusNotificationsAgent fa-lg"
                                  icon={faPlus}
                                />
                              )}
                            </div>
                          </LoadingWrapper>
                        </RowInput>
                      </Row>

                      <Row>
                        <RowTitle>{t("comments")}:</RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            label={t("comments")}
                            name="comments"
                            type="text"
                            ifToUpperCaseShipsAndPorts={true}
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>

                    {showAddCompanyForm && (
                      <FormAddNewCompany
                        addCompanyPath={pathCompaniesInPorts}
                        setShowAddCompanyForm={setShowAddCompanyForm}
                        setAddedCompanyId={setAddedCompanyId}
                        addedCompanyId={addedCompanyId}
                        companies={companies}
                        setFieldValue={setFieldValue}
                      />
                    )}

                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle>
                          <CheckboxWithLabelFormik
                            name={"dateRange"}
                            ifCircle={true}
                            label={
                              <span style={{ fontSize: "0.9rem" }}>
                                {t("date_range")}
                              </span>
                            }
                          />
                        </RowTitle>
                        {values["dateRange"] && (
                          <RowInput>
                            <DatePickerFormik
                              customLabel={t("date_from")}
                              name="dateFrom"
                              view={["year", "month", "date"]}
                              format="dd-MM-yyyy"
                              required={true}
                            />
                          </RowInput>
                        )}
                      </Row>

                      {values["dateRange"] && (
                        <Row>
                          <RowTitle>{}</RowTitle>
                          <RowInput>
                            <DatePickerFormik
                              customLabel={t("date_to2")}
                              name="dateTo"
                              view={["year", "month", "date"]}
                              format="dd-MM-yyyy"
                              required={true}
                            />
                          </RowInput>
                        </Row>
                      )}
                    </TwoColumnsRowsContainer>

                    <CheckboxWithLabelFormik
                      name="permissionForUnknownPlate"
                      ifCircle={true}
                      label={t("permission_for_unknown_plate")}
                    />
                  </div>
                </AccordionStyled>

                {/* Registrations numbers */}
                <AccordionStyled
                  title={t("registration_numbers")}
                  defaultExpanded={true}
                  backgroundColor="#FBFBFB"
                  isError={errors.registrationNumbers ? true : false}
                >
                  <div className={styles.registrationNumberContainer}>
                    <FieldArray
                      name="registrationNumbers"
                      render={(arrayHelpers) => (
                        <>
                          {values.registrationNumbers.map(
                            (
                              registrationNumber: IFormRegistrationNumber,
                              index: number
                            ) => {
                              return (
                                <React.Fragment key={index}>
                                  <RegistrationNumberForm
                                    index={index}
                                    arrayHelpers={arrayHelpers}
                                    values={values}
                                  />
                                </React.Fragment>
                              );
                            }
                          )}

                          <div className={styles.addAnotherRegistrationNumber}>
                            <ButtonStyled
                              onClick={() =>
                                arrayHelpers.push({
                                  registrationNumber: "",
                                  dateRange: false,
                                  dateFrom: null,
                                  dateTo: null,
                                })
                              }
                              type="button"
                            >
                              {t("add_another") + " "}{" "}
                              <FontAwesomeIcon icon={faPlus} />
                            </ButtonStyled>
                          </div>
                        </>
                      )}
                    />
                  </div>
                </AccordionStyled>
              </Form>
            );
          }}
        </Formik>
      </DialogContentStyled>
    </LoadingWrapper>
  );
};

export default PopupContentWhiteListCompany;
