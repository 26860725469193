export const colors = [
  "#ffdb99",
  "#8A9A5B",
  "#f0f8ff",
  "#d3ffce",
  "#ff9553",
  "#ACACE6",
  "#14A989",
  "#EED9C4",
  "#FF4466",
  "#A0E6FF",
];

export function adjust(color: string, amount: number) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
}
