import styles from "./styles.module.scss";
import DialogStyled from "../../../../helpersComponents/PopupHelpers/dialogStyled";
import DialogTitleStyled from "../../../../helpersComponents/PopupHelpers/dialogTitleStyled";
import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../../../helpersComponents/PopupHelpers/dialogActionsStyled";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DialogContentStyled } from "../../../../helpersComponents/PopupHelpers";
import TextFieldFormik from "../../../../helpersComponents/FormikInputs/TextField/textField";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../../store/hooks";
import { selectAuthUser } from "./../../../../../reducers/session";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";

const AddEditHomeOfficeAddressPopup: React.FC<any> = ({
  row,
  onApplyChanges,
  onCancelChanges,
  open,
}: any) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const authUser = useAppSelector(selectAuthUser);
  const subjectId = authUser.currentProfile.subjectId;
  const applyChanges = useRef(onApplyChanges);

  const [homeOfficeAddress, , fetchAgainHomeOfficeAddress] =
    useFetchAndSetGET<HomeOfficeAddress>({
      path: `worker-time/${subjectId}/home-office-addresses/${row.id}`,
      startFetchOnInitial: false,
    });

  const successCallback = useCallback((data) => {
    applyChanges.current(true);
  }, []);

  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const [
    fetchingStateAddEditHomeOfficeAddress,
    fetchAgainAddEditHomeOfficeAddress,
  ] = useFetchOtherThanGET({
    path: row.id
      ? `worker-time/${subjectId}/home-office-addresses/${row.id}`
      : `worker-time/${subjectId}/home-office-addresses`,
    method: row.id ? "PUT" : "POST",
    body: bodyRequest,
    setBody: setBodyRequest,
    contentType: "application/json",
    disableErrorSnackbar: true,
    successCallback: successCallback,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainAddEditHomeOfficeAddress();
    }
  }, [bodyRequest, fetchAgainAddEditHomeOfficeAddress]);

  useEffect(() => {
    if (row.id) {
      fetchAgainHomeOfficeAddress();
    }
  }, [row.id, fetchAgainHomeOfficeAddress]);

  return (
    <>
      <DialogStyled
        open={open}
        onClose={onCancelChanges}
        TransitionProps={{
          onExited: () => {},
          onEnter: () => {},
        }}
      >
        <DialogTitleStyled
          title={
            row.id
              ? t("editing_home_office_address")
              : t("adding_home_office_address")
          }
        />
        <DialogContentStyled>
          <Formik
            validateOnBlur={false}
            initialValues={{
              street: homeOfficeAddress ? homeOfficeAddress.street : "",
              city: homeOfficeAddress ? homeOfficeAddress.city : "",
            }}
            enableReinitialize={true}
            onSubmit={(values) => {
              let body = {
                street: values.street,
                city: values.city,
              };

              setBodyRequest(JSON.stringify(body));
            }}
            validationSchema={Yup.object({
              street: Yup.string().nullable().required(t("field_required")),
              city: Yup.string().nullable().required(t("field_required")),
            })}
          >
            {({ values, setFieldValue, setFieldError, setFieldTouched }) => {
              return (
                <Form noValidate id="submitForm">
                  <div className={styles.homeOfficeAddressRow}>
                    <div className="titleWidthSubstitutions">{t("street")}</div>
                    <div className="inputWidthSubstitutions">
                      <TextFieldFormik
                        label={t("street")}
                        name="street"
                        type="text"
                        multiline={false}
                        ifToUpperCaseShipsAndPorts={false}
                      />
                    </div>
                  </div>

                  <div className={styles.homeOfficeAddressRow}>
                    <div className="titleWidthSubstitutions">{t("city")}</div>
                    <div className="inputWidthSubstitutions">
                      <TextFieldFormik
                        label={t("city")}
                        name="city"
                        type="text"
                        multiline={false}
                        ifToUpperCaseShipsAndPorts={false}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContentStyled>
        <DialogActionsStyled
          typConfirm="submit"
          formConfirm="submitForm"
          onConfirmText={row.id ? t("edit") : t("add")}
          onCancel={() => {
            setErrorMessage("");
            onCancelChanges();
          }}
          isLoading={fetchingStateAddEditHomeOfficeAddress.isFetching}
          errorString={errorMessage}
        />
      </DialogStyled>
    </>
  );
};

export default AddEditHomeOfficeAddressPopup;
