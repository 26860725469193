import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import useFetchPostFormData from "../../../hooks/fetchHooks/post/useFetchPostFormData";
import { useSnackbar } from "notistack";
import useFetchGet from "../../../hooks/fetchHooks/get/useFetchGet";
import styles from "./homePage.module.scss";
import { selectAuthUser } from "../../../reducers/session";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setFetchAgainTasks } from "../../../reducers/homePage";

const AddTask = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const dispatch = useAppDispatch();

  const [ifFetchProject, setIfFetchProject] = useState<boolean>(false);
  const projectResponse = useFetchGet(
    `worker-time/${authUserId}/projects/0/tasks`,
    ifFetchProject
  );
  const [postRequestBodyNewTask, setPostRequestBodyNewTask] =
    useState<any>(false);
  useEffect(() => {
    if (projectResponse.error === 404) {
      setIfFetchProject(false);
      let formdata: any = new FormData();
      formdata.append(
        "requestData",
        JSON.stringify({
          name:
            "QuickTask " +
            new Date().toLocaleString(t("scheduler_language"), {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }),
        })
      );
      setPostRequestBodyNewTask(formdata);
      return;
    }
    if (projectResponse.error !== null) {
      setIfFetchProject(false);
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
      return;
    }
    if (projectResponse.data === null) return;
    setIfFetchProject(false);
    let task = projectResponse.data.tasks.find((task) =>
      task.name?.startsWith("QuickTask")
    );
    if (task === undefined) {
      let formdata: any = new FormData();
      formdata.append(
        "requestData",
        JSON.stringify({
          name:
            "QuickTask " +
            new Date().toLocaleString(t("scheduler_language"), {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }),
        })
      );
      setPostRequestBodyNewTask(formdata);
      return;
    } else enqueueSnackbar(t("Szybki task już istnieje"), { variant: "error" });
  }, [projectResponse, enqueueSnackbar, t]);

  let postRequestNewTaskt = useFetchPostFormData(
    `worker-time/${authUserId}/projects/0/tasks`,
    postRequestBodyNewTask
  );

  useEffect(() => {
    if (postRequestNewTaskt.data?.status === 201) {
      setPostRequestBodyNewTask(false);
      enqueueSnackbar(t("task_added"), { variant: "success" });
      dispatch(setFetchAgainTasks(true));
    } else if (postRequestNewTaskt.data?.status === 400) {
      let error = "";
      Object.keys(postRequestNewTaskt.data.errors).forEach((errorObject) => {
        postRequestNewTaskt.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      enqueueSnackbar(error, { variant: "error" });
      setPostRequestBodyNewTask(false);
    } else if (postRequestNewTaskt.error !== null) {
      setPostRequestBodyNewTask(false);
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
    }
  }, [postRequestNewTaskt, enqueueSnackbar, dispatch, t]);

  return (
    <div
      className={styles.orangeButton}
      onClick={() => {
        setIfFetchProject(true);
      }}
    >
      <div>
        <FontAwesomeIcon className="fa-2x" icon={faSignInAlt} />
      </div>
      <div>{t("begin_task")}</div>
    </div>
  );
};

export default AddTask;
