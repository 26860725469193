import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { DialogContentStyled } from "../../../helpersComponents/PopupHelpers/dialogContentStyled";
import * as Yup from "yup";
import {
  Row,
  RowInput,
  RowTitle,
} from "../../../helpersComponents/PopupHelpers";
import { TextFieldFormik } from "../../../helpersComponents/FormikInputs";

interface Props {
  rowId?: number;
  onSubmit: (values: { purposeOfExit: string }) => void;
}

const PopupContentBusinessExists: React.FC<Props> = ({ rowId, onSubmit }) => {
  const { t } = useTranslation();
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;

  const [
    fetchedBusinessExit,
    fetchingStateFetchedBusinessExit,
    fetchAgainFetchedBusinessExit,
  ] = useFetchAndSetGET<BusinessExit>({
    path: `worker-time/${authUserId}/business-exits/` + rowId,
    startFetchOnInitial: rowId ? true : false,
  });

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={fetchingStateFetchedBusinessExit.isFetching}
        isError={fetchingStateFetchedBusinessExit.isError}
        setIfFetchAgain={() => {
          if (fetchingStateFetchedBusinessExit.isError) {
            fetchAgainFetchedBusinessExit();
          }
        }}
      >
        {(rowId === undefined || fetchedBusinessExit) && (
          <DialogContentStyled>
            <Formik
              initialValues={{
                purposeOfExit: fetchedBusinessExit?.purposeOfExit
                  ? fetchedBusinessExit.purposeOfExit
                  : "",
              }}
              onSubmit={(values: { purposeOfExit: string }) => {
                onSubmit(values);
              }}
              validationSchema={Yup.object({
                purposeOfExit: Yup.string().required(t("field_required")),
              })}
            >
              <Form noValidate id="submitForm">
                <Row>
                  <RowTitle>{t("purpose_of_the_exit")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      label={t("purpose_of_the_exit")}
                      name="purposeOfExit"
                      required={true}
                      multiline
                    />
                  </RowInput>
                </Row>
              </Form>
            </Formik>
          </DialogContentStyled>
        )}
      </LoadingWrapper>
    </>
  );
};

export default PopupContentBusinessExists;
