import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import ButtonStyled from "../../../helpersComponents/Button/button";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";

type Props = {
  trainingData: TrainingObjectbrief;
  typesOfVisits: TypeOfVisit[];
};

const trainingType = {
  0: "guest",
  1: "worker",
};

export default function SingleTrainingRoot({
  trainingData,
  typesOfVisits,
}: Props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const getLocalizedTrainingName = useCallback(() => {
    switch (i18n.language) {
      case "en":
        return trainingData.nameEN;

      case "de":
        return trainingData.nameDE;

      case "ru":
        return trainingData.nameRU;

      case "uk":
        return trainingData.nameUK;

      default:
        return trainingData.namePL;
    }
  }, [i18n.language, trainingData]);

  const getLocalizedTrainingDescription = useCallback(() => {
    switch (i18n.language) {
      case "en":
        return trainingData.descriptionEN;

      case "de":
        return trainingData.descriptionDE;

      case "ru":
        return trainingData.descriptionRU;

      case "uk":
        return trainingData.descriptionUK;

      default:
        return trainingData.descriptionPL;
    }
  }, [i18n.language, trainingData]);

  const getLocalizedTypeOfVisitName = useCallback(
    (typeOfVisit: number) => {
      let typeOfVisitObj = typesOfVisits.find((e) => e.id === typeOfVisit);

      if (typeOfVisitObj) {
        switch (i18n.language) {
          case "en":
            return typeOfVisitObj.nameEN;

          case "de":
            return typeOfVisitObj.nameDE;

          case "ru":
            return typeOfVisitObj.nameRU;

          case "uk":
            return typeOfVisitObj.nameUK;

          default:
            return typeOfVisitObj.namePL;
        }
      }

      return "";
    },
    [i18n.language, typesOfVisits]
  );

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={styles.titleAccordion}>
            <div>
              <b>{getLocalizedTrainingName()}</b>
            </div>
            <div>
              <ButtonStyled
                onClick={() => {
                  history.push({
                    pathname: `/superior-ac/trainings/edit/${trainingData.id}`,
                  });
                }}
              >
                {t("edit")}
              </ButtonStyled>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.trainingDetailsRoot}>
            <div className={styles.row}>
              <div className={styles.leftRight}>
                <div className={styles.title}>
                  <b>{t("valid_from")}:</b>
                </div>
                <div className={styles.value}>
                  {toLocaleStringCustom({
                    date: new Date(trainingData.validFrom),
                    t,
                  })}
                </div>
              </div>
              <div className={styles.leftRight}>
                <div className={styles.title}>
                  <b>{t("valid_to")}:</b>
                </div>
                <div className={styles.value}>
                  {toLocaleStringCustom({
                    date: new Date(trainingData.validTo),
                    t,
                  })}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.leftRight}>
                <div className={styles.title}>
                  <b>{t("type")}:</b>
                </div>
                <div className={styles.value}>
                  {t(trainingType[trainingData.type])}
                </div>
              </div>
              <div className={styles.leftRight}>
                <div className={styles.title}>
                  <b>{t("pass_threshold")}:</b>
                </div>
                <div className={styles.value}>
                  {trainingData.passTresholdInPercent}%
                </div>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.leftRight}>
                <div className={styles.title}>
                  <b>{t("how_many_questions_to_draw_in_the_exam")}:</b>
                </div>
                <div className={styles.value}>
                  {trainingData.numberOfQuestions}
                </div>
              </div>
              <div className={styles.leftRight}>
                <div className={styles.title}>
                  <b>{t("multiple_choice")}:</b>
                </div>
                <div className={styles.value}>
                  {trainingData.multiSelectionInQuestions ? t("yes") : t("no")}
                </div>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.leftRight}>
                <div className={styles.title}>
                  <b>{t("type_of_visit")}:</b>
                </div>
                <div className={styles.value}>
                  {getLocalizedTypeOfVisitName(trainingData.kindOfVisit)}
                </div>
              </div>
              <div className={styles.leftRight}>
                <div className={styles.title}>
                  <b>{t("is_required")}:</b>
                </div>
                <div className={styles.value}>
                  {trainingData.isRequired ? t("yes") : t("no")}
                </div>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.leftRight}>
                <div className={styles.title}>
                  <b>{t("possibility_to_repeat_test")}:</b>
                </div>
                <div className={styles.value}>
                  {trainingData.isRepeatPossible ? t("yes") : t("no")}
                </div>
              </div>
              <div className={styles.leftRight}>
                <div className={styles.title}>
                  <b>{t("isActive")}:</b>
                </div>
                <div className={styles.value}>
                  {trainingData.isActive ? t("yes") : t("no")}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.leftRight}>
                <div className={styles.title}>
                  <b>{t("validity_of__test")}:</b>
                </div>
                <div className={styles.value}>
                  {trainingData.importance} {t("days")}
                </div>
              </div>
              <div className={styles.leftRight}>
                <div className={styles.title}>
                  <b>{t("description")}:</b>
                </div>
                <div className={styles.value}>
                  {getLocalizedTrainingDescription()}
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
