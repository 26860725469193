import { Tooltip } from "@material-ui/core";
import React, { ReactElement } from "react";
import styles from "./collaboratos.module.scss";

interface Props {
  photos: { timeWorkerId: number; photo: string }[];
  collaborators: {
    evidenceNumber?: string;
    firstName: string;
    lastName: string;
    id?: number;
    taskId?: number;
    timeWorkerId: number;
  }[];
}

const listOfColors = [
  "rgb(221, 247, 253)",
  "rgb(216, 241, 205)",
  "rgb(247, 240, 240)",
  "rgb(239, 247, 248)",
  "rgb(236, 229, 253)",
  "rgb(236, 236, 236)",
];

export default function CollaboratorsList({
  collaborators,
  photos,
}: Props): ReactElement {
  return (
    <div className={styles.collaboratorContainer}>
      {collaborators.map((collaborator, collaboratorIndex) => {
        let collaboratorPhoto = photos?.find(
          (photo) => photo.timeWorkerId === collaborator.timeWorkerId
        );
        return (
          <Tooltip
            key={collaborator.timeWorkerId}
            title={`${collaborator?.firstName} ${collaborator?.lastName}`}
          >
            <div className={styles.singlecollaborator}>
              <div>
                {collaboratorPhoto ? (
                  <img
                    src={"data:image/png;base64, " + collaboratorPhoto.photo}
                    alt="Profil"
                    className={styles.collaboratorPhoto}
                  />
                ) : (
                  // <img
                  //   className={styles.collaboratorPhoto}
                  //   src={profileIcon}
                  //   alt="Profil"
                  // />
                  <div
                    className={styles.collaboratorLackOfPhoto}
                    style={{
                      backgroundColor: listOfColors[collaboratorIndex % 6],
                    }}
                  >
                    {`${collaborator.firstName.substring(
                      0,
                      1
                    )} ${collaborator.lastName.substring(0, 1)}`}
                  </div>
                )}
              </div>
              {/* <div
              className={styles.collaboratorName}
            >{`${collaborator.firstName} ${collaborator.lastName}`}</div> */}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}
