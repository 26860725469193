const loadLocation = (options = {}) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};

const getCurrentPosition = async () => {
  try {
    const position: any = await loadLocation();
    return {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
  } catch (e) {
    return false;
  }
};

export default getCurrentPosition;
