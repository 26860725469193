class FrameDelimiterTransformer {
  constructor() {
    this.chunks = "";
  }

  transform(chunk, controller) {
    this.chunks += chunk;
    const separator = String.fromCharCode(0x0d, 0x03, 0x1d);
    const frames = this.chunks.split(separator);

    this.chunks = frames.pop();

    frames.forEach((frame) => {
      for (let i = 0; i <= 0x1f; i++) {
        if (i !== 0x0a && i !== 0x0d) {
          const stringToReplace = String.fromCharCode(i);
          frame = frame.replaceAll(stringToReplace, "");
        }
      }

      const stringToRemove = String.fromCharCode(0x1c, 0x02);
      frame = frame.split(stringToRemove).pop();
      controller.enqueue(frame);
    });
  }

  flush(controller) {
    controller.enqueue(this.chunks);
  }
}

export default FrameDelimiterTransformer;
