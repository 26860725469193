const convertDateFetch = (
  currentDateFetch: Date,
  time?: string | undefined
) => {
  if (!(currentDateFetch instanceof Date)) {
    return "invaildDate";
  }
  if (currentDateFetch === null) {
    return "invaildDate";
  } else {
    if (isNaN(currentDateFetch.getTime())) {
      return "invaildDate";
    }
  }

  let timeLocal;
  if (time === undefined) {
    let seconds = currentDateFetch.getSeconds();
    let minutes = currentDateFetch.getMinutes();
    let hour = currentDateFetch.getHours();
    // let milliseconds = currentDateFetch.getMilliseconds();
    let secondsString;
    let minutesString;
    let hourString;
    // let millisecondsString;

    if (seconds < 10) secondsString = "0" + seconds;
    else secondsString = seconds;

    if (minutes < 10) minutesString = "0" + minutes;
    else minutesString = minutes;

    if (hour < 10) hourString = "0" + hour;
    else hourString = hour;

    // if (milliseconds < 10) millisecondsString = "00" + milliseconds;
    // else if (milliseconds < 100) millisecondsString = "0" + milliseconds;
    // else millisecondsString = milliseconds;

    timeLocal = "T" + hourString + ":" + minutesString + ":" + secondsString;
    // + `.${millisecondsString}`;
  } else {
    timeLocal = time;
  }

  let date =
    currentDateFetch.getFullYear() +
    "-" +
    (currentDateFetch.getMonth() + 1 > 9
      ? currentDateFetch.getMonth() + 1
      : "0" + (currentDateFetch.getMonth() + 1)) +
    "-" +
    (currentDateFetch.getDate() > 9
      ? currentDateFetch.getDate()
      : "0" + currentDateFetch.getDate()) +
    timeLocal;
  return date;
};

export default convertDateFetch;
