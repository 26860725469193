import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextFieldFormik } from "../../../../../../helpersComponents/FormikInputs";
import Button from "../../../../../../helpersComponents/Button/button";
import styles from "./employeesReportAddColumnsSchema.module.scss";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useFetchOtherThanGET from "../../../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";

interface IProps {
  columns: string[];
  successCallback?: (schemaName: string) => void;
}

const EmployeesReportAddColumnsSchema: React.FC<IProps> = ({
  columns,
  successCallback,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [
    addEmployeesReportColumnsSchemaBody,
    setAddEmployeesReportColumnsSchemaBody,
  ] = useState<false | string>(false);

  const successCallbackAddEmployeesReportColumnsSchema = useCallback(
    (response: UseFetchResponse) => {
      if (successCallback) successCallback(response.resJson.name);
    },
    [successCallback]
  );

  const [, addEmployeesReportColumnsSchemaFetchAgain] = useFetchOtherThanGET({
    path: `superior-time/employees-report-columns-schemas`,
    method: "POST",
    contentType: "application/json",
    body: addEmployeesReportColumnsSchemaBody,
    setBody: setAddEmployeesReportColumnsSchemaBody,
    successCallback: successCallbackAddEmployeesReportColumnsSchema,
  });

  useEffect(() => {
    if (addEmployeesReportColumnsSchemaBody) {
      addEmployeesReportColumnsSchemaFetchAgain();
    }
  }, [
    addEmployeesReportColumnsSchemaBody,
    addEmployeesReportColumnsSchemaFetchAgain,
  ]);

  return (
    <div>
      <Formik
        validateOnBlur={false}
        initialValues={{
          schemaName: "",
        }}
        onSubmit={(values) => {
          if (values["schemaName"] === "") {
            setErrorMessage(t("no_schema_name"));
            return;
          }

          if (columns.length === 0) {
            setErrorMessage(t("no_columns_selected"));
            return;
          }

          let addColumnsSchemaQuery: IAddColumnsSchemaQuery = {
            name: values["schemaName"],
            columns: columns,
          };

          setAddEmployeesReportColumnsSchemaBody(
            JSON.stringify(addColumnsSchemaQuery)
          );

          values["schemaName"] = "";
          setErrorMessage("");
        }}
        validationSchema={Yup.object({
          schemaName: Yup.string(),
        })}
      >
        {({ handleSubmit }) => (
          <Form id="addSchemaSubmitForm">
            <div className={styles.addColumnsSchemaContainer}>
              <div>
                <TextFieldFormik
                  ifToUpperCaseShipsAndPorts={false}
                  label={t("schema_name")}
                  name="schemaName"
                  type="text"
                  required={true}
                />
              </div>
              <div className={styles.addSchemaButtonContainer}>
                <div className={styles.errorLabel}>{errorMessage}</div>

                <Button
                  className={styles.addColumnsSchemaButton}
                  onClick={() => {
                    handleSubmit();
                  }}
                  type="button"
                >
                  {t("add_schema")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmployeesReportAddColumnsSchema;
