import styles from "./homePage.module.scss";
import { useTranslation } from "react-i18next";
import AddTask from "./addTask";
import EndTask from "./endTask";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";
import useFetchGet from "../../../hooks/fetchHooks/get/useFetchGet";
import { useEffect, useState } from "react";
import {
  selectFetchAgainTasks,
  setFetchAgainTasks,
} from "../../../reducers/homePage";
import ValueUnitDescription from "../../helpersComponents/NumberWithDescription/valueUnitDescription";
import { colorNameDictionary } from "../../ProfilesRouting/profilesDictionaries";

const QuickTask = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const authUser = useAppSelector(selectAuthUser);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const [lastTask, setlastTask] = useState<any>(false);
  const fetchAgainTasks = useAppSelector(selectFetchAgainTasks);
  const dispatch = useAppDispatch();
  const color = colorNameDictionary[authUser.currentProfile?.type];

  const projectsAndTasksResponse = useFetchGet(
    `worker-time/${authUserId}/projects-and-tasks`,
    fetchAgainTasks
  );
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    if (projectsAndTasksResponse.error !== null) {
      setIsError(true);
      return;
    }
    if (projectsAndTasksResponse.data !== null) {
      dispatch(setFetchAgainTasks(false));
      let tasks = projectsAndTasksResponse.data.tasks;

      //// ignore tasks created by others, and tasks with collaborators other than me
      tasks = tasks.filter((task) => {
        if (task.ownerId === authUserId) {
          let ifOtherCollabo = task.collaborators.find(
            (collabo) => collabo.timeWorkerId !== authUserId
          );
          return ifOtherCollabo ? false : true;
        } else return false;
      });

      let tasksSorted = tasks.sort((a, b) => {
        if (new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime())
          return 1;
        else return -1;
      });
      if (tasksSorted.length > 0) {
        setlastTask(tasksSorted[0]);
      } else {
        setlastTask("none");
      }
    }
  }, [projectsAndTasksResponse, authUserId, dispatch, t]);

  useEffect(() => {
    return () => {
      dispatch(setFetchAgainTasks(true));
    };
  }, [dispatch]);

  return (
    <>
      <div className={styles.workTimeManagment}>
        <div className={styles.title}>
          <div>{t("task")}</div>
          <div>
            <div
              className={styles.buttonGoTo}
              onClick={() => {
                history.push({
                  pathname: "/worker-time/projects/my-tasks",
                });
              }}
            >
              <div>
                <FontAwesomeIcon
                  className={styles.buttonGoToIcon}
                  icon={faTasks}
                  size="2x"
                  style={{ color }}
                />
              </div>
              <div className={styles.buttonGoToText}>
                <span style={{ color }}>{t("go_to_tasks")}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.elementsManagement}>
          <AddTask />
          <EndTask />
          <div>
            <ValueUnitDescription
              value={
                isError ? (
                  t("something_went_wrong")
                ) : (
                  <>
                    {lastTask === "none"
                      ? t("none")
                      : lastTask !== false && (
                          <>
                            {`${lastTask.name} - ${new Date(
                              lastTask.createdAt
                            ).toLocaleString(t("scheduler_language"), {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            })}`}
                          </>
                        )}
                  </>
                )
              }
              description={t("last_task")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickTask;
