import { useTranslation } from "react-i18next";
import { ProfileType } from "../../../enums/profileType";

const useColumns = (profileType: ProfileType) => {
  const { t } = useTranslation();

  return profileType === ProfileType.AGENT ||
    profileType === ProfileType.OPERATIONAL_SUPPORT
    ? [
        { name: "shipName", title: t("ship") },
        { name: "dateOfArrival", title: t("date_of_arrival"), type: "date" },
        { name: "createdAt", title: t("data_created"), type: "date" },
      ]
    : [
        { name: "shipName", title: t("ship") },
        { name: "dateOfArrival", title: t("date_of_arrival"), type: "date" },
        { name: "createdAt", title: t("data_created"), type: "date" },
        { name: "addingPersonFullName", title: t("adding_person") },
      ];
};

export default useColumns;
