import React from "react";
import { Route, Redirect } from "react-router-dom";
import LoadingSettings from "./loadingSettings";

const PrivateRoute: any = ({ component: Component, profileId, ...rest }) => {
  let profile: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        return profile.tokenDecoded
          ?.isTwoFactorAuthenticationEnabledRequired ? (
          <Redirect
            to={{ pathname: "/settings", state: { from: props.location } }}
          />
        ) : (
            profile.currentProfile?.type !== undefined
              ? profile.currentProfile.type === profileId
              : false
          ) ? (
          <LoadingSettings component={Component} {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
