import "./messages.scss";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../hooks/fetchHooks/get/useFetchGet";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import RowDetail from "./rowDetail";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";

const styles = {
  isReadFalse: {
    backgroundColor: "#f5f5f5",
  },
};

let Row: any = Table.Row;

const TableRow =
  (expandedRowIds) =>
  ({ row, ...restProps }) => {
    let rowIsRead = row?.isRead;
    return (
      <Row
        {...restProps}
        // onClick={() => setIsRead(true)}
        style={{
          ...styles[rowIsRead ? "null" : "isReadFalse"],
        }}
      />
    );
  };

function MessagesContent() {
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [
    rangeStart,
    // setRangeStart
  ] = useState(1);
  const [
    RangeEnd,
    // setRangeEnd
  ] = useState(1000);
  const [isFetching, setIsFetching] = useState(true);
  // const [fetchAgain, setFetchAgain] = useState(true);
  const [ifFetchAgain, setIfFetchAgain] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const columns = [
    { name: "timeSuperiorLogin", title: t("sender") },
    { name: "messageTitle", title: t("message_title") },
    { name: "sentAt", title: t("sent_at"), type: "date_hours" },
  ];
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const { data, error } = useFetchGet(
    `worker-time/${authUserId}/messages?RangeStart=` +
      rangeStart +
      "&RangeEnd=" +
      RangeEnd,
    ifFetchAgain
  );

  useEffect(() => {
    if (error !== null) {
      setIsError(true);
      setIfFetchAgain(false);
      return;
    }
    if (data === null) return;

    let dataModified: any = [];
    data.messages.forEach((value, index) => {
      let modifiedElement = {
        id: value.id,
        isRead: value.isRead,
        messageContent: value.messageContent,
        timeWorkerFirstName: value.timeWorkerFirstName,
        timeWorkerLastName: value.timeWorkerLastName,
        timeSuperiorLogin: value.timeSuperiorLogin,
        messageTitle: value.messageTitle,
        sentAt: value.sentAt,
      };

      dataModified.push(modifiedElement);
    });
    setIfFetchAgain(false);
    setMessages(dataModified);
    setIsFetching(false);
  }, [data, error, t]);

  return (
    <>
      <TopBanner pathName={t("messages")} />
      <div className="flexAndCenter">
        <div>
          <div>
            <LoadingWrapper
              isLodadingProgress={isFetching}
              isError={isError}
              setIfFetchAgain={setIfFetchAgain}
              setIsError={setIsError}
            >
              {!isFetching && !isError && (
                <TableComponent
                  layoutSettingName="layoutWorkerTimeMessages"
                  rows={messages}
                  columns={columns}
                  ifEdit={false}
                  ifAdd={false}
                  ifDelete={false}
                  rowDetail={RowDetail}
                  rowComponent={TableRow}
                />
              )}
            </LoadingWrapper>
          </div>
        </div>
      </div>
    </>
  );
}

export default MessagesContent;
