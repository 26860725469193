import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectMultiMenu from "../../../../helpersComponents/SelectMultiMenu";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";

interface Props {
  timeWorkersIds: string[];
  setTimeWorkersIds: Dispatch<SetStateAction<string[]>>;
  successCallback: any;
}

const WorkersSelectMenu: React.FC<Props> = ({
  timeWorkersIds,
  setTimeWorkersIds,
  successCallback,
}) => {
  const { t } = useTranslation();
  const [dataUnitBodyRequest, setDataUnitBodyRequest] = useState<
    false | string
  >(false);

  const [, fetchAgainChangeDataUnit] = useFetchOtherThanGET({
    path: "superior-time/holiday-limits",
    method: "PATCH",
    body: dataUnitBodyRequest,
    contentType: "application/json",
    setBody: setDataUnitBodyRequest,
    disableSuccessSnackbar: false,
    disableErrorSnackbar: true,
    successCallback: successCallback,
  });

  useEffect(() => {
    if (dataUnitBodyRequest) {
      fetchAgainChangeDataUnit();
    }
  }, [dataUnitBodyRequest, fetchAgainChangeDataUnit]);

  const getTimeWorkerIdFromString = (timeWorkerId: string) => {
    let timeWorkerIdParts = timeWorkerId.split(";");
    return {
      timeWorkerId: parseInt(timeWorkerIdParts[0]),
      absenceTypeId: parseInt(timeWorkerIdParts[1]),
      year: parseInt(timeWorkerIdParts[2]),
    };
  };

  return (
    <SelectMultiMenu
      name={<FontAwesomeIcon icon={faEllipsisH} color="white" />}
      disabled={timeWorkersIds.length === 0}
      profile="superiorTime"
      items={[
        {
          optionName: t("holidays_limits_change_to_hours"),
          onClick: () => {
            let dataToSend: Array<any> = [];
            for (let i = 0; i < timeWorkersIds.length; i++) {
              dataToSend.push({
                resourceIdentifier: getTimeWorkerIdFromString(
                  timeWorkersIds[i]
                ),
                fieldName: "/isDay",
                fieldValue: "false",
              });
            }

            setDataUnitBodyRequest(JSON.stringify(dataToSend));
          },
        },
        {
          optionName: t("holidays_limits_change_to_days"),
          onClick: () => {
            let dataToSend: Array<any> = [];
            for (let i = 0; i < timeWorkersIds.length; i++) {
              dataToSend.push({
                resourceIdentifier: getTimeWorkerIdFromString(
                  timeWorkersIds[i]
                ),
                fieldName: "/isDay",
                fieldValue: "true",
              });
            }

            setDataUnitBodyRequest(JSON.stringify(dataToSend));
          },
        },
      ]}
    />
  );
};

export default WorkersSelectMenu;
