export enum EvidenceOperationType {
  COMPLETE_ACCORDING_TO_THE_STANDARD_EMPLOYEE_FORGOT_CARD = 0,
  COMPLETE_ACCORDING_TO_THE_STANDARD_EMPLOYEE_USED_CARD_INCORRECTLY = 1,
  COMPLETE_ACCORDING_TO_THE_STANDARD_EMPLOYEE_MAKE_UP_FOR_LOST_WORK = 2,
  COMPLETE_ACCORDING_TO_THE_STANDARD_CUSTOM_COMMENT = 3,

  LOCK_SELECTED_DAYS = 4,
  UNLOCK_SELECTED_DAYS = 5,
  LOCK_DAY_AND_ALL_PREVIOUS = 6,
  UNLOCK_DAY_AND_ALL_NEXT = 7,
  ACCEPT = 8,
  ACCEPT_AND_LOCK = 9,
}
