import { Palette } from "@devexpress/dx-react-chart";
import {
  Chart,
  Legend,
  PieSeries,
  Title,
} from "@devexpress/dx-react-chart-material-ui";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import useFetch from "../../../hooks/fetchHooks/useFetch/useFetch";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import styles from "./charts.module.scss";

export default function AbsencesChart(): ReactElement {
  const { t } = useTranslation();
  const [chartDataAbsences, setchartDataAbsences] = useState<any>([]);
  const history = useHistory();

  let [setIsFetching, fetchingState, fetchAgain] = useFetch({
    method: "GET",
    path: "superior-time/attendance-report",
  });

  useEffect(() => {
    if (fetchingState.isError || fetchingState.response === undefined) return;
    if (!Array.isArray(fetchingState.response.resJson)) return;
    let data = fetchingState.response.resJson;
    let absenceAmountLocal = 0;
    let presenceAmountLocal = 0;
    data.forEach((element) => {
      if (element?.ifPresent === true) {
        presenceAmountLocal++;
      } else {
        absenceAmountLocal++;
      }
    });

    setchartDataAbsences([
      {
        absence: `${t("present")}: ${presenceAmountLocal}`,
        amount: presenceAmountLocal,
      },
      {
        absence: `${t("absent")}: ${absenceAmountLocal}`,
        amount: absenceAmountLocal,
      },
    ]);
    setIsFetching(false);
  }, [fetchingState.isError, fetchingState.response, setIsFetching, t]);

  const title: any = (
    <div className={styles.chartLegend}>
      <div>{t("the_presence_of_employees")} </div>
      <div
        className={styles.buttonGoTo}
        onClick={() => {
          history.push({
            pathname: "/superior-time/lists/presence",
          });
        }}
      >
        <div className={styles.buttonGoToIcon}>
          <FontAwesomeIcon icon={faArrowAltCircleRight} size="2x" />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <LoadingWrapper
        isLodadingProgress={fetchingState.isFetching}
        isError={fetchingState.isError}
        setIfFetchAgain={fetchAgain}
      >
        {!fetchingState.isFetching && !fetchingState.isError && (
          <div>
            <div className={styles.chartCard}>
              <Chart data={chartDataAbsences} height={200} width={450}>
                <Palette scheme={["#50D6AD", "red"]} />
                <PieSeries
                  name="Series name"
                  valueField="amount"
                  argumentField="absence"
                />
                <Legend />
                <Title text={title} />
              </Chart>
            </div>
          </div>
        )}
      </LoadingWrapper>
    </div>
  );
}
