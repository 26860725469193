import { useTranslation } from "react-i18next";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import AccordionStyled from "../../MaterialUi/Accordion/AccordionStyled";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../../RowDetailsHelpers";

const RowDetailListOfEmployees = ({ row }: { row: GuestVisit }) => {
  const { t } = useTranslation();

  return (
    <RowDetailsContainer>
      {/*  */}
      <AccordionStyled title={t("person")} defaultExpanded={true}>
        <>
          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("person")}:</RowDetailsTitle>
              <RowDetailsValue>{`${row?.guestFirstName} ${row?.guestLastName}`}</RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("id_number")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.guestIDNumber ? row.guestIDNumber : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("company")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.companyName ? row.companyName : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>
        </>
      </AccordionStyled>

      {/*  */}
      <AccordionStyled title={t("information")} defaultExpanded={true}>
        <>
          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("entry_company_area")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.entryDate
                  ? toLocaleStringCustom({
                      date: row.entryDate,
                      t,
                      ifShowHours: true,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("exit_company_area")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.exitDate
                  ? toLocaleStringCustom({
                      date: row.exitDate,
                      t,
                      ifShowHours: true,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>
                {`${t("lists_of_employees")} - ${t("date")}`}:
              </RowDetailsTitle>
              <RowDetailsValue>
                {row?.listOfEmployeeDate
                  ? toLocaleStringCustom({
                      date: row.listOfEmployeeDate,
                      t,
                      ifShowHours: true,
                    })
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>
                {`${t("lists_of_employees")} - ${t("time")}`}:
              </RowDetailsTitle>
              <RowDetailsValue>
                {row?.listOfEmployeeTimeFrom
                  ? `${row.listOfEmployeeTimeFrom} - ${
                      row.listOfEmployeeTimeTo ? row.listOfEmployeeTimeTo : "__"
                    }`
                  : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("type_of_entry")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.typeOfEntry ? row.typeOfEntry : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("comments")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.comments ? row.comments : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("card_tag")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.cardUniversalNumber ? row.cardUniversalNumber : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>

            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("permission_zone")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.permissionZoneName ? row.permissionZoneName : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>

          {/*  */}
          <RowDetailsRow>
            <RowDetailsTitleValue>
              <RowDetailsTitle>{t("adding_person")}:</RowDetailsTitle>
              <RowDetailsValue>
                {row?.addingPersonFullName ? row.addingPersonFullName : "-"}
              </RowDetailsValue>
            </RowDetailsTitleValue>
          </RowDetailsRow>
        </>
      </AccordionStyled>
    </RowDetailsContainer>
  );
};

export default RowDetailListOfEmployees;
