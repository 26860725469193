import React from "react";
import PermittedRoomsContent from "./permittedRoomsContent";

function PermittedRooms() {
  return (
    <div>
      <PermittedRoomsContent />
    </div>
  );
}

export default PermittedRooms;
