import React, { useState, useEffect, useRef } from "react";
import styles from "./navbar.module.scss";
import { withRouter } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { selectAuthUser } from "../../reducers/session";
import { selectSettings } from "../../reducers/settings";
import { selectTimeWorkerProfile } from "../../reducers/profiles";
import { setNumberUnreadMessages } from "../../reducers/checkIfNewMessages";
import { useAppDispatch } from "../../store/hooks";
import useFetchGet from "../../hooks/fetchHooks/get/useFetchGet";
import useUpdateSettings from "../../hooks/useUpdateSettings/useUpdateSettings";
import emptyProfile from "./Images/empty-profile-picture.png";
import LogoSkalmex from "./Images/skalmex-logo-white.png";
import { profileNavigationComponentsDictionary } from "../ProfilesRouting/profilesDictionaries";
import UserPanel from "../UserPanel/userPanelContent";
import useNavbarsArray from "../ProfilesRouting/navbarsArray";

function Navbar(props) {
  const [ifUpdateSetting, setIfUpdateSetting] = useState<boolean>(false);
  const [ifFetchMessages, setIfFetchMessages] = useState<boolean>(false);
  const [ifSetEmpty, setIfSetEmpty] = useState(false);
  const authUser = useAppSelector(selectAuthUser);
  const messagesInfo = useFetchGet(
    `worker-time/${authUser.currentProfile.subjectId}/messages-info?messagesNumber=5000`,
    ifFetchMessages
  );

  const navbarsArray = useNavbarsArray();
  let ifIgonreSettings = false;
  navbarsArray.forEach((navbarArray) => {
    if (
      navbarArray.profileId === authUser.currentProfile.type &&
      navbarArray?.ifIgonreSettingsPreferences === true
    ) {
      ifIgonreSettings = true;
    }
  });

  const settingsFromRedux = useAppSelector(selectSettings);
  const dispatch = useAppDispatch();

  ////////////// Fetching settings and preferences //////////////////
  const ifSettingsUpdated = useUpdateSettings(ifUpdateSetting, ifSetEmpty);

  useEffect(() => {
    setIfUpdateSetting(false);
    if (ifIgonreSettings) {
      return setIfSetEmpty(true);
    }
    if (Object.keys(authUser).length !== 0) {
      setIfUpdateSetting(true);
    }
  }, [authUser, ifIgonreSettings]);

  useEffect(() => {
    if (ifSettingsUpdated) {
      setIfUpdateSetting(false);
    }
  }, [ifSettingsUpdated]);

  const messagesInterval: any = useRef(null);

  useEffect(() => {
    if (settingsFromRedux?.error === true) return;
    if (Object.keys(authUser).length === 0) return;
    if (authUser.currentProfile.type !== 3) return;
    if (settingsFromRedux.ifSettingsFetched === false) return;
    let pullingPeriodInMiliSeconds = 30000;
    const period = settingsFromRedux.WebWiadomosciCzestotliwoscOdpytywania;
    if (period) pullingPeriodInMiliSeconds = period.value * 1000;
    messagesInterval.current = setInterval(() => {
      setIfFetchMessages(true);
    }, pullingPeriodInMiliSeconds);

    //test błędu
    return () => {
      clearInterval(messagesInterval.current);
    };
  }, [authUser, settingsFromRedux]);

  useEffect(() => {
    if (authUser.currentProfile.type !== 3) {
      setIfFetchMessages(false);
      clearInterval(messagesInterval.current);
    }
  }, [authUser]);

  useEffect(() => {
    if (Object.keys(authUser).length === 0) return;
    if (authUser.currentProfile.type !== 3) return;
    setIfFetchMessages(true);
  }, [messagesInfo, authUser]);

  useEffect(() => {
    if (messagesInfo.error !== null) return setIfFetchMessages(false);
    if (messagesInfo.data === null) return;
    if (messagesInfo.isLoading === true) return;
    dispatch(setNumberUnreadMessages(messagesInfo.data.messages.length));
    setIfFetchMessages(false);
  }, [messagesInfo, dispatch]);

  const timeWorkerProfile = useAppSelector(selectTimeWorkerProfile);
  if (
    (timeWorkerProfile?.ifFetched === false ||
      timeWorkerProfile?.ifFetched === "error") &&
    authUser.currentProfile.type === 3
  )
    return <UserPanel />;

  if (
    props.history.location.pathname === "/login" ||
    Object.keys(authUser).length === 0
  ) {
    return <UserPanel />;
  }

  if (settingsFromRedux?.error === true) return <UserPanel />;

  return (
    <div className={styles.navbarContent}>
      <div className={styles.navbar}>
        <div>
          <div className={styles.userAvatar}>
            {timeWorkerProfile?.photo ? (
              <img
                src={"data:image/png;base64, " + timeWorkerProfile.photo}
                alt="Profil"
              />
            ) : (
              <img src={emptyProfile} alt="Profil" />
            )}
          </div>
          <div className={styles.userPanel}>
            <UserPanel />
          </div>
          <div className={styles.navigationContainer}>
            {
              profileNavigationComponentsDictionary[
                authUser.currentProfile.type
              ]
            }
          </div>
          <div className={styles.poweredBy}>
            <div>Powered by</div>
            <div className={styles.skalmexLogo}>
              <img src={LogoSkalmex} alt="Skalmex" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Navbar);
