import getDateRange from "../../../HelpersFunctions/dateAndTime/getDateRange";

export function filterTableRows(
  filterTablesByDate: FilterTablesByDateType,
  rows,
  rowDateKey: string
) {
  let rowsFilteredLocal: {}[] = [];
  let today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  let dateFrom = today;
  let dateTo = new Date();
  dateTo.setHours(23);
  dateTo.setMinutes(59);
  dateTo.setSeconds(59);
  dateTo.setMilliseconds(59);

  if (filterTablesByDate === "yesterday") {
    dateFrom.setDate(today.getDate() - 1);
    dateTo.setDate(dateTo.getDate() - 1);
  } else if (filterTablesByDate === "dayBeforeYesterday") {
    dateFrom.setDate(today.getDate() - 2);
    dateTo.setDate(dateTo.getDate() - 2);
  } else if (filterTablesByDate === "currentWeek") {
    dateFrom.setDate(today.getDate() - today.getDay() + 1);
  } else if (filterTablesByDate === "lastWeek") {
    dateFrom.setDate(today.getDate() - 7);
  } else if (filterTablesByDate === "currentMonth") {
    dateFrom.setDate(1);
  } else if (filterTablesByDate === "lastMonth") {
    dateFrom.setDate(today.getDate() - 30);
  } else if (filterTablesByDate === "previousMonth") {
    dateFrom.setMonth(today.getMonth() - 1);
    let range = getDateRange(dateFrom, "Month");
    dateFrom = range.startDate;
    dateTo = range.endDate;
  }

  rows.forEach((row) => {
    if (
      new Date(row[rowDateKey]).getTime() >= dateFrom.getTime() &&
      new Date(row[rowDateKey]).getTime() <= dateTo.getTime()
    ) {
      rowsFilteredLocal.push(row);
    }
  });

  return rowsFilteredLocal;
}
