import { DialogContent } from "@material-ui/core";
import { Form, Formik } from "formik";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TextFieldFormik from "../../../../../helpersComponents/FormikInputs/TextField/textField";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../../../../helpersComponents/PopupHelpers";
import * as Yup from "yup";
import useFetchOtherThanGET from "../../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import AutocompleteFormik from "../../../../../helpersComponents/FormikInputs/Autocomplete/autocomplete";
import { EvidenceOperationType } from "../../../../../../enums/EvidenceOperationType";

interface Props {
  open: boolean;
  summariesIds: number[];
  onApplyChanges: any;
  onCancelChanges: any;
}

const CompleteAccordingToTheStandardPopup = ({
  open,
  summariesIds,
  onApplyChanges,
  onCancelChanges,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const [bodyRequest, setBodyRequest] = useState<any>(false);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: "superior-time/summaries-operations",
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    disableErrorSnackbar: false,
    disableSuccessSnackbar: false,
  });

  const operationsTypes = [
    { id: 0, name: t("complete_work_operation_type_employee_forgot_card") },
    {
      id: 1,
      name: t("complete_work_operation_type_employee_used_card_incorrectly"),
    },
    { id: 2, name: t("complete_work_operation_type_make_up_for_lost_work") },
    { id: 3, name: t("other") },
  ];

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  const applyChanges = useRef(onApplyChanges);

  useEffect(() => {
    if (
      !fetchingStatePostData.isFetching &&
      fetchingStatePostData.response?.status === 200
    ) {
      if (applyChanges && applyChanges.current) {
        setBodyRequest(false);
        applyChanges.current();
      }
    }
  }, [
    fetchingStatePostData.isFetching,
    fetchingStatePostData.response?.status,
  ]);

  interface OperationType {
    id: number;
    name: string;
  }

  interface CompleteAccordingToTheStandardFormValues {
    operation_type: OperationType | null;
    comment: string;
  }

  return (
    <DialogStyled open={open} onClose={onCancelChanges}>
      <DialogTitleStyled title={t("complete_according_to_the_standard")} />
      <DialogContent>
        <Formik
          initialValues={
            {
              operation_type: null,
              comment: "",
            } as CompleteAccordingToTheStandardFormValues
          }
          onSubmit={(values) => {
            setBodyRequest(
              JSON.stringify({
                summariesIds: summariesIds,
                operationType: values.operation_type?.id,
                comment: values.comment,
              })
            );
          }}
          validationSchema={Yup.object({
            operation_type: Yup.object()
              .nullable()
              .required(t("field_required")),
            comment: Yup.string().test(
              "testCommentIsRequired",
              t("field_required"),
              function (value) {
                return !(
                  this.parent.comment &&
                  this.parent.operationType?.id ===
                    EvidenceOperationType.COMPLETE_ACCORDING_TO_THE_STANDARD_CUSTOM_COMMENT
                );
              }
            ),
          })}
        >
          {({ values }) => (
            <Form noValidate id="submitForm">
              <Row>
                <RowTitle>{t("operation_type")}:</RowTitle>
                <RowInput>
                  <AutocompleteFormik
                    options={operationsTypes}
                    getOptionLabel={(option: OperationType) => {
                      return `${option.name}`;
                    }}
                    width="100%"
                    getOptionSelected={(
                      option: OperationType,
                      value: OperationType
                    ) => {
                      return option.id === value.id;
                    }}
                    label={t("select_operation_type")}
                    name="operation_type"
                    required={true}
                  />
                </RowInput>
              </Row>

              {values.operation_type?.id ===
                EvidenceOperationType.COMPLETE_ACCORDING_TO_THE_STANDARD_CUSTOM_COMMENT && (
                <Row>
                  <RowTitle>{t("comment")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      ifToUpperCaseShipsAndPorts={true}
                      label={t("comment")}
                      name="comment"
                      type="text"
                      required={true}
                    />
                  </RowInput>
                </Row>
              )}
            </Form>
          )}
        </Formik>
      </DialogContent>

      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={onCancelChanges}
        isLoading={fetchingStatePostData.isFetching}
      />
    </DialogStyled>
  );
};

export default CompleteAccordingToTheStandardPopup;
