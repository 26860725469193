import { ProfileType } from "../../../../enums/profileType";

interface Params {
  authUserType: number | undefined;
  repeatNotification: INotificationInPortsGuest | null;
  targetDepartments: TargetDepartmentInterface[] | null | undefined;
  typesOfVisits: TypeOfVisitInterface[] | null;
  permissionZones: PermissionZone[] | null;
  EMPLOYEE_EMAIL_REQUIRED: boolean | undefined;
  acUserEmail?: string | null;
  acUserPhone?: string | null;
}

const getInitialState: (values: Params) => InitStateInterface = ({
  authUserType,
  repeatNotification,
  targetDepartments,
  typesOfVisits,
  permissionZones,
  EMPLOYEE_EMAIL_REQUIRED,
  acUserEmail,
  acUserPhone,
}) => {
  const initState: InitStateInterface = {
    dateFrom: repeatNotification?.notification.dateFrom
      ? new Date(repeatNotification.notification.dateFrom)
      : new Date(),
    dateTo: repeatNotification?.notification.dateTo
      ? new Date(repeatNotification.notification.dateTo)
      : new Date(),
    company: repeatNotification?.notification.companyName
      ? repeatNotification.notification.companyName
      : "",
    targetDepartment: !EMPLOYEE_EMAIL_REQUIRED
      ? repeatNotification?.notification.targetDepartmentId && targetDepartments
        ? targetDepartments.find(
            (department: TargetDepartmentInterface) =>
              department.id ===
              repeatNotification.notification.targetDepartmentId
          )
        : (authUserType === ProfileType.EMPLOYEE ||
            authUserType === ProfileType.OHS) &&
          targetDepartments?.[0]
        ? targetDepartments?.[0]
        : null
      : null,
    acSuperiorEmail: EMPLOYEE_EMAIL_REQUIRED
      ? repeatNotification?.notification.acSuperiorEmail
        ? repeatNotification.notification.acSuperiorEmail
        : authUserType !== undefined && acUserEmail
        ? acUserEmail
        : ""
      : "",
    acSuperiorPhoneOrRadio:
      EMPLOYEE_EMAIL_REQUIRED &&
      (authUserType === ProfileType.EMPLOYEE ||
        authUserType === ProfileType.OHS)
        ? repeatNotification?.notification.acSuperiorPhoneOrRadio
          ? repeatNotification.notification.acSuperiorPhoneOrRadio
          : acUserPhone
          ? acUserPhone
          : ""
        : "",
    typeOfVisit:
      repeatNotification?.notification.typeOfVisitId && typesOfVisits
        ? typesOfVisits.find(
            (typeOfVisit: TypeOfVisitInterface) =>
              typeOfVisit.id === repeatNotification.notification.typeOfVisitId
          )!
        : null,
    plateNumber: repeatNotification?.notification.registrationNumber
      ? repeatNotification.notification.registrationNumber
      : "",
    guestsArray: repeatNotification?.guests
      ? repeatNotification.guests.map((item) => ({
          id: item.guest.id,
          firstName: item.guest.firstName ? item.guest.firstName : "",
          lastName: item.guest.lastName ? item.guest.lastName : "",
          dateOfBirth: item.guest.dateOfBirth
            ? new Date(item.guest.dateOfBirth)
            : null,
          email: item.guest.email ? item.guest.email : "",
          isSelectedEmail: item.guest.email ? true : false,
          isSelectedDateOfBirth: item.guest.dateOfBirth ? true : false,
          isGuestOnBlacklist: false,
        }))
      : [
          {
            id: 0,
            firstName: "",
            lastName: "",
            dateOfBirth: null,
            email: "",
            isSelectedEmail: false,
            isSelectedDateOfBirth: false,
            isGuestOnBlacklist: false,
          },
        ],
    information: repeatNotification?.notification.comments
      ? repeatNotification.notification.comments
      : "",
    isWorkPermitRequiredTrue: repeatNotification
      ? repeatNotification.notification.isWorkPermitRequired === true
        ? true
        : false
      : false,
    isWorkPermitRequiredFalse: repeatNotification
      ? repeatNotification.notification.isWorkPermitRequired === false
        ? true
        : false
      : false,
    workPermitNumber: repeatNotification?.notification.workPermitNumber
      ? repeatNotification.notification.workPermitNumber
      : "",
    workArea: repeatNotification?.notification.workArea
      ? repeatNotification.notification.workArea
      : "",
    isWorkParticularlyDangerousTrue: repeatNotification
      ? repeatNotification.notification.isWorkParticularlyDangerous === true
        ? true
        : false
      : false,
    isWorkParticularlyDangerousFalse: repeatNotification
      ? repeatNotification.notification.isWorkParticularlyDangerous === false
        ? true
        : false
      : false,
    workParticularlyDangerousComments: repeatNotification?.notification
      .workParticularlyDangerousComments
      ? repeatNotification.notification.workParticularlyDangerousComments
      : "",
    permissionZone: repeatNotification?.notification.permissionZoneId
      ? permissionZones?.find(
          (pz) => pz.id === repeatNotification.notification.permissionZoneId
        )!
      : null,
    bh3: repeatNotification?.notification.bH3
      ? repeatNotification?.notification.bH3
      : false,
    captcha: "",
  };

  return initState;
};

export default getInitialState;
