import { FormControlLabel } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import ButtonStyled from "../../helpersComponents/Button/button";
import SwitchStyled from "../../helpersComponents/MaterialUi/Switch/StyledSwitch";
import AccordionStyled from "../../helpersComponents/MaterialUi/Accordion/AccordionStyled";
import styles from "./setting.module.scss";

interface IProps {
  title: string;
  switchChecked: boolean;
  switchOnChange: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  switchDisabled?: boolean;
  submitChanges: () => void;
  errorMessage: string;
}

const Setting: React.FC<IProps> = ({
  title,
  switchChecked,
  switchOnChange,
  switchDisabled,
  submitChanges,
  errorMessage,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <AccordionStyled title={title} defaultExpanded={false}>
      <div>
        <div className={styles.content}>
          <FormControlLabel
            control={
              <div className={styles.switchContainer}>
                <div>{t("off").toUpperCase()}.</div>
                <SwitchStyled
                  className={styles.switch}
                  checked={switchChecked}
                  onChange={switchOnChange}
                  disabled={switchDisabled}
                />
                <div>{t("on").toUpperCase()}.</div>
              </div>
            }
            label={""}
          />

          {children}

          <div className={styles.saveChanges}>
            <ButtonStyled onClick={submitChanges} className={styles.saveButton}>
              {t("save")}
            </ButtonStyled>
          </div>

          <div className={styles.errorSettings}>{errorMessage}</div>
        </div>
      </div>
    </AccordionStyled>
  );
};

export default Setting;
