import React from 'react';
import ProjectsHomeContent from "./projectsHomeContent"


function ProjectsHome() {
    return (
        <div>
            <ProjectsHomeContent/>
        </div>
    );
}

export default ProjectsHome;
