import { faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import toLocaleStringCustom from "../../../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import useConfirm from "../../../../../../hooks/useConfirm/useConfirm";
import { CheckboxStyled } from "../../../../../helpersComponents/MaterialUi";
import StyledDataGrid from "../../../../../helpersComponents/MaterialUi/DataGrid/StyledDataGrid";
import PopupSpecialPermissionItem from "./SpecialPermissionItem/PopupSpecialPermissionItem";
import styles from "./SpecialPermissionsForm.module.scss";

interface IProps {
  formFields: FormFieldsACWorker;
  formikValues: {
    [x: string]:
      | string
      | number
      | boolean
      | number[]
      | null
      | any[]
      | Date
      | any;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const SpecialPermissionsForm: React.FC<IProps> = ({
  formFields,
  formikValues,
  setFieldValue,
}) => {
  const { t } = useTranslation();

  const { specialPermissions } = formFields;

  const columnsSpecialPermissions: GridColDef[] = [
    {
      field: "roomName",
      headerName: t("room"),
      flex: 2,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.roomName}</span>
      ),
    },
    {
      field: "specialPermissionNumberName",
      headerName: t("permission"),
      flex: 2,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {t(params.row.specialPermissionNumberName)}
        </span>
      ),
    },
    {
      field: "dayName",
      headerName: t("day"),
      flex: 3,
      renderCell: (params) => (
        <span className={styles.columnSpan}>{params.row.dayName}</span>
      ),
    },
    {
      field: "isHoliday",
      headerName: t("or_on_holiday"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          <CheckboxStyled checked={params.row.isHoliday} disabled />
        </span>
      ),
    },
    {
      field: "entryFrom",
      headerName: t("from"),
      flex: 0.5,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {new Date(params.row.entryFrom).toLocaleString(
            t("scheduler_language"),
            {
              hour: "2-digit",
              minute: "2-digit",
            }
          )}
        </span>
      ),
    },
    {
      field: "entryTo",
      headerName: t("to"),
      flex: 0.5,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {new Date(params.row.entryTo).toLocaleString(
            t("scheduler_language"),
            {
              hour: "2-digit",
              minute: "2-digit",
            }
          )}
        </span>
      ),
    },
    {
      field: "rangeFrom",
      headerName: t("is_valid_from"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {toLocaleStringCustom({
            date: params.row.rangeFrom,
            t,
            ifShowHours: false,
          })}
        </span>
      ),
    },
    {
      field: "rangeTo",
      headerName: t("is_valid_to"),
      flex: 1,
      renderCell: (params) => (
        <span className={styles.columnSpan}>
          {toLocaleStringCustom({
            date: params.row.rangeTo,
            t,
            ifShowHours: false,
          })}
        </span>
      ),
    },
  ];

  const [specialPermissionItemPopup, setSpecialPermissionItemPopup] =
    useState(false);
  const closeSpecialPermissionItemPoup = useCallback(() => {
    setSpecialPermissionItemPopup(false);
  }, []);

  const addPermissionHandler = () => {
    setSpecialPermissionItemPopup(true);
  };

  const [specialPermissionsIdsToRemove, setSpecialPermissionsIdsToRemove] =
    useState<number[]>([]);
  const { confirm } = useConfirm();
  const specialPermissionRemoveHandler = async () => {
    let confirmResponse = await confirm({
      text: `${t("are_you_sure_you_want_to_remove_selected_permissions")}?`,
      buttons: [
        { buttonName: t("yes"), buttonId: 0 },
        { buttonName: t("cancel"), buttonId: 1 },
      ],
    });
    if (confirmResponse === 0) {
      const specialPermissionsList = formikValues[
        `${specialPermissions.name}`
      ] as SpecialPermission[];
      const filtered = specialPermissionsList.filter(
        (specialPermission) =>
          !specialPermissionsIdsToRemove.includes(specialPermission.id)
      );
      setFieldValue(specialPermissions.name, filtered);
    }
  };

  return (
    <div className={styles.gridSpecialPermissions}>
      <div className={styles.titleContainer}>
        <p className={styles.gridTitle}>{t("special_permissions")}</p>
        <div className={styles.buttonsContainer}>
          <div>
            <Tooltip title={t("add")!}>
              <div
                className={styles.permissionButton}
                onClick={addPermissionHandler}
              >
                <FontAwesomeIcon icon={faPlusCircle} size="lg" color="green" />
              </div>
            </Tooltip>
          </div>

          <div>
            {specialPermissionsIdsToRemove.length > 0 && (
              <Tooltip title={t("delete")!}>
                <div
                  className={styles.permissionButton}
                  onClick={specialPermissionRemoveHandler}
                >
                  <FontAwesomeIcon icon={faTrashAlt} size="lg" color="red" />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <StyledDataGrid
        rows={formikValues[`${specialPermissions.name}`] as SpecialPermission[]}
        columns={columnsSpecialPermissions}
        defaultPageSize={10}
        rowsPerPageOptions={[10]}
        density="compact"
        quickFilter
        checkboxSelection={true}
        onSelectionModelChange={(selected) => {
          setSpecialPermissionsIdsToRemove(selected as number[]);
        }}
      />

      {specialPermissionItemPopup && (
        <PopupSpecialPermissionItem
          isOpen={specialPermissionItemPopup}
          closePopup={closeSpecialPermissionItemPoup}
          formikValues={formikValues}
          setFieldValue={setFieldValue}
          specialPermissionsFormField={specialPermissions}
        />
      )}
    </div>
  );
};

export default SpecialPermissionsForm;
