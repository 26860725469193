const convertSecondsToHrsMinsSeconds = (seconds) => {
  if (seconds >= 0) {
    let h: any = Math.floor(seconds / 60 / 60);
    let m: any = Math.floor((seconds / 60) % 60);
    let s: any = Math.round(seconds % 60);
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;
    return `${h}:${m}:${s}`;
  } else {
    seconds = -1 * seconds;
    let h: any = Math.floor(seconds / 60 / 60);
    let m: any = Math.floor((seconds / 60) % 60);
    let s: any = Math.round(seconds % 60);
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;

    return `-${h}:${m}:${s}`;
  }
};
export default convertSecondsToHrsMinsSeconds;
