import { useTranslation } from "react-i18next";
import {
  AutocompleteFormik,
  TextFieldFormik,
} from "../../../../../helpersComponents/FormikInputs";
import CheckboxWithLabelFormik from "../../../../../helpersComponents/FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import {
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../../../../helpersComponents/PopupHelpers";

interface Props {
  formFields: FormFieldsACWorker;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  departmentsList: Department[];
  acSuperiorsList: ACUser[];
  zonesList: Zone[];
  meetingPlacesList: MeetingPlace[];
}

const ACWorkerDataForm: React.FC<Props> = ({
  formFields,
  setFieldValue,
  departmentsList,
  acSuperiorsList,
  zonesList,
  meetingPlacesList,
}) => {
  const { t } = useTranslation();

  const {
    identifier,
    description,
    position,
    phone,
    email,
    department,
    additions,
    isTracked,
    acSuperiors,
    zones,
    isKP,
    firstDay,
    meetingPlace,
    regularPost,
  } = formFields;

  return (
    <>
      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>{t(identifier.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t(identifier.label)}
              name={identifier.name}
              type="text"
            />
          </RowInput>
        </Row>
        <Row>
          <RowTitle width={180}>{t(description.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t(description.label)}
              name={description.name}
              type="text"
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>{t(position.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t(position.label)}
              name={position.name}
              type="text"
              required={true}
            />
          </RowInput>
        </Row>
        <Row>
          <RowTitle width={180}>{t(phone.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t(phone.label)}
              name={phone.name}
              type="text"
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>{t(email.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t(email.label)}
              name={email.name}
              type="text"
            />
          </RowInput>
        </Row>
        <Row>
          <RowTitle width={180}>{t(department.label)}:</RowTitle>
          <RowInput>
            <AutocompleteFormik
              options={departmentsList}
              getOptionLabel={(option: Department) => {
                return option.name;
              }}
              width="100%"
              getOptionSelected={(option: Department, value: Department) => {
                return option.id === value.id;
              }}
              label={t(department.label)}
              name={department.name}
              ListboxProps={{ style: { maxHeight: "12rem" } }}
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>
            <CheckboxWithLabelFormik
              name={isTracked.name}
              label={<span>{t(isTracked.label)}:</span>}
            />
          </RowTitle>
          <RowInput>
            <AutocompleteFormik
              options={acSuperiorsList}
              getOptionLabel={(option: ACUser) => {
                return option.login;
              }}
              width="100%"
              getOptionSelected={(option: ACUser, value: ACUser) => {
                return option.id === value.id;
              }}
              label={t(acSuperiors.label)}
              name={acSuperiors.name}
              multiple
              disableCloseOnSelect
              limitTags={3}
              ListboxProps={{ style: { maxHeight: "12rem" } }}
            />
          </RowInput>
        </Row>
        <Row>
          <RowTitle width={180}>{t(zones.label)}:</RowTitle>
          <RowInput>
            <AutocompleteFormik
              options={zonesList}
              getOptionLabel={(option: Zone) => {
                return option.name;
              }}
              width="100%"
              getOptionSelected={(option: Zone, value: Zone) => {
                return option.id === value.id;
              }}
              label={t(zones.label)}
              name={zones.name}
              multiple
              disableCloseOnSelect
              limitTags={3}
              ListboxProps={{ style: { maxHeight: "12rem" } }}
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>{t(firstDay.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t(firstDay.label)}
              name={firstDay.name}
              type="number"
              onChangeOwn={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = Number(event.target.value);
                if (newValue >= 1 && newValue <= 7)
                  setFieldValue(firstDay.name, newValue);
              }}
            />
          </RowInput>
        </Row>
        <Row>
          <RowTitle width={180}>{t(meetingPlace.label)}:</RowTitle>
          <RowInput>
            <AutocompleteFormik
              options={meetingPlacesList}
              getOptionLabel={(option: MeetingPlace) => {
                return option.name;
              }}
              width="100%"
              getOptionSelected={(
                option: MeetingPlace,
                value: MeetingPlace
              ) => {
                return option.id === value.id;
              }}
              label={t(meetingPlace.label)}
              name={meetingPlace.name}
              ListboxProps={{ style: { maxHeight: "12rem" } }}
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>
            <></>
          </RowTitle>
          <RowInput>
            <CheckboxWithLabelFormik
              name={isKP.name}
              label={<span>{t(isKP.label)}</span>}
            />
          </RowInput>
        </Row>
        <Row>
          <RowTitle width={180}>{t(regularPost.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t(regularPost.label)}
              name={regularPost.name}
              type="number"
              inputProps={{
                step: "0.1",
              }}
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle width={180}>{t(additions.label)}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t(additions.label)}
              name={additions.name}
              type="text"
              multiline={true}
              rows={5}
            />
          </RowInput>
        </Row>
        <Row>
          <RowTitle width={180}>{""}</RowTitle>
          <RowInput>
            <></>
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>
    </>
  );
};

export default ACWorkerDataForm;
