import { DialogTitle, DialogTitleProps } from "@material-ui/core";
import { ReactElement } from "react";

interface Props extends DialogTitleProps {
  title: string;
  subtitle?: string;
  classNameTitle?: string;
  classNameSubtitle?: string;
}
export default function DialogTitleStyled({
  title,
  subtitle,
  classNameTitle,
  ...restPorps
}: Props): ReactElement {
  return (
    <DialogTitle className="dialogTitleGlobal" {...restPorps}>
      <div className={classNameTitle ? classNameTitle : "titlePopup"}>
        {title}
      </div>

      {subtitle && (
        <div
          className={classNameTitle ? classNameTitle : "dialogSubtitleGlobal"}
        >
          {subtitle}
        </div>
      )}
    </DialogTitle>
  );
}
