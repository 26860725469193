import { ProfileType } from "../../../enums/profileType";
import ContentArrivalsOfShips from "../../helpersComponents/ArrivalsOfShips/ContentArrivalsOfShips";

function ArrivalsOfShipsPfso() {
  return (
    <ContentArrivalsOfShips
      pathApiArrivalsOfShips="pfso/arrivals-of-ships"
      pathApiAttachmentsInPorts="pfso/attachments-in-ports"
      layoutSettingsName="layoutPfsoArrivalOfShipRecords"
      profileType={ProfileType.PFSO}
    />
  );
}

export default ArrivalsOfShipsPfso;
