import React, { useCallback, useEffect, useState } from "react";
import AddEditUniversalRequestRequestPopup from "./../../../helpersComponents/Requests/UniversalRequests/AddEditUniversalRequestPopup";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { SnackbarStyled } from "../../../helpersComponents/MaterialUi";
import RowDetail from "./rowDetail";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import { statusDictionary } from "../../../../Constants/statusDictionary";
import TableAndActions from "./tableAndActions";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { useHistory } from "react-router-dom";
import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";

let Cell: any = TableEditColumn.Cell;

const ActionColumn: React.FC = ({
  children,
  row,
  classes,
  ...restProps
}: any) => {
  return (
    <Cell
      row={restProps.row}
      className="tableCellEditCancelStyleGlobal"
      style={{ textAlign: "left" }}
    >
      {children}
    </Cell>
  );
};

function UniversalRequestsContent() {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1, 0, 0, 0)
  );

  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31, 0, 0)
  );
  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);
  const history: any = useHistory();
  const { confirm } = useConfirm();

  const columns = [
    {
      name: "templateName",
      title: t("request_type"),
    },
    { name: "employee", title: t("employee") },
    { name: "evidenceNumber", title: t("evidence_number") },
    {
      name: "createdBy",
      title: t("created"),
    },
    {
      name: "createdAt",
      title: t("data_created"),
      type: "date",
    },
    {
      name: "statusTranslated",
      title: t("request_status"),
    },
  ];

  const ActionColumnEnhanced: React.FC = (props) => {
    let newProps = {
      ...props,
      /*openBackOffDaysDialog,
      setOpenBackOffDaysDialog,
      setCurrentRow,*/
    };

    return ActionColumn(newProps);
  };

  const universalRequestsResponseCallback = useCallback(
    (responseData) => {
      const modified = responseData?.map((row: any) => {
        return {
          ...row,
          statusTranslated: t(statusDictionary[row.status]),
          employee: row.firstName + " " + row.lastName,
        };
      });
      return modified;
    },
    [t]
  );

  const [
    universalRequests,
    fetchingStateUniversalRequests,
    fetchAgainUniversalRequests,
  ] = useFetchAndSetGET({
    path: `superior-time/universal-requests?dateFrom=${computeDateRangeFetch(
      startDate
    )}&dateTo=${computeDateRangeFetch(endDate)}`,
    modifyResponseCallback: universalRequestsResponseCallback,
  });

  const [requestIdToRemove, setRequestIdToRemove] = useState<false | number>(
    false
  );

  const [deleteRequestFetchingState, deleteRequestFetchAgain] =
    useFetchOtherThanGET({
      path: `superior-time/universal-requests/${requestIdToRemove}`,
      method: "DELETE",
      body: requestIdToRemove,
      setBody: setRequestIdToRemove,
      successCallback: fetchAgainUniversalRequests,
    });

  useEffect(() => {
    if (requestIdToRemove !== false) {
      deleteRequestFetchAgain();
    }
  }, [requestIdToRemove, deleteRequestFetchAgain]);

  const commitChanges = async ({ added, changed, deleted }) => {
    if (deleted) {
      let row: any = universalRequests.find((row) => row.id === deleted[0]);

      let selectedbuttonId = await confirm({
        text: `${t("are_you_sure_you_want_to_delete_request")}`,
        buttons: [
          { buttonName: t("remove"), buttonId: 0 },
          { buttonName: t("cancel"), buttonId: 1 },
        ],
      });
      if ((await selectedbuttonId) === 0) {
        setRequestIdToRemove(row.id);
      }
    } else if (added) {
      fetchAgainUniversalRequests();
      setSnackbarState([true, "success", t("application_added")]);
    } else if (changed) {
      fetchAgainUniversalRequests();
      setSnackbarState([true, "success", t("application_edited")]);
    }
  };

  return (
    <>
      <TopBanner pathName={t("universal_requests")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
          ></DatePickerRange>
        </div>

        <LoadingWrapper
          isLodadingProgress={fetchingStateUniversalRequests.isFetching}
          isError={fetchingStateUniversalRequests.isError}
          setIfFetchAgain={() => {
            fetchAgainUniversalRequests();
          }}
          //setIsError={setIsError}
        >
          {!fetchingStateUniversalRequests.isFetching &&
            !fetchingStateUniversalRequests.isError && (
              <TableAndActions
                rows={universalRequests}
                columns={columns}
                ifAdd={true}
                ifEdit={true}
                ifDelete={true}
                deleteCommandName={t("delete")}
                actionColumn={ActionColumnEnhanced}
                popup={AddEditUniversalRequestRequestPopup}
                openPopup={history.location.state ? true : false}
                parallerPatchPath={"superior-time/universal-requests"}
                settingsLayoutName={"layoutSuperiorTimeListsUniversalRequests"}
                rowDetail={RowDetail}
                fetchObject={{
                  fetchAgain: fetchAgainUniversalRequests,
                  isError: fetchingStateUniversalRequests.isError,
                  isFetching:
                    fetchingStateUniversalRequests.isFetching ||
                    deleteRequestFetchingState.isFetching,
                  response: fetchingStateUniversalRequests.response,
                  setIsFetching: () => {},
                }}
                commitChanges={commitChanges}
              />
            )}
        </LoadingWrapper>
      </div>
      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </>
  );
}

export default UniversalRequestsContent;
