import { useTranslation } from "react-i18next";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import { profileTranslationKeysDictionary } from "../../ProfilesRouting/profilesDictionaries";
import FormAddDelivery from "./form/FormAddDelivery";

const ContentDeliverer = () => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const currentProfile = authUser.currentProfile?.type;
  let extraPathName = currentProfile
    ? " - " + t(profileTranslationKeysDictionary[currentProfile])
    : "";

  return (
    <>
      <div>
        <TopBanner
          pathName={t("form_notification") + extraPathName}
          showGoToLoginPage={authUser?.token ? false : true}
          hideLogOut={authUser?.token ? false : true}
          showBackButton={authUser?.token ? true : false}
        />
      </div>
      <div>
        <FormAddDelivery />
      </div>
    </>
  );
};

export default ContentDeliverer;
