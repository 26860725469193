function compareDatesIgnoringTime(dateOne: Date, dateTwo: Date): boolean {
  dateOne = new Date(dateOne);
  dateTwo = new Date(dateTwo);

  return dateOne.getFullYear() === dateTwo.getFullYear() &&
    dateOne.getMonth() === dateTwo.getMonth() &&
    dateOne.getDate() === dateTwo.getDate()
    ? true
    : false;
}

export default compareDatesIgnoringTime;
